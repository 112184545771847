import "./OpenTableOrderStateWizard.scss";

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenTableOrderStateFlowPages, openTableOrderStateWizard } from "../wizards";
import {
    getOpenTableMemberHasFailedToSubmitOrders,
    getOpenTableMemberHasUnconfirmedOrders,
    getOpenTableMemberHasResubmittingOrders,
    getOpenTableMemberHasFailedToSubmitItemsInActiveOrder,
} from "../selectors/orders";
import { StateWizardModal } from "../../wizards/components/StateWizardModal";
import { actionCreators as openTableActionCreators } from "../reducers";
import { getParty, MemberActivity, OrderStatus } from "../../order";
import { actionCreators as cartActionCreators } from "../../order/reducers/cart";
import { createSelector } from "reselect";
import { getPayOnlyPartyShutdown, getPayOnlyPaymentOrder } from "../../payOnly/selectors";
import { usePrevious } from "../../../sharedComponents/common/shared";
import { WizardOptionsT } from "../../wizards/wizard";
import { resetModalMessage } from "../../modalMessage/actions/reset";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getCurrentMemberInAcceptedMembers } from "src/features/order/selectors";

const getIsOrderProcessComplete = createSelector(
    openTableOrderStateWizard.getState,
    getOpenTableMemberHasUnconfirmedOrders,
    getOpenTableMemberHasFailedToSubmitOrders,
    getPayOnlyPaymentOrder,
    getPayOnlyPartyShutdown,
    getCurrentMemberInAcceptedMembers,
    getOpenTableMemberHasFailedToSubmitItemsInActiveOrder,
    (orderStateWizardState,
     memberHasUnconfirmedOrders,
     memberHasFailedToSubmitOrders,
     order,
     partyShutdown,
     memberInAcceptedMembers,
     memberHasFailedToSubmitItems
    ) =>
        !!orderStateWizardState &&
        !memberHasUnconfirmedOrders &&
        !memberHasFailedToSubmitOrders &&
        !(memberInAcceptedMembers || memberHasFailedToSubmitItems) &&
        order?.status !== OrderStatus.VALIDATING &&
        !partyShutdown
);

export const OpenTableOrderStateWizard = () => {
    const dispatch = useDispatch();

    const memberHasUnconfirmedOrders = useSelector(getOpenTableMemberHasUnconfirmedOrders);
    const memberHadUnconfirmedOrders = usePrevious(memberHasUnconfirmedOrders);
    const memberInAcceptedMembers = useSelector(getCurrentMemberInAcceptedMembers);
    const memberHasFailedToSubmitOrders = useSelector(getOpenTableMemberHasFailedToSubmitOrders);
    const memberHasFailedToSubmitItems = useSelector(getOpenTableMemberHasFailedToSubmitItemsInActiveOrder);
    const orderWasUnsubmittedForRejectedItems = memberInAcceptedMembers || memberHasFailedToSubmitItems;
    const partyShutdown = useSelector(getPayOnlyPartyShutdown);
    const party = useSelector(getParty);
    const prevParty = usePrevious(party);
    const wizardState = useSelector(openTableOrderStateWizard.getState);

    const moveToPayingActivity = useCallback(() => {
        dispatch(openTableActionCreators.setActivity(MemberActivity.Paying, true));
        dispatch(cartActionCreators.showCart(false));
        dispatch(resetModalMessage());
    }, [dispatch]);

    const startWizard = useCallback(
        (page: OpenTableOrderStateFlowPages, triggeredOnRefresh?: boolean) => {
            const options: WizardOptionsT<OpenTableOrderStateFlowPages> | undefined = triggeredOnRefresh
                ? { overrideStartTransition: "no-animation" }
                : undefined;

            dispatch(openTableOrderStateWizard.actionCreators.start(page, options));
        },
        [dispatch]
    );

    useEffect(() => {
        if (wizardState) return;

        const triggeredOnRefresh = !prevParty;
        if (memberHasUnconfirmedOrders) {
            startWizard("SubmittingOrder", triggeredOnRefresh);
        } else if (memberHasFailedToSubmitOrders) {
            startWizard("OrderFailedToSubmit", triggeredOnRefresh);
        }
    }, [startWizard, memberHasUnconfirmedOrders, memberHasFailedToSubmitOrders, prevParty, wizardState]);

    useEffect(() => {
        if (!party) {
            dispatch(openTableOrderStateWizard.actionCreators.done());
        }
    }, [dispatch, party]);

    useEffect(() => {
        if (!memberHadUnconfirmedOrders || !orderWasUnsubmittedForRejectedItems) {
            return;
        }

        dispatch(openTableActionCreators.setActivity(MemberActivity.Ordering, true));
        dispatch(cartActionCreators.showCart(true));

        if (memberHasFailedToSubmitItems) {
            dispatch(showModalMessage(modalMessages.itemsRejected()));
        }
    }, [dispatch, memberHadUnconfirmedOrders, orderWasUnsubmittedForRejectedItems, memberHasFailedToSubmitItems])

    return (
        <StateWizardModal
            wizard={openTableOrderStateWizard}
            pages={{
                SubmittingOrder: {
                    type: "progress",
                    text: "Submitting order...",
                    getAutoPrevTrigger: getOpenTableMemberHasResubmittingOrders,
                    onTransitionComplete: moveToPayingActivity,
                    autoClose: partyShutdown || orderWasUnsubmittedForRejectedItems,
                },
                OrderFailedToSubmit: {
                    type: "failure",
                    text: [
                        "Unable to confirm if your order was received by the venue",
                        "Contact your server to continue",
                    ],
                    getAutoNextTrigger: getOpenTableMemberHasFailedToSubmitOrders,
                    onTransitionComplete: moveToPayingActivity,
                    autoClose: partyShutdown,
                },
                OrderSubmitted: {
                    type: "success",
                    text: "Your order was submitted",
                    getAutoNextTrigger: getIsOrderProcessComplete,
                    autoClose: true,
                },
            }}
        />
    );
};
