import "./assets/CustomTip.scss";

import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { actionCreators, TipType } from "src/features/payment";
import {
    getCustomTip,
    getDisplayTipAmount,
    getPaymentTipAmount,
    getPaymentTipPercentage,
    getTipAmount,
    getTipType,
} from "src/features/payment/selectors";
import { Button, FormControl, MaskedInput, masks, Text } from "src/sharedComponents";
import classNames from "classnames";

interface Props {
    renderPaymentButton: (disabled: boolean) => JSX.Element;
    calculatedTipPercentage?: number;
    calculatedTipAmount?: number;
}

const MAX_LIMIT_CURRENCY = 99999;
const MAX_LIMIT_PERCENTAGE = 9999;

export const CustomTip = ({ renderPaymentButton, calculatedTipPercentage, calculatedTipAmount }: Props) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const {
        currencySymbol,
        thousandsSeparatorSymbol,
        decimalSymbol: decimalSeparatorSymbol,
    } = useMemo(() => menuDataLocale.getCurrencySymbols(), [menuDataLocale]);

    const currencyMask = useMemo(
        () =>
            masks.currency({
                currencySymbol,
                thousandsSeparatorSymbol,
                decimalSeparatorSymbol,
                max: MAX_LIMIT_CURRENCY * 10, // we want to block the user when he exceed the limit by 1 digit, so he will see the error
                padDecimalPlaces: true,
            }),
        [currencySymbol, thousandsSeparatorSymbol, decimalSeparatorSymbol]
    );

    const percentageMask = useMemo(
        () =>
            masks.percentage({
                thousandsSeparatorSymbol,
                decimalSeparatorSymbol,
                max: MAX_LIMIT_PERCENTAGE * 10, // we want to block the user when he exceed the limit by 1 digit, so he will see the error & won't loose a digit when switching between currency and percentage,
            }),
        [thousandsSeparatorSymbol, decimalSeparatorSymbol]
    );

    const currencyText = useMemo(() => {
        switch (menuDataLocale?.currency) {
            case "GBP":
                return "£ Pounds";
            default:
                return "$ Dollars";
        }
    }, [menuDataLocale]);

    const customTipAmount = useSelector(getTipAmount);
    const displayTipAmount = useSelector(getDisplayTipAmount);
    const customTip = useSelector(getCustomTip);
    const paymentTipAmount = useSelector(getPaymentTipAmount);
    const paymentTipPercentage = useSelector(getPaymentTipPercentage);
    const tipType = useSelector(getTipType);

    const dispatch = useDispatch();

    const resetTipAndClose = useCallback(() => {
        dispatch(actionCreators.resetCustomTipAmount());
    }, [dispatch]);

    const setTipAmount = useCallback(
        (tipType?: TipType, amount?: number, displayAmount?: string) => {
            if (tipType !== undefined) {
                dispatch(actionCreators.setCustomTipAmount(tipType, amount, displayAmount));
            }
        },
        [dispatch]
    );

    useEffect(
        () => () => {
            dispatch(actionCreators.cleanCustomTipSymbol());
        },
        [dispatch]
    );

    const inError =
        !!customTipAmount &&
        customTipAmount > (tipType === TipType.CURRENCY ? MAX_LIMIT_CURRENCY : MAX_LIMIT_PERCENTAGE);

    calculatedTipPercentage = calculatedTipPercentage ?? paymentTipPercentage;
    calculatedTipAmount = calculatedTipAmount ?? paymentTipAmount;

    const inWarning = !inError && tipType === TipType.CURRENCY && calculatedTipPercentage >= 10;

    return (
        <div className="custom-tip">
            <SimpleNavHeader
                withBorder
                title="Adding a tip"
                customBack="#tip-selection-(review-order)/custom"
                onBack={resetTipAndClose}
            />
            <div className={classNames("custom-tip__content", inWarning && "custom-tip__content--warning")}>
                <div className="custom-tip__buttons">
                    <Button
                        className="custom-tip__buttons__button"
                        mode={tipType === TipType.PERCENTAGE ? "outline" : undefined}
                        onClick={() => setTipAmount(TipType.CURRENCY, customTipAmount, displayTipAmount)}
                    >
                        <Text preset="g-14" mode={["bold", "block"]}>
                            {currencyText}
                        </Text>
                    </Button>
                    <Button
                        className="custom-tip__buttons__button"
                        mode={tipType === TipType.CURRENCY ? "outline" : undefined}
                        onClick={() => setTipAmount(TipType.PERCENTAGE, customTipAmount, displayTipAmount)}
                    >
                        <Text preset="g-14" mode={["bold", "block"]}>
                            % Percent
                        </Text>
                    </Button>
                </div>
                <FormControl
                    invalid={inError}
                    message={
                        !customTipAmount
                            ? undefined
                            : `Adding a tip of ${
                                  tipType === TipType.CURRENCY // Showing the Percentage value when Currency, and the Amount when Percentage
                                      ? menuDataLocale?.formatPercent(calculatedTipPercentage)
                                      : menuDataLocale?.formatCurrency(calculatedTipAmount)
                              }`
                    }
                    invalidMessage={`Whoa big spender! Tips cannot exceed ${
                        tipType === TipType.CURRENCY
                            ? menuDataLocale?.formatCurrencyNoDigits(MAX_LIMIT_CURRENCY)
                            : menuDataLocale?.formatPercent(MAX_LIMIT_PERCENTAGE / 100) // format number expect a decimal value: 9,999 ==> 999,900% so we provide 999.99 ==> 9,999%
                    }`}
                >
                    <MaskedInput
                        inputMode="decimal"
                        value={displayTipAmount || ""}
                        placeholder={tipType === TipType.PERCENTAGE ? "0%" : "$0"}
                        onAccept={(_, mask) => {
                            !!customTip &&
                                setTipAmount(
                                    tipType,
                                    !!mask.unmaskedValue ? Number(mask.unmaskedValue) : undefined,
                                    mask.value
                                );
                        }}
                        {...(tipType === TipType.CURRENCY ? currencyMask : percentageMask)}
                    />
                </FormControl>
            </div>
            {customTipAmount !== null && customTipAmount !== undefined ? (
                renderPaymentButton(inError)
            ) : (
                <Button mode="solid" disabled value="Enter amount to continue" />
            )}
        </div>
    );
};
