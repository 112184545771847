import { orderApi } from "../orderApi";
import { toggleEngageEnabledForPartyOperation } from "../operations";
import { actionCreators as partyActionCreators, OrderStatus } from "..";
import { AppDispatch } from "src/features";

export function updateWalletEnabledForParty(enabled: boolean) {
    return toggleEngageEnabledForPartyOperation.getThunk(async (dispatch: AppDispatch) => {
        if (!enabled) {
            dispatch(partyActionCreators.overrideActiveOrderStatus(OrderStatus.VALIDATING));
        }
        await orderApi.invoke("updateWalletEnabledForParty", enabled);
    });
}
