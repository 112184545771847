import { createSelector } from "reselect";
import { getIsGroupTabOpen } from "src/features/groupTabs/selectors/activeGroupTab";
import { getParty } from "src/features/order";
import { getOngoingOrderHistories } from "src/features/orderHistoryData/selectors";
import { getIsOpenTable, getIsOpenTableFlexTab } from "../../openTable/selectors";
import { getCurrentMemberHasSubmittedOrders, getPartyMembersLength } from "../../order/selectors";
import { getCurrentMemberHasSecuredPaymentMethod } from "../../securedPayment/selectors";
import { PreventLeavePartyReason } from "../../openTable/types";
import { getCurrentMemberId } from "./authState";

export const getCanDeleteAccount = createSelector(
    getParty,
    getOngoingOrderHistories,
    getIsGroupTabOpen,
    (party, ongoingOrderHistories, isGroupTabOpen) => !party && !ongoingOrderHistories.length && !isGroupTabOpen
);

export const getPreventLeavePartyReason = createSelector(
    getParty,
    getIsOpenTable,
    getCurrentMemberHasSubmittedOrders,
    getCurrentMemberHasSecuredPaymentMethod,
    getCurrentMemberId,
    getPartyMembersLength,
    getIsOpenTableFlexTab,
    (
        party,
        isOpenTable,
        hasSubmittedOrders,
        hasSecuredPaymentMethod,
        currentMemberId,
        partyMembersLength,
        isOpenTableFlexTab
    ) => {
        if (!isOpenTable) return null;
        if (hasSubmittedOrders) return PreventLeavePartyReason.HAS_SUBMITTED_ORDERS;
        if (hasSecuredPaymentMethod) return PreventLeavePartyReason.HAS_SECURED_PAYMENT_METHOD;
        if (isOpenTableFlexTab && party?.members[0].memberId === currentMemberId && partyMembersLength > 1)
            return PreventLeavePartyReason.IS_FLEX_TAB_ADMIN;

        return null;
    }
);
