import { AppDispatch, AppState } from "../../index";
import { disconnectParty } from "../api";
import { actionCreators as partyActions, getParty } from "../../order";
import { cleanPartyClosed } from "../../order/actions/partyClosed";
import { DeleteTableReason } from "../persistence/tableToken";

export const disconnectAndCloseParty = async (getState: () => AppState, next: AppDispatch, reason: DeleteTableReason) => {
    await disconnectParty(next, true, reason);
    const party = getParty(getState());
    if (party) {
        next(partyActions.closed(party));
        next(cleanPartyClosed());
    }
};
