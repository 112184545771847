import "react-phone-number-input/style.css";
import "./PhoneInput.scss";

import React, { ElementType, useCallback, useMemo } from "react";
import ReactPhoneNumberInput, {
    Props,
    Country,
    isValidPhoneNumber,
    getCountryCallingCode,
} from "react-phone-number-input";
import { useFormControlContext } from "../formControl";
import { Input, InputProps } from "./Input";
import { Text } from "../text";

export type CountryCode = Country;

export type CountryOption = "XX" | "🌐" | "|" | "..." | "…" | CountryCode;

export type PhoneInputProps = Props<Omit<InputProps, "ref">>;

interface Options {
    value?: CountryCode;
    label?: string;
    divider?: boolean;
}

interface CountrySelectComponentProps {
    value: CountryCode;
    options: Options[];
    onChange: (value?: string) => void;
    iconComponent: ElementType;
}

function getSelectedOption(options: Options[], value: CountryCode) {
    for (const option of options) {
        if (!option.divider && option.value === value) {
            return option;
        }
    }

    return null;
}

const CountrySelectComponent = ({
    value,
    options,
    onChange,
    iconComponent: Icon,
    ...rest
}: CountrySelectComponentProps) => {
    const selectedOption = useMemo(() => {
        return getSelectedOption(options, value);
    }, [options, value]);

    const handleOnChange = useCallback(
        (event) => {
            const value = event.target.value;
            onChange(value === "ZZ" ? undefined : value);
        },
        [onChange]
    );

    return (
        <div className="PhoneInputCountry">
            <select {...rest} className="PhoneInputCountrySelect" value={value || "ZZ"} onChange={handleOnChange}>
                {options.map(({ value, label, divider }) => (
                    <option
                        key={divider ? "|" : value || "ZZ"}
                        value={divider ? "|" : value || "ZZ"}
                        disabled={divider ? true : false}
                        style={
                            divider
                                ? {
                                      fontSize: "1px",
                                      backgroundColor: "currentColor",
                                      color: "inherit",
                                  }
                                : undefined
                        }
                    >
                        {label}
                    </option>
                ))}
            </select>
            <Icon aria-hidden country={value} label={selectedOption?.label} />
            <Text mode="bold" preset="g-16">{`+${getCountryCallingCode(value)}`}</Text>
        </div>
    );
};

export const PhoneInput = ({ id, disabled, invalid, ...rest }: PhoneInputProps) => {
    const formControl = useFormControlContext() || { id, disabled, invalid };

    return (
        <ReactPhoneNumberInput
            id={formControl.id}
            disabled={formControl.disabled}
            invalid={formControl.invalid}
            limitMaxLength
            displayInitialValueAsLocalNumber
            addInternationalOption={false}
            countrySelectComponent={CountrySelectComponent}
            inputComponent={Input}
            {...rest}
        />
    );
};

export { isValidPhoneNumber, getCountryCallingCode };
