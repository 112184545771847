import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { LegalModal, LegalPageType } from "src/features/compliance/components/LegalModal";
import { Text, isValidPhoneNumber } from "src/sharedComponents";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { EditPhoneInput } from "./EditPhoneInput";
import { actionCreators } from "src/features/accounts/reducers/authState";
import classNames from "classnames";
import { getPhone } from "../selectors";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { canVerifyAction } from "../actions/canVerifyAction";
import { canVerifyOperation } from "../operations";
import { SsoError } from "../util/SsoError";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getSsoProvider } from "../../../common/sso";
import { verifying } from "../actions";
import { getProfile } from "../../accountmenu/selectors";
import { PrimaryButtonFunc } from "../../modalMessage/reducers/modalMessage";
import { SsoButton } from "./SsoButton";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { signInActions } from "../reducers";
import { RecaptchaLegalText } from "./RecaptchaLegalText";

export interface Props {
    title?: string;
    subtitle?: string;
    buttonText?: string;
    hidePlaceholder?: boolean;
    hideLegalLinks?: boolean;
    hideClear?: boolean;
    additionalClassName?: string;
    onComplete: () => void;
    onSubmit?: (phone: string) => void;
}

export const EditPhonePage = ({
    title,
    subtitle,
    buttonText,
    hidePlaceholder,
    hideLegalLinks,
    hideClear,
    additionalClassName,
    onComplete,
    onSubmit: onSubmitCustom,
}: Props) => {
    const phone = useSelector(getPhone);
    const canVerifyError = useSelector(canVerifyOperation.getError);
    const profile = useSelector(getProfile);

    const phoneRef = useRef(phone);
    const onCompleteRef = useRef(onComplete);
    onCompleteRef.current = onComplete;

    const { handleSubmit, errors, formState, control, setValue, watch, trigger } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: { phone },
    });
    const watchPhone = watch("phone");
    const [showLegal, setShowLegal] = useState<LegalPageType | undefined>();

    const dispatch = useDispatch();

    const onPrivacyPolicyClicked = useCallback(
        () => dispatch(actionCreators.onPrivacyPolicyClicked(false)),
        [dispatch]
    );

    const setPhoneAndComplete = useCallback(() => {
        dispatch(signInActions.setPhone(phoneRef.current));
        onCompleteRef.current();
    }, [dispatch]);

    const onSubmit = useCallback(
        async (phone: string) => {
            phoneRef.current = phone;
            await waitForKeyboardClose();

            if (onSubmitCustom) {
                onSubmitCustom(phone);
                return;
            }

            if (!profile?.hasSsoIdentity && !profile?.phoneUnverified && !(await canVerifyAction(phone, dispatch))) {
                return;
            }

            setPhoneAndComplete();
        },
        [dispatch, onSubmitCustom, profile, setPhoneAndComplete]
    );

    useEffect(() => {
        if (canVerifyError instanceof SsoError) {
            const ssoProvder = getSsoProvider(canVerifyError.source);
            const primaryButton: PrimaryButtonFunc | undefined = ssoProvder
                ? (closeAndAction) => (
                      <SsoButton
                          ssoProvider={ssoProvder}
                          onSignIn={(request) => dispatch(verifying(request))}
                          closeAndAction={closeAndAction}
                      />
                  )
                : undefined;

            dispatch(canVerifyOperation.actionCreators.reset());
            dispatch(showModalMessage(modalMessages.ssoAccountExists(primaryButton, setPhoneAndComplete)));
        }
    }, [dispatch, canVerifyError, setPhoneAndComplete]);

    return (
        <form
            onSubmit={(event) => event.preventDefault()}
            className={classNames("verify-page animated-child", additionalClassName)}
        >
            <main>
                <Text preset="title-28" mode={["bold", "block"]} className="verify-page__title">
                    {title ?? "Enter your number"}
                </Text>
                <Text preset="g-14" mode="block" className="verify-page__description">
                    {subtitle ?? "Verify your account with SMS to view your past orders."}
                </Text>
                <EditPhoneInput
                    phone={phone}
                    watchValue={watchPhone}
                    error={errors.phone?.message}
                    control={control}
                    hideClear={hideClear}
                    hidePlaceholder={hidePlaceholder}
                    validateOnChange={() => isValidPhoneNumber(watchPhone) && trigger("phone")}
                    onClear={() => setValue("phone", "", { shouldValidate: true, shouldDirty: !!phone })}
                />
                {hideLegalLinks && <RecaptchaLegalText />}
                {!hideLegalLinks && (
                    <>
                        <Text preset="g-12" className="legal-text__terms">
                            By creating an account I agree to the{" "}
                            <TappableSpan onTap={() => setShowLegal("termsOfUse")}>Terms &amp; Conditions</TappableSpan>
                            &nbsp;&amp; to my data being handled in accordance with the{" "}
                            <TappableSpan
                                onTap={() => {
                                    onPrivacyPolicyClicked();
                                    setShowLegal("privacyPolicy");
                                }}
                            >
                                Privacy Policy
                            </TappableSpan>
                            .
                        </Text>
                        <LegalModal legalPage={showLegal} goBack={() => setShowLegal(undefined)} />
                    </>
                )}
            </main>
            <div className="verify-page__submit">
                <NetworkConnectedButton
                    type="submit"
                    value={buttonText ?? "Send SMS"}
                    className={formState.isValid && watchPhone ? undefined : "disabled"}
                    onClick={handleSubmit(({ phone }) => onSubmit(phone))}
                />
            </div>
        </form>
    );
};
