import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { getParty } from "src/features/order";
import { actionCreators as activeGroupTabActionCreators } from "../reducers/activeGroupTab";
import { getActiveGroupTabData, getShowOverviewModal } from "../selectors/activeGroupTab";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { NavBarType } from "src/common/navigation/types";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { TabOverview } from "./TabOverview";
import { ModalContainer } from "src/common/modal";

export const GroupTabOverviewModal = () => {
    const dispatch = useDispatch();
    const party = useSelector(getParty);
    const viewTab = useSelector(getShowOverviewModal);
    const activeTab = useSelector(getActiveGroupTabData);

    const [show, setShow] = useState(!!viewTab);

    const afterClose = useCallback(() => {
        dispatch(activeGroupTabActionCreators.showGroupTabOverview(false));
    }, [dispatch]);

    const closeModal = () => setShow(false);

    useEffect(() => {
        return () => {
            afterClose();
        };
    }, [afterClose]);

    useEffect(() => {
        if (!!viewTab) {
            if (!party) {
                setShow(!!activeTab);
            } else {
                afterClose();
                dispatch(navBarActionCreators.activeType(NavBarType.GROUP_TABS));
            }
        }
    }, [viewTab, activeTab, party, afterClose, dispatch]);

    useEffect(() => {
        if (!activeTab && !!viewTab) {
            setShow(false);
        }
    }, [viewTab, activeTab]);

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "GroupTabOverview-modal slide-in",
                afterOpen: "GroupTabOverview-modal--after-open slide-in--after-open",
                beforeClose: "GroupTabOverview-modal--before-close slide-in--before-close",
            }}
            overlayClassName="GroupTabOverview-modal--overlay slide-in-modal--overlay"
            onRequestClose={closeModal}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            onAfterClose={afterClose}
        >
            <MenuDataLocaleContext.Provider
                value={new MenuDataLocale(activeTab?.locale || "en-AU", activeTab?.currency || "AUD")}
            >
                <LocationThemeContainer>
                    <div className="group-tab-overview-modal">
                        <SimpleNavHeader
                            closeToBack={true}
                            customBack={"view-group-tab-modal"}
                            onBack={closeModal}
                            withBorder
                        />
                        <TabOverview heroImage={activeTab?.locationImage} />
                    </div>
                </LocationThemeContainer>
            </MenuDataLocaleContext.Provider>
        </ModalContainer>
    );
};
