import "./PulseLoader.scss";
import React from "react";

interface Props {
    dots?: number;
}

export const PulseLoader = ({ dots = 3 }: Props) => (
    <div className="pulse-loader">
        {Array.from(Array(dots).keys()).map((i) => (
            <i key={i} style={{ animationDelay: `0.${i}s` }} />
        ))}
    </div>
);
