import { ApplePayPaymentMethod } from "src/common/payment";
import {
    GetApplePayAuthorizePaymentResultFunc,
    initializeApplePayButton,
    validateApplePayUrl,
} from "../common/initializeApplePayButton";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

const getAuthorizePaymentResult: GetApplePayAuthorizePaymentResultFunc = (token) =>
    Promise.resolve({
        paymentToken: JSON.stringify(token.paymentData),
    });

export const initializeTyroApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = (
    applePayPaymentMethod,
    total,
    { trackEvent }
) =>
    initializeApplePayButton(
        applePayPaymentMethod,
        total,
        trackEvent,
        (validationURL) => validateApplePayUrl(applePayPaymentMethod.paymentGateway, validationURL),
        getAuthorizePaymentResult
    );
