import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../spinnerModal/reducers/spinner";

export const Spinner = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.setIsLoading(true));
        return () => {
            dispatch(actionCreators.setIsLoading(false));
        };
    }, [dispatch]);

    return null;
};
