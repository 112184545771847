import { ApplePayPaymentMethod, paymentOptions } from "src/common/payment";
import { getBraintreeClientInstance } from "src/common/braintree";
import { applePay } from "braintree-web";
import {
    GetApplePayAuthorizePaymentResultFunc,
    GetValidApplePayMerchantSessionFunc,
    initializeApplePayButton,
} from "../common/initializeApplePayButton";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

interface ApplePayInstanceData {
    applePayInstance: any;
    deviceData: string;
}

export const initializeBraintreeApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = async (
    applePayPaymentMethod,
    total,
    { getPaymentSessionToken, trackEvent }
) => {
    let applePayInstanceData: ApplePayInstanceData | undefined;

    const getApplePayInstanceData = async () => {
        if (!applePayInstanceData) {
            const clientToken = await getPaymentSessionToken();
            const { clientInstance, deviceData } = await getBraintreeClientInstance(clientToken);
            const applePayInstance = await applePay.create({
                client: clientInstance,
            });

            applePayInstanceData = {
                applePayInstance,
                deviceData,
            };
        }
        return applePayInstanceData;
    };

    const getValidMerchantSession: GetValidApplePayMerchantSessionFunc = async (validationURL) => {
        const { applePayInstance } = await getApplePayInstanceData();
        return (await applePayInstance.performValidation({
            validationURL,
            displayName: paymentOptions.label,
        })) as any;
    };

    const getAuthorizePaymentResult: GetApplePayAuthorizePaymentResultFunc = async (token) => {
        const { applePayInstance, deviceData } = await getApplePayInstanceData();
        const { nonce } = await applePayInstance.tokenize({ token });
        return {
            paymentToken: nonce,
            additionalFraudProtectionData: deviceData,
        };
    };

    return await initializeApplePayButton(
        applePayPaymentMethod,
        total,
        trackEvent,
        getValidMerchantSession,
        getAuthorizePaymentResult
    );
};
