export type PagePosition = {
    servicePagePosition: number;
    menuPagePosition: number;
    animatingMenu: boolean;
    animatingService: boolean;
};

export interface State {
    pagePosition: PagePosition;
}

export type MenuPagePosition = { [categoryId: string]: number | undefined };

enum TypeKeys {
    SET_SERVICE_PAGE_POSITION = "PAGEPOSITION/SET_SERVICE_PAGE_POSITION",
    RESET_SERVICE_PAGE_POSITION = "PAGEPOSITION/RESET_SERVICE_PAGE_POSITION",
    SET_MENU_PAGE_POSITION = "PAGEPOSITION/SET_MENU_PAGE_POSITION",
    RESET_MENU_PAGE_POSITION = "PAGEPOSITION/RESET_MENU_PAGE_POSITION",
    MENU_ANIMATING = "PAGEPOSITION/MENU_ANIMATING",
    SERVICE_ANIMATING = "PAGEPOSITION/SERVICE_ANIMATING",
}

export const actionCreators = {
    setServicePagePosition: (pagePosition: number) => ({ type: TypeKeys.SET_SERVICE_PAGE_POSITION, pagePosition }),
    resetServicePagePosition: () => ({ type: TypeKeys.RESET_SERVICE_PAGE_POSITION }),
    setMenuPagePosition: (pagePosition: number) => ({ type: TypeKeys.SET_MENU_PAGE_POSITION, pagePosition }),
    resetMenuPagePosition: () => ({ type: TypeKeys.RESET_MENU_PAGE_POSITION }),
    animatingMenuPage: (animating: boolean) => ({ type: TypeKeys.MENU_ANIMATING, animating }),
    animatingServicePage: (animating: boolean) => ({ type: TypeKeys.SERVICE_ANIMATING, animating }),
};

export type SetServicePagePosition = {
    type: TypeKeys.SET_SERVICE_PAGE_POSITION;
    pagePosition: number;
};

export type ResetServicePagePosition = {
    type: TypeKeys.RESET_SERVICE_PAGE_POSITION;
};

export type SetMenuPagePosition = {
    type: TypeKeys.SET_MENU_PAGE_POSITION;
    pagePosition: number;
};

export type ResetMenuPagePosition = {
    type: TypeKeys.RESET_MENU_PAGE_POSITION;
};

export type AnimatingMenuPage = {
    type: TypeKeys.MENU_ANIMATING;
    animating: boolean;
};

export type AnimatingServicePage = {
    type: TypeKeys.SERVICE_ANIMATING;
    animating: boolean;
};

export type PagePositionActions =
    | SetServicePagePosition
    | ResetServicePagePosition
    | SetMenuPagePosition
    | ResetMenuPagePosition
    | AnimatingMenuPage
    | AnimatingServicePage;

const initialState: PagePosition = {
    servicePagePosition: 0,
    menuPagePosition: 0,
    animatingMenu: true,
    animatingService: true,
};

export default function reducers(state: PagePosition = initialState, action: PagePositionActions): PagePosition {
    switch (action.type) {
        case TypeKeys.SET_SERVICE_PAGE_POSITION:
            return { ...state, servicePagePosition: action.pagePosition, animatingService: true };
        case TypeKeys.RESET_SERVICE_PAGE_POSITION:
            return { ...state, servicePagePosition: 0 };
        case TypeKeys.SET_MENU_PAGE_POSITION:
            return { ...state, menuPagePosition: action.pagePosition };
        case TypeKeys.RESET_MENU_PAGE_POSITION:
            return { ...state, menuPagePosition: 0 };
        case TypeKeys.MENU_ANIMATING:
            return { ...state, animatingMenu: action.animating };
        case TypeKeys.SERVICE_ANIMATING:
            return { ...state, animatingService: action.animating };
        default:
            return state;
    }
}
