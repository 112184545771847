import React from "react";
import { PaymentMethod, SelectablePaymentMethodProps } from "./PaymentMethod";
import { useHistory } from "react-router";
import { SmallArrowRightIcon } from "../../../sharedComponents/assets/icons";

export const ViewPaymentMethodAction = ({ paymentMethod }: SelectablePaymentMethodProps) => {
    const history = useHistory();

    const viewPaymentMethod = () => {
        history.push(`/onboarding/remove-payment-method/${paymentMethod.token}`);
    };

    return (
        <PaymentMethod paymentMethod={paymentMethod} onClick={viewPaymentMethod} rightIcon={<SmallArrowRightIcon />} />
    );
};
