import { connect } from "react-redux";
import { AppState } from "../../index";

export interface OwnProps {
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

interface Props {
    isDuplicated: boolean;
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

export const RequireActiveInstance = ({ isDuplicated, success, fallback }: Props) =>
    !isDuplicated ? success() : fallback ? fallback() : null;

function mapStateToProps(state: AppState, props: OwnProps): Props {
    const isDuplicated = state.appInstance.isDuplicated;
    return {
        isDuplicated,
        ...props,
    };
}

export const RequireActiveInstanceContainer = connect(mapStateToProps)(RequireActiveInstance);
