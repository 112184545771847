import "../assets/VenueDetails.scss";
import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Text } from "src/sharedComponents";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { Map, PhoneIcon } from "src/sharedComponents/assets/icons";
import { href, device } from "src/common/experience";
import { CollectionDetails, actionCreators } from "../reducers/takeawayOptions";
import { getTopPage } from "src/common/navigation";

interface Props {
    collectionDetails: CollectionDetails;
    locationName: string;
    lightBackground?: boolean;
    collectionTime?: string | null;
    showMapAndPhone?: boolean;
}

export const VenueDetails = ({
    collectionDetails,
    locationName,
    lightBackground = false,
    collectionTime,
    showMapAndPhone = true,
}: Props) => {
    const { contactPhoneNumber, venueAddress } = collectionDetails;
    const { googleBusinessName, addressLine1, city, postcode } = venueAddress;
    const formattedAddress = `${addressLine1}, ${city}`;

    const mapAppType = device.isIOS ? "apple" : "google";
    const addressQuery = `${addressLine1} ${city}, ${postcode}`;
    const locationQuery = googleBusinessName ? `${googleBusinessName} ${addressQuery}` : addressQuery;
    const encodedQuery = encodeURIComponent(locationQuery);
    const mapsAddress = `https://maps.${mapAppType}.com/?q=${encodedQuery}`;

    const formattedNumber = contactPhoneNumber?.replace(/\s+/g, "");

    const dispatch = useDispatch();

    return (
        <div className={classNames("venue-details", { lightBackground: lightBackground })}>
            <div className="venue-details__name-address">
                <div className="venue-details__name-time">
                    <Text preset="g-16" mode={["bold", "block"]} className="venue-details__name">
                        {locationName}
                    </Text>
                    {collectionTime && (
                        <Text preset="g-16" mode={["bold", "block"]} className="venue-details__time">
                            {collectionTime}
                        </Text>
                    )}
                </div>

                <Text preset="g-14" mode="block" className="venue-details__address">
                    {formattedAddress}
                </Text>
            </div>
            {showMapAndPhone && (
                <div className="venue-details__map-contact-container">
                    {mapsAddress && (
                        <TappableAnchor
                            className={classNames("venue-details__tappable", { "dark-icons": lightBackground })}
                            onClick={() => dispatch(actionCreators.trackViewMapClicked(getTopPage()))}
                            {...href.openInNewWindowAttributes(mapsAddress)}
                        >
                            <Map />
                            <Text preset="g-14" mode="bold">
                                View map
                            </Text>
                        </TappableAnchor>
                    )}
                    {formattedNumber && (
                        <TappableAnchor
                            className={classNames("venue-details__tappable", { "dark-icons": lightBackground })}
                            onClick={() => dispatch(actionCreators.trackContactClicked(getTopPage()))}
                            {...href.openInNewWindowAttributes(`tel:${formattedNumber}`)}
                        >
                            <PhoneIcon />
                            <Text preset="g-14" mode="bold">
                                Contact
                            </Text>
                        </TappableAnchor>
                    )}
                </div>
            )}
        </div>
    );
};
