import { QrScanStatus } from "../../../common/experience/interface";

export interface QrState {
    cameraAccessDenied?: boolean;
    cameraReady?: boolean;
    scanError?: boolean;
}

enum TypeKeys {
    CAMERA_ACCESS_DENIED = "QR/CAMERA_ACCESS_DENIED",
    SCAN_STATUS = "QR/SCAN_STATUS",
    RESET_SCAN_STATUS = "QR/RESET_SCAN_STATUS",
}

export const actionCreators = {
    setCameraAccessDenied: () => ({ type: TypeKeys.CAMERA_ACCESS_DENIED }),
    setScanStatus: (status: QrScanStatus) => ({ type: TypeKeys.SCAN_STATUS, status }),
    resetScanStatus: () => ({ type: TypeKeys.RESET_SCAN_STATUS }),
};

type SetCameraAccessDeniedAction = { type: TypeKeys.CAMERA_ACCESS_DENIED };
type SetScanStatusAction = { type: TypeKeys.SCAN_STATUS; status: QrScanStatus };
type ResetScanStatusAction = { type: TypeKeys.RESET_SCAN_STATUS };

type QrAction = SetCameraAccessDeniedAction | SetScanStatusAction | ResetScanStatusAction;

const initialState: QrState = {};

export default function reducer(state: QrState = initialState, action: QrAction): QrState {
    if (action.type === TypeKeys.CAMERA_ACCESS_DENIED) {
        return {
            ...state,
            cameraAccessDenied: true,
        };
    }

    if (action.type === TypeKeys.SCAN_STATUS) {
        return {
            ...state,
            cameraReady: state.cameraReady || action.status === "cameraReady",
            scanError: action.status === "error",
        };
    }

    if (action.type === TypeKeys.RESET_SCAN_STATUS) {
        return {
            ...state,
            cameraReady: undefined,
            scanError: undefined,
        };
    }

    return state;
}
