import * as React from "react";
import "./assets/ProgressBar.scss";

export interface Props {
    containerStyles: ContainerStyles;
    fillerStyles?: FillerStyles;
    percentageDone: number;
}

export interface ContainerStyles {
    border: string;
    width: string;
    borderRadius: string;
    height: string;
}

export interface FillerStyles {
    background: string;
}

// ProgressBar
export class ProgressBar extends React.Component<Props> {
    render() {
        const { containerStyles, fillerStyles, percentageDone } = this.props;
        return (
            <div className="progress-bar" style={{ ...defaultContainerStyles, ...containerStyles }}>
                <Filler fillerStyles={fillerStyles} percentageDone={percentageDone} />
            </div>
        );
    }
}

export interface FillerProps {
    fillerStyles?: FillerStyles;
    percentageDone: number;
}

// Filler
const Filler = ({ fillerStyles, percentageDone }: FillerProps) => {
    // Do not allow the progress bar to go over 100%
    if (percentageDone > 100) {
        console.error("React Progression UI: You attempted to fill the progress bar more than 100%.");
        return null;
    }

    return (
        <div
            className="progress-bar__filler"
            style={{
                ...defaultFillerStyles,
                ...fillerStyles,
                width: percentageDone ? `${percentageDone}%` : defaultFillerStyles.width,
            }}
        />
    );
};

// Styles
const defaultContainerStyles = {
    height: "10px",
    width: "300px",
};

const defaultFillerStyles = {
    height: "100%",
    width: "20%",
    borderRadius: "inherit",
};

export default ProgressBar;
