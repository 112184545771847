import { loadExternalScript } from "../shared";
import { config } from "../config";

export const googlePayEnvironment: google.payments.api.Environment =
    (config.REACT_APP_GOOGLE_PAY_ENVIRONMENT as google.payments.api.Environment | undefined) ?? "TEST";

let paymentsClient: google.payments.api.PaymentsClient | undefined;

export const getGooglePaymentsClient = async () => {
    if (!paymentsClient) {
        await loadExternalScript("google.payments.api", "https://pay.google.com/gp/p/js/pay.js");
        paymentsClient = new google.payments.api.PaymentsClient({
            environment: googlePayEnvironment,
        });
    }
    return paymentsClient;
};
