import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { hexToRGBA } from "../../../common/shared/colors";

export interface LocationThemeProps {
    themeColor: string;
    themeTextColor: string;
    themedButtonTextColor: string;
    className?: string;
}

export const LocationTheme = (props: PropsWithChildren<LocationThemeProps>) => (
    <span
        className={classNames("location-container", props.className)}
        style={
            {
                "--theme-primary-color": props.themeColor,
                "--theme-primary-color-light": hexToRGBA(props.themeColor, 0.1),
                "--theme-primary-text-color": props.themeTextColor,
                "--theme-button-text-color": props.themedButtonTextColor,
            } as any
        }
    >
        {props.children}
    </span>
);
