import React, { useState, useContext, useRef, useEffect, CSSProperties, useMemo } from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { InfoIcon } from "../../../sharedComponents/assets/icons";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { MenuDataLocale } from "../../menudata/types/MenuDataLocale";
import { TaxClassTotal } from "../types";
import { getTotalExclusiveTaxes } from "../helpers";

import "./assets/TaxesDetail.scss";

interface Props {
    taxSummary?: TaxClassTotal[];
    isAlwaysExpanded?: boolean;
}

export const TaxesDetail = ({ taxSummary, isAlwaysExpanded }: Props) => {
    const [isExpanded, setIsExpanded] = useState(!!isAlwaysExpanded);
    const [taxesHeight, setTaxesHeight] = useState<number>(0);
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const totalExclusiveTaxes = useMemo(() => getTotalExclusiveTaxes(taxSummary), [taxSummary]);
    const exclusiveTaxes = useMemo(() => taxSummary?.filter(({ exclusive }) => exclusive), [taxSummary]);

    if (!totalExclusiveTaxes || !exclusiveTaxes?.length) return null;

    return (
        <div className="taxes-detail">
            <div className="taxes-detail__total">
                <TappableDiv
                    onTap={isAlwaysExpanded ? undefined : () => setIsExpanded(!isExpanded)}
                    className="taxes-detail__total__title"
                >
                    <Text preset="g-14">Taxes</Text>
                    {!isAlwaysExpanded && <InfoIcon />}
                </TappableDiv>
                <Text preset="g-14" value={menuDataLocale.formatCurrency(totalExclusiveTaxes)} />
            </div>
            <div
                className={classNames("taxes-detail__data", isExpanded && "show-taxes")}
                style={taxesHeight ? ({ "--taxes-height": taxesHeight + "px" } as CSSProperties) : undefined}
            >
                <TaxItems
                    items={exclusiveTaxes}
                    setHeight={(height: number) => setTaxesHeight(height)}
                    menuDataLocale={menuDataLocale}
                />
            </div>
        </div>
    );
};

interface TaxItemsProps {
    items: TaxClassTotal[];
    setHeight?: (height: number) => void;
    menuDataLocale: MenuDataLocale;
}

const TaxItems = ({ items, setHeight, menuDataLocale }: TaxItemsProps) => {
    const taxItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (taxItemRef.current && taxItemRef.current.offsetHeight && setHeight) {
            setHeight(taxItemRef.current.offsetHeight);
        }
    }, [setHeight, taxItemRef]);

    return (
        <div ref={taxItemRef} className="taxes-detail__items">
            {items.map((item) => (
                <TaxItemRow key={item.taxClass} item={item} menuDataLocale={menuDataLocale} />
            ))}
        </div>
    );
};

interface TaxItemRowProps {
    item: TaxClassTotal;
    menuDataLocale: MenuDataLocale;
}

const TaxItemRow = ({ item, menuDataLocale }: TaxItemRowProps) => {
    const displayValue = menuDataLocale.formatCurrency(item.total);

    return (
        <Text preset="g-14" className="taxes-detail__item-row">
            <span>{item.taxClass}</span>
            <span>{displayValue}</span>
        </Text>
    );
};
