import { AppDispatch, AppMiddleware } from "../..";
import { PartyAction, TypeKeys as PartyTypeKeys } from "src/features/order/reducers/party";
import { LogoutAction, TypeKeys as LogoutTypeKeys } from "src/features/signup/reducers/signIn";
import { CookiePreferencesChangedAction, TypeKeys as CookiesTypeKeys } from "../reducers/cookiePreferences";
import { gdpr } from "src/common/experience";
import { deleteLastTableToken, saveLastTableData } from "../persistence/lastTableData";
import { getParty } from "src/features/order";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { DeeplinkAction, TypeKeys as DeeplinkTypeKeys } from "src/features/deeplink";
import { UrlType } from "src/common/experience/interface";

let url: string | undefined;

export const complianceMiddleware = () => (store: AppMiddleware) => {
    return (next: AppDispatch) =>
        (action: PartyAction | LogoutAction | CookiePreferencesChangedAction | DeeplinkAction) => {
            next(action);

            if (action.type === DeeplinkTypeKeys.LOADING && action.data.type === UrlType.QR) {
                url = action.data.claimUrl;
            }

            if (
                action.type === LogoutTypeKeys.LOGOUT ||
                (action.type === CookiesTypeKeys.CHANGED && !action.allowAnalytics)
            ) {
                deleteLastTableToken();
            }

            if (action.type === PartyTypeKeys.CONNECTED && gdpr.getCookiePreferences().allowAnalytics) {
                const state = store.getState();
                const menuData = getVisibleMenuData(state);
                const party = getParty(state);
                if (party) {
                    saveLastTableData(party, menuData, url);
                }
            }
        };
};
