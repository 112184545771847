import { createSelector } from "reselect";
import { getVisibleMenuData } from "../../menu/selectors";
import { getParty } from "../../order";
import { getIsGroupTabOpen } from "../../groupTabs/selectors/activeGroupTab";
import { getIsOpeningTab } from "../../payment/selectors";
import { VenueCauseOptionWithIndex, VenueCause } from "../types";

const getSupportedVenueCauses = createSelector(getParty, (party) => party?.supportedVenueCauses);

// Right now we are only supporting the first venue cause index.
const getCurrentlySupportedVenueCause = createSelector(getSupportedVenueCauses, (supportedVenueCauses) =>
    supportedVenueCauses?.length ? supportedVenueCauses[0] : null
);

export const getVenueCause = createSelector(
    getIsGroupTabOpen,
    getIsOpeningTab,
    getCurrentlySupportedVenueCause,
    getVisibleMenuData,
    (isGroupTabOpen, isOpeningTab, supportedVenueCause, menuData) => {
        if (supportedVenueCause === null || isGroupTabOpen || isOpeningTab) return null;

        const venueCause = menuData?.venueCauses?.[supportedVenueCause] || null;

        if (!venueCause) return null;

        const sortedOptions: VenueCauseOptionWithIndex[] = [...venueCause.options]
            .map((option, index) => ({ ...option, optionIndex: index }))
            .sort((a, b) => a.amount - b.amount);

        const formattedVenueCause: VenueCause = {
            ...venueCause,
            options: sortedOptions,
        };

        return formattedVenueCause;
    }
);
