import { FeatureConfiguration } from "..";

import { reducer } from "./reducers";
import applyFoodPreferencesMiddleware from "./middleware/applyFoodPreferencesMiddleware";

export * from "./reducers";

export * from "./types";

export * from "./selectors";

export default function (config: FeatureConfiguration) {
    config.reducers.filtering = reducer;

    config.middleware.unshift(applyFoodPreferencesMiddleware);
}
