import * as React from "react";
import { statusBar } from "../experience";

export interface Props {
    backgroundColor: string;
}

interface colorType {
    id: number;
    color: string;
}

let lastColors: colorType[] = [];

export class StatusBar extends React.Component<Props> {
    id: number;
    constructor(props: Props) {
        super(props);
        this.id = Date.now();
        lastColors.push({ id: this.id, color: props.backgroundColor });
    }

    componentDidMount() {
        this.updateStatusBar(this.props.backgroundColor);
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.backgroundColor !== this.props.backgroundColor) {
            this.updateStatusBar(this.props.backgroundColor);
        }
    }
    componentWillUnmount() {
        lastColors.splice(
            lastColors.findIndex((color) => color.id === this.id),
            1
        );
        lastColors.length && this.updateStatusBar(lastColors[lastColors.length - 1].color);
    }

    updateStatusBar(hex: string | undefined) {
        if (hex) {
            statusBar.setColor(hex);
        }
    }
    render() {
        return null;
    }
}
