import { AppDispatch, AppState } from "src/features";
import { Props, MarketingModal } from "../components/MarketingModal";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { actions } from "../actions";
import { connect } from "react-redux";
import { actionCreators } from "src/features/accounts/reducers/authState";
import { SignupSource } from "../types";

export function mapStateToProps(state: AppState): DataProps<Props> {
    const {
        marketing: {
            optInData: {
                locationId,
                partyId,
                locationName,
                locationImage,
                locationLogo,
                firstName,
                lastName,
                email,
                historyOpen,
            },
            title,
            newsletterProvider,
            privacyPolicyUrl,
        },
    } = state;

    return {
        isOpen: !!locationId,
        locationId,
        historyOpen,
        partyId,
        locationName,
        locationImage,
        locationLogo,
        firstName,
        lastName,
        email,
        title,
        newsletterProvider,
        privacyPolicyUrl,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        resetMarketing: (partyId: string | null) => {
            dispatch(actions.resetMarketing());
        },
        sendOptIn: (locationId: string, firstName: string, lastName: string, email: string, partyId: string | null) => {
            dispatch(
                actions.sendOptIn(
                    locationId,
                    firstName,
                    lastName,
                    email,
                    null,
                    partyId,
                    SignupSource[SignupSource.NEWSLETTER]
                )
            );
        },
        onPrivacyPolicyClicked: (venuePrivacy: boolean, url?: string) =>
            dispatch(actionCreators.onPrivacyPolicyClicked(venuePrivacy, url)),
    };
}

export const MarketingContainer = connect(mapStateToProps, mapDispatchToProps)(MarketingModal);
