import { AppDispatch, AppState } from "src/features";
import { fetchProfileAwaitable } from "src/features/accountmenu";
import { getSurvey } from "src/features/survey/selectors/survey";
import { getProfile, getProfileEmail } from "src/features/accountmenu/selectors";
import { memberProfileEmailOperation } from "../operations";
import { getPayOnlyGetReceiptState } from "../selectors";
import { actionCreators as surveyAction } from "src/features/survey/reducers/survey";
import { actionCreators as payOnlyGetReceiptAction } from "../reducers/payOnlyGetReceipt";

export const memberProfileEmail = () => {
    return memberProfileEmailOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        const { email: surveyEmail } = getSurvey(state);
        const { email: receiptEmail } = getPayOnlyGetReceiptState(state);

        if (surveyEmail && receiptEmail) return;

        if (!getProfile(state)) {
            await fetchProfileAwaitable(dispatch, getState);
        }

        const profileEmail = getProfileEmail(getState());

        if (!surveyEmail && profileEmail) dispatch(surveyAction.setEmail(profileEmail));
        if (!receiptEmail) dispatch(payOnlyGetReceiptAction.setEmail(profileEmail));
    });
};
