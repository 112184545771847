import { updatePaymentAmount as updatePaymentAmountApi } from "src/features/payment/api/updatePaymentAmount";
import { AppDispatch, AppState } from "../../index";
import {
    getPaymentSurchargeAmount,
    getPaymentTipAmount,
    getPaymentTotal,
    getSelectedPaymentMethod,
} from "../selectors";
import { getInParty } from "src/features/order/selectors";
import { UpdatePaymentAmountInfo } from "src/features/payment/types";
import { getPaymentBehavior } from "src/features/paymentGateways";

export const updatePaymentAmount = (dispatch: AppDispatch, getState: () => AppState) => async () => {
    const state = getState();
    const selectedPaymentMethod = getSelectedPaymentMethod(state)!;
    const inParty = getInParty(state);
    const amount = getPaymentTotal(state);
    const tip = getPaymentTipAmount(state);
    const surcharge = getPaymentSurchargeAmount(state);

    const updatePaymentAmountInfo: UpdatePaymentAmountInfo = {
        amount,
        tip,
        surcharge,
    };

    const paymentBehavior = getPaymentBehavior(selectedPaymentMethod);

    if (paymentBehavior?.updatePaymentAmountInfoVisitor) {
        await paymentBehavior.updatePaymentAmountInfoVisitor(dispatch, getState, updatePaymentAmountInfo);
    }

    return !!(await updatePaymentAmountApi(
        dispatch,
        selectedPaymentMethod.paymentGateway,
        updatePaymentAmountInfo,
        inParty
    ));
};
