import "./HistoryPaymentDetails.scss";

import moment from "moment";
import React, { useCallback, useContext, useMemo } from "react";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { Text } from "src/sharedComponents";
import { MembershipIcon, PromotionsTagIcon } from "src/sharedComponents/assets/icons";
import { TaxesDetail } from "../../order/component/TaxesDetail";
import { BillAdjustment, BillAdjustmentType, TaxClassTotal } from "../../order";
import classNames from "classnames";
import { getPaymentMethodText } from "../../payment/util/getPaymentMethodText";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";

interface HistoryPaymentDetailsProps {
    tableTotal?: number;
    orderAmount?: number;
    amountAdded?: number;
    memberDiscount?: number;
    discount?: number;
    surcharge?: number;
    unallocatedAmount?: number;
    tip?: number;
    processingFee?: number;
    totalRefunded?: number;
    paymentIsOutstanding?: boolean;
    paymentMethod?: string | null;
    cardType?: string | null;
    paymentMethodDescription?: string | null;
    total: number;
    time?: string;
    taxSummary?: TaxClassTotal[];
    adjustments?: BillAdjustment[];
    surchargePercent?: number;
    isTipOnlyPayment?: boolean;
    offerDiscountAmount?: number;
    pointsSpendAmount?: number;
}

export const HistoryPaymentDetails = ({
    tableTotal,
    orderAmount,
    amountAdded,
    memberDiscount,
    discount,
    surcharge,
    unallocatedAmount,
    tip,
    processingFee,
    totalRefunded,
    paymentIsOutstanding,
    paymentMethod,
    total,
    time,
    cardType,
    paymentMethodDescription,
    taxSummary,
    adjustments,
    surchargePercent = 0,
    isTipOnlyPayment,
    offerDiscountAmount,
    pointsSpendAmount,
}: HistoryPaymentDetailsProps) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const timeDisplay = useMemo(() => (time ? moment(time).format("h:mm a") : ""), [time]);
    const paymentElemt = paymentMethod
        ? getPaymentMethodText(paymentMethod, cardType || null, paymentMethodDescription)
        : null;

    const getAdjustment = useCallback(
        (type: BillAdjustmentType) => adjustments?.find((adj) => adj.type === type),
        [adjustments]
    );
    const promoCode = useMemo(() => getAdjustment(BillAdjustmentType.PROMOCODE), [getAdjustment]);
    const trayCharge = useMemo(() => getAdjustment(BillAdjustmentType.TRAY_CHARGE), [getAdjustment]);
    const venueCause = useMemo(() => getAdjustment(BillAdjustmentType.VENUE_CAUSE), [getAdjustment]);

    return (
        <Text preset="g-14" mode="block" className="history-item-details">
            {!!paymentElemt && <div className="history-item-details__payment-method">{paymentElemt}</div>}
            {!!tableTotal && tableTotal !== orderAmount && (
                <div className="history-item-details__value">
                    <span>Item total</span>
                    <span>{menuDataLocale.formatCurrency(tableTotal)}</span>
                </div>
            )}
            {!!time && (
                <div className="history-item-details__value">
                    <span>Time</span>
                    <span>{timeDisplay}</span>
                </div>
            )}
            {!!amountAdded && (
                <div className="history-item-details__value">
                    <span>{isTipOnlyPayment ? "Tip" : "Amount added"}</span>
                    <span>{menuDataLocale.formatCurrency(amountAdded)}</span>
                </div>
            )}
            {!!offerDiscountAmount && (
                <div className="history-item-details__value history-item-details__value--offer-discount">
                    <span>
                        Offer <PromotionsTagIcon />
                    </span>
                    <span>{menuDataLocale.formatCurrency(-1 * Math.abs(offerDiscountAmount))}</span>
                </div>
            )}
            {!!memberDiscount && (
                <div className="history-item-details__value history-item-details__value--member-discount">
                    <span>
                        Member offers <MembershipIcon />
                    </span>
                    <span>{`${menuDataLocale.formatCurrency(-1 * Math.abs(memberDiscount))}`}</span>
                </div>
            )}
            {!!discount && (
                <div className="history-item-details__value">
                    <span>Venue discount</span>
                    <span>-{menuDataLocale.formatCurrency(discount)}</span>
                </div>
            )}
            {!!orderAmount && (
                <div className="history-item-details__value">
                    <span>Subtotal</span>
                    <span>{menuDataLocale.formatCurrency(orderAmount)}</span>
                </div>
            )}
            {!!promoCode && (
                <div className="history-item-details__value history-item-details__value--promotion">
                    <span className="history-item-details__value--promotion--name">
                        {promoCode.name} <PromotionsTagIcon />
                    </span>
                    <span>{menuDataLocale.formatCurrency(promoCode.value)}</span>
                </div>
            )}
            {!!surcharge && (
                <div className="history-item-details__value">
                    <span>
                        {menuDataLocale.surchargeText}
                        {surchargePercent > 0 ? ` (${menuDataLocale.formatPercent(surchargePercent)})` : ""}
                    </span>
                    <span>{menuDataLocale.formatCurrency(surcharge)}</span>
                </div>
            )}
            {!!venueCause && (
                <div className="history-item-details__value">
                    <span>{venueCause.name}</span>
                    <span>{menuDataLocale.formatCurrency(venueCause.value)}</span>
                </div>
            )}
            {!!trayCharge && (
                <div className="history-item-details__value">
                    <span>{trayCharge.name}</span>
                    <span>{menuDataLocale.formatCurrency(trayCharge.value)}</span>
                </div>
            )}
            {!!unallocatedAmount && (
                <div className="history-item-details__value">
                    <span>Unallocated items</span>
                    <span>{menuDataLocale.formatCurrency(unallocatedAmount)}</span>
                </div>
            )}
            <TaxesDetail taxSummary={taxSummary} />
            {!!pointsSpendAmount && (
                <div className="history-item-details__value history-item-details__value--member-discount">
                    <span>
                        Points <MembershipIcon />
                    </span>
                    <span>{`${menuDataLocale.formatCurrency(-1 * Math.abs(pointsSpendAmount))}`}</span>
                </div>
            )}
            {!!tip && (
                <div className="history-item-details__value">
                    <span>Tip</span>
                    <span>{menuDataLocale.formatCurrency(tip)}</span>
                </div>
            )}
            {!!processingFee && (
                <div className="history-item-details__value">
                    <span>{getProcessingFeeText(menuDataLocale, paymentMethod)}</span>
                    <span>{menuDataLocale.formatCurrency(processingFee)}</span>
                </div>
            )}
            {!!totalRefunded && (
                <div className="history-item-details__value history-item-details__value--refunded">
                    <span>Refunded</span>
                    <span>{menuDataLocale.formatCurrency(totalRefunded * -1)}</span>
                </div>
            )}
            <div className={classNames("history-item-details__value", paymentIsOutstanding && "outstanding")}>
                <Text preset="g-16" mode={["bold", "block"]}>
                    {paymentIsOutstanding ? "Outstanding balance" : "Total"}
                </Text>
                <Text preset="g-16" mode={["bold", "block"]}>
                    {menuDataLocale.formatCurrency(total)}
                </Text>
            </div>
        </Text>
    );
};
