import React from "react";
import { DrinkGlassIcon, CutleryIcon } from "../../../sharedComponents/assets/icons";
import { MenuTabNames } from "../../menudata/reducers/types";
import { CallToActionHeader } from "../../../sharedComponents/controls/header/CallToActionHeader";

interface OwnProps {
    onClick: () => void;
    menuName: string;
}

export const MenuPageEmptyTab = ({ menuName, onClick }: OwnProps) => {
    const title =
        menuName === MenuTabNames.DRINKS
            ? "There are no drinks available right now"
            : "There is no food available right now";
    const linkText = menuName === MenuTabNames.DRINKS ? "View food menu" : "View drinks menu";
    const icon = menuName === MenuTabNames.DRINKS ? <DrinkGlassIcon /> : <CutleryIcon />;

    return <CallToActionHeader title={title} ctaText={linkText} icon={icon} onClick={onClick} />;
};
