import { SplashScreen } from "../shared";
import { removeSplashScreen } from "../shared/splashScreen";
import { cordovaWindow } from "./cordova";

export const splashScreen: SplashScreen = {
    hide: () => {
        removeSplashScreen();
        // Give Initializing page time to transition
        setTimeout(cordovaWindow.navigator.splashscreen.hide, 300);
    },
};
