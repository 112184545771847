import { AppState } from "../../../index";
import { useDispatch, useSelector } from "react-redux";
import {
    getAgeRestrictionConfirmation,
    saveAgeRestrictionConfirmation,
} from "../../persistence/ageRestrictionConfirmation";
import { getLocationName } from "../../../menu/selectors";
import { leaveTable } from "../../actions/leaveTable";
import { replace } from "connected-react-router";
import { showModalMessage } from "../../../modalMessage/actions/show";
import { modalMessages } from "../../../modalMessage/messages";
import { useEffect } from "react";
import { getAgeRestriction } from "../../selectors";

const getConfirmEighteenPlus = (state: AppState) => state.restaurantFlags?.confirmEighteenPlus;

export const ConfirmAgeRestrictionModal = () => {
    const confirmEighteenPlus = useSelector(getConfirmEighteenPlus);
    const locationName = useSelector(getLocationName);
    const ageRestriction = useSelector(getAgeRestriction);
    const dispatch = useDispatch();

    useEffect(() => {
        if (confirmEighteenPlus && !getAgeRestrictionConfirmation()) {
            dispatch(
                showModalMessage(
                    modalMessages.confirmAgeRestriction(
                        locationName,
                        ageRestriction,
                        saveAgeRestrictionConfirmation,
                        modalMessages.underage(
                            locationName,
                            () => {
                                dispatch(leaveTable());
                                dispatch(replace("/join-table"));
                            },
                            ageRestriction
                        )
                    )
                )
            );
        }
    }, [confirmEighteenPlus, locationName, ageRestriction, dispatch]);

    return null;
};
