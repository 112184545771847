import "./MessageScreen.scss";
import React, { ReactNode } from "react";
import { Text } from "../../controls";

export interface MessageScreenProps {
    icon?: ReactNode;
    title: string;
    description?: string[];
}

export const MessageScreen = ({ icon, title, description }: MessageScreenProps) => (
    <div className="message-screen">
        <section className="message-screen__info-container">
            {icon}
            <div className="message-screen__text">
                <Text preset="title-24" mode="extra-bold" className="message-screen__title">
                    {title}
                </Text>
                {description?.map((text, index) => (
                    <Text
                        preset="g-14"
                        className="message-screen__description"
                        key={`message-screen-description-${index}`}
                    >
                        {text}
                    </Text>
                ))}
            </div>
        </section>
    </div>
);
