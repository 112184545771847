import { cordovaWindow } from "./cordova";

export const prompt = (message: string, title?: string, defaultText?: string) => {
    return new Promise<string | null>((resolve) => {
        function resultCallback(result: PromptResult) {
            switch (result.buttonIndex) {
                case 1:
                    // cancel
                    resolve(null);
                    break;
                case 2:
                    // confirm
                    resolve(result.input1);
                    break;
            }
        }
        cordovaWindow.navigator.notification.prompt(message, resultCallback, title, ["Cancel", "OK"], defaultText);
    });
};

interface PromptResult {
    buttonIndex: number;
    input1: string;
}
