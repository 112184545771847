import { AppDispatch, AppState } from "src/features";
import { getGroupTabHistoryData, getGroupTabHistoryList } from "../API/groupTabHistoryApi";
import { getGroupTabHistoryDataOperation, getGroupTabHistoryListOperation } from "../operations";
import { actionCreators } from "../reducers/groupTabHistory";
import { auth } from "src/features/signup";

export function fetchGroupTabHistoryList(lastServiceDate: string | null, limit?: number) {
    return getGroupTabHistoryListOperation.getThunk(async (dispatch: AppDispatch) => {
        if (auth.getIsAnonymous()) {
            return [];
        }

        dispatch(actionCreators.loadingGroupTabHistoryList("processing"));
        let groupTabHistory;

        try {
            groupTabHistory = await getGroupTabHistoryList(lastServiceDate, limit);
            dispatch(actionCreators.getGroupTabHistoryListComplete(groupTabHistory));
        } catch (ex) {
            dispatch(actionCreators.loadingGroupTabHistoryList("failure"));
            console.error(`Error while fetching Group History List`, ex);
        }
        return groupTabHistory?.data || [];
    });
}

const fetchGroupTabHistoryDataInternal = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    groupTabId: string,
    force: boolean
) => {
    const {
        groupTabHistoryData: { groupTabHistories },
    } = getState();
    const history = groupTabHistories.find((h) => h.groupTabId === groupTabId);
    if (!force && history && history.details) return history;

    const groupTabHistory = await getGroupTabHistoryData(groupTabId);
    dispatch(actionCreators.getGroupTabHistoryData(groupTabHistory));

    return groupTabHistory;
};

export const fetchGroupTabHistoryData = (groupTabId: string, force: boolean = false) =>
    getGroupTabHistoryDataOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        fetchGroupTabHistoryDataInternal(dispatch, getState, groupTabId, force)
    );

export const fetchGroupTabHistoryDataAwaitable = (
    dispatch: AppDispatch,
    getState: () => AppState,
    groupTabId: string,
    force: boolean = false
) =>
    getGroupTabHistoryDataOperation.invoke(
        () => fetchGroupTabHistoryDataInternal(dispatch, getState, groupTabId, force),
        dispatch
    );
