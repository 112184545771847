import { trackedEvent } from "src/common/events/reduxEventTracking";

export interface State {
    accountMenu: AccountMenuState;
}

export type AccountMenuDefaultView = "SelectPaymentMethod" | "OrderHistory" | "GroupTabHistory";

export interface AccountMenuState {
    open: boolean;
    defaultView?: AccountMenuDefaultView;
    enableTestingFeatures: boolean;
}

export enum TypeKeys {
    OPEN = "ACCOUNT_MENU/OPEN",
    CLOSE = "ACCOUNT_MENU/CLOSE",
    SET_ENABLE_TESTING_FEATURES = "ACCOUNT_MENU/SET_ENABLE_TESTING_FEATURES",
}

export const actionCreators = {
    openAccountMenu: (defaultView?: AccountMenuDefaultView) => ({ type: TypeKeys.OPEN, defaultView }),
    closeAccountMenu: () => ({ type: TypeKeys.CLOSE }),
    setEnableTestingFeatures: (enableTestingFeatures: boolean) =>
        trackedEvent({
            type: TypeKeys.SET_ENABLE_TESTING_FEATURES,
            enableTestingFeatures,
        }),
};

export type OpenAccountMenuAction = { type: TypeKeys.OPEN; defaultView?: AccountMenuDefaultView };
export type CloseAccountMenuAction = { type: TypeKeys.CLOSE };
export type SetEnableTestingFeatures = {
    type: TypeKeys.SET_ENABLE_TESTING_FEATURES;
    enableTestingFeatures: boolean;
};

export type AccountMenuAction = OpenAccountMenuAction | CloseAccountMenuAction | SetEnableTestingFeatures;

const initialState: AccountMenuState = {
    open: false,
    enableTestingFeatures: false,
};

export function reducer(state: AccountMenuState = initialState, action: AccountMenuAction) {
    if (action.type === TypeKeys.OPEN) {
        return {
            ...state,
            open: true,
            defaultView: action.defaultView,
        };
    }
    if (action.type === TypeKeys.CLOSE) {
        return {
            ...state,
            open: false,
            defaultView: undefined,
        };
    }
    if (action.type === TypeKeys.SET_ENABLE_TESTING_FEATURES) {
        return {
            ...state,
            enableTestingFeatures: action.enableTestingFeatures,
        };
    }

    return state;
}
