import * as React from "react";
import classNames from "classnames";
import { MenuLogo } from "../../../../sharedComponents/assets/icons";
import { isBrandedVenueLoaderEnabled } from "../../util/isBrandedVenueLoaderEnabled";
import { OnboardingLoadingImages } from "../../types";

interface Props {
    loaderImages?: OnboardingLoadingImages | null;
}

export const Initializing = ({ loaderImages }: Props) => (
    <div className={classNames("splash-screen", isBrandedVenueLoaderEnabled() && loaderImages && "dark-background")}>
        <MenuLogo />
    </div>
);
