import { cordovaWindow } from "./cordova";
import { device } from "./device";

const joinOptions = (options: { [property: string]: string }) =>
    Object.keys(options)
        .map((key) => `${key}=${options[key]}`)
        .join(",");

const defaultOptions = {
    beforeload: "get",
    hidenavigationbuttons: "yes",
    toolbarcolor: "#FFFFFF",
    closebuttoncolor: "#000000",
    closebuttoncaption: "Back",
};

const androidOptions = joinOptions({
    ...defaultOptions,
    lefttoright: "yes",
    hideurlbar: "yes",
});

const iosOptions = joinOptions({
    ...defaultOptions,
    location: "no",
    toolbarposition: "top",
});

export const webFlow = {
    start: (startUrl: string, returnUrl: string) =>
        new Promise<URL>((res, rej) => {
            const options = device.isIOS ? iosOptions : androidOptions;
            const browser = cordovaWindow.cordova.InAppBrowser.open(startUrl, "_blank", options);

            browser.addEventListener("beforeload", ({ url }, callback) => {
                if (url.startsWith(returnUrl)) {
                    res(new URL(url));
                    browser.close();
                } else {
                    callback(url);
                }
            });

            browser.addEventListener("exit", () => {
                rej(Error("cancelled"));
            });

            browser.addEventListener("loaderror", (ev) => {
                if (ev.code === 102) {
                    // There can be issues redirecting multiple times while also using beforeunload
                    // with WebKitErrorDomain (error 102) so ignore these
                    return;
                }
                rej(Error(`Error loading InAppBrowser URL: ${ev.url} Message: ${ev.message}`));
            });
        }),
    preStart: () => {},
    cancelPreStart: () => {},
};
