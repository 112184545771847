import { gdpr } from "./gdpr";

export const tracking = {
    trackFacebookPixelId: (facebookPixelId: string) => {
        const { allowAdvertising } = gdpr.getCookiePreferences();
        if (!allowAdvertising) return;

        // for Facebook pixel id Install using img tag:
        // https://developers.facebook.com/docs/facebook-pixel/advanced#install-the-pixel-using-an-img-tag
        const faceImg = document.createElement("img");
        faceImg.height = 1;
        faceImg.width = 1;
        faceImg.style.display = "none";
        faceImg.src = `https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView`;
        faceImg.addEventListener("load", () => {
            document.head.removeChild(faceImg);
        });
        document.head.appendChild(faceImg);
    },
};
