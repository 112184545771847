import "./PayOnlyPaymentStateWizard.scss";

import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getHasPaymentOrder,
    getPayOnlyMemberHasFailedToSubmitPayments,
    getPayOnlyMemberHasUnconfirmedPayments,
    getPayOnlyPartyFullyPaidAndClosed,
    getPayOnlyPartyShutdown,
    getPayOnlyPaymentOrder,
} from "../selectors";
import { showGetReceipt } from "../actions/showGetReceipt";
import { actionCreators } from "../reducers";
import { actionCreators as payOnlyFlagsActionCreators } from "../reducers/payOnlyFlags";
import { resetModalMessage } from "../../modalMessage/actions/reset";
import {
    payOnlyPaymentsFeedWizard,
    PayOnlyPaymentStateFlowPages,
    payOnlyPaymentStateWizard,
    payOnlyPaymentWizard,
} from "../wizards";
import { makePayOnlyPaymentOperation } from "../operations";
import { usePrevious } from "src/sharedComponents/common/shared";
import { getMemberPendingPayments } from "../../payment/selectors";
import { StateWizardModal } from "../../wizards/components/StateWizardModal";
import { createSelector } from "reselect";
import { getParty, OrderStatus } from "../../order";
import { WizardOptionsT } from "../../wizards/wizard";

const getIsPaymentProcessComplete = createSelector(
    payOnlyPaymentStateWizard.getState,
    makePayOnlyPaymentOperation.getStatus,
    getMemberPendingPayments,
    getPayOnlyMemberHasUnconfirmedPayments,
    getPayOnlyMemberHasFailedToSubmitPayments,
    getPayOnlyPaymentOrder,
    getPayOnlyPartyShutdown,
    (
        paymentStateWizardState,
        paymentStatus,
        memberPendingPayments,
        memberHasUnconfirmedPayments,
        memberHasFailedToSubmitPayments,
        order,
        partyShutdown
    ) =>
        !!paymentStateWizardState &&
        paymentStateWizardState.currentPage !== "BillClosed" &&
        paymentStatus !== "processing" &&
        paymentStatus !== "failed" &&
        !memberPendingPayments.length &&
        !memberHasUnconfirmedPayments &&
        !memberHasFailedToSubmitPayments &&
        order?.status !== OrderStatus.VALIDATING &&
        !partyShutdown
);

export const PayOnlyPaymentStateWizard = () => {
    const dispatch = useDispatch();

    const memberHasUnconfirmedPayments = useSelector(getPayOnlyMemberHasUnconfirmedPayments);
    const memberHasFailedToSubmitPayments = useSelector(getPayOnlyMemberHasFailedToSubmitPayments);
    const wizardState = useSelector(payOnlyPaymentStateWizard.getState);
    const hasPaymentOrder = useSelector(getHasPaymentOrder);
    const paymentStatus = useSelector(makePayOnlyPaymentOperation.getStatus);
    const memberPendingPayments = useSelector(getMemberPendingPayments);
    const partyFullyPaidAndClosed = useSelector(getPayOnlyPartyFullyPaidAndClosed);
    const partyShutdown = useSelector(getPayOnlyPartyShutdown);
    const party = useSelector(getParty);
    const prevParty = usePrevious(party);

    const showGetReceiptRef = useRef(false);
    const wizardStateRef = useRef(wizardState);
    wizardStateRef.current = wizardState;

    const resetPaymentState = useCallback(() => {
        dispatch(actionCreators.reset());
        dispatch(payOnlyPaymentWizard.actionCreators.done());
        dispatch(payOnlyPaymentsFeedWizard.actionCreators.done());
    }, [dispatch]);

    const setBillPaid = useCallback(() => {
        dispatch(payOnlyFlagsActionCreators.setBillPaid());
        dispatch(resetModalMessage());
        resetPaymentState();
    }, [dispatch, resetPaymentState]);

    const onPaymentComplete = useCallback(() => {
        resetPaymentState();
        showGetReceiptRef.current = true;
    }, [resetPaymentState]);

    const showGetReceiptIfRequired = useCallback(() => {
        if (showGetReceiptRef.current) {
            showGetReceiptRef.current = false;
            setTimeout(() => dispatch(showGetReceipt()), 500);
        }
    }, [dispatch]);

    const startWizard = useCallback(
        (page: PayOnlyPaymentStateFlowPages, triggeredOnRefresh?: boolean) => {
            const options: WizardOptionsT<PayOnlyPaymentStateFlowPages> | undefined = triggeredOnRefresh
                ? { overrideStartTransition: "no-animation" }
                : undefined;

            dispatch(payOnlyPaymentStateWizard.actionCreators.start(page, options));
        },
        [dispatch]
    );

    useEffect(() => {
        if (wizardState) return;
        const triggeredOnRefresh = !prevParty;
        if (paymentStatus === "processing" || (memberPendingPayments.length && hasPaymentOrder)) {
            startWizard("ProcessingPayment", triggeredOnRefresh);
        } else if (memberHasUnconfirmedPayments) {
            startWizard("ConfirmingWithVenue", triggeredOnRefresh);
        } else if (memberHasFailedToSubmitPayments) {
            startWizard("PaymentFailedToSubmit", triggeredOnRefresh);
        }
    }, [
        startWizard,
        hasPaymentOrder,
        paymentStatus,
        memberPendingPayments,
        memberHasUnconfirmedPayments,
        memberHasFailedToSubmitPayments,
        prevParty,
        wizardState,
    ]);

    useEffect(() => {
        if (prevParty && partyFullyPaidAndClosed) {
            wizardStateRef.current ? setBillPaid() : startWizard("BillClosed");
        }
    }, [startWizard, setBillPaid, partyFullyPaidAndClosed, prevParty]);

    useEffect(() => {
        if (partyShutdown) {
            resetPaymentState();
        }
    }, [partyShutdown, resetPaymentState]);

    return (
        <StateWizardModal
            wizard={payOnlyPaymentStateWizard}
            pages={{
                ProcessingPayment: {
                    type: "progress",
                    text: "Processing payment...",
                    autoClose: paymentStatus === "failed" || (partyShutdown && paymentStatus !== "processing"),
                },
                ConfirmingWithVenue: {
                    type: "progress",
                    text: "Confirming with venue...",
                    getAutoNextTrigger: getPayOnlyMemberHasUnconfirmedPayments,
                    autoClose: partyShutdown,
                },
                PaymentFailedToSubmit: {
                    type: "failure",
                    text: [
                        "Unable to confirm if your payment was received by the venue",
                        "Contact your server to continue",
                    ],
                    getAutoNextTrigger: getPayOnlyMemberHasFailedToSubmitPayments,
                    autoClose: partyShutdown,
                },
                PaymentSuccessful: {
                    type: "success",
                    text: "Your payment was successful",
                    getAutoNextTrigger: getIsPaymentProcessComplete,
                    onTransitionComplete: onPaymentComplete,
                    autoClose: true,
                },
                BillClosed: {
                    type: "success",
                    text: ["All payments have been received", "Closing the bill"],
                    onTransitionComplete: setBillPaid,
                    autoClose: true,
                },
            }}
            onCloseComplete={showGetReceiptIfRequired}
        />
    );
};
