import { AppDispatch, AppState } from "src/features";
import { connect } from "react-redux";
import { Profile } from "../components";
import { Props } from "../components/Profile";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import {
    getCurrentMemberHasSubmittedOrders,
    getCurrentPartyDetails,
    getIsOrderAndPay,
    getIsSectionTakeaway,
} from "../../order/selectors";
import { leaveTable } from "../../partyOnboarding/actions/leaveTable";
import { replace } from "connected-react-router";
import { NavBarType } from "src/common/navigation/types";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { auth } from "../../signup";
import { fetchMealHistory } from "src/features/orderHistoryData/actions/fetchMealHistory";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { signInAction } from "src/features/signup/actions/signInAction";
import { getTotalMembershipCount } from "src/features/membership/selectors/getActiveMembershipState";
import { fetchGroupTabHistoryList } from "src/features/groupTabsHistory/actions";
import { fetchFoodPreferences, initiateDeleteAccount } from "../actions";
import { logout } from "../actions/logout";
import { getCanDeleteAccount, getPreventLeavePartyReason } from "src/features/accounts/selectors/account";
import { onboardingActionCreators } from "src/features/partyOnboarding/reducers";
import { getIsOpenTable } from "../../openTable/selectors";
import { PreventLeavePartyReason } from "../../openTable/types";
import { getCurrentMemberHasSecuredPaymentMethod } from "src/features/securedPayment/selectors";
import { trackedEvent } from "src/common/events/reduxEventTracking";

export function mapStateToProps(state: AppState): DataProps<Props> {
    const profile = state.profile.result;
    const status = state.profile.status;
    const partyDetails = getCurrentPartyDetails(state);
    const isAnonymous = auth.getIsAnonymous();
    const displayName = profile?.firstName ?? "Guest";
    const manageMemberships = !!getTotalMembershipCount(state);
    const disableDeleteAccount = !getCanDeleteAccount(state);
    const isTakeaway = getIsSectionTakeaway(state);
    const isOrderAndPay = getIsOrderAndPay(state);
    const preventLeavePartyReason = getPreventLeavePartyReason(state);
    const hasSubmittedOrders = getCurrentMemberHasSubmittedOrders(state);
    const hasSecuredPayment = getCurrentMemberHasSecuredPaymentMethod(state);
    const isOpenTable = getIsOpenTable(state);

    return {
        profile,
        showProfile: status === "success",
        displayName,
        showHeader: !partyDetails,
        partyDetails,
        isAnonymous,
        manageMemberships,
        disableDeleteAccount,
        isTakeaway,
        isOrderAndPay,
        isOpenTable,
        hasSubmittedOrders,
        hasSecuredPayment,
        preventLeavePartyReason,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        onBack: () => {
            dispatch(accountMenuActionCreators.closeAccountMenu());
            window.setTimeout(() => dispatch(navBarActionCreators.activeType(NavBarType.MENU)), 100);
        },
        fetchHistory: () => {
            dispatch(fetchMealHistory(null));
            dispatch(fetchGroupTabHistoryList(null));
        },
        leaveParty: () => {
            dispatch(leaveTable());
            dispatch(accountMenuActionCreators.closeAccountMenu());
            dispatch(replace("/join-table"));
            dispatch(onboardingActionCreators.clearOnboardingImages());
        },
        logout: () => dispatch(logout()),
        signup: () => dispatch(signInAction()),
        showLogoutLeavePartyMessage: () => dispatch(showModalMessage(modalMessages.cannotLogout())),
        initiateDeleteAccount: () => dispatch(initiateDeleteAccount()),
        fetchFoodPreferences: () => dispatch(fetchFoodPreferences()),
        showOpenTableLeavePartyMessage: (reason: PreventLeavePartyReason) =>
            dispatch(
                showModalMessage(
                    modalMessages.cannotLeaveOpenTable(reason, () =>
                        dispatch(navBarActionCreators.activeType(NavBarType.PAY))
                    )
                )
            ),
        trackPreventLeaveParty: ({ hasSubmittedOrders, hasSecuredPayment }) =>
            dispatch(
                trackedEvent({
                    type: "PARTY/LEAVE_PARTY_PREVENTED",
                    has_submitted_orders: hasSubmittedOrders,
                    has_secured_payment: hasSecuredPayment,
                })
            ),
    };
}

export const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);
