import { ShowAddCardFunc } from "src/features/paymentGateways/types";
import {
    getToastAddedCardOperation,
    showToastAddCardOperation,
} from "src/features/paymentGateways/pos/toast/operations";
import {
    ToastInitializationFailedEvent,
    ToastInitializationSucceededEvent,
    ToastInputChangedEvent,
    ToastInputChangedEventContent,
    ToastPaymentMethod,
    ToastPaymentSessionData,
} from "src/features/paymentGateways/pos/toast/types";
import { HostedField, hostedFieldOptions } from "src/features/paymentMethods/types";
import { loadExternalScript, timeoutAndError } from "src/common/shared";
import { actionCreators as paymentActionCreators, AddedCardResult, GetAddedCardFunc } from "src/features/payment";
import { hostedFieldsActionCreators } from "src/features/paymentMethods";
import { AddedCardPaymentMethod, PaymentMethodVisibility, PaymentType } from "src/common/payment";
import { fetchPaymentSession } from "src/features/payment/api/fetchPaymentSession";
import { getInParty } from "src/features/order/selectors";
import { getMenuData } from "src/features/menu/selectors";
import {
    getApplePayPaymentMethod,
    getGooglePayPaymentMethod,
    getPaymentMethods,
    getSelectedPaymentMethod,
} from "src/features/payment/selectors";
import { getSelectablePaymentMethod } from "src/features/payment/util/getSelectablePaymentMethod";
import { createToastPaymentMethod } from "src/features/paymentGateways/pos/toast/actions/createToastPaymentMethod";

export const showToastAddCard: ShowAddCardFunc = (dispatch, getState, newCardPaymentMethod) => {
    return showToastAddCardOperation.invoke(async () => {
        const state = getState();
        const inParty = getInParty(state);
        const menuData = getMenuData(state);

        const paymentSessionData = await fetchPaymentSession<ToastPaymentSessionData>(
            dispatch,
            newCardPaymentMethod.paymentGateway,
            inParty
        );

        if (!paymentSessionData) {
            throw new Error("No payment session data");
        }

        dispatch(paymentActionCreators.setPaymentSessionData(paymentSessionData));

        const { baseUrl, oauthToken, sessionSecret, merchantId } = paymentSessionData;

        let selectedPaymentMethod: ToastPaymentMethod | undefined;
        let useLastResult = false;

        const onInputChanged = (content: ToastInputChangedEventContent) => {
            let isValid: boolean;
            ({ isValid, selectedPaymentMethod } = content);
            dispatch(hostedFieldsActionCreators.setValid(HostedField.Number, isValid));
            dispatch(hostedFieldsActionCreators.setValid(HostedField.Expiry, isValid));
            dispatch(hostedFieldsActionCreators.setValid(HostedField.CVV, isValid));
            useLastResult = false;
        };

        const initializeHostedCheckout = () =>
            new Promise<any>(async (resolve, reject) => {
                try {
                    const Toast = await loadExternalScript("Toast", `${baseUrl}/assets/loader.js`);

                    Toast.initialize(
                        (e: ToastInitializationSucceededEvent) => {
                            console.log(e);
                            onInputChanged(e.content);
                            Toast.monitor((e: ToastInputChangedEvent) => {
                                console.log(e);
                                onInputChanged(e.content);
                            });
                            resolve(Toast);
                        },
                        (e: ToastInitializationFailedEvent) => {
                            console.log(e);
                            reject(new Error(e.cause?.message ?? "Failed to initialize Toast hosted checkout"));
                        },
                        {
                            sessionSecret,
                            merchantId,
                            oauthToken,
                            domElementId: "toast-hosted-checkout",
                            acceptAmex: false,
                        },
                        {
                            backgroundColor: "#ffffff",
                            color: hostedFieldOptions.color,
                            iconColor: menuData?.themeColor,
                            fontFamily: hostedFieldOptions.fontFamily,
                            fontSize: hostedFieldOptions.fontSize,
                            fontWeight: hostedFieldOptions.fontWeight,
                        }
                    );
                } catch (err) {
                    reject(err);
                }
            });

        await Promise.race([
            initializeHostedCheckout(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Toast hosted checkout"),
        ]);

        const getAddedCard: GetAddedCardFunc = () =>
            getToastAddedCardOperation.invoke(async () => {
                const state = getState();

                if (useLastResult) {
                    return getSelectedPaymentMethod(state);
                }

                useLastResult = true;

                switch (selectedPaymentMethod) {
                    case "NEW_CARD":
                        // Call Toast.createPaymentMethod for new cards in visitToastPaymentInfo as
                        // confirmPayment fails if we change whether to vault it after it's been tokenised.
                        const addedCardPaymentMethod: AddedCardPaymentMethod = {
                            ...newCardPaymentMethod,
                            paymentType: PaymentType.ADDEDCARD,
                            displayName: "New debit or credit card",
                            token: "TOAST_ADDED_CARD", // Will be replaced with the real token when we pay
                            maskedNumber: "New debit or credit card",
                            expirationDate: "",
                            productId: "",
                            processingFee: 0,
                            processingFeeBaseAmount: 0,
                            remember: false,
                            visibility: PaymentMethodVisibility.None,
                        };
                        const result: AddedCardResult = {
                            paymentMethod: addedCardPaymentMethod,
                        };
                        return result;
                    case "APPLE_PAY":
                        // Call Toast.createPaymentMethod for Apple/Google Pay when we initialize
                        // the payment button as this triggers the payment sheet for these methods.
                        return getApplePayPaymentMethod(state);
                    case "GOOGLE_PAY":
                        return getGooglePayPaymentMethod(state);
                    case "SAVED_CARD":
                        // We need the token for saved cards so we can match them up with the
                        // card payment method we fetched for a better user experience
                        try {
                            const token = await createToastPaymentMethod();
                            const paymentMethods = getPaymentMethods(state);
                            return getSelectablePaymentMethod(paymentMethods, token);
                        } catch (err) {
                            useLastResult = false;
                            throw err;
                        }
                    default:
                        throw new Error("Invalid payment method");
                }
            }, dispatch);

        return getAddedCard;
    }, dispatch);
};
