import { AppState } from "src/features";
import { createSelector } from "reselect";
import { auth } from "src/common/auth";
import { getProfile } from "../../accountmenu/selectors";
import { getRestaurantFlags } from "src/features/order/selectors/restaurantFlags";

export const getAuthState = (state: AppState) => state.authState;

export const getCurrentMemberId = createSelector(getAuthState, (_) => auth.getId());

export const getIsAnonymous = createSelector(getAuthState, (_) => auth.getIsAnonymous());

export const getRequireSignIn = createSelector(
    getIsAnonymous,
    getProfile,
    getRestaurantFlags,
    (isAnonymous, profile, { requireEmail }) => {
        return isAnonymous || !profile?.firstName || !profile?.phone || (!!requireEmail && !profile?.email);
    }
);

export const getIsUnverified = createSelector(getIsAnonymous, getProfile, (isAnonymous, profile) => {
    // An authenticated SSO user can still be unverified if they have not yet verified their phone number
    // Users can become unverified if another user verifies with the same mobile number
    return isAnonymous || !profile || profile.phoneVerified === false || profile.phoneUnverified === true;
});
