import { CreateVerifiedAccountPage } from "../types";
import { VenueDetails } from "../../accounts/components/AccountDetails";

export interface CreatedVerifiedAccountPageDetails {
    accountDetailsTitle?: string;
    verifyPageTitle?: string;
    verifyPageAdditionalDescription?: string;
    requireEmail?: boolean;
    emailMessage?: string;
    submitText?: string;
    hideEmailMessage?: boolean;
    onSuccess?: () => void;
    onComplete?: () => void;
    subtitle?: string;
    venueDetails?: VenueDetails;
    flowName?: string;
    preventCloseOnSignInComplete?: boolean;
    signInDescription?: string;
}

export interface CreateVerifiedAccountState {
    page?: CreateVerifiedAccountPage;
    accountCreated?: boolean;
    pageDetails?: CreatedVerifiedAccountPageDetails;
    preventCompletingVerifyWizard?: boolean;
}

export enum TypeKeys {
    CREATE_VERIFIED_ACCOUNT_SHOW = "CREATE_VERIFIED_ACCOUNT_FLOW/SHOW",
    CREATE_VERIFIED_ACCOUNT_FLOW_DONE = "CREATE_VERIFIED_ACCOUNT_FLOW/DONE",
    SET_VERIFIED_ACCOUNT_CREATED = "CREATE_VERIFIED_ACCOUNT/SET_CREATED",
    SET_WIZARD_PAGE_DETAILS = "CREATE_VERIFIED_ACCOUNT/SET_WIZARD_PAGE_DETAILS",
    PREVENT_COMPLETING_VERIFY_WIZARD = "CREATE_VERIFIED_ACCOUNT/PREVENT_COMPLETING_VERIFY_WIZARD",
}

export const actionCreators = {
    showPage: (page: CreateVerifiedAccountPage) => ({ type: TypeKeys.CREATE_VERIFIED_ACCOUNT_SHOW, page }),
    completeCreateVerifiedAccountFlow: () => ({ type: TypeKeys.CREATE_VERIFIED_ACCOUNT_FLOW_DONE }),
    setVerifiedAccountCreated: () => ({ type: TypeKeys.SET_VERIFIED_ACCOUNT_CREATED }),
    setWizardPageDetails: (pageDetails?: CreatedVerifiedAccountPageDetails) => ({
        type: TypeKeys.SET_WIZARD_PAGE_DETAILS,
        pageDetails,
    }),
    preventCompletingVerifyWizard: (preventCompletingVerifyWizard?: boolean) => ({
        type: TypeKeys.PREVENT_COMPLETING_VERIFY_WIZARD,
        preventCompletingVerifyWizard,
    }),
};

export type StartCreateVerifiedAccountFlow = {
    type: TypeKeys.CREATE_VERIFIED_ACCOUNT_SHOW;
    page: CreateVerifiedAccountPage;
};
export type CompleteCreateVerifiedAccountFlowAction = { type: TypeKeys.CREATE_VERIFIED_ACCOUNT_FLOW_DONE };
export type SetVerifiedAccountCreated = { type: TypeKeys.SET_VERIFIED_ACCOUNT_CREATED };
export type SetWizardPageDetails = {
    type: TypeKeys.SET_WIZARD_PAGE_DETAILS;
    pageDetails?: CreatedVerifiedAccountPageDetails;
};
export type PreventCompletingVerifyWizard = {
    type: TypeKeys.PREVENT_COMPLETING_VERIFY_WIZARD;
    preventCompletingVerifyWizard?: boolean;
};

export type CreateVerifiedAccountFlowActions =
    | StartCreateVerifiedAccountFlow
    | CompleteCreateVerifiedAccountFlowAction
    | SetVerifiedAccountCreated
    | SetWizardPageDetails
    | PreventCompletingVerifyWizard;

const initialState: CreateVerifiedAccountState = {};

export function reducer(
    state: CreateVerifiedAccountState = initialState,
    action: CreateVerifiedAccountFlowActions
): CreateVerifiedAccountState {
    if (action.type === TypeKeys.CREATE_VERIFIED_ACCOUNT_SHOW) {
        const { page } = action;
        return {
            ...state,
            page,
        };
    }

    if (action.type === TypeKeys.SET_WIZARD_PAGE_DETAILS) {
        const { pageDetails } = action;
        return {
            ...state,
            pageDetails,
        };
    }

    if (action.type === TypeKeys.SET_VERIFIED_ACCOUNT_CREATED) {
        return {
            ...state,
            accountCreated: true,
        };
    }

    if (action.type === TypeKeys.CREATE_VERIFIED_ACCOUNT_FLOW_DONE) {
        return {
            page: undefined,
            accountCreated: undefined,
            pageDetails: undefined,
        };
    }

    if (action.type === TypeKeys.PREVENT_COMPLETING_VERIFY_WIZARD) {
        const { preventCompletingVerifyWizard } = action;
        return {
            ...state,
            preventCompletingVerifyWizard,
        };
    }

    return state;
}
