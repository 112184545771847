import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth";
import { MarketingMetadata } from "../reducers/types";

export async function getMarketingMetadata(locationId: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/marketing/${locationId}`, {
        method: "GET",
        headers,
    });
    if (!response.ok) {
        throw response;
    }
    return (await response.json()) as MarketingMetadata;
}

export async function getLocationMarketingMetadata(locationSlug: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/marketing/location/${locationSlug}`, {
        method: "GET",
        headers,
    });
    if (!response.ok) {
        throw response;
    }
    return (await response.json()) as MarketingMetadata;
}

export async function getGroupMarketingMetadata(groupId: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/marketing/group/${groupId}`, {
        method: "GET",
        headers,
    });
    if (!response.ok) {
        throw response;
    }
    return (await response.json()) as MarketingMetadata;
}
