import "../assets/OrderHistoryItemPage.scss";

import * as React from "react";
import classNames from "classnames";
import moment from "moment";
import {
    AlertIcon,
    CheckIcon,
    ClocheIcon,
    CrossErrorIcon,
    InfoIcon,
    TabInstrumentIcon,
    TimeCircleIcon,
    WhiskerIcon,
} from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { BillAdjustmentType, PartyType, SplitPaymentMode } from "../../order";
import {
    MealHistoryOrder,
    MealHistoryOrderStatus,
    OrderDetail,
    OrderHistoryData,
    RefundedState,
} from "../../orderHistoryData";
import { OrderHistoryItemList } from "./OrderHistoryItemList";
import { OrderHistoryCollect } from "./OrderHistoryCollect";
import { Text } from "src/sharedComponents";
import { InfoSection } from "./InfoSection";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { getMealHistoryLocale } from "src/features/accounts/utils/mealHistoryLocaleCache";
import { HistoryPaymentDetails } from "src/features/historyInformation/components/HistoryPaymentDetails";
import { HistoryPage } from "src/features/historyInformation/components/HistoryPage";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { config } from "../../../common/config";
import { RegularOrderHistoryDetails } from "./OrderHistoryDetails";
import { VenueDetails } from "src/features/takeaway/components/VenueDetails";
import { CollectionDetails } from "src/features/takeaway/reducers/takeawayOptions";
import { OrderHistoryStampCardButton } from "./OrderHistoryStampCardButton";

export interface Props {
    partyId: string;
    historyDetail: OrderHistoryData;
    onBack?: () => void;
    closeToBack?: boolean;
    // should this be optional (what do we want to do with areas)
    sendReceiptEmail?: (partyId: string) => void;
    markAsRead?: (partyId: string) => void;
    markAsCollected?: (partyId: string) => void;
    getMarketingMetaData?: (locationId: string) => void;
    getPartyMealHistoryData?: (partyId: string) => void;
    marketingIsOpen?: boolean;
    modalOpen?: boolean;
    trackInvoiceRequested: () => void;
    selectedOrderHistoryData: OrderHistoryData | null;
    selectPartyMealHistory: (partyId: string) => void;
    isTakeaway: boolean;
    isScheduledOrder?: boolean;
    scheduledDate: string | null | undefined;
    venueCollectionDetails: CollectionDetails | null;
}

interface State {
    showDetail: boolean;
    orderStatus: MealHistoryOrderStatus | null;
    showOrderCollect: boolean;
}
export interface OrderStatusData {
    title: string;
    body: string;
    actionText?: string;
}

export class OrderHistoryItemPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDetail: false,
            orderStatus: null,
            showOrderCollect: true,
        };

        if (!props.historyDetail.details && props.getPartyMealHistoryData) {
            props.getPartyMealHistoryData(props.partyId);
        }

        this.props.selectPartyMealHistory(this.props.historyDetail.partyId);
    }

    componentDidMount() {
        if (this.props.historyDetail.summary) {
            const { getMarketingMetaData } = this.props;
            getMarketingMetaData && getMarketingMetaData(this.props.historyDetail.summary!.locationId);
        }
        if (this.props.historyDetail.details) {
            this.markAsRead();
        }
    }

    hasFailedStatus = (order: MealHistoryOrder | null) => {
        return (
            !!order &&
            [
                MealHistoryOrderStatus.REJECTED,
                MealHistoryOrderStatus.SUBMIT_FAILED,
                MealHistoryOrderStatus.ORPHANED,
            ].includes(order.status)
        );
    };

    expectedCompleteStatus = (order: MealHistoryOrder | null) => {
        return !order || [MealHistoryOrderStatus.COMPLETED, MealHistoryOrderStatus.SUBMITTED].includes(order.status);
    };

    isErrorState = (
        mealHistoryOrders: MealHistoryOrder[] | undefined,
        orderDetail: OrderDetail[],
        refunded?: RefundedState
    ) => {
        const order = mealHistoryOrders && mealHistoryOrders.length ? mealHistoryOrders[0] : null;
        return (
            this.hasFailedStatus(order) ||
            orderDetail.some((item) => item.failedToSubmit) ||
            refunded === RefundedState.FAILED
        );
    };

    markAsRead = () => {
        const {
            markAsRead,
            partyId,
            historyDetail: { mealHistoryOrders, readError, details, summary },
        } = this.props;
        if (!readError && details && this.isErrorState(mealHistoryOrders, details.orderDetail, summary?.refunded)) {
            markAsRead && setTimeout(() => markAsRead(partyId), 100);
        }
    };

    markAsCollected = () => {
        const { markAsCollected, partyId } = this.props;
        markAsCollected && setTimeout(() => markAsCollected(partyId), 100);
    };

    componentDidUpdate(prevProps: Props) {
        const {
            historyDetail: { mealHistoryOrders, details, summary },
            marketingIsOpen,
            getMarketingMetaData,
        } = this.props;
        const {
            historyDetail: { details: prevDetails, mealHistoryOrders: prevMealHistoryOrders },
        } = prevProps;
        if (
            details &&
            (details !== prevDetails || mealHistoryOrders !== prevMealHistoryOrders) &&
            this.isErrorState(mealHistoryOrders, details.orderDetail, summary?.refunded)
        ) {
            this.markAsRead();
        }
        if (prevProps.marketingIsOpen && !marketingIsOpen && getMarketingMetaData) {
            getMarketingMetaData(summary!.locationId);
        }
    }

    orderStatusData = (order: MealHistoryOrder, menuDataLocale: MenuDataLocale): OrderStatusData => {
        const {
            isTakeaway,
            isScheduledOrder,
            scheduledDate,
            historyDetail: { summary },
        } = this.props;
        const serviceDate = summary?.serviceDate;

        const getBody = (): string => {
            const minTime = order.minTime || 15;
            if (isTakeaway) {
                if (isScheduledOrder && scheduledDate)
                    return `Order scheduled for pick up at ${menuDataLocale.getDateTimeShort(scheduledDate)}`;
                if (serviceDate) {
                    const asapTime = new Date(serviceDate);
                    asapTime.setMinutes(asapTime.getMinutes() + minTime);
                    // should never really get to a state where it's takeaway without scheduled date, but just in case
                    const scheduledTime = scheduledDate ? new Date(scheduledDate) : asapTime;
                    return `Ready from ${menuDataLocale.getDateTimeShort(scheduledTime)}`;
                }
            }
            return `Ready in ${minTime} to  ${order.maxTime || 25} minutes`;
        };

        switch (order.status) {
            case MealHistoryOrderStatus.SUBMITTED:
                return {
                    title: "Waiting for venue to accept", // to we want to use location name?
                    body: getBody(),
                };
            case MealHistoryOrderStatus.PREPARING:
                return {
                    title: isTakeaway && isScheduledOrder ? "We’ve got your order" : "Preparing your order",
                    body: getBody(),
                };
            case MealHistoryOrderStatus.READY:
                return {
                    title: isTakeaway ? "Ready to pick up" : "Order Ready",
                    body: "Time to grab your order! Head over to the pickup area.", //need to use section name?
                    actionText: isTakeaway ? "Show details" : undefined,
                };
        }
        return {
            title: "Status not found",
            body: "",
        };
    };

    renderOrderStatus = (order: MealHistoryOrder, menuDataLocale: MenuDataLocale) => {
        if (this.state.orderStatus !== null && this.state.orderStatus === MealHistoryOrderStatus.COMPLETED) {
            return null;
        }
        const orderStatusData = this.orderStatusData(order, menuDataLocale);
        return (
            <TappableDiv
                className={classNames("history-item__section", "history-item__order-status")}
                onTap={order.status === MealHistoryOrderStatus.READY ? this.showOrderCollect : undefined}
            >
                <div className="history-item__order-status__progress-container">
                    {order.status === MealHistoryOrderStatus.SUBMITTED ? (
                        <TimeCircleIcon invert color="var(--theme-primary-color)" />
                    ) : (
                        <CheckIcon />
                    )}
                    <div
                        className={classNames(
                            "history-item__order-status__progress",
                            order.status === MealHistoryOrderStatus.SUBMITTED ? "progress-active" : "completed"
                        )}
                    >
                        <div className="progress-bar" />
                    </div>
                    <WhiskerIcon
                        color={
                            order.status !== MealHistoryOrderStatus.SUBMITTED ? "var(--theme-primary-color)" : undefined
                        }
                    />
                    <div
                        className={classNames(
                            "history-item__order-status__progress",
                            order.status === MealHistoryOrderStatus.PREPARING && "progress-active",
                            order.status === MealHistoryOrderStatus.READY && "completed"
                        )}
                    >
                        <div className="progress-bar" />
                    </div>
                    <ClocheIcon
                        color={order.status === MealHistoryOrderStatus.READY ? "var(--theme-primary-color)" : undefined}
                    />
                </div>
                <Text preset="title-20" mode={["bold", "block"]} className="history-item__order-status__header">
                    {orderStatusData.title}
                </Text>
                <Text preset="g-14" mode="block" className="history-item__order-status__description">
                    {orderStatusData.body}
                    {orderStatusData.actionText && (
                        <Text mode="bold">
                            &nbsp;
                            {orderStatusData.actionText}
                        </Text>
                    )}
                </Text>
            </TappableDiv>
        );
    };

    renderTakeawayVenueDetails = () => {
        const {
            venueCollectionDetails,
            historyDetail: { summary },
        } = this.props;
        const { locationName } = summary!;

        return (
            <div className="history-item__section">
                <VenueDetails locationName={locationName} collectionDetails={venueCollectionDetails!} />
            </div>
        );
    };

    closeOrderCollect = (completed?: boolean) => {
        if (completed) {
            this.markAsCollected();
        }

        this.setState({ orderStatus: completed ? MealHistoryOrderStatus.COMPLETED : null, showOrderCollect: false });
    };

    showOrderCollect = () => {
        this.setState({ showOrderCollect: true });
    };

    getErrorToDisplay = (
        menuDataLocale: MenuDataLocale,
        orderFailed: boolean,
        someItemsFailed: boolean,
        allItemsFailed: boolean
    ) => {
        const { details, summary } = this.props.historyDetail;
        if (!summary) return null;

        if (!summary.paidWithGroupTab && summary.refunded && details?.totalRefunded) {
            switch (summary.refunded) {
                case RefundedState.PENDING:
                    return (
                        <div className={classNames("history-item__section", "refund-state", summary.refunded)}>
                            <AlertIcon />
                            <Text preset="g-12">
                                Your refund of {menuDataLocale.formatCurrency(details.totalRefunded)} is currently being
                                processed. We’ll send you another receipt when your refund is on the way.
                            </Text>
                        </div>
                    );
                case RefundedState.FAILED:
                    return (
                        <div className={classNames("history-item__section", "refund-state", summary.refunded)}>
                            <CrossErrorIcon />
                            <Text preset="g-12">
                                Your refund of {menuDataLocale.formatCurrency(details.totalRefunded)} could not be
                                processed. Please contact me&u support team
                            </Text>
                        </div>
                    );
                case RefundedState.REFUNDED:
                    return (
                        <div className={classNames("history-item__section", "refund-state", summary.refunded)}>
                            <CheckIcon />
                            <Text preset="g-12">
                                Your refund of {menuDataLocale.formatCurrency(details.totalRefunded)} has been
                                processed. It may take up to 3 business days to appear in your account, depending on
                                your bank.
                            </Text>
                        </div>
                    );
            }
        }

        if (someItemsFailed && !allItemsFailed) {
            return (
                <InfoSection
                    title="Some items were not sent"
                    className="history-item__section"
                    description="Please show this to your waiter, so that your order can be prepared or refunded."
                    error={true}
                />
            );
        }

        if (orderFailed || allItemsFailed) {
            return (
                <InfoSection
                    title="Your order can’t be confirmed"
                    className="history-item__section"
                    description="Alert venue staff to ensure you receive all of your items."
                    error={true}
                />
            );
        }

        return null;
    };

    render() {
        const { historyDetail, onBack, closeToBack, modalOpen, isTakeaway, venueCollectionDetails } = this.props;
        const { partyId, details, mealHistoryOrders, summary, partyType } = historyDetail;
        if (!summary) return null;
        const {
            locationName,
            serviceDate,
            tableNumber,
            tableLabel,
            total,
            locationImage,
            locationLogo,
            locationColor,
            locale,
            currency,
            locationId,
            refunded,
        } = summary!;
        const {
            memberId,
            tip,
            orderAmount,
            tableTotal,
            memberDiscount,
            unallocatedAmount,
            processingFee,
            orderDetail,
            surcharge,
            discount,
            paymentIsOutstanding,
            paymentOptions,
            partyPayments,
            totalRefunded,
            members,
            paymentMethod,
            cardType,
            paymentMethodDescription,
            billShare,
            pointsSpendAmount,
        } = details || {};

        const { showOrderCollect } = this.state;

        const isOpenTable = partyType === PartyType.MULTIUSER;

        const order = mealHistoryOrders && mealHistoryOrders.length ? mealHistoryOrders[0] : null;
        const date = new Date(serviceDate);
        const shortDate = moment(date).format("MMMM DD, YYYY • h:mmA");
        const billOwner =
            members && paymentOptions && members.find((member) => member.memberId === paymentOptions.owner);
        const unallocated = !isOpenTable && (orderDetail?.some((i) => !i.memberId) || false);
        const someItemsFailed =
            (orderDetail?.some((i) => !!i.failedToSubmit) || false) && this.expectedCompleteStatus(order);
        const orderFailed =
            !order ||
            order.status === MealHistoryOrderStatus.REJECTED ||
            order.status === MealHistoryOrderStatus.SUBMIT_FAILED ||
            order.status === MealHistoryOrderStatus.ORPHANED;
        const allItemsFailed = orderDetail?.every((i) => !!i.failedToSubmit) || false;
        const filteredPartyPayments = partyPayments && partyPayments.filter((p) => p.failedToSubmit === false);
        const showPickupBar =
            !allItemsFailed &&
            !someItemsFailed &&
            !orderFailed &&
            !!order &&
            (order.isPickup || order.isTakeaway) &&
            order.status !== MealHistoryOrderStatus.COMPLETED;

        const menuDataLocale = getMealHistoryLocale(locale, currency);
        const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1" || isOpenTable;
        const stampCardOffers = billShare?.adjustments?.filter(
            (a) => a.type === BillAdjustmentType.ENGAGE_OFFER && a.value === 0 && a.promotionId
        );

        return (
            <MenuDataLocaleContext.Provider value={menuDataLocale}>
                <HistoryPage
                    update={orderHistoryUpdateEnabled}
                    onBack={closeToBack && onBack ? onBack : undefined}
                    historyTime={shortDate}
                    locationName={locationName}
                    locationId={locationId}
                    locationImagePath={locationImage}
                    locationLogoPath={locationLogo}
                    locationColor={locationColor}
                    historyName={
                        isTakeaway ? tableNumber : `${!isOpenTable ? "Order" : tableLabel || "Table"} ${tableNumber}`
                    }
                    sendReceipt={paymentMethod !== "GroupTab" ? this.sendReceiptAction : undefined}
                    customBack={!modalOpen ? `history-item/${partyId}` : undefined}
                    topElements={
                        <>
                            {showPickupBar && this.renderOrderStatus(order!, menuDataLocale)}
                            {showPickupBar && isTakeaway && venueCollectionDetails && this.renderTakeawayVenueDetails()}
                            {this.getErrorToDisplay(menuDataLocale, orderFailed, someItemsFailed, allItemsFailed)}
                        </>
                    }
                    additional={
                        <>
                            {!!stampCardOffers?.length && (
                                <OrderHistoryStampCardButton
                                    locationId={locationId}
                                    cepStampsAdjustments={stampCardOffers}
                                />
                            )}
                            {unallocated && (
                                <InfoSection
                                    className="history-item__section"
                                    title="Unallocated items"
                                    description="There were unallocated items on this table. Your bill sharing has been affected to equally cover the cost."
                                />
                            )}
                            {paymentOptions && billOwner && (
                                <InfoSection
                                    className="history-item__section"
                                    title="Bill Sharing"
                                    description={
                                        <>
                                            <Text preset="g-16">
                                                {billOwner.memberName} selected&nbsp;
                                                <b>
                                                    {paymentOptions.splitPaymentMode === SplitPaymentMode.ORDER
                                                        ? "Pay by order"
                                                        : "Split equally"}
                                                </b>
                                            </Text>
                                            {!!surcharge && (
                                                <Text preset="g-12" className="history-item__bill-sharing-info">
                                                    <InfoIcon />{" "}
                                                    {`Incl. ${menuDataLocale.surchargeText.toLowerCase()}s`}
                                                </Text>
                                            )}
                                        </>
                                    }
                                    showDetailsAction={false}
                                >
                                    {!!filteredPartyPayments && (
                                        <div className="history-item__members-payment">
                                            {filteredPartyPayments
                                                .sort((m1) => (m1.memberId === memberId ? -1 : 1))
                                                .map((memberPay) => {
                                                    return (
                                                        <Text
                                                            preset="g-14"
                                                            mode={memberPay.memberId === memberId ? "bold" : undefined}
                                                            key={memberPay.memberId}
                                                            className="history-item__list-value"
                                                        >
                                                            <div>{memberPay.displayName}</div>
                                                            <div>{menuDataLocale.formatCurrency(memberPay.amount)}</div>
                                                        </Text>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </InfoSection>
                            )}
                        </>
                    }
                    orderDetails={orderHistoryUpdateEnabled ? <RegularOrderHistoryDetails /> : undefined}
                    items={
                        orderHistoryUpdateEnabled
                            ? undefined
                            : !!orderDetail && (
                                  <InfoSection
                                      title="My order"
                                      className="history-item__section history-item__section--fade-in"
                                      description={<OrderHistoryItemList items={orderDetail} />}
                                  />
                              )
                    }
                    payment={
                        orderHistoryUpdateEnabled ? undefined : (
                            <InfoSection
                                className="history-item__section history-item__payments history-item__section--fade-in"
                                title={
                                    <div
                                        className={
                                            "history-item__paid-info" + (paymentIsOutstanding ? " outstanding" : "")
                                        }
                                    >
                                        <span>
                                            {paymentIsOutstanding ? "Outstanding balance" : "You paid"}{" "}
                                            {paymentMethod === "GroupTab"
                                                ? "with"
                                                : menuDataLocale.formatCurrency(total)}
                                        </span>
                                    </div>
                                }
                                showDetailsAction={paymentMethod !== "GroupTab"}
                                description={
                                    paymentMethod === "GroupTab" && paymentMethodDescription ? (
                                        <div className="history-item-details__group-tab">
                                            <TabInstrumentIcon />
                                            {paymentMethodDescription}
                                        </div>
                                    ) : undefined
                                }
                                waitForContent={!details}
                            >
                                {paymentMethod !== "GroupTab" && (
                                    <HistoryPaymentDetails
                                        tableTotal={tableTotal}
                                        orderAmount={orderAmount}
                                        memberDiscount={memberDiscount}
                                        discount={discount}
                                        surcharge={surcharge}
                                        unallocatedAmount={unallocatedAmount}
                                        tip={tip}
                                        paymentMethod={paymentMethod}
                                        cardType={cardType}
                                        paymentMethodDescription={paymentMethodDescription}
                                        processingFee={processingFee}
                                        totalRefunded={refunded === RefundedState.REFUNDED ? totalRefunded : undefined}
                                        total={total}
                                        taxSummary={billShare?.taxSummary}
                                        adjustments={billShare?.adjustments}
                                        surchargePercent={billShare?.surchargePercentage}
                                        offerDiscountAmount={billShare?.offerDiscountAmount}
                                        pointsSpendAmount={pointsSpendAmount}
                                    />
                                )}
                            </InfoSection>
                        )
                    }
                />
                {showPickupBar && order?.status === MealHistoryOrderStatus.READY && (
                    <OrderHistoryCollect
                        show={showOrderCollect}
                        historyDetail={historyDetail}
                        close={this.closeOrderCollect}
                    />
                )}
            </MenuDataLocaleContext.Provider>
        );
    }

    sendReceiptAction = () => {
        const {
            sendReceiptEmail,
            historyDetail: { partyId },
        } = this.props;
        sendReceiptEmail && sendReceiptEmail(partyId);
    };
}
