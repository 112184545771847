import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { HistoryList } from "src/features/groupTabsHistory/components/HistoryList";
import { HistoryListItem, OrderHistoryData } from "src/features/orderHistoryData";
import { fetchMealHistory } from "src/features/orderHistoryData/actions/fetchMealHistory";
import {
    getIsAnonymous,
    getOrderHistoryForCards,
    getOrderHistoryLastServiceDate,
    orderHistoryDataHasMore,
    orderHistoryDataLoading,
} from "../selectors";
import { OrderHistoryTile } from "./OrderHistoryTile";
import { LIMIT_HISTORY_ITEMS } from "../../groupTabsHistory/constants";

export const OrderHistoryList = () => {
    const mealHistoryData = useSelector(getOrderHistoryForCards);
    const hasMore = useSelector(orderHistoryDataHasMore);
    const loading = useSelector(orderHistoryDataLoading);
    const lastServiceDate = useSelector(getOrderHistoryLastServiceDate);
    const isAnonymous = useSelector(getIsAnonymous);
    const dispatch = useDispatch();

    const loadMoreItems = useCallback(() => {
        if (loading !== "failure" && loading !== "processing") {
            dispatch(fetchMealHistory(lastServiceDate, LIMIT_HISTORY_ITEMS));
        }
    }, [loading, lastServiceDate, dispatch]);

    return (
        <HistoryList
            name="order-history-list"
            items={mealHistoryData}
            hasMore={hasMore}
            loadMoreItems={loadMoreItems}
            loading={loading === "processing"}
            showAnonymous={isAnonymous}
            to={(partyId: string) =>
                generatePath("/account/order-history/:partyId", {
                    partyId,
                })
            }
            historyCardTile={(item: HistoryListItem) => <OrderHistoryTile item={item as OrderHistoryData} />}
        />
    );
};
