import { AppDispatch, AppState } from "src/features";
import { addMarketingOptIn } from "src/features/marketing/api";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getAnyParty } from "src/features/order/selectors";
import { forceSendingReceiptEmail } from "src/features/payment/actions/forceSendingReceiptEmail";
import { showPayOnlyGetReceiptOperation } from "../operations";
import { getPayOnlyGetReceiptState } from "../selectors";
import { getMarketingInfo } from "./getMarketingInfo";
import { actionCreators as taxInvoiceActions } from "../../orderHistoryData/reducers/taxInvoice";
import { PartyType } from "src/features/order";
import { SignupSource } from "src/features/marketing";

export const showGetReceipt = () => {
    return showPayOnlyGetReceiptOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const party = getAnyParty(getState());
        if (party) {
            dispatch(
                showModalMessage(
                    modalMessages.payOnlyNeedReceipt(() => {
                        const { email, receive } = getPayOnlyGetReceiptState(getState());
                        if (email) {
                            dispatch(taxInvoiceActions.trackInvoiceRequested());
                            dispatch(
                                forceSendingReceiptEmail(party.id, {
                                    email,
                                    useLocationTheme: true,
                                    isPayOnly: true,
                                })
                            );

                            if (receive) {
                                addMarketingOptIn(
                                    party.restaurantId,
                                    null,
                                    null,
                                    email,
                                    null,
                                    party.id,
                                    PartyType[PartyType.PAYONLY],
                                    SignupSource[SignupSource.NEWSLETTER]
                                )
                                    .then(() => dispatch(getMarketingInfo()))
                                    .catch((err) => null);
                            }
                        }
                    })
                )
            );
        }
    });
};
