import * as React from "react";
import { createRef, RefObject } from "react";
import { NativeBackButton, SimpleNavHeader } from "src/common/navigation";
import { TappableDiv, TappableLink } from "src/sharedComponents/common/tappable";
import {
    ContactUsIcon,
    DietaryIcon,
    LeaveTableIcon,
    MembershipIcon,
    PrivacyIcon,
    ReceiptIcon,
    StarCheck,
    UserDetails,
    WalletIcon,
} from "src/sharedComponents/assets/icons";
import "../assets/Profile.scss";
import { AccountProfile } from "../../accountmenu";
import { Spinner } from "../../Spinner";
import { DiagnosticInformation } from "src/common/DiagnosticInformation";
import { CurrentPartyDetails } from "../../order";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Button, Text } from "src/sharedComponents";
import { gdpr } from "src/common/experience";
import { config } from "src/common/config";
import { ModalContainer } from "src/common/modal";
import { PreventLeavePartyReason } from "../../openTable/types";
import { getActualTableNumber } from "src/common/shared";

export interface Props {
    profile: AccountProfile | null;
    showProfile: boolean;
    onBack: () => void;
    fetchHistory: () => void;
    partyDetails: CurrentPartyDetails | null;
    showHeader: boolean;
    leaveParty: () => void;
    displayName: string;
    isAnonymous: boolean;
    logout: () => void;
    signup: () => void;
    showLogoutLeavePartyMessage: () => void;
    initiateDeleteAccount: () => void;
    manageMemberships: boolean;
    disableDeleteAccount: boolean;
    isTakeaway?: boolean;
    fetchFoodPreferences: () => void;
    hasSubmittedOrders: boolean;
    hasSecuredPayment: boolean;
    isOrderAndPay: boolean;
    showOpenTableLeavePartyMessage: (reason: PreventLeavePartyReason) => void;
    isOpenTable: boolean;
    preventLeavePartyReason: PreventLeavePartyReason | null;
    trackPreventLeaveParty: (trackPreventLeaveParty: TrackPreventLeaveParty) => void;
}

type LogoutStep = "None" | "Started" | "Confirmed";

export interface State {
    showVersion: boolean;
    logoutStep: LogoutStep;
}

interface TrackPreventLeaveParty {
    hasSubmittedOrders: boolean;
    hasSecuredPayment: boolean;
}

export class Profile extends React.Component<Props, State> {
    buttonPressTimer: any;
    oncontextmenu: any;
    containerRef: RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.oncontextmenu = window.oncontextmenu;
        this.containerRef = createRef();

        const { fetchHistory, fetchFoodPreferences } = props;

        fetchHistory();
        fetchFoodPreferences();

        this.state = {
            showVersion: false,
            logoutStep: "None",
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { profile } = this.props;

        if (prevProps.profile !== profile && profile) {
            this.containerRef.current?.scrollTo({ top: 0 });
        }
    }

    onLogoutStart = () => {
        const { partyDetails, logout, showLogoutLeavePartyMessage } = this.props;
        const { logoutStep } = this.state;

        if (partyDetails) {
            showLogoutLeavePartyMessage();
        } else if (logoutStep === "Started") {
            this.setState({ logoutStep: "Confirmed" });
            logout();
        } else {
            this.setState({ logoutStep: "Started" });
        }
    };

    onLeaveParty = () => {
        const {
            leaveParty,
            showOpenTableLeavePartyMessage,
            hasSubmittedOrders,
            hasSecuredPayment,
            isOpenTable,
            preventLeavePartyReason,
            trackPreventLeaveParty,
        } = this.props;

        if (!preventLeavePartyReason) {
            leaveParty();
        } else if (isOpenTable) {
            trackPreventLeaveParty({ hasSubmittedOrders, hasSecuredPayment });
            showOpenTableLeavePartyMessage(preventLeavePartyReason);
        }
    };

    getTableNumber = () => {
        const { partyDetails, isOrderAndPay } = this.props;
        const { tableNumber } = partyDetails!;
        return getActualTableNumber(tableNumber, isOrderAndPay);
    };

    getLeaveServiceSubtext = () => {
        const { isTakeaway, partyDetails } = this.props;
        const { location, tableLabel } = partyDetails!;
        if (isTakeaway) {
            return `You’re currently ordering for takeaway at ${location}. Leave this order to start ordering from a ${tableLabel}.`;
        }
        return `You’re currently ordering from ${tableLabel} ${this.getTableNumber()} at ${location}. Leave this order to start ordering from a different ${tableLabel}.`;
    };

    renderProfileRow = () => {
        const { displayName, partyDetails, isAnonymous, preventLeavePartyReason } = this.props;

        return (
            <div className="profile-row__container">
                <div className="profile-row">
                    <div onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd}>
                        <Text preset="title-28" mode={["extra-bold", "block"]} className="profile-row__name">
                            {this.renderMemberName(displayName)}
                        </Text>
                        {!isAnonymous && (
                            <Text preset="g-14" className="account__verified">
                                <StarCheck /> Verified
                            </Text>
                        )}
                    </div>
                </div>
                {partyDetails && (
                    <TappableDiv
                        className={classNames(
                            "flex-cols account__item-row",
                            "leave-table",
                            preventLeavePartyReason && "disabled"
                        )}
                        onClick={this.onLeaveParty}
                    >
                        <LeaveTableIcon />
                        <div>
                            <Text preset="g-16" mode="bold" className="leave-table__text" value="Leave this order" />
                            <Text preset="g-12" className="leave-table__description">
                                {this.getLeaveServiceSubtext()}
                            </Text>
                        </div>
                    </TappableDiv>
                )}
            </div>
        );
    };

    renderMemberName(displayName: string | null) {
        if (!!displayName) {
            return `Hi, ${displayName}`;
        }

        return "Hi";
    }

    public render() {
        const {
            showProfile,
            onBack,
            partyDetails,
            profile,
            showHeader,
            isAnonymous,
            manageMemberships,
            initiateDeleteAccount,
            disableDeleteAccount,
        } = this.props;
        const { showVersion, logoutStep } = this.state;

        return (
            <div
                className={classNames("account account__container", "animated-child", {
                    "wrapper-for-fixed-nav": showHeader,
                    "wrapper-for-no-nav": !showHeader,
                })}
            >
                {(logoutStep === "Confirmed" || !showProfile) && <Spinner />}
                {showHeader ? (
                    <div className="fixed-at-top">
                        <SimpleNavHeader
                            title="Profile"
                            withBorder
                            onBack={onBack}
                            closeToBack
                            customBack={"profile/from-home"}
                            rightElement={this.renderLoginExisting()}
                        />
                    </div>
                ) : (
                    <NativeBackButton name="profile/in-party" onPressed={onBack} />
                )}
                {showProfile && (
                    <div
                        ref={this.containerRef}
                        className={classNames("flex-top item account__container__content overflow-hidden")}
                    >
                        {!showHeader && this.renderLoginExisting()}
                        {isAnonymous && profile && this.renderVerifySMS()}
                        {this.renderProfileRow()}
                        <Text preset="g-12" mode="medium" className="account__item-title">
                            My account
                        </Text>
                        <TappableLink to="/account/history-list/order" className="flex-cols account__item-row">
                            <ReceiptIcon />
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Order history
                            </Text>
                        </TappableLink>
                        <TappableLink to="/account/preferences" className="flex-cols account__item-row">
                            <DietaryIcon />
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Dietary preferences
                            </Text>
                        </TappableLink>
                        {partyDetails && (
                            <TappableLink to="/onboarding/payment-methods" className="flex-cols account__item-row">
                                <WalletIcon />
                                <Text preset="g-16" mode="bold" className="account__item-row--text">
                                    Payment methods
                                </Text>
                            </TappableLink>
                        )}
                        <TappableLink to="/account/details" className="flex-cols account__item-row">
                            <UserDetails />
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Manage details
                            </Text>
                        </TappableLink>
                        {manageMemberships && (
                            <TappableLink to="/account/memberships" className="flex-cols account__item-row">
                                <MembershipIcon />
                                <Text preset="g-16" mode="bold" className="account__item-row--text">
                                    Loyalty & memberships
                                </Text>
                            </TappableLink>
                        )}
                        {gdpr.canSetCookiePreferences() && (
                            <TappableLink to="/account/privacy-settings" className="flex-cols account__item-row">
                                <PrivacyIcon />
                                <Text preset="g-16" mode="bold" className="account__item-row--text">
                                    Privacy settings
                                </Text>
                            </TappableLink>
                        )}
                        <Text preset="g-12" mode="medium" className="account__item-title">
                            Support
                        </Text>
                        <TappableLink to="/contact-us" className="flex-cols account__item-row">
                            <ContactUsIcon />
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Contact us
                            </Text>
                        </TappableLink>
                        <Text preset="g-12" mode="medium" className="account__item-title">
                            Legal
                        </Text>
                        <TappableLink to="/terms-of-use" className="flex-cols account__item-row">
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Terms of use
                            </Text>
                        </TappableLink>
                        <TappableLink to="/privacy-policy" className="flex-cols account__item-row">
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Privacy policy
                            </Text>
                        </TappableLink>
                        <TappableLink to="/software_licenses" className="flex-cols account__item-row">
                            <Text preset="g-16" mode="bold" className="account__item-row--text">
                                Software licenses
                            </Text>
                        </TappableLink>
                        <span className="account__bottom-section">
                            {!isAnonymous && (
                                <TappableDiv
                                    className={classNames(
                                        "flex-cols account__item-row",
                                        "account__remove",
                                        "account__remove__logout",
                                        partyDetails && "disabled"
                                    )}
                                    onClick={this.onLogoutStart}
                                >
                                    <CSSTransition
                                        in={logoutStep !== "None"}
                                        classNames="fade"
                                        timeout={200}
                                        unmountOnExit
                                    >
                                        <Text preset="g-16" mode="bold">
                                            Are you sure? Tap to confirm
                                        </Text>
                                    </CSSTransition>
                                    <CSSTransition
                                        in={logoutStep === "None"}
                                        classNames="fade"
                                        timeout={200}
                                        unmountOnExit
                                    >
                                        <Text preset="g-16" mode="bold">
                                            Log out
                                        </Text>
                                    </CSSTransition>
                                </TappableDiv>
                            )}
                            {config.enableAccountDeletion === "1" && !!profile && (
                                <TappableDiv
                                    className={classNames(
                                        "flex-cols account__item-row",
                                        "account__remove",
                                        "account__remove__delete",
                                        disableDeleteAccount && "disabled"
                                    )}
                                    onClick={initiateDeleteAccount}
                                >
                                    <Text preset="g-16" mode="bold">
                                        Delete account
                                    </Text>
                                </TappableDiv>
                            )}
                        </span>
                    </div>
                )}
                <ModalContainer
                    isOpen={showVersion}
                    className="version-message-modal-content"
                    overlayClassName="ReactModal__VersionMessage"
                    closeTimeoutMS={300}
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                    onRequestClose={this.closeVersionModal}
                >
                    <DiagnosticInformation onClick={this.closeVersionModal} />
                </ModalContainer>
            </div>
        );
    }

    onTouchStart = () => {
        window.oncontextmenu = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        this.buttonPressTimer = setTimeout(() => {
            window.oncontextmenu = this.oncontextmenu;
            this.setState({ showVersion: true });
        }, 1000);
    };

    onTouchEnd = () => {
        clearTimeout(this.buttonPressTimer);
        window.oncontextmenu = this.oncontextmenu;
    };

    closeVersionModal = () => {
        this.setState({ showVersion: false });
    };

    renderLoginExisting = () => {
        const { isAnonymous, showHeader, signup, profile } = this.props;
        if (!isAnonymous || profile?.phone) {
            return null;
        }
        return (
            <TappableDiv
                onClick={signup}
                className={classNames("account__login-existing", !showHeader && "account__login-existing--standalone")}
            >
                <Text preset="g-14" mode="block">
                    Existing account?{" "}
                    <Text preset="g-14" mode="bold" className="account__login-existing__action">
                        Sign in
                    </Text>
                </Text>
            </TappableDiv>
        );
    };

    renderVerifySMS = () => {
        const { signup } = this.props;
        return (
            <div className="account__verify">
                <Text preset="g-16" mode={["bold", "block"]} className="account__verify__title">
                    Log in to your me&u account to view your previous orders.
                </Text>
                <Button onClick={signup} value="Log in or sign up" brandColor />
            </div>
        );
    };
}
