import React, { useEffect, useRef, useState } from "react";
import { Toast } from "src/sharedComponents";
import "./MembershipConnectedConfirmation.scss";
import { MembershipIcon } from "src/sharedComponents/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { connectedActions } from "../reducers";
import { AppState } from "../../index";
import { getIsPartyPosBillPrice } from "src/features/order/selectors";
import { getCurrentMembershipApplyOnValidate } from "src/features/membership/selectors/getConnectedMembershipState";

export const MembershipConnectedConfirmation = () => {
    const dispatch = useDispatch();
    const connected = useSelector((state: AppState) => state.membership.showConnectedConfirmation);
    const isPosBilling = useSelector(getIsPartyPosBillPrice);
    const currentMembershipApplyOnValidate = useSelector(getCurrentMembershipApplyOnValidate);
    const [show, setShow] = useState(false);
    const timerRef = useRef<number | undefined>();

    useEffect(() => {
        if (connected) {
            // Give the page a little time to display before showing the toast.
            timerRef.current = window.setTimeout(() => {
                setShow(true);
                dispatch(connectedActions.hideConnectedConfirmation());
            }, 1000);
        }
    }, [connected, dispatch]);

    useEffect(() => {
        return () => {
            window.clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <div className="membership-connected-confirmation">
            <Toast
                text={
                    isPosBilling || currentMembershipApplyOnValidate
                        ? "Deals will be applied on checkout"
                        : "Members discount added"
                }
                show={show}
                icon={<MembershipIcon />}
                canDismiss={true}
                onDismiss={() => setShow(false)}
            />
        </div>
    );
};
