import "../assets/ModifierCheckboxListItem.scss";
import React, { useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { Checkbox } from "src/sharedComponents";
import { ModifierOption } from "../../menudata";
import { ModifierListItemDetails } from "./ModifierListItemDetails";
import { animateListItemBackground } from "../helpers";
import { ListItemBackground } from "../component/ListItemBackground";
import { OrderItemSelectedNestedModiferDisplayData } from "..";
import { NestedModifiersView } from "src/features/menuitem/components/NestedModifiersView";
import { useSelector } from "react-redux";
import { getActiveServiceMenuItemIds } from "src/features/menu/selectors";
import { getOptionItemReferenceNotInActiveService } from "src/features/menuitem/helpers";

interface Props {
    modifierId: number;
    onModifierOptionSelected: (modifierId: number, optionOriginalIndex: number, selected: boolean) => void;
    disabled: boolean;
    checked: boolean;
    option: ModifierOption;
    onNestedModifierEdit?: (modifierId: number, optionOriginalIndex: number) => void;
    selectedNestedModifier?: OrderItemSelectedNestedModiferDisplayData[][];
    nestingLevel?: number;
}

export const ModifierCheckboxListItem = ({
    modifierId,
    onModifierOptionSelected,
    option,
    checked,
    disabled,
    selectedNestedModifier,
    onNestedModifierEdit,
    nestingLevel,
}: Props) => {
    const backgroundRef = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<Animation | null>(null);
    const nestedOptions = selectedNestedModifier ? selectedNestedModifier[option.originalIndex] : undefined;
    const activeServiceMenuItemIds = useSelector(getActiveServiceMenuItemIds);
    const hide = getOptionItemReferenceNotInActiveService(option, activeServiceMenuItemIds);

    const id = useMemo(
        () =>
            `modifier_${modifierId}_${option.originalIndex}_${option.displayName.replace(/\W/g, "_")}${
                nestingLevel !== undefined ? `_${nestingLevel}` : ""
            }`,
        [modifierId, option.originalIndex, option.displayName, nestingLevel]
    );

    const onTap = () => {
        animateListItemBackground(backgroundRef, animationRef);
        onModifierOptionSelected(modifierId, option.originalIndex, !checked);
    };

    useEffect(
        () => () => {
            if (animationRef.current) {
                animationRef.current.cancel();
            }
        },
        [animationRef]
    );

    if (hide) {
        return null;
    }

    return (
        <>
            <div className="modifier-checkbox-list-item">
                <div className="modifier-checkbox-list-item__container">
                    <TappableDiv disabled={disabled} onTap={onTap}>
                        <Checkbox
                            className={classNames("modifier-checkbox-list-item__checkbox")}
                            id={id}
                            checked={checked}
                            label={<ModifierListItemDetails listItem={option} disabled={disabled} prefix="+" />}
                            disabled={disabled}
                        />
                    </TappableDiv>
                </div>
                <ListItemBackground ref={backgroundRef} />
            </div>
            <NestedModifiersView
                nestedOptions={nestedOptions}
                onChangeSelection={
                    onNestedModifierEdit ? () => onNestedModifierEdit(modifierId, option.originalIndex) : undefined
                }
            />
        </>
    );
};
