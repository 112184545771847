import "../assets/OrderItem.scss";

import React, { useMemo } from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { Price } from "src/features/menu/components/Price";
import { LoadingText } from "src/sharedComponents/controls/loadingText";
import { WaitTimeBadge } from "../../menu/components/WaitTimeBadge";
import { getShouldShowPriceLoader } from "../selectors";
import { useSelector } from "react-redux";
import { OrderCourseItem } from "../reducers/types";
import { QuantitySelector } from "src/sharedComponents/controls/quantitySelector";
import { getPayOnlyState } from "src/features/payOnly/selectors";
import { VisibleNestedModifierOption } from "src/features/menudata";

export interface LineProps {
    item: OrderCourseItem;
    hidePrice?: boolean;
    activeItem?: boolean;
    selectable?: boolean;
    onSelectItem?: (referenceId: string, quantity: number) => void;
}

export interface LinePriceProps {
    price: number | null;
    activeItem?: boolean;
    selectable?: boolean;
}

const OrderItemLinePrice = ({ price, activeItem, selectable }: LinePriceProps) => {
    const showPriceLoader = useSelector(getShouldShowPriceLoader) && activeItem && price !== undefined;

    return (
        <Text preset="g-14" mode="bold" className={classNames({ "order-item__details__primary": !selectable })}>
            {showPriceLoader || price === null ? <LoadingText /> : price !== undefined && <Price price={price} />}
        </Text>
    );
};

const pushNestedOptionsRecursive = (
    modifiers: string[],
    nestedOptions: VisibleNestedModifierOption[] | undefined,
    nestingLevel: number = 1
) => {
    if (!nestedOptions) return;
    for (const nestedOption of nestedOptions) {
        modifiers.push(`${"- ".repeat(nestingLevel)}${nestedOption.displayName}`);
        pushNestedOptionsRecursive(modifiers, nestedOption.nestedOptions, nestingLevel + 1);
    }
};

export const OrderItemLine = ({ item, hidePrice, selectable, activeItem, onSelectItem }: LineProps) => {
    const { selectedItems } = useSelector(getPayOnlyState);

    const selectedQuantity = (item.referenceId && selectedItems[item.referenceId]) || 0;

    const secondaryDetails = useMemo(
        () => [
            ...(!selectable && item.notes ? [item.notes] : []),
            ...(item.variantName ? [item.variantName] : []),
            ...(item.modifierOptions?.reduce<string[]>((modifiers, { displayName, nestedOptions }) => {
                modifiers.push(displayName);
                pushNestedOptionsRecursive(modifiers, nestedOptions);
                return modifiers;
            }, []) ?? []),
        ],
        [selectable, item.notes, item.variantName, item.modifierOptions]
    );

    const handleOnIncrement = () => {
        if (item.referenceId) onSelectItem?.(item.referenceId, selectedQuantity + 1);
    };

    const handleOnDecrement = () => {
        if (item.referenceId) onSelectItem?.(item.referenceId, selectedQuantity - 1);
    };

    return (
        <>
            <Text preset="g-14" mode="bold" className="order-item__quantity">
                {item.quantity}
            </Text>
            <div className="order-item__details">
                <Text preset="g-14" mode="bold" className="order-item__details__primary">
                    {item.displayName}
                </Text>
                {secondaryDetails.map((secondaryDetail, index) => (
                    <Text key={index} preset="g-14" className="order-item__details__secondary">
                        {secondaryDetail}
                    </Text>
                ))}
                {selectable && !hidePrice && (
                    <OrderItemLinePrice price={item.price} selectable={selectable} activeItem={activeItem} />
                )}
            </div>
            {selectable && item.referenceId && onSelectItem ? (
                <div className="order-item__quantity-selector">
                    <QuantitySelector
                        shouldAnimateOnActive
                        quantity={selectedQuantity}
                        maxValue={item.quantity}
                        onIncrement={handleOnIncrement}
                        onDecrement={handleOnDecrement}
                    />
                </div>
            ) : (
                <div className="order-item__right">
                    {!hidePrice && (
                        <OrderItemLinePrice price={item.price} selectable={selectable} activeItem={activeItem} />
                    )}
                    {!!item.waitTime && <WaitTimeBadge subtle waitTime={item.waitTime} />}
                </div>
            )}
        </>
    );
};
