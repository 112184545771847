import { actionCreators } from "../reducers/foodPreferences";
import { AppDispatch } from "../..";
import * as accountApi from "../api/accountApi";
import { saveFoodPreferencesOperation } from "../operations";
import { TEMP_TAG_GROUP } from "../constants";
import { actionCreators as partyActions } from "../../order/reducers/party";

export function saveFoodPreferences(ids: string[], callback: () => void) {
    return saveFoodPreferencesOperation.getThunk(async (dispatch: AppDispatch) => {
        try {
            await accountApi.saveFoodPreferencesApi(ids);
            dispatch(actionCreators.setFoodPreferences(ids));

            const preferenceNames = ids
                .map((id) => {
                    const tagDetails = TEMP_TAG_GROUP.tags.find((tag) => tag.id === id);
                    return tagDetails?.displayName || "";
                })
                .filter(Boolean);

            dispatch(partyActions.trackCustomerFoodPreferenceSet(preferenceNames.toString()));

            callback();
        } catch (ex) {
            console.log("failed to save food preferences:", ex);
            throw ex;
        }
    });
}
