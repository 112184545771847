import { FeatureConfiguration } from "..";
import { RegionReducer } from "./reducers";
import { regionMiddleware } from "./middleware/regionMiddleware";

export * from "./types";
export * from "./reducers";
export * from "./regionHelper";

export default function (config: FeatureConfiguration) {
    config.reducers.region = RegionReducer;
    config.middleware.push(regionMiddleware());
}
