import { LocationMarkerClaim } from "../types";
import { getAuthHeaders } from "../../../common/auth";
import { orderApi } from "../../order/orderApi";
import { createPartyError } from "../util/createPartyError";

export const getPartyToken = async (
    locationMarkerClaims: LocationMarkerClaim[],
    existingToken?: string,
    startFlexTab?: boolean
) => {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    if (existingToken) {
        headers.append("X-Meandu-Party-Token", existingToken);
    }

    try {
        const response = await orderApi.send(startFlexTab ? "/party/start-flex-tab" : "/party/authenticate", {
            method: "POST",
            body: JSON.stringify(locationMarkerClaims),
            credentials: "include",
            headers,
        });

        const json = await response.json();

        if (response.ok && !json.errorCode) {
            return json;
        }

        throw createPartyError(json);
    } catch (err) {
        throw createPartyError(err || {});
    }
};
