import { orderApi } from "src/features/order/orderApi";
import { getTableTokenHeaders } from "src/features/partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";

export const fetchAnotherRound = async () => {
    const headers = getTableTokenHeaders();
    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/anotherround", {
        method: "GET",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return await response.json();
};
