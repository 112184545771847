import { FeatureConfiguration } from "..";
import { spinnerReducer } from "./reducers";
import { spinnerMiddleware } from "./middleware/spinnerMiddleware";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.spinner = spinnerReducer;
    config.middleware.push(spinnerMiddleware());
}
