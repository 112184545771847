import "./GroupTabScanQrCode.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qr } from "src/common/experience";
import { AppState } from "src/features";
import { actionCreators as qrActionCreators } from "../../partyOnboarding/reducers/qr";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { Spinner } from "../../Spinner";
import { BarcodeScanView, Button } from "src/sharedComponents";
import { NativeBackButton } from "src/common/navigation";
import { CSSTransition } from "react-transition-group";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { ModalContainer } from "src/common/modal";
import { Camera } from "src/sharedComponents/assets/icons";
import { getTabTypeName } from "src/features/groupTabs/selectors/activeGroupTab";
import { findQrCode } from "src/features/partyOnboarding/actions/findQrCode";
import { findingQrCodeOperation } from "src/features/partyOnboarding/operations";

let cancel: () => void = () => {};

export const GroupTabScanQrCcode = () => {
    const dispatch = useDispatch();
    const messageTimer = useRef<number | undefined>();
    const [showScanView, setShowScanView] = useState(false);
    const { cameraReady, scanError, cameraAccessDenied } = useSelector((state: AppState) => state.qr);
    const findQrCodeError = useSelector(findingQrCodeOperation.getError);
    const findQrCodeStatus = useSelector(findingQrCodeOperation.getStatus);
    const tabTypeName = useSelector(getTabTypeName);

    const showCameraAccessDenied = useCallback(async () => {
        const message = await qr.getCameraAccessDeniedMessage(BarcodeType.QRCODE);
        dispatch(showModalMessage(modalMessages.showQrCodeCameraAccessDenied(message)));
    }, [dispatch]);

    const onClick = useCallback(async () => {
        if (cameraAccessDenied) {
            await showCameraAccessDenied();
            return;
        }
        setShowScanView(true);
    }, [cameraAccessDenied, showCameraAccessDenied]);

    const startCamera = useCallback(() => {
        dispatch(findQrCode(new Promise((resolve) => (cancel = () => resolve(undefined)))));
    }, [dispatch]);

    const resetOperations = useCallback(() => {
        dispatch(findingQrCodeOperation.actionCreators.reset());
        dispatch(qrActionCreators.resetScanStatus());
    }, [dispatch]);

    useEffect(() => {
        if (findQrCodeError) {
            switch (findQrCodeError.errorCode) {
                case "FindQrCodeTimeout":
                case "QrCodeUnknownError":
                default:
                    const message =
                        findQrCodeError.errorCode === "FindQrCodeTimeout"
                            ? modalMessages.findQrCodeTimeout()
                            : modalMessages.showQrCodeUnknownError();
                    messageTimer.current = window.setTimeout(() => dispatch(showModalMessage(message)), 300);
                    break;
                case "QrCodeCameraAccessDenied":
                    showCameraAccessDenied();
                    break;
            }
        }
    }, [dispatch, findQrCodeError, showCameraAccessDenied]);

    useEffect(() => {
        return findQrCodeStatus === "processing"
            ? () => {
                  setShowScanView(false);
              }
            : undefined;
    }, [findQrCodeStatus]);

    useEffect(() => {
        return () => {
            clearTimeout(messageTimer.current);
        };
    }, []);

    useEffect(() => {
        if (cameraReady && showScanView) {
            document.body.classList.add("group-tab-scanning-qr-code");
            return () => {
                document.body.classList.remove("group-tab-scanning-qr-code");
            };
        }
        return undefined;
    }, [cameraReady, showScanView]);

    return (
        <>
            <Button
                leftIcon={<Camera />}
                value={`Join an existing ${tabTypeName}`}
                secondary
                mode="outline"
                onClick={onClick}
            />
            <ModalContainer
                isOpen={showScanView}
                overlayClassName="ReactModal__GroupTabScanQrCode"
                bodyOpenClassName="ReactModal__GroupTabScanQrCode"
                className={{
                    base: "group-tab-scan-qr-code",
                    afterOpen: "group-tab-scan-qr-code--after-open",
                    beforeClose: "group-tab-scan-qr-code--before-close",
                }}
                onAfterClose={resetOperations}
                onAfterOpen={startCamera}
                closeTimeoutMS={300}
                onRequestClose={cancel}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={true}
            >
                <NativeBackButton onPressed={cancel} name="group-tabs/scan-qr-code">
                    {qr.requiresTargetElement && <div id="reader" />}
                    {!cameraReady && <Spinner />}
                    <CSSTransition in={cameraReady} classNames="fade" timeout={200} unmountOnExit>
                        <div>
                            <BarcodeScanView
                                title="Scan the QR code."
                                description="Hold the camera over the tab owner’s QR code"
                                barcodeType={BarcodeType.QRCODE}
                                scanError={scanError}
                            />
                            <div className="group-tab-scan-qr-code__bottom">
                                <Button
                                    className="group-tab-scan-qr-code__bottom__button"
                                    mode="outline"
                                    onClick={cancel}
                                    value="Cancel"
                                />
                            </div>
                        </div>
                    </CSSTransition>
                </NativeBackButton>
            </ModalContainer>
        </>
    );
};
