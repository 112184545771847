import { ApplePayPaymentMethod } from "../../../../payment";
import { GetDevicePaymentMethodAvailableFunc } from "../../../interface";

export const getApplePayAvailable: GetDevicePaymentMethodAvailableFunc<ApplePayPaymentMethod> = async (
    { applePayMerchantId },
    trackEvent
) => {
    const { ApplePaySession } = window as any;
    const supportsVersion3 = ApplePaySession?.supportsVersion(3) ?? false;
    const canMakePayments = ApplePaySession?.canMakePayments() ?? false;
    const canMakePaymentsWithActiveCard =
        (await ApplePaySession?.canMakePaymentsWithActiveCard(applePayMerchantId)) ?? false;

    const available = supportsVersion3 && canMakePayments && canMakePaymentsWithActiveCard;

    if (!available) {
        trackEvent({
            type: "NOT_AVAILABLE",
            ApplePaySession: !!ApplePaySession,
            supportsVersion3,
            canMakePayments,
            canMakePaymentsWithActiveCard,
            applePayMerchantId,
        });
    }

    return available;
};
