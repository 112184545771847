import "../assets/PayPalCheckoutButton.scss";

import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { initializePayPalCheckoutButton } from "../actions/initializePayPalCheckoutButton";
import { PaymentButtonProps } from "../types";

export const PayPalCheckoutButton = ({ onPayment }: PaymentButtonProps) => {
    const dispatch = useDispatch();

    const onPaymentInternal = useRef(onPayment);

    useEffect(() => {
        onPaymentInternal.current = onPayment;
    }, [onPayment]);

    useEffect(() => {
        const payPalAbortController = new AbortController();

        dispatch(
            initializePayPalCheckoutButton(payPalAbortController.signal, (result) => {
                if (result) {
                    dispatch(onPaymentInternal.current(result));
                }
            })
        );

        return () => {
            payPalAbortController.abort();
        };
    }, [dispatch]);

    return <div id="paypal-button" />;
};
