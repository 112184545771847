import { reducer as navBarReducer } from "./reducers/navBar";
import { reducer as nativeBackButtonReducer } from "./reducers/nativeBackButton";
import { FeatureConfiguration } from "../../features";

export * from "./components/BackButton";
export * from "./components/NativeBackButton";
export { SimpleNavHeader } from "./components/SimpleNavHeader";
export { ProgressBar } from "./components/ProgressBar";
export * from "./reducers";
export * from "./selectors";

export default function (config: FeatureConfiguration) {
    config.reducers.navBar = navBarReducer;
    config.reducers.nativeBackButton = nativeBackButtonReducer;
}
