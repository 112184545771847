import * as React from "react";
import "./assets/NavBar.scss";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import { NavBarElement, NavBarElementWithTooltip, NavBarType } from "../types";
import { NavBarIcon } from "./NavBarIcon";
import { scrolling } from "src/common/experience";
import { Text, Tooltip } from "../../../sharedComponents";
import { ReadyMembersIndicator } from "../../../features/openTable/components/ReadyMembersIndicator";
import { capitaliseFirstLetter } from "src/common/formatter";
import { simplePlural } from "src/common/strings";

export interface Props {
    navBarType: NavBarType;
    activeType: (type: NavBarType) => void;
    isInMenuPage: boolean;
    goBack: () => void;
    inActiveGroupTab: boolean;
    showManageTabPrompt: boolean;
    onManageTabPromptDismiss: (shouldNavigate: boolean) => void;
    tabTypeName: string;
    hideTabs: boolean;
    isOpenTable: boolean;
    engageEnabled: boolean;
    showPayBadge: boolean;
    showOfferTooltip: boolean;
    onOfferTooltipDismiss: () => void;
    offerTooltipCount: number;
}

export interface State {
    nextNavBarType: NavBarType | null;
}

const getNavBarElements = (
    tabTypeName: string,
    hideTabs: boolean,
    isOpenTable: boolean,
    engageEnabled: boolean,
    showManageTabPrompt: boolean,
    showOfferTooltip: boolean,
    offerTooltipCount: number
): NavBarElement[] => {
    const menuElement = {
        type: NavBarType.MENU,
        text: "Menu",
    };
    const offersElement = {
        type: NavBarType.OFFERS,
        text: "Rewards",
        tooltipProps: {
            show: showOfferTooltip,
            text: `You have ${offerTooltipCount} offer${simplePlural(offerTooltipCount)} waiting for you!`,
        },
    };
    const tabElement = {
        type: NavBarType.GROUP_TABS,
        text: `${capitaliseFirstLetter(tabTypeName)}s`,
        tooltipProps: {
            show: showManageTabPrompt,
            text: "Manage your open tab and invite others to order with you.",
        },
    };
    const payElement = {
        type: NavBarType.PAY,
        text: "Pay",
    };
    const profileElement = {
        type: NavBarType.PROFILE,
        text: "Profile",
    };

    return [
        menuElement,
        engageEnabled && offersElement,
        !hideTabs && tabElement,
        isOpenTable && payElement,
        profileElement,
    ].filter(Boolean) as NavBarElement[];
};

export class NavBar extends React.Component<Props, State> {
    element: React.RefObject<any>;
    tooltipRef: React.RefObject<any>;
    cleanAnimationTimeout: number = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            nextNavBarType: null,
        };
        this.element = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { showManageTabPrompt, showOfferTooltip } = this.props;

        if (prevProps.showManageTabPrompt !== showManageTabPrompt || prevProps.showOfferTooltip !== showOfferTooltip) {
            if (showManageTabPrompt || showOfferTooltip) {
                this.addOutsideTooltipTouchListener();
            } else {
                this.removeOutsideTooltipTouchListener();
            }
        }
    }

    componentWillUnmount() {
        this.cleanAnimationTimeout && window.clearTimeout(this.cleanAnimationTimeout);
        this.removeOutsideTooltipTouchListener();
    }

    componentDidMount() {
        const { showManageTabPrompt, showOfferTooltip } = this.props;

        if (showManageTabPrompt || showOfferTooltip) {
            this.addOutsideTooltipTouchListener();
        }
    }

    addOutsideTooltipTouchListener = () => {
        const partyWrapper = document.querySelector<HTMLDivElement>(".party-wrapper");
        if (partyWrapper) {
            partyWrapper.addEventListener("click", this.handlePartyWrapperClicked);
        }
    };

    removeOutsideTooltipTouchListener = () => {
        const partyWrapper = document.querySelector<HTMLDivElement>(".party-wrapper");
        if (partyWrapper) {
            partyWrapper.removeEventListener("click", this.handlePartyWrapperClicked);
        }
    };

    handlePartyWrapperClicked = (e: MouseEvent) => {
        const { onManageTabPromptDismiss, onOfferTooltipDismiss, showManageTabPrompt, showOfferTooltip } = this.props;
        const isTooltipClick = e.target === this.tooltipRef.current;

        if (isTooltipClick) {
            e.preventDefault();
        }

        setTimeout(() => {
            if (showManageTabPrompt) {
                onManageTabPromptDismiss(isTooltipClick);
            }
            if (showOfferTooltip) {
                onOfferTooltipDismiss();
            }
        });
        this.removeOutsideTooltipTouchListener();
    };

    render() {
        const {
            showManageTabPrompt,
            tabTypeName,
            hideTabs,
            isOpenTable,
            engageEnabled,
            showOfferTooltip,
            offerTooltipCount,
        } = this.props;
        const navBarElements = getNavBarElements(
            tabTypeName,
            hideTabs,
            isOpenTable,
            engageEnabled,
            showManageTabPrompt,
            showOfferTooltip,
            offerTooltipCount
        );
        return (
            <>
                <div className="nav-bar-height">{isOpenTable && <ReadyMembersIndicator />}</div>
                <div className={classNames("nav-bar-height nav-bar top-shadow")} ref={this.element}>
                    <div className="nav-bar__elements">
                        {navBarElements.map((e) => {
                            if (
                                (e.type === NavBarType.GROUP_TABS && showManageTabPrompt) ||
                                (e.type === NavBarType.OFFERS && showOfferTooltip)
                            ) {
                                return this.renderElementWithTooltip(e as NavBarElementWithTooltip);
                            }
                            return this.renderElement(e);
                        })}
                    </div>
                </div>
            </>
        );
    }

    onTouchStart = (type: NavBarType) => {
        this.setState({ nextNavBarType: type });
    };

    onTouchEnd = (type: NavBarType) => {
        const { navBarType } = this.props;

        if (type === NavBarType.MENU && navBarType === NavBarType.MENU) {
            this.activeMenu();
        }

        this.setState({ nextNavBarType: null });
        this.cleanAnimationTimeout = window.setTimeout(this.onAnimationEnd, 200);
    };

    onClick = (type: NavBarType) => {
        const { activeType } = this.props;
        activeType(type);
    };

    activeMenu = () => {
        const { isInMenuPage, goBack } = this.props;
        const pagePosition = scrolling.scrollTop();
        if (isInMenuPage) {
            if (pagePosition !== 0) {
                scrolling.scrollTo({ top: 0, behavior: "smooth" });
            }
        } else {
            goBack();
        }
    };

    addIndicationBadge = (type: NavBarType) => {
        const { inActiveGroupTab, showPayBadge, showOfferTooltip } = this.props;
        switch (type) {
            case NavBarType.OFFERS:
                return showOfferTooltip && <span className="red-badge" />;
            case NavBarType.GROUP_TABS:
                return inActiveGroupTab && <span className="red-badge" />;
            case NavBarType.PAY:
                return showPayBadge && <span className="red-badge" />;
            default:
                return null;
        }
    };

    renderElementWithTooltip = (element: NavBarElementWithTooltip) => {
        return (
            <Tooltip
                key={`${element.type}-tooltip`}
                show={element.tooltipProps.show}
                showClose
                tooltipContent={<Text mode={["block"]} preset="g-14" value={element.tooltipProps.text} />}
                ref={this.tooltipRef}
                arrowXPosition="element-center"
            >
                {this.renderElement(element)}
            </Tooltip>
        );
    };

    renderElement = (element: NavBarElement) => {
        const { navBarType } = this.props;
        const { nextNavBarType } = this.state;
        const { type, text } = element;
        return (
            <div className="nav-bar__wrapper" key={type}>
                <TappableDiv
                    className={classNames(
                        "nav-bar__element",
                        type === (nextNavBarType ?? navBarType) && "active",
                        nextNavBarType !== null && "onTouch"
                    )}
                    key={type}
                    onTouchStart={() => this.onTouchStart(type)}
                    onTouchEnd={() => this.onTouchEnd(type)}
                    onClick={() => this.onClick(type)}
                >
                    <div className={classNames("nav-bar__element__icon", type === NavBarType.GROUP_TABS && "tab-icon")}>
                        {NavBarIcon(type)}
                        {this.addIndicationBadge(type)}
                    </div>
                    <span className="nav-bar__element__text">{text}</span>
                </TappableDiv>
                <div className="nav-bar__shield" />
            </div>
        );
    };

    onAnimationEnd = () => {
        this.cleanAnimationTimeout && window.clearTimeout(this.cleanAnimationTimeout);
        this.element.current!.classList.remove("nav-bar-animation");
    };
}
