import React from "react";
import classNames from "classnames";
import { Text } from "../../../sharedComponents";

interface Props {
    className?: string;
}

export const DateSeparator = ({ className }: Props) => (
    <Text
        className={classNames("payment-method__add-card__date-separator", className)}
        preset="g-18"
        mode="bold"
        value="/"
    />
);
