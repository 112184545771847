import { orderApi } from ".";
import { actionCreators, PartyAction, TypeKeys } from "../reducers/party";
import { MemberActivity, OrderApiProxy, Party, PartyType } from "..";
import { getCurrentMemberInParty, getIsSectionTakeaway, getLatestOrderReadyTime, getParty } from "../selectors";
import { AppDispatch, AppState } from "../..";
import { updateMenuData } from "../../menudata/actions/update";
import { decompressParty } from "./util/decompressor";
import debounce from "debounce-promise";
import { getEasyReorderItems } from "../../easyReorderItem/actions/getEasyReorderItems";
import { fetchPartyMealHistoryDataAwaitable } from "../../orderHistoryData/actions/fetchMealHistory";
import { actionCreators as historyActionCreators } from "../../orderHistoryData/reducers/mealHistory";
import { partyCompleted } from "../actions/partyCompleted";
import { cleanPartyClosed, partyClosed } from "../actions/partyClosed";
import { fetchActions } from "../../menudata";
import { updatePrices } from "../../menudata/mutators";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { getMenuData } from "../../menu/selectors";
import { actionCreators as joinTabActionCreators } from "../../groupTabs/reducers/joinGroupTab";
import { actionCreators as newTabActionCreators } from "../../groupTabs/reducers/groupTabWizard";
import { actionCreators as cartActions, actionCreators as cartActionCreators } from "../../order/reducers/cart";
import { fetchSurveyDetails } from "../../survey/actions/fetchSurveyDetails";
import { PaymentStatus } from "../../payment";
import { showFeedback } from "src/features/payOnly/actions/showFeedback";
import { actionCreators as takeawayActionCreators } from "src/features/takeaway/reducers/takeawayOptions";
import { hasScheduledTimeExpired } from "src/features/takeaway/actions/hasScheduledTimeExpired";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getIsOpenTableOrderingDisabled, getOpenTableMemberActivity } from "src/features/openTable/selectors";
import { actionCreators as pendingItemAction } from "../../order/reducers/pendingItem";
import { getNavBarStatus } from "src/common/navigation";
import { NavBarType } from "src/common/navigation/types";
import { getPayOnlyPartyFullyPaidAndClosed } from "../../payOnly/selectors";
import { deleteMemberActivityInfo } from "../../partyOnboarding/persistence/memberActivity";
import { openTableOrderStateWizard } from "../../openTable/wizards";
import { showPayOnlyPartyClosedMessage } from "src/features/payOnly/actions/showPayOnlyPartyClosedMessage";
import { actionCreators as openTableActionCreators } from "../../openTable";
import { getIsPayingMember } from "src/features/payment/selectors/party";
import { getCurrentMembershipLevelId } from "src/features/membership/selectors/getConnectedMembershipState";
import { setMembershipSignupPromotion } from "src/features/membership/actions/setMembershipSignupPromotion";

const showMealHistory = (partyId: string) => (dispatch: AppDispatch) => {
    dispatch(historyActionCreators.selectPartyMealHistory(partyId, true));
};

export const proxy: OrderApiProxy = (getState: () => AppState, next: AppDispatch) => {
    async function onPaidAction(party: Party) {
        // Warm up order history modal by pre-selecting party ID
        next(cartActions.showCart(false));
        next(historyActionCreators.selectPartyMealHistory(party.id, false));

        await Promise.allSettled([
            fetchPartyMealHistoryDataAwaitable(next, getState, party.id),
            fetchSurveyDetails(next, party.restaurantId, party.id),
        ]);

        next(joinTabActionCreators.resetJoinGroupTab());
        next(newTabActionCreators.completeGroupTabWizard());
        next(setMembershipSignupPromotion());
        next(partyCompleted(party));

        setTimeout(() => {
            next(showMealHistory(party.id));
            setTimeout(() => {
                next(actionCreators.closed(party));
                next(cleanPartyClosed());
            }, 500); // Give order history modal time to show before closing party
        }, 500); // Give order complete modal time to show before showing order history
    }

    orderApi.subscribe((connection) => {
        connection.on("partyUpdated", (partyUpdate: Party | string, timestamp: number) => {
            const party = typeof partyUpdate === "string" ? decompressParty(partyUpdate) : partyUpdate;
            const isPayOnly = party.type === PartyType.PAYONLY;
            const isOpenTable = party.type === PartyType.MULTIUSER;

            // Don't do anything if we get a party update while the venue is closing.
            // We'll get a partyClosed message soon after this anyway.
            if (!party.serviceId && !isPayOnly && !isOpenTable) return;

            const state = getState();
            const previousParty = getParty(state);
            const previousIsOpenTableOrderingDisabled = getIsOpenTableOrderingDisabled(state);

            next(actionCreators.updated(party, timestamp));

            if (previousParty && !isPayOnly) {
                const state = getState();
                const menuData = getMenuData(state)!;
                const isTakeaway = getIsSectionTakeaway(state);
                const latestOrderReadyTime = getLatestOrderReadyTime(state);
                const isOpenTableOrderingDisabled = getIsOpenTableOrderingDisabled(state);
                const memberActivity = getOpenTableMemberActivity(state);
                const navBarStatus = getNavBarStatus(state);
                const openTableOrderWizardState = openTableOrderStateWizard.getState(state);
                const currentMemberInParty = getCurrentMemberInParty(state);

                if (
                    isOpenTableOrderingDisabled &&
                    !previousIsOpenTableOrderingDisabled &&
                    navBarStatus !== NavBarType.PROFILE &&
                    memberActivity !== MemberActivity.Paying &&
                    !openTableOrderWizardState &&
                    currentMemberInParty
                ) {
                    next(cartActionCreators.showCart(false));
                    next(pendingItemAction.resetPendingItem());
                    next(openTableActionCreators.setActivity(MemberActivity.Paying, true));
                    next(showModalMessage(modalMessages.openTableOrderingOffline()));
                }

                if (isTakeaway && latestOrderReadyTime && party.activeOrder.dateScheduled) {
                    next(hasScheduledTimeExpired(latestOrderReadyTime, party.activeOrder.dateScheduled));
                }

                if (previousParty.serviceId !== party.serviceId) {
                    const membershipLevelId = getCurrentMembershipLevelId(state, party);

                    if (isTakeaway) {
                        next(takeawayActionCreators.showServiceChange(false));
                    }
                    next(getEasyReorderItems(party.id));
                    next(fetchActions.updated(updatePrices(menuData, party.serviceId, membershipLevelId)));
                    next(fetchActions.updateActiveServiceId(party.serviceId));
                    return;
                }

                const memberId = getCurrentMemberId(state);
                const prevPartyMember = previousParty.members.find((pm) => pm.memberId === memberId);
                const partyMember = party.members.find((pm) => pm.memberId === memberId);

                if (
                    partyMember &&
                    prevPartyMember &&
                    partyMember.membershipLevelId !== prevPartyMember.membershipLevelId
                ) {
                    next(fetchActions.updated(updatePrices(menuData, party.serviceId, partyMember.membershipLevelId)));
                }

                if (previousParty.packageId !== party.packageId) {
                    next(fetchActions.updated());
                }
            }
        });

        connection.on("partyClosed", async (partyUpdate: Party | string) => {
            const party = typeof partyUpdate === "string" ? decompressParty(partyUpdate) : partyUpdate;
            const state = getState();
            const activeParty = getParty(state);

            if (activeParty && activeParty.id !== party.id) {
                return;
            }

            // Stop listening to events for this connection
            // When we join a new party we'll re-register the listeners
            connection.off("partyUpdated");
            connection.off("partyClosed");

            switch (party.type) {
                case PartyType.MULTIUSER:
                case PartyType.PAYONLY:
                    next(actionCreators.closed(party));
                    const state = getState();
                    const partyFullyPaidAndClosed = getPayOnlyPartyFullyPaidAndClosed(state);
                    if (partyFullyPaidAndClosed) {
                        // If the bill is paid then it doesn't matter how the party closed
                        switch (party.type) {
                            case PartyType.MULTIUSER:
                                deleteMemberActivityInfo();
                                await fetchSurveyDetails(next, party.restaurantId, party.id);
                                return;
                            case PartyType.PAYONLY:
                                if (getIsPayingMember(state)) {
                                    next(showFeedback());
                                }
                                return;
                        }
                    }
                    next(cleanPartyClosed());
                    next(showPayOnlyPartyClosedMessage());
                    return;
            }

            // For pay on submit users paying with Apple/Google Pay payment user details are changed server-side to a
            // temporary user so the current user is no longer in the includedMembers but any complete payments for
            // single users should be treated as payment confirmed
            const hasPaid = party.payments?.some((p) => p.status === PaymentStatus.COMPLETE);
            if (hasPaid) {
                await onPaidAction(party);
            } else {
                next(partyClosed(party));
            }
        });

        connection.on("menuDataUpdated", async (partyUpdate: Party | string) => {
            const party = typeof partyUpdate === "string" ? decompressParty(partyUpdate) : partyUpdate;
            await updateMenuData(next, getState, true, party.menuDataUpdateUrl);
        });
    });

    return async (action: PartyAction) => {
        if (action.type === TypeKeys.REMOVE_ORDER_ITEM_BEGIN) {
            let {
                party: { activeParty: party, updatedTimestamp },
            } = getState();

            if (!party) return;

            try {
                party = await orderApi.invoke<Party>("removeOrderItem", party.id, action.orderItemId);
                if (!party) return;
                next(actionCreators.updated(party, updatedTimestamp + 1));
            } catch (e) {
                next(actionCreators.removeFromOrderFailure(e));
            }
        }

        if (action.type === TypeKeys.LEAVE_PARTY_BEGIN) {
            const { party } = getState();

            if (!party.activeParty) {
                return;
            }

            const partyId = party.activeParty.id;

            try {
                await orderApi.invoke("leaveParty");

                next(actionCreators.leftParty(partyId));
                next(cleanPartyClosed());
            } catch (e) {
                next(actionCreators.leavePartyFailure(e));
            }
        }

        if (action.type === TypeKeys.VIEW_NOTIFICATION_BEGIN) {
            const state = getState();

            const {
                party: { activeParty: party },
            } = state;

            if (!party) return;

            const memberId = getCurrentMemberId(state);
            const notificationIds = party.notifications
                .filter((n) => action.notificationIds.indexOf(n.id) !== -1 && n.viewedMembers.indexOf(memberId) === -1)
                .map((n) => n.id);

            if (!notificationIds.length) return;

            await debouncedSetPartyNotificationsViewed(party.id, notificationIds, next);
        }

        if (action.type === TypeKeys.SELECT_NOTIFICATION_CHOICE_BEGIN) {
            const { party } = getState();

            if (!party.activeParty) {
                return;
            }

            try {
                await orderApi.invoke(
                    "selectPartyNotificationChoice",
                    party.activeParty.id,
                    action.notificationId,
                    action.choice
                );
            } catch (e) {
                next(actionCreators.selectNotificationChoiceFailure(e));
            }
        }
    };
};

const setPartyNotificationsViewed = async (partyId: string | any[], notificationIds: string[], next: AppDispatch) => {
    const actualPartyId = Array.isArray(partyId) ? partyId[0][0] : partyId;
    const actualNotificationIds = Array.isArray(partyId)
        ? partyId.reduce((res, n) => res.concat(n[1]), [])
        : notificationIds;
    const actualNext = Array.isArray(partyId) ? partyId[0][2] : next;

    try {
        await orderApi.invoke("setPartyNotificationViewed", actualPartyId, actualNotificationIds);
    } catch (e) {
        actualNext(actionCreators.viewNotificationFailure(e));
    }

    // To satisfy debounce-promise which expects the same number of values to be returned if accumulating
    return Promise.all((Array.isArray(partyId) ? partyId : [partyId]).map((p) => p));
};

const debouncedSetPartyNotificationsViewed = debounce(setPartyNotificationsViewed, 1000, { accumulate: true });
