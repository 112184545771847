import "./ReadyMembersIndicator.scss";

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getShowReadyMembersIndicator } from "../selectors/memberStatus";
import { Text } from "../../../sharedComponents";
import { getActiveOrder, getPartyMembers } from "../../order/selectors";

export const ReadyMembersIndicator = () => {
    const showReadyMembersIndicator = useSelector(getShowReadyMembersIndicator);
    const numAcceptedMembers = useSelector(getActiveOrder)?.acceptedMembers.length || 1; // default to 1 to prevent showing zero people ready before hiding
    const numPartyMembers = useSelector(getPartyMembers)?.length;

    useEffect(() => {
        if (showReadyMembersIndicator) {
            document.body.classList.add("with-ready-indicator");
        }

        return () => {
            document.body.classList.remove("with-ready-indicator");
        };
    }, [showReadyMembersIndicator]);

    return (
        <div className={classNames("ready-members-indicator", showReadyMembersIndicator && "active")}>
            <Text preset="g-14" mode="medium">
                {numAcceptedMembers}/{numPartyMembers} people ready
            </Text>
        </div>
    );
};
