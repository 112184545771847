import { connect } from "react-redux";
import { PaymentWizard, Props } from "../components/PaymentWizard";
import { AppDispatch, AppState } from "../..";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { paymentFlowActions } from "../";
import { signInAction } from "src/features/signup/actions/signInAction";
import { getIsAnonymous, getIsUnverified } from "../../accounts/selectors";
import { PaymentFlowStateUpdate } from "../reducers/paymentFlow";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { confirmOrder } from "../../order/actions/confirmOrder";
import { signInActions } from "../../signup";
import { getPaymentMethods } from "../actions/getPaymentMethods";
import { getIsSectionTakeaway } from "src/features/order/selectors";
import { getServiceChanging } from "src/features/service/components/ServiceChangeModal";
import { getPaymentFlowState } from "../selectors";
import { getPhone } from "../../signup/selectors";

export function mapStateToProps(state: AppState): DataProps<Props> {
    const {
        paymentFlow: { showWizard, page, edit, direction, startPage, isOpeningTab },
    } = state;
    const isAnonymous = getIsAnonymous(state);
    const isUnverified = getIsUnverified(state);
    const isTakeaway = getIsSectionTakeaway(state);
    const isServiceChanging = getServiceChanging(state);
    const phone = getPhone(state);

    return {
        showWizard,
        page,
        edit,
        isOpeningTab,
        isAnonymous,
        isUnverified,
        direction,
        startPage,
        phone,
        isTakeaway,
        isServiceChanging,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    const getPaymentMethodsInternal = (onSuccess: () => void) => dispatch(getPaymentMethods(onSuccess));

    return {
        next: (nextWizardState?: PaymentFlowStateUpdate) =>
            dispatch(paymentFlowActions.nextPaymentPage(nextWizardState)),
        prev: (nextWizardState?: PaymentFlowStateUpdate) =>
            dispatch(paymentFlowActions.prevPaymentPage(nextWizardState)),
        close: () => dispatch(paymentFlowActions.completePaymentFlow()),
        doneEditing: () => dispatch(paymentFlowActions.doneEditing()),
        signup: () => dispatch(signInAction()),
        startOpenTabVerification: async (isAnonymous: boolean) => {
            await waitForKeyboardClose();
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    requireVerification: true,
                    isMidWizard: true,
                    slideAnimation: "slide-from-right",
                    onComplete: () => {
                        const isVerifyOnly = !isAnonymous;
                        const next = () =>
                            dispatch(paymentFlowActions.nextPaymentPage({ page: "CreateTab", isOpeningTab: true }));

                        if (isVerifyOnly) {
                            next();
                        } else {
                            getPaymentMethodsInternal(next);
                        }
                    },
                    getSignInComplete: (state) => getPaymentFlowState(state).page === "CreateTab",
                })
            );
        },
        clearPhone: () => dispatch(signInActions.completeSignInFlow()),
        relaunchPaymentFlow: () => {
            dispatch(signInActions.completeSignInFlow());
            dispatch(confirmOrder());
        },
        onEnterPhone: () => dispatch(paymentFlowActions.nextPaymentPage({ page: "Verify" })),
    };
}

export const PaymentWizardContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentWizard);
