import "../assets/OrderHistoryDetails.scss";

import React, { Fragment } from "react";
import { OrderHistoryItemList } from "./OrderHistoryItemList";
import { Divider } from "../../../sharedComponents";
import { OrderPayments, OrderPaymentsProps } from "./OrderPayments";
import { OrderTotalSummary, OrderTotalSummaryProps } from "./OrderTotalSummary";
import { useSelector } from "react-redux";
import {
    getHistoryOrderPayments,
    getHistoryOrderTotalSummary,
    getSelectedOrderHistoryData,
} from "../../accounts/selectors";
import { OrderHistoryData } from "../../orderHistoryData";
import { getSelectedTabHistoryData } from "../../groupTabsHistory/selectors/groupTabHistorySelector";
import { getOrderPaymentsFromOrderData, getOrderTotalSummaryFromOrderData, getShowOrderTotalSummary } from "../helpers";
import { getOrders } from "../../groupTabs/selectors/activeGroupTab";
import { InfoSection } from "./InfoSection";
import { config } from "../../../common/config";
import classNames from "classnames";
import { PartyType } from "../../order/types";

export const RegularOrderHistoryDetails = () => {
    const orderHistoryItem = useSelector(getSelectedOrderHistoryData);
    const orderTotalSummary = useSelector(getHistoryOrderTotalSummary);
    const orderPayments = useSelector(getHistoryOrderPayments);

    return (
        <OrderHistoryDetails
            orderHistoryItem={orderHistoryItem}
            orderTotalSummary={orderTotalSummary}
            orderPayments={orderPayments}
            baseClassName="order-history-details"
            title="Order"
        />
    );
};

interface TabOrderHistoryDetailsProps {
    partyId: string;
}

export const TabOrderHistoryDetails = ({ partyId }: TabOrderHistoryDetailsProps) => {
    const tabHistoryData = useSelector(getSelectedTabHistoryData);
    const orderHistoryItem = tabHistoryData?.details?.ordersItems?.[partyId].orderData || null;
    const orderTotalSummary = getOrderTotalSummaryFromOrderData(orderHistoryItem);
    const orderPayments = getOrderPaymentsFromOrderData(orderHistoryItem);
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    return (
        <>
            <OrderHistoryDetails
                orderHistoryItem={orderHistoryItem}
                orderTotalSummary={orderTotalSummary}
                orderPayments={orderPayments}
                baseClassName={classNames(
                    orderHistoryUpdateEnabled && "tab-order-history-details__no-gutter",
                    "tab-order-history-details"
                )}
                isTabOrder
            />
        </>
    );
};

interface ActiveTabOrderHistoryDetailsProps {
    partyId: string;
}

export const ActiveTabOrderHistoryDetails = ({ partyId }: ActiveTabOrderHistoryDetailsProps) => {
    const activeTabOrders = useSelector(getOrders);
    const orderHistoryItem = activeTabOrders?.[partyId].orderData || null;
    const orderTotalSummary = getOrderTotalSummaryFromOrderData(orderHistoryItem);
    const orderPayments = getOrderPaymentsFromOrderData(orderHistoryItem);
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    return (
        <>
            <OrderHistoryDetails
                orderHistoryItem={orderHistoryItem}
                orderTotalSummary={orderTotalSummary}
                orderPayments={orderPayments}
                baseClassName={classNames(
                    orderHistoryUpdateEnabled && "tab-order-history-details__no-gutter",
                    "tab-order-history-details"
                )}
                isTabOrder
            />
        </>
    );
};

interface OrderHistoryDetailsProps {
    orderHistoryItem: OrderHistoryData | null;
    orderTotalSummary?: OrderTotalSummaryProps | null;
    orderPayments?: OrderPaymentsProps[] | null;
    baseClassName: string;
    isTabOrder?: boolean;
    title?: string;
}

export const OrderHistoryDetails = ({
    orderHistoryItem,
    orderTotalSummary,
    orderPayments,
    baseClassName,
    isTabOrder,
    title,
}: OrderHistoryDetailsProps) => {
    if (!orderHistoryItem?.summary) return null;

    const { orderDetail } = orderHistoryItem.details || {};

    const showOrderTotalSummary = getShowOrderTotalSummary(orderTotalSummary);
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";
    const isOpenTableOrder = orderHistoryItem.partyType === PartyType.MULTIUSER;

    return (
        <>
            <InfoSection
                title={orderHistoryUpdateEnabled ? title : "Order"}
                className="history-item__section history-item__section--fade-in"
                hideChildrenDivider
                update
                showHeader={orderHistoryUpdateEnabled ? !isTabOrder : true}
            >
                <div className={baseClassName}>
                    {orderDetail && <OrderHistoryItemList items={orderDetail} />}
                    {orderHistoryUpdateEnabled && orderHistoryItem.details && showOrderTotalSummary && (
                        <div className={`${baseClassName}__section`}>
                            <Divider fullWidth />
                            <OrderTotalSummary
                                {...orderTotalSummary}
                                isTabOrder={isTabOrder}
                                showTotal={!orderHistoryUpdateEnabled || isOpenTableOrder}
                            />
                            {isTabOrder && <Divider fullWidth />}
                        </div>
                    )}
                    {!orderHistoryUpdateEnabled && (
                        <div className={`${baseClassName}__section`}>
                            <Divider fullWidth />
                            {orderHistoryItem.details && showOrderTotalSummary && (
                                <OrderTotalSummary {...orderTotalSummary} isTabOrder={isTabOrder} showTotal={true} />
                            )}
                        </div>
                    )}
                    {orderHistoryUpdateEnabled && !isTabOrder && !isOpenTableOrder && !!orderPayments?.length && (
                        <div className={classNames(!showOrderTotalSummary && `${baseClassName}__section__top-padding`)}>
                            <Divider fullWidth />
                            {orderPayments.map((orderPayment, i) => (
                                <Fragment key={i}>
                                    {i > 0 && <Divider fullWidth />}
                                    <OrderPayments noTopPadding={i === 0} {...orderPayment} />
                                </Fragment>
                            ))}
                        </div>
                    )}
                </div>
            </InfoSection>
            {(!orderHistoryUpdateEnabled || isOpenTableOrder) && !!orderPayments?.length && (
                <InfoSection
                    title="Payments"
                    className="history-item__section history-item__section--fade-in"
                    hideChildrenDivider
                    update
                >
                    <div className={baseClassName}>
                        {orderPayments?.map((orderPayment, i) => (
                            <Fragment key={i}>
                                {i > 0 && <Divider fullWidth />}
                                <OrderPayments noTopPadding={i === 0} {...orderPayment} />
                            </Fragment>
                        ))}
                    </div>
                </InfoSection>
            )}
        </>
    );
};
