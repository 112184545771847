import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { actionCreators as paymentActionCreators } from "../../payment";
import { useDispatch, useSelector } from "react-redux";
import { getAddCardFormActive } from "../../payment/selectors";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { CardPaymentMethod, NewCardPaymentMethod, SelectablePaymentMethod } from "src/common/payment";
import { waitForKeyboardClose } from "src/common/keyboard";
import { SelectPaymentMethodActions } from "../types";
import { AddCardBehavior } from "../../paymentGateways/types";
import { AddCardForm } from "./AddCardForm";
import { SelectPaymentMethodAction } from "./SelectPaymentMethodAction";
import { hostedFieldsActionCreators } from "../reducers";
import { getIsConnected } from "src/features/order/selectors";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";
import { getAddCardBehavior } from "src/features/paymentGateways";

interface Props<TResultFunc, TBehaviorFunc> {
    paymentMethod: CardPaymentMethod | NewCardPaymentMethod;
    getRequiredBehavior: (addCardBehavior: AddCardBehavior) => TBehaviorFunc | undefined;
    getResultFunc: (behaviorFunc: TBehaviorFunc) => Promise<TResultFunc | undefined>;
    getPaymentMethod: (
        resultFunc: TResultFunc,
        resetForm: (() => void) | undefined,
        remember: boolean,
        disableVaultCard: boolean | undefined
    ) => Promise<SelectablePaymentMethod | null>;
    isValid: boolean;
    showVaultCard?: boolean;
    actions?: SelectPaymentMethodActions;
    select?: boolean;
}

export function AddCardFormPaymentMethodAction<TResultFunc, TBehaviorFunc>({
    paymentMethod,
    getRequiredBehavior,
    getResultFunc,
    getPaymentMethod,
    isValid,
    showVaultCard,
    actions,
    select,
}: Props<TResultFunc, TBehaviorFunc>) {
    const dispatch = useDispatch();

    const [tempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    const addCardFormActive = useSelector(getAddCardFormActive);
    const isConnected = useSelector(getIsConnected);

    const [renderAddCardForm, setRenderAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [hideAddCardForm, setHideAddCardForm] = useState(false);
    const [vaultCard, setVaultCard] = useState(true);

    const behaviorFunc = useRef<TBehaviorFunc>();
    const resultFunc = useRef<TResultFunc>();
    const unmounted = useRef(false);
    const remember = useRef(vaultCard);
    remember.current = vaultCard;
    const connected = useRef(isConnected);
    connected.current = isConnected;
    const getResult = useRef(getResultFunc);
    getResult.current = getResultFunc;

    const addCardBehavior = useMemo(() => getAddCardBehavior(paymentMethod), [paymentMethod]);

    const resetState = useCallback(() => {
        if (unmounted.current) return;
        setRenderAddCardForm(false);
        setShowAddCardForm(false);
        setHideAddCardForm(false);
        setVaultCard(true);
        resultFunc.current = undefined;
        dispatch(paymentActionCreators.setAddCardFormActive(false));
    }, [dispatch]);

    const onShow = useCallback(() => {
        dispatch(paymentActionCreators.setAddCardFormActive(true));
    }, [dispatch]);

    const onSelect = useCallback(async () => {
        await waitForKeyboardClose();
        return await getPaymentMethod(
            resultFunc.current!,
            addCardBehavior?.disableResetAddCardForm ? undefined : resetState,
            remember.current,
            addCardBehavior?.disableVaultCard
        );
    }, [getPaymentMethod, resetState, addCardBehavior]);

    const initAddCardForm = useCallback(() => {
        actions?.setGetPaymentMethod(onSelect);
        actions?.setIsValid(false);

        if (!connected.current) {
            dispatch(showModalMessage(modalMessages.initAddCardFormFailed()));
            return;
        }

        if (!addCardBehavior) return;
        behaviorFunc.current = getRequiredBehavior(addCardBehavior);
        if (!behaviorFunc.current) return;

        setRenderAddCardForm(true);
        dispatch(hostedFieldsActionCreators.clearState());
    }, [dispatch, addCardBehavior, getRequiredBehavior, actions, onSelect]);

    useEffect(() => {
        if (renderAddCardForm && behaviorFunc.current) {
            const behaviorFuncLocal = behaviorFunc.current;
            behaviorFunc.current = undefined;
            getResult.current(behaviorFuncLocal).then((func) => {
                if (!unmounted.current) {
                    if (!func) {
                        dispatch(showModalMessage(modalMessages.initAddCardFormFailed()));
                        setRenderAddCardForm(false);
                    } else {
                        resultFunc.current = func;
                        setShowAddCardForm(true);
                    }
                }
            });
        }
    }, [dispatch, renderAddCardForm]);

    useEffect(() => {
        return () => {
            dispatch(paymentActionCreators.setAddCardFormActive(false));
            unmounted.current = true;
        };
    }, [dispatch]);

    useEffect(() => {
        if (resultFunc.current || addCardBehavior?.showAddCardFormAutomatically) {
            actions?.setIsValid(isValid);
        }
    }, [actions, isValid, addCardBehavior]);

    useEffect(() => {
        if (addCardBehavior?.showAddCardFormAutomatically) return;
        if (tempSelectedPaymentMethod === paymentMethod && !addCardFormActive) {
            initAddCardForm();
        } else if (tempSelectedPaymentMethod !== paymentMethod && select && resultFunc.current) {
            waitForKeyboardClose().then(() => {
                if (resultFunc.current) {
                    setHideAddCardForm(true);
                }
            });
        }
    }, [paymentMethod, tempSelectedPaymentMethod, addCardFormActive, initAddCardForm, select, addCardBehavior]);

    useEffect(() => {
        if (addCardBehavior?.showAddCardFormAutomatically) {
            initAddCardForm();
        }
    }, [addCardBehavior, initAddCardForm]);

    return (
        <>
            {!addCardBehavior?.showAddCardFormAutomatically && (
                <SelectPaymentMethodAction paymentMethod={paymentMethod} rightIcon={!select ? null : undefined} />
            )}
            {renderAddCardForm && (
                <AddCardForm
                    paymentMethod={paymentMethod}
                    show={showAddCardForm}
                    hide={hideAddCardForm}
                    onShow={onShow}
                    onHide={resetState}
                    vaultCardController={
                        showVaultCard
                            ? {
                                  value: vaultCard,
                                  onChange: setVaultCard,
                              }
                            : undefined
                    }
                    onAdd={actions ? undefined : onSelect}
                />
            )}
        </>
    );
}
