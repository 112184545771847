import { AppDispatch } from "../../index";
import { saveOnboardingLoadingImages } from "../persistence/onboarding";
import { OnboardingAction, TypeKeys } from "../reducers/onboarding";
import { deleteOnboardingImages } from "src/features/partyOnboarding/persistence/onboarding";

export const onboardingMiddleware = () => () => (next: AppDispatch) => (action: OnboardingAction) => {
    next(action);

    if (action.type === TypeKeys.SET_LOADER_IMAGES) {
        saveOnboardingLoadingImages(action.backgroundImage, action.logoUrl);
    }

    if (action.type === TypeKeys.CLEAR_IMAGES) {
        deleteOnboardingImages();
    }
};
