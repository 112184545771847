import { OrderItem, OrderItemModifier, PendingItem } from "../../types";
import { ItemWithAvailablePrices, LocationMenuData, MenuItemModifier, MenuItemVariant } from "../../../menudata";
import { getSelectedModifierChoice } from "../../../menu/helpers";

export function iterateOrderItems(
    menuData: LocationMenuData,
    orderItem: OrderItem | PendingItem,
    itemCallback: (item: ItemWithAvailablePrices | undefined) => void,
    modifierCallback: (modifier: MenuItemModifier, orderItemModifier: OrderItemModifier) => void
) {
    const menuItem = orderItem?.itemId ? menuData?.items[orderItem.itemId] : undefined;
    if (!menuItem) {
        return;
    }

    if (orderItem.variant !== null) {
        if (menuItem.variants) {
            const selectedVariant: MenuItemVariant | undefined = getSelectedModifierChoice(
                menuItem.variants,
                orderItem.variant
            );
            itemCallback(selectedVariant);
        }
    } else {
        itemCallback(menuItem);
    }

    if (orderItem.modifiers && menuItem.modifiers) {
        for (let orderItemModifier of orderItem.modifiers) {
            const modifier = menuItem.modifiers[orderItemModifier.modifier];
            modifierCallback(modifier, orderItemModifier);
            orderItemModifier?.optionNestedModifiers?.forEach((nestedOption) => {
                nestedOption.modifiers?.forEach((nestedOptionModifier) => {
                    const modifierData = menuData.modifiers![nestedOptionModifier.modifierId];
                    for (let i = 0; i < nestedOption.quantity; i++) {
                        modifierCallback(modifierData, nestedOptionModifier);
                    }
                });
            });
        }
    }
}
