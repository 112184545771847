import "../assets/StampCardClaimPage.scss";
import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { getOfferData, getOfferDataStatus } from "../selectors";
import { NoOfferContainer } from "./NoOfferContainer";
import { OfferFooter } from "./OfferFooter";
import { isLightColor } from "src/common/shared";
import { OfferStatus } from "../types";
import { ButtonProps } from "src/sharedComponents/controls/button/Button";
import { ViewOfferModal } from "./ViewOfferModal";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { StampCardLandingContent } from "./StampCardLandingContent";

interface Props {
    onBack: () => void;
    onSuccess: () => void;
}

export const StampCardClaimPage = ({ onBack, onSuccess }: Props) => {
    const [openStampDetails, setOpenStampDetails] = useState(false);
    const offer = useSelector(getOfferData);
    const offerDataStatus = useSelector(getOfferDataStatus);
    const offerColor = offer?.offerColor ?? "";
    const textButtonColor = isLightColor(offerColor) ? "var(--color-text)" : "var(--color-text-inverse)";
    const textFields = useMemo(() => {
        switch (offerDataStatus) {
            case OfferStatus.CLAIMED:
            case OfferStatus.REDEEMED:
                return {
                    title: "Already claimed",
                    subtitle: "Check out your progress on this card so far:",
                };
            default:
                return {
                    title: "Your savings are waiting",
                    subtitle: "Get rewarded when you dine. Claim this card to earn stamps when you order through me&u.",
                };
        }
    }, [offerDataStatus]);

    const buttonProps: ButtonProps | undefined = useMemo(() => {
        switch (offerDataStatus) {
            case OfferStatus.AVAILABLE:
                return {
                    value: "Claim stamp card",
                };
            case OfferStatus.CLAIMED:
            case OfferStatus.REDEEMED:
                return {
                    value: "Done",
                    onClick: onBack,
                    mode: "outline",
                    unthemed: true,
                };
            default:
                return undefined;
        }
    }, [offerDataStatus, onBack]);

    const viewStampCardButtonProps: ButtonProps | undefined = useMemo(() => {
        switch (offerDataStatus) {
            case OfferStatus.AVAILABLE:
                return { value: "Claim stamp card" };
            case OfferStatus.CLAIMED:
            case OfferStatus.REDEEMED:
                return {
                    disabled: true,
                    value: "Stamp card claimed and ready to use",
                };
            default:
                return undefined;
        }
    }, [offerDataStatus]);

    const customThemeProps = useMemo(
        () => ({
            themeColor: offerColor,
            themeTextColor: textButtonColor,
            themedButtonTextColor: textButtonColor,
            className: "stamp-card-location-theme",
        }),
        [offerColor, textButtonColor]
    );

    if (!offer?.id || offerDataStatus === OfferStatus.EXPIRED) {
        return <NoOfferContainer />;
    }

    return (
        <LocationThemeContainer customTheme={customThemeProps}>
            <div className="stamp-card-claim-page">
                <ViewOfferModal
                    shouldOpen={openStampDetails}
                    onAfterViewOfferClose={() => setOpenStampDetails(false)}
                    allowOptIn
                    buttonProps={viewStampCardButtonProps}
                    onSuccess={() => {
                        onSuccess();
                        setOpenStampDetails(false);
                    }}
                    customThemeProps={customThemeProps}
                />
                <div className={classNames("scroll-element", "animated-child")}>
                    <SimpleNavHeader backgroundTransparent circleClose closeToBack onBack={onBack} />
                    <StampCardLandingContent
                        titleText={textFields.title}
                        subtitleText={textFields.subtitle}
                        onStampCardClick={() => setOpenStampDetails(true)}
                        offer={offer}
                    >
                        <OfferFooter
                            onSuccess={onSuccess}
                            buttonProps={buttonProps}
                            offer={offer}
                            allowOptIn={!openStampDetails}
                        />
                    </StampCardLandingContent>
                </div>
            </div>
        </LocationThemeContainer>
    );
};
