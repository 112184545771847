import { BannerType, BannerEvent, BannerNotification } from "../../sharedComponents/controls/banner";
import { __ } from "../../common/strings";

export const bannerMessages = {
    activeGroupTab(tabTypeName: string): BannerNotification {
        return {
            type: BannerType.DEFAULT,
            title: __("Ordering on {tabTypeName} menu", { tabTypeName }),
            event: BannerEvent.STATIC,
        };
    },
    demoMode(): BannerNotification {
        return {
            type: BannerType.ERROR,
            title: __("You are in demo mode"),
            event: BannerEvent.STATIC,
        };
    },
    onSomeoneElsesFlexTab(tabOwnerName: string): BannerNotification {
        return {
            type: BannerType.PRIMARY,
            title: __("You’re on {tabOwnerName}’s Tab", { tabOwnerName }),
            event: BannerEvent.STATIC,
        };
    },
};
