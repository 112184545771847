import { PaymentInfoVisitor } from "../../types";
import { fetchPaymentSessionToken } from "../../../payment/api/fetchPaymentSessionToken";
import { getBraintreeClientInstance } from "../../../../common/braintree";
import { getParty } from "../../../order";
import { NotRequired } from "../../../payment";

export const visitBraintreePaymentInfo: PaymentInfoVisitor = async (dispatch, getState, paymentInfo) => {
    try {
        if (!paymentInfo.additionalFraudProtectionData && paymentInfo.paymentGateway !== NotRequired) {
            const inParty = !!getParty(getState());
            const clientToken = await fetchPaymentSessionToken(dispatch, paymentInfo.paymentGateway, inParty);
            if (clientToken) {
                const { deviceData } = await getBraintreeClientInstance(clientToken);
                paymentInfo.additionalFraudProtectionData = deviceData;
            }
        }
    } catch {
        // Do nothing as failing to get deviceData shouldn't cause a payment failure
    }
};
