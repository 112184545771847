import { OrderHistoryData, OrderHistoryResponse } from "./types";
import { LoadingStatus } from "src/features/accountmenu";

export type OrderHistoryDataState = {
    orderHistoryData: OrderHistoryData[];
    selectedPartyOrderHistory: string | null;
    shouldOpen: boolean;
    loading: LoadingStatus;
    hasMore: boolean;
    displayedError: string[];
    selectedOrderHistoryData: OrderHistoryData | null;
};

export interface State {
    orderHistoryData: OrderHistoryDataState;
}

export enum TypeKeys {
    LOAD_MEAL_HISTORY = "HISTORYDATA/LOAD_MEAL_HISTORY",
    GET_MEAL_HISTORY_COMPLETE = "HISTORYDATA/GET_MEAL_HISTORY_COMPLETE",
    GET_PARTY_MEAL_HISTORY_DATA = "HISTORYDATA/GET_PARTY_MEAL_HISTORY_DATA",
    GET_PARTIES_MEAL_HISTORY_COMPLETE = "HISTORYDATA/GET_PARTIES_MEAL_HISTORY_COMPLETE",
    SELECT_PARTY_MEAL_HISTORY = "HISTORYDATA/SELECT_PARTY_MEAL_HISTORY",
    ADD_DISPLAYED_ERROR = "HISTORYDATA/ADD_DISPLAYED_ERROR",
}

export const actionCreators = {
    loadingMealHistory: (loading: LoadingStatus) => ({ type: TypeKeys.LOAD_MEAL_HISTORY, loading }),
    getMealHistoryComplete: (results: OrderHistoryResponse) => ({ type: TypeKeys.GET_MEAL_HISTORY_COMPLETE, results }),
    getPartyMealHistoryData: (result: OrderHistoryData) => ({ type: TypeKeys.GET_PARTY_MEAL_HISTORY_DATA, result }),
    getPartiesMealHistoryComplete: (results: OrderHistoryData[]) => ({
        type: TypeKeys.GET_PARTIES_MEAL_HISTORY_COMPLETE,
        results,
    }),
    selectPartyMealHistory: (partyId: string | null, shouldOpen: boolean | null) => ({
        type: TypeKeys.SELECT_PARTY_MEAL_HISTORY,
        partyId,
        shouldOpen,
    }),
    addDisplayedError: (partiesId: string[]) => ({ type: TypeKeys.ADD_DISPLAYED_ERROR, partiesId }),
};

export type GetMealHistoryComplete = {
    type: TypeKeys.GET_MEAL_HISTORY_COMPLETE;
    results: OrderHistoryResponse;
};

export type GetPartiesMealHistoryComplete = {
    type: TypeKeys.GET_PARTIES_MEAL_HISTORY_COMPLETE;
    results: OrderHistoryData[];
};

export type GetPartyMealHistoryData = {
    type: TypeKeys.GET_PARTY_MEAL_HISTORY_DATA;
    result: OrderHistoryData;
};
export type AddDisplayedError = {
    type: TypeKeys.ADD_DISPLAYED_ERROR;
    partiesId: string[];
};
export type LoadMealHistory = {
    type: TypeKeys.LOAD_MEAL_HISTORY;
    loading: LoadingStatus;
};

export type SelectPartyMealHistory = {
    type: TypeKeys.SELECT_PARTY_MEAL_HISTORY;
    partyId: string | null;
    shouldOpen: boolean | null;
};

export type MealHistoryActions =
    | GetMealHistoryComplete
    | GetPartiesMealHistoryComplete
    | GetPartyMealHistoryData
    | SelectPartyMealHistory
    | AddDisplayedError
    | LoadMealHistory;

const initialState: OrderHistoryDataState = {
    orderHistoryData: [],
    selectedPartyOrderHistory: null,
    shouldOpen: false,
    displayedError: [],
    hasMore: true,
    loading: "initial",
    selectedOrderHistoryData: null,
};

function mergeResultWithState(orderHistoryData: OrderHistoryData[], results: OrderHistoryData[]) {
    const newOrderHistoryData = [...results];
    const modifiedData = orderHistoryData.map((order) => {
        const updateItemIndex = newOrderHistoryData.findIndex((resOrder) => resOrder.partyId === order.partyId);
        return updateItemIndex > -1
            ? {
                  ...order,
                  ...newOrderHistoryData.splice(updateItemIndex, 1)[0],
              }
            : order;
    });
    if (newOrderHistoryData.length) {
        return newOrderHistoryData.concat(modifiedData).sort((m1, m2) => m2.posixServiceDate - m1.posixServiceDate);
    }
    return modifiedData;
}

export default function reducer(
    state: OrderHistoryDataState = initialState,
    action: MealHistoryActions
): OrderHistoryDataState {
    if (action.type === TypeKeys.LOAD_MEAL_HISTORY) {
        return {
            ...state,
            loading: action.loading,
        };
    }

    if (action.type === TypeKeys.GET_MEAL_HISTORY_COMPLETE) {
        return {
            ...state,
            loading: "success",
            hasMore: action.results.hasMore,
            orderHistoryData: mergeResultWithState(state.orderHistoryData, action.results.data),
        };
    }
    if (action.type === TypeKeys.GET_PARTIES_MEAL_HISTORY_COMPLETE) {
        return {
            ...state,
            orderHistoryData: action.results.length
                ? mergeResultWithState(state.orderHistoryData, action.results)
                : state.orderHistoryData,
        };
    }
    if (action.type === TypeKeys.GET_PARTY_MEAL_HISTORY_DATA) {
        let selectedOrderHistoryData = state.selectedOrderHistoryData;

        if (!selectedOrderHistoryData || selectedOrderHistoryData?.partyId === action.result.partyId) {
            selectedOrderHistoryData = action.result;
        }

        return {
            ...state,
            orderHistoryData: state.orderHistoryData.map((item) =>
                item.partyId === action.result.partyId ? action.result : item
            ),
            selectedOrderHistoryData,
        };
    }

    if (action.type === TypeKeys.SELECT_PARTY_MEAL_HISTORY) {
        const orderHistoryData = action.partyId
            ? state.orderHistoryData.find((item) => item.partyId === action.partyId)
            : null;

        return {
            ...state,
            selectedPartyOrderHistory: action.partyId !== null ? action.partyId : state.selectedPartyOrderHistory,
            shouldOpen: action.shouldOpen !== null ? action.shouldOpen : state.shouldOpen,
            selectedOrderHistoryData: orderHistoryData || null,
        };
    }

    if (action.type === TypeKeys.ADD_DISPLAYED_ERROR) {
        const displayedError = state.displayedError.concat(action.partiesId);
        return {
            ...state,
            displayedError,
        };
    }

    return state;
}
