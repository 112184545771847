import { AppDispatch, AppState } from "src/features";
import { actionCreators } from "../reducers/payOnlyFlags";
import { getPayOnlyBill } from "../selectors";

export const markBillAsPaid = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const bill = getPayOnlyBill(getState());

        if (!bill?.remainingBalance && bill?.total) {
            dispatch(actionCreators.setBillPaid());
        }
    };
};
