import React from "react";
import "./Divider.scss";
import classNames from "classnames";

type DividerSpace = "medium" | "small" | "none";

export interface DividerProps {
    small?: boolean;
    space?: DividerSpace;
    fullWidth?: boolean;
    dashed?: boolean;
    className?: string;
    extraHeight?: boolean;
    caption?: string;
    transparent?: boolean;
}

export const Divider = ({
    small,
    fullWidth,
    dashed,
    space,
    extraHeight,
    className,
    caption,
    transparent,
}: DividerProps) => (
    <div
        className={classNames(
            "divider",
            small && "small",
            space,
            fullWidth && "full-width",
            extraHeight && "extra-height",
            dashed && "dashed",
            transparent && "transparent",
            className
        )}
        data-caption={caption}
    />
);
