import { FilteringState } from "../reducers";
import { AppState } from "../..";
import { createSelector } from "reselect";
import { TAG_GROUP_TAG_ID } from "src/features/menu/selectors";
import { getServiceDietaryTags } from "src/features/order/selectors";

export const getFilteringState = (state: AppState): FilteringState => state.filtering;

export const getFilteringFoodPreference = createSelector(
    getFilteringState,
    (filterState) => filterState.preferences[TAG_GROUP_TAG_ID]
);

export const getFilteringFoodPreferenceWithName = createSelector(
    getFilteringState,
    getServiceDietaryTags,
    (filterState, tagGroups) =>
        filterState.preferences[TAG_GROUP_TAG_ID].map((tagId) => ({
            [tagId]: tagGroups?.find((tag) => tag.id === tagId)?.displayName ?? "[no-name]",
        }))
);
