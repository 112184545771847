import { AuthorizePaymentResult, PayPalCheckoutPaymentMethod } from "../../../common/payment";
import { getPayPalClient } from "../../../common/payment/payPal";

export type CreatePayPalOrderFunc = (data: any, actions: any) => Promise<any>;

export type GetPayPalAuthorizePaymentResultFunc = (data: any, actions: any) => Promise<AuthorizePaymentResult>;

export interface StandardPayPalBehavior {
    createOrder: CreatePayPalOrderFunc;
    getAuthorizePaymentResult: GetPayPalAuthorizePaymentResultFunc;
}

export interface CustomPayPalBehavior {
    onClick: () => Promise<AuthorizePaymentResult | null>;
}

export type PayPalBehavior = StandardPayPalBehavior | CustomPayPalBehavior;

export const initializePayPalButton =
    async (payPalCheckoutPaymentMethod: PayPalCheckoutPaymentMethod, signal: AbortSignal, behavior: PayPalBehavior) =>
    () =>
        new Promise<AuthorizePaymentResult | null>(async (resolve, reject) => {
            signal.addEventListener("abort", () => resolve(null));

            const paypal = await getPayPalClient(payPalCheckoutPaymentMethod);

            if (!document.getElementById("paypal-button")) {
                resolve(null);
                return;
            }

            await paypal
                .Buttons({
                    style: {
                        color: "gold",
                        shape: "rect",
                        height: 54,
                        label: "paypal",
                        tagline: false,
                    },

                    fundingSource: paypal.FUNDING.PAYPAL,

                    onInit: function (data: any, actions: any) {
                        if ("onClick" in behavior) {
                            actions.disable();
                        }
                    },

                    onClick: async function () {
                        if ("onClick" in behavior) {
                            const { onClick } = behavior as CustomPayPalBehavior;
                            try {
                                const result = await onClick();
                                if (result !== null) {
                                    // If we cancel then continue to wait in this Promise for the next click
                                    resolve(result);
                                }
                            } catch (err) {
                                reject(err);
                            }
                        }
                    },

                    createOrder: async function (data: any, actions: any) {
                        const { createOrder } = behavior as StandardPayPalBehavior;
                        return await createOrder(data, actions);
                    },

                    onApprove: async function (data: any, actions: any) {
                        const { getAuthorizePaymentResult } = behavior as StandardPayPalBehavior;
                        const result = await getAuthorizePaymentResult(data, actions);
                        resolve(result);
                    },

                    onCancel: function () {
                        // If we cancel then continue to wait in this Promise for the next click
                    },

                    onError: function (err: any) {
                        reject(err);
                    },
                })
                .render("#paypal-button");
        });
