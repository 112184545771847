import React, { useState, useEffect, useCallback, useMemo } from "react";
import { match, RouteComponentProps } from "react-router";
import classNames from "classnames";
import { SimpleNavHeader } from "../../../common/navigation";
import { MenuSearchButtonContainer } from "../../menuSearch/containers/MenuSearchButtonContainer";
import { useSelector, useDispatch } from "react-redux";
import { getMenuSwitcherActiveServiceMenus } from "../selectors";
import { MenuPageContainer } from "../containers/MenuPageContainer";
import { AppState } from "../..";
import { NavBarType } from "../../../common/navigation/types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { pagePositionActions } from "../../pagePosition";
import { getSelectedFiltersByCategory } from "../../filter";
import { DrinkGlassIcon, CutleryIcon } from "../../../sharedComponents/assets/icons";
import { HeaderTab } from "../../../common/navigation/components/HeaderTabs";
import { scrolling } from "../../../common/experience";
import { MenuTabNames } from "../../menudata";
import { getStaticBanners } from "../../banners";

import "../assets/MenuPageTabs.scss";

export interface OwnProps {
    match: match<MenuRouteParams>;
}

interface MenuRouteParams {
    menuId: string;
    categoryId?: string;
    menuItemId: string;
}

export const MenuPageTabs = (props: RouteComponentProps<MenuRouteParams>) => {
    const {
        params: { menuId, categoryId },
    } = props.match;
    const activeServiceMenus = useSelector(getMenuSwitcherActiveServiceMenus) || {};
    const navBarStatus = useSelector((state: AppState) => state.navBar.navBarStatus);
    const hasBanner = !!useSelector(getStaticBanners)?.length;

    const [activeMenuId, setActiveMenuId] = useState(menuId);
    const [animatingBetweenTabs, setAnimatingBetweenTabs] = useState(false);
    const [firstTabScrollPosition, setFirstTabScrollPosition] = useState(0);
    const [secondTabScrollPosition, setSecondTabScrollPosition] = useState(0);
    const [modalToggle, setModalToggle] = useState(false);

    const menuKeys = useMemo(() => Object.keys(activeServiceMenus), [activeServiceMenus]);
    const displayTabSwitcher = useMemo(() => {
        const isFoodAndDrinkMenus = menuKeys.every(
            (key) =>
                activeServiceMenus[key].displayName === MenuTabNames.DRINKS ||
                activeServiceMenus[key].displayName === MenuTabNames.FOOD
        );

        return isFoodAndDrinkMenus && menuKeys.length === 2;
    }, [activeServiceMenus, menuKeys]);
    const menuIds = useMemo(() => (displayTabSwitcher ? menuKeys : [menuId]), [menuId, menuKeys, displayTabSwitcher]);
    const selectedTabFilters = useSelector((state: AppState) =>
        getSelectedFiltersByCategory(state, { menuId: displayTabSwitcher ? activeMenuId : menuId })
    );

    let initialIndex = menuIds.indexOf(menuId);
    if (initialIndex < 0) {
        initialIndex = 0;
    }
    const [activeTabIndex, setActiveTabIndex] = useState(initialIndex);

    const initialActiveCategories = useMemo(
        () =>
            menuIds.reduce((categoryMap, currentMenuId) => {
                if (currentMenuId === menuId) {
                    categoryMap[currentMenuId] = categoryId;
                } else {
                    categoryMap[currentMenuId] = activeServiceMenus[currentMenuId].categories[0];
                }

                return categoryMap;
            }, {}),
        [activeServiceMenus, categoryId, menuId, menuIds]
    );
    const [tabActiveCategoryId, setTabActiveCategoryId] = useState(initialActiveCategories);

    const dispatch = useDispatch();
    const setAnimating = useCallback(
        (isAnimating: boolean) => {
            dispatch(pagePositionActions.animatingMenuPage(isAnimating));
        },
        [dispatch]
    );

    const handleFilter = useCallback(() => {
        setModalToggle(!modalToggle);
    }, [modalToggle]);

    const onChangeActiveTab = useCallback(
        (index: number) => {
            const scrollPosition = scrolling.scrollTop();

            if (index === 0) {
                setSecondTabScrollPosition(scrollPosition);
            } else {
                setFirstTabScrollPosition(scrollPosition);
            }

            const categoryTabs = document.querySelectorAll(".menu-page__items-header > div");
            if (categoryTabs) {
                const activeCategoryTab = Array.from(categoryTabs).find((node) =>
                    node.firstElementChild?.className.includes("mode-solid")
                );
                const categoryId = activeCategoryTab?.id.split("-")[1];

                if (categoryId) {
                    const tabActiveCategoryIds = { ...tabActiveCategoryId, [activeMenuId]: categoryId };
                    setTabActiveCategoryId(tabActiveCategoryIds);
                }
            }

            setActiveTabIndex(index);
            setAnimating(true);
            setAnimatingBetweenTabs(true);
        },
        [activeMenuId, tabActiveCategoryId, setAnimating]
    );

    useEffect(() => {
        setActiveMenuId(menuIds[activeTabIndex]);
    }, [activeTabIndex, menuIds]);

    useEffect(
        () => () => {
            setAnimating(true);
        },
        [setAnimating]
    );

    const headerTabs = useMemo(
        () =>
            menuIds.map((menuId) => ({
                icon:
                    activeServiceMenus[menuId]?.icon === "menu-food-dinner" ? (
                        <CutleryIcon />
                    ) : activeServiceMenus[menuId]?.icon === "menu-drink-glass" ? (
                        <DrinkGlassIcon />
                    ) : null,
                name: activeServiceMenus[menuId]?.displayName,
            })),
        [activeServiceMenus, menuIds]
    ) as HeaderTab[];

    const activeCategoryId = tabActiveCategoryId[activeMenuId];
    const categorySelectedFilters = selectedTabFilters[activeCategoryId];

    const activeMenu = activeServiceMenus[activeMenuId];
    if (!activeMenu) return null;

    const isActive = navBarStatus === NavBarType.MENU;
    const canOpenFilter = activeServiceMenus[activeMenuId]?.displayName === MenuTabNames.FOOD;

    return (
        <CSSTransition unmountOnExit in={isActive} classNames="party-wrapper-transition" timeout={150}>
            <div className={classNames("menu-page-tabs", hasBanner && "with-banner")}>
                <SimpleNavHeader
                    hasBackButton
                    withBorder
                    handleFilter={handleFilter}
                    selectedFilters={canOpenFilter ? categorySelectedFilters : undefined}
                    rightElement={<MenuSearchButtonContainer className="menu-page__search" />}
                    headerTabs={{
                        activeIndex: activeTabIndex,
                        onTabClick: onChangeActiveTab,
                        headerTabs,
                    }}
                />
                <TransitionGroup
                    className={classNames("menu-page-tabs__page-wrapper", activeTabIndex === 0 ? "POP" : "PUSH")}
                >
                    <CSSTransition
                        unnmountOnExit
                        classNames="slide"
                        timeout={250}
                        key={`${activeMenuId}-content`}
                        onExited={() => {
                            setAnimating(false);
                        }}
                        onEnter={() => {
                            const scrollToPosition =
                                activeTabIndex === 0 ? firstTabScrollPosition : secondTabScrollPosition;
                            scrolling.scrollTo({ top: scrollToPosition });
                        }}
                        onExit={(node: HTMLElement) => {
                            const nodeSection = node?.querySelector("section");
                            const scrollToPosition =
                                activeTabIndex === 0 ? secondTabScrollPosition : firstTabScrollPosition;
                            nodeSection?.scrollTo({ top: scrollToPosition || 0 });
                        }}
                    >
                        <MenuPageContainer
                            {...props}
                            menuId={activeMenuId}
                            categoryId={tabActiveCategoryId[activeMenuId]}
                            animatingBetweenTabs={animatingBetweenTabs}
                            modalToggle={modalToggle}
                            handleChangeTab={() => onChangeActiveTab(1 - activeTabIndex)}
                            showFooter={displayTabSwitcher}
                        />
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </CSSTransition>
    );
};
