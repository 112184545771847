import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getAuthHeaders } from "../../../common/auth";
import { EasyReorderItem } from "../../order";

export async function fetchEasyReorderItems(partyId: string) {
    const response = await orderApi.send(
        `/meal/easyreorder?partyId=${partyId}`,
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );
    if (!response.ok) {
        throw response;
    }
    const data = await response.json();
    return data as EasyReorderItem[];
}
