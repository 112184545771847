import "./WaitingForMembers.scss";

import { useDispatch } from "react-redux";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { motion, usePresence, AnimatePresence } from "framer-motion";
import { OpenTablePartyMember } from "src/features/order";
import { MemberIndicator } from "src/sharedComponents/controls/memberIndicator";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { Text } from "src/sharedComponents";
import classNames from "classnames";

interface Props {
    members: OpenTablePartyMember[];
    className: string;
}

interface FadeInOutProps {
    layout?: boolean;
    className: string;
    onClick?: () => void;
}

function FadeInOut({ layout, className, children, onClick }: PropsWithChildren<FadeInOutProps>) {
    const [isPresent, safeToRemove] = usePresence();
    const ref = useRef<HTMLDivElement>(null);

    return (
        <motion.div
            ref={ref}
            layout={layout}
            className={className}
            initial={{ opacity: 0 }}
            animate={isPresent ? "in" : "out"}
            variants={{ in: { opacity: 1 }, out: { opacity: 0 } }}
            style={
                !isPresent
                    ? {
                          position: "absolute",
                          left: ref.current?.getBoundingClientRect().left,
                      }
                    : undefined
            }
            onAnimationComplete={() => !isPresent && safeToRemove?.()}
            onClick={onClick}
        >
            {children}
        </motion.div>
    );
}

export function WaitingForMembers({ members, className }: Props) {
    const [cutLimit, setCutLimit] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const needsToCut = members.length > cutLimit;
    const membersToRender = needsToCut ? members.slice(0, cutLimit - 1) : members;

    const handleOnClickCounter = () => {
        dispatch(
            trackedEvent({
                type: "PARTY/WAITING_ROOM_CLICKED",
                button_clicked: "Waiting members counter",
            })
        );
    };

    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(() => {
            setCutLimit(Math.floor(((ref.current?.offsetWidth ?? 0) + 8) / 64));
        });

        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div ref={ref} className={classNames(className, "waiting-for-members")}>
            {cutLimit && (
                <AnimatePresence>
                    {membersToRender.map(({ memberId, firstName, initials }) => (
                        <FadeInOut layout key={memberId} className="waiting-for-members__member">
                            <MemberIndicator isLarge value={initials} />
                            <Text preset="g-12" mode="bold">
                                {firstName || "Guest"}
                            </Text>
                        </FadeInOut>
                    ))}
                    {needsToCut && (
                        <FadeInOut className="waiting-for-members__member-counter" onClick={handleOnClickCounter}>
                            <MemberIndicator isLarge value={`+${members.length - membersToRender.length}`} />
                        </FadeInOut>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
}
