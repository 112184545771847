import menuDataReducer from "./reducers";
import { FeatureConfiguration } from "..";

export * from "./reducers/types";

export { actions } from "./actions";
export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.menuData = menuDataReducer;
}
