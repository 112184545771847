import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { CookiePreferences } from "../../../common/experience/interface";

export enum TypeKeys {
    CHANGED = "COOKIE_PREFERENCES/CHANGED",
}

export const actionCreators = {
    changed: (cookiePreferences: CookiePreferences) => trackedEvent({ type: TypeKeys.CHANGED, ...cookiePreferences }),
};

export type CookiePreferencesChangedAction = { type: TypeKeys.CHANGED } & CookiePreferences;
