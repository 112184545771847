import React, { ReactNode } from "react";
import "./Switch.scss";
import { Text } from "../text";
import classNames from "classnames";
import { RequireOnlyOne } from "src/sharedComponents/types/utils";
import { TappableLabel } from "../../common/tappable";

interface PropsBase {
    id: string;
    checked: boolean;
    defaultChecked: boolean;
    onChange?: (checked: boolean) => void;
    className?: string;
    title?: ReactNode;
    text?: ReactNode;
    disabled?: boolean;
}

export type SwitchProps = RequireOnlyOne<PropsBase, "checked" | "defaultChecked">;

export const Switch = ({ id, className, onChange, title, text, defaultChecked, disabled, checked }: SwitchProps) => (
    <div className={classNames("switch-element", className)}>
        {(title || text) && (
            <div className="switch-element__message">
                {title && (
                    <Text preset="g-18" mode={["bold", "block"]} className="switch-element__title">
                        {title}
                    </Text>
                )}
                {text && (
                    <Text preset="g-14" mode="block">
                        {text}
                    </Text>
                )}
            </div>
        )}
        <div className="switch-element__switch">
            <input
                type="checkbox"
                defaultChecked={defaultChecked}
                id={id}
                disabled={disabled}
                checked={checked}
                onChange={onChange ? (event) => onChange(event.target.checked) : undefined}
            />
            <TappableLabel htmlFor={id} disabled={disabled} />
        </div>
    </div>
);
