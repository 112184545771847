const KEY = "MEANDU_LAST_GROUP_TAB";

let lastGroupTabId: string | null = null;

export function getLastGroupTabId(): string | null {
    try {
        return lastGroupTabId ?? window.localStorage.getItem(KEY);
    } catch (err) {
        return null;
    }
}

export function saveLastGroupTabId(groupTabId: string) {
    lastGroupTabId = groupTabId;
    window.localStorage.setItem(KEY, groupTabId);
}

export function deleteLastGroupTabId() {
    lastGroupTabId = null;
    window.localStorage.removeItem(KEY);
}
