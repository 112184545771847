import "../assets/ViewOfferPage.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OfferPageContent } from "./OfferPageContent";
import { ModalContainer } from "src/common/modal";
import { getOfferData, getOfferIemsSelectionIndex } from "../selectors";
import { OfferFooter } from "./OfferFooter";
import { ButtonProps } from "src/sharedComponents/controls/button/Button";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { LocationThemeProps } from "src/sharedComponents/common/theme";

interface Props {
    shouldOpen?: boolean;
    onAfterViewOfferClose?: () => void;
    onBack?: () => void;
    allowOptIn?: boolean;
    buttonProps?: ButtonProps;
    onSuccess?: () => void;
    customThemeProps?: LocationThemeProps;
}

export const ViewOfferModal = ({
    shouldOpen = true,
    onAfterViewOfferClose,
    onBack,
    allowOptIn,
    buttonProps,
    onSuccess,
    customThemeProps,
}: Props) => {
    const selectedOffer = useSelector(getOfferData);
    const itemsIndex = useSelector(getOfferIemsSelectionIndex);
    const [isOpen, setIsOpen] = useState(!!selectedOffer && shouldOpen);

    useEffect(() => setIsOpen(!!selectedOffer && shouldOpen), [selectedOffer, shouldOpen]);

    if (!selectedOffer) {
        return null;
    }

    return (
        <ModalContainer
            isOpen={!!selectedOffer && isOpen && itemsIndex === undefined}
            className={{
                base: "view-offer-modal",
                afterOpen: "view-offer-modal slide-in--after-open",
                beforeClose: "view-offer-modal--before-close slide-in--before-close",
            }}
            overlayClassName="slide-in-modal--overlay view-offer-modal--overlay"
            closeTimeoutMS={250}
            onAfterClose={onAfterViewOfferClose}
        >
            <LocationThemeContainer customTheme={customThemeProps}>
                <div className="view-offer-page">
                    <OfferPageContent
                        offer={selectedOffer}
                        onBack={() => {
                            onBack?.();
                            setIsOpen(false);
                        }}
                    >
                        <OfferFooter
                            offer={selectedOffer}
                            allowOptIn={allowOptIn}
                            buttonProps={buttonProps}
                            onSuccess={onSuccess}
                        />
                    </OfferPageContent>
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
