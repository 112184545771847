import { getApplePayAvailable } from "../common/getApplePayAvailable";
import { getGooglePayAvailable } from "../common/getGooglePayAvailable";
import { DevicePaymentBehavior } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod } from "src/common/payment";
import { initializeTyroApplePayButton } from "./initializeTyroApplePayButton";
import { initializeTyroGooglePayButton } from "./initializeTyroGooglePayButton";

export const applePayBehavior: DevicePaymentBehavior<ApplePayPaymentMethod> = {
    getAvailable: getApplePayAvailable,
    initializeButton: initializeTyroApplePayButton,
};

export const googlePayBehavior: DevicePaymentBehavior<GooglePayPaymentMethod> = {
    getAvailable: getGooglePayAvailable,
    initializeButton: initializeTyroGooglePayButton,
};
