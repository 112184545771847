import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { findPropertiesPlugin } from "../util/findAppInsightsPlugin";
import { config } from "src/common/config";

export class BuildInfoPlugin extends BaseTelemetryPlugin {
    public identifier = "MeanduBuildInfoPlugin";

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        const properties = findPropertiesPlugin(extensions);

        if (properties) {
            const application = properties.context.application;

            application.ver = config.REACT_APP_BUILD_NUMBER || "";
            application.build = config.REACT_APP_BUILD_COMMIT || "";
        }
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        this.processNext(event, itemCtx!);
    }
}
