import { createSelector } from "reselect";
import { getCurrentMemberId } from "../../accounts/selectors";
import { getStateData } from "./getMembershipState";
import { ConnectedMembershipState, MembershipState, MembershipStatus } from "../types/MembershipState";
import { AppState } from "src/features/index";
import { Party } from "src/features/order";

// These selectors need their own local copy of getParty as the inclusion order
// of selectors fails based on membership selectors now being used in middleware
const getParty = (state: AppState) => state.party.activeParty;

const getSpecifiedParty = (_: AppState, party: Party | null) => party;

export const getCurrentMembershipProgramId = createSelector(
    getCurrentMemberId,
    getParty,
    (memberId, party) => party?.members.find((m) => m.memberId === memberId)?.membershipProgramId
);

export const getCurrentMembershipApplyOnValidate = createSelector(
    getCurrentMemberId,
    getParty,
    (memberId, party) => party?.members.find((m) => m.memberId === memberId)?.membershipApplyOnValidate ?? false
);

export const getCurrentMembershipLevelId = createSelector(
    getCurrentMemberId,
    getSpecifiedParty,
    (memberId: string, party: Party | null) =>
        party?.members.find((m) => m.memberId === memberId)?.membershipLevelId ?? null
);

export const getConnectedMembershipState = createSelector(
    getStateData,
    getCurrentMembershipProgramId,
    (data: MembershipState[] | null, memberProgramId?: string | null): ConnectedMembershipState | null =>
        !data?.length || !memberProgramId
            ? null
            : (data.find(
                  (membershipState) =>
                      membershipState.status === MembershipStatus.CONNECTED &&
                      membershipState.programId === memberProgramId
              ) as ConnectedMembershipState)
);
