import React, { useRef, useEffect } from "react";
import { Chip } from "../../../sharedComponents/controls/chip";

interface Props {
    isActive: boolean;
    index: number;
    categoryId: string;
    displayName: string;
    onTabSelected?: (tabIndex: number) => void;
    noAnimation?: boolean;
}

export const TabButton = ({ isActive, index, categoryId, displayName, onTabSelected, noAnimation }: Props) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isActive && buttonRef.current?.parentElement) {
            buttonRef.current.parentElement.scroll({
                behavior: noAnimation ? "auto" : "smooth",
                left: buttonRef.current.offsetLeft - 10,
            });
        }
    }, [isActive, buttonRef, noAnimation]);

    return (
        <div ref={buttonRef} id={"header-" + categoryId}>
            <Chip
                key={"header-" + categoryId}
                active={isActive}
                text={displayName}
                onClick={onTabSelected ? () => onTabSelected(index) : undefined}
                size="small"
            />
        </div>
    );
};
