import { actionCreators } from "../reducers/rating";
import { AppDispatch } from "../../index";
import { Rating } from "../../../sharedComponents/controls/thumbs";

export function setRating(rating: Rating | null) {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreators.setRating(rating));
    };
}

export function resetRating() {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreators.resetRating());
    };
}
