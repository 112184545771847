import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { BackButton } from "src/common/navigation";
import { Input, Text } from "src/sharedComponents";
import { BinocularIcon, SearchIcon } from "src/sharedComponents/assets/icons";
import { StatusBar } from "src/common/statusBar";
import { FuseMenuExpression, FuseMenuItem, FuseMenuResult } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { menuSearchActions } from "..";
import { AppState } from "src/features";
import { MenuSearchLineItem } from "./MenuSearchLineItem";
import { getStaticBanners } from "../../banners";
import { ViewCartButton } from "../../order/component/ViewCartButton";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { ModalContainer } from "src/common/modal";
import "../assets/MenuSearchPage.scss";

export type MenuItemLine = {
    category: string;
    data: FuseMenuItem[];
};

export const MenuSearchPage = () => {
    const hasBanner = !!useSelector(getStaticBanners)?.length;
    const [search, setSearch] = useState<RegExp | undefined>();
    const [list, setList] = useState<MenuItemLine[]>([]);

    const { showPage, fuseMenu } = useSelector((state: AppState) => state.menuSearch);

    const debounceTrack = useRef(-1);
    const trackData = useRef({
        keyword: "",
        visibleItems: new Map<string, string>(),
    });

    const dispatch = useDispatch();

    const closeSearch = useCallback(() => dispatch(menuSearchActions.showMenuSearchPage(false)), [dispatch]);

    const trackKeyWord = useCallback(() => {
        if (trackData.current.keyword) {
            const visibleItems = Array.from(trackData.current.visibleItems.entries());
            const trackingData = Object.assign(
                {},
                ...[...visibleItems].map(([k, v]) => ({ [k]: { availability: v } }))
            ); // Convert Map to Object to be stringified

            dispatch(
                menuSearchActions.trackKeywordSearch({
                    keyword: trackData.current.keyword,
                    data: JSON.stringify(trackingData),
                })
            );
        }
    }, [dispatch]);

    useEffect(() => {
        if (!showPage) {
            if (trackData.current.visibleItems.size) {
                trackKeyWord();
            }
            setSearch(undefined);
        }
    }, [showPage, trackKeyWord]);

    const onSearch = (val?: string) => {
        if (!val) {
            setSearch(undefined);
            trackData.current.keyword = val || "";
            return;
        }
        setSearch(new RegExp(val, "i"));
        trackData.current.keyword = val;
        window.clearTimeout(debounceTrack.current);
        debounceTrack.current = window.setTimeout(trackKeyWord, 500); // 500ms for the user to stop typing before reporting
    };

    const trackActiveSearch = () => {
        if (list.length && search?.source) {
            trackKeyWord();
        }
    };

    const trackItemViewed = (itemId: string, availability: string) =>
        trackData.current.visibleItems.set(itemId, availability);
    const trackItemRemoved = (itemId: string) => trackData.current.visibleItems.delete(itemId);

    useEffect(() => {
        if (!search) {
            setList([]);
            return;
        }

        let searchStr = search.source.trimLeft();
        if (searchStr.toLowerCase() === "drinks") {
            // The type is drink but users might type drinks
            searchStr = "drink";
        }

        // the ' symbol before the text make the search to be include-match
        const searchVal = `'${searchStr}`;

        const query: FuseMenuExpression[] = [
            {
                $or: [
                    { type: searchVal },
                    { displayName: searchVal },
                    { description: searchVal },
                    { extra: searchVal },
                    { category: searchVal },
                    { variants: searchVal },
                ],
            },
        ];

        const searchResults = fuseMenu?.search<FuseMenuItem>({ $and: query }, { limit: 50 });

        setList(
            searchResults?.reduce((catItems: MenuItemLine[], res: FuseMenuResult) => {
                const rec = catItems.find((cat) => cat.category === res.item.category);
                if (!!rec) {
                    rec.data.push({ ...res.item });
                } else {
                    catItems.push({
                        category: res.item.category,
                        data: [{ ...res.item }],
                    });
                }
                return catItems;
            }, [] as MenuItemLine[]) || []
        );
    }, [search, fuseMenu, trackKeyWord]);

    return (
        <ModalContainer
            isOpen={showPage}
            className={{
                base: "menu-search-page",
                afterOpen: "menu-search-page--after-open",
                beforeClose: "menu-search-page--before-close",
            }}
            overlayClassName={classNames("ReactModal__MenuSearchModal", hasBanner && "with-banner")}
            bodyOpenClassName="menu-search-modal__Body"
            contentLabel="Menu Search Modal"
            closeTimeoutMS={300}
            parentSelector={() => document.getElementById("root") || document.body}
            onAfterClose={closeSearch}
            allowBodyScroll
        >
            {!hasBanner && <StatusBar backgroundColor={"#FFF"} />}
            <div className="menu-search-page__header">
                <BackButton customBack="menu-search-page" onBack={closeSearch} />
                <span className="menu-search-page__header__icon">
                    <SearchIcon />
                </span>
                <Input
                    allowClear
                    name="search"
                    placeholder="Search"
                    value={search?.source || ""}
                    onChange={onSearch}
                    onFocus={trackActiveSearch}
                />
            </div>
            <div className="menu-search-page__content">
                {list.map((catItems, index) => (
                    <div key={`menu-category-${catItems.category}-${index}`}>
                        <Text preset="g-18" mode={["block", "bold"]} className="menu-search-page__content__category">
                            {catItems.category}
                        </Text>
                        {catItems.data.map((item, indexItem) => (
                            <MenuSearchLineItem
                                key={`menu-item-${item.id}-${indexItem}-${index}`}
                                item={item}
                                search={search}
                                whenVisible={trackItemViewed}
                                itemRemoved={trackItemRemoved}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {!list.length && !!search && (
                <div className="menu-search-page__no-results">
                    <BinocularIcon />
                    <Text preset="g-16" mode={["block", "bold"]} className="menu-search-page__no-results__title">
                        No results found
                    </Text>
                    <Text preset="g-14" mode={["block"]}>
                        Try searching for something else.
                    </Text>
                </div>
            )}
            <div className="menu-search-page__view-cart top-shadow">
                <LocationThemeContainer>
                    <ViewCartButton />
                </LocationThemeContainer>
            </div>
        </ModalContainer>
    );
};
