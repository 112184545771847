import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ModifierCheckboxListItem } from "./ModifierCheckboxListItem";
import { ExpandableList } from "src/sharedComponents/controls/expandableList";
import { getModifierListInitialLimit } from "../helpers";
import { MODIFIER_LIST_INITIAL_LIMIT, reduceNestedModifierLength } from "../../menuitem/components/MenuItemDetails";
import { useHasMounted } from "src/sharedComponents/common/shared";
import { withListTracking } from "../component/withListTracking";
import { ModifierListSingleSelectProps } from "src/features/order/Elements/OrderModifiers";

const CheckboxList = ({
    modifier,
    modifierId,
    onModifierOptionSelected,
    selectedOptions,
    selectedNestedModifier,
    onNestedModifierEdit,
    trackExpand,
    canHideItems,
    nestingLevel,
}: ModifierListSingleSelectProps) => {
    const hasMounted = useHasMounted();
    const [limit, setLimit] = useState(MODIFIER_LIST_INITIAL_LIMIT);
    const nestedModiferKey = useMemo(
        () =>
            !selectedNestedModifier || limit === modifier.options.length
                ? ""
                : `-${reduceNestedModifierLength(selectedNestedModifier)}`,
        [modifier.options, limit, selectedNestedModifier]
    );

    const isOptionSelected = (index: number = 0) => !!selectedOptions && selectedOptions.indexOf(index) !== -1;

    useEffect(() => {
        if (!hasMounted) {
            setLimit(getModifierListInitialLimit(MODIFIER_LIST_INITIAL_LIMIT, modifier.options, selectedOptions));
        }
    }, [hasMounted, modifier.options, selectedOptions]);

    const onListExpand = useCallback(
        (numberOptionsRevealed: number) => {
            setLimit(modifier.options.length);
            if (trackExpand) {
                trackExpand(numberOptionsRevealed, "checkbox");
            }
        },
        [trackExpand, modifier.options.length]
    );

    return !hasMounted ? null : (
        <ExpandableList
            key={`modifier-checkbox-list-${modifierId}${nestedModiferKey}`}
            initialLimit={limit}
            totalItems={modifier.options.length}
            showHiddenCount={true}
            onExpand={onListExpand}
            canHideItems={canHideItems}
            renderItem={(positionIndex: number) => {
                const option = modifier.options[positionIndex];
                const checked = isOptionSelected(option.originalIndex);
                const hasReachedMaxSelection =
                    !!modifier.maxSelection && modifier.maxSelection === selectedOptions?.length;
                const disabled = checked ? false : option.available === false || hasReachedMaxSelection;

                return (
                    <ModifierCheckboxListItem
                        key={`modifier-${modifierId}-${positionIndex}`}
                        checked={checked}
                        disabled={disabled}
                        onModifierOptionSelected={onModifierOptionSelected}
                        modifierId={modifierId}
                        selectedNestedModifier={selectedNestedModifier}
                        onNestedModifierEdit={onNestedModifierEdit}
                        option={option}
                        nestingLevel={nestingLevel}
                    />
                );
            }}
        />
    );
};

export const ModifierCheckboxList = withListTracking(CheckboxList);
