import { connectivityRegister, onOffline, onOnline } from "./connectivityChange";

export const connectivityFactory = (eventOwner : Document | Window) => ({
    onOffline: (cb: () => void) => {
        const dispose = connectivityRegister({ onOffline: cb });
        eventOwner.removeEventListener("offline", onOffline, false);
        eventOwner.addEventListener("offline", onOffline, false);

        return dispose;
    },
    onOnline: (cb: () => void) => {
        const dispose = connectivityRegister({ onOnline: cb });
        eventOwner.removeEventListener("online", onOnline, false);
        eventOwner.addEventListener("online", onOnline, false);

        return dispose;
    },
    awaitOnline: function() {

        const subscribe = (cb: () => void) => this.onOnline(cb);

        return new Promise<void>((res) => {
            if (window.navigator.onLine) {
                res();
                return;
            }

            const dispose = subscribe(() => {
                dispose();
                res();
            });
        });
    }
});
