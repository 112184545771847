import { OperationAction, OperationsTypeKeys } from "../../../features/operations";
import { submitPaymentAndOrderOperation } from "../../../features/payment/operations";
import { submitOpenTableOrderOperation } from "../../../features/openTable/operations";

export interface NativeBackButtonState {
    preventBack: boolean;
}

const initialState: NativeBackButtonState = {
    preventBack: false,
};

const preventsBack = (action: OperationAction) => {
    switch (action.operation) {
        case submitPaymentAndOrderOperation.name:
        case submitOpenTableOrderOperation.name:
            return true;
        default:
            return false;
    }
};

const enablesBack = (action: OperationAction) => {
    return preventsBack(action);
};

export function reducer(state: NativeBackButtonState = initialState, action: OperationAction): NativeBackButtonState {
    if (action.type === OperationsTypeKeys.BEGIN && preventsBack(action)) {
        return {
            preventBack: true,
        };
    }
    if (
        (action.type === OperationsTypeKeys.COMPLETED || action.type === OperationsTypeKeys.FAILED) &&
        enablesBack(action)
    ) {
        return {
            preventBack: false,
        };
    }
    return state;
}
