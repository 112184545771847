import "../assets/OfferFooter.scss";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getOfferDataStatus } from "../selectors";
import { getMarketingData } from "src/features/marketing/selectors/optInMarketing";
import { OfferData, OfferStatus } from "../types";
import { Checkbox, Text } from "src/sharedComponents";
import { TappableAnchor, TappableDiv } from "src/sharedComponents/common/tappable";
import { href } from "src/common/experience";
import { LegalModal } from "src/features/compliance/components/LegalModal";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { getIsAnonymous } from "src/features/accounts/selectors";
import { claimOfferAction } from "../actions";
import { signInAction } from "src/features/signup/actions/signInAction";
import { getOfferData as getOfferDataAction } from "../actions/getOfferData";
import { ButtonProps } from "src/sharedComponents/controls/button/Button";
import { getAvailableOffersAction } from "../actions/getAvailableOffers";

interface Props {
    onSuccess?: () => void;
    buttonText?: string;
    buttonDisabled?: boolean;
    buttonProps?: ButtonProps;
    offer: OfferData;
    allowOptIn?: boolean;
}

export const OfferFooter = ({ onSuccess, buttonProps, offer, allowOptIn }: Props) => {
    const [marketingChecked, setMarketingChecked] = useState(true);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const offerDataStatus = useSelector(getOfferDataStatus);
    const isAnonymous = useSelector(getIsAnonymous);
    const marketingData = useSelector(getMarketingData);
    const dispatch = useDispatch();

    const subscribeToMarketing = !!marketingData.enabled && marketingChecked;
    const offerAvailable = offerDataStatus === OfferStatus.AVAILABLE;

    const claimOffer = useCallback(() => {
        if (offer) {
            dispatch(claimOfferAction(offer.scopeId, offer.id, subscribeToMarketing, offer.scope, offer.source));
        }
    }, [dispatch, offer, subscribeToMarketing]);

    const onClaim = useCallback(() => {
        if (isAnonymous) {
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    isMidWizard: true,
                    slideAnimation: "slide-from-right",
                    onCancel: () => {
                        dispatch(getOfferDataAction(offer.scopeId, offer.id, offer.scope));
                    },
                    onComplete: () => {
                        dispatch(getAvailableOffersAction());
                        dispatch(getOfferDataAction(offer.scopeId, offer.id, offer.scope));
                        claimOffer();
                        onSuccess?.();
                    },
                    accountDetailsOptions: {
                        signInDescription: "Claim your rewards & more with a me&u account.",
                        requireEmail: true,
                    },
                })
            );
        } else {
            claimOffer();
            onSuccess?.();
        }
    }, [dispatch, claimOffer, isAnonymous, onSuccess, offer]);

    return (
        <footer className="offer-footer">
            {offerAvailable && marketingData.enabled && allowOptIn && (
                <div className="offer-footer__newsletter-container">
                    <Checkbox
                        id="newsletter-subscribe-checkbox"
                        checked={marketingChecked}
                        onChange={() => setMarketingChecked((prev) => !prev)}
                    />
                    <div className="offer-footer__newsletter-text">
                        <TappableDiv
                            className="offer-footer__tappable-text-container"
                            onTap={() => setMarketingChecked((prev) => !prev)}
                        >
                            <Text preset="g-14" mode="bold">
                                Subscribe me to the {marketingData.newsletterProvider} newsletter
                            </Text>
                        </TappableDiv>
                        {marketingData.privacyPolicyUrl ? (
                            <Text preset="g-12" className="offer-footer__subtitle">
                                View their{"\u00A0"}
                                <TappableAnchor
                                    className={classNames("offer-footer", "link")}
                                    {...href.openInNewWindowAttributes(marketingData.privacyPolicyUrl)}
                                >
                                    Privacy Policy
                                </TappableAnchor>
                                {"\u00A0"}
                                and me&u’s{"\u00A0"}
                                <TappableAnchor
                                    shouldPreventDefault
                                    onTap={() => {
                                        setShowPrivacyPolicy(true);
                                    }}
                                    className={classNames("offer-footer", "link")}
                                >
                                    Privacy Policy.
                                </TappableAnchor>
                            </Text>
                        ) : (
                            <Text preset="g-12">
                                View me&u’s{"\u00A0"}
                                <TappableAnchor
                                    shouldPreventDefault
                                    onTap={() => {
                                        setShowPrivacyPolicy(true);
                                    }}
                                    className="account-details__privacy-policy__link"
                                >
                                    Privacy Policy.
                                </TappableAnchor>
                            </Text>
                        )}
                    </div>
                    {showPrivacyPolicy && (
                        <LegalModal legalPage={"privacyPolicy"} goBack={() => setShowPrivacyPolicy(false)} />
                    )}
                </div>
            )}
            <NetworkConnectedButton onClick={onClaim} {...buttonProps} />
        </footer>
    );
};
