import { PaymentMethod } from "../../../common/payment";
import { orderApi } from "../../order/orderApi";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";

export async function fetchPaymentMethodSessionToken(paymentMethod: PaymentMethod) {
    const { paymentGateway } = paymentMethod;

    const response = await orderApi.send(`/paymentgateway/${paymentGateway}/vaultedpaymentmethods/sessiontoken`, {
        method: "POST",
        headers: getTableTokenHeaders(),
    });

    await ProblemDetailsError.throwError(response);

    return await response.text();
}
