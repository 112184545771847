import { orderApi } from "../../order/orderApi";
import { removePromoCodeOperation } from "../operations";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

const removePromoCodeApi = async () => {
    const headers = getTableTokenHeaders();

    const response = await orderApi.send("/promotions/remove-code", {
        method: "DELETE",
        headers,
    });

    await ProblemDetailsError.throwError(response);
    return response.status;
};

export function removePromoCode() {
    return removePromoCodeOperation.getThunk(async () => {
        try {
            await removePromoCodeApi();
        } catch (ex) {
            console.log("failed to remove promo code:", ex);
            throw ex;
        }
    });
}
