import "./InviteToGroupTab.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { NativeBackButton, SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { Button, Text } from "src/sharedComponents";
import { actionCreators } from "../reducers/activeGroupTab";
import {
    getActiveTabJoinCode,
    getActiveTabLink,
    getGroupTabLocationName,
    getGroupTabName,
    getShowInviteModal,
    getTabType,
    getTabTypeName,
} from "../selectors/activeGroupTab";
import { ModalContainer } from "src/common/modal";
import { getTabTypeReadableName } from "../selectors/helpers";
import classNames from "classnames";
import { getLastTableData } from "src/features/compliance/persistence/lastTableData";
import { TabJoinMethod } from "src/features/groupTabs/types";

export const InviteToGroupTab = () => {
    const dispatch = useDispatch();
    const showModal = useSelector(getShowInviteModal);
    const joinCode = useSelector(getActiveTabJoinCode);
    const groupTabName = useSelector(getGroupTabName);
    const activeTabPath = useSelector(getActiveTabLink);
    const locationName = useSelector(getGroupTabLocationName);
    const tabTypeName = useSelector(getTabTypeName);
    const tabType = useSelector(getTabType);
    const [show, setShow] = useState(!!showModal && !!joinCode);

    const resetState = useCallback(() => {
        dispatch(actionCreators.showInviteToGroupTab(false));
    }, [dispatch]);

    useEffect(() => {
        setShow(!!showModal && !!joinCode);
    }, [showModal, joinCode]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    const closeModal = useCallback(() => {
        setShow(false);
        dispatch(actionCreators.showInviteToGroupTab(false));
    }, [dispatch]);

    const qrCodeUrl = useMemo(() => {
        const params = new URLSearchParams({
            from: TabJoinMethod.QR,
        });
        const lastTableData = getLastTableData();
        if (lastTableData?.url) {
            params.set("qr", lastTableData.url);
        }
        return `${activeTabPath}?${params.toString()}`;
    }, [activeTabPath]);

    const share = () => {
        if (!window.navigator.share) {
            return;
        }
        window.navigator
            .share({
                text: `👋 Hey! Join ${groupTabName}${
                    locationName ? " at " + locationName : ""
                }. Tap the link to start ordering together.`,
                title: `Join ${tabTypeName}`,
                url: activeTabPath,
            })
            .finally(closeModal);
    };

    const modalName = `invite-to-group-tab-${getTabTypeReadableName(tabType)}`;

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: classNames("invite-group-tab-modal slide-in"),
                afterOpen: classNames("invite-group-tab-modal--after-open slide-in--after-open"),
                beforeClose: classNames("invite-group-tab-modal--before-close slide-in--before-close"),
            }}
            overlayClassName={classNames("ReactModal__InviteGroupTab slide-in-modal--overlay")}
            onAfterClose={resetState}
            onRequestClose={closeModal}
            closeTimeoutMS={150}
        >
            <NativeBackButton name={modalName} onPressed={closeModal}>
                <LocationThemeContainer>
                    <div className="invite-group-tab">
                        <SimpleNavHeader closeToBack={true} customBack={modalName} onBack={closeModal} withBorder />
                        <div className="invite-group-tab__with-qr">
                            <Text className="invite-group-tab__title" preset="title-24" mode="extra-bold">
                                {groupTabName}
                            </Text>
                            <Text className="invite-group-tab__message" preset="g-14">
                                Share a link or get others to scan your QR code so they can join your {tabTypeName}
                            </Text>
                            <div className="invite-group-tab__qr">
                                <QRCodeSVG value={qrCodeUrl} size={200} />
                            </div>
                            <Text className="invite-group-tab__secondary-message" preset="g-14">
                                If you share your {tabTypeName} with someone, they can start using your {tabTypeName} as
                                payment.
                            </Text>
                        </div>
                        <div className="invite-group-tab__footer">
                            {!!window.navigator.share ? (
                                <>
                                    <Button value={`Share ${tabTypeName} link`} onClick={share} />
                                    <Button value="Dismiss" mode="blank" onClick={closeModal} secondary />
                                </>
                            ) : (
                                <Button value="Dismiss" onClick={closeModal} />
                            )}
                        </div>
                    </div>
                </LocationThemeContainer>
            </NativeBackButton>
        </ModalContainer>
    );
};
