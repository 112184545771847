import { orderApi } from "src/features/order/orderApi";
import { getAuthHeaders } from "src/common/auth";
import { LocationMarkerClaim } from "../../partyOnboarding/types";

const getLocationInfo = async (locationClaim: LocationMarkerClaim) => {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify([locationClaim]);
    const response = await orderApi.send("/location/claiminfo", {
        method: "POST",
        body,
        headers,
    });
    if (response.ok) {
        return await response.json();
    }
};

export default getLocationInfo;
