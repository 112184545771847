import "../assets/ModifierIndicators.scss";

import React from "react";
import classNames from "classnames";
import { AvailablePrices } from "../../menudata";
import { Text } from "../../../sharedComponents";
import { MembershipIcon, RecommendedIcon } from "../../../sharedComponents/assets/icons";
import { IconWithBackground } from "../../../sharedComponents/assets/icons/IconWithBackground";

export interface Props {
    availablePrices?: AvailablePrices;
    recommended: boolean;
}

export const ModifierIndicator = ({ availablePrices, recommended }: Props) => {
    if (!availablePrices?.memberPrice && !recommended) return null;

    return (
        <span className={classNames("modifier-indicator__container")}>
            {!!availablePrices?.memberPrice ? (
                <div className="modifier-indicator__member-price">
                    <IconWithBackground icon={<MembershipIcon />} className="membership-indicator" />
                    <Text
                        preset="g-12"
                        value="Members"
                        mode="bold"
                        className="modifier-indicator__member-price__title"
                    />
                </div>
            ) : null}
            {!!recommended ? (
                <Text preset="g-12" mode="bold">
                    <IconWithBackground icon={<RecommendedIcon />} />
                    Recommended
                </Text>
            ) : null}
        </span>
    );
};
