import "../assets/OfferPage.scss";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getOfferData, getOfferDataStatus } from "../selectors";
import { NoOfferContainer } from "./NoOfferContainer";
import { OfferPageContent } from "./OfferPageContent";
import { OfferFooter } from "./OfferFooter";
import { OfferStatus } from "../types";
import { ButtonProps } from "src/sharedComponents/controls/button/Button";
import { getOfferTypeString } from "../utils";

interface Props {
    onSuccess: () => void;
    onBack: () => void;
}

export const OfferClaimPageWrapper = ({ onSuccess, onBack }: Props) => {
    const offer = useSelector(getOfferData);
    const offerDataStatus = useSelector(getOfferDataStatus);
    const offerStringType = getOfferTypeString(offer);

    const buttonProps: ButtonProps | undefined = useMemo(() => {
        switch (offerDataStatus) {
            case OfferStatus.AVAILABLE:
                return {
                    value: `Claim this ${offerStringType}`,
                };
            case OfferStatus.CLAIMED:
                return { value: "Offer claimed and ready to use", disabled: true };
            case OfferStatus.REDEEMED:
                return { value: "Offer already redeemed", disabled: true };
            case OfferStatus.EXPIRED:
                return { value: "Offer is no longer available", disabled: true };
            default:
                return undefined;
        }
    }, [offerDataStatus, offerStringType]);

    if (!offer?.id) {
        return <NoOfferContainer />;
    }

    return (
        <OfferPageContent onBack={onBack} offer={offer}>
            <OfferFooter onSuccess={onSuccess} buttonProps={buttonProps} offer={offer} allowOptIn />
        </OfferPageContent>
    );
};
