import React, { useState, useCallback, useEffect } from "react";
import { Text } from "../text";
import { ThumbIcon } from "../../assets/icons";
import { TappableSpan } from "../../common/tappable";
import classNames from "classnames";

import "./Thumbs.scss";

export enum Rating {
    NEGATIVE = 0,
    POSITIVE,
}

export interface ThumbsProps {
    onThumbClick: (rating: Rating) => void;
    title?: string;
    rating?: Rating;
    defaultRating?: Rating | null;
    isLargeTitle?: boolean;
    isLargeIcons?: boolean;
    locationTheme?: boolean;
    showSelectedOnly?: boolean;
}

export const Thumbs = ({
    onThumbClick,
    title,
    rating,
    defaultRating,
    isLargeTitle,
    isLargeIcons,
    locationTheme,
    showSelectedOnly,
}: ThumbsProps) => {
    const [selectedRating, setSelectedRating] = useState<undefined | Rating | null>(defaultRating);

    const onIconClick = useCallback(
        (rating: Rating) => {
            if (selectedRating !== rating) {
                setSelectedRating(rating);
                onThumbClick(rating);
            }
        },
        [onThumbClick, selectedRating]
    );

    useEffect(() => {
        if (rating !== undefined && rating !== selectedRating) setSelectedRating(rating);
    }, [rating, selectedRating]);

    return (
        <div
            className={classNames(
                "thumbs",
                locationTheme && "thumbs-location-theme",
                showSelectedOnly && selectedRating !== null && selectedRating !== undefined && "selected"
            )}
        >
            {title && (
                <Text
                    preset={isLargeTitle ? "title-28" : "g-16"}
                    mode="bold"
                    value={title}
                    className={classNames("thumbs__title", isLargeTitle && "large")}
                />
            )}
            <div className="thumbs__icons">
                <TappableSpan
                    className={classNames(
                        "thumbs__icons__down",
                        selectedRating === Rating.NEGATIVE && "active",
                        isLargeIcons && "large"
                    )}
                    onClick={() => onIconClick(Rating.NEGATIVE)}
                >
                    <ThumbIcon />
                </TappableSpan>
                <TappableSpan
                    className={classNames(
                        "thumbs__icons__up",
                        selectedRating === Rating.POSITIVE && "active",
                        isLargeIcons && "large"
                    )}
                    onClick={() => onIconClick(Rating.POSITIVE)}
                >
                    <ThumbIcon />
                </TappableSpan>
            </div>
        </div>
    );
};
