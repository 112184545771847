import { AppDispatch, AppState } from "src/features";
import { actionCreators } from "../reducers";
import { getPayOnlyLastShare } from "../selectors";
import { setShare } from "./setShares";

export const showSplitEvenly = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const share = getPayOnlyLastShare(state);

        dispatch(setShare(share ?? { split: 2, shares: 1 }));

        dispatch(actionCreators.setSplitOption("SplitEvenly"));
    };
};
