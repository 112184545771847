import { AppDispatch, AppState } from "src/features/index";
import { getAnyParty, getTableLabel } from "src/features/order/selectors";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { PartyCloseReason } from "src/features/order";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { makePayOnlyPaymentOperation } from "src/features/payOnly/operations";

export const showPayOnlyPartyClosedMessage = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const party = getAnyParty(state);
        if (!party) return;
        const tableLabel = getTableLabel(state);
        const paymentStatus = makePayOnlyPaymentOperation.getStatus(state);
        const showNotCharged = paymentStatus === "processing";
        if (party.closeReason === PartyCloseReason.CANCELLED) {
            const menuData = getVisibleMenuData(state);
            dispatch(
                showModalMessage(modalMessages.tableOrSectionDisabled(menuData?.title, showNotCharged, tableLabel))
            );
        } else if (party.closeReason === PartyCloseReason.VENUECLOSED) {
            dispatch(showModalMessage(modalMessages.tableUnavailable(showNotCharged)));
        } else if (party.closeReason === PartyCloseReason.FAILED) {
            dispatch(showModalMessage(modalMessages.tableClosedNotFound(tableLabel, showNotCharged)));
        } else {
            dispatch(showModalMessage(modalMessages.tableClosed(showNotCharged)));
        }
    };
};
