import { AppDispatch, AppState } from "../../index";
import { LocationMenuAdUnits } from "..";
import { fetchingMenuDataAdUnitOperation } from "../operations";
import { attempt } from "../../order/orderApi/util/attempt";
import { reattemptStrategies } from "../../order/orderApi";
import { menuDataUrl } from "../API/menuDataUrl";
import { getActiveService, getVisibleMenuData } from "src/features/menu/selectors";
import { quickSellActions, QuickSellService } from "src/features/quickSell";
import { config } from "src/common/config";
import { flatten } from "src/features/order/selectors/util/flatten";
import { fetchActions } from "../reducers";
import { getParty } from "src/features/order/selectors";
import { restaurantAdUnitEnabled } from "src/features/order/selectors/restaurantFlags";

export function updateMenuAdUnits() {
    return fetchingMenuDataAdUnitOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const party = getParty(state);
        const menuAdUnitsUrl = party?.menuDataAdUnitUrl;
        const adUnitEnabled = restaurantAdUnitEnabled(state);

        if (!menuAdUnitsUrl || !adUnitEnabled) {
            const activeService = getActiveService(state);
            const data = getVisibleMenuData(state);

            if (state.restaurantFlags.quickSellEnabled && config.useDemoQuickSell === "1" && data && activeService) {
                const food = activeService.menus.reduce((res, menu) => {
                    if (data.menus[menu].displayName === "Food") {
                        return res.concat(
                            flatten(data.menus[menu].categories.map((cat) => data.categories[cat].menuItems))
                        );
                    }
                    return res;
                }, [] as string[]);
                dispatch(quickSellActions.setQuickSellDrinksOnly({ [activeService.id]: food }));
            }
            return;
        }

        const fullMenuAdUnitsUrl = menuDataUrl(menuAdUnitsUrl);

        const onAttemptFailed = (count: number) => dispatch(fetchActions.connectionFailed(fullMenuAdUnitsUrl, count));

        const response = await attempt(
            () => fetch(fullMenuAdUnitsUrl),
            reattemptStrategies.limited,
            undefined,
            onAttemptFailed
        );

        if (!response.ok) throw new Error(`Failed to fetch menu ad units file ${fullMenuAdUnitsUrl}`);

        let menuAdUnits: LocationMenuAdUnits = await response.json();
        if (!menuAdUnits.ids?.length) return;
        if (state.restaurantFlags.quickSellEnabled && !!menuAdUnits.quickSell?.drinksOnly) {
            const quickSellItemsByService = Object.keys(menuAdUnits.quickSell.drinksOnly).reduce(
                (foodItemByService, serviceItems) => {
                    foodItemByService[serviceItems] = menuAdUnits.quickSell!.drinksOnly![serviceItems].map(
                        (itemIndex) => menuAdUnits.ids![itemIndex]
                    );
                    return foodItemByService;
                },
                {} as QuickSellService
            );

            dispatch(quickSellActions.setQuickSellDrinksOnly(quickSellItemsByService));
        }
    });
}
