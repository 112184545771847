import { createOperation } from "../operations";

export const showPayOnlyGetReceiptOperation = createOperation("PAY_ONLY/SHOW_GET_RECEIPT");
export const makePayOnlyPaymentOperation = createOperation("PAY_ONLY/MAKE_PAYMENT", {
    tracked: true,
    allowParallel: false,
});
export const getMarketingInfoOperation = createOperation("PAY_ONLY/GET_MARKETING_METADATA");
export const memberProfileEmailOperation = createOperation("PAY_ONLY/SET_MEMBER_EMAIL_FROM_PROFILE");
export const setPayOnlyPaymentsSeenOperation = createOperation("PAY_ONLY/SET_PAYMENTS_SEEN");
export const refreshTableOrderOperation = createOperation("PAY_ONLY/REFRESH_TABLE_ORDER");
