export function normalizeError(err: any): Error {
    if (err instanceof Error) {
        return err;
    }

    return new Error(String(err));
}

export interface ErrorHandler {
    code: string;
    handler: (details?: string) => HandledError;
}

export class HandledError extends Error {}
