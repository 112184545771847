import { createSelector } from "reselect";
import { AppState } from "../..";
import { getAnyParty, getPartyMembersLength, getParty } from "../../order/selectors";
import { OrderFlowBehaviorFlags, PartyType } from "../../order";
import { config } from "src/common/config";
import { getEnvUrl } from "src/common/shared";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { regionHelper } from "src/features/region";

export const getOpenTableState = (state: AppState) => state.openTable;

export const getOpenTableOrderFlowBehaviorFlags = createSelector(getAnyParty, (party) => {
    return party?.openTableOrderFlowBehaviorFlags;
});

export const getIsOpenTable = createSelector(getAnyParty, (party) => party?.type === PartyType.MULTIUSER);

export const getIsOpenTableFlexTab = createSelector(
    getIsOpenTable,
    getOpenTableOrderFlowBehaviorFlags,
    (isOpenTable, openTableOrderFlowBehaviorFlags) => {
        return (
            isOpenTable &&
            openTableOrderFlowBehaviorFlags !== undefined &&
            (openTableOrderFlowBehaviorFlags & OrderFlowBehaviorFlags.Tab) === OrderFlowBehaviorFlags.Tab
        );
    }
);

export const getIsOpenTableOrderingDisabled = createSelector(
    getIsOpenTable,
    getOpenTableOrderFlowBehaviorFlags,
    (isOpenTable, openTableOrderFlowBehaviorFlags) => {
        return (
            isOpenTable &&
            openTableOrderFlowBehaviorFlags !== undefined &&
            (openTableOrderFlowBehaviorFlags & OrderFlowBehaviorFlags.Ordering) !== OrderFlowBehaviorFlags.Ordering
        );
    }
);

export const getOpenTableMemberActivity = createSelector(
    getOpenTableState,
    (openTableState) => openTableState.memberActivity
);

export const getOpenTableMemberActiveOrderStatus = createSelector(
    getOpenTableState,
    (openTableState) => openTableState.memberActiveOrderStatus
);

//Should only be used when a party does not exist and the user is deciding whether to join someone else's flex tab or create their own.
export const getOpenTableHasFlexTabBehaviorOnboarding = createSelector(
    getOpenTableState,
    (openTableState) => openTableState.hasFlexTabBehavior
);

//Should only be used when a party does not exist and the user is deciding whether to join someone else's flex tab or create their own.
export const getOpenTableLocationMarkerClaims = createSelector(
    getOpenTableState,
    (openTableState) => openTableState.locationMarkerClaims
);

export const getActiveFlexTabLink = createSelector(getParty, (activeParty) =>
    config.REACT_APP_APP_BASE_URL
        ? `${getEnvUrl(config.REACT_APP_APP_BASE_URL)}/join-flex/${activeParty?.flexJoinCode}?r=${regionHelper.region.id}`
        : undefined
);

export const getShowInviteToFlexTabCTA = createSelector(
    getIsOpenTableFlexTab,
    getParty,
    getCurrentMemberId,
    (isOpenTableFlexTab, party, currentMemberId) =>
        isOpenTableFlexTab && currentMemberId === party?.members[0].memberId && !!party?.flexJoinCode
);

export const getFlexTabOwnerFirstName = createSelector(
    getParty,
    (activeParty) => activeParty?.members[0].firstName ?? "Guest"
);

export const getShowFlexMembersStatusList = createSelector(
    getIsOpenTable,
    getIsOpenTableFlexTab,
    getPartyMembersLength,
    (isOpenTable, isOpenTableFlexTab, partyMembersLength) => {
        return (isOpenTable && !isOpenTableFlexTab) || (isOpenTableFlexTab && partyMembersLength > 1);
    }
);

export const getShowOnSomeoneElsesFlexTabBanner = createSelector(
    getIsOpenTableFlexTab,
    getParty,
    getCurrentMemberId,
    (isOpenTableFlexTab, party, currentMemberId) => {
        return isOpenTableFlexTab && currentMemberId !== party?.members[0].memberId && !!party?.flexJoinCode;
    }
);

export const getOpenInviteToFlexTabModal = createSelector(
    getOpenTableState,
    (openTableState) => openTableState.openInviteToFlexTabModal || false
);
