import { AppDispatch, AppState } from "../..";
import { actionCreators } from "../reducers/pendingItem";
import { PendingItem } from "..";
import { getActiveServiceMenuItemLinkParams, getVisibleMenuData } from "../../menu/selectors";
import { OrderItemModifier, OrderItemSelectedNestedModiferData } from "../types";
import { flatten } from "../selectors/util/flatten";
import { getSelectedModifierChoice } from "../../menu/helpers";
import { Indexed, MenuItemModifier } from "../../menudata";

export interface CreatePendingItem {
    menuItemId: string;
    menuId?: string;
    categoryId?: string;
    courseId?: string;
    modal?: boolean;
    preselect?: boolean;
    quantity?: number;
    variant?: number | null;
    notes?: string;
    modifiers?: OrderItemModifier[] | null;
    orderItemId: string | null;
    isFeatured?: boolean;
}

export function setPendingItem({
    menuItemId,
    menuId,
    categoryId,
    preselect,
    courseId,
    modal,
    quantity,
    orderItemId,
    variant,
    notes,
    modifiers,
    isFeatured,
}: CreatePendingItem) {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const menuData = getVisibleMenuData(state)!;
        const menuItem = menuData.items[menuItemId];

        if (!menuId) {
            const data = getActiveServiceMenuItemLinkParams(state).get(menuItemId);
            if (data) {
                menuId = data.menuId;
                categoryId = categoryId || data.categoryId;
            }
        } else if (!categoryId) {
            categoryId = menuData.menus[menuId].categories.find(
                (cid) => menuData.categories[cid].menuItems.indexOf(menuItemId) !== -1
            );
        }

        if (!categoryId) {
            categoryId = menuItemId;
        }

        if (variant === null || variant === undefined) {
            const variantIndex =
                menuItem.variants?.findIndex(
                    (variantData) => variantData.available !== false && variantData.default === true
                ) ?? -1;
            variant = variantIndex > -1 ? variantIndex : null;
        }

        if (modifiers) {
            // Deep clone modifiers here, otherwise we're directly editing the modifiers
            // of the order item in memory, so if we change anything but don't update the
            // cart it will still be changed for that order item locally
            modifiers = JSON.parse(JSON.stringify(modifiers));
            if (menuItem.modifiers && menuData.modifiers) {
                setSelectedNestedDataRecursive(modifiers, menuItem.modifiers, menuData.modifiers);
            }
        }

        const pendingItem: PendingItem = {
            courseId: !!courseId ? courseId : null,
            menuId,
            itemId: menuItemId,
            orderItemId: orderItemId || null,
            categoryId,
            quantity: quantity || 1,
            notes: notes || null,
            variant,
            modifiers: modifiers || null,
            canSubmit: false,
            preselect,
            unavailable: false,
            price: 0,
            triedToSubmit: 0,
            modal,
            isFeatured,
        };

        dispatch(actionCreators.setPendingItem(pendingItem));
    };
}

const setSelectedNestedDataRecursive = <T extends OrderItemModifier>(
    modifiers: T[] | null | undefined,
    menuItemModifiers: MenuItemModifier[],
    globalModifiers: Indexed<MenuItemModifier>
) => {
    modifiers?.forEach((modifier) => {
        const menuItemModifier = modifier["modifierId"]
            ? globalModifiers[modifier["modifierId"]]
            : menuItemModifiers[modifier.modifier];

        modifier.optionNestedModifiers?.forEach((optionNestedModifier) => {
            if (!optionNestedModifier.modifiers?.length) {
                // no nested options selected for this option, need to initiate
                const selectedModifier = getSelectedModifierChoice(
                    menuItemModifier?.options,
                    optionNestedModifier.optionIndex
                );

                if (selectedModifier?.nestedModifiers) {
                    optionNestedModifier.selectedNestedData = selectedModifier.nestedModifiers.map(
                        (nestedId): OrderItemSelectedNestedModiferData => ({
                            required: !!globalModifiers[nestedId].minSelection,
                        })
                    );
                }

                return;
            }

            optionNestedModifier.selectedNestedData = flatten(
                optionNestedModifier.modifiers.map((nestedModifier): OrderItemSelectedNestedModiferData[] => {
                    const modifierData = globalModifiers[nestedModifier.modifierId];

                    if (!nestedModifier.selectedOptions?.length) {
                        return [{ required: !!modifierData.minSelection }];
                    }

                    return nestedModifier.selectedOptions.map(
                        ({ displayName, optionIndex, unitNonMemberPrice, unitPrice }) => ({
                            displayName,
                            optionIndex,
                            modifierId: nestedModifier.modifierId,
                            price:
                                unitPrice > 0 || unitNonMemberPrice > 0
                                    ? {
                                          basePrice: unitNonMemberPrice,
                                          memberPrice: unitPrice,
                                      }
                                    : undefined,
                            required: !!modifierData.minSelection,
                        })
                    );
                })
            );

            setSelectedNestedDataRecursive(optionNestedModifier.modifiers, menuItemModifiers, globalModifiers);
        });
    });
};
