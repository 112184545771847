import { createSelector } from "reselect";
import { getAvailableMembershipStates } from "./getActiveMembershipState";
import { getParty, OrderItemModifier } from "src/features/order";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { getMembershipPriceList, updatePrices } from "src/features/menudata/mutators";
import { AvailablePrices, ItemWithAvailablePrices, MenuItemModifier } from "src/features/menudata";
import { MembershipAuthMethods, MembershipTypes } from "../types/MembershipState";
import { iterateOrderItems } from "src/features/order/selectors/util/iterateOrderItems";
import { getSelectedModifierChoice } from "src/features/menu/helpers";
import { getMemberNonMemberSubTotal, getMemberSubTotal } from "src/features/payment/selectors";
import { getConnectedMembershipState, getCurrentMembershipApplyOnValidate } from "./getConnectedMembershipState";

export const getOptInMembership = createSelector(getAvailableMembershipStates, (memberships) => {
    return memberships?.find((membership) => membership.authentication.method === MembershipAuthMethods.OPT_IN);
});

export const getIsConnectedToMembershipWithSavings = createSelector(
    getConnectedMembershipState,
    (connectedMembership) =>
        connectedMembership?.type === MembershipTypes.VENUE || connectedMembership?.type === MembershipTypes.SPROUT
);

export const getHasMembershipSavings = createSelector(
    getIsConnectedToMembershipWithSavings,
    getCurrentMembershipApplyOnValidate,
    getMemberSubTotal,
    getMemberNonMemberSubTotal,
    (isConnected, currentMembershipApplyOnValidate, subTotal, nonMemberSubtotal) =>
        (isConnected || currentMembershipApplyOnValidate) && subTotal < nonMemberSubtotal
);

const potentialSavingsPriceResolver = (availablePrices?: AvailablePrices) =>
    availablePrices?.memberPrice !== undefined ? availablePrices.basePrice - availablePrices.memberPrice : 0;

export const getMembershipHasPriceList = createSelector(
    getParty,
    getVisibleMenuData,
    getOptInMembership,
    (party, menuData, potentialMembership) => {
        const levelId = potentialMembership?.potentialLevelId;

        if (!menuData || !levelId) {
            return false;
        }

        const membershipPriceList = getMembershipPriceList(menuData, party?.serviceId, levelId);

        return membershipPriceList !== undefined ? membershipPriceList >= 0 : undefined;
    }
);

export const getPotentialMemberSavings = createSelector(
    getParty,
    getVisibleMenuData,
    getOptInMembership,
    (party, menuData, potentialMembership) => {
        const activeOrderItems = party?.activeOrder.items;
        const levelId = potentialMembership?.potentialLevelId;
        let totalSavings = 0;

        if (menuData && levelId) {
            const updatedMenu = updatePrices(menuData, party?.serviceId, levelId);
            let itemPotentialSavings = 0;
            const getPotentialSavings = (item: ItemWithAvailablePrices | undefined) => {
                const prices = item?.availablePrices;
                itemPotentialSavings += potentialSavingsPriceResolver(prices);
            };

            const modifierCallback = (modifier: MenuItemModifier, orderItemModifier: OrderItemModifier) => {
                for (let orderItemOption of orderItemModifier.options) {
                    const menuItemOption = getSelectedModifierChoice(modifier.options, orderItemOption);
                    getPotentialSavings(menuItemOption);
                }
            };

            activeOrderItems?.forEach((item) => {
                const itemFromMenu = item.itemId ? updatedMenu.items[item.itemId] : undefined;

                if (itemFromMenu) {
                    iterateOrderItems(updatedMenu, item, getPotentialSavings, modifierCallback);
                    totalSavings += itemPotentialSavings * item.quantity;
                }
                itemPotentialSavings = 0;
            });
        }
        return totalSavings;
    }
);
