import "./GroupTabOrders.scss";

import classNames from "classnames";
import React, { CSSProperties, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { GroupTabOrderBase } from "src/features/groupTabs";
import { Indexed } from "src/features/menudata";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { OrderItemRow } from "src/features/order/component/OrderItemRow";
import { OrderCourseItem } from "src/features/order/reducers/types";
import { Animate, Divider, Text } from "src/sharedComponents";
import { DownArrowRightIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { GroupTabHistoryOrderItems } from "..";
import { TaxClassTotal } from "../../order";
import { useDispatch } from "react-redux";
import { getTotalExclusiveTaxes } from "../../order/helpers";
import { groupTabHistoryActions } from "../reducers";
import { config } from "../../../common/config";
import {
    ActiveTabOrderHistoryDetails,
    TabOrderHistoryDetails,
} from "../../orderHistoryItem/components/OrderHistoryDetails";

interface GroupTabOrdersProps {
    debits: GroupTabOrderBase[];
    orders?: Indexed<GroupTabHistoryOrderItems>;
    gratuityFactor?: number;
    getOrderData: (partyId: string) => void;
    isTabActive?: boolean;
    groupTabId: string;
}

export const GroupTabOrders = ({
    debits,
    orders,
    gratuityFactor,
    getOrderData,
    isTabActive,
    groupTabId,
}: GroupTabOrdersProps) => {
    const menuDataLocale = useContext(MenuDataLocaleContext)!;
    const [ordersHeight, setOrdersHeight] = useState<{ [x: string]: number }>({});
    const [visibleOrders, setVisibleOrders] = useState<{ [x: string]: boolean }>({});
    const hasTrackedShowOrder = useRef<boolean>(false);
    const dispatch = useDispatch();
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    const trackOrderHistoryDetailsClicked = useCallback(() => {
        dispatch(groupTabHistoryActions.trackGroupTabHistoryOrderDetailsClicked(!!isTabActive, groupTabId));
    }, [dispatch, groupTabId, isTabActive]);

    const handleShowingOrder = (partyId?: string) => {
        if (partyId && (!orders || !orders[partyId])) {
            getOrderData(partyId);
        }

        const isOrderVisible = partyId ? visibleOrders[partyId] : false;

        if (partyId) {
            setVisibleOrders((prevVisibleOrders) =>
                config.enableOrderHistoryUpdate === "1"
                    ? {
                          ...prevVisibleOrders,
                          [partyId]: !isOrderVisible,
                      }
                    : { [partyId]: !isOrderVisible }
            );
        }

        if (isOrderVisible && !hasTrackedShowOrder.current) {
            hasTrackedShowOrder.current = true;
            trackOrderHistoryDetailsClicked();
        }
    };

    return (
        <>
            {debits.map((debit, index) => (
                <div key={"group-tab-order-" + debit.partyId}>
                    <div
                        className={classNames(
                            "group-tab-order__wrapper",
                            config.enableOrderHistoryUpdate === "1" && "order-history-update"
                        )}
                        style={
                            ordersHeight[debit.partyId]
                                ? ({ "--order-height": ordersHeight[debit.partyId] + "px" } as CSSProperties)
                                : undefined
                        }
                    >
                        <TappableDiv
                            className={classNames("group-tab-order", visibleOrders[debit.partyId] && "show-order")}
                            onTap={() => handleShowingOrder(debit.partyId)}
                        >
                            <Text preset="g-14" mode="bold">
                                <DownArrowRightIcon /> Order at{" "}
                                {new Date(debit.dateCreated).toLocaleTimeString("en-AU", {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                })}
                            </Text>
                            <Text preset="g-14" mode="bold" value={menuDataLocale.formatCurrency(debit.amount)} />
                        </TappableDiv>
                        {!orders || !debit.partyId || !orders[debit.partyId] ? (
                            <div
                                className={classNames(
                                    "group-tab-order__data group-tab-order__data--loading",
                                    orderHistoryUpdateEnabled && "group-tab-order__data-new"
                                )}
                            >
                                <Animate name="twoDotSpinner" className="history-list-item__loader__svg" />
                            </div>
                        ) : (
                            <div
                                className={classNames(
                                    "group-tab-order__data",
                                    orderHistoryUpdateEnabled && "group-tab-order__data-new"
                                )}
                            >
                                <GroupTabOrderItems
                                    isTabActive={!!isTabActive}
                                    orderItems={orders[debit.partyId].items}
                                    partyId={debit.partyId}
                                    surcharges={orders[debit.partyId].orderData.details?.surcharge}
                                    taxSummary={orders[debit.partyId].orderData.details?.billShare?.taxSummary}
                                    surchargePercent={
                                        orders[debit.partyId].orderData.details?.billShare?.surchargePercentage
                                    }
                                    gratuityFactor={gratuityFactor}
                                    gratuity={orders[debit.partyId].orderData.details?.tip}
                                    identifier={debit.partyId + debit.dateCreated}
                                    setHeight={
                                        !visibleOrders[debit.partyId] || !!ordersHeight[debit.partyId]
                                            ? undefined
                                            : (height: number) =>
                                                  setOrdersHeight((ordersHeight) => ({
                                                      ...ordersHeight,
                                                      [debit.partyId]: height,
                                                  }))
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {index !== debits.length - 1 && (orderHistoryUpdateEnabled || !visibleOrders[debit.partyId]) && (
                        <Divider small fullWidth />
                    )}
                </div>
            ))}
        </>
    );
};

interface GroupTabOrderItemsProps {
    orderItems: OrderCourseItem[];
    identifier: string;
    setHeight?: (height: number) => void;
    surcharges?: number;
    taxSummary?: TaxClassTotal[];
    surchargePercent?: number;
    gratuityFactor?: number;
    gratuity?: number;
    partyId: string;
    isTabActive: boolean;
}

const GroupTabOrderItems = ({
    orderItems,
    setHeight,
    identifier,
    surcharges,
    taxSummary,
    surchargePercent = 0,
    gratuityFactor = 0,
    gratuity,
    partyId,
    isTabActive,
}: GroupTabOrderItemsProps) => {
    const menuDataLocale = useContext(MenuDataLocaleContext)!;
    const orderItemsRef = useRef<HTMLDivElement>(null);
    const totalExclusiveTaxes = useMemo(() => getTotalExclusiveTaxes(taxSummary), [taxSummary]);
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    useLayoutEffect(() => {
        if (orderItemsRef.current && orderItemsRef.current.offsetHeight && setHeight) {
            setHeight(orderItemsRef.current.offsetHeight);
        }
    }, [setHeight]);

    if (!orderItems?.length) {
        return null;
    }

    return (
        <div ref={orderItemsRef} className={orderHistoryUpdateEnabled ? "" : "group-tab-order__data__items"}>
            {orderHistoryUpdateEnabled ? (
                isTabActive ? (
                    <ActiveTabOrderHistoryDetails partyId={partyId} />
                ) : (
                    <TabOrderHistoryDetails partyId={partyId} />
                )
            ) : (
                <>
                    {orderItems.map((orderItem, index) => (
                        <OrderItemRow key={`group-tab-order-item-${identifier}-${index}`} item={orderItem} />
                    ))}
                    {(!!surcharges || !!totalExclusiveTaxes || !!gratuity) && (
                        <>
                            <Divider small fullWidth className="group-tab-order__data__full-row" />
                            <div />
                            {!!surcharges && (
                                <>
                                    <Text preset="g-14" className="group-tab-order__data__title">
                                        {menuDataLocale.surchargeText}
                                        {surchargePercent > 0
                                            ? ` (${menuDataLocale.formatPercent(surchargePercent)})`
                                            : ""}
                                    </Text>
                                    <Text preset="g-14" className="group-tab-order__data__value">
                                        {menuDataLocale.formatCurrency(surcharges)}
                                    </Text>
                                </>
                            )}
                            {!!surcharges && !!totalExclusiveTaxes && <div />}
                            {!!totalExclusiveTaxes && (
                                <>
                                    <Text preset="g-14" className="group-tab-order__data__title">
                                        Taxes
                                    </Text>
                                    <Text preset="g-14" className="group-tab-order__data__value">
                                        {menuDataLocale.formatCurrency(totalExclusiveTaxes)}
                                    </Text>
                                </>
                            )}
                            {(!!surcharges || !!totalExclusiveTaxes) && !!gratuity && <div />}
                            {!!gratuity && (
                                <>
                                    <Text preset="g-14" className="group-tab-order__data__title">
                                        Venue gratuity
                                        {gratuityFactor > 0 ? ` (${menuDataLocale.formatPercent(gratuityFactor)})` : ""}
                                    </Text>
                                    <Text preset="g-14" className="group-tab-order__data__value">
                                        {menuDataLocale.formatCurrency(gratuity)}
                                    </Text>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
