import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { Button, Text } from "src/sharedComponents";
import { AvailableMembershipState, manageMembershipsActions, MembershipState } from "..";
import {
    getFeaturedMembershipState,
    getOtherConnectedMembershipsState,
    getOtherMembershipsState,
    getTotalMembershipCount,
} from "../selectors/getActiveMembershipState";
import { ManageMembershipRow } from "./ManageMembershipRow";
import { MembershipFormSignup } from "./MembershipFormSignup";
import { MembershipOAuthSignup } from "./MembershipOAuthSignup";
import { ModalContainer } from "src/common/modal";
import "./ManageMemberships.scss";
import { MembershipAuthMethods } from "../types/MembershipState";
import { MembershipIcon } from "src/sharedComponents/assets/icons";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { removeProgram } from "../actions/remove";
import { switchProgram } from "../actions/switch";
import { modalMessages } from "src/features/modalMessage/messages";
import { getConnectedMembershipState } from "../selectors/getConnectedMembershipState";
import { usePrevious } from "src/sharedComponents/common/shared";
import { useSignupMembership } from "src/features/membership/hooks/useSignupMembership";

export const ManageMemberships = () => {
    const dispatch = useDispatch();

    const totalMemberships = useSelector(getTotalMembershipCount);
    const featuredMembership = useSelector(getFeaturedMembershipState);
    const connectedMembership = useSelector(getConnectedMembershipState);
    const prevConnectedMembership = usePrevious(connectedMembership);
    const otherAvailableMemberships = useSelector(getOtherMembershipsState);
    const otherConnectedMemberships = useSelector(getOtherConnectedMembershipsState);

    const [showSignup, setSignupProgram, closeSignup, signupMembership] = useSignupMembership("ManageMemberships");

    const closeManageMemberships = useCallback(
        () => dispatch(manageMembershipsActions.showManageMemberships(false)),
        [dispatch]
    );

    useEffect(() => {
        if (
            connectedMembership &&
            (!prevConnectedMembership || connectedMembership.programId !== prevConnectedMembership.programId)
        ) {
            closeManageMemberships();
        }
    }, [connectedMembership, prevConnectedMembership, closeManageMemberships]);

    const connectMembership = useCallback(
        (membership: MembershipState) => {
            return "authentication" in membership
                ? () => setSignupProgram(membership as AvailableMembershipState, true)
                : undefined;
        },
        [setSignupProgram]
    );

    const switchMembership = useCallback(
        (membership: MembershipState, active) => {
            dispatch(
                showModalMessage(
                    modalMessages.connectMembershipModal(
                        membership.programName,
                        () => dispatch(switchProgram(membership.programId)),
                        () => dispatch(removeProgram(membership.programId)),
                        active
                    )
                )
            );
        },
        [dispatch]
    );

    return (
        <>
            <div className="manage-memberships scroll-element">
                <div className="profile-page__title">
                    <Text preset="title-28" mode={["block", "extra-bold"]}>
                        Loyalty & memberships
                    </Text>
                    <Text preset="g-14" mode="block">
                        Get your members discount whenever your order after linking your account to me&u.
                    </Text>
                </div>
                {!totalMemberships && (
                    <div className="manage-memberships__no-memberships">
                        <Text preset="g-14" mode="bold">
                            No memberships connected. Don’t worry!{" "}
                        </Text>
                        <Text preset="g-14" mode="block">
                            Next time you tap into a venue that supports membership, we’ll ask you to connect your
                            account.
                        </Text>
                    </div>
                )}
                {featuredMembership && (
                    <div className="manage-memberships__featured">
                        {featuredMembership.logoUrl ? (
                            <img
                                className="manage-memberships__featured__logo"
                                src={featuredMembership.logoUrl}
                                alt={`${featuredMembership.programName} logo`}
                            />
                        ) : (
                            <div className="manage-memberships__featured__icon">
                                <MembershipIcon />
                            </div>
                        )}
                        <Text
                            className="manage-memberships__featured__title"
                            preset="g-18"
                            mode={["block", "medium"]}
                            value={featuredMembership.programName}
                        />
                        {featuredMembership.features &&
                            featuredMembership.features.map((feature, index) => (
                                <Text
                                    className="manage-memberships__featured__features"
                                    key={"membership-feature-" + index}
                                    preset="g-14"
                                    mode="block"
                                    value={feature}
                                />
                            ))}
                        <Button
                            className="manage-memberships__featured__link"
                            value={
                                featuredMembership?.authentication?.method === MembershipAuthMethods.OPT_IN
                                    ? featuredMembership?.authentication.allowRegistration
                                        ? "Sign up"
                                        : "Activate"
                                    : "Link account"
                            }
                            onClick={connectMembership(featuredMembership)}
                        />
                    </div>
                )}
                {(connectedMembership || !!otherConnectedMemberships?.length) && (
                    <>
                        <Text className="manage-memberships__section-title" preset="g-12" mode={["block", "caps"]}>
                            Connected accounts
                        </Text>
                        {connectedMembership && (
                            <ManageMembershipRow
                                membership={connectedMembership}
                                connected
                                more={() => switchMembership(connectedMembership, true)}
                            />
                        )}
                        {!!otherConnectedMemberships?.length &&
                            otherConnectedMemberships.map((membership) => {
                                if (membership.programId === featuredMembership?.programId) return null;
                                return (
                                    <ManageMembershipRow
                                        key={membership.programId}
                                        membership={membership}
                                        more={() => switchMembership(membership, false)}
                                    />
                                );
                            })}
                    </>
                )}
                {!!otherAvailableMemberships?.length && (
                    <>
                        <Text className="manage-memberships__section-title" preset="g-12" mode={["block", "caps"]}>
                            Other supported memberships
                        </Text>
                        {otherAvailableMemberships.map((membership) => {
                            if (membership.programId === featuredMembership?.programId) return null;
                            return (
                                <ManageMembershipRow
                                    key={membership.programId}
                                    membership={membership}
                                    connect={connectMembership(membership)}
                                />
                            );
                        })}
                    </>
                )}
            </div>
            {signupMembership && (
                <ModalContainer
                    isOpen={showSignup}
                    className={{
                        base: "membership-signup-modal profile-drawer-modal",
                        afterOpen: "membership-signup-modal--after-open profile-drawer-modal--after-open",
                        beforeClose: "membership-signup-modal--before-close profile-drawer-modal--before-close",
                    }}
                    overlayClassName="ReactModal__MembershipSignup ReactModal__ProfileDrawer keyboard-unfixed"
                    bodyOpenClassName="SignUpMemberships-modal__Body--open"
                    closeTimeoutMS={250}
                >
                    <LocationThemeContainer>
                        <SimpleNavHeader
                            onBack={closeSignup}
                            customBack={`membership-signup/${signupMembership.programId}`}
                            withBorder
                        />
                        {signupMembership.authentication.method === "oauth" ? (
                            <MembershipOAuthSignup onStartFlow={closeManageMemberships} />
                        ) : (
                            <MembershipFormSignup />
                        )}
                    </LocationThemeContainer>
                </ModalContainer>
            )}
        </>
    );
};
