import * as React from "react";
import { SimpleNavHeader } from "../../../common/navigation";
import "../assets/ContactUs.scss";
import { Text, Button } from "src/sharedComponents";
import { useSelector } from "react-redux";
import { getSupportEmailAttributes } from "../selectors/supportEmail";

export const ContactUs = () => {
    const attributes = useSelector(getSupportEmailAttributes);

    return (
        <div className="flex bg-white">
            <div className="flex-top item wrapper-for-fixed-nav">
                <div className="fixed-at-top">
                    <SimpleNavHeader customBack={"contact-us"} withBorder />
                </div>
                <div className="account-page">
                    <Text preset="title-28" mode="bold">
                        Need some help?
                    </Text>
                    <Text preset="g-14" className="account-page__description">
                        Something went wrong? Have a fresh new idea for us to make me&u even better? Let us know. We’re
                        always checking our inbox and we’ll get back to you shortly.
                    </Text>
                    <Button mode="outline" brandColor>
                        <a {...attributes}>
                            <Text preset="g-16" mode="bold" className="account-page__link">
                                Email us
                            </Text>
                        </a>
                    </Button>
                </div>
            </div>
        </div>
    );
};
