import { AppDispatch } from "src/features";
import { fetchOfferDataOperation } from "../operations";
import { actionCreators } from "../reducers";
import { fetchOfferData } from "../api";
import { offerClaimFlowWizard } from "../wizard";
import { replace } from "connected-react-router";
import { IdentifierScope } from "../types";

export function getOfferData(scopeId: string, offerId: string, scope: IdentifierScope, cb?: () => void) {
    return fetchOfferDataOperation.getThunk(async (dispatch: AppDispatch) => {
        const response = await fetchOfferData(scopeId, offerId, scope, "[dntrack]");
        dispatch(actionCreators.setOffer({ ...response, scopeId, scope }));
        cb?.();
    });
}

export function getAndShowOfferData(
    scopeId: string,
    offerId: string,
    shortScope: string,
    source?: string,
    coupon?: string
) {
    return fetchOfferDataOperation.getThunk(async (dispatch: AppDispatch) => {
        var scope = shortScope === "v" ? IdentifierScope.LOCATION : IdentifierScope.GROUP;
        const response = await fetchOfferData(scopeId, offerId, scope, source);
        dispatch(actionCreators.setOffer({ ...response, coupon, scopeId, source, scope }));
        dispatch(offerClaimFlowWizard.actionCreators.start("OfferPage"));
        if (!window.location.pathname.startsWith("/offer")) {
            const s = source ? `?s=${source}` : "";
            const scopePath = shortScope ? `/${shortScope}` : "";
            dispatch(replace(`/offer${scopePath}/${scopeId}/${offerId}${s}`));
        }
    });
}
