import { SecuredPaymentInfoVisitor } from "src/features/paymentGateways/types";
import { getToastPaymentSessionData } from "../selectors";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { paymentOptions, PaymentType } from "src/common/payment";
import { createToastPaymentMethod } from "../actions/createToastPaymentMethod";

export const visitToastSecuredPaymentInfo: SecuredPaymentInfoVisitor = async (_, getState, securedPayment) => {
    const state = getState();

    const selectedPaymentMethod = getSelectedPaymentMethod(state);

    const { externalReferenceId, paymentIntentId: paymentId } = getToastPaymentSessionData(state);

    if (selectedPaymentMethod?.paymentType !== PaymentType.CARD) {
        securedPayment.token = await createToastPaymentMethod(
            selectedPaymentMethod?.paymentType === PaymentType.ADDEDCARD
                ? undefined
                : [{ label: paymentOptions.label, amount: 0 }]
        );
    }

    securedPayment.posPaymentInfo = {
        externalReferenceId,
        paymentId,
    };
};
