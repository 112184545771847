import "../assets/PromoCodeDetails.scss";

import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { PromotionsTagIcon, ClearIcon, ErrorIcon } from "../../../sharedComponents/assets/icons";
import { Text, Divider } from "../../../sharedComponents";
import { getPartyPromoCodePromotionContext, getIsConnected } from "../selectors";
import { removePromoCode } from "../../payment/actions/removePromoCode";
import { getIsRemovingPromoCode } from "../../payment/selectors";
import { NetworkConnectedTappableSpan } from "src/features/notifications/components/NetworkConnectedTappableSpan";

export const PromoCodeDetails = () => {
    const appliedPromoCode = useSelector(getPartyPromoCodePromotionContext);
    const isConnected = useSelector(getIsConnected);
    const isRemovingPromoCode = useSelector(getIsRemovingPromoCode);
    const dispatch = useDispatch();

    const isRemoveDisabled = !isConnected || isRemovingPromoCode;

    const removeCode = useCallback(() => {
        dispatch(removePromoCode());
    }, [dispatch]);

    if (!appliedPromoCode) return null;

    return (
        <>
            <Divider />
            <div className="promo-code-details">
                <span className="promo-code-details__icon">
                    <PromotionsTagIcon />
                </span>
                <div className="promo-code-details__content">
                    <Text preset="g-14" mode="bold">
                        {appliedPromoCode.code}
                    </Text>
                    <Text
                        preset="g-14"
                        className={classNames(
                            "promo-code-details__content--description",
                            !!appliedPromoCode.errorMessage && "warning"
                        )}
                    >
                        {!!appliedPromoCode.errorMessage && (
                            <ErrorIcon circleColour="#F2994A" exclamationColour="#F0F0F0" fillCircle={true} />
                        )}
                        {appliedPromoCode.errorMessage || appliedPromoCode.description}
                    </Text>
                </div>
                <NetworkConnectedTappableSpan
                    className={classNames("promo-code-details__remove", isRemoveDisabled && "disabled")}
                    onTap={isRemoveDisabled ? undefined : removeCode}
                >
                    <ClearIcon />
                </NetworkConnectedTappableSpan>
            </div>
        </>
    );
};
