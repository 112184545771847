import "./PayOnlyReceipt.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsPayingMember } from "../../payment/selectors";
import { getMarketingInfo } from "../actions/getMarketingInfo";
import { memberProfileEmail } from "../actions/memberProfileEmail";
import classNames from "classnames";
import { PayOnlyHeader, PayOnlyHeaderSpacer } from "./PayOnlyHeader";
import { PayOnlyTerms } from "./PayOnlyTerms";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Text } from "src/sharedComponents";
import { getIsOpenTable, getShowInviteToFlexTabCTA } from "../../openTable/selectors";
import { refreshTableOrder } from "../actions/refreshTableOrder";
import { ErrorIcon } from "src/sharedComponents/assets/icons";
import {
    getPayOnlyFailedToSubmitPayments,
    getPayOnlyPaymentOrder,
    getPayOnlyPaymentOrderStatus,
    getPayOnlyRemainingBalance,
} from "../selectors";
import { MemberActivity, OrderStatus } from "../../order";
import { CSSTransition } from "react-transition-group";
import { PayOnlyNotifications } from "src/features/payOnly/components/PayOnlyNotifications";
import { getOpenTableFailedToSubmitOrders, getOpenTableHasSubmittingOrder } from "../../openTable/selectors/orders";
import { PayOnlyReceiptInfo } from "./PayOnlyReceiptInfo";
import { PayOnlyNoItems, PayOnlyRefreshFailed } from "./PayOnlyBillUnavailable";
import { PayOnlyPullToRefreshLoader } from "./PayOnlyPullToRefreshLoader";
import { payOnlyPaymentStateWizard, payOnlyPaymentWizard } from "../wizards";
import { createSelector } from "reselect";
import { AppState } from "../../index";
import { openTableOrderStateWizard } from "../../openTable/wizards";
import { getProfile } from "../../accountmenu/selectors";
import { actionCreators as openTableActionCreators } from "../../openTable";
import { getPartySubmittedOrders } from "../../order/selectors";
import { actionCreators as payOnlyFlagsActionCreators } from "../reducers/payOnlyFlags";
import { PayOnlyReceiptPay } from "./PayOnlyReceiptPay";
import { PayOnlyReceiptCTAs } from "./PayOnlyReceiptCTAs";
import { InviteToFlexTabCallToAction } from "src/features/openTable/components/FlexTab/InviteToFlexTabCallToAction";

let refreshComplete: (isValid: boolean) => void = () => {};

const getReceiptState = createSelector(
    getOpenTableHasSubmittingOrder,
    getPayOnlyFailedToSubmitPayments,
    getOpenTableFailedToSubmitOrders,
    getPartySubmittedOrders,
    getPayOnlyPaymentOrder,
    getPayOnlyRemainingBalance,
    payOnlyPaymentStateWizard.getState,
    openTableOrderStateWizard.getState,
    (_: AppState, showRefreshing: boolean) => showRefreshing,
    (
        hasSubmittingOrder,
        failedToSubmitPayments,
        failedToSubmitOrders,
        submittedOrders,
        order,
        remainingBalance,
        payOnlyPaymentStateWizardState,
        openTableOrderStateWizardState,
        showRefreshing
    ) => {
        const refreshing =
            showRefreshing || !!failedToSubmitPayments.length || !!failedToSubmitOrders.length || hasSubmittingOrder;
        const refreshFailed = order?.status === OrderStatus.INVALID;
        const showPayButton = !refreshing && !!order?.items.length && !refreshFailed && remainingBalance > 0;
        const showRefreshingForMinimumTime = !payOnlyPaymentStateWizardState && !openTableOrderStateWizardState;
        const showNoItems = !order?.items.length && ((!refreshing && !refreshFailed) || !submittedOrders.length);
        return {
            refreshing: refreshing && !showNoItems,
            refreshFailed,
            showPayButton,
            showRefreshingForMinimumTime,
            showNoItems,
        };
    }
);

const PullToRefreshText = () => (
    <Text
        preset="g-14"
        mode={["bold", "block"]}
        value="Pull to refresh"
        className="pay-only-receipt__pull-to-refresh__text"
    />
);

interface Props {
    disableUnseenPayments: boolean;
}

export const PayOnlyActiveReceipt = ({ disableUnseenPayments }: Props) => {
    const dispatch = useDispatch();

    const isPayingMember = useSelector(getIsPayingMember);
    const isOpenTable = useSelector(getIsOpenTable);
    const orderStatus = useSelector(getPayOnlyPaymentOrderStatus);
    const profile = useSelector(getProfile);
    const showInviteToFlexTabCTA = useSelector(getShowInviteToFlexTabCTA);

    const [showError, setShowError] = useState(false);
    const [showRefreshing, setShowRefreshing] = useState(false);
    const [showRefreshComplete, setShowRefreshComplete] = useState(false);

    const isPaying = !!useSelector(payOnlyPaymentWizard.getState);

    const timerRef = useRef<number>();

    const onRefresh = useCallback(() => {
        setShowRefreshing(true);
        return new Promise<boolean>((resolve) => {
            refreshComplete = (isValid) => {
                resolve(isValid);
                setShowRefreshing(false);
            };
            dispatch(refreshTableOrder());
            timerRef.current = window.setTimeout(() => setShowRefreshComplete(true), 1500);
        });
    }, [dispatch]);

    const onViewMenu = useCallback(() => {
        dispatch(openTableActionCreators.setActivity(MemberActivity.Ordering, true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getMarketingInfo());
        return () => {
            clearTimeout(timerRef.current);
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(memberProfileEmail());
    }, [dispatch, profile]);

    useEffect(() => {
        if (!showRefreshComplete) return;

        if (orderStatus === OrderStatus.VALID) {
            refreshComplete(true);
            setShowRefreshComplete(false);
        }

        if (orderStatus === OrderStatus.INVALID) {
            setShowRefreshComplete(false);
            setShowError(true);
            timerRef.current = window.setTimeout(() => {
                refreshComplete(false);
                setShowError(false);
            }, 1500);
        }
    }, [showRefreshComplete, orderStatus]);

    const { refreshing, refreshFailed, showPayButton, showRefreshingForMinimumTime, showNoItems } = useSelector(
        (state: AppState) => getReceiptState(state, showRefreshing)
    );

    useEffect(() => {
        dispatch(payOnlyFlagsActionCreators.setRefreshing(refreshing || (refreshFailed && !showNoItems)));
    }, [dispatch, refreshing, refreshFailed, showNoItems]);

    useEffect(() => {
        if (isOpenTable && orderStatus === OrderStatus.VALIDATING && !showRefreshing) {
            setShowRefreshing(true);
            refreshComplete = () => {
                setShowRefreshing(false);
            };
            if (!showRefreshingForMinimumTime) {
                setShowRefreshComplete(true);
            } else {
                timerRef.current = window.setTimeout(() => setShowRefreshComplete(true), 1500);
            }
        }
    }, [isOpenTable, orderStatus, showRefreshing, showRefreshingForMinimumTime]);

    return (
        <>
            <PayOnlyHeader disableUnseenPayments={disableUnseenPayments} />
            <PayOnlyHeaderSpacer />
            <PullToRefresh
                isPullable={!refreshing}
                onRefresh={onRefresh}
                className={classNames("pay-only-receipt", {
                    "pay-only-receipt--extra-padding": isOpenTable,
                    "paying-member": isPayingMember,
                    "refresh-failed": refreshFailed || showNoItems,
                })}
                pullDownThreshold={40}
                resistance={3}
                pullingContent={<PullToRefreshText />}
                refreshingContent={
                    <div className="pay-only-receipt__pull-to-refresh__transition-parent">
                        <CSSTransition in={!showError} classNames="fade" timeout={250} unmountOnExit>
                            <PayOnlyPullToRefreshLoader showRefreshing={showRefreshing} />
                        </CSSTransition>
                        <CSSTransition in={showError} classNames="fade" timeout={250} unmountOnExit>
                            <div className="pay-only-receipt__pull-to-refresh__error">
                                <ErrorIcon circleColour="#FF832B" exclamationColour="#FFFFFF" fillCircle={true} />
                                <Text preset="g-14" mode={["bold", "block"]} value="Couldn’t refresh data" />
                            </div>
                        </CSSTransition>
                    </div>
                }
            >
                {showNoItems ? (
                    <PayOnlyNoItems onClick={onViewMenu} />
                ) : !refreshing && refreshFailed ? (
                    <PayOnlyRefreshFailed onClick={onRefresh} />
                ) : (
                    <>
                        <PayOnlyNotifications />
                        {showInviteToFlexTabCTA ? (
                            <div className="pay-only-receipt__invite-to-flex-tab-cta">
                                <InviteToFlexTabCallToAction />
                            </div>
                        ) : (
                            <div className="pay-only-receipt__spacer" />
                        )}
                        <PayOnlyReceiptInfo refreshing={refreshing} />
                        {showPayButton && <PayOnlyTerms />}
                    </>
                )}
            </PullToRefresh>
            {!showNoItems && !refreshFailed && (
                <>
                    {isOpenTable && !isPaying ? (
                        <PayOnlyReceiptCTAs hidden={!showPayButton} canStartPayment={onRefresh} />
                    ) : (
                        <PayOnlyReceiptPay hidden={!showPayButton} canStartPayment={onRefresh} />
                    )}
                </>
            )}
        </>
    );
};
