import "./PayOnlyReceiptCTAs.scss";

import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Button } from "src/sharedComponents";
import { actionCreators } from "src/common/navigation/reducers/navBar";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { NavBarType } from "src/common/navigation/types";

import { PayOnlyPayButton } from "./PayOnlyPayButton";

interface Props {
    hidden: boolean;
    canStartPayment?: () => Promise<boolean>;
}

export const PayOnlyReceiptCTAs = ({ hidden, canStartPayment }: Props) => {
    const dispatch = useDispatch();

    const handleOnOrderAgain = () => {
        dispatch(trackedEvent({ type: "OPEN_TABLE/ORDER_AGAIN_CTA" }));
        dispatch(actionCreators.activeType(NavBarType.MENU));
    };

    return (
        <div className={classNames("pay-only-receipt-ctas", hidden && "hidden")}>
            <Button onClick={handleOnOrderAgain}>View menu</Button>
            <PayOnlyPayButton unthemed mode="outline" disabled={hidden} canStartPayment={canStartPayment} />
        </div>
    );
};
