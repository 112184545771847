import "./PayOnlySplitByItem.scss";

import React, { useCallback } from "react";
import { Text } from "src/sharedComponents";
import { useDispatch } from "react-redux";
import { OrderCourseItem } from "src/features/order/reducers/types";
import { OrderItems } from "src/features/order/component/OrderItems";
import { updateItemSelection } from "../actions/updateItemSelection";

export interface Props {
    visibleItems: OrderCourseItem[];
}

export const PayOnlySplitByItem = ({ visibleItems }: Props) => {
    const dispatch = useDispatch();

    const handleSelectItem = useCallback(
        (referenceId: string, quantity: number) => {
            dispatch(updateItemSelection(referenceId, quantity));
        },
        [dispatch]
    );

    return (
        <div className="pay-only-split-by-item">
            <div className="pay-only-select-split-options__title">
                <Text preset="title-28" mode="bold">
                    Select unpaid items
                </Text>
                <Text
                    preset="g-14"
                    mode="block"
                    className="pay-only-select-split-options__subtitle"
                    value="Item prices may not include fees, taxes or discounts."
                />
            </div>
            <OrderItems selectable items={visibleItems} onSelectItem={handleSelectItem} />
        </div>
    );
};
