import { createSelector } from "reselect";
import { getSelectedPaymentMethod } from "./paymentMethods";
import { evenRound } from "../../../sharedComponents/common/utils";

export const getProcessingFeePercentage = createSelector(getSelectedPaymentMethod, (paymentMethod) =>
    paymentMethod && "processingFee" in paymentMethod ? paymentMethod.processingFee : 0
);

export const getProcessingFeeBaseAmount = createSelector(getSelectedPaymentMethod, (paymentMethod) =>
    paymentMethod && "processingFeeBaseAmount" in paymentMethod ? paymentMethod.processingFeeBaseAmount : 0
);

export const calculateProcessingFee = (feePercentage: number, feeBaseAmount: number, total: number) => {
    if (total === 0) return 0;
    return evenRound(total * feePercentage + feeBaseAmount, 2);
};
