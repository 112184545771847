import {
    AmexInstrumentIcon,
    ApplePayInstrumentIcon,
    ChargeToRoomInstrumentIcon,
    CreditCardInstrumentIcon,
    GooglePayInstrumentIcon,
    MasterCardInstrumentIcon,
    PayPalInstrumentIcon,
    TabInstrumentIcon,
    VisaInstrumentIcon,
} from "../../../sharedComponents/assets/icons";
import React from "react";

export const getPaymentMethodText = (
    paymentMethod?: string | null,
    cardType?: string | null,
    paymentMethodDescription?: string | null
) => {
    switch (paymentMethod) {
        case "PayPalCheckout":
            return (
                <>
                    <PayPalInstrumentIcon /> PayPal
                </>
            );
        case "GooglePay":
            return (
                <>
                    <GooglePayInstrumentIcon /> {paymentMethodDescription || "Google Pay"}
                </>
            );
        case "ApplePay":
            return (
                <>
                    <ApplePayInstrumentIcon /> {paymentMethodDescription || "Apple Pay"}
                </>
            );
        case "Card":
        case "AddedCard":
            if (paymentMethodDescription) {
                switch (cardType) {
                    case "Visa":
                        return (
                            <>
                                <VisaInstrumentIcon /> {paymentMethodDescription}
                            </>
                        );
                    case "MasterCard":
                        return (
                            <>
                                <MasterCardInstrumentIcon /> {paymentMethodDescription}
                            </>
                        );
                    case "American Express":
                        return (
                            <>
                                <AmexInstrumentIcon /> {paymentMethodDescription}
                            </>
                        );
                    default:
                        return (
                            <>
                                <CreditCardInstrumentIcon /> {paymentMethodDescription}
                            </>
                        );
                }
            }
            return null;
        case "GroupTab":
            return (
                <>
                    <TabInstrumentIcon /> {paymentMethodDescription}
                </>
            );
        case "Pos":
            return (
                <>
                    <ChargeToRoomInstrumentIcon /> {paymentMethodDescription}
                </>
            );
    }
    return null;
};
