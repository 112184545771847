import "../assets/ScheduleTakeaway.scss";
import React, { useState } from "react";
import { Button, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getTakeawayScheduleSlots } from "../selectors/takeawayOptions";
import { ScheduleServiceTimes } from "./ScheduleServiceTimes";
import { sendScheduleTime } from "../actions/sendScheduledTime";
import { actionCreators } from "../reducers/takeawayOptions";

export const ScheduleTakeaway = () => {
    const [selectedTime, setSelectedTime] = useState<string | Date | null>(null);
    const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
    const dispatch = useDispatch();

    const allServiceSlots = useSelector(getTakeawayScheduleSlots);

    if (!allServiceSlots?.length) return null;

    const submitTakeawayScheduleTime = () => {
        dispatch(actionCreators.trackCollectionTimeSet());
        dispatch(sendScheduleTime(selectedTime, selectedServiceId));
    };

    const onServiceSelection = (timeSlot: string | Date, serviceId: string) => {
        setSelectedTime(timeSlot);
        setSelectedServiceId(serviceId);
    };

    return (
        <>
            <div className="schedule-takeaway">
                <Text preset="title-28" mode={["block", "bold"]}>
                    Choose a pickup time
                </Text>
                <Text preset="g-14" mode="block">
                    The availability of some menu items may change depending on your chosen pickup time.
                </Text>
                <div className="schedule-takeaway__services">
                    {allServiceSlots.map((service) => (
                        <ScheduleServiceTimes
                            service={service}
                            selectedTime={selectedTime}
                            onServiceSelection={onServiceSelection}
                            key={`${service.serviceStartTime}-${service.serviceId}`}
                        />
                    ))}
                </div>
            </div>

            <div className="schedule-takeaway__choose-time">
                <Button
                    disabled={!selectedTime}
                    className="schedule-takeaway__choose-time-button"
                    onClick={submitTakeawayScheduleTime}
                >
                    Choose collection time
                </Button>
            </div>
        </>
    );
};
