import "../assets/TipSelectionModal.scss";

import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { ModalContainer } from "../../../common/modal";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { TipSelection } from "../../order/component/TipSelection";
import { CustomTip } from "../../order/component/CustomTip";
import { getCustomTip, getShowTipModal } from "../../payment/selectors";

interface Props {
    onClose: () => void;
    onAfterClose?: () => void;
    renderCallToAction: (disabled: boolean) => JSX.Element;
    tipRecipients?: string;
    calculatedTipAmount?: number;
    calculatedTipPercentage?: number;
}

export const TipSelectionModal = ({
    renderCallToAction,
    tipRecipients,
    onClose,
    onAfterClose,
    calculatedTipAmount,
    calculatedTipPercentage,
}: Props) => {
    const show = useSelector(getShowTipModal);
    const customTip = useSelector(getCustomTip);

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "tip-selection-modal",
                afterOpen: "tip-selection-modal--after-open",
                beforeClose: "tip-selection-modal--before-close",
            }}
            overlayClassName="ReactModal__TipSelection"
            onRequestClose={onClose}
            closeTimeoutMS={150}
            onAfterClose={onAfterClose}
        >
            <LocationThemeContainer>
                <div className="tip-selection">
                    <CSSTransition classNames="fade" timeout={250} in={!customTip} unmountOnExit>
                        <TipSelection
                            renderPaymentButton={renderCallToAction}
                            onBack={onClose}
                            tipRecipients={tipRecipients}
                            calculatedTipAmount={calculatedTipAmount}
                        />
                    </CSSTransition>
                    <CSSTransition classNames="fade" timeout={250} in={!!customTip} unmountOnExit>
                        <CustomTip
                            renderPaymentButton={renderCallToAction}
                            calculatedTipAmount={calculatedTipAmount}
                            calculatedTipPercentage={calculatedTipPercentage}
                        />
                    </CSSTransition>
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
