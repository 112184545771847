export enum NavBarType {
    MENU = 0,
    PROFILE = 1,
    GROUP_TABS = 2,
    PAY = 3,
    OFFERS = 4,
}

export interface TooltipProps {
    show: boolean;
    onClose?: () => void;
    text: string;
}

export interface NavBarElement {
    type: NavBarType;
    text: string;
    title?: string;
}

export interface NavBarElementWithTooltip extends NavBarElement {
    tooltipProps: TooltipProps;
}
