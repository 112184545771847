export interface CartState {
    showCart: boolean;
}

export interface State {
    cart: CartState;
}

enum TypeKeys {
    SHOW_CART = "CART/SHOW_CART",
}

export const actionCreators = {
    showCart: (show: boolean) => ({ type: TypeKeys.SHOW_CART, show }),
};

export type ShowCartAction = { type: TypeKeys.SHOW_CART; show: boolean };

export type CartActions = ShowCartAction;

const initialState = {
    showCart: false,
};

export function reducer(state: CartState = initialState, action: CartActions): CartState {
    if (action.type === TypeKeys.SHOW_CART) {
        return {
            ...state,
            showCart: action.show,
        };
    }

    return state;
}
