import { createOperation } from "../operations";
import { OrderHistoryData } from "./reducers/types";

export const getMealHistoryOperation = createOperation<OrderHistoryData[]>("HISTORYDATA/GET_MEAL_HISTORY");

export const getMealHistoryDetailsOperation = createOperation<OrderHistoryData>("HISTORYDATA/GET_PARTY_MEAL_HISTORY");

export const markMealHistoryAsReadOperation = createOperation<OrderHistoryData>("HISTORYDATA/MARK_MEAL_HISTORY_READ");
export const markMealHistoryAsCollectedOperation = createOperation<OrderHistoryData>(
    "HISTORYDATA/MARK_MEAL_HISTORY_COLLECTED"
);

export const fetchOrderHistoryOperation = createOperation<void>("HISTORYDATA/SET_MEAL_HISTORY");
