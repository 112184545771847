import { AppDispatch } from "../../index";
import { actionCreators as pendingItemAction } from "../reducers/pendingItem";
import { actionCreators as easyReorderActions } from "../../easyReorderItem/reducers/easyReorder";
import { pagePositionActions } from "../../pagePosition/reducers";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { submitPaymentAndOrderOperation } from "../../payment/operations";
import { orderAgainActions } from "../../orderAgain";
import { submitOpenTableOrderOperation } from "../../openTable/operations";
import { makePayOnlyPaymentOperation } from "../../payOnly/operations";

export function resetOrderingState() {
    return (dispatch: AppDispatch) => {
        dispatch(pendingItemAction.resetPendingItem());
        dispatch(easyReorderActions.resetEasyReorderItems());
        dispatch(pagePositionActions.resetServicePagePosition());
        dispatch(navBarActionCreators.reset());
        dispatch(submitPaymentAndOrderOperation.actionCreators.reset());
        dispatch(submitOpenTableOrderOperation.actionCreators.reset());
        dispatch(makePayOnlyPaymentOperation.actionCreators.reset());
        dispatch(orderAgainActions.resetAnotherRound());
    };
}
