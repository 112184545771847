import { createSelector } from "reselect";
import { getAvailableFilters } from "./getAvailableFilters";
import { getFilteringState } from "./getFilteringState";
import { FilteringState } from "../reducers";
import { CategoryIndexed, SelectedFilters, TagValueMap, AvailableFilters } from "../types";

export const getSelectedFiltersByCategory = createSelector(
    getAvailableFilters,
    getFilteringState,
    (availableFilters, filteringState) => {
        return Object.keys(availableFilters).reduce((map, categoryId) => {
            map[categoryId] = createCategoryEditableFilter(categoryId, availableFilters[categoryId], filteringState);

            return map;
        }, {} as CategoryIndexed<SelectedFilters>);
    }
);

function createCategoryEditableFilter(
    categoryId: string,
    availableFilters: AvailableFilters,
    state: FilteringState
): SelectedFilters {
    const { categoryFilters, preferences } = state;

    const currentCategoryFilters = categoryFilters[categoryId] || {
        tags: {},
    };

    const tags = availableFilters.tagGroups.reduce((map, tagGroup) => {
        map[tagGroup.id] = tagGroup.isUserPreference
            ? preferences[tagGroup.id] || []
            : currentCategoryFilters.tags[tagGroup.id] || [];

        return map;
    }, {} as TagValueMap);

    const price = currentCategoryFilters.price;

    return {
        tags,
        price,
    };
}
