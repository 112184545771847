import { MembershipSignupPromotion } from "src/features/membership/types/MembershipState";
import { trackedEvent } from "src/common/events/reduxEventTracking";

enum TypeKeys {
    SET_SIGNUP_PROMOTION = "SIGNUP_PROMOTION/SET_ACTIVE",
    SHOW_SIGNUP_PROMOTION = "SIGNUP_PROMOTION/SHOW",
    SIGNUP_PROMOTION_CTA_CLICKED = "SIGNUP_PROMOTION/CTA_CLICKED",
}

export type SignupPromotionState = {
    showSignupPromotionModal: boolean;
    signupPromotion?: MembershipSignupPromotion;
};

export const signupPromotionActions = {
    setSignupPromotion: (signupPromotion?: MembershipSignupPromotion) => ({
        type: TypeKeys.SET_SIGNUP_PROMOTION,
        signupPromotion,
    }),
    showSignupPromotionModal: (show: boolean) => ({ type: TypeKeys.SHOW_SIGNUP_PROMOTION, show }),
    signupPromotionCtaClicked: (programId?: string) =>
        trackedEvent({ type: TypeKeys.SIGNUP_PROMOTION_CTA_CLICKED, program_id: programId }),
};

type SetSignupPromotionAction = { type: TypeKeys.SET_SIGNUP_PROMOTION; signupPromotion?: MembershipSignupPromotion };
type ShowSignupPromotionAction = { type: TypeKeys.SHOW_SIGNUP_PROMOTION; show: boolean };

type SignupPromotionAction = SetSignupPromotionAction | ShowSignupPromotionAction;

const defaultState: SignupPromotionState = {
    showSignupPromotionModal: false,
};

export default function reducer(
    state: SignupPromotionState = defaultState,
    action: SignupPromotionAction
): SignupPromotionState {
    if (action.type === TypeKeys.SET_SIGNUP_PROMOTION) {
        return {
            ...state,
            signupPromotion: action.signupPromotion,
            showSignupPromotionModal: false,
        };
    }

    if (action.type === TypeKeys.SHOW_SIGNUP_PROMOTION) {
        return {
            ...state,
            showSignupPromotionModal: action.show,
        };
    }

    return state;
}
