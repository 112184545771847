import React, { ReactNode, useEffect, useState, useCallback, useRef } from "react";
import classNames from "classnames";
import { Text } from "../text";

import "./Banner.scss";

export enum BannerEvent {
    DYNAMIC = "dynamic",
    STATIC = "static",
}

export enum BannerType {
    DEFAULT = "default",
    PRIMARY = "primary",
    WARNING = "warning",
    ERROR = "error",
}

export interface BannerNotification {
    type?: BannerType;
    event: BannerEvent;
    title: string;
    icon?: ReactNode;
    show?: boolean;
}

export interface BannerProps extends BannerNotification {
    removeBanner: () => void;
    noAnimation: boolean;
}

const BANNER_TIMEOUT = 5000;

export const Banner = ({
    type = BannerType.DEFAULT,
    title,
    event,
    icon,
    show,
    removeBanner,
    noAnimation,
}: BannerProps) => {
    const [showing, setShowing] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [canShow, setCanShow] = useState(true);
    const bannerRef = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<Animation | null>(null);

    const startRemoving = useCallback(() => {
        setRemoving(true);
        if (!!bannerRef.current && !noAnimation) {
            if (!!bannerRef.current.animate) {
                animationRef.current = bannerRef.current.animate(
                    [{ transform: "translate3d(0, 0, 0)" }, { transform: "translate3d(0, -100%, 0)" }],
                    {
                        duration: 150,
                    }
                );

                animationRef.current.oncancel = () => {
                    animationRef.current = null;
                    removeBanner();
                };
                animationRef.current.onfinish = () => {
                    animationRef.current = null;
                    removeBanner();
                };
            } else {
                removeBanner();
            }
        }
    }, [setRemoving, removeBanner, animationRef, bannerRef, noAnimation]);

    useEffect(
        () => () => {
            if (animationRef.current) {
                animationRef.current.cancel();
            }
        },
        [animationRef]
    );

    useEffect(() => {
        let timer: number | undefined;
        if (show && canShow && !showing && !removing) {
            setShowing(true);
            if (!!bannerRef.current && !!bannerRef.current.animate && !noAnimation) {
                bannerRef.current.animate(
                    [{ transform: "translate3d(0, -100%, 0)" }, { transform: "translate3d(0, 0, 0)" }],
                    {
                        duration: 150,
                    }
                );
            }
        } else if (!show && !showing && !removing) {
            setCanShow(true);
        } else if (show && showing && !removing && event === BannerEvent.DYNAMIC) {
            timer = window.setTimeout(startRemoving, BANNER_TIMEOUT);
        } else if (!show && showing && !removing && event === BannerEvent.STATIC) {
            startRemoving();
        }

        return () => {
            clearTimeout(timer);
        };
    }, [show, removing, showing, canShow, startRemoving, setShowing, setCanShow, event, title, noAnimation]);

    return (
        <div ref={bannerRef} className={classNames("banner", `banner--${type}`, showing && "show")}>
            {icon}
            <Text preset="g-14" mode="medium" value={title} />
        </div>
    );
};
