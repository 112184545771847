import "./PayOnlyGetReceipt.scss";

import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Text } from "src/sharedComponents";
import { TappableAnchor, TappableSpan } from "src/sharedComponents/common/tappable";
import { LegalModal } from "src/features/compliance/components/LegalModal";
import { href } from "src/common/experience";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../reducers/payOnlyGetReceipt";
import { getPayOnlyGetReceiptState } from "../selectors";
import { EmailInput } from "src/common/email/components/EmailInput";
import { isValidEmail } from "src/common/validation";
import { ComponentActions } from "src/features/modalMessage/reducers/modalMessage";
import { actionCreators as accountActionCreators } from "src/features/accounts/reducers/authState";

interface Props extends ComponentActions {}

export const PayOnlyGetReceipt = ({ validate }: Props) => {
    const { email, receive, newsletterProvider, privacyPolicyUrl, title } = useSelector(getPayOnlyGetReceiptState);
    const [openPrivacy, setOpenPrivacy] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        validate(!!email && isValidEmail(email));
    }, [email, validate, dispatch]);

    const setEmail = useCallback(
        (email: string, isValid: boolean) => {
            dispatch(actionCreators.setEmail(email));
            validate(isValid);
        },
        [dispatch, validate]
    );

    const setReceive = useCallback(
        (checked: boolean) => {
            dispatch(actionCreators.setMarketingOptIn(checked));
        },
        [dispatch]
    );

    const onPrivacyPolicyClicked = useCallback(
        (venuePolicy: boolean) => {
            if (venuePolicy) {
                dispatch(accountActionCreators.onPrivacyPolicyClicked(true, privacyPolicyUrl));
            }
            dispatch(accountActionCreators.onPrivacyPolicyClicked(false));
        },
        [dispatch, privacyPolicyUrl]
    );

    return (
        <div className="get-receipt">
            <EmailInput initialEmail={email} infoOnChange={setEmail} placeholder="Email" />
            {newsletterProvider && (
                <Checkbox
                    id={"marketing-opt-in"}
                    checked={receive}
                    onChange={setReceive}
                    className="get-receipt__marketing"
                    label={
                        <div>
                            <Text preset="g-14" value={title || "I also want to receive venue promotions"} />
                            <Text preset="g-12" mode="block" className="get-receipt__marketing__description">
                                By signing up, you will be subscribed to the {newsletterProvider} newsletter. View our{" "}
                                <TappableSpan
                                    className="get-receipt__marketing__description__link"
                                    shouldPreventDefault
                                    onTap={() => {
                                        onPrivacyPolicyClicked(false);
                                        setOpenPrivacy(true);
                                    }}
                                >
                                    Privacy Policy
                                </TappableSpan>
                                {privacyPolicyUrl ? (
                                    <>
                                        {" "}
                                        and the{" "}
                                        <TappableAnchor
                                            className="get-receipt__marketing__description__link"
                                            {...href.openInNewWindowAttributes(privacyPolicyUrl)}
                                            onTap={() => onPrivacyPolicyClicked(true)}
                                        >
                                            Privacy Policy
                                        </TappableAnchor>{" "}
                                        of the venue.
                                    </>
                                ) : (
                                    "."
                                )}
                            </Text>
                        </div>
                    }
                />
            )}
            <LegalModal legalPage={openPrivacy ? "privacyPolicy" : undefined} goBack={() => setOpenPrivacy(false)} />
        </div>
    );
};
