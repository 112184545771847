import "../assets/GetHelpButton.scss";
import { ModalMessagePrimaryButton } from "src/features/modalMessage/reducers/modalMessage";
import { href } from "src/common/experience";
import { Button, Text } from "src/sharedComponents";
import * as React from "react";

interface Props extends ModalMessagePrimaryButton {
    helpUrl: string;
}

export const GetHelpButton = ({ closeAndAction, helpUrl }: Props) => (
    <Button className="get-help-button" mode="outline" unthemed>
        <a {...href.openInNewWindowAttributes(helpUrl)} onClick={() => closeAndAction?.()}>
            <Text preset="g-16" mode="bold">
                Get help
            </Text>
        </a>
    </Button>
);
