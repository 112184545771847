import { AppDispatch, AppState } from "src/features";
import { connect } from "react-redux";
import { ProfileDrawer, Props } from "../components/ProfileDrawer";
import { fetchProfile } from "../actions";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";

interface OwnProps {
    open: boolean;
}

export function mapStateToProps(state: AppState, { open }: OwnProps): DataProps<Props> {
    const {
        accountMenu: { defaultView },
        navBar: { navBarStatus },
        profile,
    } = state;

    return {
        defaultView,
        navBarType: navBarStatus,
        open,
        profileLoaded: profile.status === "success" && !!profile.result?.firstName,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        fetchProfile: () =>
            dispatch(
                fetchProfile(
                    () => {},
                    () => {
                        dispatch(showModalMessage(modalMessages.noNetworkFound()));
                        dispatch(accountMenuActionCreators.closeAccountMenu());
                    }
                )
            ),
    };
}

export const ProfileDrawerContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileDrawer);
