import "../assets/OrderSavingsModal.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Price } from "src/features/menu/components/Price";
import {
    getLocationPromotionsEnabled,
    getMemberDiscountAmount,
    getMemberMembershipDiscountAmount,
    getMemberOfferDiscountAmount,
    getMemberPromoCode,
    getMemberSubTotal,
} from "src/features/payment/selectors";
import { Button, Divider, Text } from "src/sharedComponents";
import { MembershipIcon, PromotionsTagIcon } from "src/sharedComponents/assets/icons";
import { confirmOrder } from "../actions/confirmOrder";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { BackButton } from "src/common/navigation";
import { Confetti } from "src/sharedComponents/controls/confetti";
import { PaymentItem } from "../../payment/components/PaymentItem";
import { ModalContainer } from "src/common/modal";
import { getIsConnectedToMembershipWithSavings } from "src/features/membership/selectors/getVenueMembershipState";
import { getIsEngageEnabled } from "../selectors";
import { getConnectedMembershipState } from "../../membership/selectors/getConnectedMembershipState";
import { actionCreators as cartActionCreators } from "src/features/order/reducers/cart";
import { MINIMUM_ORDER_VALUE } from "src/features/order/component/CartViewModal";

interface Props {
    showIfNeeded: boolean;
    onClose: () => void;
}

const AUTO_CONTINUE_TIMEOUT = 10;

export const OrderSavingsModal = ({ showIfNeeded, onClose }: Props) => {
    const memberSavings = useSelector(getMemberMembershipDiscountAmount);
    const memberSubtotal = useSelector(getMemberSubTotal);
    const venueDiscount = useSelector(getMemberDiscountAmount);
    const connectedMembership = useSelector(getConnectedMembershipState);
    const promoCodesEnabled = useSelector(getLocationPromotionsEnabled);
    const isConnectedToVenueMembership = useSelector(getIsConnectedToMembershipWithSavings);
    const offerDiscountSavings = useSelector(getMemberOfferDiscountAmount);
    const engageEnabled = useSelector(getIsEngageEnabled);

    const appliedPromoCode = useSelector(getMemberPromoCode);
    const promoCodeDiscount = promoCodesEnabled ? appliedPromoCode?.value || 0 : 0;

    // When promoCodesEnabled is removed, merge this value with hasSavings below to return total line items and use that for determining hasSavings
    const hasMultipleLineItems = useMemo(() => {
        if (!promoCodesEnabled) return true;
        let lineItemCount = 0;
        if (memberSavings < 0) lineItemCount++;
        if (venueDiscount < 0) lineItemCount++;
        if (promoCodeDiscount < 0) lineItemCount++;
        if (offerDiscountSavings < 0) lineItemCount++;
        return lineItemCount > 1;
    }, [memberSavings, venueDiscount, promoCodeDiscount, promoCodesEnabled, offerDiscountSavings]);

    const hasSavings = useMemo(() => {
        return (
            memberSavings < 0 ||
            venueDiscount < 0 ||
            (offerDiscountSavings < 0 && engageEnabled) ||
            (promoCodeDiscount < 0 && promoCodesEnabled)
        );
    }, [memberSavings, venueDiscount, promoCodeDiscount, promoCodesEnabled, offerDiscountSavings, engageEnabled]);

    const savingsText = useMemo(() => {
        if (offerDiscountSavings) {
            return "Woo! Your offer has been added to this order";
        }
        if (memberSavings) {
            if (connectedMembership?.discountAppliedText) {
                return connectedMembership.discountAppliedText;
            }
            if (isConnectedToVenueMembership && connectedMembership) {
                return `${connectedMembership.programName} offers have been applied to your order`;
            }
            return "Benefits have been applied to your order";
        }
        if (appliedPromoCode && promoCodesEnabled) {
            return "Promo code has been applied to your order";
        }
        return "Offers have been applied to your order";
    }, [
        memberSavings,
        appliedPromoCode,
        promoCodesEnabled,
        isConnectedToVenueMembership,
        connectedMembership,
        offerDiscountSavings,
    ]);

    const [show, setShow] = useState(
        showIfNeeded && memberSavings < 0 && venueDiscount < 0 && (promoCodesEnabled ? promoCodeDiscount < 0 : true)
    );
    const [fire, setFire] = useState(false);
    const dispatch = useDispatch();

    const confirm = useCallback(() => {
        setShow(false);
        const timeout = window.setTimeout(() => dispatch(confirmOrder()), 200);
        return () => {
            window.clearTimeout(timeout);
        };
    }, [dispatch]);

    const viewMenu = useCallback(() => {
        setShow(false);
        dispatch(cartActionCreators.showCart(false));
    }, [dispatch]);

    const closeWithoutContinue = useCallback(() => {
        setShow(false);
    }, []);

    useEffect(() => {
        return () => {
            onClose();
            setFire(false);
        };
    }, [onClose]);

    useEffect(() => {
        let fireTimeout = 0;
        if (showIfNeeded) {
            if (hasSavings) {
                setShow(true);
                window.setTimeout(() => setFire(true), 150);
            } else {
                dispatch(confirmOrder());
                setShow(false);
                onClose();
            }
        }
        return () => {
            window.clearTimeout(fireTimeout);
        };
    }, [showIfNeeded, dispatch, onClose, hasSavings]);

    if (!hasSavings) {
        return null;
    }

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "order-saving-modal",
                afterOpen: "order-saving-modal--after-open",
                beforeClose: "order-saving-modal--before-close",
            }}
            overlayClassName="ReactModal__OrderSaving"
            closeTimeoutMS={250}
            onAfterClose={() => {
                setFire(false);
                onClose && onClose();
            }}
        >
            <LocationThemeContainer>
                <Confetti fire={fire} full y={hasMultipleLineItems ? 0.5 : 0.7} />
                <div className="order-saving-modal__content">
                    <TappableDiv onClick={closeWithoutContinue} className="order-saving-modal__close">
                        <BackButton showAsClose customBack={"membership-savings-modal"} onBack={closeWithoutContinue} />
                    </TappableDiv>
                    <div className="order-saving-modal__header">
                        <>
                            {memberSavings >= 0 && (appliedPromoCode || offerDiscountSavings < 0) && (
                                <span className="order-saving-modal__header__icon">
                                    <PromotionsTagIcon />
                                </span>
                            )}
                            {memberSavings < 0 &&
                                (connectedMembership?.logoUrl ? (
                                    <img
                                        className="order-saving-modal__header__image"
                                        src={connectedMembership.logoUrl}
                                        alt="Membership logo"
                                    />
                                ) : (
                                    <div className="order-saving-modal__header__generic-icon">
                                        <MembershipIcon />
                                    </div>
                                ))}
                            <Text preset="title-24" mode="bold" className="order-saving-modal__header__title">
                                You’re saving{" "}
                                <Price
                                    price={Math.abs(
                                        venueDiscount + memberSavings + promoCodeDiscount + offerDiscountSavings
                                    )}
                                />
                            </Text>
                        </>

                        <Text preset="g-14" className="order-saving-modal__header__savings-text">
                            {savingsText}
                        </Text>
                        {memberSubtotal < MINIMUM_ORDER_VALUE && (
                            <div className="order-saving-modal__header__below-minimum">
                                <Price
                                    priceTextPreset="g-16"
                                    priceTextMode={["bold", "block"]}
                                    price={MINIMUM_ORDER_VALUE}
                                    prefix="A minimum order of "
                                    suffix=" is required."
                                />
                                <Text
                                    preset="g-16"
                                    mode={["bold", "block"]}
                                    value="Please add more items to your cart, or seek assistance from our staff."
                                />
                            </div>
                        )}
                    </div>
                    {hasMultipleLineItems && (
                        <>
                            <Divider fullWidth />
                            <div className="payment-detail">
                                {!!memberSavings && (
                                    <PaymentItem
                                        title="Deals"
                                        value={memberSavings}
                                        extraClass="payment-item--member-discount"
                                        titleSuffix={<MembershipIcon />}
                                    />
                                )}
                                {!!venueDiscount && <PaymentItem title="Venue discount" value={venueDiscount} />}
                                {!!appliedPromoCode && promoCodesEnabled && (
                                    <PaymentItem
                                        extraClass="payment-item--promotion-discount"
                                        title={appliedPromoCode.name}
                                        value={appliedPromoCode.value}
                                        titleSuffix={<PromotionsTagIcon />}
                                    />
                                )}
                                {!promoCodesEnabled && (
                                    <PaymentItem title="Subtotal" value={memberSubtotal} bold showZeroValue />
                                )}
                            </div>
                        </>
                    )}
                    {memberSubtotal < MINIMUM_ORDER_VALUE ? (
                        <Button value="Add more items" onClick={viewMenu} className="order-saving-modal__button" />
                    ) : (
                        <Button
                            value="Continue to checkout"
                            startCountDown
                            countDownSeconds={AUTO_CONTINUE_TIMEOUT}
                            onClick={confirm}
                            className="order-saving-modal__button"
                        />
                    )}
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
