import "../assets/OrderHistoryDetails.scss";

import React, { useContext, useMemo } from "react";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { Text } from "src/sharedComponents";
import classNames from "classnames";
import { getPaymentMethodText } from "../../payment/util/getPaymentMethodText";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";
import moment from "moment";
import { MembershipIcon } from "../../../sharedComponents/assets/icons";

export interface OrderPaymentsProps {
    tip?: number;
    processingFee?: number;
    totalRefunded?: number;
    paymentIsOutstanding?: boolean;
    paymentMethod?: string | null;
    cardType?: string | null;
    paymentMethodDescription?: string | null;
    total: number;
    amountAdded?: number;
    time?: string;
    isTipOnlyPayment?: boolean;
    noTopPadding?: boolean;
    amount?: number;
    pointsSpendAmount?: number;
}

export const OrderPayments = ({
    tip,
    processingFee,
    totalRefunded,
    paymentIsOutstanding,
    paymentMethod,
    cardType,
    paymentMethodDescription,
    total,
    amountAdded,
    time,
    isTipOnlyPayment,
    noTopPadding,
    amount,
    pointsSpendAmount,
}: OrderPaymentsProps) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);

    const timeDisplay = useMemo(() => (time ? moment(time).format("h:mm a") : ""), [time]);
    const paymentElemt = paymentMethod
        ? getPaymentMethodText(paymentMethod, cardType || null, paymentMethodDescription)
        : null;

    return (
        <Text preset="g-14" mode="block" className="order-history-details">
            {!!paymentElemt && (
                <div className={classNames("order-history-details__payment-method", noTopPadding && "no-top-padding")}>
                    {paymentElemt}
                </div>
            )}
            {!!time && (
                <div className="order-history-details__value">
                    <span>Time</span>
                    <span>{timeDisplay}</span>
                </div>
            )}
            {!!amountAdded && (
                <div className="order-history-details__value">
                    <span>{isTipOnlyPayment ? "Tip" : "Amount added"}</span>
                    <span>{menuDataLocale.formatCurrency(amountAdded)}</span>
                </div>
            )}
            {!!amount && (
                <div className="order-history-details__value">
                    <span>Amount</span>
                    <span>{menuDataLocale.formatCurrency(amount)}</span>
                </div>
            )}
            {!!pointsSpendAmount && (
                <div className="order-history-details__value order-history-details__value--member-discount">
                    <span>
                        Points <MembershipIcon />
                    </span>
                    <span>{menuDataLocale.formatCurrency(-1 * Math.abs(pointsSpendAmount))}</span>
                </div>
            )}
            {!!tip && paymentMethod !== "GroupTab" && (
                <div className="order-history-details__value">
                    <span>Tip</span>
                    <span>{menuDataLocale.formatCurrency(tip)}</span>
                </div>
            )}
            {!!processingFee && (
                <div className="order-history-details__value">
                    <span>{getProcessingFeeText(menuDataLocale, paymentMethod)}</span>
                    <span>{menuDataLocale.formatCurrency(processingFee)}</span>
                </div>
            )}
            {!!totalRefunded && (
                <div className="order-history-details__value order-history-details__value--refunded">
                    <span>Refund</span>
                    <span>{menuDataLocale.formatCurrency(totalRefunded * -1)}</span>
                </div>
            )}
            <div className={classNames("order-history-details__value", paymentIsOutstanding && "outstanding")}>
                <Text preset="g-16" mode={["bold", "block"]}>
                    {paymentIsOutstanding ? "Outstanding balance" : "Paid"}
                </Text>
                <Text preset="g-16" mode={["bold", "block"]}>
                    {menuDataLocale.formatCurrency(total)}
                </Text>
            </div>
        </Text>
    );
};
