// This import can be shortened to "../../order"
// but doing so crashes the app so leave it as is
import { actionCreators } from "../../order/reducers/party";

import { AppDispatch, AppState } from "../..";
import { orderApi } from "../../order/orderApi";
import { leavingTableOperation } from "../operations";
import { deleteTableToken } from "../persistence/tableToken";
import { cleanPartyClosed } from "src/features/order/actions/partyClosed";
import { getParty } from "src/features/order";

const innerLeaveParty = async (getState: () => AppState) => {
    const party = getParty(getState());

    if (!party) {
        return;
    }

    await orderApi.invoke("leaveParty");

    return party.id;
};

export function leaveTable() {
    deleteTableToken("left");

    return leavingTableOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const partyId = await innerLeaveParty(getState);

        if (!partyId) return;

        dispatch(actionCreators.leftParty(partyId));
        dispatch(cleanPartyClosed());
    });
}
