import { trackedEvent } from "../../../common/events/reduxEventTracking";

enum TypeKeys {
    LOGIN_SUCCEEDED = "PARTY/MEMBERSHIP_LOGIN_SUCCEEDED",
    LOGIN_FAILED = "PARTY/MEMBERSHIP_LOGIN_FAILED",
    MEMBERSHIP_REMOVED = "PARTY/MEMBERSHIP_REMOVED",
}

export const actionCreators = {
    trackLoginSucceeded: (membership_program_id: string) =>
        trackedEvent({ type: TypeKeys.LOGIN_SUCCEEDED, membership_program_id }),
    trackLoginFailed: (membership_program_id: string, reason?: string) =>
        trackedEvent({ type: TypeKeys.LOGIN_FAILED, membership_program_id, reason }),
    trackMembershipRemoved: (membershipProgramId: string) =>
        trackedEvent({ type: TypeKeys.MEMBERSHIP_REMOVED, membership_program_id: membershipProgramId }),
};
