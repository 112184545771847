import { AppDispatch, AppState } from "../..";
import { rejoiningPayOnlyPartyOperation } from "../operations";
import { disconnectParty, joinParty, verifyParty } from "../api";
import { deleteTableToken } from "../persistence/tableToken";
import { getRestaurantFlagsOperation } from "../../order/operations";
import { getABTests } from "../../abtests/actions/getABTests";
import { closeAllModals } from "./helper";
import { getPaymentMethodsBackground } from "../../payment/actions/getPaymentMethods";
import { profileActionCreators } from "../../accountmenu";
import { getPayOnlyToken } from "../../payOnly/persistence/payOnlyToken";
import { joinPayOnlyParty } from "./joinPayOnlyParty";
import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export function rejoinPayOnlyParty(tableToken: string) {
    return rejoiningPayOnlyPartyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(closeAllModals());
            await disconnectParty(dispatch, false, "none");

            const { restaurantFlags, profile } = await verifyParty(tableToken);
            dispatch(getRestaurantFlagsOperation.actionCreators.completed(restaurantFlags));
            dispatch(profileActionCreators.fetchSuccess(profile));
            dispatch(getABTests());

            await joinParty(getState, dispatch, tableToken);

            dispatch(getPaymentMethodsBackground());
        } catch (err) {
            getAppInsights()?.trackException({
                exception: err as Error,
                severityLevel: SeverityLevel.Error,
            })
            deleteTableToken("failed_rejoin_pay");
            const payOnlyToken = getPayOnlyToken();
            if (payOnlyToken) {
                dispatch(joinPayOnlyParty([{ token: payOnlyToken, type: "qr" }]));
            } else {
                throw err;
            }
        }
    });
}
