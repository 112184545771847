import { config } from "src/common/config";
import { cordovaWindow } from "src/common/experience/native/cordova";

const recaptchaSiteKey: string | undefined = config.recaptchaSiteKey;
const recaptchaEnabled: boolean = !!recaptchaSiteKey;

export const reCaptcha = {
    enabled: recaptchaEnabled,
    execute: async (action: string) => {
        if (!recaptchaSiteKey) {
            return null;
        }

        return await new Promise<string | null>((res) => {
            cordovaWindow.NativeRecaptcha.executeAction(recaptchaSiteKey, action, res, () => res(null));
        });
    },
};
