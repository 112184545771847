import { orderHistoryActions, TypeKeys } from "../reducers";
import { actionCreators as mealHistoryActionCreators, MealHistoryActions } from "../reducers/mealHistory";
import { AppDispatch, AppMiddleware, AppState } from "../..";
import { MealHistoryOrderStatus } from "..";
import {
    fetchMealHistory,
    fetchOngoingPartiesMealHistory,
    fetchPartyMealHistoryData,
} from "../actions/fetchMealHistory";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { __ } from "../../../common/strings";
import { PaymentFlowActions, TypeKeys as PaymentTypeKeys } from "src/features/payment/reducers/paymentFlow";
import { completeSignIn } from "../../signup/actions/completeSignIn";
import { hideKeyboard, isKeyboardOpen, waitForKeyboardClose } from "../../../common/keyboard";
import { getJoinGroupTabCode } from "src/features/groupTabs/selectors/joinGroupTab";
import { JoinGroupTabActions, TypeKeys as JoinGroupTabTypeKeys } from "src/features/groupTabs/reducers/joinGroupTab";
import { getOngoingOrderHistories } from "../selectors";

let mealHistoryPolling: number | null = null;

export const mealHistoryNotificationsMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    const handleOrderHistoryNotifications = () => (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const {
            orderHistoryData: { displayedError, orderHistoryData: orderHistoryList },
            paymentFlow: { showWizard },
        } = state;
        const groupTabCode = getJoinGroupTabCode(state);

        if (showWizard) return;

        const failedUnReadOrderHistories = orderHistoryList.filter((order) => {
            return (
                order &&
                !order.readError &&
                !displayedError.includes(order.partyId) &&
                order.mealHistoryOrders &&
                order.mealHistoryOrders.some(
                    (mho) =>
                        mho.status === MealHistoryOrderStatus.REJECTED ||
                        mho.status === MealHistoryOrderStatus.ORPHANED ||
                        mho.status === MealHistoryOrderStatus.SUBMIT_FAILED ||
                        ([MealHistoryOrderStatus.COMPLETED, MealHistoryOrderStatus.SUBMITTED].includes(mho.status) &&
                            order.someItemsFailed)
                )
            );
        });

        const inCompleteOrderHistories = getOngoingOrderHistories(state);

        if (failedUnReadOrderHistories.length && !groupTabCode) {
            next(mealHistoryActionCreators.addDisplayedError(failedUnReadOrderHistories.map((fo) => fo.partyId)));

            if (failedUnReadOrderHistories.length === 1 && !failedUnReadOrderHistories[0].details) {
                // Warm up order history modal by pre-selecting party ID
                next(orderHistoryActions.selectPartyMealHistory(failedUnReadOrderHistories[0].partyId, false));
                next(fetchPartyMealHistoryData(failedUnReadOrderHistories[0].partyId));
            }

            const openOrderHistoryAction =
                failedUnReadOrderHistories.length === 1
                    ? () => {
                          const {
                              orderHistoryData: { orderHistoryData: orderHistoryList },
                          } = getState();
                          const history = orderHistoryList.find(
                              (item) => item.partyId === failedUnReadOrderHistories[0].partyId
                          );
                          history &&
                              !history.readError &&
                              next(orderHistoryActions.selectPartyMealHistory(history.partyId, true));
                      }
                    : () => next(accountMenuActionCreators.openAccountMenu("OrderHistory"));

            const primaryActionText = __("Review order", "Review orders", failedUnReadOrderHistories.length);

            const primaryAction = async () => {
                const {
                    signIn: { page },
                } = getState();

                await waitForKeyboardClose();

                if (page) {
                    // Close the SignInWizard if it's open before showing this error as the meal history
                    // should be viewable at this point rather than being hidden behind the signin modal
                    // Also Back on signin causes the meal history to close first because of history
                    next(completeSignIn());
                    setTimeout(openOrderHistoryAction, 400);
                } else {
                    openOrderHistoryAction();
                }
            };

            if (isKeyboardOpen()) {
                hideKeyboard();
            }

            if (
                !failedUnReadOrderHistories.some(
                    (order) =>
                        order.mealHistoryOrders!.some(
                            (mh) =>
                                mh.status === MealHistoryOrderStatus.REJECTED ||
                                mh.status === MealHistoryOrderStatus.ORPHANED
                        ) || order.allItemsFailed
                )
            ) {
                next(showModalMessage(modalMessages.someItemsFailedToSend(primaryAction, primaryActionText)));
            } else {
                next(showModalMessage(modalMessages.orderNotConfirmed(primaryAction, primaryActionText)));
            }
        }

        if (inCompleteOrderHistories.length > 0) {
            waitForMealHistoryUpdate(inCompleteOrderHistories.map((o) => o.partyId));
            if (inCompleteOrderHistories.length === 1 && !inCompleteOrderHistories[0].details) {
                next(fetchPartyMealHistoryData(inCompleteOrderHistories[0].partyId));
            }
        }

        if (
            (inCompleteOrderHistories.length > 1 && inCompleteOrderHistories.some((h) => !h.summary)) ||
            (failedUnReadOrderHistories.length > 1 && failedUnReadOrderHistories.some((h) => !h.summary))
        ) {
            next(fetchMealHistory(null));
        }
    };

    function waitForMealHistoryUpdate(inCompleteOrderPartiesId: string[]) {
        mealHistoryPolling && window.clearTimeout(mealHistoryPolling);
        mealHistoryPolling = window.setTimeout(() => {
            next(fetchOngoingPartiesMealHistory(inCompleteOrderPartiesId));
        }, 7000);
    }

    function waitForParty(partyId: string | null) {
        const {
            orderHistoryData: { orderHistoryData },
        } = store.getState();
        if (partyId && !orderHistoryData.some((data) => data.partyId === partyId)) {
            // we need to force update this party history incase the lastUpdated is later then the created of it
            next(fetchOngoingPartiesMealHistory([partyId], true));
        }
    }

    return (action: MealHistoryActions | PaymentFlowActions | JoinGroupTabActions) => {
        next(action);
        if (
            action.type === TypeKeys.GET_MEAL_HISTORY_COMPLETE ||
            action.type === TypeKeys.GET_PARTIES_MEAL_HISTORY_COMPLETE ||
            action.type === PaymentTypeKeys.PAYMENT_FLOW_DONE ||
            action.type === PaymentTypeKeys.PREV_PAYMENT_PAGE ||
            action.type === JoinGroupTabTypeKeys.GROUP_TAB_RESET_JOIN ||
            (action.type === JoinGroupTabTypeKeys.GROUP_TAB_SET_CODE && !action.joinCode)
        ) {
            next(handleOrderHistoryNotifications());
        }
        if (action.type === TypeKeys.SELECT_PARTY_MEAL_HISTORY) {
            waitForParty(action.partyId);
        }
        if (action.type === PaymentTypeKeys.PAYMENT_FLOW_START) {
            mealHistoryPolling && window.clearTimeout(mealHistoryPolling);
        }
    };
};
