import {
    BaseTelemetryPlugin,
    IAppInsights,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
    SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { AppState } from "../..";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { getActionProperties, IReduxActionPlugin } from "./IReduxActionPlugin";
import { findAppInsightsPlugin } from "../util/findAppInsightsPlugin";
import { AnyAction } from "redux";
import { normalizeError } from "src/common/error";
import { TRACK_ERROR_PROPERTY, TRACK_EVENT_PROPERTY } from "../../../common/events/reduxEventTracking";

export interface ReduxTelemetryEnhancer {
    (event: ITelemetryItem, getState: () => AppState): void;
}

export class ReduxActionEventPlugin extends BaseTelemetryPlugin implements IReduxActionPlugin {
    public identifier = "MeanduReduxActionEventPlugin";

    private appInsights: IAppInsights | undefined = undefined;

    private getState: (() => AppState) | null = null;

    private bufferedActions: AnyAction[] = [];

    constructor(private enhancers: ReduxTelemetryEnhancer[]) {
        super();
    }

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        this.appInsights = findAppInsightsPlugin(extensions);

        if (this.appInsights && this.bufferedActions.length && this.getState) {
            while (this.bufferedActions.length > 0) {
                this.handleReduxAction(this.bufferedActions.shift()!, this.getState);
            }
        } else {
            this.bufferedActions = [];
        }
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        if (this.getState !== null) {
            for (let i = 0; i < this.enhancers.length; i++) {
                this.enhancers[i](event, this.getState);
            }
        }

        this.processNext(event, itemCtx!);
    }

    handleReduxAction(action: AnyAction, state: () => AppState, buffer?: boolean) {
        if (this.getState === null) {
            this.getState = state;
        }

        if (!this.appInsights) {
            if (buffer) {
                this.bufferedActions.push(action);
            } else if (this.bufferedActions.length) {
                this.bufferedActions = [];
            }
            return;
        }

        if (action.type && action.type.startsWith("@")) {
            return;
        }

        const properties = getActionProperties(action);

        if (action[TRACK_EVENT_PROPERTY] === true) {
            this.appInsights.trackEvent({
                name: action.type,
                properties,
            });
            return;
        }

        if (/(ERROR|FAILURE|FAILED)$/.test(action.type) || action[TRACK_ERROR_PROPERTY] === true) {
            if (action.error !== undefined) {
                this.appInsights.trackException(
                    {
                        exception: normalizeError(action.error),
                        severityLevel: SeverityLevel.Error,
                    },
                    properties
                );
            }

            this.appInsights.trackTrace({
                message: action.type,
                properties,
                severityLevel: SeverityLevel.Error,
            });

            return;
        }
    }
}
