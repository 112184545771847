import { SplitPaymentOption } from "src/features/order/reducers/types";
import { PaymentShare } from "src/features/payment";

export type SelectedItems = { [key: string]: number };

export interface PayOnlyState {
    amount: number;
    share?: PaymentShare;
    splitOption?: SplitPaymentOption;
    selectedItems: SelectedItems;
}

export enum TypeKeys {
    SET_AMOUNT = "PAY_ONLY/SET_AMOUNT",
    SET_SHARE = "PAY_ONLY/SET_SHARE",
    SET_SPLIT_OPTION = "PAY_ONLY/SET_SPLIT_OPTION",
    SET_SELECTED_ITEMS = "PAY_ONLY/SET_SELECTED_ITEMS",
    RESET = "PAY_ONLY/RESET",
}

export const actionCreators = {
    setAmount: (amount: number) => ({ type: TypeKeys.SET_AMOUNT, amount }),
    setShare: (amount: number, share?: PaymentShare) => ({ type: TypeKeys.SET_SHARE, amount, share }),
    setSplitOption: (option?: SplitPaymentOption) => ({ type: TypeKeys.SET_SPLIT_OPTION, option }),
    setSelectedItems: (selectedItems: SelectedItems) => ({ type: TypeKeys.SET_SELECTED_ITEMS, selectedItems }),
    reset: () => ({ type: TypeKeys.RESET }),
};

type SetAmountAction = { type: TypeKeys.SET_AMOUNT; amount: number };
type SetShareAction = { type: TypeKeys.SET_SHARE; amount: number; share?: PaymentShare };
type SetSplitOptionAction = { type: TypeKeys.SET_SPLIT_OPTION; option?: SplitPaymentOption };
type SetSelectedItemsAction = { type: TypeKeys.SET_SELECTED_ITEMS; selectedItems: SelectedItems };
type ResetAction = { type: TypeKeys.RESET };

export type PayOnlyAction =
    | SetAmountAction
    | SetShareAction
    | SetSplitOptionAction
    | SetSelectedItemsAction
    | ResetAction;

const initialState: PayOnlyState = {
    amount: 0,
    selectedItems: {},
};

export function reducer(state: PayOnlyState = initialState, action: PayOnlyAction): PayOnlyState {
    if (action.type === TypeKeys.SET_AMOUNT) {
        return {
            ...state,
            amount: action.amount,
        };
    }

    if (action.type === TypeKeys.SET_SHARE) {
        return {
            ...state,
            amount: action.amount,
            share: action.share,
        };
    }

    if (action.type === TypeKeys.SET_SPLIT_OPTION) {
        return {
            ...state,
            splitOption: action.option,
        };
    }

    if (action.type === TypeKeys.SET_SELECTED_ITEMS) {
        return {
            ...state,
            selectedItems: action.selectedItems,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return {
            ...initialState,
        };
    }

    return state;
}
