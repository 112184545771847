import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { OrderHistoryData, OrderHistoryResponse } from "..";
import { getAuthHeaders } from "../../../common/auth";
import { FetchError } from "src/features/order/orderApi/FetchError";

let lastUpdateTime: string | null;
export function clearUpdateTime() {
    lastUpdateTime = null;
}

export async function getMealHistory(lastServiceDate: string | null, limit: number = 13) {
    const response = await orderApi.send(
        `/meal/all?lastServiceDate=${lastServiceDate}&limit=${limit}`,
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const data = await response.json();

    return {
        hasMore: data.length === limit,
        data,
    } as OrderHistoryResponse;
}

export async function getPartyMealHistoryData(partyId: string) {
    const headers = await getAuthHeaders();
    const response = await orderApi.send(
        `/meal/${partyId}`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }
    const data = await response.json();

    if (data) {
        lastUpdateTime = response.headers.get("Date");
    }
    return data as OrderHistoryData;
}

export async function getAllOngoingMealHistory() {
    const headers = await getAuthHeaders();
    const response = await orderApi.send(
        `/meal/ongoing`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const data = await response.json();

    if (data?.length) {
        lastUpdateTime = response.headers.get("Date");
    }

    return data as OrderHistoryData[];
}

export async function getOngoingPartiesMealHistory(partiesId: string[], forceUpdate?: boolean) {
    const headers = await getAuthHeaders();
    const response = await orderApi.send(
        `/meal/ongoing?partyIds=${partiesId}&lastUpdateTime=${forceUpdate ? null : lastUpdateTime}`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const data = (await response.json()) as OrderHistoryData[];

    if (data?.length) {
        lastUpdateTime = response.headers.get("Date");
    }

    return data;
}

export async function markMealHistoryAsRead(partyId: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/meal/${partyId}/markreaderror`, {
        method: "POST",
        headers,
    });

    if (!response.ok) {
        throw response;
    }

    lastUpdateTime = response.headers.get("Date");
    const data = await response.json();

    if (!data) {
        throw new Error(`Mark history error as read for ${partyId} has failed`);
    }

    return data as OrderHistoryData;
}

export async function markMealHistoryAsCollected(partyId: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/meal/${partyId}/collected`, {
        method: "POST",
        headers,
    });

    if (!response.ok) {
        throw new FetchError(response);
    }

    lastUpdateTime = response.headers.get("Date");
    const data = await response.json();

    if (!data) {
        throw new Error(`Mark history as accepted for ${partyId} has failed`);
    }

    return data as OrderHistoryData;
}
