import { device } from "./device";

const LEFT_SPACE = 25;
const RIGHT_SPACE = 15;

const preventSwipeBack = (e: TouchEvent) => {
    if (e.touches.length > 1 || (e.target as HTMLElement)?.classList.contains("allow-swipe-back")) return;

    const { pageX } = e.touches[0];

    const ratio = window.innerWidth / window.outerWidth;
    const minPageX = window.pageXOffset + LEFT_SPACE * ratio;
    const maxPageX = window.pageXOffset + window.innerWidth - RIGHT_SPACE * ratio;

    if (pageX < minPageX || pageX > maxPageX) {
        e.preventDefault();
    }
};

export const navigation = {
    preventSwipeBack: () => {
        if (device.isIOS) {
            window.addEventListener("touchstart", preventSwipeBack, { passive: false, capture: true });
        }
    },
};
