export class InvocationPool {
    private idlePromise: Promise<void> = Promise.resolve();
    private invocationCount: number = 0;
    private resolveIdlePromise?: () => void;

    increment() {
        if (this.invocationCount === 0) {
            this.idlePromise = new Promise<void>((executor) => {
                this.resolveIdlePromise = executor;
            });
        }

        this.invocationCount++;
    }

    decrement() {
        this.invocationCount--;

        if (this.invocationCount === 0 && this.resolveIdlePromise) {
            this.resolveIdlePromise();
        }
    }

    waitForIdle(): Promise<void> {
        return this.idlePromise;
    }

    async wrap<T>(promise: () => Promise<T>): Promise<T> {
        this.increment();
        try {
            return await promise();
        } finally {
            this.decrement();
        }
    }
}
