import { Party } from "src/features/order";
import { device, deviceReady } from "../experience";
import { normalizeError } from "../error";
import { isOrderAndPayParty } from "src/features/order/helpers";

export * from "./colors";
export * from "./env";
export * from "./poll";

export const getActualTableNumber = (tableNumber: string, isOrderAndPay: boolean) =>
    isOrderAndPay ? tableNumber.split("-")[0] : tableNumber;

export const getTableNumber = (party: Party | null) =>
    party ? getActualTableNumber(party.tableNumber, isOrderAndPayParty(party)) : undefined;

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const timeoutAndError = async (ms: number, err: string | Error) => {
    await timeout(ms);
    throw normalizeError(err);
};

export const getPropertyValueRecursive = (propertyNameParts: string[], object: any): any => {
    const propertyName = propertyNameParts[0];

    if (!(propertyName in object)) return undefined;

    const propertyValue = object[propertyName];

    if (propertyNameParts.length === 1) return propertyValue;

    return getPropertyValueRecursive(propertyNameParts.slice(1), propertyValue);
};

interface Attributes {
    [id: string]: string;
}

const scriptsInFlight: { [url: string]: Promise<void> } = {};

export const fetchExternalScript = (url: string, attributes?: Attributes) =>
    scriptsInFlight[url] ??
    (scriptsInFlight[url] = new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = false;
        attributes && Object.keys(attributes).forEach((name) => script.setAttribute(name, attributes[name]));
        script.onload = () => resolve();
        script.onerror = reject;
        document.getElementsByTagName("head")[0].appendChild(script);
    })).finally(() => delete scriptsInFlight[url]);

export const loadExternalScript = async (windowPropertyName: string, url: string, attributes?: Attributes) => {
    const propertyName = windowPropertyName.split(".");
    let prop = getPropertyValueRecursive(propertyName, window);
    if (prop) return prop;
    await fetchExternalScript(url, attributes);
    prop = getPropertyValueRecursive(propertyName, window);
    if (prop) return prop;
    throw new Error("Expected property was not added by loading this external script");
};

export const unloadExternalScript = (
    windowPropertyName: string,
    baseUrl: string,
    shouldUnload?: (fullUrl: string) => boolean,
    beforeRemoveProperty?: (windowPropertyValue: any) => void
) => {
    if (!(windowPropertyName in window)) return;

    const scripts = Array.from(document.getElementsByTagName("script"));

    const script = scripts.filter((s) => s.src.indexOf(baseUrl) === 0)[0];

    if (!script || (shouldUnload && !shouldUnload(script.src))) return;

    script.remove();

    if (beforeRemoveProperty) {
        beforeRemoveProperty(window[windowPropertyName]);
    }

    try {
        delete window[windowPropertyName];
    } catch {
        window[windowPropertyName] = undefined;
    }
};

export const setValueWithEvent = (input: HTMLInputElement, value?: string) => {
    const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set!;
    valueSetter.call(input, value);
    input.dispatchEvent(new Event("input", { bubbles: true }));
};

export const setSelectValueWithEvent = (select: HTMLSelectElement, value?: string) => {
    const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, "value")!.set!;
    valueSetter.call(select, value);
    select.dispatchEvent(new Event("change", { bubbles: true }));
};
export const trimInput = (input: HTMLInputElement) => (input.value = input.value.trim());

export const initDeviceStyle = {
    deviceType: () => {
        deviceReady().then(() => {
            document.body.classList.add(device.isIOS ? "ios" : "android");
            document.body.classList.add(device.isMobile ? "mobile" : "tablet");
        });
    },
    deviceHeight: () => {
        deviceReady().then(() => {
            document.body.style.setProperty("--device-height", window.innerHeight + "px");
        });
    },
};

export const triggerVirtualPageTrack = (detail: any) => {
    document.dispatchEvent(new CustomEvent("virtualState", { detail }));
};

export const scrollElementToParentCentre = (element: Element | null, parent: Element | null) => {
    if (!element || !(element instanceof HTMLElement) || !parent || !(parent instanceof HTMLElement)) return;

    let top = 0;
    let el: HTMLElement | null = element;

    for (
        ;
        el && el !== parent && el !== document.body;
        top += el.offsetTop, el = el.offsetParent as HTMLElement | null
    ) {}

    top = top - parent.clientHeight / 2 + element.clientHeight / 2;

    parent.scroll({ top, behavior: "smooth" });
};

export function parsePrice(price?: string): number {
    return price ? parseFloat(price.replace(/[^\d.]/g, "")) : 0;
}

export const preloadImage = (url: string) =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
    });

export async function waitMinimum<T>(promise: Promise<T>, minWaitMilliseconds: number): Promise<T> {
    const result = await Promise.all([promise, timeout(minWaitMilliseconds)]);
    return result[0] as T;
}

//diacritics
export function normalizeAccents(data?: string) {
    return data?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || data;
}

export interface IntersectionObserverRef {
    data?: IntersectionObserver;
}
