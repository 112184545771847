import { createSelector } from "reselect";
import { TagGroup } from "../../menudata";
import { FilteringState } from "../reducers";
import { TagValueMap, SelectedFilters } from "../types";
import { getFilteringState } from "./getFilteringState";
import { getUserPreferenceFilters } from "./getUserPreferenceFilters";

export const getSelectedUserPreferencesFilters = createSelector(
    getUserPreferenceFilters,
    getFilteringState,
    (userPreferenceFilters, filteringState) =>
        createUserPreferencesEditableFilter(userPreferenceFilters.tagGroups, filteringState)
);

function createUserPreferencesEditableFilter(tagGroups: TagGroup[], state: FilteringState): SelectedFilters {
    const { preferences } = state;

    const tags = tagGroups
        .filter((group) => group.isUserPreference)
        .reduce((map, group) => {
            map[group.id] = preferences[group.id] || [];
            return map;
        }, {} as TagValueMap);

    return {
        tags,
    };
}
