import { VisibleModifierOption } from "src/features/menudata";
import { OrderItemModifier } from "../types";

export interface OrderSummary {
    food?: OrderCourseItem[];
    drinks?: OrderCourseItem[];
}

export interface OrderCourse {
    displayName: string;
    items: OrderCourseItem[];
    totalQuantity: number;
}

export interface OrderCourseItem {
    orderItemId: string | null;
    menuItemId?: string;
    displayName: string;
    variant?: number | null;
    variantName?: string;
    notes?: string;
    modifierOptions?: VisibleModifierOption[] | null;
    modifiers?: OrderItemModifier[] | null;
    quantity: number;
    price: number | null;
    submitted: boolean;
    canDelete?: boolean;
    type?: "food" | "drink";
    memberId?: string;
    failedToSubmit: boolean;
    waitTime?: number;
    referenceId?: string;
}

export enum TipLevel {
    NODEFAULT = -1,
    NOTIP = 0,
    LEVEL1 = 1,
    LEVEL2 = 2,
    LEVEL3 = 3,
    ROUNDUP = 4,
}

export type SplitPaymentOption = "SelectAmount" | "SplitEvenly" | "SelectItems";

export interface RestaurantFlags {
    allowNotesToKitchen: boolean;
    confirmEighteenPlus: boolean;
    allowTips: boolean;
    defaultTipLevel?: TipLevel | null;
    mustAcceptAllergenNotice: boolean;
    quickSellEnabled: boolean;
    quickSellDrinksOnly: boolean;
    facebookPixelId?: string;
    groupTabsEnabled: boolean;
    ageRestriction?: number;
    promotionsEnabled: boolean;
    splitPaymentOptions?: SplitPaymentOption[];
    groupTabGratuityFactor?: number;
    enableOpenTabs?: boolean;
    allowTipOnly?: boolean;
    salesMode: boolean;
    openTableBatchTimeSeconds: number;
    enableOrderHeadCount?: boolean;
    customFeedbackUrl?: string;
    customFeedbackButtonText?: string;
    dietaryInformationUrl?: string;
    googleTagManagerId?: string;
    bespokeModalTitle?: string;
    bespokeModalText?: string;
    requireEmail?: boolean;
}

export enum OrderCampaigns {
    FEATURED_ITEM = "FeaturedItem",
    ANOTHER_ROUND = "AnotherRound",
    QUICK_SELL = "QuickSell",
    MEMBERSHIP = "Membership",
}

export interface OrderHeadCount {
    showOrderHeadCountModal: boolean;
    orderHeadCount: number;
}
