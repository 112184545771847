import * as React from "react";
import { OrderHistoryData } from "../../orderHistoryData";
import { OrderNotificationIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import "../assets/ToastOrderHistoryStatus.scss";
import { IoIosArrowForward } from "react-icons/io";

export interface Props {
    isReady: boolean;
    dontShow: boolean;
    orderHistory: OrderHistoryData[];
    goToHistory: (partyId: string) => void;
}

export class ToastOrderHistoryStatus extends React.Component<Props> {
    goToHistory = () => {
        const { orderHistory, goToHistory } = this.props;
        goToHistory(orderHistory.length > 1 ? "" : orderHistory[0].partyId);
    };
    render() {
        const { isReady, orderHistory, dontShow } = this.props;
        if (dontShow || !orderHistory.length || !isReady) return null;

        return (
            <TappableDiv className={"toast-order-status toast-order-status__ready"} onTap={this.goToHistory}>
                <div className="toast-order-status__ready__icon">
                    <OrderNotificationIcon />
                </div>
                <div className="toast-order-status__ready__title">Order is ready to collect</div>
                <div className="toast-order-status__ready__description">Tap for more information</div>
                <div className="toast-order-status__ready__arrow">
                    <IoIosArrowForward />
                </div>
            </TappableDiv>
        );
    }
}
