import "./TabDisclaimer.scss";

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getTabTypeName } from "../selectors/activeGroupTab";
import { Disclaimer } from "src/sharedComponents";

interface Props {
    className?: string;
}

export const TabDisclaimer = ({ className }: Props) => {
    const tabTypeName = useSelector(getTabTypeName);

    const bodyText = useMemo(
        () =>
            `You are the Owner responsible for this ${tabTypeName}. You can set a ${tabTypeName} limit, and share a ${tabTypeName} code with your friends. Anyone with your code can use your ${tabTypeName}. You can remove people from your ${tabTypeName} at any time, but please be aware me&u or the Venue cannot offer you a refund if the ${tabTypeName} is shared with or used by people you haven’t authorised.`,
        [tabTypeName]
    );

    return <Disclaimer bodyText={bodyText} className={className} />;
};
