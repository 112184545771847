import { AppDispatch, AppState } from "src/features";
import { createVerifiedAccountActions } from "../reducers";
import { CreatedVerifiedAccountPageDetails } from "../reducers/createVerifiedAccount";
import { getProfile } from "../../accountmenu/selectors";

export const startCreateVerifiedAccountAction = (pageDetails?: CreatedVerifiedAccountPageDetails) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const profile = getProfile(getState());

        if (!!pageDetails) {
            dispatch(createVerifiedAccountActions.setWizardPageDetails(pageDetails));
        }

        dispatch(createVerifiedAccountActions.showPage(!profile?.phone ? "AccountDetails" : "Verify"));
    };
};
