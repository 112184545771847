import { browserSupport } from "src/common/experience";

const KEY = "ACTIVE_INSTANCE";

const thisInstanceId: string = new Date().getTime().toString();

export function activateInstance() {
    if (!browserSupport.isSupportedBrowser) return;
    window.localStorage.setItem(KEY, thisInstanceId);
}

let deactivatedHandler: Function | null = null;

export function setDeactivatedHandler(handler: Function | null) {
    deactivatedHandler = handler;
}

window.addEventListener("storage", () => {
    if (!browserSupport.isSupportedBrowser) return;
    const newToken = window.localStorage.getItem(KEY);

    if (newToken !== thisInstanceId && deactivatedHandler) {
        deactivatedHandler();
    }
});
