import { Text } from "src/sharedComponents";
import classNames from "classnames";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import * as React from "react";

interface Props {
    onClick: () => void;
    title: string;
    open: boolean;
    canClose?: boolean;
}

export const MenuItemExpandableHeader = ({ onClick, title, open, canClose = true }: Props) => {
    return (
        <TappableDiv
            className={classNames("menuitemcard-note__heading", !canClose && "menuitemcard-note__heading--no-close")}
            onClick={onClick}
        >
            <Text preset="title-20" mode="extra-bold" value={title} />
            <div className="menuitemcard-note__heading__icon">
                <div
                    className={classNames(
                        "menuitemcard-note__heading__icon__arrow",
                        open && "menuitemcard-note__heading__icon__arrow--open"
                    )}
                />
            </div>
        </TappableDiv>
    );
};
