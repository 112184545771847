import * as React from "react";
import { DrinkCardContainer } from "../containers/DrinkCardContainer";
import { FoodCardContainer } from "../containers/FoodCardContainer";

export interface Props {
    type: "food" | "drink";
    menuItemId: string;
    showFromPrice?: boolean;
    quantity?: number;
}

export const MenuItemCard = ({ type, menuItemId, showFromPrice, quantity }: Props) =>
    type === "drink" ? (
        <DrinkCardContainer menuItemId={menuItemId} showFromPrice={showFromPrice} quantity={quantity} />
    ) : (
        <FoodCardContainer menuItemId={menuItemId} showFromPrice={showFromPrice} quantity={quantity} />
    );
