import moment from "moment";
import React, { useMemo } from "react";
import { GroupTabHistoryView } from "..";
import { Text } from "src/sharedComponents";
import "./GroupTabHistoryTile.scss";
import { getMealHistoryLocale } from "src/features/accounts/utils/mealHistoryLocaleCache";

interface Props {
    item: GroupTabHistoryView;
}

export const GroupTabHistoryTile = ({ item }: Props) => {
    const menuDataLocale = getMealHistoryLocale(item.locale, item.currency);
    const activePeriod = useMemo(
        () =>
            `${moment(item.dateOpened).format("MMMM DD, YYYY • h:mmA")} to ${moment(item.dateClosed).format("h:mmA")}`,
        [item]
    );
    return (
        <div className="group-tab-history-tile">
            <div className="group-tab-history-tile__first-line">
                <Text preset="g-14" mode="bold">
                    {item.tabName}
                </Text>
                <Text preset="g-14" mode="bold">
                    {menuDataLocale.formatCurrency(item.totalCharged)}
                </Text>
            </div>
            <Text preset="g-14">{activePeriod}</Text>
        </div>
    );
};
