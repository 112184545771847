import * as React from "react";
import { Tag } from "../../menudata";
import { DietaryIcons } from "src/sharedComponents/assets/icons/DietaryIcons";

export type DietaryIconKey = keyof typeof DietaryIcons;

export interface Props {
    tags: Tag[];
}

export const DietaryTags = ({ tags }: Props) => (
    <ol className="dietaryinfo" r-cmp="dietaryinfo">
        {tags
            .map(({ icon }) =>
                DietaryIcons[icon as DietaryIconKey] && icon ? (
                    <li key={icon} className="dietaryinfo__item">
                        {DietaryIcons[icon]}
                    </li>
                ) : null
            )
            .filter((node) => node !== null)}
    </ol>
);
