import { AnyAction, Dispatch } from "redux";
import { TypeKeys } from "src/features/menudata/reducers/active";
import { applyDietaryTags } from "../actions/applyDietaryTags";
import { AppMiddleware } from "../..";
import { isPreview } from "../../preview";

export default ({ dispatch }: AppMiddleware) =>
    (next: Dispatch) =>
    (action: AnyAction) => {
        next(action);

        // should we concider replacing the check for items with Pay Only check (we will need to add that flag on the published menudata)
        // also what would the DietaryTags help if there are not items on the menu :)
        if (action.type === TypeKeys.SUCCESS && !isPreview && Object.keys(action.data.items).length) {
            dispatch(applyDietaryTags());
        }
    };
