import { AppDispatch, AppState } from "src/features";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getTakeawayASAPServiceId } from "../selectors/takeawayOptions";
import { sendScheduleTime } from "./sendScheduledTime";
import { showSchedulePage } from "./showSchedulePage";
import { actionCreators } from "../reducers/takeawayOptions";

export function hasScheduledTimeExpired(latestOrderReadyTime: number, dateScheduled: string) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + latestOrderReadyTime);
        if (new Date(dateScheduled) < now) {
            const takeawaySlotASAPServiceId = getTakeawayASAPServiceId(getState());
            dispatch(
                showModalMessage(
                    modalMessages.selectedTakeawayTimeExpired(
                        () => dispatch(sendScheduleTime(null, takeawaySlotASAPServiceId)),
                        () => {
                            dispatch(actionCreators.setDefaultTimeSelectedToASAP(true));
                            dispatch(showSchedulePage());
                        }
                    )
                )
            );
        }
    };
}
