import { cordovaWindow } from "../../cordova";
import { ApplePayPaymentMethod } from "../../../../payment";
import { StripeApplePayPaymentMethod } from "../../../../../features/paymentGateways/stripe/types";
import { GetDevicePaymentMethodAvailableFunc } from "../../../interface";

export const getStripeApplePayAvailable: GetDevicePaymentMethodAvailableFunc<ApplePayPaymentMethod> = (
    applePayPaymentMethod
) =>
    new Promise<boolean>((resolve, reject) => {
        const { apiKey } = applePayPaymentMethod as StripeApplePayPaymentMethod;

        cordovaWindow.StripeDevicePayments.getApplePayAvailable(apiKey, ({ available }) => resolve(available), reject);
    });
