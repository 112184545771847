export const getCardDisplayName = (cardType: string) => {
    switch (cardType.toLowerCase()) {
        case "visa":
            return "Visa";
        case "mastercard":
        case "master":
            return "MasterCard";
        case "american express":
        case "american_express":
        case "amex":
            return "American Express";
        default:
            return "Unknown";
    }
};
