import { connect } from "react-redux";
import { AppDispatch, AppState } from "../..";
import { FoodCard, Props } from "../components/FoodCard";
import { getMenuItemPriorityDietaryTags, getVisibleMenuData } from "../selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { addAllItemInDemoMode } from "../util/helpers";
import { MenuItem } from "src/features/menudata";
import { getTestingFeaturesEnabled } from "src/features/order/selectors";
import { hasMemberPrices } from "src/features/membership/utils";

export interface OwnProps {
    menuItemId: string;
    showFromPrice?: boolean;
    quantity?: number;
}

function mapDispatchToProps(dispatch: AppDispatch, { menuItemId }: OwnProps): FunctionProps<Props> {
    return {
        onAddAllItem: (menuItem: MenuItem) => dispatch(addAllItemInDemoMode(menuItem, menuItemId)),
    };
}

export function mapStateToProps(state: AppState, { menuItemId, showFromPrice, quantity }: OwnProps): DataProps<Props> {
    const { items, hideProductImages } = getVisibleMenuData(state)!;

    const menuItem = items[menuItemId];

    const showAddAllItem = getTestingFeaturesEnabled(state);

    if (!menuItem || menuItem.type !== "food") {
        // tslint:disable-next-line:no-console
        console.warn(`Menu item '${menuItemId}' not found`);
        return {
            dietaryTags: [],
            menuItem: {} as any,
            showAddAllItem,
            quantity,
            hideProductImages,
        };
    }

    const dietaryTags = getMenuItemPriorityDietaryTags(menuItem, state, 5);

    const { availablePrices, minVariantPrice } = menuItem;
    const showTags = hasMemberPrices(availablePrices, minVariantPrice) || !!dietaryTags.length;

    return { menuItem, dietaryTags, showFromPrice, showAddAllItem, hideProductImages, showTags };
}

export const FoodCardContainer = connect(mapStateToProps, mapDispatchToProps)(FoodCard);
