import {
    OrderItemNestedModifier,
    OrderItemOptionNestedModifier,
    OrderItemSelectedNestedModiferData,
    PendingItem,
} from "../types";

export interface PendingModifierState {
    modifierTitle: string;
    editingNestedModifiers: string[];
    selectedOptions: OrderItemNestedModifier[];
    optionNestedModifier: OrderItemOptionNestedModifier;
    onNestedModifierSaved: (
        optionNestedModifier: OrderItemOptionNestedModifier,
        selectedNestedOptions: OrderItemNestedModifier[],
        selectedNestedData: OrderItemSelectedNestedModiferData[]
    ) => void;
}

export interface PendingItemState {
    item: PendingItem | null;
    pendingModifiers: PendingModifierState[];
}

export enum TypeKeys {
    SET_PENDING_ITEM = "PENDING_ITEM/SET_PENDING_ITEM",
    RESET_PENDING_ITEM = "PENDING_ITEM/RESET_PENDING_ITEM",
    EDIT_NESTED_MODIFIER = "PENDING_ITEM/EDIT_NESTED_MODIFIER",
    DONE_EDITING_NESTED_MODIFIER = "PENDING_ITEM/DONE_EDITING_NESTED_MODIFIER",
    RESET_PENDING_MODIFIERS = "PENDING_ITEM/RESET_PENDING_MODIFIERS",
}

export const actionCreators = {
    setPendingItem: (pendingItem: PendingItem) => ({ type: TypeKeys.SET_PENDING_ITEM, pendingItem }),
    editNestedModifier: (pendingModifier: PendingModifierState) => ({
        type: TypeKeys.EDIT_NESTED_MODIFIER,
        pendingModifier,
    }),
    doneEditingNestedModifier: () => ({ type: TypeKeys.DONE_EDITING_NESTED_MODIFIER }),
    resetPendingItem: () => ({ type: TypeKeys.RESET_PENDING_ITEM }),
    resetPendingModifiers: () => ({ type: TypeKeys.RESET_PENDING_MODIFIERS }),
};

const initialState: PendingItemState = {
    item: null,
    pendingModifiers: [],
};

type SetPendingItemAction = {
    type: TypeKeys.SET_PENDING_ITEM;
    pendingItem: PendingItem;
};

type EditNestedModifierAction = {
    type: TypeKeys.EDIT_NESTED_MODIFIER;
    pendingModifier: PendingModifierState;
};

type DoneEditingNestedModifierAction = {
    type: TypeKeys.DONE_EDITING_NESTED_MODIFIER;
};

type ResetPendingItemAction = {
    type: TypeKeys.RESET_PENDING_ITEM;
};

type ResetPendingModifiersAction = {
    type: TypeKeys.RESET_PENDING_MODIFIERS;
};

type PendingItemAction =
    | SetPendingItemAction
    | ResetPendingItemAction
    | EditNestedModifierAction
    | DoneEditingNestedModifierAction
    | ResetPendingModifiersAction;

export function reducer(state = initialState, action: PendingItemAction): PendingItemState {
    if (action.type === TypeKeys.SET_PENDING_ITEM) {
        return {
            ...state,
            item: action.pendingItem,
            pendingModifiers: state.item ? state.pendingModifiers : [],
        };
    }

    if (action.type === TypeKeys.RESET_PENDING_ITEM) {
        return initialState;
    }

    if (action.type === TypeKeys.EDIT_NESTED_MODIFIER) {
        state.pendingModifiers.push(action.pendingModifier);
        return {
            ...state,
            pendingModifiers: [...state.pendingModifiers],
        };
    }

    if (action.type === TypeKeys.DONE_EDITING_NESTED_MODIFIER) {
        state.pendingModifiers.pop();
        return {
            ...state,
            pendingModifiers: [...state.pendingModifiers],
        };
    }

    if (action.type === TypeKeys.RESET_PENDING_MODIFIERS) {
        return {
            ...state,
            pendingModifiers: [],
        };
    }

    return state;
}
