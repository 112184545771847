import "../assets/OrderHeadCountModal.scss";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContainer } from "../../../common/modal";
import { getOrderHeadCountValue, getShowOrderHeadCountModal } from "../selectors/orderHeadCount";
import { PeopleGroupIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { QuantitySelector } from "src/sharedComponents/controls/quantitySelector";
import { actionCreators } from "../reducers/orderHeadCount";
import { AppDispatch } from "src/features";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";

interface Props {
    onClose: () => void;
    onAfterClose?: () => void;
    renderCallToAction: () => JSX.Element;
}

export const OrderHeadCountModal = ({ renderCallToAction, onClose, onAfterClose }: Props) => {
    const show = useSelector(getShowOrderHeadCountModal);
    const orderHeadCountValue = useSelector(getOrderHeadCountValue);
    const dispatch = useDispatch<AppDispatch>();

    const onDecrement = useCallback(() => {
        dispatch(actionCreators.updateOrderHeadCountValue(orderHeadCountValue - 1));
    }, [dispatch, orderHeadCountValue]);
    const onIncrement = useCallback(() => {
        dispatch(actionCreators.updateOrderHeadCountValue(orderHeadCountValue + 1));
    }, [dispatch, orderHeadCountValue]);

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "order-head-count-modal",
                afterOpen: "order-head-count-modal--after-open",
                beforeClose: "order-head-count-modal--before-close",
            }}
            overlayClassName="ReactModal__HeadCountSelection"
            onRequestClose={onClose}
            closeTimeoutMS={150}
            onAfterClose={onAfterClose}
        >
            <LocationThemeContainer>
                <div className="order-head-count">
                    <PeopleGroupIcon />
                    <Text preset="title-28" mode="extra-bold" className="order-head-count__title">
                        How many?
                    </Text>
                    <Text preset="g-14" className="order-head-count__subtitle">
                        Please let us know how many people you are ordering for today
                    </Text>
                    <QuantitySelector
                        minValue={1}
                        maxValue={20}
                        onDecrement={onDecrement}
                        onIncrement={onIncrement}
                        quantity={orderHeadCountValue}
                        size="extra-large"
                        useThemeColours
                    />
                    {renderCallToAction()}
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
