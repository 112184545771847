import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAppInsights } from "src/features/analytics";

const KEY = "MEANDU_PAY_ONLY";

let payOnlyToken: string | null = null;

export function getPayOnlyToken(): string | null {
    try {
        return payOnlyToken ?? window.localStorage.getItem(KEY);
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to retrieve pay-only token from storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        return null;
    }
}

export function savePayOnlyToken(token: string) {
    try {
        payOnlyToken = token;
        return window.localStorage.setItem(KEY, token);
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to save pay-only token to storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        throw err;
    }
}

export function deletePayOnlyToken() {
    try {
        payOnlyToken = null;
        window.localStorage.removeItem(KEY);
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to delete pay-only token from storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        throw err;
    }
}
