import { LocationMenuData, MenuDataState } from "./types";

export enum TypeKeys {
    BEGIN = "MENUDATA/FETCH_BEGIN",
    SUCCESS = "MENUDATA/FETCH_SUCCESS",
    FAILURE = "MENUDATA/FETCH_FAILURE",
    CONNECTION_FAILED = "MENUDATA/CONNECTION_FAILED",
    UPDATED = "MENUDATA/UPDATED",
    RESET = "MENUDATA/RESET",
    UPDATE_SERVICE = "MENUDATA/UPDATE_SERVICE",
}

export const fetchActions = {
    loading: () => ({ type: TypeKeys.BEGIN }),
    loaded: (url: string, data: LocationMenuData, activeServiceId?: string) => ({
        type: TypeKeys.SUCCESS,
        data,
        url,
        activeServiceId,
    }),
    updateActiveServiceId: (activeServiceId?: string) => ({ type: TypeKeys.UPDATE_SERVICE, activeServiceId }),

    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
    connectionFailed: (url: string, count: number) => ({ type: TypeKeys.CONNECTION_FAILED, url, count }),
    updated: (data?: LocationMenuData) => ({ type: TypeKeys.UPDATED, data }),
    reset: () => ({ type: TypeKeys.RESET }),
};

export * from "./types";

type FetchMenuDataSuccessAction = {
    type: TypeKeys.SUCCESS;
    data: LocationMenuData;
    url: string;
    activeServiceId: string;
};

type FetchMenuDataBeginAction = { type: TypeKeys.BEGIN };
type FetchMenuDataFailureAction = { type: TypeKeys.FAILURE };
export type FetchMenuDataConnectionFailedAction = { type: TypeKeys.CONNECTION_FAILED; url: string; count: number };
type UpdateMenuDataAction = { type: TypeKeys.UPDATED; data?: LocationMenuData };
type ResetMenuDataAction = { type: TypeKeys.RESET };
type UpdateActiveServiceIdAction = { type: TypeKeys.UPDATE_SERVICE; activeServiceId?: string };

export type SnapshotAction =
    | FetchMenuDataSuccessAction
    | FetchMenuDataBeginAction
    | FetchMenuDataFailureAction
    | UpdateMenuDataAction
    | ResetMenuDataAction
    | UpdateActiveServiceIdAction;

const defaultState: MenuDataState = {
    state: "unloaded",
};

export default function (state: MenuDataState = defaultState, action: SnapshotAction): MenuDataState {
    if (action.type === TypeKeys.SUCCESS) {
        const { data, url, activeServiceId } = action;

        return {
            state: "loaded",
            data,
            url,
            activeServiceId,
        };
    }

    if (action.type === TypeKeys.BEGIN) {
        return {
            ...state,
            state: "loading",
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        return {
            ...state,
            state: "failed",
        };
    }

    if (action.type === TypeKeys.UPDATED && action.data) {
        const { data } = action;

        return {
            ...state,
            data,
        };
    }

    if (action.type === TypeKeys.RESET) {
        return {
            ...state,
            state: "unloaded",
        };
    }

    if (action.type === TypeKeys.UPDATE_SERVICE) {
        return {
            ...state,
            activeServiceId: action.activeServiceId,
        };
    }

    return state;
}
