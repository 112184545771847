import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Camera } from "src/sharedComponents/assets/icons";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { useDispatch, useSelector } from "react-redux";
import { qr } from "src/common/experience";
import { AppState } from "src/features";
import { findBarcode } from "../actions/findBarcode";
import { actionCreators as qrActionCreators } from "../../partyOnboarding/reducers/qr";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { findingBarcodeOperation } from "../operations";
import { __ } from "../../../common/strings";
import "./MembershipScanBarcode.scss";
import { Spinner } from "../../Spinner";
import { BarcodeScanView, Button } from "../../../sharedComponents";
import { NativeBackButton } from "../../../common/navigation";
import { CSSTransition } from "react-transition-group";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { ModalContainer } from "src/common/modal";

interface Props {
    membershipName: string;
    onSetMemberNumber: (code: string) => void;
}

export const MembershipScanBarcode: FC<Props> = ({ membershipName, onSetMemberNumber }) => {
    const dispatch = useDispatch();
    const cancelRef = useRef<(() => void) | undefined>();
    const messageTimer = useRef<number | undefined>();
    const [showScanView, setShowScanView] = useState(false);
    const { cameraReady, scanError, cameraAccessDenied } = useSelector((state: AppState) => state.qr);
    const findBarcodeError = useSelector(findingBarcodeOperation.getError);

    const onClick = useCallback(async () => {
        if (cameraAccessDenied) {
            const message = await qr.getCameraAccessDeniedMessage(BarcodeType.BARCODE);
            dispatch(showModalMessage(modalMessages.showQrCodeCameraAccessDenied(message)));
            return;
        }
        setShowScanView(true);
    }, [dispatch, cameraAccessDenied]);

    const startCamera = useCallback(async () => {
        const barcode = await findBarcode(dispatch, new Promise<void>((resolve) => (cancelRef.current = resolve)));
        barcode && onSetMemberNumber(barcode);
        setShowScanView(false);
    }, [dispatch, onSetMemberNumber]);

    const resetOperations = useCallback(() => {
        dispatch(findingBarcodeOperation.actionCreators.reset());
        dispatch(qrActionCreators.resetScanStatus());
    }, [dispatch]);

    const description = useMemo(() => {
        return __("Hold your camera over the barcode of your {membership} card.", { membership: membershipName });
    }, [membershipName]);

    useEffect(() => {
        if (findBarcodeError) {
            setShowScanView(false);
            switch (findBarcodeError.errorCode) {
                case "FindQrCodeTimeout":
                case "QrCodeUnknownError":
                default:
                    const message =
                        findBarcodeError.errorCode === "FindQrCodeTimeout"
                            ? modalMessages.findBarcodeTimeout(membershipName)
                            : modalMessages.showBarcodeUnknownError(membershipName);
                    messageTimer.current = window.setTimeout(() => dispatch(showModalMessage(message)), 300);
                    break;
                case "QrCodeCameraAccessDenied":
                    qr.getCameraAccessDeniedMessage(BarcodeType.BARCODE).then((message) =>
                        dispatch(showModalMessage(modalMessages.showQrCodeCameraAccessDenied(message)))
                    );
                    break;
            }
        }
    }, [dispatch, findBarcodeError, membershipName]);

    useEffect(() => {
        return () => {
            clearTimeout(messageTimer.current);
        };
    }, []);

    useEffect(() => {
        if (cameraReady && showScanView) {
            document.body.classList.add("scanning-barcode");
        }
        return () => {
            document.body.classList.remove("scanning-barcode");
        };
    }, [cameraReady, showScanView]);

    return (
        <>
            <TappableSpan className="membership-scan-barcode__icon" onClick={onClick}>
                <Camera />
            </TappableSpan>
            <ModalContainer
                isOpen={showScanView}
                overlayClassName="ReactModal__MembershipScanBarcode"
                bodyOpenClassName="ReactModal__MembershipScanBarcode"
                className={{
                    base: "membership-scan-barcode",
                    afterOpen: "membership-scan-barcode--after-open",
                    beforeClose: "membership-scan-barcode--before-close",
                }}
                onAfterClose={resetOperations}
                onAfterOpen={startCamera}
                closeTimeoutMS={300}
                onRequestClose={cancelRef.current}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={true}
            >
                <NativeBackButton
                    onPressed={() => cancelRef.current && cancelRef.current()}
                    name="membership/scan-barcode"
                >
                    {qr.requiresTargetElement && <div id="reader" />}
                    {!cameraReady && <Spinner />}
                    <CSSTransition in={cameraReady} classNames="fade" timeout={200} unmountOnExit>
                        <div>
                            <BarcodeScanView
                                title="Scan the Barcode."
                                description={description}
                                barcodeType={BarcodeType.BARCODE}
                                scanError={scanError}
                            />
                            <div className="membership-scan-barcode__bottom">
                                <Button
                                    className="membership-scan-barcode__bottom__button"
                                    mode="outline"
                                    onClick={cancelRef.current}
                                    value="Cancel"
                                />
                            </div>
                        </div>
                    </CSSTransition>
                </NativeBackButton>
            </ModalContainer>
        </>
    );
};
