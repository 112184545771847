import { goBack } from "connected-react-router";
import { ModifierMap } from "..";
import { AppDispatch } from "../..";
import { OrderCampaigns } from "../reducers/types";
import { addOrUpdateOrderItem } from "./addOrUpdateOrderItem";

export function addToOrder(
    menuItemId: string,
    courseId: string,
    categoryId: string | null,
    orderItemId: string | null,
    variant: number | null,
    modifiers?: ModifierMap,
    quantity?: number,
    campaign?: OrderCampaigns,
    preventBack?: boolean,
    quickSellCarouselPosition?: number
) {
    return async (dispatch: AppDispatch) => {
        const modifiersList = modifiers
            ? Object.keys(modifiers)
                  .map((id) => parseInt(id, 10))
                  .map((modifier: number) => ({ modifier: modifier, options: modifiers[modifier] }))
            : undefined;

        dispatch(
            addOrUpdateOrderItem(
                courseId,
                categoryId,
                menuItemId,
                null,
                orderItemId,
                variant,
                modifiersList,
                quantity,
                campaign,
                undefined,
                undefined,
                quickSellCarouselPosition
            )
        );
        !preventBack && dispatch(goBack());
    };
}
