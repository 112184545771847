import { refreshApp } from "src/common/experience";
import { AppDispatch } from "src/features";
import { auth } from "src/features/signup";
import { actionCreators } from "src/features/signup/reducers/signIn";
import { logoutOperation } from "../operations";

export function logout() {
    return logoutOperation.getThunk(async (dispatch: AppDispatch) => {
        await auth.logout();
        document.dispatchEvent(new CustomEvent("logout"));
        dispatch(actionCreators.logout());

        refreshApp();
    });
}
