import { ApplePayPaymentMethod, AuthorizePaymentResult, paymentOptions } from "../../../../payment";
import { cordovaWindow } from "../../cordova";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

export const initializeStripeApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = async (
    applePayPaymentMethod,
    total
) => {
    const { countryCode, currency, applePayMerchantId } = applePayPaymentMethod;

    return () =>
        new Promise<AuthorizePaymentResult | null>((resolve, reject) =>
            cordovaWindow.StripeDevicePayments.authorizeApplePayPayment(
                countryCode,
                currency,
                applePayMerchantId,
                total.toFixed(2),
                paymentOptions.label,
                resolve,
                reject
            )
        );
};
