import { AppDispatch, AppState } from "../../../index";
import { getThreeDSecureVerificationData } from "../../../payment/selectors";
import { MessageTypeKeys, ThreeDSecureError, ThreeDSecureInfo, VerificationCancelledError } from "../../../payment";
import { normalizeError } from "../../../../common/error";
import { device } from "../../../../common/experience";
import { getEnvUrl, loadExternalScript } from "../../../../common/shared";
import { actionCreators as paymentEventsActionCreators } from "../../../payment/reducers/paymentEvents";
import { PaymentType } from "../../../../common/payment";
import { config } from "../../../../common/config";
import { messageHandler } from "../../../../common/messages/messageHandler";
import { fetchThreeDSecureVerificationData } from "../../../payment/api/fetchThreeDSecureVerificationData";
import { getParty } from "../../../order";
import { TyroNewCardPaymentMethod, TyroThreeDSecureVerificationData } from "../types";
import { verifyTyroCardOperation } from "../operations";
import { VerifyCardAction } from "../../types";

const RETURN_URL = "/threedsecure/complete";
const IFRAME_ID = "iframe";

const configure = (ThreeDS: any, sessionId: string, tyroNewCardPaymentMethod: TyroNewCardPaymentMethod) =>
    new Promise((resolve, reject) => {
        const { merchantId, locale, apiVersion } = tyroNewCardPaymentMethod;

        ThreeDS.configure({
            merchantId,
            sessionId,
            callback: function () {
                if (ThreeDS.isConfigured()) {
                    resolve();
                } else {
                    reject(new Error("Failed to configure ThreeDS"));
                }
            },
            configuration: {
                userLanguage: locale,
                wsVersion: apiVersion,
            },
        });
    });

const initiateAuthentication = (ThreeDS: any, orderId: string, transactionId: string) =>
    new Promise((resolve, reject) => {
        ThreeDS.initiateAuthentication(orderId, transactionId, function (data: any) {
            if (data?.error) {
                const { code, msg, cause, result, status } = data.error;
                return reject(new ThreeDSecureError(code, msg || cause || result || status));
            }

            if (data?.gatewayRecommendation !== "PROCEED") {
                return reject(new Error(`initiateAuthentication did not get PROCEED (${data?.gatewayRecommendation})`));
            }

            // If this is 3DS2 ThreeDS will automatically create an iframe with id="iframe"
            // using the contents of the htmlRedirectCode to send additional data
            resolve();
        });
    });

const authenticatePayer = (ThreeDS: any, orderId: string, transactionId: string, ipAddress: string) =>
    new Promise((resolve, reject) => {
        // Docs specify that the user agent must be passed if we use PAYER_BROWSER as a channel
        // And that it has a maximum length of 255
        const optionalParams = {
            device: {
                browser: device.userAgent?.substring(0, 255),
                ipAddress,
            },
        };

        ThreeDS.authenticatePayer(
            orderId,
            transactionId,
            function (data: any) {
                if (data?.error) {
                    const { code, msg, cause, result, status } = data.error;
                    return reject(new ThreeDSecureError(code, msg || cause || result || status));
                }

                if (data?.gatewayRecommendation !== "PROCEED") {
                    return reject(new Error(`authenticatePayer did not get PROCEED (${data?.gatewayRecommendation})`));
                }

                if (!data?.htmlRedirectCode) {
                    return reject(new Error("No htmlRedirectCode"));
                }

                // Create an iframe the same as ThreeDS and insert the htmlRedirectCode
                document.getElementById(IFRAME_ID)?.remove();

                let iframe = document.createElement("iframe");
                iframe.id = IFRAME_ID; // Give our iframe the same id as Tyro's for simplicity
                iframe.classList.add("tyro-threedsecure");
                document.body.append(iframe);

                iframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
                ((iframeDocument) => {
                    iframeDocument.open();
                    iframeDocument.write(data.htmlRedirectCode);
                    iframeDocument.close();

                    // Frictionless flow still opens the iframe so wait 1 second after it loads
                    // so we can avoid seeing it if we don't have to
                    iframeDocument.querySelector("iframe")?.addEventListener("load", () => {
                        setTimeout(() => {
                            document.getElementById(IFRAME_ID)?.classList.add("open");
                        }, 1000);
                    });
                })(iframe.contentWindow!.document);

                resolve();
            },
            optionalParams
        );
    });

const submit3DS2AdditionalData = () =>
    new Promise((resolve) => {
        const iframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement | undefined;

        if (!iframe) return resolve();

        const innerIframe = iframe.contentWindow?.document.querySelector("iframe") as HTMLIFrameElement | undefined;

        if (!innerIframe?.contentDocument) {
            // We are unable to access the contentDocument of the inner iframe
            // indicating that it has already loaded and now has a different origin
            return resolve();
        }

        innerIframe.addEventListener("load", resolve);
    });

export const verifyTyroCard: VerifyCardAction = (completePayment, signal) => {
    return verifyTyroCardOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            let cancelled = false;
            let cancel: (() => void) | undefined;

            signal.addEventListener("abort", () => {
                dispatch(paymentEventsActionCreators.threeDSecureCancelled());
                cancelled = true;
                cancel && cancel();
                completePayment(null);
            });

            const { party, total, selectedPaymentMethod, newCardPaymentMethod } = getThreeDSecureVerificationData(
                getState()
            );
            const { paymentGateway, token: paymentToken, paymentType } = selectedPaymentMethod!;
            const returnUrl = getEnvUrl(config.REACT_APP_APP_BASE_URL) + RETURN_URL;

            const threeDSecureInfo: ThreeDSecureInfo = {
                paymentGateway,
                paymentToken,
                isAddedCard: paymentType === PaymentType.ADDEDCARD,
                total,
                returnUrl,
                orderApiUrl: getEnvUrl(config.REACT_APP_ORDER_API),
            };

            if (cancelled) return;
            const tyroNewCardPaymentMethod = newCardPaymentMethod as TyroNewCardPaymentMethod;
            const inParty = !!getParty(getState());
            const [{ sessionId, transactionId, ipAddress }, ThreeDS] = await Promise.all([
                fetchThreeDSecureVerificationData<TyroThreeDSecureVerificationData>(
                    dispatch,
                    threeDSecureInfo,
                    inParty
                ),
                loadExternalScript(
                    "ThreeDS",
                    `${tyroNewCardPaymentMethod.baseUrl}/static/threeDS/1.3.0/three-ds.min.js`
                ),
            ]);

            await configure(ThreeDS, sessionId, tyroNewCardPaymentMethod);

            await initiateAuthentication(ThreeDS, party!.id, transactionId);
            // If this is 3DS2 an iframe will have been created to submit additional data
            await submit3DS2AdditionalData();

            if (cancelled) return;

            let urlPromise;
            [urlPromise, cancel] = messageHandler.listen(MessageTypeKeys.VERIFY_CARD_COMPLETE, returnUrl);

            const [url] = await Promise.all([
                urlPromise,
                authenticatePayer(ThreeDS, party!.id, transactionId, ipAddress),
            ]);

            if (!url) return; // The process was cancelled

            if (url.searchParams.get("success") !== "1") {
                throw new Error("Liability not shifted");
            }

            completePayment({
                paymentToken: transactionId,
            });

            dispatch(paymentEventsActionCreators.threeDSecureSuccess());
        } catch (err) {
            if (!(err instanceof VerificationCancelledError)) {
                dispatch(paymentEventsActionCreators.threeDSecureFailed(err?.code, err?.message));
            }

            ((err) => {
                completePayment(err);
                throw err;
            })(normalizeError(err));
        } finally {
            document.getElementById(IFRAME_ID)?.remove();
        }
    });
};
