import { FeatureConfiguration } from "..";

import { reducer as partyReducer } from "./reducers/party";
import { reducer as pendingItemReducer } from "./reducers/pendingItem";
import { reducer as cartReducer } from "./reducers/cart";
import { reducer as orderHeadCountReducer } from "./reducers/orderHeadCount";
import { getRestaurantFlagsOperation } from "./operations";
import { registerReduxEnhancer } from "../analytics";
import { addOrderItemEnhancer } from "./analytics/addOrderItemEnhancer";

export { actions } from "./actions";

export { actionCreators } from "./reducers/party";

export * from "./types";

export { proxy as orderApiProxy } from "./orderApi/proxy";

export * from "./middleware/orderApiMiddleware";

export { getParty } from "./selectors";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.party = partyReducer;
    config.reducers.pendingItem = pendingItemReducer;
    config.reducers.restaurantFlags = getRestaurantFlagsOperation.reducer;
    config.reducers.cart = cartReducer;
    config.reducers.orderHeadCount = orderHeadCountReducer;

    registerReduxEnhancer(addOrderItemEnhancer);
}

export * from "./types";

export { OrderModifiers } from "./Elements/OrderModifiers";
export { OrderVariants } from "./Elements/OrderVariants";
