import "../assets/MenuItemFooter.scss";

import React, { useMemo, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import {
    getIsConnected,
    getCurrentMemberInParty,
    getAlcoholicDrinksLimitReachedOrExceeded,
    getAlcoholicDrinksCount,
    getDoesPendingItemHaveAlcoholicDrink,
} from "../../order/selectors";
import { getPendingItem } from "../../order/selectors/pendingItem";
import { Price } from "../../menu/components/Price";
import { actionCreators as pendingItemAction } from "../../order/reducers/pendingItem";
import { actionCreators as orderActions } from "../../order";
import { QuantitySelector } from "../../../sharedComponents/controls/quantitySelector";
import { calculateOrderItemTotal } from "../../order/selectors/util/calculateOrderTotal";
import { canAddPendingItemToCart } from "../helpers";
import { getPriceResolver } from "../../membership/selectors/getPriceResolver";
import { MenuItem } from "../../menudata";
import { PendingItem } from "../../order";
import { TrashIcon } from "../../../sharedComponents/assets/icons";
import {
    getAlcoholicDrinksLimit,
    getHasLimitedAlcoholicTooltipBeenDismissed,
    getVisibleMenuDataModifiers,
} from "../../menu/selectors";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { Text, Tooltip } from "src/sharedComponents";

interface Props {
    addToOrder: () => void;
    removeFromOrder: () => void;
    menuItem: MenuItem;
}

export const MenuItemFooter = ({ addToOrder, removeFromOrder, menuItem }: Props) => {
    const { item: pendingItem } = useSelector(getPendingItem);
    const connected = useSelector(getIsConnected);
    const priceResolver = useSelector(getPriceResolver);
    const currentMemberInParty = useSelector(getCurrentMemberInParty);
    const globalModifiers = useSelector(getVisibleMenuDataModifiers);
    const alcoholicDrinksCount = useSelector(getAlcoholicDrinksCount);
    const alcoholicDrinksLimit = useSelector(getAlcoholicDrinksLimit);
    const alcoholicDrinksLimited = useSelector(getAlcoholicDrinksLimitReachedOrExceeded);
    const pendingItemHasAlcoholicDrink = useSelector(getDoesPendingItemHaveAlcoholicDrink);
    const alcoholicDrinksLimitTrackingDispatched = useRef(false);
    const hasAlcoholicTooltipBeenDismissed = useSelector(getHasLimitedAlcoholicTooltipBeenDismissed);

    const dispatch = useDispatch();

    const isEditingItem = !!pendingItem?.orderItemId;
    const canRemove = isEditingItem && pendingItem?.quantity === 0;
    const canSubmit = canRemove ? connected : !!pendingItem?.canSubmit;
    const disabled = canRemove ? !connected : !!pendingItem?.unavailable || !currentMemberInParty || !connected;

    const addItemText = useMemo(() => {
        if (pendingItem?.unavailable) {
            return "Oh no! Item unavailable";
        } else if (pendingItem?.price) {
            return pendingItem?.orderItemId ? "Update order" : "Add for";
        } else if (pendingItem?.orderItemId) {
            return "Save changes";
        }
        return "Add your item";
    }, [pendingItem]);

    useEffect(() => {
        if (
            alcoholicDrinksLimit &&
            alcoholicDrinksCount &&
            alcoholicDrinksLimited &&
            !hasAlcoholicTooltipBeenDismissed &&
            !alcoholicDrinksLimitTrackingDispatched.current
        ) {
            dispatch(
                orderActions.trackViewedLimitedAlcoholicDrinksTooltip({
                    current_page: "item_page",
                    max_number_drinks: alcoholicDrinksLimit,
                    nb_total_items: alcoholicDrinksCount,
                })
            );
            alcoholicDrinksLimitTrackingDispatched.current = true;
        }
    }, [
        alcoholicDrinksLimited,
        hasAlcoholicTooltipBeenDismissed,
        alcoholicDrinksCount,
        alcoholicDrinksLimit,
        dispatch,
    ]);

    const updatePendingItem = useCallback(
        (updatedPendingItem: PendingItem) => {
            updatedPendingItem.price = calculateOrderItemTotal(menuItem, updatedPendingItem, priceResolver);
            updatedPendingItem.canSubmit = canAddPendingItemToCart(menuItem, updatedPendingItem, globalModifiers);

            dispatch(pendingItemAction.setPendingItem(updatedPendingItem));
        },
        [dispatch, menuItem, priceResolver, globalModifiers]
    );

    const onIncrement = useCallback(
        () => updatePendingItem({ ...pendingItem!, quantity: pendingItem!.quantity + 1 }),
        [pendingItem, updatePendingItem]
    );

    const onDecrement = useCallback(
        () => updatePendingItem({ ...pendingItem!, quantity: pendingItem!.quantity - 1 }),
        [pendingItem, updatePendingItem]
    );

    if (!pendingItem) return null;

    return (
        <>
            <Tooltip
                show={pendingItemHasAlcoholicDrink && alcoholicDrinksLimited && !hasAlcoholicTooltipBeenDismissed}
                className={!!menuItem.waitTime ? "with-banner" : ""}
                onClose={() => {
                    dispatch(
                        orderActions.trackDismissedLimitedAlcoholicDrinksTooltip("item_page", alcoholicDrinksCount!)
                    );
                }}
                showClose={true}
                tooltipContent={
                    <Text
                        mode={["block"]}
                        preset="g-14"
                        value={`Please note that this venue has a total limit of ${alcoholicDrinksLimit} alcoholic drinks per order.`}
                    />
                }
            />

            <div className={classNames("menu-item-footer", { "top-shadow": connected || !isEditingItem })}>
                <div className="menu-item-footer__quantity-selector">
                    <QuantitySelector
                        minValue={isEditingItem ? 0 : 1}
                        onDecrement={onDecrement}
                        onIncrement={onIncrement}
                        quantity={pendingItem.quantity}
                        size="large"
                        disabled={!!pendingItem?.unavailable || !currentMemberInParty}
                        useThemeColours={false}
                    />
                </div>
                <div className="menu-item-footer__action">
                    <NetworkConnectedButton
                        onClick={canRemove ? removeFromOrder : addToOrder}
                        disabled={disabled}
                        className={classNames({
                            disabled: disabled || !canSubmit,
                            "menuitemcard-add__button--connecting": !connected && !isEditingItem,
                            "menu-item-footer__action--error": canRemove,
                        })}
                    >
                        <span>
                            {canRemove ? (
                                <div className="menu-item-footer__action__remove">
                                    <TrashIcon /> Remove item
                                </div>
                            ) : !!pendingItem.price && !pendingItem.unavailable ? (
                                <>
                                    {addItemText}&nbsp;
                                    <Price price={pendingItem.price} />
                                </>
                            ) : (
                                addItemText
                            )}
                        </span>
                    </NetworkConnectedButton>
                </div>
            </div>
        </>
    );
};
