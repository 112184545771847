import { PaymentInfoVisitor } from "src/features/paymentGateways/types";
import { getToastPaymentSessionData } from "../selectors";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { PaymentType } from "src/common/payment";
import { createToastPaymentMethod } from "../actions/createToastPaymentMethod";

export const visitToastPaymentInfo: PaymentInfoVisitor = async (_, getState, paymentInfo) => {
    const state = getState();

    const selectedPaymentMethod = getSelectedPaymentMethod(state);

    const { externalReferenceId, paymentIntentId: paymentId } = getToastPaymentSessionData(state);

    if (selectedPaymentMethod?.paymentType === PaymentType.ADDEDCARD) {
        paymentInfo.paymentToken = await createToastPaymentMethod();
    }

    paymentInfo.posPaymentInfo = {
        externalReferenceId,
        paymentId,
    };
};
