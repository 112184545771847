import { connect } from "react-redux";
import { NavBar, Props } from "../components/NavBar";
import { AppState } from "../../../features";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { NavBarType } from "../types";
import { RouteComponentProps, withRouter } from "react-router";
import { goBack } from "connected-react-router";
import { DataProps, FunctionProps } from "src/common/types/utils";
import {
    getIsGroupTabOpen,
    getShowManageTabPrompt,
    getTabTypeName,
} from "src/features/groupTabs/selectors/activeGroupTab";
import { setHasSeenManageTabPromptAction } from "../../../features/groupTabs/actions/setHasSeenManageTabPromptAction";
import { getHideTabsFromNavBar } from "../../../features/groupTabs/selectors";
import { getIsOpenTable } from "../../../features/openTable/selectors";
import { getIsEngageEnabledForLocation } from "src/features/order/selectors";
import { getOfferTooltipCount, getShowOfferTooltip } from "src/features/offers/selectors";
import { saveOfferTooltipViewedAction } from "src/features/offers/actions/offerTooltipAction";
import { getOpenTableShowPayBadge } from "../selectors";

const mapStateToProps = (
    state: AppState,
    {
        history: {
            location: { pathname },
        },
    }: RouteComponentProps
): DataProps<Props> => {
    const navBarType = state.navBar.navBarStatus;
    const isInMenuPage = pathname === "/menu/service";
    const inActiveGroupTab = getIsGroupTabOpen(state);
    const showManageTabPrompt = getShowManageTabPrompt(state);
    const tabTypeName = getTabTypeName(state);
    const hideTabs = getHideTabsFromNavBar(state);
    const engageEnabled = getIsEngageEnabledForLocation(state);
    const isOpenTable = getIsOpenTable(state);
    const showPayBadge = getOpenTableShowPayBadge(state);
    const showOfferTooltip = getShowOfferTooltip(state);
    const offerTooltipCount = getOfferTooltipCount(state);

    return {
        navBarType,
        isInMenuPage,
        inActiveGroupTab,
        showManageTabPrompt: showManageTabPrompt && navBarType === NavBarType.MENU,
        tabTypeName,
        hideTabs,
        isOpenTable,
        engageEnabled,
        showPayBadge,
        showOfferTooltip,
        offerTooltipCount,
    };
};

const mapDispatchToProps = (dispatch: any): FunctionProps<Props> => {
    return {
        activeType: (type: NavBarType) => dispatch(navBarActionCreators.activeType(type)),
        goBack: () => dispatch(goBack()),
        onManageTabPromptDismiss: (shouldNavigate: boolean) => {
            if (shouldNavigate) {
                dispatch(navBarActionCreators.activeType(NavBarType.GROUP_TABS));
            }
            dispatch(setHasSeenManageTabPromptAction());
        },
        onOfferTooltipDismiss: () => dispatch(saveOfferTooltipViewedAction()),
    };
};

export const NavBarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
