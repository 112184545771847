import "../assets/NoNetworkNotification.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Modal from "react-modal";

import { Prompt } from "src/sharedComponents";
import { CloseIcon, NoNetworkIcon } from "src/sharedComponents/assets/icons";
import { getShowNoNetworkConnection } from "../selectors";
import { getAccountMenuOpen } from "src/features/accountmenu/selectors";
import { actionCreators as notificationsActionCreators } from "src/features/notifications/reducers/notifications";
import { getActiveGroupTabData, getShowOverviewModal } from "src/features/groupTabs/selectors/activeGroupTab";

export const NoNetworkNotification = () => {
    const dispatch = useDispatch();

    const showNoNetworkConnection = useSelector(getShowNoNetworkConnection);
    const accountMenuOpen = useSelector(getAccountMenuOpen);
    const viewTab = useSelector(getShowOverviewModal);
    const activeTab = useSelector(getActiveGroupTabData);

    const [show, setShow] = useState(false);

    useEffect(() => {
        let timer: number | undefined;

        if (showNoNetworkConnection) {
            setShow(true);
        } else {
            timer = window.setTimeout(() => setShow(false), 200);
        }

        return () => {
            window.clearTimeout(timer);
        };
    }, [showNoNetworkConnection]);

    const hideNoNetworkConnectionNotification = useCallback(() => {
        dispatch(notificationsActionCreators.showNoNetworkConnection(false));
    }, [dispatch]);

    /**
     * We don't display the NoNetworkNotification component
     * when on join-table page and the profile modal or the tab overview page shortcut is not open
     * so we need to make sure the canShow does not include any of these scenario.
     */
    const activeTabOpen = viewTab && activeTab;
    const canShow = show && (window.location.pathname !== "/join-table" || accountMenuOpen || !!activeTabOpen);

    return (
        <Modal
            isOpen={canShow}
            className={{
                base: "NoNetworkNotification-modal",
                afterOpen: "NoNetworkNotification-modal--after-open",
                beforeClose: "NoNetworkNotification-modal--before-close",
            }}
            overlayClassName="NoNetworkNotification-modal__overlay"
            bodyOpenClassName="NoNetworkNotification-modal__Body--open"
        >
            <div
                className={classnames(
                    "no-network-notification",
                    showNoNetworkConnection ? "no-network-notification__fade-in" : "no-network-notification__fade-out"
                )}
            >
                <Prompt
                    leftIcon={<NoNetworkIcon />}
                    onClick={hideNoNetworkConnectionNotification}
                    rightIcon={<CloseIcon />}
                    subtitle="Your network connection has been lost."
                    title="No network detected"
                    className="no-network-notification__prompt"
                />
            </div>
        </Modal>
    );
};
