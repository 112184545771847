import { hideKeyboard, isKeyboardOpen } from "../../keyboard";
import { device } from "./device";

let initialWindowHeight = 0;
let currentWindowHeight = 0;

const onRotated = async (event: MediaQueryListEvent) => {
    if (event.matches) {
        if (isKeyboardOpen()) {
            hideKeyboard();
        }
    } else if (device.isIOS && currentWindowHeight === initialWindowHeight) {
        // Restore the browser chrome so it doesn't end up a weird height
        document.documentElement.style.height = "initial";
        setTimeout(() => {
            document.documentElement.style.height = "";
            window.scrollTo(0, 1);
        }, 300); // 300ms seems to be the minimum to let the browser rotate animation complete
    }
};

export const rotationHandler = {
    register: () => {
        const rotated = window.matchMedia("screen and (min-aspect-ratio: 15/9)");

        if ("addEventListener" in rotated) {
            rotated.addEventListener("change", onRotated);
        } else {
            (rotated as any).addListener(onRotated);
        }

        if (device.isIOS) {
            initialWindowHeight = currentWindowHeight = window.innerHeight;

            window.addEventListener("resize", () => {
                if (window.innerHeight > window.innerWidth) {
                    currentWindowHeight = window.innerHeight;
                }
            });
        }
    },
};
