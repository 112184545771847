import { getABTestsOperation } from "../operations";
import { device, isNative, isWeb } from "../../../common/experience";
import { CustomerInfo } from "../types";
import { fetchABTests } from "../api/fetchABTests";

export const getABTests = () =>
    getABTestsOperation.getThunk(async () => {
        const { isIOS, isAndroid, isSafari, isChrome } = device;

        const customerInfo: CustomerInfo = {
            deviceInfo: {
                isIOS,
                isAndroid,
                isWeb,
                isNative,
                width: window.innerWidth,
                height: window.innerHeight,
                isSafari,
                isChrome,
            },
        };

        return await fetchABTests(customerInfo);
    });
