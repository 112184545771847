import { trackedEvent } from "../../../common/events/reduxEventTracking";

enum TypeKeys {
    ADD_PROMO_CODE_CLICKED = "PARTY/PROMOTION_CLICKED",
    PROMO_CODE_ABANDONED = "PARTY/PROMOTION_CODE_ABANDONED",
}

export const actionCreators = {
    trackAddPromoCodeClicked: () => trackedEvent({ type: TypeKeys.ADD_PROMO_CODE_CLICKED }),
    trackPromoCodeAbandoned: (code?: string) => trackedEvent({ type: TypeKeys.PROMO_CODE_ABANDONED, code }),
};
