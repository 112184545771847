import { createOperation } from "../operations";

export const verifyOperation = createOperation<void>("ONBOARDING/VERIFY", { showSpinner: true });

export const ssoSignInOperation = createOperation<void>("ONBOARDING/SSO_SIGN_IN", {
    allowPause: false,
});

export const canVerifyOperation = createOperation<boolean>("ONBOARDING/CAN_VERIFY", {
    showSpinner: true,
});
