import { getAuthHeaders } from "src/common/auth";
import { orderApi } from "src/features/order/orderApi";
import { FetchError } from "src/features/order/orderApi/FetchError";

export async function removeMembership(programId: string) {
    const response = await orderApi.send(`/membership/${encodeURIComponent(programId)}`, {
        method: "DELETE",
        headers: await getAuthHeaders(),
    });

    if (!response.ok) {
        throw new FetchError(response);
    }
}
