import { client, dataCollector } from "braintree-web";

export const getBraintreeClientInstance = async (clientToken: string) => {
    const clientInstance = await client.create({
        authorization: clientToken,
    });

    const dataCollectorInstance = await dataCollector.create({
        client: clientInstance,
    });

    return {
        clientInstance,
        deviceData: dataCollectorInstance.deviceData,
    };
};
