import { isRemote, cordovaWindow } from "./cordova";
import { device } from "./device";

export const asset = (path: string) => {
    if (path.startsWith("data:") || path.startsWith("http")) return path;

    if (!isRemote) {
        const replacedPath = path.replace(/^[./]+/, "");

        if (device.isIOS) {
            return `app://localhost/${replacedPath}`;
        }

        return `${cordovaWindow.cordova.file.applicationDirectory}www/${replacedPath}`;
    }

    return path;
};
