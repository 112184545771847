import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../reducers/groupTabHistory";
import { getSelectedGroupTabHistory, showGroupTabHistoryModal } from "../selectors/groupTabHistorySelector";
import { GroupTabHistoryPage } from "./GroupTabHistoryPage";
import { ModalContainer } from "src/common/modal";

export const GroupTabHistoryModal = () => {
    const selectedGroupTabHistory = useSelector(getSelectedGroupTabHistory);
    const showModal = useSelector(showGroupTabHistoryModal);
    const [isOpen, setIsOpen] = useState(!!showModal && !!selectedGroupTabHistory);

    const dispatch = useDispatch();

    const cleanState = useCallback(() => {
        dispatch(actionCreators.selectGroupTabHistory("", false));
    }, [dispatch]);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        setIsOpen(!!showModal && !!selectedGroupTabHistory);
    }, [showModal, selectedGroupTabHistory]);

    useEffect(() => () => cleanState(), [cleanState]);

    return (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "history-item-modal slide-in",
                afterOpen: "history-item--after-open slide-in--after-open",
                beforeClose: "history-item--before-close slide-in--before-close",
            }}
            overlayClassName="slide-in-modal--overlay history-item--overlay"
            contentLabel="History Item Modal"
            closeTimeoutMS={250}
            onAfterClose={cleanState}
            shouldCloseOnEsc
            onRequestClose={closeModal}
            allowBodyScroll
        >
            {!!selectedGroupTabHistory && (
                <GroupTabHistoryPage selectedItem={selectedGroupTabHistory} onBack={closeModal} />
            )}
        </ModalContainer>
    );
};
