import { createWizard } from "../wizards";

export type PayOnlyPaymentsFeedPages = "PaymentsFeed";

export const payOnlyPaymentsFeedWizard = createWizard<PayOnlyPaymentsFeedPages>("PayOnlyPaymentsFeedWizard", [
    "PaymentsFeed",
]);

export type PayOnlyPaymentFlowPages =
    | "SelectPaymentMethod"
    | "SelectSplitOption"
    | "SplitOptionConfiguration"
    | "SetFirstName"
    | "ReviewPayment";

export const payOnlyPaymentWizard = createWizard<PayOnlyPaymentFlowPages>("PayOnlyPaymentWizard", [
    "SelectPaymentMethod",
    "ReviewPayment",
]);

export type PayOnlyPaymentStateFlowPages =
    | "ProcessingPayment"
    | "ConfirmingWithVenue"
    | "PaymentSuccessful"
    | "PaymentFailedToSubmit"
    | "BillClosed";

export const payOnlyPaymentStateWizard = createWizard<PayOnlyPaymentStateFlowPages>("PayOnlyPaymentStateWizard", [
    "ProcessingPayment",
    "ConfirmingWithVenue",
    "PaymentFailedToSubmit",
    "PaymentSuccessful",
]);
