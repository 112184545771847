import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { Button, Checkbox, Text } from "src/sharedComponents";
import { PadlockIcon } from "src/sharedComponents/assets/icons";
import { useSelector } from "react-redux";
import { HostedField } from "../types";
import { getHostedFieldsValidity } from "../selectors/hostedFields";
import { CardPaymentMethod, NewCardPaymentMethod, PaymentType } from "src/common/payment";
import { getAddCardBehavior } from "src/features/paymentGateways";

export interface VaultCardController {
    value: boolean;
    onChange: (value: boolean) => void;
}

export interface AddCardFormProps {
    paymentMethod: NewCardPaymentMethod | CardPaymentMethod;
    show: boolean;
    hide: boolean;
    vaultCardController?: VaultCardController;
    onAdd?: () => void;
    onShow?: () => void;
    onHide?: () => void;
}

export const AddCardForm = (props: AddCardFormProps) => {
    const { paymentMethod } = props;
    const addCardBehavior = getAddCardBehavior(paymentMethod);
    const PaymentGatewaySpecificAddCardForm = addCardBehavior?.addCardFormElement;

    return PaymentGatewaySpecificAddCardForm ? (
        <PaymentGatewaySpecificAddCardForm {...props} />
    ) : (
        <AddCardFormInner {...props} />
    );
};

export interface AddCardFormInnerProps extends AddCardFormProps {
    cardNumberElement?: ReactElement;
    expiryElement?: ReactElement;
    cvvElement?: ReactElement;
}

export const AddCardFormInner = (props: AddCardFormInnerProps) => {
    const {
        paymentMethod,
        show,
        hide,
        vaultCardController,
        onAdd,
        onShow,
        onHide,
        cardNumberElement,
        expiryElement,
        cvvElement,
    } = props;

    const { cardNumberValid, cardNumberInvalid, cvvValid, cvvInvalid, expiryValid, expiryInvalid } =
        useSelector(getHostedFieldsValidity);

    const addCardFormRef = useRef<HTMLDivElement>(null);
    const { current: cardPaymentMethod } = useRef(
        paymentMethod.paymentType === PaymentType.CARD ? paymentMethod : undefined
    );

    const onAnimationEnd = useCallback(
        (event: AnimationEvent) => {
            if (event.animationName.endsWith("-show")) {
                onShow?.();
            } else {
                onHide?.();
            }
        },
        [onShow, onHide]
    );

    useEffect(() => {
        addCardFormRef.current?.addEventListener("animationend", onAnimationEnd);
    }, [onAnimationEnd]);

    return (
        <div
            ref={addCardFormRef}
            className={classNames(
                "payment-method__add-card",
                `payment-method__add-${paymentMethod.paymentGateway.toLowerCase()}-card`,
                { show: show },
                { hide: hide }
            )}
        >
            <div className="payment-method__add-card-inner">
                <div className="payment-method__add-card__fields">
                    <div
                        className={classNames(
                            "payment-method__add-card__field-wrapper",
                            "payment-method__add-card__number",
                            cardPaymentMethod && "readonly"
                        )}
                    >
                        <label htmlFor={HostedField.Number}>
                            <Text preset="g-14" mode="bold" value="Card number" />
                        </label>
                        {cardPaymentMethod ? (
                            <div className={classNames(HostedField.Number, "payment-method__add-card__field")}>
                                <Text preset="g-16" value={cardPaymentMethod.maskedNumber} />
                            </div>
                        ) : (
                            <div
                                id={!cardNumberElement ? HostedField.Number : undefined}
                                className={classNames(HostedField.Number, "payment-method__add-card__field")}
                            >
                                {cardNumberElement}
                            </div>
                        )}
                        <small>
                            <Text preset="g-14" value={cardNumberInvalid ? "Invalid card number" : ""} />
                        </small>
                    </div>
                    <div className="payment-method__add-card__expiry-cvv">
                        <div
                            className={classNames(
                                "payment-method__add-card__field-wrapper",
                                cardPaymentMethod && "readonly"
                            )}
                        >
                            <label htmlFor={HostedField.Expiry}>
                                <Text preset="g-14" mode="bold" value="Expiry" />
                            </label>
                            {cardPaymentMethod ? (
                                <div className={classNames(HostedField.Expiry, "payment-method__add-card__field")}>
                                    <Text preset="g-16" value="•• / ••" />
                                </div>
                            ) : (
                                <div
                                    id={!expiryElement ? HostedField.Expiry : undefined}
                                    className={classNames(HostedField.Expiry, "payment-method__add-card__field")}
                                >
                                    {expiryElement}
                                </div>
                            )}
                            <small>
                                <Text preset="g-14" value={expiryInvalid ? "Invalid expiry" : ""} />
                            </small>
                        </div>
                        <div className="payment-method__add-card__field-wrapper">
                            <label htmlFor={HostedField.CVV}>
                                <Text preset="g-14" mode="bold" value="CVV" />
                            </label>
                            <div
                                id={!cvvElement ? HostedField.CVV : undefined}
                                className={classNames(HostedField.CVV, "payment-method__add-card__field")}
                            >
                                {cvvElement}
                            </div>
                            <small>
                                <Text
                                    preset="g-14"
                                    value={cvvInvalid ? (cardPaymentMethod ? "Verify your CVV" : "Invalid CVV") : ""}
                                />
                            </small>
                        </div>
                    </div>
                    {vaultCardController && (
                        <div className="payment-method__add-card__remember-me">
                            <Checkbox
                                id="remember_me"
                                onChange={vaultCardController.onChange}
                                label="Remember me for faster checkouts"
                                defaultChecked={vaultCardController.value}
                            />
                        </div>
                    )}
                    <div className="payment-method__add-card__security">
                        <PadlockIcon />
                        <Text preset="g-12">
                            Your payment information is encrypted and stored securely in your account.
                        </Text>
                    </div>
                </div>
                {onAdd && (
                    <div className="payment-method__add-card__footer">
                        <Button
                            mode="solid"
                            value="Add"
                            disabled={!cardNumberValid || !expiryValid || !cvvValid}
                            onClick={onAdd}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
