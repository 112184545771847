import React from "react";
import { Text } from "src/sharedComponents";
import { AddIcon, CheckIcon, MembershipIcon, MoreIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { MembershipState } from "..";

interface NormalMembershipProps {
    membership: MembershipState;
    connected?: boolean;
    connect?: () => void;
    more?: () => void;
}

export const ManageMembershipRow = ({ membership, connected, connect, more }: NormalMembershipProps) => {
    return (
        <TappableDiv className="manage-memberships__membership" onClick={!!connect ? connect : more}>
            <div className="manage-memberships__membership__logo">
                {membership.logoUrl ? (
                    <img
                        className="manage-memberships__membership__logo__image"
                        src={membership.logoUrl}
                        alt={`membership logo ${membership.programName}`}
                    />
                ) : (
                    <span className="manage-memberships__membership__logo__internal">
                        <MembershipIcon />
                    </span>
                )}
            </div>
            <div className="manage-memberships__membership__content">
                <Text className="manage-memberships__membership__title" preset="g-14">
                    {membership.programName}
                </Text>
                {connected && (
                    <Text className="manage-memberships__membership__connected" preset="g-14">
                        <CheckIcon /> Currently active
                    </Text>
                )}
            </div>
            {more ? (
                <TappableDiv onClick={more} className="manage-memberships__membership__action">
                    <MoreIcon />
                </TappableDiv>
            ) : (
                !!connect && (
                    <TappableDiv onClick={connect} className="manage-memberships__membership__action">
                        <AddIcon />
                    </TappableDiv>
                )
            )}
        </TappableDiv>
    );
};
