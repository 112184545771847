import "../assets/NestedModifiersView.scss";

import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { OrderItemSelectedNestedModiferDisplayData } from "src/features/order";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { Text } from "src/sharedComponents";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { RightArrowIcon } from "src/sharedComponents/assets/icons";
import { getPriceResolver } from "../../membership/selectors/getPriceResolver";
import { useSelector } from "react-redux";
import { getSelectedNestedSummary } from "../helpers";

interface Props {
    nestedOptions?: OrderItemSelectedNestedModiferDisplayData[];
    onChangeSelection?: () => void;
}

export const NestedModifiersView = ({ nestedOptions, onChangeSelection }: Props) => {
    const menuDataLocale = useContext(MenuDataLocaleContext)!;
    const priceResolver = useSelector(getPriceResolver);

    const [totalRequired, setRequired] = useState<number>(0);
    const [totalAvailable, setAvailable] = useState<number>(0);
    const [hasSelection, setHasSelection] = useState<boolean>(true);

    useEffect(() => {
        if (!nestedOptions) return;

        const { totalRequired, totalAvailable, hasSelection } = getSelectedNestedSummary(nestedOptions);

        setRequired(totalRequired);
        setAvailable(totalAvailable);
        setHasSelection(hasSelection);
    }, [nestedOptions]);

    if (!nestedOptions) return null;

    return (
        <TappableDiv
            onTap={onChangeSelection}
            className={classNames("selected-nested-modifiers", totalRequired > 0 && "select-mode required")}
        >
            {nestedOptions.map((nested, index) =>
                !nested.displayName ? null : (
                    <Text
                        className="selected-nested-modifiers__item"
                        preset="g-14"
                        mode="block"
                        key={index + nested.displayName}
                    >
                        {"- ".repeat(nested.nestingLevel)}
                        {nested.displayName}
                        {nested.price ? ` (${menuDataLocale.formatCurrency(priceResolver(nested.price))})` : ""}
                    </Text>
                )
            )}
            <Text
                className={classNames("selected-nested-modifiers__action", hasSelection && "with-selection")}
                preset="g-14"
                mode="bold"
            >
                {totalRequired
                    ? `${totalRequired} selection${totalRequired > 1 ? "s" : ""} required `
                    : hasSelection
                    ? `Change selection${nestedOptions.length > 1 ? "s" : ""}`
                    : `${totalAvailable} selection${totalAvailable > 1 ? "s" : ""} available `}
                <RightArrowIcon />
            </Text>
        </TappableDiv>
    );
};
