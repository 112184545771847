import "./SecuredPaymentPage.scss";

import React, { useCallback, useMemo } from "react";
import { Disclaimer, Divider, Text } from "src/sharedComponents";
import { InlineVaultedPaymentMethods } from "../paymentMethods/components/InlineVaultedPaymentMethods";
import { useDispatch, useSelector } from "react-redux";
import { getMenuDataLocale } from "../menudata/selectors/getMenuDataLocale";
import { getWalkOutGratuityFactor } from "./selectors";
import { saveSecuredPaymentMethod } from "./actions/saveSecuredPaymentMethod";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { isDisplayedForAllVaultedPaymentMethodSelection } from "src/common/payment";
import { getTableLabel } from "src/features/order/selectors";

export const SecuredPaymentPage = () => {
    const walkOutGratuityFactor = useSelector(getWalkOutGratuityFactor);
    const menuDataLocale = useSelector(getMenuDataLocale);
    const tableLabel = useSelector(getTableLabel);
    const dispatch = useDispatch();

    const handleOnVaultCard = useCallback(() => {
        dispatch(trackedEvent({ type: "PARTY/SECURED_PAYMENT/CARD_VAULTED" }));
    }, [dispatch]);

    const onConfirm = useCallback(() => {
        dispatch(trackedEvent({ type: "PARTY/SECURED_PAYMENT/SAVE_PAYMENT_CLICKED" }));
        dispatch(saveSecuredPaymentMethod());
    }, [dispatch]);

    const disclaimerBodyText = useMemo(() => {
        return `Only one payment method per ${tableLabel} is required. Your payment method will be charged if the bill
        is left unpaid.
        ${
            !!(walkOutGratuityFactor && menuDataLocale)
                ? `A ${menuDataLocale.formatPercent(walkOutGratuityFactor)} default gratuity will be applied.`
                : ""
        }
        A temporary hold may appear in your bank transaction history and can take up to 5 business days
        to disappear.`;
    }, [menuDataLocale, walkOutGratuityFactor, tableLabel]);

    return (
        <div className="secured-payment-page animated-child scroll-element">
            <div className="secured-payment-page__header">
                <Text preset="title-28" mode={["bold", "block"]} value="Select payment method" />
                <Text preset="g-14" mode="block">
                    Add your details to secure this order. Your card won’t be charged, simply settle the bill when you
                    are ready to leave.
                </Text>
            </div>
            <Text
                preset="g-18"
                mode={["bold", "block"]}
                value="Choose payment method"
                className="secured-payment-page__payment-title"
            />
            <InlineVaultedPaymentMethods
                vaultCard
                buttonText="Save payment method"
                onVaultCard={handleOnVaultCard}
                onSuccess={onConfirm}
                filter={isDisplayedForAllVaultedPaymentMethodSelection}
            >
                <Divider fullWidth={true} extraHeight={true} />
                <Disclaimer bodyText={disclaimerBodyText} />
            </InlineVaultedPaymentMethods>
        </div>
    );
};
