import "./assets/EngageOfferSection.scss";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Animate, Divider, Text } from "src/sharedComponents";
import { ClearIcon, PromotionsTagIcon } from "src/sharedComponents/assets/icons";
import { LoadingText } from "src/sharedComponents/controls/loadingText";
import { getActiveOrderStatus, getIsEngageEnabledForParty } from "../selectors";
import { OrderStatus } from "../types";
import { Price } from "src/features/menu/components/Price";
import { getEngageOffer, getMemberOrderTotal, getMemberSubTotal } from "src/features/payment/selectors";
import { NetworkConnectedTappableSpan } from "src/features/notifications/components/NetworkConnectedTappableSpan";
import { updateWalletEnabledForParty } from "../actions/updateWalletEnabledForParty";
import classNames from "classnames";

export const EngageOfferSection = () => {
    const dispatch = useDispatch();
    const orderStatus = useSelector(getActiveOrderStatus);
    const engageOffer = useSelector(getEngageOffer);
    const enabledForParty = useSelector(getIsEngageEnabledForParty);
    const itemTotal = useSelector(getMemberOrderTotal);
    const subTotal = useSelector(getMemberSubTotal);
    const validatingOrder = orderStatus === OrderStatus.VALIDATING;
    const noValidOffer = !validatingOrder && !engageOffer && enabledForParty;
    const onAllowOffersToggle = useCallback(
        (allowOffers: boolean) => {
            dispatch(updateWalletEnabledForParty(allowOffers));
        },
        [dispatch]
    );

    if (noValidOffer) {
        return (
            <div className="cart-view__content__total">
                <Text preset="g-18" mode="bold">
                    Item total
                </Text>
                <Price priceTextPreset="g-14" priceTextMode="bold" price={itemTotal} />
            </div>
        );
    }

    return (
        <div className="engage-offer">
            <div className="engage-offer__discount">
                {validatingOrder && enabledForParty ? (
                    <LoadingText />
                ) : (
                    <>
                        <span className={classNames("engage-offer__discount__icon", !enabledForParty && "highlight")}>
                            <PromotionsTagIcon />
                        </span>
                        {enabledForParty ? (
                            <>
                                <div className="engage-offer__discount__text">
                                    <Text preset="g-14" mode={["bold", "block"]}>
                                        {engageOffer?.name}
                                    </Text>
                                    <Text preset="g-14" mode="block" className="engage-offer__discount__text__subtitle">
                                        Your best offer for this cart
                                    </Text>
                                </div>
                                <NetworkConnectedTappableSpan
                                    className="engage-offer__remove"
                                    onTap={() => onAllowOffersToggle(false)}
                                >
                                    <ClearIcon />
                                </NetworkConnectedTappableSpan>
                            </>
                        ) : (
                            <NetworkConnectedTappableSpan
                                className="engage-offer__discount__text"
                                onTap={() => onAllowOffersToggle(true)}
                            >
                                <Text preset="g-14" mode="bold" className="engage-offer__discount__text__toggle">
                                    Check for the best offer
                                </Text>
                            </NetworkConnectedTappableSpan>
                        )}
                    </>
                )}
            </div>
            <Divider />
            <div className="engage-offer__price">
                {validatingOrder ? (
                    <div className="engage-offer__price__text">
                        <LoadingText />
                        <LoadingText />
                    </div>
                ) : (
                    <>
                        {engageOffer && (
                            <div className="engage-offer__price__text">
                                <Text preset="g-14">Item total</Text>
                                <Price priceTextPreset="g-14" price={itemTotal} />
                            </div>
                        )}
                        <div className="engage-offer__price__text">
                            <Text preset="g-18" mode="bold">
                                {engageOffer ? "Subtotal" : "Item total"}
                            </Text>
                            <Price priceTextMode="bold" priceTextPreset="g-18" price={subTotal} />
                        </div>
                    </>
                )}
            </div>
            {validatingOrder && enabledForParty && (
                <div className="engage-offer__discount__loading-spinner">
                    <Animate name="twoDotSpinner" />
                </div>
            )}
        </div>
    );
};
