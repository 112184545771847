import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingModifiers } from "src/features/order/selectors/pendingItem";
import { CSSTransition } from "react-transition-group";
import { Button } from "src/sharedComponents";
import { actionCreators as pendingItemAction, PendingModifierState } from "../../order/reducers/pendingItem";
import { SimpleNavHeader } from "src/common/navigation";
import { OrderItemNestedModifier, OrderItemSelectedNestedModiferData, OrderModifiers } from "src/features/order";
import { StatusBar } from "src/common/statusBar";
import classNames from "classnames";
import { MenuItemDetails } from "./MenuItemDetails";
import { MenuItemModifier } from "src/features/menudata";
import { flatten } from "src/features/order/selectors/util/flatten";
import { getSelectedModifierChoice } from "src/features/menu/helpers";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import "../assets/NestedModifiersPage.scss";
import { getActiveServiceMenuItemIds, getVisibleMenuData } from "src/features/menu/selectors";
import { nonNullable } from "src/common/types/nonNullable";
import { backHandler } from "src/common/experience";

const scrollToNextItem = () => {
    MenuItemDetails.scrollNextItemIntoView(
        ".nested-modifiers__page .select-mode.available-selections",
        ".nested-modifiers__page__scroll"
    );
};

const getModifierObjects = (modifierIndex: number, selectedNested: OrderItemNestedModifier[], modifierId: string) => {
    let selectedModifierIndex = selectedNested.findIndex((m) => m.modifier === modifierIndex);
    let selectedModifier = selectedNested[selectedModifierIndex];

    if (selectedModifierIndex < 0) {
        selectedModifier = {
            modifier: modifierIndex,
            modifierId,
            options: [],
        };
        selectedModifierIndex = selectedNested.length;
    }

    return {
        selectedModifierIndex,
        selectedModifier: {
            ...selectedModifier,
            options: [...selectedModifier.options],
            selectedOptions: selectedModifier.selectedOptions && [...selectedModifier.selectedOptions],
        } as OrderItemNestedModifier,
    };
};

const flattenNestedData = (selectedModifiers: OrderItemNestedModifier[], modifiersData: MenuItemModifier[]) =>
    flatten<OrderItemSelectedNestedModiferData>(
        modifiersData.map((modifierData, index) => {
            const selectedModifier = selectedModifiers.find((m) => m.modifier === index);
            const isRequiredModifier = !!modifierData.minSelection;

            return selectedModifier?.options.length
                ? selectedModifier.options.map((o) => {
                      const globalOption = getSelectedModifierChoice(modifierData.options, o);
                      return {
                          displayName: globalOption?.displayName,
                          optionIndex: globalOption?.originalIndex,
                          modifierId: selectedModifier?.modifierId,
                          price: globalOption?.availablePrices,
                          required: isRequiredModifier,
                      } as OrderItemSelectedNestedModiferData;
                  })
                : [
                      {
                          required: isRequiredModifier,
                      } as OrderItemSelectedNestedModiferData,
                  ];
        })
    );

const selectNested = (
    selectedModifier: OrderItemNestedModifier,
    selectedModifierIndex: number,
    setSelectedNested: (value: React.SetStateAction<OrderItemNestedModifier[]>) => void
) => {
    setSelectedNested((selected) => {
        if (selectedModifier.options.length) {
            selectedModifier.options.sort();
            const selectedCopy = [...selected];
            selectedCopy.splice(selectedModifierIndex, 1, selectedModifier);
            return selectedCopy;
        }
        return selected.filter((s) => s.modifier !== selectedModifier.modifier);
    });
};

interface Props {
    nestingLevel?: number;
    setIsParentDisabled?: (disabled: boolean) => void;
}

export const NestedModifiersPage = ({ nestingLevel = 0, setIsParentDisabled }: Props) => {
    const pendingModifiers = useSelector(getPendingModifiers);
    const menuData = useSelector(getVisibleMenuData);

    const isEditingNestedModifier = !!pendingModifiers[nestingLevel + 1];
    const { modifierTitle, editingNestedModifiers, selectedOptions, optionNestedModifier, onNestedModifierSaved } =
        pendingModifiers[nestingLevel] ?? ({} as PendingModifierState);

    // Array of global modifiers from menuData matching the ids in our option's nestedModifiers array
    const editNestedModifierData = useMemo(() => {
        const data = editingNestedModifiers?.map((modifierId) => menuData?.modifiers?.[modifierId]).filter(nonNullable);
        return data?.length ? data : null;
    }, [editingNestedModifiers, menuData]);

    const [isOpen, setIsOpen] = useState(!!editNestedModifierData);
    const [selectedNested, setSelectedNested] = useState<OrderItemNestedModifier[]>([]);
    const [selectedNestedData, setSelectedNestedData] = useState<OrderItemSelectedNestedModiferData[]>([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [optionsDisabledByNestedModifiers, setOptionsDisabledByNestedModifiers] = useState<number[]>([]);
    const [triedToSave, setTriedToSave] = useState(false);
    const [shouldScroll, setShouldScroll] = useState(false);
    const activeServiceMenuItemIds = useSelector(getActiveServiceMenuItemIds);

    const optionIndexRef = useRef(0);

    const dispatch = useDispatch();

    const getIsValid = useCallback(
        () =>
            editNestedModifierData?.every(
                (m, i) =>
                    !m.minSelection ||
                    selectedNested.some((n) => n.modifier === i && n.options.length >= m.minSelection)
            ) ?? true,
        [selectedNested, editNestedModifierData]
    );

    const setOptionDisabledByNestedModifier = useCallback((disabled: boolean) => {
        setOptionsDisabledByNestedModifiers((prevState) =>
            disabled ? [...prevState, optionIndexRef.current] : prevState.filter((i) => i !== optionIndexRef.current)
        );
    }, []);

    const afterClose = useCallback(() => {
        setTriedToSave(false);
        setIsDisabled(false);
        setOptionsDisabledByNestedModifiers([]);
        setSelectedNested([]);
        setSelectedNestedData([]);
        dispatch(pendingItemAction.doneEditingNestedModifier());
    }, [dispatch]);

    const onSave = useCallback(() => {
        const isDisabled = !getIsValid();
        setIsDisabled(isDisabled);
        setIsParentDisabled?.(isDisabled);
        if (isDisabled || optionsDisabledByNestedModifiers.length) {
            setTriedToSave(true);
            setShouldScroll(true);
            return;
        }
        onNestedModifierSaved?.(optionNestedModifier, selectedNested, selectedNestedData);
        setIsOpen(false);
        backHandler.handleBack(() => {});
    }, [
        onNestedModifierSaved,
        setIsOpen,
        selectedNested,
        selectedNestedData,
        getIsValid,
        setIsParentDisabled,
        optionNestedModifier,
        optionsDisabledByNestedModifiers,
    ]);

    const editNestedModifier = useCallback(
        (pendingModifier: PendingModifierState) => {
            dispatch(pendingItemAction.editNestedModifier(pendingModifier));
        },
        [dispatch]
    );

    const getModifierObjectsForSelect = useCallback(
        (modifierIndex: number, optionId: number) => {
            const modifierId = editingNestedModifiers?.[modifierIndex];
            const modifier = editNestedModifierData?.[modifierIndex];

            if (!modifierId || !modifier || isEditingNestedModifier) return null;

            optionIndexRef.current = optionId;
            setOptionDisabledByNestedModifier(false);

            return {
                ...getModifierObjects(modifierIndex, selectedNested, modifierId),
                modifier,
            };
        },
        [
            editingNestedModifiers,
            editNestedModifierData,
            selectedNested,
            setOptionDisabledByNestedModifier,
            isEditingNestedModifier,
        ]
    );

    const onNestedModifierOptionSelected = useCallback(
        (modifierIndex: number, optionId: number, selected: boolean) => {
            const modifierObjects = getModifierObjectsForSelect(modifierIndex, optionId);

            if (!modifierObjects) return;

            const { modifier, selectedModifier, selectedModifierIndex } = modifierObjects;

            const shouldScrollNext = MenuItemDetails.changeModifierOption(
                optionId,
                selected,
                modifier,
                selectedModifier
            );

            setShouldScroll(!!shouldScrollNext);

            MenuItemDetails.handleNestedModifiers(
                optionId,
                selected,
                modifier,
                menuData?.modifiers,
                selectedModifier,
                editNestedModifier,
                onNestedModifierSaved,
                true
            );

            // Update selected nested modifiers in state
            selectNested(selectedModifier, selectedModifierIndex, setSelectedNested);
        },
        [getModifierObjectsForSelect, menuData, editNestedModifier, onNestedModifierSaved]
    );

    const onModifierMultiSelectChanged = useCallback(
        (modifierIndex: number, optionId: number, isAdded: boolean) => {
            const modifierObjects = getModifierObjectsForSelect(modifierIndex, optionId);

            if (!modifierObjects) return;

            const { modifier, selectedModifier, selectedModifierIndex } = modifierObjects;

            if (MenuItemDetails.changeModifierMultiSelect(optionId, isAdded, modifier, selectedModifier)) {
                setShouldScroll(true);
            }

            if (isAdded || selectedModifier.optionNestedModifiers?.some((nm) => nm.optionIndex === optionId)) {
                MenuItemDetails.handleNestedModifiers(
                    optionId,
                    selectedModifier.options.some((option) => option === optionId),
                    modifier,
                    menuData?.modifiers,
                    selectedModifier,
                    editNestedModifier,
                    onNestedModifierSaved,
                    true
                );
            }

            selectNested(selectedModifier, selectedModifierIndex, setSelectedNested);
        },
        [getModifierObjectsForSelect, editNestedModifier, menuData, onNestedModifierSaved]
    );

    const onNestedModifierEdit = useCallback(
        (modifierIndex: number, optionId: number) => {
            const modifierObjects = getModifierObjectsForSelect(modifierIndex, optionId);

            if (!modifierObjects) return;

            MenuItemDetails.handleNestedModifiers(
                optionId,
                true,
                modifierObjects.modifier,
                menuData?.modifiers,
                modifierObjects.selectedModifier,
                editNestedModifier,
                onNestedModifierSaved,
                true,
                true
            );
        },
        [getModifierObjectsForSelect, editNestedModifier, menuData, onNestedModifierSaved]
    );

    useEffect(() => {
        setIsOpen(!!editNestedModifierData);
    }, [editNestedModifierData]);

    useEffect(() => {
        setSelectedNested(selectedOptions || []);
    }, [selectedOptions]);

    useEffect(() => {
        if (shouldScroll) {
            scrollToNextItem();
            setShouldScroll(false);
        }
    }, [shouldScroll]);

    useEffect(() => {
        setIsDisabled(!getIsValid());
    }, [getIsValid]);

    useEffect(
        () => () => {
            if (nestingLevel > 0) return;
            dispatch(pendingItemAction.resetPendingModifiers());
        },
        [dispatch, nestingLevel]
    );

    useEffect(() => {
        if (editNestedModifierData) {
            setSelectedNestedData(flattenNestedData(selectedNested, editNestedModifierData));
        }

        let updated = false;
        const availableNestedModifiers = selectedNested
            ?.map((m) => {
                const nestedModifierOptions = !editNestedModifierData
                    ? m.options
                    : m.options.filter((mo) => {
                          if (
                              getSelectedModifierChoice(editNestedModifierData[m.modifier]?.options, mo)?.available !==
                              false
                          ) {
                              return true;
                          } else {
                              updated = true;
                              return false;
                          }
                      });
                return {
                    ...m,
                    options: nestedModifierOptions,
                };
            })
            .filter((m) => m.options.length);

        if (updated) {
            setSelectedNested(availableNestedModifiers);
            dispatch(showModalMessage(modalMessages.orderUpdated()));
        }
    }, [editNestedModifierData, selectedNested, dispatch]);

    useEffect(() => {
        if (nestingLevel > 0) return;
        document.body.classList.toggle("disable-body-scroll", isOpen);
    }, [isOpen, nestingLevel]);

    useEffect(
        () => () => {
            if (nestingLevel > 0) return;
            document.body.classList.remove("disable-body-scroll");
        },
        [nestingLevel]
    );

    if (!editNestedModifierData) return null;

    return (
        <CSSTransition
            in={isOpen}
            classNames="nested-modifiers__transition"
            timeout={250}
            onExited={afterClose}
            onEntered={() => setIsParentDisabled?.(!getIsValid())}
            unmountOnExit
        >
            <div className="nested-modifiers__page">
                <StatusBar backgroundColor="#fff" />
                <SimpleNavHeader
                    withBorder
                    customBack={`nested-modifiers/${modifierTitle?.replace(/\W/g, "_")}`}
                    title={modifierTitle}
                    onBack={() => setIsOpen(false)}
                    disabled={isEditingNestedModifier}
                />
                <div className="nested-modifiers__page__scroll">
                    <OrderModifiers
                        modifiers={editNestedModifierData}
                        selectedModifiers={selectedNested}
                        onModifierOptionSelected={onNestedModifierOptionSelected}
                        triedToSubmit={triedToSave}
                        onModifierMultiSelectChanged={onModifierMultiSelectChanged}
                        activeServiceMenuItemIds={activeServiceMenuItemIds}
                        onNestedModifierEdit={onNestedModifierEdit}
                        nestingLevel={nestingLevel}
                    />
                </div>
                <footer className="top-shadow">
                    <Button
                        onClick={onSave}
                        className={classNames((isDisabled || optionsDisabledByNestedModifiers.length) && "disabled")}
                        value="Save options"
                        disabled={isEditingNestedModifier}
                    />
                </footer>
                <NestedModifiersPage
                    nestingLevel={nestingLevel + 1}
                    setIsParentDisabled={setOptionDisabledByNestedModifier}
                />
            </div>
        </CSSTransition>
    );
};
