import * as React from "react";
import { ModifierDetail, OrderDetail } from "../../orderHistoryData";
import { OrderCourseItem } from "../../order/reducers/types";
import { OrderItems } from "../../order/component/OrderItems";
import { VisibleModifierOption, VisibleNestedModifierOption } from "src/features/menudata";

export interface Props {
    items: OrderDetail[];
}

const mapNestedModifierOptionsRecursive = (nestedModifiers?: ModifierDetail[]) =>
    nestedModifiers?.map(
        (detail): VisibleNestedModifierOption => ({
            displayName: detail.optionName,
            price: detail.price,
            nestedOptions: mapNestedModifierOptionsRecursive(detail.nestedModifiers),
        })
    );

export function mapOrderItemToCourseItem(items: OrderDetail[]): OrderCourseItem[] {
    const mapModifierDetails = (detail: ModifierDetail): VisibleModifierOption => {
        return {
            displayName: detail.optionName,
            originalIndex: -1,
            nestedOptions: mapNestedModifierOptionsRecursive(detail.nestedModifiers),
        } as VisibleModifierOption;
    };
    return items.map((item) => {
        return {
            displayName: item.displayName,
            orderItemId: null,
            quantity: item.count,
            failedToSubmit: !!item.failedToSubmit,
            price: item.amount,
            memberId: item.memberId,
            notes: item.notes,
            variantName: item.variant,
            modifierOptions: item.modifierDetails?.map(mapModifierDetails) ?? null,
            submitted: true,
            waitTime: item.waitTime,
        };
    }) as OrderCourseItem[];
}

export const OrderHistoryItemList = ({ items }: Props) => {
    const newItems: OrderCourseItem[] = mapOrderItemToCourseItem(items);

    return <OrderItems items={newItems} />;
};
