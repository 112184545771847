import { FeatureConfiguration } from "..";
import { orderHistoryDataReducer } from "./reducers";

export * from "./reducers/types";
export { actions } from "./actions";
export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.orderHistoryData = orderHistoryDataReducer;
}
