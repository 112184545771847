import { cordovaWindow } from "../../cordova";
import { googlePayEnvironment } from "../../../../payment";

export const getBraintreeGooglePayAvailable = () =>
    new Promise<boolean>((resolve, reject) =>
        cordovaWindow.BraintreeDevicePayments.getGooglePayAvailable(
            googlePayEnvironment,
            ({ available }) => resolve(available),
            reject
        )
    );
