import "./LimitedMenuPackageItem.scss";

import React from "react";
import { LimitedMenuPackage } from "../types";
import { ActionableRow } from "../../../sharedComponents";

interface Props {
    item: LimitedMenuPackage;
    onClick: (packageId: string) => void;
}

export const LimitedMenuPackageItem = ({ item, onClick }: Props) => (
    <ActionableRow
        onClick={() => onClick(item.id)}
        title={item.displayName}
        description={item.description}
        showArrowIcon
    />
);
