import classNames from "classnames";
import * as React from "react";
import { TappableAnchor, TappableDiv } from "src/sharedComponents/common/tappable";
import { DietaryTags } from "../../menu/components/DietaryTags";
import { ArrowRight, MembershipIcon, PopularIcon, SpecialIcon, TrashIcon } from "src/sharedComponents/assets/icons";
import { Indexed, MenuItem, MenuItemModifier, MenuItemVariant, ModifierOption, Tag } from "../../menudata";
import {
    EasyReorderItem,
    OrderItemModifier,
    OrderItemNestedModifier,
    OrderItemOptionNestedModifier,
    OrderItemSelectedNestedModiferData,
    OrderModifiers,
    OrderVariants,
    PendingItem,
} from "../../order";
import "../assets/MenuItemDetails.scss";
import { calculateOrderItemTotal } from "../../order/selectors/util/calculateOrderTotal";
import { CSSTransition } from "react-transition-group";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { modalMessages } from "../../modalMessage/messages";
import { Divider, FormControl, Input, Text } from "src/sharedComponents";
import { device, href, scrolling } from "src/common/experience";
import { PriceResolver } from "../../membership/selectors/getPriceResolver";
import { hasMemberPrices } from "src/features/membership/utils";
import { getSelectedModifierChoice } from "../../menu/helpers";
import { PendingModifierState } from "src/features/order/reducers/pendingItem";
import { canAddPendingItemToCart, getCanScrollPastOptionWithNestedModifiers } from "../helpers";
import { EnergyContent } from "./EnergyContent";
import { getHasAvailableNestedModifier, isModifierAvailable } from "../../menudata/helpers/availabilityHelpers";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { AddToOrderFunc } from "../types";
import { nonNullable } from "src/common/types/nonNullable";
import { MenuItemExpandableContent } from "src/features/menuitem/components/MenuItemExpandableContent";
import { MenuItemExpandableHeader } from "src/features/menuitem/components/MenuItemExpandableHeader";

export interface Props {
    courseId: string;
    menuItemId: string;
    menuId?: string;
    orderItemId?: string;
    menuItem: MenuItem;
    globalModifiers?: Indexed<MenuItemModifier>;
    dietaryTags: Tag[];
    dietaryInformationUrl?: string;
    relatedItems: string[];
    modal?: boolean;
    setPendingItem: (pendingItem: PendingItem) => void;
    triedToSubmit: number;
    addToOrder: AddToOrderFunc;
    allowNotesToKitchen: boolean;
    hasParty: boolean;
    easyReorderItem?: EasyReorderItem;
    pendingItem: PendingItem;
    isEditingNestedModifier: boolean;
    hasPreselectOptions: boolean;
    showModalMessage: (modalMessage: ModalMessage) => void;
    priceResolver: PriceResolver;
    editNestedModifier?: (pendingModifier: PendingModifierState) => void;
    resetNestedModifiers: () => void;
    removeFromOrder: () => void;
    trackAllergenNoticeClick: () => void;
    activeServiceMenuItemIds: Indexed<boolean>;
}

export interface LocalState {
    showFullDescription: boolean;
    showMore: boolean;
    pendingItem: PendingItem;
    showNote: boolean;
    visibleModifiers?: number[];
    prevVisibleModifiers?: number[];
    selectedNestedModifier?: string;
}

interface AvailableModifiersResult<T extends OrderItemModifier> {
    availableModifiers: T[] | undefined;
    updated: boolean;
    silentUpdateRequired: boolean;
}

export const MODIFIER_LIST_INITIAL_LIMIT = 5;

export const reduceNestedModifierLength = (selectedNestedModifier: OrderItemSelectedNestedModiferData[][]) =>
    selectedNestedModifier?.reduce(
        (total, nested) =>
            total +
            (!nested ? 0 : nested.some((n) => !!n.displayName) ? nested.filter((n) => !!n.displayName).length : 100),
        0
    );

export class MenuItemDetails extends React.Component<Props, LocalState> {
    commonVariantModifiers?: number[];
    variantsRef: React.RefObject<any> | undefined;
    descriptionRef?: React.RefObject<any>;
    shouldScrollNext = false;

    constructor(props: Props) {
        super(props);
        const {
            easyReorderItem,
            hasPreselectOptions,
            pendingItem,
            menuItem: { variants, modifiers: itemModifiers, available, description },
            globalModifiers,
        } = props;
        const notes = hasPreselectOptions && easyReorderItem ? easyReorderItem.notes : pendingItem.notes;
        let variant = hasPreselectOptions && easyReorderItem ? easyReorderItem.variant : pendingItem.variant;
        let modifiers = hasPreselectOptions && easyReorderItem ? easyReorderItem.modifiers : pendingItem.modifiers;
        let showOptionUnavailableModal = false;

        const updatedVariant = MenuItemDetails.checkVariant(variant, variants);

        if (updatedVariant !== variant) {
            showOptionUnavailableModal = true;
            variant = updatedVariant;
        }

        const { updatedModifiers, silentUpdateRequired } = MenuItemDetails.checkModifiers(
            modifiers,
            itemModifiers ?? null,
            globalModifiers
        );

        if (silentUpdateRequired) {
            modifiers = updatedModifiers;
        } else if (updatedModifiers !== modifiers) {
            showOptionUnavailableModal = true;
            modifiers = updatedModifiers;
        }

        const selectedVariant: MenuItemVariant | undefined =
            variant !== null && variants ? getSelectedModifierChoice(variants, variant) : undefined;

        this.state = {
            showFullDescription: false,
            showMore: true,
            pendingItem: MenuItemDetails.getUpdatedPendingItem(props, {
                courseId: props.courseId,
                menuId: props.menuId || pendingItem.menuId,
                orderItemId: pendingItem.orderItemId,
                itemId: props.menuItemId,
                categoryId: pendingItem.categoryId,
                modal: props.modal,
                quantity: pendingItem.quantity,
                notes: notes,
                variant: variant,
                modifiers: modifiers,
                canSubmit: false,
                unavailable: available === false,
                price: 0,
                triedToSubmit: 0,
                isFeatured: pendingItem.isFeatured,
            }),
            showNote: !!notes,
            visibleModifiers: selectedVariant ? selectedVariant.modifiers : undefined,
        };

        props.setPendingItem(this.state.pendingItem);

        if (description && description.length) {
            this.descriptionRef = React.createRef();
        }

        if (variants && variants.length) {
            this.variantsRef = React.createRef();

            if (variants.every((v) => !!v.modifiers)) {
                const mods0 = variants[0].modifiers!;
                let commonModifiers = true;
                for (let i = 1; i < variants.length && commonModifiers; i++) {
                    const mods = variants[i].modifiers!;
                    commonModifiers = mods0.length === mods.length && mods0.every((m, i) => m === mods[i]);
                }
                this.commonVariantModifiers = commonModifiers ? mods0 : [];
            }
        }

        if (showOptionUnavailableModal) {
            setTimeout(() => props.showModalMessage(modalMessages.orderUpdated()), 500);
        }
    }

    static checkVariant(variant: number | null, variants?: MenuItemVariant[]) {
        const selectedVariant = getSelectedModifierChoice(variants, variant);
        if (variant !== null && variants && selectedVariant && selectedVariant.available === false) {
            return null;
        }

        return variant;
    }

    //  Nested modifier structure of a top level order item OrderItemModifier
    //
    //  OrderItemModifier {
    //      modifier: number;
    //      options: number[];
    //      optionNestedModifiers?: OrderItemOptionNestedModifier [{
    //          optionIndex: number;
    //          quantity: number;
    //          modifiers: OrderItemNestedModifier extends OrderItemModifier [{
    //              modifierId: string;
    //              modifier: number; // not used
    //              options: number[];
    //              optionNestedModifiers?: : OrderItemOptionNestedModifier [{
    //                  ...
    //              }]
    //          }];
    //          selectedNestedData: OrderItemSelectedNestedModiferData [{
    //              displayName?: string;
    //              optionIndex?: number;
    //              modifierId?: string;
    //              price?: AvailablePrices;
    //              required: boolean;
    //          }];
    //      }];
    //  }
    static checkModifiers(
        orderItemModifiers: OrderItemModifier[] | null,
        menuItemModifiers: MenuItemModifier[] | null,
        globalModifiers?: Indexed<MenuItemModifier>
    ) {
        const { availableModifiers, updated, silentUpdateRequired } = MenuItemDetails.getAvailableModifiersRecursive(
            orderItemModifiers,
            menuItemModifiers,
            globalModifiers
        );

        if (updated || silentUpdateRequired) {
            return {
                updatedModifiers: availableModifiers?.length ? availableModifiers : null,
                silentUpdateRequired: !updated && silentUpdateRequired,
            };
        }

        return { updatedModifiers: orderItemModifiers };
    }

    static getAvailableModifiersRecursive<T extends OrderItemModifier>(
        orderItemModifiers: T[] | null | undefined,
        menuItemModifiers: MenuItemModifier[] | null,
        globalModifiers?: Indexed<MenuItemModifier>
    ): AvailableModifiersResult<T> {
        let updated = false;
        let silentUpdateRequired = false;

        const availableModifiers = orderItemModifiers
            ?.map((orderItemModifier): T => {
                const menuItemModifier = orderItemModifier["modifierId"]
                    ? globalModifiers?.[orderItemModifier["modifierId"]]
                    : menuItemModifiers?.[orderItemModifier.modifier];

                // Get selected modifier options that are still available
                const availableOptions = !menuItemModifier
                    ? orderItemModifier.options
                    : orderItemModifier.options.filter(
                          (option) => getSelectedModifierChoice(menuItemModifier.options, option)?.available !== false
                      );

                // Ensure a message is shown to the user if any of their selected options are now unavailable
                if (availableOptions.length < orderItemModifier.options.length) {
                    updated = true;
                }

                const isOptionNestedModifierAvailable = (optionNestedModifier: OrderItemOptionNestedModifier) => {
                    // If a modifier option is no longer available then the nested modifier should no longer be either
                    if (!availableOptions.includes(optionNestedModifier.optionIndex)) return false;

                    if (!menuItemModifier) return true;

                    const option = getSelectedModifierChoice(
                        menuItemModifier.options,
                        optionNestedModifier.optionIndex
                    );

                    if (!globalModifiers || !option?.nestedModifiers) return true;

                    // Check the option's nested modifier ids still map to available global modifiers
                    return getHasAvailableNestedModifier(option, globalModifiers);
                };

                const getOptionNestedModiferWithAvailableModifiersAndOptions = (
                    optionNestedModifier: OrderItemOptionNestedModifier
                ): OrderItemOptionNestedModifier => {
                    // Go through all nested modifiers and remove any that are no longer available
                    // Ensure we only keep the nested data (price etc) for those options that are still available
                    const {
                        availableModifiers: availableNestedModifiers,
                        updated: nestedUpdated,
                        silentUpdateRequired: nestedSilentUpdateRequired,
                    } = MenuItemDetails.getAvailableModifiersRecursive(
                        optionNestedModifier.modifiers,
                        menuItemModifiers,
                        globalModifiers
                    );

                    if (nestedUpdated) {
                        updated = true;
                    }

                    if (nestedSilentUpdateRequired) {
                        silentUpdateRequired = true;
                    }

                    let availableSelectedNestedData = optionNestedModifier.selectedNestedData?.filter(
                        (d) =>
                            d.optionIndex !== undefined &&
                            availableNestedModifiers?.some(
                                (m) => m.modifierId === d.modifierId && m.options.includes(d.optionIndex!)
                            )
                    );

                    if (!availableSelectedNestedData?.length) {
                        // All selected modifiers are now unavailable, so rebuild selected nested data from scratch
                        const option = menuItemModifier
                            ? getSelectedModifierChoice(menuItemModifier.options, optionNestedModifier.optionIndex)
                            : undefined;

                        // Get nested data for all available nested options of this modifier option
                        availableSelectedNestedData = option
                            ? MenuItemDetails.getAvailableSelectedNestedData(option, globalModifiers)
                            : ([] as OrderItemSelectedNestedModiferData[]);

                        // If nothing was selected and we're not already updating and the number of available options
                        // has changed then we do a silent update
                        if (availableSelectedNestedData.length !== optionNestedModifier.selectedNestedData?.length) {
                            silentUpdateRequired = true;
                        }
                    }

                    return {
                        ...optionNestedModifier,
                        modifiers: availableSelectedNestedData.length ? availableNestedModifiers ?? [] : [],
                        selectedNestedData: availableSelectedNestedData,
                    };
                };

                // Get all available nested modifiers for this modifier option
                const availableOptionNestedModifiers = orderItemModifier.optionNestedModifiers
                    ?.filter(isOptionNestedModifierAvailable)
                    .map(getOptionNestedModiferWithAvailableModifiersAndOptions);

                // Ensure a message is shown to the user if any of their selected nested modifiers are no longer available
                if (
                    availableOptionNestedModifiers &&
                    orderItemModifier.optionNestedModifiers &&
                    availableOptionNestedModifiers.length !== orderItemModifier.optionNestedModifiers.length
                ) {
                    updated = true;
                }

                return {
                    ...orderItemModifier,
                    options: availableOptions,
                    optionNestedModifiers: availableOptionNestedModifiers?.length
                        ? availableOptionNestedModifiers
                        : undefined,
                };
            })
            .filter((m) => m.options.length);

        return {
            availableModifiers,
            updated,
            silentUpdateRequired,
        };
    }

    hasVariantSpecificModifiers = () => !!this.commonVariantModifiers && !this.commonVariantModifiers.length;

    componentDidMount() {
        if (
            !!this.descriptionRef?.current &&
            this.descriptionRef.current.scrollHeight <= this.descriptionRef.current.clientHeight
        ) {
            this.setState({ showMore: false });
        }
    }

    static getUpdatedPendingItem(props: Props, pendingItem: PendingItem, pendingItemUpdate: Partial<PendingItem> = {}) {
        const {
            menuItem,
            triedToSubmit,
            priceResolver,
            pendingItem: { quantity },
            globalModifiers,
        } = props;

        const newPendingItem: PendingItem = {
            ...pendingItem,
            triedToSubmit,
            ...pendingItemUpdate,
        };

        newPendingItem.quantity = quantity;
        newPendingItem.canSubmit = canAddPendingItemToCart(menuItem, newPendingItem, globalModifiers);
        newPendingItem.price = calculateOrderItemTotal(menuItem, newPendingItem, priceResolver);

        return newPendingItem;
    }

    updatePendingItem = (pendingItemUpdate: Partial<PendingItem> = {}) => {
        const newPendingItem = MenuItemDetails.getUpdatedPendingItem(
            this.props,
            this.state.pendingItem,
            pendingItemUpdate
        );

        this.setState({ pendingItem: newPendingItem }, () => this.props.setPendingItem(this.state.pendingItem));
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<LocalState>) {
        const { menuItem, showModalMessage, triedToSubmit, globalModifiers, resetNestedModifiers } = this.props;
        const {
            pendingItem: { variant, modifiers, unavailable },
        } = this.state;

        if (prevProps.menuItem !== menuItem) {
            const update: Partial<PendingItem> = {};
            const updatedUnavailable = menuItem.available === false;

            if (updatedUnavailable !== unavailable) {
                update.unavailable = updatedUnavailable;
            }

            let showOptionUnavailableModal = false;
            const updatedVariant = MenuItemDetails.checkVariant(variant, menuItem.variants);

            if (updatedVariant !== variant) {
                showOptionUnavailableModal = true;
                update.variant = updatedVariant;
            }

            const { updatedModifiers, silentUpdateRequired } = MenuItemDetails.checkModifiers(
                modifiers,
                menuItem.modifiers ?? null,
                globalModifiers
            );

            if (silentUpdateRequired) {
                update.modifiers = updatedModifiers;
            } else if (updatedModifiers !== modifiers) {
                showOptionUnavailableModal = true;
                update.modifiers = updatedModifiers;
            }

            if (Object.keys(update).length) {
                this.updatePendingItem(update);
            }

            if (showOptionUnavailableModal) {
                showModalMessage(modalMessages.orderUpdated());
                resetNestedModifiers();
            }
        }

        if ((triedToSubmit && prevProps.triedToSubmit !== triedToSubmit) || this.shouldScrollNext) {
            this.shouldScrollNext = false;
            MenuItemDetails.scrollNextItemIntoView();
        }
    }

    static scrollNextItemIntoView(elementQuery?: string, scrollingElementQuery?: string) {
        const element = document.querySelector<HTMLSpanElement>(
            elementQuery || ".menuitemcard .select-mode.available-selections"
        );
        if (element) {
            if (device.isAndroid || device.isChrome) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                scrolling.scrollTo(
                    { top: element.offsetTop, behavior: "smooth" },
                    scrollingElementQuery || ".MenuItemModalPortal"
                );
            }
        }
    }

    render() {
        const {
            dietaryTags,
            dietaryInformationUrl,
            menuItem,
            allowNotesToKitchen,
            hasParty,
            removeFromOrder,
            trackAllergenNoticeClick,
        } = this.props;
        const {
            showFullDescription,
            showMore,
            showNote,
            pendingItem: { notes, unavailable, orderItemId },
            visibleModifiers,
        } = this.state;
        const expanded = showFullDescription || !showMore;

        const { variants, modifiers, availablePrices, minVariantPrice, energyContent } = menuItem;
        const hasVariants = variants && variants.length > 0;
        const hasModifiers = modifiers && modifiers.length > 0;
        const hasMemberPricing = hasMemberPrices(availablePrices, minVariantPrice);

        const descriptionClass = classNames("menuitemcard-content__description", {
            "menuitemcard-content__description--expanded": expanded,
        });

        return (
            <div className={classNames("menuitemcard", { unavailable: unavailable })}>
                <div className="menuitemcard-scroll">
                    <div className="menuitemcard-content">
                        {hasMemberPricing && (
                            <Text preset="g-14" mode="bold" className="menuitemcard-content__member-pricing">
                                <MembershipIcon />
                                Members
                            </Text>
                        )}
                        <div className="menuitemcard-content__info">
                            {!menuItem.displayNames && (
                                <Text preset="title-32" mode="extra-bold" className="menuitemcard-content__info__name">
                                    {menuItem.displayName}
                                </Text>
                            )}
                            {menuItem.displayNames && (
                                <Text preset="title-32" mode="extra-bold" className="menuitemcard-content__info__name">
                                    {menuItem.displayNames[0]}
                                </Text>
                            )}
                            <div className="menuitemcard-content__info__attributes">
                                <div>
                                    <DietaryTags tags={dietaryTags} />
                                </div>
                            </div>
                        </div>
                        {menuItem.displayNames && (
                            <>
                                <span className="menuitemcard-content__info__name--varietal">{` ${menuItem.displayNames
                                    .slice(1)
                                    .join(" ")}`}</span>
                                {menuItem.details && (
                                    <div className={descriptionClass}>
                                        <p className="menuitemcard-content__info__description--details">
                                            {menuItem.details.join(`  |  `)}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                        {!hasVariants && <EnergyContent value={energyContent} />}
                        {!!menuItem.description && (
                            <TappableDiv onClick={this.showFullDescription} className={descriptionClass}>
                                <Text preset="g-14">
                                    <p className="menuitemcard-content__description--text" ref={this.descriptionRef}>
                                        {menuItem.description}
                                    </p>
                                </Text>
                                {!!menuItem.description && (
                                    <Text preset="g-14" mode="bold" className="menuitemcard-content__description--more">
                                        See more
                                    </Text>
                                )}
                            </TappableDiv>
                        )}
                        {(menuItem.special || menuItem.popular) && (
                            <div className="menuitemcard-content__info__tags">
                                <ol className="tag" r-cmp="tag">
                                    {menuItem.special && (
                                        <li className="tag__item">
                                            <SpecialIcon />
                                            <Text preset="g-14"> Special</Text>
                                        </li>
                                    )}
                                    {menuItem.popular && (
                                        <li className="tag__item">
                                            <PopularIcon />
                                            <Text preset="g-14"> Popular</Text>
                                        </li>
                                    )}
                                </ol>
                            </div>
                        )}
                        {dietaryInformationUrl && (
                            <div className="menuitemcard-content__info__allergen">
                                <TappableAnchor
                                    {...href.openInNewWindowAttributes(dietaryInformationUrl)}
                                    onTap={trackAllergenNoticeClick}
                                >
                                    <Text mode="bold" preset="g-14">
                                        View allergen information
                                    </Text>
                                    <ArrowRight />
                                </TappableAnchor>
                            </div>
                        )}
                    </div>
                    {(hasVariants || hasModifiers) && !unavailable && (
                        <div className="menuitemcard-modifiers" ref={this.variantsRef}>
                            {hasVariants && this.renderVariants()}
                            {hasModifiers && (
                                <>
                                    {this.hasVariantSpecificModifiers() ? (
                                        <CSSTransition in={!!visibleModifiers} classNames="fade" timeout={200}>
                                            {this.renderModifiers()}
                                        </CSSTransition>
                                    ) : (
                                        this.renderModifiers()
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {allowNotesToKitchen && hasParty && (
                        <>
                            <div className="menuitemcard-note">
                                <MenuItemExpandableHeader
                                    title="Order notes"
                                    onClick={this.toggleNote}
                                    open={showNote && !unavailable}
                                    canClose={!notes}
                                />
                                <MenuItemExpandableContent show={showNote && !unavailable}>
                                    <FormControl
                                        id="menu-item-details-note"
                                        invalid={!!notes && notes.length > 20}
                                        message="Maximum 20 characters"
                                        invalidMessage="Looks like you have a detailed request, please ask your waiter for
                                        assistance."
                                    >
                                        <Input
                                            label="Order note"
                                            placeholder="Example: No onions"
                                            value={notes || ""}
                                            onChange={this.showLimits}
                                            onClear={this.showLimits}
                                            onFocus={(e) => e?.target && scrolling.scrollElementIntoView(e.target)}
                                        />
                                    </FormControl>
                                </MenuItemExpandableContent>
                            </div>
                        </>
                    )}
                    {!!orderItemId && (
                        <div className="menuitemcard__remove">
                            <NetworkConnectedButton
                                mode="blank"
                                onClick={removeFromOrder}
                                className="menuitemcard__remove__button"
                            >
                                <TrashIcon /> Remove item
                            </NetworkConnectedButton>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderVariants = () => {
        const {
            menuItem: { variants },
            triedToSubmit,
        } = this.props;
        const {
            pendingItem: { variant },
        } = this.state;

        return (
            <>
                <div className="menuitemcard-variants">
                    <div className="modifiers__header">
                        <div className="l">
                            <Text
                                preset="g-12"
                                className={classNames("select-mode", {
                                    error: triedToSubmit && variant === null,
                                    required: variant === null,
                                })}
                                value="Required"
                            />
                            <Text preset="title-20" mode="extra-bold" value="Type" />
                        </div>
                    </div>
                    <div className="menuitemcard-variants__dropDownList">
                        <OrderVariants
                            variants={variants!}
                            selectedVariantId={variant}
                            onVariantSelected={this.onVariantSelected}
                        />
                    </div>
                    <Divider />
                </div>
            </>
        );
    };

    renderModifiers = () => {
        const {
            menuItem: { modifiers },
            triedToSubmit,
            activeServiceMenuItemIds,
        } = this.props;
        const {
            pendingItem: { variant, modifiers: selectedModifiers },
            visibleModifiers,
            prevVisibleModifiers,
        } = this.state;

        return (
            <div className="addtoordermodal__modifiers">
                <OrderModifiers
                    modifiers={modifiers!}
                    selectedModifiers={selectedModifiers}
                    onModifierOptionSelected={this.onModifierOptionSelected}
                    triedToSubmit={!!triedToSubmit}
                    visibleModifiers={
                        variant === null ? this.commonVariantModifiers : visibleModifiers || prevVisibleModifiers
                    }
                    onModifierMultiSelectChanged={this.onModifierMultiSelectChanged}
                    onNestedModifierEdit={this.onNestedModifierEdit}
                    activeServiceMenuItemIds={activeServiceMenuItemIds}
                    canCollapse
                />
            </div>
        );
    };

    showLimits = (notes?: string) => {
        const regex_symbols = /[^a-zA-Z0-9 ]/g;
        this.updatePendingItem({
            notes: notes?.replace(regex_symbols, "") || null,
        });
    };

    toggleNote = () => {
        const {
            showNote,
            pendingItem: { notes },
        } = this.state;
        if (notes) return;
        this.setState({ showNote: !showNote });
    };

    getSelectedModifier = (modifierId: number, selectedModifiers: OrderItemModifier[]) => {
        let selectedModifier = selectedModifiers.find((m) => m.modifier === modifierId);

        if (!selectedModifier) {
            selectedModifier = {
                modifier: modifierId,
                options: [],
            };
            selectedModifiers.push(selectedModifier);
        }

        return selectedModifier;
    };

    updateModifiers = (
        modifierId: number,
        selectedModifiers: OrderItemModifier[],
        selectedModifier: OrderItemModifier
    ) => {
        this.updatePendingItem({
            modifiers: selectedModifier.options.length
                ? [...selectedModifiers]
                : selectedModifiers.filter((m) => m.modifier !== modifierId),
        });
    };

    onModifierOptionSelected = (modifierId: number, optionId: number, selected: boolean) => {
        const {
            menuItem: { modifiers },
            editNestedModifier,
            globalModifiers,
            isEditingNestedModifier,
        } = this.props;
        if (isEditingNestedModifier) return;

        const { pendingItem } = this.state;

        const modifier = modifiers![modifierId];
        const selectedModifiers = pendingItem.modifiers || [];
        const selectedModifier = this.getSelectedModifier(modifierId, selectedModifiers);
        const shouldScrollNext = MenuItemDetails.changeModifierOption(optionId, selected, modifier, selectedModifier);

        if (shouldScrollNext !== undefined) {
            this.shouldScrollNext = shouldScrollNext;
        }

        MenuItemDetails.handleNestedModifiers(
            optionId,
            selected,
            modifier,
            globalModifiers,
            selectedModifier,
            editNestedModifier,
            this.onNestedModifierSaved,
            true
        );

        this.updateModifiers(modifierId, selectedModifiers, selectedModifier);
    };

    onNestedModifierEdit = (modifierId: number, optionId: number) => {
        const {
            menuItem: { modifiers },
            editNestedModifier,
            globalModifiers,
            isEditingNestedModifier,
        } = this.props;
        if (isEditingNestedModifier) return;

        const { pendingItem } = this.state;

        const selectedModifiers = pendingItem.modifiers || [];
        const modifier = modifiers![modifierId];
        const selectedModifier = this.getSelectedModifier(modifierId, selectedModifiers);

        MenuItemDetails.handleNestedModifiers(
            optionId,
            true,
            modifier,
            globalModifiers,
            selectedModifier,
            editNestedModifier,
            this.onNestedModifierSaved,
            true,
            true
        );
    };

    static changeModifierOption = (
        optionId: number,
        selected: boolean,
        modifier: MenuItemModifier,
        selectedModifier: OrderItemModifier
    ) => {
        if (!selected) {
            selectedModifier.options = selectedModifier.options.filter((o) => o !== optionId);
        } else if (
            modifier.maxSelection === 1 &&
            modifier.options.length !== 1 &&
            selectedModifier.options[0] !== optionId
        ) {
            selectedModifier.options = [optionId];
            selectedModifier.optionNestedModifiers = [];

            return getCanScrollPastOptionWithNestedModifiers(selectedModifier.optionNestedModifiers);
        } else if (selectedModifier.options.length < modifier.maxSelection || modifier.maxSelection === 0) {
            selectedModifier.options = selectedModifier.options.concat(optionId);

            const optionHasNestedModifiers = modifier.options[optionId].nestedModifiers?.length;
            const optionHasNestedModifiersSelection = selectedModifier.optionNestedModifiers?.some(
                (nestedMod) => nestedMod.optionIndex === optionId
            );

            if (!modifier.maxSelection || (optionHasNestedModifiers && !optionHasNestedModifiersSelection)) {
                return false;
            }

            const allSelectionsMade = selectedModifier.options.length === modifier.maxSelection;
            const canScroll = getCanScrollPastOptionWithNestedModifiers(selectedModifier.optionNestedModifiers);

            return canScroll && allSelectionsMade;
        }
        return;
    };

    static getAvailableSelectedNestedData = (option: ModifierOption, globalModifiers?: Indexed<MenuItemModifier>) => {
        if (!globalModifiers || !option.nestedModifiers) return [];

        return option.nestedModifiers
            .map((nested) => {
                const modifierDetails = globalModifiers[nested];
                if (modifierDetails.available === false || !isModifierAvailable(modifierDetails)) {
                    return null;
                }

                return {
                    required: !!modifierDetails.minSelection,
                    modifierId: nested,
                };
            })
            .filter(nonNullable);
    };

    static handleNestedModifiers = (
        optionId: number,
        selected: boolean,
        modifier: MenuItemModifier,
        globalModifiers: Indexed<MenuItemModifier> | undefined,
        selectedModifier: OrderItemModifier,
        editNestedModifier: ((pendingModifier: PendingModifierState) => void) | undefined,
        onNestedModifierSaved: (
            optionNestedModifiers: OrderItemOptionNestedModifier,
            selectedNestedOptions: OrderItemNestedModifier[],
            selectedNestedData: OrderItemSelectedNestedModiferData[]
        ) => void,
        show: boolean,
        edit: boolean = false
    ) => {
        const selectedOption: ModifierOption | undefined = getSelectedModifierChoice(modifier.options, optionId);

        if (
            !selectedOption?.nestedModifiers?.length ||
            !globalModifiers ||
            !selectedOption.nestedModifiers.some((nested) => globalModifiers[nested])
        ) {
            return;
        }

        if (!selected) {
            selectedModifier.optionNestedModifiers = selectedModifier.optionNestedModifiers?.filter(
                (n) => n.optionIndex !== optionId
            );
            return;
        }

        let optionNestedModifier = selectedModifier.optionNestedModifiers?.find((onm) => onm.optionIndex === optionId);

        if (!optionNestedModifier) {
            // Get nested data for all available options of this nested modifier
            const availableSelectedNestedData = MenuItemDetails.getAvailableSelectedNestedData(
                selectedOption,
                globalModifiers
            );

            if (!availableSelectedNestedData.length) return;

            optionNestedModifier = {
                optionIndex: optionId,
                quantity: 0,
                modifiers: [],
                selectedNestedData: availableSelectedNestedData,
            };

            selectedModifier.optionNestedModifiers = selectedModifier.optionNestedModifiers ?? [];
            selectedModifier.optionNestedModifiers.push(optionNestedModifier);
        }

        optionNestedModifier.quantity = selectedModifier.options.filter((o) => o === optionId).length || 1;

        if (show && editNestedModifier && (edit || optionNestedModifier.selectedNestedData.some((nm) => nm.required))) {
            editNestedModifier({
                modifierTitle: selectedOption.displayName,
                editingNestedModifiers: selectedOption.nestedModifiers,
                selectedOptions: optionNestedModifier.modifiers,
                optionNestedModifier: optionNestedModifier,
                onNestedModifierSaved,
            });
        }
    };

    onNestedModifierSaved = (
        optionNestedModifier: OrderItemOptionNestedModifier,
        selectedNestedOptions: OrderItemNestedModifier[],
        selectedNestedData: OrderItemSelectedNestedModiferData[]
    ) => {
        const { pendingItem } = this.state;
        if (!pendingItem.modifiers) return;

        optionNestedModifier.modifiers = selectedNestedOptions;
        optionNestedModifier.selectedNestedData = selectedNestedData;

        this.updatePendingItem({
            modifiers: [...pendingItem.modifiers],
        });
    };

    onModifierMultiSelectChanged = (modifierId: number, optionId: number, isAdded: boolean) => {
        const {
            menuItem: { modifiers },
            editNestedModifier,
            globalModifiers,
            isEditingNestedModifier,
        } = this.props;
        if (isEditingNestedModifier) return;

        const { pendingItem } = this.state;

        const modifier = modifiers![modifierId];
        const selectedModifiers = pendingItem.modifiers || [];
        const selectedModifier = this.getSelectedModifier(modifierId, selectedModifiers);
        const shouldScrollNext = MenuItemDetails.changeModifierMultiSelect(
            optionId,
            isAdded,
            modifier,
            selectedModifier
        );

        if (shouldScrollNext !== undefined) {
            this.shouldScrollNext = shouldScrollNext;
        }

        if (isAdded || selectedModifier.optionNestedModifiers?.some((nm) => nm.optionIndex === optionId)) {
            MenuItemDetails.handleNestedModifiers(
                optionId,
                selectedModifier.options.some((option) => option === optionId),
                modifier,
                globalModifiers,
                selectedModifier,
                editNestedModifier,
                this.onNestedModifierSaved,
                false
            );
        }

        this.updateModifiers(modifierId, selectedModifiers, selectedModifier);
    };

    static changeModifierMultiSelect = (
        optionId: number,
        isAdded: boolean,
        modifier: MenuItemModifier,
        selectedModifier: OrderItemModifier
    ) => {
        if (isAdded) {
            selectedModifier.options = selectedModifier.options.concat(optionId);

            if (!modifier.maxSelection) {
                return false;
            } else if (modifier.maxSelection === selectedModifier.options.length) {
                return getCanScrollPastOptionWithNestedModifiers(selectedModifier.optionNestedModifiers);
            }
        } else {
            const index = selectedModifier.options.findIndex((o) => o === optionId);
            selectedModifier.options.splice(index, 1);
            if (!selectedModifier.options.some((o) => o === optionId)) {
                selectedModifier.optionNestedModifiers = selectedModifier.optionNestedModifiers?.filter(
                    (onm) => onm.optionIndex !== optionId
                );
            }
        }
        return;
    };

    onVariantSelected = (variant: any) => {
        const {
            menuItem: { variants },
        } = this.props;
        const { visibleModifiers } = this.state;
        const selectedVariant: MenuItemVariant | undefined = getSelectedModifierChoice(variants, variant);

        if (
            this.hasVariantSpecificModifiers() &&
            selectedVariant &&
            selectedVariant.modifiers?.toString() !== this.state.visibleModifiers?.toString()
        ) {
            this.setState({
                visibleModifiers: undefined,
                prevVisibleModifiers: visibleModifiers || this.commonVariantModifiers,
            });

            window.setTimeout(() => {
                this.setState({ visibleModifiers: selectedVariant.modifiers });
                MenuItemDetails.scrollNextItemIntoView();
            }, 200);
        } else {
            this.shouldScrollNext = true;
        }

        this.updatePendingItem({
            variant,
        });
    };

    showFullDescription = (e: any) => {
        e.preventDefault();
        this.setState({ showFullDescription: true });
    };
}
