import { HostedFieldFlags } from "../types";

export enum TypeKeys {
    SET_VALID = "HOSTED_FIELDS/SET_VALID",
    SET_EMPTY = "HOSTED_FIELDS/SET_EMPTY",
    CLEAR_STATE = "HOSTED_FIELDS/CLEAR_STATE",
}

export const actionCreators = {
    setValid: (field: string, valid: boolean | undefined) => ({ type: TypeKeys.SET_VALID, field, valid }),
    setEmpty: (field: string, empty: boolean) => ({ type: TypeKeys.SET_EMPTY, field, empty }),
    clearState: () => ({ type: TypeKeys.CLEAR_STATE }),
};

export interface HostedFieldsState {
    validState: HostedFieldFlags;
    emptyState: HostedFieldFlags;
}

type SetFieldValidStateAction = { type: TypeKeys.SET_VALID; field: string; valid: boolean | undefined };
type SetFieldEmptyStateAction = { type: TypeKeys.SET_EMPTY; field: string; empty: boolean };
export type ClearStateAction = { type: TypeKeys.CLEAR_STATE };

type HostedFieldFlagsAction = SetFieldValidStateAction | SetFieldEmptyStateAction | ClearStateAction;

const initialState: HostedFieldsState = {
    validState: {},
    emptyState: {},
};

export function reducer(state: HostedFieldsState = initialState, action: HostedFieldFlagsAction): HostedFieldsState {
    if (action.type === TypeKeys.SET_VALID) {
        return {
            ...state,
            validState: {
                ...state.validState,
                [action.field]: action.valid,
            },
        };
    }

    if (action.type === TypeKeys.SET_EMPTY) {
        return {
            ...state,
            emptyState: {
                ...state.emptyState,
                [action.field]: action.empty,
            },
        };
    }

    if (action.type === TypeKeys.CLEAR_STATE) {
        return {
            validState: {},
            emptyState: {},
        };
    }

    return state;
}
