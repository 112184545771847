export interface BackoffOptions {
    initialDelay?: number;
    maxDelay?: number;
    multiplier?: number;
    maxRetries?: number;
}

export function backoff(options: BackoffOptions, delayer: (ms: number) => Promise<void> = wait) {
    const { initialDelay, maxDelay, multiplier, maxRetries } = options;

    let count = 0;
    let delay = initialDelay;

    delayer = (delayer || wait)!;

    return async (err: any) => {
        if (maxRetries && count++ >= maxRetries) {
            throw err ? err : Error(`Maximum retries (${maxRetries}) reached`);
        }

        if (delay) {
            await delayer!(delay);

            if (multiplier) {
                delay *= multiplier;

                if (maxDelay && delay > maxDelay) {
                    delay = maxDelay;
                }
            }
        }
    };
}

export function wait(ms: number) {
    return new Promise<void>((res) => window.setTimeout(res, ms));
}
