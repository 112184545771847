import { actionCreators } from "../reducers/native";
import { AppDispatch, AppState } from "../../index";
import { getOperationPreventsPause } from "../../operations/operation";

export function paused() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        for (const [operationName, operationState] of Object.entries(getState().operations)) {
            if (operationState?.status === "processing" && getOperationPreventsPause(operationName)) {
                return;
            }
        }
        dispatch(actionCreators.paused());
    };
}
