import { GooglePayPaymentMethod } from "src/common/payment";
import { GetGooglePayAuthorizePaymentResultFunc, initializeGooglePayButton } from "../common/initializeGooglePayButton";
import { TyroGooglePayPaymentMethod } from "src/features/paymentGateways/tyro/types";
import { allowedPaymentMethod } from "../common/getGooglePayAvailable";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

const getPaymentDataRequest = (
    googlePayPaymentMethod: GooglePayPaymentMethod,
    transactionInfo: google.payments.api.TransactionInfo
) => {
    const { googlePayMerchantId, googlePayMerchantName, gateway, gatewayMerchantId, countryCode } =
        googlePayPaymentMethod as TyroGooglePayPaymentMethod;

    const paymentDataRequest: google.payments.api.PaymentDataRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo: {
            merchantId: googlePayMerchantId ?? "TEST",
            merchantName: googlePayMerchantName,
        },
        allowedPaymentMethods: [
            {
                ...allowedPaymentMethod,
                tokenizationSpecification: {
                    type: "PAYMENT_GATEWAY",
                    parameters: {
                        gateway,
                        gatewayMerchantId,
                    },
                },
            },
        ],
        transactionInfo: {
            ...transactionInfo,
            countryCode,
        },
    };

    return Promise.resolve(paymentDataRequest);
};

const getAuthorizePaymentResult: GetGooglePayAuthorizePaymentResultFunc = (paymentData) =>
    Promise.resolve({
        paymentToken: paymentData.paymentMethodData.tokenizationData.token!,
    });

export const initializeTyroGooglePayButton: InitializeDevicePaymentMethodButtonFunc<GooglePayPaymentMethod> = (
    googlePayPaymentMethod,
    total,
    { trackEvent }
) =>
    initializeGooglePayButton(
        googlePayPaymentMethod,
        total,
        trackEvent,
        (transactionInfo) => getPaymentDataRequest(googlePayPaymentMethod, transactionInfo),
        getAuthorizePaymentResult
    );
