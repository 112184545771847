import { AppDispatch, AppState } from "../..";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { orderApi } from "../../order/orderApi";
import { enableGroupTabPackageOperation } from "../operations";
import { getTabTypeName } from "../selectors/activeGroupTab";

export function enableGroupTabPackageAction() {
    return enableGroupTabPackageOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const tabTypeName = getTabTypeName(getState());
        try {
            await orderApi.invoke("enableGroupTabPackage");
        } catch (ex) {
            if (ProblemDetailsError.isProblemDetailsError(ex)) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("failed to enable group tab package", ex);
            }
        }
    });
}
