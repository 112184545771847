import { cordovaWindow } from "./cordova";

const KEY = "lastReview";
export const storeRating = {
    init: () => {
        const { AppRate } = cordovaWindow;
        AppRate.init();
        AppRate.preferences = {
            displayAppName: "me&u",
            usesUntilPrompt: 0,
            promptAgainForEachNewVersion: false,
            inAppReview: true,
            simpleMode: true,
            storeAppURL: {
                ios: "au.com.meandu.orderapp",
                android: "market://details?id=au.com.meandu.orderapp",
            },
            customLocale: {
                title: "Loving %@?",
                message: "Enjoying the new way to order & pay? Rate us on the Play Store.",
                cancelButtonLabel: "",
                laterButtonLabel: "Not now",
                rateButtonLabel: "Rate it now",
            },
            callbacks: {
                onButtonClicked: function (buttonIndex: number) {
                    switch (buttonIndex) {
                        // rate now
                        case 3:
                            window.localStorage.setItem(KEY, "true");
                            break;
                    }
                },
            },
            openUrl: AppRate.preferences.openUrl,
        };
        AppRate.promptForRating(false);

        document.addEventListener("logout", () => {
            window.localStorage.removeItem(KEY);
        });
    },
    trigger: () => {
        const reviewed = window.localStorage.getItem(KEY);
        if (reviewed && reviewed === "true") {
            return;
        }
        const { device, AppRate } = cordovaWindow;
        if (device.platform === "iOS") {
            AppRate.navigateToAppStore();
        } else {
            AppRate.promptForRating();
        }
    },
};
