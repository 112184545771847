import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { StatusBar } from "src/common/statusBar";
import { SimpleNavHeader } from "src/common/navigation";
import "../assets/MarketingModal.scss";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { PrivacyPolicy } from "src/features/compliance/components/PrivacyPolicy";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { isValidEmail } from "../../../common/validation";
import { Button, Input, Text } from "src/sharedComponents";
import { href } from "src/common/experience";
import { ModalContainer } from "src/common/modal";

export interface Props {
    locationId: string | null;
    partyId: string | null;
    groupName?: string;
    isPremiumGroup?: boolean;
    locationName?: string;
    locationImage?: string;
    locationLogo?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    title?: string;
    newsletterProvider?: string;
    historyOpen?: boolean;
    resetMarketing: (partyId: string | null) => void;
    sendOptIn: (locationId: string, firstName: string, lastName: string, email: string, partyId: string | null) => void;
    onPrivacyPolicyClicked: (venuePrivacy: boolean, url?: string) => void;
    isOpen: boolean;
    privacyPolicyUrl?: string;
}

interface State {
    firstName: string;
    lastName: string;
    email: string;
    error?: boolean;
    openPrivacy: boolean;
    historyOpen?: boolean;
    locationImage?: string;
    locationLogo?: string;
}

const getInitialState = () => ({
    openPrivacy: false,
    firstName: "",
    lastName: "",
    email: "",
});

const windowOuterHeight = window.outerHeight;

export class MarketingModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = getInitialState();
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.firstName && !!this.props.firstName) {
            this.setState({ firstName: this.props.firstName });
        }
        if (!prevProps.lastName && !!this.props.lastName) {
            this.setState({ lastName: this.props.lastName });
        }
        if (!prevProps.email && !!this.props.email) {
            this.setState({ email: this.props.email });
        }
        if (this.props.historyOpen !== undefined && prevProps.historyOpen !== this.props.historyOpen) {
            this.setState({ historyOpen: this.props.historyOpen });
        }
        if (this.props.locationLogo !== undefined && prevProps.locationLogo !== this.props.locationLogo) {
            this.setState({ locationLogo: this.props.locationLogo });
        }
        if (this.props.locationImage !== undefined && prevProps.locationImage !== this.props.locationImage) {
            this.setState({ locationImage: this.props.locationImage });
        }
        if (prevProps.isOpen && !this.props.isOpen) {
            setTimeout(() => this.setState(getInitialState()), 300);
        }
    }

    closeModal = async () => {
        await waitForKeyboardClose();
        const { partyId, resetMarketing } = this.props;
        resetMarketing(partyId);
    };

    sendOptIn = async () => {
        const { partyId, locationId, sendOptIn } = this.props;
        const { firstName, lastName, email } = this.state;
        if (!firstName?.trim() || !lastName?.trim() || !email?.trim() || !isValidEmail(email)) {
            this.setState({ error: true });
            return;
        }
        this.setState({ error: false });
        await waitForKeyboardClose();
        
        if (locationId) {
            sendOptIn(locationId, firstName, lastName, email, partyId);
        }
    };

    openPrivacy = async () => {
        this.props.onPrivacyPolicyClicked(false);
        await waitForKeyboardClose();
        this.setState({ openPrivacy: true });
    };

    closePrivacy = () => this.setState({ openPrivacy: false });

    renderFooterPrivacy = () => {
        const { privacyPolicyUrl, onPrivacyPolicyClicked } = this.props;
        return (
            <Text preset="g-12" className="marketing--footer--privacy">
                By signing up, you will be subscribed to the {this.props.newsletterProvider} newsletter. View our{" "}
                <TappableDiv className="marketing--footer--privacy--link" onTap={this.openPrivacy}>
                    Privacy Policy
                </TappableDiv>
                {privacyPolicyUrl ? (
                    <>
                        {" "}
                        and the{" "}
                        <a
                            className="marketing--footer--privacy--link"
                            {...href.openInNewWindowAttributes(privacyPolicyUrl)}
                            onClick={() => onPrivacyPolicyClicked(true, privacyPolicyUrl)}
                        >
                            Privacy Policy
                        </a>{" "}
                        of the venue.
                    </>
                ) : (
                    "."
                )}
            </Text>
        );
    };

    render() {
        const { isOpen, partyId, locationName, title } = this.props;
        const { firstName, lastName, email, error, openPrivacy, historyOpen, locationImage, locationLogo } = this.state;

        const showHeader =
            !!locationImage && !!locationLogo && locationLogo.endsWith(".svg") && windowOuterHeight > 500;
        return (
            <ModalContainer
                isOpen={isOpen}
                className={{
                    base: "marketing slide-in",
                    afterOpen: "marketing-modal--after-open slide-in--after-open",
                    beforeClose: "marketing-modal--before-close slide-in--before-close",
                }}
                bodyOpenClassName="Marketing-modal__Body--open"
                overlayClassName={classNames(
                    "slide-in-modal--overlay",
                    "marketing-modal--overlay",
                    "keyboard-unfixed",
                    historyOpen && "from-history"
                )}
                contentLabel="Marketing modal container"
                closeTimeoutMS={250}
            >
                <StatusBar backgroundColor={showHeader ? "#000" : "#fff"} />
                <div className="marketing--header">
                    <SimpleNavHeader
                        closeToBack={!historyOpen}
                        circleClose={showHeader}
                        hasBackground={!showHeader}
                        onBack={this.closeModal}
                        customBack={`marketing/${partyId}`}
                    />
                    {showHeader && (
                        <div
                            className="marketing--header-container"
                            style={{
                                background: `linear-gradient(0, rgba(0,0,0,0.3), rgba(0,0,0, 0.3)), url(${locationImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <div className="marketing__logo">
                                {locationLogo && locationLogo.endsWith(".svg") ? (
                                    <SVG src={locationLogo} color="white" />
                                ) : (
                                    <img src={locationLogo} alt="location logo" />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={classNames("marketing--scroll", showHeader && "show-header")}>
                    <div className="marketing--content">
                        <Text preset="title-24" mode="bold" className="marketing--content--title">
                            {title || "Get venue promotions"}
                        </Text>
                        <Text preset="g-14" className="marketing--content--subtitle">
                            Be the first to know what’s going on at {locationName} by joining our mailing list.
                        </Text>
                        <form>
                            <Input
                                allowClear
                                label="First name"
                                value={firstName}
                                placeholder="First name"
                                invalid={error && !firstName?.trim()}
                                onChange={(firstName: string = "") => this.setState({ firstName })}
                            />
                            <Input
                                allowClear
                                label="Last name"
                                value={lastName}
                                placeholder="Last name"
                                invalid={error && !lastName?.trim()}
                                onChange={(lastName: string = "") => this.setState({ lastName })}
                            />
                            <Input
                                allowClear
                                label="Email"
                                type="email"
                                value={email}
                                placeholder="Email"
                                invalid={error && (!email?.trim() || !isValidEmail(email))}
                                onChange={(email: string = "") => this.setState({ email })}
                            />
                        </form>
                        {this.renderFooterPrivacy()}
                    </div>
                </div>
                <div className="marketing--footer">
                    <Button mode="solid" onClick={this.sendOptIn}>
                        Sign up
                    </Button>
                    <Button mode="blank" secondary onClick={this.closeModal}>
                        No thanks
                    </Button>
                </div>

                <ModalContainer
                    isOpen={openPrivacy}
                    className={{
                        base: "marketing-modal slide-in",
                        afterOpen: "marketing-modal--after-open slide-in--after-open",
                        beforeClose: "marketing-modal--before-close slide-in--before-close",
                    }}
                    overlayClassName="slide-in--overlay marketing-modal--overlay"
                    contentLabel="Privacy Modal"
                    closeTimeoutMS={360}
                >
                    <PrivacyPolicy closeIcon goBack={this.closePrivacy} />
                </ModalContainer>
            </ModalContainer>
        );
    }
}
