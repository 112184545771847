import qrReducer, { actionCreators as qrActionCreators, QrState } from "./qr";
import onboardingReducer, { actionCreators as onboardingActionCreators } from "./onboarding";
import { OnboardingImages } from "../types";

export { qrReducer, qrActionCreators, onboardingReducer, onboardingActionCreators };

export interface State {
    qr: QrState;
    onboarding: OnboardingImages | null;
}
