import { Party, PartyType, TaxClassTotal } from "./types";
import { MenuItemModifier, ModifierChoice } from "../menudata";

const getTotalTaxes = (taxSummary?: TaxClassTotal[]) => {
    if (!taxSummary) return;
    return taxSummary
        .map(({ total }) => total)
        .filter((value) => !isNaN(value))
        .reduce((total, value) => total + value, 0);
};

export const getTotalExclusiveTaxes = (taxSummary?: TaxClassTotal[]) => {
    if (!taxSummary) return;
    const exclusiveTaxes = taxSummary.filter(({ exclusive }) => exclusive);
    return getTotalTaxes(exclusiveTaxes);
};

export const getModifierListInitialLimit = (
    defaultLimit: number,
    listItems: ModifierChoice[],
    selectedItems: number[] | null
) => {
    const shouldShowAll = selectedItems?.some((selectedItem) => {
        const index = listItems.findIndex((item) => item.originalIndex === selectedItem);
        return !!index && index + 1 > defaultLimit;
    });

    return shouldShowAll ? listItems.length : defaultLimit;
};

export const animateListItemBackground = (
    backgroundRef: React.MutableRefObject<HTMLDivElement | null>,
    animationRef: React.MutableRefObject<Animation | null>
) => {
    if (!!backgroundRef.current && !!backgroundRef.current.animate) {
        animationRef.current = backgroundRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 150,
        });

        animationRef.current.oncancel = () => {
            animationRef.current = null;
        };
        animationRef.current.onfinish = () => {
            animationRef.current = null;
        };
    }
};

/**
 * Modifier rule scenarios explained:
 *
 * !max && !min     -> "Optional"
 * max === min      -> "Select [max amount]"
 * !min && !!max    -> "Select up to [max amount]"
 * !!min && !max    -> "Select at least [min amount]"
 * !!min && !!max   -> "Select between [min amount] to [max amount]"
 */
export const getModifierRule = (modifier: MenuItemModifier) => {
    const { maxSelection, minSelection } = modifier;

    if (!!minSelection) {
        if (minSelection === maxSelection) {
            return `Required. Select ${maxSelection}`;
        } else if (!maxSelection) {
            return `Required. Select at least ${minSelection}`;
        } else {
            return `Required. Select between ${minSelection} to ${maxSelection}`;
        }
    } else {
        if (maxSelection) {
            return `Select up to ${maxSelection}`;
        } else {
            return "Optional";
        }
    }
};

export const isOrderAndPayParty = (party: Party | null) => party?.type === PartyType.SINGLEUSER;
