import * as React from "react";
import { ImageSet } from "../../menudata";
import { menuDataUrl } from "../../menudata/API/menuDataUrl";
import "react-lazy-load-image-component/src/effects/opacity.css";

export interface Props {
    images: ImageSet;
    name: string;
    className?: string;
    placeholderName?: string;
}

export const MenuItemImage = ({ className, images, name, placeholderName }: Props) => {
    const relativeImage = images[name];
    const relativePlaceHolder = placeholderName ? images[placeholderName] : "";

    const image = menuDataUrl(relativeImage);

    const placeholder = menuDataUrl(relativePlaceHolder);
    if (placeholderName) {
        const imageLoad = new Image();
        imageLoad.onload = () => {
            if (realImage.current) {
                realImage.current.src = imageLoad.src;
            }
        };
        imageLoad.src = image;
    }
    const realImage: React.RefObject<HTMLImageElement> = React.createRef();

    // TODO: Handle missing image
    // TODO: How will retina work (try for `${name}@2`?)

    return (
        <img
            ref={realImage}
            className={className || ""}
            src={placeholderName ? placeholder : image}
            data-src={image}
            alt={images.alt}
        />
    );
};
