import { hostedFieldsActionCreators } from "../reducers";
import { AppDispatch } from "../../index";

export function setHostedFieldValidity(field: string, valid: boolean, error: boolean) {
    return (dispatch: AppDispatch) => {
        if (valid) {
            dispatch(hostedFieldsActionCreators.setValid(field, true));
        } else if (error) {
            dispatch(hostedFieldsActionCreators.setValid(field, false));
        } else {
            dispatch(hostedFieldsActionCreators.setValid(field, undefined));
        }
    };
}
