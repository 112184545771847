import "./PayOnlySelectSplitOption.scss";

import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getPayOnlySplitPaymentOptions } from "../selectors";
import { SplitPaymentOption } from "src/features/order/reducers/types";
import { BulletListIcon, CoinStacksIcon, PieStatisticSliceIcon } from "src/sharedComponents/assets/icons";
import { ActionableRow } from "src/sharedComponents/layouts/actionableRow";
import { actionCreators } from "../reducers";
import { showSplitEvenly } from "../actions/showSplitEvenly";
import { payOnlyTrackActionCreators } from "../reducers/payOnlyTrack";

interface Props {
    onSuccess: () => void;
}

export const PayOnlySelectSplitOption = ({ onSuccess }: Props) => {
    const splitPaymentOptions = useSelector(getPayOnlySplitPaymentOptions);
    const dispatch = useDispatch();

    const trackSelection = useCallback(
        (selectedOption: SplitPaymentOption) =>
            dispatch(payOnlyTrackActionCreators.trackSelectedSplitOption(selectedOption, splitPaymentOptions)),
        [dispatch, splitPaymentOptions]
    );

    const selectAmount = useCallback(() => {
        dispatch(actionCreators.setSplitOption("SelectAmount"));
        trackSelection("SelectAmount");
        onSuccess();
    }, [dispatch, onSuccess, trackSelection]);

    const splitEvenly = useCallback(() => {
        dispatch(showSplitEvenly());
        trackSelection("SplitEvenly");
        onSuccess();
    }, [dispatch, onSuccess, trackSelection]);

    const selectItems = useCallback(() => {
        dispatch(actionCreators.setSplitOption("SelectItems"));
        trackSelection("SelectItems");
        onSuccess();
    }, [dispatch, onSuccess, trackSelection]);

    const renderRow = useCallback(
        (option: SplitPaymentOption) => {
            switch (option) {
                case "SelectAmount":
                    return (
                        <ActionableRow
                            key={"SelectAmount"}
                            onClick={selectAmount}
                            title="Select amount"
                            description="Select how much you want to pay"
                            icon={<CoinStacksIcon />}
                            showArrowIcon
                        />
                    );
                case "SplitEvenly":
                    return (
                        <ActionableRow
                            key={"SplitEvenly"}
                            onClick={splitEvenly}
                            title="Split evenly"
                            description="Set how many people are paying"
                            icon={<PieStatisticSliceIcon />}
                            showArrowIcon
                        />
                    );
                case "SelectItems":
                    return (
                        <ActionableRow
                            key={"SelectItems"}
                            onClick={selectItems}
                            title="Select items"
                            description="Select items you want to pay for"
                            icon={<BulletListIcon />}
                            showArrowIcon
                        />
                    );
            }
        },
        [selectAmount, splitEvenly, selectItems]
    );

    useEffect(() => {
        dispatch(actionCreators.reset());
    }, [dispatch]);

    return (
        <div className="pay-only-select-split-options">
            <div className={classNames("scroll-element", "animated-child")}>
                <div className="pay-only-select-split-options__title">
                    <Text preset="title-28" mode="bold">
                        How do you want to split?
                    </Text>
                </div>
                <div>{splitPaymentOptions.map(renderRow)}</div>
            </div>
        </div>
    );
};
