import "./ToastHostedCheckout.scss";
import React, { useEffect } from "react";
import { AddCardFormProps } from "src/features/paymentMethods/components/AddCardForm";
import classNames from "classnames";

export const ToastHostedCheckout = ({ show, onShow }: AddCardFormProps) => {
    useEffect(() => {
        if (show) {
            onShow?.();
        }
    }, [show, onShow]);

    return <div id="toast-hosted-checkout" className={classNames(show && "show")}></div>;
};
