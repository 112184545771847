import { initializePayPalButton } from "../common/initializePayPalButton";
import { beginBrowserPayment } from "../../payment/api/beginBrowserPayment";
import { BeginBrowserPaymentInfo, MessageTypeKeys } from "../../payment";
import { getEnvUrl } from "../../../common/shared";
import { config } from "../../../common/config";
import { InitializePayPalButtonFunc } from "../types";
import { authorizeTyroPayPalPaymentOperation } from "./operations";
import { webFlow } from "../../../common/experience";
import { normalizeError } from "../../../common/error";
import { fetchBrowserPaymentStatus } from "../../payment/api/fetchBrowserPaymentStatus";

export const RETURN_URL = "/browserpayment/complete";

export const initializeTyroPayPalButton: InitializePayPalButtonFunc = async (
    payPalCheckoutPaymentMethod,
    getTotal,
    _,
    inParty,
    signal,
    __,
    dispatch
) => {
    const onClick = async () => {
        const result = await authorizeTyroPayPalPaymentOperation.invoke(async () => {
            const { paymentGateway } = payPalCheckoutPaymentMethod;
            const returnUrl = getEnvUrl(config.REACT_APP_APP_BASE_URL) + RETURN_URL;
            const beginBrowserPaymentInfo: BeginBrowserPaymentInfo = {
                total: getTotal(),
                returnUrl,
            };

            webFlow.preStart("Redirecting you to PayPal...");
            let redirectUrl: string;
            let paymentId: string;

            try {
                ({ redirectUrl, paymentId } = await beginBrowserPayment(
                    paymentGateway,
                    beginBrowserPaymentInfo,
                    inParty
                ));
            } catch (err) {
                webFlow.cancelPreStart();
                throw err;
            }

            try {
                await webFlow.start(redirectUrl, returnUrl, MessageTypeKeys.BROWSER_PAYMENT_COMPLETE);
            } catch (err) {
                // If the user manually closed the window then they just cancelled
                if (normalizeError(err).message === "cancelled") {
                    return null;
                }
                throw err;
            }

            // Clicking Cancel on Tyro's PayPal page completes the flow but puts the transaction
            // in a CANCELLED state so we have to check the status to avoid submitting the order
            const status = await fetchBrowserPaymentStatus(paymentGateway, paymentId, inParty);
            if (status === "CANCELLED") {
                return null;
            }

            // We don't get anything back indicating success at this point but we'll use the
            // generated Payment ID to create the PartyPayment and confirm the transaction
            return {
                paymentToken: paymentId,
            };
        }, dispatch);

        if (result === undefined) {
            throw new Error("Failed to authorize Tyro PayPal payment");
        }

        return result;
    };

    return await initializePayPalButton(payPalCheckoutPaymentMethod, signal, {
        onClick,
    });
};
