import * as React from "react";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/ServiceChangeModal.scss";
import { getActiveService } from "../../menu/selectors";
import { getParty, MemberActivity } from "../../order";
import { createSelector } from "reselect";
import { Animate, Button, Text } from "../../../sharedComponents";
import { StatusBar } from "../../../common/statusBar";
import { replace } from "connected-react-router";
import { resetOrderingState } from "../../order/actions/resetOrderingState";
import { CSSTransition } from "react-transition-group";
import { AppState } from "src/features";
import { getGroupTabMenuChanging } from "src/features/groupTabs/selectors/activeGroupTab";
import { ModalContainer } from "src/common/modal";
import { getShowTakeawayServiceChanging } from "src/features/takeaway/selectors/takeawayOptions";
import {
    getCurrentMemberInAcceptedMembers,
    getCurrentMemberInParty,
    getIsSectionTakeaway,
} from "src/features/order/selectors";
import { getIsOpenTable, getOpenTableMemberActivity } from "src/features/openTable/selectors";
import { getOpenTableMemberHasUnconfirmedOrders } from "../../openTable/selectors/orders";

type State = { status: "closed" } | { status: "changing"; timeChanged: number } | { status: "changed" };

const getNextService = createSelector(getParty, (party) => party?.nextService);

/**
 * For OpenTable, we don't want to display the ServiceChangeModal when...
 * 1.   User is in the paying activity
 * 2.   User is submitting an order
 * 3.   User is on the waiting screen
 * 4.   User is on the lobby screen
 */
const getCanShowServiceChangeModal = createSelector(
    getIsOpenTable,
    getOpenTableMemberActivity,
    getOpenTableMemberHasUnconfirmedOrders,
    getCurrentMemberInAcceptedMembers,
    getCurrentMemberInParty,
    (isOpenTable, activity, hasUnconfirmedOrders, currentMemberHasAccepted, currentMemberInParty) => {
        if (!isOpenTable) return true;

        if (
            activity === MemberActivity.Paying ||
            hasUnconfirmedOrders ||
            currentMemberHasAccepted ||
            !currentMemberInParty
        ) {
            return false;
        }

        return true;
    }
);

export const getServiceChanging = createSelector(
    getIsOpenTable,
    getNextService,
    getShowTakeawayServiceChanging,
    (isOpenTable, nextService, takeawayServiceChanging) =>
        takeawayServiceChanging || (!!nextService?.changing && (!isOpenTable || !!nextService?.id))
    //For OpenTable, we don't want to display the ServiceChangeModal when there are no services to change to(Last active service ends).
    // We will get an update soon where party.nextService will be null and OpenTable will only be supporting paying.
);

export const getServiceChangingOrOverdue = createSelector(
    getNextService,
    getServiceChanging,
    (nextService, serviceChanging) => !!nextService?.overdue || serviceChanging
);

const getCategoriesChanging = (state: AppState) => state.party.categoriesChanging;

const getActiveServiceName = createSelector(getActiveService, (service) => service?.displayName);

const backgroundBlurClass = "background-blur";
const minChangingTime = 1500;

const reducer = (prevState: State, newState: "closed" | "changing" | "changed"): State => {
    switch (newState) {
        case "closed":
            document.body.classList.remove(backgroundBlurClass);
            return { status: "closed" };
        case "changing":
            return { status: "changing", timeChanged: performance.now() };
        case "changed":
            return { status: "changed" };
    }
    return prevState;
};

export const ServiceChangeModal = () => {
    const dispatch = useDispatch();
    const resetTimerRef = useRef<number | undefined>();
    const changeTimerRef = useRef<number | undefined>();

    const serviceChanging = useSelector(getServiceChanging);
    const categoriesChanging = useSelector(getCategoriesChanging);
    const groupTabMenuChanging = useSelector(getGroupTabMenuChanging);
    const isTakeaway = useSelector(getIsSectionTakeaway);
    const canShowChangingServiceModal = useSelector(getCanShowServiceChangeModal);
    const changing = canShowChangingServiceModal && (serviceChanging || categoriesChanging || groupTabMenuChanging);
    const serviceName = useSelector(getActiveServiceName);

    const [state, setState] = useReducer(reducer, { status: "closed" });
    const [showDescription, setShowDescription] = useState(!groupTabMenuChanging);

    useEffect(() => {
        if (changing && state.status !== "changing") {
            if (state.status === "closed") {
                resetTimerRef.current = window.setTimeout(() => {
                    document.body.classList.add(backgroundBlurClass);
                    dispatch(resetOrderingState());
                    dispatch(replace("/menu/service"));
                }, 250);
            }
            setState("changing");
        } else if (!changing && state.status === "changing") {
            const timeLeft = Math.max(minChangingTime - (performance.now() - state.timeChanged), 0);
            changeTimerRef.current = window.setTimeout(() => {
                setState("changed");
            }, timeLeft);
        }
    }, [changing, state, dispatch]);

    useEffect(() => {
        if (groupTabMenuChanging || state.status === "closed") {
            setShowDescription(!groupTabMenuChanging);
        }
    }, [groupTabMenuChanging, state]);

    useEffect(() => {
        return () => {
            clearTimeout(resetTimerRef.current);
            clearTimeout(changeTimerRef.current);
            document.body.classList.remove(backgroundBlurClass);
        };
    }, []);

    const getMenuChangedText = () => {
        if (serviceName) {
            if (isTakeaway) return `We’ve swapped to ${serviceName}`;
            if (serviceChanging) return `${serviceName} has started`;
        }
        return "We’ve swapped menus";
    };

    return (
        <ModalContainer
            isOpen={state.status !== "closed"}
            className={{
                base: "ServiceChange-modal slide-in",
                afterOpen: "ServiceChange-modal--after-open slide-in--after-open",
                beforeClose: "ServiceChange-modal--before-close slide-in--before-close",
            }}
            overlayClassName="ServiceChange-modal--overlay slide-down-modal--overlay"
            closeTimeoutMS={250}
            onRequestClose={() => setState("closed")}
            shouldCloseOnEsc={state.status === "changed"}
            shouldCloseOnOverlayClick={state.status === "changed"}
        >
            <StatusBar backgroundColor="#000" />
            <div className="service-change">
                <CSSTransition in={state.status === "changing"} classNames="changing" timeout={400} unmountOnExit>
                    <div className="service-change__status">
                        <div className="service-change__spinner">
                            <Animate name="twoDotSpinnerWhite" />
                        </div>
                        <Text className="service-change__title" preset="title-36" mode={["extra-bold", "block"]}>
                            One sec, we’re updating your menu
                        </Text>
                        {showDescription && (
                            <Text
                                preset="g-14"
                                mode={["medium", "block"]}
                                value="If you were still ordering, some products may no longer be available to order."
                            />
                        )}
                    </div>
                </CSSTransition>
                <CSSTransition in={state.status !== "changing"} classNames="changing" timeout={400} unmountOnExit>
                    <div className="service-change__status">
                        <Text className="service-change__title" preset="title-36" mode={["extra-bold", "block"]}>
                            {getMenuChangedText()}
                        </Text>
                        {showDescription && (
                            <Text
                                preset="g-14"
                                mode={["medium", "block"]}
                                value="If you were still ordering, some products may no longer be available to order."
                            />
                        )}
                    </div>
                </CSSTransition>
                <div className="service-change__footer">
                    <CSSTransition
                        in={state.status !== "changing"}
                        classNames="changing-button"
                        timeout={400}
                        unmountOnExit
                    >
                        <Button
                            className="service-change__button"
                            mode="solidinverted"
                            onClick={() => setState("closed")}
                            value="Got it"
                            countDownSeconds={10}
                            startCountDown={state.status === "changed"}
                        />
                    </CSSTransition>
                </div>
            </div>
        </ModalContainer>
    );
};
