import { DevicePaymentBehavior } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod } from "src/common/payment";
import { getToastApplePayAvailable, getToastGooglePayAvailable } from "./actions/getToastDevicePaymentAvailable";
import {
    initializeToastApplePayButton,
    initializeToastGooglePayButton,
} from "./actions/initializeToastDevicePaymentButton";

export const applePayBehavior: DevicePaymentBehavior<ApplePayPaymentMethod> = {
    getAvailable: getToastApplePayAvailable,
    initializeButton: initializeToastApplePayButton,
};

export const googlePayBehavior: DevicePaymentBehavior<GooglePayPaymentMethod> = {
    getAvailable: getToastGooglePayAvailable,
    initializeButton: initializeToastGooglePayButton,
};
