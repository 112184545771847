import { createSelector } from "reselect";
import { getPaymentState } from "src/features/payment/selectors/paymentState";
import { AppState } from "src/features/index";
import { ToastPaymentSessionData } from "src/features/paymentGateways/pos/toast/types";
import { PaymentState } from "src/features/payment";

export const getToastPaymentSessionData = createSelector<AppState, PaymentState, ToastPaymentSessionData>(
    getPaymentState,
    ({ paymentSessionData }) => paymentSessionData as ToastPaymentSessionData
);
