import "./PayOnlyNotifications.scss";

import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOpenTableFailedToSubmitOrders } from "src/features/openTable/selectors/orders";
import { getPayOnlyFailedToSubmitPayments } from "src/features/payOnly/selectors";
import { createSelector } from "reselect";
import { AlertCircleIcon } from "src/sharedComponents/assets/icons";
import { CollapsibleSection, Prompt, Text } from "src/sharedComponents";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";

export type NotificationType = "order" | "payment";

interface Notification {
    type: NotificationType;
    id: string;
    date: Date;
}

const getNotifications = createSelector(
    getOpenTableFailedToSubmitOrders,
    getPayOnlyFailedToSubmitPayments,
    (failedToSubmitOrders, failedToSubmitPayments) => {
        return failedToSubmitOrders
            .map(
                ({ id, dateSubmitted }): Notification => ({
                    type: "order",
                    id,
                    date: dateSubmitted ? new Date(dateSubmitted) : new Date(),
                })
            )
            .concat(
                failedToSubmitPayments.map(
                    ({ id, dateCreated }): Notification => ({
                        type: "payment",
                        id,
                        date: dateCreated ? new Date(dateCreated) : new Date(),
                    })
                )
            )
            .sort((a, b) => b.date.getTime() - a.date.getTime())
            .reduce((notifications, notification) => {
                if (!notifications.has(notification.type)) {
                    notifications.set(notification.type, notification);
                }
                return notifications;
            }, new Map<NotificationType, Notification>());
    }
);

export const PayOnlyNotifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(getNotifications);

    const notificationsRef = useRef<Notification[]>();
    if (notifications.size) {
        notificationsRef.current = Array.from(notifications.values());
    }

    const showDetails = useCallback(
        (type: NotificationType) => {
            dispatch(showModalMessage(modalMessages.unconfirmedOrderOrPayment(type)));
        },
        [dispatch]
    );

    return (
        <CollapsibleSection
            show={notifications.size}
            animateOnMount={false}
            collapsibleElement={
                <div className="pay-only-notifications">
                    {notificationsRef.current?.map(({ id, type }) => (
                        <Prompt
                            key={id}
                            leftIcon={<AlertCircleIcon />}
                            onClick={() => showDetails(type)}
                            subtitle={`The venue has not confirmed one or more ${type}s. Contact venue staff.`}
                            title={`Unconfirmed ${type}`}
                            className="pay-only-notification"
                        />
                    ))}
                </div>
            }
        />
    );
};

export const PayOnlyNotificationDetails = ({ type }: Pick<Notification, "type">) => (
    <div className="pay-only-notification-details">
        <Text preset="title-24" mode="extra-bold">
            Unconfirmed {type}
        </Text>
        <Text preset="g-14">
            We were unable to confirm if one or more of your {type}s were received by the venue. Contact venue staff to
            {type === "order" ? " ensure you receive all of your items" : " continue"}.
        </Text>
        <Text preset="g-14">Payments have been temporarily disabled until the issue is resolved.</Text>
    </div>
);
