import {
    browserName,
    getUA,
    isAndroid,
    isChrome,
    isEdge,
    isSafari,
    isIOS,
    isMobile,
    mobileModel,
    mobileVendor,
    osName,
    osVersion,
} from "react-device-detect";
import { detectIncognito } from "detectincognitojs";
import { config } from "src/common/config";
import { DeviceInfo, Platform } from "../shared";
import { registerToKeyboard } from "../shared/registerToKeyboard";

export const device: DeviceInfo = {
    available: true,
    name: browserName,
    isIOS,
    isAndroid,
    isEdge,
    isChrome,
    isSafari,
    isMobile,
    platform: osName as Platform,
    version: osVersion,
    isIOS15orHigher:
        config.enableIOS15Scroll === "1" &&
        !!isIOS &&
        !!isSafari &&
        !Number.isNaN(parseInt(osVersion)) &&
        parseInt(osVersion) >= 15,
    model: mobileModel,
    manufacturer: mobileVendor,
    userAgent: getUA,
    isVirtual: false,
    cordova: "NA",
    serial: "NA",
    uuid: "NA",
    supportsMultipleInstances: true,
    isLocalHost: Boolean(
        window.location.hostname === "localhost" ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === "[::1]" ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    ),
    subBrowser: getSubBrowser(),
    isPrivate:  false
};

export const isPrivate = new Promise<boolean>((res) => detectIncognito().then(
    (result) => res(device.isPrivate = result.isPrivate),
    () => res(false)
));

function getSubBrowser() {

    const navigator = window.navigator as any;

    if ("brave" in navigator && 
        typeof navigator.brave["isBrave"] === "function" &&
        typeof navigator.brave.isBrave()) {
            return "Brave";
    }

    if (!!getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-background')) {
        return "Arc";
    }

    return undefined;
}

export { registerToKeyboard };
