import { createSelector } from "reselect";
import { getPayOnlyPaymentOrder } from "./payOnlyOrder";
import { getHasPaymentOrder, getPayOnlyFlagsState } from "./payOnlyState";
import { getAnyParty, getParty } from "../../order/selectors";
import { PartyStatus } from "../../order";

export const getPayOnlyBill = createSelector(getPayOnlyPaymentOrder, (order) => order?.bill ?? null);

export const getPayOnlyRemainingBalance = createSelector(getPayOnlyBill, (bill) => bill?.remainingBalance ?? 0);

export const getPayOnlyPendingRemainingBalance = createSelector(
    getPayOnlyBill,
    (bill) => bill?.pendingRemainingBalance ?? 0
);

export const getPayOnlyFullBalance = createSelector(getPayOnlyBill, (bill) => bill?.total ?? 0);

export const getPayOnlyBillPaid = createSelector(getPayOnlyFlagsState, (state) => state.billPaid);

export const getPayOnlyPartyFullyPaidAndClosed = createSelector(
    getHasPaymentOrder,
    getAnyParty,
    getPayOnlyRemainingBalance,
    getPayOnlyPaymentOrder,
    (hasPaymentOrder, party, remainingBalance, order) =>
        hasPaymentOrder &&
        (party?.status === PartyStatus.CLOSED || party?.status === PartyStatus.PENDING_CLOSED) &&
        remainingBalance === 0 &&
        !!order?.items.length
);

export const getPayOnlyPartyShutdown = createSelector(
    getHasPaymentOrder,
    getParty,
    getPayOnlyPartyFullyPaidAndClosed,
    (hasPaymentOrder, party, partyFullyPaidAndClosed) => hasPaymentOrder && !party && !partyFullyPaidAndClosed
);
