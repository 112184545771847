import "./MembershipPayWithPoints.scss";

import React, { useContext } from "react";
import { Checkbox, Prompt, Text } from "../../../sharedComponents";
import { MembershipIcon } from "../../../sharedComponents/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedMembershipState } from "../selectors/getConnectedMembershipState";
import { fetchActions } from "../reducers";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { getPotentialMembershipPointsSpendTotal } from "../../payment/selectors";

export const MembershipPayWithPoints = () => {
    const dispatch = useDispatch();

    const menuDataLocale = useContext(MenuDataLocaleContext)!;

    const {
        programId,
        levelPercentDiscount,
        pointsBalance,
        pointsSpendBalance,
        pointsSpendInFullBalance,
        usePointsSpendBalance,
    } = useSelector(getConnectedMembershipState) || {};

    const potentialMembershipPointsSpendTotal = useSelector(getPotentialMembershipPointsSpendTotal);

    if (!programId || pointsSpendBalance === undefined) return null;

    const subtitle = (() => {
        if (!potentialMembershipPointsSpendTotal) {
            return "Not available for this order";
        }
        if (!pointsBalance) {
            return "Redeem your points towards this order";
        }
        return `Current balance: ${menuDataLocale.formatNumber(pointsBalance)} points`;
    })();

    return (
        <Prompt
            className="pay-with-points"
            title="Pay with points"
            subtitle={subtitle}
            titleRightIcon={<MembershipIcon />}
            type="themed"
            rightIcon={
                potentialMembershipPointsSpendTotal ? (
                    <Checkbox
                        id="pay-with-points"
                        onChange={(checked) => dispatch(fetchActions.setUsePointsSpendBalance(programId, checked))}
                        defaultChecked={!!usePointsSpendBalance}
                    />
                ) : undefined
            }
        >
            {!!pointsSpendInFullBalance && !!levelPercentDiscount && (
                <Text mode="block" preset="g-12">
                    Save {menuDataLocale.formatPercent(levelPercentDiscount)} of your points when paid in full using
                    this payment method.
                </Text>
            )}
        </Prompt>
    );
};
