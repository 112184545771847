import { MenuItem } from "../../menudata";
import { PriceResolver } from "../../membership/selectors/getPriceResolver";

export function getMenuItemPrices(menuItem: MenuItem, priceResolver: PriceResolver): number[] {
    const { variants } = menuItem;

    if (variants && variants.length) {
        return variants.map((variant) => priceResolver(variant.availablePrices));
    }

    return [priceResolver(menuItem.availablePrices)];
}
