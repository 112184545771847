import "./VenueCause.scss";

import React, { useCallback, useEffect, useRef } from "react";
import { Chip, Prompt } from "src/sharedComponents";
import { HeartIcon } from "src/sharedComponents/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getIsConnected } from "../order/selectors";
import { getMenuDataLocale } from "../menudata/selectors/getMenuDataLocale";
import { VenueCauseOptionWithIndex } from "./types";
import { getVenueCause } from "./selectors/venueCause";
import { removeVenueCause } from "./actions/removeVenueCause";
import { addVenueCause } from "./actions/addVenueCause";
import { getMemberVenueCause } from "../payment/selectors";
import { trackedEvent } from "src/common/events/reduxEventTracking";

export const VenueCause = () => {
    const venueCause = useSelector(getVenueCause);
    const isConnected = useSelector(getIsConnected);
    const menuDataLocale = useSelector(getMenuDataLocale);
    const billVenueCauseAmount = useSelector(getMemberVenueCause)?.value;
    const dispatch = useDispatch();

    const animationRef = useRef<Animation | null>(null);
    const iconRef = useRef<HTMLSpanElement | null>(null);

    const startAnimating = useCallback(() => {
        if (iconRef.current?.animate) {
            animationRef.current = iconRef.current.animate(
                [
                    { transform: "scale(1)" },
                    { transform: "scale(0.75)" },
                    { transform: "scale(1.25)" },
                    { transform: "scale(0.75)" },
                    { transform: "scale(1)" },
                ],
                { duration: 750 }
            );

            animationRef.current.oncancel = () => {
                animationRef.current = null;
            };
            animationRef.current.onfinish = () => {
                animationRef.current = null;
            };
        }
    }, []);

    const onChipClick = useCallback(
        (option: VenueCauseOptionWithIndex, positionIndex: number) => {
            if (billVenueCauseAmount === option.amount) {
                dispatch(removeVenueCause());
            } else {
                startAnimating();
                dispatch(addVenueCause(option.optionIndex));
                dispatch(
                    trackedEvent({ type: "PAYMENT/ADD_VENUE_CAUSE", amount: option.amount, position: positionIndex })
                );
            }
        },
        [startAnimating, billVenueCauseAmount, dispatch]
    );

    useEffect(
        () => () => {
            if (animationRef.current) {
                animationRef.current.cancel();
            }
        },
        []
    );

    if (!venueCause) return null;

    return (
        <Prompt
            type="light"
            leftIcon={
                <span ref={iconRef}>
                    <HeartIcon />
                </span>
            }
            title={venueCause.title}
            subtitle={venueCause.description}
            className="venue-cause"
        >
            <div className="venue-cause__options">
                {venueCause.options.map((option, index) => (
                    <Chip
                        unthemed={true}
                        disabled={!isConnected}
                        text={"+" + menuDataLocale?.formatCurrencyNoDigits(option.amount) || `${option.amount}`}
                        mode="grey"
                        onClick={() => onChipClick(option, index)}
                        key={`venue-cause-${option.amount}`}
                        active={option.amount === billVenueCauseAmount}
                        size="small"
                    />
                ))}
            </div>
        </Prompt>
    );
};
