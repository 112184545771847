import * as React from "react";
import "../assets/DuplicatedPage.scss";
import { TapOnMenuHeader, Text } from "../../../sharedComponents/controls";

export const DuplicatedPage = () => (
    <div className="duplicated-page">
        <TapOnMenuHeader />
        <div className="duplicated-page__content">
            <Text className="duplicated-page__title" preset="title-36" mode="extra-bold" value="Close this tab." />
            <Text
                preset="g-14"
                mode="medium"
                value="Looks like you had another me&u tab open, so we’ve put this one to sleep. You can close this tab at any time."
            />
        </div>
    </div>
);
