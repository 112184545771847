import "./PayOnlySetFirstName.scss";

import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { FormControl, Input, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "src/features/accountmenu/selectors";
import { saveProfile } from "src/features/accountmenu/actions/saveProfile";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { FieldValues, useForm } from "react-hook-form";
import { validationRules } from "src/common/validation";
import { trimInput } from "src/common/shared";
import { getTableLabel } from "src/features/order/selectors";

interface Props {
    onSuccess: () => void;
}

export const PayOnlySetFirstName = ({ onSuccess }: Props) => {
    const profile = useSelector(getProfile);
    const tableLabel = useSelector(getTableLabel);
    const profileRef = useRef(profile);
    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            firstName: profile?.firstName,
        },
    });

    const onContinue = useCallback(
        ({ firstName }: FieldValues) => {
            dispatch(
                saveProfile({
                    ...profileRef.current,
                    firstName,
                })
            );
            onSuccess();
        },
        [dispatch, onSuccess]
    );

    return (
        <form onSubmit={(event) => event.preventDefault()} className="pay-only-set-first-name">
            <div className={classNames("scroll-element", "animated-child")}>
                <div className="pay-only-set-first-name__title">
                    <Text preset="title-28" mode="bold">
                        Let everyone know who’s paying
                    </Text>
                </div>
                <FormControl
                    id="pay-only_firstName"
                    invalid={!!errors.firstName}
                    invalidMessage={errors.firstName?.message}
                    message={`So others ordering from your ${tableLabel} know who’s paid`}
                >
                    <Input
                        ref={register({ ...validationRules.name, ...validationRules.firstName })}
                        name="firstName"
                        label="First name"
                        placeholder="First name"
                        onClear={() => {
                            setValue("firstName", undefined, {
                                shouldValidate: true,
                                shouldDirty: !!profile?.firstName,
                            });
                        }}
                        onBlur={(e) => e?.target && trimInput(e.target)}
                    />
                </FormControl>
            </div>
            <footer>
                <div className="pay-only-set-first-name__footer">
                    <NetworkConnectedButton value="Continue" onClick={handleSubmit(onContinue)} />
                </div>
            </footer>
        </form>
    );
};
