import { orderApi } from "../../order/orderApi";
import { addVenueCauseOperation } from "../operations";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

const addVenueCauseApi = async (optionIndex: number) => {
    const headers = getTableTokenHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/party/add-venue-cause", {
        method: "POST",
        body: JSON.stringify({ optionIndex }),
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return response.status;
};

export function addVenueCause(optionIndex: number) {
    return addVenueCauseOperation.getThunk(async () => {
        await addVenueCauseApi(optionIndex);
    });
}
