import { Region } from "../types";

export interface State {
    region: Region | null;
}

export enum TypeKeys {
    CHANGED = "REGION/CHANGED",
}

export const actionCreators = {
    changed: (region: Region) => ({ type: TypeKeys.CHANGED, region }),
};

export type RegionChangedAction = { type: TypeKeys.CHANGED; region: Region };

export default function reducer(state: Region | null = null, action: RegionChangedAction): Region | null {
    if (action.type === TypeKeys.CHANGED) {
        return action.region;
    }
    return state;
}
