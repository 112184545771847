import { submitOpenTableOrderOperation } from "../operations";
import { orderApi } from "../../order/orderApi";
import { AppDispatch, AppState } from "../..";
import { getOrderDateToSubmitString } from "../../order/selectors";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";

export const submitOpenTableOrder = () => {
    return submitOpenTableOrderOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const errorMessage = "cannot submitOpenTableOrder because dateToSubmit was null";

        try {
            const dateToSubmit = getOrderDateToSubmitString(getState());

            if (!dateToSubmit) {
                throw new Error(errorMessage);
            }

            await orderApi.invokeWithPartyUpdateAsNeeded(dispatch, getState, "submitOpenTableOrder", dateToSubmit);
        } catch (e) {
            if (!(e instanceof Error) || e.message !== errorMessage) {
                // TODO: Use more specific message on error
                dispatch(showModalMessage(modalMessages.failedToSubmit()));
            }
            throw e; // For app insights
        }
    });
};
