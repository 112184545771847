import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { getTabTypeName } from "../selectors/activeGroupTab";
import { getTabTipFactor } from "../../payment/selectors";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { Text } from "src/sharedComponents";

interface Props {
    className?: string;
}

export const TabDefaultTipDisclaimer = ({ className }: Props) => {
    const tabTypeName = useSelector(getTabTypeName);
    const tipFactor = useSelector(getTabTipFactor);
    const menuDataLocale = useContext(MenuDataLocaleContext);

    if (!tipFactor) return null;

    return (
        <Text preset="g-14" mode="block" className={className}>
            An automatic tip of {menuDataLocale.formatPercent(tipFactor)} will be added if you forget to close out your{" "}
            {tabTypeName}.
        </Text>
    );
};
