import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getPaymentSessionTokenOperation } from "../operations";
import { Dispatch } from "redux";
import { getAuthHeaders } from "../../../common/auth";
import { PaymentGateway } from "../../../common/payment";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";

export function fetchPaymentSessionToken(dispatch: Dispatch, paymentGateway: PaymentGateway, inParty: boolean) {
    return getPaymentSessionTokenOperation.invoke(async () => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

        const response = await orderApi.send(
            `/paymentgateway/${paymentGateway}/paymentsessiontoken`,
            {
                method: "POST",
                headers,
            },
            reattemptStrategies.limited
        );

        await ProblemDetailsError.throwError(response);

        return await response.text();
    }, dispatch);
}
