import { actionCreators } from "../reducers/foodPreferences";
import { fetchFoodPreferencesOperation } from "../operations";
import { AppDispatch } from "../..";
import { fetchFoodPreferencesApi } from "../api/accountApi";

export function fetchFoodPreferences() {
    return fetchFoodPreferencesOperation.getThunk(async (dispatch: AppDispatch) => {
        try {
            const storedPreferences = await fetchFoodPreferencesApi();
            dispatch(actionCreators.setFoodPreferences(storedPreferences));
        } catch (ex) {
            console.log("failed to fetch food preferences:", ex);
            throw ex;
        }
    });
}
