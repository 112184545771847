import "./NewGroupTabWizard.scss";

import React, { useCallback, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { SimpleNavHeader } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import classNames from "classnames";
import { usePrevious } from "src/sharedComponents/common/shared";
import { useDispatch, useSelector } from "react-redux";
import { GroupTabName } from "./GroupTabName";
import { NewGroupTabPage } from "../types";
import { actionCreators } from "../reducers/groupTabWizard";
import {
    getShowGroupTabsWizard,
    getWizardGroupTabsPackageId,
    getWizardGroupTabsPage,
    getWizardGroupTabsPages,
} from "../selectors/groupTabWizardSelectors";
import { signInAction } from "src/features/signup/actions/signInAction";
import { GroupTabLimit } from "./GroupTabLimit";
import { getIsAnonymous, getIsUnverified, getRequireSignIn } from "src/features/accounts/selectors";
import { GroupTabLimitedMenuPackages } from "./GroupTabLimitedMenuPackages";
import { GroupTabLimitedMenuPackagePreview } from "./GroupTabLimitedMenuPackagePreview";
import { getGroupTabPackageId, getTabType } from "../selectors/activeGroupTab";
import { ModalContainer } from "src/common/modal";
import { getTabTypeReadableName } from "../selectors/helpers";
import { useWizardPaymentMethods } from "src/features/payment/hooks/useWizardPaymentMethods";
import { createGroupTabOperation, increaseGroupTabLimitOperation } from "src/features/groupTabs/operations";

const pageRequiresSsoSignIn = (page: NewGroupTabPage) =>
    page === "PaymentMethods" || page === "SetLimit" || page === "IncreaseLimit";

export const NewGroupTabWizard = () => {
    const showWizard = useSelector(getShowGroupTabsWizard);
    const pages = useSelector(getWizardGroupTabsPages);
    const isAnonymous = useSelector(getIsAnonymous);
    const isUnverified = useSelector(getIsUnverified);
    const requireSignIn = useSelector(getRequireSignIn);
    const selectedPackageId = useSelector(getWizardGroupTabsPackageId);
    const activeGroupTabPackageId = useSelector(getGroupTabPackageId);
    const tabType = useSelector(getTabType);
    const [show, setShow] = useState(showWizard);
    const [showPaymentMethods, setShowPaymentMethods] = useState<boolean | undefined>();
    const [currentStep, setCurrentStep] = useState(1);
    const [page, setPage] = useState<NewGroupTabPage | undefined>();
    const [transitionClass, setTransitionClass] = useState("group-tab-wizard-transition--push");
    const prevStep = usePrevious(currentStep);
    const dispatch = useDispatch();

    const afterClose = useCallback(() => {
        // close the wizard but leave all the fields as is
        dispatch(actionCreators.startGroupTabWizard(undefined));
        setTransitionClass("group-tab-wizard-transition--push");
        setCurrentStep(1);
    }, [dispatch]);

    const close = useCallback(() => {
        setShow(false);
    }, []);

    const prev = useCallback(() => {
        setCurrentStep((prevStep) => {
            if (prevStep === 1) {
                close();
                return prevStep;
            } else {
                return prevStep - 1;
            }
        });
    }, [close]);

    const shouldShowSsoSignin = requireSignIn || isUnverified;

    const next = useCallback(() => {
        setCurrentStep((prevStep) => {
            if (pages && prevStep < pages.length) {
                if (pageRequiresSsoSignIn(pages[prevStep]) && shouldShowSsoSignin) {
                    dispatch(
                        signInAction({
                            useLocationTheme: true,
                            requireVerification: true,
                            isMidWizard: true,
                            slideAnimation: "slide-from-right",
                            onComplete: () => setCurrentStep(prevStep + 1),
                            getSignInComplete: (state) => {
                                const page = getWizardGroupTabsPage(state);
                                return !!page && pageRequiresSsoSignIn(page);
                            },
                        })
                    );
                    return prevStep;
                }
                return prevStep + 1;
            }
            close();
            return prevStep;
        });
    }, [dispatch, pages, close, shouldShowSsoSignin]);

    useEffect(() => {
        setShow(showWizard);
    }, [showWizard]);

    useEffect(() => {
        if (pages && pages.length >= currentStep) {
            const page = pages[currentStep - 1];
            setPage(page);
            dispatch(actionCreators.setGroupTabWizardPage(page));
        }
        if (prevStep !== currentStep) {
            setTransitionClass(
                prevStep < currentStep ? "group-tab-wizard-transition--push" : "group-tab-wizard-transition--pop"
            );
        }
    }, [dispatch, currentStep, prevStep, pages]);

    useEffect(() => {
        if (showPaymentMethods) {
            setPage("PaymentMethods");
            dispatch(actionCreators.setGroupTabWizardPage("PaymentMethods"));
            setTransitionClass("group-tab-wizard-transition-edit--push");
        } else if (showPaymentMethods === false) {
            const page = pages![currentStep - 1];
            setPage(page);
            dispatch(actionCreators.setGroupTabWizardPage(page));
            setTransitionClass("group-tab-wizard-transition-edit--pop");
            setShowPaymentMethods(undefined);
        }
    }, [dispatch, showPaymentMethods, pages, currentStep]);

    useEffect(() => {
        return () => {
            afterClose();
        };
    }, [afterClose]);

    const {
        component: PaymentMethods,
        unmountBehavior: { unmountOnExit, exitHandler, endHandler, style },
    } = useWizardPaymentMethods<NewGroupTabPage>(
        "PaymentMethods",
        page,
        "group-tab-wizard",
        pages?.includes("IncreaseLimit") ? increaseGroupTabLimitOperation : createGroupTabOperation
    );

    const limitedMenuPackagePreviewPage =
        page === "LimitedMenuPackagePreview"
            ? pages?.length === 1
                ? `group-tab-LimitedMenuPackagePreview${activeGroupTabPackageId ? `/${activeGroupTabPackageId}` : ""}`
                : `new-group-tab-wizard-LimitedMenuPackagePreview${selectedPackageId ? `/${selectedPackageId}` : ""}`
            : undefined;

    const tabTypeReadableName = getTabTypeReadableName(tabType);

    const limitPage =
        page === "SetLimit" || page === "IncreaseLimit"
            ? `new-group-tab-wizard-${page === "SetLimit" ? "setLimit" : "changeLimit"}-${tabTypeReadableName}`
            : undefined;

    return (
        <ModalContainer
            isOpen={show && !!pages?.length}
            className={{
                base: "GroupTabsWizard-modal slide-in",
                afterOpen: "GroupTabsWizard-modal--after-open slide-in--after-open",
                beforeClose: "GroupTabsWizard-modal--before-close slide-in--before-close",
            }}
            bodyOpenClassName="GroupTabsWizard-modal__Body--open"
            overlayClassName={classNames(
                "GroupTabsWizard-modal--overlay",
                "slide-in-modal--overlay",
                page !== "SetLimit" && page !== "IncreaseLimit" && "keyboard-unfixed"
            )}
            onRequestClose={close}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            onAfterClose={afterClose}
        >
            <LocationThemeContainer>
                <StatusBar backgroundColor="#fff" />
                <CSSTransition in={page === "TabName"} classNames={transitionClass} timeout={250} unmountOnExit appear>
                    <div className="group-tab-wizard">
                        <SimpleNavHeader
                            key={"new-group-tab-wizard-name"}
                            closeToBack={true}
                            customBack={"new-group-tab-wizard-name"}
                            onBack={prev}
                        />
                        <GroupTabName onSuccess={next} />
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={page === "LimitedMenuPackages"}
                    classNames={transitionClass}
                    timeout={250}
                    unmountOnExit
                    appear
                >
                    <div className="group-tab-wizard">
                        <SimpleNavHeader
                            key={"new-group-tab-wizard-LimitedMenuPackages"}
                            customBack={"new-group-tab-wizard-LimitedMenuPackages"}
                            onBack={prev}
                        />
                        <GroupTabLimitedMenuPackages onSuccess={next} />
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={page === "LimitedMenuPackagePreview"}
                    classNames={transitionClass}
                    timeout={250}
                    unmountOnExit
                    appear
                >
                    <div className="group-tab-wizard">
                        <SimpleNavHeader
                            key={limitedMenuPackagePreviewPage}
                            customBack={limitedMenuPackagePreviewPage}
                            closeToBack={pages?.length === 1}
                            onBack={prev}
                        />
                        <GroupTabLimitedMenuPackagePreview onSuccess={next} isPreviewModal={pages?.length === 1} />
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={page === "PaymentMethods" && !isAnonymous}
                    classNames={transitionClass}
                    timeout={250}
                    unmountOnExit={unmountOnExit}
                    onExited={exitHandler}
                    addEndListener={endHandler}
                    appear
                >
                    <div className="group-tab-wizard" style={style}>
                        <SimpleNavHeader
                            key={"group-tab-wizard-SelectPaymentMethod"}
                            customBack={"group-tab-wizard_SelectPaymentMethod"}
                            onBack={showPaymentMethods ? () => setShowPaymentMethods(false) : prev}
                            closeToBack={showPaymentMethods}
                        />
                        <PaymentMethods
                            onSuccess={showPaymentMethods ? () => setShowPaymentMethods(false) : next}
                            select
                        />
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={page === "SetLimit" || page === "IncreaseLimit"}
                    classNames={transitionClass}
                    timeout={250}
                    unmountOnExit
                    appear
                >
                    <div className="group-tab-wizard">
                        <SimpleNavHeader
                            closeToBack={pages?.length === 1}
                            key={limitPage}
                            customBack={limitPage}
                            onBack={prev}
                        />
                        <GroupTabLimit
                            increasingGroupTab={page === "IncreaseLimit"}
                            changePaymentMethod={() => setShowPaymentMethods(true)}
                        />
                    </div>
                </CSSTransition>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
