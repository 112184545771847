import { AppDispatch, AppMiddleware } from "../..";
import { PartyAction, TypeKeys as PartyTypeKeys } from "../../order/reducers/party";
import { OperationAction, TypeKeys as OperationTypeKeys } from "../../operations";
import {
    joiningFlexTabPartyOperation,
    joiningTableOperation,
    rejoiningPayOnlyPartyOperation,
    rejoiningTableOperation,
} from "../../partyOnboarding/operations";
import { getRestaurantFlagsOperation, validateActiveOrderOperation } from "../../order/operations";
import { initializeFacebookPixel } from "../actions/initializeFacebookPixel";
import {
    CookiePreferencesChangedAction,
    TypeKeys as CookiePreferencesTypeKeys,
} from "../../compliance/reducers/cookiePreferences";
import { initializeGoogleTagManager } from "../actions/initializeGoogleTagManager";
import { pushPurchaseEvent } from "../actions/pushPurchaseEvent";
import { pushBeginCheckoutEvent } from "../actions/pushBeginCheckoutEvent";

let joining = false;
let rejoining = false;

export const analyticsMiddleware =
    () =>
    (store: AppMiddleware) =>
    (next: AppDispatch) =>
    (action: PartyAction | OperationAction | CookiePreferencesChangedAction) => {
        next(action);

        if (action.type === OperationTypeKeys.BEGIN) {
            if (
                action.operation === joiningTableOperation.name ||
                action.operation === joiningFlexTabPartyOperation.name
            ) {
                joining = true;
            } else if (
                action.operation === rejoiningTableOperation.name ||
                action.operation === rejoiningPayOnlyPartyOperation.name
            ) {
                rejoining = true;
            }
        }

        if (
            action.type === OperationTypeKeys.COMPLETED ||
            action.type === OperationTypeKeys.FAILED ||
            action.type === OperationTypeKeys.RESET
        ) {
            if (
                action.operation === joiningTableOperation.name ||
                action.operation === joiningFlexTabPartyOperation.name
            ) {
                joining = false;
            } else if (
                action.operation === rejoiningTableOperation.name ||
                action.operation === rejoiningPayOnlyPartyOperation.name
            ) {
                rejoining = false;
            }
        }

        if (
            action.type === OperationTypeKeys.COMPLETED &&
            action.operation === getRestaurantFlagsOperation.name &&
            !rejoining
        ) {
            store.dispatch(initializeFacebookPixel());
        }

        if (action.type === CookiePreferencesTypeKeys.CHANGED) {
            store.dispatch(initializeGoogleTagManager());
        }

        if (action.type === PartyTypeKeys.SWITCHED && (joining || rejoining)) {
            store.dispatch(initializeGoogleTagManager());
        }

        if (action.type === OperationTypeKeys.COMPLETED && action.operation === validateActiveOrderOperation.name) {
            store.dispatch(pushBeginCheckoutEvent());
        }

        if (action.type === PartyTypeKeys.CLOSED) {
            store.dispatch(pushPurchaseEvent());
        }
    };
