import { AppDispatch } from "../../index";
import { DeleteTableReason, deleteTableToken } from "../persistence/tableToken";
import { orderApi } from "../../order/orderApi";
import { actionCreators as partyActions } from "../../order";

export const disconnectParty = async (next: AppDispatch, deleteToken: boolean, reason: DeleteTableReason) => {
    if (deleteToken) {
        deleteTableToken(reason);
    }
    await orderApi.setInitializer(null);
    await orderApi.disconnect();
    next(partyActions.disconnected());
};
