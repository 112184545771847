import { connect } from "react-redux";
import { match } from "react-router";
import { AppDispatch, AppState } from "../..";
import { OrderHistoryItemPage, Props } from "../components/OrderHistoryItemPage";
import {
    markMealHistoryAsRead,
    fetchPartyMealHistoryData,
    markMealHistoryAsCollected,
} from "../../orderHistoryData/actions/fetchMealHistory";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { forceSendingReceiptEmail } from "../../payment/actions/forceSendingReceiptEmail";
import { actions as marketingActions } from "src/features/marketing/actions";
import { actionCreators as taxInvoiceActions } from "src/features/orderHistoryData/reducers/taxInvoice";
import { actionCreators as historyActionCreators } from "../../orderHistoryData/reducers/mealHistory";
import { getPreviousVenueLocationsDetails } from "src/features/takeaway/selectors/takeawayOptions";
import { DeliveryOptionType } from "src/features/order/types";

export interface OwnProps extends OrderItemRouteParams {
    match: match<OrderItemRouteParams>;
}

export interface OrderItemRouteParams {
    partyId: string;
}

export function mapStateToProps(state: AppState, props: OwnProps): DataProps<Props> {
    const mealHistoryData = state.orderHistoryData && state.orderHistoryData.orderHistoryData;
    const partyId = props.match.params.partyId;
    const {
        marketing: {
            optInData: { locationId },
        },
    } = state;
    const historyDetail = mealHistoryData.filter((item) => item.partyId === partyId)[0];
    const mealHistoryOrder = historyDetail.mealHistoryOrders?.[0];
    const isTakeaway = mealHistoryOrder?.deliveryOption?.type === DeliveryOptionType.TAKEAWAY;
    const isScheduledOrder = mealHistoryOrder?.isScheduled;
    const scheduledDate = mealHistoryOrder?.dateScheduled;
    const allVenuesCollectionDetails = getPreviousVenueLocationsDetails(state);
    const venueLocationId = historyDetail.summary?.locationId;
    const venueCollectionDetails =
        allVenuesCollectionDetails && venueLocationId ? allVenuesCollectionDetails[venueLocationId] : null;

    return {
        partyId,
        historyDetail,
        marketingIsOpen: !!locationId,
        selectedOrderHistoryData: state.orderHistoryData.selectedOrderHistoryData,
        isTakeaway,
        isScheduledOrder,
        scheduledDate,
        venueCollectionDetails,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        sendReceiptEmail: (partyId: string) => dispatch(forceSendingReceiptEmail(partyId)),
        markAsRead: (partyId: string) => {
            dispatch(markMealHistoryAsRead(partyId));
        },
        markAsCollected: (partyId: string) => {
            dispatch(markMealHistoryAsCollected(partyId));
        },
        getMarketingMetaData: (locationId: string) => dispatch(marketingActions.marketingMetaData(locationId)),
        getPartyMealHistoryData: (partyId: string) => dispatch(fetchPartyMealHistoryData(partyId)),
        trackInvoiceRequested: () => dispatch(taxInvoiceActions.trackInvoiceRequested()),
        selectPartyMealHistory: (partyId: string) =>
            dispatch(historyActionCreators.selectPartyMealHistory(partyId, false)),
    };
}

export const OrderHistoryItemPageContainer = connect(mapStateToProps, mapDispatchToProps)(OrderHistoryItemPage);
