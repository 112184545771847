export function getMinutePrecisionDate(input: Date) {
    input.setSeconds(0);
    input.setMilliseconds(0);

    return input;
}

export const HOURS_MINUTES_FORMAT = "h:mm a";

export const DATE_TIME_FORMAT = "ddd, MMM D YYYY h:mm A";
