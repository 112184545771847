import { AppDispatch, AppState } from "src/features";
import { getLocationName } from "src/features/menu/selectors";
import { actionCreators } from "../reducers";
import { getAvailableDiscountOffers, getDiscoverableStampCard } from "../selectors";

const KEY = "MEANDU_OFFER_TOOLTIP";

export function getOfferTooltipState() {
    return JSON.parse(window.localStorage.getItem(KEY)!);
}

export function getShouldShowOfferTooltip(state: AppState) {
    const venueName = getLocationName(state);
    const availableDiscountOffers = getAvailableDiscountOffers(state);
    const discoverableStampCard = getDiscoverableStampCard(state);
    if (!venueName) {
        return false;
    }
    if (!availableDiscountOffers?.length && !discoverableStampCard) {
        return false;
    }
    const tooltipState = getOfferTooltipState();
    if (!tooltipState) {
        return true;
    }
    const venueState = tooltipState[venueName];
    if (!venueState) {
        return true;
    }
    const today = new Date();
    const lastVenueDate = new Date(venueState.date);
    return lastVenueDate.getDate() !== today.getDate();
}

export function saveOfferTooltipViewedAction() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const venueName = getLocationName(getState());
        const discoverableStampCard = getDiscoverableStampCard(getState());
        dispatch(actionCreators.setShowTooltip(false));
        if (!venueName) {
            return;
        }

        const today = new Date();
        const tooltipState = getOfferTooltipState() ?? {};
        let venueTooltipState = tooltipState[venueName] ?? {};
        if (typeof venueTooltipState === "string") {
            venueTooltipState = {
                date: venueTooltipState,
            };
        }
        venueTooltipState.date = today;
        if (discoverableStampCard) {
            if (venueTooltipState.campaignId === discoverableStampCard.id) {
                if (venueTooltipState.count < 4) {
                    venueTooltipState.count += 1;
                }
            } else {
                venueTooltipState.campaignId = discoverableStampCard.id;
                venueTooltipState.count = 1;
            }
        }
        tooltipState[venueName] = venueTooltipState;
        window.localStorage.setItem(KEY, JSON.stringify(tooltipState));
    };
}
