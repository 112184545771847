import { createGroupTabOperation, increaseGroupTabLimitOperation, startGroupTabWizardOperation } from "../operations";
import { AppDispatch, AppState } from "../../index";
import { getPaymentMethodsAsNeeded } from "../../payment/actions/getPaymentMethods";
import { LimitedMenuType, NewGroupTabPage } from "../types";
import { auth } from "../../../common/auth";
import { actionCreators as wizardActionCreators } from "../reducers/groupTabWizard";
import { getWizardGroupTabsMenuType } from "../selectors/groupTabWizardSelectors";

type StartGroupTabWizardOptions = {
    allStates?: boolean;
    increasingGroupTab?: boolean;
    previewPackage?: boolean;
};

export function startGroupTabWizardAction(options: StartGroupTabWizardOptions = {}) {
    return startGroupTabWizardOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const pages = await getGroupTabWizardPages(dispatch, getState, options);

        dispatch(createGroupTabOperation.actionCreators.reset());
        dispatch(increaseGroupTabLimitOperation.actionCreators.reset());
        dispatch(wizardActionCreators.startGroupTabWizard(pages));
    });
}

async function getGroupTabWizardPages(
    dispatch: AppDispatch,
    getState: () => AppState,
    { allStates, increasingGroupTab, previewPackage }: StartGroupTabWizardOptions
): Promise<NewGroupTabPage[]> {
    if (previewPackage) {
        return ["LimitedMenuPackagePreview"];
    }
    const pages: NewGroupTabPage[] = [];
    if (!increasingGroupTab) {
        pages.push("TabName");

        const menuType = getWizardGroupTabsMenuType(getState());
        if (menuType === LimitedMenuType.LIMITED) {
            pages.push("LimitedMenuPackages", "LimitedMenuPackagePreview");
        }
    }

    const selectedPaymentMethod = await getPaymentMethodsAsNeeded(dispatch, getState, true);
    if (allStates || auth.getIsAnonymous() || !selectedPaymentMethod) {
        pages.push("PaymentMethods");
    }

    pages.push(!increasingGroupTab ? "SetLimit" : "IncreaseLimit");
    return pages;
}
