import { createOperation } from "../../operations";
import { AddedCardResult, GetAddedCardFunc } from "../../payment";

export const showBraintreeAddCardOperation = createOperation<GetAddedCardFunc>("PAYMENT/SHOW_BRAINTREE_ADD_CARD", {
    showSpinner: true,
});
export const getBraintreeAddedCardOperation = createOperation<AddedCardResult | null>(
    "PAYMENT/GET_BRAINTREE_ADDED_CARD"
);
export const getBraintreeAddedCardForPaymentOperation = createOperation<AddedCardResult | null>(
    "PAYMENT/GET_BRAINTREE_ADDED_CARD_FOR_PAYMENT",
    { showSpinner: true }
);
export const verifyBraintreeCardOperation = createOperation<void>("PAYMENT/VERIFY_BRAINTREE_CARD");
