import { BaseTelemetryPlugin, IProcessTelemetryContext, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { AppState } from "../..";
import { DeliveryOptionType, getParty, PartyStatus, PartyType } from "../../order";
import { IReduxActionPlugin } from "./IReduxActionPlugin";
import { AnyAction } from "redux";
import { getPreviousParty } from "src/features/order/selectors";

export class PartyInfoPlugin extends BaseTelemetryPlugin implements IReduxActionPlugin {
    public identifier = "MeanduPartyInfoPlugin";

    private getState: (() => AppState) | null = null;

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        if (this.getState) {
            const state = this.getState();
            const party = getParty(state);
            const prevParty = getPreviousParty(state);
            const actualParty = party || prevParty;

            if (actualParty) {
                const partyUpdatedTimestamp = state.party.updatedTimestamp;

                event.data = {
                    ...event.data,
                    ...{
                        isPrevPartyDetails: !party,
                        "party.id": actualParty.id,
                        "party.status": actualParty.status ? PartyStatus[actualParty.status] : null,
                        "party.tableNumber": actualParty.tableNumber,
                        "party.serviceId": actualParty.serviceId,
                        "party.sequenceId": String(actualParty.sequenceId),
                        "party.updatedTimestamp": String(partyUpdatedTimestamp),
                        "party.type": typeof actualParty.type === "number" ? PartyType[actualParty.type] : null,
                        "location.id": actualParty.restaurantId,
                        "location.menuDataUrl": actualParty.menuDataUrl,
                        "location.menuDataUpdateUrl": actualParty.menuDataUpdateUrl,
                        "location.isPickup": !!actualParty.deliveryOptions?.some(
                            (opt) => opt.type === DeliveryOptionType.PICKUP
                        ),
                        "location.menuDataAdUnitUrl": actualParty.menuDataAdUnitUrl,
                    },
                };
            }
        }

        this.processNext(event, itemCtx!);
    }

    handleReduxAction(action: AnyAction, getState: () => AppState) {
        this.getState = getState;
    }
}
