import { AppRate } from "./appRate";
import { DeviceInfo } from "../../shared";
import { AuthorizePaymentResult } from "../../../payment";
import { Identity } from "../../../sso";

export interface AvailableResult {
    available: boolean;
}

export interface BraintreeDevicePayments {
    getApplePayAvailable: (success: (result: AvailableResult) => void, failure: (error: any) => void) => void;
    getGooglePayAvailable: (
        environment: google.payments.api.Environment,
        success: (result: AvailableResult) => void,
        failure: (error: any) => void
    ) => void;
    authorizeApplePayPayment: (
        total: string,
        label: string,
        token: string,
        success: (result: AuthorizePaymentResult | null) => void,
        failure: (error: any) => void
    ) => void;
    authorizeGooglePayPayment: (
        currency: string,
        googlePayMerchantId: string | null,
        total: string,
        token: string,
        success: (result: AuthorizePaymentResult | null) => void,
        failure: (error: any) => void
    ) => void;
}

export interface StripeDevicePayments {
    getApplePayAvailable: (
        apiKey: string,
        success: (result: AvailableResult) => void,
        failure: (error: any) => void
    ) => void;
    getGooglePayAvailable: (
        environment: google.payments.api.Environment,
        apiKey: string,
        success: (result: AvailableResult) => void,
        failure: (error: any) => void
    ) => void;
    authorizeApplePayPayment: (
        countryCode: string,
        currency: string,
        applePayMerchantId: string,
        total: string,
        label: string,
        success: (result: AuthorizePaymentResult | null) => void,
        failure: (error: any) => void
    ) => void;
    completeApplePayPayment: (clientSecret: string | null, success: () => void, failure: (error: any) => void) => void;
    authorizeGooglePayPayment: (
        countryCode: string,
        currency: string,
        googlePayMerchantName: string,
        total: string,
        label: string,
        success: (result: AuthorizePaymentResult | null) => void,
        failure: (error: any) => void
    ) => void;
}

export interface SMSRetriever {
    startWatch: (success: (msg: string) => void, failure: (err: any) => void) => void;
}

export interface CordovaNavigator extends Navigator {
    app: {
        loadUrl: (url: string, props?: any) => void;
        clearCache: () => void;
        cancelLoadUrl: () => void;
        clearHistory: () => void;
        backHistory: () => void;
        overrideBackbutton: () => void;
        overrideButton: () => void;
        exitApp: () => void;
    };
    notification: {
        alert(message: string, alertCallback: Function, title?: string, buttonName?: string): void;
        confirm(message: string, confirmCallback: Function, title?: string, buttonLabels?: string | string[]): void;
        prompt(
            message: string,
            confirmCallback: Function,
            title?: string,
            buttonLabels?: string | string[],
            defaultText?: string
        ): void;
        beep(times: number): void;
        vibrate(milliseconds: number): void;
    };
}

interface CordovaFile {
    /* Read-only directory where the application is installed. */
    applicationDirectory: string;
    /* Root of app's private writable storage */
    applicationStorageDirectory: string;
    /* Where to put app-specific data files. */
    dataDirectory: string;
    /* Cached files that should survive app restarts. Apps should not rely on the OS to delete files in here. */
    cacheDirectory: string;
    /* Android: the application space on external storage. */
    externalApplicationStorageDirectory: string;
    /* Android: Where to put app-specific data files on external storage. */
    externalDataDirectory: string;
    /* Android: the application cache on external storage. */
    externalCacheDirectory: string;
    /* Android: the external storage (SD card) root. */
    externalRootDirectory: string;
    /* iOS: Temp directory that the OS can clear at will. */
    tempDirectory: string;
    /* iOS: Holds app-specific files that should be synced (e.g. to iCloud). */
    syncedDataDirectory: string;
    /* iOS: Files private to the app, but that are meaningful to other applications (e.g. Office files) */
    documentsDirectory: string;
    /* BlackBerry10: Files globally available to all apps */
    sharedDirectory: string;
}

export interface UniversalLinkEventData {
    url: string;
    scheme: string;
    host: string;
    path: string;
    params: { [key: string]: string };
    hash: string;
}

export interface UniversalLinks {
    subscribe: (event: string, handler: (e: UniversalLinkEventData) => void) => void;
}

export interface InAppBrowserBeforeLoadListener {
    (params: { url: string }, callback: (url: string) => void): void;
}

export interface InAppBrowserBeforeLoadErrorListener {
    (params: { url: string; message: string; code: number }): void;
}

export interface InAppBrowserInstance {
    addEventListener(type: "beforeload", listener: InAppBrowserBeforeLoadListener): void;
    addEventListener(type: "loadstop" | "exit", listener: () => void): void;
    addEventListener(type: "loaderror", listener: InAppBrowserBeforeLoadErrorListener): void;
    close(): void;
    executeScript(options: { code: string }): void;
}

interface SsoSignIn {
    signIn: (success: (result: Identity | null) => void, failure: (error: any) => void) => void;
}

interface GoogleSignIn {
    signIn: (clientId: string, success: (result: Identity | null) => void, failure: (error: any) => void) => void;
}

interface NativeRecaptcha {
    createClient: (siteKey: string, success: () => void, failure: (error: any) => void) => void;
    executeAction: (
        siteKey: string,
        action: string,
        success: (token: string | null) => void,
        failure: (error: any) => void
    ) => void;
}

export interface CordovaWindow extends Window {
    cordova: {
        file: CordovaFile;
        InAppBrowser: {
            open: (url?: string, target?: string, features?: string, replace?: boolean) => InAppBrowserInstance;
        };
        fireDocumentEvent: (event: string, data: any) => void;
    };
    device: DeviceInfo;
    handleOpenURL: (url: string) => void;
    universalLinks: UniversalLinks;
    StatusBar: StatusBar;
    SMSRetriever: SMSRetriever;
    navigator: CordovaNavigator;
    AppRate: AppRate;
    BraintreeDevicePayments: BraintreeDevicePayments;
    StripeDevicePayments: StripeDevicePayments;
    GoogleSignIn: GoogleSignIn;
    AppleSignIn: SsoSignIn;
    FacebookSignIn: SsoSignIn;
    NativeRecaptcha: NativeRecaptcha;
}

export const cordovaWindow = window as unknown as CordovaWindow;
export const isRemote = window.location.origin.indexOf("http") === 0;
