import { createSelector } from "reselect";
import { AppState } from "../../index";
import { HistoryListItem, OrderHistoryData } from "src/features/orderHistoryData";
import { getOrderPaymentsFromOrderData, getOrderTotalSummaryFromOrderData } from "../../orderHistoryItem/helpers";

export const getOrderHistoryData = (state: AppState) =>
    state.orderHistoryData && state.orderHistoryData.orderHistoryData;

export const getSelectedOrderHistoryData = (state: AppState) => state.orderHistoryData.selectedOrderHistoryData;
export const orderHistoryDataLoading = ({ orderHistoryData }: AppState) => orderHistoryData.loading;
export const orderHistoryDataHasMore = ({ orderHistoryData }: AppState) => orderHistoryData.hasMore;

export const getOrderHistoryForCards = createSelector(getOrderHistoryData, (mealHistoryData) => {
    if (!mealHistoryData) return [] as OrderHistoryData[];

    const aggregatedHistoryItems = [] as OrderHistoryData[];

    mealHistoryData
        .filter((history) => !!history.summary) // there may be ongoing orders in this list that are older than the latest summaries
        .forEach((history) => {
            const lastItem = aggregatedHistoryItems[aggregatedHistoryItems.length - 1];
            if (
                lastItem?.summary &&
                lastItem.summary.locationName === history.summary!.locationName &&
                lastItem.posixServiceDate < history.posixServiceDate + 10 * 60 * 60
            ) {
                lastItem.relatedItems.push(history);
            } else {
                aggregatedHistoryItems.push({
                    ...history,
                    relatedItems: [],
                });
            }
        });

    return aggregatedHistoryItems.map(
        (item) =>
            ({
                ...item,
                itemId: item.partyId,
                locationName: item.summary!.locationName,
                locationImagePath: item.summary!.locationImage,
                relatedItems: item.relatedItems.map((subItem) => {
                    const orderHistoryData = subItem as OrderHistoryData;
                    return {
                        ...subItem,
                        itemId: orderHistoryData.partyId,
                        locationName: orderHistoryData.summary!.locationName,
                        locationImagePath: orderHistoryData.summary!.locationImage,
                    } as HistoryListItem;
                }),
            } as HistoryListItem)
    );
});

export const getOrderHistoryLastServiceDate = createSelector(getOrderHistoryForCards, (aggregatedHistoryListItems) => {
    let aggregatedHistoryItems = aggregatedHistoryListItems as OrderHistoryData[];
    const lastItem = aggregatedHistoryItems[aggregatedHistoryItems.length - 1];

    if (!lastItem) return null;

    if (lastItem.relatedItems.length) {
        return (lastItem.relatedItems[lastItem.relatedItems.length - 1] as OrderHistoryData).summary!.serviceDate;
    }

    return lastItem.summary!.serviceDate;
});

export const getHistoryOrderTotalSummary = createSelector(getSelectedOrderHistoryData, (orderHistory) =>
    getOrderTotalSummaryFromOrderData(orderHistory)
);

export const getHistoryOrderPayments = createSelector(getSelectedOrderHistoryData, (orderHistory) =>
    getOrderPaymentsFromOrderData(orderHistory)
);
