import { AppDispatch, AppState } from "src/features";
import { actionCreators } from "../reducers";
import { getPayOnlySplitPaymentOptions } from "../selectors";
import { PayOnlyPaymentFlowPages, payOnlyPaymentWizard } from "../wizards";
import { getIsAnonymous, getRequireSignIn } from "../../accounts/selectors";
import { getIsOpenTable } from "../../openTable/selectors";
import { payTotal } from "./payTotal";
import { getPaymentMethodsAsNeeded } from "../../payment/actions/getPaymentMethods";
import { getSelectedPaymentMethod } from "../../payment/selectors";
import { signInAction } from "../../signup/actions/signInAction";

export const selectSplitPayment = (afterSignIn?: boolean) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const splitPaymentOptions = getPayOnlySplitPaymentOptions(state);
        const isOpenTable = getIsOpenTable(state);

        if (!splitPaymentOptions.length) {
            await dispatch(payTotal());
            return;
        }

        if (isOpenTable) {
            const requireSignIn = getRequireSignIn(state);
            if (requireSignIn) {
                dispatch(
                    signInAction({
                        useLocationTheme: true,
                        slideAnimation: "slide-in",
                        onComplete: () => {
                            dispatch(selectSplitPayment(true));
                        },
                        getSignInComplete: (state) => !!payOnlyPaymentWizard.getState(state),
                    })
                );
                return;
            }
        }

        const isAnonymous = getIsAnonymous(state);
        const selectedPaymentMethod = isOpenTable
            ? await getPaymentMethodsAsNeeded(dispatch, getState)
            : getSelectedPaymentMethod(state);

        const pages: PayOnlyPaymentFlowPages[] = [];

        if (!isOpenTable || isAnonymous) {
            pages.push("SetFirstName");
        }

        if (splitPaymentOptions.length > 1) {
            pages.push("SelectSplitOption");
        } else {
            dispatch(actionCreators.setSplitOption(splitPaymentOptions[0]));
        }

        pages.push("SplitOptionConfiguration");

        if (!selectedPaymentMethod) {
            pages.push("SelectPaymentMethod");
        }

        pages.push("ReviewPayment");

        dispatch(
            payOnlyPaymentWizard.actionCreators.start(pages[0], {
                overridePages: pages,
                overrideStartTransition: afterSignIn ? "slide-from-right" : undefined,
            })
        );
    };
};
