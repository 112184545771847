import React, { ReactNode, ErrorInfo } from "react";
import { refreshApp, errorBoundaryMessages } from "src/common/experience";
import { Button, Text } from "src/sharedComponents";
import { ErrorIcon } from "src/sharedComponents/assets/icons";
import { actionCreators } from "../reducers";
import { Store } from "redux";
import "./ErrorBoundary.scss";

interface Props {
    children: ReactNode;
    store: Store;
}
interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };

        if (process.env.NODE_ENV !== "production") {
            // Only for dev, to expose the store in Safari (no redux extension available)
            window["__ReduxStore"] = props.store;
        }
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.store.dispatch(actionCreators.error(error, errorInfo));
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <div className="error-boundary">
                    <div className="error-boundary__content">
                        <ErrorIcon />
                        <Text preset="title-28" mode="extra-bold">
                            {errorBoundaryMessages.title}
                        </Text>
                        <Text preset="g-14" mode="bold">
                            {errorBoundaryMessages.description}
                        </Text>
                        <Text preset="g-14" mode="bold">
                            {errorBoundaryMessages.reported}
                        </Text>
                    </div>
                    <Button mode="outline" onClick={refreshApp} value={errorBoundaryMessages.action} />
                </div>
            );
        }

        return children;
    }
}
