import { config } from "src/common/config";

export const statusBar = {
    setColor: (hex: string) => {
        if (config.enableDynamicStatusColor !== "1" && hex !== "#095d44") {
            hex = "#FEFEFE";
        }

        const el = document.querySelector<HTMLMetaElement>("meta[name=theme-color]");

        if (el) {
            el.content = hex;
        }
    },
};
