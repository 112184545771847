import { fetchActions } from "../reducers/active";
import { AppDispatch } from "src/features";
import { getMembershipState } from "../api/getMembershipStates";
import { config } from "src/common/config";

export async function fetchActive(locationId: string, dispatch: AppDispatch) {
    if (config.enableConnectMembership !== "1") {
        return;
    }

    dispatch(fetchActions.loading());

    try {
        const results = await getMembershipState(locationId);
        dispatch(fetchActions.loaded(results));
    } catch (e) {
        dispatch(fetchActions.failed(e));
    }
}
