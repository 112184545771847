import { AppDispatch, AppState } from "../../index";
import { confirmOrder } from "../../order/actions/confirmOrder";
import { validateActiveOrderInBackground } from "../../order/actions/validateActiveOrder";
import { startGroupTabWizardAction } from "../../groupTabs/actions/startGroupTabWizardAction";
import { createVerifiedAccountActions, signInActions } from "../reducers";

export const completeSignIn = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const {
            paymentFlow,
            groupTabs: { wizard: groupTabsWizard },
            createVerifiedAccount,
        } = getState();
        if (paymentFlow.showWizard) {
            dispatch(confirmOrder());
            dispatch(validateActiveOrderInBackground());
        }
        if (groupTabsWizard.showWizard) {
            dispatch(startGroupTabWizardAction({ allStates: true }));
        }
        if (createVerifiedAccount.page && !createVerifiedAccount.preventCompletingVerifyWizard) {
            dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
        }
        dispatch(signInActions.completeSignInFlow());
    };
};
