import { FeatureConfiguration } from "..";
import { reducer } from "./reducers/takeawayOptions";
import { createOperation } from "../operations";

export const fetchTakeawayScheduleSlotsOperation = createOperation<void>("TAKEAWAY/FETCH_SCHEDULE_SLOTS", {
    showSpinner: true,
});
export const sendTakeawayScheduledTimeOperation = createOperation<void>("TAKEAWAY/SEND_SCHEDULED_TIME");

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.takeawayOptions = reducer;
}
