import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getAuthHeaders } from "../../../common/auth";
import { PaymentGateway } from "../../../common/payment";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";

export const fetchBrowserPaymentStatus = async (
    paymentGateway: PaymentGateway,
    paymentId: string,
    inParty: boolean
) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    const response = await orderApi.send(
        `/paymentgateway/${paymentGateway}/browserpayment/${paymentId}`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.limited
    );

    await ProblemDetailsError.throwError(response);

    return await response.text();
};
