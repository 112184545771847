import "./CodeInput.scss";

import React, {
    ChangeEvent,
    CSSProperties,
    DetailedHTMLProps,
    forwardRef,
    InputHTMLAttributes,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import classNames from "classnames";
import { useFormControlContext } from "../formControl";
import { Text } from "../";

export interface CodeInputProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
    value: string;
    length: number;
    invalid?: boolean;
    onClick?: () => void;
    onDisabledClick?: () => void;
    onChange?: (value: string) => void;
}

export const CodeInput = forwardRef<HTMLInputElement | null, CodeInputProps>(
    ({ id, value, length, disabled, invalid, className, onClick, onDisabledClick, onChange, ...rest }, ref) => {
        const internalRef = useRef<HTMLInputElement>(null);
        const formControl = useFormControlContext() || { id, disabled, invalid };
        const chars = useMemo(() => new Array(length).fill(""), [length]);

        useImperativeHandle(ref, () => internalRef.current, []);

        const handleOnClick = () => {
            internalRef.current?.focus();

            if (formControl.disabled) onDisabledClick?.();
            else onClick?.();
        };

        const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event.target.value);
        };

        return (
            <div
                style={{ "--code-length": length } as CSSProperties}
                className={classNames(
                    "code-input-control",
                    {
                        "code-input-control--disabled": formControl.disabled,
                        "code-input-control--invalid": formControl.invalid,
                    },
                    className
                )}
            >
                <input
                    id={formControl.id}
                    ref={internalRef}
                    value={value}
                    disabled={formControl.disabled}
                    maxLength={length}
                    pattern={`[0-9A-Za-z]{${length}}`}
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck={false}
                    onChange={handleOnChange}
                    onLoad={(event) => event.preventDefault()}
                    {...rest}
                />
                {/*This hidden input is needed for iOS 15 which crashes for some reason when a code is clicked on the keyboard*/}
                {/*I don't know why it stops it crashing ¯\_(ツ)_/¯*/}
                <input style={{ display: "none" }} />
                {chars.map((_, i) => (
                    <div
                        key={i}
                        className={classNames("code-input-control__char", {
                            "code-input-control__char--value": i <= value.length,
                        })}
                        data-char={i === value.length || (value.length === length && i === value.length - 1) ? " " : ""}
                        onClick={handleOnClick}
                    >
                        <Text preset={length > 5 ? "title-24" : length === 5 ? "title-28" : "title-32"} mode="bold">
                            {value.charAt(i) || " "}
                        </Text>
                    </div>
                ))}
            </div>
        );
    }
);
