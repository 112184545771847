import React from "react";
import classNames from "classnames";
import { TappableDiv, TappableSpan } from "../../common/tappable";
import { DecreaseIcon, IncreaseIcon } from "../../assets/icons";
import { Text } from "../text";

import "./QuantitySelector.scss";

export type SelectorSize = "standard" | "large" | "extra-large";

export interface QuantitySelectorProps {
    onDecrement: () => void;
    onIncrement: () => void;
    onDisabledDecrement?: () => void;
    onDisabledIncrement?: () => void;
    quantity: number;
    size?: SelectorSize;
    minValue?: number;
    maxValue?: number;
    shouldAnimateOnActive?: boolean;
    disableIncrementOverride?: boolean;
    disabled?: boolean;
    useThemeColours?: boolean;
}

export const QuantitySelector = ({
    quantity,
    onDecrement,
    onIncrement,
    onDisabledDecrement,
    onDisabledIncrement,
    size = "standard",
    disableIncrementOverride,
    minValue = 0,
    maxValue = 99,
    shouldAnimateOnActive,
    disabled,
    useThemeColours = true,
}: QuantitySelectorProps) => {
    const isIncrementDisabled = disabled || disableIncrementOverride || quantity >= maxValue;
    const isDecrementDisabled = disabled || quantity <= minValue;
    const isExpanded = !shouldAnimateOnActive || !!quantity;

    return (
        <div
            className={classNames(
                "quantity-selector",
                size !== "standard" && size,
                isExpanded && "show-selector",
                useThemeColours && "with-theme"
            )}
        >
            <div className="quantity-selector__overlay">
                <div className="quantity-selector__picker">
                    <TappableSpan
                        onTap={onDecrement}
                        onDisabledTap={onDisabledDecrement}
                        className={classNames(
                            "quantity-selector__button quantity-selector__button--negative",
                            isDecrementDisabled && "disabled"
                        )}
                        disabled={isDecrementDisabled}
                    >
                        <DecreaseIcon />
                    </TappableSpan>
                    <Text
                        preset={size !== "standard" ? "g-16" : "g-14"}
                        mode="bold"
                        className={classNames("quantity-selector__value", disabled && "disabled")}
                    >
                        {shouldAnimateOnActive && quantity === 0 ? quantity + 1 : quantity}
                    </Text>
                    <TappableSpan
                        onTap={onIncrement}
                        onDisabledTap={onDisabledIncrement}
                        className={classNames("quantity-selector__button", isIncrementDisabled && "disabled")}
                        disabled={isIncrementDisabled}
                    >
                        <IncreaseIcon />
                    </TappableSpan>
                </div>
                <TappableDiv
                    onTap={onIncrement}
                    onDisabledTap={onDisabledIncrement}
                    className={classNames(
                        "quantity-selector__button quantity-selector__button--add",
                        isIncrementDisabled && "disabled"
                    )}
                    disabled={isIncrementDisabled}
                >
                    <IncreaseIcon />
                </TappableDiv>
            </div>
        </div>
    );
};
