import { AppDispatch, AppState } from "../..";
import { joiningPayOnlyPartyOperation } from "../operations";
import { disconnectParty, getPartyToken, joinParty } from "../api";
import { deleteTableToken, saveTableToken } from "../persistence/tableToken";
import { getRestaurantFlagsOperation } from "../../order/operations";
import { timeoutAndError, waitMinimum } from "src/common/shared";
import { getABTests } from "../../abtests/actions/getABTests";
import { closeAllModals, INITIAL_WAIT_PARTY_TIME, MIN_JOIN_LOADING } from "./helper";
import { actionCreators } from "src/features/order";
import { actions as menudataActions } from "../../menudata/actions";
import { deletePayOnlyToken, savePayOnlyToken } from "src/features/payOnly/persistence/payOnlyToken";
import { markBillAsPaid } from "src/features/payOnly/actions/markBillAsPaid";
import { getPaymentMethodsBackground } from "../../payment/actions/getPaymentMethods";
import { profileActionCreators } from "../../accountmenu";
import { actionCreators as takeawayOptionActions } from "src/features/takeaway/reducers/takeawayOptions";
import { LocationMarkerClaim } from "../types";
import { getIsExternalPath } from "src/common/experience/shared/url";
import { isPayOnlyPartyToken } from "src/features/partyOnboarding/util/isPayOnlyPartyToken";
import { joinTable } from "src/features/partyOnboarding/actions/joinTable";
import { replace } from "connected-react-router";

export function joinPayOnlyParty(locationMarkerClaims: LocationMarkerClaim[], existingToken?: string) {
    return joiningPayOnlyPartyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(closeAllModals());

            await disconnectParty(dispatch, false, "switch_to_pay");

            const timeStart = performance.now();
            const {
                access_token: tableToken,
                restaurantFlags,
                closedParty,
                profile,
                takeawayContext,
            } = await Promise.race([
                getPartyToken(locationMarkerClaims, existingToken),
                timeoutAndError(INITIAL_WAIT_PARTY_TIME, new Error("Timeout")),
            ]);

            if (tableToken && !isPayOnlyPartyToken(tableToken)) {
                // We have a Pay Only claim URL but we ended up in a non-Pay Only party
                // so we must have already been in a non-Pay Only party and remained there
                // so hand off to joinTable where we will join the same party again but
                // go through the correct flow and set the correct state data
                dispatch(joinTable(locationMarkerClaims, existingToken));
                dispatch(replace("/join-table"));
                return;
            }

            const timeEnd = performance.now();
            const timeoutLeft = INITIAL_WAIT_PARTY_TIME - (timeEnd - timeStart);

            dispatch(getRestaurantFlagsOperation.actionCreators.completed(restaurantFlags));
            dispatch(profileActionCreators.fetchSuccess(profile));
            dispatch(takeawayOptionActions.setTakeawayDetails(takeawayContext));

            if (tableToken) {
                saveTableToken(tableToken);

                const token = locationMarkerClaims[0].token;
                const url = new URL(token);
                if (!getIsExternalPath(url.pathname)) {
                    savePayOnlyToken(token);
                } else {
                    deletePayOnlyToken();
                }

                dispatch(getABTests());

                await waitMinimum(joinParty(getState, dispatch, tableToken, undefined, timeoutLeft), MIN_JOIN_LOADING);

                dispatch(getPaymentMethodsBackground());
            } else if (closedParty) {
                // member viewing a closed PayOnly receipt
                deleteTableToken("pay_party_closed");
                savePayOnlyToken(locationMarkerClaims[0].token);
                dispatch(actionCreators.viewPayOnlyParty(closedParty));
                dispatch(markBillAsPaid());
                await menudataActions.fetch(closedParty.menuDataUrl, dispatch, getState, closedParty.serviceId);
            }
        } catch (e) {
            if (!e.message) {
                throw new Error("Failed to fetch pay-only table: no error message");
            }
            throw e;
        }
    });
}
