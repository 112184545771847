import "../assets/RewardEarned.scss";

import React from "react";
import { StampCard } from "./StampCard";
import { useSelector } from "react-redux";
import { CardFlip, Divider, Text } from "src/sharedComponents";
import { getBounceBackEngageOfferAdjustments, getBounceBackOfferStampCard } from "src/features/order/selectors";
import { OfferTile } from "./OfferTile";
import { Confetti } from "src/sharedComponents/controls/confetti";

export const RewardEarned = () => {
    const stampCardOfferReward = useSelector(getBounceBackOfferStampCard);
    const bounceBackEngageOfferAdjustments = useSelector(getBounceBackEngageOfferAdjustments);
    // priorities Stamp card completed
    const bounceBackAdjustment =
        bounceBackEngageOfferAdjustments?.find(
            (adjustment) => adjustment.cepOfferContext?.offerId === stampCardOfferReward?.id
        ) ?? bounceBackEngageOfferAdjustments?.[0];
    const bounceBackReward = bounceBackAdjustment?.cepOfferContext?.bounceBack;

    if (!bounceBackReward) {
        return null;
    }

    if (stampCardOfferReward?.stampCard) {
        stampCardOfferReward.stampCard.earnedStamps = stampCardOfferReward.stampCard.totalStamps;
    }

    return (
        <div className="stampcard-reward-earned">
            <Divider fullWidth className="stampcard-reward-earned__divider" />
            <Text preset="g-18" mode={["block", "bold"]}>
                You’ve earned a reward
            </Text>
            <Text preset="g-14" mode={["block"]}>
                Use this next time you place an order:
            </Text>
            {stampCardOfferReward?.stampCard ? (
                <CardFlip
                    autoFlipTimeMS={2500}
                    frontSide={
                        <StampCard
                            stampCard={stampCardOfferReward.stampCard}
                            title={stampCardOfferReward.title}
                            subtitle={stampCardOfferReward.subtitle}
                            status={stampCardOfferReward.status}
                            className="stampcard-reward-earned__stamp-card"
                        />
                    }
                    backSide={<OfferTile offer={bounceBackReward} key={bounceBackReward.id} mode="small" />}
                />
            ) : (
                <div className="stampcard-reward-earned__stand-alone">
                    <OfferTile offer={bounceBackReward} key={bounceBackReward.id} mode="small" />
                </div>
            )}
            <Confetti fire full y={0.7} startVelocity={35} />
        </div>
    );
};
