import "./MembersStatusList.scss";

import React, { useMemo } from "react";
import { Text, Divider } from "src/sharedComponents";
import { useSelector } from "react-redux";
import { getAllPartyMembersWithStatus } from "../selectors/memberStatus";
import { OpenTableMemberStatus } from "../types";
import { MemberIndicator } from "../../../sharedComponents/controls/memberIndicator";
import classNames from "classnames";
import { OpenTablePartyMember } from "../../order";
import { getCurrentMemberId } from "../../accounts/selectors";

const statusPriority = {
    [OpenTableMemberStatus.WAITING]: 0,
    [OpenTableMemberStatus.ORDERING]: 1,
    [OpenTableMemberStatus.BROWSING]: 2,
};

const sortMembersByStatus = (members: OpenTablePartyMember[], currentMemberId: string) =>
    members.sort((a, b) => {
        if (!a.derivedMemberStatus && !b.derivedMemberStatus) return 0;
        if (!a.derivedMemberStatus) return 1;
        if (!b.derivedMemberStatus) return -1;

        const statusA = statusPriority[a.derivedMemberStatus];
        const statusB = statusPriority[b.derivedMemberStatus];

        if (a.memberId === currentMemberId && statusA === statusB) return -1;

        return statusA - statusB;
    });

export const MembersStatusList = () => {
    const members = useSelector(getAllPartyMembersWithStatus);
    const currentMemberId = useSelector(getCurrentMemberId);
    const sortedMembers = useMemo(() => sortMembersByStatus([...members], currentMemberId), [members, currentMemberId]);

    if (!members?.length) return null;

    return (
        <div className="members-status-list">
            <div className="members-status-list__members">
                {sortedMembers.map((member) => {
                    const isReady = member.derivedMemberStatus === OpenTableMemberStatus.WAITING;
                    const isBrowsing = member.derivedMemberStatus === OpenTableMemberStatus.BROWSING;
                    return (
                        <div
                            className={classNames(
                                "members-status-list__members__member",
                                isReady && "ready",
                                isBrowsing && "browsing"
                            )}
                            key={member.memberId}
                        >
                            <MemberIndicator
                                active={isReady}
                                disabled={isBrowsing}
                                value={member.initials}
                                isLarge={true}
                            />
                            <Text
                                preset="g-12"
                                mode={["block", "bold"]}
                                className="members-status-list__members__member__name"
                            >
                                {member.memberId === currentMemberId ? "You" : member.firstName || "Guest"}
                            </Text>
                            {member.derivedMemberStatus && !isBrowsing && (
                                <Text
                                    preset="g-12"
                                    mode={["block", "medium"]}
                                    className="members-status-list__members__member__status"
                                >
                                    {isReady ? "Ready" : member.derivedMemberStatus}
                                </Text>
                            )}
                        </div>
                    );
                })}
            </div>
            <Divider />
        </div>
    );
};
