import { getAuthHeaders } from "src/common/auth";
import { orderApi, reattemptStrategies } from "src/features/order/orderApi";

export async function sendGroupTabReceiptApi(groupTabId: string) {
    const response = await orderApi.send(
        `/grouptabshistory/${groupTabId}/receipt`,
        {
            method: "POST",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }
    const data = await response.json();

    return data as string | null;
}
