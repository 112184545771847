import { LocationMenuData } from "..";
import { AppDispatch, AppState } from "../..";
import { menuDataUrl } from "../API/menuDataUrl";
import { fetchActions } from "../reducers";
import { attempt } from "../../order/orderApi/util/attempt";
import { reattemptStrategies } from "../../order/orderApi";
import { fetchBackgroundImage } from "./fetchBackgroundImage";
import { populateLegacyDefaults, updatePrices } from "../mutators";
import { getMenuData, getMenuState } from "src/features/menu/selectors";

export default async (
    snapshotUrl: string,
    dispatch: AppDispatch,
    getState: () => AppState,
    serviceId?: string,
    membershipLevelId?: string | null,
    isPayOnly?: boolean
) => {
    const fullSnapshotUrl = menuDataUrl(snapshotUrl);

    const state = getState();
    const currentState = getMenuState(state);
    const currentMenu = getMenuData(state);

    if (currentState.url === snapshotUrl && currentMenu && !isPayOnly) {
        const menuData = updatePrices(currentMenu, serviceId, membershipLevelId);
        dispatch(fetchActions.loaded(snapshotUrl, menuData, currentState.activeServiceId!));
        return;
    }

    try {
        dispatch(fetchActions.loading());

        const onAttemptFailed = (count: number) => dispatch(fetchActions.connectionFailed(fullSnapshotUrl, count));

        const response = await attempt(
            () => fetch(fullSnapshotUrl),
            reattemptStrategies.limited,
            undefined,
            onAttemptFailed
        );

        if (!response.ok) {
            throw new Error();
        }

        let menuData: LocationMenuData = await response.json();

        populateLegacyDefaults(menuData);

        if (!isPayOnly) {
            menuData = updatePrices(menuData, serviceId, membershipLevelId);
            dispatch(fetchBackgroundImage(menuData.backgroundImage?.full));
            dispatch(fetchBackgroundImage(menuData.menuBackgroundImage?.full));
        }

        dispatch(fetchActions.loaded(snapshotUrl, menuData, serviceId));
    } catch (e) {
        dispatch(fetchActions.failed(e));
        throw e;
    }
};
