import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRefValues } from "src/sharedComponents/common/shared";
import { Thumbs, Rating } from "../../../sharedComponents";
import { actions as ratingActions } from "../actions";
import { getRating } from "../selectors/rating";
import { getSurveyDetails, getSurvey } from "../selectors/survey";

interface Props {
    isLarge?: boolean;
    locationTheme?: boolean;
    onNegative?: () => void;
    onPositive?: () => void;
    validate: (result: boolean) => void;
}

export const ThumbsFeedback = ({ isLarge, locationTheme, onNegative, onPositive, validate }: Props) => {
    const rating = useSelector(getRating);
    const surveyDetails = useSelector(getSurveyDetails);
    const { tags, negativeNote } = useSelector(getSurvey);
    const [onNegativeRef] = useRefValues(onNegative);
    const [onPositiveRef] = useRefValues(onPositive);

    const dispatch = useDispatch();

    const setRating = useCallback(
        (rating: Rating | null) => {
            dispatch(ratingActions.setRating(rating));
            switch (rating) {
                case Rating.NEGATIVE: {
                    onNegativeRef.current && onNegativeRef.current();
                    break;
                }
                case Rating.POSITIVE: {
                    onPositiveRef.current && onPositiveRef.current();
                    break;
                }
            }
        },
        [dispatch, onNegativeRef, onPositiveRef]
    );

    useEffect(() => {
        const isValid = rating === null || rating === Rating.POSITIVE || !!tags?.length || !!negativeNote?.length;
        validate(isValid);
    }, [validate, rating, tags, negativeNote]);

    return (
        <Thumbs
            isLargeIcons={!!isLarge}
            isLargeTitle={!!isLarge}
            locationTheme={locationTheme}
            title={surveyDetails?.title || "How would you rate me&u?"}
            defaultRating={rating}
            onThumbClick={setRating}
        />
    );
};
