import "./Lobby.scss";

import React, { useCallback, useEffect, useRef } from "react";
import { Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { leaveTable } from "../../partyOnboarding/actions/leaveTable";
import { NativeBackButton, SimpleNavHeader } from "src/common/navigation";
import { joinOpenTableParty } from "src/features/partyOnboarding/actions/joinOpenTableParty";
import { resetJoinTableOperations } from "../../partyOnboarding/actions/resetOperations";
import { LobbyHeader } from "./LobbyHeader";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { getIsOpenTableOrderingDisabled } from "../selectors";
import { actionCreators } from "../reducers";
import { MemberActivity } from "../../order";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { getTableLabel } from "src/features/order/selectors";

export const Lobby = () => {
    const isOpenTableOrderingDisabled = useSelector(getIsOpenTableOrderingDisabled);
    const tableLabel = useSelector(getTableLabel);
    const joinedRef = useRef(false);
    const dispatch = useDispatch();

    const onCancel = useCallback(() => {
        dispatch(leaveTable());
        dispatch(resetJoinTableOperations());
    }, [dispatch]);

    const onActivitySelection = useCallback(
        (activity: MemberActivity) => {
            joinedRef.current = true;
            dispatch(actionCreators.trackActivitySelection(activity));
            dispatch(actionCreators.setActivity(activity));
            dispatch(joinOpenTableParty());
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            if (!joinedRef.current) {
                onCancel();
            }
        };
    }, [onCancel]);

    return (
        <NativeBackButton name="lobby" onPressed={onCancel}>
            <LocationThemeContainer>
                <SimpleNavHeader onBack={onCancel} closeToBack customBack="lobby" />
                <div className="lobby">
                    <main>
                        <Text preset="title-24" mode="bold" className="lobby__title" value="Joining an open order" />
                        <LobbyHeader />
                        <Text preset="g-14" className="lobby__description">
                            By joining this {tableLabel}, you will be able to order together and pay at the end. To
                            start ordering from a new {tableLabel}, please contact venue staff.
                        </Text>
                    </main>
                    <footer>
                        {isOpenTableOrderingDisabled && (
                            <Text
                                preset="g-14"
                                className="lobby__ordering-unavailable"
                                value="This venue is not taking orders right now"
                            />
                        )}
                        <NetworkConnectedButton
                            mode={isOpenTableOrderingDisabled ? "solid" : "outline"}
                            onClick={() => onActivitySelection(MemberActivity.Paying)}
                            value="Pay for this order"
                        />
                        <NetworkConnectedButton
                            mode="solid"
                            onClick={() => onActivitySelection(MemberActivity.Ordering)}
                            value="Start ordering"
                            disabled={isOpenTableOrderingDisabled}
                        />
                    </footer>
                </div>
            </LocationThemeContainer>
        </NativeBackButton>
    );
};
