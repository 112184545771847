import { PaymentGatewayBehaviors } from "../../interface";
import {
    applePayBehavior as braintreeApplePayBehavior,
    googlePayBehavior as braintreeGooglePayBehavior,
} from "./braintree/behaviors";
import {
    applePayBehavior as stripeApplePayBehavior,
    googlePayBehavior as stripeGooglePayBehavior,
} from "./stripe/behaviors";
import { PaymentMethod } from "src/common/payment";

const paymentGatewayBehaviors: PaymentGatewayBehaviors = {
    Braintree: {
        getApplePayBehavior: () => braintreeApplePayBehavior,
        getGooglePayBehavior: () => braintreeGooglePayBehavior,
    },
    Tyro: {},
    Stripe: {
        getApplePayBehavior: () => stripeApplePayBehavior,
        getGooglePayBehavior: () => stripeGooglePayBehavior,
    },
    GroupTab: {},
    Spreedly: {},
    Pos: {},
};

export const getApplePayBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getApplePayBehavior?.(paymentMethod);

export const getGooglePayBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getGooglePayBehavior?.(paymentMethod);

export const getCardVerificationSupported = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getCardVerificationSupported?.(paymentMethod);
