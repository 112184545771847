import { createSelector } from "reselect";
import { getParty, SecuredPaymentState } from "../../order";
import { getCurrentMemberDetails } from "../../order/selectors";

export const getSecuredPaymentState = createSelector(getParty, (activeParty) => activeParty?.securedPaymentState);

export const getIsSecuredPaymentMethodRequired = createSelector(
    getSecuredPaymentState,
    (securedPaymentState) => securedPaymentState === SecuredPaymentState.REQUIRED
);

export const getIsSecuredPaymentSatisfied = createSelector(
    getSecuredPaymentState,
    (securedPaymentState) => securedPaymentState === SecuredPaymentState.SATISFIED
);

export const getWalkOutGratuityFactor = createSelector(getParty, (activeParty) => activeParty?.walkOutGratuityFactor);

export const getCurrentMemberHasSecuredPaymentMethod = createSelector(
    getCurrentMemberDetails,
    (currentMember) => !!currentMember?.hasSecuredPayment
);
