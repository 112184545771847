export interface SpinnerState {
    isLoading: boolean;
    isLoaderHidden: boolean;
    isOperationRunning: boolean;
}

enum TypeKeys {
    SET_IS_LOADING = "SPINNER/SET_IS_LOADING",
    SET_IS_OPERATION_RUNNING = "SPINNER/SET_IS_OPERATION_RUNNING",
    SET_IS_LOADER_HIDDEN = "SPINNER/SET_IS_LOADER_HIDDEN",
}

export const actionCreators = {
    setIsLoading: (isLoading: boolean) => ({ type: TypeKeys.SET_IS_LOADING, isLoading }),
    setIsLoaderHidden: (isLoaderHidden: boolean) => ({ type: TypeKeys.SET_IS_LOADER_HIDDEN, isLoaderHidden }),
    setIsOperationRunning: (isOperationRunning: boolean) => ({
        type: TypeKeys.SET_IS_OPERATION_RUNNING,
        isOperationRunning,
    }),
};

type SetIsLoadingAction = { type: TypeKeys.SET_IS_LOADING; isLoading: boolean };
type SetIsLoaderHiddenAction = { type: TypeKeys.SET_IS_LOADER_HIDDEN; isLoaderHidden: boolean };
type SetIsOperationRunningAction = { type: TypeKeys.SET_IS_OPERATION_RUNNING; isOperationRunning: boolean };

export type SpinnerAction = SetIsLoadingAction | SetIsLoaderHiddenAction | SetIsOperationRunningAction;

const initialState: SpinnerState = {
    isLoading: false,
    isLoaderHidden: false,
    isOperationRunning: false,
};

export function reducer(state: SpinnerState = initialState, action: SpinnerAction): SpinnerState {
    if (action.type === TypeKeys.SET_IS_LOADING) {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    }
    if (action.type === TypeKeys.SET_IS_LOADER_HIDDEN) {
        return {
            ...state,
            isLoaderHidden: action.isLoaderHidden,
        };
    }
    if (action.type === TypeKeys.SET_IS_OPERATION_RUNNING) {
        return {
            ...state,
            isOperationRunning: action.isOperationRunning,
        };
    }
    return state;
}
