import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth/auth";
import { AccountProfile } from "../index";
import { FetchError } from "../../order/orderApi/FetchError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

export async function fetchFoodPreferencesApi(): Promise<string[]> {
    const response = await orderApi.send(
        "/account/foodpreferences",
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const json = await response.json();

    return json as string[];
}

export async function saveFoodPreferencesApi(tags: string[]) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        "/account/foodpreferences",
        {
            method: "PUT",
            body: JSON.stringify(tags),
            headers,
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }
}

export async function fetchProfile(): Promise<AccountProfile | null> {
    const response = await orderApi.send(
        "/account/profile",
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const json = await response.json();

    return json as AccountProfile;
}

export async function saveProfile(accountProfile: AccountProfile, inParty: boolean) {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();
    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        "/account/profile",
        {
            method: "PUT",
            body: JSON.stringify(accountProfile),
            headers,
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }
}

export async function deleteAccount() {
    const headers = await getAuthHeaders();

    const response = await orderApi.send(`/account`, {
        method: "DELETE",
        headers,
    });

    if (!response.ok) {
        throw new FetchError(response);
    }
}
