import React, { forwardRef, PropsWithChildren } from "react";

import "../assets/ListItemBackground.scss";

interface Props {
    innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
}

const Background = ({ innerRef }: Props) => <div className="list-item-background" ref={innerRef} />;

export const ListItemBackground = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref?) => (
    <Background innerRef={ref} {...props} />
));
