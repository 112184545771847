import React, { PropsWithChildren, useEffect } from "react";
import { PaymentMethods } from "./PaymentMethods";
import { PaymentMethod, SelectablePaymentMethod } from "../../../common/payment";
import { useDispatch, useSelector } from "react-redux";
import { getAddedCardPaymentMethod } from "../../payment/selectors";
import { actionCreators as paymentActionCreators } from "../../payment";

interface Props {
    vaultCard?: boolean;
    buttonText?: string;
    onVaultCard?: () => void;
    onSuccess?: (paymentMethod: SelectablePaymentMethod) => void;
    filter?: (paymentMethod: PaymentMethod) => boolean;
}

export const InlineVaultedPaymentMethods = ({
    vaultCard,
    buttonText,
    onVaultCard,
    onSuccess,
    filter,
    children,
}: PropsWithChildren<Props>) => {
    const dispatch = useDispatch();

    const addedCardPaymentMethod = useSelector(getAddedCardPaymentMethod);

    useEffect(() => {
        if (addedCardPaymentMethod && !addedCardPaymentMethod.remember) {
            dispatch(paymentActionCreators.clearAddedCard());
        }
    }, [dispatch, addedCardPaymentMethod]);

    return (
        <PaymentMethods
            select
            vaultCard={vaultCard}
            showEnterCvv
            showVaultCard={false}
            title={null}
            buttonText={buttonText}
            onVaultCard={onVaultCard}
            onSuccess={onSuccess}
            filter={filter}
        >
            {children}
        </PaymentMethods>
    );
};
