enum TypeKeys {
    SHOW_CONNECTED_CONFIRMATION = "ACTIVE_MEMBERSHIP/SHOW_CONNECTED_CONFIRMATION",
    HIDE_CONNECTED_CONFIRMATION = "ACTIVE_MEMBERSHIP/HIDE_CONNECTED_CONFIRMATION",
}

export const actionCreators = {
    showConnectedConfirmation: () => ({ type: TypeKeys.SHOW_CONNECTED_CONFIRMATION }),
    hideConnectedConfirmation: () => ({ type: TypeKeys.HIDE_CONNECTED_CONFIRMATION }),
};

type ShowConnectedConfirmationAction = { type: TypeKeys.SHOW_CONNECTED_CONFIRMATION };
type HideConnectedConfirmationAction = { type: TypeKeys.HIDE_CONNECTED_CONFIRMATION };

type ConnectedConfirmationAction = ShowConnectedConfirmationAction | HideConnectedConfirmationAction;

export function reducer(state: boolean = false, action: ConnectedConfirmationAction): boolean {
    if (action.type === TypeKeys.SHOW_CONNECTED_CONFIRMATION) {
        return true;
    }

    if (action.type === TypeKeys.HIDE_CONNECTED_CONFIRMATION) {
        return false;
    }

    return state;
}
