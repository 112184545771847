import "../assets/SurveyPage.scss";

import React, { useState, ChangeEvent, useRef, useEffect, CSSProperties, FocusEvent } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackList } from "./FeedbackList";
import { scrolling } from "src/common/experience";
import { Text, Rating, Input, FormControl, Textarea } from "src/sharedComponents";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { DownArrowRightIcon } from "../../../sharedComponents/assets/icons";
import { actions } from "../actions";
import { getRating } from "../selectors/rating";
import { getSurveyDetails, getSurvey } from "../selectors/survey";

export const SurveyPage = () => {
    const rating = useSelector(getRating);
    const surveyDetails = useSelector(getSurveyDetails);
    const { positiveNote, negativeNote, email } = useSelector(getSurvey);
    const [isTagsExpanded, setIsTagsExpanded] = useState((surveyDetails?.availableTags?.length || 0) <= 6);
    const [isInputsExpanded, setIsInputsExpanded] = useState(false);
    const [inputsHeight, setInputsHeight] = useState<number>();
    const dispatch = useDispatch();

    const note = rating === Rating.POSITIVE ? positiveNote : negativeNote;

    const handleOnChangeNote = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (rating === Rating.POSITIVE) {
            dispatch(actions.setPositiveNote(event.target.value));
        } else {
            dispatch(actions.setNegativeNote(event.target.value));
        }
    };

    const handleOnChangeEmail = (value: string) => {
        dispatch(actions.setEmail(value));
    };

    const handleOnInputFocus = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event?.target) {
            scrolling.scrollElementIntoView(event.target);
        }
    };

    const inputsRef = useRef<HTMLDivElement>(null);

    const handleExpand = () => {
        if (!isTagsExpanded) {
            setIsTagsExpanded(true);
        } else {
            setIsInputsExpanded(true);
        }
    };

    useEffect(() => {
        if (inputsRef.current && inputsRef.current.offsetHeight) {
            setInputsHeight(inputsRef.current.offsetHeight);
        }
    }, [isInputsExpanded]);

    useEffect(() => {
        let timeout: number | undefined;

        if (isInputsExpanded) {
            timeout = window.setTimeout(() => {
                const textArea = document.querySelector(".survey-page__inputs-anchor");

                if (textArea) {
                    textArea.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [isInputsExpanded]);

    return (
        <div className="survey__container">
            <div className="survey__content">
                <div className="survey-page">
                    {rating === Rating.POSITIVE ? (
                        <Text preset="g-18" mode="bold">
                            We’re glad you had a great experience!
                        </Text>
                    ) : (
                        <>
                            <Text preset="g-18" mode="bold" className="survey-page__heading">
                                Sorry to hear that. What went wrong?
                            </Text>
                            <FeedbackList showAll={isTagsExpanded} />
                        </>
                    )}
                    <div
                        className={classNames("survey-page__inputs-container", {
                            visible: rating === Rating.POSITIVE,
                            expanded: isInputsExpanded,
                        })}
                        style={inputsHeight ? ({ "--inputs-height": `${inputsHeight}px` } as CSSProperties) : undefined}
                    >
                        <div ref={inputsRef} className="survey-page__inputs">
                            <Text preset="g-18" mode="bold">
                                Leave a note
                            </Text>
                            <Textarea
                                value={note}
                                label="Your note"
                                placeholder="Write your comments here"
                                onChange={handleOnChangeNote}
                                onFocus={handleOnInputFocus}
                            />
                            {rating === Rating.NEGATIVE && (
                                <FormControl message="We’ll follow up to this email address">
                                    <Input
                                        allowClear
                                        type="email"
                                        value={email}
                                        label="Your email"
                                        onChange={handleOnChangeEmail}
                                        onFocus={handleOnInputFocus}
                                    />
                                </FormControl>
                            )}
                        </div>
                    </div>
                    {!isInputsExpanded && rating === Rating.NEGATIVE && (
                        <TappableDiv className="survey-page__expand" onClick={handleExpand}>
                            <Text preset="g-14" mode="medium">
                                {isTagsExpanded ? "Other" : "Show more"}
                                <DownArrowRightIcon />
                            </Text>
                        </TappableDiv>
                    )}
                    <div className="survey-page__inputs-anchor" />
                </div>
            </div>
        </div>
    );
};
