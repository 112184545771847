import { device } from "./device";

export const scaleHandler = {
    register: () => {
        if (!device.isIOS) return;

        document.addEventListener(
            "touchmove",
            (event: any) => {
                if (event.scale > 1 || (event.scale < 1 && window.innerWidth === window.outerWidth)) {
                    event.preventDefault();
                }
            },
            { passive: false }
        );
    },
};
