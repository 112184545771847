import "../assets/ProfileDrawer.scss";

import * as React from "react";
import { MemoryRouter, Redirect, Route, Switch } from "react-router";
import { ContactUs } from "../../compliance/components/ContactUs";
import { PrivacyPolicy } from "../../compliance/components/PrivacyPolicy";
import { TermsOfUse } from "../../compliance/components/TermsOfUse";
import { ProfileContainer } from "../containers";
import { AccountMenuDefaultView } from "../reducers/accountMenu";
import { OrderHistoryItemPageContainer } from "../../orderHistoryItem";
import { StatusBar } from "../../../common/statusBar";
import { NavBarType } from "../../../common/navigation/types";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { LicensePage } from "src/features/licenses/component/LicensePage";
import { ProfilePageWrapperContainer } from "../containers/ProfilePageWrapperContainer";
import { PageTransition } from "src/sharedComponents";
import { PrivacySettings } from "./PrivacySettings";
import { CookiePolicy } from "../../compliance/components/CookiePolicy";
import { ManageMemberships } from "src/features/membership/components/ManageMemberships";
import { GroupTabHistoryPage } from "src/features/groupTabsHistory/components/GroupTabHistoryPage";
import { HistoryListsPage } from "src/features/historyInformation/components/HistoryListsPage";
import { RemovePaymentMethod } from "../../paymentMethods/components/RemovePaymentMethod";
import { PaymentMethods } from "../../paymentMethods/components/PaymentMethods";
import { AccountDetails } from "../../accounts/components/AccountDetails";
import { EditFoodPreferences } from "./EditFoodPreferences";
import { isVisibleForProfile } from "src/common/payment";

export interface Props {
    defaultView?: AccountMenuDefaultView;
    fetchProfile: () => void;
    navBarType: NavBarType;
    open: boolean;
    profileLoaded: boolean;
}

export interface State {
    continueToOrder?: boolean;
}

export class ProfileDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { continueToOrder: false };

        if (!props.profileLoaded) {
            props.fetchProfile();
        }
    }

    getRedirectTo = () => {
        switch (this.props.defaultView) {
            case "OrderHistory":
                return "/account/history-list/order/standalone";
            case "GroupTabHistory":
                return "/account/history-list/group-tab/standalone";
            case "SelectPaymentMethod":
                return "/onboarding/payment-methods/select";
            default:
                return "/account/menu";
        }
    };

    render() {
        const { navBarType, open } = this.props;
        return (
            <CSSTransition
                in={navBarType === NavBarType.PROFILE || open}
                classNames="party-wrapper-transition"
                timeout={150}
                unmountOnExit
            >
                <div className={classNames("profile-drawer", open && "profile-drawer--modal")}>
                    <StatusBar backgroundColor="#fff" />
                    <MemoryRouter initialEntries={["/", { pathname: this.getRedirectTo() }]} initialIndex={1}>
                        <Route
                            render={({ location, history }) => (
                                <PageTransition
                                    location={location}
                                    animation="move-left"
                                    transitionAction={history.action}
                                >
                                    <Switch location={location}>
                                        <Route exact path="/account/menu" component={ProfileContainer} />
                                        <Route
                                            exact
                                            path="/account/preferences"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="profile-food-preference"
                                                    renderPage={(done) => <EditFoodPreferences onSuccess={done} />}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/account/memberships"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="account-details"
                                                    renderPage={() => <ManageMemberships />}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/account/details"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="account-details"
                                                    renderPage={(done) => (
                                                        <AccountDetails
                                                            onSuccess={done}
                                                            standalone
                                                            flowName="profile_page"
                                                        />
                                                    )}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/onboarding/payment-methods"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="payment-methods"
                                                    renderPage={() => <PaymentMethods filter={isVisibleForProfile} />}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/account/privacy-settings"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="privacy-settings"
                                                    renderPage={() => <PrivacySettings />}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/account/history-list/:type/:standalone?"
                                            component={HistoryListsPage}
                                        />
                                        <Route
                                            exact
                                            path="/account/order-history/:partyId"
                                            component={OrderHistoryItemPageContainer}
                                        />
                                        <Route
                                            exact
                                            path="/account/group-tab-history/:groupTabId"
                                            component={GroupTabHistoryPage}
                                        />
                                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                        <Route exact path="/software_licenses" component={LicensePage} />
                                        <Route
                                            exact
                                            path="/onboarding/remove-payment-method/:token"
                                            render={(routeComponentProps) => (
                                                <ProfilePageWrapperContainer
                                                    customBack="on-boarding-remove-payment"
                                                    renderPage={() => <RemovePaymentMethod />}
                                                    {...routeComponentProps}
                                                />
                                            )}
                                        />
                                        <Route exact path="/terms-of-use" component={TermsOfUse} />
                                        <Route exact path="/contact-us" component={ContactUs} />
                                        <Route exact path="/cookie-policy" component={CookiePolicy} />
                                        <Redirect to={this.getRedirectTo()} />
                                    </Switch>
                                </PageTransition>
                            )}
                        />
                    </MemoryRouter>
                </div>
            </CSSTransition>
        );
    }
}
