import { AppMiddleware } from "src/features";
import { Dispatch } from "redux";
import { PartyAction, TypeKeys } from "../../order/reducers/party";
import { connectedActions, fetchActions, manageMembershipsActions, signupPromotionActions } from "../reducers";
import { getParty } from "../../order";
import { actionCreators as membershipLoginActions } from "../reducers/login";
import { auth, createVerifiedAccountActions } from "src/features/signup";
import { getAccountPageDetails } from "src/features/signup/selectors";
import { getConnectedMembershipState, getCurrentMembershipLevelId } from "../selectors/getConnectedMembershipState";
import { ConnectActiveMembershipSuccessAction, TypeKeys as MembershipTypeKeys } from "../reducers/active";
import {
    SelectPartyMealHistory,
    TypeKeys as MealHistoryTypeKeys,
} from "src/features/orderHistoryData/reducers/mealHistory";
import { getMembershipSignupPromotion } from "src/features/membership/selectors/getMembershipSignupPromotion";

export const membershipMiddleware = () => (store: AppMiddleware) => (next: Dispatch) => {
    return (action: PartyAction | ConnectActiveMembershipSuccessAction | SelectPartyMealHistory) => {
        let prevConnectedMembership;
        if (action.type === TypeKeys.SWITCHED || action.type === TypeKeys.UPDATED) {
            const state = store.getState();
            const party = getParty(state);
            const currentMembershipLevelId = getCurrentMembershipLevelId(state, party);
            const membershipLevelId = getCurrentMembershipLevelId(state, action.party);

            prevConnectedMembership = getConnectedMembershipState(state);

            if (
                membershipLevelId &&
                (membershipLevelId !== currentMembershipLevelId || party?.id !== action.party.id)
            ) {
                next(connectedActions.showConnectedConfirmation());
            }
        }

        if (action.type === TypeKeys.CLOSED || action.type === TypeKeys.LEFT) {
            next(connectedActions.hideConnectedConfirmation());
            next(fetchActions.reset());
            next(manageMembershipsActions.showManageMemberships(false));
            next(manageMembershipsActions.setSignupProgramId());
        }

        next(action);

        if (
            action.type === TypeKeys.SWITCHED ||
            action.type === TypeKeys.UPDATED ||
            action.type === MembershipTypeKeys.CONNECTED
        ) {
            const state = store.getState();
            const pageDetails = getAccountPageDetails(state);
            const connectedMembership = getConnectedMembershipState(state);

            if (!connectedMembership) {
                next(connectedActions.hideConnectedConfirmation());
                return;
            }

            if (prevConnectedMembership?.programId !== connectedMembership.programId) {
                store.dispatch(membershipLoginActions.trackLoginSucceeded(connectedMembership.programId));
                next(connectedActions.showConnectedConfirmation());
            }

            if (!auth.getIsAnonymous() && pageDetails?.flowName === "venue_membership") {
                next(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
            }
        }

        if (action.type === MealHistoryTypeKeys.SELECT_PARTY_MEAL_HISTORY && action.partyId === null) {
            const membershipSignupPromotion = getMembershipSignupPromotion(store.getState());
            if (membershipSignupPromotion) {
                next(signupPromotionActions.setSignupPromotion(undefined));
            }
        }
    };
};
