import { createSelector } from "reselect";
import { AppState } from "src/features";
import { HistoryListItem } from "src/features/orderHistoryData";
import { GroupTabHistoryView } from "..";
import { getGroupTabHistoryDataOperation } from "../operations";

export const getGroupTabHistory = (state: AppState) => state.groupTabHistoryData;
export const getGroupTabHistories = (state: AppState) => state.groupTabHistoryData.groupTabHistories;
export const getSelectedTabHistoryData = (state: AppState) => state.groupTabHistoryData.selectedTabOrderHistoryData;
export const getGroupTabHistoriesLoading = (state: AppState) => state.groupTabHistoryData.loading;
export const getGroupTabHistoriesHasMore = (state: AppState) => state.groupTabHistoryData.hasMore;
export const fetchGroupTabDetailsStatus = (state: AppState) => getGroupTabHistoryDataOperation.getStatus(state);

export const showGroupTabHistoryModal = createSelector(
    getGroupTabHistory,
    (state) => state.shouldOpen && state.selectedGroupTabId
);

export const getSelectedGroupTabHistory = createSelector(getGroupTabHistory, (state) =>
    state.selectedGroupTabId
        ? state.groupTabHistories.find((history) => history.groupTabId === state.selectedGroupTabId)
        : undefined
);

export const getGroupTabHistoryById = (groupTabId?: string) =>
    createSelector(getGroupTabHistory, (state) =>
        groupTabId ? state.groupTabHistories.find((history) => history.groupTabId === groupTabId) : undefined
    );

export const getGroupTabHistoryForCards = createSelector(getGroupTabHistories, (groupTabsHistoryData) => {
    if (!groupTabsHistoryData) return [] as GroupTabHistoryView[];

    const aggregatedHistoryItems = [] as GroupTabHistoryView[];

    groupTabsHistoryData.forEach((history) => {
        const lastItem = aggregatedHistoryItems[aggregatedHistoryItems.length - 1];
        if (
            lastItem?.locationName === history.locationName &&
            lastItem.posixClosedDate < history.posixClosedDate + 10 * 60 * 60
        ) {
            lastItem.relatedItems.push(history);
        } else {
            aggregatedHistoryItems.push({
                ...history,
                relatedItems: [],
            });
        }
    });

    return aggregatedHistoryItems.map(
        (item) =>
            ({
                ...item,
                itemId: item.groupTabId,
                relatedItems: item.relatedItems.map(
                    (subItem) =>
                        ({
                            ...subItem,
                            itemId: (subItem as GroupTabHistoryView).groupTabId,
                        } as HistoryListItem)
                ),
            } as HistoryListItem)
    );
});

export const getGroupTabHistoryLastServiceDate = createSelector(
    getGroupTabHistoryForCards,
    (aggregatedHistoryListItems) => {
        let aggregatedHistoryItems = aggregatedHistoryListItems as GroupTabHistoryView[];
        const lastItem = aggregatedHistoryItems[aggregatedHistoryItems.length - 1];

        if (!lastItem) return null;

        if (lastItem.relatedItems.length) {
            return (lastItem.relatedItems[lastItem.relatedItems.length - 1] as GroupTabHistoryView).dateClosed;
        }

        return lastItem.dateClosed;
    }
);
