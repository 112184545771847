import "./OrderHoldingModal.scss";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animate, Button, PulseLoader, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import Countdown, { zeroPad } from "react-countdown";
import { OpenTableMemberStatus } from "../types";
import { setOrderMemberAccepted } from "../../order/actions/setOrderMemberAccepted";
import { getOrderAcceptedMembers, getOrderDateToSubmit, getTableLabel } from "../../order/selectors";
import { ModalContainer } from "src/common/modal";
import { getOtherPartyMembersWithStatus } from "../selectors/memberStatus";
import { getCurrentMemberId } from "../../accounts/selectors";
import { usePrevious } from "src/sharedComponents/common/shared";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { resetOpenTableDateToSubmit } from "../actions/resetOpenTableDateToSubmit";
import { submitOpenTableOrder } from "../actions/submitOpenTableOrder";
import { getOpenTableBatchTimeSeconds } from "src/features/menu/selectors";
import { ReloadIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { WaitingForMembers } from "./WaitingForMembers";
import { simplePlural } from "src/common/strings";

enum OrderHoldingButtonActions {
    MORETIME = "More time",
    SKIP = "Skip",
    BACK = "Go back",
}

const delaySeconds = 2;

export const OrderHoldingModal = () => {
    const otherMembers = useSelector(getOtherPartyMembersWithStatus);
    const acceptedMembers = useSelector(getOrderAcceptedMembers);
    const currentMemberId = useSelector(getCurrentMemberId);
    const openTableBatchTimeSeconds = useSelector(getOpenTableBatchTimeSeconds);
    const tableLabel = useSelector(getTableLabel);
    const timeoutRef = useRef<number>();
    const joinedRef = useRef(false);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showModal, setShowModal] = useState(!!acceptedMembers?.includes(currentMemberId));
    const orderDateToSubmit = useSelector(getOrderDateToSubmit);
    const [dateToSubmit, setDateToSubmit] = useState<Date | null>(null);
    const [isResetting, setIsResetting] = useState(false);
    const [isTimerComplete, setIsTimerComplete] = useState(false);

    const dispatch = useDispatch();
    const prevAcceptedMembers = usePrevious(acceptedMembers);

    const trackEvent = useCallback(
        (button_clicked: OrderHoldingButtonActions) => {
            dispatch(
                trackedEvent({
                    type: "PARTY/WAITING_ROOM_CLICKED",
                    button_clicked,
                })
            );
        },
        [dispatch]
    );

    const resetTimer = useCallback(() => {
        setIsResetting(true);
        dispatch(resetOpenTableDateToSubmit(delaySeconds));
        trackEvent(OrderHoldingButtonActions.MORETIME);
    }, [dispatch, trackEvent]);

    const handleConfirmationModalResponse = useCallback(
        (shouldSubmitOrder: boolean) => {
            dispatch(
                trackedEvent({
                    type: "PARTY/WAITING_ROOM_RESPONSE",
                    response: shouldSubmitOrder ? "Continue" : "Cancel",
                })
            );
            setShowConfirmation(false);
            if (shouldSubmitOrder) {
                dispatch(submitOpenTableOrder());
            }
        },
        [dispatch]
    );

    const handleClose = useCallback(() => dispatch(setOrderMemberAccepted(false)), [dispatch]);

    const onBack = useCallback(() => {
        trackEvent(OrderHoldingButtonActions.BACK);
        dispatch(showModalMessage(modalMessages.confirmExitOrderHolding(() => setShowModal(false))));
    }, [dispatch, trackEvent]);

    const onSkip = useCallback(() => {
        trackEvent(OrderHoldingButtonActions.SKIP);
        setShowConfirmation(true);
    }, [trackEvent]);

    const waitingForMembers = useMemo(
        () =>
            otherMembers.filter(
                (m) =>
                    m.derivedMemberStatus === OpenTableMemberStatus.BROWSING ||
                    m.derivedMemberStatus === OpenTableMemberStatus.ORDERING
            ),
        [otherMembers]
    );

    useEffect(() => {
        let timer: number | undefined;
        setDateToSubmit((prevDateToSubmit) => {
            if (!orderDateToSubmit) return null;
            const seconds = (orderDateToSubmit.getTime() - Date.now()) / 1000;
            let batchTimeSeconds = openTableBatchTimeSeconds;
            if (prevDateToSubmit) {
                // We add a delay to the batch time for resets so we get a chance to
                // animate it in the app and still show the full batch time
                batchTimeSeconds += delaySeconds;
                const delay = seconds - openTableBatchTimeSeconds - 0.5; // Leave at least 500ms to see timer start
                if (delay > 0) {
                    setIsResetting(true);
                    timer = window.setTimeout(() => setIsResetting(false), delay * 1000);
                }
            }
            const offset = seconds - batchTimeSeconds;
            if (offset > 0) {
                orderDateToSubmit.setSeconds(orderDateToSubmit.getSeconds() - offset);
            }
            return orderDateToSubmit;
        });
        return () => {
            clearTimeout(timer);
        };
    }, [orderDateToSubmit, openTableBatchTimeSeconds]);

    useEffect(() => {
        const inAcceptedMembers = acceptedMembers?.includes(currentMemberId);
        const inPrevAcceptedMembers = prevAcceptedMembers?.includes(currentMemberId);

        if (inAcceptedMembers && !inPrevAcceptedMembers) setShowModal(true);
        else if (!inAcceptedMembers && inPrevAcceptedMembers) {
            timeoutRef.current = window.setTimeout(() => {
                setShowModal(false);
                setShowConfirmation(false);
                setIsTimerComplete(false);
            }, 1000);
        }
    }, [acceptedMembers, prevAcceptedMembers, currentMemberId]);

    useEffect(() => {
        if (!showModal && joinedRef.current) {
            joinedRef.current = false;
        } else if (showModal && !joinedRef.current) {
            joinedRef.current = true;
            dispatch(
                trackedEvent({
                    type: "PARTY/WAITING_ROOM_JOINED",
                    waitingFor: acceptedMembers?.length ?? 0,
                })
            );
        }
    }, [dispatch, showModal, acceptedMembers]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <>
            <ModalContainer
                isOpen={showModal}
                className={{
                    base: "OrderHolding-modal",
                    afterOpen: "OrderHolding--after-open",
                    beforeClose: "OrderHolding--before-close",
                }}
                bodyOpenClassName="OrderHolding__Body--open"
                overlayClassName="OrderHolding--overlay"
                closeTimeoutMS={250}
                onAfterClose={handleClose}
            >
                <LocationThemeContainer>
                    <div className="order-holding">
                        <header className="order-holding__header">
                            <div className="order-holding__header__text">
                                <Text
                                    preset="g-16"
                                    mode={["block", "extra-bold"]}
                                    value="Your order will be sent in..."
                                />
                                <Text preset="g-12" mode="block" value="Need more time? Press to reset clock" />
                            </div>
                            {dateToSubmit && (
                                <TappableDiv
                                    className="order-holding__timer"
                                    onTap={resetTimer}
                                    disabled={isTimerComplete}
                                >
                                    {isResetting || isTimerComplete ? (
                                        <PulseLoader />
                                    ) : (
                                        <>
                                            <Countdown
                                                date={dateToSubmit}
                                                renderer={({ minutes, seconds }) => (
                                                    <Text preset="g-16" className="order-holding__timer__time">
                                                        {minutes}:{zeroPad(seconds)}
                                                    </Text>
                                                )}
                                                onComplete={() => {
                                                    setIsTimerComplete(true);
                                                    setShowConfirmation(false);
                                                }}
                                            />
                                            <ReloadIcon />
                                        </>
                                    )}
                                </TappableDiv>
                            )}
                        </header>
                        <main>
                            <div className="order-holding__loader">
                                <Animate name="twoDotSpinnerWhite" />
                            </div>
                            <Text preset="title-24" mode="bold" className="order-holding__title">
                                Waiting for {waitingForMembers.length} other{simplePlural(waitingForMembers.length)}..
                            </Text>
                            <Text
                                preset="g-14"
                                className="order-holding__sub-title"
                                value={`To make sure orders from your ${tableLabel} come together, wait for everyone to be ready`}
                            />
                            <WaitingForMembers className="order-holding__members" members={waitingForMembers} />
                        </main>
                        <footer>
                            {waitingForMembers.length > 0 && (
                                <NetworkConnectedButton
                                    mode="solidinverted"
                                    onClick={onSkip}
                                    value="Skip waiting, send order"
                                    disabled={isTimerComplete}
                                    unthemed
                                />
                            )}
                            <NetworkConnectedButton
                                mode="blank"
                                onClick={onBack}
                                value="Edit order"
                                disabled={isTimerComplete}
                            />
                        </footer>
                    </div>
                </LocationThemeContainer>
            </ModalContainer>
            <ModalContainer
                isOpen={showConfirmation}
                className={{
                    base: "order-holding-confirmation-modal",
                    afterOpen: "order-holding-confirmation-modal--after-open",
                    beforeClose: "order-holding-confirmation-modal--before-close",
                }}
                overlayClassName="ReactModal__OrderHoldingConfirmation"
            >
                <LocationThemeContainer>
                    <div className="order-holding-confirmation">
                        <Text
                            mode="bold"
                            preset="title-20"
                            value={`Orders from your ${tableLabel} will not come together if you continue`}
                            className="order-holding-confirmation__title"
                        />
                        <Button mode="outline" onClick={() => handleConfirmationModalResponse(false)} value="Cancel" />
                        <NetworkConnectedButton
                            mode="solid"
                            onClick={() => handleConfirmationModalResponse(true)}
                            value="Continue"
                        />
                    </div>
                </LocationThemeContainer>
            </ModalContainer>
        </>
    );
};
