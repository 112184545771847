import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { NativeBackButton, getNavBarStatus } from "src/common/navigation";
import { NavBarType } from "src/common/navigation/types";
import { GroupTabsInformationPage } from "./GroupTabsInformationPage";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { getIsGroupTabOpen } from "../selectors/activeGroupTab";
import { TabOverview } from "./TabOverview";
import { NewGroupTabWizard } from "./NewGroupTabWizard";

export const GroupTabsView = () => {
    const navBarStatus = useSelector(getNavBarStatus);
    const isGroupTab = navBarStatus === NavBarType.GROUP_TABS;
    const activeGroupTabOpen = useSelector(getIsGroupTabOpen);
    const dispatch = useDispatch();
    const closeGroupTab = useCallback(() => {
        window.setTimeout(() => dispatch(navBarActionCreators.activeType(NavBarType.MENU)), 100);
    }, [dispatch]);

    return (
        <>
            <CSSTransition
                in={isGroupTab && !activeGroupTabOpen}
                classNames="party-wrapper-transition"
                timeout={150}
                unmountOnExit
            >
                <NativeBackButton name="group-tab-view" onPressed={closeGroupTab}>
                    <GroupTabsInformationPage />
                </NativeBackButton>
            </CSSTransition>
            <CSSTransition
                in={isGroupTab && activeGroupTabOpen}
                classNames="party-wrapper-transition"
                timeout={150}
                unmountOnExit
            >
                <NativeBackButton name="group-tab-view" onPressed={closeGroupTab}>
                    <TabOverview />
                </NativeBackButton>
            </CSSTransition>
            {!activeGroupTabOpen && <NewGroupTabWizard />}
        </>
    );
};
