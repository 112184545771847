import { FeatureConfiguration } from "../index";
import { qrReducer, onboardingReducer } from "./reducers";
import { onboardingMiddleware } from "./middleware/onboardingMiddleware";

export { proxy as orderApiProxy } from "./orderApi/proxy";
export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.qr = qrReducer;
    config.reducers.onboarding = onboardingReducer;
    config.middleware.push(onboardingMiddleware());
}
