import React, { PropsWithChildren } from "react";
import SVG from "react-inlinesvg";
import { OfferData } from "../types";
import { Text } from "src/sharedComponents";
import { StampCard } from "./StampCard";

interface Props {
    offer: OfferData;
    titleText: string;
    subtitleText?: string;
    onStampCardClick: () => void;
}

export const StampCardLandingContent = ({
    offer,
    titleText,
    subtitleText,
    onStampCardClick,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <div className="stamp-card-claim-page__content">
            <div className="stamp-card-claim-page__middle-section">
                {offer.offerDetails?.offerLogoUrl && (
                    <div className="stamp-card-claim-page__image-header">
                        <SVG src={offer.offerDetails.offerLogoUrl} />
                    </div>
                )}
                <section className="stamp-card-claim-page__text-section">
                    <Text preset="title-24" mode={["extra-bold", "block"]}>
                        {titleText}
                    </Text>
                    {offer.subtitle && (
                        <Text preset="g-14" className="stamp-card-claim-page__subtitle">
                            {subtitleText}
                        </Text>
                    )}
                </section>
                {offer.stampCard && (
                    <StampCard
                        stampCard={offer.stampCard}
                        title={offer.title}
                        subtitle={offer.subtitle}
                        onClick={onStampCardClick}
                        status={offer.status}
                        showActions
                    />
                )}
            </div>
            {children}
        </div>
    );
};
