import "../assets/WaitTimeBadge.scss";

import * as React from "react";
import { Text } from "src/sharedComponents";
import { TimingIcon } from "../../../sharedComponents/assets/icons";
import classNames from "classnames";

interface Props {
    waitTime: number | undefined;
    subtle?: boolean;
}

export const WaitTimeBadge = ({ waitTime, subtle }: Props) =>
    waitTime ? (
        <Text preset="g-12" mode="medium" className={classNames("wait-time-badge", subtle && "subtle")}>
            <TimingIcon />
            {waitTime}m wait
        </Text>
    ) : null;
