import { OperationAction, OperationsTypeKeys } from "../../operations";
import { AppDispatch, AppMiddleware } from "../../index";
import { debounce } from "lodash";
import { actionCreators } from "../reducers/spinner";
import { getOperationShowsSpinner } from "../../operations/operation";

export const spinnerMiddleware = () => (store: AppMiddleware) => {
    const runningOperations: string[] = [];
    const runningOperationsToRemove: string[] = [];

    const stopOperationActions = [OperationsTypeKeys.COMPLETED, OperationsTypeKeys.FAILED, OperationsTypeKeys.RESET];

    const tryRemoveOperation = (operations: string[], operation: string) => {
        const index = operations.indexOf(operation);
        if (index === -1) return false;
        operations.splice(index, 1);
        return true;
    };

    const tryAddOperation = (operations: string[], operation: string) => {
        const index = operations.indexOf(operation);
        if (index >= 0) return false;
        operations.push(operation);
        return true;
    };

    const addRunningOperation = (operation: string) => {
        if (tryRemoveOperation(runningOperationsToRemove, operation)) {
            // If the operation is in the to-remove list
            // then just remove it from that list
            return;
        }
        if (!tryAddOperation(runningOperations, operation)) {
            // If the operation is already running then don't do anything
            // this may be the case for operations that run in parallel
            return;
        }
        if (!store.getState().spinner.isOperationRunning) {
            store.dispatch(actionCreators.setIsOperationRunning(true));
        }
    };

    const removeRunningOperations = debounce(() => {
        let operation: string | undefined;
        while ((operation = runningOperationsToRemove.shift())) {
            tryRemoveOperation(runningOperations, operation);
        }
        if (!runningOperations.length) {
            store.dispatch(actionCreators.setIsOperationRunning(false));
        }
    }, 250);

    return (next: AppDispatch) => (action: OperationAction) => {
        next(action);

        if (action.type === OperationsTypeKeys.BEGIN && getOperationShowsSpinner(action.operation)) {
            addRunningOperation(action.operation);
        }

        if (stopOperationActions.includes(action.type) && getOperationShowsSpinner(action.operation)) {
            runningOperationsToRemove.push(action.operation);
            removeRunningOperations();
        }
    };
};
