import { marketingMetaData, showMarketingPage, resetMarketing } from "./displayMarketing";
import { sendOptIn, logMarketingView } from "./sendOptIn";
import { getLatestTermsUpdate, acceptTermsUpdate } from "./termsUpdate";

export const actions = {
    marketingMetaData,
    showMarketingPage,
    resetMarketing,
    sendOptIn,
    logMarketingView,
    getLatestTermsUpdate,
    acceptTermsUpdate,
};
