import { trackedEvent } from "../../common/events/reduxEventTracking";
import { UrlLinkData } from "../../common/experience/interface";

export interface State {
    deepLink: DeepLinkState;
}

export interface DeepLinkState {
    isLoaded: boolean;
    data?: UrlLinkData;
    reason?: string;
}

export enum TypeKeys {
    CANCELLING = "DEEPLINK/CANCELLING",
    CANCELLED = "DEEPLINK/CANCELLED",
    LOADING = "DEEPLINK/LOADING",
    LOADED = "DEEPLINK/LOADED",
}

export const actionCreators = {
    cancelling: (reason: string) => ({ type: TypeKeys.CANCELLING, reason }),
    cancelled: (reason: string) => ({ type: TypeKeys.CANCELLED, reason }),
    loading: (data: UrlLinkData) => ({ type: TypeKeys.LOADING, data }),
    loaded: (url: string, urlType: string, source?: string) =>
        trackedEvent({ type: TypeKeys.LOADED, url, urlType, source }),
};

type DeeplinkCancellingAction = { type: TypeKeys.CANCELLING; reason: string };
type DeeplinkCancelledAction = { type: TypeKeys.CANCELLED };
type DeeplinkLoadingAction = { type: TypeKeys.LOADING; data: UrlLinkData };
type DeeplinkLoadedAction = { type: TypeKeys.LOADED; url: string; urlType: string; source?: string };

export type DeeplinkAction =
    | DeeplinkCancellingAction
    | DeeplinkCancelledAction
    | DeeplinkLoadingAction
    | DeeplinkLoadedAction;

const initialState: DeepLinkState = {
    isLoaded: false,
};

export function reducer(state: DeepLinkState = initialState, action: DeeplinkAction): DeepLinkState {
    if (action.type === TypeKeys.CANCELLED) {
        return {
            isLoaded: true,
        };
    }
    if (action.type === TypeKeys.LOADED) {
        return {
            ...state,
            isLoaded: true,
        };
    }
    if (action.type === TypeKeys.LOADING) {
        return {
            ...state,
            data: action.data,
            isLoaded: false,
        };
    }
    if (action.type === TypeKeys.CANCELLING) {
        return {
            isLoaded: state.isLoaded,
            reason: action.reason,
        };
    }

    return state;
}
