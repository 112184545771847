import * as React from "react";
import { NavBarType } from "../types";
import { MenuNavIcon, ProfileNavIcon, TabIcon, CreditCardIcon, PresentIcon } from "src/sharedComponents/assets/icons";

export const NavBarIcon = (type: NavBarType) => {
    switch (type) {
        case NavBarType.MENU:
            return <MenuNavIcon />;
        case NavBarType.OFFERS:
            return <PresentIcon />;
        case NavBarType.PROFILE:
            return <ProfileNavIcon />;
        case NavBarType.GROUP_TABS:
            return <TabIcon />;
        case NavBarType.PAY:
            return <CreditCardIcon />;
        default:
            return null;
    }
};
