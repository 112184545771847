import * as React from "react";
import { Fragment } from "react";
import { Indexed, MenuItemModifier } from "../../menudata";
import classNames from "classnames";
import "../assets/OrderModifiers.scss";
import { ModifierCheckboxList } from "./ModifierCheckboxList";
import { ModifierRadioList } from "./ModifierRadioList";
import { ModifierMultiSelectList } from "./ModifierMultiSelectList";
import { OrderItemModifier, OrderItemOptionNestedModifier, OrderItemSelectedNestedModiferDisplayData } from "../types";
import { Divider, Text } from "src/sharedComponents";
import { getModifierRule } from "../helpers";
import { getOptionItemReferenceNotInActiveService } from "src/features/menuitem/helpers";
import { MenuItemExpandableContent } from "src/features/menuitem/components/MenuItemExpandableContent";
import { MenuItemExpandableHeader } from "src/features/menuitem/components/MenuItemExpandableHeader";
import { WithListTrackingProps } from "src/features/order/component/withListTracking";

export interface Props {
    modifiers: MenuItemModifier[];
    selectedModifiers: OrderItemModifier[] | null;
    onModifierOptionSelected: (modifierId: number, optionOriginalIndex: number, selected: boolean) => void;
    triedToSubmit: boolean;
    visibleModifiers?: number[];
    onModifierMultiSelectChanged: (modifierId: number, optionIndex: number, isAdded: boolean) => void;
    onNestedModifierEdit?: (modifierId: number, optionIndex: number) => void;
    activeServiceMenuItemIds: Indexed<boolean>;
    canCollapse?: boolean;
    nestingLevel?: number;
}

interface State {
    expandedModifiers: boolean[];
}

interface ModifierListProps extends WithListTrackingProps {
    modifier: MenuItemModifier;
    modifierId: number;
    selectedOptions: number[] | null;
    onNestedModifierEdit?: (modifierId: number, optionIndex: number) => void;
    selectedNestedModifier?: OrderItemSelectedNestedModiferDisplayData[][];
    canHideItems?: boolean;
    nestingLevel?: number;
}

export interface ModifierListSingleSelectProps extends ModifierListProps {
    onModifierOptionSelected: (modifierId: number, optionOriginalIndex: number, selected: boolean) => void;
}

export interface ModifierListMultiSelectProps extends ModifierListProps {
    onModifierMultiSelectChanged: (modifierId: number, optionIndex: number, isAdded: boolean) => void;
}

export class OrderModifiers extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expandedModifiers: props.modifiers.map(
                (_, index) => !!props.selectedModifiers?.some((m) => m.modifier === index)
            ),
        };
    }

    shouldRenderModifier = (index: number) => {
        const options = this.props.modifiers[index].options;
        const { activeServiceMenuItemIds } = this.props;

        const numUnavailableInServiceOptions = options.filter((option) => {
            return getOptionItemReferenceNotInActiveService(option, activeServiceMenuItemIds);
        }).length;

        return (
            options.length > 0 &&
            numUnavailableInServiceOptions < options.length &&
            (!this.props.visibleModifiers || this.props.visibleModifiers.indexOf(index) >= 0)
        );
    };

    render() {
        const { modifiers } = this.props;

        return (
            <div className="modifiers">
                {modifiers.map((modifier, index) =>
                    !!modifier.minSelection ? this.renderModifier(modifier, index) : null
                )}
                {modifiers.map((modifier, index) =>
                    !modifier.minSelection ? this.renderModifier(modifier, index) : null
                )}
            </div>
        );
    }

    toggleModifierExpanded = (modifier: MenuItemModifier, index: number) => {
        const { expandedModifiers } = this.state;
        if (!this.canCollapseModifier(modifier, index)) return;
        expandedModifiers[index] = !expandedModifiers[index];
        this.setState({ expandedModifiers: [...expandedModifiers] });
    };

    canCollapseModifier = (modifier: MenuItemModifier, index: number) => {
        const { selectedModifiers } = this.props;
        const { expandedModifiers } = this.state;
        return (
            !expandedModifiers[index] ||
            (!selectedModifiers?.some((m) => m.modifier === index) && !modifier.minSelection)
        );
    };

    renderModifier = (modifier: MenuItemModifier, index: number) => {
        if (!this.shouldRenderModifier(index)) return null;
        const { selectedModifiers, triedToSubmit, canCollapse } = this.props;
        const { expandedModifiers } = this.state;
        const disabled = modifier.available === false;
        const isRequired = !!modifier.minSelection;
        const canSelectMoreOptions =
            selectedModifiers === null ||
            !selectedModifiers.some((m) => m.modifier === index && m.options.length >= modifier.minSelection);
        const modifierRule = getModifierRule(modifier);

        const collapsible = modifier.collapsible && canCollapse;
        const error = triedToSubmit && isRequired && canSelectMoreOptions;
        const expanded = expandedModifiers[index];

        return (
            <Fragment key={index}>
                <div className={classNames("modifiers__header", { "modifiers__header--disabled": disabled })}>
                    <div className="l">
                        <Text
                            preset="g-12"
                            className={classNames("select-mode", {
                                error,
                                "available-selections": canSelectMoreOptions,
                            })}
                        >
                            {modifierRule}
                        </Text>
                        {collapsible ? (
                            <MenuItemExpandableHeader
                                onClick={() => this.toggleModifierExpanded(modifier, index)}
                                title={modifier.displayName}
                                open={expanded || error}
                                canClose={this.canCollapseModifier(modifier, index)}
                            />
                        ) : (
                            <Text preset="title-20" mode="extra-bold" value={modifier.displayName} />
                        )}
                    </div>
                </div>
                <div className={classNames(triedToSubmit && "tried-to-submit")}>
                    {collapsible ? (
                        <MenuItemExpandableContent show={expanded} error={error}>
                            {this.renderModifierOptions(modifier, index)}
                        </MenuItemExpandableContent>
                    ) : (
                        this.renderModifierOptions(modifier, index)
                    )}
                </div>
                <Divider />
            </Fragment>
        );
    };

    getSelectedNestedModifierDataRecursive = (
        optionNestedModifier: OrderItemOptionNestedModifier,
        nestingLevel: number = 0
    ) => {
        const selectedNestedModifierData: OrderItemSelectedNestedModiferDisplayData[] = [];

        optionNestedModifier.selectedNestedData?.forEach((data) => {
            selectedNestedModifierData.push({
                ...data,
                nestingLevel,
            });
            if (!data.displayName) return;
            optionNestedModifier.modifiers?.forEach((modifier) => {
                modifier.options?.forEach((optionIndex) => {
                    if (modifier.modifierId !== data.modifierId || optionIndex !== data.optionIndex) return;
                    const nestedOptionNestedModifier = modifier.optionNestedModifiers?.find(
                        (onm) => onm.optionIndex === optionIndex
                    );
                    if (!nestedOptionNestedModifier) return;
                    const nestedSelectedNestedModifierData = this.getSelectedNestedModifierDataRecursive(
                        nestedOptionNestedModifier,
                        nestingLevel + 1
                    );
                    if (!nestedSelectedNestedModifierData) return;
                    selectedNestedModifierData.push(...nestedSelectedNestedModifierData);
                });
            });
        });

        return selectedNestedModifierData.length ? selectedNestedModifierData : undefined;
    };

    renderModifierOptions = (modifier: MenuItemModifier, index: number) => {
        const {
            onModifierOptionSelected,
            selectedModifiers,
            triedToSubmit,
            onModifierMultiSelectChanged,
            onNestedModifierEdit,
            canCollapse,
            nestingLevel,
        } = this.props;

        const collapsible = modifier.collapsible && canCollapse;

        let modifierSelectedValues = null;
        let selectedNestedModifier: OrderItemSelectedNestedModiferDisplayData[][] | undefined;

        if (selectedModifiers) {
            const selectedModifier = selectedModifiers.find((m) => m.modifier === index);
            if (selectedModifier) {
                modifierSelectedValues = selectedModifier.options;
                selectedNestedModifier = selectedModifier.optionNestedModifiers?.reduce((s, onm) => {
                    const selectedNestedData = this.getSelectedNestedModifierDataRecursive(onm);
                    if (selectedNestedData) {
                        s[onm.optionIndex] = selectedNestedData;
                    }
                    return s;
                }, [] as OrderItemSelectedNestedModiferDisplayData[][]);

                if (!selectedNestedModifier?.length) {
                    selectedNestedModifier = undefined;
                }
            }
        }

        if (modifier.maxSelection === 1 && modifier.options.length > 1) {
            return (
                <ModifierRadioList
                    modifier={modifier}
                    modifierId={index}
                    selectedOptions={modifierSelectedValues}
                    onModifierOptionSelected={onModifierOptionSelected}
                    triedToSubmit={triedToSubmit}
                    selectedNestedModifier={selectedNestedModifier}
                    onNestedModifierEdit={onNestedModifierEdit}
                    canHideItems={!collapsible}
                    nestingLevel={nestingLevel}
                />
            );
        }

        const hasMultiSelectOptions = (modifier?.maxSelectionPerOption || 0) > 1;

        if (hasMultiSelectOptions && modifier.maxSelection !== 1) {
            return (
                <ModifierMultiSelectList
                    modifier={modifier}
                    modifierId={index}
                    selectedOptions={modifierSelectedValues}
                    onModifierMultiSelectChanged={onModifierMultiSelectChanged}
                    onNestedModifierEdit={onNestedModifierEdit}
                    selectedNestedModifier={selectedNestedModifier}
                    canHideItems={!collapsible}
                    nestingLevel={nestingLevel}
                />
            );
        }

        return (
            <ModifierCheckboxList
                modifier={modifier}
                modifierId={index}
                selectedOptions={modifierSelectedValues}
                onModifierOptionSelected={onModifierOptionSelected}
                selectedNestedModifier={selectedNestedModifier}
                onNestedModifierEdit={onNestedModifierEdit}
                canHideItems={!collapsible}
                nestingLevel={nestingLevel}
            />
        );
    };
}
