import "./RefreshButton.scss";

import React from "react";
import classNames from "classnames";
import { TappableButton } from "../../common/tappable";
import { Text } from "..";
import { ReloadIcon } from "../../assets/icons";

export interface RefreshButtonProps {
    show: boolean;
    onClick: () => void;
    text: string | React.ReactNode;
    mode?: "dark" | "light";
}

export const RefreshButton = ({ show, onClick, text, mode = "dark" }: RefreshButtonProps) => (
    <div className={classNames("refresh-button__wrapper", show && "show")}>
        <TappableButton className={classNames("refresh-button__button", mode)} onClick={onClick}>
            <Text preset="g-14" mode="medium">
                <ReloadIcon /> {text}
            </Text>
        </TappableButton>
    </div>
);
