import { cordovaWindow } from "../../cordova";
import { ApplePayPaymentMethod } from "../../../../payment";
import { CompleteDevicePaymentMethodPaymentFunc } from "../../../interface";

export const completeStripeApplePayPayment: CompleteDevicePaymentMethodPaymentFunc<ApplePayPaymentMethod> = (
    _,
    clientSecret
) =>
    new Promise<void>((resolve, reject) =>
        cordovaWindow.StripeDevicePayments.completeApplePayPayment(clientSecret ?? null, resolve, reject)
    );
