import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { getAuthHeaders } from "../../../common/auth";
import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { PaymentMethod } from "../../../common/payment";

type PaymentMethodTypes = "vaulted" | "device";

export const fetchPaymentMethods = async (type: PaymentMethodTypes, inParty: boolean) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    const response = await orderApi.send(
        `/paymentgateway/${type}paymentmethods`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as PaymentMethod[];
};
