import * as React from "react";

export class StandaloneRestrictions extends React.Component {
    constructor(props: {}) {
        super(props);

        if (isStandalone()) {
            window.addEventListener("contextmenu", (e) => e.preventDefault());
        }
    }

    render() {
        return this.props.children;
    }
}

function isStandalone() {
    return "matchMedia" in window && window.matchMedia("(display-mode: standalone)").matches;
}
