import "./TabOverviewHeader.scss";

import React, { useContext } from "react";
import { HeaderBackgroundImage } from "../../../sharedComponents/controls/headerBackgroundImage";
import { useSelector } from "react-redux";
import { getIsGroupTabOwner, getGroupTabData, getTabTypeName, getTabSpend } from "../selectors/activeGroupTab";
import { Text } from "../../../sharedComponents";
import { getLocationHeroImage } from "../../menu/selectors";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";

interface Props {
    heroImage?: string;
}

export const TabOverviewHeader = ({ heroImage }: Props) => {
    const isGroupTabOwner = useSelector(getIsGroupTabOwner);
    const activeTab = useSelector(getGroupTabData);
    const locationHeroImage = useSelector(getLocationHeroImage);
    const menuDataLocale = useContext(MenuDataLocaleContext)!;
    const tabTypeName = useSelector(getTabTypeName);
    const tabSpend = useSelector(getTabSpend);

    if (!activeTab) return null;

    return (
        <HeaderBackgroundImage
            className="tab-overview-header"
            url={heroImage || locationHeroImage}
            imagePercent={0.2}
            backgroundGradientDecimal={0.7}
        >
            <div className="tab-overview-header__chip">
                <Text preset="g-12" mode={["medium", "block"]}>
                    {isGroupTabOwner ? `YOUR ${tabTypeName.toUpperCase()}` : `${tabTypeName.toUpperCase()} MEMBER`}
                </Text>
            </div>
            <Text
                preset="g-18"
                mode={["medium", "block"]}
                className="tab-overview-header__tab-name"
                value={activeTab.tabName}
            />
            <Text
                className="tab-overview-header__spend"
                key={"tab-spend" + tabSpend}
                preset="title-32"
                mode={["extra-bold", "block"]}
                value={menuDataLocale.formatCurrency(tabSpend)}
            />
            <Text
                preset="g-14"
                mode="block"
                value={isGroupTabOwner ? "spent so far" : `Your spend on this ${tabTypeName}`}
            />
        </HeaderBackgroundImage>
    );
};
