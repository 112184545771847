import { BannerNotification } from "../../../sharedComponents/controls/banner";

export interface BannersState {
    banners: BannerNotification[];
}

export interface State {
    banners: BannersState;
}

enum TypeKeys {
    SHOW_BANNER = "BANNERS/SHOW_BANNER",
    REMOVE_BANNER = "BANNERS/REMOVE_BANNER",
    RESET_BANNERS = "BANNERS/RESET_BANNERS",
}

export const actionCreators = {
    removeBanner: (bannerTitle: string) => ({ type: TypeKeys.REMOVE_BANNER, bannerTitle }),
    showBanner: (banner: BannerNotification, show: boolean) => ({ type: TypeKeys.SHOW_BANNER, banner, show }),
    resetBanners: () => ({ type: TypeKeys.RESET_BANNERS }),
};

export type RemoveBannerAction = { type: TypeKeys.REMOVE_BANNER; bannerTitle: string };
export type ShowBannerAction = { type: TypeKeys.SHOW_BANNER; banner: BannerNotification; show: boolean };
export type ResetBannersAction = { type: TypeKeys.RESET_BANNERS };

export type BannersActions = RemoveBannerAction | ShowBannerAction | ResetBannersAction;

const initialState = {
    banners: [] as BannerNotification[],
};

export function reducer(state: BannersState = initialState, action: BannersActions): BannersState {
    if (action.type === TypeKeys.REMOVE_BANNER) {
        return {
            ...state,
            banners: state.banners.filter((b) => b.title !== action.bannerTitle),
        };
    }

    if (action.type === TypeKeys.SHOW_BANNER) {
        const { show, banner } = action;
        const newBanners = [...state.banners];

        const currentBannerIndex = state.banners.findIndex((b) => b.title === banner.title);
        if (show && currentBannerIndex < 0) {
            return {
                ...state,
                banners: newBanners.concat({ ...banner, show }),
            };
        } else if (!show && currentBannerIndex > -1) {
            newBanners[currentBannerIndex].show = show;
            return {
                ...state,
                banners: newBanners,
            };
        }
    }

    if (action.type === TypeKeys.RESET_BANNERS) {
        return {
            ...state,
            banners: [],
        };
    }

    return state;
}
