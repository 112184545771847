import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";

const cache: { [key: string]: MenuDataLocale | undefined } = {};

export function getMealHistoryLocale(locale: string, currency: string): MenuDataLocale {
    const key = `${locale}|${currency}`;

    if (cache[key] === undefined) {
        cache[key] = new MenuDataLocale(locale, currency);
    }

    return cache[key]!;
}
