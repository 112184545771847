import React, { CSSProperties } from "react";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";
import { Animate, Text } from "src/sharedComponents";
import { OnboardingLoadingImages } from "../../types";
import { isBrandedVenueLoaderEnabled } from "../../util/isBrandedVenueLoaderEnabled";

interface Props {
    logoUrl?: string;
    paused: boolean;
    isPayOnly?: boolean;
    loaderImages?: OnboardingLoadingImages | null;
}

interface State {
    takingLonger: boolean;
}

export class Connecting extends React.Component<Props, State> {
    timer: number | undefined;

    constructor(props: Props) {
        super(props);

        this.state = {
            takingLonger: false,
        };

        if (!props.paused) {
            this.timer = window.setTimeout(() => this.setState({ takingLonger: true }), 10000);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { paused } = this.props;

        if (prevProps.paused && !paused) {
            if (this.timer) {
                window.clearTimeout(this.timer);
            }
            this.timer = window.setTimeout(() => this.setState({ takingLonger: true }), 10000);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
    }

    render() {
        const { takingLonger } = this.state;
        const { isPayOnly, loaderImages } = this.props;

        return (
            <div className="connecting">
                {isBrandedVenueLoaderEnabled() && (
                    <CSSTransition in={!!loaderImages?.backgroundImage} classNames="fade" timeout={300} unmountOnExit>
                        <div
                            className="branded-venue-content-container"
                            style={
                                {
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${loaderImages?.backgroundImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "bottom",
                                } as CSSProperties
                            }
                        >
                            {loaderImages?.logoUrl && (
                                <div className="venue-logo-container">
                                    <SVG src={loaderImages.logoUrl} />
                                </div>
                            )}
                        </div>
                    </CSSTransition>
                )}
                <div className="connecting__header" />
                <div className="connecting__container">
                    <Animate name={isPayOnly ? "logoSpinner" : "logoSpinnerWhite"} />
                    <Text
                        preset="g-16"
                        mode={["bold", "block"]}
                        className="status-text"
                        value={takingLonger ? "This is taking longer than usual" : "Just a sec,"}
                    />
                    <Text
                        preset="g-16"
                        mode="bold"
                        className="status-text"
                        value={
                            takingLonger ? "" : isPayOnly ? "we’re fetching your order" : "we’re grabbing your menu."
                        }
                    />
                </div>
            </div>
        );
    }
}
