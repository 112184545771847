import { FeatureConfiguration } from "..";
import { marketingReducer } from "./reducers";

export * from "./types";

export * from "./containers/MarketingContainer";
export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.marketing = marketingReducer;
}
