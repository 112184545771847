import { orderApi } from "../orderApi";
import { AppDispatch, AppState } from "../..";
import { getOrderHeadCountValue } from "../selectors/orderHeadCount";
import { getIsOrderHeadCountEnabled } from "../selectors/restaurantFlags";
import { patchOrderMetadataOperation } from "../operations";

interface PatchOrderMetadataInput {
    headCount?: number;
}
export const patchOrderMetadata = (dispatch: AppDispatch, getState: () => AppState) => {
    return patchOrderMetadataOperation.invoke(async () => {
        const state = getState();
        const params: PatchOrderMetadataInput = {};
        if (getIsOrderHeadCountEnabled(state)) {
            params.headCount = getOrderHeadCountValue(state);
        }

        if (Object.keys(params).length === 0) {
            return;
        }

        await orderApi.invoke("patchOrderMetadata", params);
    }, dispatch, getState);
};
