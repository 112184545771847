import { DevicePaymentBehavior } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod } from "src/common/payment";
import { getBraintreeApplePayAvailable } from "./getBraintreeApplePayAvailable";
import { initializeBraintreeApplePayButton } from "./initializeBraintreeApplePayButton";
import { getBraintreeGooglePayAvailable } from "./getBraintreeGooglePayAvailable";
import { initializeBraintreeGooglePayButton } from "./initializeBraintreeGooglePayButton";

export const applePayBehavior: DevicePaymentBehavior<ApplePayPaymentMethod> = {
    getAvailable: getBraintreeApplePayAvailable,
    initializeButton: initializeBraintreeApplePayButton,
};

export const googlePayBehavior: DevicePaymentBehavior<GooglePayPaymentMethod> = {
    getAvailable: getBraintreeGooglePayAvailable,
    initializeButton: initializeBraintreeGooglePayButton,
};
