import { device } from "./device";
import { GooglePayPaymentMethod } from "../../payment";
import { AnyAction } from "redux";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";
import { getGooglePayBehavior } from "./paymentGateways/behaviors";

export const googlePay = {
    isAvailable: async (googlePayPaymentMethod: GooglePayPaymentMethod, trackEvent: (action: AnyAction) => void) => {
        if (!device.isAndroid) return false;

        const available = await getGooglePayBehavior(googlePayPaymentMethod)?.getAvailable(
            googlePayPaymentMethod,
            trackEvent
        );

        return !!available;
    },
    initializeButton: (
        googlePayPaymentMethod: GooglePayPaymentMethod,
        total: number,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        getGooglePayBehavior(googlePayPaymentMethod)!.initializeButton(
            googlePayPaymentMethod,
            total,
            actions,
            cancelPromise
        ),
    vaultPaymentMethod: () => Promise.resolve(null),
    canVaultPaymentMethod: () => false,
    getCustomButtonId: () => null,
};
