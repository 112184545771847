import "../assets/ModifierListItemDetails.scss";

import React from "react";
import classNames from "classnames";
import { UnavailableIcon } from "../../../sharedComponents/assets/icons";
import { ModifierPrice } from "./ModifierPrice";
import { ModifierIndicator } from "./ModifierIndicator";
import { ModifierOption, MenuItemVariant } from "../../menudata";
import { Text } from "../../../sharedComponents";
import { EnergyContent } from "../../menuitem/components/EnergyContent";

interface Props {
    listItem: ModifierOption | MenuItemVariant;
    prefix?: string;
    disabled?: boolean;
}

export const ModifierListItemDetails = ({
    listItem: { displayName, availablePrices, recommended, available, energyContent },
    prefix,
    disabled,
}: Props) => (
    <div className="modifier-list-item-details">
        <Text
            preset="g-14"
            mode={["medium", "block"]}
            value={displayName}
            className={classNames("modifier-list-item-details__name", (disabled || available === false) && "disabled")}
        />
        {available === false && (
            <div className="modifier-list-item-details__unavailable">
                <UnavailableIcon />
                <Text preset="g-12" mode="bold">
                    Unavailable
                </Text>
            </div>
        )}
        {available !== false && <EnergyContent value={energyContent} />}
        {available !== false && availablePrices && (
            <span className="modifier-list-item-details__value">
                <ModifierPrice availablePrices={availablePrices} prefix={prefix} />
            </span>
        )}
        <ModifierIndicator availablePrices={availablePrices} recommended={!!recommended && available !== false} />
    </div>
);
