import * as React from "react";
import "../assets/InfoSection.scss";
import classNames from "classnames";
import { ErrorIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { Divider, Text } from "src/sharedComponents";

interface Props {
    title?: string | JSX.Element;
    description?: string | JSX.Element;
    className?: string;
    showDetailsAction?: boolean;
    showDetails?: boolean;
    emphasize?: boolean;
    error?: boolean | JSX.Element;
    waitForContent?: boolean;
    hideChildrenDivider?: boolean; // temporary attribute, remove when GroupTabs refactor for enableOrderHistoryUpdate is done
    update?: boolean;
    showHeader?: boolean;
}

export interface State {
    showingDetails?: boolean;
    animateMove?: boolean;
    hide?: boolean;
}

export class InfoSection extends React.Component<Props, State> {
    element: React.RefObject<any>;
    collapsibleElement: React.RefObject<any>;
    collapsibleHeight: number = 0;
    collapsibleChangeHeight: number = 0;
    scrollArea?: number;
    cleanAnimationTimeout: number = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            showingDetails: props.showDetails,
        };
        this.element = React.createRef();
        this.collapsibleElement = React.createRef();
    }

    componentDidMount() {
        if (!this.props.waitForContent) {
            this.setCollapsibleHeightAndHide();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.showingDetails && this.state.showingDetails) {
            // We have open the collapsible run animation
            window.setTimeout(() => {
                this.element.current.parentNode.classList.add("collapsible-parent-animation");
                this.element.current.classList.add("opening");
                this.cleanAllSiblings(this.element.current.nextElementSibling, "nextElementSibling");
            }, 16);
        }

        if (prevProps.waitForContent && !this.props.waitForContent) {
            this.setCollapsibleHeightAndHide();
        }
    }

    setCollapsibleHeightAndHide = () => {
        if (this.collapsibleElement.current) {
            this.collapsibleHeight = this.collapsibleElement.current.clientHeight;
        }
        this.setState({
            hide: true,
        });
    };

    moveAllSiblings(nextElement: any, direction: string) {
        let i = 10;
        while (nextElement && --i) {
            nextElement.classList.add("collapsible-animating-down");
            nextElement = nextElement[direction];
        }
    }

    cleanAllSiblings(nextElement: any, direction: string) {
        let i = 10;
        while (nextElement && --i) {
            nextElement.classList.remove("collapsible-animating-down");
            nextElement = nextElement[direction];
        }
    }

    toggleShowingDetails = () => {
        this.element.current.parentNode.style.setProperty("--collapsible-size", this.collapsibleHeight + "px");
        const pos = this.element.current.getBoundingClientRect();
        const parentPos = this.element.current.parentNode.getBoundingClientRect();

        if (this.scrollArea === undefined) {
            this.scrollArea = Math.floor(parentPos.bottom - pos.bottom);
            this.collapsibleChangeHeight = Math.max(this.collapsibleHeight - this.scrollArea, 0);
        }
        const shouldFixScroll = this.collapsibleChangeHeight > 0;
        let scrollMove = 0;
        if (shouldFixScroll) {
            const parent = this.element.current.parentNode.parentNode;
            scrollMove = Math.max(
                this.collapsibleChangeHeight -
                    Math.max(parent.scrollHeight - (parent.clientHeight + parent.scrollTop), 0),
                0
            );
            if (!window.navigator.userAgent.includes("iPhone") && parent.scrollTop && this.scrollArea > 0) {
                // this is to fix andorid margin-bottom
                scrollMove += 14;
            }
        }
        this.element.current.classList.add("collapsible-animation");
        if (this.state.showingDetails) {
            this.element.current.classList.add("opening");
            this.element.current.parentNode.classList.add("collapsible-parent-animation-down");
            window.setTimeout(() => {
                this.element.current.parentNode.style.setProperty("--changeable-collapsible-size", -scrollMove + "px");
                this.element.current.parentNode.classList.add("collapsible-parent-animation");
                this.element.current.classList.remove("opening");
                this.moveAllSiblings(this.element.current.nextElementSibling, "nextElementSibling");
            }, 16);
        } else {
            this.element.current.parentNode.style.setProperty(
                "--changeable-collapsible-size",
                Math.min(this.collapsibleChangeHeight, this.element.current.offsetTop - 60) + "px"
            );
            this.moveAllSiblings(this.element.current.nextElementSibling, "nextElementSibling");
            if (shouldFixScroll) this.element.current.parentNode.classList.add("collapsible-parent-animation-down");
            this.setState({
                showingDetails: true,
            });
        }
        this.cleanAnimationTimeout = window.setTimeout(this.onAnimationEnd, 350);
    };

    onAnimationEnd = () => {
        this.cleanAnimationTimeout && window.clearTimeout(this.cleanAnimationTimeout);
        this.element.current.classList.remove("collapsible-animation");
        this.element.current.parentNode.classList.remove(
            "collapsible-parent-animation",
            "collapsible-parent-animation-down"
        );
        this.element.current.parentNode.style.removeProperty("--collapsible-size");
        this.element.current.parentNode.style.removeProperty("--changeable-collapsible-size");
        this.cleanAllSiblings(this.element.current.nextElementSibling, "nextElementSibling");
        if (this.element.current.classList.contains("opening")) {
            this.element.current.parentNode.parentNode.scrollBy({
                top: Math.min(this.collapsibleChangeHeight, this.element.current.offsetTop - 60),
            });
            this.element.current.classList.remove("opening");
        } else {
            this.scrollArea = undefined;
            this.setState({
                showingDetails: false,
            });
        }
    };

    render() {
        const {
            className,
            error,
            emphasize,
            title,
            description,
            showDetailsAction,
            children,
            hideChildrenDivider,
            update,
            showHeader = true,
        } = this.props;
        const { showingDetails, hide } = this.state;
        return (
            <div
                ref={this.element}
                className={classNames(
                    "info-section",
                    className,
                    error && "info-error",
                    emphasize && "emphasize",
                    update && "info-section--update"
                )}
            >
                {showHeader && (
                    <div className="info-section__header">
                        <Text preset="g-18" mode={["bold", "block"]} className="info-section__header__title">
                            <span className={classNames("info-section__header__title__error", "show-on-error")}>
                                {error === true ? <ErrorIcon /> : error}
                            </span>
                            {title}
                        </Text>
                        {description && (
                            <Text preset="g-14" mode="block" className="info-section__header__description">
                                {description}
                            </Text>
                        )}
                        {showDetailsAction && (
                            <TappableDiv
                                className="info-section__header__show-details"
                                onTap={this.toggleShowingDetails}
                            >
                                <Text preset="g-12" mode="bold">
                                    {showingDetails ? "Hide details" : "Show details"}
                                </Text>
                            </TappableDiv>
                        )}
                    </div>
                )}
                {children && (
                    <div
                        ref={this.collapsibleElement}
                        className={classNames(
                            "info-section__details",
                            !showDetailsAction || showingDetails ? "show" : hide && "hidden"
                        )}
                    >
                        {!hideChildrenDivider && <Divider fullWidth />}
                        {children}
                    </div>
                )}
                <div className={classNames("collapsible-shield")} onTransitionEnd={this.onAnimationEnd}>
                    <div className="collapsible-shield__border" />
                </div>
            </div>
        );
    }
}
