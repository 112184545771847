import { orderApi, reattemptStrategies } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { ActiveGroupTabData } from "..";
import { getAuthHeaders } from "../../../common/auth";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

export interface GetGroupTabOptions {
    inParty: boolean;
    lastUpdated?: string;
    groupTabId?: string;
    locationId?: string;
}

export const getGroupTabApi = async ({ inParty, lastUpdated, groupTabId, locationId }: GetGroupTabOptions) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/grouptabs${groupTabId ? `/${groupTabId}` : ""}${locationId ? `/${locationId}` : ""}${
            lastUpdated ? `/${lastUpdated}` : ""
        }`,
        {
            method: "GET",
            headers,
        },
        lastUpdated || groupTabId ? undefined : reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ActiveGroupTabData;
};
