import { FeatureConfiguration } from "..";
import { previewMiddleware } from "./previewMiddleware";

export { PreviewPageContainer } from "./containers/PreviewPageContainer";

export { isPreview } from "./previewMiddleware";

export default function (config: FeatureConfiguration) {
    config.middleware.push(previewMiddleware(config.history));
}
