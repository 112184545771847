import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { WizardModal } from "src/features/wizards/components/WizardModal";
import { offerClaimFlowWizard } from "../wizard";
import { OfferClaimPageWrapper } from "./OfferClaimPageWrapper";
import { actionCreators } from "../reducers";
import { sendMarketingOptIn } from "src/features/marketing";
import { OfferClaimedInPartyPage } from "./OfferClaimedInPartyPage";
import { getAvailableOffersAction } from "../actions/getAvailableOffers";

export const OfferClaimInPartyWizard = () => {
    const dispatch = useDispatch();

    const reset = useCallback(() => {
        dispatch(offerClaimFlowWizard.actionCreators.done());
        dispatch(actionCreators.setOffer(undefined));
        dispatch(sendMarketingOptIn.actionCreators.reset());
        dispatch(actionCreators.offerClaimResult(undefined));
    }, [dispatch]);

    const onDone = useCallback(() => {
        dispatch(getAvailableOffersAction());
        reset();
    }, [dispatch, reset]);

    return (
        <WizardModal
            wizard={offerClaimFlowWizard}
            transition="slide-up"
            onReset={reset}
            onComplete={onDone}
            onCloseComplete={reset}
            pages={{
                OfferPage: {
                    render: (onSuccess) => <OfferClaimPageWrapper onSuccess={onSuccess} onBack={reset} />,
                    header: null,
                },
                OfferClaimed: {
                    render: () => <OfferClaimedInPartyPage onClose={onDone} />,
                    header: null,
                },
            }}
        />
    );
};
