import { MenuItem, Service } from "../../menudata";
import { RoundItem } from "../../orderAgain/types";
import { FuseMenuItem } from "../../menuSearch/types";
import { addOrUpdateOrderItem } from "src/features/order/actions/addOrUpdateOrderItem";
import { getActiveService, getActiveServiceMenuItemLinkParams, getVisibleMenuData } from "../selectors";
import { AppDispatch, AppState } from "src/features";
import { OrderItemModifier, OrderItemNestedModifier, OrderItemOptionNestedModifier } from "src/features/order";

export function truncateString(string: string, numLetters: number) {
    return string.slice(0, numLetters);
}

export const getItemAvailability = (item: MenuItem | RoundItem | FuseMenuItem) =>
    item.available === false ? "sold_out" : item.waitTime ? `${item.waitTime} min` : undefined;

export const getServiceNameById = (serviceId: string, services: Service[]) =>
    services.find((service) => service.id === serviceId)?.displayName;

export const addAllItemInDemoMode = (menuItem: MenuItem, menuItemId: string) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const itemsToAdd = [];
        const data = getActiveServiceMenuItemLinkParams(state).get(menuItemId);

        if (data) {
            let courseId: string | undefined;
            const categoryId = data.categoryId;
            const menuData = getVisibleMenuData(state)!;

            const category = menuData.categories[categoryId];
            if (category.course) {
                courseId = category.course;
            } else {
                const service = getActiveService(state);
                courseId = service?.defaultCourse;
            }

            const mappedModifiers = menuItem.modifiers?.map((modifier, modifierIndex) => {
                return {
                    modifier: modifierIndex,
                    options: modifier.options.map((_, index) => index),
                    optionNestedModifiers: modifier.options.reduce((nestedModifier, option, optionIndex) => {
                        if (option.nestedModifiers && menuData.modifiers) {
                            const optionNestedMod: OrderItemOptionNestedModifier = {
                                optionIndex,
                                quantity: 1,
                                modifiers: [],
                                selectedNestedData: [],
                            };
                            const modifiers = option.nestedModifiers.map((nestedMod) => ({
                                ...menuData.modifiers![nestedMod],
                                id: nestedMod,
                            }));
                            modifiers.forEach((mod, modifierIndex) => {
                                optionNestedMod.modifiers.push({
                                    modifierId: mod.id,
                                    modifier: modifierIndex,
                                    options: mod.options.map((_, index) => index),
                                } as OrderItemNestedModifier);
                            });
                            nestedModifier.push(optionNestedMod);
                        }
                        return nestedModifier;
                    }, [] as OrderItemOptionNestedModifier[]),
                } as OrderItemModifier;
            });

            if (menuItem.variants?.length) {
                menuItem.variants.forEach((variant, index) => {
                    itemsToAdd.push({
                        courseId,
                        categoryId,
                        menuItemId,
                        variant: index,
                        modifiers: !variant.modifiers?.length
                            ? undefined
                            : mappedModifiers?.filter((_, index) => variant.modifiers?.includes(index)),
                    });
                });
            } else {
                itemsToAdd.push({
                    courseId,
                    categoryId,
                    menuItemId,
                    variant: null,
                    modifiers: mappedModifiers,
                });
            }

            itemsToAdd.forEach((i) => {
                dispatch(
                    addOrUpdateOrderItem(i.courseId!, i.categoryId, i.menuItemId, null, null, i.variant, i.modifiers)
                );
            });
        }
    };
};
