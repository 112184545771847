import React, { FC } from "react";
import { Modal, ModalProps } from "../../sharedComponents";
import { useSelector } from "react-redux";
import { AppState } from "../../features";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";

interface Props extends Omit<ModalProps, "keyboardOpen"> {
    useLocationTheme?: boolean;
}

export const ModalContainer: FC<Props> = (props) => {
    const keyboardOpen = useSelector((state: AppState) => state.nativeState.keyboardOpen);
    const { useLocationTheme, children, ...rest } = props;
    return useLocationTheme ? (
        <Modal {...rest} keyboardOpen={keyboardOpen}>
            <LocationThemeContainer>{children}</LocationThemeContainer>
        </Modal>
    ) : (
        <Modal {...rest} keyboardOpen={keyboardOpen} children={children} />
    );
};
