import "./MembershipSignupPromotion.scss";
import React from "react";
import { Text } from "src/sharedComponents/controls/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { signupPromotionActions } from "..";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { StatusBar } from "src/common/statusBar";
import { ModalContainer } from "src/common/modal";
import {
    getMembershipSignupPromotion,
    getShowMembershipSignupPromotionModal,
} from "src/features/membership/selectors/getMembershipSignupPromotion";
import { Button } from "src/sharedComponents";
import { href } from "src/common/experience";

export const MembershipSignupPromotion = () => {
    const dispatch = useDispatch();

    const signupPromotion = useSelector(getMembershipSignupPromotion);
    const showSignupPromotionModal = useSelector(getShowMembershipSignupPromotionModal);

    if (!signupPromotion) return null;

    const {
        programId,
        programName,
        logoUrl,
        bannerUrl,
        title,
        ctaText,
        ctaUrl,
        benefits,
        termsText,
        termsUrl,
        termsUrlText,
    } = signupPromotion;

    return (
        <ModalContainer
            isOpen={showSignupPromotionModal}
            className={{
                base: "membership-signup-promotion-modal slide-in",
                afterOpen: "membership-signup-promotion-modal--after-open slide-in--after-open",
                beforeClose: "membership-signup-promotion-modal--before-close slide-in--before-close",
            }}
            overlayClassName="membership-signup-promotion-modal--overlay slide-in-modal--overlay keyboard-unfixed"
            bodyOpenClassName="MembershipSignupPromotion-modal__Body--open"
            closeTimeoutMS={250}
        >
            <LocationThemeContainer>
                <StatusBar backgroundColor="#FFFFFF" />
                <SimpleNavHeader
                    onBack={() => dispatch(signupPromotionActions.showSignupPromotionModal(false))}
                    closeToBack
                    customBack="membership-signup-promotion"
                />
                <div className="membership-signup-promotion">
                    <div className="membership-signup-promotion__header">
                        {bannerUrl && (
                            <img className="membership-signup-promotion__banner" src={bannerUrl} alt={programName} />
                        )}
                        {logoUrl && (
                            <img className="membership-signup-promotion__logo" src={logoUrl} alt={programName} />
                        )}
                    </div>
                    <div className="membership-signup-promotion__content">
                        <Text preset="title-24" mode={["bold", "block"]} value={title} />
                        <div className="membership-signup-promotion__benefits">
                            {benefits.map(({ title, text, iconUrl }, i) => (
                                <div key={i} className="membership-signup-promotion__benefit">
                                    {iconUrl && (
                                        <img
                                            className="membership-signup-promotion__benefit__icon"
                                            src={iconUrl}
                                            alt={title}
                                        />
                                    )}
                                    <div className="membership-signup-promotion__benefit__content">
                                        <Text preset="g-14" mode={["bold", "block"]} value={title} />
                                        <Text preset="g-14" mode={["medium", "block"]} value={text} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {(termsText || termsUrl) && (
                            <div className="membership-signup-promotion__terms">
                                {termsText && (
                                    <Text
                                        className="membership-signup-promotion__terms__text"
                                        preset="g-12"
                                        mode={["medium", "block"]}
                                        value={termsText}
                                    />
                                )}
                                {termsUrl && (
                                    <a {...href.openInNewWindowAttributes(termsUrl)}>
                                        <Text
                                            preset="g-12"
                                            mode={["medium", "block"]}
                                            value={termsUrlText ?? termsUrl}
                                        />
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="membership-signup-promotion__footer">
                        <Button mode="solid">
                            <a
                                onClick={() => dispatch(signupPromotionActions.signupPromotionCtaClicked(programId))}
                                {...href.openInNewWindowAttributes(ctaUrl)}
                            >
                                <Text preset="g-16" mode="bold" value={ctaText} />
                            </a>
                        </Button>
                    </div>
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
