import "./TabMembersModal.scss";

import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { Button, Text, CallToActionHeader } from "src/sharedComponents/";
import { useDispatch, useSelector } from "react-redux";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { actionCreators } from "../reducers/activeGroupTab";
import {
    getTabTypeName,
    getShowMembersModal,
    getTabMembersExcludingOwner,
    getActiveTabId,
    getTabType,
} from "../selectors/activeGroupTab";
import { MemberInitials } from "src/features/member/compoenents/MemberInitials";
import { DeleteIcon, JoinIcon } from "src/sharedComponents/assets/icons";
import { removeMemberGroupTabAction } from "../actions/removeMemberAction";
import { GroupTabMember } from "../types";
import { getIsConnected } from "src/features/order/selectors";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { InviteToGroupTab } from "./InviteToGroupTab";
import { ModalContainer } from "../../../common/modal";
import { getTabTypeReadableName } from "../selectors/helpers";

export const TabMembersModal = () => {
    const dispatch = useDispatch();
    const showMembers = useSelector(getShowMembersModal);
    const tabMembers = useSelector(getTabMembersExcludingOwner);
    const isConnected = useSelector(getIsConnected);
    const tabTypeName = useSelector(getTabTypeName);
    const tabType = useSelector(getTabType);
    const tabId = useSelector(getActiveTabId);

    const [show, setShow] = useState(!!showMembers);

    const afterClose = useCallback(() => {
        dispatch(actionCreators.showTabMembers(false));
    }, [dispatch]);

    const closeModal = () => setShow(false);

    const addMembers = useCallback(() => {
        dispatch(actionCreators.showInviteToGroupTab(true));
    }, [dispatch]);

    const removeMember = useCallback(
        (member: GroupTabMember) => {
            dispatch(
                showModalMessage(
                    modalMessages.removeMember(
                        member.firstName || "",
                        member.lastName || "",
                        () => {
                            dispatch(removeMemberGroupTabAction(member.memberId));
                        },
                        tabTypeName
                    )
                )
            );
            tabId && dispatch(actionCreators.trackGroupTabRemoveMemberViewed(tabId, getTabTypeReadableName(tabType)));
        },
        [dispatch, tabTypeName, tabId, tabType]
    );

    useEffect(() => {
        return () => {
            afterClose();
        };
    }, [afterClose]);

    useEffect(() => {
        setShow(!!showMembers);
    }, [showMembers]);

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "TabMembersModal-modal slide-in",
                afterOpen: "TabMembersModal-modal--after-open slide-in--after-open",
                beforeClose: "TabMembersModal-modal--before-close slide-in--before-close",
            }}
            overlayClassName={classNames("TabMembersModal-modal--overlay", "slide-in-modal--overlay")}
            onRequestClose={closeModal}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            onAfterClose={afterClose}
        >
            <LocationThemeContainer>
                <div className="tab-members-page">
                    <SimpleNavHeader closeToBack={true} customBack={"manage-tab-members"} onBack={closeModal} />
                    <div className="tab-members-page__title profile-page__title">
                        <Text preset="title-28" mode="bold">
                            {capitaliseFirstLetter(tabTypeName)} members
                        </Text>
                        <Text preset="g-14" className="tab-members-page__title--description">
                            {capitaliseFirstLetter(tabTypeName)} members can order using your {tabTypeName}. You can add
                            or remove {tabTypeName} members at any time.
                        </Text>
                    </div>
                    <main className="scroll-element">
                        {!!tabMembers?.length ? (
                            <div className="tab-members-page__members">
                                {tabMembers.map((member) => (
                                    <div
                                        key={"manage-member-" + member.memberId}
                                        className="tab-members-page__members__member"
                                    >
                                        <MemberInitials memberInitials={member.initial} />
                                        <Text preset="g-14" className="tab-members-page__members__name">
                                            {member.firstName} {member.lastName}
                                        </Text>
                                        <TappableSpan disabled={!isConnected} onClick={() => removeMember(member)}>
                                            <DeleteIcon />
                                        </TappableSpan>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="tab-members-page__empty">
                                <CallToActionHeader
                                    icon={<JoinIcon />}
                                    title={`Add others to your ${tabTypeName}`}
                                    subtitle={`Invite others to order with you & use your ${tabTypeName} to pay`}
                                    ctaText={`Invite to ${tabTypeName}`}
                                    ctaProps={{ mode: "outline", secondary: true, preset: "default" }}
                                    onClick={addMembers}
                                />
                            </div>
                        )}
                    </main>
                    {!!tabMembers?.length && (
                        <footer>
                            <Button value={`Invite to ${tabTypeName}`} mode="solid" onClick={addMembers} />
                        </footer>
                    )}
                </div>
            </LocationThemeContainer>
            <InviteToGroupTab />
        </ModalContainer>
    );
};
