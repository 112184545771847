import React from "react";
import { Button, Text } from "../../../sharedComponents";

import "../assets/MenuPageFooter.scss";

const menuPageFooterConfig = {
    Food: {
        title: "Looking for something to drink?",
        buttonCopy: "View drinks menu",
    },
    Drinks: {
        title: "Looking for something to eat?",
        buttonCopy: "View food menu",
    },
};

interface OwnProps {
    menuName: string;
    onClick?: () => void;
}

export const MenuPageFooter = ({ menuName, onClick }: OwnProps) => {
    const footerConfig = menuPageFooterConfig[menuName];

    return footerConfig && onClick ? (
        <div className="menu-page-footer">
            <Text className="menu-page-footer__title" preset="g-14">
                {footerConfig.title}
            </Text>
            <Button preset="half-size" mode="outline" onClick={onClick} value={footerConfig.buttonCopy} />
        </div>
    ) : null;
};
