import React, { FC, PropsWithChildren, ReactNode } from "react";

export interface Args {
    [key: string]: string | number | boolean;
}

export const format = (str: string, args: Args) => {
    const keys = Object.keys(args);
    if (!keys.length) return str;

    keys.forEach((key) => {
        str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key].toString());
    });

    return str;
};

export function __(str: string, args?: Args): string;

export function __(strSingle: string, strPlural: string, count: number): string;

export function __(strSingle: string, strPlural: string, args: Args, countArg: keyof typeof args): string;

// Rip off from WordPress but this will eventually let us replace
// the specified string from a lookup table in another language
export function __(str: string, argsOrStrPlural: any, countOrArgs?: any, countArg?: keyof typeof countOrArgs): string {
    if (!argsOrStrPlural) {
        return str;
    }
    if (typeof argsOrStrPlural === "object") {
        const args = argsOrStrPlural; // argsOrStrPlural is args: Args
        return format(str, args);
    }
    const strPlural = argsOrStrPlural; // argsOrStrPlural is strPlural: string
    if (typeof countOrArgs === "number") {
        const count = countOrArgs; // countOrArgs is count: number
        return count > 1 ? strPlural : str;
    }
    const args = countOrArgs; // countOrArgs is args: Args
    if (typeof args[countArg!] === "number" && args[countArg!] > 1) {
        return format(strPlural, args);
    }
    return format(str, args);
}

export interface ReplaceLinkProps {
    link: string;
}

export function replaceLinks(str: string | ReactNode, ReplaceComponent: FC<PropsWithChildren<ReplaceLinkProps>>) {
    if (typeof str !== "string") return str;
    const legalPageRegex = new RegExp("\\{\\{([A-Za-z ]+)\\|([A-Za-z]+)\\}\\}", "g");
    let match = legalPageRegex.exec(str);

    if (!match) return str;

    let lastIndex = 0;
    const textParts: ReactNode[] = [];

    do {
        const [, title, link] = match;
        textParts.push(<span key={textParts.length}>{str.substring(lastIndex, match.index)}</span>);
        textParts.push(
            <ReplaceComponent key={textParts.length} link={link}>
                {title}
            </ReplaceComponent>
        );
        lastIndex = legalPageRegex.lastIndex;
    } while ((match = legalPageRegex.exec(str)) !== null);

    textParts.push(<span key={textParts.length}>{str.substring(lastIndex)}</span>);

    return textParts;
}

export function getInitials(firstName?: string, lastName?: string, fallbackValue?: string) {
    // uses Symbol.iterator to account for utf-16 characters that span 2 indices
    return firstName && lastName
        ? firstName[Symbol.iterator]().next().value + lastName[Symbol.iterator]().next().value
        : fallbackValue;
}

export const simplePlural = (n: number = 0) => {
    return n !== 1 ? "s" : "";
};
