import "../assets/AnotherRoundCarouselItem.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "src/sharedComponents";
import { getIsConnected } from "src/features/order/selectors";
import { actionCreators } from "src/features/notifications/reducers/notifications";
import { QuantitySelector } from "src/sharedComponents/controls/quantitySelector";
import { WaitTimeBadge } from "src/features/menu/components/WaitTimeBadge";
import { EnergyContent } from "src/features/menuitem/components/EnergyContent";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { Price } from "src/features/menu/components/Price";
import { MenuItemImage } from "src/features/menuitem";
import classNames from "classnames";

import { RoundItem } from "../types";

interface Props {
    index: number;
    size?: "md" | "lg";
    quantity?: number;
    roundItem: RoundItem;
    onChange: (roundItem: RoundItem, quantity: number) => void;
}

export function AnotherRoundCarouselItem({ index, size = "md", quantity, roundItem, onChange }: Props) {
    const isConnected = useSelector(getIsConnected);
    const [internalQuantity, setInternalQuantity] = useState(quantity ?? 0);
    const timer = useRef<number | undefined>();
    const trackedRef = useRef(false);
    const dispatch = useDispatch();

    const onChangeQuantity = (newQuantity: number) => {
        setInternalQuantity(newQuantity);
        onChange(roundItem, newQuantity);
    };

    const handleOnIncrement = () => {
        if (!trackedRef.current) {
            dispatch(
                trackedEvent({
                    type: "FLEX/ANOTHER_ROUND_ITEM_ADDED",
                    position: index + 1,
                    hasVariants: roundItem.variant !== null,
                    hasModifiers: roundItem.modifiers !== null,
                    hasNotes: roundItem.notes !== null,
                })
            );
            trackedRef.current = true;
        }

        onChangeQuantity(internalQuantity + 1);
    };

    const handleOnDecrement = () => {
        onChangeQuantity(internalQuantity - 1);
    };

    const handleOnDisabledChange = () => {
        if (!isConnected) dispatch(actionCreators.showNoNetworkConnection(true));
    };

    useEffect(() => {
        timer.current = window.setTimeout(() => {
            setInternalQuantity(quantity ?? 0);
        }, 500);

        return () => {
            window.clearTimeout(timer.current);
        };
    }, [quantity]);

    useEffect(() => {
        window.clearTimeout(timer.current);
    }, [internalQuantity]);

    return (
        <div
            className={classNames("carousel-item", {
                "carousel-item--selected": internalQuantity,
                "carousel-item--lg": size === "lg",
            })}
        >
            <div className="carousel-item__control">
                <div className="carousel-item__control__image">
                    {roundItem.images && <MenuItemImage images={roundItem.images} name="thumb" />}
                </div>
                <div className="carousel-item__control__selector">
                    <QuantitySelector
                        shouldAnimateOnActive
                        size="large"
                        disabled={!isConnected}
                        quantity={internalQuantity}
                        onIncrement={handleOnIncrement}
                        onDecrement={handleOnDecrement}
                        onDisabledIncrement={handleOnDisabledChange}
                        onDisabledDecrement={handleOnDisabledChange}
                    />
                </div>
                <WaitTimeBadge waitTime={roundItem.waitTime} />
            </div>
            <div className="carousel-item__details">
                <Text preset="g-14" mode={["block", "bold"]}>
                    {roundItem.displayName}
                </Text>
                <Text preset="g-14" mode="block">
                    {roundItem.variantName}
                </Text>
                <EnergyContent value={roundItem.energyContent} />
                {roundItem.price && <Price priceTextPreset="g-14" priceTextMode="block" price={roundItem.price} />}
            </div>
        </div>
    );
}
