import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { AddCardFormInner, AddCardFormProps } from "../../../paymentMethods/components/AddCardForm";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { hostedFieldsActionCreators } from "../../../paymentMethods";
import { getHostedFieldsValidity } from "../../../paymentMethods/selectors/hostedFields";
import classNames from "classnames";

const expiryRegex = /^(0[1-9]|1[0-2]|\d)(\s*\/\s*)?(\d{0,2})$/;

export const SpreedlyAddCardForm = (props: AddCardFormProps) => {
    const [expiry, setExpiry] = useState("");

    const dispatch = useDispatch();
    const { expiryInvalid } = useSelector(getHostedFieldsValidity);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setExpiry((prevExpiry) => {
            if (!value) return value;
            const match = expiryRegex.exec(value);
            if (!match) return prevExpiry;
            const [, month, , year] = match;
            if (month === "0" || (month === "1" && !year)) return month;
            return `${("0" + month).slice(-2)}${year || prevExpiry.length < value.length ? " / " : ""}${year}`;
        });
    }, []);

    const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Tab") {
            event.preventDefault();
            event.stopPropagation();
            Spreedly.transferFocus("cvv");
        }
    }, []);

    useEffect(() => {
        if (expiry.length < 7) {
            dispatch(hostedFieldsActionCreators.setValid(HostedField.Expiry, undefined));
        } else {
            const valid = !moment(expiry, "MM / YY").endOf("month").isBefore(moment());
            dispatch(hostedFieldsActionCreators.setValid(HostedField.Expiry, valid));
        }
    }, [expiry, dispatch]);

    return (
        <AddCardFormInner
            expiryElement={
                <input
                    type="tel"
                    id={HostedField.Expiry}
                    value={expiry}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder={hostedFieldOptions.placeholders.expiry}
                    className={classNames({ invalid: expiryInvalid })}
                />
            }
            {...props}
        />
    );
};
