import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { ThreeDSecureInfo } from "../types";
import { getThreeDSecureVerificatonDataOperation } from "../operations";
import { getAuthHeaders } from "../../../common/auth";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { Dispatch } from "redux";

export function fetchThreeDSecureVerificationData<T>(
    dispatch: Dispatch,
    threeDSecureInfo: ThreeDSecureInfo,
    inParty: boolean
) {
    return getThreeDSecureVerificatonDataOperation.invoke(async () => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

        headers.append("Content-Type", "application/json");

        const response = await orderApi.send(
            `/paymentgateway/${threeDSecureInfo.paymentGateway}/threedsecureverificationdata`,
            {
                method: "POST",
                body: JSON.stringify(threeDSecureInfo),
                headers,
            },
            reattemptStrategies.limited
        );

        await ProblemDetailsError.throwError(response);

        return (await response.json()) as T;
    }, dispatch);
}
