import { AuthState, AuthStateChangeReason } from "src/common/auth/auth";
import { trackedEvent } from "src/common/events/reduxEventTracking";

export enum TypeKeys {
    SET_AUTH_STATE = "ACCOUNT/SET_AUTH_STATE",
    DETAILS_INTERACTION_RESPONSE = "ACCOUNT/DETAILS_INTERACTION_RESPONSE",
    PRIVACY_POLICY_CLICKED = "ACCOUNT/PRIVACY_POLICY_CLICKED",
}

export const actionCreators = {
    setAuthState: (authState: AuthState, reason: AuthStateChangeReason | "initial", source: string, prevSource?: string) =>
        trackedEvent({ type: TypeKeys.SET_AUTH_STATE, authState, reason, source, prevSource }), // authState won't be included in the event because it's an object
    accountDetailsInteraction: (dismissed: boolean, errorMessages: string[], flowName: string) =>
        trackedEvent({
            type: TypeKeys.DETAILS_INTERACTION_RESPONSE,
            dismissed,
            error_messages: errorMessages,
            flow_name: flowName,
        }),
    onPrivacyPolicyClicked: (venue_policy: boolean, URL?: string) =>
        trackedEvent({ type: TypeKeys.PRIVACY_POLICY_CLICKED, venue_policy, URL }),
};

export type SetAuthStateAction = {
    type: TypeKeys.SET_AUTH_STATE;
    authState: AuthState;
};

export type AuthStateAction = SetAuthStateAction;

const initialState: AuthState | null = null;

export function reducer(state: AuthState | null = initialState, action: AuthStateAction): AuthState | null {
    if (action.type === TypeKeys.SET_AUTH_STATE) {
        return action.authState;
    }

    return state;
}
