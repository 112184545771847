import { OnboardingImages } from "../types";

export enum TypeKeys {
    SET_LOADER_IMAGES = "ONBOARDING/SET_LOADER_IMAGES",
    SET_MENU_BACKGROUND_IMAGE = "ONBOARDING/SET_MENU_BACKGROUND_IMAGE",
    CLEAR_IMAGES = "ONBOARDING/CLEAR_IMAGES",
}

export const actionCreators = {
    setLoaderImages: (backgroundImage: string, logoUrl: string) => ({
        type: TypeKeys.SET_LOADER_IMAGES,
        backgroundImage,
        logoUrl,
    }),
    setMenuBackgroundImage: (menuBackgroundImage: string) => ({
        type: TypeKeys.SET_MENU_BACKGROUND_IMAGE,
        menuBackgroundImage,
    }),
    clearOnboardingImages: () => ({
        type: TypeKeys.CLEAR_IMAGES,
    }),
};

type SetLoaderImagesAction = {
    type: TypeKeys.SET_LOADER_IMAGES;
    backgroundImage: string;
    logoUrl: string;
};
type SetMenuBackgroundImageAction = {
    type: TypeKeys.SET_MENU_BACKGROUND_IMAGE;
    menuBackgroundImage: string;
};
type ClearOnboardingImageAction = { type: TypeKeys.CLEAR_IMAGES };

export type OnboardingAction = SetLoaderImagesAction | ClearOnboardingImageAction | SetMenuBackgroundImageAction;

const initialState = null;

export default function reducer(
    state: OnboardingImages | null = initialState,
    action: OnboardingAction
): OnboardingImages | null {
    if (action.type === TypeKeys.SET_LOADER_IMAGES) {
        return {
            ...state,
            loaderImages: {
                backgroundImage: action.backgroundImage,
                logoUrl: action.logoUrl,
            },
        };
    }

    if (action.type === TypeKeys.SET_MENU_BACKGROUND_IMAGE) {
        return {
            ...state,
            menuBackgroundImage: action.menuBackgroundImage,
        };
    }

    if (action.type === TypeKeys.CLEAR_IMAGES) {
        return null;
    }

    return state;
}
