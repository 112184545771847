import { FeatureConfiguration } from "..";

import reducer from "./reducer";

export { TypeKeys as OperationsTypeKeys } from "./reducer";

export * from "./reducer";

export * from "./components/OperationView";

export { createOperation } from "./operation";

export * from "./utils";

export default function (config: FeatureConfiguration) {
    config.reducers.operations = reducer;
}
