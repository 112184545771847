import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getProductFeesOperation } from "../operations";
import { Dispatch } from "redux";
import { getAuthHeaders } from "../../../common/auth";
import { PaymentGateway } from "../../../common/payment";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { ProductFees } from "../types";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

export function fetchProductFees(
    dispatch: Dispatch,
    paymentGateway: PaymentGateway,
    productId: string,
    currency: string,
    country: string | null,
    inParty: boolean
) {
    return getProductFeesOperation.invoke(async () => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

        headers.append("Content-Type", "application/json");

        let url = `/paymentgateway/${paymentGateway}/productfees/${productId}/${currency}`;
        if (country != null) {
            url += `/${country}`;
        }

        const response = await orderApi.send(
            url,
            {
                method: "GET",
                headers,
            },
            reattemptStrategies.limited
        );

        await ProblemDetailsError.throwError(response);

        return (await response.json()) as ProductFees;
    }, dispatch);
}
