import React from "react";
import { MenuItemBase } from "../../menudata";
import { hasMemberPrices } from "src/features/membership/utils";
import { MembershipIcon } from "../../../sharedComponents/assets/icons";

import "../assets/MemberPriceIndicator.scss";

export interface Props {
    menuItem: MenuItemBase;
}

export const MemberPriceIndicator = ({ menuItem: { availablePrices, minVariantPrice } }: Props) => {
    if (!hasMemberPrices(availablePrices, minVariantPrice)) return null;
    return <MemberPriceIndicatorIcon />;
};

export const MemberPriceIndicatorIcon = () => (
    <div className="member-price-indicator">
        <MembershipIcon />
    </div>
);
