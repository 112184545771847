import { ApplePayPaymentMethod, AuthorizePaymentResult, PaymentGateway, paymentOptions } from "../../../../payment";
import { AnyAction } from "redux";
import { orderApi, reattemptStrategies } from "../../../../../features/order/orderApi";
import { getAuthHeaders } from "../../../../auth";
import { ProblemDetailsError } from "../../../../../features/order/orderApi/ProblemDetailError";

export type GetValidApplePayMerchantSessionFunc = (validationURL: string) => Promise<any>;

export type GetApplePayAuthorizePaymentResultFunc = (
    token: ApplePayJS.ApplePayPaymentToken
) => Promise<AuthorizePaymentResult>;

export const validateApplePayUrl = async (paymentGateway: PaymentGateway, validationUrl: string) => {
    const body = new FormData();
    body.append("validationUrl", validationUrl);
    body.append("paymentGateway", paymentGateway);

    const response = await orderApi.send(
        "/paymentgateway/validate",
        {
            method: "POST",
            body,
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    await ProblemDetailsError.throwError(response);

    return await response.json();
};

export const initializeApplePayButton =
    async (
        applePayPaymentMethod: ApplePayPaymentMethod,
        total: number,
        trackEvent: (action: AnyAction) => void,
        getValidMerchantSession: GetValidApplePayMerchantSessionFunc,
        getAuthorizePaymentResult: GetApplePayAuthorizePaymentResultFunc
    ) =>
    () =>
        new Promise<AuthorizePaymentResult | null>((resolve, reject) => {
            const { currency, countryCode } = applePayPaymentMethod;

            const applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest = {
                currencyCode: currency,
                countryCode,
                total: {
                    label: paymentOptions.label,
                    amount: total.toFixed(2),
                },
                merchantCapabilities: ["supports3DS"],
                supportedNetworks: ["visa", "masterCard", "amex"],
            };

            const session = new ApplePaySession(3, applePayPaymentRequest);

            session.onvalidatemerchant = async ({ validationURL }: ApplePayJS.ApplePayValidateMerchantEvent) => {
                try {
                    const merchantSession = await getValidMerchantSession(validationURL);

                    if (!merchantSession) {
                        throw new Error("Failed to get merchant session");
                    }

                    session.completeMerchantValidation(merchantSession);
                } catch (err) {
                    if (err instanceof DOMException) {
                        const { code, name, message } = err;
                        trackEvent({ type: "DOM_EXCEPTION", code, name, message });
                        resolve(null);
                    } else {
                        session.abort();
                        reject(err);
                        return;
                    }
                }
            };

            session.onpaymentauthorized = async ({ payment: { token } }: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
                try {
                    const result = await getAuthorizePaymentResult(token);

                    if (!result?.paymentToken) {
                        throw new Error("Failed to get required authorization data");
                    }

                    session.completePayment(ApplePaySession.STATUS_SUCCESS);
                    resolve(result);
                } catch (err) {
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                    reject(err);
                }
            };

            session.oncancel = () => {
                resolve(null);
            };

            session.begin();
        });
