import { AppState } from "../";
import { createSelector } from "reselect";
import { BannerEvent } from "../../sharedComponents/controls/banner";

export const getBanners = (state: AppState) => state.banners.banners;

export const getVisibleBanners = createSelector(getBanners, (banners) => banners.filter(({ show }) => show));

export const getStaticBanners = createSelector(getVisibleBanners, (banners) =>
    banners.filter((banner) => banner.event === BannerEvent.STATIC)
);
