import { findingQrCodeOperation } from "../operations";
import { AppDispatch } from "src/features";
import { qr } from "src/common/experience";
import { QrCodeError } from "../../../common/experience/shared/qr";
import { qrActionCreators } from "../reducers";
import { actionCreators } from "../../deeplink";
import { UrlType } from "../../../common/experience/interface";
import { timeoutAndError } from "../../../common/shared";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { actionCreators as joinTabActionCreators } from "src/features/groupTabs/reducers/joinGroupTab";
import { getIsFlexJoinPath, getIsTabJoinPath, getIsTakeawayPath } from "../../../common/experience/shared/url";

export function findQrCode(cancelPromise: Promise<void>) {
    return findingQrCodeOperation.getThunk(async (dispatch: AppDispatch) => {
        try {
            const result: void | string = await Promise.race([
                qr.findQRCode(BarcodeType.QRCODE, (status) => dispatch(qrActionCreators.setScanStatus(status))),
                timeoutAndError(30000, new QrCodeError("FindQrCodeTimeout")),
                cancelPromise,
            ]);

            if (!result) return;

            const resultUrl = new URL(result);
            let type = UrlType.QR;

            if (getIsTabJoinPath(resultUrl.pathname)) {
                type = UrlType.JOIN_GROUP_TAB;
            }

            if (getIsTakeawayPath(resultUrl.pathname)) {
                type = UrlType.TAKEAWAY;
            }

            if (getIsFlexJoinPath(resultUrl.pathname)) {
                type = UrlType.JOIN_FLEX;
            }

            dispatch(actionCreators.loading({ type, claimUrl: result }));
        } catch (err) {
            if (
                err instanceof QrCodeError &&
                err.errorCode === "QrCodeCameraAccessDenied" &&
                qr.storeCameraAccessDenied
            ) {
                dispatch(qrActionCreators.setCameraAccessDenied());
            }
            dispatch(joinTabActionCreators.setJoinGroupTabCode(undefined));
            throw err;
        } finally {
            await qr.cancelFindQRCode();
        }
    });
}
