import { createSelector } from "reselect";
import { AppState } from "src/features";
import { getActiveABTests } from "src/features/abtests/selectors";
import { getActiveOrderByType } from "src/features/order/selectors";
import { getRestaurantFlags } from "src/features/order/selectors/restaurantFlags";

export const getQuickSellIds = (state: AppState) => state.quickSell.drinksOnly;

export const getQuickSellDrinksOnly = createSelector(getActiveABTests, getRestaurantFlags, (abTests, flags) =>
    abTests?.includes("QuickSell drinks only") ? false : flags.quickSellDrinksOnly
);

export const getIsQuickSellEnabled = createSelector(
    getQuickSellDrinksOnly,
    getActiveOrderByType,
    getQuickSellIds,
    (drinksOnly, order, quickSellIds) => (!drinksOnly || !order.food?.length) && !!quickSellIds
);
