const offlineFuncs: Set<() => void> = new Set();
const onlineFuncs: Set<() => void> = new Set();

let offlineTimeout = -1;

const BUFFER_TIMEOUT = 2 * 1000;

export const connectivityRegister = (options: { onOffline?: () => void; onOnline?: () => void }) => {
    options.onOffline && offlineFuncs.add(options.onOffline);
    options.onOnline && onlineFuncs.add(options.onOnline);

    return () => {
        options.onOffline && offlineFuncs.delete(options.onOffline);
        options.onOnline && onlineFuncs.delete(options.onOnline);
    };
};

export function onOffline() {
    offlineTimeout && window.clearTimeout(offlineTimeout);
    offlineTimeout = window.setTimeout(() => {
        if (window.navigator.onLine === false) {
            offlineFuncs.forEach((offline) => offline());
        }
    }, BUFFER_TIMEOUT);
}
export function onOnline() {
    offlineTimeout && window.clearTimeout(offlineTimeout);
    onlineFuncs.forEach((online) => online());
}
