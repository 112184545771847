import { activateLocationMarkerDeepLink } from "../actions/activateLocationMarkerDeepLink";
import { AppDispatch, AppMiddleware } from "../..";
import { actionCreators } from "../reducer";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { browserSupport, deviceReady, handleUrlLink } from "src/common/experience";
import { UrlLinkData, UrlType } from "src/common/experience/interface";
import { initDeviceStyle } from "src/common/shared";
import { deleteTableToken, getTableToken } from "../../partyOnboarding/persistence/tableToken";
import { rejoinTable } from "../../partyOnboarding/actions/rejoinTable";
import {
    fetchMealAndGroupTabHistories,
    fetchMealHistory,
    fetchMealOrOrderHistory,
} from "src/features/orderHistoryData/actions/fetchMealHistory";
import { replace } from "connected-react-router";
import { RegionChangedAction, TypeKeys } from "../../region/reducers/region";
import { isPreview } from "../../preview";
import { actionCreators as joinTabActionCreators } from "src/features/groupTabs/reducers/joinGroupTab";
import { actionCreators as activeGroupTabActionCreators } from "src/features/groupTabs/reducers/activeGroupTab";
import { actionCreators as groupTabDeepLinkActionCreators } from "src/features/groupTabs/reducers/groupTabDeepLink";
import { fetchGroupTabHistoryList } from "src/features/groupTabsHistory/actions";
import { auth } from "src/features/signup";
import { isPayOnlyPartyToken } from "../../partyOnboarding/util/isPayOnlyPartyToken";
import { deletePayOnlyToken } from "../../payOnly/persistence/payOnlyToken";
import { TabJoinMethod } from "../../groupTabs";
import { MemberActivity } from "src/features/order/types";
import { actionCreators as openTableActionCreators } from "../../openTable/reducers";
import { getAndShowOfferData } from "src/features/offers/actions";
import { fetchProfile } from "src/features/accountmenu";
import { getABTests } from "src/features/abtests/actions/getABTests";
import { getGroupMarketingInfo, getLocationMarketingInfo } from "src/features/marketing/actions/displayMarketing";

export const deepLinkMiddleware = () => (store: AppMiddleware) => {
    let cancelTimeout: number = 0;
    let cancelled = false;

    function handleURL(data: UrlLinkData) {
        clearTimeout(cancelTimeout);

        switch (data.type) {
            case UrlType.NFC:
            case UrlType.QR:
            case UrlType.GROUP_TAB_HISTORY:
            case UrlType.ORDER_HISTORY:
            case UrlType.JOIN_GROUP_TAB:
            case UrlType.MANAGE_GROUP_TAB:
            case UrlType.CLOSE_GROUP_TAB:
            case UrlType.PAY_ONLY:
            case UrlType.ORDER_HISTORY_ITEM:
            case UrlType.TAKEAWAY:
            case UrlType.PAY:
            case UrlType.OFFER:
            case UrlType.JOIN_FLEX:
                store.dispatch(actionCreators.loading(data));
                break;
            default:
                if (!cancelled) {
                    store.dispatch(actionCreators.cancelling("unknown"));
                }
                break;
        }

        initDeviceStyle.deviceHeight();
    }

    function loadUrl(data: UrlLinkData) {
        const { type, claimUrl } = data;

        switch (type) {
            case UrlType.NFC:
            case UrlType.QR:
            case UrlType.PAY_ONLY:
            case UrlType.TAKEAWAY:
            case UrlType.JOIN_FLEX:
                const tableToken = getTableToken();
                store.dispatch(activateLocationMarkerDeepLink(claimUrl, type, tableToken));
                break;

            case UrlType.PAY:
                store.dispatch(accountMenuActionCreators.closeAccountMenu());
                store.dispatch(openTableActionCreators.setActivity(MemberActivity.Paying));
                store.dispatch(actionCreators.loaded(claimUrl, "Pay"));
                tryRejoinTable(UrlType.PAY);
                break;

            case UrlType.JOIN_GROUP_TAB:
                const joinUrl = new URL(claimUrl);
                const joinCode = joinUrl.pathname.split("/").pop();
                if (joinCode) {
                    const joinMethod = (joinUrl.searchParams.get("from") || TabJoinMethod.LINK) as TabJoinMethod;
                    store.dispatch(joinTabActionCreators.setJoinGroupTabCode(joinCode));
                    store.dispatch(groupTabDeepLinkActionCreators.trackGroupTabJoinWithDeepLink(joinCode, joinMethod));
                    if (!getTableToken()) {
                        const claimUrl = joinUrl.searchParams.get("qr");
                        if (claimUrl) {
                            store.dispatch(actionCreators.loading({ claimUrl, type: UrlType.QR }));
                            return;
                        }
                    }
                    store.dispatch(actionCreators.loaded(claimUrl, "JoinGroupTab"));
                    tryRejoinTable();
                }
                break;

            case UrlType.MANAGE_GROUP_TAB:
                tryRejoinTable();
                store.dispatch(accountMenuActionCreators.closeAccountMenu());
                store.dispatch(activeGroupTabActionCreators.showGroupTabOverview(true));
                store.dispatch(actionCreators.loaded(claimUrl, "ManageGroupTab"));
                store.dispatch(groupTabDeepLinkActionCreators.trackManageGroupTabDeepLink());
                break;

            case UrlType.CLOSE_GROUP_TAB:
                tryRejoinTable();
                store.dispatch(accountMenuActionCreators.closeAccountMenu());
                store.dispatch(activeGroupTabActionCreators.confirmCloseGroupTab());
                store.dispatch(actionCreators.loaded(claimUrl, "CloseGroupTab"));
                store.dispatch(groupTabDeepLinkActionCreators.trackGroupTabCloseDeepLink());
                break;

            case UrlType.GROUP_TAB_HISTORY:
                if (auth.getIsAnonymous()) {
                    store.dispatch(actionCreators.loaded(claimUrl, "GroupTabHistory"));
                    break;
                }
                store.dispatch(fetchGroupTabHistoryList(null));
                store.dispatch(accountMenuActionCreators.openAccountMenu("GroupTabHistory"));
                store.dispatch(actionCreators.loaded(claimUrl, "GroupTabHistory"));
                store.dispatch(fetchMealHistory(null));
                break;

            case UrlType.ORDER_HISTORY_ITEM:
                const orderHistoryItemParam = new URL(claimUrl).pathname.split("/").pop();
                if (orderHistoryItemParam) {
                    store.dispatch(fetchMealOrOrderHistory(orderHistoryItemParam, claimUrl));
                } else {
                    fetchMealAndGroupTabHistories(store.dispatch, claimUrl);
                }
                break;

            case UrlType.ORDER_HISTORY:
                fetchMealAndGroupTabHistories(store.dispatch, claimUrl);
                break;

            case UrlType.OFFER:
                const offerURL = new URL(claimUrl);
                const offerPath = offerURL.pathname.split("/");
                const offerId = offerPath.pop();
                const groupId = offerPath.pop();
                const shortScope = offerPath.pop(); // if exists could refer to g ==> group, v ==> venue\brand, if not will be "offer", supporting no path as group
                const source = offerURL.searchParams.get("s") ?? undefined;
                if (groupId && offerId && (shortScope === "v" || shortScope === "g" || shortScope === "offer")) {
                    store.dispatch(fetchProfile());
                    store.dispatch(getABTests());
                    if (shortScope === "v") {
                        store.dispatch(getLocationMarketingInfo(groupId));
                    } else {
                        store.dispatch(getGroupMarketingInfo(groupId));
                    }
                    store.dispatch(
                        getAndShowOfferData(groupId, offerId, shortScope === "offer" ? "" : shortScope, source)
                    );
                }

                store.dispatch(actionCreators.loaded(claimUrl, "Offer", source));
                break;
        }
    }

    function tryRejoinTable(urlType?: UrlType) {
        deletePayOnlyToken();
        const tableToken = getTableToken();
        if (tableToken) {
            if (isPayOnlyPartyToken(tableToken)) {
                // We have a Pay Only table token so remove it
                deleteTableToken("switch_to_pay");
                return;
            }
            store.dispatch(rejoinTable(tableToken));
            // Very slim chance this could happen in a party
            // but just in case we should be on join-table
            store.dispatch(replace("/join-table"));
        } else if (urlType === UrlType.PAY) {
            store.dispatch(openTableActionCreators.resetActivity());
        }
    }

    function cancel(reason: string) {
        if (reason !== "unsupported browser") {
            tryRejoinTable();
        }

        store.dispatch(actionCreators.cancelled(reason));
    }

    function handleBadResponse(reason: string) {
        cancelled = true;
        store.dispatch(actionCreators.cancelling(reason));
        initDeviceStyle.deviceHeight();
    }

    deviceReady().then(() => {
        if (isPreview) return;

        if (!browserSupport.isSupportedBrowser) {
            handleBadResponse("unsupported browser");
            return;
        }

        cancelTimeout = window.setTimeout(() => handleBadResponse("timeout"), 500);

        try {
            handleUrlLink(handleURL);
        } catch (reason) {
            handleBadResponse(reason);
        }
    });

    /*
    Example of how to test this in a browser

    setTimeout(() => store.dispatch(activateNfcDeepLink(
        "https://app.meandu.com.au/nfc?e=98DCE654918A6ECE4C00C4937E81709B&c=7ECA15779B33EB36")), 
        0
    );
    */

    return (next: AppDispatch) => (action: RegionChangedAction) => {
        next(action);

        if (action.type === TypeKeys.CHANGED) {
            const {
                deepLink: { data, reason },
            } = store.getState();

            if (data) {
                loadUrl(data);
            } else if (reason) {
                cancel(reason);
            }
        }
    };
};
