import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IPageViewTelemetry,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { History } from "history";
import { findAppInsightsPlugin } from "../util/findAppInsightsPlugin";
import { IPlugin } from "@microsoft/applicationinsights-core-js";

export class HistoryPlugin extends BaseTelemetryPlugin {
    public identifier = "MeanduHistoryPlugin";

    constructor(private history: History) {
        super();
    }

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        const appInsights = findAppInsightsPlugin(extensions);

        if (!appInsights) {
            return;
        }

        let refUri = window.location.href;

        const pageViewTelemetry: IPageViewTelemetry = {
            uri: window.location.origin + this.history.location.pathname,
            refUri,
        };
        appInsights.trackPageView(pageViewTelemetry);

        const onStateChange = (data: any) => {
            try {
                if (!data || !data.name) return;
                setTimeout(() => {
                    const uri = window.location.origin + "/" + data.name;
                    if (uri === refUri) {
                        return;
                    }
                    const pageViewTelemetry: IPageViewTelemetry = {
                        name: data.name,
                        uri,
                        refUri,
                        pageType: "virtual-page",
                    };
                    appInsights.trackPageView(pageViewTelemetry);
                    refUri = uri;
                }, 500);
            } catch (e) {
                console.log("Exception in pushstate", e);
            }
        };

        document.addEventListener("virtualState", (ev: any) => onStateChange(ev.detail));

        this.history.listen((newLocation, action) => {
            if (window.history.state && window.history.state.name) return;
            setTimeout(() => {
                const uri = window.location.origin + newLocation.pathname;
                if (uri === refUri) {
                    return;
                }
                const pageViewTelemetry: IPageViewTelemetry = {
                    name: newLocation.pathname,
                    uri,
                    refUri,
                    pageType: "real-page",
                };
                appInsights.trackPageView(pageViewTelemetry);
                refUri = uri;
            }, 500);
        });
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        this.processNext(event, itemCtx!);
    }
}
