import { ConnectedMembershipState, MembershipState, MembershipStatus } from "../types/MembershipState";

export enum TypeKeys {
    BEGIN = "ACTIVE_MEMBERSHIP/FETCH_BEGIN",
    SUCCESS = "ACTIVE_MEMBERSHIP/FETCH_SUCCESS",
    CONNECTED = "ACTIVE_MEMBERSHIP/CONNECTED_SUCCESS",
    FAILURE = "ACTIVE_MEMBERSHIP/FETCH_FAILURE",
    RESET = "ACTIVE_MEMBERSHIP/RESET",
    USE_POINTS_SPEND = "ACTIVE_MEMBERSHIP/USE_POINTS_SPEND",
}

export const fetchActions = {
    loading: () => ({ type: TypeKeys.BEGIN }),
    loaded: (data: MembershipState[]) => ({
        type: TypeKeys.SUCCESS,
        data,
    }),
    connected: (data: ConnectedMembershipState) => ({
        type: TypeKeys.CONNECTED,
        data,
    }),
    failed: (error: any) => ({ type: TypeKeys.FAILURE, error }),
    reset: () => ({ type: TypeKeys.RESET }),
    setUsePointsSpendBalance: (programId: string, usePointsSpendBalance: boolean) => ({
        type: TypeKeys.USE_POINTS_SPEND,
        programId,
        usePointsSpendBalance,
    }),
};

export type ActiveMembershipState =
    | { status: "unloaded" }
    | { status: "loading" }
    | { status: "loaded"; data: MembershipState[] }
    | { status: "failed" };

type FetchActiveMembershipSuccessAction = {
    type: TypeKeys.SUCCESS;
    data: MembershipState[];
};

export type ConnectActiveMembershipSuccessAction = {
    type: TypeKeys.CONNECTED;
    data: ConnectedMembershipState;
};

type FetchActiveMembershipBeginAction = { type: TypeKeys.BEGIN };
type FetchActiveMembershipFailedAction = { type: TypeKeys.FAILURE };
type ResetActiveMembershipAction = { type: TypeKeys.RESET };
type UseActiveMembershipPointsSpendAction = {
    type: TypeKeys.USE_POINTS_SPEND;
    programId: string;
    usePointsSpendBalance: boolean;
};

type FetchActiveMembershipAction =
    | FetchActiveMembershipSuccessAction
    | ConnectActiveMembershipSuccessAction
    | FetchActiveMembershipBeginAction
    | ResetActiveMembershipAction
    | FetchActiveMembershipFailedAction
    | UseActiveMembershipPointsSpendAction;

const defaultState: ActiveMembershipState = {
    status: "unloaded",
};

export default function (
    state: ActiveMembershipState = defaultState,
    action: FetchActiveMembershipAction
): ActiveMembershipState {
    if (action.type === TypeKeys.BEGIN) {
        return {
            ...state,
            status: "loading",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { data } = action;

        return {
            status: "loaded",
            data,
        };
    }

    if (action.type === TypeKeys.CONNECTED && state.status === "loaded") {
        const { data } = action;
        return {
            ...state,
            data: state.data.map((d) => (d.programId === data.programId ? data : d)),
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        return {
            ...state,
            status: "failed",
        };
    }

    if (action.type === TypeKeys.RESET) {
        return {
            ...state,
            status: "unloaded",
        };
    }

    if (action.type === TypeKeys.USE_POINTS_SPEND && state.status === "loaded") {
        return {
            ...state,
            data: state.data.map((d) => {
                if (d.programId !== action.programId) return d;
                if (d.status !== MembershipStatus.CONNECTED) return d;
                return {
                    ...d,
                    usePointsSpendBalance: action.usePointsSpendBalance,
                };
            }),
        };
    }

    return state;
}
