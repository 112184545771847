import { createOperation } from "../operations";

export const startGroupTabWizardOperation = createOperation<void>("PARTY/START_GROUP_TAB_WIZARD");
export const createGroupTabOperation = createOperation<void>("PARTY/CREATE_GROUP_TAB", { showSpinner: true });
export const closeGroupTabOperation = createOperation<void>("PARTY/CLOSE_GROUP_TAB", { showSpinner: true });
export const leaveGroupTabOperation = createOperation<void>("PARTY/LEAVE_GROUP_TAB", { showSpinner: true });
export const getGroupTabOperation = createOperation<void>("PARTY/GET_GROUP_TAB");
export const joinGroupTabOperation = createOperation<void>("PARTY/JOIN_GROUP_TAB");
export const getOrderDataOperation = createOperation<void>("PARTY/GROUP_TAB_GET_ORDER");
export const getGroupTabUpdatesOperation = createOperation<void>("PARTY/GROUP_TAB_GET_UPDATES");
export const increaseGroupTabLimitOperation = createOperation<void>("PARTY/INCREASE_GROUP_TAB_LIMIT", {
    tracked: true,
    allowParallel: false,
    showSpinner: true,
});
export const updateTabLimitOperation = createOperation<void>("PARTY/UPDATE_TAB_LIMIT", {
    tracked: true,
    allowParallel: false,
    showSpinner: true,
});
export const enableGroupTabPackageOperation = createOperation<void>("PARTY/ENABLE_GROUP_TAB_PACKAGE");
export const disableGroupTabPackageOperation = createOperation<void>("PARTY/DISABLE_GROUP_TAB_PACKAGE");
export const setHasSeenManageTabPromptOperation = createOperation<void>("PARTY/SET_HAS_SEEN_MANAGE_TAB_PROMPT");
