import { trackedEvent } from "src/common/events/reduxEventTracking";
import { SplitPaymentOption } from "../../order/reducers/types";
import { SelectedItems } from "./payOnly";

export const payOnlyTrackActionCreators = {
    lowBalanceAction: (amount_set: string, amount_left: string) =>
        trackedEvent({ type: "PARTY/LOW_BALANCE_PROMPT", amount_left, amount_set }),
    overPaymentResponseAction: (tip_selected: boolean, remaining_balance: string, overpayment_amount?: string) =>
        trackedEvent({ type: "PARTY/OVER_PAYMENT_RESPONSE", tip_selected, remaining_balance, overpayment_amount }),
    splitBillAction: (
        split_type: SplitPaymentOption,
        split_amount: number,
        nb_bill_split?: number,
        nb_payments?: number,
        selected_items?: SelectedItems
    ) =>
        trackedEvent({
            type: "PARTY/BILL_SPLIT",
            split_type,
            split_amount,
            nb_bill_split,
            nb_payments,
            selected_items,
        }),
    trackSelectedSplitOption: (selectedOption: SplitPaymentOption, available_options: SplitPaymentOption[]) =>
        trackedEvent({
            type: "PARTY/PAYMENT_FLOW/SELECT_SPLIT_OPTION",
            response: selectedOption,
            available_options: available_options.toString(),
        }),
    paymentOption: (selectedPaymentOption: string) =>
        trackedEvent({ type: "PARTY/PAYMENT_FLOW/PAYMENT_OPTION", response: selectedPaymentOption }),
    trackRefreshItemListButtonShown: () =>
        trackedEvent({
            type: "PARTY/PAYMENT_FLOW/REFRESH_ITEM_LIST_SHOWN",
        }),
    trackRefreshItemListButtonClicked: () =>
        trackedEvent({
            type: "PARTY/PAYMENT_FLOW/REFRESH_ITEM_LIST_CLICKED",
        }),
};
