import { createSelector } from "reselect";
import { AppState } from "../..";
import { JoinTableStep } from "../components/JoinTablePage";
import {
    findingQrCodeOperation,
    joiningFlexTabPartyOperation,
    joiningOpenTablePartyOperation,
    joiningPayOnlyPartyOperation,
    joiningTableOperation,
    rejoiningPayOnlyPartyOperation,
    rejoiningTableOperation,
} from "../operations";
import { QrCodeError } from "src/common/experience/shared/qr";
import { getParty, PartyType } from "../../order";
import {
    authorizeApplePayPaymentOperation,
    authorizeGooglePayPaymentOperation,
    authorizePayPalPaymentOperation,
} from "../../payment/operations";
import { getVisibleMenuData } from "../../menu/selectors";
import { getRestaurantFlags } from "../../order/selectors/restaurantFlags";
import { getAddCardFormActive } from "../../payment/selectors";
import { OnboardingLoadingImages, PartyError, PartyErrors } from "../types";
import { getLoaderImagesFromLocalStorage } from "../persistence/onboarding";
import { UrlType } from "src/common/experience/interface";
import { getCurrentMemberInParty } from "../../order/selectors";
import { getOpenTableHasFlexTabBehaviorOnboarding } from "../../openTable/selectors";

export const getFindingQrCodeOperationState = (state: AppState) => findingQrCodeOperation.getStatus(state);

export const getJoiningTableOperationState = (state: AppState) => joiningTableOperation.getStatus(state);

export const getJoiningFlexTabOperationState = (state: AppState) => joiningFlexTabPartyOperation.getStatus(state);

export const getRejoiningTableOperationState = (state: AppState) => rejoiningTableOperation.getStatus(state);

export const getJoiningPayOnlyOperationState = (state: AppState) => joiningPayOnlyPartyOperation.getStatus(state);

export const getRejoiningPayOnlyOperationState = (state: AppState) => rejoiningPayOnlyPartyOperation.getStatus(state);

export const getJoiningOpenTableOperationState = (state: AppState) => joiningOpenTablePartyOperation.getStatus(state);

export const getAuthorizePayPalPaymentOperationState = (state: AppState) =>
    authorizePayPalPaymentOperation.getStatus(state);

export const getAuthorizeApplePayPaymentOperationState = (state: AppState) =>
    authorizeApplePayPaymentOperation.getStatus(state);

export const getAuthorizeGooglePayPaymentOperationState = (state: AppState) =>
    authorizeGooglePayPaymentOperation.getStatus(state);

export const getConnectivityState = (state: AppState) => state.nativeState.connected;

export const getIsDeepLinkLoaded = (state: AppState) => state.deepLink.isLoaded;

export const getDeepLinkUrlType = (state: AppState) => state.deepLink.data?.type;

export const getIsPaused = (state: AppState) => state.nativeState.paused;

const dineInPartyErrors: PartyErrors[] = ["GuarantorNotFound", "InvalidSource", "OutsideServiceHours", "VenueNotFound"];

export const getPartyError = (error: any, urlType?: UrlType): PartyError | undefined => {
    if (error instanceof PartyError) {
        // while the user is on takeaway we don't want to show party errors referring to dining-in experience
        if (urlType === UrlType.TAKEAWAY && error.errorCode && dineInPartyErrors.includes(error.errorCode)) {
            return new PartyError("TakeawayUnavailable", error?.additionalData, error?.venueName);
        }
        return error;
    }
    if (error instanceof QrCodeError) return new PartyError(error.errorCode);
    if (error instanceof Error) return new PartyError("UnknownError", error.toString());
    if (error) return new PartyError("UnknownError", JSON.stringify(error));

    return;
};

export const getJoinTableError = createSelector(
    joiningTableOperation.getError,
    joiningFlexTabPartyOperation.getError,
    findingQrCodeOperation.getError,
    getDeepLinkUrlType,
    (joinTableError, joinFlexTabPartyError, findQrCodeError, urlType) =>
        getPartyError(joinTableError || joinFlexTabPartyError || findQrCodeError, urlType)
);

export const getJoinTableStep = createSelector(
    getParty,
    getFindingQrCodeOperationState,
    getJoiningTableOperationState,
    getJoiningOpenTableOperationState,
    getRejoiningTableOperationState,
    getIsDeepLinkLoaded,
    getConnectivityState,
    getIsPaused,
    getOpenTableHasFlexTabBehaviorOnboarding,
    getJoiningFlexTabOperationState,
    getCurrentMemberInParty,
    (
        party,
        findingQrCodeStatus,
        joiningTableStatus,
        joiningOpenTableStatus,
        rejoiningTableStatus,
        isDeepLinkLoaded,
        connected,
        paused,
        hasFlexTabBehavior,
        joiningFlexTabStatus,
        currentMemberInParty
    ): JoinTableStep => {
        if (!isDeepLinkLoaded) return "Initializing";

        if (hasFlexTabBehavior) {
            if (joiningFlexTabStatus === "processing" || joiningTableStatus === "processing") {
                return "Joining";
            }

            if (joiningTableStatus === "complete" && party == null) {
                return "Deciding";
            }
        } else if (
            joiningTableStatus === "processing" ||
            (joiningTableStatus === "complete" && party === null) ||
            (joiningOpenTableStatus === "processing" && party !== null)
        ) {
            return "Joining";
        }

        if (rejoiningTableStatus === "processing" || (rejoiningTableStatus === "complete" && party === null))
            return "Rejoining";
        if (party?.type === PartyType.MULTIUSER) {
            if (!currentMemberInParty && party !== null) return "Viewing";
            if (
                currentMemberInParty &&
                (joiningTableStatus === "complete" ||
                    joiningOpenTableStatus === "complete" ||
                    rejoiningTableStatus === "complete" ||
                    joiningFlexTabStatus === "complete")
            )
                return "Congrats";
        } else {
            if (party !== null && (joiningTableStatus === "complete" || rejoiningTableStatus === "complete"))
                return "Congrats";
        }

        if (findingQrCodeStatus === "processing" && connected) return "FindingQrCode";
        if (paused) return "Paused";

        return "TouchToStart";
    }
);

export const getJoinPayOnlyStep = createSelector(
    getParty,
    getRejoiningPayOnlyOperationState,
    getJoiningPayOnlyOperationState,
    getIsDeepLinkLoaded,
    getIsPaused,
    (party, joiningTableStatus, rejoiningTableStatus, isDeepLinkLoaded, paused): JoinTableStep => {
        if (!isDeepLinkLoaded) return "Initializing";
        if (joiningTableStatus === "processing") return "Joining";
        if (rejoiningTableStatus === "processing") return "Rejoining";
        if ((joiningTableStatus === "complete" || rejoiningTableStatus === "complete") && party === null)
            return "Failed";
        if (party !== null && (joiningTableStatus === "complete" || rejoiningTableStatus === "complete"))
            return "Congrats";
        if (paused) return "Paused";

        return "TouchToStart";
    }
);

export const getJoiningTable = createSelector(
    getJoiningTableOperationState,
    (joiningTableStatus) => joiningTableStatus === "processing"
);

export const getCanPause = createSelector(
    getAuthorizePayPalPaymentOperationState,
    getAuthorizeApplePayPaymentOperationState,
    getAuthorizeGooglePayPaymentOperationState,
    getAddCardFormActive,
    (
        authorizePayPalPaymentOperationState,
        authorizeApplePayPaymentOperationState,
        authorizeGooglePayPaymentOperationState,
        addCardFormActive
    ) =>
        authorizePayPalPaymentOperationState !== "processing" &&
        authorizeApplePayPaymentOperationState !== "processing" &&
        authorizeGooglePayPaymentOperationState !== "processing" &&
        !addCardFormActive
);

export const getAgeRestriction = createSelector(getVisibleMenuData, getRestaurantFlags, (menuData, restaurantFlags) => {
    if (restaurantFlags.ageRestriction) {
        return restaurantFlags.ageRestriction;
    }

    switch (menuData?.locale) {
        case "en-US":
            return 21;
        default:
            return 18;
    }
});

const getOnboardingLoaderImages = (state: AppState): OnboardingLoadingImages | undefined =>
    state.onboarding?.loaderImages;

export const getLoaderImages = createSelector(
    getOnboardingLoaderImages,
    (loaderImages) => loaderImages ?? getLoaderImagesFromLocalStorage()
);

export const getMenuBackgroundImage = (state: AppState): string | undefined => state.onboarding?.menuBackgroundImage;
