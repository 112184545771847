import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { Text } from "src/sharedComponents";
import { getGroupTabMenuCallToAction, getGroupTabId, getTabTypeName } from "../selectors/activeGroupTab";
import { useCallback } from "react";
import { modalMessages } from "src/features/modalMessage/messages";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { GroupTabMenuActionState } from "..";
import { enableGroupTabPackageAction } from "../actions/enableGroupTabPackageAction";
import { startGroupTabWizardAction } from "../actions/startGroupTabWizardAction";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { actionCreators as bannerActionCreators } from "../../banners/reducers";
import { actionCreators as activeGroupTabActionCreators } from "../reducers/activeGroupTab";
import { bannerMessages } from "../../banners/messages";
import { disableGroupTabPackageAction } from "../actions/disableGroupTabPackageAction";
import { LimitedMenuType } from "../types";
import "./GroupTabMenuCallToAction.scss";
import { getIsPartyDemo } from "src/features/order/selectors";

export const GroupTabMenuCallToAction = () => {
    const currentPackageViewState = useSelector(getGroupTabMenuCallToAction);
    const activeGroupTabId = useSelector(getGroupTabId);
    const dispatch = useDispatch();
    const tabTypeName = useSelector(getTabTypeName);
    const isDemo = useSelector(getIsPartyDemo);

    const onClick = useCallback(() => {
        switch (currentPackageViewState?.actionState) {
            case GroupTabMenuActionState.TO_FULL:
                dispatch(
                    showModalMessage(
                        modalMessages.groupTabSwitchToFullMenu(() => {
                            dispatch(disableGroupTabPackageAction());
                            dispatch(paymentActionCreators.clearPaymentMethods());
                            dispatch(
                                bannerActionCreators.removeBanner(bannerMessages.activeGroupTab(tabTypeName).title)
                            );
                            dispatch(
                                activeGroupTabActionCreators.trackSwapMenu(activeGroupTabId!, LimitedMenuType.FULL)
                            );
                        }, tabTypeName)
                    )
                );
                break;
            case GroupTabMenuActionState.TO_LIMITED:
                dispatch(
                    showModalMessage(
                        modalMessages.groupTabSwitchToLimitedMenu(() => {
                            dispatch(enableGroupTabPackageAction());
                            dispatch(paymentActionCreators.clearPaymentMethods());
                            dispatch(
                                bannerActionCreators.showBanner(bannerMessages.activeGroupTab(tabTypeName), !isDemo)
                            );
                            dispatch(
                                activeGroupTabActionCreators.trackSwapMenu(activeGroupTabId!, LimitedMenuType.LIMITED)
                            );
                        }, tabTypeName)
                    )
                );
                break;
            case GroupTabMenuActionState.OWNER:
                dispatch(startGroupTabWizardAction({ previewPackage: true }));
                break;
        }
    }, [dispatch, currentPackageViewState, activeGroupTabId, tabTypeName, isDemo]);

    if (!currentPackageViewState) return null;

    return (
        <TappableDiv onClick={onClick} className="group-tab-menu-cta">
            <TabIcon />
            <div className="group-tab-menu-cta__text">
                <Text preset="g-14" mode="block" value={currentPackageViewState.title} />
                <Text preset="g-14" mode={["medium", "block"]} value={currentPackageViewState.callToAction} />
            </div>
        </TappableDiv>
    );
};
