import * as React from "react";
import "../assets/FixedNotification.scss";
import { RightArrowIcon, TagIcon } from "src/sharedComponents/assets/icons";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../index";
import { getParty, PartyNotification, PartyNotificationTier, PartyNotificationType } from "../../order";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import { push } from "connected-react-router";
import { PromotionalPage } from "../../promotion/components/PromotionalPage";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { Text } from "src/sharedComponents";

export interface Props {
    notifications: PartyNotification[] | null;
    goToPromotionPage: () => void;
    headerText?: string;
}

export interface State {
    promotionModalOpen: boolean;
}

export interface OwnProps {
    partyNotificationTypesToDisplay?: PartyNotificationType[];
    headerText?: string;
}

export class StatusNotificationsInner extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            promotionModalOpen: false,
        };
    }

    closePromotionModal = () => this.setState({ promotionModalOpen: false });

    render() {
        const { notifications, headerText } = this.props;

        if (!notifications?.length) return null;

        return (
            <>
                {headerText && <h3 className="fixed-notification-header">{headerText}</h3>}
                {notifications?.map(this.renderNotification)}
            </>
        );
    }

    handleOnClick = () => this.setState({ promotionModalOpen: true });

    renderNotification = (notification: PartyNotification) => (
        <div key={notification.id} className="fixed-notification__wrapper">
            {notification.type === PartyNotificationType.CREDIT ? (
                <>
                    {this.renderMessage(
                        notification.title,
                        notification.shortDescription,
                        <TagIcon color="#4CD964" />,
                        "promotion",
                        this.handleOnClick
                    )}
                    <PromotionalPage
                        isOpen={this.state.promotionModalOpen}
                        close={this.closePromotionModal}
                        notification={notification}
                    />
                </>
            ) : (
                this.renderMessage(notification.title, notification.shortDescription, null, notification.id)
            )}
        </div>
    );

    renderMessage = (
        title: string,
        description: string | null,
        icon: React.ReactNode,
        id?: string,
        onClick?: () => void
    ) => (
        <TappableDiv
            className={classNames("fixed-notification", { "fixed-notification--promotion": id === "promotion" })}
            key={id}
            onClick={onClick}
        >
            <div className="fixed-notification__icon">{icon}</div>
            <div className="fixed-notification__content">
                <Text preset="g-16" mode="bold">
                    {title}
                </Text>
                <Text preset="g-12" mode="block">
                    {description}
                </Text>
            </div>
            {onClick && (
                <div className="fixed-notification__arrow">
                    <RightArrowIcon color="#C4C4C4" />
                </div>
            )}
        </TappableDiv>
    );
}

const mapStateToProps = (state: AppState, props: OwnProps): DataProps<Props> => {
    const party = getParty(state);
    const memberId = getCurrentMemberId(state);

    const { partyNotificationTypesToDisplay, headerText } = props;

    const notifications =
        party &&
        party.notifications
            .filter(
                (n) =>
                    n.tier === PartyNotificationTier.STATUS &&
                    !n.cancelled &&
                    (!n.includedMembers.length || n.includedMembers.indexOf(memberId) >= 0) &&
                    (!n.excludedMembers.length || n.excludedMembers.indexOf(memberId) < 0) &&
                    (!partyNotificationTypesToDisplay || partyNotificationTypesToDisplay.indexOf(n.type) >= 0)
            )
            .reverse();

    return {
        notifications,
        headerText,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        goToPromotionPage: () => dispatch(push("/menu/promotion")),
    };
};

export const StatusNotifications = connect(mapStateToProps, mapDispatchToProps)(StatusNotificationsInner);
