import { device } from "../..";
import { cordovaWindow } from "../cordova";
import { isLightColor } from "../../../shared";

export const statusBar = {
    setColor: (hex: string) => {
        if (device.isAndroid || (hex.length !== 4 && hex.length !== 7)) {
            return;
        }
        if (isLightColor(hex)) {
            // this is a light background we should use a dark statusbar
            cordovaWindow.StatusBar.styleDefault();
        } else {
            // this is a dark background we should use a light statusbar
            cordovaWindow.StatusBar.styleLightContent();
        }
    },
};
