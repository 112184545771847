import { Auth0QueryState } from "./auth0";
import { SignInFlowState } from "./signIn";
import { CreateVerifiedAccountState } from "./createVerifiedAccount";

export { auth0ActionCreators } from "./auth0";
export { actionCreators as signInActions } from "./signIn";
export { actionCreators as createVerifiedAccountActions } from "./createVerifiedAccount";

export interface State {
    auth0: Auth0QueryState;
    signIn: SignInFlowState;
    createVerifiedAccount: CreateVerifiedAccountState;
}
