import "./PaymentMethod.scss";

import React, { ReactElement } from "react";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { PaymentMethod as PaymentMethodType, PaymentType, SelectablePaymentMethod } from "../../../common/payment";
import {
    AmexInstrumentIcon,
    ApplePayInstrumentIcon,
    ChargeToRoomInstrumentIcon,
    CreditCardInstrumentIcon,
    GooglePayInstrumentIcon,
    MasterCardInstrumentIcon,
    PayPalInstrumentIcon,
    TabInstrumentIcon,
    VisaInstrumentIcon,
} from "src/sharedComponents/assets/icons";
import { useSelector } from "react-redux";
import { getOpenTabPaymentMethod } from "../../payment/selectors";

export interface SelectablePaymentMethodProps {
    paymentMethod: SelectablePaymentMethod;
}

export interface Props {
    paymentMethod: PaymentMethodType;
    text?: string;
    leftIcon?: ReactElement | null;
    rightIcon?: ReactElement | null;
    className?: string;
    onClick?: () => void;
}

export const PaymentMethod = ({ paymentMethod, text, leftIcon, rightIcon, className, onClick }: Props) => {
    const openTabPaymentMethod = useSelector(getOpenTabPaymentMethod);
    return (
        <TappableDiv className={classNames("payment-method", className)} onClick={onClick}>
            <div className="payment-method__left">
                {leftIcon ?? getPaymentMethodIcon(paymentMethod, openTabPaymentMethod)}
            </div>
            <Text preset="g-14" className="payment-method__center">
                {text ?? getPaymentMethodText(paymentMethod, openTabPaymentMethod)}
            </Text>
            <Text preset="g-14" className="payment-method__right">
                {rightIcon}
            </Text>
        </TappableDiv>
    );
};

const getPaymentMethodIcon = (
    paymentMethod: PaymentMethodType,
    openTabPaymentMethod: SelectablePaymentMethod | null
) => {
    if (paymentMethod === openTabPaymentMethod) {
        return <TabInstrumentIcon />;
    }
    switch (paymentMethod.paymentType) {
        case PaymentType.APPLEPAY:
            return <ApplePayInstrumentIcon />;
        case PaymentType.GOOGLEPAY:
            return <GooglePayInstrumentIcon />;
        case PaymentType.PAYPALCHECKOUT:
            return <PayPalInstrumentIcon />;
        case PaymentType.GROUPTAB:
            return <TabInstrumentIcon />;
        case PaymentType.NEWCARD:
            return <CreditCardInstrumentIcon />;
        case PaymentType.CARD:
        case PaymentType.ADDEDCARD:
            switch (paymentMethod.displayName) {
                case "Visa":
                    return <VisaInstrumentIcon />;
                case "MasterCard":
                    return <MasterCardInstrumentIcon />;
                case "American Express":
                    return <AmexInstrumentIcon />;
                default:
                    return <CreditCardInstrumentIcon />;
            }
        case PaymentType.POS:
            return <ChargeToRoomInstrumentIcon />;
        default:
            return undefined;
    }
};

const getPaymentMethodText = (
    paymentMethod: PaymentMethodType,
    openTabPaymentMethod: SelectablePaymentMethod | null
) => {
    if (paymentMethod === openTabPaymentMethod) {
        return paymentMethod.displayName;
    }
    switch (paymentMethod.paymentType) {
        case PaymentType.CARD:
        case PaymentType.ADDEDCARD:
            return paymentMethod.maskedNumber;
        case PaymentType.NEWCARD:
            return "Add credit or debit card";
        default:
            return paymentMethod.displayName;
    }
};
