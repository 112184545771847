import { AppDispatch, AppState } from "src/features";
import { getOptInMembership } from "src/features/membership/selectors/getVenueMembershipState";
import { auth } from "../../signup";
import { connect as connectMembership } from "./connect";
import { createVerifiedAccountActions } from "../../signup/reducers";
import { getProfile } from "src/features/accountmenu/selectors";
import { sendOptIn } from "src/features/marketing/actions/sendOptIn";
import { getActiveLocationId, getParty } from "src/features/order/selectors";
import { marketingMetaData } from "src/features/marketing/actions/displayMarketing";
import { getLocationName } from "src/features/menu/selectors";
import { SignupSource } from "src/features/marketing";
import { AvailableMembershipState, MembershipAuthMethods } from "../types/MembershipState";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { CreatedVerifiedAccountPageDetails } from "../../signup/reducers/createVerifiedAccount";
import { signInAction } from "../../signup/actions/signInAction";
import { getConnectedMembershipState } from "src/features/membership/selectors/getConnectedMembershipState";
import { createSelector } from "reselect";

export const connectVenueOptInMembership = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const membership = getOptInMembership(state);

        if (membership) {
            const { firstName, lastName, email, phone } = { ...getProfile(state) };
            const locationId = getActiveLocationId(state);
            const party = getParty(state);
            if (locationId) {
                dispatch(marketingMetaData(locationId));
            }
            dispatch(
                connectMembership(
                    membership.programId,
                    async () => {
                        return new FormData();
                    },
                    () => {
                        if (membership.authentication.method === MembershipAuthMethods.OPT_IN) {
                            if (membership.authentication.allowRegistration) {
                                dispatch(showModalMessage(modalMessages.connectMembershipFailed()));
                            } else {
                                dispatch(showModalMessage(modalMessages.activateMembershipFailed()));
                            }
                        }
                    }
                )
            );
            if (firstName && lastName && email && locationId && party?.id) {
                dispatch(
                    sendOptIn(
                        locationId,
                        firstName,
                        lastName,
                        email,
                        phone ?? null,
                        party?.id,
                        SignupSource[SignupSource.VENUEMEMBERSHIP]
                    )
                );
            }

            dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
        }
    };
};

const onAccountDetailsSuccess = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const isAnonymous = auth.getIsAnonymous();
        const profile = getProfile(state);
        dispatch(createVerifiedAccountActions.preventCompletingVerifyWizard(false));
        if (profile?.phone && isAnonymous) {
            dispatch(createVerifiedAccountActions.showPage("Verify"));
        }
        if (profile?.email && !isAnonymous) {
            dispatch(connectVenueOptInMembership());
        }
    };
};

const getConnectVenueOptInMembershipComplete = createSelector(
    getConnectedMembershipState,
    (_: AppState, membershipState: AvailableMembershipState) => membershipState,
    (connectedMembershipState, membershipState) => connectedMembershipState?.programId === membershipState.programId
);

export const startLinkToVenueMembership = (fromProfile: boolean) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const membership = getOptInMembership(state);
        const venueName = getLocationName(state);

        if (membership && membership.authentication.method === MembershipAuthMethods.OPT_IN) {
            const pageDetails: CreatedVerifiedAccountPageDetails = {
                accountDetailsTitle: membership.authentication.allowRegistration
                    ? "Sign up to our loyalty program"
                    : "Activate membership",
                verifyPageTitle: "Verify your number",
                verifyPageAdditionalDescription: "To activate a membership you must verifiy your phone.",
                requireEmail: true,
                submitText: membership.authentication.allowRegistration ? "Sign up" : "Activate now",
                hideEmailMessage: true,
                flowName: "venue_membership",
                venueDetails: {
                    privacyPolicy: membership.links?.privacy,
                    legalAgreementMessage: membership.authentication.allowRegistration
                        ? `By signing up, you will become a member of ${venueName} loyalty program and receive ${venueName} newsletters. You agree me&u can share your information with ${venueName} for this purpose.`
                        : `By activating, you will become a member of ${venueName} loyalty program and receive ${venueName} newsletters and you consent to receiving their marketing and promotional material. You agree me&u can share your information with ${venueName} for this purpose.`,
                    venueName: venueName,
                },
                subtitle: membership.programName,
                onSuccess: () => {
                    dispatch(onAccountDetailsSuccess());
                },
                onComplete: () => dispatch(connectVenueOptInMembership()),
                preventCloseOnSignInComplete: true,
            };

            dispatch(
                signInAction({
                    useLocationTheme: true,
                    requireVerification: true,
                    onComplete: () => {
                        const connectedMembershipState = getConnectedMembershipState(getState());
                        if (connectedMembershipState?.programId === membership.programId) return;
                        dispatch(connectVenueOptInMembership());
                    },
                    getSignInComplete: (state: AppState) => getConnectVenueOptInMembershipComplete(state, membership),
                    accountDetailsOptions: pageDetails,
                    slideAnimation: fromProfile ? "slide-from-right" : "slide-in",
                    isMidWizard: fromProfile,
                })
            );
        }
    };
};
