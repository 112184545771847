import { orderApi } from "src/features/order/orderApi";
import { getTableTokenHeaders } from "src/features/partyOnboarding/persistence/tableToken";
import { getServiceNameById } from "src/features/menu/util/helpers";
import { Service } from "src/features/menudata";
import { TakeawayDetails } from "../reducers/takeawayOptions";

export async function fetchTakeawayScheduleSlots(allServices: Service[]) {
    const response = await orderApi.send("/party/takeawayOptions", {
        method: "GET",
        headers: await getTableTokenHeaders(),
    });

    if (!response.ok) {
        throw response;
    }

    const result: TakeawayDetails = await response.json();

    const sortedServices = result?.serviceScheduleSlots
        ?.map((service) => ({
            ...service,
            displayName: getServiceNameById(service.serviceId, allServices),
            serviceStartTime: new Date(service.serviceStartTime),
            scheduleSlots: service.scheduleSlots
                .map((item) => ({ ...item, scheduleTime: new Date(item.scheduleTime) }))
                .sort((a, b) => a.scheduleTime.getTime() - b.scheduleTime.getTime()),
        }))
        .sort((a, b) => a.serviceStartTime.getTime() - b.serviceStartTime.getTime());

    return sortedServices;
}
