import { QuickSell, QuickSellService } from "../types";

export interface State {
    quickSell: QuickSell;
}

enum TypeKeys {
    SET_QUICK_SELL_DRINKS_ONLY = "QUICK_SELL/SET_QUICK_SELL_DRINKS_ONLY",
}

export const actionCreators = {
    setQuickSellDrinksOnly: (drinksOnly?: QuickSellService) => ({
        type: TypeKeys.SET_QUICK_SELL_DRINKS_ONLY,
        drinksOnly,
    }),
};

type SetQuickSelFoodAction = { type: TypeKeys.SET_QUICK_SELL_DRINKS_ONLY; drinksOnly?: QuickSellService };

export type QuickSellActions = SetQuickSelFoodAction;

const initialState: QuickSell = {};

export default function reducers(state: QuickSell = initialState, action: QuickSellActions): QuickSell {
    switch (action.type) {
        case TypeKeys.SET_QUICK_SELL_DRINKS_ONLY:
            return {
                ...state,
                drinksOnly: action.drinksOnly,
            };
        default:
            return state;
    }
}
