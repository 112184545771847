import "../assets/StampEarned.scss";

import React from "react";
import { useSelector } from "react-redux";
import { getAvailableStampCardOffers } from "../selectors";
import { getActiveOrderStatus, getStampCardAdjustments } from "src/features/order/selectors";
import { OrderStatus } from "src/features/order";
import { LoadingText } from "src/sharedComponents/controls/loadingText";
import { Text } from "src/sharedComponents";
import { CheckIcon } from "src/sharedComponents/assets/icons";
import { simplePlural } from "src/common/strings";

export const StampEarned = () => {
    const availableStampCardOffers = useSelector(getAvailableStampCardOffers);
    const stampCardAdjustments = useSelector(getStampCardAdjustments);
    const orderStatus = useSelector(getActiveOrderStatus);

    const validatingOrder = orderStatus === OrderStatus.VALIDATING;

    if (!availableStampCardOffers?.length || (!validatingOrder && !stampCardAdjustments?.length)) {
        return null;
    }

    return (
        <div className="stamp-earned">
            {validatingOrder && <LoadingText />}
            {!!stampCardAdjustments?.length && !validatingOrder && (
                <Text preset="g-14" mode="bold">
                    <CheckIcon />
                    You’re earning {stampCardAdjustments.length} stamp
                    {simplePlural(stampCardAdjustments.length)}
                </Text>
            )}
        </div>
    );
};
