import { combineReducers } from "redux";
import { ActiveGroupTabState, GroupTabsWizardState, JoinCodeState } from "..";
import { reducer as groupTabWizard } from "./groupTabWizard";
import { reducer as activeGroupTab } from "./activeGroupTab";
import { reducer as joinGroupTab } from "./joinGroupTab";

export const reducer = combineReducers({
    wizard: groupTabWizard,
    activeTab: activeGroupTab,
    joinTab: joinGroupTab,
});

export interface GroupTabsState {
    wizard: GroupTabsWizardState;
    activeTab: ActiveGroupTabState;
    joinTab: JoinCodeState;
}

export interface State {
    groupTabs: GroupTabsState;
}
