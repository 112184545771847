import { AppDispatch, AppMiddleware } from "../../index";
import { regionActionCreators } from "../reducers";
import { DeeplinkAction, TypeKeys as DeeplinkTypeKeys } from "../../deeplink";
import { RegionChangedAction, TypeKeys as RegionTypeKeys } from "../reducers/region";
import { getLegacyRegion } from "../utils/getLegacyRegion";
import { Region } from "../types";
import { regionHelper } from "../regionHelper";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { gdpr } from "../../../common/experience";
import { isPreview } from "../../preview";
import { cookiePreferencesActionCreators } from "../../compliance/reducers";
import { UrlType } from "src/common/experience/interface";

export const MEANDU_REGION_KEY = "MEANDU_REGION";

export const regionMiddleware = () => (store: AppMiddleware) => {
    let allowAnalytics = true;
    let allowAdvertising = true;

    function onRegionChanged(region: Region) {
        if (isPreview || !gdpr.shouldShowCookiePreferences()) {
            store.dispatch(regionActionCreators.changed(region));
            return;
        }

        allowAnalytics = true;
        allowAdvertising = true;

        store.dispatch(
            showModalMessage(
                modalMessages.cookiePreferences(
                    () => {
                        const cookiePreferences = {
                            allowAnalytics,
                            allowAdvertising,
                        };

                        gdpr.setCookiePreferences(cookiePreferences);
                        store.dispatch(cookiePreferencesActionCreators.changed(cookiePreferences));
                        store.dispatch(regionActionCreators.changed(region));
                    },
                    (allowed) => {
                        allowAnalytics = allowed;
                    },
                    (allowed) => {
                        allowAdvertising = allowed;
                    }
                )
            )
        );
    }

    regionHelper.onRegionChanged(onRegionChanged);

    return (next: AppDispatch) => (action: DeeplinkAction | RegionChangedAction) => {
        next(action);

        if (action.type === RegionTypeKeys.CHANGED) {
            localStorage.setItem(MEANDU_REGION_KEY, action.region.id);
        }

        if (action.type === DeeplinkTypeKeys.CANCELLING) {
            const regionId = localStorage.getItem(MEANDU_REGION_KEY);

            regionHelper.init(regionId);
        }

        if (action.type === DeeplinkTypeKeys.LOADING) {
            let regionId: string | null = null;
            let defaultRegionId: string | null = null;
            try {
                const params = new URL(action.data.claimUrl).searchParams;
                regionId = params.get("r");
                defaultRegionId = shouldUsePreviousRegion(action.data.type);
            } catch {}

            regionHelper.init(regionId ?? defaultRegionId ?? getLegacyRegion());
        }
    };
};

const regionAgnosticDeepLinkTypes = [UrlType.NFC, UrlType.QR, UrlType.PAY_ONLY, UrlType.JOIN_FLEX];

function shouldUsePreviousRegion(deepLinkType: UrlType) {
    return regionAgnosticDeepLinkTypes.includes(deepLinkType) ? null : localStorage.getItem(MEANDU_REGION_KEY);
}
