import "./Textarea.scss";

import React, {
    ChangeEvent,
    FocusEvent,
    MouseEvent,
    TextareaHTMLAttributes,
    TouchEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { useFormControlContext } from "../formControl";

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    invalid?: boolean;
    size?: "md" | "lg";
}

export const Textarea = forwardRef<HTMLTextAreaElement | null, TextareaProps>(
    (
        {
            id,
            value,
            defaultValue,
            label,
            placeholder,
            disabled,
            invalid,
            size = "md",
            className,
            onChange,
            onFocus,
            onBlur,
            ...rest
        },
        ref
    ) => {
        const internalRef = useRef<HTMLTextAreaElement>(null);
        const formControl = useFormControlContext() || { id, disabled, invalid };
        const [isBlank, setIsBlank] = useState(!defaultValue && !value);
        const [isFocused, setIsFocused] = useState(false);

        const isControlled = value !== undefined;

        useImperativeHandle(ref, () => internalRef.current, []);

        useEffect(() => {
            if (!isControlled) setIsBlank(!internalRef.current?.value);
        }, [isControlled]);

        const handleOnClick = () => {
            internalRef.current?.focus();
        };

        const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
            const parentNode = internalRef.current?.parentNode;

            if (parentNode instanceof HTMLElement) {
                parentNode.dataset.replicatedValue = event.target.value;
            }

            if (!isControlled && internalRef.current) {
                internalRef.current.value = event.target.value;
            }

            setIsBlank(!event.target.value);
            onChange?.(event);
        };

        const handleOnFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
            setIsFocused(true);
            onFocus?.(event);
        };

        const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
            setIsFocused(false);
            onBlur?.(event);
        };

        const preventLossOfFocus = (event: MouseEvent | TouchEvent) => {
            if (event.target !== internalRef.current) event.preventDefault();
        };

        return (
            <div
                className={classNames(
                    "textarea-control",
                    `textarea-control--${size}`,
                    {
                        "textarea-control--disabled": formControl.disabled,
                        "textarea-control--invalid": formControl.invalid,
                    },
                    className
                )}
                onMouseDown={preventLossOfFocus}
                onClick={handleOnClick}
            >
                <div className={classNames("textarea-control__textarea")}>
                    <textarea
                        ref={internalRef}
                        id={formControl.id}
                        rows={1}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder || " "}
                        disabled={formControl.disabled}
                        onChange={handleOnChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        {...rest}
                    />
                </div>
                {label && (
                    <label
                        className={classNames({ "label--floating": isFocused || !isBlank })}
                        htmlFor={formControl.id}
                    >
                        {label}
                    </label>
                )}
            </div>
        );
    }
);
