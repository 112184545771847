import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ModifierRadioListItem } from "./ModifierRadioListItem";
import { getModifierListInitialLimit } from "../helpers";
import { ExpandableList } from "src/sharedComponents/controls/expandableList";
import { MODIFIER_LIST_INITIAL_LIMIT, reduceNestedModifierLength } from "../../menuitem/components/MenuItemDetails";
import { useHasMounted } from "src/sharedComponents/common/shared";
import { withListTracking } from "../component/withListTracking";
import { ModifierListSingleSelectProps } from "src/features/order/Elements/OrderModifiers";

interface Props extends ModifierListSingleSelectProps {
    triedToSubmit: boolean;
}

const RadioList = (props: Props) => {
    const {
        modifierId,
        modifier,
        selectedOptions,
        onModifierOptionSelected,
        selectedNestedModifier,
        onNestedModifierEdit,
        trackExpand,
        canHideItems,
    } = props;
    const hasMounted = useHasMounted();
    const [limit, setLimit] = useState(MODIFIER_LIST_INITIAL_LIMIT);
    const nestedModiferKey = useMemo(
        () =>
            !selectedNestedModifier || limit === modifier.options.length
                ? ""
                : `-${reduceNestedModifierLength(selectedNestedModifier)}`,
        [modifier.options, limit, selectedNestedModifier]
    );

    const onModifierOptionChange = useCallback(
        (optionIndex: number, selected: boolean) => {
            onModifierOptionSelected(modifierId, optionIndex, selected);
        },
        [onModifierOptionSelected, modifierId]
    );

    useEffect(() => {
        if (!hasMounted) {
            setLimit(getModifierListInitialLimit(MODIFIER_LIST_INITIAL_LIMIT, modifier.options, selectedOptions));
        }
    }, [hasMounted, modifier.options, selectedOptions]);

    const onListExpand = useCallback(
        (numberOptionsRevealed: number) => {
            setLimit(modifier.options.length);
            if (trackExpand) {
                trackExpand(numberOptionsRevealed, "radio");
            }
        },
        [trackExpand, modifier.options.length]
    );

    return !hasMounted ? null : (
        <ExpandableList
            key={`modifier-radio-list-${modifierId}${nestedModiferKey}`}
            initialLimit={limit}
            totalItems={modifier.options.length}
            showHiddenCount={true}
            onExpand={onListExpand}
            canHideItems={canHideItems}
            renderItem={(positionIndex: number) => {
                const option = modifier.options[positionIndex];
                const selectedOption = selectedOptions ? selectedOptions[0] : undefined;
                const checked = selectedOption === option.originalIndex;
                const disabled = option.available === false;
                const isRequiredModifier = !!modifier.minSelection;

                return (
                    <ModifierRadioListItem
                        key={`modifier-${modifierId}-${positionIndex}`}
                        onChange={(checked: boolean) =>
                            onModifierOptionChange(option.originalIndex, isRequiredModifier || checked)
                        }
                        checked={checked}
                        disabled={disabled}
                        listItem={option}
                        selectedNestedModifier={selectedNestedModifier}
                        onNestedModifierEdit={
                            onNestedModifierEdit
                                ? () => onNestedModifierEdit(modifierId, option.originalIndex)
                                : undefined
                        }
                        prefix="+"
                    />
                );
            }}
        />
    );
};

export const ModifierRadioList = withListTracking(RadioList);
