import { AppDispatch } from "src/features";
import { setHasSeenManageTabPromptOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { setHasSeenManageTabPromptApi } from "../API/setHasSeenManageTabPromptApi";

export function setHasSeenManageTabPromptAction() {
    return setHasSeenManageTabPromptOperation.getThunk(async (dispatch: AppDispatch) => {
        try {
            const res = await setHasSeenManageTabPromptApi();
            dispatch(activeTabActionCreators.setGroupTabData(res));
        } catch (ex) {
            console.log("failed to set has seen manage prompt:", ex);
        }
    });
}
