import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { AppDispatch, AppState } from "src/features";
import { orderApi } from "src/features/order/orderApi";
import { saveSecuredPaymentMethodOperation } from "../operations/saveSecuredPaymentMethod";
import { SecuredPaymentInfo } from "../types";
import { getPaymentBehavior } from "src/features/paymentGateways";

export const saveSecuredPaymentMethod = () => {
    return saveSecuredPaymentMethodOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const state = getState();

            const selectedPaymentMethod = getSelectedPaymentMethod(state)!;

            const { paymentGateway: gateway, token } = selectedPaymentMethod;

            const securedPayment: SecuredPaymentInfo = {
                gateway,
                token,
            };

            const paymentBehavior = getPaymentBehavior(selectedPaymentMethod);

            if (paymentBehavior?.securedPaymentInfoVisitor) {
                await paymentBehavior.securedPaymentInfoVisitor(dispatch, getState, securedPayment);
            }

            await orderApi.invoke("saveSecuredPaymentMethod", securedPayment);
        } catch (e) {
            // TODO: LION-1066 Use more specific message on error for payment security
            dispatch(showModalMessage(modalMessages.failedToSubmit()));

            throw e; // For app insights
        }
    });
};
