export const oneTimePassword = {
    isSupported: "OTPCredential" in window,
    otpFormat: "web",
    request: async function (signal: AbortSignal): Promise<string> {
        const content = await navigator.credentials.get({
            otp: {
                transport: ["sms"],
            },
            signal,
        } as any);

        const code: string = (content as any).code;

        return code;
    },
};
