import React from "react";
import { IMaskInputProps, IMaskMixin } from "react-imask";
import { Input, InputProps } from ".";

export type MaskedInputProps = IMaskInputProps & InputProps;

interface NumberMaskOptions {
    thousandsSeparatorSymbol?: string;
    decimalSeparatorSymbol?: string;
    max?: number;
}

interface CurrencyMaskOptions extends NumberMaskOptions {
    currencySymbol?: string;
    padDecimalPlaces?: boolean;
}

export const masks = {
    currency: ({
        thousandsSeparatorSymbol = ",",
        decimalSeparatorSymbol,
        max,
        currencySymbol = "",
        padDecimalPlaces,
    }: CurrencyMaskOptions) => ({
        mask: [
            {
                mask: "",
            },
            {
                mask: `${currencySymbol}num`,
                lazy: false,
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: thousandsSeparatorSymbol,
                        scale: decimalSeparatorSymbol ? 2 : 0,
                        signed: false,
                        mapToRadix: decimalSeparatorSymbol ? [decimalSeparatorSymbol] : [],
                        radix: decimalSeparatorSymbol,
                        max: max,
                        padFractionalZeros: padDecimalPlaces,
                    },
                },
            },
        ],
    }),
    percentage: ({ thousandsSeparatorSymbol = ",", decimalSeparatorSymbol, max }: NumberMaskOptions) => ({
        mask: [
            {
                mask: "",
            },
            {
                mask: "num%",
                lazy: false,
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: thousandsSeparatorSymbol,
                        scale: decimalSeparatorSymbol ? 2 : 0,
                        signed: false,
                        mapToRadix: decimalSeparatorSymbol ? [decimalSeparatorSymbol] : [],
                        radix: decimalSeparatorSymbol,
                        max: max,
                        min: 0,
                    },
                },
            },
        ],
    }),
};

const MaskedStyledInput = IMaskMixin<InputProps, HTMLInputElement>(({ inputRef, ...props }) => (
    <Input {...props} ref={inputRef} />
));

export const MaskedInput = (props: MaskedInputProps) => {
    return <MaskedStyledInput {...props} />;
};
