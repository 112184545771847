import { SelectablePaymentMethod } from "../../../common/payment";
import { orderApi } from "../../order/orderApi";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";

export async function removePaymentMethod(paymentMethod: SelectablePaymentMethod) {
    const { paymentGateway, token } = paymentMethod;

    const response = await orderApi.send(`/paymentgateway/${paymentGateway}/vaultedpaymentmethods/${token}`, {
        method: "DELETE",
        headers: getTableTokenHeaders(),
    });

    await ProblemDetailsError.throwError(response);
}
