import { ImageSet, VideoSet } from "src/features/menudata";
import { menuDataUrl } from "src/features/menudata/API/menuDataUrl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "../assets/MenuItemVideo.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { useInView } from "react-intersection-observer";

export interface Props {
    imageName: string;
    images: ImageSet;
    videos: VideoSet;
    placeholderName?: string;
    itemId: string;
}

export const MenuItemVideo = ({ placeholderName, imageName, images, videos, itemId }: Props) => {
    const relativeImage = images[imageName];
    const relativePlaceHolder = placeholderName ? images[placeholderName] : "";
    const image = menuDataUrl(relativeImage);
    const placeholder = menuDataUrl(relativePlaceHolder);
    const [backgroundImage, setBackgroundImage] = useState(placeholderName ? placeholder : image);
    const [fadeInVideo, setFadeInVideo] = useState(false);
    const loadStartTime = useRef<number>();
    const videoLoadTime = useRef<number>();
    const dispatch = useDispatch();
    const videoRef = useRef<HTMLVideoElement>();
    const trackingEventTriggered = useRef<boolean>(false);
    const { ref: inViewRef, inView } = useInView({
        threshold: 0.5,
        trackVisibility: true,
        delay: 100,
    });

    const setRefs = useCallback(
        (node) => {
            videoRef.current = node;
            inViewRef(node);
        },
        [inViewRef]
    );

    const trackEvent = useCallback(() => {
        trackingEventTriggered.current = true;
        dispatch(
            trackedEvent({
                type: "PARTY/VIDEO_PLAYED",
                item_id: itemId,
                loading_Time: videoLoadTime.current,
                duration: videoRef.current?.duration,
            })
        );
    }, [itemId, dispatch]);

    useEffect(() => {
        let timer: number | undefined;
        const video = videoRef.current;
        if (!trackingEventTriggered.current && fadeInVideo && video && inView) {
            timer = window.setTimeout(trackEvent, video.duration < 4 ? video.duration * 1000 : 4000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [inView, fadeInVideo, trackEvent]);

    useEffect(() => {
        if (placeholderName) {
            const imageLoad = new Image();
            imageLoad.onload = () => {
                setBackgroundImage(imageLoad.src);
            };
            imageLoad.src = image;
        }
    }, [placeholderName, image]);

    const handleCanPlayThrough = () => {
        if (loadStartTime.current) {
            videoLoadTime.current = Date.now() - loadStartTime.current;
            loadStartTime.current = undefined;
        }
    };

    //We've implemented a simple solution to only display videos when they autoplay.
    //Complex solution of preventing the video from being downloaded if it cannot be autoplayed or allowing user to click the play button could be considered in the future.
    const handlePlaying = () => {
        if (!fadeInVideo) {
            setFadeInVideo(true);
        }
    };

    const handleOnLoadStart = () => {
        loadStartTime.current = Date.now();
    };

    return (
        <div
            className="menuitemcard-video"
            style={
                {
                    "--video-background-image": `url("${backgroundImage}")`,
                } as React.CSSProperties
            }
        >
            <video
                ref={setRefs}
                loop
                muted
                //For video to play on iPhone without user action, autoPlay and playsInline attribute must exist
                autoPlay
                playsInline
                className={classNames("menuitemcard-video__header", fadeInVideo && "fade-in")}
                onCanPlayThrough={handleCanPlayThrough}
                onLoadStart={handleOnLoadStart}
                onPlaying={handlePlaying}
            >
                {Object.entries(videos).map(([key, value]) => (
                    <source key={key} src={menuDataUrl(value)} type={key} />
                ))}
            </video>
        </div>
    );
};
