import { DevicePaymentBehavior } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod } from "src/common/payment";
import { vaultStripeApplePayPaymentMethod, vaultStripeGooglePayPaymentMethod } from "./vaultStripeDevicePaymentMethod";
import { getStripeApplePayAvailable, getStripeGooglePayAvailable } from "./getStripeDevicePaymentAvailable";
import { initializeStripeApplePayButton, initializeStripeGooglePayButton } from "./initializeStripeDevicePaymentButton";

export const customGooglePayButtonId = "stripe-payment-request-button";

export const applePayBehavior: DevicePaymentBehavior<ApplePayPaymentMethod> = {
    getAvailable: getStripeApplePayAvailable,
    initializeButton: initializeStripeApplePayButton,
    vaultPaymentMethod: vaultStripeApplePayPaymentMethod,
};

export const googlePayBehavior: DevicePaymentBehavior<GooglePayPaymentMethod> = {
    getAvailable: getStripeGooglePayAvailable,
    initializeButton: initializeStripeGooglePayButton,
    vaultPaymentMethod: vaultStripeGooglePayPaymentMethod,
    customButtonId: customGooglePayButtonId,
};
