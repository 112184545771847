import { AppDispatch } from "src/features";
import { resetMarketing } from "src/features/marketing/actions/displayMarketing";
import { manageMembershipsActions } from "src/features/membership";
import { resetModalMessage } from "src/features/modalMessage/actions/reset";
import { createVerifiedAccountActions } from "src/features/signup";
import { resetRating } from "src/features/survey/actions/setRating";
import { actionCreators as historyActionCreators } from "../../orderHistoryData/reducers/mealHistory";
import { actionCreators as signInActionCreators } from "../../signup/reducers/signIn";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { actionCreators as activeTabActionCreators } from "../../groupTabs/reducers/activeGroupTab";
import { actionCreators as newTabActionCreators } from "../../groupTabs/reducers/groupTabWizard";
import { actionCreators as surveyActionCreators } from "../../survey/reducers/survey";

export const INITIAL_WAIT_PARTY_TIME = 20000; //milliseconds
export const MIN_JOIN_LOADING = 1500;

// Add any modal that shouldn't be open during join table
export function closeAllModals() {
    return (dispatch: AppDispatch) => {
        // history
        dispatch(historyActionCreators.selectPartyMealHistory(null, false));
        // marketing
        dispatch(resetMarketing());
        // rating
        dispatch(resetRating());
        // survey
        dispatch(surveyActionCreators.resetSurvey());
        // signIn
        dispatch(signInActionCreators.completeSignInFlow());
        // createVerifiedAccount
        dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
        // profile
        dispatch(accountMenuActionCreators.closeAccountMenu());
        // message
        dispatch(resetModalMessage());
        // manageMemberships
        dispatch(manageMembershipsActions.showManageMemberships(false));
        // manage group tab
        dispatch(activeTabActionCreators.showManageGroupTab(false));
        // share group tab
        dispatch(activeTabActionCreators.showInviteToGroupTab(false));
        // create group tab
        dispatch(newTabActionCreators.startGroupTabWizard(undefined));
    };
}
