import * as React from "react";
import { connect } from "react-redux";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { LinkProps } from "react-router-dom";
import { TappableLink } from "src/sharedComponents/common/tappable";
import { AppState } from "../..";
import { getActiveServiceMenuItemLinks, getVisibleMenuData } from "../../menu/selectors";

interface OwnProps extends RouteComponentProps<CategoryRouteParams> {
    menuItemId: string;
    className?: string;
    children: React.ReactNode;
    preselect?: boolean;
    onBeforeNavigate?: () => void;
    disabled?: boolean;
}

interface CategoryRouteParams {
    menuId?: string;
    categoryId?: string;
}

interface MenuItemLinkProxyProps extends LinkProps {
    onBeforeNavigate?: () => void;
    disabled?: boolean;
}

const MenuItemLinkProxy = ({ to, className, children, onBeforeNavigate, disabled }: MenuItemLinkProxyProps) => (
    <TappableLink to={to} className={className} onBeforeNavigate={onBeforeNavigate} disabled={disabled}>
        {children}
    </TappableLink>
);

function mapStateToProps(state: AppState, props: OwnProps): MenuItemLinkProxyProps {
    const { menuItemId, className, preselect, onBeforeNavigate, disabled } = props;
    let { menuId, categoryId } = props.match.params;

    const menuData = getVisibleMenuData(state)!;

    const useCurrentCategory = categoryId && menuData.categories[categoryId].menuItems.indexOf(menuItemId) !== -1;

    if (!useCurrentCategory) {
        const categoryIdFromCurrentMenu = menuId
            ? menuData.menus[menuId].categories.find(
                  (cid) => menuData.categories[cid].menuItems.indexOf(menuItemId) !== -1
              )
            : undefined;

        if (categoryIdFromCurrentMenu) {
            categoryId = categoryIdFromCurrentMenu;
        } else {
            const defaultMenuItemLink = getActiveServiceMenuItemLinks(state).get(menuItemId);
            return {
                className,
                to: `${defaultMenuItemLink}/${preselect ? "preselect" : "home"}` || "#",
                onBeforeNavigate,
                disabled,
            };
        }
    }

    return {
        to: generatePath(`/menu/:menuId/:categoryId/:menuItemId`, {
            menuId: menuId!,
            categoryId: categoryId!,
            menuItemId,
        }),
        className,
        onBeforeNavigate,
        disabled,
    };
}

export const MenuItemLink = withRouter(connect(mapStateToProps)(MenuItemLinkProxy));
