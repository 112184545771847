import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth";

export async function addMarketingOptIn(
    locationId: string,
    firstName: string | null,
    lastName: string | null,
    email: string,
    phone: string | null,
    partyId: string | null,
    partyType: string | null,
    signupSource: string | null
) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/marketing/${locationId}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            partyId,
            partyType,
            signupSource,
        }),
    });
    if (!response.ok) {
        throw response;
    }
}

export async function addGroupMarketingOptIn(
    groupId: string,
    firstName: string | null,
    lastName: string | null,
    email: string,
    phone: string | null,
    signupSource: string | null
) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send(`/marketing/group/${groupId}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            signupSource,
        }),
    });
    if (!response.ok) {
        throw response;
    }
}

export async function logMarketingOptIn(locationId: string, userRequest?: boolean) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    await orderApi.send(`/marketing/${locationId}`, {
        method: "PUT",
        headers,
        body: JSON.stringify({
            viewedSource: userRequest ? "inline" : "prompt",
        }),
    });
}
