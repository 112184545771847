import { connect } from "react-redux";
import { AppDispatch, AppState } from "../..";
import { OrderHistoryItemPage, Props } from "../components/OrderHistoryItemPage";
import * as React from "react";
import { OrderHistoryData } from "../../orderHistoryData";
import { actionCreators as historyActionCreators } from "../../orderHistoryData/reducers/mealHistory";
import {
    fetchPartyMealHistoryData,
    markMealHistoryAsCollected,
    markMealHistoryAsRead,
} from "../../orderHistoryData/actions/fetchMealHistory";
import { getHistoryDetail } from "../../orderHistoryData/selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { forceSendingReceiptEmail } from "../../payment/actions/forceSendingReceiptEmail";
import { actions as marketingActions } from "src/features/marketing/actions";
import { actionCreators as taxInvoiceActions } from "src/features/orderHistoryData/reducers/taxInvoice";
import { ModalContainer } from "src/common/modal";
import { getPreviousVenueLocationsDetails } from "src/features/takeaway/selectors/takeawayOptions";
import { DeliveryOptionType } from "src/features/order/types";

type PartialProps = Omit<Props, "partyId" | "historyDetail">;

interface HistoryItemModalProxyProps extends PartialProps {
    historyDetailData: OrderHistoryData | null;
    onClose: () => void;
    isOpen: boolean;
}

class MenuItemModalProxy extends React.Component<HistoryItemModalProxyProps> {
    componentDidUpdate(prevProps: HistoryItemModalProxyProps) {
        const { isOpen, onClose } = this.props;
        if (!isOpen && prevProps.isOpen) {
            setTimeout(onClose, 400);
        }
    }

    render() {
        const { isOpen, historyDetailData } = this.props;
        let tempProps = { ...this.props };

        return (
            <ModalContainer
                isOpen={isOpen && !!historyDetailData}
                className={{
                    base: "history-item-modal slide-in",
                    afterOpen: "history-item--after-open slide-in--after-open",
                    beforeClose: "history-item--before-close slide-in--before-close",
                }}
                overlayClassName="slide-in-modal--overlay history-item--overlay"
                contentLabel="History Item Modal"
                closeTimeoutMS={250}
                allowBodyScroll
            >
                {historyDetailData && (
                    <OrderHistoryItemPage
                        {...tempProps}
                        partyId={historyDetailData.partyId}
                        historyDetail={historyDetailData}
                        closeToBack
                    />
                )}
            </ModalContainer>
        );
    }
}

export function mapStateToProps(state: AppState): DataProps<HistoryItemModalProxyProps> {
    const mealHistoryData = state.orderHistoryData;
    const historyDetailData = getHistoryDetail(state);
    const {
        marketing: {
            optInData: { locationId },
        },
        modalMessage,
    } = state;

    const mealHistoryOrder = historyDetailData?.mealHistoryOrders?.[0];
    const isTakeaway = mealHistoryOrder?.deliveryOption?.type === DeliveryOptionType.TAKEAWAY;
    const isScheduledOrder = mealHistoryOrder?.isScheduled;
    const scheduledDate = mealHistoryOrder?.dateScheduled;
    const allVenuesCollectionDetails = getPreviousVenueLocationsDetails(state);
    const venueLocationId = historyDetailData?.summary?.locationId;
    const venueCollectionDetails =
        allVenuesCollectionDetails && venueLocationId ? allVenuesCollectionDetails[venueLocationId] : null;

    return {
        isOpen: !!mealHistoryData.selectedPartyOrderHistory && mealHistoryData.shouldOpen,
        historyDetailData,
        marketingIsOpen: !!locationId,
        modalOpen: !!modalMessage,
        selectedOrderHistoryData: mealHistoryData.selectedOrderHistoryData,
        isTakeaway,
        isScheduledOrder,
        scheduledDate,
        venueCollectionDetails,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<HistoryItemModalProxyProps> {
    return {
        onBack: () => {
            dispatch(historyActionCreators.selectPartyMealHistory(null, false));
        },
        sendReceiptEmail: (partyId: string) => dispatch(forceSendingReceiptEmail(partyId)),
        markAsRead: (partyId: string) => dispatch(markMealHistoryAsRead(partyId)),
        markAsCollected: (partyId: string) => dispatch(markMealHistoryAsCollected(partyId)),
        onClose: () => {
            dispatch(historyActionCreators.selectPartyMealHistory(null, false));
        },
        getMarketingMetaData: (locationId: string) => dispatch(marketingActions.marketingMetaData(locationId)),
        getPartyMealHistoryData: (partyId: string) => dispatch(fetchPartyMealHistoryData(partyId)),
        trackInvoiceRequested: () => dispatch(taxInvoiceActions.trackInvoiceRequested()),
        selectPartyMealHistory: (partyId: string) =>
            dispatch(historyActionCreators.selectPartyMealHistory(partyId, null)),
    };
}

export const OrderHistoryItemModalContainer = connect(mapStateToProps, mapDispatchToProps)(MenuItemModalProxy);
