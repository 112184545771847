import { createSelector } from "reselect";
import { href } from "src/common/experience";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { getParty, PartyType } from "src/features/order";
import { getAnyParty } from "src/features/order/selectors";
import { regionHelper } from "src/features/region";
import { getLastTableData } from "../persistence/lastTableData";

export const getSupportEmailAttributes = createSelector(
    getAnyParty,
    getVisibleMenuData,
    getLastTableData,
    getParty,
    getCurrentMemberId,
    (anyParty, menudata, lastActiveInfo, party, userId) => {
        let mailTo: string[] = [];
        if ((anyParty && menudata) || lastActiveInfo) {
            mailTo = [
                `Location: ${menudata?.title ?? lastActiveInfo!.location}`,
                `Party ID: ${anyParty?.id ?? lastActiveInfo!.partyId}`,
                `Table: ${anyParty?.tableNumber ?? lastActiveInfo!.table}`,
                `Time: ${anyParty?.dateOpened ?? lastActiveInfo!.time}`,
                `Locale: ${menudata?.locale ?? lastActiveInfo!.locale}`,
            ];
        }
        mailTo = mailTo.concat([`Live: ${!!party}`, `Member ID: ${encodeURIComponent(userId)}`]);
        return href.openInNewWindowAttributes(
            `mailto:${regionHelper.region.contactDetails?.helpEmail || "helpme@meandu.com.au"}?subject=me%26u ${
                anyParty?.type === PartyType.PAYONLY ? "Pay" : "app"
            } support&body=Help Center information: ${mailTo.join(" • ")}`
        );
    }
);
