import { AppDispatch, AppState } from "src/features";
import { getMarketingMetadata } from "src/features/marketing/api";
import { getAnyParty } from "src/features/order/selectors";
import { getMarketingInfoOperation } from "../operations";
import { actionCreators } from "../reducers/payOnlyGetReceipt";
import { regionHelper } from "src/features/region";

export const getMarketingInfo = () => {
    return getMarketingInfoOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const locationId = getAnyParty(getState())?.restaurantId;

        if (!locationId) return;

        const { enabled, newsletterProvider, privacyPolicyUrl, title } = await getMarketingMetadata(locationId);

        if (enabled && newsletterProvider) {
            const defaultChecked = !regionHelper.region.isGdpr;
            dispatch(actionCreators.setMarketingMetadata(newsletterProvider, defaultChecked, privacyPolicyUrl, title));
        } else {
            dispatch(actionCreators.ressetMarketingMetadata());
        }
    });
};
