import "./Tooltip.scss";

import React, { useCallback, PropsWithChildren, useState, useEffect, CSSProperties } from "react";
import TappableDiv from "../../common/tappable/components/TappableDiv";
import { CloseIcon } from "../../assets/icons";
import classNames from "classnames";
import { usePrevious } from "../../common/shared";

const FADE_IN_CLASSNAME = "show-tooltip";
const FADE_OUT_CLASSNAME = "hide-tooltip";

export interface BaseProps {
    onClose?: () => void;
    tooltipContent: React.ReactNode;
    showClose?: boolean;
    arrowYPosition?: "top" | "bottom";
    arrowXPosition?: "left" | "center" | "right" | "element-center";
    show: boolean;
    innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
    className?: string;
}

export type TooltipProps = PropsWithChildren<BaseProps>;

export const InnerTooltip = ({
    onClose,
    children,
    showClose,
    arrowYPosition = "bottom",
    arrowXPosition = "center",
    tooltipContent,
    show,
    innerRef,
    className,
}: TooltipProps) => {
    const [animateClassname, setAnimateClassname] = useState("");
    const [elementCenterLeft, setElementCenterLeft] = useState<string | undefined>();
    const [visible, setVisible] = useState(false);
    const prevShow = usePrevious(show);

    const handleDismiss = useCallback(() => {
        if (show) {
            setAnimateClassname(FADE_OUT_CLASSNAME);
        }
    }, [show]);

    const onAnimationEnd = useCallback(
        (event: React.AnimationEvent<HTMLDivElement>) => {
            if (event.animationName === "fade-in-reverse") {
                // end of hide-tooltip animation
                setVisible(false);
                if (show) {
                    onClose?.();
                }
            }
        },
        [onClose, show]
    );

    useEffect(() => {
        if ((!prevShow && show) || (prevShow && show && animateClassname === "")) {
            setAnimateClassname(FADE_IN_CLASSNAME);
            setVisible(true);
        } else if (prevShow && !show) {
            setAnimateClassname(FADE_OUT_CLASSNAME);
        }
    }, [prevShow, show, animateClassname]);

    const updateEelementPosition = useCallback((element: HTMLDivElement | null) => {
        if (!element) {
            return;
        }
        const position = element.getBoundingClientRect();
        setElementCenterLeft(`${position.left + position.width / 2}px`);
    }, []);

    if (!visible) {
        return <>{children}</>;
    }

    return (
        <TappableDiv
            className="tooltip-wrapper"
            ref={arrowXPosition === "element-center" ? updateEelementPosition : undefined}
            onTap={showClose || onClose ? handleDismiss : undefined}
        >
            {children}
            <div
                className={classNames("tooltip", arrowYPosition, arrowXPosition, animateClassname, className)}
                onAnimationEnd={onAnimationEnd}
                style={{ "--element-left": elementCenterLeft } as CSSProperties}
            >
                <span className="tooltip__content" ref={innerRef}>
                    {tooltipContent}
                </span>
                {showClose && (
                    <div className="tooltip__close">
                        <CloseIcon />
                    </div>
                )}
            </div>
        </TappableDiv>
    );
};

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>((props, ref?) => (
    <InnerTooltip innerRef={ref} {...props} />
));
