import { createSelector } from "reselect";
import { AppState } from "src/features";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { getPartySubmittedOrders } from "src/features/order/selectors";
import { getActiveServiceMenuItemIds, getVisibleMenuData } from "src/features/menu/selectors";
import { Indexed, LocationMenuData, MenuItemModifier } from "src/features/menudata";
import {
    Order,
    OrderItem,
    OrderItemBase,
    OrderItemNestedModifier,
    OrderItemOptionNestedModifier,
    OrderItemSelectedNestedModiferData,
} from "src/features/order/types";
import { calculateOrderItemTotal } from "src/features/order/selectors/util/calculateOrderTotal";
import { getPriceResolver } from "src/features/membership/selectors/getPriceResolver";

import { RoundItem } from "../types";
import { getIsSameItem } from "../helpers/getIsSameItem";

export const getAnotherRoundItems = (state: AppState) => state.orderAgain.anotherRound?.items;

let orders: Order[] = [];

export const getPartySubmittedOrdersIfChanged = createSelector(getPartySubmittedOrders, (submittedOrders) => {
    if (submittedOrders.length > orders.length) {
        orders = submittedOrders;
    }
    return orders;
});

export const getShowPreviousPartyAnotherRound = createSelector(
    getCurrentMemberId,
    getPartySubmittedOrders,
    (currentMemberId, partySubmittedOrders) =>
        !partySubmittedOrders.length ||
        !partySubmittedOrders.find(({ items }) =>
            items.find(({ memberId, menuItemType }) => memberId === currentMemberId && menuItemType === "drink")
        )
);

const injectSelectedNestedDataRecursive = (
    optionNestedModifiers: OrderItemOptionNestedModifier[] | undefined,
    visibleMenuData: LocationMenuData
): OrderItemOptionNestedModifier[] | undefined => {
    return optionNestedModifiers?.map((optionNestedModifier) => ({
        ...optionNestedModifier,
        modifiers:
            optionNestedModifier.modifiers?.map((nestedModifier) => ({
                ...nestedModifier,
                optionNestedModifiers: injectSelectedNestedDataRecursive(
                    nestedModifier.optionNestedModifiers,
                    visibleMenuData
                ),
            })) ?? ([] as OrderItemNestedModifier[]),
        selectedNestedData: optionNestedModifier.modifiers.reduce((selectedNestedData, { modifierId, options }) => {
            selectedNestedData.push(
                ...options.map((option) => {
                    const modifierOption = visibleMenuData.modifiers?.[modifierId]?.options.find(
                        ({ originalIndex }) => option === originalIndex
                    );

                    return { price: modifierOption?.availablePrices, required: false };
                })
            );

            return selectedNestedData;
        }, [] as OrderItemSelectedNestedModiferData[]),
    }));
};

const injectSelectedNestedData = (orderItemBase: OrderItemBase, visibleMenuData: LocationMenuData): OrderItemBase => {
    return {
        ...orderItemBase,
        modifiers:
            orderItemBase.modifiers?.map((modifier) => ({
                ...modifier,
                optionNestedModifiers: injectSelectedNestedDataRecursive(
                    modifier.optionNestedModifiers,
                    visibleMenuData
                ),
            })) ?? [],
    };
};

const getIsEveryNestedModifierAvailableRecursive = (
    optionNestedModifiers?: OrderItemOptionNestedModifier[],
    visibleMenuDataModifiers?: Indexed<MenuItemModifier>
): boolean =>
    optionNestedModifiers?.every(({ modifiers }) =>
        modifiers.every(
            ({ modifierId, options, optionNestedModifiers: nestedOptionNestedModifiers }) =>
                options.every(
                    (option) =>
                        visibleMenuDataModifiers?.[modifierId].options.find(
                            ({ originalIndex }) => originalIndex === option
                        )?.available !== false
                ) && getIsEveryNestedModifierAvailableRecursive(nestedOptionNestedModifiers, visibleMenuDataModifiers)
        )
    ) !== false;

const isItemAvailable = (
    item: RoundItem | OrderItem,
    activeServiceMenuItemIds: Indexed<boolean>,
    visibleMenuData: LocationMenuData
) =>
    item.itemId &&
    visibleMenuData.items[item.itemId] &&
    activeServiceMenuItemIds[item.itemId] &&
    visibleMenuData.items[item.itemId].available !== false &&
    visibleMenuData.items[item.itemId].variants?.find(({ originalIndex }) => originalIndex === item.variant)
        ?.available !== false &&
    item.modifiers?.every(({ modifier: modifierIndex, options, optionNestedModifiers }) => {
        const modifier = visibleMenuData.items[item.itemId!].modifiers?.find((_, index) => index === modifierIndex);
        return (
            modifier?.available !== false &&
            options.every(
                (option) => modifier?.options.find(({ originalIndex }) => originalIndex === option)?.available !== false
            ) &&
            getIsEveryNestedModifierAvailableRecursive(optionNestedModifiers, visibleMenuData.modifiers)
        );
    }) !== false;

export const getOpenTableAnotherRoundItems = createSelector(
    getPriceResolver,
    getVisibleMenuData,
    getActiveServiceMenuItemIds,
    getCurrentMemberId,
    getAnotherRoundItems,
    getPartySubmittedOrdersIfChanged,
    getShowPreviousPartyAnotherRound,
    (
        priceResolver,
        visibleMenuData,
        activeServiceMenuItemIds,
        currentMemberId,
        anotherRoundItems,
        partySubmittedOrders,
        showPreviousePartyAnotherRound
    ) => {
        const roundItems: RoundItem[] = [];
        if (!visibleMenuData) return roundItems;

        if (showPreviousePartyAnotherRound) {
            return anotherRoundItems
                ?.filter((item) => isItemAvailable(item, activeServiceMenuItemIds, visibleMenuData))
                .map((roundItem) => {
                    const menuItem = visibleMenuData.items[roundItem.itemId];
                    const orderItemBase = injectSelectedNestedData(roundItem, visibleMenuData);

                    return {
                        ...roundItem,
                        available: true,
                        waitTime: menuItem?.waitTime,
                        price: calculateOrderItemTotal(menuItem, orderItemBase, priceResolver),
                    };
                });
        }

        [...partySubmittedOrders]
            .reverse()
            .filter(({ acceptedMembers }) => acceptedMembers.includes(currentMemberId))
            .forEach((submittedOrder) => {
                [...submittedOrder.items]
                    .filter((item) => isItemAvailable(item, activeServiceMenuItemIds, visibleMenuData))
                    .forEach((orderItem) => {
                        if (!orderItem.itemId || orderItem.menuItemType !== "drink") return;

                        const duplicatedRoundItemIndex = roundItems.findIndex((roundItem) =>
                            getIsSameItem(roundItem, orderItem)
                        );

                        if (duplicatedRoundItemIndex !== -1) {
                            if (roundItems[duplicatedRoundItemIndex]?.memberId !== currentMemberId) {
                                roundItems.splice(duplicatedRoundItemIndex, 1);
                            } else {
                                return;
                            }
                        }

                        const [displayName, variantName] = orderItem.displayName?.split(" / ") ?? [];
                        const menuItem = visibleMenuData.items[orderItem.itemId];
                        const orderItemBase = injectSelectedNestedData(orderItem, visibleMenuData);

                        const energyContent =
                            orderItem.variant === null
                                ? menuItem?.energyContent
                                : menuItem?.variants?.[orderItem.variant]?.energyContent;

                        roundItems.push({
                            ...orderItem,
                            displayName,
                            variantName: variantName ?? null,
                            itemId: orderItem.itemId,
                            categoryId: orderItem.categoryId!,
                            available: true,
                            images: menuItem?.images,
                            waitTime: menuItem?.waitTime,
                            energyContent,
                            price: calculateOrderItemTotal(menuItem, orderItemBase, priceResolver),
                        });
                    });
            });

        return roundItems
            .sort((a, b) => (a.memberId === b.memberId ? 0 : a.memberId === currentMemberId ? -1 : 1))
            .slice(0, 8);
    }
);
