import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AppState } from "../../index";
import { actionCreators, PartyNotification } from "../../order";
import { getModalNotification } from "../selectors";
import { auth } from "../../../common/auth";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { showModalMessage } from "../../modalMessage/actions/show";
import { JoinTableStep } from "../../partyOnboarding/components/JoinTablePage";
import { getJoinTableStep } from "../../partyOnboarding/selectors";

export interface Props {
    notification?: PartyNotification;
    step: JoinTableStep;
    setViewed: (notificationId: string) => void;
    selectChoice: (notificationId: string, choice: string) => void;
    showModalMessage: (modalMessage: ModalMessage) => void;
}

const ModalNotificationInner: React.FC<Props> = ({ notification, step, setViewed, selectChoice, showModalMessage }) => {
    useEffect(() => {
        if (!notification || step !== "TouchToStart") return;

        const closeAndSelectChoice = (choice?: string) => {
            setViewed(notification.id);
            if (choice) selectChoice(notification.id, choice);
        };

        if (notification.choices.length > 1) {
            showModalMessage({
                type: "action",
                title: notification.title,
                text: notification.shortDescription ?? notification.longDescription,
                primaryAction: () => closeAndSelectChoice(notification.choices[0].name),
                primaryActionText: notification.choices[0].name,
                secondaryAction: () => closeAndSelectChoice(notification.choices[1].name),
                secondaryActionText: notification.choices[1].name,
                useLocationTheme: true,
                fromServer: true,
            });
        } else {
            showModalMessage({
                type: "info",
                title: notification.title,
                text: notification.longDescription ?? undefined,
                importantText: notification.shortDescription ?? undefined,
                primaryAction: () => closeAndSelectChoice(notification.choices[0]?.name),
                primaryActionText: notification.choices[0]?.name,
                useLocationTheme: true,
                mustAction: true,
                fromServer: true,
            });
        }
    }, [notification, step, setViewed, selectChoice, showModalMessage]);

    return null;
};

function mapStateToProps(state: AppState): DataProps<Props> {
    const userId = auth.getId();
    const notification = userId ? getModalNotification(state) : undefined;
    const step = getJoinTableStep(state);

    return {
        notification,
        step,
    };
}

function mapDispatchToProps(dispatch: any): FunctionProps<Props> {
    return {
        setViewed: (notificationId: string) => dispatch(actionCreators.viewNotification([notificationId])),
        selectChoice: (notificationId: string, choice: string) =>
            dispatch(actionCreators.selectNotificationChoice(notificationId, choice)),
        showModalMessage: (modalMessage: ModalMessage) => dispatch(showModalMessage(modalMessage)),
    };
}

export const ModalNotification = connect(mapStateToProps, mapDispatchToProps)(ModalNotificationInner);
