import { BeginPaymentResult } from "../../payment";
import {
    ApplePayPaymentMethod,
    CardPaymentMethod,
    GooglePayPaymentMethod,
    NewCardPaymentMethod,
} from "../../../common/payment";
import { hostedFieldOptions } from "../../paymentMethods/types";

export type StripePaymentMethod = {
    apiKey: string;
};

export type StripeCardPaymentMethod = CardPaymentMethod & StripePaymentMethod;
export type StripeNewCardPaymentMethod = NewCardPaymentMethod & StripePaymentMethod;
export type StripeApplePayPaymentMethod = ApplePayPaymentMethod & StripePaymentMethod;
export type StripeGooglePayPaymentMethod = GooglePayPaymentMethod &
    StripePaymentMethod & {
        googlePayMerchantName: string;
    };

export interface StripeCreatePaymentIntentResult extends BeginPaymentResult {
    paymentIntentId: string;
    clientSecret: string;
    requiresAction: boolean;
}

export enum StripeHostedField {
    Number = "cardNumber",
    Expiry = "cardExpiry",
    CVV = "cardCvc",
}

export const stripeElementOptions = {
    style: {
        base: {
            color: hostedFieldOptions.color,
            fontFamily: hostedFieldOptions.fontFamily,
            fontSize: hostedFieldOptions.fontSize,
            "::placeholder": {
                color: hostedFieldOptions.placeholderColor,
            },
        },
        invalid: {
            color: hostedFieldOptions.errorColor,
        },
    },
};
