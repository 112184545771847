const foregroundFuncs: Array<() => void> = [];
const backgroundFuncs: Array<() => void> = [];

export const appForegroundHandler = (onForeground: () => void, onBackground: () => void) => {
    foregroundFuncs.push(onForeground);
    backgroundFuncs.push(onBackground);
};

export function onPause() {
    backgroundFuncs.forEach((backgroundFunc) => backgroundFunc());
}
export function onResume() {
    foregroundFuncs.forEach((foregroundFunc) => foregroundFunc());
}
