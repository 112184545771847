import { OfferData, OfferStatus, OfferType } from "./types";

export const unavailableOfferStatuses = [OfferStatus.CLAIMED, OfferStatus.REDEEMED, OfferStatus.EXPIRED];

export const getOfferTypeString = (offer?: OfferData) => {
    switch (offer?.offerType) {
        case OfferType.DISCOUNT:
            return "offer";
        case OfferType.STAMP_CARD:
            return "stamp card";
        default:
            return "offer";
    }
};
