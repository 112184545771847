export const TEMP_TAG_GROUP = {
    id: "5b6590ae49634b2c1c312f99",
    displayName: "Dietary requirements",
    filterLabel: "Select your preferences",
    filterMode: "all",
    isUserPreference: true,
    scope: "food",
    tags: [
        {
            id: "5b6636573c918222a06286d3",
            displayName: "Dairy Free",
            icon: "dietary-dairyfree",
            selected: false,
        },
        {
            id: "5b6636573c918222a06286d4",
            displayName: "Gluten Free",
            icon: "dietary-glutenfree",
            selected: false,
        },
        {
            id: "5b6636573c918222a06286d5",
            displayName: "Vegan",
            icon: "dietary-vegan",
            selected: false,
        },
        {
            id: "5b6636573c918222a06286d6",
            displayName: "Vegetarian",
            icon: "dietary-vegetarian",
            selected: false,
        },
        {
            id: "5b6636573c918222a06286d7",
            displayName: "Halal",
            icon: "dietary-halal",
            selected: false,
        },
        {
            id: "5bd6a18a58f3bc5e586d14a2",
            displayName: "Kosher",
            icon: "dietary-kosher",
            selected: false,
        },
        {
            id: "5c6df5397a72940133452ac2",
            displayName: "Mild",
            icon: "dietary-mild-spicy",
            selected: false,
        },
        {
            id: "5b725840d966d4723878bae7",
            displayName: "Spicy",
            icon: "dietary-spicy",
            selected: false,
        },
        {
            id: "5c6df5397a72940133452ac1",
            displayName: "Nut Free",
            icon: "dietary-nutfree",
            selected: false,
        },
        {
            id: "5b6636573c918222a06286d9",
            displayName: "No Garlic",
            icon: "dietary-nogarlic",
            selected: false,
        },
    ],
};
