import React, { ReactNode } from "react";
import { Text } from "src/sharedComponents";

interface Props {
    textOne: ReactNode;
    textTwo: ReactNode;
}

export const MembershipCallToActionText = ({ textOne, textTwo }: Props) => (
    <>
        <Text preset="g-14" mode="block">
            {textOne}
        </Text>
        <Text preset="g-14" mode={["bold", "block"]}>
            {textTwo}
        </Text>
    </>
);
