import { AppDispatch, AppMiddleware } from "..";
import { AnyAction } from "redux";
import { TypeKeys } from "./reducers/preview";
import { config } from "src/common/config";
import { fetchActions } from "src/features/menudata/reducers/active";
import { History } from "history";
import { updatePrices } from "../menudata/mutators";
import { splashScreen } from "src/common/experience";
import { Region, regionHelper } from "../region";
import { getRestaurantFlagsOperation } from "../order/operations";
import { RestaurantFlagsState } from "../order/reducers/restaurantFlags";

// Check the URL on initialize - this is the only way to handle other early checks, like the PayPal auto-close in multiInstanceMiddleware
export const isPreview = /\/preview$/.test(window.location.pathname);
export const isExtension = () => window.sessionStorage.getItem("TECH_EXT") === "true";
export const isGoogleTagManagerPreview = new URL(window.location.href).searchParams.has("gtm_debug");

export const previewMiddleware = (history: History) => (store: AppMiddleware) => {
    function enablePreview() {
        window.opener.postMessage({ type: "previewReady" }, config.REACT_APP_CMS_ORIGIN);

        document.title = "me&u - Preview Mode";

        window.addEventListener("message", handlePreviewMessage);
    }

    function handlePreviewMessage(e: MessageEvent) {
        if (e.origin === config.REACT_APP_CMS_ORIGIN) {
            if (e.data?.type === "setPreviewMenuData") {
                let menuData = e.data.menuData;
                const serviceId = e.data.serviceId;
                const membershipLevelId = e.data.membershipLevelId;

                regionHelper.region = e.data.region as Region;

                splashScreen.hide();

                menuData = updatePrices(menuData, serviceId, membershipLevelId);

                store.dispatch(fetchActions.loaded("#preview", menuData, serviceId));

                store.dispatch(getRestaurantFlagsOperation.actionCreators.completed({} as RestaurantFlagsState));

                history.replace("/menu/service");
            }
        }
    }

    return (next: AppDispatch) => (action: AnyAction) => {
        if (action?.type === TypeKeys.INITIALIZE) {
            enablePreview();
        }

        next(action);
    };
};
