import { ReactElement } from "react";

import { useIsActiveABTest } from "../hooks/useIsActiveABTest";

export interface ABTestProps {
    testName: string;
    A: () => ReactElement | null;
    B: () => ReactElement | null;
}

export const ABTest = ({ testName, A, B }: ABTestProps) => {
    const isActive = useIsActiveABTest(testName);

    if (isActive === null) return null;

    if (isActive) {
        // If we're part of the test render the test content
        return B();
    }

    return A(); // Render the control content
};
