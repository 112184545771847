import { AppState } from "src/features";
import { DataProps } from "src/common/types/utils";
import { Props, FeaturedItem } from "../components/FeaturedItem";
import { connect } from "react-redux";
import { getFeaturedItem } from "../selectors/getFeaturedItem";

function mapStateToProps(state: AppState): DataProps<Props> {
    const featured = getFeaturedItem(state);

    return {
        title: featured?.title!,
        itemId: featured?.id,
        categoryId: featured?.categoryId,
    };
}

export const FeaturedItemContainer = connect(mapStateToProps)(FeaturedItem);
