import { AuthorizePaymentResult, GooglePayPaymentMethod } from "src/common/payment";
import { getBraintreeClientInstance } from "src/common/braintree";
import { BraintreeGooglePayPaymentMethod } from "src/features/paymentGateways/braintree/types";
import { cordovaWindow } from "../../cordova";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

export const initializeBraintreeGooglePayButton: InitializeDevicePaymentMethodButtonFunc<
    GooglePayPaymentMethod
> = async (googlePayPaymentMethod, total, { getPaymentSessionToken }) => {
    const { currency, googlePayMerchantId } = googlePayPaymentMethod as BraintreeGooglePayPaymentMethod;

    const token = await getPaymentSessionToken();

    return () =>
        new Promise<AuthorizePaymentResult | null>((resolve, reject) =>
            cordovaWindow.BraintreeDevicePayments.authorizeGooglePayPayment(
                currency,
                googlePayMerchantId ?? null,
                total.toFixed(2),
                token,
                async (result) => {
                    if (result) {
                        const { deviceData } = await getBraintreeClientInstance(token);
                        result.additionalFraudProtectionData = deviceData;
                    }
                    resolve(result);
                },
                reject
            )
        );
};
