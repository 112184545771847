import { loadExternalScript } from "../../shared";
import { config } from "../../config";
import { SsoBehaviors } from "../interface";
import { Identity } from "../../sso";

let googleSuccessCallback: (response: google.accounts.id.CredentialResponse) => void;

export const sso: SsoBehaviors = {
    Google: {
        isAvailable: () => true,
        async initialize() {
            await loadExternalScript("google.accounts.id", "https://accounts.google.com/gsi/client");
            google.accounts.id.initialize({
                client_id: config.googleSignInClientId!,
                callback: (response) => {
                    googleSuccessCallback(response);
                },
            });
        },
        renderButton(buttonElement) {
            google.accounts.id.disableAutoSelect();
            google.accounts.id.renderButton(buttonElement, {
                type: "standard",
                theme: "outline",
                size: "large",
                shape: "rectangular",
                logo_alignment: "center",
                width: `${buttonElement.clientWidth}px`,
            });
        },
        signIn() {
            return new Promise<Identity | null>((resolve) => {
                googleSuccessCallback = (response) => {
                    resolve({
                        token: response.credential,
                    });
                };
            });
        },
    },
    Apple: {
        isAvailable: () => true,
        async initialize() {
            await loadExternalScript(
                "AppleID.auth",
                "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            );
            AppleID.auth.init({
                clientId: config.appleSignInServiceId,
                scope: "name email",
                redirectURI: config.REACT_APP_APP_BASE_URL,
                usePopup: true,
            });
        },
        async signIn() {
            try {
                const {
                    authorization: { id_token: token },
                    user,
                } = await AppleID.auth.signIn();

                return {
                    token,
                    firstName: user?.name?.firstName,
                    lastName: user?.name?.lastName,
                    email: user?.email,
                };
            } catch (err) {
                if (err.error === "popup_closed_by_user") return null;
                throw err;
            }
        },
    },
    Facebook: {
        isAvailable: () => true,
        async initialize() {
            await loadExternalScript("FB", "https://connect.facebook.net/en_US/sdk.js", { id: "facebook-jssdk" });
            FB.init({
                appId: config.facebookSignInAppId,
                version: "v15.0",
            });
        },
        signIn() {
            return new Promise<Identity | null>((resolve, reject) => {
                FB.login(
                    (response) => {
                        switch (response.status) {
                            case "connected":
                                return resolve({
                                    token: response.authResponse.accessToken,
                                });
                            case "unknown": // User closed popup window
                                return resolve(null);
                            default:
                                return reject(new Error(response.status));
                        }
                    },
                    { scope: "public_profile,email" }
                );
            });
        },
    },
};
