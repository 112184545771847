import "./PayOnlyHeader.scss";

import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPayOnlyPaidAmount,
    getPayOnlyPaymentOrder,
    getPayOnlyRefreshing,
    getPayOnlyRemainingBalance,
    getPayOnlyUnseenPayments,
} from "../selectors";
import { PulseLoader, Text } from "../../../sharedComponents";
import { Price } from "../../menu/components/Price";
import { BackIconLarge, CheckIcon, CloseIconLarge, FeedIcon } from "../../../sharedComponents/assets/icons";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import classNames from "classnames";
import { SimpleNavProps } from "../../../common/navigation/components/SimpleNavHeader";
import { NativeBackButton } from "../../../common/navigation";
import { getPendingPayments } from "../../payment/selectors";
import { payOnlyPaymentsFeedWizard, payOnlyPaymentWizard } from "../wizards";
import { getParty } from "../../order";
import { actionCreators as payOnlyFlagsActionCreators } from "../reducers/payOnlyFlags";

interface PayOnlyHeaderProps extends SimpleNavProps {}

interface PayOnlyHeaderContextProps extends PayOnlyHeaderProps {
    setProps?: (props: PayOnlyHeaderProps) => void;
}

const PayOnlyHeaderContext = createContext<PayOnlyHeaderContextProps>({});

export const PayOnlyHeaderPropsProvider = ({ children }: { children?: ReactNode }) => {
    const [props, setProps] = useState<PayOnlyHeaderProps>({});
    return <PayOnlyHeaderContext.Provider value={{ ...props, setProps }}>{children}</PayOnlyHeaderContext.Provider>;
};

export const PayOnlyHeaderSpacer = ({ children }: { children?: ReactNode }) => {
    const paidAmount = useSelector(getPayOnlyPaidAmount);
    const party = useSelector(getParty);
    return (
        <div className={classNames("pay-only-header__height", !!paidAmount && party && "with-banner")}>{children}</div>
    );
};

let proxies: number[] = [];

export const PayOnlyHeaderProxy = (props: PayOnlyHeaderProps) => {
    const { setProps } = useContext(PayOnlyHeaderContext);
    const proxyRef = useRef(new Date().valueOf());

    useEffect(() => {
        const id = proxyRef.current;
        proxies.unshift(id);
        return () => {
            proxies = proxies.filter((p) => p !== id);
        };
    }, []);

    useEffect(() => {
        if (proxies[0] === proxyRef.current) {
            setProps?.(props);
        }
    }, [props, setProps]);

    const { customBack, onBack } = props;

    if (!onBack) return <PayOnlyHeaderSpacer />;

    return (
        <NativeBackButton name={customBack ?? ""} onPressed={onBack}>
            <PayOnlyHeaderSpacer />
        </NativeBackButton>
    );
};

interface PayOnlyHeaderOwnProps {
    disableUnseenPayments?: boolean;
}

export const PayOnlyHeader = ({ disableUnseenPayments }: PayOnlyHeaderOwnProps) => {
    const dispatch = useDispatch();
    const { onBack, closeToBack, title } = useContext(PayOnlyHeaderContext);

    const { currentPage } = useSelector(payOnlyPaymentWizard.getState) ?? {};
    const remainingBalance = useSelector(getPayOnlyRemainingBalance);
    const unseenPayments = useSelector(getPayOnlyUnseenPayments);
    const paidAmount = useSelector(getPayOnlyPaidAmount);
    const pendingPayments = useSelector(getPendingPayments);
    const order = useSelector(getPayOnlyPaymentOrder);
    const party = useSelector(getParty);
    const refreshing = useSelector(getPayOnlyRefreshing);

    const [displayRemainingBalance, setDisplayRemainingBalance] = useState(remainingBalance);
    const [displayPendingPayments, setDisplayPendingPayments] = useState(false);
    const displayPendingPaymentsStart = useRef(0);

    useEffect(() => {
        let timer: number | undefined;

        if (pendingPayments.length > 0) {
            displayPendingPaymentsStart.current = performance.now();
            setDisplayPendingPayments(true);
        } else if (displayPendingPaymentsStart.current > 0) {
            const elapsed = performance.now() - displayPendingPaymentsStart.current;
            if (elapsed > 450) {
                setDisplayPendingPayments(false);
            } else {
                timer = window.setTimeout(() => setDisplayPendingPayments(false), 500 - elapsed);
            }
        }

        return () => {
            clearTimeout(timer);
        };
    }, [pendingPayments]);

    useEffect(() => {
        dispatch(payOnlyFlagsActionCreators.setDisplayPendingPayments(displayPendingPayments));
    }, [dispatch, displayPendingPayments]);

    useEffect(() => {
        remainingBalance > 0 && setDisplayRemainingBalance(remainingBalance);
    }, [remainingBalance]);

    const showPayments = useCallback(() => {
        dispatch(payOnlyPaymentsFeedWizard.actionCreators.standalone("PaymentsFeed"));
    }, [dispatch]);

    const hasUnseenPayments = !disableUnseenPayments && unseenPayments > 0;

    return (
        <div className="pay-only-header">
            <div className="pay-only-header__left-to-pay">
                <div className="pay-only-header__left-to-pay__center">
                    {currentPage !== "SplitOptionConfiguration" && refreshing ? (
                        <PulseLoader />
                    ) : (
                        !!order?.items.length &&
                        party && (
                            <>
                                {!title && (
                                    <Price
                                        priceTextPreset="g-16"
                                        priceTextMode={["extra-bold", "block"]}
                                        price={displayRemainingBalance}
                                    />
                                )}
                                <Text preset="g-16" mode={["bold", "block"]} value={title ?? "to pay"} />
                            </>
                        )
                    )}
                </div>
                {onBack && (
                    <TappableDiv onTap={onBack} className="pay-only-header__left-to-pay__left">
                        {closeToBack ? <CloseIconLarge /> : <BackIconLarge />}
                    </TappableDiv>
                )}
                <TappableDiv onTap={showPayments} className="pay-only-header__left-to-pay__right">
                    <div className={classNames(hasUnseenPayments && "pay-only-header__left-to-pay__right--alert")}>
                        <FeedIcon />
                    </div>
                    <Text preset="g-14" mode={["bold", "block"]} value="Payments" />
                </TappableDiv>
            </div>
            {!!paidAmount && party && (
                <div className="pay-only-header__paid">
                    <CheckIcon />
                    <Price
                        prefix={"You’ve paid "}
                        priceTextPreset="g-14"
                        priceTextMode="block"
                        price={paidAmount}
                        suffix={" of the total"}
                    />
                </div>
            )}
        </div>
    );
};
