import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth";
import { TermsUpdate } from "../reducers/types";

export async function getLatestTermsUpdate() {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send("/termsupdates/latest", {
        method: "GET",
        headers,
    });
    if (!response.ok) {
        throw response;
    }
    return (await response.json()) as TermsUpdate | null;
}

export async function acceptTermsUpdate() {
    const headers = await getAuthHeaders();
    const response = await orderApi.send("/termsupdates/accept", {
        method: "POST",
        headers,
    });
    if (!response.ok) {
        throw response;
    }
}
