import { connect } from "react-redux";
import { AppDispatch, AppState } from "src/features";
import { DataProps, FunctionProps } from "../../../common/types/utils";
import { RouteComponentProps } from "react-router";
import { getParty } from "../../order/selectors";
import { ProfilePageWrapper, Props } from "../components/ProfilePageWrapper";
import { ReactNode } from "react";

export interface OwnProps extends RouteComponentProps {
    customBack: string;
    renderPage: (done: () => void) => ReactNode;
}

const mapStateToProps = (state: AppState, { customBack }: OwnProps): DataProps<Props> => {
    const navBarIsVisible = !!getParty(state);

    return {
        navBarIsVisible,
        customBack,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { history, renderPage }: OwnProps): FunctionProps<Props> => {
    return {
        done: () => history.goBack(),
        renderPage,
    };
};

export const ProfilePageWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(ProfilePageWrapper);
