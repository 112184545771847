import * as React from "react";
import { FC, useState } from "react";
import { SimpleNavHeader } from "../../../common/navigation";
import "../assets/LegalPage.scss";
import { Spinner } from "../../Spinner";
import { regionHelper } from "../../region";
import { useSelector } from "react-redux";
import { getParty } from "../../order";
import classNames from "classnames";

interface Props {
    goBack?: () => void;
    title: string;
    relativeUrl: string;
    closeIcon?: boolean;
}

export const LegalPage: FC<Props> = ({ goBack, title, relativeUrl, closeIcon }) => {
    const [loading, setLoading] = useState(true);
    const inParty = !!useSelector(getParty);

    const doneLoading = () => setLoading(false);

    return (
        <div className="flex bg-white legal-page">
            <div className="fixed-at-top">
                <SimpleNavHeader
                    title={title}
                    onBack={goBack}
                    withBorder
                    closeToBack={closeIcon}
                    customBack={title.toLocaleLowerCase().replace(/ /g, "-")}
                />
            </div>
            {loading && <Spinner />}
            <iframe
                title={title}
                className={classNames("legal-page__iframe", inParty && "with-nav-bar")}
                src={`${regionHelper.region.brandUrl || "https://www.meandu.com"}/${relativeUrl}`}
                onLoad={doneLoading}
            />
        </div>
    );
};
