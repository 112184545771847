import "../assets/StampCard.scss";
import React, { useMemo } from "react";
import { Button, Text } from "src/sharedComponents";
import {
    CheckIcon,
    EmptyCircleIcon,
    PadlockIcon,
    PresentFilledIcon,
    SmallArrowRightIcon,
} from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { OfferStatus, StampCard as StampCardProps } from "../types";
import classNames from "classnames";

interface Props {
    stampCard: StampCardProps;
    title: string;
    subtitle?: string;
    showActions?: boolean;
    onClick?: () => void;
    claimButtonText?: string;
    status: OfferStatus;
    className?: string;
}

export const StampCard = ({
    stampCard,
    title,
    subtitle,
    onClick,
    claimButtonText,
    status,
    showActions,
    className,
}: Props) => {
    const { totalStamps, earnedStamps } = stampCard;

    const mappedStamps = useMemo(() => {
        const newMappedStamps = [...Array(totalStamps)].map((_, index) => {
            if (status === OfferStatus.REDEEMED || (earnedStamps && index < earnedStamps)) {
                return { icon: <CheckIcon />, type: "earned" };
            }

            return {
                icon: null,
                type: "empty",
            };
        });
        if (status === OfferStatus.REDEEMED) {
            newMappedStamps.push({ icon: <CheckIcon />, type: "earned" });
        } else {
            newMappedStamps.push({ icon: <PresentFilledIcon />, type: "reward" });
        }

        return newMappedStamps;
    }, [totalStamps, earnedStamps, status]);

    return (
        <TappableDiv className={classNames("stamp-card", className)} onClick={onClick}>
            <Text preset="g-16" mode={["bold", "block"]}>
                {title}
            </Text>
            {subtitle && <Text preset="g-14">{subtitle}</Text>}
            <div className="stamp-card__stamps">
                {mappedStamps.map((item, index) => (
                    <div className="stamp-card__icon-container" key={`${item.type}-${index}`}>
                        <span className="empty-circle-icon">
                            <EmptyCircleIcon />
                        </span>
                        {item.icon && <span className={`${item.type}-icon`}>{item.icon}</span>}
                    </div>
                ))}
            </div>
            {showActions && (
                <div className="stamp-card__learn-more">
                    {claimButtonText ? (
                        <Button
                            className="stamp-card__claim-button"
                            mode="solidinverted"
                            leftIcon={<PadlockIcon />}
                            onClick={onClick}
                        >
                            {claimButtonText}
                        </Button>
                    ) : (
                        <>
                            <Text preset="g-16" mode="bold">
                                Learn more
                            </Text>
                            <SmallArrowRightIcon />
                        </>
                    )}
                </div>
            )}
        </TappableDiv>
    );
};
