import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddedCardPaymentMethod } from "../../payment/selectors";
import { actionCreators as paymentActions } from "../../payment";
import { removeCard } from "../actions/removeCard";
import { getPaymentMethods } from "../../payment/actions/getPaymentMethods";
import { TrashIcon } from "../../../sharedComponents/assets/icons";
import { PaymentMethod, SelectablePaymentMethodProps } from "./PaymentMethod";
import { useHistory } from "react-router";

export const RemovePaymentMethodAction = ({ paymentMethod }: SelectablePaymentMethodProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const addedCardPaymentMethod = useSelector(getAddedCardPaymentMethod);

    const removePaymentMethod = () => {
        if (paymentMethod === addedCardPaymentMethod) {
            dispatch(paymentActions.clearAddedCard());
            history.goBack();
        } else {
            dispatch(removeCard(paymentMethod, () => dispatch(getPaymentMethods(history.goBack))));
        }
    };

    return (
        <PaymentMethod
            paymentMethod={paymentMethod}
            text="Delete this payment method"
            leftIcon={<TrashIcon color="#23262A" />}
            onClick={removePaymentMethod}
        />
    );
};
