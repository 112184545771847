export interface PayOnlyGetReceiptState {
    email?: string;
    receive: boolean;
    newsletterProvider?: string;
    privacyPolicyUrl?: string;
    title?: string;
}

export enum TypeKeys {
    SET_EMAIL = "PAY_ONLY/SET_EMAIL",
    SET_MARKETING_OPT_IN = "PAY_ONLY/SET_MARKETING_OPT_IN",
    SET_MARKETING_METADATA = "PAY_ONLY/SET_MARKETING_METADATA",
    RESET_MARKETING_METADATA = "PAY_ONLY/RESET_MARKETING_METADATA",
}

export const actionCreators = {
    setEmail: (email?: string) => ({ type: TypeKeys.SET_EMAIL, email }),
    setMarketingOptIn: (receive: boolean) => ({ type: TypeKeys.SET_MARKETING_OPT_IN, receive }),
    setMarketingMetadata: (
        newsletterProvider: string,
        defaultChecked: boolean,
        privacyPolicyUrl?: string,
        title?: string
    ) => ({
        type: TypeKeys.SET_MARKETING_METADATA,
        newsletterProvider,
        privacyPolicyUrl,
        title,
        receive: defaultChecked,
    }),
    ressetMarketingMetadata: () => ({ type: TypeKeys.RESET_MARKETING_METADATA }),
};

type SetEmailAction = { type: TypeKeys.SET_EMAIL; email: string };
type SetMarketingOptInAction = { type: TypeKeys.SET_MARKETING_OPT_IN; receive: boolean };
type SetMarketingMetadataAction = {
    type: TypeKeys.SET_MARKETING_METADATA;
    newsletterProvider?: string;
    privacyPolicyUrl?: string;
    title?: string;
    receive: boolean;
};
type ResetMarketingMetadataAction = { type: TypeKeys.RESET_MARKETING_METADATA };

export type PayOnlyAction =
    | SetEmailAction
    | SetMarketingOptInAction
    | SetMarketingMetadataAction
    | ResetMarketingMetadataAction;

const initialState: PayOnlyGetReceiptState = {
    receive: false,
};

export function reducer(state: PayOnlyGetReceiptState = initialState, action: PayOnlyAction): PayOnlyGetReceiptState {
    if (action.type === TypeKeys.SET_EMAIL) {
        return {
            ...state,
            email: action.email,
        };
    }

    if (action.type === TypeKeys.SET_MARKETING_OPT_IN) {
        return {
            ...state,
            receive: action.receive,
        };
    }

    if (action.type === TypeKeys.SET_MARKETING_METADATA) {
        const { newsletterProvider, privacyPolicyUrl, title, receive } = action;
        return {
            ...state,
            receive,
            newsletterProvider,
            privacyPolicyUrl,
            title,
        };
    }

    if (action.type === TypeKeys.RESET_MARKETING_METADATA) {
        return {
            email: state.email,
            receive: false,
        };
    }

    return state;
}
