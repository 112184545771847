import "../assets/ScheduleTakeawayModal.scss";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTakeawayASAPServiceId, getShouldDefaultToASAP, getShowSchedulePage } from "../selectors/takeawayOptions";
import { ModalContainer } from "src/common/modal";
import { actionCreators } from "../reducers/takeawayOptions";
import { SimpleNavHeader } from "src/common/navigation";
import { ScheduleTakeaway } from "./ScheduleTakeaway";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { sendScheduleTime } from "../actions/sendScheduledTime";

export const ScheduleTakeawayModal = () => {
    const showModal = useSelector(getShowSchedulePage);
    const defaultToASAPOnClose = useSelector(getShouldDefaultToASAP);
    const takeawaySlotASAPServiceId = useSelector(getTakeawayASAPServiceId);

    const dispatch = useDispatch();

    const cleanState = useCallback(() => {
        if (defaultToASAPOnClose) {
            dispatch(sendScheduleTime(null, takeawaySlotASAPServiceId));
            dispatch(actionCreators.setDefaultTimeSelectedToASAP(false));
        }
        dispatch(actionCreators.showSchedulePage(false));
    }, [dispatch, takeawaySlotASAPServiceId, defaultToASAPOnClose]);

    return (
        <ModalContainer
            isOpen={showModal}
            className={{
                base: "ScheduleTakeaway",
                afterOpen: "ScheduleTakeaway--after-open",
                beforeClose: "ScheduleTakeaway--before-close",
            }}
            overlayClassName="ScheduleTakeaway-modal--overlay"
            contentLabel="Schedule Takeaway modal container"
            closeTimeoutMS={250}
            shouldCloseOnEsc
            onRequestClose={cleanState}
            allowBodyScroll
        >
            <LocationThemeContainer>
                <div className="ScheduleTakeaway__page">
                    <SimpleNavHeader onBack={cleanState} customBack={"schedule-takeaway"} />
                    <ScheduleTakeaway />
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
