import "./SecuredPaymentWizard.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { securedPaymentWizard } from "../openTable/wizards";
import { getParty } from "../order";
import { getPayOnlyPartyShutdown } from "../payOnly/selectors";
import { WizardModal } from "../wizards/components/WizardModal";
import { setOrderMemberAccepted } from "../order/actions/setOrderMemberAccepted";
import { getPaymentState } from "../payment/selectors/paymentState";
import { SecuredPaymentPage } from "./SecuredPaymentPage";
import { Text } from "../../sharedComponents";
import { CheckIcon } from "../../sharedComponents/assets/icons";
import { getCurrentMemberHasSecuredPaymentMethod, getIsSecuredPaymentSatisfied } from "./selectors";
import { actionCreators as paymentActionCreators } from "../payment/reducers";
import { getCurrentMemberInAcceptedMembers } from "../order/selectors";
import { getOpenTableHasSubmittingOrder } from "../openTable/selectors/orders";
import { isKeyboardOpen, hideKeyboard } from "../../common/keyboard";

export const SecuredPaymentWizard = () => {
    const dispatch = useDispatch();
    const partyShutdown = useSelector(getPayOnlyPartyShutdown);
    const party = useSelector(getParty);
    const wizardState = useSelector(securedPaymentWizard.getState);
    const { showSecuredPaymentModal } = useSelector(getPaymentState);
    const hasSecuredPayment = useSelector(getIsSecuredPaymentSatisfied);

    const onClose = useCallback(() => {
        dispatch(securedPaymentWizard.actionCreators.done());
        dispatch(paymentActionCreators.showSecuredPaymentModal(false));
    }, [dispatch]);

    useEffect(() => {
        if (wizardState || hasSecuredPayment) return;
        if (showSecuredPaymentModal) {
            dispatch(securedPaymentWizard.actionCreators.start("ChoosePayment"));
        }
    }, [dispatch, wizardState, showSecuredPaymentModal, hasSecuredPayment]);

    useEffect(() => {
        if (!party) {
            dispatch(securedPaymentWizard.actionCreators.done());
        }
    }, [dispatch, party]);

    return (
        <WizardModal
            wizard={securedPaymentWizard}
            pages={{
                ChoosePayment: {
                    render: () => <SecuredPaymentPage />,
                    autoClose: partyShutdown,
                },
                PaymentVerified: {
                    render: () => <SuccessPage />,
                    header: null,
                    getAutoNextTrigger: getIsSecuredPaymentSatisfied,
                },
            }}
            onCloseComplete={onClose}
        />
    );
};

const SuccessPage = () => {
    const memberHasSecuredPaymentMethod = useSelector(getCurrentMemberHasSecuredPaymentMethod);
    const isInAcceptedMembers = useSelector(getCurrentMemberInAcceptedMembers);
    const isSubmittingOrder = useSelector(getOpenTableHasSubmittingOrder);
    const [isDelayComplete, setIsDelayComplete] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const delayTimer = window.setTimeout(() => {
            setIsDelayComplete(true);
        }, 2000);

        return () => {
            window.clearTimeout(delayTimer);
        };
    }, []);

    useEffect(() => {
        if (isKeyboardOpen()) {
            hideKeyboard();
        }
    }, []);

    /**
     * Only close the secured payments wizard after either Order Holding modal or Order State wizard has shown
     * Delay closure by 1 second to allow the listed modals/wizards to animate in
     */
    useEffect(() => {
        const userReady = isInAcceptedMembers || isSubmittingOrder;
        let closeTimer: number | undefined;

        if (isDelayComplete && !userReady) {
            dispatch(setOrderMemberAccepted(true));
        } else if (userReady) {
            closeTimer = window.setTimeout(() => {
                dispatch(securedPaymentWizard.actionCreators.done());
            }, 1000);
        }

        return () => {
            window.clearTimeout(closeTimer);
        };
    }, [dispatch, isInAcceptedMembers, isSubmittingOrder, isDelayComplete]);

    return (
        <div className="secured-payment-wizard secured-payment-wizard__success animated-child">
            <CheckIcon />
            <Text preset="g-16" mode="block">
                {memberHasSecuredPaymentMethod ? "Your " : "A "}payment method has been saved!
            </Text>
        </div>
    );
};
