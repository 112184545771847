import { AppDispatch, AppState } from "../..";
import { joiningOpenTablePartyOperation } from "../operations";
import { getTableToken } from "../persistence/tableToken";
import { orderApi } from "../../order/orderApi";
import { Party } from "../../order";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { leaveTable } from "./leaveTable";
import { resetJoinTableOperations } from "./resetOperations";
import { PartyError, PartyErrors } from "../types";
import { fetchMenuAndMembership } from "../api";

export const joinOpenTableParty = () => {
    return joiningOpenTablePartyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const tableToken = getTableToken();

            if (tableToken) {
                const party = await orderApi.invoke<Party>("joinParty").catch((err) => {
                    const match = /\{\{(.*)\}\}/.exec(err.message);
                    if (match) {
                        const errorCode = match[1];
                        throw new PartyError(errorCode as PartyErrors);
                    }
                });

                if (!party) {
                    throw new PartyError("UnknownError", "joinParty returned null party");
                }

                await fetchMenuAndMembership(party, dispatch, getState);
            } else {
                throw new Error("Failed to find existing table token");
            }
        } catch (e) {
            dispatch(showModalMessage(modalMessages.unableToJoinThisTable()));
            dispatch(leaveTable());
            dispatch(resetJoinTableOperations());

            if (!e.message) {
                throw new Error("Failed to fetch open-table table: no error message");
            }
            throw e;
        }
    });
};
