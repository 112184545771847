import "../assets/EditFoodPreferences.scss";

import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedFoodPreferences } from "../selectors";
import { TagButtonGroup } from "../../filter/components/TagButtonGroup";
import { TEMP_TAG_GROUP } from "../constants";
import { Text } from "src/sharedComponents";
import { Tag } from "../../menudata";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { saveFoodPreferences } from "../actions";

interface Props {
    onSuccess: () => void;
}

export const EditFoodPreferences = ({ onSuccess }: Props) => {
    const selectedPreferences = useSelector(getSelectedFoodPreferences);

    const [localSelectedTags, setLocalSelectedTags] = useState(selectedPreferences);

    const dispatch = useDispatch();

    const savePreferences = useCallback(() => {
        dispatch(saveFoodPreferences(localSelectedTags, onSuccess));
    }, [dispatch, localSelectedTags, onSuccess]);

    const onTagSelect = useCallback(
        (_: string, selectedTag: Tag, isCurrentlySelected: boolean) => {
            if (!isCurrentlySelected) {
                setLocalSelectedTags(localSelectedTags.concat(selectedTag.id));
            } else {
                setLocalSelectedTags(localSelectedTags.filter((localTag) => localTag !== selectedTag.id));
            }
        },
        [localSelectedTags]
    );

    useEffect(() => {
        setLocalSelectedTags(selectedPreferences);
    }, [selectedPreferences]);

    return (
        <div className="edit-food-preferences bg-white">
            <div className="edit-food-preferences__content scroll-element">
                <Text preset="title-28" mode="bold" value="Dietary preferences" />
                <Text preset="g-14" className="edit-food-preferences__description">
                    Set your preferences here and we will apply them to all the menus, so you never see the food you
                    can’t have.
                </Text>
                <TagButtonGroup
                    tagGroupId={TEMP_TAG_GROUP.id}
                    tags={TEMP_TAG_GROUP.tags}
                    selectedValues={localSelectedTags}
                    onSelect={onTagSelect}
                    brandColor
                    requiresNetworkConnection
                />
            </div>
            <div className="edit-food-preferences__submit profile-page__submit top-shadow">
                <div className="edit-food-preferences__submit__wrapper">
                    <NetworkConnectedButton type="submit" value="Save changes" onClick={savePreferences} />
                </div>
            </div>
        </div>
    );
};
