import { FeatureConfiguration } from "..";
import { invalidPromotionErrorHandler, KnownPromotionErrors } from "./errors";

export * from "./errors";

export { actionCreators } from "./reducers/promotions";

export default function (config: FeatureConfiguration) {
    config.errorHandlers.push({ code: KnownPromotionErrors.InvalidPromotion, handler: invalidPromotionErrorHandler });
}
