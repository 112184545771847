import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { isKeyboardOpen } from "../../../common/keyboard";

export interface ModalProps extends PropsWithChildren<Omit<ReactModal.Props, "onRequestClose">> {
    allowBodyScroll?: boolean;
    onRequestClose?: () => void;
    keyboardOpen: boolean;
}

interface ModalElement {
    ref: HTMLDivElement;
    zIndex: number;
    allowBodyScroll: boolean;
}

let modals: ModalElement[] = [];

const getTopmostModal = () => {
    const highestZIndex = Math.max(...modals.map((m) => m.zIndex));
    const topmostModals = modals.filter((m) => m.zIndex === highestZIndex);

    if (topmostModals.length === 1) return topmostModals[0];

    const modalOverlays = Array.from(document.getElementsByClassName("ReactModal__Overlay")).filter((o) =>
        topmostModals.some((m) => m.ref === o)
    );

    const topmostModal = modalOverlays.pop();
    return modals.find((m) => m.ref === topmostModal);
};

const isAtTopOfScroll = (el: Element) => el.scrollTop === 0;

const isAtBottomOfScroll = (el: Element) => el.scrollHeight - el.scrollTop <= el.clientHeight;

export const Modal: FC<ModalProps> = (props) => {
    const { isOpen, allowBodyScroll, overlayRef, onRequestClose, shouldCloseOnOverlayClick, keyboardOpen } = props;

    const initialY = useRef(0);
    const deltaY = useRef(0);

    const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);

    const getInitialY = useCallback((event: TouchEvent) => {
        initialY.current = event.targetTouches[0].clientY;
    }, []);

    const getDeltaY = useCallback((event: TouchEvent) => {
        deltaY.current = event.targetTouches[0].clientY - initialY.current;
    }, []);

    const unhookModal = useCallback(() => {
        if (!modalRef) return;

        const modal = modals.find((m) => m.ref === modalRef);
        if (!modal) return;

        modals = modals.filter((m) => m.ref !== modalRef);

        if (allowBodyScroll) return;

        modalRef.removeEventListener("touchstart", getInitialY);
        modalRef.removeEventListener("touchmove", getDeltaY);

        enableBodyScroll(modalRef);
    }, [modalRef, allowBodyScroll, getInitialY, getDeltaY]);

    useEffect(() => {
        if (!modalRef) return;

        if (isOpen && !keyboardOpen) {
            const zIndex = parseInt(getComputedStyle(modalRef).zIndex);
            modals.push({ ref: modalRef, zIndex, allowBodyScroll: !!allowBodyScroll });

            if (allowBodyScroll) return;

            modalRef.addEventListener("touchstart", getInitialY);
            modalRef.addEventListener("touchmove", getDeltaY);

            disableBodyScroll(modalRef, {
                allowTouchMove: (el) => {
                    const topmostModal = getTopmostModal();
                    if (topmostModal?.ref !== modalRef) return topmostModal?.allowBodyScroll === true;
                    if (isKeyboardOpen()) return true;

                    let allow = false;

                    while (el && el !== modalRef && el !== document.body && el.parentElement) {
                        // Fixed elements cause bodyscroll so should always return false
                        if (getComputedStyle(el).position === "fixed") return false;

                        if (el.scrollHeight > el.clientHeight && el.clientHeight > 0) {
                            // Allow scroll if we're moving an element inside a parent which can scroll
                            // Also set overscroll behaviour so it isn't sent up the chain
                            (el as HTMLElement).style?.setProperty("overscroll-behavior", "contain");

                            // If we allow down scroll when element is already at the bottom
                            // or up scroll when element is already at the top the scroll
                            // gets sent to the background
                            allow = !(
                                (isAtTopOfScroll(el) && deltaY.current > 0) ||
                                (isAtBottomOfScroll(el) && deltaY.current < 0)
                            );
                        }

                        el = el.parentElement;
                    }

                    // But only allow scroll if this element is a descendant of our modal
                    return allow && el === modalRef;
                },
            });
        } else {
            unhookModal();
        }
        return () => unhookModal();
    }, [isOpen, keyboardOpen, modalRef, allowBodyScroll, getInitialY, getDeltaY, unhookModal]);

    const [isOpenInner, setIsOpenInner] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (modalRef === null) {
                setIsOpenInner(true);
            }
        } else {
            setIsOpenInner(false);
        }
    }, [isOpen, setIsOpenInner, modalRef]);

    return (
        <ReactModal
            {...props}
            overlayRef={(instance) => {
                overlayRef && overlayRef(instance);
                setModalRef(instance);
                instance?.addEventListener("click", (event) => {
                    if (
                        event.target === instance &&
                        shouldCloseOnOverlayClick !== false && // Defaults to true
                        onRequestClose
                    ) {
                        event.preventDefault();
                        event.stopPropagation();
                        onRequestClose();
                    }
                });
            }}
            isOpen={isOpenInner}
        />
    );
};
