import { Text } from "src/sharedComponents";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { MenuLogo } from "src/sharedComponents/assets/icons";
import React, { useCallback, useState } from "react";
import { actionCreators } from "../../accounts/reducers/authState";
import { useDispatch, useSelector } from "react-redux";
import { LegalModal } from "../../compliance/components/LegalModal";
import { getIsOpenTable } from "../../openTable/selectors";

export const PayOnlyTerms = () => {
    const isOpenTable = useSelector(getIsOpenTable);

    const dispatch = useDispatch();

    const [showLegalModal, setShowLegalModal] = useState<"privacyPolicy" | "termsOfUse" | undefined>();

    const onPrivacyPolicyClicked = useCallback(
        () => dispatch(actionCreators.onPrivacyPolicyClicked(false)),
        [dispatch]
    );

    return (
        <>
            <Text preset="g-12" className="pay-only-receipt__container__terms" mode="block">
                By continuing to payment, I agree to the{" "}
                <TappableSpan
                    className="pay-only-receipt__container__terms__action"
                    onTap={() => setShowLegalModal("termsOfUse")}
                >
                    Terms &amp; Conditions
                </TappableSpan>{" "}
                &amp; to my data being handled in accordance with the{" "}
                <TappableSpan
                    className="pay-only-receipt__container__terms__action"
                    onTap={() => {
                        onPrivacyPolicyClicked();
                        setShowLegalModal("privacyPolicy");
                    }}
                >
                    Privacy Policy
                </TappableSpan>
                .
            </Text>
            {!isOpenTable && (
                <div className="pay-only-receipt__icon">
                    <MenuLogo />
                </div>
            )}
            <LegalModal legalPage={showLegalModal} goBack={() => setShowLegalModal(undefined)} />
        </>
    );
};
