import { connect } from "react-redux";
import { AppState } from "../../index";
import { CartView, Props } from "../component/CartView";
import { getActiveOrderByType, getIsPartyPosBillPrice, getParty, getShouldShowPriceLoader } from "../selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { actionCreators as partyAction } from "..";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { actionCreators as cartActionCreators } from "../reducers/cart";
import { actionCreators as menuSearchActionCreators } from "src/features/menuSearch/reducers/search";
import { NavBarType } from "src/common/navigation/types";
import { paymentFlowActions } from "src/features/payment";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { getFeaturedMembershipState } from "src/features/membership/selectors/getActiveMembershipState";
import {
    getMemberMembershipDiscountAmount,
    getMemberNonMemberSubTotal,
    getMemberSubTotal,
} from "src/features/payment/selectors";
import { getTableNumber } from "../../../common/shared";
import { manageMembershipsActions } from "src/features/membership";
import { getIsQuickSellEnabled } from "src/features/quickSell/selectors";
import { showSchedulePage } from "src/features/takeaway/actions/showSchedulePage";
import { getOpenTableMemberHasUnconfirmedOrders } from "../../openTable/selectors/orders";

interface OwnProps {
    editable?: boolean;
}

let lastProps: DataProps<Props> | undefined;

const mapStateToProps = (state: AppState, { editable }: OwnProps): DataProps<Props> => {
    const party = getParty(state);
    const userId = getCurrentMemberId(state);
    const canSubmit = party!.activeOrder.items.some((oi) => oi.memberId === userId) && !!party!.activeOrder.bill;
    const order = getActiveOrderByType(state);
    const subTotal = getMemberSubTotal(state);
    const nonMemberSubTotal = getMemberNonMemberSubTotal(state);
    const membershipDiscountAmount = getMemberMembershipDiscountAmount(state);
    const membershipAvailable = !!getFeaturedMembershipState(state);
    const showQuickSellItems = getIsQuickSellEnabled(state);
    const showPriceLoader = getShouldShowPriceLoader(state);
    const isPartyPosBillPrice = getIsPartyPosBillPrice(state);
    const tableNumber = getTableNumber(party);
    const memberHasUnconfirmedOrders = getOpenTableMemberHasUnconfirmedOrders(state);

    if (memberHasUnconfirmedOrders && lastProps) {
        // If we are submitting straight from the Cart page for Flex
        // return cached props so we don't see the cart get cleared
        // as the order submission modal fades in
        return lastProps;
    }

    return (lastProps = {
        order,
        canSubmit,
        editable,
        subTotal,
        nonMemberSubTotal,
        membershipDiscountAmount,
        showQuickSellItems,
        membershipAvailable,
        showPriceLoader,
        isPartyPosBillPrice,
        tableNumber,
    });
};

const mapDispatchToProps = (dispatch: any): FunctionProps<Props> => {
    return {
        removeItem: (orderItemId: string, menuItemId: string) =>
            dispatch(partyAction.removeFromOrder(orderItemId, menuItemId)),
        addItems: (editable?: boolean) => {
            dispatch(menuSearchActionCreators.showMenuSearchPage(false));

            if (editable) {
                dispatch(navBarActionCreators.activeType(NavBarType.MENU));
                dispatch(cartActionCreators.showCart(false));
            } else {
                dispatch(paymentFlowActions.completePaymentFlow());
            }
        },
        manageMemberships: () => dispatch(manageMembershipsActions.showManageMemberships(true)),
        showTakeawaySchedulePage: () => dispatch(showSchedulePage()),
    };
};

export const CartViewContainer = connect(mapStateToProps, mapDispatchToProps)(CartView);
