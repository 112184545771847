import { createWizard } from "../wizards";

export type OpenTableOrderStateFlowPages = "SubmittingOrder" | "OrderFailedToSubmit" | "OrderSubmitted";

export type SecuredPaymentWizardPages = "ChoosePayment" | "PaymentVerified";

export const openTableOrderStateWizard = createWizard<OpenTableOrderStateFlowPages>("OpenTableOrderStateWizard", [
    "SubmittingOrder",
    "OrderFailedToSubmit",
    "OrderSubmitted",
]);

export const securedPaymentWizard = createWizard<SecuredPaymentWizardPages>("SecuredPaymentWizard", [
    "ChoosePayment",
    "PaymentVerified",
]);
