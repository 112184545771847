import { createOperation } from "../../operations";
import { AddedCardResult, GetAddedCardFunc } from "../../payment";

export const showSpreedlyAddCardOperation = createOperation<GetAddedCardFunc>("PAYMENT/SHOW_SPREEDLY_ADD_CARD", {
    showSpinner: true,
});
export const getSpreedlyAddedCardOperation = createOperation<AddedCardResult | null>("PAYMENT/GET_SPREEDLY_ADDED_CARD");
export const getSpreedlyAddedCardForPaymentOperation = createOperation<AddedCardResult | null>(
    "PAYMENT/GET_SPREEDLY_ADDED_CARD_FOR_PAYMENT",
    { showSpinner: true }
);
