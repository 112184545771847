import { actionCreators } from "../reducers/marketing";
import { sendMarketingOptIn } from "..";
import { addGroupMarketingOptIn, addMarketingOptIn } from "../api";
import { PartyType } from "src/features/order";
import { AppDispatch, AppState } from "src/features";
import { getProfile } from "src/features/accountmenu/selectors";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";

export function sendOptIn(
    locationId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string | null,
    partyId: string | null,
    signupSource: string | null
) {
    return sendMarketingOptIn.getThunk(async (dispatch) => {
        await addMarketingOptIn(
            locationId,
            firstName,
            lastName,
            email,
            phone,
            partyId,
            PartyType[PartyType.SINGLEUSER],
            signupSource
        );
        dispatch(actionCreators.resetMarketing());
    });
}

export function logMarketingView(locationId: string, userRequest?: boolean) {
    return sendMarketingOptIn.getThunk(async () => {
        await logMarketingView(locationId, userRequest);
    });
}

export function subscribeToLocationMarketing(locationId: string, signupSource: string | null) {
    return sendMarketingOptIn.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const { firstName, lastName, email, phone } = { ...getProfile(getState()) };
            if (firstName && lastName && email) {
                await addMarketingOptIn(
                    locationId,
                    firstName,
                    lastName,
                    email,
                    phone ?? null,
                    null,
                    null,
                    signupSource
                );
                dispatch(actionCreators.resetMarketing());
            }
        } catch (err) {
            dispatch(showModalMessage(modalMessages.optInMarketingFailed()));
            throw err;
        }
    });
}

export function subscribeToGroupMarketing(groupId: string, signupSource: string | null) {
    return sendMarketingOptIn.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const { firstName, lastName, email, phone } = { ...getProfile(getState()) };
        if (firstName && lastName && email) {
            await addGroupMarketingOptIn(groupId, firstName, lastName, email, phone ?? null, signupSource);
            dispatch(actionCreators.resetMarketing());
        }
    });
}
