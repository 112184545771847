import { MenuDataLocale } from "../../menudata/types/MenuDataLocale";
import { PaymentType } from "../../../common/payment";

export const getProcessingFeeText = (menuDataLocale: MenuDataLocale, paymentMethod?: PaymentType | string | null) => {
    if (menuDataLocale.locale === "en-GB") {
        return "Administration fee";
    } else if (menuDataLocale.locale === "en-US") {
        return "Card processing fee";
    }

    switch (paymentMethod) {
        case PaymentType.CARD:
        case PaymentType.ADDEDCARD:
        case PaymentType.GROUPTAB:
        case "Card":
        case "AddedCard":
        case "GroupTab":
        case null:
        case undefined:
            return "Card processing fee";
        default:
            return "Payment processing fee";
    }
};
