import * as React from "react";
import { DrinkMenuItem, MenuItem } from "../../menudata";
import { MenuItemImage } from "../../menuitem";
import "../assets/DrinkCard.scss";
import { PriceList } from "./PriceList";
import { Tags } from "./Tags";
import classNames from "classnames";
import { UnavailableIcon } from "src/sharedComponents/assets/icons";
import { Button, Counter, Text } from "src/sharedComponents";
import { MemberPriceIndicator } from "./MemberPriceIndicator";
import { WaitTimeBadge } from "./WaitTimeBadge";

export interface Props {
    menuItem: DrinkMenuItem;
    showFromPrice?: boolean;
    onAddAllItem: (menuItem: MenuItem) => void;
    showAddAllItem: boolean;
    quantity?: number;
    hideProductImages?: boolean;
    showTags?: boolean;
}

export const DrinkCard = ({
    menuItem,
    showFromPrice,
    showAddAllItem,
    onAddAllItem,
    quantity,
    hideProductImages,
    showTags,
}: Props) => (
    <li className={classNames("drinkcard", { unavailable: menuItem.available === false })}>
        {!hideProductImages && (
            <div className="l">
                <div className="drinkcard__thumb-wrapper">
                    <MenuItemImage className="drinkcard__thumb" images={menuItem.images} name="thumb" />
                    <WaitTimeBadge waitTime={menuItem.waitTime} />
                    {!!quantity && <Counter mode="border" className="drinkcard__item-counter" quantity={quantity} />}
                </div>
                <Tags menuItem={menuItem} />
                {showAddAllItem && (
                    <Button onClick={() => onAddAllItem(menuItem)} className="drinkcard__add-item-button">
                        Add
                    </Button>
                )}
            </div>
        )}
        <div className="r">
            <div className="drinkcard__descriptors">
                <div className="drinkcard__descriptors__name-wrapper">
                    <Text preset="g-16" mode="bold" className="drinkcard__descriptors__name">
                        {hideProductImages && !!quantity && (
                            <Counter mode="border" className="drinkcard__item-counter" quantity={quantity} />
                        )}
                        {hideProductImages && <Tags menuItem={menuItem} />}
                        {menuItem.displayName}
                    </Text>
                    {hideProductImages && <WaitTimeBadge waitTime={menuItem.waitTime} />}
                </div>
                {menuItem.description && (
                    <Text preset="g-14" className="drinkcard__descriptors__description">
                        {menuItem.description}
                    </Text>
                )}
                {showTags && (
                    <div className="drinkcard__descriptors__tags">
                        <MemberPriceIndicator menuItem={menuItem} />
                    </div>
                )}
            </div>
            <div className="drinkcard__attributes">
                <PriceList item={menuItem} showFromPrice={showFromPrice} />
            </div>
            <div className="drinkcard__unavailable">
                <UnavailableIcon /> <Text preset="g-14"> Unavailable</Text>
            </div>
            {hideProductImages && showAddAllItem && (
                <Button onClick={() => onAddAllItem(menuItem)} className="drinkcard__add-item-button">
                    Add
                </Button>
            )}
        </div>
    </li>
);
