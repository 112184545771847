import React, { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Text } from "../text";
import "./Toast.scss";
import { CloseIcon } from "../../assets/icons";
import { TappableDiv } from "../../common/tappable";
import classNames from "classnames";

type ToastTypes = "warning";

interface Props {
    text: string;
    show: boolean;
    canDismiss?: boolean;
    autoDismiss?: boolean;
    onDismiss?: () => void;
    type?: ToastTypes;
    icon?: ReactElement;
}

export const Toast: FC<Props> = ({ text, show, canDismiss, autoDismiss, onDismiss, type, icon }) => {
    const [showing, setShowing] = useState(false);
    const [hiding, setHiding] = useState(false);
    const [canShow, setCanShow] = useState(true);

    const hide = useCallback(() => setHiding(true), [setHiding]);

    const reallyHide = useCallback(() => {
        setHiding(false);
        setShowing(false);
        setCanShow(false);
    }, [setHiding, setShowing, setCanShow]);

    useEffect(() => {
        let timer: number | undefined;

        if (show && canShow && !showing && !hiding) {
            setShowing(true);
        } else if (!show && !showing && !hiding) {
            setCanShow(true);
        } else if (show && showing && autoDismiss !== false) {
            timer = window.setTimeout(hide, 5000);
        } else if (!show && showing && autoDismiss === false) {
            hide();
        }

        return () => {
            clearTimeout(timer);
        };
    }, [show, hiding, showing, canShow, hide, setShowing, setCanShow, autoDismiss]);

    useEffect(() => {
        if (hiding && onDismiss) {
            onDismiss();
        }
    }, [hiding, onDismiss]);

    return (
        <div
            onTransitionEnd={hiding ? reallyHide : undefined}
            className={classNames("toast", type, { show: showing, hide: hiding })}
        >
            {icon}
            <Text preset="g-14" mode="bold" value={text} />
            {canDismiss && (
                <TappableDiv onClick={hide} className="toast__close">
                    <CloseIcon />
                </TappableDiv>
            )}
        </div>
    );
};
