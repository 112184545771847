import { Dispatch } from "redux";
import { AppState } from "../../index";
import { LocationMenuDataUpdate } from "..";
import { fetchingMenuDataUpdateOperation } from "../operations";
import { attempt } from "../../order/orderApi/util/attempt";
import { reattemptStrategies } from "../../order/orderApi";
import { menuDataUrl } from "../API/menuDataUrl";
import { fetchActions } from "../reducers";
import { mergeUpdate } from "../mutators";
import { actionCreators as partyActions } from "../../order/reducers/party";
import { getActiveServiceUnfiltered, getMenuData } from "src/features/menu/selectors";

export function updateMenuData(
    dispatch: Dispatch,
    getState: () => AppState,
    fromUpdate: boolean,
    menuDataUpdateUrl?: string
) {
    return fetchingMenuDataUpdateOperation.invoke(async () => {
        if (!menuDataUpdateUrl) return;

        const state = getState();
        const menuData = getMenuData(state);
        const activeService = getActiveServiceUnfiltered(state);
        const fullMenuDataUpdateUrl = menuDataUrl(menuDataUpdateUrl);

        const onAttemptFailed = (count: number) =>
            dispatch(fetchActions.connectionFailed(fullMenuDataUpdateUrl, count));

        const response = await attempt(
            () => fetch(fullMenuDataUpdateUrl),
            reattemptStrategies.limited,
            undefined,
            onAttemptFailed
        );

        if (!response.ok) throw new Error(`Failed to fetch latest menu data update file ${fullMenuDataUpdateUrl}`);

        const menuDataUpdate: LocationMenuDataUpdate = await response.json();
        if (fromUpdate && activeService?.originalCategories) {
            const shouldUpdateMenu = activeService.originalCategories.filter(
                (catId) => !menuDataUpdate.categories || !menuDataUpdate.categories[catId]?.unavailable
            );
            if (shouldUpdateMenu?.length !== activeService?.categories.length) {
                dispatch(partyActions.categoriesChanging(true));

                window.setTimeout(() => {
                    const newMenuData = mergeUpdate(menuData!, menuDataUpdate, activeService.originalCategories);
                    dispatch(fetchActions.updated(newMenuData));
                    dispatch(partyActions.categoriesChanging(false));
                }, 300); // need to wait for the menu change modal
                return;
            }
        }

        const newMenuData = mergeUpdate(menuData!, menuDataUpdate, activeService?.categories);

        dispatch(fetchActions.updated(newMenuData));
    }, dispatch);
}
