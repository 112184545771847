import { AppDispatch, AppMiddleware } from "../..";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { SnapshotAction, TypeKeys as MenuDataTypeKeys } from "../reducers/active";
import { getItemIdsWithVideos } from "../../menu/selectors";

export const menudataMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => (action: SnapshotAction) => {
    next(action);

    if (action.type === MenuDataTypeKeys.SUCCESS) {
        const { dispatch, getState } = store;

        const itemIds = getItemIdsWithVideos(getState());
        if (itemIds?.length) {
            dispatch(
                trackedEvent({
                    type: "PARTY/ITEMS_WITH_VIDEO",
                    item_ids: itemIds.join(),
                })
            );
        }
    }
};
