export enum OpenTableMemberStatus {
    BROWSING = "Browsing",
    ORDERING = "Ordering",
    WAITING = "Waiting",
    INACTIVE = "Inactive",
}

export enum PreventLeavePartyReason {
    HAS_SUBMITTED_ORDERS = 1,
    HAS_SECURED_PAYMENT_METHOD = 2,
    IS_FLEX_TAB_ADMIN = 3,
}
