import { ServiceType } from "../../order";
import { LoadingStatus } from "src/features/accountmenu";
import { GroupTabHistoryView, GroupTabHistoryResponse, GroupTabHistoryDetails } from "../types";
import { OrderCourseItem } from "src/features/order/reducers/types";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { OrderHistoryData } from "../../orderHistoryData";

export type GroupTabHistoryDataState = {
    groupTabHistories: GroupTabHistoryView[];
    selectedGroupTabId: string | null;
    shouldOpen: boolean;
    loading: LoadingStatus;
    hasMore: boolean;
    displayedError: string[];
    selectedTabOrderHistoryData: GroupTabHistoryView | null;
};

export interface State {
    groupTabHistoryData: GroupTabHistoryDataState;
}

export enum TypeKeys {
    LOAD_GROUP_TAB_HISTORY_LIST = "HISTORYDATA/LOAD_GROUP_TAB_HISTORY_LIST",
    GET_GROUP_TAB_HISTORY_LIST_COMPLETE = "HISTORYDATA/GET_GROUP_TAB_HISTORY_LIST_COMPLETE",
    GET_GROUP_TAB_HISTORY_DATA = "HISTORYDATA/GET_GROUP_TAB_HISTORY_DATA",
    SELECT_GROUP_TAB_HISTORY = "HISTORYDATA/SELECT_GROUP_TAB_HISTORY",
    GROUP_TAB_SET_ORDER_DATA = "HISTORYDATA/GROUP_TAB_SET_ORDER_DATA",
    GROUP_TAB_ORDER_DETAILS_CLICKED = "PARTY/GROUP_TAB/ORDER_DETAILS_CLICKED",
}

export const actionCreators = {
    loadingGroupTabHistoryList: (loading: LoadingStatus) => ({ type: TypeKeys.LOAD_GROUP_TAB_HISTORY_LIST, loading }),
    getGroupTabHistoryListComplete: (results: GroupTabHistoryResponse) => ({
        type: TypeKeys.GET_GROUP_TAB_HISTORY_LIST_COMPLETE,
        results,
    }),
    getGroupTabHistoryData: (result: GroupTabHistoryView) => ({ type: TypeKeys.GET_GROUP_TAB_HISTORY_DATA, result }),
    selectGroupTabHistory: (groupTabId: string | null, shouldOpen: boolean | null) => ({
        type: TypeKeys.SELECT_GROUP_TAB_HISTORY,
        groupTabId,
        shouldOpen,
    }),
    setOrderData: (
        groupTabId: string,
        partyId: string,
        orderItems: OrderCourseItem[],
        orderHistoryData: OrderHistoryData
    ) => ({
        type: TypeKeys.GROUP_TAB_SET_ORDER_DATA,
        groupTabId,
        partyId,
        orderItems,
        orderHistoryData,
    }),
    trackGroupTabHistoryOrderDetailsClicked: (groupTabIsActive: boolean, groupTabId: string) =>
        trackedEvent({ type: TypeKeys.GROUP_TAB_ORDER_DETAILS_CLICKED, groupTabIsActive, groupTabId }),
};

export type LoadGroupTabHistoryList = {
    type: TypeKeys.LOAD_GROUP_TAB_HISTORY_LIST;
    loading: LoadingStatus;
};

export type GetGroupTabHistoryListComplete = {
    type: TypeKeys.GET_GROUP_TAB_HISTORY_LIST_COMPLETE;
    results: GroupTabHistoryResponse;
};

export type GetGroupTabHistoryData = {
    type: TypeKeys.GET_GROUP_TAB_HISTORY_DATA;
    result: GroupTabHistoryView;
};

export type SelectGroupTabHistory = {
    type: TypeKeys.SELECT_GROUP_TAB_HISTORY;
    groupTabId: string;
    shouldOpen: boolean;
    orderSubmittedType: ServiceType | null;
};
export type SetGroupTabOrderAction = {
    type: TypeKeys.GROUP_TAB_SET_ORDER_DATA;
    groupTabId: string;
    partyId: string;
    orderItems: OrderCourseItem[];
    orderHistoryData: OrderHistoryData;
};

export type GroupTabHistoryActions =
    | GetGroupTabHistoryListComplete
    | GetGroupTabHistoryData
    | SelectGroupTabHistory
    | LoadGroupTabHistoryList
    | SetGroupTabOrderAction;

const initialState: GroupTabHistoryDataState = {
    groupTabHistories: [],
    selectedGroupTabId: null,
    shouldOpen: false,
    displayedError: [],
    hasMore: true,
    loading: "initial",
    selectedTabOrderHistoryData: null,
};

function mergeResultWithState(groupTabHistories: GroupTabHistoryView[], results: GroupTabHistoryView[]) {
    const newGroupTabHistoryView = [...results];
    const modifiedData = groupTabHistories.map((groupTab) => {
        const updateItemIndex = newGroupTabHistoryView.findIndex(
            (resHistory) => resHistory.groupTabId === groupTab.groupTabId
        );
        return updateItemIndex > -1
            ? {
                  ...groupTab,
                  ...newGroupTabHistoryView.splice(updateItemIndex, 1)[0],
              }
            : groupTab;
    });
    if (newGroupTabHistoryView.length) {
        return newGroupTabHistoryView.concat(modifiedData).sort((m1, m2) => m2.posixClosedDate - m1.posixClosedDate);
    }
    return modifiedData;
}

export default function reducer(
    state: GroupTabHistoryDataState = initialState,
    action: GroupTabHistoryActions
): GroupTabHistoryDataState {
    if (action.type === TypeKeys.LOAD_GROUP_TAB_HISTORY_LIST) {
        return {
            ...state,
            loading: action.loading,
        };
    }

    if (action.type === TypeKeys.GET_GROUP_TAB_HISTORY_LIST_COMPLETE) {
        return {
            ...state,
            loading: "success",
            hasMore: action.results.hasMore,
            groupTabHistories: mergeResultWithState(state.groupTabHistories, action.results.data),
        };
    }

    if (action.type === TypeKeys.GET_GROUP_TAB_HISTORY_DATA && !!action.result) {
        let selectedTabOrderHistoryData = state.selectedTabOrderHistoryData;

        if (!selectedTabOrderHistoryData || selectedTabOrderHistoryData?.groupTabId !== action.result.groupTabId) {
            selectedTabOrderHistoryData = action.result;
        }

        return {
            ...state,
            groupTabHistories: state.groupTabHistories.some((item) => item.groupTabId === action.result.groupTabId)
                ? state.groupTabHistories.map((item) =>
                      item.groupTabId === action.result.groupTabId ? action.result : item
                  )
                : mergeResultWithState(state.groupTabHistories, [action.result]),
            selectedTabOrderHistoryData,
        };
    }

    if (action.type === TypeKeys.SELECT_GROUP_TAB_HISTORY) {
        const tabOrderHistoryData = action.groupTabId
            ? state.groupTabHistories.find((item) => item.groupTabId === action.groupTabId)
            : null;
        return {
            ...state,
            selectedGroupTabId: action.groupTabId !== null ? action.groupTabId : state.selectedGroupTabId,
            shouldOpen: action.shouldOpen !== null ? action.shouldOpen : state.shouldOpen,
            selectedTabOrderHistoryData: tabOrderHistoryData || null,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SET_ORDER_DATA) {
        const { partyId, groupTabId, orderHistoryData, orderItems } = action;
        const { groupTabHistories, selectedTabOrderHistoryData } = state;

        let selectedHistoryData = selectedTabOrderHistoryData;
        let updatedHistoryData: GroupTabHistoryView | null = null;

        const tabHistories = groupTabHistories.map((history) => {
            if (history.groupTabId !== groupTabId) {
                return history;
            }

            updatedHistoryData = {
                ...history,
                details: {
                    ...history.details!,
                    ordersItems: {
                        ...history.details?.ordersItems,
                        [partyId]: {
                            orderData: orderHistoryData,
                            items: orderItems,
                        },
                    },
                } as GroupTabHistoryDetails,
            } as GroupTabHistoryView;

            return updatedHistoryData;
        });

        if (!selectedHistoryData || selectedHistoryData.groupTabId === groupTabId) {
            selectedHistoryData = updatedHistoryData;
        }

        return {
            ...state,
            groupTabHistories: tabHistories,
            selectedTabOrderHistoryData: selectedHistoryData,
        };
    }

    return state;
}
