import { device, isPrivate } from "./device";

export * from "./device";
export * from "./statusBar";
export * from "./prompt";
export * from "./asset";
export * from "./browserSupport";
export * from "./splashScreen";
export * from "./handleUrlLink";
export * from "./backHandler";
export * from "./storeRating";
export * from "./appForegroundChange";
export * from "./href";
export * from "./connectivity";
export * from "./applePay";
export * from "./googlePay";
export * from "./qr";
export * from "./errorBoundaryMessages";
export * from "./oneTimePassword";
export * from "./scrolling";
export * from "./rotationHandler";
export * from "./navigation";
export * from "./webFlow";
export * from "./incorrectDocumentHeightHandler";
export * from "./scaleHandler";
export * from "./browserTranslate";
export * from "./tracking";
export * from "./gdpr";
export * from "./cardVerification";
export * from "./sso";
export * from "./recaptcha";

export const deviceReady = () =>
    new Promise<boolean>((resolve) => {
        document.documentElement.classList.add("web");
        document.body.classList.add("web");
        if (device.isIOS15orHigher) {
            document.documentElement.classList.add("scroll-root");
            document.body.classList.add("scroll-root");
        }
        resolve(true);
    }).then(() => isPrivate);

export const refreshApp = () => window.location.reload();

export const isWeb = true;
export const isNative = false;
