import { normalizeError } from "../../../../error";
import { getGooglePaymentsClient, GooglePayPaymentMethod } from "../../../../payment";
import { timeoutAndError } from "../../../../shared";
import { GetDevicePaymentMethodAvailableFunc } from "../../../interface";

export const allowedPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
    type: "CARD",
    parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: ["VISA", "MASTERCARD", "AMEX"],
    },
};

export const getGooglePayAvailable: GetDevicePaymentMethodAvailableFunc<GooglePayPaymentMethod> = async (
    _,
    trackEvent
) => {
    if (!("PaymentRequest" in window)) {
        trackEvent({ type: "NOT_AVAILABLE", PaymentRequest: false });
        return false;
    }

    const paymentsClient = await getGooglePaymentsClient();

    let isReadyToPayResponse: google.payments.api.IsReadyToPayResponse | undefined;

    for (let i = 0; i < 3 && !isReadyToPayResponse; i++) {
        try {
            isReadyToPayResponse = await Promise.race([
                paymentsClient.isReadyToPay({
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [allowedPaymentMethod],
                    existingPaymentMethodRequired: true,
                }),
                timeoutAndError(10000, "timed out"),
            ]);
        } catch (err) {
            const { message, stack } = normalizeError(err);
            trackEvent({ type: "NOT_AVAILABLE", message, stack });
        }
    }

    if (!isReadyToPayResponse) return false;

    const { result, paymentMethodPresent } = isReadyToPayResponse;

    // According to the documentation:
    // paymentMethodPresent will be set if existingPaymentMethodRequired === true
    // and will be true if the user has one of the allowed payment methods available
    // It will ALWAYS be true when the environment is TEST
    // Although in some cases I have found that sometimes it ISN'T set at all so for now treat absence as true
    const available = result && (paymentMethodPresent === undefined || paymentMethodPresent);

    if (!available) {
        trackEvent({
            type: "NOT_AVAILABLE",
            result,
            paymentMethodPresent: String(paymentMethodPresent),
        });
    }

    return available;
};
