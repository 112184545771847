import { AppDispatch } from "../../index";
import { applePay } from "../../../common/experience";
import { ApplePayPaymentMethod } from "../../../common/payment";
import { getApplePayAvailableOperation } from "../operations";

export const getApplePayAvailable = (dispatch: AppDispatch, applePayPaymentMethod: ApplePayPaymentMethod) =>
    getApplePayAvailableOperation.invoke(
        () =>
            applePay.isAvailable(applePayPaymentMethod, (action) =>
                getApplePayAvailableOperation.trackEvent(action, dispatch)
            ),
        dispatch
    );
