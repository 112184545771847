import { onTappableElementClick, TappableElementProps, useTappable } from "./base/TappableBase";
import React, { FC, HTMLAttributes } from "react";

const TappableSpan: FC<TappableElementProps<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>> = (props) => {
    const [attributes, children] = useTappable(props);
    return (
        <span onClick={(e) => onTappableElementClick(e, props)} {...attributes}>
            {children}
        </span>
    );
};

export default TappableSpan;
