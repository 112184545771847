import { isEqual } from "lodash";

import {
    OrderItem,
    OrderItemModifier,
    OrderItemNestedModifier,
    OrderItemOptionNestedModifier,
} from "src/features/order/types";

import { RoundItem } from "../types";

function getIsSameNestedModifiers(
    roundItemNestedModifiers: OrderItemNestedModifier[] | undefined,
    orderItemNestedModifiers: OrderItemNestedModifier[] | undefined
): boolean {
    return roundItemNestedModifiers
        ? roundItemNestedModifiers.length === orderItemNestedModifiers?.length &&
              roundItemNestedModifiers.every(
                  (nestedModifierModifier, nestedModifierModifierIndex) =>
                      nestedModifierModifier.modifierId ===
                          orderItemNestedModifiers?.[nestedModifierModifierIndex]?.modifierId &&
                      isEqual(
                          nestedModifierModifier.options,
                          orderItemNestedModifiers?.[nestedModifierModifierIndex]?.options
                      ) &&
                      getIsSameOptionNestedModifiersRecursive(
                          nestedModifierModifier.optionNestedModifiers,
                          orderItemNestedModifiers?.[nestedModifierModifierIndex]?.optionNestedModifiers
                      )
              )
        : roundItemNestedModifiers === orderItemNestedModifiers;
}

function getIsSameOptionNestedModifiersRecursive(
    roundItemOptionNestedModifiers: OrderItemOptionNestedModifier[] | undefined,
    orderItemOptionNestedModifiers: OrderItemOptionNestedModifier[] | undefined
): boolean {
    return roundItemOptionNestedModifiers
        ? roundItemOptionNestedModifiers.length === orderItemOptionNestedModifiers?.length &&
              roundItemOptionNestedModifiers.every(
                  (optionNestedModifiers, optionNestedModifierIndex) =>
                      optionNestedModifiers.optionIndex ===
                          orderItemOptionNestedModifiers?.[optionNestedModifierIndex].optionIndex &&
                      getIsSameNestedModifiers(
                          optionNestedModifiers.modifiers,
                          orderItemOptionNestedModifiers[optionNestedModifierIndex].modifiers
                      )
              )
        : roundItemOptionNestedModifiers === orderItemOptionNestedModifiers;
}

function getIsSameModifiers(
    roundItemModifiers: OrderItemModifier[] | null,
    orderItemModifiers: OrderItemModifier[] | null
) {
    return roundItemModifiers
        ? roundItemModifiers.length === orderItemModifiers?.length &&
              roundItemModifiers.every(
                  (modifier, modiferIndex) =>
                      modifier.modifier === orderItemModifiers?.[modiferIndex].modifier &&
                      isEqual(modifier.options, orderItemModifiers?.[modiferIndex]?.options) &&
                      getIsSameOptionNestedModifiersRecursive(
                          modifier.optionNestedModifiers,
                          orderItemModifiers?.[modiferIndex]?.optionNestedModifiers
                      )
              )
        : roundItemModifiers === orderItemModifiers;
}

export function getIsSameItem(roundItem: RoundItem, orderItem: OrderItem) {
    return (
        roundItem.itemId === orderItem.itemId &&
        roundItem.variant === orderItem.variant &&
        roundItem.notes === orderItem.notes &&
        getIsSameModifiers(roundItem.modifiers, orderItem.modifiers)
    );
}
