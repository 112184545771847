import "../assets/OrderHistoryTile.scss";

import classNames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import { MealHistoryOrderStatus, OrderHistoryData, RefundedState } from "src/features/orderHistoryData";
import { getMealHistoryLocale } from "../utils/mealHistoryLocaleCache";
import { Text } from "src/sharedComponents";
import { useSelector } from "react-redux";
import { getTabTypeName } from "../../groupTabs/selectors/activeGroupTab";
import "moment/locale/en-gb";

interface Props {
    item: OrderHistoryData;
    noImage?: boolean;
}

const failedOrderStatuses = [
    MealHistoryOrderStatus.REJECTED,
    MealHistoryOrderStatus.ORPHANED,
    MealHistoryOrderStatus.SUBMIT_FAILED,
];

export const OrderHistoryTile = ({ item: { summary, mealHistoryOrders, readError, someItemsFailed } }: Props) => {
    const { serviceDate, total, paidWithGroupTab, locale, currency, refunded } = summary!;
    const menuDataLocale = getMealHistoryLocale(locale, currency);
    const tabTypeName = useSelector(getTabTypeName);
    const showDate = useMemo(() => {
        moment.locale("en-gb");
        return new Date(serviceDate);
    }, [serviceDate]);
    const formatDate = useMemo(() => moment(showDate).format("ddd, MMM D"), [showDate]);
    const formatTime = useMemo(() => moment(showDate).format("h:mm A"), [showDate]);
    const orderStatus =
        mealHistoryOrders && mealHistoryOrders.length ? mealHistoryOrders[0].status : MealHistoryOrderStatus.READY;

    const showError =
        !readError &&
        (refunded === RefundedState.FAILED || failedOrderStatuses.includes(orderStatus) || someItemsFailed);

    return (
        <div
            className={classNames(
                "order-history-tile",
                "area",
                orderStatus === MealHistoryOrderStatus.READY && "order-history-tile--ready"
            )}
        >
            <div className="order-history-tile__first-line">
                <Text preset="g-14" mode="bold" className="order-history-tile__date">
                    {formatDate} • {formatTime}
                </Text>
                {paidWithGroupTab ? (
                    <Text preset="g-14" mode="medium" className="order-history-tile__used-tab">
                        Used {tabTypeName}
                    </Text>
                ) : (
                    <Text preset="g-14" mode="bold" className="order-history-tile__amount-value">
                        {" "}
                        {menuDataLocale.formatCurrency(total)}
                    </Text>
                )}
            </div>
            <OrderHistoryStatus orderStatus={orderStatus} showError={showError} refunded={refunded} />
        </div>
    );
};

interface StatusProps {
    orderStatus: MealHistoryOrderStatus;
    showError: boolean;
    refunded?: RefundedState;
}

const OrderHistoryStatus = ({ orderStatus, showError, refunded }: StatusProps) => (
    <div
        className={classNames(
            "order-history-tile__status",
            "order-history-tile__status--" +
                (showError
                    ? "error"
                    : orderStatus !== MealHistoryOrderStatus.READY && refunded
                    ? refunded
                    : orderStatus)
        )}
    >
        <Text
            preset="g-12"
            mode={orderStatus === MealHistoryOrderStatus.READY || showError ? ["caps", "bold"] : "caps"}
            className="order-history-tile__status__message"
        >
            {refunded
                ? mapRefundDisplayText(orderStatus, refunded)
                : showError
                ? "Review order"
                : mapStatusDisplayText(orderStatus)}
        </Text>
    </div>
);

const mapStatusDisplayText = (orderStatus: MealHistoryOrderStatus) => {
    switch (orderStatus) {
        case MealHistoryOrderStatus.SUBMITTED:
            return "Waiting for venue";
        case MealHistoryOrderStatus.PREPARING:
            return "Preparing order";
        case MealHistoryOrderStatus.READY:
            return "Ready to pick up";
    }
    return "Completed";
};

const mapRefundDisplayText = (orderStatus: MealHistoryOrderStatus, refunded: RefundedState) => {
    if (orderStatus === MealHistoryOrderStatus.READY) {
        return mapStatusDisplayText(orderStatus);
    }

    switch (refunded) {
        case RefundedState.PENDING:
            return "PENDING REFUND";
        case RefundedState.FAILED:
            return "REFUND FAILED";
    }
    return "Refunded";
};
