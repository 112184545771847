import { Indexed, MenuItemModifier, MenuItemVariant, ModifierOption } from "..";

/**
 * Modifier is required if:
 * minSelection > 0 (User must select an option(s))
 */
export const getIsRequiredModifier = (modifier: MenuItemModifier) => {
    if (!modifier) return modifier;
    const evaluatedMinSelection = evaluateMinSelection(modifier);
    return !!evaluatedMinSelection;
};

/**
 * Total Selectable Options are the number options that can be selected on a modifier,
 * based on the availability of all options within the modifiers and the modifiers maxSelectionPerOption
 */
export const getTotalSelectableOptions = (modifier: MenuItemModifier) => {
    const availableOptions = modifier.options.filter((option) => option.available !== false);
    return availableOptions.length * (modifier.maxSelectionPerOption || 1);
};

/**
 * For legacy purposes, if required is set and minSelection is not, we need to return 1.
 * minSelection is a required field going forward and cannot be set to less than 0.
 */
export const evaluateMinSelection = (modifier: MenuItemModifier) =>
    modifier.minSelection ? modifier.minSelection : modifier.required ? 1 : 0;

export const getModifierWithOptionAvailabilitySet = (
    modifier: MenuItemModifier,
    unavailableSkus?: string[],
    globalModifiers?: Indexed<MenuItemModifier>
) => {
    const modWithOptionsAvailability = modifier.options.map((option) => ({
        ...option,
        available: getIsOptionAvailable(option, unavailableSkus, globalModifiers),
    })) as ModifierOption[];

    return {
        ...modifier,
        options: modWithOptionsAvailability,
    };
};

export const isModifierAvailable = (
    modifier: MenuItemModifier,
    unavailableSkus?: string[],
    globalModifiers?: Indexed<MenuItemModifier>
) => {
    const actualModifier =
        unavailableSkus && globalModifiers
            ? getModifierWithOptionAvailabilitySet(modifier, unavailableSkus, globalModifiers)
            : modifier;

    const evaluatedMinSelection = evaluateMinSelection(actualModifier);
    const totalSelectableOptions = getTotalSelectableOptions(actualModifier);

    return totalSelectableOptions > 0 && totalSelectableOptions >= evaluatedMinSelection;
};

/**
 * Modifiers are unavailable IF:
 * - it is a non-variant-specific modifier
 * AND
 * - it has no available options
 */
export const areAllItemRequiredModifiersAvailable = (modifiers?: MenuItemModifier[], variants?: MenuItemVariant[]) =>
    modifiers &&
    modifiers.every((m, i) => {
        const isVariantModifier = !!variants?.some((v) => v.modifiers && v.modifiers.indexOf(i) >= 0);
        if (isVariantModifier) return true;
        const isRequired = getIsRequiredModifier(m);

        return !isRequired || m.available !== false;
    });

/**
 * Check variant availability based on its required modifier availability
 * If a variant has a required modifier that is unavailable, then the variant is unavailable.
 */
export const areAllVariantRequiredModifiersAvailable = (variant: MenuItemVariant, modifiers?: MenuItemModifier[]) =>
    variant.modifiers &&
    modifiers &&
    variant.modifiers.every((i) => {
        const variantModifier = modifiers[i];
        const isRequired = getIsRequiredModifier(variantModifier);

        return !isRequired || variantModifier.available !== false;
    });

/**
 * A modifier option will be categorised as available/unavailable in the following ways (note - the ordering of the available/unavailable checks in the function are important).
 *
 * Will evaluate to AVAILABLE:
 * - There are no unavailable SKUs: All modifiers are available and no modifier rules can be broken
 * - The options sku is not included in the unavailable SKUs list
 * - There are no global modifiers or nested modifiers. If an option has no nested modifiers
 * - The option has no required nested modifiers. Options with optional modifiers are still available to be selected, even if none of the optional nested modifiers are available
 * - The option has all required nested modifiers available (meeting minSelection rules)
 *
 * UNAVAILABLE:
 * - The option is included in the unavailable SKUs list
 * - The option has required nested modifiers and one of them is not available or does not meet min selection rules
 *
 * @param option - modifier option to be evaluated
 * @param unavailableSkus - array of SKU of unavailable options
 * @param globalModifiers - venue's catalog of modifiers
 */
export const getIsOptionAvailable = (
    option: ModifierOption,
    unavailableSkus?: string[],
    globalModifiers?: Indexed<MenuItemModifier>
): boolean | undefined => {
    if (!unavailableSkus) return undefined;

    if (isOptionSkuUnavailable(option, unavailableSkus)) {
        return false;
    }

    if (!globalModifiers || !option.nestedModifiers) {
        return undefined;
    }

    const detailedNestedModifiers = option.nestedModifiers.map((nm) => globalModifiers[nm]);
    const requiredNestedModifiers = detailedNestedModifiers.filter(getIsRequiredModifier);

    // If there are no required nested modifiers, then the option should not be considered unavailable unless it is in the unavailableSkus array
    // Optional nested modifiers should not impact the availability of the option
    if (!requiredNestedModifiers.length) {
        return undefined;
    }

    // An option is unavailable if it has a required nested modifier with no available options or it cannot meet minSelection rules
    return requiredNestedModifiers.every((nm) => isModifierAvailable(nm, unavailableSkus, globalModifiers));
};

export const isOptionSkuUnavailable = (option: ModifierOption, unavailableSkus?: string[]) =>
    option.sku && unavailableSkus && unavailableSkus.includes(option.sku);

export const getHasAvailableNestedModifier = (option: ModifierOption, globalModifiers?: Indexed<MenuItemModifier>) =>
    !!option.nestedModifiers &&
    !!globalModifiers &&
    option.nestedModifiers
        .map((modifierId) => globalModifiers[modifierId])
        .some((modifier) => isModifierAvailable(modifier));
