import "./FormControl.scss";

import React, { PropsWithChildren, createContext, forwardRef, useContext } from "react";
import classNames from "classnames";
import { Text } from "../text";

interface FormControlOptions {
    id?: string;
    disabled?: boolean;
    invalid?: boolean;
}

export interface FormControlProps extends PropsWithChildren<FormControlOptions> {
    label?: string;
    message?: string;
    invalidMessage?: string;
}

type FormControlContextProps = FormControlOptions | undefined;

const FormControlContext = createContext<FormControlContextProps>(undefined);

export function useFormControlContext() {
    return useContext(FormControlContext);
}

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
    ({ id, disabled, invalid, label, message, invalidMessage, children }, ref) => {
        // TODO: use useId() to provide a default id as soon as react is updated
        const context: FormControlContextProps = {
            id,
            disabled,
            invalid,
        };

        return (
            <FormControlContext.Provider value={context}>
                <div
                    ref={ref}
                    className={classNames("form-control", {
                        "form-control--disabled": context.disabled,
                        "form-control--invalid": context.invalid,
                    })}
                >
                    {label && (
                        <label htmlFor={context.id} className="form-control__label">
                            <Text preset="g-14" mode="bold">
                                {label}
                            </Text>
                        </label>
                    )}
                    {children}
                    <Text className="form-control__message" preset="g-14">
                        {context.invalid && invalidMessage ? invalidMessage : message}
                    </Text>
                </div>
            </FormControlContext.Provider>
        );
    }
);
