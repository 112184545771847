import { AppDispatch, AppState } from "src/features";
import { Bill } from "src/features/order";
import { evenRound } from "src/sharedComponents/common/utils";
import { actionCreators } from "../reducers";
import { getPayOnlyBill, getPayOnlyEmbellishedItems, getPayOnlyState } from "../selectors";

export const updateItemSelection = (referenceId: string, quantity: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const { selectedItems } = getPayOnlyState(state);
        const embellishedItems = getPayOnlyEmbellishedItems(state);
        const bill = getPayOnlyBill(state)!;

        const newSelectedItems = { ...selectedItems, [referenceId]: quantity };

        const totalPrice = embellishedItems
            .filter(({ referenceId }) => referenceId && !!newSelectedItems[referenceId])
            .reduce(
                (accumulator, { referenceId, price }) =>
                    accumulator + (price || 0) * ((referenceId && newSelectedItems[referenceId]) || 0),
                0
            );

        const totalAmount = evenRound(calculatePayOnlyShareByPrice(bill, totalPrice), 2);

        dispatch(actionCreators.setAmount(totalAmount));
        dispatch(actionCreators.setSelectedItems(newSelectedItems));
    };
};

export const calculatePayOnlyShareByPrice = (bill: Bill, totalPrice: number) => {
    const { subTotal, surchargeAmount, surchargeAmountExTax, discountAmount, membershipDiscountAmount, taxSummary } =
        bill;

    // We are paying a share of the bill so work out how much
    // of a share of everything else we are paying
    const share = totalPrice / subTotal;

    const surchargeAmountShare = evenRound(surchargeAmount * share, 2);
    const surchargeAmountExTaxShare = evenRound(surchargeAmountExTax * share, 2);
    const discountAmountShare = evenRound(discountAmount * share, 2);
    const membershipDiscountAmountShare = evenRound(membershipDiscountAmount * share, 2);
    const taxSummaryShares = taxSummary.map((taxClassTotal) => ({
        ...taxClassTotal,
        total: taxClassTotal.total * share,
    }));

    const exclusiveTaxTotal = taxSummaryShares
        .filter((taxSummaryShare) => taxSummaryShare.exclusive === true)
        .reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);

    return (
        totalPrice +
        discountAmountShare +
        exclusiveTaxTotal +
        (surchargeAmountExTaxShare || surchargeAmountShare) +
        membershipDiscountAmountShare
    );
};
