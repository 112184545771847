import { HandledError } from "../../../common/error";

export enum KnownPaymentErrors {
    MustUseExtendedValidation = "MustUseExtendedValidation",
    ReviewOrder = "ReviewOrder",
}

export class MustUseExtendedValidationError extends HandledError {}

export function mustUseExtendedValidationErrorHandler() {
    return new MustUseExtendedValidationError();
}

export class ReviewOrderError extends HandledError {
    public reasons?: string[];

    constructor(reason?: string) {
        super(reason);
        this.reasons = reason?.split("|");
    }
}

export function reviewOrderErrorHandler(details?: string) {
    return new ReviewOrderError(details);
}
