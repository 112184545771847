import "../assets/CartViewModal.scss";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveOrderStatus,
    getAlcoholicDrinksCount,
    getCanSubmit,
    getCurrentMemberHasInvalidActiveOrderItem,
    getIsAlcoholicDrinksLimitExceeded,
    getIsEngageEnabled,
    getIsEngageEnabledForLocation,
    getIsPartyPosBillPrice,
    getShowEngageForUser,
} from "../selectors";
import {
    getEngageOffer,
    getIsPaymentWizardOpen,
    getLocationPromotionsEnabled,
    getMemberItemsHasPrice,
    getMemberNonMemberSubTotal,
    getMemberPromoCode,
    getMemberSubTotal,
} from "../../payment/selectors";
import { submitPaymentAndOrderOperation } from "../../payment/operations";
import { AppState } from "../..";
import {
    validateActiveOrder,
    validateActiveOrderInBackground,
    validateActiveOrderSilently,
} from "../actions/validateActiveOrder";
import { confirmOrder } from "../actions/confirmOrder";
import { showModalMessage } from "../../modalMessage/actions/show";
import { actionCreators as orderActions, OrderStatus } from "..";
import { modalMessages } from "../../modalMessage/messages";
import { usePrevious } from "src/sharedComponents/common/shared";
import { SimpleNavHeader } from "src/common/navigation";
import { Text, Tooltip } from "src/sharedComponents";
import { CartViewContainer } from "../container/CartViewContainer";
import { OrderSavingsModal } from "./OrderSavingsModal";
import { getShowCart } from "../selectors/cart";
import { actionCreators as cartActionCreators } from "../reducers/cart";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { scrolling } from "src/common/experience";
import { getIsItemModalOpen, getPendingItemId } from "../selectors/pendingItem";
import { StatusBar } from "src/common/statusBar";
import { ModalContainer } from "src/common/modal";
import { getHasMembershipSavings } from "src/features/membership/selectors/getVenueMembershipState";
import { getIsManageMembershipsOpen } from "src/features/membership/selectors/getActiveMembershipState";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { getAlcoholicDrinksLimit } from "src/features/menu/selectors";
import { getIsOpenTable, getOpenTableMemberActiveOrderStatus } from "../../openTable/selectors";
import { getServiceChanging, getServiceChangingOrOverdue } from "src/features/service/components/ServiceChangeModal";
import { submitOpenTableOrderOperation } from "../../openTable/operations";
import { getIsAnonymous, getRequireSignIn } from "../../accounts/selectors";
import { actionCreators as openTableActionCreators } from "src/features/openTable/reducers";
import { signInAction } from "../../signup/actions/signInAction";
import { confirmOrderOperation } from "../operations";
import { StampEarned } from "src/features/offers/components/StampEarned";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";

export const MINIMUM_ORDER_VALUE = 0.5;

export const CartViewModal = () => {
    const showCartModal = useSelector(getShowCart);
    const canSubmit = useSelector(getCanSubmit);
    const orderStatus = useSelector(getActiveOrderStatus);
    const subTotal = useSelector(getMemberSubTotal);
    const nonMemberSubTotal = useSelector(getMemberNonMemberSubTotal);
    const memberHasItemWithPrice = useSelector(getMemberItemsHasPrice);
    const isPosBilling = useSelector(getIsPartyPosBillPrice);
    const submitting = useSelector(
        (state: AppState) =>
            submitPaymentAndOrderOperation.getStatus(state) === "processing" ||
            submitOpenTableOrderOperation.getStatus(state) === "processing"
    );
    const confirmOrderOperationProcessing = useSelector(confirmOrderOperation.getStatus) === "processing";
    const isSearchOpen = useSelector((state: AppState) => state.menuSearch.showPage);
    const appliedPromoCode = useSelector(getMemberPromoCode);
    const promoCodesEnabled = useSelector(getLocationPromotionsEnabled);
    const isPaymentWizardOpen = useSelector(getIsPaymentWizardOpen);
    const hasVenueMembershipSavings = useSelector(getHasMembershipSavings);
    const isManageMembershipsOpen = useSelector(getIsManageMembershipsOpen);
    const alcoholicDrinksLimit = useSelector(getAlcoholicDrinksLimit);
    const alcoholicDrinksLimitExceeded = useSelector(getIsAlcoholicDrinksLimitExceeded);
    const alcoholicDrinksCount = useSelector(getAlcoholicDrinksCount);
    const isPendingItem = useSelector(getPendingItemId);
    const isOpenTable = useSelector(getIsOpenTable);
    const requireSignIn = useSelector(getRequireSignIn);

    // When item modal is open, we need to allow the body to scroll, otherwise the item modal is not scrollable when opened on top of the cart modal
    const viewingItem = useSelector(getIsItemModalOpen);

    const serviceChanging = useSelector(getServiceChanging);
    const serviceChangingOrOverdue = useSelector(getServiceChangingOrOverdue);

    const shouldConfirmOrderInBackground = useMemo(
        () => isPosBilling && !isPendingItem && !alcoholicDrinksLimitExceeded && !isOpenTable,
        [isPosBilling, isPendingItem, alcoholicDrinksLimitExceeded, isOpenTable]
    );

    const prevOrderStatus = usePrevious(orderStatus);
    const prevShouldConfirmOrderInBackground = usePrevious(shouldConfirmOrderInBackground);
    const prevCanSubmit = usePrevious(canSubmit);

    const [showSavingModal, setShowSavingModal] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [actionButtonText, setActionButtonText] = useState("");
    const [firstTimeCart, setFirstTimeCart] = useState(false);
    const [previousPagePosition, setPreviousPagePosition] = useState(0);

    const openTableMemberSubmitting = useRef(false);
    const prevIsConfirming = useRef<boolean | null>(null);
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const alcoholicDrinksLimitTrackingDispatched = useRef(false);
    const openTableMemberActiveOrderStatus = useSelector(getOpenTableMemberActiveOrderStatus);
    const currentMemberHasInvalidActiveOrderItem = useSelector(getCurrentMemberHasInvalidActiveOrderItem);
    const engageEnabledForLocation = useSelector(getIsEngageEnabledForLocation);
    const engageOfferApplied = useSelector(getEngageOffer);
    const engageEnabled = useSelector(getIsEngageEnabled);
    const showEngage = useSelector(getShowEngageForUser);
    const isAnonymous = useSelector(getIsAnonymous);

    const dispatch = useDispatch();

    const menuDataLocale = useContext(MenuDataLocaleContext);

    useEffect(() => {
        if (showCartModal && !firstTimeCart) {
            setFirstTimeCart(true);
        } else if (!showCartModal && firstTimeCart) {
            setFirstTimeCart(false);
        }
    }, [showCartModal, firstTimeCart]);

    useEffect(() => {
        if (!showCartModal && alcoholicDrinksLimitTrackingDispatched.current) {
            alcoholicDrinksLimitTrackingDispatched.current = false;
        }
    }, [showCartModal]);

    useEffect(() => {
        if (
            alcoholicDrinksLimit &&
            alcoholicDrinksCount &&
            alcoholicDrinksLimitExceeded &&
            showCartModal &&
            !alcoholicDrinksLimitTrackingDispatched.current
        ) {
            dispatch(
                orderActions.trackViewedLimitedAlcoholicDrinksTooltip({
                    current_page: "cart_page",
                    max_number_drinks: alcoholicDrinksLimit,
                    nb_total_items: alcoholicDrinksCount,
                })
            );
            alcoholicDrinksLimitTrackingDispatched.current = true;
        }
    }, [alcoholicDrinksLimitExceeded, showCartModal, alcoholicDrinksCount, alcoholicDrinksLimit, dispatch]);

    const close = useCallback(() => dispatch(cartActionCreators.showCart(false)), [dispatch]);

    const showMinimumOrderValue = useCallback(() => {
        dispatch(
            showModalMessage(modalMessages.minimumOrderValue(menuDataLocale.formatCurrency(MINIMUM_ORDER_VALUE), close))
        );
    }, [dispatch, menuDataLocale, close]);

    const onConfirm = useCallback(() => {
        if (isOpenTable) {
            if (!memberHasItemWithPrice) {
                return showMinimumOrderValue();
            }

            const markSubmittingAndValidate = () => {
                openTableMemberSubmitting.current = true;
                dispatch(validateActiveOrder());
            };

            if (requireSignIn) {
                dispatch(
                    signInAction({
                        useLocationTheme: true,
                        onComplete: markSubmittingAndValidate,
                        completeAfterLinkSso: true,
                    })
                );
                return;
            }

            return markSubmittingAndValidate();
        }

        if (nonMemberSubTotal < MINIMUM_ORDER_VALUE) {
            return showMinimumOrderValue();
        }

        const hasPromoCodeApplied = !!appliedPromoCode?.value;

        if (orderStatus === OrderStatus.VALID) {
            if (
                hasPromoCodeApplied ||
                shouldConfirmOrderInBackground ||
                hasVenueMembershipSavings ||
                (engageOfferApplied && engageEnabled)
            ) {
                setShowSavingModal(true);
            } else if (subTotal < MINIMUM_ORDER_VALUE) {
                // Something else reduced the subTotal to below the minimum amount
                // so show the generic modal
                return showMinimumOrderValue();
            }
        }

        if (
            shouldConfirmOrderInBackground &&
            !serviceChangingOrOverdue &&
            orderStatus &&
            [OrderStatus.INVALID, OrderStatus.OPEN, OrderStatus.PAYMENTFAILED].includes(orderStatus)
        ) {
            dispatch(validateActiveOrderInBackground());
        } else if (!shouldConfirmOrderInBackground) {
            if (hasPromoCodeApplied || hasVenueMembershipSavings || engageOfferApplied) {
                dispatch(validateActiveOrderInBackground());
            } else if (orderStatus === OrderStatus.VALID) {
                dispatch(confirmOrder());
            } else {
                dispatch(validateActiveOrder());
            }
        }
    }, [
        subTotal,
        nonMemberSubTotal,
        memberHasItemWithPrice,
        orderStatus,
        shouldConfirmOrderInBackground,
        dispatch,
        appliedPromoCode,
        hasVenueMembershipSavings,
        serviceChangingOrOverdue,
        isOpenTable,
        requireSignIn,
        engageOfferApplied,
        engageEnabled,
        showMinimumOrderValue,
    ]);

    const resetShowSavingModal = useCallback(() => setShowSavingModal(false), []);

    useEffect(() => {
        if (serviceChanging) {
            close();
        }
    }, [serviceChanging, close]);

    useEffect(() => {
        if (showCartModal && !isSearchOpen) {
            const scrollPosition = scrolling.scrollTop();
            setPreviousPagePosition(scrollPosition);
        }
    }, [showCartModal, isSearchOpen]);

    useEffect(() => {
        if (isOpenTable) return;

        if (
            shouldConfirmOrderInBackground &&
            canSubmit &&
            showCartModal &&
            (orderStatus === OrderStatus.OPEN || orderStatus === OrderStatus.PAYMENTFAILED) &&
            !engageEnabled &&
            (firstTimeCart ||
                prevOrderStatus !== OrderStatus.OPEN ||
                !prevShouldConfirmOrderInBackground ||
                !prevCanSubmit)
        ) {
            dispatch(validateActiveOrderInBackground());
        }

        if (!shouldConfirmOrderInBackground && prevOrderStatus === OrderStatus.VALIDATING && showCartModal) {
            if (orderStatus === OrderStatus.INVALID) {
                dispatch(showModalMessage(modalMessages.validationFailed()));
            } else if (orderStatus === OrderStatus.VALID && !submitting) {
                if (appliedPromoCode?.value || hasVenueMembershipSavings) {
                    if (!isPaymentWizardOpen) {
                        setShowSavingModal(true);
                    }
                } else if (subTotal < MINIMUM_ORDER_VALUE) {
                    // Something else reduced the subTotal to below the minimum amount
                    // so show the generic modal
                    if (!isPaymentWizardOpen) {
                        showMinimumOrderValue();
                    }
                } else if (!isManageMembershipsOpen && (!engageEnabledForLocation || isAnonymous)) {
                    dispatch(confirmOrder());
                }
            }
        }

        if (
            canSubmit &&
            showCartModal &&
            (orderStatus === OrderStatus.OPEN || orderStatus === OrderStatus.PAYMENTFAILED) &&
            showEngage &&
            !confirmOrderOperationProcessing &&
            (firstTimeCart || prevOrderStatus !== OrderStatus.OPEN || !prevCanSubmit)
        ) {
            dispatch(validateActiveOrderSilently());
        }
    }, [
        canSubmit,
        shouldConfirmOrderInBackground,
        orderStatus,
        prevCanSubmit,
        prevShouldConfirmOrderInBackground,
        prevOrderStatus,
        submitting,
        dispatch,
        firstTimeCart,
        showCartModal,
        appliedPromoCode,
        isPaymentWizardOpen,
        hasVenueMembershipSavings,
        isManageMembershipsOpen,
        isOpenTable,
        engageEnabledForLocation,
        engageOfferApplied,
        showEngage,
        isAnonymous,
        engageEnabled,
        confirmOrderOperationProcessing,
        subTotal,
        showMinimumOrderValue,
    ]);

    useEffect(() => {
        if (
            isOpenTable &&
            showCartModal &&
            (openTableMemberActiveOrderStatus === OrderStatus.VALID ||
                openTableMemberActiveOrderStatus === OrderStatus.INVALID)
        ) {
            //We can't rely on the OrderStatus being Validating, Valid, or Invalid since the ActiveOrder is shared and backend will not be changing the ActiveOrder.Status.
            //We added MemberActiveOrderStatus in the Redux store and it is set based on the response of the 'ValidateActiveOrder' request to determine whether validation passed or failed.

            if (openTableMemberActiveOrderStatus === OrderStatus.INVALID || currentMemberHasInvalidActiveOrderItem) {
                dispatch(showModalMessage(modalMessages.validationFailed()));

                //As we don't want to use MemberActiveOrderStatus for any other purpose after displaying the failed message, we set it to undefined.
                dispatch(openTableActionCreators.setMemberActiveOrderStatus());
            } else if (
                openTableMemberActiveOrderStatus === OrderStatus.VALID &&
                !submitting &&
                openTableMemberSubmitting.current
            ) {
                setPreviousPagePosition(0);
                openTableMemberSubmitting.current = false;
                dispatch(confirmOrder());

                //As we don't want to use MemberActiveOrderStatus for any other purpose after submitting member order, we set it to undefined.
                dispatch(openTableActionCreators.setMemberActiveOrderStatus());
            }
        }
    }, [
        submitting,
        dispatch,
        showCartModal,
        isOpenTable,
        openTableMemberActiveOrderStatus,
        currentMemberHasInvalidActiveOrderItem,
    ]);

    useEffect(() => {
        const nextIsConfirming =
            shouldConfirmOrderInBackground &&
            (orderStatus === OrderStatus.VALIDATING || orderStatus === OrderStatus.OPEN);

        if (nextIsConfirming !== isConfirming) {
            prevIsConfirming.current = isConfirming;
        }

        setIsConfirming(nextIsConfirming);
    }, [shouldConfirmOrderInBackground, orderStatus, isConfirming]);

    useEffect(() => {
        let ctaText = isOpenTable ? "Submit order" : "Go to checkout";
        if (shouldConfirmOrderInBackground) {
            if (!canSubmit || orderStatus === OrderStatus.VALID) {
                ctaText = "Confirm & apply offers";
            } else if (orderStatus === OrderStatus.INVALID) {
                ctaText = "Retry";
            } else {
                ctaText = "Confirming prices with the venue...";
            }
        }

        setActionButtonText(ctaText);
    }, [canSubmit, shouldConfirmOrderInBackground, orderStatus, showCartModal, isOpenTable]);

    useEffect(() => {
        const hasJustConfirmed = prevIsConfirming.current !== null && isConfirming !== prevIsConfirming.current;
        if (shouldConfirmOrderInBackground && orderStatus === OrderStatus.INVALID && hasJustConfirmed) {
            dispatch(showModalMessage(modalMessages.cannotConfirmPrices()));
        }
    }, [shouldConfirmOrderInBackground, orderStatus, isConfirming, dispatch]);

    const onClose = useCallback(() => {
        prevIsConfirming.current = null;
        scrolling.scrollTo({ top: previousPagePosition });
    }, [previousPagePosition]);

    const onOpen = useCallback(() => {
        document.querySelector(".party-wrapper")?.scrollTo({ top: previousPagePosition });
    }, [previousPagePosition]);

    useEffect(() => {
        if (overlayRef.current) {
            if (isPaymentWizardOpen) {
                overlayRef.current.style.setProperty("--animation-z", "0px");
            } else {
                overlayRef.current.style.removeProperty("--animation-z");
            }
        }
    }, [isPaymentWizardOpen]);

    const isSavingsModalVisible =
        (!promoCodesEnabled && shouldConfirmOrderInBackground) ||
        promoCodesEnabled ||
        hasVenueMembershipSavings ||
        engageEnabled;

    return (
        <ModalContainer
            isOpen={showCartModal}
            className={{
                base: "CartView-modal slide-in",
                afterOpen: "CartView-modal--after-open slide-in--after-open",
                beforeClose: "CartView-modal--before-close slide-in--before-close",
            }}
            overlayClassName="CartView-modal--overlay slide-in-modal--overlay"
            bodyOpenClassName="CartView-modal__Body--open"
            closeTimeoutMS={250}
            shouldCloseOnEsc
            parentSelector={() => document.getElementById("root") || document.body}
            portalClassName="CartViewModalPortal ReactModalPortal"
            onAfterClose={onClose}
            onAfterOpen={onOpen}
            allowBodyScroll={viewingItem}
            overlayRef={(ref) => {
                overlayRef.current = ref;
            }}
        >
            <StatusBar backgroundColor="#fff" />
            <SimpleNavHeader key={"cart-view-modal"} closeToBack={true} customBack={"cart-view-modal"} onBack={close} />
            <LocationThemeContainer>
                <div className="CartView-modal__content">
                    <CartViewContainer editable />
                    <footer className="CartView-modal__footer top-shadow">
                        <StampEarned />
                        <Tooltip
                            show={alcoholicDrinksLimitExceeded}
                            tooltipContent={
                                <Text
                                    mode={["block"]}
                                    preset="g-14"
                                    value={`This venue has a limit of ${alcoholicDrinksLimit} alcoholic drinks per order. Reduce your quantity to continue.`}
                                />
                            }
                        />
                        <NetworkConnectedButton
                            onClick={onConfirm}
                            disabled={
                                !canSubmit ||
                                isConfirming ||
                                alcoholicDrinksLimitExceeded ||
                                (showEngage && orderStatus !== OrderStatus.VALID)
                            }
                            mode={
                                shouldConfirmOrderInBackground && orderStatus === OrderStatus.INVALID
                                    ? "outline"
                                    : undefined
                            }
                            value={actionButtonText}
                        />
                    </footer>
                </div>
            </LocationThemeContainer>
            {isSavingsModalVisible && (
                <OrderSavingsModal showIfNeeded={showSavingModal} onClose={resetShowSavingModal} />
            )}
        </ModalContainer>
    );
};
