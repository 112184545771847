import { trackedEvent } from "src/common/events/reduxEventTracking";
import { ServiceScheduleSlot } from "../types";

export interface TakeawayDetails {
    scheduleEnabled: boolean;
    scheduleBufferTimeInMinutes?: number;
    serviceScheduleSlots?: ServiceScheduleSlot[];
    contactPhoneNumber?: string | null;
    collectionInstructions?: string | null;
}

export interface LocationAddressDetails {
    googleBusinessName: string | null;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    region: string | null;
    postcode: string | null;
    country: string;
}

export interface TakeawayOptionsState {
    showSchedulePage: boolean;
    takeawayDetails: TakeawayDetails;
    showServiceChange: boolean;
    setDefaultSelectedTimeToASAP: boolean;
    previousVenueDetails?: { [key: string]: CollectionDetails };
}

export interface CollectionDetails {
    collectionInstructions?: string | null;
    contactPhoneNumber?: string | null;
    venueAddress: LocationAddressDetails;
}

export enum TypeKeys {
    SET_TAKEAWAY_DETAILS = "TAKEAWAY_OPTIONS/SET_TAKEAWAY_DETAILS",
    SHOW_SCHEDULE_PAGE = "TAKEAWAY_OPTIONS/SHOW_SCHEDULE_PAGE",
    SET_SCHEDULE_SLOTS = "TAKEAWAY_OPTIONS/SET_SCHEDULE_SLOTS",
    SHOW_TAKEAWAY_SERVICE_CHANGE = "TAKEAWAY/SHOW_SERVICE_CHANGE",
    SET_DEFAULT_SELECTED_TIME_SLOT_TO_ASAP = "TAKEAWAY/SET_DEFAULT_SELECTED_TIME_SLOT_TO_ASAP",
    SET_VENUE_DETAILS = "TAKEAWAY/SET_VENUE_DETAILS",
    COLLECTION_TIME_SET = "PARTY/COLLECTION_TIME_SET",
    SHOULD_CHANGE_MENU = "PARTY/MENU_CHANGE_RESPONSE",
    VIEW_MAP_CLICKED = "PARTY/VIEW_MAP_CLICKED",
    CONTACT_CLICKED = "PARTY/CONTACT_CLICKED",
}

export const actionCreators = {
    setTakeawayDetails: (takeawayDetails: TakeawayDetails) => ({
        type: TypeKeys.SET_TAKEAWAY_DETAILS,
        takeawayDetails,
    }),
    showSchedulePage: (showSchedulePage: boolean) => ({
        type: TypeKeys.SHOW_SCHEDULE_PAGE,
        showSchedulePage,
    }),
    setScheduleSlots: (slots: ServiceScheduleSlot[]) => ({
        type: TypeKeys.SET_SCHEDULE_SLOTS,
        slots,
    }),
    showServiceChange: (showServiceChange: boolean) => ({
        type: TypeKeys.SHOW_TAKEAWAY_SERVICE_CHANGE,
        showServiceChange,
    }),
    setDefaultTimeSelectedToASAP: (setDefaultSelectedTimeToASAP: boolean) => ({
        type: TypeKeys.SET_DEFAULT_SELECTED_TIME_SLOT_TO_ASAP,
        setDefaultSelectedTimeToASAP,
    }),
    setVenueDetails: (venueDetails: { [key: string]: CollectionDetails }) => ({
        type: TypeKeys.SET_VENUE_DETAILS,
        venueDetails,
    }),
    trackServiceChange: (collectionTimeChanged: boolean) =>
        trackedEvent({
            type: TypeKeys.SHOULD_CHANGE_MENU,
            collection_time_changed: collectionTimeChanged,
        }),
    trackCollectionTimeSet: () => trackedEvent({ type: TypeKeys.COLLECTION_TIME_SET }),
    trackViewMapClicked: (currentPage: string) =>
        trackedEvent({
            type: TypeKeys.VIEW_MAP_CLICKED,
            current_page: currentPage,
        }),
    trackContactClicked: (currentPage: string) =>
        trackedEvent({
            type: TypeKeys.CONTACT_CLICKED,
            current_page: currentPage,
        }),
};

export type SetTakeawayDetails = { type: TypeKeys.SET_TAKEAWAY_DETAILS; takeawayDetails: TakeawayDetails };
export type ShowTakeawayPage = { type: TypeKeys.SHOW_SCHEDULE_PAGE; showSchedulePage: boolean };
export type SetScheduleSlots = { type: TypeKeys.SET_SCHEDULE_SLOTS; slots: ServiceScheduleSlot[] };
export type SetShowServiceChange = { type: TypeKeys.SHOW_TAKEAWAY_SERVICE_CHANGE; showServiceChange: boolean };
export type SetSelectedTimeSlotExpired = {
    type: TypeKeys.SET_DEFAULT_SELECTED_TIME_SLOT_TO_ASAP;
    setSelectedTimeToASAP: boolean;
};
export type SetVenueDetails = {
    type: TypeKeys.SET_VENUE_DETAILS;
    venueDetails: { [key: string]: CollectionDetails };
};

export type TakeawayOptionsAction =
    | SetTakeawayDetails
    | ShowTakeawayPage
    | SetScheduleSlots
    | SetShowServiceChange
    | SetSelectedTimeSlotExpired
    | SetVenueDetails;

const initialState: TakeawayOptionsState = {
    showSchedulePage: false,
    takeawayDetails: {
        scheduleEnabled: false,
    },
    showServiceChange: false,
    setDefaultSelectedTimeToASAP: false,
};

export function reducer(
    state: TakeawayOptionsState = initialState,
    action: TakeawayOptionsAction
): TakeawayOptionsState {
    if (action.type === TypeKeys.SET_TAKEAWAY_DETAILS) {
        return {
            ...state,
            takeawayDetails: action.takeawayDetails,
        };
    }

    if (action.type === TypeKeys.SHOW_SCHEDULE_PAGE) {
        return {
            ...state,
            showSchedulePage: action.showSchedulePage,
        };
    }

    if (action.type === TypeKeys.SET_SCHEDULE_SLOTS) {
        return {
            ...state,
            takeawayDetails: {
                ...state.takeawayDetails,
                serviceScheduleSlots: action.slots,
            },
        };
    }

    if (action.type === TypeKeys.SHOW_TAKEAWAY_SERVICE_CHANGE) {
        return {
            ...state,
            showServiceChange: action.showServiceChange,
        };
    }

    if (action.type === TypeKeys.SET_DEFAULT_SELECTED_TIME_SLOT_TO_ASAP) {
        return {
            ...state,
            setDefaultSelectedTimeToASAP: action.setSelectedTimeToASAP,
        };
    }

    if (action.type === TypeKeys.SET_VENUE_DETAILS) {
        return {
            ...state,
            previousVenueDetails: {
                ...state.previousVenueDetails,
                ...action.venueDetails,
            },
        };
    }

    return state;
}
