import { AppDispatch, AppMiddleware } from "../..";
import { TypeKeys } from "..";
import { OpenTableAction } from "../reducers";
import { getParty, MemberActivity } from "../../order";
import { replace } from "connected-react-router";
import { getIsOpenTable } from "../selectors";
import { saveMemberActivityInfo } from "../../partyOnboarding/persistence/memberActivity";
import {
    actionCreators as navBarActionCreators,
    NavBarActions,
    TypeKeys as NavBarTypeKeys,
} from "../../../common/navigation/reducers/navBar";
import { NavBarType } from "../../../common/navigation/types";
import { pagePositionActions } from "src/features/pagePosition";
import { actionCreators as openTableActionCreators } from "../../openTable/reducers";

const goToActivityPage = (dispatch: AppDispatch, memberActivity: MemberActivity) => {
    if (memberActivity === MemberActivity.Paying) {
        dispatch(navBarActionCreators.activeType(NavBarType.PAY));
    } else if (memberActivity === MemberActivity.Ordering) {
        dispatch(navBarActionCreators.activeType(NavBarType.MENU));
    }
};

type OpenTableMiddlewareAction = OpenTableAction | NavBarActions;

export const openTableMiddleware =
    () => (store: AppMiddleware) => (next: AppDispatch) => (action: OpenTableMiddlewareAction) => {
        next(action);

        const state = store.getState();
        const party = getParty(state);
        const isOpenTable = getIsOpenTable(state);

        if (party && isOpenTable) {
            if (action.type === TypeKeys.SET_ACTIVITY && action.shouldStartActivity) {
                goToActivityPage(store.dispatch, action.activity);
            }

            if (action.type === NavBarTypeKeys.ACTIVE_TYPE) {
                if (action.navBarType === NavBarType.PAY && window.location.pathname !== "/pay") {
                    store.dispatch(replace("/pay"));
                    next(openTableActionCreators.setActivity(MemberActivity.Paying));
                    saveMemberActivityInfo(party.id, MemberActivity.Paying);
                }

                if (action.navBarType === NavBarType.MENU && !window.location.pathname.startsWith("/menu")) {
                    store.dispatch(replace("/menu/service"));
                    saveMemberActivityInfo(party.id, MemberActivity.Ordering);
                    next(openTableActionCreators.setActivity(MemberActivity.Ordering));
                    next(pagePositionActions.resetServicePagePosition());
                }
            }
        }
    };
