import { createSelector } from "reselect";
import { getVisibleMenuData } from "../../menu/selectors";
import { getRestaurantFlags } from "../../order/selectors/restaurantFlags";
import { Tips } from "../../menudata";
import { TipLevel } from "../../order/reducers/types";
import { getPaymentState } from "./paymentState";
import { TipType } from "../types";
import { CustomTip } from "../reducers";
import { evenRound } from "../../../sharedComponents/common/utils";
import { getShowGratuity } from "./groupTabs";
import {
    getActiveGroupTabData,
    getActiveTabTipFactor,
    getActiveTabGratuityFactor,
} from "../../groupTabs/selectors/activeGroupTab";

export const getTipsConfiguration = createSelector(
    getVisibleMenuData,
    getRestaurantFlags,
    getActiveGroupTabData,
    (menuData, restaurantFlags, activeTab): Tips => {
        if (activeTab?.tips) {
            return { ...activeTab.tips };
        }

        if (!menuData || !menuData.tips) {
            const levels = menuData?.locale === "en-US" ? [0.18, 0.2, 0.22, 0] : [0.05, 0.1, 0.2, 0];
            if (menuData?.locale === "en-GB") {
                levels[1] = 0.125;
            }

            let defaultLevelIndex: number | null = null;

            switch (restaurantFlags?.defaultTipLevel) {
                case TipLevel.NOTIP:
                    defaultLevelIndex = 3;
                    break;
                case TipLevel.LEVEL1:
                    defaultLevelIndex = 0;
                    break;
                case TipLevel.LEVEL2:
                    defaultLevelIndex = 1;
                    break;
                case TipLevel.LEVEL3:
                    defaultLevelIndex = 2;
                    break;
            }

            return {
                enabled: !!restaurantFlags?.allowTips,
                default: defaultLevelIndex,
                levels: levels,
            };
        }

        return {
            enabled: !!restaurantFlags?.allowTips,
            default: menuData.tips.default,
            levels: menuData.tips.levels,
        };
    }
);

export const getTipsEnabled = createSelector(
    getTipsConfiguration,
    getShowGratuity,
    (tipsConfiguration, showGratuity) => tipsConfiguration.enabled && !showGratuity
);

export const getCustomTip = createSelector(
    getPaymentState,
    getTipsConfiguration,
    (paymentState, getTipsConfiguration) => (getTipsConfiguration.enabled ? paymentState.customTip : undefined)
);

export const getTipType = createSelector(getCustomTip, (customTip) => customTip?.tipType);

export const getTipAmount = createSelector(getCustomTip, (customTip) => customTip?.tipAmount);

export const getDisplayTipAmount = createSelector(getCustomTip, (customTip) => customTip?.displayTipAmount);

export const getTipLevel = createSelector(getPaymentState, getTipsConfiguration, (paymentState, tipLevels) =>
    !tipLevels.enabled
        ? null
        : paymentState.tipLevel === null
        ? tipLevels.default !== null
            ? tipLevels.levels[tipLevels.default]
            : null
        : paymentState.tipLevel
);

export const getShowTipModal = createSelector(getPaymentState, (paymentState) => paymentState.showTipModal);

export const calculateTipAmount = (customTip: CustomTip | undefined, tipLevel: number | null, total: number) => {
    if (total === 0) return 0;
    if (customTip) {
        if (!customTip.tipAmount) return 0;
        if (customTip.tipType === TipType.PERCENTAGE) {
            return evenRound((total * customTip.tipAmount) / 100, 2);
        } else {
            return customTip.tipAmount;
        }
    }
    if (!tipLevel) return 0;
    return evenRound(total * tipLevel, 2);
};

export const calculateTipPercentage = (customTip: CustomTip | undefined, tipLevel: number | null, total: number) => {
    if (total === 0) return 0;
    if (customTip) {
        if (!customTip.tipAmount) return 0;
        if (customTip.tipType === TipType.CURRENCY) {
            return evenRound(customTip.tipAmount / total, 2);
        } else {
            return customTip.tipAmount / 100;
        }
    }
    if (tipLevel === null || tipLevel === 0) return 0;
    return tipLevel;
};

export const getTabTipFactor = createSelector(
    getRestaurantFlags,
    getTipsConfiguration,
    getActiveTabTipFactor,
    getActiveTabGratuityFactor,
    ({ groupTabGratuityFactor, allowTipOnly }, tipConfig, activeTabTipFactor, activeTabGratuityFactor) => {
        if (activeTabTipFactor) return activeTabTipFactor;
        if (!allowTipOnly || activeTabGratuityFactor || groupTabGratuityFactor || tipConfig.default === null) {
            return undefined;
        }

        return tipConfig.levels[tipConfig.default];
    }
);
