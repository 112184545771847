import { createSelector } from "reselect";
import { Party, PartyPayment, PartyRefund } from "../../order";
import { PaymentStatus } from "../types";
import { getAnyParty, getParty } from "../../order/selectors";
import { getCurrentMemberId } from "src/features/accounts/selectors";

export const getSurchargePercentage = createSelector(
    getParty,
    (party) => party?.activeOrder.bill?.surchargePercentage ?? 0
);

const getPaymentsByStatus = (party: Party | null, status: PaymentStatus) =>
    (party?.payments?.filter((p) => p.status === status) ?? []) as PartyPayment[];

const getRefundsByStatus = (party: Party | null, status: string) =>
    (party?.refunds?.filter((p) => p.status === status) ?? []) as PartyRefund[];

export const getCompletePayments = createSelector(getAnyParty, (party) =>
    getPaymentsByStatus(party, PaymentStatus.COMPLETE)
);

export const getFailedPayments = createSelector(getAnyParty, (party) =>
    getPaymentsByStatus(party, PaymentStatus.FAILED)
);

export const getPendingPayments = createSelector(getAnyParty, (party) =>
    getPaymentsByStatus(party, PaymentStatus.PENDING)
);

export const getCompleteRefunds = createSelector(getAnyParty, (party) => getRefundsByStatus(party, "Complete"));

const getMemberPayments = (payments: PartyPayment[], memberId: string) => payments.filter((p) => p.owner === memberId);

export const getMemberCompletePayments = createSelector(getCompletePayments, getCurrentMemberId, getMemberPayments);

export const getMemberFailedPayments = createSelector(getFailedPayments, getCurrentMemberId, getMemberPayments);

export const getMemberPendingPayments = createSelector(getPendingPayments, getCurrentMemberId, getMemberPayments);

export const getIsPayingMember = createSelector(
    getMemberCompletePayments,
    (memberPayments) => memberPayments.length > 0
);
