import { createSelector } from "reselect";
import { AppState } from "src/features";

export const getOrderHeadCount = (state: AppState) => state.orderHeadCount;

export const getShowOrderHeadCountModal = createSelector(
    getOrderHeadCount,
    (orderHeadCount) => !!orderHeadCount.showOrderHeadCountModal
);

export const getOrderHeadCountValue = createSelector(getOrderHeadCount, (orderHeadCount) => orderHeadCount.value);
