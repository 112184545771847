import { actionCreators } from "../reducers/marketing";
import { acceptTermsUpdateOperation, fetchTermsUpdateOperation } from "..";
import * as api from "../api";

export function getLatestTermsUpdate() {
    return fetchTermsUpdateOperation.getThunk(async (dispatch) => {
        const response = await api.getLatestTermsUpdate();

        if (response !== null) {
            dispatch(actionCreators.promptAcceptTermsUpdate(response));
        }
    });
}

export function acceptTermsUpdate() {
    return acceptTermsUpdateOperation.getThunk(async (dispatch) => {
        await api.acceptTermsUpdate();
        dispatch(actionCreators.acceptTermsUpdate());
    });
}
