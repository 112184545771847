import "../assets/OfferClaimedPage.scss";
import React, { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { getClaimOfferResult, getIsClaimingOffer, getOfferData } from "../selectors";
import { Text } from "src/sharedComponents";
import { Confetti } from "src/sharedComponents/controls/confetti";
import { CouponCircleIcon } from "src/sharedComponents/assets/icons";
import { getOfferTypeString } from "../utils";
import { capitaliseFirstLetter } from "src/common/formatter";
import { OfferType } from "../types";

export const OfferClaimedPageContent = ({ children }: PropsWithChildren<{}>) => {
    const offer = useSelector(getOfferData);
    const claimOfferResult = useSelector(getClaimOfferResult);
    const claimingOffer = useSelector(getIsClaimingOffer);
    const offerTypeString = getOfferTypeString(offer);

    const claimTitle = useMemo(() => {
        if (claimingOffer) {
            return `Claiming your ${offerTypeString}`;
        }
        if (claimOfferResult?.claimed) {
            return `${capitaliseFirstLetter(offerTypeString)} claimed`;
        }
        return `Failed to claim ${offerTypeString}`;
    }, [claimingOffer, claimOfferResult, offerTypeString]);

    const claimSubtitle = () => {
        switch (offer?.offerType) {
            case OfferType.DISCOUNT:
                return (
                    <>
                        <Text preset="g-14" className="offer-claimed-page__text__subtitle">
                            Your offer has been saved to your account. Now you just need to order through me&u and if
                            your cart meets the conditions your reward will be auto-applied.
                        </Text>
                        <Text preset="g-14" className="offer-claimed-page__text__subtitle">
                            See you real soon!
                        </Text>
                    </>
                );
            case OfferType.STAMP_CARD:
                return (
                    <Text preset="g-14" className="offer-claimed-page__text__subtitle">
                        Congratulations, your stamp card is now saved! Earn stamps towards your final reward when you
                        order through me&u.
                    </Text>
                );
            default:
                return undefined;
        }
    };

    return (
        <div className="offer-claimed-page__content">
            <section className="offer-claimed-page__info-container">
                {claimOfferResult?.claimed && <CouponCircleIcon />}
                <div className="offer-claimed-page__text">
                    <Text preset="title-24" mode="extra-bold" className="offer-claimed-page__text__title">
                        {claimTitle}
                    </Text>
                    {claimOfferResult?.claimed && claimSubtitle()}
                </div>
                <Confetti fire={claimOfferResult?.claimed} full y={0.7} startVelocity={50} />
            </section>
            {children}
        </div>
    );
};
