import { HandledError } from "../../../common/error";

export enum KnownPayOnlyErrors {
    Overpayment = "Overpayment",
    RemainingBalanceTooLow = "RemainingBalanceTooLow",
    PartyClosedBeforePayment = "PartyClosedBeforePayment",
}

export class PayOnlyOverpaymentError extends HandledError {}

export class PayOnlyRemainingBalanceTooLowError extends HandledError {}

export class PayOnlyPartyClosedBeforePaymentError extends HandledError {}

export function payOnlyOverpaymentErrorHandler() {
    return new PayOnlyOverpaymentError();
}

export function payOnlyRemainingBalanceTooLowErrorHandler() {
    return new PayOnlyRemainingBalanceTooLowError();
}

export function payOnlyPartyClosedBeforePaymentErrorHandler() {
    return new PayOnlyPartyClosedBeforePaymentError();
}
