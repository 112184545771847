import * as pako from "pako";
import { Party } from "../../types";

export const decompressParty = (compressedParty: string): Party => {
    const binaryData = atob(compressedParty);
    const charData = binaryData.split("").map((x) => x.charCodeAt(0));
    const byteData = new Uint8Array(charData);
    const decompressedData = pako.inflate(byteData);
    const partyJson = String.fromCharCode.apply(null, Array.from(new Uint16Array(decompressedData)));
    return JSON.parse(partyJson);
};
