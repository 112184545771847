import { DeliveryOptions, MemberShare, PartyMember, PartyType, PaymentOptions } from "../../order";

export interface OrderHistoryResponse {
    data: OrderHistoryData[];
    hasMore: boolean;
}

export interface HistoryListItem {
    itemId: string;
    relatedItems: HistoryListItem[];
    to: (itemId: string) => string;
    locationName: string;
    locationImagePath: string;
}
export interface OrderHistoryData extends HistoryListItem {
    partyId: string;
    posixServiceDate: number;
    readError?: boolean;
    mealHistoryOrders?: MealHistoryOrder[];
    someItemsFailed: boolean;
    allItemsFailed: boolean;
    details?: OrderHistoryDetails;
    summary?: OrderHistorySummary;
    partyType?: PartyType;
}

export interface OrderHistorySummary {
    locationId: string;
    locationImage: string;
    locationLogo?: string;
    locationName: string;
    serviceDate: string;
    tableNumber: string;
    tableLabel?: string;
    total: number;
    locationColor?: string;
    locale: string;
    currency: string;
    paidWithGroupTab?: boolean;
    refunded?: RefundedState;
}

export interface OrderHistoryPayment {
    processingFee: number;
    tip: number;
    paymentMethod: string | null;
    paymentMethodDescription: string | null;
    cardType: string | null;
    paymentIsOutstanding: boolean;
    subtotal: number;
    total: number;
    totalRefunded: number;
    gratuityFactor: number;
}

export interface OrderHistoryDetails extends OrderHistoryPayment {
    memberId: string;
    partyId: string;
    surcharge: number;
    discount: number;
    orderAmount?: number;
    memberDiscount?: number;
    unallocatedAmount?: number;
    tableTotal: number;
    orderDetail: OrderDetail[];
    paymentOptions?: PaymentOptions;
    partyPayments?: PartyPayment[];
    members?: PartyMember[];
    rating?: number;
    billShare?: MemberShare;
    payments?: OrderHistoryPayment[];
    pointsSpendAmount?: number;
}

export interface MealHistoryOrder {
    orderId: string;
    tableNumber: string;
    status: MealHistoryOrderStatus;
    isPickup?: boolean;
    minTime?: number;
    maxTime?: number;
    readyTime?: string;
    isBuzzer?: boolean;
    isTakeaway?: boolean;
    deliveryOption?: DeliveryOptions;
    isScheduled: boolean;
    dateScheduled: string | null;
}

export enum MealHistoryOrderStatus {
    COMPLETED = 0,
    SUBMITTED = 1,
    PREPARING = 2,
    READY = 3,
    REJECTED = 4,
    SUBMIT_FAILED = 5,
    ORPHANED = 6,
}

export interface OrderDetail {
    displayName: string;
    memberId?: string;
    amount: number;
    notes?: string;
    variant?: string;
    modifiers?: string[];
    modifierDetails?: ModifierDetail[];
    options?: string[]; // should include all the text the user seen on in the bento box?
    count: number;
    failedToSubmit?: boolean;
    isPickupItem: boolean;
    waitTime?: number;
}

export interface ModifierDetail {
    optionName: string;
    price: number;
    nestedModifiers?: ModifierDetail[];
}

export interface PartyPayment {
    memberId: string;
    displayName: string;
    amount: number;
    failedToSubmit?: boolean;
}

export enum RefundedState {
    PENDING = "pending",
    REFUNDED = "complete",
    FAILED = "failed",
}
