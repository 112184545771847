import { trackedEvent } from "../../../common/events/reduxEventTracking";

export interface AppInstanceState {
    isDuplicated: boolean;
}

enum TypeKeys {
    DUPLICATED = "APP_INSTANCE/DUPLICATED",
}

export const actionCreators = {
    duplicate: () => trackedEvent({ type: TypeKeys.DUPLICATED }),
};

const initialState: AppInstanceState = {
    isDuplicated: false,
};

type DuplicateAction = { type: TypeKeys.DUPLICATED };

type AppInstanceActions = DuplicateAction;

export function reducer(state = initialState, actions: AppInstanceActions) {
    switch (actions.type) {
        case TypeKeys.DUPLICATED:
            return { isDuplicated: true };
        default:
            return state;
    }
}
