import { AppDispatch, AppMiddleware } from "../..";
import { LocationMenuData, SnapshotAction, TypeKeys as MenuDataTypeKeys } from "src/features/menudata/reducers/active";
import { TypeKeys as PartyTypeKeys, PartyAction } from "src/features/order/reducers/party";
import { config } from "src/common/config";
import Fuse from "fuse.js";
import { FuseMenu, FuseMenuItem } from "../types";
import { menuSearchActions } from "../reducers";
import { getActiveServiceMenus, getVisibleMenuData } from "src/features/menu/selectors";
import { normalizeAccents } from "src/common/shared";

export const menuSearchMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    if (config.enableMenuSearch !== "1") {
        return (action: SnapshotAction) => {
            next(action);
        };
    }

    function menuDataToSearch(data: LocationMenuData) {
        const activeMenus = getActiveServiceMenus(store.getState());
        const fuseMenuItems: FuseMenuItem[] = [];

        activeMenus?.forEach((menu) =>
            menu.categories.forEach((cat) =>
                data.categories[cat].menuItems.forEach((itemId) => {
                    const itemData = data.items[itemId];
                    fuseMenuItems.push({
                        id: itemId,
                        displayName: itemData.displayName,
                        description: itemData.description,
                        extra: `${itemData.displayNames?.join(" ") || ""} ${itemData.details?.join(" ") || ""}`.trim(),
                        type: itemData.type,
                        images: itemData.images,
                        available: itemData.available,
                        category: data.categories[cat].displayName,
                        variants: itemData.variants?.length
                            ? itemData.variants?.map((variant) => variant.displayName).join(" ")
                            : "",
                        waitTime: itemData.waitTime,
                    });
                })
            )
        );

        return fuseMenuItems;
    }

    function initMenuSearch() {
        const data = getVisibleMenuData(store.getState());
        if (!data || !Object.keys(data.items).length) return;
        const menu = menuDataToSearch(data);
        const fuse: FuseMenu = new Fuse(menu, {
            threshold: 0.25,
            useExtendedSearch: true,
            ignoreLocation: true,
            getFn: function (item: FuseMenuItem, path: string | string[]) {
                const pa = (Fuse as any).config.getFn.call(this, item, path);
                return normalizeAccents(pa) || pa;
            },
            keys: [
                {
                    name: "displayName",
                    weight: 1,
                },
                {
                    name: "description",
                    weight: 0.8,
                },
                {
                    name: "extra",
                    weight: 0.8,
                },
                {
                    name: "category",
                    weight: 1,
                },
                {
                    name: "variants",
                    weight: 0.6,
                },
                {
                    name: "categoryId",
                    weight: 1,
                },
                {
                    name: "type",
                    weight: 1,
                },
            ],
        });

        next(menuSearchActions.setFuseMenu(fuse));
    }

    return (action: SnapshotAction | PartyAction) => {
        next(action);

        if (action.type === MenuDataTypeKeys.UPDATED || action.type === PartyTypeKeys.SWITCHED) {
            Promise.resolve().then(() => initMenuSearch()); // need to test -- we need to get the data after preparing to view
        }
    };
};
