import React, { useEffect, useRef, useState } from "react";
import "../assets/UnsupportedPage.scss";
import classNames from "classnames";
import { ChromeIcon, DeviceIcon, SafariIcon } from "src/sharedComponents/assets/icons";
import { isMobile } from "react-device-detect";
import { QRCodeSVG } from "qrcode.react";
import SVG from "react-inlinesvg";
import { TapOnMenuHeader, Text } from "../../../sharedComponents";
import { useDispatch } from "react-redux";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { browserSupport, handleUrlLink, splashScreen } from "src/common/experience";
import getLocationInfo from "../api/getLocationInfo";
import { UrlLinkData, UrlType } from "src/common/experience/interface";
import { getIsOfferPath, getIsTakeawayPath } from "src/common/experience/shared/url";
import { LocationMarkerClaim } from "../../partyOnboarding/types";

interface LocationClaimInfo {
    locationId: string;
    locationName: string;
    logoUrl: string;
    themeColor: string;
}

const renderIcon = () =>
    !isMobile ? (
        <DeviceIcon />
    ) : (
        <>
            <SafariIcon />
            <ChromeIcon />
        </>
    );

const renderTitle = () =>
    !isMobile ? "Sorry, this is an unsupported device." : "Your browser isn’t supported, sorry!";

const renderDescription = () =>
    !isMobile
        ? "Load this page with your mobile device to order & pay with me&u."
        : "Please launch this page with Safari or Google Chrome.";

export const UnsupportedPage = () => {
    const dispatch = useDispatch();
    const [locationClaimInfo, setLocationClaimInfo] = useState<LocationClaimInfo | null>(null);
    const [qrUrl, setQrUrl] = useState(window.location.href);

    const isOffer = useRef(getIsOfferPath(window.location.pathname));
    useEffect(() => {
        const unsupportedRequirements = Object.keys(browserSupport.requirements)
            .filter((requirement) => browserSupport.requirements[requirement] === false)
            .join(",");

        const isTakeaway = getIsTakeawayPath(window.location.pathname);
        const url = new URL(window.location.href);
        let source = url.searchParams.get("s") ?? "";

        dispatch(
            trackedEvent({
                type: "UNSUPPORTED_CLIENT",
                userAgent: navigator.userAgent,
                unsupportedRequirements,
                isTakeaway,
                isOffer: isOffer.current,
                source,
            })
        );

        if (source) {
            source += ",";
        }
        source += "un";

        url.searchParams.set("s", source);
        setQrUrl(url.href);
    }, [dispatch]);

    useEffect(() => {
        const onHandleUrlSuccess = async (data: UrlLinkData) => {
            const { type, claimUrl } = data;
            if (type === UrlType.TAKEAWAY && !isMobile) {
                const locationClaim: LocationMarkerClaim = {
                    type: "qr",
                    token: claimUrl,
                };
                const result = await getLocationInfo(locationClaim);
                if (result) {
                    setLocationClaimInfo(result);
                }
            }
            splashScreen.hide();
        };
        handleUrlLink(onHandleUrlSuccess);
    }, []);

    const qrSize = Math.min(500, Math.max(160, (window.innerHeight - 106) * 0.4));

    return (
        <div
            className={classNames(
                "unsupported-page",
                locationClaimInfo && !isMobile && "unsupported-page--location-info"
            )}
        >
            <TapOnMenuHeader />
            <div className="unsupported-page__content">
                {!isMobile && (locationClaimInfo || isOffer.current) ? (
                    <section className="unsupported-page--location-info__section">
                        <div className="unsupported-page--location-info__logo">
                            {locationClaimInfo?.logoUrl && <SVG src={locationClaimInfo.logoUrl} />}
                        </div>
                        <QRCodeSVG value={qrUrl} size={qrSize} className="unsupported-page--location-info__qr-code" />
                        <div className="unsupported-page__browser-icons">{renderIcon()}</div>
                        {locationClaimInfo?.locationName && (
                            <Text preset="title-28" mode="extra-bold">
                                Scan on to {locationClaimInfo.locationName}
                            </Text>
                        )}
                        {isOffer.current ? (
                            <Text preset="g-18">Scan the QR code with your phone’s camera to view promotions</Text>
                        ) : (
                            <Text preset="g-12">
                                Scan the QR code with your mobile phone to view{" "}
                                {locationClaimInfo?.locationName
                                    ? `${locationClaimInfo.locationName}’s takeaway menu`
                                    : ""}
                            </Text>
                        )}
                    </section>
                ) : (
                    <section className="unsupported-page__section">
                        <div className="unsupported-page__browser-icons">{renderIcon()}</div>
                        <Text className="title" preset="title-36" mode="extra-bold" value={renderTitle()} />
                        <Text preset="g-14" mode="medium" value={renderDescription()} />
                    </section>
                )}
            </div>
        </div>
    );
};
