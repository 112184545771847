import { createOperation } from "../../operations";
import { AddedCardResult, GetAddedCardFunc } from "../../payment";
import { AuthorizePaymentResult } from "../../../common/payment";

export const showTyroAddCardOperation = createOperation<GetAddedCardFunc>("PAYMENT/SHOW_TYRO_ADD_CARD", {
    showSpinner: true,
});
export const getTyroAddedCardOperation = createOperation<AddedCardResult | null>("PAYMENT/GET_TYRO_ADDED_CARD");
export const getTyroAddedCardForPaymentOperation = createOperation<AddedCardResult | null>(
    "PAYMENT/GET_TYRO_ADDED_CARD_FOR_PAYMENT",
    { showSpinner: true }
);
export const verifyTyroCardOperation = createOperation<void>("PAYMENT/VERIFY_TYRO_CARD");
export const authorizeTyroPayPalPaymentOperation = createOperation<AuthorizePaymentResult | null>(
    "PAYMENT/AUTHORIZE_TYRO_PAYPAL_PAYMENT",
    { showSpinner: true }
);
