import "./Prompt.scss";

import React, { PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";
import { TappableDiv } from "../../common/tappable";
import { Text } from "..";

interface BaseProps {
    type?: "default" | "warning" | "light" | "themed";
    title?: string;
    subtitle?: string;
    titleLeftIcon?: ReactNode;
    titleRightIcon?: ReactNode;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    className?: string;
    onClick?: () => void;
}

export type PromptProps = PropsWithChildren<BaseProps>;

export const Prompt = ({
    type = "default",
    title,
    subtitle,
    titleLeftIcon,
    titleRightIcon,
    leftIcon,
    rightIcon,
    className,
    onClick,
    children,
}: PromptProps) => {
    return (
        <TappableDiv className={classNames("prompt", `prompt--${type}`, className)} onTap={onClick}>
            <div className="prompt__left">
                {leftIcon && <div className="prompt__icon prompt__icon--left">{leftIcon}</div>}
                {(title || subtitle) && (
                    <div>
                        {title && (
                            <div className="prompt__title">
                                {titleLeftIcon && (
                                    <span className="prompt__title__icon prompt__title__icon--left">
                                        {titleLeftIcon}
                                    </span>
                                )}
                                <Text mode={["bold"]} preset="g-16">
                                    {title}
                                </Text>
                                {titleRightIcon && (
                                    <span className="prompt__title__icon prompt__title__icon--right">
                                        {titleRightIcon}
                                    </span>
                                )}
                            </div>
                        )}
                        {subtitle && <Text preset="g-14" value={subtitle} />}
                        {children}
                    </div>
                )}
            </div>
            {rightIcon && <div className="prompt__icon prompt__icon--right">{rightIcon}</div>}
        </TappableDiv>
    );
};
