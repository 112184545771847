import { ITelemetryItem } from "@microsoft/applicationinsights-web";
import { getTopPage } from "src/common/navigation";
import { AppState } from "src/features";
import { getVisibleMenuData } from "src/features/menu/selectors";

export function addOrderItemEnhancer(event: ITelemetryItem, getState: () => AppState) {
    if (event.baseType === "EventData") {
        if (event.baseData?.name === "PARTY/ADD_OR_UPDATE_ORDER_ITEM_BEGIN") {
            const state = getState();
            const pendingItem = state.pendingItem.item;
            const menuData = getVisibleMenuData(state);

            event.data = {
                ...event.data,
                current_page: getTopPage(),
            };
            if (pendingItem && menuData) {
                const categoryId = pendingItem.categoryId;
                const category = menuData.categories[categoryId];
                event.data = {
                    ...event.data,
                    "category.id": categoryId,
                    "category.name": category?.displayName ?? "",
                };
            }
        }
    }
}
