import "./TitleWithAction.scss";

import React, { PropsWithChildren } from "react";
import { TappableSpan } from "../../common/tappable";
import { Text } from "../text";

interface TitleOnlyProps {
    title: string;
}

interface TitleWithActionProps extends TitleOnlyProps {
    onClick: () => void;
    ctaText: string;
}

type Props = TitleOnlyProps | TitleWithActionProps;

export const TitleWithAction = ({ title, ...rest }: Props) => {
    if (!("onClick" in rest)) return <TitleWithActionParent title={title} />;

    const { onClick, ctaText } = rest;

    return (
        <TitleWithActionParent title={title}>
            <TappableSpan onClick={onClick}>
                <Text preset="g-14" mode="bold" className="title-with-action__action">
                    {ctaText}
                </Text>
            </TappableSpan>
        </TitleWithActionParent>
    );
};

const TitleWithActionParent = ({ title, children }: PropsWithChildren<TitleOnlyProps>) => (
    <span className="title-with-action">
        <Text preset="g-18" mode="bold">
            {title}
        </Text>
        {children}
    </span>
);
