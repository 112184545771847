import { AppDispatch, AppState } from "src/features";
import { getProfile } from "src/features/accountmenu/selectors";
import { subscribeToLocationMarketing } from "src/features/marketing/actions/sendOptIn";
import { getMarketingData } from "src/features/marketing/selectors/optInMarketing";
import { getActiveLocationId } from "src/features/order/selectors";
import { signInAction } from "src/features/signup/actions/signInAction";
import { CreatedVerifiedAccountPageDetails } from "src/features/signup/reducers/createVerifiedAccount";

export function marketingOptInAction() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const locationId = getActiveLocationId(state);
        const marketingData = getMarketingData(state);

        const { firstName, lastName, email } = { ...getProfile(state) };
        if (!firstName || !lastName || !email) {
            const pageDetails: CreatedVerifiedAccountPageDetails = {
                accountDetailsTitle: "Add your details",
                subtitle: `To subscribe tell us your details and we’ll pass them on to ${marketingData.newsletterProvider}.`,
                requireEmail: true,
                emailMessage: "Needed to subscribe to newsletter",
            };
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    requireVerification: true,
                    onComplete: () => dispatch(subscribeToLocationMarketing(locationId!, null)),
                    accountDetailsOptions: pageDetails,
                })
            );
        } else {
            dispatch(subscribeToLocationMarketing(locationId!, null));
        }
    };
}
