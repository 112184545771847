import { AppDispatch, AppState } from "src/features";
import { claimOffer } from "../api";
import { claimOfferOperation } from "../operations";
import { actionCreators } from "../reducers";
import { subscribeToGroupMarketing, subscribeToLocationMarketing } from "src/features/marketing/actions/sendOptIn";
import { IdentifierScope } from "../types";
import { SignupSource } from "src/features/marketing";

export function claimOfferAction(
    identifier: string,
    offerId: string,
    subscribeToMarketing: boolean,
    scope: IdentifierScope,
    source?: string
) {
    return claimOfferOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const response = await claimOffer(identifier, offerId, scope, source);
        dispatch(actionCreators.offerClaimResult(response));
        if (subscribeToMarketing) {
            if (scope === IdentifierScope.LOCATION) {
                dispatch(subscribeToLocationMarketing(identifier, SignupSource[SignupSource.OFFERCLAIM]));
            } else {
                dispatch(subscribeToGroupMarketing(identifier, SignupSource[SignupSource.OFFERCLAIM]));
            }
        }
    });
}
