import { FeatureConfiguration } from "..";
import { reducer } from "./reducer";
import { deepLinkMiddleware } from "./middleware/deepLinkMiddleware";
import { complianceMiddleware } from "../compliance/middleware/complianceMiddleware";

export * from "./reducer";

export default function (config: FeatureConfiguration) {
    config.reducers.deepLink = reducer;

    config.middleware.push(deepLinkMiddleware());
    config.middleware.push(complianceMiddleware());
}
