import { OnboardingLoadingImages } from "../types";

export const saveOnboardingLoadingImages = (backgroundImage: string, logoUrl: string) => {
    const onboardingImages: OnboardingLoadingImages = {
        backgroundImage,
        logoUrl,
    };
    localStorage.setItem("LOADER_IMAGES", JSON.stringify(onboardingImages));
};

export const getLoaderImagesFromLocalStorage = (): OnboardingLoadingImages | null => {
    try {
        return JSON.parse(window.localStorage.getItem("LOADER_IMAGES")!) as OnboardingLoadingImages;
    } catch (err) {
        return null;
    }
};

export const deleteOnboardingImages = () => {
    localStorage.removeItem("LOADER_IMAGES");
};
