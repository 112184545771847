import { createSelector } from "reselect";
import { AppState } from "src/features";
import { getLatestOrderReadyTime } from "src/features/order/selectors";

export const getTakeawayDetails = (state: AppState) => state.takeawayOptions.takeawayDetails;
export const getShowSchedulePage = (state: AppState) => state.takeawayOptions.showSchedulePage;
export const getShowTakeawayServiceChanging = (state: AppState) => state.takeawayOptions.showServiceChange;
export const getShouldDefaultToASAP = (state: AppState) => state.takeawayOptions.setDefaultSelectedTimeToASAP;
export const getPreviousVenueLocationsDetails = (state: AppState) => state.takeawayOptions?.previousVenueDetails;

export const getIsTakeawaySchedulingEnabled = createSelector(
    getTakeawayDetails,
    (takeawayOptions) => takeawayOptions?.scheduleEnabled
);

export const getTakeawayScheduleSlotsExists = createSelector(
    getTakeawayDetails,
    (takeawayOptions) => !!takeawayOptions?.serviceScheduleSlots
);

export const getTakeawayScheduleSlots = createSelector(
    getTakeawayDetails,
    getLatestOrderReadyTime,
    (takeawayOptions, latestInMinutes) => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + (latestInMinutes || 0));

        const filteredOptions = takeawayOptions?.serviceScheduleSlots?.map((service) => ({
            ...service,
            scheduleSlots: service.scheduleSlots.filter((slot) => {
                return now < slot.scheduleTime;
            }),
        }));

        return filteredOptions;
    }
);

export const getTakeawayASAPServiceId = createSelector(
    getTakeawayScheduleSlots,
    (slots) => slots?.[0]?.serviceId || null
);
