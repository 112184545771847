import "./StateWizardModal.scss";

import React, { useMemo } from "react";
import { Animate, Text } from "../../../sharedComponents";
import { AlertCircleIcon, CheckIcon } from "../../../sharedComponents/assets/icons";
import { Props as WizardProps, WizardModal, WizardPage } from "./WizardModal";

interface StateTextProps {
    text: string | string[];
}

const StateText = ({ text }: StateTextProps) =>
    Array.isArray(text) ? (
        <>
            {text.map((t) => (
                <Text key={t} preset="g-16" mode={["bold", "block"]} value={t} />
            ))}
        </>
    ) : (
        <Text preset="g-16" mode={["bold", "block"]} value={text} />
    );

const Progress = ({ text }: StateTextProps) => (
    <div className="state-wizard-state state-wizard-state--progress animated-child">
        <Animate name="twoDotSpinnerWhite" />
        <StateText text={text} />
    </div>
);

const Success = ({ text }: StateTextProps) => (
    <div className="state-wizard-state state-wizard-state--success animated-child">
        <CheckIcon />
        <StateText text={text} />
    </div>
);

const Failed = ({ text }: StateTextProps) => (
    <div className="state-wizard-state state-wizard-state--failed animated-child">
        <div className="state-wizard-state__icon-wrapper">
            <AlertCircleIcon />
        </div>
        <StateText text={text} />
        <Animate name="twoDotSpinnerWhite" />
    </div>
);

interface StateWizardPage extends Omit<WizardPage, "render" | "header" | "headerProps">, StateTextProps {
    type: "progress" | "success" | "failure";
}

interface Props<T extends string>
    extends Omit<WizardProps<T>, "pages" | "transition" | "pageTransition" | "customWizardName"> {
    pages: { [page in T]: StateWizardPage };
}

export const StateWizardModal = <T extends string>({ pages: stateWizardPages, ...rest }: Props<T>) => {
    const pages = useMemo(
        () =>
            Object.entries<StateWizardPage>(stateWizardPages).reduce((pages, [page, { text, type, ...rest }]) => {
                pages[page] = {
                    ...rest,
                    header: null,
                    render: (() => {
                        switch (type) {
                            case "success":
                                return () => <Success text={text} />;
                            case "failure":
                                return () => <Failed text={text} />;
                            default:
                                return () => <Progress text={text} />;
                        }
                    })(),
                };
                return pages;
            }, {} as { [page in T]: WizardPage }),
        [stateWizardPages]
    );

    return (
        <WizardModal
            pages={pages}
            transition="fade-in"
            pageTransition="fade-in"
            {...rest}
            customWizardName="StateWizard"
        />
    );
};
