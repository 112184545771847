import { useDispatch, useSelector } from "react-redux";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { EndHandler, ExitHandler } from "react-transition-group/Transition";
import { getNewCardPaymentMethod } from "src/features/payment/selectors";
import { getAddCardBehavior } from "src/features/paymentGateways";
import { PaymentMethods, PaymentMethodsProps } from "src/features/paymentMethods/components/PaymentMethods";
import { Operation } from "src/features/operations/types";
import { actionCreators as paymentActionCreators } from "src/features/payment/reducers";
import { WizardPageUnmountBehaviorProps } from "src/features/wizards/components/WizardModal";

const displayNone: CSSProperties = { display: "none" };

const NullPaymentMethods = (_: PaymentMethodsProps) => null;

export const useWizardPaymentMethods = <T extends string>(
    activePage: T,
    currentPage: T | undefined,
    baseClassName: string,
    paymentOperation: Operation<any>
) => {
    const dispatch = useDispatch();

    const newCardPaymentMethod = useSelector(getNewCardPaymentMethod);
    const paymentStatus = useSelector(paymentOperation.getStatus);

    const [visible, setVisible] = useState(false);
    const [mounted, setMounted] = useState(false);

    const nodeRef = useRef<HTMLElement | undefined>();

    const addCardBehavior = newCardPaymentMethod && getAddCardBehavior(newCardPaymentMethod);
    // This will briefly change when we clear payment methods and we don't
    // want to re-trigger the payment failed side-effect when it gets reset
    const addCardBehaviorRef = useRef(addCardBehavior);
    addCardBehaviorRef.current = addCardBehavior;

    const exitHandler: ExitHandler = useCallback(() => {
        setVisible(false);
        if (!nodeRef.current) return;
        nodeRef.current.className = baseClassName;
        nodeRef.current.scrollTo({ top: 0 });
    }, [baseClassName]);

    const endHandler: EndHandler = useCallback((node) => (nodeRef.current = node), []);

    useEffect(() => {
        if (activePage !== currentPage) return;
        setMounted(true);
        setVisible(true);
    }, [activePage, currentPage]);

    useEffect(() => {
        if (paymentStatus === "failed" && addCardBehaviorRef.current?.disableUnmountPaymentMethods) {
            setMounted(false);
            if (addCardBehaviorRef.current.clearPaymentMethodOnFailure) {
                dispatch(paymentActionCreators.clearPaymentMethods());
            }
        }
    }, [dispatch, paymentStatus]);

    const unmountOnExit = !currentPage || addCardBehavior?.disableUnmountPaymentMethods !== true;

    const unmountBehavior: WizardPageUnmountBehaviorProps = {
        unmountOnExit,
        exitHandler: !unmountOnExit ? exitHandler : undefined,
        endHandler: !unmountOnExit ? endHandler : undefined,
        style: !unmountOnExit && !visible ? displayNone : undefined,
    };

    return {
        component: mounted ? PaymentMethods : NullPaymentMethods,
        unmountBehavior,
    };
};
