import { createSelector } from "reselect";
import { getActiveOrder, getPartyMembers, getPartyMembersExcludingCurrentMember } from "../../order/selectors";
import { OpenTablePartyMember, Order, PartyMember } from "../../order";
import { OpenTableMemberStatus } from "../types";
import { getInitials } from "../../../common/strings";
import { getMemberHasItemInOrders, getOpenTableSubmittingOrders } from "./orders";
import { getIsOpenTable } from "./index";
import { getNavBarStatus } from "src/common/navigation";
import { NavBarType } from "src/common/navigation/types";

export const getDerivedMemberStatus = (
    memberId: string,
    activeOrder: Order | undefined,
    submittingOrders: Order[]
): OpenTableMemberStatus | undefined => {
    if (!activeOrder) return undefined;
    const { acceptedMembers, items } = activeOrder;
    if (acceptedMembers.includes(memberId)) {
        return OpenTableMemberStatus.WAITING;
    } else if (
        items.some((i) => i.memberId === memberId) ||
        // Include submitting orders as "ORDERING" so we don't get
        // a screen jump as the order submission modal fades in
        getMemberHasItemInOrders(submittingOrders, memberId)
    ) {
        return OpenTableMemberStatus.ORDERING;
    } else {
        return OpenTableMemberStatus.BROWSING;
    }
};

const formatMembersWithStatus = (
    members: PartyMember[] = [],
    activeOrder: Order | undefined,
    submittingOrders: Order[]
): OpenTablePartyMember[] =>
    members.map((member) => ({
        ...member,
        derivedMemberStatus: getDerivedMemberStatus(member.memberId, activeOrder, submittingOrders),
        initials: getInitials(member.firstName, member.lastName),
    }));

export const getOtherPartyMembersWithStatus = createSelector(
    getPartyMembersExcludingCurrentMember,
    getActiveOrder,
    getOpenTableSubmittingOrders,
    formatMembersWithStatus
);

export const getAllPartyMembersWithStatus = createSelector(
    getPartyMembers,
    getActiveOrder,
    getOpenTableSubmittingOrders,
    formatMembersWithStatus
);

export const getShowReadyMembersIndicator = createSelector(
    getIsOpenTable,
    getNavBarStatus,
    getOtherPartyMembersWithStatus,
    (isOpenTable, navBarStatus, members) =>
        isOpenTable &&
        navBarStatus === NavBarType.MENU &&
        members.some((m) => m.derivedMemberStatus === OpenTableMemberStatus.WAITING)
);
