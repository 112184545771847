import { AppState } from "../../index";
import { createSelector } from "reselect";

export const getProfile = (state: AppState) => state.profile.result;

export const getHasFetchedProfile = (state: AppState) => state.profile.status === "success";

export const getProfilePhone = createSelector(getProfile, (profile) => profile?.phone);

export const getProfileEmail = createSelector(getProfile, (profile) => profile?.email);

export const getAccountMenuOpen = (state: AppState) => state.accountMenu.open;

export const getFoodPreferences = (state: AppState) => state.foodPreferences;

export const getSelectedFoodPreferences = createSelector(getFoodPreferences, (preferences) => preferences.result);

export const getTestingFeaturesEnabledState = (state: AppState) => state.accountMenu.enableTestingFeatures;
