import { DeviceInfo, SplashScreen } from "./shared";
import {
    ApplePayPaymentMethod,
    DevicePaymentMethod,
    GetAuthorizePaymentResultFunc,
    GooglePayPaymentMethod,
    InitializeDevicePaymentMethodButtonResult,
    PaymentGateway,
    PaymentMethod,
} from "../payment";
import { AnchorHTMLAttributes, ReactNode } from "react";
import { AnyAction } from "redux";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { Identity, SsoProvider } from "../sso";

export * from "./shared";

// Add only Experience, no Business-Logic code

export interface Experience {
    deviceReady: () => Promise<boolean>;
    isWeb: boolean;
    isNative: boolean;
    browserSupport: {
        isSupportedBrowser: boolean;
        requirements: { [requirement: string]: boolean };
    };
    device: DeviceInfo;
    statusBar: {
        setColor: (hex: string) => void;
    };
    prompt: (message: string, title?: string, defaultText?: string) => Promise<string | null>;
    handleUrlLink: (successCB: (res: UrlLinkData) => void) => void;
    asset: (path: string) => string;
    splashScreen: SplashScreen;
    backHandler: {
        register: (popStateHandler: () => void) => void;
        push: (data: PopStateData, replace?: boolean) => void;
        handleBack: (fallback: () => void) => void;
    };
    storeRating: {
        init: () => void;
        trigger: () => void;
    };
    appForegroundChange: (onForeground: () => void, onBackground: () => void) => void;
    registerToKeyboard: (onOpenChanged: (open: boolean) => void) => void;
    href: {
        openInNewWindowAttributes: (href: string) => AnchorHTMLAttributes<HTMLAnchorElement>;
    };
    connectivity: {
        onOffline: (cb: () => void) => void;
        onOnline: (cb: () => void) => void;
        awaitOnline: () => Promise<void>;
    };
    applePay: {
        isAvailable: (
            applePayPaymentMethod: ApplePayPaymentMethod,
            trackEvent: (action: AnyAction) => void
        ) => Promise<boolean>;
        initializeButton: (
            applePayPaymentMethod: ApplePayPaymentMethod,
            total: number,
            actions: InitializeDevicePaymentMethodButtonActions,
            cancelPromise: Promise<void>
        ) => Promise<GetAuthorizePaymentResultFunc | InitializeDevicePaymentMethodButtonResult>;
        vaultPaymentMethod: (
            applePayPaymentMethod: ApplePayPaymentMethod,
            actions: InitializeDevicePaymentMethodButtonActions,
            cancelPromise: Promise<void>
        ) => Promise<string | null>;
        canVaultPaymentMethod: (applePayPaymentMethod: ApplePayPaymentMethod) => boolean;
        completePayment: (applePayPaymentMethod: ApplePayPaymentMethod, token?: string) => Promise<void>;
    };
    googlePay: {
        isAvailable: (
            googlePayPaymentMethod: GooglePayPaymentMethod,
            trackEvent: (action: AnyAction) => void
        ) => Promise<boolean>;
        initializeButton: (
            googlePayPaymentMethod: GooglePayPaymentMethod,
            total: number,
            actions: InitializeDevicePaymentMethodButtonActions,
            cancelPromise: Promise<void>
        ) => Promise<GetAuthorizePaymentResultFunc | InitializeDevicePaymentMethodButtonResult>;
        vaultPaymentMethod: (
            googlePayPaymentMethod: GooglePayPaymentMethod,
            actions: InitializeDevicePaymentMethodButtonActions,
            cancelPromise: Promise<void>
        ) => Promise<string | null>;
        canVaultPaymentMethod: (googlePayPaymentMethod: GooglePayPaymentMethod) => boolean;
        getCustomButtonId: (googlePayPaymentMethod: GooglePayPaymentMethod) => string | null;
    };
    qr: {
        findQRCode: (barcodeType: BarcodeType, onStatusChanged: (status: QrScanStatus) => void) => Promise<string>;
        cancelFindQRCode: () => Promise<void>;
        requiresTargetElement: boolean;
        getCameraAccessDeniedMessage: (barcodeType: BarcodeType) => Promise<CameraAccessDeniedMessage>;
        storeCameraAccessDenied: boolean;
    };
    refreshApp: () => void;
    errorBoundaryMessages: {
        title: ReactNode;
        description: ReactNode;
        reported: ReactNode;
        action: string;
    };
    oneTimePassword: {
        isSupported: boolean;
        otpFormat: string | null;
        request: (signal: AbortSignal) => Promise<string>;
    };
    scrolling: {
        scrollElementIntoView: (element: HTMLElement) => void;
        scrollTop: (elementQuery?: string) => number;
        scrollTo: (options?: ScrollToOptions, elementQuery?: string) => void;
    };
    rotationHandler: {
        register: () => void;
    };
    navigation: {
        preventSwipeBack: () => void;
    };
    webFlow: {
        preStart: (redirectingText?: string) => void;
        start: (startUrl: string, returnUrl: string, messageType: string) => Promise<URL>;
        cancelPreStart: () => void;
    };
    incorrectDocumentHeightHandler: {
        register: () => void;
    };
    scaleHandler: {
        register: () => void;
    };
    browserTranslate: {
        isSupported: boolean;
        isEnabled: () => boolean;
        observeTranslate: (cb: (isEnabled: boolean) => void) => void;
        disconnect: (cb: (isEnabled: boolean) => void) => void;
    };
    tracking: {
        trackFacebookPixelId: (facebookPixelId: string) => void;
    };
    gdpr: {
        canSetCookiePreferences: () => boolean;
        shouldShowCookiePreferences: () => boolean;
        setCookiePreferences: (cookiePreferences: CookiePreferences) => void;
        getCookiePreferences: () => CookiePreferences;
    };
    cardVerification: {
        isSupported: (paymentMethod: PaymentMethod) => boolean;
    };
    sso: SsoBehaviors;
    reCaptcha: {
        enabled: boolean;
        execute: (action: string) => Promise<string | null>;
    };
}

export type PopStateData = {
    name: string;
};

export type UrlLinkData = {
    type: UrlType;
    claimUrl: string;
};

export enum UrlType {
    UNKNOWN = 0,
    NFC = 1,
    ORDER_HISTORY = 2,
    QR = 3,
    JOIN_GROUP_TAB = 4,
    MANAGE_GROUP_TAB = 5,
    GROUP_TAB_HISTORY = 6,
    CLOSE_GROUP_TAB = 7,
    PAY_ONLY = 8,
    ORDER_HISTORY_ITEM = 9,
    TAKEAWAY = 10,
    PAY = 11,
    OFFER = 12,
    JOIN_FLEX = 13,
}

export interface CameraAccessDeniedMessage {
    text: ReactNode;
    primaryAction?: () => void;
    primaryActionText?: string;
}

export type QrScanStatus = "cameraReady" | "waiting" | "error";

export interface CookiePreferences {
    allowAnalytics: boolean;
    allowAdvertising: boolean;
}

export interface SpinnerActions {
    start: () => void;
    stop: () => void;
}

export interface InitializeDevicePaymentMethodButtonActions {
    getPaymentSessionToken: () => Promise<string>;
    updatePaymentAmount: () => Promise<boolean>;
    trackEvent: (action: AnyAction) => void;
    spinnerActions: SpinnerActions;
}

export type GetDevicePaymentMethodAvailableFunc<T extends DevicePaymentMethod> = (
    devicePaymentMethod: T,
    trackEvent: (action: AnyAction) => void
) => Promise<boolean>;

export type InitializeDevicePaymentMethodButtonFunc<T extends DevicePaymentMethod> = (
    devicePaymentMethod: T,
    total: number,
    actions: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => Promise<GetAuthorizePaymentResultFunc | InitializeDevicePaymentMethodButtonResult>;

export type VaultDevicePaymentMethodFunc<T extends DevicePaymentMethod> = (
    devicePaymentMethod: T,
    actions: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => Promise<string | null>;

export type CompleteDevicePaymentMethodPaymentFunc<T extends DevicePaymentMethod> = (
    devicePaymentMethod: T,
    token?: string
) => Promise<void>;

export type DevicePaymentBehavior<T extends DevicePaymentMethod> = {
    getAvailable: GetDevicePaymentMethodAvailableFunc<T>;
    initializeButton: InitializeDevicePaymentMethodButtonFunc<T>;
    customButtonId?: string;
    vaultPaymentMethod?: VaultDevicePaymentMethodFunc<T>;
    completePayment?: CompleteDevicePaymentMethodPaymentFunc<T>;
};

export interface PaymentGatewayBehavior {
    getApplePayBehavior?: (paymentMethod?: PaymentMethod) => DevicePaymentBehavior<ApplePayPaymentMethod> | undefined;
    getGooglePayBehavior?: (paymentMethod?: PaymentMethod) => DevicePaymentBehavior<GooglePayPaymentMethod> | undefined;
    getCardVerificationSupported?: (paymentMethod?: PaymentMethod) => boolean | undefined;
}

export type PaymentGatewayBehaviors = Record<PaymentGateway, PaymentGatewayBehavior>;

export interface SsoBehavior {
    initialize?: () => Promise<void>;
    renderButton?: (buttonElement: HTMLDivElement) => void;
    signIn: () => Promise<Identity | null>;
    isAvailable: () => boolean;
}

export type SsoBehaviors = Record<SsoProvider, SsoBehavior>;
