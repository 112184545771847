import React from "react";
import { Text } from "src/sharedComponents/controls/text/Text";
import { MembershipIcon } from "src/sharedComponents/assets/icons/Icons";
import "./MembershipCallToAction.scss";
import { useSelector } from "react-redux";
import { Price } from "../../menu/components/Price";
import { getMemberMembershipDiscountAmount } from "src/features/payment/selectors";
import { getShowMembershipWillApply } from "../selectors/getActiveMembershipState";

export const MembershipSaving = () => {
    const membershipSaving = useSelector(getMemberMembershipDiscountAmount);
    const membershipWillApplyName = useSelector(getShowMembershipWillApply);

    if (membershipSaving <= 0 && !membershipWillApplyName) {
        return null;
    }

    return (
        <div className="membership-cta membership-cta--inverse">
            <div className="membership-cta__icon">
                <MembershipIcon />
            </div>
            <div className="membership-cta__text">
                {!!membershipWillApplyName ? (
                    <Text preset="g-14" mode="block">
                        Any {membershipWillApplyName} deals will be applied when confirming your order.
                    </Text>
                ) : (
                    <Text preset="g-14" mode="block">
                        Woo! You saved&nbsp;
                        <Price price={membershipSaving} priceTextPreset="g-14" priceTextMode="bold" />
                        &nbsp;with this order using your member discount!
                    </Text>
                )}
            </div>
        </div>
    );
};
