import { PaymentMethod as PaymentMethodType } from "../../../common/payment";
import { createContext, useContext } from "react";

type SelectPaymentMethodContextProps = [PaymentMethodType | null, (paymentMethod: PaymentMethodType) => void];

const SelectPaymentMethodContext = createContext<SelectPaymentMethodContextProps>([null, () => {}]);
const { Provider } = SelectPaymentMethodContext;

export { Provider as SelectPaymentMethodProvider };

export const usePaymentMethodSelectionContext = () => useContext(SelectPaymentMethodContext);
