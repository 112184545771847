import { AddedCardResult, PaymentMethodInfo } from "../../payment";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { orderApi } from "../../order/orderApi";

interface ApiError {
    code: string;
}

export class CardRejectedError extends Error {
    constructor() {
        super("One or more of the details below are incorrect. Please verify and try again.");
        // TODO: Look at setting an ES6 target so we can remove this
        Object.setPrototypeOf(this, CardRejectedError.prototype);
    }
}

export async function addPaymentMethod(addedCardResult: AddedCardResult) {
    const {
        paymentMethod: { token, paymentGateway },
        additionalFraudProtectionData,
    } = addedCardResult;

    const paymentMethodInfo: PaymentMethodInfo = {
        paymentToken: token,
        additionalFraudProtectionData,
    };

    const headers = getTableTokenHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(`/paymentgateway/${paymentGateway}/vaultedpaymentmethods`, {
        method: "POST",
        headers,
        body: JSON.stringify(paymentMethodInfo),
    });

    if (!response.ok) {
        if (response.status === 400) {
            const error: ApiError = await response.json();

            if (error && error.code === "rejected") {
                throw new CardRejectedError();
            }
        }

        throw response;
    }
}
