import React, { useEffect, useState } from "react";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfUse } from "./TermsOfUse";
import "../assets/LegalModal.scss";
import { CookiePolicy } from "./CookiePolicy";
import { ModalContainer } from "src/common/modal";

interface Props {
    legalPage?: LegalPageType;
    goBack: () => void;
}

export type LegalPageType = "privacyPolicy" | "termsOfUse" | "cookiePolicy";

export const LegalModal = ({ legalPage, goBack }: Props) => {
    const [isOpen, setIsOpen] = useState(!!legalPage);

    useEffect(() => setIsOpen(!!legalPage), [legalPage]);

    const handleBack = () => setIsOpen(false);

    return (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "privacy-modal",
                afterOpen: "privacy-modal--after-open",
                beforeClose: "privacy-modal--before-close",
            }}
            overlayClassName="privacy-modal--overlay"
            onAfterClose={goBack}
            shouldCloseOnEsc
            closeTimeoutMS={360}
        >
            {legalPage === "privacyPolicy" && <PrivacyPolicy goBack={handleBack} />}
            {legalPage === "termsOfUse" && <TermsOfUse goBack={handleBack} />}
            {legalPage === "cookiePolicy" && <CookiePolicy goBack={handleBack} />}
        </ModalContainer>
    );
};
