import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHostedFieldsValidity } from "../selectors/hostedFields";
import { GetCvvTokenFunc } from "../../payment";
import { CardPaymentMethod, SelectablePaymentMethod } from "../../../common/payment";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { AddCardBehavior, ShowEnterCvvFunc } from "../../paymentGateways/types";
import { HostedField, SelectPaymentMethodActions } from "../types";
import { AddCardFormPaymentMethodAction } from "./AddCardFormPaymentMethodAction";
import { hostedFieldsActionCreators } from "../reducers";

interface Props {
    paymentMethod: CardPaymentMethod;
    actions: SelectPaymentMethodActions;
}

export const SelectCardPaymentMethodAction = ({ paymentMethod, actions }: Props) => {
    const dispatch = useDispatch();

    const { cvvValid } = useSelector(getHostedFieldsValidity);

    const getRequiredBehavior = useCallback((addCardBehavior: AddCardBehavior) => {
        return addCardBehavior.showEnterCvv;
    }, []);

    const getResultFunc = useCallback(
        (showEnterCvv: ShowEnterCvvFunc) => {
            dispatch(hostedFieldsActionCreators.setValid(HostedField.CVV, false));
            return showEnterCvv(dispatch, paymentMethod);
        },
        [dispatch, paymentMethod]
    );

    const getPaymentMethod = useCallback(
        (getCvvToken: GetCvvTokenFunc) =>
            new Promise<SelectablePaymentMethod | null>(async (resolve) => {
                const cvvToken = await getCvvToken();

                if (!cvvToken) {
                    dispatch(showModalMessage(modalMessages.getCvvTokenFailed()));
                    return resolve(null);
                }

                paymentMethod.cvv = cvvToken;
                resolve(paymentMethod);
            }),
        [dispatch, paymentMethod]
    );

    return (
        <AddCardFormPaymentMethodAction
            paymentMethod={paymentMethod}
            getRequiredBehavior={getRequiredBehavior}
            getResultFunc={getResultFunc}
            getPaymentMethod={getPaymentMethod}
            isValid={cvvValid}
            actions={actions}
            select={true}
        />
    );
};
