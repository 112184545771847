import { FeatureConfiguration } from "..";
import { reducer as paymentStateReducer } from "./reducers/payment";
import { reducer as paymentFlowStateReducer } from "./reducers/paymentFlow";
import { RETURN_URL } from "../paymentGateways/tyro/initializeTyroPayPalButton";
import { KnownPaymentErrors, mustUseExtendedValidationErrorHandler, reviewOrderErrorHandler } from "./errors";

export * from "./types";
export * from "./reducers";

export { actionCreators } from "./reducers/payment";
export { actionCreators as paymentFlowActions } from "./reducers/paymentFlow";

export enum MessageTypeKeys {
    VERIFY_CARD_COMPLETE = "PAYMENT/VERIFY_CARD_COMPLETE",
    BROWSER_PAYMENT_COMPLETE = "PAYMENT/BROWSER_PAYMENT_COMPLETE",
}

export default function (config: FeatureConfiguration) {
    config.reducers.payment = paymentStateReducer;
    config.reducers.paymentFlow = paymentFlowStateReducer;

    config.callbackUrls.push({
        url: RETURN_URL,
        context: "window",
        messageType: MessageTypeKeys.BROWSER_PAYMENT_COMPLETE,
    });

    config.errorHandlers.push({
        code: KnownPaymentErrors.MustUseExtendedValidation,
        handler: mustUseExtendedValidationErrorHandler,
    });

    config.errorHandlers.push({
        code: KnownPaymentErrors.ReviewOrder,
        handler: reviewOrderErrorHandler,
    });
}
