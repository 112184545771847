export type ReattemptFunc = (err: any) => Promise<any>;

export async function attempt<T>(
    fn: () => Promise<T>,
    wait?: ReattemptFunc,
    cancel?: Promise<any>,
    onAttemptFailed?: (count: number) => void
) {
    let cancelled = false;
    if (cancel) {
        cancel.then(
            () => (cancelled = true),
            () => (cancelled = true)
        );
    }

    let count = 0;

    while (true) {
        if (cancelled) {
            return await cancel;
        }

        try {
            return await fn();
        } catch (e) {
            onAttemptFailed && onAttemptFailed(++count);

            if (wait) {
                await wait(e);
            }
        }
    }
}
