import "./IconWithBackground.scss";

import React, { ReactNode } from "react";
import classNames from "classnames";

interface Props {
    icon: ReactNode;
    className?: string;
}

export const IconWithBackground = ({ icon, className }: Props) => (
    <div className={classNames("icon-with-background", className)}>{icon}</div>
);
