import "./CollapsibleSection.scss";

import classNames from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useHasMounted } from "../../common/shared";

export interface CollapsibleSectionProps {
    show: boolean | number;
    collapsibleElement: ReactNode;
    duration?: number;
    easing?: string;
    animateOnMount?: boolean;
}

const getHeight = (el: Element | null) => {
    if (!el) return 0;
    const { height } = el.getBoundingClientRect();
    const { marginTop, marginBottom } = window.getComputedStyle(el);
    return height + parseInt(marginTop) + parseInt(marginBottom);
};

export const CollapsibleSection = ({
    show,
    collapsibleElement,
    duration = 250,
    easing = "ease-in-out",
    animateOnMount = true,
}: CollapsibleSectionProps) => {
    const collapsibleRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<Animation>();
    const heightRef = useRef(0);

    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const hasMounted = useHasMounted();

    useEffect(() => {
        const onAnimationComplete = () => {
            setIsAnimating(false);
            if (!show) {
                setIsOpen(false);
            }
        };

        setIsOpen((prevIsOpen) => {
            if ((show || (!show && prevIsOpen)) && collapsibleRef.current?.animate) {
                const newHeight = !show ? 0 : getHeight(collapsibleRef.current.firstElementChild as HTMLElement);
                if (newHeight !== heightRef.current) {
                    animationRef.current = collapsibleRef.current.animate(
                        [
                            {
                                height: `${heightRef.current}px`,
                            },
                            {
                                height: `${newHeight}px`,
                            },
                        ],
                        {
                            direction: "normal",
                            easing,
                            duration: !hasMounted && !animateOnMount ? 0 : duration,
                            fill: "forwards",
                        }
                    );
                    heightRef.current = newHeight;
                    animationRef.current.addEventListener("finish", onAnimationComplete);
                    animationRef.current.addEventListener("cancel", onAnimationComplete);
                    setIsAnimating(true);
                }
                return true;
            }
            return prevIsOpen;
        });

        return () => {
            animationRef.current?.removeEventListener("finish", onAnimationComplete);
            animationRef.current?.removeEventListener("cancel", onAnimationComplete);
        };
    }, [show, duration, easing, hasMounted, animateOnMount]);

    if (!show && !isOpen) return null;

    return (
        <div
            ref={collapsibleRef}
            className={classNames("collapsible-section", isOpen && "open", isAnimating && "animating")}
        >
            {collapsibleElement}
        </div>
    );
};
