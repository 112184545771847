import * as React from "react";
import { FC } from "react";
import { OrderCourseItem } from "../reducers/types";
import { SwipeableOrderItem } from "./SwipeableOrderItem";
import { OrderItem } from "./OrderItem";

export interface Props {
    items: OrderCourseItem[];
    hidePrice?: boolean;
    editable?: boolean;
    selectable?: boolean;
    removeItem?: (orderItemId: string, menuItemId: string) => void;
    onDeleted?: () => void;
    onSelectItem?: (referenceId: string, quantity: number) => void;
}

export const OrderItems: FC<Props> = ({
    items,
    hidePrice,
    editable,
    selectable,
    removeItem,
    onDeleted,
    onSelectItem,
}) => {
    return (
        <>
            {items.map((item, index) =>
                editable ? (
                    <SwipeableOrderItem
                        key={item.orderItemId || `order-item-${index}`}
                        item={item}
                        hidePrice={hidePrice}
                        removeItem={removeItem}
                        onDeleted={onDeleted}
                    />
                ) : (
                    <OrderItem
                        key={item.orderItemId || `order-item-${index}`}
                        item={item}
                        hidePrice={hidePrice}
                        selectable={selectable}
                        onSelectItem={onSelectItem}
                    />
                )
            )}
        </>
    );
};
