import { EasyReorderItem } from "../../order";

export type EasyReorderItems = EasyReorderItem[];

export interface State {
    easyReorderItems: EasyReorderItems;
}

enum TypeKeys {
    GET_EASY_REORDER_ITEMS_COMPLETE = "REORDERITEM/GET_EASY_REORDER_ITEMS_COMPLETE",
    RESET_EASY_REORDER_ITEMS = "REORDERITEM/RESET_EASY_REORDER_ITEMS",
}

export const actionCreators = {
    getEasyReorderItem: (results: EasyReorderItems) => ({ type: TypeKeys.GET_EASY_REORDER_ITEMS_COMPLETE, results }),
    resetEasyReorderItems: () => ({ type: TypeKeys.RESET_EASY_REORDER_ITEMS }),
};

export type GetEasyReorderItem = {
    type: TypeKeys.GET_EASY_REORDER_ITEMS_COMPLETE;
    results: EasyReorderItems;
};

export type ResetEasyReorderItem = {
    type: TypeKeys.RESET_EASY_REORDER_ITEMS;
};

export type EasyReorderActions = GetEasyReorderItem | ResetEasyReorderItem;

const initialState: EasyReorderItems = [];

export default function reducer(state: EasyReorderItems = initialState, action: EasyReorderActions): EasyReorderItems {
    switch (action.type) {
        case TypeKeys.GET_EASY_REORDER_ITEMS_COMPLETE:
            return action.results;
        case TypeKeys.RESET_EASY_REORDER_ITEMS:
            return [];
        default:
            return state;
    }
}
