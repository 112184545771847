import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth/auth";
import { sendSurveyOperation } from "../operations";
import { AppDispatch, AppState } from "../..";
import { Rating } from "../../../sharedComponents";
import { actionCreators as surveyActionCreators } from "../reducers/survey";
import { actionCreators as ratingActionCreators } from "../reducers/rating";
import { SurveyRatingRequest } from "../reducers/types";
import { getAnyParty } from "src/features/order/selectors";
import { getSurvey, getSurveyDetails } from "../selectors/survey";
import { getRating } from "../selectors/rating";

export function sendSurvey() {
    return sendSurveyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const headers = await getAuthHeaders();
        headers.append("Content-Type", "application/json");

        const state = getState();
        const party = getAnyParty(state);
        const rating = getRating(state);
        const { tags, positiveNote, negativeNote, email } = getSurvey(state);
        const surveyDetails = getSurveyDetails(state);

        if (!party || !surveyDetails || rating === undefined) {
            dispatch(surveyActionCreators.resetSurvey());
            dispatch(ratingActionCreators.resetRating());
            return;
        }

        const isNegative = rating === Rating.NEGATIVE;

        const ratingRequest: SurveyRatingRequest = {
            locationId: party.restaurantId,
            partyId: party.id,
            title: surveyDetails.title,
            rating: rating,
            tags: isNegative ? tags.join() : undefined,
            notes: isNegative ? negativeNote : positiveNote,
            email: isNegative ? email : undefined,
        };

        const response = await orderApi.send("/feedback/submitsurvey", {
            method: "POST",
            body: JSON.stringify(ratingRequest),
            headers,
        });

        if (!response.ok) {
            throw response;
        } else {
            dispatch(surveyActionCreators.resetSurvey());
            dispatch(ratingActionCreators.resetRating());
        }
    });
}
