import classNames from "classnames";
import React, { useCallback } from "react";
import { Controller, FieldValues, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { trimInput } from "src/common/shared";
import { Button, Input, Text, FormControl } from "src/sharedComponents";
import { actionCreators } from "../reducers/groupTabWizard";
import { getWizardGroupTabsMenuType, getWizardGroupTabsName } from "../selectors/groupTabWizardSelectors";
import { GroupTabMenuType } from "./GroupTabMenuType";
import { validationRules } from "../../../common/validation";
import { TabDisclaimer } from "./TabDisclaimer";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { getTabTypeName } from "../selectors/activeGroupTab";
import "./GroupTabName.scss";
import { TAB_NAME_MAX_LENGTH } from "../types";
import { actionCreators as partyActions } from "src/features/order/reducers/party";

interface Props {
    onSuccess: () => void;
}

export const GroupTabName = ({ onSuccess }: Props) => {
    const tabName = useSelector(getWizardGroupTabsName);
    const menuType = useSelector(getWizardGroupTabsMenuType);
    const tabTypeName = useSelector(getTabTypeName);

    const form = useForm({
        mode: "onChange",
        defaultValues: {
            tabName,
            menuType: menuType || "",
        },
    });
    const { register, handleSubmit, formState, errors, setValue, control } = form;

    const dispatch = useDispatch();
    const onClick = useCallback(
        (fields: FieldValues) => {
            dispatch(actionCreators.setGroupTabName(fields.tabName));
            onSuccess();
        },
        [dispatch, onSuccess]
    );

    const onInvalid = useCallback(() => {
        dispatch(partyActions.trackDisabledButtonClicked("start_group_tab"));
    }, [dispatch]);

    return (
        <FormProvider {...form}>
            <form onSubmit={(event) => event.preventDefault()} className="group-tab-name">
                <main className={classNames("scroll-element", "animated-child")}>
                    <div className="group-tab-wizard__title profile-page__title">
                        <Text preset="title-28" mode="bold">
                            Start a {tabTypeName}
                        </Text>
                        <Text preset="g-14" mode="block" className="group-tab-wizard__description">
                            Decide what menu to make available on your {tabTypeName}.
                        </Text>
                    </div>
                    <FormControl
                        id="group-tab-name"
                        invalid={!!errors.tabName}
                        invalidMessage={errors.tabName?.message}
                    >
                        <Input
                            ref={register({
                                required: {
                                    value: true,
                                    message: "Required",
                                },
                                maxLength: {
                                    value: TAB_NAME_MAX_LENGTH,
                                    message: `Maximum ${TAB_NAME_MAX_LENGTH} characters`,
                                },
                            })}
                            name="tabName"
                            label={`${capitaliseFirstLetter(tabTypeName)} name`}
                            placeholder="Friday Drinks"
                            onClear={() => {
                                setValue("tabName", undefined, { shouldValidate: true });
                            }}
                            onBlur={(e) => e?.target && trimInput(e.target)}
                        />
                    </FormControl>
                    <Controller
                        control={control}
                        name="menuType"
                        rules={validationRules.requiredOption}
                        render={({ onChange, value }) => <GroupTabMenuType onChange={onChange} menuType={value} />}
                    />
                    <TabDisclaimer className="group-tab-name__disclaimer" />
                </main>
                <footer className="top-shadow">
                    <Button
                        type="submit"
                        value="Continue"
                        className={!formState.isValid ? "disabled" : ""}
                        onClick={handleSubmit(onClick, onInvalid)}
                    />
                </footer>
            </form>
        </FormProvider>
    );
};
