import { AppDispatch, AppState } from "../../index";
import { getParty, PartyType } from "src/features/order";
import { replace } from "connected-react-router";

export function redirectToParty() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const party = getParty(getState());

        switch (party?.type) {
            case PartyType.PAYONLY:
                return dispatch(replace("/pay-only"));
            default:
                return dispatch(replace("/menu/service"));
        }
    };
}
