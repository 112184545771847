import "../assets/GooglePayButton.scss";

import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompletePaymentFunc, PaymentButtonProps } from "../types";
import { initializeGooglePayButton } from "src/features/payment/actions/initializeGooglePayButton";
import { getPaymentTotal, getSelectedPaymentMethod } from "src/features/payment/selectors";
import { GooglePayPaymentMethod, UpdateTotalFunc } from "src/common/payment";
import { googlePay } from "src/common/experience";
import classNames from "classnames";
import { useUnmountCancelPromise } from "src/sharedComponents/common/shared";

export interface GooglePayButtonProps extends PaymentButtonProps {}

export const GooglePayButton = ({ onPayment }: GooglePayButtonProps) => {
    const dispatch = useDispatch();

    const googlePayPaymentMethod = useSelector(getSelectedPaymentMethod) as GooglePayPaymentMethod;
    const total = useSelector(getPaymentTotal);

    const buttonRef = useRef<HTMLDivElement>(null);
    const updateTotalRef = useRef<UpdateTotalFunc>();
    const onPaymentInternal = useRef(onPayment);

    const cancelPromise = useUnmountCancelPromise();

    const customButtonId = googlePay.getCustomButtonId(googlePayPaymentMethod);

    useEffect(() => {
        onPaymentInternal.current = onPayment;
    }, [onPayment]);

    useEffect(() => {
        updateTotalRef.current?.(total);
    }, [total]);

    useEffect(() => {
        const completePayment: CompletePaymentFunc = (result) => {
            if (!result) return;
            dispatch(onPaymentInternal.current(result));
        };

        const setUpdateTotal = customButtonId
            ? (updateTotal: UpdateTotalFunc) => {
                  updateTotalRef.current = updateTotal;
              }
            : null;

        dispatch(initializeGooglePayButton(buttonRef.current!, completePayment, setUpdateTotal, cancelPromise));
    }, [dispatch, cancelPromise, customButtonId]);

    return (
        <div className={classNames("google-pay-button", customButtonId && "google-pay-button--custom")} ref={buttonRef}>
            {customButtonId && <div id={customButtonId} />}
        </div>
    );
};
