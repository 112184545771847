import { AppDispatch, AppMiddleware } from "../..";
import { PartyAction, TypeKeys as PartyTypeKeys } from "src/features/order/reducers/party";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { OperationAction, OperationsTypeKeys } from "../../operations";
import { getABTestsOperation } from "../operations";
import { getActiveABTests } from "../selectors";
import { TypeKeys } from "../";

export const abTestsMiddleware =
    () => (store: AppMiddleware) => (next: AppDispatch) => (action: PartyAction | OperationAction) => {
        next(action);

        if (
            action.type === PartyTypeKeys.SWITCHED ||
            (action.type === OperationsTypeKeys.COMPLETED && action.operation === getABTestsOperation.name)
        ) {
            const abTests = getActiveABTests(store.getState());
            if (abTests) {
                store.dispatch(
                    trackedEvent({
                        type: TypeKeys.AB_TESTS_ACTIVE,
                        ab_tests: abTests.join(),
                    })
                );
            }
        }
    };
