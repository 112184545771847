import * as React from "react";
import { TappableLink } from "src/sharedComponents/common/tappable";
import { Category, Menu } from "../../menudata";
import "../assets/MenuCard.scss";
import { CategoryCard } from "./CategoryCard";
import { Text } from "src/sharedComponents";

export interface Props {
    menuId: string;
    menu: Menu;
    categories: Category[];
    onBeforeNavigate?: () => void;
    hideProductImages?: boolean;
}

export const MenuCard = ({ menuId, menu, categories, onBeforeNavigate, hideProductImages }: Props) => (
    <div className="menu-card">
        <Text preset="title-28" mode="extra-bold" className="menu-card__title">
            <span>{menu.displayName}</span>
        </Text>
        <ol className="menu-card__list">
            {menu.categories.map((id, index) => (
                <li key={index} className="menu-card__item">
                    <TappableLink to={`/menu/${menuId}/${id}`} onBeforeNavigate={onBeforeNavigate}>
                        <CategoryCard category={categories[index]} hideProductImages={hideProductImages} />
                    </TappableLink>
                </li>
            ))}
        </ol>
    </div>
);
