import { PayPalCheckoutPaymentMethod } from "./types";
import { loadExternalScript, unloadExternalScript } from "../shared";

const PAYPAL_SDK_BASE_URL = "https://www.paypal.com/sdk/js";

export const getPayPalClient = async ({ currency, payPalClientId }: PayPalCheckoutPaymentMethod) => {
    unloadAltCurrencyPayPal(currency);

    const queryString = new URLSearchParams({
        "client-id": payPalClientId,
        currency: currency,
        intent: "capture",
        "disable-funding": "card",
    });

    return await loadExternalScript("paypal_sdk", `${PAYPAL_SDK_BASE_URL}?${queryString}`, {
        "data-namespace": "paypal_sdk",
    });
};

function internalDestroyPayPal(payPalSdk: any) {
    if (typeof payPalSdk.__internal_destroy__ === "function") {
        payPalSdk.__internal_destroy__();
    }
}

// Unload the PayPal SDK if the loaded currency doesn't match
// This isn't actually supported, but since it shouldn't occur
// outside of testing it should be sufficient.
function unloadAltCurrencyPayPal(currency: string) {
    unloadExternalScript(
        "paypal_sdk",
        PAYPAL_SDK_BASE_URL,
        (url) => url.indexOf(`currency=${currency}`) === -1,
        internalDestroyPayPal
    );
}
