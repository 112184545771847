import { AppDispatch, AppState } from "src/features";
import { Props } from "../components/AcceptTermsUpdateModal";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { actions } from "../actions";
import { connect } from "react-redux";
import { AcceptTermsUpdateModal } from "../components/AcceptTermsUpdateModal";
import { actionCreators } from "src/features/accounts/reducers/authState";

export function mapStateToProps(state: AppState): DataProps<Props> {
    const {
        marketing: { termsUpdate },
    } = state;

    return {
        termsUpdate,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        accept: () => dispatch(actions.acceptTermsUpdate()),
        onPrivacyPolicyClicked: () => dispatch(actionCreators.onPrivacyPolicyClicked(false)),
    };
}

export const AcceptTermsUpdateContainer = connect(mapStateToProps, mapDispatchToProps)(AcceptTermsUpdateModal);
