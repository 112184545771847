import "../assets/OfferClaimedPage.scss";
import React, { useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getClaimOfferResult, getOfferData } from "../selectors";
import { Button } from "src/sharedComponents";
import { offerClaimFlowWizard } from "../wizard";
import { SimpleNavHeader } from "src/common/navigation";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { href } from "src/common/experience";
import { ClaimedFailedReason } from "../types";
import { OfferClaimedPageContent } from "./OfferClaimedPageContent";
import { trackedEvent } from "src/common/events/reduxEventTracking";

interface Props {
    onClose: () => void;
}

export const OfferClaimedPage = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const offer = useSelector(getOfferData);
    const claimOfferResult = useSelector(getClaimOfferResult);

    const claimedFailedReason = claimOfferResult?.claimedFailedReason;
    useEffect(() => {
        if (claimedFailedReason === ClaimedFailedReason.ALREADY_EXISTS) {
            dispatch(offerClaimFlowWizard.actionCreators.prev("OfferPage"));
        }
    }, [dispatch, claimedFailedReason]);

    return (
        <div className="offer-claimed-page">
            <div className={classNames("scroll-element", "animated-child")}>
                <SimpleNavHeader backgroundTransparent closeToBack onBack={onClose} />
                <OfferClaimedPageContent>
                    {claimOfferResult &&
                        offer?.offerDetails?.claimedActionCopy &&
                        offer?.offerDetails.claimedActionUrl && (
                            <TappableAnchor
                                {...href.openInNewWindowAttributes(offer.offerDetails.claimedActionUrl)}
                                className="offer-claimed-page__button"
                            >
                                <Button
                                    value={offer.offerDetails.claimedActionCopy}
                                    onClick={() => {
                                        dispatch(
                                            trackedEvent({
                                                type: "OFFER/CLAIMED_CTA_CLICKED",
                                                cta_copy: offer?.offerDetails?.claimedActionCopy,
                                                cta_link: offer?.offerDetails?.claimedActionUrl,
                                            })
                                        );
                                        dispatch(offerClaimFlowWizard.actionCreators.prev("OfferPage"));
                                    }}
                                    mode="outline"
                                    unthemed
                                />
                            </TappableAnchor>
                        )}
                </OfferClaimedPageContent>
            </div>
        </div>
    );
};
