import {
    Category,
    Indexed,
    Menu,
    MenuItem,
    MenuItemModifier,
    MenuItemVariant,
    ModifierChoice,
    ModifierOption,
    Service,
} from "../menudata";
import { evaluateMinSelection } from "../menudata/helpers/availabilityHelpers";
import { LimitedMenuPackage } from "../groupTabs";

export function reduceItemsByPackage(items: Indexed<MenuItem>, packageData: LimitedMenuPackage | null | undefined) {
    if (!packageData?.categories) return items;

    return Object.keys(packageData?.categories).reduce((reducedItems, categoryId) => {
        const packageVariantIndexes = packageData?.categories[categoryId].variants;

        packageData.categories[categoryId].menuItems.forEach((itemId, index) => {
            reducedItems[itemId] = {
                ...items[itemId],
                variants: reduceVariantsByPackageProductVariants(
                    items[itemId].variants,
                    packageVariantIndexes?.[index]
                ),
            };
        });

        return reducedItems;
    }, {} as Indexed<MenuItem>);
}

export function reduceVariantsByPackageProductVariants(
    variants: MenuItemVariant[] | undefined,
    packageProductVariants: number[] | undefined
) {
    if (!packageProductVariants) return variants;

    return variants?.reduce((previousValue, currentValue, currentIndex) => {
        if (packageProductVariants.length === 0 || packageProductVariants.includes(currentIndex)) {
            previousValue.push(
                currentValue.originalIndex === undefined
                    ? { ...currentValue, originalIndex: currentIndex }
                    : currentValue
            );
        }

        return previousValue;
    }, [] as MenuItemVariant[]);
}

export function reduceCategories(
    menuDataCategories: Indexed<Category>,
    items: Indexed<MenuItem>,
    activeCategoryIds: string[]
) {
    return activeCategoryIds.reduce((map, id) => {
        const menuItemsSplit = menuDataCategories[id]?.menuItems.reduce(
            (categoryItems, itemId) => {
                const toAdd = items[itemId];
                if (toAdd) {
                    if (toAdd.available !== false) {
                        categoryItems.availables.push(itemId);
                    } else {
                        categoryItems.unavailables.push(itemId);
                    }
                }
                return categoryItems;
            },
            { availables: [], unavailables: [] } as { availables: string[]; unavailables: string[] }
        );

        const menuItems = menuItemsSplit?.availables.concat(menuItemsSplit.unavailables);

        if (!!menuItems && menuItems.length) {
            map[id] = {
                ...menuDataCategories[id],
                menuItems,
            };
        }
        return map;
    }, {} as Indexed<Category>);
}

export function reduceMenus(menus: Indexed<Menu>, categories: Indexed<Category>) {
    return Object.keys(menus).reduce((map, id) => {
        map[id] = {
            ...menus[id],
            categories: menus[id].categories.filter((cat) => categories[cat]),
        };
        return map;
    }, {} as Indexed<Menu>);
}

export function reduceServices(services: Service[], menus: Indexed<Menu>, categories: Indexed<Category>) {
    return services.map((service) => ({
        ...service,
        categories: service.categories.filter((cat) => categories[cat]),
        menus: service.menus.filter((menu) => !!menus[menu].categories.length),
    }));
}

export const embellishItemVariantsAndModifierOptions = (items: Indexed<MenuItem>) =>
    Object.entries(items).reduce(
        (embellishedItems, [itemId, currItem]) => ({
            ...embellishedItems,
            [itemId]: {
                ...currItem,
                modifiers: !currItem.modifiers
                    ? currItem.modifiers
                    : [...currItem.modifiers].map((modifier) => ({
                          ...modifier,
                          options: setIndexAndSortByAvailability(modifier.options) as ModifierOption[],
                          minSelection: evaluateMinSelection(modifier),
                      })),
                variants: !currItem.variants
                    ? currItem.variants
                    : (setIndexAndSortByAvailability([...currItem.variants]) as MenuItemVariant[]),
            },
        }),
        {} as Indexed<MenuItem>
    );

export const embellishGlobalModifierOptions = (modifiers?: Indexed<MenuItemModifier>) =>
    !modifiers
        ? modifiers
        : Object.entries(modifiers).reduce(
              (embellishedModifiers, [modifierId, currModifier]) => ({
                  ...embellishedModifiers,
                  [modifierId]: {
                      ...currModifier,
                      options: setIndexAndSortByAvailability(currModifier.options) as ModifierOption[],
                      minSelection: evaluateMinSelection(currModifier),
                  },
              }),
              {} as Indexed<MenuItemModifier>
          );

const setIndexAndSortByAvailability = (items: ModifierChoice[]) => {
    const { available, unavailable } = items.reduce(
        (availability, item, index) => {
            const indexedItem = item.originalIndex === undefined ? { ...item, originalIndex: index } : item;
            if (item.available !== false) {
                availability.available.push(indexedItem);
            } else {
                availability.unavailable.push(indexedItem);
            }

            return availability;
        },
        { available: [], unavailable: [] } as { available: ModifierChoice[]; unavailable: ModifierChoice[] }
    );

    return [...available, ...unavailable];
};

export const getSelectedModifierChoice = <T extends ModifierChoice>(
    items: T[] | undefined,
    selectedItem: number | null
) => items?.find((i) => i.originalIndex === selectedItem);
