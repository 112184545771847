import { PosPaymentGatewayBehaviors, PosType } from "./types";
import { addCardBehavior as toastAddCardBehavior, paymentBehavior as toastPaymentBehavior } from "./toast/behaviors";
import { PaymentMethod } from "src/common/payment";

const posPaymentGatewayBehaviors: PosPaymentGatewayBehaviors = {
    toast: {
        getAddCardBehavior: () => toastAddCardBehavior,
        getPaymentBehavior: () => toastPaymentBehavior,
    },
};

export const getPosAddCardBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getAddCardBehavior?.(paymentMethod);

export const getPosVerifyCardBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getVerifyCardBehavior?.(paymentMethod);

export const getPosPaymentBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getPaymentBehavior?.(paymentMethod);

export const getPosPayPalBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getPayPalBehavior?.(paymentMethod);
