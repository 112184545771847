import { trackedEvent } from "src/common/events/reduxEventTracking";

export interface NativeState {
    paused: boolean;
    connected: boolean;
    keyboardOpen: boolean;
}

export enum TypeKeys {
    PAUSED = "NATIVE/PAUSED",
    RESUMED = "NATIVE/RESUMED",
    OFFLINE = "NATIVE/OFFLINE",
    ONLINE = "NATIVE/ONLINE",
    KEYBOARD_OPEN = "NATIVE/KEYBOARD_OPEN",
}

export const actionCreators = {
    paused: () => ({ type: TypeKeys.PAUSED }),
    resumed: () => ({ type: TypeKeys.RESUMED }),
    offline: () => trackedEvent({ type: TypeKeys.OFFLINE }),
    online: () => trackedEvent({ type: TypeKeys.ONLINE }),
    keyboardOpen: (open: boolean) => ({ type: TypeKeys.KEYBOARD_OPEN, open }),
};

export type NativePausedAction = { type: TypeKeys.PAUSED };
export type NativeResumedAction = { type: TypeKeys.RESUMED };
export type NativeOfflineAction = { type: TypeKeys.OFFLINE };
export type NativeOnlineAction = { type: TypeKeys.ONLINE };
export type NativeKeyboardOpenAction = { type: TypeKeys.KEYBOARD_OPEN; open: boolean };

export type NativeAction =
    | NativePausedAction
    | NativeResumedAction
    | NativeOfflineAction
    | NativeOnlineAction
    | NativeKeyboardOpenAction;

const initialState: NativeState = {
    paused: false,
    connected: true,
    keyboardOpen: false,
};

export function reducer(state: NativeState = initialState, action: NativeAction): NativeState {
    if (action.type === TypeKeys.PAUSED) {
        return {
            ...state,
            paused: true,
        };
    }

    if (action.type === TypeKeys.RESUMED) {
        return {
            ...state,
            paused: false,
        };
    }

    if (action.type === TypeKeys.OFFLINE) {
        return {
            ...state,
            connected: false,
        };
    }

    if (action.type === TypeKeys.ONLINE) {
        return {
            ...state,
            connected: true,
        };
    }

    if (action.type === TypeKeys.KEYBOARD_OPEN) {
        return {
            ...state,
            keyboardOpen: action.open,
        };
    }

    return state;
}
