import * as Web from "./web";
import * as Native from "./native";
import { Experience } from "./interface";
import { config } from "../config";
import { disabledOneTimePassword } from "../shared/oneTimePassword";

const useWeb = (config.REACT_APP_WEB === "auto" && !(window as any).inCordova) || config.REACT_APP_WEB === "1";

const experience: Experience = useWeb ? Web : Native;

const {
    isWeb,
    isNative,
    browserSupport,
    deviceReady,
    device,
    statusBar,
    prompt,
    asset,
    handleUrlLink,
    splashScreen,
    backHandler,
    storeRating,
    appForegroundChange,
    registerToKeyboard,
    href,
    connectivity,
    applePay,
    googlePay,
    qr,
    refreshApp,
    errorBoundaryMessages,
    oneTimePassword: experienceOneTimePassword,
    scrolling,
    rotationHandler,
    navigation,
    webFlow,
    incorrectDocumentHeightHandler,
    scaleHandler,
    browserTranslate,
    tracking,
    gdpr,
    cardVerification,
    sso,
    reCaptcha,
} = experience;

// Experience VS Business-Logic

const oneTimePassword = config["enableOtp"] === "1" ? experienceOneTimePassword : disabledOneTimePassword;

export {
    isWeb,
    isNative,
    browserSupport,
    deviceReady,
    device,
    statusBar,
    prompt,
    asset,
    handleUrlLink,
    splashScreen,
    backHandler,
    storeRating,
    registerToKeyboard,
    appForegroundChange,
    href,
    connectivity,
    applePay,
    googlePay,
    qr,
    refreshApp,
    errorBoundaryMessages,
    oneTimePassword,
    scrolling,
    rotationHandler,
    navigation,
    webFlow,
    incorrectDocumentHeightHandler,
    scaleHandler,
    browserTranslate,
    tracking,
    gdpr,
    cardVerification,
    sso,
    reCaptcha,
};
