import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { device, scrolling } from "src/common/experience";
import { usePrevious } from "src/sharedComponents/common/shared";

interface Props {
    show: boolean;
    error?: boolean;
}

export const MenuItemExpandableContent = ({ show, error, children }: PropsWithChildren<Props>) => {
    const contentInnerRef = useRef<HTMLDivElement>(null);
    const prevShow = usePrevious(show);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        setTimeout(() => setContentHeight(contentInnerRef.current?.offsetHeight ?? 0));
    }, []);

    useEffect(() => {
        if (!show || prevShow) return;
        const timeout = setTimeout(() => {
            if (device.isAndroid || device.isChrome) {
                contentInnerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                scrolling.scrollTo(
                    { top: contentInnerRef.current?.offsetTop, behavior: "smooth" },
                    ".MenuItemModalPortal"
                );
            }
        }, 300);
        return () => clearTimeout(timeout);
    }, [show, prevShow]);

    return (
        <div
            className={classNames("menuitemcard-note__content", (show || error) && "menuitemcard-note__content--show")}
            style={{ "--menuitemcard-note-content-height": `${contentHeight}px` } as CSSProperties}
        >
            <div ref={contentInnerRef} className="menuitemcard-note__content__inner">
                {children}
            </div>
        </div>
    );
};
