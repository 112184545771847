import "../assets/NoOfferContainer.scss";
import React, { useCallback, useEffect } from "react";
import { MarketingOptInPage } from "src/features/marketing/components/MarketingOptInPage";
import { useDispatch, useSelector } from "react-redux";
import { getMarketingData, getMarketingPrivacyPolicyUrl } from "src/features/marketing/selectors/optInMarketing";
import { getIsAnonymous } from "src/features/accounts/selectors";
import { signInAction } from "src/features/signup/actions/signInAction";
import { subscribeToGroupMarketing, subscribeToLocationMarketing } from "src/features/marketing/actions/sendOptIn";
import { CouponCircleIcon, EnvelopeCircleIcon } from "src/sharedComponents/assets/icons";
import { WizardModal } from "src/features/wizards/components/WizardModal";
import { optInMarketingFlowWizard } from "src/features/marketing/wizard";
import { getParty } from "src/features/order";
import { getTableToken } from "src/features/partyOnboarding/persistence/tableToken";
import { rejoinTable } from "src/features/partyOnboarding/actions/rejoinTable";
import { replace } from "connected-react-router";
import classNames from "classnames";
import { MessageScreen } from "src/sharedComponents/layouts/messageScreen/MessageScreen";
import { SimpleNavHeader } from "src/common/navigation";
import { getOfferData } from "../selectors";
import { SignupSource } from "src/features/marketing";
import { IdentifierScope } from "../types";

export const NoOfferContainer = () => {
    const marketingData = useSelector(getMarketingData);
    const privacyPolicyUrl = useSelector(getMarketingPrivacyPolicyUrl);
    const isAnonymous = useSelector(getIsAnonymous);
    const offer = useSelector(getOfferData);
    const dispatch = useDispatch();
    const party = useSelector(getParty);
    const tableToken = getTableToken();

    const newsletterProvider = marketingData.newsletterProvider;

    const showNoOfferPage = !offer || !privacyPolicyUrl || !newsletterProvider;

    useEffect(() => {
        if (!showNoOfferPage) {
            dispatch(optInMarketingFlowWizard.actionCreators.start("OptInLandingPage"));
        }
    }, [dispatch, showNoOfferPage]);

    const onDone = useCallback(() => dispatch(optInMarketingFlowWizard.actionCreators.done()), [dispatch]);

    const onOfferClose = useCallback(() => {
        if (!party && tableToken) {
            dispatch(rejoinTable(tableToken));
        }
        dispatch(replace("/join-table"));
    }, [dispatch, party, tableToken]);

    const onSubscribeToGroupMarketing = useCallback(
        (onSuccess: () => void) => {
            var marketingOptIn = () => {
                if (offer!.scope === IdentifierScope.LOCATION) {
                    subscribeToLocationMarketing(offer!.scopeId, SignupSource[SignupSource.OFFERCLAIM]);
                } else {
                    subscribeToGroupMarketing(offer!.scopeId, SignupSource[SignupSource.OFFERCLAIM]);
                }
            };

            if (isAnonymous) {
                dispatch(
                    signInAction({
                        useLocationTheme: true,
                        isMidWizard: true,
                        slideAnimation: "slide-from-right",
                        onComplete: () => {
                            dispatch(marketingOptIn());
                            onSuccess();
                        },
                        accountDetailsOptions: {
                            signInDescription: "Create an account with me&u to save your offer",
                            subtitle: "Please provide your details to finish subscribing to the newsletter",
                            requireEmail: true,
                            emailMessage: "Needed to subscribe to newsletter",
                        },
                    })
                );
            } else {
                dispatch(marketingOptIn());
                onSuccess();
            }
        },
        [dispatch, isAnonymous, offer]
    );

    if (showNoOfferPage) {
        return (
            <div className="no-offer-container">
                <div className={classNames("scroll-element", "animated-child")}>
                    <SimpleNavHeader closeToBack onBack={onOfferClose} withBorder />
                    <MessageScreen
                        icon={<CouponCircleIcon />}
                        title="Offer not found"
                        description={[
                            "We can’t find the offer you were looking for. Keep an eye out for more custom offers in the future.",
                        ]}
                    />
                </div>
            </div>
        );
    }

    return (
        <WizardModal
            wizard={optInMarketingFlowWizard}
            transition="slide-up"
            onReset={onDone}
            onComplete={onDone}
            onCloseComplete={onDone}
            pages={{
                OptInLandingPage: {
                    render: (onSuccess) => (
                        <div className="no-offer-container">
                            <MarketingOptInPage
                                onSubmit={() => onSubscribeToGroupMarketing(onSuccess)}
                                subscribeButtonText="Subscribe now"
                                onPageClose={onOfferClose}
                                displayName={newsletterProvider!}
                                icon={<CouponCircleIcon />}
                                title="Don’t miss out again"
                                description={[
                                    `We can’t find the offer you were looking for. But subscribe to the ${newsletterProvider} newsletter today to keep up to date with the latest news and events.`,
                                ]}
                                privacyPolicyUrl={privacyPolicyUrl!}
                            />
                        </div>
                    ),
                    header: null,
                },
                OptInSuccessfulPage: {
                    render: () => (
                        <div className="no-offer-container">
                            <div className={classNames("scroll-element", "animated-child")}>
                                <SimpleNavHeader closeToBack onBack={onOfferClose} withBorder />
                                <MessageScreen
                                    icon={<EnvelopeCircleIcon />}
                                    title="Thanks for subscribing"
                                    description={[
                                        "Keep a lookout in your inbox for all the latest.",
                                        "Until next time!",
                                    ]}
                                />
                            </div>
                        </div>
                    ),
                    header: null,
                },
            }}
        />
    );
};
