import * as accountApi from "../api/accountApi";
import { AppDispatch } from "src/features";
import { deleteAccountOperation } from "../operations";
import { logout } from "./logout";
import { trackedEvent } from "src/common/events/reduxEventTracking";

export function deleteAccount() {
    return deleteAccountOperation.getThunk(async (dispatch: AppDispatch) => {
        dispatch(trackedEvent({ type: deleteAccountOperation.name }));
        await accountApi.deleteAccount();
        dispatch(logout());
    });
}
