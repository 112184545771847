import { AppState } from "src/features";
import { claimOfferOperation } from "../operations";
import { createSelector } from "reselect";
import { OfferStatus, OfferType } from "../types";
import { getLocationName } from "src/features/menu/selectors";
import { getOfferTooltipState } from "../actions/offerTooltipAction";
import { isEqual } from "lodash";

export const getOfferData = (state: AppState) => state.offers.offerData;
export const getClaimOfferResult = (state: AppState) => state.offers.claimOfferResult;
export const getAvailableOffers = (state: AppState) => state.offers.availableOffers;
export const getShowOfferTooltip = (state: AppState) => !!state.offers.showTooltip;
export const getDiscoverableOffers = (state: AppState) => state.offers.discoverableOffers;
export const getOfferIemsSelectionIndex = (state: AppState) => state.offers.itemsSelectionIndex;

export const getOfferDataStatus = createSelector(
    getOfferData,
    (offerData) => offerData?.status ?? OfferStatus.AVAILABLE
);
export const getIsClaimingOffer = createSelector(claimOfferOperation.getStatus, (status) => status === "processing");
export const getHasClaimOfferError = createSelector(claimOfferOperation.getError, (error) => !!error);
export const getAvailableDiscountOffers = createSelector(getAvailableOffers, (availableOffers) =>
    availableOffers?.filter((offer) => offer.offerType === OfferType.DISCOUNT)
);
export const getAvailableStampCardOffers = createSelector(getAvailableOffers, (availableOffers) =>
    availableOffers?.filter((offer) => offer.offerType === OfferType.STAMP_CARD)
);
export const getDiscoverableStampCard = createSelector(getDiscoverableOffers, (discoverableOffers) =>
    discoverableOffers?.find((offer) => offer.offerType === OfferType.STAMP_CARD)
);

export const getOfferTooltipCount = createSelector(
    getLocationName,
    getDiscoverableStampCard,
    getAvailableDiscountOffers,
    (venueName, discoverableStampCard, availableDiscountOffers) => {
        if (!venueName || (!discoverableStampCard && !availableDiscountOffers)) {
            return 0;
        }
        const tooltipState = getOfferTooltipState() ?? {};
        const venueTooltipState = tooltipState[venueName] ?? {};
        let totalCount = 0;

        if (
            discoverableStampCard &&
            (venueTooltipState.campaignId !== discoverableStampCard.id ||
                (venueTooltipState.count && venueTooltipState.count < 4))
        ) {
            totalCount += 1;
        }
        totalCount += availableDiscountOffers?.length ?? 0;

        return totalCount;
    }
);

export const getSelectedOfferOfferItems = createSelector(getOfferData, (offer) => {
    if (!offer?.offerItems) return undefined;
    if (offer.offerItems.length === 2 && isEqual(offer.offerItems[0].ids, offer.offerItems[1].ids)) {
        return offer.offerItems.slice(0, 1);
    }
    return offer.offerItems;
});

export const getSelectedOfferMinItems = createSelector(
    getSelectedOfferOfferItems,
    getOfferIemsSelectionIndex,
    (offerItems, itemsSelectionIndex) => {
        if (itemsSelectionIndex === undefined || !offerItems?.[itemsSelectionIndex]) return undefined;
        return offerItems[itemsSelectionIndex].minUnits;
    }
);
