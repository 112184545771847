import { AppDispatch, AppState } from "src/features";
import { leaveGroupTabOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { removeMemberFromGroupTabApi } from "../API/removeMemberFromGroupTabApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getTabType, getTabTypeName } from "../selectors/activeGroupTab";
import { getTabTypeReadableName } from "../selectors/helpers";

export function removeMemberGroupTabAction(memberId: string) {
    return leaveGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const tabTypeName = getTabTypeName(getState());
        const tabType = getTabType(getState());
        try {
            const res = await removeMemberFromGroupTabApi(memberId);
            dispatch(activeTabActionCreators.setGroupTabData(res));
            dispatch(activeTabActionCreators.trackGroupTabMemberRemoved(res.id, getTabTypeReadableName(tabType)));
        } catch (ex) {
            if (ProblemDetailsError.isProblemDetailsError(ex)) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("failed to remove member from group tab:", ex);
            }
        }
    });
}
