import { AppDispatch, AppState } from "src/features";
import { getCurrentMemberId, getRequireSignIn } from "src/features/accounts/selectors";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { getIsSectionTakeaway, getParty } from "src/features/order/selectors";
import { GroupTabStatus } from "..";
import { getGroupTabOperation, getGroupTabUpdatesOperation, joinGroupTabOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as wizardTabActionCreators } from "../reducers/groupTabWizard";
import { actionCreators as joinTabActionCreators } from "../reducers/joinGroupTab";
import { actionCreators as bannerActionCreators } from "../../banners/reducers";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { getGroupTabsWizard } from "../selectors/groupTabWizardSelectors";
import { getJoinGroupTabCode } from "../selectors/joinGroupTab";
import { disableGroupTabPackageAction } from "./disableGroupTabPackageAction";
import { getGroupTabApi } from "../API/getGroupTabApi";
import { closeGroupTabApi } from "../API/closeGroupTabApi";
import { leaveGroupTabApi } from "../API/leaveGroupTabApi";
import { getActiveGroupTabData, getIsGroupTabOwner, getTabTypeName } from "../selectors/activeGroupTab";
import { getLastGroupTabId } from "../persistence/lastGroupTab";
import { getActivePackageId } from "../../menu/selectors";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";
import { getTabTypeNameByLocale } from "../selectors/helpers";
import { bannerMessages } from "../../banners/messages";
import { getIsOpeningTab } from "../../payment/selectors";
import { actionCreators as paymentActionCreators } from "../../payment";
import { TabType, ActiveGroupTabData } from "../types";
import { NavBarType } from "../../../common/navigation/types";
import { getNavBarStatus } from "../../../common/navigation";
import { joinGroupTabApi } from "../API/joinGroupTabApi";
import { fetchProfile } from "../../accountmenu";
import { MenuDataLocale } from "../../menudata/types/MenuDataLocale";
import { getIsOpenTable } from "../../openTable/selectors";
import { leaveTable } from "../../partyOnboarding/actions/leaveTable";
import { resetJoinTableOperations } from "../../partyOnboarding/actions/resetOperations";
import { signInAction } from "src/features/signup/actions/signInAction";

export const getGroupTabAction = (cb?: () => void, isJoiningOpenTable?: boolean) => {
    return getGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        let tabTypeName = getTabTypeName(state);

        try {
            const groupTabCode = getJoinGroupTabCode(state);
            const inParty = !!getParty(state);
            const isTakeaway = getIsSectionTakeaway(state);
            const requireSignIn = getRequireSignIn(state);
            const isOpenTable = inParty ? getIsOpenTable(state) : !!isJoiningOpenTable;

            if (groupTabCode && isJoiningOpenTable) {
                dispatch(
                    showModalMessage(
                        modalMessages.tabUnavailable(() => {
                            dispatch(leaveTable());
                            dispatch(joinTabActionCreators.resetJoinGroupTab());
                            dispatch(resetJoinTableOperations());
                        }, tabTypeName)
                    )
                );
                return;
            }

            if (requireSignIn) {
                if (inParty && groupTabCode && !isTakeaway) {
                    dispatch(joinGroupTabAction(groupTabCode));
                }
                return;
            }

            const res = await getGroupTabApi({ inParty });

            if (res?.locale) {
                tabTypeName = getTabTypeNameByLocale(res.locale);
            }

            const memberId = getCurrentMemberId(state);

            if (
                res &&
                res.status === GroupTabStatus.OPEN &&
                res.members.some((member) => member.memberId === memberId)
            ) {
                const menuDataLocale = inParty ? getMenuDataLocale(state) : MenuDataLocale.fromTabData(res);
                if (isOpenTable) {
                    handleMemberInActiveTab(
                        dispatch,
                        menuDataLocale,
                        res,
                        memberId,
                        tabTypeName,
                        isOpenTable,
                        groupTabCode,
                        cb
                    );
                    return;
                }
                if (inParty && isTakeaway) {
                    dispatch(showModalMessage(modalMessages.groupTabUnavailableWithTakeaway(`${tabTypeName}s`)));
                    dispatch(paymentActionCreators.removeGroupTabPaymentMethod());
                    if (groupTabCode) {
                        dispatch(joinTabActionCreators.resetJoinGroupTab());
                    }
                    return;
                }
                const groupTabWizard = getGroupTabsWizard(state);
                const isOpeningTab = getIsOpeningTab(state);
                if (
                    inParty &&
                    (groupTabWizard.showWizard ||
                        (groupTabCode && res.joinCode !== groupTabCode) ||
                        // Change so this only triggers the error for open tabs if we have a callback
                        // so it will be triggered from CreateTab and not verifying
                        (isOpeningTab && cb))
                ) {
                    if (groupTabCode) {
                        dispatch(joinTabActionCreators.resetJoinGroupTab());
                    }
                    handleMemberInActiveTab(
                        dispatch,
                        menuDataLocale,
                        res,
                        memberId,
                        tabTypeName,
                        isOpenTable,
                        groupTabCode,
                        cb
                    );
                } else {
                    dispatch(activeTabActionCreators.setGroupTabData(res));
                    if (inParty) {
                        dispatch(joinTabActionCreators.resetJoinGroupTab());
                    }
                    cb?.();
                }
            } else if (inParty && groupTabCode) {
                dispatch(activeTabActionCreators.setGroupTabData(res));
                dispatch(joinGroupTabAction(groupTabCode));
            } else {
                const groupTabId = getLastGroupTabId();
                if (groupTabId) {
                    dispatch(getSpecificGroupTab(groupTabId));
                } else {
                    dispatch(activeTabActionCreators.setGroupTabData(res));
                }
            }
        } catch (ex) {
            if (ex instanceof ProblemDetailsError) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("failed to get group tab:", ex);
            }
        }
    });
};

export function getSpecificGroupTab(groupTabId: string) {
    return getGroupTabUpdatesOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const inParty = !!getParty(state);
        const memberId = getCurrentMemberId(state);
        const isGroupTabOwner = getIsGroupTabOwner(state);
        const partyPackageId = getActivePackageId(state);
        const groupTab = await getGroupTabApi({ inParty, groupTabId });
        const tabTypeName = getTabTypeNameByLocale(groupTab?.locale);
        const navBarStatus = getNavBarStatus(state);
        const isInviteeOnLimitedMenu =
            !isGroupTabOwner && !!groupTab?.packageId && groupTab.packageId === partyPackageId;
        const disableGroupTabPackage = isInviteeOnLimitedMenu
            ? () => dispatch(disableGroupTabPackageAction(true))
            : undefined;

        if (groupTab?.status === GroupTabStatus.CLOSED) {
            if (inParty && navBarStatus === NavBarType.GROUP_TABS && groupTab.type === TabType.PAYONORDER) {
                dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            }
            dispatch(
                showModalMessage(
                    groupTab.owner === memberId
                        ? modalMessages.groupTabClosedOwner(groupTab.closeReason, tabTypeName)
                        : modalMessages.groupTabClosed(tabTypeName, disableGroupTabPackage)
                )
            );
            dispatch(paymentActionCreators.clearPaymentMethods());
        } else if (groupTab?.removedMembers.some((member) => member.memberId === memberId)) {
            if (inParty && navBarStatus === NavBarType.GROUP_TABS && groupTab.type === TabType.PAYONORDER) {
                dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            }
            dispatch(showModalMessage(modalMessages.groupTabMemberRemoved(tabTypeName, disableGroupTabPackage)));
            dispatch(bannerActionCreators.removeBanner(bannerMessages.activeGroupTab(tabTypeName).title));
        }

        dispatch(activeTabActionCreators.setGroupTabData(groupTab));
    });
}

export function getGroupTabUpdatesAction() {
    return getGroupTabUpdatesOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const activeGroupTab = getActiveGroupTabData(state);
        const tabTypeName = getTabTypeName(state);
        const navBarStatus = getNavBarStatus(state);

        if (!activeGroupTab) {
            return;
        }

        const inParty = !!getParty(state);
        try {
            const res = await getGroupTabApi({
                inParty,
                groupTabId: activeGroupTab.id,
                lastUpdated: activeGroupTab.dateUpdated,
                locationId: activeGroupTab.locationId,
            });
            if (res) {
                if (res.status !== GroupTabStatus.OPEN) {
                    if (inParty && navBarStatus === NavBarType.GROUP_TABS && res.type === TabType.PAYONORDER) {
                        dispatch(navBarActionCreators.activeType(NavBarType.MENU));
                    }
                    dispatch(showModalMessage(modalMessages.groupTabClosedOwner(res.closeReason, tabTypeName)));
                    dispatch(paymentActionCreators.clearPaymentMethods());
                }
                dispatch(activeTabActionCreators.setGroupTabData(res));
            }
        } catch (ex) {
            console.log("failed to get update", ex);
        }
    });
}

export const joinGroupTabAction = (joinCode: string, joinedViaCodeInput?: boolean) => {
    return joinGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const inParty = !!getParty(state);
        if (!joinCode || !inParty) return;

        const requireSignIn = getRequireSignIn(state);
        let tabTypeName = getTabTypeName(state);
        if (requireSignIn) {
            dispatch(joinTabActionCreators.setJoinGroupTabCode(joinCode));
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    onComplete: () => dispatch(getGroupTabAction()),
                    onCancel: () => dispatch(joinTabActionCreators.setJoinGroupTabCode(undefined)),
                })
            );
            return;
        }
        try {
            const res = await joinGroupTabApi(joinCode);
            if (res?.locale) {
                tabTypeName = getTabTypeNameByLocale(res.locale);
            }
            dispatch(fetchProfile());
            dispatch(activeTabActionCreators.setGroupTabData(res));
            dispatch(joinTabActionCreators.resetJoinGroupTab());
            dispatch(paymentActionCreators.clearPaymentMethods());
            dispatch(joinTabActionCreators.trackJoinGroupTab(res?.id, !!joinedViaCodeInput));
            // need to make sure the Menu is on ServicePage and not MenuPage (cause a white page)
            window.history.replaceState(null, "", "/menu/service");
        } catch (ex) {
            if (ex instanceof ProblemDetailsError) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("Join group tab exception:", ex);
            }
            // ToDo remove once wee support close existing tabs
            dispatch(joinTabActionCreators.resetJoinGroupTab());
            dispatch(getGroupTabAction());
        }
    });
};

const handleMemberInActiveTab = (
    dispatch: AppDispatch,
    menuDataLocale: MenuDataLocale | null,
    tab: ActiveGroupTabData,
    memberId: string,
    tabTypeName: string,
    isOpenTable: boolean,
    groupTabCode?: string,
    cb?: () => void
) => {
    if (tab.owner === memberId) {
        dispatch(
            showModalMessage(
                modalMessages.ownAnotherGroupTab(
                    menuDataLocale?.formatCurrency(tab.limit - tab.available) || (tab.limit - tab.available).toString(),
                    menuDataLocale?.formatCurrency(tab.available) || tab.available.toString(),
                    groupTabCode ? "joining" : "creating",
                    async () => {
                        await closeGroupTabApi();
                        dispatch(paymentActionCreators.removeGroupTabPaymentMethod());
                        if (groupTabCode) {
                            dispatch(joinGroupTabAction(groupTabCode));
                        }
                    },
                    tabTypeName,
                    tab.type,
                    `Close ${tabTypeName} & ${groupTabCode ? "join" : "continue"}`,
                    () => {
                        dispatch(wizardTabActionCreators.completeGroupTabWizard());
                        dispatch(activeTabActionCreators.setGroupTabData(tab));
                        if (isOpenTable) {
                            dispatch(leaveTable());
                            dispatch(resetJoinTableOperations());
                        }
                        cb?.();
                    },
                    isOpenTable ? "Cancel" : `Stay on current ${tabTypeName}`,
                    isOpenTable
                )
            )
        );
    } else {
        dispatch(
            showModalMessage(
                modalMessages.partOfGroupTabCreate(
                    groupTabCode ? "joining" : "creating",
                    async () => {
                        await leaveGroupTabApi();
                        dispatch(paymentActionCreators.removeGroupTabPaymentMethod());
                        if (groupTabCode && !isOpenTable) {
                            dispatch(joinGroupTabAction(groupTabCode));
                        }
                    },
                    !isOpenTable && groupTabCode ? `Join new ${tabTypeName}` : `Leave ${tabTypeName} & continue`,
                    tabTypeName,
                    () => {
                        dispatch(wizardTabActionCreators.completeGroupTabWizard());
                        if (isOpenTable) {
                            dispatch(leaveTable());
                            dispatch(resetJoinTableOperations());
                        } else {
                            dispatch(activeTabActionCreators.setGroupTabData(tab));
                        }
                        dispatch(joinTabActionCreators.resetJoinGroupTab());
                        cb?.();
                    },
                    isOpenTable
                )
            )
        );
    }
};
