import { trackedEvent } from "../../../common/events/reduxEventTracking";

enum TypeKeys {
    TAX_INVOICE_REQUESTED = "TAX_INVOICE_REQUESTED",
    TAX_INVOICE_SENT = "TAX_INVOICE_SENT",
}

export const actionCreators = {
    trackInvoiceRequested: () => trackedEvent({ type: TypeKeys.TAX_INVOICE_REQUESTED }),
    trackInvoiceSent: () => trackedEvent({ type: TypeKeys.TAX_INVOICE_SENT }),
};
