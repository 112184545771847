import { AuthorizePaymentResult, GooglePayPaymentMethod, paymentOptions } from "../../../../payment";
import { cordovaWindow } from "../../cordova";
import { StripeGooglePayPaymentMethod } from "../../../../../features/paymentGateways/stripe/types";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

export const initializeStripeGooglePayButton: InitializeDevicePaymentMethodButtonFunc<GooglePayPaymentMethod> = async (
    googlePayPaymentMethod,
    total
) => {
    const { countryCode, currency, googlePayMerchantName } = googlePayPaymentMethod as StripeGooglePayPaymentMethod;

    return () =>
        new Promise<AuthorizePaymentResult | null>((resolve, reject) =>
            cordovaWindow.StripeDevicePayments.authorizeGooglePayPayment(
                countryCode,
                currency,
                googlePayMerchantName,
                total.toFixed(2),
                paymentOptions.label,
                resolve,
                reject
            )
        );
};
