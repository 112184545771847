import * as React from "react";
import "../assets/ToastifiedMessage.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PartyNotification } from "../../order";

export interface Props {
    notifications?: PartyNotification[];
    viewNotification: (id: string) => void;
    disabled: boolean;
}

export interface State {
    showedNotifications: string[];
}

export class ToastifiedMessage extends React.Component<Props, State> {
    componentDidUpdate(prevProps: Readonly<Props>) {
        const { notifications } = this.props;
        if (prevProps.notifications !== notifications && notifications && notifications.length) {
            notifications.map(this.toastifyMessage);
        }
    }
    toastifyMessage = (notification: PartyNotification) => {
        const { viewNotification, disabled } = this.props;
        viewNotification(notification.id);
        if (disabled) return;
        toast(this.renderMessage(notification), {
            position: toast.POSITION.TOP_RIGHT,
            className: "toastifiedMessage",
            hideProgressBar: true,
        });
    };
    renderMessage = (notification: PartyNotification) => (
        <div className="toastifiedMessage__body">
            <div className="toastifiedMessage__body__wrapper">
                <div className="toastifiedMessage__body__wrapper__title">{notification.title}</div>
                <div className="toastifiedMessage__body__wrapper__description">{notification.shortDescription}</div>
            </div>
        </div>
    );
    render() {
        return <></>;
    }
}
