import { ITelemetryPlugin } from "@microsoft/applicationinsights-core-js";
import { IReduxActionPlugin } from "../plugins/IReduxActionPlugin";

export function findReduxActionPlugins<T>(
    extensions: (ITelemetryPlugin & Partial<IReduxActionPlugin>)[]
): IReduxActionPlugin[] {
    const ret: IReduxActionPlugin[] = [];

    for (let ext of extensions) {
        if (isReduxActionPlugin(ext)) {
            ret.push(ext);
        }
    }

    return ret;
}

function isReduxActionPlugin(ext: Partial<IReduxActionPlugin>): ext is IReduxActionPlugin {
    return !!ext.handleReduxAction;
}
