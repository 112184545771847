import * as React from "react";
import { connect } from "react-redux";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { LinkProps } from "react-router-dom";
import { TappableLink } from "src/sharedComponents/common/tappable";
import { AppState } from "../../index";
import { DataProps } from "src/common/types/utils";

interface OwnProps extends RouteComponentProps<CategoryRouteParams> {
    partyId: string;
    className?: string;
    children: React.ReactNode;
}

interface CategoryRouteParams {
    menuId?: string;
    categoryId?: string;
}

const OrderHistoryItemLinkProxy = ({ to, className, children }: LinkProps) => (
    <TappableLink to={to} className={className}>
        {children}
    </TappableLink>
);

function mapStateToProps(state: AppState, props: OwnProps): DataProps<LinkProps> {
    const { className, partyId } = props;

    return {
        to: generatePath("/account/order-history/:partyId", {
            partyId,
        }),
        className,
    };
}

export const OrderHistoryItemLink = withRouter(connect(mapStateToProps)(OrderHistoryItemLinkProxy));
