import { AppDispatch, AppState } from "../..";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { orderApi } from "../../order/orderApi";
import { disableGroupTabPackageOperation } from "../operations";
import { getTabTypeName } from "../selectors/activeGroupTab";

export const disableGroupTabPackageAction = (clearGroupTabData?: boolean) => {
    return disableGroupTabPackageOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const tabTypeName = getTabTypeName(getState());
        try {
            await orderApi.invoke("disableGroupTabPackage");
            if (clearGroupTabData) {
                dispatch(activeTabActionCreators.setGroupTabData(undefined));
            }
        } catch (ex) {
            if (ex instanceof ProblemDetailsError) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("failed to disable group tab package:", ex);
            }
        }
    });
};
