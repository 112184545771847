import { connect } from "react-redux";
import { AppState, AppDispatch } from "../..";
import { ToastOrderHistoryStatus, Props } from "../components/ToastOrderHistoryStatus";
import { getReadyOrdersHistory, getActiveOrdersHistory } from "../../orderHistoryData/selectors";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { selectMealHistory } from "../../orderHistoryData/actions/fetchMealHistory";
import { NavBarType } from "../../../common/navigation/types";
import { FunctionProps, DataProps } from "src/common/types/utils";

interface OwnProps {
    onlyReady?: boolean;
}

export function mapStateToProps(state: AppState, { onlyReady }: OwnProps): DataProps<Props> {
    const readyOrderHistory = getReadyOrdersHistory(state);
    const activeOrderHistory = getActiveOrdersHistory(state);
    const dontShow = state.navBar && state.navBar.navBarStatus === NavBarType.PROFILE;
    return {
        isReady: !!readyOrderHistory.length,
        dontShow,
        orderHistory: onlyReady || readyOrderHistory.length ? readyOrderHistory : activeOrderHistory,
    };
}
export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    return {
        goToHistory: (partyId: string) => {
            if (!!partyId) {
                selectMealHistory(dispatch, partyId, true);
            } else {
                dispatch(accountMenuActionCreators.openAccountMenu("OrderHistory"));
            }
        },
    };
}

export const ToastOrderHistoryStatusContainer = connect(mapStateToProps, mapDispatchToProps)(ToastOrderHistoryStatus);
