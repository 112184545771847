import { UpdatePaymentAmountInfoVisitor } from "src/features/paymentGateways/types";
import { getToastPaymentSessionData } from "src/features/paymentGateways/pos/toast/selectors";

export const visitToastUpdatePaymentAmountInfo: UpdatePaymentAmountInfoVisitor = async (
    _,
    getState,
    updatePaymentAmountInfo
) => {
    const { paymentIntentId } = getToastPaymentSessionData(getState());

    updatePaymentAmountInfo.posPaymentInfo = {
        paymentId: paymentIntentId,
    };
};
