import { deviceReady } from "../";

import { onPause, onResume, appForegroundHandler } from "../shared/appForegroundChange";

export const appForegroundChange = (onForeground: () => void, onBackground: () => void) => {
    appForegroundHandler(onForeground, onBackground);
    deviceReady().then(() => {
        document.removeEventListener("pause", onPause, false);
        document.addEventListener("pause", onPause, false);
        document.removeEventListener("resume", onResume, false);
        document.addEventListener("resume", onResume, false);
    });
};
