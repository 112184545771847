import { AppDispatch, AppMiddleware } from "src/features";
import { auth } from "src/common/auth";
import { AuthState, AuthStateChangeReason } from "src/common/auth/auth";
import { AnyAction, Dispatch } from "redux";
import { authStateActionCreators } from "../reducers";
import { regionHelper } from "../../region";

export const authStateMiddleware = () => (store: AppMiddleware) => {
    function setAuthState(dispatch: AppDispatch, prevSource?: string) {
        const initialState = auth.getState();
        const source = auth.getAuthStateSource()!;

        if (initialState !== null) {
            dispatch(authStateActionCreators.setAuthState(initialState, "initial", source, prevSource));
        }
    }

    auth.onAuthStateChanged((state: AuthState, reason: AuthStateChangeReason, source: string, prevSource?: string) =>
        store.dispatch(authStateActionCreators.setAuthState(state, reason, source, prevSource))
    );

    regionHelper.onRegionChanged(() => {
        const prevSource = auth.getAuthStateSource();
        auth.refreshState();
        setAuthState(store.dispatch, prevSource);
    });

    return (next: Dispatch) => {
        setAuthState(next);

        return (action: AnyAction) => next(action);
    };
};
