import {
    AuthorizePaymentResult,
    getGooglePaymentsClient,
    GooglePayPaymentMethod,
    paymentOptions,
} from "../../../../payment";
import { AnyAction } from "redux";

export type GetGooglePayPaymentDataRequestFunc = (
    transactionInfo: google.payments.api.TransactionInfo
) => Promise<google.payments.api.PaymentDataRequest>;

export type GetGooglePayAuthorizePaymentResultFunc = (
    paymentData: google.payments.api.PaymentData
) => Promise<AuthorizePaymentResult | null>;

export const initializeGooglePayButton = async (
    googlePayPaymentMethod: GooglePayPaymentMethod,
    total: number,
    trackEvent: (action: AnyAction) => void,
    getPaymentDataRequest: GetGooglePayPaymentDataRequestFunc,
    getAuthorizePaymentResult: GetGooglePayAuthorizePaymentResultFunc
) => {
    const { currency } = googlePayPaymentMethod;

    const transactionInfo: google.payments.api.TransactionInfo = {
        currencyCode: currency,
        totalPriceStatus: "FINAL",
        totalPrice: total.toFixed(2),
        totalPriceLabel: paymentOptions.label,
    };

    const [paymentDataRequest, paymentsClient] = await Promise.all([
        getPaymentDataRequest(transactionInfo),
        getGooglePaymentsClient(),
    ]);

    return async () => {
        try {
            const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
            return await getAuthorizePaymentResult(paymentData);
        } catch (err) {
            // If the Google Pay dialog is closed by pressing back it raises a DOMException
            if (
                err instanceof DOMException &&
                (err as DOMException).message === "User closed the Payment Request UI."
            ) {
                const { code, name, message } = err;
                trackEvent({ type: "DOM_EXCEPTION", code, name, message });
                return null;
            }

            // If the Google Pay login dialog is closed it throws a {statusCode: "CANCELED"}
            if (typeof err === "object" && "statusCode" in err && err.statusCode === "CANCELED") {
                trackEvent({ ...err, type: "CANCELED" });
                return null;
            }

            throw err;
        }
    };
};
