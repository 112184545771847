import * as React from "react";
import { connect } from "react-redux";
import { offline, online, paused, resumed } from "../actions";
import { FunctionProps } from "src/common/types/utils";
import {
    appForegroundChange,
    connectivity,
    registerToKeyboard,
    rotationHandler,
    storeRating,
} from "src/common/experience";
import { initDeviceStyle } from "src/common/shared";
import { keyboardOpen } from "../actions/keyboardOpen";

export interface Props {
    pause: () => void;
    resume: () => void;
    offline: () => void;
    online: () => void;
    keyboardOpen: (open: boolean) => void;
}

export class NativeEventListenerInner extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        const { offline, online, pause, resume, keyboardOpen } = props;

        registerToKeyboard(keyboardOpen);
        storeRating.init();
        initDeviceStyle.deviceType();
        connectivity.onOffline(offline);
        connectivity.onOnline(online);
        appForegroundChange(resume, pause);
        rotationHandler.register();
    }

    render() {
        return null;
    }
}

function mapDispatchToProps(dispatch: any): FunctionProps<Props> {
    return {
        pause: () => dispatch(paused()),
        resume: () => dispatch(resumed()),
        offline: () => dispatch(offline()),
        online: () => dispatch(online()),
        keyboardOpen: (open: boolean) => dispatch(keyboardOpen(open)),
    };
}

export const NativeEventListener = connect(null, mapDispatchToProps)(NativeEventListenerInner);
