import "./PayOnlySplitOptionConfigure.scss";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { PayOnlySelectAmount } from "./PayOnlySelectAmount";
import { PulseLoader, RefreshButton, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import {
    getPayOnlyEmbellishedItems,
    getPayOnlyPotentialRemainingBill,
    getPayOnlyRefreshing,
    getPayOnlyState,
} from "../selectors";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { SplitPaymentOption } from "src/features/order/reducers/types";
import { simplePlural } from "src/common/strings";
import { PayOnlySplitEvenly } from "./PayOnlySplitEvenly";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { PayOnlySplitByItem } from "./PayOnlySplitByItem";
import { payOnlyTrackActionCreators } from "../reducers/payOnlyTrack";

interface Props {
    onSuccess?: () => void;
}

export const PayOnlySplitOptionConfigure = ({ onSuccess }: Props) => {
    const dispatch = useDispatch();
    const menuDataContext = useContext(MenuDataLocaleContext);
    const potentialRemainingBalance = useSelector(getPayOnlyPotentialRemainingBill);
    const { splitOption, amount, selectedItems } = useSelector(getPayOnlyState);
    const embellishedItems = useSelector(getPayOnlyEmbellishedItems);
    const [visibleItems, setVisibleItems] = useState(embellishedItems);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [newItemsCount, setNewItemsCount] = useState(0);
    const refreshing = useSelector(getPayOnlyRefreshing);

    useEffect(() => {
        if (!visibleItems.length && embellishedItems.length) {
            setVisibleItems(embellishedItems);
        }
    }, [embellishedItems, visibleItems]);

    const newItems = useMemo(() => embellishedItems.length - visibleItems.length, [embellishedItems, visibleItems]);

    useEffect(() => {
        if (newItems) {
            setNewItemsCount(newItems);
            setShowRefreshButton(true);
        }
    }, [newItems]);

    useEffect(() => {
        if (showRefreshButton) dispatch(payOnlyTrackActionCreators.trackRefreshItemListButtonShown());
    }, [dispatch, showRefreshButton]);

    const handleOnClickRefreshButton = useCallback(() => {
        setShowRefreshButton(false);
        setVisibleItems(embellishedItems);
        dispatch(payOnlyTrackActionCreators.trackRefreshItemListButtonClicked());
    }, [dispatch, embellishedItems]);

    const totalSelectedItems = Object.values(selectedItems).reduce(
        (totalSelectedItems, itemQuantity) => totalSelectedItems + itemQuantity,
        0
    );

    const buttonLabel = totalSelectedItems
        ? `Review & pay for ${totalSelectedItems} item${simplePlural(totalSelectedItems)}`
        : "Review & pay";

    const renderRow = useCallback(
        (option: SplitPaymentOption) => {
            switch (option) {
                case "SelectAmount":
                    return <PayOnlySelectAmount />;
                case "SplitEvenly":
                    return <PayOnlySplitEvenly />;
                case "SelectItems":
                    return <PayOnlySplitByItem visibleItems={visibleItems} />;
                default:
                    return null;
            }
        },
        [visibleItems]
    );

    if (!splitOption) return null;

    return (
        <div className="pay-only-split-option animated-child">
            <div className={classNames("scroll-element", "animated-child")}>{renderRow(splitOption)}</div>
            {splitOption === "SelectItems" && (
                <div className="pay-only-split-option__refresh">
                    <RefreshButton
                        show={showRefreshButton}
                        text={`Tap to view ${newItemsCount} new item${simplePlural(newItemsCount)}`}
                        onClick={handleOnClickRefreshButton}
                    />
                </div>
            )}
            <footer>
                <div className="pay-only-split-option__footer">
                    <div className="pay-only-split-option__footer__message">
                        {refreshing ? (
                            <PulseLoader />
                        ) : (
                            <Text preset="g-14">
                                There will be {menuDataContext.formatCurrency(potentialRemainingBalance)} remaining on
                                the bill
                            </Text>
                        )}
                    </div>
                    <NetworkConnectedButton value={buttonLabel} disabled={!amount} onClick={onSuccess} />
                </div>
            </footer>
        </div>
    );
};
