import * as React from "react";
import { NativeBackButton } from "../../../common/navigation";
import { CartViewContainer } from "../container/CartViewContainer";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { CloseIcon } from "src/sharedComponents/assets/icons";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import "../assets/CartViewPreview.scss";
import { ModalContainer } from "src/common/modal";

export interface Props {
    close: () => void;
    isOpen: boolean;
}

export class CartViewPreview extends React.Component<Props> {
    scrollRef: HTMLDivElement | null;
    prevTop: number;
    startTop: number;
    isReadyForClosePos: boolean;

    constructor(props: Props) {
        super(props);
        this.scrollRef = null;
        this.prevTop = 0;
        this.startTop = 0;
        this.isReadyForClosePos = true;
    }

    register = () => {
        window.addEventListener("touchstart", this.touchstart);
        window.addEventListener("touchmove", this.touchmove);
        window.addEventListener("touchend", this.touchend);
    };

    unregister = () => {
        window.removeEventListener("touchstart", this.touchstart);
        window.removeEventListener("touchmove", this.touchmove);
        window.removeEventListener("touchend", this.touchend);
    };

    unregisterAndClose = () => {
        this.unregister();
        this.props.close();
    };

    touchstart = (e: TouchEvent) => {
        this.isReadyForClosePos = this.scrollRef ? this.scrollRef.scrollTop < 2 : true;
        this.startTop = e.touches[0]?.screenY || 0;
    };

    touchmove = (e: TouchEvent) => {
        this.prevTop = e.touches[0]?.screenY || 0;
    };

    touchend = (e: TouchEvent) => {
        if (this.isReadyForClosePos && this.startTop - this.prevTop < -20) {
            this.unregisterAndClose();
        }
    };

    componentWillUnmount() {
        this.unregister();
    }

    render() {
        const { isOpen } = this.props;

        return (
            <ModalContainer
                isOpen={isOpen}
                className={{
                    base: "cart-view-preview-modal",
                    afterOpen: "cart-view-preview-modal-after-open",
                    beforeClose: "cart-view-preview-modal-before-close",
                }}
                overlayClassName="cart-view-preview-modal--overlay"
                contentLabel="Cart View Modal"
                closeTimeoutMS={250}
                shouldCloseOnEsc
                onRequestClose={this.unregisterAndClose}
                onAfterClose={this.unregister}
                onAfterOpen={this.register}
                overlayRef={(r) => (this.scrollRef = r)}
                shouldCloseOnOverlayClick
                allowBodyScroll
            >
                <LocationThemeContainer>
                    <div className="cart-view-preview">
                        <NativeBackButton name="cart-view-preview" onPressed={this.unregisterAndClose}>
                            <TappableDiv className="close-circle" onTap={this.unregisterAndClose}>
                                <CloseIcon />
                            </TappableDiv>
                            <CartViewContainer />
                        </NativeBackButton>
                    </div>
                </LocationThemeContainer>
            </ModalContainer>
        );
    }
}
