import { createOperation } from "../operations";
import { ProductFees } from "./types";

export const forceSendReceiptOperation = createOperation<void>("PAYMENT/FORCE_SENDING_RECEIPT", { showSpinner: true });
export const submitPaymentAndOrderOperation = createOperation<void>("PAYMENT/SUBMIT_PAYMENT_AND_ORDER", {
    tracked: true,
    allowParallel: false,
});

export const getApplePayAvailableOperation = createOperation<boolean>("ACCOUNT/GET_APPLE_PAY_AVAILABLE");
export const authorizeApplePayPaymentOperation = createOperation<void>("PAYMENT/AUTHORIZE_APPLE_PAY_PAYMENT", {
    tracked: true,
    showSpinner: true,
});

export const getGooglePayAvailableOperation = createOperation<boolean>("ACCOUNT/GET_GOOGLE_PAY_AVAILABLE");
export const initializeGooglePayPaymentOperation = createOperation<void>("PAYMENT/INITIALIZE_GOOGLE_PAY_PAYMENT", {
    showSpinner: true,
});
export const initializeGooglePayPaymentBackgroundOperation = createOperation<void>(
    "PAYMENT/INITIALIZE_GOOGLE_PAY_PAYMENT_BACKGROUND"
);
export const initializeCustomGooglePayButtonOperation = createOperation<void>(
    "PAYMENT/INITIALIZE_CUSTOM_GOOGLE_PAY_BUTTON"
);
export const authorizeGooglePayPaymentOperation = createOperation<void>("PAYMENT/AUTHORIZE_GOOGLE_PAY_PAYMENT", {
    tracked: true,
    showSpinner: true,
    allowPause: false,
});

export const getPayPalAvailableOperation = createOperation<boolean>("ACCOUNT/GET_PAY_PAL_AVAILABLE");
export const initializePayPalPaymentOperation = createOperation<void>("PAYMENT/INITIALIZE_PAYPAL_PAYMENT", {
    showSpinner: true,
});
export const initializePayPalPaymentBackgroundOperation = createOperation<void>(
    "PAYMENT/INITIALIZE_PAYPAL_PAYMENT_BACKGROUND"
);
export const authorizePayPalPaymentOperation = createOperation<void>("PAYMENT/AUTHORIZE_PAYPAL_PAYMENT", {
    tracked: true,
    allowPause: false,
});

export const initializeCustomGooglePayButtonForVaultOperation = createOperation<string | null>(
    "PAYMENT/INITIALIZE_CUSTOM_GOOGLE_PAY_BUTTON"
);
export const vaultDevicePaymentMethodOperation = createOperation<string | null>("PAYMENT/VAULT_DEVICE_PAYMENT_METHOD", {
    showSpinner: true,
});

export const getPaymentMethodsOperation = createOperation<void>("PAYMENT/GET_PAYMENT_METHODS", { showSpinner: true });
export const getPaymentMethodsBackgroundOperation = createOperation<void>("PAYMENT/GET_PAYMENT_METHODS_BACKGROUND");
export const getPaymentSessionTokenOperation = createOperation<string>("PAYMENT/GET_PAYMENT_SESSION_TOKEN");
export const getPaymentSessionOperation = createOperation<Record<string, string>>("PAYMENT/GET_PAYMENT_SESSION");
export const getProductFeesOperation = createOperation<ProductFees>("PAYMENT/GET_PRODUCT_FEES");
export const getThreeDSecureVerificatonDataOperation = createOperation<any>("PAYMENT/GET_3DS_VERIFICATION_DATA");
export const updatePaymentAmountOperation = createOperation<boolean>("PAYMENT/UPDATE_PAYMENT_AMOUNT");
export const getAddCardAvailableOperation = createOperation<boolean>("ACCOUNT/GET_ADD_CARD_AVAILABLE");

export const applyPromoCodeOperation = createOperation<void>("PAYMENT/APPLY_PROMO_CODE", { tracked: true });
export const removePromoCodeOperation = createOperation<void>("PAYMENT/REMOVE_PROMO_CODE");
