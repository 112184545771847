import { Component } from "react";
import { connect } from "react-redux";
import { AppDispatch } from "src/features";
import { actionCreators } from "../reducers/preview";
import { SpinnerModal } from "src/features/spinnerModal/components/SpinnerModal";
import React from "react";

interface Props {
    initialize: () => void;
}

export class PreviewPage extends Component<Props> {
    constructor(props: Props) {
        super(props);
        props.initialize();
    }

    render() {
        return <SpinnerModal />;
    }
}

function mapDispatchToProps(dispatch: AppDispatch): Props {
    return {
        initialize: () => dispatch(actionCreators.initialize()),
    };
}

export const PreviewPageContainer = connect(undefined, mapDispatchToProps)(PreviewPage);
