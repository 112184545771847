import { IdentitySource } from "../../../common/sso";
import { FetchError } from "../../order/orderApi/FetchError";

interface SsoApiError {
    code: string;
    source: IdentitySource;
    maskedEmail?: string;
}

export class SsoError extends Error {
    public source: IdentitySource;
    public maskedEmail?: string;

    constructor(ssoApiError: SsoApiError) {
        super();
        this.source = ssoApiError.source;
        this.maskedEmail = ssoApiError.maskedEmail;
    }

    static async throwError(response: Response): Promise<void> {
        if (response.ok) {
            return;
        }

        try {
            const ssoApiError = (await response.json()) as SsoApiError;
            if (ssoApiError.code === "SSO") {
                throw new SsoError(ssoApiError);
            }
        } catch (err) {
            if (err instanceof SsoError) throw err;
        }

        throw new FetchError(response);
    }
}
