import * as React from "react";
import { ReactNode, useContext } from "react";
import { Text } from "../../../sharedComponents";
import classNames from "classnames";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import "../assets/PaymentItem.scss";

interface Props {
    title: string;
    value?: number;
    bold?: boolean;
    extraClass?: string;
    titleSuffix?: ReactNode;
    valueIncluded?: boolean;
    valuePrefix?: ReactNode;
    showZeroValue?: boolean;
}

export const PaymentItem = ({
    value,
    title,
    bold,
    extraClass,
    titleSuffix,
    valueIncluded,
    valuePrefix,
    showZeroValue,
}: Props) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    if (typeof value !== "number") return null;
    if (value === 0 && !showZeroValue) return null;
    const displayValue = menuDataLocale.formatCurrency(value);
    return (
        <Text
            key={`${title}-${displayValue}`}
            preset={bold ? "g-18" : "g-14"}
            mode={bold ? "bold" : undefined}
            className={classNames("payment-item", extraClass)}
        >
            <div className="payment-item__text">
                {title}
                {titleSuffix}
            </div>
            <div className="payment-item__value">
                {valuePrefix}
                {valueIncluded ? `(${displayValue})` : displayValue}
            </div>
        </Text>
    );
};
