import * as React from "react";
import { Price } from "./Price";
import { MenuItem, MenuItemVariant } from "src/features/menudata";
import { VariantLabel } from "./VariantLabel";
import { Text } from "src/sharedComponents";

export interface Props {
    item: MenuItem;
    showFromPrice?: boolean;
}

export const PriceList = ({ item, showFromPrice }: Props) => {
    if ((!item.variants || item.variants.length === 0) && item.availablePrices) {
        return (
            <Price
                priceTextPreset="g-14"
                priceTextMode="bold"
                availablePrices={item.availablePrices}
                showBasePrice
                basePriceTextPreset="g-14"
            />
        );
    }

    if (item.variants && item.variants.length > 0) {
        if (item.minVariantPrice && (showFromPrice || item.minVariantPrice.memberPrice !== undefined)) {
            return (
                <Text preset="g-14" mode="bold" className="price__from">
                    from&nbsp;
                    <Price
                        priceTextPreset="g-14"
                        priceTextMode="bold"
                        availablePrices={item.minVariantPrice}
                        showBasePrice
                        basePriceTextPreset="g-14"
                    />
                </Text>
            );
        }

        return (
            <ol className="pricelist" r-cmp="price">
                {item.variants.map((variant, index) => (
                    <li key={index} className="pricelist__item">
                        <VariantListItem variant={variant} />
                    </li>
                ))}
            </ol>
        );
    }

    return (
        <Text preset="g-14" mode="bold">
            <Price price={0} />
        </Text>
    );
};

export interface VariantListItemProps {
    variant: MenuItemVariant;
}

export const VariantListItem = ({ variant }: VariantListItemProps) => (
    <>
        <VariantLabel name={variant.displayName} />
        <Text preset="g-14" mode="bold">
            {variant.availablePrices ? <Price availablePrices={variant.availablePrices} /> : <Price price={0} />}
        </Text>
    </>
);
