// Vendor styles need to be imported first
// before any components that include their own styling
import "swiper/swiper.scss";
import "./styles/vendor/react-select.1.2.1.css";
import "react-swipe-to-delete-component/dist/swipe-to-delete.css";
import "react-loading-skeleton/dist/skeleton.css";
// Import Inconsolata Font from local
import "./sharedComponents/assets/fonts/inconsolata/InconsolataRegular.woff2";
import "./sharedComponents/assets/fonts/inconsolata/InconsolataRegular.woff";
import "./sharedComponents/assets/fonts/inconsolata/InconsolataRegular.ttf";
import "./sharedComponents/assets/fonts/inconsolata/InconsolataBold.woff2";
import "./sharedComponents/assets/fonts/inconsolata/InconsolataBold.woff";
import "./sharedComponents/assets/fonts/inconsolata/InconsolataBold.ttf";
import "./sharedComponents/assets/fonts/fonts.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App";
import { browserHistory } from "./browserHistory";
import { StandaloneRestrictions } from "./StandaloneRestrictions";
import { featureConfig, reconfigureReducers, store } from "./store";
import "./styles/index.scss";
import { RotatedPage } from "./features/unsupportedPage/components/RotatedPage";
import {
    browserSupport,
    deviceReady,
    incorrectDocumentHeightHandler,
    navigation,
    scaleHandler,
} from "src/common/experience";
import { isPreview } from "./features/preview";
import { ErrorBoundary } from "./features/errorBoundary/components/ErrorBoundary";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { UnsupportedPage } from "./features/unsupportedPage/components/UnsupportedPage";

require("intersection-observer");

const rootEl = document.getElementById("root") as HTMLElement;
const rotateEl = document.getElementById("rotate") as HTMLElement;

ReactModal.setAppElement(rootEl);

const render = (AppComponent: React.ComponentType) => {
    ReactDOM.render(
        <StandaloneRestrictions>
            <Provider store={store}>
                <ErrorBoundary store={store}>
                    <Router history={browserHistory}>
                        <AppComponent />
                    </Router>
                </ErrorBoundary>
            </Provider>
        </StandaloneRestrictions>,
        rootEl
    );
    ReactDOM.render(<RotatedPage />, rotateEl);
};

const renderApp = () => {
    if (browserSupport.isSupportedBrowser || isPreview) {
        render(App);
    } else {
        render(UnsupportedPage);
    }
};

export const errorHandlers = featureConfig.errorHandlers;

const callbackUrl = featureConfig.callbackUrls.find((u) => window.location.pathname.startsWith(u.url));
let context: Window | undefined;

if (callbackUrl && (context = callbackUrl.context === "window" ? window.opener : window.parent) !== undefined) {
    context.postMessage(
        {
            type: callbackUrl.messageType,
            url: window.location.href,
        },
        window.location.origin
    );

    if (callbackUrl.context === "window") {
        window.close();
    } else if (callbackUrl.idToRemove) {
        context.document.getElementById(callbackUrl.idToRemove)?.remove();
    }
} else {
    if (module.hot) {
        module.hot.accept("./App", () => {
            const nextApp = require("./App").default;
            render(nextApp);
        });

        module.hot.accept("./features", () => {
            reconfigureReducers();
        });
    }

    deviceReady().then(() => {
        renderApp();
        navigation.preventSwipeBack();
        scaleHandler.register();
        setTimeout(incorrectDocumentHeightHandler.register, 0);
    });
}
