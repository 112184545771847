import { AppDispatch, AppState } from "src/features";
import { leaveGroupTabApi } from "../API/leaveGroupTabApi";
import { leaveGroupTabOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { replace } from "connected-react-router";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { NavBarType } from "src/common/navigation/types";
import { getTabTypeName } from "../selectors/activeGroupTab";

export function leaveGroupTabAction() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const tabTypeName = getTabTypeName(getState());
        try {
            dispatch(
                showModalMessage(
                    modalMessages.leaveTab(() => {
                        dispatch(innerLeaveGroupTabAction());
                    }, tabTypeName)
                )
            );
        } catch (ex) {
            if (ex instanceof ProblemDetailsError) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("failed to leave group tab:", ex);
            }
        }
    };
}

export function innerLeaveGroupTabAction() {
    return leaveGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const tabTypeName = getTabTypeName(getState());
        try {
            const res = await leaveGroupTabApi();
            dispatch(replace("/menu/service"));
            dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            dispatch(activeTabActionCreators.setGroupTabData(res));
            dispatch(paymentActionCreators.clearPaymentMethods());
        } catch (ex) {
            if (ex instanceof ProblemDetailsError) {
                dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
            } else {
                console.log("inner failed to leave group tab:", ex);
            }
        }
    });
}
