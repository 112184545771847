import { LocationMenuData, Indexed, MenuItemWithId } from "src/features/menudata";
import { reduceVariantsByPackageProductVariants } from "src/features/menu/helpers";
import { GroupTabDebit, LimitedMenuPackage, LimitedMenuPackageDetails, TabType } from "..";

export function compareFn(debit1: GroupTabDebit, debit2: GroupTabDebit) {
    return Date.parse(debit2.dateCreated) - Date.parse(debit1.dateCreated);
}

export function limitedMenuDetails(
    menudata: LocationMenuData | null,
    packageDetails: LimitedMenuPackage | null | undefined
): [LimitedMenuPackage | null | undefined, Indexed<LimitedMenuPackageDetails> | null, string[]] {
    if (!packageDetails || !menudata) {
        return [packageDetails, null, []];
    }
    const categoryIds: string[] = [];
    const menuDetails = Object.keys(packageDetails.categories).reduce((menu, categoryId) => {
        const displayName = menudata.categories[categoryId]?.displayName;
        const packageMenuItemIds = packageDetails.categories[categoryId].menuItems;

        if (displayName && packageMenuItemIds.length) {
            const items: MenuItemWithId[] = packageMenuItemIds.map((itemId, index) => ({
                ...menudata.items[itemId],
                id: itemId,
                variants: reduceVariantsByPackageProductVariants(
                    menudata.items[itemId].variants,
                    packageDetails.categories[categoryId].variants?.[index]
                ),
            }));
            categoryIds.push(categoryId);
            menu[categoryId] = {
                displayName,
                menuItems: items,
            };
        }
        return menu;
    }, {} as Indexed<LimitedMenuPackageDetails>);

    return [packageDetails, menuDetails, categoryIds];
}

export const getTabTypeNameByLocale = (locale?: string | null): string => {
    if (!locale) {
        return "Group Tab";
    }

    return locale.toLowerCase().includes("us") ? "tab" : "Group Tab";
};

export const getTabTypeReadableName = (tabType?: TabType) =>
    tabType === TabType.PAYONORDER ? "PayOnOrder" : "PayOnCreate";
