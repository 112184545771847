import { createSelector } from "reselect";
import { Tag, TagGroup } from "../../menudata";
import { AvailableFilters } from "../types";
import { getServiceTagGroups } from "../../order/selectors";

export const getUserPreferenceFilters = createSelector(getServiceTagGroups, (tagGroups: TagGroup[]) =>
    getAvailableUserPreferenceFilters(tagGroups)
);

function getAvailableUserPreferenceFilters(tagGroups: TagGroup[]): AvailableFilters {
    const availableTags = tagGroups
        .filter((group) => group.isUserPreference)
        .reduce((map, group) => {
            map[group.id] = group.tags;
            return map;
        }, {} as { [tagGroupId: string]: Tag[] });

    const availableGroups = tagGroups.filter((g) => availableTags[g.id] && availableTags[g.id].length >= 2);

    return {
        tagGroups: availableGroups,
        tags: availableTags,
        price: undefined,
    };
}
