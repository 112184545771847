import { AppDispatch, AppState } from "src/features";
import { joinTable } from "../../partyOnboarding/actions/joinTable";
import { getJoiningTable } from "../../partyOnboarding/selectors";
import { actionCreators } from "../reducer";
import { replace } from "connected-react-router";
import { UrlType } from "../../../common/experience/interface";
import { joinPayOnlyParty } from "src/features/partyOnboarding/actions/joinPayOnlyParty";
import { rejoinPayOnlyParty } from "src/features/partyOnboarding/actions/rejoinPayOnlyParty";
import { deletePayOnlyToken, getPayOnlyToken } from "src/features/payOnly/persistence/payOnlyToken";
import { deleteTableToken } from "../../partyOnboarding/persistence/tableToken";
import { isPayOnlyPartyToken } from "../../partyOnboarding/util/isPayOnlyPartyToken";
import { LocationMarkerClaim } from "../../partyOnboarding/types";
import { getIsExternalPath } from "../../../common/experience/shared/url";

export function activateLocationMarkerDeepLink(url: string, urlType: UrlType, tableToken: string | null) {
    return function (dispatch: AppDispatch, getState: () => AppState) {
        const state = getState();
        const type = ClaimTypeMapping[urlType] || "qr";

        const locationClaim: LocationMarkerClaim = {
            type,
            token: url,
        };

        // The UI-interactive scan is in progress or we're already joining a table
        if (getJoiningTable(state)) {
            dispatch(actionCreators.loaded(url, type));
            return;
        }

        if (urlType === UrlType.PAY_ONLY) {
            activatePayOnlyMarker(dispatch, url, locationClaim, tableToken);
        } else {
            deletePayOnlyToken();
            dispatch(joinTable([locationClaim], tableToken ?? undefined));
            dispatch(replace("/join-table"));
        }

        const source = new URL(url).searchParams.get("s") ?? undefined;

        dispatch(actionCreators.loaded(url, type, source));
    };
}

function activatePayOnlyMarker(
    dispatch: AppDispatch,
    url: string,
    locationClaim: LocationMarkerClaim,
    tableToken: string | null
) {
    const parsedUrl = new URL(url);
    const params = parsedUrl.searchParams;

    if ((params.has("l") && params.has("o")) || getIsExternalPath(parsedUrl.pathname)) {
        // scanning a QR, try to join a party
        dispatch(joinPayOnlyParty([locationClaim], tableToken ?? undefined));
    } else if (tableToken && isPayOnlyPartyToken(tableToken)) {
        // try to rejoin a receipt table
        dispatch(rejoinPayOnlyParty(tableToken));
    } else {
        if (tableToken) {
            // If we have a tableToken it isn't a Pay Only one so delete it
            deleteTableToken("switch_to_pay");
        }
        const payOnlyToken = getPayOnlyToken();
        if (payOnlyToken) {
            dispatch(joinPayOnlyParty([{ token: payOnlyToken, type: "qr" }]));
        }
    }
    dispatch(replace("/pay-only"));
}

const ClaimTypeMapping = {
    [UrlType.NFC]: "nfc",
    [UrlType.JOIN_FLEX]: "party",
};
