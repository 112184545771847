import "./GroupTabsInformationPage.scss";

import React, { ReactNode, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeerIcon, MateConferenceIcon, MoneyBoxPigIcon, TabIcon } from "src/sharedComponents/assets/icons";
import { CallToActionHeader, Text } from "src/sharedComponents";
import { getIsTrayChargeEnabled, getLocationGroupTabsEnabled } from "../selectors";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { getGroupTabData, getIsGroupTabOwner, getTabTypeName } from "../selectors/activeGroupTab";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { closeGroupTabAction } from "../actions/closeGroupTabAction";
import { GroupTabStatus } from "../types";
import { startGroupTabWizardAction } from "../actions/startGroupTabWizardAction";
import { NavBarType } from "src/common/navigation/types";
import { capitaliseFirstLetter } from "src/common/formatter";
import { getIsSectionTakeaway } from "src/features/order/selectors";
import { getLocationName } from "src/features/menu/selectors";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { GroupTabScanQrCcode } from "src/features/groupTabs/components/GroupTabScanQrCode";

export const GroupTabsInformationPage = () => {
    const isGroupTabEnabled = useSelector(getLocationGroupTabsEnabled);
    const isTrayChargeEnabled = useSelector(getIsTrayChargeEnabled);
    const isOwner = useSelector(getIsGroupTabOwner);
    const groupTabData = useSelector(getGroupTabData);
    const tabTypeName = useSelector(getTabTypeName);
    const isTakeaway = useSelector(getIsSectionTakeaway);
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const venueName = useSelector(getLocationName);
    const dispatch = useDispatch();

    const onExistingGroupTab = useCallback(
        (beforeaction) => {
            if (isOwner && groupTabData?.status === GroupTabStatus.OPEN) {
                dispatch(
                    showModalMessage(
                        modalMessages.ownAnotherGroupTab(
                            menuDataLocale?.formatCurrency(groupTabData.limit - groupTabData.available) ||
                                (groupTabData.limit - groupTabData.available).toString(),
                            menuDataLocale?.formatCurrency(groupTabData.available) || groupTabData.available.toString(),
                            beforeaction,
                            () => dispatch(closeGroupTabAction()),
                            tabTypeName,
                            groupTabData.type,
                            `Close ${tabTypeName} & continue`
                        )
                    )
                );
                return true;
            } else {
                return false;
            }
        },
        [isOwner, groupTabData, menuDataLocale, dispatch, tabTypeName]
    );

    const showGroupTabWizard = useCallback(() => {
        if (!onExistingGroupTab("creating")) {
            dispatch(startGroupTabWizardAction());
        }
    }, [dispatch, onExistingGroupTab]);

    const goToMenu = useCallback(() => {
        dispatch(navBarActionCreators.activeType(NavBarType.MENU));
    }, [dispatch]);

    const getGroupTabNotEnabledText = useCallback(() => {
        const capitalisedTabTypeName = capitaliseFirstLetter(tabTypeName);
        if (isTakeaway) {
            return {
                title: `${capitalisedTabTypeName}s are not available for takeaway`,
                subtitle: `${venueName} does not support ${tabTypeName}s for takeaway orders.`,
            };
        }
        if (isTrayChargeEnabled) {
            return {
                title: `${capitalisedTabTypeName}s are not supported for this area`,
                subtitle: `${venueName} does not support ${tabTypeName}s at this time.`,
            };
        }
        return {
            title: `${capitalisedTabTypeName}s are not enabled at this venue`,
            subtitle: `${venueName} does not support ${tabTypeName}s at this time.`,
        };
    }, [isTakeaway, isTrayChargeEnabled, tabTypeName, venueName]);

    const { title, subtitle } = getGroupTabNotEnabledText();

    return (
        <div className="group-tab-information-page">
            <div className="group-tab-information-page__body">
                <Text preset="title-28" mode={["bold", "block"]} className="group-tab-information-page__header">
                    Order together, with {tabTypeName}s
                </Text>
                {isGroupTabEnabled ? (
                    <>
                        <GroupTabRow
                            icon={<MateConferenceIcon />}
                            title="Invite your friends"
                            description={`Share your link or ${tabTypeName} code to get started.`}
                        />
                        <GroupTabRow
                            icon={<MoneyBoxPigIcon />}
                            title="Set a limit"
                            description="Set a spending limit & top up as you go. Unused funds will be refunded."
                        />
                        <GroupTabRow
                            icon={<BeerIcon />}
                            title={`Select your ${tabTypeName} menu`}
                            description="Order from the entire menu or select a limited menu your friends can order from."
                        />
                    </>
                ) : (
                    <CallToActionHeader
                        icon={<TabIcon />}
                        title={title}
                        ctaText="View menu"
                        onClick={goToMenu}
                        subtitle={subtitle}
                    />
                )}
            </div>
            <footer>
                {isGroupTabEnabled && (
                    <>
                        <NetworkConnectedButton value={`Start a ${tabTypeName}`} onClick={showGroupTabWizard} />
                        <GroupTabScanQrCcode />
                    </>
                )}
            </footer>
        </div>
    );
};

interface GroupTabRowProps {
    icon: ReactNode;
    title: string;
    description: string;
}

const GroupTabRow = ({ icon, title, description }: GroupTabRowProps) => {
    return (
        <div className="group-tab-information-page__row">
            <div className="group-tab-information-page__row__icon">{icon}</div>
            <Text
                mode={["block", "medium"]}
                preset="g-16"
                className="group-tab-information-page__row__title"
                value={title}
            />
            <Text
                mode="block"
                preset="g-14"
                className="group-tab-information-page__row__description"
                value={description}
            />
        </div>
    );
};
