import { createOperation } from "src/features/operations";
import { RestaurantFlagsState } from "./reducers/restaurantFlags";

export const getRestaurantFlagsOperation = createOperation<RestaurantFlagsState>("PARTY/GET_RESTAURANT_FLAGS");

export const validateActiveOrderOperation = createOperation<void>("PARTY/VALIDATE_ACTIVE_ORDER", { showSpinner: true });

export const validateActiveOrderInBackgroundOperation = createOperation<void>(
    "PARTY/VALIDATE_ACTIVE_ORDER_IN_BACKGROUND",
    { showSpinner: true }
);

export const validateActiveOrderSilentlyOperation = createOperation<void>("PARTY/VALIDATE_ACTIVE_ORDER_SILENTLY");

export const confirmOrderOperation = createOperation<void>("PARTY/CONFIRM_ORDER", { showSpinner: true });

export const addOrUpdateOrderItemOperation = createOperation<void>("PARTY/ADD_OR_UPDATE_ORDER_ITEM");

export const setOrderMemberAcceptedOperation = createOperation<void>("PARTY/SET_ORDER_MEMBER_ACCEPTED");

export const patchOrderMetadataOperation = createOperation<void>("PARTY/PATCH_ORDER_METADATA");

export const toggleEngageEnabledForPartyOperation = createOperation<void>("PARTY/TOGGLE_ENGAGE_ENABLED_FOR_PARTY");
