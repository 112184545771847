import * as React from "react";
import { connect } from "react-redux";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { AppState } from "../..";
import { setPendingItem, CreatePendingItem } from "../../order/actions/setPendingItem";
import { OrderCourseItem } from "../../order/reducers/types";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { pagePositionActions } from "../../pagePosition/reducers";
import { scrolling } from "src/common/experience";

interface OwnProps {
    item?: OrderCourseItem;
    menuItemId: string;
    menuId?: string;
    categoryId?: string;
    className?: string;
    children: React.ReactNode;
    preselect?: boolean;
    onBeforeNavigate?: () => void;
    disabled?: boolean;
    disabledItem?: boolean;
    isFeatured?: boolean;
}

export interface MenuItemButtonProxyProps {
    showItem: () => void;
    className?: string;
    children: React.ReactNode;
    disabled?: boolean;
}

const MenuItemButtonProxy = ({ showItem, className, children, disabled }: MenuItemButtonProxyProps) => (
    <TappableDiv onTap={showItem} className={className} disabled={disabled}>
        {children}
    </TappableDiv>
);

function mapStateToProps(state: AppState, props: OwnProps): DataProps<MenuItemButtonProxyProps> {
    const { className, disabled, children } = props;

    return {
        className,
        disabled,
        children,
    };
}

function mapDispatchToProps(
    dispatch: any,
    { menuItemId, menuId, categoryId, preselect, item, disabledItem, isFeatured }: OwnProps
): FunctionProps<MenuItemButtonProxyProps> {
    const showItem = () => {
        dispatch(pagePositionActions.setMenuPagePosition(scrolling.scrollTop()));
        const pendinItemData: CreatePendingItem = {
            menuItemId,
            menuId,
            categoryId,
            preselect,
            orderItemId: null,
            modal: true,
            isFeatured: isFeatured,
        };
        if (item) {
            pendinItemData.modifiers = item.modifiers;
            pendinItemData.variant = item.variant;
            pendinItemData.notes = item.notes;
            pendinItemData.quantity = item.quantity;
            pendinItemData.orderItemId = disabledItem ? null : item.orderItemId;
        }
        dispatch(setPendingItem(pendinItemData));
    };
    return {
        showItem,
    };
}

export const MenuItemButton = connect(mapStateToProps, mapDispatchToProps)(MenuItemButtonProxy);
