import "../assets/MarketingOptInPage.scss";
import React, { useState } from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { SimpleNavHeader } from "src/common/navigation";
import { LegalModal } from "src/features/compliance/components/LegalModal";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { href } from "src/common/experience";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { MessageScreen } from "../../../sharedComponents/layouts/messageScreen/MessageScreen";

interface Props {
    onPageClose: () => void;
    onSubmit?: () => void;
    subscribeButtonText?: string;
    displayName: string;
    icon?: JSX.Element;
    title: string;
    description?: string[];
    privacyPolicyUrl: string;
}

export const MarketingOptInPage = ({
    onPageClose,
    onSubmit,
    subscribeButtonText,
    displayName,
    icon,
    title,
    description,
    privacyPolicyUrl,
}: Props) => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    return (
        <div className="opt-in-page">
            <div className={classNames("scroll-element", "animated-child")}>
                <SimpleNavHeader closeToBack onBack={onPageClose} withBorder />
                <MessageScreen icon={icon} title={title} description={description} />

                <footer className="opt-in-page__footer">
                    {showPrivacyPolicy && (
                        <LegalModal legalPage={"privacyPolicy"} goBack={() => setShowPrivacyPolicy(false)} />
                    )}
                    <NetworkConnectedButton value={subscribeButtonText} onClick={onSubmit} />
                    <Text preset="g-12" className="opt-in-page__privacy-policy-text" mode="block">
                        By signing up, you consent to receive {displayName} newsletters. View their
                        {"\u00A0"}
                        <TappableAnchor
                            className="account-details__privacy-policy__link"
                            {...href.openInNewWindowAttributes(privacyPolicyUrl)}
                        >
                            Privacy Policy
                        </TappableAnchor>
                        {"\u00A0"}
                        and me&u’s{"\u00A0"}
                        <TappableAnchor
                            shouldPreventDefault
                            onTap={() => {
                                setShowPrivacyPolicy(true);
                            }}
                            className="account-details__privacy-policy__link"
                        >
                            Privacy Policy.
                        </TappableAnchor>
                    </Text>
                </footer>
            </div>
        </div>
    );
};
