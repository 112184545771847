import { FeatureConfiguration } from "..";
import menuFiltersReducer from "./reducers/menuFilters";

export * from "./reducers";

export { MenuPageContainer } from "./containers/MenuPageContainer";
export { MenuCardContainer } from "./containers/MenuCardContainer";
export { MenuItemCardContainer } from "./containers/MenuItemCardContainer";

export { MenuCard } from "./components/MenuCard";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.menuFilters = menuFiltersReducer;
}
