import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe } from "@stripe/stripe-js";

let stripe: Stripe | null | undefined;
let lastApiKey: string | undefined;

export const getStripe = async (apiKey: string) => {

    if (!stripe || apiKey !== lastApiKey) {
        const stripePromise = loadStripe(apiKey);

        if ("debugFailStripeJs" in window && window["debugFailStripeJs"] === true) {
            simulateStripeLoadFailure();
        }

        stripe = await stripePromise;
        if (!stripe) {
            throw new Error("Stripe not loaded");
        }
        lastApiKey = apiKey;
    }
    return stripe;
};

// This is actually how Stripe's own unit tests simulate failure
// and triggers the same paths that we're trying to simulate
// https://github.com/stripe/stripe-js/blob/master/src/index.test.ts
function simulateStripeLoadFailure() {
    const injectedScript = document.querySelector(
        'script[src="https://js.stripe.com/v3"]'
    );

    if (!injectedScript) {
        console.warn('could not find Stripe.js script element');
        return;
    }

    injectedScript.dispatchEvent(new Event('error'));
}