import { AppDispatch } from "../..";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { ReviewOrderReason as OfferReviewOrderReason } from "../../offers";

const getReviewOrderMessage = (reasons?: string[]) => {
    if (reasons?.length === 1) {
        switch (reasons[0]) {
            case OfferReviewOrderReason.OfferChanged:
                return modalMessages.offersChanged();
        }
    }
    return modalMessages.reviewOrder();
};

export const showReviewOrderMessage = (reasons?: string[]) => {
    return (dispatch: AppDispatch) => {
        const message = getReviewOrderMessage(reasons);
        dispatch(showModalMessage(message));
    };
};
