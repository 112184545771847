import { SelectedFilters } from "../..";

export const isFiltered = (selectedFilters: SelectedFilters): boolean => {
    if (selectedFilters.price !== undefined) {
        return true;
    }

    for (let filter in selectedFilters.tags) {
        if (filter && selectedFilters.tags[filter].length > 0) {
            return true;
        }
    }
    return false;
};
