import { JoinCodeState } from "../types";
import { trackedEvent } from "../../../common/events/reduxEventTracking";

export enum TypeKeys {
    GROUP_TAB_SET_CODE = "GROUP_TAB/SET_CODE",
    GROUP_TAB_CREATE_TAB = "GROUP_TAB/CREATE_TAB",
    GROUP_TAB_SHOW_JOIN_MODAL = "GROUP_TAB/SHOW_JOIN_MODAL",
    GROUP_TAB_JOIN_GROUP_TAB_ERROR = "GROUP_TAB/JOIN_ERROR",
    GROUP_TAB_CLEAR_JOIN_GROUP_TAB_ERROR_STATE = "GROUP_TAB/CLEAR_JOIN_ERROR_STATE",
    GROUP_TAB_RESET_JOIN = "GROUP_TAB/RESET_JOIN",
    GROUP_TAB_JOIN = "PARTY/GROUP_TAB/JOIN",
}

export const actionCreators = {
    setJoinGroupTabCode: (joinCode?: string) => ({ type: TypeKeys.GROUP_TAB_SET_CODE, joinCode }),
    setJoinGroupTabError: (error: string) => ({ type: TypeKeys.GROUP_TAB_JOIN_GROUP_TAB_ERROR, error }),
    clearJoinGroupTabErrorState: () => ({ type: TypeKeys.GROUP_TAB_CLEAR_JOIN_GROUP_TAB_ERROR_STATE }),
    resetJoinGroupTab: () => ({ type: TypeKeys.GROUP_TAB_RESET_JOIN }),
    trackJoinGroupTab: (group_tab_id: string, joined_with_code: boolean) =>
        trackedEvent({ type: TypeKeys.GROUP_TAB_JOIN, group_tab_id, joined_with_code }),
};

export type SetJoinGroupTabCodeAction = { type: TypeKeys.GROUP_TAB_SET_CODE; joinCode?: string };
export type SetJoinGroupTabErrorAction = { type: TypeKeys.GROUP_TAB_JOIN_GROUP_TAB_ERROR; error: string };
export type ClearJoinGroupTabErrorStateAction = { type: TypeKeys.GROUP_TAB_CLEAR_JOIN_GROUP_TAB_ERROR_STATE };
export type ResetJoinGroupTabAction = { type: TypeKeys.GROUP_TAB_RESET_JOIN };

export type JoinGroupTabActions =
    | SetJoinGroupTabCodeAction
    | SetJoinGroupTabErrorAction
    | ClearJoinGroupTabErrorStateAction
    | ResetJoinGroupTabAction;

const initialState: JoinCodeState = {};

export function reducer(state: JoinCodeState = initialState, action: JoinGroupTabActions): JoinCodeState {
    if (action.type === TypeKeys.GROUP_TAB_SET_CODE) {
        const { joinCode } = action;
        return {
            ...state,
            joinCode,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_JOIN_GROUP_TAB_ERROR) {
        const { error } = action;
        return {
            ...state,
            error,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_CLEAR_JOIN_GROUP_TAB_ERROR_STATE) {
        return {
            ...state,
            error: undefined,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_RESET_JOIN) {
        return {};
    }

    return state;
}
