import { trackedEvent } from "src/common/events/reduxEventTracking";
import { OrderCampaigns } from "src/features/order/reducers/types";

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

export const campaignsActionCreators = {
    campaignImpression: ({ campaign_name, data }: CampaignImpression) =>
        trackedEvent({ type: "CAMPAIGN/IMPRESSION", campaign_name, ...data }),
    campaignClicked: ({ campaign_name, data }: CampaignImpression) =>
        trackedEvent({ type: "CAMPAIGN/CLICKED", campaign_name, ...data }),
    campaignNotDisplayed: ({ campaign_name, noDisplayedReason, data }: campaignNotDisplayed) =>
        trackedEvent({
            type: "CAMPAIGN/NOT_DISPLAYED",
            campaign_name,
            "campaign.not_displayed_reason": noDisplayedReason,
            ...data,
        }),
};

interface CampaignImpression {
    campaign_name: OrderCampaigns;
    data: CampaignData;
}

interface campaignNotDisplayed extends CampaignImpression {
    noDisplayedReason: CampaignNotDisplayedReason;
}

export enum CampaignNotDisplayedReason {
    NOT_ENOUGH_ITEMS = "not_enough_items",
}

type CampaignData = { current_page: string; [x: string]: string | undefined };
