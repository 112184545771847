import { RatingState } from "./types";

export interface State {
    ratings: RatingState;
}

enum TypeKeys {
    SET_RATING = "SURVEY/SET_RATING",
    SET_RATING_PARTY = "SURVEY/SET_RATING_PARTY",
    RESET_RATING = "SURVEY/RESET_RATING",
}

export const actionCreators = {
    setRating: (rating: number | null) => ({ type: TypeKeys.SET_RATING, rating }),
    resetRating: () => ({ type: TypeKeys.RESET_RATING }),
};

type SetRatingAction = { type: TypeKeys.SET_RATING; rating: number | null };
type ResetRatingAction = { type: TypeKeys.RESET_RATING };

export type RatingActions = SetRatingAction | ResetRatingAction;

const initialState: RatingState = {
    rating: null,
    partyId: null,
};

export function reducer(state: RatingState = initialState, action: RatingActions): RatingState {
    switch (action.type) {
        case TypeKeys.SET_RATING: {
            return {
                ...state,
                rating: action.rating,
            };
        }
        case TypeKeys.RESET_RATING: {
            return {
                ...state,
                partyId: null,
                rating: null,
            };
        }
        default: {
            return state;
        }
    }
}
