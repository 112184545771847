import { AppState } from "../..";
import { createSelector } from "reselect";

export const getPendingItem = (state: AppState) => state.pendingItem;

export const getPendingModifiers = (state: AppState) => state.pendingItem.pendingModifiers;

export const getPendingItemId = createSelector(getPendingItem, (pendingItem) => pendingItem.item?.itemId);

export const getIsItemModalOpen = createSelector(getPendingItem, (pendingItem) => !!pendingItem.item?.modal);
