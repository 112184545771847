import { AppDispatch, AppState } from "src/features/index";
import { manageMembershipsActions } from "src/features/membership/reducers";
import { AvailableMembershipState } from "src/features/membership/types/MembershipState";
import { getIsUnverified, getRequireSignIn } from "src/features/accounts/selectors";
import { getProfile } from "src/features/accountmenu/selectors";
import { signInAction } from "src/features/signup/actions/signInAction";
import { getSignupMembershipState } from "src/features/membership/selectors/getActiveMembershipState";
import { getConnectedMembershipState } from "src/features/membership/selectors/getConnectedMembershipState";
import { createSelector } from "reselect";

const getMembershipSignInComplete = createSelector(
    getSignupMembershipState,
    getConnectedMembershipState,
    (_: AppState, membershipState: AvailableMembershipState) => membershipState,
    (signupMembershipState, connectedMembershipState, membershipState) =>
        connectedMembershipState?.programId === membershipState.programId || !!signupMembershipState
);

export const setSignupProgramWithSignIn = (
    membershipState: AvailableMembershipState,
    onBeforeSetSignupProgram: () => void
) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const profile = getProfile(state);
        const requireSignIn = getRequireSignIn(state);
        const isUnverified = getIsUnverified(state);

        const {
            authentication: { requireMemberId, requireProfile, requireEmail, requireVerification },
        } = membershipState;

        if (
            ((requireMemberId || requireProfile) && requireSignIn) ||
            (requireEmail && !profile?.email) ||
            (requireVerification && isUnverified)
        ) {
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    requireVerification,
                    onComplete: () => {
                        const connectedMembershipState = getConnectedMembershipState(getState());
                        if (connectedMembershipState?.programId === membershipState.programId) return;
                        onBeforeSetSignupProgram();
                        dispatch(manageMembershipsActions.setSignupProgramId(membershipState.programId));
                    },
                    getSignInComplete: (state: AppState) => getMembershipSignInComplete(state, membershipState),
                    accountDetailsOptions: {
                        requireEmail,
                        hideEmailMessage: true,
                        subtitle: "",
                    },
                })
            );
        } else {
            onBeforeSetSignupProgram();
            dispatch(manageMembershipsActions.setSignupProgramId(membershipState.programId));
        }
    };
};
