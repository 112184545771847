import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { HistoryListItem } from "src/features/orderHistoryData";
import { GroupTabHistoryView } from "..";
import { fetchGroupTabHistoryList } from "../actions";
import {
    getGroupTabHistoriesHasMore,
    getGroupTabHistoriesLoading,
    getGroupTabHistoryForCards,
    getGroupTabHistoryLastServiceDate,
} from "../selectors/groupTabHistorySelector";
import { GroupTabHistoryTile } from "./GroupTabHistoryTile";
import { HistoryList } from "./HistoryList";
import { LIMIT_HISTORY_ITEMS } from "../constants";

export const GroupTabHistoryList = () => {
    const groupTabHistoryData = useSelector(getGroupTabHistoryForCards);
    const hasMore = useSelector(getGroupTabHistoriesHasMore);
    const loading = useSelector(getGroupTabHistoriesLoading);
    const lastServiceDate = useSelector(getGroupTabHistoryLastServiceDate);
    const dispatch = useDispatch();

    const loadMoreItems = useCallback(() => {
        if (loading !== "failure" && loading !== "processing") {
            dispatch(fetchGroupTabHistoryList(lastServiceDate, LIMIT_HISTORY_ITEMS));
        }
    }, [loading, lastServiceDate, dispatch]);

    return (
        <HistoryList
            name="group-tab-history-list"
            items={groupTabHistoryData}
            hasMore={hasMore}
            loadMoreItems={loadMoreItems}
            loading={loading === "processing"}
            showAnonymous={false}
            to={(groupTabId: string) =>
                generatePath("/account/group-tab-history/:groupTabId", {
                    groupTabId,
                })
            }
            historyCardTile={(item: HistoryListItem) => <GroupTabHistoryTile item={item as GroupTabHistoryView} />}
        />
    );
};
