import * as React from "react";
import { AppDispatch, AppState } from "src/features";
import { connect } from "react-redux";
import {
    getActiveCategory,
    getActiveServiceMenuItemIds,
    getMenuItemCourseAccessor,
    getMenuItemDietaryTags,
    getVisibleMenuData,
} from "../../menu/selectors";
import { actionCreators as partyAction, getParty, PendingItem } from "../../order";
import { actionCreators as pendingItemAction, PendingModifierState } from "../../order/reducers/pendingItem";
import { MenuItemPage, Props as MenuItemProps } from "../components/MenuItemPage";
import { getIsConnected } from "../../order/selectors";
import { RouteComponentProps, withRouter } from "react-router";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { backHandler, device, scrolling } from "src/common/experience";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { showModalMessage } from "../../modalMessage/actions/show";
import { getPriceResolver, PriceResolver, PriceResolverProps } from "../../membership/selectors/getPriceResolver";
import { getDietaryInformationUrl } from "src/features/order/selectors/restaurantFlags";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { OrderCampaigns } from "src/features/order/reducers/types";
import { actionCreators as availabilityActions, AvailabilityTrackingAction } from "../../menu/reducers/availability";
import { addOrUpdateOrderItem } from "../../order/actions/addOrUpdateOrderItem";
import { ModalContainer } from "src/common/modal";
import { getShowNoNetworkConnection } from "src/features/notifications/selectors";
import { AddToOrderFunc } from "../types";
import { Indexed } from "src/features/menudata";
import { getPendingModifiers } from "src/features/order/selectors/pendingItem";

let menuItem: MenuItemProps;

interface MenuItemModalProxyProps {
    menuItem?: MenuItemProps;
    dietaryInformationUrl?: string;
    setPendingItem: (pendingItem: PendingItem) => void;
    resetPendingItem: () => void;
    removeOrderItem?: (orderItemId: string, menuItemId: string) => void;
    addToOrder: AddToOrderFunc;
    close: () => void;
    currentMemberInParty: boolean;
    connected: boolean;
    showModalMessage: (modalMessage: ModalMessage) => void;
    menuPagePosition: number;
    isOpen: boolean;
    priceResolver: PriceResolver;
    trackAvailability: (trackingData: AvailabilityTrackingAction) => void;
    editNestedModifier?: (pendingModifier: PendingModifierState) => void;
    resetNestedModifiers: () => void;
    showNetworkNotification?: boolean;
    trackDisabledButtonClicked: () => void;
    trackAllergenNoticeClick: () => void;
    activeServiceMenuItemIds: Indexed<boolean>;
}

interface State {
    itemAdded: boolean;
    itemRemoved: boolean;
    isOpen: boolean;
}

class MenuItemModalProxy extends React.Component<MenuItemModalProxyProps, State> {
    constructor(props: MenuItemModalProxyProps) {
        super(props);
        this.state = {
            isOpen: false,
            itemAdded: false,
            itemRemoved: false,
        };
    }

    onAddToOrder: AddToOrderFunc = (
        courseId,
        categoryId,
        menuItemId,
        notes,
        orderItemId,
        variant,
        quantity,
        modifiers,
        isFeatured,
        source,
        isVideo
    ) => {
        // close the modal with the Item Added animation
        this.setState(
            { itemAdded: !orderItemId, itemRemoved: false },
            () =>
                this.props.addToOrder &&
                this.props.addToOrder(
                    courseId,
                    categoryId,
                    menuItemId,
                    notes,
                    orderItemId,
                    variant,
                    quantity,
                    modifiers,
                    isFeatured,
                    source,
                    isVideo
                )
        );
    };

    onRemoveOrderItem = async (orderItemId: string, menuItemId: string) => {
        // close the modal up
        this.setState(
            { itemAdded: false, itemRemoved: true },
            () => this.props.removeOrderItem && this.props.removeOrderItem(orderItemId, menuItemId)
        );
    };

    onClose = async () => {
        if (!this.state.itemAdded && !this.state.itemRemoved) {
            this.setState({ itemAdded: false, itemRemoved: false }, () => this.props.close && this.props.close());
        }

        if (device.isIOS15orHigher) {
            // We need to trigger the scroll position restoration earlier for iOS 15 Safari
            scrolling.scrollTo({ top: this.props.menuPagePosition });
        }
    };

    cleanState = () => {
        this.setState({ itemAdded: false, itemRemoved: false }, () => {
            if (!device.isIOS15orHigher) {
                scrolling.scrollTo({ top: this.props.menuPagePosition });
            }
        });
    };

    onOpen = () => {
        document.querySelector(".party-wrapper")?.scrollTo({ top: this.props.menuPagePosition });
    };

    render() {
        const {
            isOpen,
            menuItem,
            dietaryInformationUrl,
            setPendingItem,
            resetPendingItem,
            currentMemberInParty,
            connected,
            showModalMessage,
            priceResolver,
            trackAvailability,
            editNestedModifier,
            resetNestedModifiers,
            trackDisabledButtonClicked,
            trackAllergenNoticeClick,
            activeServiceMenuItemIds,
        } = this.props;

        return (
            <ModalContainer
                isOpen={isOpen}
                className={{
                    base: "menu-item-modal",
                    afterOpen: "menu-item-modal--after-open ",
                    beforeClose: "menu-item-modal--before-close",
                }}
                overlayClassName={`menu-item-modal--overlay${
                    this.state.itemAdded ? " item-added" : this.state.itemRemoved ? " item-removed" : ""
                }`}
                contentLabel="Menu Item Modal"
                portalClassName="MenuItemModalPortal ReactModalPortal"
                onAfterClose={this.cleanState}
                onAfterOpen={this.onOpen}
                shouldCloseOnEsc
                closeTimeoutMS={350}
                allowBodyScroll
            >
                {!!menuItem && (
                    <MenuItemPage
                        courseId={menuItem.courseId}
                        menuItemId={menuItem.menuItemId}
                        menuItem={menuItem.menuItem}
                        dietaryTags={menuItem.dietaryTags}
                        dietaryInformationUrl={dietaryInformationUrl}
                        backText={null}
                        relatedItems={menuItem.relatedItems}
                        triedToSubmit={menuItem.triedToSubmit}
                        pendingItem={menuItem.pendingItem}
                        isEditingNestedModifier={menuItem.isEditingNestedModifier}
                        allowNotesToKitchen={menuItem.allowNotesToKitchen}
                        hasParty={menuItem.hasParty}
                        easyReorderItems={menuItem.easyReorderItems}
                        hasPreselectOptions={menuItem.hasPreselectOptions}
                        setPendingItem={setPendingItem}
                        resetPendingItem={resetPendingItem}
                        addToOrder={this.onAddToOrder}
                        goBackButton={this.onClose}
                        removeOrderItem={this.onRemoveOrderItem}
                        currentMemberInParty={currentMemberInParty}
                        connected={connected}
                        showModalMessage={showModalMessage}
                        priceResolver={priceResolver}
                        trackAvailability={trackAvailability}
                        editNestedModifier={editNestedModifier}
                        resetNestedModifiers={resetNestedModifiers}
                        globalModifiers={menuItem.globalModifiers}
                        trackDisabledButtonClicked={trackDisabledButtonClicked}
                        trackAllergenNoticeClick={trackAllergenNoticeClick}
                        activeServiceMenuItemIds={activeServiceMenuItemIds}
                    />
                )}
            </ModalContainer>
        );
    }
}

type RouteParams = { categoryId: string };

export function mapStateToProps(
    state: AppState,
    props: RouteComponentProps<RouteParams>
): DataProps<MenuItemModalProxyProps> & PriceResolverProps {
    const {
        pendingItem: { item },
        pagePosition: { menuPagePosition },
    } = state;
    const party = getParty(state);
    const userId = getCurrentMemberId(state);
    const currentMemberInParty = !!userId && !!party && party.members.some((m) => m.memberId === userId);
    const connected = getIsConnected(state);
    const priceResolver = getPriceResolver(state);
    const showNetworkNotification = getShowNoNetworkConnection(state);
    const activeServiceMenuItemIds = getActiveServiceMenuItemIds(state);
    const isEditingNestedModifier = !!getPendingModifiers(state).length;

    if (!state.menuData.data || !item || !item.modal) {
        return {
            isOpen: false,
            menuItem,
            currentMemberInParty,
            connected,
            menuPagePosition,
            priceResolver,
            showNetworkNotification,
            activeServiceMenuItemIds,
        };
    }

    const menuItemId = item.itemId;
    const courseId = getMenuItemCourseAccessor(state, props.match.params)(menuItemId);

    //If there is no courseId we shouldn't display the item, this could be because the user is trying to add the item from the bill view after service changed
    if (!courseId) {
        return {
            isOpen: false,
            menuItem,
            currentMemberInParty,
            connected,
            menuPagePosition,
            priceResolver,
            showNetworkNotification,
            activeServiceMenuItemIds,
        };
    }

    const hasParty = party !== null;

    const menuData = getVisibleMenuData(state)!;
    const menuItemData = menuData.items[menuItemId];

    const dietaryTags = getMenuItemDietaryTags(menuItemData, state, undefined);

    const category = getActiveCategory(state, item.categoryId)!;
    const dietaryInformationUrl = getDietaryInformationUrl(state);

    const relatedItems =
        menuItemData.relatedItems && menuItemData.relatedItems.length
            ? menuItemData.relatedItems
            : category && category.relatedItems
            ? category.relatedItems
            : [];

    const hasPreselectOptions = item.preselect && !item.orderItemId;
    item.preselect = false;

    const triedToSubmit = item ? item.triedToSubmit : false;
    const allowNotesToKitchen = state.restaurantFlags && state.restaurantFlags.allowNotesToKitchen;
    const easyReorderItems = !item.orderItemId ? state.easyReorderItems : [];

    menuItem = {
        courseId,
        menuItemId,
        menuItem: menuItemData,
        globalModifiers: menuData.modifiers,
        dietaryTags,
        relatedItems,
        triedToSubmit,
        pendingItem: item,
        isEditingNestedModifier,
        allowNotesToKitchen,
        hasParty,
        easyReorderItems,
        hasPreselectOptions,
    } as MenuItemProps;

    return {
        isOpen: true,
        menuItem,
        dietaryInformationUrl,
        currentMemberInParty,
        connected,
        menuPagePosition,
        priceResolver,
        showNetworkNotification,
        activeServiceMenuItemIds,
    };
}

export function mapDispatchToProps(
    dispatch: AppDispatch
): FunctionProps<Exclude<MenuItemModalProxyProps, PriceResolverProps>> {
    const closeButton = () => dispatch(pendingItemAction.resetPendingItem());
    const addToOrder: AddToOrderFunc = (
        courseId,
        categoryId,
        menuItemId,
        notes,
        orderItemId,
        variant,
        quantity,
        modifiers,
        isFeatured,
        source,
        isVideo
    ) => {
        dispatch(
            addOrUpdateOrderItem(
                courseId,
                categoryId,
                menuItemId,
                notes,
                orderItemId,
                variant,
                modifiers,
                quantity,
                isFeatured ? OrderCampaigns.FEATURED_ITEM : undefined,
                source,
                isVideo
            )
        );
        backHandler.handleBack(closeButton);
    };
    const removeOrderItem = (orderItemId: string, menuItemId: string) => {
        dispatch(partyAction.removeFromOrder(orderItemId, menuItemId));
        backHandler.handleBack(closeButton);
    };
    return {
        close: closeButton,
        setPendingItem: (pendingItem: PendingItem) => dispatch(pendingItemAction.setPendingItem(pendingItem)),
        resetPendingItem: () => dispatch(pendingItemAction.resetPendingItem()),
        removeOrderItem,
        addToOrder,
        showModalMessage: (modalMessage: ModalMessage) => dispatch(showModalMessage(modalMessage)),
        trackAvailability: (availabilityData: AvailabilityTrackingAction) =>
            dispatch(availabilityActions.trackAvailability(JSON.stringify(availabilityData))),
        editNestedModifier: (pendingModifier: PendingModifierState) =>
            dispatch(pendingItemAction.editNestedModifier(pendingModifier)),
        resetNestedModifiers: () => dispatch(pendingItemAction.resetPendingModifiers()),
        trackDisabledButtonClicked: () => dispatch(partyAction.trackDisabledButtonClicked("item")),
        trackAllergenNoticeClick: () => dispatch(partyAction.trackAllergenNoticeClicked("product")),
    };
}

export const MenuItemModalContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuItemModalProxy)
) as any;
