import { AppState } from "../../index";
import { createSelector } from "reselect";
import { getAnyParty } from "../../order/selectors";
import { PartyType } from "../../order";
import { getIsOpenTable } from "src/features/openTable/selectors";

export const getPayOnlyState = (state: AppState) => state.payOnly;

export const getPayOnlyFlagsState = (state: AppState) => state.payOnlyFlags;

export const getPayOnlyRefreshing = createSelector(
    getPayOnlyFlagsState,
    ({ refreshing, displayPendingPayments }) => refreshing || displayPendingPayments
);

export const getPayOnlyGetReceiptState = (state: AppState) => state.payOnlyGetReceipt;

export const getIsPayOnly = createSelector(getAnyParty, (party) => party?.type === PartyType.PAYONLY);

export const getHasPaymentOrder = createSelector(
    getIsPayOnly,
    getIsOpenTable,
    (isPayOnly, isOpenTable) => isPayOnly || isOpenTable
);
