import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../..";
import { LocationMenuData } from "src/features/menudata";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { getMenuData } from "src/features/menu/selectors";
import { getMenuDataLocale } from "src/features/menudata/selectors/getMenuDataLocale";

export interface OwnProps {
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

interface Props {
    menuData: LocationMenuData | null;
    locale: MenuDataLocale | null;
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

export const RequireMenuData = ({ menuData, locale, success, fallback }: Props) => {
    if (!locale || !menuData) {
        return fallback ? fallback() : null;
    }

    return <MenuDataLocaleContext.Provider value={locale}>{success()}</MenuDataLocaleContext.Provider>;
};

function mapStateToProps(state: AppState, props: OwnProps): Props {
    const menuData = getMenuData(state);
    const locale = getMenuDataLocale(state);

    return {
        menuData,
        locale,
        ...props,
    };
}

export const RequireMenuDataContainer = connect(mapStateToProps)(RequireMenuData);
