import "./assets/SimpleNavHeader.scss";

import React from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { BackButton } from "src/common/navigation";
import { SelectedFilters } from "../../../features/filter";
import { Filter } from "../../../features/filter/components/Filter";
import { HeaderTabs, HeaderTabsProps } from "./HeaderTabs";

export interface SimpleNavProps {
    onBack?: () => void;
    closeToBack?: boolean;
    customBack?: string;
    title?: string | null;
}

export interface SimpleNavHeaderProps extends SimpleNavProps {
    hasBackButton?: boolean;
    hasBackground?: boolean;
    openAccountMenu?: () => void;
    reset?: () => void;
    handleFilter?: () => void;
    backgroundTransparent?: boolean;
    selectedFilters?: SelectedFilters;
    canOpenFilter?: boolean;
    rightElement?: React.ReactNode;
    withBorder?: boolean;
    circleClose?: boolean;
    headerTabs?: HeaderTabsProps;
    disabled?: boolean;
}

export class SimpleNavHeader extends React.Component<SimpleNavHeaderProps> {
    static defaultProps = {
        hasBackButton: true,
    };

    render() {
        const {
            title,
            headerTabs,
            hasBackButton,
            hasBackground,
            onBack,
            reset,
            handleFilter,
            backgroundTransparent,
            selectedFilters,
            canOpenFilter,
            closeToBack,
            customBack,
            rightElement,
            withBorder,
            circleClose,
            disabled,
        } = this.props;

        const simpleNavHeaderClasses = classNames("simpleNavHeader__wrapper", "simpleNavHeader__height", {
            "no-background": hasBackground === false,
            "has-back-button": hasBackButton,
            "with-border": withBorder,
            "with-right": selectedFilters || !!rightElement,
            "simpleNavHeader__wrapper--transparent": backgroundTransparent,
            "circle-close": circleClose,
        });

        const hasNoRightIcons = !selectedFilters && !rightElement;

        return (
            <>
                <div className="simpleNavHeader__height" />
                <div className={simpleNavHeaderClasses}>
                    <div
                        className={classNames(
                            "simpleNavHeader__wrapper__left",
                            { "simpleNavHeader__wrapper__left--shifted": hasNoRightIcons },
                            !hasBackButton && "simpleNavHeader__wrapper__left--space"
                        )}
                    >
                        {hasBackButton && (
                            <BackButton
                                showAsClose={closeToBack}
                                customBack={customBack}
                                reset={reset}
                                onBack={onBack}
                                disabled={disabled}
                            />
                        )}
                        {title ? (
                            <Text preset="g-16" mode="bold" className="simpleNavHeader__title" value={title} />
                        ) : (
                            headerTabs && <HeaderTabs {...headerTabs} />
                        )}
                    </div>
                    <div
                        className={classNames("simpleNavHeader__wrapper__right", {
                            "simpleNavHeader__wrapper__right--shifted": hasNoRightIcons,
                        })}
                    >
                        {selectedFilters && (
                            <Filter
                                onClick={handleFilter}
                                selectedFilters={selectedFilters}
                                canOpenFilter={canOpenFilter}
                            />
                        )}
                        {rightElement}
                    </div>
                </div>
            </>
        );
    }
}
