import React from "react";
import { Text } from "../text";
import { Radio } from "../radio";

import "./radioListItem.scss";

export interface RadioListItemProps {
    name: string;
    title: string;
    description?: string;
    disabled: boolean;
    checked: boolean;
    value: any;
}

interface Props extends RadioListItemProps {
    onChange: (value: any) => void;
}

export const RadioListItem = ({ name, title, description, disabled, checked, onChange, value }: Props) => (
    <li className="radio-list-item">
        <Radio disabled={disabled} name={name} onChange={() => onChange(value)} checked={checked}>
            <div className="radio-list-item__option__titles">
                <Text preset="g-16" mode="bold" value={title} />
                {description && (
                    <Text
                        preset="g-14"
                        mode="block"
                        value={description}
                        className="radio-list-item__option__titles__description"
                    />
                )}
            </div>
        </Radio>
    </li>
);
