import { AppDispatch, AppState } from "src/features";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { orderApi } from "src/features/order/orderApi";
import { getActiveLocationId, getParty } from "src/features/order/selectors";
import { fetchActions } from "..";
import { getMembershipState } from "../api/getMembershipStates";
import { removeMembership } from "../api/removeMembership";
import { removeMembershipOperation } from "../operations";
import { actionCreators as loginActions } from "../reducers/login";
import { getCurrentMembershipProgramId } from "../selectors/getConnectedMembershipState";

export function removeProgram(programId: string) {
    return removeMembershipOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const locationId = getActiveLocationId(state);

        if (!locationId) {
            return;
        }

        try {
            const partyId = getParty(state)?.id;
            const memberProgramId = getCurrentMembershipProgramId(state);

            await removeMembership(programId);
            if (partyId && memberProgramId === programId) {
                await orderApi.invoke("clearActiveMembershipProgram");
            }
            const results = await getMembershipState(locationId);
            dispatch(fetchActions.loaded(results));
            dispatch(loginActions.trackMembershipRemoved(programId));
        } catch (e) {
            dispatch(showModalMessage(modalMessages.removeMembershipFailed()));
            throw e; // For app insights
        }
    });
}
