import { Indexed, MenuItem, MenuItemModifier, MenuItemVariant, ModifierOption } from "../menudata";
import {
    OrderItemNestedModifier,
    OrderItemOptionNestedModifier,
    OrderItemSelectedNestedModiferData,
    PendingItem,
} from "../order";
import { getSelectedModifierChoice } from "../menu/helpers";

export const canAddPendingItemToCart = (
    menuItem: MenuItem,
    pendingItem: PendingItem,
    globalModifiers?: Indexed<MenuItemModifier>
) => {
    const { variants, modifiers } = menuItem;
    const { variant, modifiers: selectedModifiers, notes } = pendingItem;

    const hasValidVariant = !variants || variants.length === 0 || variant !== null;
    const selectedVariant: MenuItemVariant | undefined = getSelectedModifierChoice(variants, variant);

    const hasValidRequiredModifiers =
        !modifiers ||
        modifiers.every((m, i) => {
            // If the current modifier is NOT in the select variants modifiers list, return true (should not disable canSubmit)
            const isNotSelectedVariantModifier =
                variant !== null && selectedVariant?.modifiers && selectedVariant.modifiers!.indexOf(i) < 0;

            if (isNotSelectedVariantModifier) return true;

            const hasValidRequiredNestedModifiers =
                selectedModifiers?.every((m) =>
                    hasValidRequiredNestedModifiersRecursive(m.optionNestedModifiers, globalModifiers)
                ) ?? true;

            const isOptionalModifier = !m.minSelection;

            const hasSatisfiedModifierMinSelection =
                !!selectedModifiers &&
                selectedModifiers.some((sm) => sm.modifier === i && sm.options.length >= m.minSelection);

            return (
                hasValidRequiredNestedModifiers &&
                (isOptionalModifier || isNotSelectedVariantModifier || hasSatisfiedModifierMinSelection)
            );
        });

    return hasValidVariant && hasValidRequiredModifiers && (!notes || notes.length <= 20);
};

const hasValidRequiredNestedModifiersRecursive = (
    optionNestedModifiers?: OrderItemOptionNestedModifier[],
    globalModifiers?: Indexed<MenuItemModifier>
): boolean =>
    optionNestedModifiers?.every((optionNestedModifier) => {
        if (globalModifiers) {
            const hasRequiredModifierWithNoSelectedData = getRequiredModifierHasNoSelectedData(
                optionNestedModifier.modifiers,
                globalModifiers,
                optionNestedModifier.selectedNestedData
            );
            if (hasRequiredModifierWithNoSelectedData) return false; // required nested modifier is not valid
        }

        // If all required modifiers have selected data, validate the selected nested data
        // Get the number of options required to be selected per required nested modifier
        // (must select at least the modifier.minSelection if present)
        const nestedIdQuantity: Record<string, number> = optionNestedModifier.selectedNestedData.reduce(
            (modifierMinSelectionMap, nestedData) => {
                if (nestedData.modifierId && globalModifiers) {
                    const globalModifier = globalModifiers[nestedData.modifierId];

                    if (globalModifier.minSelection) {
                        modifierMinSelectionMap[nestedData.modifierId] = globalModifier.minSelection;
                    }
                }
                return modifierMinSelectionMap;
            },
            {} as Record<string, number>
        );

        // For each selected data, subtract 1 from the relevant selected modifier
        optionNestedModifier.selectedNestedData.forEach((nestedData) => {
            if (nestedData.modifierId && nestedData.displayName) {
                nestedIdQuantity[nestedData.modifierId] = nestedIdQuantity[nestedData.modifierId] - 1;
            }
        });

        // Validate that none of the modifiers require more selections (val > 0).
        // When the value is <= 0, this means the user has selected exactly or more than the minSelection which is fine)
        if (Object.values(nestedIdQuantity).some((val) => val > 0)) return false;

        return optionNestedModifier.modifiers.every((nestedModifier) =>
            hasValidRequiredNestedModifiersRecursive(nestedModifier.optionNestedModifiers, globalModifiers)
        );
    }) ?? true;

// If a REQUIRED modifier has no selected data, the modifier cannot be valid.
const getRequiredModifierHasNoSelectedData = (
    modifiers: OrderItemNestedModifier[],
    globalModifiers: Indexed<MenuItemModifier>,
    selectedNestedData: OrderItemSelectedNestedModiferData[]
) => {
    const modifierIdsWithSelectedData = selectedNestedData.map((selectedData) => selectedData.modifierId);
    return modifiers.some((m) => {
        const globalModifier = globalModifiers[m.modifierId];
        const hasSelectedData = modifierIdsWithSelectedData.includes(m.modifierId);
        return !!globalModifier.minSelection && !hasSelectedData;
    });
};

export const getSelectedNestedSummary = (nestedOptions: OrderItemSelectedNestedModiferData[]) => {
    const { totalRequired, totalAvailable } = nestedOptions.reduce(
        (required, nested: OrderItemSelectedNestedModiferData) => {
            if (!nested.displayName) {
                if (nested.required) {
                    required.totalRequired++;
                } else {
                    required.totalAvailable++;
                }
            }
            return required;
        },
        { totalRequired: 0, totalAvailable: 0 }
    );

    const hasSelection = nestedOptions.some((nested) => nested.displayName);

    return {
        hasSelection,
        totalRequired,
        totalAvailable,
    };
};

/**
 * Rules for when we would and would not auto scroll to the next available modifier
 * 1. Modifier has an option with a required nested modifier
 *    Only scroll past the modifier when ALL required nested modifiers are satisfied
 *
 * 2. All modifier options either have no nested modifiers or they are optional
 *    Only scroll past modifier when at least ONE of the optional nested modifiers has selected data
 *
 * @param optionNestedModifiers = selected modifier's option (that has just been updated)
 */
export const getCanScrollPastOptionWithNestedModifiers = (optionNestedModifiers?: OrderItemOptionNestedModifier[]) => {
    if (!optionNestedModifiers?.length) return true;

    return optionNestedModifiers.some((mod) => {
        const hasRequiredNestedMod = mod.selectedNestedData.some((nested) => nested.required);
        const { totalRequired, hasSelection } = getSelectedNestedSummary(mod.selectedNestedData);

        if (hasRequiredNestedMod) {
            // IF has at least 1 REQUIRED mod, only scroll when ALL nested mods have been satisfied
            return totalRequired === 0 && hasSelection;
        } else {
            // IF all optional mods, only scroll when at least 1 modifier has AT LEAST 1 option selected
            return hasSelection;
        }
    });
};

export const getOptionItemReferenceNotInActiveService = (
    option: ModifierOption,
    activeServiceMenuItemIds: Indexed<boolean>
) => {
    const itemReference = option.itemReference?.itemId;

    if (!itemReference) {
        return false;
    }

    return !activeServiceMenuItemIds[itemReference];
};
