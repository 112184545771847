import { connect } from "react-redux";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { AppDispatch, AppState } from "../..";
import { MenuSearchButton, Props } from "../components/MenuSearchButton";
import { menuSearchActions } from "../reducers";

interface OwnProps {
    className?: string;
    innerClassName?: string;
    beforeOpen?: () => void;
}

export function mapStateToProps(state: AppState, { className, innerClassName }: OwnProps): DataProps<Props> {
    return {
        className,
        innerClassName,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch, { beforeOpen }: OwnProps): FunctionProps<Props> {
    return {
        beforeOpen,
        showMenuSearch: () => dispatch(menuSearchActions.showMenuSearchPage(true)),
    };
}

export const MenuSearchButtonContainer = connect(mapStateToProps, mapDispatchToProps)(MenuSearchButton);
