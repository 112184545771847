import { FeatureConfiguration } from "../index";
import { getABTestsOperation } from "./operations";
import { abTestsMiddleware } from "./middleware/abTestsMiddleware";

export default function (config: FeatureConfiguration) {
    config.reducers.abTests = getABTestsOperation.reducer;
    config.middleware.push(abTestsMiddleware());
}

export interface State {
    abTests: string[] | null;
}

export enum TypeKeys {
    AB_TESTS_ACTIVE = "AB_TESTS/ACTIVE",
}

type ABTestsActiveAction = { type: TypeKeys.AB_TESTS_ACTIVE };

export type ABTestsAction = ABTestsActiveAction;
