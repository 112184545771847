import { PayPalCheckout, paypalCheckout } from "braintree-web";
import { getBraintreeClientInstance } from "../../../common/braintree";
import {
    CreatePayPalOrderFunc,
    GetPayPalAuthorizePaymentResultFunc,
    initializePayPalButton,
} from "../common/initializePayPalButton";
import { InitializePayPalButtonFunc } from "../types";

interface PayPalCheckoutInstanceData {
    payPalCheckoutInstance: PayPalCheckout;
    deviceData: string;
}

export const initializeBraintreePayPalButton: InitializePayPalButtonFunc = async (
    payPalCheckoutPaymentMethod,
    getTotal,
    getPaymentSessionToken,
    _,
    signal
) => {
    let payPalCheckoutInstanceData: PayPalCheckoutInstanceData | undefined;

    const getPayPalCheckoutInstanceData = async () => {
        if (!payPalCheckoutInstanceData) {
            const clientToken = await getPaymentSessionToken();
            const { clientInstance, deviceData } = await getBraintreeClientInstance(clientToken);
            const payPalCheckoutInstance = await paypalCheckout.create({
                client: clientInstance,
            });

            payPalCheckoutInstanceData = {
                payPalCheckoutInstance,
                deviceData,
            };
        }
        return payPalCheckoutInstanceData;
    };

    const createOrder: CreatePayPalOrderFunc = async () => {
        const { payPalCheckoutInstance } = await getPayPalCheckoutInstanceData();
        return await payPalCheckoutInstance.createPayment({
            flow: "checkout",
            amount: getTotal(),
            currency: payPalCheckoutPaymentMethod.currency,
            intent: "capture",
        } as any);
    };

    const getAuthorizePaymentResult: GetPayPalAuthorizePaymentResultFunc = async (data) => {
        const { payPalCheckoutInstance, deviceData } = await getPayPalCheckoutInstanceData();
        const { nonce } = await payPalCheckoutInstance.tokenizePayment(data);
        return {
            paymentToken: nonce,
            additionalFraudProtectionData: deviceData,
        };
    };

    return await initializePayPalButton(payPalCheckoutPaymentMethod, signal, {
        createOrder,
        getAuthorizePaymentResult,
    });
};
