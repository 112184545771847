import "./StartOrJoinFlexTab.scss";

import React, { ReactNode, useCallback } from "react";
import { Text } from "src/sharedComponents";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { BeerIcon, CreditCardIconStandard, QrIcon } from "src/sharedComponents/assets/icons";
import { FlexTabScanQrCcode } from "./FlexTabScanQrCode";
import { useDispatch } from "react-redux";
import { startFlexTab } from "../../actions/startFlexTab";

export const StartOrJoinFlexTab = () => {
    const dispatch = useDispatch();

    const onStartFlexTab = useCallback(() => {
        dispatch(startFlexTab());
    }, [dispatch]);

    return (
        <LocationThemeContainer>
            <div className="start-or-join-flex-tab">
                <main>
                    <Text
                        preset="title-28"
                        mode={["bold", "block"]}
                        className="start-or-join-flex-tab__title"
                        value="Start ordering on your tab"
                    />
                    <>
                        <FlexTabRow
                            icon={<BeerIcon />}
                            title={`Order from the menu`}
                            description="Explore the menu to start your order."
                        />
                        <FlexTabRow
                            icon={<QrIcon />}
                            title="Invite friends"
                            description={`Order on your own or invite friends by sharing your tab link or tab QR code.`}
                        />
                        <FlexTabRow
                            icon={<CreditCardIconStandard />}
                            title="Pay & tip"
                            description="Pay the balance or split the bill at the end."
                        />
                    </>
                </main>
                <footer>
                    <NetworkConnectedButton mode="solid" onClick={onStartFlexTab} value="Start a tab" />
                    <FlexTabScanQrCcode />
                </footer>
            </div>
        </LocationThemeContainer>
    );
};

interface FlexTabRow {
    icon: ReactNode;
    title: string;
    description: string;
}

const FlexTabRow = ({ icon, title, description }: FlexTabRow) => {
    return (
        <div className="start-or-join-flex-tab__row">
            <div className="start-or-join-flex-tab__row__icon">{icon}</div>
            <Text
                mode={["block", "medium"]}
                preset="g-16"
                className="start-or-join-flex-tab__row__title"
                value={title}
            />
            <Text mode="block" preset="g-14" className="start-or-join-flex-tab__row__description" value={description} />
        </div>
    );
};
