import { config } from "../../config";

const TAB_JOIN_PATTERN = /\/group-tab\/[0-9A-Za-z]{6}$/;
const TAKEAWAY_PATTERN = /\/order\/[0-9A-Za-z-]+$/;
const PAY_ONLY_PATTERN = /\/pay-only/;
const EXT_PATTERN = /\/ext\/[a-z-]+$/;
const OFFER_PATTERN = /\/offer(\/[gv]){0,1}\/[0-9A-Za-z-]+\/[0-9A-Za-z-]+$/;
const FLEX_JOIN_PATTERN = /\/join-flex\/[0-9A-Za-z]{6}$/;

export const getIsTabJoinPath = (pathname: string) => TAB_JOIN_PATTERN.test(pathname);

export const getIsFlexJoinPath = (pathname: string) => FLEX_JOIN_PATTERN.test(pathname);

export const getIsTakeawayPath = (pathname: string) => config.enableTakeaway === "1" && TAKEAWAY_PATTERN.test(pathname);

export const getIsPayOnlyPath = (pathname: string) => PAY_ONLY_PATTERN.test(pathname) || getIsExternalPath(pathname);

export const getIsExternalPath = (pathname: string) => EXT_PATTERN.test(pathname);

export const getIsOfferPath = (pathname: string) => OFFER_PATTERN.test(pathname);
