import { WizardTransition } from "./types";

export enum TypeKeys {
    START = "WIZARD/START",
    NEXT = "WIZARD/NEXT",
    PREV = "WIZARD/PREV",
    STANDALONE = "WIZARD/STANDALONE",
    DONE = "WIZARD/DONE",
}

export interface WizardOptions {
    overridePages?: string[];
    overrideStartTransition?: WizardTransition;
}

export const actionCreators = {
    start: (wizard: string, page: string, pages: string[], options?: WizardOptions) => ({
        type: TypeKeys.START,
        wizard,
        page,
        pages,
        options,
    }),
    next: (wizard: string, page?: string) => ({ type: TypeKeys.NEXT, wizard, page }),
    prev: (wizard: string, page?: string) => ({ type: TypeKeys.PREV, wizard, page }),
    standalone: (wizard: string, page: string) => ({ type: TypeKeys.STANDALONE, wizard, page }),
    done: (wizard: string) => ({ type: TypeKeys.DONE, wizard }),
};

export type WizardsState = { [wizard: string]: WizardState | undefined };

export type State = {
    wizards: WizardsState;
};

export interface WizardState {
    currentPage: string;
    startPage?: string;
    prevPages?: string[];
    pages?: string[];
    standalone?: boolean;
    direction: "next" | "prev";
    options?: WizardOptions;
}

type WizardStartAction = {
    type: TypeKeys.START;
    wizard: string;
    page: string;
    pages: string[];
    options?: WizardOptions;
};
type WizardNextAction = { type: TypeKeys.NEXT; wizard: string; page?: string };
type WizardPrevAction = { type: TypeKeys.PREV; wizard: string; page?: string };
type WizardStandaloneAction = { type: TypeKeys.STANDALONE; wizard: string; page: string };
type WizardDoneAction = { type: TypeKeys.DONE; wizard: string };

export type WizardAction =
    | WizardStartAction
    | WizardNextAction
    | WizardPrevAction
    | WizardStandaloneAction
    | WizardDoneAction;

const initialState: WizardsState = {};

export default function reducer(state = initialState, action: WizardAction) {
    if (action.type === TypeKeys.START) {
        const { wizard, page, pages, options } = action;
        return updateWizard(
            wizard,
            state,
            (wizardState) =>
                wizardState ?? {
                    currentPage: page,
                    startPage: page,
                    pages: options?.overridePages ?? pages,
                    direction: "next",
                    options,
                }
        );
    }

    if (action.type === TypeKeys.NEXT) {
        const { wizard, page } = action;
        return updateWizard(wizard, state, (wizardState) => {
            if (!wizardState) return wizardState;
            const { currentPage, pages, prevPages, standalone } = wizardState;
            if (!pages || standalone) return wizardState;
            const currentPageIndex = pages.findIndex((p) => p === currentPage);
            const nextPageIndex = page ? pages.findIndex((p) => p === page) : currentPageIndex + 1;
            if (nextPageIndex <= currentPageIndex) return wizardState;
            const nextPage = pages[nextPageIndex];
            if (!nextPage) return undefined;
            return {
                ...wizardState,
                currentPage: nextPage,
                prevPages: [...(prevPages ?? []), currentPage],
                direction: "next",
            };
        });
    }

    if (action.type === TypeKeys.PREV) {
        const { wizard, page } = action;
        return updateWizard(wizard, state, (wizardState) => {
            if (!wizardState) return wizardState;
            const { pages, standalone, prevPages, currentPage } = wizardState;
            // we need to use pages, as some wizards start in the middle and can move back to an earlier potential page
            if ((!prevPages && !pages) || standalone) return wizardState;
            const prePageIndex = page
                ? (prevPages ?? pages!).findIndex((p) => p === page)
                : (prevPages?.length ?? 0) - 1;
            if (prePageIndex === -1) return undefined;
            if (!prevPages && pages) {
                const currentPageIndex = pages.findIndex((p) => p === currentPage);
                if (prePageIndex >= currentPageIndex) return wizardState;
            }
            const prevPage = (prevPages ?? pages!)[prePageIndex];
            if (!prevPage) return undefined;
            const newPrevPages = prevPages?.slice(0, prePageIndex) ?? [];
            return {
                ...wizardState,
                currentPage: prevPage,
                prevPages: newPrevPages,
                direction: "prev",
            };
        });
    }

    if (action.type === TypeKeys.STANDALONE) {
        const { wizard, page } = action;
        return updateWizard(wizard, state, (wizardState) => {
            const { currentPage, prevPages } = wizardState ?? {};
            if (page === currentPage) return wizardState;
            return {
                ...wizardState,
                currentPage: page,
                prevPages: currentPage ? [...(prevPages ?? []), currentPage] : undefined,
                standalone: true,
                direction: "next",
            };
        });
    }

    if (action.type === TypeKeys.DONE) {
        return updateWizard(action.wizard, state, (wizardState) => {
            if (!wizardState) return wizardState;
            const { standalone, prevPages } = wizardState;
            if (!standalone || !prevPages) return undefined;
            const prevPage = prevPages.pop();
            if (!prevPage) return undefined;
            return {
                ...wizardState,
                currentPage: prevPage,
                prevPages: [...prevPages],
                standalone: undefined,
                direction: "next",
            };
        });
    }

    return state;
}

function updateWizard(
    wizard: string,
    state: WizardsState,
    update: (wizardState: WizardState | undefined) => WizardState | undefined
) {
    const wizardState = update(state[wizard]);
    if (wizardState === state[wizard]) return state;
    return {
        ...state,
        [wizard]: wizardState,
    };
}
