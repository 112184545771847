import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Props as PaymentMethodProps } from "./PaymentMethod";
import { vaultDevicePaymentMethod } from "src/features/payment/actions/vaultDevicePaymentMethod";
import { DevicePaymentMethod, PaymentType } from "src/common/payment";
import { SelectPaymentMethodAction } from "./SelectPaymentMethodAction";
import { SelectPaymentMethodActions } from "../types";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";
import { googlePay } from "src/common/experience";

let cancel: () => void = () => {};

interface Props extends PaymentMethodProps {
    paymentMethod: DevicePaymentMethod;
    actions: SelectPaymentMethodActions;
}

export const SelectVaultDevicePaymentMethodAction = ({ paymentMethod, actions, ...props }: Props) => {
    const dispatch = useDispatch();
    const [tempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    useEffect(() => () => cancel(), []);

    useEffect(() => {
        if (tempSelectedPaymentMethod !== paymentMethod) {
            cancel();
            return;
        }

        const cancelPromise = new Promise<void>((resolve) => (cancel = resolve));

        let getPaymentMethod = async () => {
            const paymentToken = await vaultDevicePaymentMethod(paymentMethod, cancelPromise, dispatch);
            if (!paymentToken) return null;

            return {
                ...paymentMethod,
                token: paymentToken,
            };
        };

        if (paymentMethod.paymentType === PaymentType.GOOGLEPAY && googlePay.getCustomButtonId(paymentMethod)) {
            // We have a custom button so execute getPaymentMethod to initialize it up front
            // and create a new method that just returns the created promise but when it
            // resolves we need to call the original click method to capture the paymentMethod
            const getPaymentMethodPromise = getPaymentMethod().then((paymentMethod) => {
                if (paymentMethod) {
                    actions.parentOnClick();
                }
                return paymentMethod;
            });

            getPaymentMethod = () => getPaymentMethodPromise;
        }

        actions.setGetPaymentMethod(getPaymentMethod);
        actions.setIsValid(true);
    }, [dispatch, paymentMethod, actions, tempSelectedPaymentMethod]);

    return <SelectPaymentMethodAction paymentMethod={paymentMethod} actions={actions} {...props} />;
};
