import { device } from "./device";
import { isTablet } from "react-device-detect";
import { config } from "src/common/config";
import { isExtension, isGoogleTagManagerPreview } from "src/features/preview/previewMiddleware";

const requirements: { [requirement: string]: boolean } = {};
let isSupportedBrowser = true;

function addRequirement(name: string, evaluator: () => boolean) {
    const result = evaluator();
    requirements[name] = result;
    isSupportedBrowser = isExtension() || isGoogleTagManagerPreview || (isSupportedBrowser && result);
}

const enableDesktopBrowsers = (config.enableDesktopBrowsers === "1");

if (!enableDesktopBrowsers) {
    addRequirement("whitelistedBrowser", () => {
        if (device.isIOS) {
            const regex = /safari|chrome|edge|instagram|facebook|duckduckgo|webkit/i;
            if (regex.test(device.name)) {
                return true;
            }
        }

        if (device.isAndroid) {
            const regex = /chrome|firefox|samsung browser|edge|instagram|facebook|duckduckgo/i;
            if (regex.test(device.name)) {
                return true;
            }
        }

        return false;
    });
}

addRequirement("fetch", () => "fetch" in window);
addRequirement("localStorage", () => "localStorage" in window);
addRequirement("numberFormat", () => "Intl" in window && "NumberFormat" in window.Intl);
addRequirement("notTablet", () => !isTablet);

Object.freeze(requirements);

export const browserSupport = {
    isSupportedBrowser,
    requirements,
};
