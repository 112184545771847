import { TagValueMap } from "../../types";

export function cloneTagValueMap(input: TagValueMap | undefined): TagValueMap {
    if (input === undefined) {
        return {};
    }

    return Object.keys(input).reduce((map, categoryId) => {
        map[categoryId] = input[categoryId].slice(0);
        return map;
    }, {} as TagValueMap);
}
