export const isLightColor = (color: string) => {
    let r, g, b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const colors = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        if (!colors) {
            return false;
        }

        r = parseInt(colors[1]);
        g = parseInt(colors[2]);
        b = parseInt(colors[3]);
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const rgbColor = +("0x" + color.slice(1).replace(color.length < 5 ? /./g : "", "$&$&"));

        r = rgbColor >> 16;
        // eslint-disable-next-line
        g = (rgbColor >> 8) & 255;
        b = rgbColor & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return hsp > 145;
};

export const hexToRGBA = (hex: string | null | undefined, alpha: number = 1) => {
    if (!hex) return null;
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r},${g},${b},${alpha})`;
};
