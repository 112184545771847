import { PaymentFlowPages } from "../types";
import { paymentFlowPageNames, PaymentFlowStateUpdate } from "../reducers/paymentFlow";

export const getNextWizardState = (
    isNext: boolean,
    currentPage: PaymentFlowPages,
    isOpeningTab: boolean,
    isUnverified: boolean
): PaymentFlowStateUpdate => {
    if (isNext) {
        let page = paymentFlowPageNames[paymentFlowPageNames.findIndex((p) => p === currentPage) + 1];

        if (currentPage === "SelectPaymentMethod") {
            if (!isOpeningTab) {
                page = "ReviewOrder"; // skip Verify, CreateTab
            } else if (!isUnverified) {
                page = "CreateTab"; // skip Verify
            }
        }

        return {
            page,
            isOpeningTab: isOpeningTab || page === "CreateTab",
        };
    } else {
        let page = paymentFlowPageNames[paymentFlowPageNames.findIndex((p) => p === currentPage) - 1];

        if ((currentPage === "ReviewOrder" && !isOpeningTab) || currentPage === "CreateTab") {
            page = "SelectPaymentMethod"; // Skip passed CreateTab and Verify
        }

        if (isOpeningTab && page === "SelectPaymentMethod") {
            isOpeningTab = false;
        }

        return {
            page,
            isOpeningTab,
        };
    }
};
