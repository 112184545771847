const KEY = "MEANDU_FAKE_BEACON";

export interface FakeBeacon {
    locationSlug: string;
    table: string;
}

export function getFakeBeacon(): FakeBeacon | null {
    try {
        return JSON.parse(window.localStorage.getItem(KEY)!) as FakeBeacon;
    } catch (err) {
        return null;
    }
}

export function saveFakeBeacon(fakeBeacon: FakeBeacon) {
    window.localStorage.setItem(KEY, JSON.stringify(fakeBeacon));
}
