export const hasHeader = (init: RequestInit | undefined, header: string) => {
    return getHeader(init, header) !== null;
};

export const getAuthorizationHeaderValue = (init: RequestInit | undefined): string | null => {
    const authHeader = getHeader(init, "Authorization");

    if (!authHeader || !authHeader.startsWith("Bearer ")) {
        return null;
    }

    const token = authHeader.substr("Bearer ".length);

    if (!token) {
        return null;
    }

    return token;
};

export const getHeader = (init: RequestInit | undefined, header: string) => {
    if (!init || !init.headers) return null;

    if (init.headers instanceof Headers) {
        return init.headers.get(header);
    }

    if (Array.isArray(init.headers)) {
        return init.headers.find((h) => h[0] === header)?.[1] ?? null;
    }

    return null;
};

export const setHeader = (init: RequestInit | undefined, header: string, value: string) => {
    if (!init) throw Error("Can't set header as none have been configured");

    if (!init.headers) init.headers = new Headers();

    if (init.headers instanceof Headers) {
        init.headers.set(header, value);
        return;
    }

    if (Array.isArray(init.headers)) {
        const h = init.headers.find((h) => h[0] === header);

        if (!h) {
            init.headers.push([header, value]);
        } else {
            h[1] = value;
        }

        return;
    }

    if (typeof init.headers === "object") {
        init.headers[header] = value;
    }
};
