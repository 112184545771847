import { LocationMenuData } from "..";
import { ActiveGroupTabData } from "../../groupTabs";

export class MenuDataLocale {
    private currencyFormat: Intl.NumberFormat;
    private rawCurrencyFormat: Intl.NumberFormat;
    private percentageFormat: Intl.NumberFormat;
    private numberFormat: Intl.NumberFormat;
    private dateTimeShort: Intl.DateTimeFormat;
    public currencySymbol: string | undefined;
    public thousandsSeparatorSymbol: string | undefined;
    public decimalSymbol: string | undefined;
    public regionCode: string;
    public surchargeText: string;

    static fromMenuData(menuData: LocationMenuData) {
        return new MenuDataLocale(menuData.locale, menuData.currency);
    }

    static fromTabData(tab?: ActiveGroupTabData) {
        if (tab?.locale && tab?.currency) {
            return new MenuDataLocale(tab.locale, tab.currency);
        }
        return null;
    }

    constructor(public locale: string, public currency: string) {
        this.currencyFormat = new Intl.NumberFormat(locale, {
            style: "currency",
            currency,
        });

        // Keep all the other formatting options from currency, accept the
        // style that would include the currency symbol
        // (some currencies don't have decimal places, etc, so best inherit)
        this.rawCurrencyFormat = new Intl.NumberFormat(locale, {
            ...this.currencyFormat.resolvedOptions(),
            style: "decimal",
        });

        this.currencySymbol = getCurrencySymbol(locale, currency);
        this.thousandsSeparatorSymbol = getThousandsSeparatorSymbol(locale, currency);
        this.decimalSymbol = getDecimalSymbol(locale, currency);

        this.percentageFormat = new Intl.NumberFormat(locale, {
            style: "percent",
            maximumFractionDigits: 2,
        });

        this.numberFormat = new Intl.NumberFormat(locale);

        this.regionCode = locale.split("-")[1];
        this.surchargeText = getSurchargeText(locale);

        this.dateTimeShort = new Intl.DateTimeFormat(locale, { hour: "numeric", minute: "numeric" });
    }

    formatCurrency(value: number | string, includeSymbol: boolean = true) {
        if (typeof value === "string" && value.indexOf("$") === 0) {
            value = value.substr(1);
        }

        return (includeSymbol ? this.currencyFormat : this.rawCurrencyFormat).format(value as any);
    }

    formatCurrencyNoDigits(value: number | string) {
        const currencyResolvedOptions = this.currencyFormat.resolvedOptions();
        const formattedValue = new Intl.NumberFormat(currencyResolvedOptions.locale, {
            ...currencyResolvedOptions,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value as any);

        return formattedValue.replace("-", "-");
    }

    formatPercent(value: number) {
        return this.percentageFormat.format(value);
    }

    formatNumber(value: number) {
        return this.numberFormat.format(value);
    }

    getCurrencySymbols() {
        return {
            thousandsSeparatorSymbol: this.thousandsSeparatorSymbol,
            decimalSymbol: this.decimalSymbol,
            currencySymbol: this.currencySymbol,
        };
    }

    getDateTimeShort(date: Date | string) {
        return this.dateTimeShort.format(typeof date === "string" ? Date.parse(date) : date).toUpperCase();
    }
}

function getCurrencySymbol(locale: string, currency: string) {
    return (0)
        .toLocaleString(locale, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
}
function getThousandsSeparatorSymbol(locale: string, currency: string) {
    return (1000)
        .toLocaleString(locale, {
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d*/g, "")
        .trim();
}
function getDecimalSymbol(locale: string, currency: string) {
    return (0.1)
        .toLocaleString(locale, {
            currency,
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        })
        .replace(/\d*/g, "")
        .trim();
}

function getSurchargeText(locale: string) {
    switch (locale) {
        case "en-GB":
            return "Service charge";
        default:
            return "Venue surcharge";
    }
}
