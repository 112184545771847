import { PaymentFlowPages } from "..";
import { trackedEvent } from "../../../common/events/reduxEventTracking";

export const paymentFlowPageNames: PaymentFlowPages[] = [
    "Phone",
    "Verify",
    "AccountDetails",
    "SelectPaymentMethod",
    "CreateTab",
    "ReviewOrder",
];

export interface PaymentFlowStateUpdate {
    page: PaymentFlowPages;
    isOpeningTab?: boolean;
}

export enum WizardDirection {
    FORWARD = "forward",
    BACK = "back",
}

export interface PaymentFlowState {
    showWizard: boolean;
    page?: PaymentFlowPages;
    edit?: boolean;
    isOpeningTab?: boolean;
    startPage?: PaymentFlowPages;
    direction: WizardDirection;
}

export enum TypeKeys {
    PAYMENT_FLOW_START = "PAYMENT_FLOW/START",
    NEXT_PAYMENT_PAGE = "PAYMENT_FLOW/NEXT",
    PREV_PAYMENT_PAGE = "PAYMENT_FLOW/PREV",
    PAYMENT_FLOW_DONE = "PAYMENT_FLOW/DONE",
    PAYMENT_FLOW_EDIT = "PAYMENT_FLOW/EDIT",
    PAYMENT_FLOW_DONE_EDITING = "PAYMENT_FLOW/DONE_EDITING",
    PAYMENT_FLOW_OPEN_TAB_CLICKED = "PAYMENT_FLOW/OPEN_TAB_CLICKED",
    PAYMENT_FLOW_CREATE_TAB_CARD_DETAILS_VIEWED = "PAYMENT_FLOW/CREATE_TAB_CARD_DETAILS_VIEWED",
}

export const actionCreators = {
    startPaymentFlow: (page: PaymentFlowPages) => ({ type: TypeKeys.PAYMENT_FLOW_START, page }),
    nextPaymentPage: (nextWizardState?: PaymentFlowStateUpdate) => ({
        type: TypeKeys.NEXT_PAYMENT_PAGE,
        nextWizardState,
    }),
    prevPaymentPage: (nextWizardState?: PaymentFlowStateUpdate) => ({
        type: TypeKeys.PREV_PAYMENT_PAGE,
        nextWizardState,
    }),
    completePaymentFlow: () => ({ type: TypeKeys.PAYMENT_FLOW_DONE }),
    editPaymentPage: (page: PaymentFlowPages) => ({ type: TypeKeys.PAYMENT_FLOW_EDIT, page }),
    doneEditing: () => ({ type: TypeKeys.PAYMENT_FLOW_DONE_EDITING }),
    trackPaymentFlowOpenTabClicked: () => trackedEvent({ type: TypeKeys.PAYMENT_FLOW_OPEN_TAB_CLICKED }),
    trackPaymentFlowCreateTabCardDetailsViewed: (has_vaulted_card: boolean) =>
        trackedEvent({ type: TypeKeys.PAYMENT_FLOW_CREATE_TAB_CARD_DETAILS_VIEWED, has_vaulted_card }),
};

export type StartPaymentFlowAction = { type: TypeKeys.PAYMENT_FLOW_START; page: PaymentFlowPages };
export type NextPaymentPageAction = {
    type: TypeKeys.NEXT_PAYMENT_PAGE;
    nextWizardState?: PaymentFlowStateUpdate;
};
export type PrevPaymentPageAction = { type: TypeKeys.PREV_PAYMENT_PAGE; nextWizardState?: PaymentFlowStateUpdate };
export type CompletePaymentFlowAction = { type: TypeKeys.PAYMENT_FLOW_DONE };
export type EditPaymentPageAction = { type: TypeKeys.PAYMENT_FLOW_EDIT; page: PaymentFlowPages };
export type DoneEditingPaymentPageAction = { type: TypeKeys.PAYMENT_FLOW_DONE_EDITING };

export type PaymentFlowActions =
    | StartPaymentFlowAction
    | NextPaymentPageAction
    | PrevPaymentPageAction
    | CompletePaymentFlowAction
    | EditPaymentPageAction
    | DoneEditingPaymentPageAction;

const initialState: PaymentFlowState = {
    showWizard: false,
    direction: WizardDirection.FORWARD,
};

export function reducer(state: PaymentFlowState = initialState, action: PaymentFlowActions): PaymentFlowState {
    if (action.type === TypeKeys.PAYMENT_FLOW_START) {
        const { page } = action;
        return {
            ...state,
            showWizard: true,
            page,
            edit: undefined,
            startPage: page,
            direction: WizardDirection.FORWARD,
        };
    }

    if (action.type === TypeKeys.NEXT_PAYMENT_PAGE) {
        const nextPage =
            action.nextWizardState?.page ||
            paymentFlowPageNames[paymentFlowPageNames.findIndex((p) => p === state.page) + 1];

        return {
            ...state,
            page: nextPage,
            edit: undefined,
            direction: WizardDirection.FORWARD,
            isOpeningTab: action.nextWizardState?.isOpeningTab,
        };
    }

    if (action.type === TypeKeys.PREV_PAYMENT_PAGE && state.startPage !== state.page) {
        const stateUpdate = action.nextWizardState;
        const page =
            stateUpdate?.page || paymentFlowPageNames[paymentFlowPageNames.findIndex((p) => p === state.page) - 1];
        const isOpeningTab = stateUpdate === undefined ? state.isOpeningTab : stateUpdate.isOpeningTab;

        return {
            ...state,
            page,
            edit: undefined,
            isOpeningTab,
            direction: WizardDirection.BACK,
        };
    }

    if (
        action.type === TypeKeys.PAYMENT_FLOW_DONE ||
        (action.type === TypeKeys.PREV_PAYMENT_PAGE && state.startPage === state.page)
    ) {
        return {
            showWizard: false,
            page: undefined,
            edit: undefined,
            isOpeningTab: false,
            startPage: undefined,
            direction: WizardDirection.FORWARD,
        };
    }

    if (action.type === TypeKeys.PAYMENT_FLOW_EDIT) {
        return {
            ...state,
            page: action.page,
            edit: true,
        };
    }

    if (action.type === TypeKeys.PAYMENT_FLOW_DONE_EDITING) {
        return {
            ...state,
            page: "ReviewOrder",
            edit: false,
        };
    }

    return state;
}
