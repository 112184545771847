import * as React from "react";
import InputRange, { InputRangeProps, Range } from "react-input-range";
import "react-input-range/lib/css/index.css";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { Tag, TagGroup } from "../../menudata";
import "../assets/FilterModal.scss";
import { AvailableFilters, CategoryIndexed, ChangedTagValueMap, SelectedFilters, TagValueMap } from "../types";
import { Button } from "./Button";
import { DropDownList } from "./DropDownList";
import { TagButtonGroup } from "./TagButtonGroup";
import { cloneTagValueMap } from "./util/cloneTagValueMap";
import { NativeBackButton } from "../../../common/navigation";
import { backHandler } from "src/common/experience";
import { MenuFilterIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { PartyFoodPreferenceState } from "src/features/order/reducers/party";
import { ModalContainer } from "src/common/modal";

export interface ExtendedInputRangeProps extends InputRangeProps {
    value: Range | number;
    onChange: (value: Range | number) => void;
}

export interface InputRangeStates {
    priceValue: Range | undefined;
}

export interface FilterModalProps {
    availableFilters: AvailableFilters;
    selectedFilters: SelectedFilters;
    modalIsOpen: boolean;
    category?: string;
    handleOnClick: () => void;
    closeModal?: () => void;
    handleApplyFilters(
        tagGroups: TagGroup[],
        selectedFilters: SelectedFilters,
        category?: string,
        changedTagValues?: ChangedTagValueMap
    ): void;
}

export interface LocalState {
    tagGroupRows: TagGroup[][];
    selectedValues: TagValueMap;

    sourceTagGroups: TagGroup[];
    sourceSelectedValues: TagValueMap;

    changedTagValues: ChangedTagValueMap;
    priceValueCategoryMap: CategoryIndexed<Range | undefined>;
}

export class FilterModal extends React.PureComponent<FilterModalProps, LocalState & InputRangeStates> {
    static contextType = MenuDataLocaleContext;
    context!: MenuDataLocale;

    constructor(props: FilterModalProps) {
        super(props);
        this.state = {
            priceValue: props.selectedFilters.price,
            sourceTagGroups: props.availableFilters.tagGroups,
            tagGroupRows: FilterModal.getTagGroupRows(props.availableFilters.tagGroups),
            sourceSelectedValues: props.selectedFilters.tags,
            selectedValues: cloneTagValueMap(props.selectedFilters.tags),
            changedTagValues: {},
            priceValueCategoryMap: {},
        };
    }

    componentDidUpdate(prevProps: FilterModalProps, prevState: LocalState) {
        const {
            modalIsOpen,
            selectedFilters: { tags, price },
            category,
        } = this.props;

        if (!prevProps.modalIsOpen && modalIsOpen) {
            this.setState({ selectedValues: cloneTagValueMap(tags) });
        }

        if (!prevProps.category && category) {
            this.setState({ priceValueCategoryMap: { ...this.state.priceValueCategoryMap, [category]: price } });
        }
    }

    static getDerivedStateFromProps(prevProps: FilterModalProps, nextState: LocalState): Partial<LocalState> | null {
        const state: Partial<LocalState> = {};

        if (prevProps.availableFilters.tagGroups !== nextState.sourceTagGroups) {
            state.tagGroupRows = FilterModal.getTagGroupRows(prevProps.availableFilters.tagGroups);
            state.sourceTagGroups = prevProps.availableFilters.tagGroups;
        }

        if (prevProps.selectedFilters.tags !== nextState.sourceSelectedValues) {
            state.selectedValues = cloneTagValueMap(prevProps.selectedFilters.tags);
            state.sourceSelectedValues = prevProps.selectedFilters.tags;
        }

        return state;
    }

    render() {
        const {
            modalIsOpen,
            availableFilters: { price },
            category,
        } = this.props;
        const { tagGroupRows, priceValueCategoryMap } = this.state;
        const hasTagGroups = tagGroupRows.some((group) => group.length);

        return (
            <ModalContainer
                isOpen={modalIsOpen}
                className="menuitem-filter__modal"
                overlayClassName="menuitem-filter__modal__overlay"
                onRequestClose={this.closeModal}
                contentLabel="Filter Modal"
                bodyOpenClassName="menuitem-filter__Body"
                closeTimeoutMS={250}
            >
                <NativeBackButton name="#menu-item-filter" onPressed={this.props.handleOnClick}>
                    <LocationThemeContainer>
                        <div className="filtermodal__content--wrapper">
                            <div className="filtermodal__header">
                                <div className="filtermodal__header--left">
                                    <MenuFilterIcon />
                                    <Text preset="g-18" mode="bold" className="filtermodal__filter-label">
                                        Filter
                                    </Text>
                                </div>
                                <div className="filtermodal__header--right">
                                    <TappableDiv className="filtermodal__close-icon" onClick={this.closeModal} />
                                </div>
                            </div>
                            <div className="filtermodal__filters">
                                {hasTagGroups && tagGroupRows.map(this.renderFilterTagsRow)}
                                {price && (
                                    <>
                                        {hasTagGroups && <HorizontalRuleRow />}
                                        <div className="filtermodal__price">
                                            <div className="filtermodal__item__label">Price range</div>
                                            <InputRange
                                                step={5}
                                                maxValue={price.max}
                                                minValue={price.min}
                                                formatLabel={(value) => this.context.formatCurrency(value)}
                                                value={
                                                    priceValueCategoryMap[category || ""] ||
                                                    this.state.priceValue ||
                                                    price
                                                }
                                                onChange={this.handleInputRangeChange}
                                                onChangeComplete={(value) => console.log(value)}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="filtermodal__apply">
                                <Button label="Apply" onClick={this.applyFilter} />
                            </div>
                            <div className="filtermodal__reset">
                                <TappableDiv className="filtermodal__reset-icon" onClick={this.handleResetFilters}>
                                    Reset
                                </TappableDiv>
                            </div>
                        </div>
                    </LocationThemeContainer>
                </NativeBackButton>
            </ModalContainer>
        );
    }

    handleInputRangeChange = (priceValue: number | Range) => {
        if (typeof priceValue === "number") {
            return;
        }

        const { price: availablePriceRange } = this.props.availableFilters;

        if (priceValue.max - priceValue.min < 20) {
            return;
        }

        const selectedPriceRange =
            priceValue.min === availablePriceRange!.min && priceValue.max === availablePriceRange!.max
                ? undefined
                : priceValue;

        const priceValues = { ...this.state.priceValueCategoryMap };
        if (this.props.category) {
            priceValues[this.props.category] = selectedPriceRange;
            this.setState({ priceValueCategoryMap: priceValues });
        } else {
            this.setState({ priceValue: selectedPriceRange });
        }
    };

    applyFilter = () => {
        const { availableFilters, category } = this.props;
        const { priceValueCategoryMap, priceValue } = this.state;
        const price = category ? priceValueCategoryMap[category] : priceValue;
        const selectedFilters: SelectedFilters = { price, tags: this.state.selectedValues };
        this.props.handleApplyFilters(
            availableFilters.tagGroups,
            selectedFilters,
            category,
            this.state.changedTagValues
        );
        this.setState({ changedTagValues: {} });
        this.closeModal();
    };

    handleResetFilters = () => {
        const { availableFilters, category } = this.props;
        const priceValues = { ...this.state.priceValueCategoryMap };
        if (category) {
            priceValues[category] = undefined;
        }

        this.setState({
            selectedValues: {},
            priceValue: undefined,
            priceValueCategoryMap: priceValues,
        });

        this.props.handleApplyFilters(
            availableFilters.tagGroups,
            {
                tags: {},
                price: undefined,
            },
            category
        );
    };

    renderFilterTagsRow = (groups: TagGroup[], index: number, arr: TagGroup[][]) => {
        const isLast = index < arr.length - 1;
        return (
            <React.Fragment key={index}>
                <div className="filterTagsRow">{groups.map(this.renderFilterTags)}</div>
                {isLast && <HorizontalRuleRow />}
            </React.Fragment>
        );
    };

    renderFilterTags = (filterTagGroup: TagGroup) => {
        let tags = this.props.availableFilters.tags[filterTagGroup.id] || {};
        const filterMode = filterTagGroup.filterMode;

        switch (filterMode) {
            case "single":
                if (filterTagGroup.parentTagGroup) {
                    const selectedParentTags = this.state.selectedValues[filterTagGroup.parentTagGroup];

                    if (selectedParentTags) {
                        tags = FilterModal.filterChildTags(tags, selectedParentTags);
                    }
                }
                return (
                    <div key={filterTagGroup.id} className="dropdown-wrapper">
                        <div className="filtermodal__item__label filtermodal__title">{filterTagGroup.filterLabel}</div>
                        <DropDownList
                            options={tags}
                            tagGroupId={filterTagGroup.id}
                            onChange={this.handleOnChange}
                            selectedValues={this.state.selectedValues}
                        />
                    </div>
                );
            case "any":
            case "all":
                return (
                    <div key={filterTagGroup.id}>
                        <div key={filterTagGroup.id} className="filtermodal__item__label filtermodal__title">
                            {filterTagGroup.filterLabel}
                        </div>
                        <TagButtonGroup
                            tagGroupId={filterTagGroup.id}
                            tags={tags}
                            selectedValues={this.state.selectedValues[filterTagGroup.id]}
                            onSelect={this.onSelect}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    onSelect = (tagGroupId: string, selectedTag: Tag, selected: boolean) => {
        const selectedValues = {
            ...(this.state.selectedValues || []),
        };

        const changedTagValues = { ...this.state.changedTagValues };
        let changedValues = changedTagValues[tagGroupId] || [];
        const isChangedPreference = !changedValues.find(
            ({ preferenceName }) => preferenceName === selectedTag.displayName
        );

        if (isChangedPreference) {
            changedValues.push({
                preferenceName: selectedTag.displayName,
                preferenceState: selected ? PartyFoodPreferenceState.OFF : PartyFoodPreferenceState.ON,
            });
        } else {
            changedValues = changedValues.filter(({ preferenceName }) => preferenceName !== selectedTag.displayName);
        }

        changedTagValues[tagGroupId] = changedValues;

        if (!selected) {
            const tagValues = selectedValues[tagGroupId] || [];
            tagValues.push(selectedTag.id);

            selectedValues[tagGroupId] = tagValues;
        } else {
            const remainingTags =
                selectedValues[tagGroupId] && selectedValues[tagGroupId].filter((t: any) => t !== selectedTag.id);
            if (remainingTags && remainingTags.length === 0) {
                delete selectedValues[tagGroupId];
            } else {
                selectedValues[tagGroupId] = remainingTags;
            }
        }

        this.setState({
            selectedValues,
            changedTagValues,
        });
    };

    handleOnChange = (tagGroupId: string, selectedOption: Tag) => {
        this.setState({
            selectedValues: {
                ...(this.state.selectedValues || {}),
                [tagGroupId]: selectedOption.id ? [selectedOption.id] : [],
            },
        });
    };

    closeModal = () => {
        backHandler.handleBack(() => {
            if (this.props.closeModal) {
                this.props.closeModal();
            }
            this.props.handleOnClick();
        });
    };

    static getTagGroupRows(tagGroups: TagGroup[]) {
        const MAX_ROW_WIDTH = 2;

        const [rows] = tagGroups.reduce(
            function (ag, tagGroup) {
                let [rows, currentRowWidth] = ag;

                const tagGroupWidth = tagGroup.filterMode === "single" ? 1 : 2;
                let row = rows[rows.length - 1];
                const reuseRow = currentRowWidth + tagGroupWidth <= MAX_ROW_WIDTH;

                if (!reuseRow) {
                    row = [];
                    currentRowWidth = 0;
                    rows.push(row);
                }

                row.push(tagGroup);
                currentRowWidth += tagGroupWidth;
                return [rows, currentRowWidth] as [Array<Array<TagGroup>>, number];
            },
            [[[]], 0] as [Array<Array<TagGroup>>, number]
        );

        return rows;
    }

    static filterChildTags(tags: Tag[], parentTags: string[]) {
        return tags.filter((t) => parentTags.indexOf(t.parentTag!) !== -1);
    }
}

const HorizontalRuleRow = () => (
    <div className="filtermodal__horizontalRule">
        <hr />
    </div>
);
