import "./PayOnlyReviewPayment.scss";

import { Text } from "../../../sharedComponents";
import React, { useCallback } from "react";
import { PayOnlyPaymentDetail } from "./PayOnlyPaymentDetail";
import classNames from "classnames";
import { PayOnlyTerms } from "./PayOnlyTerms";
import { makePayment } from "../actions/makePayment";
import { makePayOnlyPaymentOperation } from "../operations";
import { PaymentButton } from "../../payment/components/PaymentButton";
import { payOnlyPaymentWizard } from "../wizards";
import { useDispatch, useSelector } from "react-redux";
import { getPayOnlyPaymentOrder } from "../selectors";
import { OrderStatus } from "../../order";

export const PayOnlyReviewPayment = () => {
    const dispatch = useDispatch();

    const order = useSelector(getPayOnlyPaymentOrder);

    const changePaymentMethod = useCallback(() => {
        dispatch(payOnlyPaymentWizard.actionCreators.standalone("SelectPaymentMethod"));
    }, [dispatch]);

    return (
        <div className="pay-only-review-payment">
            <div className={classNames("scroll-element", "animated-child")}>
                <div className="pay-only-review-payment__title">
                    <Text preset="title-28" mode="bold">
                        Review & pay
                    </Text>
                </div>
                <PayOnlyPaymentDetail />
                <PayOnlyTerms />
            </div>
            <footer>
                <div className="pay-only-review-payment__footer">
                    <PaymentButton
                        value="Pay"
                        payingValue="Sending your payment..."
                        onPayment={makePayment}
                        operation={makePayOnlyPaymentOperation}
                        changePaymentMethod={changePaymentMethod}
                        tipRecipients="staff"
                        disabled={order?.status === OrderStatus.INVALID}
                    />
                </div>
            </footer>
        </div>
    );
};
