import React, { useState, useCallback, useRef, CSSProperties, useEffect } from "react";
import classNames from "classnames";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { Text } from "src/sharedComponents";
import { SmallArrowRightIcon } from "src/sharedComponents/assets/icons";
import { Indexed, MenuItemWithId } from "../../menudata";
import { MenuItemImage } from "../../menuitem/components/MenuItemImage";
import { LimitedMenuPackageDetails } from "../types";
import "./PackageMenu.scss";

interface Props {
    packageDetails: Indexed<LimitedMenuPackageDetails> | null;
    categoryIds: string[];
}

export const PackageMenu = ({ packageDetails, categoryIds }: Props) => {
    const [openCategories, setOpenCategories] = useState<string[]>(categoryIds);
    const [categoryHeights, setCategoryHeights] = useState<{ [x: string]: number }>({});

    const handleShowingCategory = useCallback(
        (catId: string) => {
            const isExpanded = openCategories.includes(catId);

            if (isExpanded) {
                setOpenCategories(openCategories.filter((openCatId) => openCatId !== catId));
            } else {
                setOpenCategories([...openCategories, catId]);
            }
        },
        [openCategories]
    );

    if (!packageDetails) return null;

    return (
        <>
            {categoryIds.map((catId) => {
                const { displayName, menuItems } = packageDetails[catId];
                const isExpanded = openCategories.includes(catId);

                return (
                    <div
                        key={catId}
                        className="package-menu"
                        style={
                            categoryHeights[catId]
                                ? ({ "--category-height": categoryHeights[catId] + "px" } as CSSProperties)
                                : undefined
                        }
                    >
                        <TappableDiv
                            className={classNames("package-menu-category", isExpanded && "show-category")}
                            onTap={() => handleShowingCategory(catId)}
                        >
                            <Text
                                preset="g-18"
                                mode="bold"
                                className="package-menu-category__name"
                                value={displayName}
                            />
                            <Text
                                preset="g-14"
                                mode="bold"
                                className="package-menu-category__item-count"
                                value={menuItems.length.toString()}
                            />
                            <Text preset="g-14" mode="medium" className="package-menu-category__icon">
                                <SmallArrowRightIcon />
                            </Text>
                        </TappableDiv>
                        <div className="package-menu-category__data">
                            <PackageMenuItems
                                items={menuItems}
                                setHeight={
                                    !isExpanded || !!categoryHeights[catId]
                                        ? undefined
                                        : (height: number) =>
                                              setCategoryHeights((categoryHeights) => ({
                                                  ...categoryHeights,
                                                  [catId]: height,
                                              }))
                                }
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

interface PackageMenuItemsProps {
    items: MenuItemWithId[];
    setHeight?: (height: number) => void;
}

const PackageMenuItems = ({ items, setHeight }: PackageMenuItemsProps) => {
    const menuItemsRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (menuItemsRef.current && menuItemsRef.current.offsetHeight && setHeight) {
                setHeight(menuItemsRef.current.offsetHeight);
            }
        }, 100);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [menuItemsRef, setHeight]);

    return (
        <div ref={menuItemsRef} className="package-menu-items">
            {items.map((item) => (
                <PackageItemRow key={item.id} item={item} />
            ))}
        </div>
    );
};

interface PackageItemRowProps {
    item: MenuItemWithId;
}

const PackageItemRow = ({ item }: PackageItemRowProps) => (
    <div className="package-menu-item-row">
        <div className="package-menu-item-row__image">
            <MenuItemImage className={`${item.type}card__thumb`} images={item.images} name="thumb" />
        </div>
        <div>
            <Text preset="g-14" className="package-menu-item-row__name" value={item.displayName} />
            {!!item.variants?.length && (
                <Text preset="g-14" className="package-menu-item-row__variants">
                    {item.variants.map(({ displayName }) => displayName).join(", ")}
                </Text>
            )}
        </div>
    </div>
);
