import { AppState } from "src/features/index";
import { createSelector } from "reselect";

const getMembershipSignupPromotionState = ({ membership: { signupPromotion } }: AppState) => signupPromotion;

export const getMembershipSignupPromotion = createSelector(
    getMembershipSignupPromotionState,
    ({ signupPromotion }) => signupPromotion
);

export const getShowMembershipSignupPromotionModal = createSelector(
    getMembershipSignupPromotionState,
    ({ showSignupPromotionModal }) => showSignupPromotionModal
);
