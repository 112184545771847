import { SsoBehaviors } from "../interface";
import { Identity } from "../../sso";
import { cordovaWindow } from "./cordova";
import { config } from "../../config";
import { device } from "./device";
import { webFlow } from "./webFlow";
import { normalizeError } from "../../error";
import { format } from "../../strings";
import { regionHelper } from "../../../features/region";

export const sso: SsoBehaviors = {
    Google: {
        isAvailable: () => true,
        signIn: () =>
            new Promise<Identity | null>((resolve, reject) =>
                cordovaWindow.GoogleSignIn.signIn(config.googleSignInClientId!, resolve, reject)
            ),
    },
    Apple: {
        isAvailable: () => true,
        signIn: () =>
            new Promise<Identity | null>(async (resolve, reject) => {
                if (device.isIOS && parseInt(device.version) >= 13) {
                    cordovaWindow.AppleSignIn.signIn(resolve, reject);
                } else {
                    const orderApiUrl = format(config.regionSpecificOrderApi!, { regionId: regionHelper.region.id });
                    const returnUrl = `${orderApiUrl}/sso/apple/callback`;
                    const queryString = new URLSearchParams({
                        client_id: config.appleSignInServiceId!,
                        redirect_uri: returnUrl,
                        response_type: "code id_token",
                        scope: "name email",
                        response_mode: "form_post",
                    });

                    const startUrl = `https://appleid.apple.com/auth/authorize?${queryString}`;

                    try {
                        const { searchParams } = await webFlow.start(startUrl, returnUrl);
                        const token = searchParams.get("token");

                        if (!token) return reject(new Error("No token"));

                        resolve({
                            token,
                            firstName: searchParams.get("firstName") ?? undefined,
                            lastName: searchParams.get("lastName") ?? undefined,
                            email: searchParams.get("email") ?? undefined,
                        });
                    } catch (err) {
                        const error = normalizeError(err);
                        if (error.message === "cancelled") {
                            resolve(null);
                        } else {
                            reject(error);
                        }
                    }
                }
            }),
    },
    Facebook: {
        isAvailable: () => true,
        signIn: () =>
            new Promise<Identity | null>((resolve, reject) => cordovaWindow.FacebookSignIn.signIn(resolve, reject)),
    },
};
