import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignupMembershipState } from "src/features/membership/selectors/getActiveMembershipState";
import { manageMembershipsActions } from "src/features/membership/reducers";
import { AvailableMembershipState, MembershipAuthMethods } from "src/features/membership/types/MembershipState";
import { startLinkToVenueMembership } from "src/features/membership/actions/linkToVenueMemberships";
import { setSignupProgramWithSignIn } from "src/features/membership/actions/setSignupProgramWithSignIn";
import { Indexed } from "src/features/menudata";

const activeInstances: Indexed<boolean> = {};

export function useSignupMembership(
    instanceName: string
): [
    boolean,
    (membership: AvailableMembershipState, fromProfile?: boolean) => void,
    () => void,
    AvailableMembershipState | null
] {
    const dispatch = useDispatch();
    const [showSignup, setShowSignup] = useState(false);
    const signupMembership = useSelector(getSignupMembershipState);

    const closeSignup = useCallback(() => setShowSignup(false), []);

    const setSignupProgram = useCallback(
        (membership: AvailableMembershipState, fromProfile: boolean = false) => {
            if (membership.authentication.method === MembershipAuthMethods.OPT_IN) {
                dispatch(startLinkToVenueMembership(fromProfile));
            } else {
                dispatch(setSignupProgramWithSignIn(membership, () => (activeInstances[instanceName] = true)));
            }
        },
        [dispatch, instanceName]
    );

    useEffect(() => {
        if (!activeInstances[instanceName]) return;
        if (signupMembership) {
            setShowSignup(true);
        } else {
            activeInstances[instanceName] = false;
            setShowSignup(false);
            // signupMembership has been cleared by some other process
            // Not having a signupMembership doesn't necessarily mean the signupProgramId has been cleared
            // So clear it now, otherwise if we unlink a membership we suddenly have a signupMembership again
            dispatch(manageMembershipsActions.setSignupProgramId(undefined));
        }
    }, [dispatch, signupMembership, instanceName]);

    useEffect(() => {
        if (!activeInstances[instanceName]) return;
        if (showSignup || !signupMembership) return;
        const timeout = setTimeout(() => dispatch(manageMembershipsActions.setSignupProgramId(undefined)), 250);
        return () => clearTimeout(timeout);
    }, [dispatch, showSignup, signupMembership, instanceName]);

    useEffect(
        () => () => {
            if (!activeInstances[instanceName]) return;
            activeInstances[instanceName] = false;
            dispatch(manageMembershipsActions.setSignupProgramId(undefined));
        },
        [dispatch, instanceName]
    );

    return [showSignup, setSignupProgram, closeSignup, signupMembership];
}
