import { AppState } from "../../index";
import { AvailablePrices } from "../../menudata";

export type PriceResolver = (availablePrices?: AvailablePrices) => number;

export interface PriceResolverProps {
    priceResolver: PriceResolver;
}

const priceResolver = (availablePrices?: AvailablePrices) =>
    availablePrices?.memberPrice ?? availablePrices?.basePrice ?? 0;

export const getPriceResolver = (_: AppState): PriceResolver => priceResolver;
