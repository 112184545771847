import { createSelector } from "reselect";
import { Category, LocationMenuData, Menu, MenuItem, Tag, TagGroup } from "../../menudata";
import { AvailableFilters, Range } from "../types";
import { getActiveMenu, getVisibleMenuData } from "../../menu/selectors";
import { getMenuItemPrices } from "./getMenuItemPriceRange";
import { getServiceTagGroups } from "../../order/selectors";
import { getPriceResolver, PriceResolver } from "../../membership/selectors/getPriceResolver";

export const getAvailableFilters = createSelector(
    getVisibleMenuData,
    getActiveMenu,
    getServiceTagGroups,
    getPriceResolver,
    (menuData: LocationMenuData | null, menu: Menu, tagGroups: TagGroup[], priceResolver: PriceResolver) => {
        const { items: menuItems, categories } = menuData!;
        return menu.categories.reduce((map, categoryId: string) => {
            const category = categories[categoryId];
            const categoryMenuItems = category.menuItems.map((mi) => menuItems[mi]);
            map[categoryId] = getCategoryAvailableFilters(category, categoryMenuItems, tagGroups, priceResolver);
            return map;
        }, {} as { [categoryId: string]: AvailableFilters });
    }
);

function getCategoryAvailableFilters(
    category: Category,
    categoryMenuItems: MenuItem[],
    tagGroups: TagGroup[],
    priceResolver: PriceResolver
): AvailableFilters {
    const allTagsInUse = getFlatSet(categoryMenuItems.filter((mi) => mi.tags).map((mi) => mi.tags!));

    const availableTags = tagGroups.reduce((map, group) => {
        map[group.id] = group.isUserPreference
            ? group.scope === category.type
                ? group.tags
                : []
            : group.tags.filter((tag) => allTagsInUse.has(tag.id));
        return map;
    }, {} as { [tagGroupId: string]: Tag[] });

    const availableGroups = tagGroups.filter((g) => availableTags[g.id].length >= 2);

    const priceFilter = category.type === "drink" ? getPriceFilter(categoryMenuItems, priceResolver) : undefined;

    return {
        tagGroups: availableGroups,
        tags: availableTags,
        price: priceFilter,
    };
}

function getFlatSet<T>(values: T[][]) {
    const set = new Set();

    for (const array of values) {
        for (const value of array) {
            set.add(value);
        }
    }

    return set;
}
const MIN_PRICE_RANGE_SCALE = 20;

function getPriceFilter(menuItems: MenuItem[], priceResolver: PriceResolver): Range | undefined {
    const { min, max } = menuItems.reduce(
        (range, item) => {
            const prices = getMenuItemPrices(item, priceResolver);

            for (let price of prices) {
                if (price < range.min || range.min === 0) {
                    range.min = price;
                }

                if (price > range.max) {
                    range.max = price;
                }
            }

            return range;
        },
        { min: 0, max: 0 }
    );

    return max - min < MIN_PRICE_RANGE_SCALE
        ? undefined
        : { min: Math.floor(min / 5) * 5, max: Math.ceil(max / 5) * 5 };
}
