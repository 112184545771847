import { getFeaturedMembershipState } from "src/features/membership/selectors/getActiveMembershipState";
import { getMembershipSignupPromotion } from "src/features/membership/selectors/getMembershipSignupPromotion";
import { signupPromotionActions } from "src/features/membership/reducers";
import { preloadImage } from "src/common/shared";
import { AppDispatch, AppState } from "src/features/index";

export const setMembershipSignupPromotion = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const featuredMembership = getFeaturedMembershipState(state);
        if (!featuredMembership?.signupPromotion) {
            const membershipSignupPromotion = getMembershipSignupPromotion(state);
            if (membershipSignupPromotion) {
                dispatch(signupPromotionActions.setSignupPromotion(undefined));
            }
            return;
        }
        const { programId, programName, logoUrl, signupPromotion } = featuredMembership;
        signupPromotion.logoUrl = signupPromotion.logoUrl ?? logoUrl ?? null;
        dispatch(
            signupPromotionActions.setSignupPromotion({
                programId,
                programName,
                ...signupPromotion,
            })
        );
        signupPromotion.bannerUrl && preloadImage(signupPromotion.bannerUrl);
    };
};
