import React, { useCallback, useEffect, useState } from "react";
import "./HistoryListsPage.scss";
import { Button, Text } from "src/sharedComponents";
import { OrderNavIcon, OrionChat, TabIcon } from "src/sharedComponents/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getGroupTabHistories } from "src/features/groupTabsHistory/selectors/groupTabHistorySelector";
import { signInAction } from "src/features/signup/actions/signInAction";
import { useHistory, useParams } from "react-router";
import { actionCreators as accountMenuActionCreators } from "src/features/accountmenu/reducers/accountMenu";
import classNames from "classnames";
import { getIsAnonymous } from "src/features/accounts/selectors";
import { OrderHistoryList } from "src/features/accounts/components/OrderHistoryList";
import { SimpleNavHeader } from "src/common/navigation";
import { GroupTabHistoryList } from "src/features/groupTabsHistory/components/GroupTabHistoryList";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { getTabTypeName } from "../../groupTabs/selectors/activeGroupTab";

interface OrderHistoryRouteProps {
    standalone?: string;
    type?: "order" | "group-tab";
}

let lastTab: number | undefined;

export const HistoryListsPage = () => {
    const groupTabs = useSelector(getGroupTabHistories);
    const isAnonymous = useSelector(getIsAnonymous);
    const tabTypeName = useSelector(getTabTypeName);
    const history = useHistory();
    const { standalone, type } = useParams<OrderHistoryRouteProps>();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(
        lastTab !== undefined ? lastTab : !groupTabs || type === "order" ? 0 : 1
    );

    const login = useCallback(() => dispatch(signInAction()), [dispatch]);
    const onActiveTab = useCallback((index: number) => {
        lastTab = index;
        setActiveTab(index);
    }, []);

    const onBack = useCallback(() => {
        lastTab = undefined;
        if (standalone) {
            dispatch(accountMenuActionCreators.closeAccountMenu());
        } else {
            history.goBack();
        }
    }, [standalone, history, dispatch]);

    useEffect(() => {
        setActiveTab(lastTab !== undefined ? lastTab : !groupTabs || type === "order" ? 0 : 1);
    }, [groupTabs, type]);

    return (
        <div className="flex history-lists">
            <div className="bottom-shadow fixed-at-top">
                <SimpleNavHeader
                    title={groupTabs?.length ? undefined : "Order history"}
                    headerTabs={{
                        brandColor: true,
                        activeIndex: activeTab,
                        onTabClick: onActiveTab,
                        headerTabs: [
                            {
                                icon: <OrderNavIcon />,
                                name: "Orders",
                            },
                            {
                                icon: (
                                    <span className="history-lists__group-tab-icon">
                                        <TabIcon />
                                    </span>
                                ),
                                name: `${capitaliseFirstLetter(tabTypeName)}s`,
                            },
                        ],
                    }}
                    onBack={onBack}
                    customBack={activeTab ? "order-history-list" : "group-tab-history-lists"}
                />
            </div>
            <div
                className={classNames(
                    "flex-top",
                    "wrapper-for-fixed-nav",
                    "history-lists__container",
                    activeTab && "history-lists__container--second"
                )}
            >
                <OrderHistoryList />
                <GroupTabHistoryList />
            </div>
            {isAnonymous && (
                <div className="history-lists__login">
                    <div className="history-lists__login__content">
                        <OrionChat />
                        <Text preset="title-20" mode={["extra-bold", "block"]} className="history-lists__login__title">
                            Log in or sign up to view your previous orders.
                        </Text>
                        <Text preset="g-14" mode={["medium", "block"]} className="history-lists__login__description">
                            Save your previous orders, payment methods & more with a me&u account.
                        </Text>
                        <Button value="Log in or sign up" mode="solidinverted" brandColor onClick={login} />
                    </div>
                </div>
            )}
        </div>
    );
};
