export enum TypeKeys {
    CHECKING = "UPDATES/CHECKING",
    UPDATE_AVAILABLE = "UPDATES/UPDATE_AVAILABLE",
    UP_TO_DATE = "UPDATES/UP_TO_DATE",
}

export const actionCreators = {
    checking: () => ({ type: TypeKeys.CHECKING }),
    updateAvailable: () => ({ type: TypeKeys.UPDATE_AVAILABLE }),
    upToDate: () => ({ type: TypeKeys.UP_TO_DATE }),
};

export interface UpdatesState {
    status: UpdateStatus;
}

export type UpdateStatus = "checking" | "update-available" | "up-to-date";

type CheckingAction = { type: TypeKeys.CHECKING };
type UpdateAvailableAction = { type: TypeKeys.UPDATE_AVAILABLE };
type UpToDateAction = { type: TypeKeys.UP_TO_DATE };

type UpdatesAction = CheckingAction | UpdateAvailableAction | UpToDateAction;

const defaultState: UpdatesState = {
    status: "checking",
};

export function reducer(state = defaultState, action: UpdatesAction): UpdatesState {
    if (action.type === TypeKeys.CHECKING) {
        return {
            status: "checking",
        };
    }

    if (action.type === TypeKeys.UPDATE_AVAILABLE) {
        return {
            status: "update-available",
        };
    }

    if (action.type === TypeKeys.UP_TO_DATE) {
        return {
            status: "up-to-date",
        };
    }

    return state;
}
