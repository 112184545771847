import { Menu } from "../../menudata";
import { MenuCard, Props } from "../components/MenuCard";
import { connect } from "react-redux";
import { getVisibleMenuData } from "../selectors";
import { AppState } from "../..";

export interface OwnProps {
    menuId: string;
    menu: Menu;
    onBeforeNavigate?: () => void;
}

function mapStateToProps(state: AppState, props: OwnProps): Props {
    const { menuId, menu, onBeforeNavigate } = props;

    const { categories, hideProductImages } = getVisibleMenuData(state)!;

    return {
        menuId,
        menu,
        categories: menu.categories.map((id) => categories[id]),
        onBeforeNavigate,
        hideProductImages,
    };
}

export const MenuCardContainer = connect(mapStateToProps)(MenuCard);
