import { useCallback, useEffect, useState } from "react";
import { useRefValues } from ".";

// an accurate timer that doesn't have any of the browsers pitfalls
// https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95
// https://www.youtube.com/watch?v=MCi6AZMkxcU&list=PLNYkxOF6rcIAKIQFsNbV0JDws_G_bnNo9&index=2
export function exactTimeInterval(ms: number, signal: AbortSignal, callback: (ticks: number) => void) {
    // Prefer currentTime, as it'll better sync animations queued in the
    // same frame, but if it isn't supported, performance.now() is fine.
    const start = document.timeline?.currentTime ?? performance.now();
    let tick = 0;

    function frame(time: number) {
        if (signal.aborted) return;
        tick++;
        callback(tick);
        scheduleFrame(time);
    }

    function scheduleFrame(time: number) {
        const elapsed = time - start;
        const roundedElapsed = Math.round(elapsed / ms) * ms;
        const targetNext = start + roundedElapsed + ms;
        const delay = targetNext - performance.now();
        setTimeout(() => requestAnimationFrame(frame), delay);
    }

    scheduleFrame(start);
}

export function useExactTimeInterval(intervalMS: number, callback: (ticks: number) => void) {
    const [canRun, setCanRun] = useState(false);
    const [cbRef] = useRefValues(callback);

    const start = useCallback(() => {
        setCanRun(true);
    }, []);

    const cancel = useCallback(() => {
        setCanRun(false);
    }, []);

    const currentCB = cbRef.current;

    useEffect(() => {
        let controller: AbortController;
        if (canRun) {
            controller = new AbortController();
            exactTimeInterval(intervalMS, controller.signal, currentCB);
        }
        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [intervalMS, canRun, currentCB]);

    return [start, cancel];
}
