import { AvailablePrices } from "../../menudata";
import * as React from "react";
import { Price } from "../../menu/components/Price";

export interface Props {
    availablePrices: AvailablePrices;
    prefix?: string;
}

export const ModifierPrice = ({ availablePrices, prefix }: Props) => {
    const baseClass = "modifier__price";

    return (
        <Price
            priceTextPreset="g-14"
            className={baseClass}
            availablePrices={availablePrices}
            prefix={prefix}
            showBasePrice
            basePriceTextPreset="g-14"
            basePriceClassName={`${baseClass}--base`}
            wrapperClassName={`${baseClass}--wrapper`}
        />
    );
};
