import { onTappableElementClick, TappableElementProps, useTappable } from "./base/TappableBase";
import React, { AnchorHTMLAttributes, FC } from "react";

const TappableAnchor: FC<TappableElementProps<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>> = (
    props
) => {
    const [attributes, children] = useTappable(props);
    return (
        <a onClick={(e) => onTappableElementClick(e, props)} {...attributes}>
            {children}
        </a>
    );
};

export default TappableAnchor;
