import "./Chip.scss";

import React, { ReactNode } from "react";
import { Button } from "../button";
import { Text } from "../text";
import { TappableDiv } from "../../common/tappable";
import classNames from "classnames";

interface BaseChipProps {
    active?: boolean;
    onClick?: () => void;
    size?: "default" | "small" | "xsmall";
    icon?: React.ReactNode;
    className?: string;
    mode?: "default" | "dark" | "grey";
    disabled?: boolean;
    unthemed?: boolean;
}

interface OptionalChild extends BaseChipProps {
    children: React.ReactNode;
}

interface OptionalText extends BaseChipProps {
    text: string;
}

export type ChipProps = OptionalChild | OptionalText;

export const Chip = ({
    active,
    onClick,
    size = "default",
    icon,
    className,
    mode = "default",
    disabled,
    unthemed,
    ...props
}: ChipProps) => {
    const text = (props as OptionalText).text;
    const children = (props as OptionalChild).children;
    return (
        <TappableDiv onTap={onClick} className={classNames("chip", `chip--${size}`, className)} disabled={disabled}>
            <Button
                className={classNames("chip__button", `chip__button--${disabled ? "disabled" : mode}`)}
                mode={active ? "solid" : "outline"}
                onClick={onClick}
                disabled={disabled}
                unthemed={unthemed}
            >
                {icon && icon}
                {text !== undefined && <Text preset="g-14" mode="bold" value={text} />}
                {children}
            </Button>
        </TappableDiv>
    );
};
