import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WizardModal } from "src/features/wizards/components/WizardModal";
import { offerClaimFlowWizard } from "../wizard";
import { OfferClaimPageWrapper } from "./OfferClaimPageWrapper";
import { OfferClaimedPage } from "./OfferClaimedPage";
import { replace } from "connected-react-router";
import { getHasClaimOfferError, getOfferData } from "../selectors";
import { modalMessages } from "src/features/modalMessage/messages";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { getParty } from "src/features/order";
import { getTableToken } from "src/features/partyOnboarding/persistence/tableToken";
import { rejoinTable } from "src/features/partyOnboarding/actions/rejoinTable";
import { actionCreators } from "../reducers";
import { sendMarketingOptIn } from "src/features/marketing";
import { OfferType } from "../types";
import { StampCardClaimPage } from "./StampCardClaimPage";

interface Props {
    parentSelector?(): HTMLElement;
}

export const OfferModal = ({ parentSelector }: Props) => {
    const dispatch = useDispatch();
    const claimError = useSelector(getHasClaimOfferError);
    const party = useSelector(getParty);
    const tableToken = getTableToken();
    const offer = useSelector(getOfferData);

    const onDone = useCallback(() => {
        dispatch(offerClaimFlowWizard.actionCreators.done());
        dispatch(actionCreators.setOffer(undefined));
        dispatch(sendMarketingOptIn.actionCreators.reset());
        if (!party && tableToken) {
            dispatch(rejoinTable(tableToken));
        }
        dispatch(replace("/join-table"));
    }, [dispatch, party, tableToken]);

    useEffect(() => {
        if (claimError) {
            dispatch(offerClaimFlowWizard.actionCreators.prev("OfferPage"));
            dispatch(showModalMessage(modalMessages.claimOfferError()));
        }
    }, [claimError, dispatch]);

    const OfferPage = useMemo(() => {
        switch (offer?.offerType) {
            case OfferType.DISCOUNT:
                return OfferClaimPageWrapper;
            case OfferType.STAMP_CARD:
                return StampCardClaimPage;
            default:
                return OfferClaimPageWrapper;
        }
    }, [offer]);

    return (
        <WizardModal
            wizard={offerClaimFlowWizard}
            transition="slide-up"
            parentSelector={parentSelector}
            onReset={onDone}
            onComplete={onDone}
            onCloseComplete={onDone}
            pages={{
                OfferPage: {
                    render: (onSuccess) => <OfferPage onSuccess={onSuccess} onBack={onDone} />,
                    header: null,
                },
                OfferClaimed: {
                    render: () => <OfferClaimedPage onClose={onDone} />,
                    header: null,
                },
            }}
        />
    );
};
