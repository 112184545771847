import React from "react";
import classNames from "classnames";
import { Text } from "../text";

import "./Counter.scss";

export interface CounterProps {
    quantity: number;
    small?: boolean;
    max?: number;
    limitPillLengthToMax?: boolean;
    mode?: "outline" | "border";
    className?: string;
}

export const Counter = ({ quantity, small, max, limitPillLengthToMax = false, mode, className }: CounterProps) => (
    <div className={classNames("counter", !limitPillLengthToMax && small && "small", className)}>
        <div
            className={classNames(
                "counter__circle",
                mode && "mode-" + mode,
                !limitPillLengthToMax && quantity > 99 && "large-number"
            )}
        />
        <div className="counter__value">
            <div
                className={classNames(
                    "counter__value__number",
                    mode && "mode-" + mode,
                    !limitPillLengthToMax && quantity > 999 && "extra-large-number"
                )}
            >
                <Text
                    mode="bold"
                    value={
                        limitPillLengthToMax && max && quantity > max
                            ? "•••"
                            : max && quantity > max
                            ? `${max}+`
                            : quantity.toString()
                    }
                />
            </div>
        </div>
    </div>
);
