import { config } from "src/common/config";
import { loadExternalScript } from "src/common/shared";

interface GoogleRecaptchaExecutionOptions {
    action: string;
}

interface GoogleRecaptcha {
    ready(callback: () => void): void;
    execute(siteKey: string, options: GoogleRecaptchaExecutionOptions): Promise<string>;
}

const recaptchaSiteKey: string | undefined = config.recaptchaSiteKey;
const recaptchaEnabled: boolean = !!recaptchaSiteKey;

export const reCaptcha = {
    enabled: recaptchaEnabled,
    execute: async (action: string) => {
        if (!recaptchaSiteKey) {
            return null;
        }

        try {
            const grecaptcha: GoogleRecaptcha = await loadExternalScript(
                "grecaptcha.enterprise",
                `https://www.recaptcha.net/recaptcha/enterprise.js?render=${recaptchaSiteKey}`
            );

            return await new Promise<string | null>((res) => {
                grecaptcha.ready(() => {
                    grecaptcha.execute(recaptchaSiteKey, { action }).then(res, () => res(null));
                });
            });
        } catch (err) {
            return null;
        }
    },
};
