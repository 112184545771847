import { createOperation } from "../operations";

export const joiningTableOperation = createOperation<void>("PARTY_ONBOARDING/JOINING_TABLE");
export const joiningFlexTabPartyOperation = createOperation<void>("PARTY_ONBOARDING/JOINING_FLEX_TAB");
export const rejoiningTableOperation = createOperation<void>("PARTY_ONBOARDING/REJOINING_TABLE");
export const joiningPayOnlyPartyOperation = createOperation<void>("PARTY_ONBOARDING/JOINING_PAY_ONLY");
export const joiningOpenTablePartyOperation = createOperation<void>("PARTY_ONBOARDING/JOINING_OPEN_TABLE");
export const rejoiningPayOnlyPartyOperation = createOperation<void>("PARTY_ONBOARDING/REJOINING_PAY_ONLY");
export const leavingTableOperation = createOperation<void>("PARTY_ONBOARDING/LEAVING_TABLE");
export const changingLocationOperation = createOperation<void>("PARTY_ONBOARDING/CHANGING_LOCATION");
export const findingQrCodeOperation = createOperation<void>("PARTY_ONBOARDING/FINDING_QR_CODE");
export const downloadLoaderImagesOperation = createOperation<void>("PARTY_ONBOARDING/DOWNLOAD_LOADER_IMAGES");
export const downloadMenuBackgroundImageOperation = createOperation<void>(
    "PARTY_ONBOARDING/DOWNLOAD_MENU_BACKGROUND_IMAGE"
);
