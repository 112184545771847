import "../assets/MyOffersPage.scss";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { NavBarType } from "src/common/navigation/types";
import {
    getAvailableDiscountOffers,
    getAvailableOffers,
    getAvailableStampCardOffers,
    getDiscoverableStampCard,
    getOfferData,
} from "../selectors";
import { actionCreators } from "../reducers";
import { IdentifierScope, OfferData, OfferStatus } from "../types";
import { ViewOfferModal } from "./ViewOfferModal";
import { CallToActionHeader, Text } from "src/sharedComponents";
import { getActiveService, getLocationName } from "src/features/menu/selectors";
import { OfferTile } from "./OfferTile";
import { getOfferData as getOfferDataAction } from "../actions";
import { getActiveLocationId, getSelectedOfferHasItems } from "src/features/order/selectors";
import { getNavBarStatus } from "src/common/navigation";
import { SuccessfulMarketingOptInPage } from "./SuccessfulMarketingOptInPage";
import { NoOffersSection } from "./NoOffersSection";
import { getIsAnonymous } from "src/features/accounts/selectors";
import { CouponIconFilled, PadlockIcon } from "src/sharedComponents/assets/icons";
import { signInAction } from "src/features/signup/actions/signInAction";
import { fetchAllOffersOperation } from "../operations";
import { LoadingOffer } from "./LoadingOffer";
import { actionCreators as navBarActionCreators } from "src/common/navigation/reducers/navBar";
import { useHistory } from "react-router";
import { getProfile } from "src/features/accountmenu/selectors";
import { StampCard } from "./StampCard";
import { OfferClaimInPartyWizard } from "./OfferClaimInPartyWizard";
import { offerClaimFlowWizard } from "../wizard";
import { OfferItemsPage } from "./OfferItemsPage";

export const MyOffersPage = () => {
    const [shouldOpenOfferModal, setShouldOpenOfferModal] = useState(true);
    const [shouldOpenOrderModal, setShouldOpenOrderModal] = useState(true);
    const navBarStatus = useSelector(getNavBarStatus);
    const dispatch = useDispatch();
    const history = useHistory();
    const allAvailableOffers = useSelector(getAvailableOffers);
    const availableDiscountOffers = useSelector(getAvailableDiscountOffers);
    const availableStampCardOffers = useSelector(getAvailableStampCardOffers);
    const discoverableStampCard = useSelector(getDiscoverableStampCard);
    const venueName = useSelector(getLocationName);
    const locationId = useSelector(getActiveLocationId);
    const isAnonymous = useSelector(getIsAnonymous);
    const fetchOffersStatus = useSelector(fetchAllOffersOperation.getStatus);
    const activeService = useSelector(getActiveService);
    const { firstName } = useSelector(getProfile) || {};
    const loadingOffers = fetchOffersStatus === "processing";
    const selectedOffer = useSelector(getOfferData);
    const selectedOfferHasItems = useSelector(getSelectedOfferHasItems);

    const stampCardOffer = availableStampCardOffers?.[0] ?? discoverableStampCard;

    const visibleStampCardOffer = stampCardOffer
        ? {
              ...stampCardOffer,
              scopeId: locationId!,
              scope: IdentifierScope.LOCATION,
              source: encodeURIComponent("me&u Rewards tab"),
          }
        : undefined;

    const isOpen = navBarStatus === NavBarType.OFFERS;

    const setCurrentOffer = useCallback(
        (offer: OfferData) => {
            if (offer.isPartial) {
                dispatch(getOfferDataAction(locationId!, offer.id, IdentifierScope.LOCATION));
            } else {
                dispatch(actionCreators.setOffer(offer));
            }
        },
        [dispatch, locationId]
    );

    const onAfterViewOfferClose = () => {
        (!selectedOfferHasItems || !shouldOpenOrderModal) && dispatch(actionCreators.setOffer(undefined));
        setShouldOpenOfferModal(false);
    };

    const onViewOfferButtonClick = useCallback(() => {
        const closeAndNavigate = () => {
            setShouldOpenOfferModal(false);
            dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            if (activeService) {
                history.push(`/menu/${activeService.menus[0]}/${activeService.categories[0]}`);
            }
        };

        if (history.location.pathname !== "/menu/service") {
            history.replace(`/menu/service`);
            setTimeout(closeAndNavigate, 50);
        } else {
            closeAndNavigate();
        }
    }, [dispatch, activeService, history]);

    const onViewOfferShowItems = useCallback(() => {
        setShouldOpenOrderModal(true);
        dispatch(actionCreators.setItemsSelectionIndex(0));
    }, [dispatch]);

    const viewOfferButtonProps = selectedOfferHasItems
        ? {
              onClick: onViewOfferShowItems,
              value: "Order items",
          }
        : {
              onClick: onViewOfferButtonClick,
              value: "View menu",
          };

    const onOfferClick = (offer: OfferData) => {
        setCurrentOffer(offer);
        if (offer.stampCard && offer.status === OfferStatus.AVAILABLE) {
            dispatch(offerClaimFlowWizard.actionCreators.start("OfferPage"));
            setShouldOpenOfferModal(false);
        } else {
            setShouldOpenOfferModal(true);
        }
        dispatch(actionCreators.setItemsSelectionIndex(undefined));
    };

    const onOrderClick = (offer: OfferData) => {
        setCurrentOffer(offer);
        dispatch(actionCreators.setItemsSelectionIndex(0));
    };

    const subtitle = useMemo(() => {
        if (!allAvailableOffers?.length && !discoverableStampCard) {
            return null;
        }

        if (isAnonymous) {
            return (
                <>
                    <Text mode="bold">Log in</Text> to check out your rewards from {venueName}
                </>
            );
        }
        return (
            <>
                Hi{firstName ? ` ${firstName}` : ""}, check out your rewards from {venueName}.
            </>
        );
    }, [allAvailableOffers, firstName, discoverableStampCard, venueName, isAnonymous]);

    return (
        <CSSTransition in={isOpen || !!selectedOffer} classNames="party-wrapper-transition" timeout={150} unmountOnExit>
            <>
                <OfferClaimInPartyWizard />
                <ViewOfferModal
                    onAfterViewOfferClose={onAfterViewOfferClose}
                    onBack={() => {
                        setShouldOpenOrderModal(false);
                    }}
                    buttonProps={viewOfferButtonProps}
                    shouldOpen={shouldOpenOfferModal}
                />
                <OfferItemsPage
                    buttonsProps={{
                        onClick: onViewOfferButtonClick,
                        value: "Explore full menu",
                    }}
                />
                <SuccessfulMarketingOptInPage />
                <div className="my-offers-page">
                    <section className="my-offers-page__header">
                        <Text preset="title-28" mode={["extra-bold", "block"]} className="my-offers-page__title">
                            Rewards
                        </Text>
                        {subtitle && (
                            <Text preset="g-14" mode="block">
                                {subtitle}
                            </Text>
                        )}
                    </section>
                    {loadingOffers && (
                        <div className="my-offers-page__loading-offer">
                            <LoadingOffer />
                        </div>
                    )}
                    {isAnonymous && !loadingOffers && !visibleStampCardOffer && (
                        <div className="my-offers-page__sign-in">
                            <CallToActionHeader
                                title="Enjoy promotions and deals personalised just for you!"
                                titlePreset="title-20"
                                subtitle={`Log in or sign up to view your custom offers from ${venueName}.`}
                                ctaText="Log in or sign up"
                                ctaProps={{ mode: "solidinverted" }}
                                icon={<CouponIconFilled />}
                                onClick={() => dispatch(signInAction())}
                            />
                        </div>
                    )}
                    {!allAvailableOffers?.length && !isAnonymous && !loadingOffers && !discoverableStampCard && (
                        <NoOffersSection />
                    )}
                    {visibleStampCardOffer?.stampCard && !loadingOffers && (
                        <section className="my-offers-page__stamp-card">
                            <div className="my-offers-page__section-title-container">
                                <Text preset="g-18" mode="bold" className="my-offers-page__section-title">
                                    Stamp card
                                    {visibleStampCardOffer.status === OfferStatus.AVAILABLE && (
                                        <Text preset="g-12" mode="bold" className="my-offers-page__unclaimed">
                                            <PadlockIcon />
                                            Unclaimed
                                        </Text>
                                    )}
                                </Text>
                            </div>
                            <StampCard
                                stampCard={visibleStampCardOffer.stampCard}
                                title={visibleStampCardOffer.title}
                                subtitle={visibleStampCardOffer.subtitle}
                                onClick={() => onOfferClick(visibleStampCardOffer)}
                                claimButtonText={
                                    visibleStampCardOffer.status === OfferStatus.AVAILABLE
                                        ? "Claim this stamp card"
                                        : undefined
                                }
                                status={visibleStampCardOffer.status}
                                showActions
                            />
                        </section>
                    )}
                    {!!availableDiscountOffers?.length && !loadingOffers && (
                        <section className="my-offer-page__discount-offers">
                            <div className="my-offers-page__section-title-container">
                                <Text preset="g-18" mode={["bold", "block"]} className="my-offers-page__section-title">
                                    Your offers
                                </Text>
                                <Text preset="g-12" mode={"block"} className="my-offers-page__section-subtitle">
                                    Limit one per order
                                </Text>
                            </div>
                            {availableDiscountOffers.map((offer, index) => (
                                <OfferTile
                                    offer={offer}
                                    onDetailsClick={() => {
                                        setShouldOpenOrderModal(false);
                                        onOfferClick(offer);
                                    }}
                                    onOrderClick={offer.offerItems?.length ? () => onOrderClick(offer) : undefined}
                                    key={`${offer.id}-${index}`}
                                />
                            ))}
                        </section>
                    )}
                </div>
            </>
        </CSSTransition>
    );
};
