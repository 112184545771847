import * as React from "react";
import { connect } from "react-redux";
import { State } from "../reducer";

export interface DeepLinkWrapperProps {
    hasLoaded: boolean;
    children?: React.ReactNode;
}

export const DeepLinkWrapper = (props: DeepLinkWrapperProps) => {
    return props.children;
};

function mapStateToProps(state: State): DeepLinkWrapperProps {
    return {
        hasLoaded: state.deepLink.isLoaded,
    };
}

const DeepLinkWrapperContainer = connect(mapStateToProps)(DeepLinkWrapper as any);

export const withDeepLink =
    (Component: React.ComponentType<{}>): React.SFC<{}> =>
    () => {
        return (
            <DeepLinkWrapperContainer>
                <Component />
            </DeepLinkWrapperContainer>
        );
    };
