import { isChrome } from "react-device-detect";

const onTranslateFuncs: Set<(isEnabled: boolean) => void> = new Set();
let translateObserver: MutationObserver | null = null;
export const browserTranslate = {
    isSupported: isChrome,
    isEnabled: () =>
        browserTranslate.isSupported &&
        document.documentElement.className.includes("translated") &&
        document.documentElement.lang !== "en",
    observeTranslate: (cb: (isEnabled: boolean) => void) => {
        if (!browserTranslate.isSupported) return;
        if (!translateObserver) {
            translateObserver = new MutationObserver(() => {
                onTranslateFuncs.forEach((func) => {
                    func(browserTranslate.isEnabled());
                });
            });
            translateObserver.observe(document.documentElement, {
                attributes: true,
                attributeFilter: ["class", "lang"],
            });
        }
        onTranslateFuncs.add(cb);
    },
    disconnect: (cb: (isEnabled: boolean) => void) => {
        onTranslateFuncs.delete(cb);
        if (onTranslateFuncs.size === 0 && translateObserver) {
            translateObserver.disconnect();
            translateObserver = null;
        }
    },
};
