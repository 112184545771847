import { removeCardOperation } from "../operations";
import { SelectablePaymentMethod } from "../../../common/payment";
import { removePaymentMethod } from "../api/removePaymentMethod";

export function removeCard(paymentMethod: SelectablePaymentMethod, callback: () => void) {
    return removeCardOperation.getThunk(async () => {
        await removePaymentMethod(paymentMethod);
        callback();
    });
}
