import "../assets/OfferPage.scss";
import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { Text } from "src/sharedComponents";
import { SimpleNavHeader } from "src/common/navigation";
import { BrandedCouponIcon } from "src/sharedComponents/assets/icons";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { href } from "src/common/experience";
import { OfferData, OfferType } from "../types";

interface Props {
    onBack: () => void;
    offer: OfferData;
}

const getOfferPageFields = (offer: OfferData) => {
    switch (offer.offerType) {
        case OfferType.DISCOUNT:
            return [
                {
                    title: "Details",
                    text: offer.offerDetails?.description,
                },
                {
                    title: "Availability",
                    text: offer.offerDetails?.redeemDateSummary,
                },
                {
                    title: "Conditions",
                    text: offer.offerDetails?.complianceCopy,
                },
            ];
        case OfferType.STAMP_CARD:
            return [
                {
                    title: "Collecting stamps",
                    text: offer.offerDetails?.description,
                },
                {
                    title: "Completing the card",
                    text: offer.offerDetails?.complianceCopy,
                },
                {
                    title: "Availability",
                    text: offer.offerDetails?.redeemDateSummary,
                },
            ];
        default:
            return undefined;
    }
};

export const OfferPageContent = ({ onBack, offer, children }: PropsWithChildren<Props>) => {
    const pageFields = getOfferPageFields(offer);
    return (
        <div className="offer-page">
            <div className={classNames("scroll-element", "animated-child")}>
                <SimpleNavHeader backgroundTransparent circleClose closeToBack onBack={onBack} />
                <div className="offer-page__content">
                    <div className="offer-page__fallback-image">
                        <BrandedCouponIcon />
                    </div>
                    {offer.imageUrl && (
                        <div
                            className="offer-page__image"
                            style={{
                                background: `linear-gradient(0, rgba(0,0,0,0.3), rgba(0,0,0, 0.3)), url(${offer.imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        />
                    )}
                    <div className="offer-page__content-container">
                        <section className="offer-page__text-container">
                            <div className="offer-page__top-section">
                                <Text preset="title-32" mode={["extra-bold", "block"]}>
                                    {offer.title}
                                </Text>
                                {offer.subtitle && (
                                    <Text preset="g-14" className="offer-page__subtitle">
                                        {offer.subtitle}
                                    </Text>
                                )}
                            </div>
                            <div className="offer-page__middle-section">
                                {pageFields?.map((field) => {
                                    if (field.text) {
                                        return (
                                            <section className="offer-page__section" key={field.title}>
                                                <Text preset="g-14" mode={["bold", "block"]}>
                                                    {field.title}
                                                </Text>
                                                <Text
                                                    className="offer-page__section-text offer-page__section-text--multi-line"
                                                    preset="g-14"
                                                >
                                                    {field.text}
                                                </Text>
                                            </section>
                                        );
                                    }
                                    return null;
                                })}
                                {offer.offerDetails?.complianceLink && (
                                    <Text
                                        preset="g-14"
                                        className="offer-page__section-text terms-and-conditions"
                                        mode="block"
                                    >
                                        View the full{"\u00A0"}
                                        <TappableAnchor
                                            className="offer-page link"
                                            {...href.openInNewWindowAttributes(offer.offerDetails.complianceLink)}
                                        >
                                            Terms and Conditions.
                                        </TappableAnchor>
                                    </Text>
                                )}
                            </div>
                        </section>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
