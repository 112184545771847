import jwtDecode from "jwt-decode";
import { orderApi } from "src/features/order/orderApi";
import { browserSupport, oneTimePassword, reCaptcha } from "../experience";
import { isPreview } from "src/features/preview";
import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getLegacyRegion } from "../../features/region/utils/getLegacyRegion";
import { regionHelper } from "../../features/region";
import { FetchError } from "src/features/order/orderApi/FetchError";
import { apiBackoff } from "src/features/order/orderApi/serverOrderApi";
import { getTableTokenHeaders } from "../../features/partyOnboarding/persistence/tableToken";
import { IdentityValidationRequest } from "../sso";
import { SsoError } from "../../features/signup/util/SsoError";
import { config } from "../../common/config";

const enableAuthRestore = config.enableAuthRestore === "1";

interface AuthResponse {
    id_token: string;
    expires_in: number;
    refresh_token: string;
    access_token: string;
    token_type: string;
    is_anonymous?: boolean;
}

export type AuthStateChangeReason =
    | "restore" // User authentication state is being restored
    | "login" // User is logging in (includes anonymous)
    | "logout" // User is logging out
    | "refresh" // Auth state has refreshed
    | "failed"; // Auth state failed to refresh

export type AuthStateListener = (
    state: AuthState,
    reason: AuthStateChangeReason,
    stateSource: string,
    prevStateSource?: string
) => void;

class Auth {
    restore = async (reason: AuthStateChangeReason = "login") => {
        if (!enableAuthRestore) {
            return await this.anonymousAuth();
        }

        const response = await orderApi.send(
            "/account/auth/restore",
            {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
            },
            apiBackoff()
        );

        if (!response.ok) {
            throw response;
        }

        const result = (await response.json()) as AuthenticationResult;

        const newState: AuthState = {
            idToken: "",
            expiresAt: Date.now() + result.expiresIn * 1000,
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
            isAnonymous: result.isAnonymous ?? false,
            authProvider: "meandu",
        };

        this.setAuthState(newState, reason);

        return newState;
    };

    anonymousAuth = async (reason: AuthStateChangeReason = "login") => {
        const response = await orderApi.send(
            "/account/anonymoustoken",
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
            },
            apiBackoff()
        );

        if (!response.ok) {
            throw response;
        }
        const result = await response.json();
        this.setSession(result, reason);

        return this.getState()!;
    };

    setSession(
        { id_token, expires_in, access_token, refresh_token, is_anonymous }: AuthResponse,
        reason: AuthStateChangeReason
    ) {
        if (id_token === undefined || expires_in === undefined) {
            return;
        }

        const model: AuthState = {
            authProvider: is_anonymous ? undefined : "meandu",
            idToken: id_token,
            expiresAt: Date.now() + (expires_in - 60) * 1000,
            accessToken: access_token,
            refreshToken: refresh_token,
            isAnonymous: is_anonymous,
        };

        this.setAuthState(model, reason);
    }

    private authState?: AuthState | null;
    private authStateSource?: string;

    private authStateListeners: AuthStateListener[] = [];

    onAuthStateChanged(listener: AuthStateListener) {
        this.authStateListeners.push(listener);
    }

    getAuthStateSource(): string | undefined {
        return this.authStateSource;
    }

    setAuthState(state: AuthState, reason: AuthStateChangeReason) {
        const prevStateSource = this.authStateSource;

        try {
            if (state === null) {
                localStorage.removeItem(`auth_${regionHelper.region.id}`);
            } else {
                localStorage.setItem(`auth_${regionHelper.region.id}`, JSON.stringify(state));
            }

            // Either way, we can remove the pre-region auth key
            if (regionHelper.region.id === getLegacyRegion()) {
                localStorage.removeItem("auth");
            }

            this.authStateSource = "runtime";
        } catch {
            this.authStateSource = "transient";
        }

        this.authState = state;

        for (let listener of this.authStateListeners) {
            listener(state, reason, this.authStateSource, prevStateSource);
        }
    }

    isAuthenticated = () => {
        const authState = this.getState();

        if (authState === null) {
            return false;
        }

        const { expiresAt } = authState;

        return !!authState.refreshToken || Date.now() < expiresAt;
    };

    private sharedGetIdToken: Promise<string> | null = null;

    async getIdToken(): Promise<string> {
        if (regionHelper.regionPromise) {
            await regionHelper.regionPromise;
        }

        if (this.sharedGetIdToken === null) {
            this.sharedGetIdToken = this.getIdTokenInternal();
        }

        try {
            return await this.sharedGetIdToken;
        } finally {
            this.sharedGetIdToken = null;
        }
    }

    private async getIdTokenInternal() {
        const state = this.getState();

        if (state !== null && Date.now() < state.expiresAt) {
            return state.accessToken;
        }

        const refreshedState = await this.refresh(state ?? ({ isAnonymous: true } as AuthState));

        return refreshedState.accessToken;
    }

    canRefreshToken(authToken: string) {
        try {
            return parseJwt(authToken)!.aud === "member";
        } catch {
            return false;
        }
    }

    private sharedRefreshIdToken: Promise<string> | null = null;

    async refreshIdToken(): Promise<string> {
        if (this.sharedRefreshIdToken === null) {
            this.sharedRefreshIdToken = this.refreshIdTokenInternal();
        }

        try {
            return await this.sharedRefreshIdToken;
        } finally {
            this.sharedRefreshIdToken = null;
        }
    }

    async refreshIdTokenInternal(): Promise<string> {
        const state = this.getState();

        if (state === null) {
            throw Error("User is not authenticated");
        }

        const newState = await this.refresh(state);

        return newState.accessToken;
    }

    refreshState() {
        this.authState = undefined;
        this.getState();
    }

    getState() {
        if (!browserSupport.isSupportedBrowser) {
            this.authStateSource = "unsupportedBrowser";
            return null;
        }

        if (this.authState !== undefined) {
            return this.authState;
        }

        this.authState = null;

        if (!regionHelper.region) {
            this.authStateSource = "noRegion";
            return this.authState;
        }

        let authValue = localStorage.getItem(`auth_${regionHelper.region.id}`);
        this.authStateSource = "localStorage";

        if (authValue === null && regionHelper.region.id === getLegacyRegion()) {
            authValue = localStorage.getItem("auth");
            this.authStateSource = "legacyStorage";
        }

        if (authValue !== null) {
            try {
                this.authState = JSON.parse(authValue) as AuthState;
            } catch {
                this.authStateSource = "failedParse";
            }
        } else {
            this.authStateSource = "none";
        }

        return this.authState;
    }

    canVerify = async (phoneNumber: string) => {
        const headers = await getAuthHeaders();
        headers.append("Content-Type", "application/json");

        const response = await orderApi.send("/account/auth/phone/canverify", {
            method: "POST",
            body: JSON.stringify({
                phoneNumber,
            }),
            headers,
        });

        await SsoError.throwError(response);
    };

    passwordlessLogin = async (phoneNumber: string, inParty: boolean) => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();
        headers.append("Content-Type", "application/json");

        const captchaToken =
            regionHelper.region.enableRecaptcha === true ? await reCaptcha.execute("MemberAuthOTP") : null;

        const response = await orderApi.send("/account/auth/phone/start", {
            method: "POST",
            body: JSON.stringify({
                phoneNumber,
                otpFormat: oneTimePassword.isSupported ? oneTimePassword.otpFormat : null,
                captchaToken,
                captchaSiteKey: config.recaptchaSiteKey,
            }),
            headers,
        });

        await SsoError.throwError(response);
    };

    verify = async (request: IdentityValidationRequest) => {
        const headers = await getAuthHeaders();
        headers.set("Content-Type", "application/json");

        const response = await orderApi.send("/account/auth/validate", {
            method: "POST",
            body: JSON.stringify(request),
            headers,
        });

        await SsoError.throwError(response);

        const result = (await response.json()) as AuthenticationResult;

        this.setAuthState(
            {
                idToken: "",
                expiresAt: Date.now() + result.expiresIn * 1000,
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
                isAnonymous: false,
                authProvider: "meandu",
            },
            "login"
        );
        return;
    };

    private async refresh({ refreshToken, isAnonymous }: AuthState) {
        if (isAnonymous) {
            return await this.restore();
        }

        const response = await orderApi.send(
            "/account/auth/refresh",
            {
                method: "POST",
                body: JSON.stringify({
                    refreshToken,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            },
            apiBackoff()
        );

        if (!response.ok) {
            if (response.status === 400) {
                logAuthStateFailure(response);

                return await this.anonymousAuth("failed");
            }

            throw new Error(await response.text());
        }

        const result = (await response.json()) as AuthenticationResult;

        const newState = {
            idToken: "",
            expiresAt: Date.now() + result.expiresIn * 1000,
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
            isAnonymous: false,
            authProvider: "meandu",
        };

        this.setAuthState(newState, "refresh");

        return newState;
    }

    getId() {
        const token = this.getState();

        if (!token) {
            return "";
        }

        if (token.accessToken === "MEANDU") {
            return token.accessToken;
        }

        return jwtDecode<BasicJwt>(token.accessToken).sub;
    }

    getIsAnonymous = () => this.getState()?.isAnonymous ?? true;

    logout = () => this.anonymousAuth("logout");
}

interface BasicJwt {
    sub: string;
}

export interface AuthState {
    idToken: string;
    accessToken: string;
    expiresAt: number;
    refreshToken?: string;
    isAnonymous?: boolean;
    authProvider?: string;
}

interface AuthenticationResult {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    isAnonymous: boolean;
}

function parseJwt(jwt: string): { [claim: string]: string | undefined } {
    return JSON.parse(atob(jwt.split(".")[1]));
}

export const auth = new Auth();

export async function getAuthHeaders(): Promise<Headers> {
    try {
        const idToken = await auth.getIdToken();

        return createAuthHeaders(idToken);
    } catch (e) {
        if (isPreview || !browserSupport.isSupportedBrowser) {
            return new Headers();
        }

        throw e;
    }
}

function createAuthHeaders(idToken: string) {
    return new Headers({
        Authorization: `Bearer ${idToken}`,
    });
}

function logAuthStateFailure(response: Response) {
    const ai = getAppInsights();
    if (ai) {
        ai.trackEvent({
            name: "APPLICATION/AUTH_STATE_FAILED",
        });
        ai.trackException({
            exception: new FetchError(response),
            severityLevel: SeverityLevel.Error,
        });
    }
}
