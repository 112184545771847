import { SurveyState, SurveyDetails } from "./types";

export interface State {
    survey: SurveyState;
}

enum TypeKeys {
    SET_TAGS = "SURVEY/SET_TAGS",
    SET_POSITIVE_NOTE = "SURVEY/SET_POSITIVE_NOTE",
    SET_NEGATIVE_NOTE = "SURVEY/SET_NEGATIVE_NOTE",
    SET_EMAIL = "SURVEY/SET_EMAIL",
    SET_DETAILS = "SURVEY/SET_DETAILS",
    RESET_SURVEY = "SURVEY/RESET",
}

export const actionCreators = {
    setTags: (tags: string[]) => ({ type: TypeKeys.SET_TAGS, tags }),
    setPositiveNote: (note: string) => ({ type: TypeKeys.SET_POSITIVE_NOTE, note }),
    setNegativeNote: (note: string) => ({ type: TypeKeys.SET_NEGATIVE_NOTE, note }),
    setEmail: (email: string) => ({ type: TypeKeys.SET_EMAIL, email }),
    setDetails: (surveyDetails: SurveyDetails) => ({ type: TypeKeys.SET_DETAILS, surveyDetails }),
    resetSurvey: () => ({ type: TypeKeys.RESET_SURVEY }),
};

type SetTagsAction = { type: TypeKeys.SET_TAGS; tags: string[] };
type SetPositiveNoteAction = { type: TypeKeys.SET_POSITIVE_NOTE; note: string };
type SetNegativeNoteAction = { type: TypeKeys.SET_NEGATIVE_NOTE; note: string };
type SetEmailAction = { type: TypeKeys.SET_EMAIL; email: string };
type SetDetailsAction = { type: TypeKeys.SET_DETAILS; surveyDetails: SurveyDetails };
type ResetSurveyAction = { type: TypeKeys.RESET_SURVEY };

export type SurveyActions =
    | SetTagsAction
    | SetPositiveNoteAction
    | SetNegativeNoteAction
    | SetEmailAction
    | SetDetailsAction
    | ResetSurveyAction;

const initialState: SurveyState = {
    tags: [],
    positiveNote: "",
    negativeNote: "",
    email: "",
    details: null,
};

export function reducer(state: SurveyState = initialState, action: SurveyActions): SurveyState {
    if (action.type === TypeKeys.SET_TAGS) {
        return {
            ...state,
            tags: action.tags,
        };
    }
    if (action.type === TypeKeys.SET_POSITIVE_NOTE) {
        return {
            ...state,
            positiveNote: action.note,
        };
    }
    if (action.type === TypeKeys.SET_NEGATIVE_NOTE) {
        return {
            ...state,
            negativeNote: action.note,
        };
    }
    if (action.type === TypeKeys.SET_EMAIL) {
        return {
            ...state,
            email: action.email,
        };
    }
    if (action.type === TypeKeys.SET_DETAILS) {
        return {
            ...state,
            details: action.surveyDetails,
        };
    }
    if (action.type === TypeKeys.RESET_SURVEY) {
        return initialState;
    }
    return state;
}
