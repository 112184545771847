import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getWizardPackageVisibleData } from "../selectors/groupTabWizardSelectors";
import { Button, Text } from "src/sharedComponents";
import { PackageMenu } from "./PackageMenu";
import { getActivePackageVisibleData } from "../selectors/activeGroupTab";
import "./GroupTabLimitedMenuPackagePreview.scss";
import { getTableLabel } from "src/features/order/selectors";

interface Props {
    onSuccess: () => void;
    isPreviewModal: boolean;
}

export const GroupTabLimitedMenuPackagePreview = ({ onSuccess, isPreviewModal }: Props) => {
    const wizardData = useSelector(getWizardPackageVisibleData);
    const groupTabData = useSelector(getActivePackageVisibleData);
    const tableLabel = useSelector(getTableLabel);
    const [packageData, packageDisplayDetails, categoryIds] = isPreviewModal ? groupTabData : wizardData;

    if (!packageData) return null;

    return (
        <div className="package-preview">
            <main className="scroll-element animated-child">
                <div
                    className={classNames(
                        "group-tab-wizard__title profile-page__title",
                        isPreviewModal && "package-preview__modal-title"
                    )}
                >
                    <Text preset="title-28" mode="bold">
                        {packageData.displayName}
                    </Text>
                    {packageData.description && (
                        <Text preset="g-14" mode="block" className="group-tab-wizard__description">
                            {packageData.description}
                        </Text>
                    )}
                </div>
                <div className="package-preview__availability-message">
                    <Text
                        preset="g-14"
                        mode="block"
                        value={`Some items may not be available to order from your ${tableLabel}.`}
                    />
                </div>
                <PackageMenu packageDetails={packageDisplayDetails} categoryIds={categoryIds} />
            </main>
            {!isPreviewModal && (
                <footer className="top-shadow">
                    <Button type="submit" value="Select package" onClick={onSuccess} />
                </footer>
            )}
        </div>
    );
};
