import "./ActionableRow.scss";

import React, { ReactNode } from "react";
import { TappableDiv } from "../../common/tappable";
import { Text } from "../../";
import { SmallArrowRightIcon } from "../../assets/icons";
import { Divider } from "../divider";

export interface ActionableRowProps {
    title: string;
    description?: string;
    icon?: ReactNode;
    showArrowIcon?: boolean;
    onClick: () => void;
}

export const ActionableRow = ({ onClick, icon, title, description, showArrowIcon }: ActionableRowProps) => (
    <>
        <TappableDiv onClick={onClick} className="actionable-row">
            {icon && (
                <Text preset="g-14" className="actionable-row__right">
                    {icon}
                </Text>
            )}
            <div className="actionable-row__text">
                <Text preset="g-16" mode="bold" className="actionable-row__title">
                    {title}
                </Text>
                {description && (
                    <Text preset="g-14" className="actionable-row__description">
                        {description}
                    </Text>
                )}
            </div>
            {showArrowIcon && (
                <div className="actionable-row__arrowIcon">
                    <SmallArrowRightIcon />
                </div>
            )}
        </TappableDiv>
        <Divider space="none" fullWidth />
    </>
);
