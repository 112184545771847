import { AppDispatch, AppState } from "../../index";
import { getGoogleTagManagerId } from "../../order/selectors/restaurantFlags";
import { refreshApp } from "src/common/experience";
import { gdpr } from "src/common/experience/web";
import { initializeGoogleTagManagerOperation } from "../operations";
import { fetchExternalScript } from "src/common/shared";
import { getParty } from "../../order";
import { getMenuData } from "../../menu/selectors";
import { CookiePreferences } from "src/common/experience/interface";
import { isOrderAndPayParty } from "src/features/order/helpers";

let prevGoogleTagManagerId: string | undefined;
let prevCookiePreferences: CookiePreferences | undefined;
let prevLocationId: string | undefined;

const pushLocation = (locationId: string, locationName?: string) => {
    prevLocationId = locationId;
    window.dataLayer?.push({
        meandu: {
            locationId,
            locationName,
        },
    });
};

const gtag = function (_: string, __: string, ___: any) {
    window.dataLayer?.push(arguments);
};

const getConsent = (allowed: boolean) => (allowed ? "granted" : "denied");

enum Consent {
    ADS = "ad_storage",
    ANALYTICS = "analytics_storage",
}

export const initializeGoogleTagManager = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const party = getParty(state);
        const menuData = getMenuData(state);
        const googleTagManagerId = getGoogleTagManagerId(state) || undefined;

        const cookiePreferences = gdpr.getCookiePreferences();

        if (window.dataLayer && prevGoogleTagManagerId) {
            // GTM is already initialized
            if (googleTagManagerId !== prevGoogleTagManagerId) {
                // ID has changed so restart the app
                refreshApp();
                return;
            }
            if (party && party.restaurantId !== prevLocationId) {
                pushLocation(party.restaurantId, menuData?.title);
            }
            if (prevCookiePreferences) {
                if (prevCookiePreferences.allowAdvertising !== cookiePreferences.allowAdvertising) {
                    gtag("consent", "update", {
                        [Consent.ADS]: getConsent(cookiePreferences.allowAdvertising),
                    });
                }
                if (prevCookiePreferences.allowAnalytics !== cookiePreferences.allowAnalytics) {
                    gtag("consent", "update", {
                        [Consent.ANALYTICS]: getConsent(cookiePreferences.allowAnalytics),
                    });
                }
            }
            prevCookiePreferences = cookiePreferences;
            return;
        }

        if (!googleTagManagerId || !party || !isOrderAndPayParty(party)) {
            // GTM isn't initialized but we can't continue as we don't have an ID
            // we aren't allowed to use analytics or it's not an Order & Pay party
            return;
        }

        prevGoogleTagManagerId = googleTagManagerId;
        prevCookiePreferences = cookiePreferences;

        dispatch(
            initializeGoogleTagManagerOperation.getThunk(async () => {
                window.dataLayer = [
                    {
                        "gtm.allowlist": [
                            "sandboxedScripts", // Custom Templates (restricted by polices below)
                            "e", // Event triggers
                            "nonGooglePixels", // Pixels to non-google sources
                            "gaawe", // GA4 Event tag
                            "awct", // Google Ad Converstion tag
                        ],
                    },
                ];
                gtag("policy", "all", function (_: string, permissionId: string, data: any) {
                    switch (permissionId) {
                        case "logging":
                        case "listen_data_layer":
                        case "read_data_layer":
                        case "read_event_metadata":
                        case "read_event_data":
                        case "access_template_storage":
                        case "send_pixel":
                            return true;
                        case "access_consent":
                            const { read, write, consentType } = data ?? {};
                            return read && !write && (consentType === Consent.ADS || consentType === Consent.ANALYTICS);
                        default:
                            return false;
                    }
                });
                gtag("consent", "default", {
                    [Consent.ADS]: getConsent(cookiePreferences.allowAdvertising),
                    [Consent.ANALYTICS]: getConsent(cookiePreferences.allowAnalytics),
                });
                await fetchExternalScript(`https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`);
                window.dataLayer.push({ "gtm.start": Date.now(), event: "gtm.js" });
                pushLocation(party.restaurantId, menuData?.title);
            })
        );
    };
};
