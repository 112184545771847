import * as React from "react";
import { truncateString } from "../util/helpers";
import { Text } from "src/sharedComponents";

export interface Props {
    name: string;
}

export const VariantLabel = ({ name }: Props) => {
    return (
        <Text preset="g-14" mode="bold" className="price__label">
            {truncateString(name, 1)}
        </Text>
    );
};
