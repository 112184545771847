import { createSelector } from "reselect";
import { getIsPaused } from "src/features/partyOnboarding/selectors";
import { closeGroupTabOperation } from "../operations";
import { AppState } from "src/features";

const closeGroupTabOperationState = (state: AppState) => closeGroupTabOperation.getStatus(state);

export const getIsGroupTabPollingPaused = createSelector(
    getIsPaused,
    closeGroupTabOperationState,
    (appPaused, state) => appPaused || state === "processing"
);
