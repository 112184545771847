import { orderApi } from "src/features/order/orderApi";
import { getAuthHeaders } from "src/common/auth";

export async function fetchTakeawayCollectionDetails(locationId: string, partyId: string) {
    const response = await orderApi.send(`/${locationId}/${partyId}/collectionDetails`, {
        method: "GET",
        headers: await getAuthHeaders(),
    });

    if (!response.ok) {
        throw response;
    }

    const result = await response.json();

    return result;
}
