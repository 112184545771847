import { AnyAction, Dispatch } from "redux";
import { actionCreators } from "../reducers/survey";

export function setTags(tags: string[]) {
    return async (dispatch: Dispatch<AnyAction>) => {
        dispatch(actionCreators.setTags(tags));
    };
}

export function setPositiveNote(note: string) {
    return async (dispatch: Dispatch<AnyAction>) => {
        dispatch(actionCreators.setPositiveNote(note));
    };
}

export function setNegativeNote(note: string) {
    return async (dispatch: Dispatch<AnyAction>) => {
        dispatch(actionCreators.setNegativeNote(note));
    };
}

export function setEmail(email: string) {
    return async (dispatch: Dispatch<AnyAction>) => {
        dispatch(actionCreators.setEmail(email));
    };
}
