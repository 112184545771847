import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";
import "./HeaderBackgroundImage.scss";

interface Props {
    url?: string;
    children?: ReactNode;
    className?: string;
    imagePercent?: number;
    backgroundGradientDecimal?: number;
}

export const HeaderBackgroundImage = ({
    url,
    children,
    className,
    imagePercent = 0.3,
    backgroundGradientDecimal = 0.3,
}: Props) => {
    return (
        <div
            className={classNames("header-background-image", className)}
            style={
                {
                    backgroundImage: `linear-gradient(rgba(0,0,0,${backgroundGradientDecimal}),rgba(0,0,0,${backgroundGradientDecimal})), url(${url})`,
                    "--image-height": imagePercent,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                } as CSSProperties
            }
        >
            {children}
        </div>
    );
};
