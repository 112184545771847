// tslint:disable:jsx-no-lambda

import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { regionHelper } from "./features/region";
import { NativeEventListener } from "./features/native/components/NativeEventListener";
import { SpinnerModal } from "./features/spinnerModal/components/SpinnerModal";
import { withDeepLink } from "./features/deeplink/components/withDeepLink";
import { JoinTablePageContainer } from "./features/partyOnboarding/containers/JoinTablePageContainer";
import { ModalNotification } from "./features/notifications/components/ModalNotification";
import { ToastifiedMessageContainer } from "./features/notifications/containers/ToastifiedMessageContainer";
import { toast } from "react-toastify";
import { CloseIcon } from "./sharedComponents/assets/icons";
import { StatusBar } from "./common/statusBar";
import { ProfileModal } from "./features/accountmenu/components/ProfileModal";
import { OrderHistoryItemModalContainer } from "./features/orderHistoryItem";
import { RequirePartyContainer } from "./features/party/containers/RequirePartyContainer";
import { RequireMenuDataContainer } from "./features/party/containers/RequireMenuDataContainer";
import { RequireActiveInstanceContainer } from "./features/multiInstance/containers/RequireActiveInstance";
import { DuplicatedPage } from "./features/multiInstance/components/DuplicatedPage";
import { MarketingContainer } from "./features/marketing";
import { PreviewPageContainer } from "./features/preview";
import { AcceptTermsUpdateContainer } from "./features/marketing/containers/AcceptTermsUpdateContainer";
import { PartyContainer } from "./features/party/containers/PartyContainer";
import { ModalMessageModal } from "./features/modalMessage/components/ModalMessageModal";
import { GroupTabOverviewModal } from "./features/groupTabs/components/GroupTabOverviewModal";
import { GroupTabHistoryModal } from "./features/groupTabsHistory/components/GroupTabHistoryModal";
import { CreateVerifiedAccountWizard } from "./features/signup/components/CreateVerifiedAccountWizard";
import { PayOnly } from "./features/payOnly/components/PayOnly";
import { NoNetworkNotification } from "./features/notifications/components/NoNetworkNotification";
import { SignInWizard } from "./features/signup/components/SignInWizard";
import { OpenTableOrderStateWizard } from "./features/openTable/components/OpenTableOrderStateWizard";
import { SecuredPaymentWizard } from "./features/securedPayment/SecuredPaymentWizard";
import { PayOnlyPaymentStateWizard } from "./features/payOnly/components/PayOnlyPaymentStateWizard";
import { OfferModal } from "./features/offers/components/OfferModal";
import { MembershipSignupPromotion } from "src/features/membership/components/MembershipSignupPromotion";

const defaultUrl = "/join-table";

// This needs to be imported in this file otherwise something breaks in selectors
export const notUsed = () => regionHelper;

class App extends React.Component<any> {
    constructor(props: any) {
        super(props);
        toast.configure({
            autoClose: 4000,
            draggable: true,
            closeButton: <CloseIcon />,
        });
    }

    // Add any modal that shouldn't be open during join table to the `closeAllModals` in joinTable.ts
    public render() {
        return (
            <RequireActiveInstanceContainer
                success={() => (
                    <>
                        <NativeEventListener />
                        <StatusBar backgroundColor="#fff" />
                        <GroupTabOverviewModal />
                        <ModalMessageModal />
                        <SpinnerModal />
                        <ModalNotification />
                        <ToastifiedMessageContainer />
                        <GroupTabHistoryModal />
                        <OrderHistoryItemModalContainer />
                        <MarketingContainer />
                        <AcceptTermsUpdateContainer />
                        <CreateVerifiedAccountWizard />
                        <SignInWizard />
                        <SecuredPaymentWizard />
                        <OpenTableOrderStateWizard />
                        <PayOnlyPaymentStateWizard />
                        <MembershipSignupPromotion />
                        <Route
                            render={({ location, history }) => (
                                <Switch location={location}>
                                    <Redirect exact from="/" to={defaultUrl} />
                                    <Route
                                        render={() => (
                                            <>
                                                <Route component={ProfileModal} />
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/join-table"
                                                        component={JoinTablePageContainer}
                                                    />
                                                    <Route exact path="/preview" component={PreviewPageContainer} />
                                                    <Route path={"/offer"} component={OfferModal} />
                                                    <Route path={"/pay-only"} component={PayOnly} />
                                                    <Route
                                                        render={() => (
                                                            <RequireMenuDataContainer
                                                                success={() => (
                                                                    <RequirePartyContainer
                                                                        open={true}
                                                                        success={() => (
                                                                            <PartyContainer
                                                                                location={location}
                                                                                history={history}
                                                                            />
                                                                        )}
                                                                        fallback={() => (
                                                                            <PartyContainer
                                                                                location={location}
                                                                                history={history}
                                                                                noParty
                                                                            />
                                                                        )}
                                                                    />
                                                                )}
                                                                fallback={() => <Redirect to={defaultUrl} />}
                                                            />
                                                        )}
                                                    />
                                                </Switch>
                                            </>
                                        )}
                                    />
                                </Switch>
                            )}
                        />
                        <NoNetworkNotification />
                    </>
                )}
                fallback={() => <DuplicatedPage />}
            />
        );
    }
}

export default withDeepLink(App);
