import "./PayOnlyPaymentDetail.scss";

import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { useDispatch, useSelector } from "react-redux";
import { PaymentItem } from "../../payment/components/PaymentItem";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";
import {
    getHasPaymentOrder,
    getPayOnlyExclusiveSubTotal,
    getPayOnlyPaymentDetail,
    getPayOnlySplitPaymentOptions,
    getPayOnlyState,
    getVenueSurchargeAmount,
    getVenueSurchargePercentage,
} from "../selectors";
import { TitleWithAction } from "../../../sharedComponents/controls/titleWithAction/TitleWithAction";
import { Divider, Prompt } from "../../../sharedComponents";
import { getSelectedPaymentMethod } from "../../payment/selectors";
import { actionCreators } from "../reducers";
import { ChangePaymentMethodAction } from "../../paymentMethods/components/ChangePaymentMethodAction";
import { payOnlyPaymentWizard } from "../wizards";
import { getVenueServiceFeeText } from "../utils";
import { getIsOpenTable } from "../../openTable/selectors";
import { getCanShowDemoFeatures } from "src/features/order/selectors";
import { AlertIcon } from "src/sharedComponents/assets/icons";
import { getSurchargeText } from "../../payment/util/getSurchargeText";

export const PayOnlyPaymentDetail = () => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const dispatch = useDispatch();

    const isDemo = useSelector(getCanShowDemoFeatures);
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
    const { splitOption } = useSelector(getPayOnlyState);
    const splitPaymentOptions = useSelector(getPayOnlySplitPaymentOptions);
    const isOpenTable = useSelector(getIsOpenTable);
    const hasPaymentOrder = useSelector(getHasPaymentOrder);
    const isSplitByItem = splitOption === "SelectItems";
    const {
        total,
        subTotal,
        processingFee,
        tipAmount,
        paymentType,
        paying,
        venueServiceFee,
        venueServiceFeeFactor,
        exclusiveTaxTotal,
        discountAmount,
    } = useSelector(getPayOnlyPaymentDetail);
    const exclusiveSubTotal = useSelector(getPayOnlyExclusiveSubTotal);
    const venueSurchargeAmount = useSelector(getVenueSurchargeAmount);
    const venueSurchargePercentage = useSelector(getVenueSurchargePercentage);

    const [isPayingTotal] = useState(!splitOption);

    const changePaymentMethod = useCallback(() => {
        dispatch(payOnlyPaymentWizard.actionCreators.standalone("SelectPaymentMethod"));
    }, [dispatch]);

    const changeAmount = useCallback(() => {
        dispatch(payOnlyPaymentWizard.actionCreators.done());
        dispatch(actionCreators.reset());
    }, [dispatch]);

    return (
        <div className="pay-only-payment-detail">
            {hasPaymentOrder && isDemo && (
                <Prompt
                    type="warning"
                    title="You’re in demo mode"
                    subtitle={
                        isOpenTable
                            ? "POS will receive your order, but payments will not be processed."
                            : "Payments will not be processed."
                    }
                    titleLeftIcon={<AlertIcon />}
                />
            )}
            {isOpenTable && isPayingTotal && (
                <Prompt
                    subtitle="By paying the total amount, your current order will be closed & the venue will not receive any items that haven’t been sent yet."
                    className="open-table-notification"
                />
            )}
            <TitleWithAction
                title="Paying"
                onClick={splitPaymentOptions.length ? changeAmount : () => {}}
                ctaText={splitPaymentOptions.length ? "Change amount" : ""}
            />
            <div className="pay-only-payment-detail__inner">
                {isSplitByItem ? (
                    <>
                        <PaymentItem title="Items total" value={exclusiveSubTotal} />
                        <PaymentItem title="Taxes" value={exclusiveTaxTotal} />
                        <PaymentItem title="Venue discount" value={discountAmount} />
                        <PaymentItem
                            title={getSurchargeText(menuDataLocale, venueSurchargePercentage)}
                            value={venueSurchargeAmount}
                        />
                    </>
                ) : (
                    <PaymentItem title="Amount to pay" value={subTotal} />
                )}

                {paying && <PaymentItem title="Tip" value={tipAmount} />}
                <PaymentItem
                    title={getVenueServiceFeeText(menuDataLocale, venueServiceFeeFactor)}
                    value={venueServiceFee}
                />
                <PaymentItem title={getProcessingFeeText(menuDataLocale, paymentType)} value={processingFee} />
                <PaymentItem title="Total" value={total} bold />
            </div>
            <Divider small fullWidth />
            {selectedPaymentMethod ? (
                <ChangePaymentMethodAction paymentMethod={selectedPaymentMethod} onClick={changePaymentMethod} />
            ) : (
                <TitleWithAction title="Paying with" onClick={changePaymentMethod} ctaText="Add payment" />
            )}
        </div>
    );
};
