import "./PayOnlyBillUnavailable.scss";

import React, { ReactElement } from "react";
import { AlertCircleIcon, CreditCardIcon } from "../../../sharedComponents/assets/icons";
import { Button, Text } from "../../../sharedComponents";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { useSelector } from "react-redux";
import { getPayOnlyPaidAmount } from "../selectors";
import classNames from "classnames";
import { getIsOpenTable, getIsOpenTableOrderingDisabled } from "../../openTable/selectors";
import { getTableLabel } from "src/features/order/selectors";

interface Props {
    icon?: ReactElement;
    text: string;
    buttonText?: string;
    onClick?: () => void;
    buttonNeedsNetwork?: boolean;
}

export const PayOnlyBillUnavailable = ({
    icon = <AlertCircleIcon />,
    text,
    buttonText,
    onClick,
    buttonNeedsNetwork = true,
}: Props) => {
    const paidAmount = useSelector(getPayOnlyPaidAmount);
    return (
        <div className={classNames("pay-only-bill-unavailable", !!paidAmount && "with-banner")}>
            {icon}
            <Text preset="g-16" mode={["bold", "block"]} value={text} />
            {buttonText &&
                onClick &&
                (buttonNeedsNetwork ? (
                    <NetworkConnectedButton value={buttonText} mode="outline" unthemed onClick={onClick} />
                ) : (
                    <Button value={buttonText} mode="outline" unthemed onClick={onClick} />
                ))}
        </div>
    );
};

export const PayOnlyRefreshFailed = ({ onClick }: Pick<Props, "onClick">) => (
    <PayOnlyBillUnavailable
        text="Unable to load your bill. Pull to refresh or try again."
        buttonText="Refresh"
        onClick={onClick}
    />
);

export const PayOnlyNoItems = ({ onClick }: Pick<Props, "onClick">) => {
    const isOpenTableOrderingDisabled = useSelector(getIsOpenTableOrderingDisabled);
    const isOpenTable = useSelector(getIsOpenTable);
    const tableLabel = useSelector(getTableLabel);
    const canOrder = isOpenTable && !isOpenTableOrderingDisabled;
    return (
        <PayOnlyBillUnavailable
            icon={<CreditCardIcon />}
            text={`${
                canOrder ? "Nothing to pay yet" : "Please order through your server"
            }. Check back here after an order has been placed from your ${tableLabel}.`}
            buttonText="View menu"
            onClick={canOrder ? onClick : undefined}
            buttonNeedsNetwork={false}
        />
    );
};
