import { AppDispatch, AppState } from "src/features";
import { fetchTakeawayScheduleSlotsOperation } from "..";
import { actionCreators } from "../reducers/takeawayOptions";
import { fetchTakeawayScheduleSlots } from "../api/scheduleSlotsApi";
import { getTakeawayScheduleSlotsExists } from "../selectors/takeawayOptions";
import { getAllServices } from "src/features/menu/selectors";

export function showSchedulePage() {
    return fetchTakeawayScheduleSlotsOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const allServices = getAllServices(state);
        const takeawaySlots = getTakeawayScheduleSlotsExists(state);

        if (!takeawaySlots && allServices) {
            const slots = await fetchTakeawayScheduleSlots(allServices);
            if (slots) {
                dispatch(actionCreators.setScheduleSlots(slots));
            }
        }

        dispatch(actionCreators.showSchedulePage(true));
    });
}
