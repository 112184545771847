import "../assets/PromoCode.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { PromotionsTagIcon } from "../../../sharedComponents/assets/icons";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { Animate, Divider, FormControl, Input, Text } from "../../../sharedComponents";
import { applyPromoCode } from "../../payment/actions/applyPromoCode";
import { getIsConnected, getPartyPromoCodePromotionContext } from "../selectors";
import { PromoCodeDetails } from "./PromoCodeDetails";
import { getApplyPromoCodeErrorDetails, getIsApplyingPromoCode, getIsPaymentWizardOpen } from "../../payment/selectors";
import { applyPromoCodeOperation } from "../../payment/operations";
import { getIsItemModalOpen } from "../selectors/pendingItem";
import { getSignupProgramId } from "../../membership/selectors/getActiveMembershipState";
import { usePrevious } from "../../../sharedComponents/common/shared";
import { getIsAnonymous } from "../../accounts/selectors";
import { AppState } from "../..";
import { scrolling } from "../../../common/experience";
import { actionCreators as promotionActionCreators } from "../../promotion/reducers/promotions";
import { getShowCart } from "../selectors/cart";
import { actionCreators as notificationsActionCreators } from "src/features/notifications/reducers/notifications";
import { signInAction } from "../../signup/actions/signInAction";

const PROMO_CODE_LENGTH = 12;
const ANIMATION_DURATION = 2000;

export const PromoCode = () => {
    const appliedPromoCode = useSelector(getPartyPromoCodePromotionContext);
    const isApplyingPromoCode = useSelector(getIsApplyingPromoCode);
    const applyPromoCodeError = useSelector(getApplyPromoCodeErrorDetails);
    const isConnected = useSelector(getIsConnected);
    const isEditingItem = useSelector(getIsItemModalOpen);
    const isPaymentWizardOpen = useSelector(getIsPaymentWizardOpen);
    const signUpProgramId = useSelector(getSignupProgramId);
    const previousSignUpProgramId = usePrevious(signUpProgramId);
    const isAnonymous = useSelector(getIsAnonymous);
    const accountCreated = useSelector((state: AppState) => state.createVerifiedAccount.accountCreated);
    const showCartModal = useSelector(getShowCart);

    const [isAnimating, setIsAnimating] = useState(false);
    const [isActive, setIsActive] = useState(!!appliedPromoCode);
    const [localPromoCode, setLocalPromoCode] = useState(appliedPromoCode?.code ?? "");

    const dispatch = useDispatch();

    const resetError = useCallback(() => dispatch(applyPromoCodeOperation.actionCreators.reset()), [dispatch]);

    const setFormattedPromoCode = useCallback(
        (code?: string) => {
            const formattedCode = code?.toUpperCase();
            setLocalPromoCode(formattedCode || "");
            if (!!applyPromoCodeError) {
                resetError();
            }
        },
        [applyPromoCodeError, resetError]
    );

    const revealNetworkNotification = useCallback(() => {
        dispatch(notificationsActionCreators.showNoNetworkConnection(true));
    }, [dispatch]);

    const onApply = () => {
        if (!isConnected) {
            revealNetworkNotification();
        } else if (isConnected) {
            setIsAnimating(true);
            dispatch(applyPromoCode(localPromoCode));
        }
    };

    const onClear = useCallback(() => {
        setLocalPromoCode("");
        resetError();
    }, [resetError]);

    const validateUser = useCallback(() => {
        dispatch(promotionActionCreators.trackAddPromoCodeClicked());

        if (isAnonymous) {
            dispatch(
                signInAction({
                    useLocationTheme: true,
                })
            );
        } else {
            setIsActive(true);
        }
    }, [isAnonymous, dispatch]);

    useEffect(() => {
        if (isActive && !!appliedPromoCode && !isAnimating) {
            setIsActive(false);
            setLocalPromoCode("");
        }
    }, [isActive, appliedPromoCode, isAnimating]);

    useEffect(() => {
        let timeout: number | undefined;
        if (isAnimating) {
            timeout = window.setTimeout(() => {
                setIsAnimating(false);
            }, ANIMATION_DURATION);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [isAnimating]);

    useEffect(
        () => () => {
            if (applyPromoCodeError) {
                resetError();
            }
        },
        [resetError, applyPromoCodeError]
    );

    useEffect(() => {
        if ((isPaymentWizardOpen || !showCartModal) && isActive && !appliedPromoCode && !!localPromoCode) {
            dispatch(promotionActionCreators.trackPromoCodeAbandoned(localPromoCode));
        }
    }, [isPaymentWizardOpen, isActive, appliedPromoCode, localPromoCode, showCartModal, dispatch]);

    useEffect(() => {
        // Clear input and remove error when going to edit an item, checkout or manage membership whilst in an error state
        // Going from no signUpProgramId to signUpProgramId = membership modal has been opened
        if (
            (isEditingItem || isPaymentWizardOpen || (!previousSignUpProgramId && signUpProgramId)) &&
            !!applyPromoCodeError
        ) {
            onClear();
        }
    }, [isEditingItem, onClear, applyPromoCodeError, isPaymentWizardOpen, signUpProgramId, previousSignUpProgramId]);

    useEffect(() => {
        if (accountCreated) {
            setIsActive(true);
        }
    }, [accountCreated, dispatch]);

    const isLoading = isAnimating || isApplyingPromoCode;
    const errorMessage = !!applyPromoCodeError && !isLoading ? applyPromoCodeError.detail : undefined;

    return (
        <>
            {!isActive && !appliedPromoCode && <Divider />}
            <div className="promo-code">
                {!isActive &&
                    (!appliedPromoCode ? (
                        <TappableDiv className="promo-code__action" onTap={validateUser}>
                            <PromotionsTagIcon />
                            <Text preset="g-14" mode="bold" value="Add a promo code" />
                        </TappableDiv>
                    ) : (
                        <PromoCodeDetails />
                    ))}
                {isActive && (
                    <div className="promo-code__input-wrapper">
                        <div className={classNames("promo-code__input", !!errorMessage && "in-error")}>
                            <div className={classNames("promo-code__input--mask", isLoading && "hidden")}>
                                <FormControl
                                    id="promo-code"
                                    disabled={isLoading}
                                    invalid={!!errorMessage}
                                    invalidMessage={errorMessage}
                                >
                                    <Input
                                        autoFocus
                                        blockPattern={/[^a-zA-Z0-9]/}
                                        maxLength={PROMO_CODE_LENGTH}
                                        value={localPromoCode}
                                        label="Enter promo code"
                                        placeholder="Your promo code"
                                        applyButtonText="Apply"
                                        applyButtonIsDisabled={
                                            !localPromoCode || !!errorMessage || isApplyingPromoCode || !isConnected
                                        }
                                        onChange={setFormattedPromoCode}
                                        onClear={!isConnected ? revealNetworkNotification : onClear}
                                        onFocus={(e) => e?.target && scrolling.scrollElementIntoView(e.target)}
                                        onApplyButtonClick={onApply}
                                        onApplyButtonDisabledClick={
                                            !isConnected ? revealNetworkNotification : undefined
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className={classNames("promo-code__input--loading", isLoading && "visible")}>
                                <Animate name="twoDotSpinner" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
