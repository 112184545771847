import React from "react";
import { Button, Text, TextPreset } from "..";
import { ButtonProps } from "../button/Button";

import "./CallToActionHeader.scss";

export interface CallToActionHeaderProps {
    onClick?: () => void;
    icon?: React.ReactNode;
    title: string;
    subtitle?: string;
    ctaText?: string;
    ctaProps?: ButtonProps;
    titlePreset?: TextPreset;
}

export const CallToActionHeader = ({
    icon,
    onClick,
    title,
    titlePreset = "g-16",
    subtitle,
    ctaText,
    ctaProps = { mode: "blank", preset: "half-size" },
}: CallToActionHeaderProps) => (
    <div className="call-to-action-header">
        {icon && <div className="call-to-action-header__icon">{icon}</div>}
        <Text className="call-to-action-header__title" mode={["block", "bold"]} preset={titlePreset}>
            {title}
        </Text>
        {subtitle && (
            <Text className="call-to-action-header__subtitle" mode="block" preset="g-14">
                {subtitle}
            </Text>
        )}
        {ctaText && (
            <Button {...ctaProps} className="call-to-action-header__button" onClick={onClick} value={ctaText} />
        )}
    </div>
);
