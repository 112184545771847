import React, { useCallback } from "react";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { useDispatch, useSelector } from "react-redux";
import { getPayOnlySplitPaymentOptions } from "../selectors";
import { selectSplitPayment } from "../actions/selectSplitPayment";
import {
    NetworkConnectedButton,
    NetworkConnectedButtonProps,
} from "../../notifications/components/NetworkConnectedButton";
import { makePayOnlyPaymentOperation } from "../operations";
import { payTotal } from "../actions/payTotal";
import { payOnlyTrackActionCreators } from "../reducers/payOnlyTrack";

interface Props extends Omit<NetworkConnectedButtonProps, "value" | "children" | "onClick"> {
    canStartPayment?: () => Promise<boolean>;
}

export const PayOnlyPayButton = ({ canStartPayment, ...props }: Props) => {
    const splitPaymentOptions = useSelector(getPayOnlySplitPaymentOptions);

    const dispatch = useDispatch();

    const trackSelectedPaymentOption = useCallback(
        (option: string) => {
            dispatch(payOnlyTrackActionCreators.paymentOption(option));
        },
        [dispatch]
    );

    const onClick = useCallback(async () => {
        if (canStartPayment && !(await canStartPayment())) return;

        dispatch(makePayOnlyPaymentOperation.actionCreators.reset());
        if (splitPaymentOptions.length) {
            dispatch(
                showModalMessage(
                    modalMessages.payOnlyPaymentOptions(
                        () => {
                            dispatch(payTotal());
                            trackSelectedPaymentOption("pay_balance");
                        },
                        () => {
                            dispatch(selectSplitPayment());
                            trackSelectedPaymentOption("split_bill");
                        }
                    )
                )
            );
        } else {
            dispatch(payTotal());
        }
    }, [dispatch, canStartPayment, trackSelectedPaymentOption, splitPaymentOptions]);

    return (
        <NetworkConnectedButton
            value={splitPaymentOptions.length ? "Split & pay" : "Pay now"}
            mode="solid"
            onClick={onClick}
            {...props}
        />
    );
};
