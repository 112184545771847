import React from "react";

export const sharedErrorBoundaryMessages = {
    title: (
        <>
            Sorry, something
            <br />
            went wrong.
        </>
    ),
    reported: "A crash report has been sent for review.",
};
