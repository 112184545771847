import { GooglePayPaymentMethod } from "src/common/payment";
import { getBraintreeClientInstance } from "src/common/braintree";
import { googlePayment } from "braintree-web";
import { BraintreeGooglePayPaymentMethod } from "src/features/paymentGateways/braintree/types";
import {
    GetGooglePayAuthorizePaymentResultFunc,
    GetGooglePayPaymentDataRequestFunc,
    initializeGooglePayButton,
} from "../common/initializeGooglePayButton";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";
import { GooglePayment } from "braintree-web/modules/google-payment";

interface GooglePayInstanceData {
    googlePayInstance: GooglePayment;
    deviceData: string;
}

export const initializeBraintreeGooglePayButton: InitializeDevicePaymentMethodButtonFunc<
    GooglePayPaymentMethod
> = async (googlePayPaymentMethod, total, { getPaymentSessionToken, trackEvent }) => {
    let googlePayInstanceData: GooglePayInstanceData | undefined;

    const getGooglePayInstanceData = async () => {
        if (!googlePayInstanceData) {
            const { googlePayMerchantId } = googlePayPaymentMethod as BraintreeGooglePayPaymentMethod;
            const clientToken = await getPaymentSessionToken();
            const { clientInstance, deviceData } = await getBraintreeClientInstance(clientToken);
            const googlePayInstance = await googlePayment.create({
                client: clientInstance,
                googlePayVersion: 2,
                googleMerchantId: googlePayMerchantId ?? undefined,
            });

            googlePayInstanceData = {
                googlePayInstance,
                deviceData,
            };
        }
        return googlePayInstanceData;
    };

    const getPaymentDataRequest: GetGooglePayPaymentDataRequestFunc = async (transactionInfo) => {
        const { googlePayInstance } = await getGooglePayInstanceData();
        return await googlePayInstance.createPaymentDataRequest({
            transactionInfo,
        });
    };

    const getAuthorizePaymentResult: GetGooglePayAuthorizePaymentResultFunc = async (paymentData) => {
        const { googlePayInstance, deviceData } = await getGooglePayInstanceData();
        const {
            nonce,
            details: { isNetworkTokenized },
        } = await googlePayInstance.parseResponse(paymentData);

        return {
            paymentToken: nonce,
            additionalFraudProtectionData: deviceData,
            extendedValidation: isNetworkTokenized === false,
        };
    };

    return await initializeGooglePayButton(
        googlePayPaymentMethod,
        total,
        trackEvent,
        getPaymentDataRequest,
        getAuthorizePaymentResult
    );
};
