import React, { CSSProperties } from "react";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { OrderHistoryData } from "../../orderHistoryData";
import { NativeBackButton } from "../../../common/navigation";
import { CartIcon, TimeCircleIcon } from "src/sharedComponents/assets/icons";
import { OrderHistoryItemList } from "./OrderHistoryItemList";
import { Button, Divider, Text } from "src/sharedComponents";
import { ModalContainer } from "src/common/modal";
import "../assets/OrderHistoryCollect.scss";
import { VenueDetails } from "src/features/takeaway/components/VenueDetails";
import { useSelector } from "react-redux";
import { getPreviousVenueLocationsDetails } from "src/features/takeaway/selectors/takeawayOptions";
import { DeliveryOptionType } from "src/features/order/types";
import { hexToRGBA } from "src/common/shared";

const MINUTE = 1000 * 60;
const TO_HOUR = 60;

export interface Props {
    show: boolean;
    historyDetail: OrderHistoryData;
    close: (completed?: boolean) => void;
}

function convertTime(value: number, units: number) {
    return Math.floor(value / units);
}

export const OrderHistoryCollect = ({ show, historyDetail, close }: Props) => {
    const { summary, details, mealHistoryOrders } = historyDetail;
    const allVenuesCollectionDetails = useSelector(getPreviousVenueLocationsDetails);
    const venueLocationId = historyDetail.summary?.locationId;
    const venueCollectionDetails =
        allVenuesCollectionDetails && venueLocationId ? allVenuesCollectionDetails[venueLocationId] : null;
    const isTakeaway = mealHistoryOrders?.[0]?.deliveryOption?.type === DeliveryOptionType.TAKEAWAY;

    const pickupOrderDetails = details?.orderDetail?.filter((o) => o.isPickupItem) || [];
    const pickupOrderQty = pickupOrderDetails.reduce((count, o) => count + o.count, 0);

    const readyTime =
        !mealHistoryOrders || !mealHistoryOrders.length
            ? null
            : mealHistoryOrders
                  .map((o) => (o.readyTime ? Date.parse(o.readyTime) : -1))
                  .sort((a: number, b: number) => b - a)[0];

    let shortTime = "";
    if (readyTime) {
        const minutesPassed = convertTime(Date.now() - readyTime.valueOf(), MINUTE);
        const hoursPassed = convertTime(minutesPassed, TO_HOUR);
        if (minutesPassed < 1) {
            shortTime = "just now";
        } else if (minutesPassed < 60) {
            shortTime = `${minutesPassed} min ago`;
        } else {
            shortTime = `${hoursPassed} hour${hoursPassed < 2 ? "" : "s"} ago`;
        }
    }

    function complete() {
        close(true);
    }

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "collect-order-modal slide-in",
                afterOpen: "collect-order-after-open slide-in--after-open",
                beforeClose: "collect-order-before-close slide-in--before-close",
            }}
            overlayClassName="slide-in-modal--overlay collect-order-modal--overlay"
            contentLabel="Collect Order Modal"
            closeTimeoutMS={250}
        >
            <TappableDiv className="shield" onTap={close} />
            <NativeBackButton name="collect-order" onPressed={close} />
            <div
                className="collect-order__scroll"
                style={
                    {
                        "--theme-primary-color": summary?.locationColor,
                        "--theme-primary-color-light": hexToRGBA(summary?.locationColor, 0.1),
                    } as CSSProperties
                }
            >
                <Text preset="title-24" mode="bold" className="collect-order__title">
                    Pick up your order!
                </Text>
                <Text preset="g-14" className="collect-order__subtitle">
                    Show the venue staff your order code below.
                </Text>
                <Divider fullWidth />
                <div className="collect-order__header">
                    <Text preset="g-14" mode="bold" className="collect-order__header--title">
                        ORDER CODE
                    </Text>
                    <Text preset="g-18" mode="bold" className="collect-order__header--code">
                        {summary?.tableNumber}
                    </Text>
                    <div className="collect-order__header--meta">
                        <div className="collect-order__header--meta--container">
                            <div className="collect-order__header--meta--container--icon">
                                <CartIcon color="var(--theme-primary-color)" />
                            </div>
                            <div className="collect-order__header--meta--container--details">
                                <Text
                                    preset="g-12"
                                    mode="bold"
                                    className="collect-order__header--title collect-order__header--meta--container--details--title"
                                >
                                    Total Items
                                </Text>
                                <Text
                                    preset="g-14"
                                    mode="bold"
                                    className="collect-order__header--meta--container--details--value"
                                >
                                    QTY {pickupOrderQty}
                                </Text>
                            </div>
                        </div>
                        <div className="collect-order__header--meta--container">
                            <div className="collect-order__header--meta--container--icon">
                                <TimeCircleIcon color="var(--theme-primary-color)" />
                            </div>
                            <div className="collect-order__header--meta--container--details">
                                <Text
                                    preset="g-12"
                                    mode="bold"
                                    className="collect-order__header--title collect-order__header--meta--container--details--title"
                                >
                                    Notified
                                </Text>
                                <Text
                                    preset="g-14"
                                    mode="bold"
                                    className="collect-order__header--meta--container--details--value"
                                >
                                    {shortTime}
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {isTakeaway && venueCollectionDetails && summary?.locationName && (
                    <>
                        <div className="collect-order__location-details">
                            <VenueDetails
                                locationName={summary.locationName}
                                collectionDetails={venueCollectionDetails}
                                lightBackground
                            />
                        </div>
                        {venueCollectionDetails.collectionInstructions && (
                            <>
                                <Divider fullWidth />
                                <div className="collect-order__collection-instructions">
                                    <Text preset="g-18" mode="extra-bold">
                                        Where to go
                                    </Text>
                                    <Text
                                        preset="g-14"
                                        mode="block"
                                        className="collect-order__collection-instructions--description"
                                    >
                                        {venueCollectionDetails.collectionInstructions}
                                    </Text>
                                </div>
                            </>
                        )}
                    </>
                )}
                <Divider fullWidth />
                <div className="collect-order__items">
                    <OrderHistoryItemList items={pickupOrderDetails} />
                </div>
                <footer className="top-shadow">
                    <Button value="I’ve collected my order" onClick={complete} />
                </footer>
            </div>
            <div className="collect-order__fade-scroll" />
        </ModalContainer>
    );
};
