import { AnyAction } from "redux";
import { AppState } from "../..";
import { TRACK_ERROR_PROPERTY, TRACK_EVENT_PROPERTY } from "../../../common/events/reduxEventTracking";

export interface IReduxActionPlugin {
    handleReduxAction: (action: AnyAction, state: () => AppState, buffer?: boolean) => void;
}

export function getActionProperties(action: AnyAction) {
    return Object.keys(action)
        .filter((k) => k !== "type" && typeof action[k] !== "object" && action[k] !== undefined)
        .reduce((map, key) => {
            if (key !== TRACK_EVENT_PROPERTY) {
                map[key] = String(action[key]);
            }
            if (key !== TRACK_ERROR_PROPERTY) {
                map[key] = String(action[key]);
            }
            return map;
        }, {} as { [key: string]: string });
}
