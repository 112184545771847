import { AppState } from "src/features";
import { createSelector } from "reselect";
import { getProfile } from "../../accountmenu/selectors";
import { getIsAnonymous, getIsUnverified } from "../../accounts/selectors";
import { SignInWizardPage } from "../types";
import { isValidNextWizardPage } from "../util";
import { getJoinTableStep } from "../../partyOnboarding/selectors";
import { getRestaurantFlags } from "src/features/order/selectors/restaurantFlags";

export const getAccountPageDetails = (state: AppState) => state.createVerifiedAccount.pageDetails;

const getSignIn = (state: AppState) => state.signIn;

export const getSignInPage = createSelector(getSignIn, (signIn) => signIn.page);

export const getSignInOptions = createSelector(getSignIn, (signIn) => signIn.options);

export const getPhone = createSelector(
    getSignIn,
    getProfile,
    (signIn, profile) => signIn.phone || profile?.phone || ""
);

export const getNextSignInPage = createSelector(
    getSignIn,
    getIsAnonymous,
    getIsUnverified,
    getProfile,
    getRestaurantFlags,
    ({ page, options }, isAnonymous, isUnverified, profile, { requireEmail }): SignInWizardPage | null => {
        const shouldShowPhone = !profile?.phone;
        const shouldShowVerify = isUnverified && (options?.requireVerification || !profile?.hasSsoIdentity);
        const shouldShowDetails =
            !profile?.firstName || (!!requireEmail && !profile?.email) || !!options?.accountDetailsOptions;
        const shouldShowOptions =
            isAnonymous || (!shouldShowPhone && !shouldShowVerify && !shouldShowDetails && !profile?.hasSsoIdentity);

        if (isValidNextWizardPage("Options", page) && shouldShowOptions) {
            return "Options";
        }

        if (isValidNextWizardPage("Phone", page) && shouldShowPhone) {
            return "Phone";
        }

        if (isValidNextWizardPage("Verify", page) && shouldShowVerify) {
            return "Verify";
        }

        if (isValidNextWizardPage("Details", page) && shouldShowDetails) {
            return "Details";
        }

        return null;
    }
);

export const getPrevSignInPage = createSelector(
    getSignIn,
    getIsAnonymous,
    getIsUnverified,
    ({ page, prevPage, startPage }, isAnonymous, isUnverified): SignInWizardPage | null => {
        switch (page) {
            case "Phone":
                return startPage === "Options" && isAnonymous ? "Options" : null;
            case "Details":
                return prevPage === "Phone" && isUnverified ? "Phone" : null;
            default:
                return null;
        }
    }
);

export const getShowAccountUnverified = createSelector(
    getSignInPage,
    getProfile,
    getJoinTableStep,
    (page, profile, joinTableStep) => {
        switch (joinTableStep) {
            case "Initializing":
            case "Joining":
            case "Rejoining":
                return false;
            default:
                return !page && !!profile?.phoneUnverified;
        }
    }
);

export const getAccountDetailsOptions = createSelector(getSignInOptions, (options) => options?.accountDetailsOptions);
