import React, { ReactNode } from "react";
import classNames from "classnames";
import { TappableLabel } from "../../common/tappable";
import { RequireOnlyOne } from "../../types/utils";

import "./Radio.scss";

export enum RadioPosition {
    LEFT = "left",
    RIGHT = "right",
}

interface PropsBase {
    disabled?: boolean;
    checked?: boolean;
    defaultChecked: boolean;
    onChange: (checked: boolean) => void;
    name?: string;
    position?: RadioPosition;
    children?: ReactNode;
}

export type RadioProps = RequireOnlyOne<PropsBase, "checked" | "defaultChecked">;

export const Radio = ({
    position = RadioPosition.RIGHT,
    children,
    checked,
    onChange,
    disabled,
    name,
    defaultChecked,
}: RadioProps) => (
    <TappableLabel disabled={!!disabled} className="radio__wrapper-action">
        <div className={classNames("radio", !!position && `position-${position}`)}>
            <input
                type="checkbox"
                defaultChecked={defaultChecked}
                disabled={!!disabled}
                name={name}
                checked={checked}
                onChange={onChange ? (event) => onChange(event.target.checked) : undefined}
            />
            <span className="fake-radio" />
            <div className="radio__content">{children}</div>
        </div>
    </TappableLabel>
);
