import { State as RatingState } from "./rating";
import { State as SurveyState } from "./survey";
import { createOperation } from "../../operations";

export { reducer as ratingReducer } from "./rating";
export { reducer as surveyReducer } from "./survey";

export const fetchSurveyDetailsOperation = createOperation<boolean>("SURVEY/FETCH_SURVEY_DETAILS");

export type State = RatingState & SurveyState;
