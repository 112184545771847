import { HandledError } from "../../../common/error";

export enum KnownMembershipErrors {
    MembershipInsufficientBalance = "MembershipInsufficientBalance",
}

export class MembershipInsufficientBalanceError extends HandledError {}

export function membershipInsufficientBalanceErrorHandler() {
    return new MembershipInsufficientBalanceError();
}
