import "../assets/VerifyPage.scss";

import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { oneTimePassword } from "src/common/experience";
import { getAppInsights } from "src/features/analytics";
import { FormControl, CodeInput, Text } from "src/sharedComponents";
import classNames from "classnames";
import { IdentitySource, IdentityValidationRequest } from "../../../common/sso";
import { resetVerify as resetVerifyAction, verifying } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { InvalidVerificationCodeError } from "../types";
import { verifyOperation } from "../operations";
import { getPhone } from "../selectors";
import { NetworkConnectedTappableSpan } from "../../notifications/components/NetworkConnectedTappableSpan";
import { actionCreators as notificationsActionCreators } from "../../notifications/reducers/notifications";
import { getIsConnected, getParty } from "../../order/selectors";
import { auth } from "../../../common/auth";
import { actionCreators as verifyActionCreators } from "../reducers/verify";
import { FetchError } from "src/features/order/orderApi/FetchError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";

export interface Props {
    title?: string;
    subtitle?: (phone: string) => ReactNode;
    additionalDescription?: string;
    additionalClassName?: string;
    onComplete?: () => void;
}

const CODE_PATTERN = /^[0-9]*$/;

const getErrorMessage = (error: any) => {
    if (!error) return "";
    if (error instanceof InvalidVerificationCodeError) return error.message;
    return "Sorry, something went wrong. Please check your connection and try again.";
};

export const VerifyPage = ({ title, subtitle, additionalDescription, additionalClassName, onComplete }: Props) => {
    const verifyError = getErrorMessage(useSelector(verifyOperation.getError));
    const verifyStatus = useSelector(verifyOperation.getStatus);
    const phone = useSelector(getPhone);
    const isConnected = useSelector(getIsConnected);
    const inParty = !!useSelector(getParty);

    const [smsCode, setSmsCode] = useState("");
    const smsRef = useRef<HTMLInputElement>(null);
    const focusTimeoutRef = useRef(0);
    const phoneRef = useRef(phone);
    const onCompleteRef = useRef(onComplete);
    onCompleteRef.current = onComplete;

    const dispatch = useDispatch();

    const verify = useCallback(
        (phone: string, smsCode: string) => {
            const request: IdentityValidationRequest = {
                token: smsCode,
                source: IdentitySource.PhoneNumber,
                phoneNumber: phone,
            };
            dispatch(verifying(request, onCompleteRef.current));
        },
        [dispatch]
    );

    const resetVerify = useCallback(() => dispatch(resetVerifyAction()), [dispatch]);

    const handleOnDisabledClick = useCallback(() => {
        dispatch(notificationsActionCreators.showNoNetworkConnection(true));
    }, [dispatch]);

    const sendPhoneOtp = useCallback(async function sendPhoneOtp(number: string, inParty: boolean) {
        try {
            await auth.passwordlessLogin(number, inParty);
        } catch (err) {
            if (err instanceof FetchError) {
                // For now, always provide a generic message
                // reCaptcha errors do return a problem detail so we could switch to systemMessages.problemDetail
                dispatch(showModalMessage(modalMessages.phoneOtpFailed()));
            }
        }
    }, [dispatch]);

    const resend = useCallback(() => {
        dispatch(verifyActionCreators.trackResendVerificationCodeClicked());
        sendPhoneOtp(phoneRef.current, inParty);
        setSmsCode("");
        focusTimeoutRef.current = window.setTimeout(() => smsRef.current?.focus(), 300);
    }, [dispatch, inParty, sendPhoneOtp]);

    useEffect(() => {
        let otpAbort: AbortController | null = null;

        if (oneTimePassword.isSupported) {
            otpAbort = new AbortController();
            oneTimePassword.request(otpAbort.signal).then(
                (code: string) => {
                    smsRef.current?.blur();
                    setSmsCode(code);
                    otpAbort = null;
                },
                (error: Error) => {
                    getAppInsights()?.trackException({
                        error,
                        severityLevel: SeverityLevel.Error,
                    });
                    otpAbort = null;
                }
            );
        } else {
            focusTimeoutRef.current = window.setTimeout(() => smsRef.current?.focus(), 300);
        }

        return () => {
            otpAbort && otpAbort.abort();
            window.clearTimeout(focusTimeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (smsCode.length === 4) {
            smsRef.current?.blur();
            verify(phoneRef.current, smsCode);
        } else {
            resetVerify();
        }
    }, [smsCode, verify, resetVerify]);

    useEffect(() => {
        sendPhoneOtp(phoneRef.current, inParty);
    }, [inParty, sendPhoneOtp]);

    return (
        <form id="verify-page" className={classNames("verify-page animated-child", additionalClassName)}>
            <main>
                <Text preset="title-28" mode={["bold", "block"]} className="verify-page__title">
                    {title || "Secure your account"}
                </Text>
                {additionalDescription && (
                    <Text preset="g-14" mode="block" className="verify-page__description__additional">
                        {additionalDescription}
                    </Text>
                )}
                <Text preset="g-14" mode="block" className="verify-page__description">
                    {subtitle?.(phone) ?? (
                        <>
                            Enter the verification code sent to{" "}
                            <Text className="verify-page__phone-number" preset="g-14" mode="bold">
                                {phone}
                            </Text>
                        </>
                    )}
                </Text>
                <div className="verify-page__content">
                    <div className="verify-page__container">
                        <FormControl
                            disabled={!isConnected && verifyStatus !== "processing"}
                            invalid={!!verifyError}
                            invalidMessage={verifyError}
                        >
                            <CodeInput
                                type="tel"
                                ref={smsRef}
                                value={smsCode}
                                length={4}
                                onChange={(value) => CODE_PATTERN.test(value) && setSmsCode(value)}
                                onDisabledClick={handleOnDisabledClick}
                            />
                        </FormControl>
                    </div>
                </div>
            </main>
            <ResendCode resendCode={resend} />
        </form>
    );
};

const ResendCode = ({ resendCode }: { resendCode: () => void }) => {
    const [timeLeft, setTimeLeft] = useState(15);

    useEffect(() => {
        let timer = 0;
        if (timeLeft > 0) {
            timer = window.setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        }
        return () => window.clearTimeout(timer);
    }, [timeLeft]);

    return (
        <NetworkConnectedTappableSpan
            className="verify-page__resend"
            onClick={() => {
                setTimeLeft(15);
                resendCode();
            }}
            disabled={timeLeft > 0}
        >
            <Text preset="g-14" mode="bold">
                Resend code
                {timeLeft > 0 && (
                    <Text preset="g-14" mode="bold">
                        &nbsp;0:{timeLeft < 10 ? "0" + timeLeft : timeLeft}
                    </Text>
                )}
            </Text>
        </NetworkConnectedTappableSpan>
    );
};
