import React, { CSSProperties, useEffect, useRef } from "react";
import { useLottie, Lottie } from "react-lottie-hook";
import { Animations, AnimationsName } from "../../assets/animations";
import classNames from "classnames";

import "./Animate.scss";

export interface AnimateProps {
    name: AnimationsName;
    className?: string;
    style?: CSSProperties;
}

export const Animate: React.FC<AnimateProps> = ({ name, className, style }: AnimateProps) => {
    const [lottieRef, , controls] = useLottie({
        renderer: "svg",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            progressiveLoad: false,
        },
        animationData: Animations[name],
    });

    const controlsRef = useRef(controls);

    useEffect(() => {
        controlsRef.current = controls;
    }, [controls]);

    useEffect(() => {
        controlsRef.current.selectAnimation(Animations[name]);
    }, [name]);

    // using -1 to override the 200px default width and height which comes with Lottie
    return (
        <Lottie
            lottieRef={lottieRef}
            width={-1}
            height={-1}
            className={classNames("animated-svg", name, className)}
            style={style}
        />
    );
};
