import "../assets/CreateVerifiedAccountWizard.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { CreateVerifiedAccountPage } from "../types";
import { waitForKeyboardClose } from "../../../common/keyboard";
import { AppState } from "../..";
import { createVerifiedAccountActions } from "../reducers";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { Text } from "src/sharedComponents";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { signInAction } from "../actions/signInAction";
import { startCreateVerifiedAccountAction } from "../actions/createVerifiedAccountAction";
import { getProfilePhone } from "../../accountmenu/selectors";
import { getIsAnonymous } from "../../accounts/selectors";
import { ModalContainer } from "src/common/modal";
import { VerifyPage } from "./VerifyPage";
import { AccountDetails, Props as AccountDetailsProps } from "../../accounts/components/AccountDetails";

export const CreateVerifiedAccountWizard = () => {
    const phone = useSelector(getProfilePhone);
    const showPage = useSelector((state: AppState) => state.createVerifiedAccount.page);
    const accountCreated = useSelector((state: AppState) => state.createVerifiedAccount.accountCreated);
    const pageDetails = useSelector((state: AppState) => state.createVerifiedAccount.pageDetails);
    const previousPage = useRef<CreateVerifiedAccountPage | null>(null);
    const isAnonymous = useSelector(getIsAnonymous);
    const accountDetailsRef = useRef<HTMLDivElement>(null);

    const [showSteps, setShowSteps] = useState(showPage === "AccountDetails");
    const [page, setPage] = useState<CreateVerifiedAccountPage | undefined>();

    const dispatch = useDispatch();

    const backToDetails = useCallback(() => {
        dispatch(createVerifiedAccountActions.showPage("AccountDetails"));
        setShowSteps(true);
    }, [dispatch]);

    const close = useCallback(async () => {
        await waitForKeyboardClose();
        dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
    }, [dispatch]);

    const startVerification = useCallback(() => {
        dispatch(startCreateVerifiedAccountAction());
    }, [dispatch]);

    const signIn = useCallback(() => {
        const preventCompleting = pageDetails?.preventCloseOnSignInComplete;
        if (preventCompleting) {
            dispatch(createVerifiedAccountActions.preventCompletingVerifyWizard(preventCompleting));
        }
        dispatch(signInAction());
    }, [dispatch, pageDetails]);

    const onComplete = useCallback(async () => {
        await waitForKeyboardClose();
        dispatch(createVerifiedAccountActions.setVerifiedAccountCreated());
    }, [dispatch]);

    const afterClose = useCallback(() => {
        setShowSteps(false);
        setPage(undefined);
    }, []);

    useEffect(() => {
        if (showPage) {
            setPage(showPage);
        }
    }, [showPage]);

    useEffect(() => {
        if (page === "Verify" && previousPage.current !== "AccountDetails") {
            // Hide steps on verify page if not coming from account details
            setShowSteps(false);
        } else if (page === "AccountDetails") {
            setShowSteps(true);
        }

        if (page !== previousPage.current) {
            previousPage.current = page ?? null;
        }
    }, [page]);

    useEffect(() => {
        if (!isAnonymous && accountCreated) {
            dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
        }
    }, [isAnonymous, dispatch, accountCreated]);

    return (
        <ModalContainer
            isOpen={!!showPage}
            className={{
                base: "CreateVerifiedAccountWizard-modal slide-in",
                afterOpen: "CreateVerifiedAccountWizard-modal--after-open slide-in--after-open",
                beforeClose: "CreateVerifiedAccountWizard-modal--before-close slide-in--before-close",
            }}
            bodyOpenClassName="CreateVerifiedAccountWizard-modal__Body--open"
            overlayClassName="CreateVerifiedAccountWizard-modal--overlay slide-in-modal--overlay keyboard-unfixed"
            onRequestClose={close}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            onAfterClose={afterClose}
        >
            <LocationThemeContainer>
                <CSSTransition
                    timeout={250}
                    classNames={"create-verified-account-wizard-transition--pop"}
                    in={page === "AccountDetails" || !phone}
                    unmountOnExit
                >
                    <div className="create-verified-account-wizard" ref={accountDetailsRef}>
                        <SimpleNavHeader
                            customBack="create-verified-account-wizard--confirm-details-or-sign-in"
                            closeToBack
                            onBack={close}
                            rightElement={
                                isAnonymous && (
                                    <TappableDiv onClick={signIn} className="create-verified-account-wizard__signin">
                                        <Text preset="g-14" mode="block">
                                            Existing account?{" "}
                                            <Text
                                                preset="g-14"
                                                mode="bold"
                                                className="create-verified-account-wizard__signin__action"
                                            >
                                                Sign in
                                            </Text>
                                        </Text>
                                    </TappableDiv>
                                )
                            }
                        />
                        {renderStep(2, page, true)}
                        <AccountDetails
                            onSuccess={startVerification}
                            title={pageDetails?.accountDetailsTitle}
                            containerRef={accountDetailsRef}
                            flowName={pageDetails?.flowName || "create_account_wizard"}
                            {...(pageDetails as Partial<AccountDetailsProps>)}
                        />
                    </div>
                </CSSTransition>
                <CSSTransition
                    timeout={250}
                    classNames={"create-verified-account-wizard-transition--push"}
                    appear
                    in={page === "Verify" && !!phone}
                    unmountOnExit
                >
                    <div className="create-verified-account-wizard">
                        <SimpleNavHeader customBack="create-verified-account-wizard--verify" onBack={backToDetails} />
                        {renderStep(2, page, showSteps)}
                        <VerifyPage
                            onComplete={pageDetails?.onComplete || onComplete}
                            additionalDescription={pageDetails?.verifyPageAdditionalDescription}
                            title={pageDetails?.verifyPageTitle}
                        />
                    </div>
                </CSSTransition>
            </LocationThemeContainer>
        </ModalContainer>
    );
};

const renderStep = (totalSteps: number, currentPage?: CreateVerifiedAccountPage, showSteps?: boolean) => {
    const currentStep = currentPage === "AccountDetails" ? 1 : 2;
    return showSteps && currentPage && totalSteps > 1 ? (
        <Text preset="g-14" className="create-verified-account-wizard__step" mode="block">
            Step {currentStep} of {totalSteps}
        </Text>
    ) : (
        <div className="create-verified-account-wizard__spacer" />
    );
};
