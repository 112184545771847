export enum CountryCode {
    Australia = "+61",
}

export class InvalidVerificationCodeError extends Error {
    constructor() {
        super("The code you’ve entered is incorrect");
    }
}

export type SignInWizardPage = "Options" | "Phone" | "Verify" | "Details";

export type CreateVerifiedAccountPage = "AccountDetails" | "Verify";
