import { AnyAction } from "redux";
import { actionCreators, OperationAction, State, TypeKeys } from "./reducer";
import { fromPromise, getOperationError, getOperationStatus } from "./utils";
import { trackedError, trackedEvent } from "../../common/events/reduxEventTracking";
import { AppDispatch, AppState } from "../index";
import { Operation, OperationOptions, Thunk } from "./types";

const defaultOptions: OperationOptions = {
    tracked: false,
    allowParallel: true,
    showSpinner: false,
    allowPause: true,
};

const showSpinnerOperations: string[] = [];
const preventPauseOperations: string[] = [];

export const getOperationShowsSpinner = (operationName: string) => showSpinnerOperations.indexOf(operationName) >= 0;

export const getOperationPreventsPause = (operationName: string) => preventPauseOperations.indexOf(operationName) >= 0;

export const createOperation = <T>(operationName: string, options?: Partial<OperationOptions>): Operation<T> => {
    let start: number = 0;

    const appliedOptions = {
        ...defaultOptions,
        ...options,
    };

    const wrapTracking: typeof trackedEvent = appliedOptions.tracked ? trackedEvent : (input) => input;

    if (appliedOptions.showSpinner) {
        showSpinnerOperations.push(operationName);
    }

    if (!appliedOptions.allowPause) {
        preventPauseOperations.push(operationName);
    }

    const operationActionCreators = {
        begin: () => {
            start = performance.now();
            return wrapTracking(actionCreators.begin(operationName));
        },
        completed: (result: T | undefined) =>
            wrapTracking(actionCreators.completed(operationName, result, performance.now() - start)),
        failed: (error: any) => actionCreators.failed(operationName, error, performance.now() - start),
        reset: () => actionCreators.reset(operationName),
    };

    const operation: Operation<T> = {
        name: operationName,
        options: appliedOptions,
        invoke: (
            action: () => Promise<T>,
            dispatch: AppDispatch,
            getState: () => AppState,
            completeActionCreator?: (result: T) => AnyAction
        ) => fromPromise(operation, action, dispatch, getState, completeActionCreator),
        actionCreators: operationActionCreators,
        reducer: operationResultReducer<T>(operationName),
        getStatus: (state: State) => getOperationStatus(state, operationName),
        getError: (state: State) => getOperationError(state, operationName),
        getThunk: <A extends AnyAction>(
            action: (dispatch: AppDispatch, getState: () => AppState) => Promise<T>
        ): Thunk<A> =>
            ((dispatch: AppDispatch, getState: () => AppState) =>
                fromPromise<T, AppState, A>(operation, action, dispatch, getState)) as any,
        trackEvent: (action: AnyAction, dispatch: AppDispatch) =>
            dispatch(trackedEvent({ ...action, type: `${operationName}/${action.type}` })),
        trackError: (action: AnyAction, dispatch: AppDispatch) =>
            dispatch(trackedError({ ...action, type: `${operationName}/${action.type}` })),
    };

    return operation;
};

export function operationResultReducer<T>(operation: string) {
    return function (state: T | undefined, action: OperationAction): T {
        if (action.type === TypeKeys.COMPLETED && action.operation === operation) {
            return action.result;
        }

        return state || null!;
    };
}
