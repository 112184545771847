import "./InviteToFlexTabCallToAction.scss";

import React, { useCallback, useMemo } from "react";
import { Text } from "src/sharedComponents/controls/text/Text";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { QrIcon } from "src/sharedComponents/assets/icons/Icons";
import { NativeBackButton, SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { ModalContainer } from "src/common/modal";
import { useDispatch, useSelector } from "react-redux";
import { TabJoinMethod } from "src/features/groupTabs";
import classNames from "classnames";
import { QRCodeSVG } from "qrcode.react";
import { Button } from "src/sharedComponents";
import { getActiveFlexTabLink, getFlexTabOwnerFirstName, getOpenInviteToFlexTabModal } from "../../selectors";
import { getLocationName } from "src/features/menu/selectors";
import { getRequireSignIn } from "src/features/accounts/selectors";
import { signInAction } from "src/features/signup/actions/signInAction";
import { actionCreators as openTableActionCreators } from "../../reducers";

export const InviteToFlexTabCallToAction = () => {
    const activeFlexTabPath = useSelector(getActiveFlexTabLink);
    const venueName = useSelector(getLocationName);
    const ownerFirstName = useSelector(getFlexTabOwnerFirstName);
    const requireSignIn = useSelector(getRequireSignIn);
    const openInviteToFlexTabModal = useSelector(getOpenInviteToFlexTabModal);

    const dispatch = useDispatch();

    const showModal = useCallback(() => {
        const setShowInviteToFlexTabCTA = () => {
            dispatch(openTableActionCreators.setOpenInviteToFlexTabModal(true));
        };

        if (requireSignIn) {
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    onComplete: setShowInviteToFlexTabCTA,
                    completeAfterLinkSso: true,
                })
            );
        } else {
            setShowInviteToFlexTabCTA();
        }
    }, [dispatch, requireSignIn]);

    const closeModal = () => {
        dispatch(openTableActionCreators.setOpenInviteToFlexTabModal(false));
    };

    const qrCodeUrl = useMemo(() => {
        const params = new URLSearchParams({
            from: TabJoinMethod.QR,
        });
        return `${activeFlexTabPath}&${params.toString()}`;
    }, [activeFlexTabPath]);

    const share = () => {
        if (!window.navigator.share) {
            return;
        }
        window.navigator
            .share({
                text: `👋 Hey! Join ${ownerFirstName}’s Tab${
                    venueName ? " at " + venueName : ""
                }. Tap the link to start ordering together.`,
                title: `Join Tab`,
                url: activeFlexTabPath,
            })
            .finally(closeModal);
    };

    return (
        <>
            <TappableDiv onClick={showModal} className="invite-flex-tab-cta">
                <div className="invite-flex-tab-cta__icon">
                    <QrIcon />
                </div>
                <div className="invite-flex-tab-cta__text">
                    <Text preset="g-14" mode={["bold", "block"]}>
                        Invite others to your tab
                    </Text>
                    <Text preset="g-14" mode={["block"]}>
                        Order together & split the bill at the end
                    </Text>
                </div>
            </TappableDiv>
            <ModalContainer
                isOpen={openInviteToFlexTabModal}
                className={{
                    base: classNames("invite-flex-tab-modal slide-in"),
                    afterOpen: classNames("invite-flex-tab-modal--after-open slide-in--after-open"),
                    beforeClose: classNames("invite-flex-tab-modal--before-close slide-in--before-close"),
                }}
                overlayClassName={classNames("ReactModal__InviteFlexTab slide-in-modal--overlay")}
                onRequestClose={closeModal}
                closeTimeoutMS={150}
            >
                <NativeBackButton name="invite-to-flex-tab" onPressed={closeModal}>
                    <LocationThemeContainer>
                        <div className="invite-flex-tab">
                            <SimpleNavHeader
                                closeToBack={true}
                                customBack="invite-to-flex-tab"
                                onBack={closeModal}
                                withBorder
                            />
                            <div className="invite-flex-tab__with-qr">
                                <Text className="invite-flex-tab__title" preset="title-24" mode="extra-bold">
                                    Invite others to your tab
                                </Text>
                                <Text className="invite-flex-tab__message" preset="g-14">
                                    Share a link or get others to scan your QR code so they can join your tab
                                </Text>
                                <div className="invite-flex-tab__qr">
                                    <QRCodeSVG value={qrCodeUrl} size={200} />
                                </div>
                                <Text className="invite-flex-tab__secondary-message" preset="g-14">
                                    By sharing your tab QR with someone, they will order on your tab. Split payment
                                    options are available.
                                </Text>
                            </div>
                            <div className="invite-flex-tab__footer">
                                {!!window.navigator.share ? (
                                    <>
                                        <Button value="Share tab link" onClick={share} />
                                        <Button value="Dismiss" mode="blank" onClick={closeModal} secondary />
                                    </>
                                ) : (
                                    <Button value="Dismiss" onClick={closeModal} />
                                )}
                            </div>
                        </div>
                    </LocationThemeContainer>
                </NativeBackButton>
            </ModalContainer>
        </>
    );
};
