import { createSelector } from "reselect";
import { getShowGroupTabsWizard } from "../../groupTabs/selectors/groupTabWizardSelectors";
import { getGroupTabTotal } from "./groupTabs";
import {
    getMemberBillTotal,
    getMemberShare,
    getMembershipPointsSpendTotal,
    getMemberTipOrGratuityAmount,
    getMemberTipOrGratuityPercentage,
    getMemberTotal,
} from "./members";
import { getParty } from "../../order";
import { getProfile } from "../../accountmenu/selectors";
import { getCurrentMemberId } from "../../accounts/selectors";
import { verifyBraintreeCardOperation } from "../../paymentGateways/braintree/operations";
import { verifyTyroCardOperation } from "../../paymentGateways/tyro/operations";
import { authorizePayPalPaymentOperation } from "../operations";
import { getNewCardPaymentMethod, getSelectedPaymentMethod } from "./paymentMethods";
import {
    getHasPaymentOrder,
    getPayOnlyShare,
    getPayOnlyTipAmount,
    getPayOnlyTipPercentage,
    getPayOnlyTotal,
    getVenueServiceFee,
} from "../../payOnly/selectors";
import { getIsOrderAndPay } from "../../order/selectors";
import { evenRound } from "src/sharedComponents/common/utils";

const getPayOnlyOrMemberAmount = (memberAmount: number, payOnlyAmount: number, hasPaymentOrder: boolean) =>
    hasPaymentOrder ? payOnlyAmount : memberAmount;

export const getPaymentTotal = createSelector(
    getShowGroupTabsWizard,
    getGroupTabTotal,
    getMemberTotal,
    getPayOnlyTotal,
    getHasPaymentOrder,
    (showGroupTabsWizard, groupTabTotal, memberTotal, payOnlyTotal, hasPaymentOrder) => {
        if (showGroupTabsWizard) return groupTabTotal;
        return getPayOnlyOrMemberAmount(memberTotal, payOnlyTotal, hasPaymentOrder);
    }
);

export const getIsAllowedZeroPayment = createSelector(
    getPaymentTotal,
    getIsOrderAndPay,
    getMemberBillTotal,
    getMembershipPointsSpendTotal,
    (total, isOrderAndPay, memberTotal, membershipPointsSpendTotal) =>
        isOrderAndPay && total === 0 && memberTotal > 0 && memberTotal === membershipPointsSpendTotal
);

export const getPaymentTipAmount = createSelector(
    getMemberTipOrGratuityAmount,
    getPayOnlyTipAmount,
    getHasPaymentOrder,
    getPayOnlyOrMemberAmount
);

export const getPaymentSurchargeAmount = createSelector(
    getMemberShare,
    getPayOnlyShare,
    getVenueServiceFee,
    getHasPaymentOrder,
    (memberShare, payOnlyShare, venueServiceFee, hasPaymentOrder) =>
        hasPaymentOrder
            ? evenRound((payOnlyShare?.surchargeAmount ?? 0) + venueServiceFee, 2)
            : memberShare?.surchargeAmount ?? 0
);

export const getPaymentTipPercentage = createSelector(
    getMemberTipOrGratuityPercentage,
    getPayOnlyTipPercentage,
    getHasPaymentOrder,
    getPayOnlyOrMemberAmount
);

export const getThreeDSecureVerificationData = createSelector(
    getParty,
    getProfile,
    getCurrentMemberId,
    getPaymentTotal,
    getSelectedPaymentMethod,
    getNewCardPaymentMethod,
    (party, profile, memberId, total, selectedPaymentMethod, newCardPaymentMethod) => ({
        party,
        profile,
        memberId,
        total,
        selectedPaymentMethod,
        newCardPaymentMethod,
    })
);

export const getIsVerifyingCard = createSelector(
    verifyBraintreeCardOperation.getStatus,
    verifyTyroCardOperation.getStatus,
    (verifyBraintreeCardStatus, verifyTyroCardStatus) =>
        verifyBraintreeCardStatus === "processing" || verifyTyroCardStatus === "processing"
);

export const getShouldContextSwitchCancelPayment = createSelector(
    getIsVerifyingCard,
    authorizePayPalPaymentOperation.getStatus,
    (verifyingCard, payPalStatus) => !verifyingCard && payPalStatus !== "processing"
);
