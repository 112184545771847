import * as React from "react";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

export type TappableElementProps<
    TElement extends HTMLElement,
    TAttributes extends HTMLAttributes<TElement>
> = DetailedHTMLProps<TAttributes, TElement> & {
    onTap?: () => void;
    reset?: () => void;
    disabled?: boolean;
    shouldPreventDefault?: boolean;
    onDisabledTap?: () => void;
};

export function onTappableElementClick<TElement extends HTMLElement, TAttributes extends HTMLAttributes<TElement>>(
    e: React.MouseEvent<TElement, MouseEvent>,
    {
        disabled,
        shouldPreventDefault,
        reset,
        onTap,
        onClick,
        onDisabledTap,
    }: TappableElementProps<TElement, TAttributes>
) {
    e.stopPropagation();

    if (disabled) {
        if (onDisabledTap) {
            onDisabledTap();
        }
        e.preventDefault();
        return;
    }

    if (shouldPreventDefault) {
        e.preventDefault();
    }

    if (reset) {
        reset();
    }

    if (onTap) {
        onTap();
    } else if (onClick) {
        onClick(e);
    }
}

export function useTappable<TElement extends HTMLElement, TAttributes extends HTMLAttributes<TElement>>(
    props: TappableElementProps<TElement, TAttributes>
): [
    Omit<
        TappableElementProps<TElement, TAttributes>,
        "reset" | "onTap" | "onClick" | "children" | "shouldPreventDefault" | "onDisabledTap"
    >,
    ReactNode | undefined
] {
    const { reset, onTap, onClick, children, shouldPreventDefault, onDisabledTap, ...attributes } = props;
    return [attributes, children];
}
