import * as React from "react";
import "../assets/RotatedPage.scss";
import { RotateIcon } from "src/sharedComponents/assets/icons";
import { Text } from "../../../sharedComponents/controls/text";

export const RotatedPage = () => (
    <div className="rotated-page">
        <RotateIcon />
        <div className="rotated-page__content">
            <Text className="rotated-page__title" preset="title-36" mode="extra-bold" value="Whoa, I’m more of a" />
            <Text className="rotated-page__title" preset="title-36" mode="extra-bold" value="portrait kind of app." />
            <Text preset="g-14" mode="medium" value="Rotate your phone to continue ordering." />
        </div>
    </div>
);
