import React, { FC, HTMLAttributes } from "react";
import { onTappableElementClick, TappableElementProps, useTappable } from "./base/TappableBase";

type TappableDivInnerRef = TappableElementProps<HTMLDivElement, HTMLAttributes<HTMLDivElement>> & {
    innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
};

const InnerTappableDiv: FC<TappableDivInnerRef> = ({ innerRef, ...props }) => {
    const [attributes, children] = useTappable(props);
    return (
        <div onClick={(e) => onTappableElementClick(e, props)} ref={innerRef} {...attributes}>
            {children}
        </div>
    );
};

const TappableDiv = React.forwardRef<
    HTMLDivElement,
    TappableElementProps<HTMLDivElement, HTMLAttributes<HTMLDivElement>>
>((props, ref?) => <InnerTappableDiv innerRef={ref} {...props} />);

export default TappableDiv;
