import "./PayOnlyReceiptPay.scss";

import React from "react";
import classNames from "classnames";

import { PayOnlyPayButton } from "./PayOnlyPayButton";

interface Props {
    hidden: boolean;
    canStartPayment?: () => Promise<boolean>;
}

export const PayOnlyReceiptPay = ({ hidden, canStartPayment }: Props) => {
    return (
        <div className={classNames("pay-only-receipt-pay", hidden && "hidden")}>
            <PayOnlyPayButton disabled={hidden} canStartPayment={canStartPayment} />
        </div>
    );
};
