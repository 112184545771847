import { showTyroAddCard } from "./actions/showTyroAddCard";
import { TyroAddCardForm } from "./components/TyroAddCardForm";
import { verifyTyroCard } from "./actions/verifyTyroCard";
import { initializeTyroPayPalButton } from "./initializeTyroPayPalButton";
import { AddCardBehavior, PayPalBehavior, VerifyCardBehavior } from "src/features/paymentGateways/types";

export const addCardBehavior: AddCardBehavior = {
    showAddCard: showTyroAddCard,
    addCardFormElement: TyroAddCardForm,
};

export const verifyCardBehavior: VerifyCardBehavior = {
    verifyCardAction: verifyTyroCard,
};

export const payPalBehavior: PayPalBehavior = {
    initializeButton: initializeTyroPayPalButton,
};
