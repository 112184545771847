import { orderApi, reattemptStrategies } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "src/features/partyOnboarding/persistence/tableToken";
import { ActiveGroupTabData, IncreaseGroupTabInfo, OpenGroupTabInfo, UpdateTabLimitInfo } from "..";
import { getAuthHeaders } from "../../../common/auth";
import { BeginPaymentResult } from "../../payment";

export const openOrUpdateGroupTabApi = async (
    groupTabInfo: OpenGroupTabInfo | IncreaseGroupTabInfo | UpdateTabLimitInfo,
    inParty: boolean
) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/grouptabs/${"tabName" in groupTabInfo ? "open" : "increase-limit"}`,
        {
            method: "POST",
            body: JSON.stringify(groupTabInfo),
            headers,
        },
        reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ActiveGroupTabData;
};

export const beginOpenOrUpdateGroupTabApi = async (
    groupTabInfo: OpenGroupTabInfo | IncreaseGroupTabInfo,
    inParty: boolean
) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/grouptabs/begin-${"tabName" in groupTabInfo ? "open" : "increase-limit"}`,
        {
            method: "POST",
            body: JSON.stringify(groupTabInfo),
            headers,
        },
        reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as BeginPaymentResult;
};
