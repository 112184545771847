import { replace } from "connected-react-router";
import { AppDispatch, AppState } from "src/features";
import { actionCreators, getParty } from "../../order";
import { TypeKeys as PartyTypeKeys } from "../../order/reducers/party";
import { getTableToken } from "../persistence/tableToken";
import { actionCreators as paymentActionCreators } from "../../payment/reducers/payment";
import { TypeKeys as NativeTypeKeys } from "../../native/reducers/native";
import { rejoinTable } from "../actions/rejoinTable";
import { getCanPause } from "../selectors";
import { config } from "../../../common/config";
import { PartyOnboardingAction } from "../types";
import { disconnectParty } from "../api";

let inBackgroundTimer = 0;

export const proxy = (getState: () => AppState, next: AppDispatch) => {
    return async (action: PartyOnboardingAction) => {
        if (action.type === PartyTypeKeys.CLOSED || action.type === PartyTypeKeys.LEFT) {
            next(paymentActionCreators.clearPaymentMethods());

            await disconnectParty(next, true,
                action.type === PartyTypeKeys.CLOSED ? "party_closed" : "left");
        }

        if (action.type === PartyTypeKeys.PAUSED) {
            await disconnectParty(next, false, "none");
            next(replace("/join-table"));
        }

        if (config["enableBackgroundDisconnect"] === "1") {
            if (action.type === NativeTypeKeys.PAUSED && getCanPause(getState())) {
                inBackgroundTimer = window.setTimeout(() => {
                    const party = getParty(getState());
                    if (party) {
                        next(actionCreators.paused(party));
                    }
                }, 20000);
            }

            if (action.type === NativeTypeKeys.RESUMED) {
                window.clearTimeout(inBackgroundTimer);

                const party = getParty(getState());
                const tableToken = getTableToken();

                if (!party && tableToken) {
                    next(rejoinTable(tableToken));
                }
            }
        }
    };
};
