import * as React from "react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { OrderItemRow } from "./OrderItemRow";
import { Text } from "../../../sharedComponents";
import { BinIcon } from "../../../sharedComponents/assets/icons";
import { OrderCourseItem } from "../reducers/types";

export interface Props {
    item: OrderCourseItem;
    hidePrice?: boolean;
    editable?: boolean;
    selectable?: boolean;
    removeItem?: (orderItemId: string, menuItemId: string) => void;
    onRendered?: (height: number) => void;
    onDeleted?: () => void;
    onSelectItem?: (referenceId: string, quantity: number) => void;
}

export interface HTMLDivElementInternal extends HTMLDivElement {
    addEventListenerInternal?(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListenerInternal?(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | EventListenerOptions
    ): void;
}

export const OrderItem: FC<Props> = ({
    item,
    hidePrice,
    editable,
    selectable,
    removeItem,
    onRendered,
    onSelectItem,
}) => {
    const [active, setActive] = useState(false);
    const swipeContainerRef = useRef<HTMLDivElementInternal | null>(null);
    const animatingRef = useRef(false);
    const listenerRef = useRef<EventListener | undefined>();
    const transitionEndInternalTimerRef = useRef<number | undefined>();

    const customTransitionEndListener = useCallback(({ currentTarget }: Event) => {
        // We only care about currentTarget for react-swipe-to-delete-component
        // So we need to extract it here so it doesn't get set to null
        listenerRef.current!({ currentTarget } as Event);
    }, []);

    useEffect(() => {
        if (!editable) return;
        // react-swipe-to-delete-component adds the 'transitionend' listener when you drag it and then let it go
        // so it can perform a task after the animation has completed. I want to know when this happens so I can
        // trigger the row active state (grey background) to disappear at the same time rather than disappearing
        // as soon as the touchend event is triggered, so I'm hooking into add/removeEventListener so I know to
        // add a 'transitionend' listener myself or just setActive(false)
        const swipeContainer = swipeContainerRef.current!;
        if (swipeContainer.addEventListenerInternal) return;

        swipeContainer.addEventListenerInternal = swipeContainer.addEventListener;
        swipeContainer.addEventListener = function (
            type: string,
            listener: EventListener,
            options?: boolean | AddEventListenerOptions
        ) {
            if (type === "transitionend") {
                animatingRef.current = true;
                listenerRef.current = listener;
                this.addEventListenerInternal!("transitionend-internal", customTransitionEndListener, options);
                return;
            }
            this.addEventListenerInternal!(type, listener, options);
        };

        swipeContainer.removeEventListenerInternal = swipeContainer.removeEventListener;
        swipeContainer.removeEventListener = function (
            type: string,
            listener: EventListener,
            options?: boolean | EventListenerOptions
        ) {
            if (type === "transitionend") {
                animatingRef.current = false;
                this.removeEventListenerInternal!("transitionend-internal", customTransitionEndListener, options);
                return;
            }
            this.removeEventListenerInternal!(type, listener, options);
        };

        if (onRendered) {
            onRendered(swipeContainer.clientHeight);
        }

        return () => {
            clearTimeout(transitionEndInternalTimerRef.current);
        };
    }, [editable, onRendered, customTransitionEndListener]);

    const onTouchStart = useCallback(() => {
        if (!editable) return;
        setActive(true);
    }, [editable]);

    const setInactiveAfterTransition = useCallback(() => {
        clearTimeout(transitionEndInternalTimerRef.current);
        swipeContainerRef.current!.removeEventListenerInternal!("transitionend", setInactiveAfterTransition);
        swipeContainerRef.current!.dispatchEvent(new CustomEvent("transitionend-internal"));
        setActive(false);
    }, []);

    const onTouchEnd = useCallback(() => {
        if (!editable) return;
        if (!animatingRef.current) {
            setActive(false);
        } else {
            swipeContainerRef.current!.addEventListenerInternal!("transitionend", setInactiveAfterTransition, false);
            // It is possible for transitionend events not to fire in some cases
            // As this underpins whether the react-swipe-to-delete-component becomes swipeable again we will set
            // a timer for after the transition ends that will manually fire our internal transitionend event
            transitionEndInternalTimerRef.current = window.setTimeout(setInactiveAfterTransition, 300);
        }
    }, [editable, setInactiveAfterTransition]);

    return (
        <div ref={swipeContainerRef}>
            <div
                className={classNames("order-items", active && "active")}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                <OrderItemRow
                    item={item}
                    hidePrice={hidePrice}
                    activeItem={editable}
                    selectable={selectable}
                    removeItem={removeItem}
                    onSelectItem={onSelectItem}
                />
            </div>
            {editable && (
                <Text className="order-item__swipe-remove" preset="g-14" mode="bold">
                    <BinIcon />
                    Remove
                </Text>
            )}
        </div>
    );
};
