import membershipReducer from "./reducers";
import { FeatureConfiguration } from "..";
import { membershipMiddleware } from "./middleware/membershipMiddleware";
import { RETURN_URL } from "./components/MembershipOAuthSignup";
import { KnownMembershipErrors, membershipInsufficientBalanceErrorHandler } from "./errors";

export * from "./types/MembershipState";

export { actions } from "./actions";
export * from "./reducers";

export enum MessageTypeKeys {
    OAUTH_SIGNUP_COMPLETE = "MEMBERSHIP/OAUTH_SIGNUP_COMPLETE",
}

export default function (configBuilder: FeatureConfiguration) {
    configBuilder.reducers.membership = membershipReducer;
    configBuilder.middleware.push(membershipMiddleware());

    configBuilder.callbackUrls.push({
        url: RETURN_URL,
        context: "window",
        messageType: MessageTypeKeys.OAUTH_SIGNUP_COMPLETE,
    });

    configBuilder.errorHandlers.push({
        code: KnownMembershipErrors.MembershipInsufficientBalance,
        handler: membershipInsufficientBalanceErrorHandler,
    });
}
