import "./TabPaymentSelector.scss";

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { Divider, Text } from "src/sharedComponents";
import { getWizardGroupTabsLimit } from "../selectors/groupTabWizardSelectors";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { getGroupTabProcessingFee, getGroupTabTotal, getSelectedPaymentMethod } from "src/features/payment/selectors";
import { PaymentItem } from "../../payment/components/PaymentItem";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";
import { PaymentMethod } from "../../paymentMethods/components/PaymentMethod";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { getTabTypeName } from "../selectors/activeGroupTab";

interface Props {
    increasingGroupTab?: boolean;
    changePaymentMethod: () => void;
}

export const TabPaymentSelector = ({ increasingGroupTab, changePaymentMethod }: Props) => {
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
    const processingFee = useSelector(getGroupTabProcessingFee);
    const total = useSelector(getGroupTabTotal);
    const tabLimit = useSelector(getWizardGroupTabsLimit);
    const tabTypeName = useSelector(getTabTypeName);

    const menuDataLocale = useContext(MenuDataLocaleContext)!;

    return (
        <>
            <div className="tab-payment-selector__details">
                <span className="tab-payment-selector__details__title">
                    <Text preset="g-18" mode="bold">
                        Paying with
                    </Text>
                    <TappableSpan onClick={() => changePaymentMethod()}>
                        <Text preset="g-14" mode="bold" className="review-order__content__details__title__action">
                            {selectedPaymentMethod ? "Change" : "Add"} payment
                        </Text>
                    </TappableSpan>
                </span>
                {selectedPaymentMethod && <PaymentMethod paymentMethod={selectedPaymentMethod} />}
            </div>
            <Divider />
            {!!selectedPaymentMethod && tabLimit && tabLimit > 0 && (
                <div className="tab-payment-selector__details">
                    <span className="tab-payment-selector__details__title">
                        <Text preset="g-18" mode="bold">
                            Payment summary
                        </Text>
                    </span>
                    <PaymentItem
                        title={increasingGroupTab ? "Adding" : `${capitaliseFirstLetter(tabTypeName)} amount`}
                        value={tabLimit}
                    />
                    <PaymentItem
                        title={getProcessingFeeText(menuDataLocale, selectedPaymentMethod.paymentType)}
                        value={processingFee}
                    />
                    <PaymentItem title="Total" value={total} bold />
                </div>
            )}
        </>
    );
};
