import { trackedEvent } from "../../../common/events/reduxEventTracking";

export enum TypeKeys {
    THREEDSECURE_CANCEL = "PAYMENT/3DS_CANCEL",
    THREEDSECURE_SUCCESS = "PAYMENT/3DS_SUCCESS",
    THREEDSECURE_FAIL = "PAYMENT/3DS_FAIL",
}

export const actionCreators = {
    threeDSecureCancelled: () => trackedEvent({ type: TypeKeys.THREEDSECURE_CANCEL }),
    threeDSecureSuccess: () => trackedEvent({ type: TypeKeys.THREEDSECURE_SUCCESS }),
    threeDSecureFailed: (error_code?: string, error_message?: string, error_json?: string) =>
        trackedEvent({ type: TypeKeys.THREEDSECURE_FAIL, error_code, error_message, error_json }),
};
