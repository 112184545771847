import { device } from "./device";
import ResizeObserver from "resize-observer-polyfill";
import { initDeviceStyle } from "src/common/shared";

export const incorrectDocumentHeightHandler = {
    register: () => {
        if (!device.isIOS) {
            return;
        }

        if (device.isChrome && document.documentElement.clientHeight !== window.innerHeight) {
            registerIOSChrome();
        }

        if (device.isIOS15orHigher) {
            registerIOS15Safari();
        }
    },
};

function registerIOS15Safari() {
    window.addEventListener("resize", () => {
        if (Math.abs(document.body.clientHeight - window.innerHeight) < 100) {
            document.body.style.setProperty("--device-height", window.innerHeight + "px");
        }
    });
}

function registerIOSChrome() {
    const propertyName = "--document-height-diff";
    const propertyInitialName = "--document-height-initial";
    const className = "incorrect-document-height";
    const initialWindowHeight = window.innerHeight;
    const initialDocumentHeight = document.documentElement.clientHeight;
    const documentHeightDiff = initialDocumentHeight - initialWindowHeight;
    let atFullDiffHeight = true;

    const initHeights = () => {
        document.body.classList.add(className);
        document.body.style.setProperty(propertyName, `${documentHeightDiff}px`);
        document.body.style.setProperty(propertyInitialName, `${documentHeightDiff}px`);
    };
    initHeights();

    const onScroll = () => {
        if (!document.scrollingElement) return;

        const availableBottomArea =
            document.scrollingElement.scrollHeight - document.scrollingElement.scrollTop - window.innerHeight;

        if (availableBottomArea < documentHeightDiff) {
            document.body.style.setProperty(propertyName, `${availableBottomArea}px`);
            atFullDiffHeight = false;
        } else if (!atFullDiffHeight) {
            document.body.style.setProperty(propertyName, `${documentHeightDiff}px`);
            atFullDiffHeight = true;
        }
        if (window.innerHeight >= document.documentElement.clientHeight) {
            resetHeights();
        }
    };

    const resetHeights = () => {
        document.body.classList.remove(className);
        initDeviceStyle.deviceHeight();
        document.body.style.setProperty(propertyName, "0px");
        document.body.style.setProperty(propertyInitialName, "0px");
    };

    const onResize = () => {
        if (window.innerHeight > initialWindowHeight || window.innerHeight >= document.documentElement.clientHeight) {
            resetHeights();
        } else {
            initHeights();
        }
    };

    document.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]?.contentRect.height < initialDocumentHeight) {
            resizeObserver.disconnect();
            document.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", onResize);
            document.body.style.removeProperty(propertyName);
            document.body.classList.remove(className);
        }
    });

    resizeObserver.observe(document.body);
}
