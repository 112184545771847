import { AppDispatch, AppMiddleware } from "../../index";
import { ActiveGroupTabActions, TypeKeys } from "../reducers/activeGroupTab";
import { deleteLastGroupTabId, saveLastGroupTabId } from "../persistence/lastGroupTab";
import { getCurrentMemberId } from "../../accounts/selectors";
import { GroupTabStatus } from "../types";
import { actionCreators as bannerActionCreators } from "../../banners/reducers";
import { bannerMessages } from "../../banners/messages";
import { getIsGroupTabOwner, getTabTypeName } from "../selectors/activeGroupTab";
import { getActivePackageId } from "../../menu/selectors";
import { getActiveLocationId, getIsPartyDemo } from "../../order/selectors";

export const groupTabsMiddleware =
    () => (store: AppMiddleware) => (next: AppDispatch) => (action: ActiveGroupTabActions) => {
        next(action);

        if (action.type === TypeKeys.GROUP_TAB_SET_DATA) {
            const state = store.getState();
            const memberId = getCurrentMemberId(state);
            const groupTab = action.data;
            const isGroupTabOwner = getIsGroupTabOwner(state);
            const partyPackageId = getActivePackageId(state);
            const partyLocationId = getActiveLocationId(state);
            const tabTypeName = getTabTypeName(state);
            const isDemo = getIsPartyDemo(state);

            if (
                groupTab &&
                groupTab.status === GroupTabStatus.OPEN &&
                groupTab.members.some((member) => member.memberId === memberId)
            ) {
                saveLastGroupTabId(action.data.id);
                if (
                    (isGroupTabOwner || groupTab.packageId === partyPackageId) &&
                    groupTab.locationId === partyLocationId
                ) {
                    next(bannerActionCreators.showBanner(bannerMessages.activeGroupTab(tabTypeName), !isDemo));
                }
            } else {
                deleteLastGroupTabId();
                next(bannerActionCreators.showBanner(bannerMessages.activeGroupTab(tabTypeName), false));
            }
        }
    };
