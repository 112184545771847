import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../index";
import "../assets/SpinnerModal.scss";
import { Animate } from "src/sharedComponents";
import { ModalContainer } from "src/common/modal";
import classNames from "classnames";

export interface Props {
    isLoading?: boolean;
}

export const SpinnerModal: FC<Props> = (props) => {
    const { isLoading, isLoaderHidden } = useSelector((state: AppState) => {
        const {
            spinner: { isLoading, isLoaderHidden, isOperationRunning },
            modalMessage,
        } = state;
        return {
            isLoading:
                (isLoading || isOperationRunning || !!props.isLoading) &&
                (!modalMessage || modalMessage.type === "progress" || !!modalMessage.allowsSpinner),
            isLoaderHidden,
        };
    });

    return (
        <ModalContainer
            isOpen={isLoading}
            className="spinner-modal"
            overlayClassName="ReactModal__Spinner"
            bodyOpenClassName="Spinner-modal__Body--open"
            closeTimeoutMS={200}
        >
            <div className={classNames("spinner", isLoaderHidden && "spinner--hidden")}>
                <Animate name="twoDotSpinner" />
            </div>
        </ModalContainer>
    );
};
