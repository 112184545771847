import * as React from "react";

import { DrinkMenuItem, FoodMenuItem } from "../../menudata";
import { PopularIcon, SpecialIcon } from "src/sharedComponents/assets/icons";

export interface Props {
    menuItem: DrinkMenuItem | FoodMenuItem;
}

export const Tags = ({ menuItem }: Props) => {
    if (!menuItem.popular && !menuItem.special) return null;

    return (
        <div className="menu-card__fav">{menuItem.popular ? <PopularIcon /> : menuItem.special && <SpecialIcon />}</div>
    );
};
