import React, { ButtonHTMLAttributes, FC } from "react";
import { onTappableElementClick, TappableElementProps, useTappable } from "./base/TappableBase";

const TappableButton: FC<TappableElementProps<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>> = (
    props
) => {
    const [attributes, children] = useTappable(props);
    return (
        <button onClick={(e) => onTappableElementClick(e, props)} {...attributes}>
            {children}
        </button>
    );
};

export default TappableButton;
