import {
    Indexed,
    LocationMenuData,
    LocationMenuDataUpdate,
    MenuItem,
    MenuItemModifier,
    MenuItemUpdate,
    MenuItemVariant,
} from "..";
import { Category, Menu, Service, UpdateData } from "../reducers/types";
import {
    areAllItemRequiredModifiersAvailable,
    areAllVariantRequiredModifiersAvailable,
    evaluateMinSelection,
    getModifierWithOptionAvailabilitySet,
    isModifierAvailable,
} from "../helpers/availabilityHelpers";

export function mergeUpdate(
    menuData: LocationMenuData,
    update: LocationMenuDataUpdate,
    activeServiceCategories?: string[]
): LocationMenuData {
    const menus = removeMenuCategories(menuData.menus, update.categories);
    const categories = mergeCategories(menuData.categories, update.categories);
    const activeCategories: Indexed<Category> =
        activeServiceCategories?.reduce((serviceCategories, categoryId) => {
            serviceCategories[categoryId] = categories[categoryId];
            return serviceCategories;
        }, {}) || {};

    const globalModifiers = mergeGlobalModifiers(menuData.modifiers, update.unavailableSkus);

    const items = mergeItems(menuData.items, activeCategories, update.items, update.unavailableSkus, globalModifiers);

    return {
        ...menuData,
        services: removeServiceCategories(menuData.services, menus, update.categories),
        menus,
        categories,
        items,
        modifiers: globalModifiers,
    };
}

function mergeItems(
    items: Indexed<MenuItem>,
    categories: Indexed<Category>,
    itemUpdates?: Indexed<MenuItemUpdate>,
    unavailableSkus?: string[],
    globalModifiers?: Indexed<MenuItemModifier>
): Indexed<MenuItem> {
    return Object.keys(items).reduce((map, id) => {
        const maxWaitTime = Object.keys(categories).reduce((maxWaitTime, categoryId) => {
            const { menuItems, waitTime } = categories[categoryId];

            if (!waitTime || !menuItems.includes(id) || waitTime <= maxWaitTime) return maxWaitTime;
            return waitTime;
        }, 0);

        map[id] = mergeItem(
            items[id],
            maxWaitTime,
            itemUpdates ? itemUpdates[id] : undefined,
            unavailableSkus,
            globalModifiers
        );
        return map;
    }, {} as Indexed<MenuItem>);
}

function removeServiceCategories(services: Service[], menus: Indexed<Menu>, updatedCategories?: Indexed<UpdateData>) {
    return services.map((service) => {
        const originalCategories = service.originalCategories || service.categories;
        const originalMenus = service.originalMenus || service.menus;
        return {
            ...service,
            originalCategories: originalCategories,
            originalMenus: originalMenus,
            categories: originalCategories.filter((cat) => !updatedCategories || !updatedCategories[cat]?.unavailable),
            menus: originalMenus.filter((menu) => !!menus[menu].categories.length),
        };
    });
}

function removeMenuCategories(menus: Indexed<Menu>, updatedCategories?: Indexed<UpdateData>) {
    return Object.keys(menus).reduce((map, id) => {
        const originalCategories = menus[id].originalCategories || menus[id].categories;
        map[id] = {
            ...menus[id],
            originalCategories: originalCategories,
            categories: originalCategories.filter((cat) => !updatedCategories || !updatedCategories[cat]?.unavailable),
        };
        return map;
    }, {} as Indexed<Menu>);
}

function mergeCategories(categories: Indexed<Category>, updatedCategories?: Indexed<UpdateData>) {
    return Object.keys(categories).reduce((map, id) => {
        map[id] = {
            ...categories[id],
            unavailable: updatedCategories && updatedCategories[id]?.unavailable,
            waitTime: updatedCategories && updatedCategories[id]?.waitTime,
        };
        return map;
    }, {} as Indexed<Category>);
}

function mergeItem(
    item: MenuItem,
    waitTime: number,
    itemUpdate?: MenuItemUpdate,
    unavailableSkus?: string[],
    globalModifiers?: Indexed<MenuItemModifier>
) {
    const modifiers = mergeModifiers(item.modifiers, globalModifiers, unavailableSkus);
    const variants = mergeVariants(item.variants, unavailableSkus, modifiers);

    // Check item availability by item ID
    let available = itemUpdate?.available;

    if (available !== false) {
        // Check item availability by SKU
        available = unavailableSkus && item.sku ? unavailableSkus.indexOf(item.sku) < 0 : undefined;
    }

    if (available !== false) {
        // Check item availability based on variant availability
        // If all variants are unavailable then item is unavailable
        available = variants && variants.length ? !variants.every((v) => v.available === false) : undefined;
    }

    if (available !== false) {
        // Check item availability based on modifier availability
        // If all options of a required non-variant-specific modifier are unavailable then item is unavailable
        available = areAllItemRequiredModifiersAvailable(modifiers, variants);
    }

    return {
        ...item,
        available,
        variants,
        modifiers,
        waitTime: available === false ? undefined : waitTime,
    } as MenuItem;
}

function mergeVariants(variants?: MenuItemVariant[], unavailableSkus?: string[], modifiers?: MenuItemModifier[]) {
    if (!variants || !variants.length) return variants;

    return variants.map((variant) => {
        // Check variant availability by SKU
        let available = unavailableSkus && variant.sku ? unavailableSkus.indexOf(variant.sku) < 0 : undefined;

        if (available !== false) {
            // Check variant availability based on modifier availability
            // If all options of a required variant-specific modifier are unavailable then variant is unavailable
            available = areAllVariantRequiredModifiersAvailable(variant, modifiers);
        }

        return {
            ...variant,
            available,
        };
    });
}

function mergeModifiers(
    modifiers?: MenuItemModifier[],
    mergedGlobalModifiers?: Indexed<MenuItemModifier>,
    unavailableSkus?: string[]
) {
    if (!modifiers || !modifiers.length) return modifiers;

    return modifiers.map((modifier) => {
        const globalMod = mergedGlobalModifiers?.[modifier.id];
        const modifierWithUpdatedOptions = getModifierWithOptionAvailabilitySet(
            modifier,
            unavailableSkus,
            mergedGlobalModifiers
        );

        return {
            ...modifierWithUpdatedOptions,
            available: globalMod ? globalMod.available : isModifierAvailable(modifierWithUpdatedOptions),
            minSelection: evaluateMinSelection(modifierWithUpdatedOptions),
        };
    });
}

function mergeGlobalModifiers(modifiers?: Indexed<MenuItemModifier>, unavailableSkus?: string[]) {
    if (!modifiers) return modifiers;

    return Object.keys(modifiers).reduce((map, id) => {
        const currentModifier = modifiers[id];
        const modifierWithUpdatedOptions = getModifierWithOptionAvailabilitySet(
            currentModifier,
            unavailableSkus,
            modifiers
        );

        const isAvailable = isModifierAvailable(modifierWithUpdatedOptions);

        map[id] = {
            ...modifierWithUpdatedOptions,
            available: isAvailable,
            minSelection: evaluateMinSelection(modifierWithUpdatedOptions),
        };

        return map;
    }, {} as Indexed<MenuItemModifier>);
}
