import { AppDispatch, AppState } from "src/features";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getParty } from "src/features/order";
import { orderApi } from "src/features/order/orderApi";
import { switchMembershipOperation } from "../operations";

export function switchProgram(programId: string) {
    return switchMembershipOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const partyId = getParty(getState())?.id;
            if (partyId) {
                await orderApi.invoke("setActiveMembershipProgram", programId, true);
            }
        } catch (e) {
            dispatch(showModalMessage(modalMessages.switchMembershipFailed()));
            throw e; // For app insights
        }
    });
}
