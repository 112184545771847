import React from "react";
import { SmallArrowRightIcon } from "../../../sharedComponents/assets/icons";
import { PaymentMethod, SelectablePaymentMethodProps } from "./PaymentMethod";

interface Props extends SelectablePaymentMethodProps {
    onClick?: () => void;
}

export const ChangePaymentMethodAction = ({ paymentMethod, onClick }: Props) => {
    return <PaymentMethod paymentMethod={paymentMethod} onClick={onClick} rightIcon={<SmallArrowRightIcon />} />;
};
