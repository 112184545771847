import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { device, deviceReady } from "src/common/experience";
import { IPlugin } from "@microsoft/applicationinsights-core-js";

export class DeviceInfoPlugin extends BaseTelemetryPlugin {
    public identifier = "DeviceInfoPlugin";

    private deviceInfo: { [key: string]: string } | null = null;

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        deviceReady().then(() => {
            this.deviceInfo = device
                ? {
                      "device.cordova": device.cordova, // Cordova version
                      "device.model": device.model,
                      "device.platform": device.platform,
                      "device.uuid": device.uuid,
                      "device.version": device.version,
                      "device.manufacturer": device.manufacturer,
                      "device.isVirtual": device.isVirtual.toString(), // Is running under simulator?
                      "device.serial": device.serial,
                      "device.userAgent": device.userAgent,
                      "device.width": window.innerWidth.toString(),
                      "device.height": window.innerHeight.toString(),
                      "device.isPrivate": device.isPrivate.toString(),
                      "device.subBrowser": device.subBrowser ?? ''
                  }
                : null;
        });
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        if (this.deviceInfo) {
            event.data = {
                ...event.data,
                ...this.deviceInfo,
            };
        }

        this.processNext(event, itemCtx!);
    }
}
