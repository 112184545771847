import * as accountApi from "../api/accountApi";
import { saveProfileOperation } from "../operations";
import { AccountProfile } from "../types";
import { fetchProfile, profileActionCreators } from "../index";
import { getProfile } from "../selectors";
import { getParty } from "../../order";

export function saveProfile(profile: AccountProfile, callback?: () => void) {
    return saveProfileOperation.getThunk(async (dispatch, getState) => {
        const state = getState();
        dispatch(
            profileActionCreators.fetchSuccess({
                ...getProfile(state),
                ...profile,
            })
        );
        await accountApi.saveProfile(profile, !!getParty(state));
        callback?.();
        dispatch(fetchProfile());
    });
}
