import "../assets/SuccessfulMarketingOptInPage.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as navBarActionCreators } from "src/common/navigation/reducers/navBar";
import { NavBarType } from "src/common/navigation/types";
import { ModalContainer } from "src/common/modal";
import classNames from "classnames";
import { SimpleNavHeader } from "src/common/navigation";
import { Button, MessageScreen } from "src/sharedComponents";
import { EnvelopeCircleIcon } from "src/sharedComponents/assets/icons";
import { sendMarketingOptIn } from "src/features/marketing";
import { getOfferData } from "../selectors";

export const SuccessfulMarketingOptInPage = () => {
    const dispatch = useDispatch();
    const offer = useSelector(getOfferData);
    const optInStatus = useSelector(sendMarketingOptIn.getStatus);
    const [isOpen, setIsOpen] = useState(false);

    const onButtonClick = useCallback(() => {
        dispatch(navBarActionCreators.activeType(NavBarType.MENU));
        setIsOpen(false);
    }, [dispatch]);

    useEffect(() => setIsOpen(optInStatus === "complete" && !offer), [optInStatus, offer]);

    return (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "successful-marketing-modal",
                afterOpen: "successful-marketing-modal",
                beforeClose: "successful-marketing-modal--before-close",
            }}
            overlayClassName="successful-marketing-modal--overlay"
            closeTimeoutMS={250}
        >
            <div className="successful-marketing-modal">
                <div className={classNames("scroll-element", "animated-child")}>
                    <SimpleNavHeader closeToBack onBack={() => setIsOpen(false)} withBorder />
                    <MessageScreen
                        icon={<EnvelopeCircleIcon />}
                        title="Thanks for subscribing"
                        description={["Keep a lookout in your inbox for all the latest.", "Until next time!"]}
                    />
                    <footer className="successful-marketing-modal__footer">
                        <Button value={"View menu"} onClick={onButtonClick} />
                    </footer>
                </div>
            </div>
        </ModalContainer>
    );
};
