export interface OrderHeadCountState {
    showOrderHeadCountModal: boolean;
    value: number;
}

export enum TypeKeys {
    SHOW_ORDER_HEAD_COUNT_MODAL = "PARTY/SHOW_ORDER_HEAD_COUNT_MODAL",
    UPDATE_ORDER_HEAD_COUNT_VALUE = "PARTY/UPDATE_ORDER_HEAD_COUNT_VALUE",
    RESET_ORDER_HEAD_COUNT = "PARTY/RESET_ORDER_HEAD_COUNT",
}

export const actionCreators = {
    showOrderHeadCountModal: (show: boolean) => ({ type: TypeKeys.SHOW_ORDER_HEAD_COUNT_MODAL, show }),
    updateOrderHeadCountValue: (value: number) => ({ type: TypeKeys.UPDATE_ORDER_HEAD_COUNT_VALUE, value }),
    resetOrderHeadCount: () => ({ type: TypeKeys.RESET_ORDER_HEAD_COUNT }),
};

export type ShowOrderHeadCountModalAction = { type: TypeKeys.SHOW_ORDER_HEAD_COUNT_MODAL; show: boolean };
export type UpdateOrderHeadCountValueAction = { type: TypeKeys.UPDATE_ORDER_HEAD_COUNT_VALUE; value: number };
export type ResetOrderHeadCountAction = { type: TypeKeys.RESET_ORDER_HEAD_COUNT };

export type OrderHeadCountAction =
    | ShowOrderHeadCountModalAction
    | UpdateOrderHeadCountValueAction
    | ResetOrderHeadCountAction;

const initialState: OrderHeadCountState = {
    showOrderHeadCountModal: false,
    value: 1,
};

export function reducer(state: OrderHeadCountState = initialState, action: OrderHeadCountAction): OrderHeadCountState {
    if (action.type === TypeKeys.SHOW_ORDER_HEAD_COUNT_MODAL) {
        return {
            ...state,
            showOrderHeadCountModal: action.show,
        };
    }

    if (action.type === TypeKeys.UPDATE_ORDER_HEAD_COUNT_VALUE) {
        return {
            ...state,
            value: action.value,
        };
    }

    if (action.type === TypeKeys.RESET_ORDER_HEAD_COUNT) {
        return initialState;
    }

    return state;
}
