import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { href } from "src/common/experience";
import { Text } from "src/sharedComponents/controls/text/Text";
import { MembershipLinks } from "..";
import { actionCreators } from "src/features/accounts/reducers/authState";

interface Props {
    programName: string;
    title?: string;
    links?: MembershipLinks;
}

export const MembershipSignupHeader = ({ title, programName, links }: Props) => {
    const dispatch = useDispatch();
    const linksAttributes = links?.privacy && href.openInNewWindowAttributes(links?.privacy);
    const termsAttributes = links?.terms && href.openInNewWindowAttributes(links?.terms);

    const onPrivacyPolicyClicked = useCallback(
        () => dispatch(actionCreators.onPrivacyPolicyClicked(true, links?.privacy)),
        [dispatch, links]
    );
    return (
        <div className="membership-signup__title profile-page__title">
            <Text preset="title-28" mode="bold" value={title || "Membership discounts"}></Text>
            <Text preset="g-14" className="membership-signup__subtitle" mode="block">
                Link your {programName} account to unlock your deals every time you order.{" "}
                {!!linksAttributes && !!termsAttributes && (
                    <span>
                        {" "}
                        View {programName}{" "}
                        <a className="membership-signup__subtitle__link" {...termsAttributes}>
                            Terms &amp; Conditions
                        </a>{" "}
                        and{" "}
                        <a
                            className="membership-signup__subtitle__link"
                            {...linksAttributes}
                            onClick={onPrivacyPolicyClicked}
                        >
                            Privacy Policy
                        </a>
                        .
                    </span>
                )}
            </Text>
        </div>
    );
};
