import * as React from "react";
import { FormControl, Input } from "src/sharedComponents";
import { isValidEmail } from "../../validation";

interface Props {
    initialEmail?: string;
    infoOnChange: (email: string, isValid: boolean) => void;
    placeholder?: string;
}

interface State {
    email: string;
    isValid: boolean;
    reported: boolean;
}

export class EmailInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { initialEmail } = props;
        this.state = {
            email: initialEmail || "",
            isValid: true,
            reported: false,
        };
    }

    render() {
        const { placeholder } = this.props;
        const { email, isValid, reported } = this.state;

        return (
            <FormControl id="email_input" invalid={reported && !isValid} invalidMessage="Email address is invalid">
                <Input
                    type="email"
                    label="Email"
                    value={email}
                    placeholder={placeholder ?? "Your email"}
                    onChange={this.handleEmailChange}
                    onBlur={this.onBlur}
                />
            </FormControl>
        );
    }

    onBlur = () => {
        this.setState({ reported: true });
    };

    sendUpdate = () => {
        const { infoOnChange } = this.props;
        const { email, isValid } = this.state;
        infoOnChange(email, isValid);
    };

    handleEmailChange = (email?: string) => {
        const isValid = !!email && isValidEmail(email);
        if (isValid) {
            this.setState({ reported: false });
        }
        this.setState({ isValid, email: email || "" }, this.sendUpdate);
    };
}
