import { orderApi } from "../../order/orderApi";
import { AppDispatch, AppState } from "../..";
import { makePayOnlyPaymentOperation } from "../operations";
import { getParty, PartyStatus } from "../../order";
import { getPaymentInfo, handleExternalPayment, trackTip } from "../../payment/actions/handlePayment";
import {
    getPayOnlyPaymentInfo,
    getPayOnlyPendingRemainingBalance,
    getPayOnlyState,
    getPayOnlyTipAmount,
    getPayOnlyTotalBeforeTip,
} from "../selectors";
import { PayOnlyOverpaymentError, PayOnlyRemainingBalanceTooLowError } from "../errors";
import { getPaymentMethodsBackground } from "../../payment/actions/getPaymentMethods";
import { fetchProfile } from "../../accountmenu";
import { actionCreators as paymentActionCreators, PaymentFunc, TipType } from "../../payment";
import { MustUseExtendedValidationError } from "../../payment/errors";
import { payOnlyTrackActionCreators } from "../reducers/payOnlyTrack";
import { invokeConnectionRetry } from "src/features/order/orderApi/serverOrderApi";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";
import { actionCreators } from "../reducers";
import { evenRound } from "../../../sharedComponents/common/utils";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getAnyParty } from "../../order/selectors";
import { payOnlyPaymentWizard } from "src/features/payOnly/wizards";

const LOW_BALANCE_LIMIT = 5; // if a bill balance is less than 5 after a user attempts to pay, the low balance limit error is triggered.

export const makePayment: PaymentFunc = (result, completePayment, validate) => {
    return makePayOnlyPaymentOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            if (window.navigator.onLine === false) throw new Error("Offline");

            const state = getState();
            const party = getParty(state);
            trackTip(state, dispatch);

            result = await validate(result);

            const getPaymentInfoLocal = async () => ({
                ...(await getPaymentInfo(dispatch, getState, result)),
                ...getPayOnlyPaymentInfo(state),
            });

            let paymentInfo = await getPaymentInfoLocal();

            const isExternalPayment = await handleExternalPayment(dispatch, getState, paymentInfo, party);

            // We only want to retry 'external' payments (where payment completes _before_
            // payAndSubmitOrderInSequence) otherwise we might create multiple payments
            const invokeMakePayment = () =>
                isExternalPayment
                    ? orderApi.invokeWithRetry(invokeConnectionRetry(10), "makePayment", paymentInfo)
                    : orderApi.invoke("makePayment", paymentInfo);

            try {
                await invokeMakePayment();
            } catch (err) {
                if (!(err instanceof MustUseExtendedValidationError)) throw err;

                result = await validate(result, true);
                paymentInfo = await getPaymentInfoLocal();
                await invokeMakePayment();
            }

            const { splitOption, amount, share, selectedItems } = getPayOnlyState(state);

            if (splitOption) {
                dispatch(
                    payOnlyTrackActionCreators.splitBillAction(
                        splitOption,
                        amount,
                        share?.split,
                        share?.shares,
                        selectedItems
                    )
                );
            }

            const anyParty = getAnyParty(getState());
            if (anyParty?.status === PartyStatus.OPEN) {
                dispatch(fetchProfile(() => dispatch(getPaymentMethodsBackground())));
            }
        } catch (err) {
            if (err instanceof PayOnlyOverpaymentError) {
                dispatch(showOverpayment(result, completePayment, validate));
            } else if (err instanceof PayOnlyRemainingBalanceTooLowError) {
                dispatch(showRemainingBalanceTooLow(result, completePayment, validate));
            }
            throw err;
        } finally {
            await completePayment?.();
        }
    });
};

const showRemainingBalanceTooLow: PaymentFunc = (result, completePayment, validate) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const menuDataLocale = getMenuDataLocale(state)!;
        const remainingBalance = getPayOnlyPendingRemainingBalance(state);
        const total = getPayOnlyTotalBeforeTip(state);
        const tipAmount = getPayOnlyTipAmount(state);

        const remakePayment = () => {
            dispatch(paymentActionCreators.setCustomTipAmount(TipType.CURRENCY, tipAmount));
            dispatch(actionCreators.setAmount(remainingBalance));
            dispatch(makePayment(result, completePayment, validate));
        };

        const amountLeft = evenRound(remainingBalance - total, 2);

        dispatch(payOnlyTrackActionCreators.lowBalanceAction(total.toFixed(2), amountLeft.toFixed(2)));

        dispatch(
            showModalMessage(
                modalMessages.payOnlyRemainingBalanceTooLow(
                    menuDataLocale.formatCurrency(amountLeft),
                    remakePayment,
                    menuDataLocale.formatCurrency(LOW_BALANCE_LIMIT)
                )
            )
        );
    };
};

const showOverpayment: PaymentFunc = (result, completePayment, validate) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const menuDataLocale = getMenuDataLocale(state)!;
        const remainingBalance = getPayOnlyPendingRemainingBalance(state);

        if (!remainingBalance) {
            dispatch(
                showModalMessage(
                    modalMessages.payOnlyZeroRemainingBalance(() => {
                        dispatch(payOnlyPaymentWizard.actionCreators.done());
                        dispatch(actionCreators.reset());
                    })
                )
            );
            return;
        }

        const total = getPayOnlyTotalBeforeTip(state);
        const tipAmount = getPayOnlyTipAmount(state);

        const remakePayment = (newTipAmount?: number) => {
            dispatch(paymentActionCreators.setCustomTipAmount(TipType.CURRENCY, newTipAmount ?? tipAmount));
            dispatch(actionCreators.setAmount(remainingBalance));
            dispatch(makePayment(result, completePayment, validate));
        };

        const trackOverPaymentResponse = (tip?: number) => {
            dispatch(
                payOnlyTrackActionCreators.overPaymentResponseAction(
                    !!tip,
                    menuDataLocale.formatCurrency(remainingBalance),
                    tip ? menuDataLocale.formatCurrency(tip) : undefined
                )
            );
        };

        const payRemainingAction = () => {
            trackOverPaymentResponse();
            remakePayment();
        };

        const tip = evenRound(total - remainingBalance, 2);

        const increaseTipAction = () => {
            trackOverPaymentResponse(tip);
            remakePayment(tip);
        };

        dispatch(
            showModalMessage(
                modalMessages.payOnlyOverpayment(
                    menuDataLocale.formatCurrency(remainingBalance),
                    menuDataLocale.formatCurrency(total),
                    menuDataLocale.formatCurrency(tip),
                    increaseTipAction,
                    payRemainingAction
                )
            )
        );
    };
};
