import { deviceReady } from "..";

import { appForegroundHandler, onPause, onResume } from "../shared/appForegroundChange";

const onVisibilityChange = () => {
    if (document.hidden) {
        onPause();
    } else {
        onResume();
    }
};

export const appForegroundChange = (onForeground: () => void, onBackground: () => void) => {
    appForegroundHandler(onForeground, onBackground);
    deviceReady().then(() => {
        if ("hidden" in document) {
            document.removeEventListener("visibilitychange", onVisibilityChange, false);
            document.addEventListener("visibilitychange", onVisibilityChange, false);
        } else {
            window.removeEventListener("blur", onPause);
            window.addEventListener("blur", onPause);
            window.removeEventListener("focus", onResume);
            window.addEventListener("focus", onResume);
        }
    });
};
