import React, { CSSProperties, ReactNode } from "react";
import { Location } from "history";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";
import classNames from "classnames";
import "./PageTransition.scss";

interface Props {
    location: Location;
    children: ReactNode;
    timeout?: number;
    animation?: string | CSSTransitionClassNames;
    transitionAction?: string;
    onLoad?: () => void;
    cssTransitionProps?: TransitionProps;
}

export const PageTransition = ({
    location,
    children,
    timeout = 300,
    onLoad,
    transitionAction,
    animation = "slide-left",
    cssTransitionProps,
}: Props) => {
    return (
        <TransitionGroup
            className={classNames(transitionAction, "page-transition-wrapper")}
            style={{ "--animation-time": timeout + "ms" } as CSSProperties}
        >
            <CSSTransition
                key={location.pathname}
                {...cssTransitionProps}
                onEntered={onLoad || cssTransitionProps?.onEnter}
                classNames={animation}
                timeout={{ enter: timeout, exit: timeout - 25 }}
            >
                {children}
            </CSSTransition>
        </TransitionGroup>
    );
};
