import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { href } from "src/common/experience";
import { LegalModal } from "src/features/compliance/components/LegalModal";
import { getMarketingData } from "src/features/marketing/selectors/optInMarketing";
import { getLocationName } from "src/features/menu/selectors";
import { Text } from "src/sharedComponents";
import { PresentIcon } from "src/sharedComponents/assets/icons";
import { TappableAnchor, TappableSpan } from "src/sharedComponents/common/tappable";
import { marketingOptInAction } from "../actions/marketingOptInAction";

export const NoOffersSection = () => {
    const marketingData = useSelector(getMarketingData);
    const venueName = useSelector(getLocationName);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const dispatch = useDispatch();

    const showMarketingOptIn = !!(
        marketingData.enabled &&
        marketingData.newsletterProvider &&
        marketingData.privacyPolicyUrl
    );

    return (
        <>
            {showPrivacyPolicy && <LegalModal legalPage={"privacyPolicy"} goBack={() => setShowPrivacyPolicy(false)} />}
            <section className="my-offers-page__no-offers">
                <PresentIcon />
                <Text preset="g-16" mode={["bold", "block"]}>
                    No rewards right now
                </Text>
                <Text preset="g-14" mode="block" className="my-offers-page__no-offers__subtitle">
                    Rewards are personalised deals or promotions you may receive from venues. You’ll only see rewards
                    for the venue you’re ordering from right now.
                </Text>
                {showMarketingOptIn && (
                    <Text preset="g-14" mode="block" className="my-offers-page__no-offers__subtitle--opt-in">
                        Stay updated on news and rewards by signing up for the {venueName} newsletter.
                    </Text>
                )}
            </section>
            {showMarketingOptIn && (
                <>
                    <TappableSpan
                        onClick={() => dispatch(marketingOptInAction())}
                        className="my-offers-page__subscribe-button"
                    >
                        <Text preset="g-16" mode="bold">
                            Subscribe to mailing list
                        </Text>
                    </TappableSpan>
                    <div className="my-offers-page__privacy-policy">
                        <Text preset="g-12" className="opt-in-page__privacy-policy-text" mode="block">
                            By subscribing, I agree to join the {venueName} mailing list. View their {"\u00A0"}
                            <TappableAnchor
                                className="my-offers-page__privacy-policy__link"
                                {...href.openInNewWindowAttributes(marketingData.privacyPolicyUrl!)}
                            >
                                Privacy Policy
                            </TappableAnchor>
                            {"\u00A0"}
                            and me&u’s{"\u00A0"}
                            <TappableAnchor
                                shouldPreventDefault
                                onTap={() => {
                                    setShowPrivacyPolicy(true);
                                }}
                                className="my-offers-page__privacy-policy__link"
                            >
                                Privacy Policy.
                            </TappableAnchor>
                        </Text>
                    </div>
                </>
            )}
        </>
    );
};
