import React, { useCallback, HTMLAttributes } from "react";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { TappableElementProps } from "../../../sharedComponents/common/tappable/components/base/TappableBase";
import { useDispatch, useSelector } from "react-redux";
import { getIsConnected } from "../../order/selectors";
import { actionCreators as notificationsActionCreators } from "../reducers/notifications";

export const NetworkConnectedTappableDiv = (
    props: TappableElementProps<HTMLDivElement, HTMLAttributes<HTMLDivElement>>
) => {
    const dispatch = useDispatch();
    const connected = useSelector(getIsConnected);

    const handleDisabledTap = useCallback(() => {
        dispatch(notificationsActionCreators.showNoNetworkConnection(true));
    }, [dispatch]);

    return (
        <TappableDiv
            {...props}
            ref={null}
            disabled={!connected || props.disabled}
            onDisabledTap={!connected ? handleDisabledTap : props.onDisabledTap}
        />
    );
};
