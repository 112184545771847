import { AppDispatch, AppState } from "../..";
import { joinTable } from "src/features/partyOnboarding/actions/joinTable";
import { getOpenTableLocationMarkerClaims } from "../selectors";

export const startFlexTab = () => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const locationClaim = getOpenTableLocationMarkerClaims(getState());
        dispatch(joinTable(locationClaim!, undefined, true));
    };
};
