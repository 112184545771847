import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Button, Text } from "src/sharedComponents";
import { ServiceScheduleSlot } from "../types";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";

interface Props {
    service: ServiceScheduleSlot;
    selectedTime: string | Date | null;
    onServiceSelection: (time: string | Date, service: string) => void;
}

export const ScheduleServiceTimes = ({ service, selectedTime, onServiceSelection }: Props) => {
    const [showAllTimesForService, setShowAllTimesForService] = useState(false);
    const menuDateLocale = useContext(MenuDataLocaleContext);

    if (!service.scheduleSlots.length) {
        return null;
    }

    const scheduleSlots = showAllTimesForService ? service.scheduleSlots : service.scheduleSlots.slice(0, 12);

    return (
        <div className="schedule-takeaway__service-container">
            <Text preset="g-18" mode="bold" className="schedule-takeaway__service-name">
                {service?.displayName}
            </Text>
            <div className="schedule-takeaway__times-grid">
                {scheduleSlots.map((timeSlot) => {
                    const isActive = timeSlot.scheduleTime === selectedTime;
                    return (
                        <Button
                            key={`${timeSlot.scheduleTime}-${service.serviceId}`}
                            className={classNames("schedule-takeaway__time-slot", isActive ? "active" : "inactive")}
                            mode={isActive ? "solid" : "outline"}
                            onClick={() => onServiceSelection(timeSlot.scheduleTime, service.serviceId)}
                        >
                            <Text preset="g-14" mode="bold">
                                {menuDateLocale.getDateTimeShort(timeSlot.scheduleTime)}
                            </Text>
                        </Button>
                    );
                })}
            </div>
            {service.scheduleSlots.length > 12 && (
                <TappableDiv onTap={() => setShowAllTimesForService(!showAllTimesForService)}>
                    <Text preset="g-14" mode="bold" className="schedule-takeaway__more-less-button">
                        Show {showAllTimesForService ? "less" : "more"}
                    </Text>
                </TappableDiv>
            )}
        </div>
    );
};
