import "../assets/OfferClaimedPage.scss";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { SimpleNavHeader } from "src/common/navigation";
import { StampCardLandingContent } from "./StampCardLandingContent";
import { useDispatch, useSelector } from "react-redux";
import { getClaimOfferResult, getOfferData } from "../selectors";
import { ClaimedFailedReason, OfferStatus } from "../types";
import { offerClaimFlowWizard } from "../wizard";
import { Button } from "src/sharedComponents";
import { OfferClaimedPageContent } from "./OfferClaimedPageContent";
import { actionCreators } from "../reducers";
import { actionCreators as navBarActionCreators } from "src/common/navigation/reducers/navBar";
import { NavBarType } from "src/common/navigation/types";
import { getActiveService } from "src/features/menu/selectors";
import { useHistory } from "react-router";

interface Props {
    onClose: () => void;
}

export const OfferClaimedInPartyPage = ({ onClose }: Props) => {
    const offer = useSelector(getOfferData);
    const claimOfferResult = useSelector(getClaimOfferResult);
    const activeService = useSelector(getActiveService);

    const dispatch = useDispatch();
    const history = useHistory();

    const claimedFailedReason = claimOfferResult?.claimedFailedReason;

    const offerAlreadyExistsInWallet = claimedFailedReason === ClaimedFailedReason.ALREADY_EXISTS;

    const onViewMenuButtonClick = useCallback(() => {
        dispatch(offerClaimFlowWizard.actionCreators.done());
        setTimeout(() => {
            dispatch(actionCreators.setOffer(undefined));
            dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            if (activeService) {
                history.replace(`/menu/${activeService.menus[0]}/${activeService.categories[0]}`);
            }
        }, 250);
    }, [dispatch, activeService, history]);

    const stampCardText = useMemo(() => {
        if (offerAlreadyExistsInWallet) {
            switch (offer?.status) {
                case OfferStatus.CLAIMED:
                    return {
                        title: "Already claimed",
                        subtitle: "Check out your progress on this card so far:",
                    };
                case OfferStatus.REDEEMED:
                    return {
                        title: "Stamp card complete",
                        subtitle:
                            "You’ve already earned enough stamps to unlock all the rewards available on this card. Congratulations!",
                    };
                default:
                    return undefined;
            }
        }
        return undefined;
    }, [offerAlreadyExistsInWallet, offer]);

    if (!offer) {
        return null;
    }

    const OfferClaimedInPartyFooter = () => {
        return (
            <footer className="offer-claimed-page__in-party-footer">
                <Button value="View the menu" onClick={onViewMenuButtonClick} />
                <Button
                    value="Back to rewards"
                    onClick={() => {
                        dispatch(offerClaimFlowWizard.actionCreators.done());
                    }}
                    mode="outline"
                />
            </footer>
        );
    };

    return (
        <div className="offer-claimed-page">
            <div className={classNames("scroll-element", "animated-child")}>
                <SimpleNavHeader backgroundTransparent closeToBack onBack={onClose} />
                {offerAlreadyExistsInWallet && stampCardText ? (
                    <>
                        <StampCardLandingContent
                            titleText={stampCardText.title}
                            subtitleText={stampCardText.subtitle}
                            onStampCardClick={() => {
                                dispatch(offerClaimFlowWizard.actionCreators.prev());
                            }}
                            offer={offer}
                        >
                            <OfferClaimedInPartyFooter />
                        </StampCardLandingContent>
                    </>
                ) : (
                    <OfferClaimedPageContent>
                        {claimOfferResult && <OfferClaimedInPartyFooter />}
                    </OfferClaimedPageContent>
                )}
            </div>
        </div>
    );
};
