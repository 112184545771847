import * as React from "react";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { OrderCourseItem } from "../reducers/types";
import { actionCreators as partyActions } from "../reducers/party";
import SwipeToDelete from "react-swipe-to-delete-component";
import { OrderItem, Props } from "./OrderItem";

export const SwipeableOrderItem: FC<Props> = ({ item, hidePrice, removeItem, onDeleted }) => {
    const dispatch = useDispatch();

    const [deleted, setDeleted] = useState(false);
    const [height, setHeight] = useState(0);

    const deleteItem = useCallback(
        (del: { item: OrderCourseItem }) => {
            setDeleted(true);
            dispatch(partyActions.removeFromOrder(del.item.orderItemId!, del.item.menuItemId!));
            onDeleted && onDeleted();
        },
        [dispatch, onDeleted]
    );

    return (
        <div
            style={{ "--order-item-height": `${height}px` } as React.CSSProperties}
            id={"order-item-" + item.orderItemId!}
        >
            {deleted && <div className="order-item--deleted" />}
            <SwipeToDelete
                key={item.orderItemId!}
                item={item}
                background={<></>}
                classNameTag="order-item__swipe-to-delete"
                onDelete={deleteItem}
            >
                <OrderItem editable item={item} hidePrice={hidePrice} removeItem={removeItem} onRendered={setHeight} />
            </SwipeToDelete>
        </div>
    );
};
