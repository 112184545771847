import React, { useCallback } from "react";
import { Button } from "../../../sharedComponents";
import { ButtonProps } from "../../../sharedComponents/controls/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getIsConnected } from "../../order/selectors";
import { actionCreators as notificationsActionCreators } from "../reducers/notifications";

export interface NetworkConnectedButtonProps extends ButtonProps {}

export const NetworkConnectedButton = (props: NetworkConnectedButtonProps) => {
    const dispatch = useDispatch();
    const connected = useSelector(getIsConnected);

    const handleDisabledClick = useCallback(() => {
        dispatch(notificationsActionCreators.showNoNetworkConnection(true));
    }, [dispatch]);

    return (
        <Button
            {...props}
            disabled={!connected || props.disabled}
            onDisabledClick={!connected ? handleDisabledClick : props.onDisabledClick}
        />
    );
};
