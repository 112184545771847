import { connect } from "react-redux";
import { AppState } from "../../index";
import { Props, ReviewOrder } from "../component/ReviewOrder";
import {
    getActiveLocationId,
    getActiveOrderStatus,
    getActiveVenueLocationDetails,
    getCanShowDemoFeatures,
    getIsConnected,
    getIsSectionTakeaway,
    getOrderDateTimeScheduled,
    getOrderReadyTime,
    getParty,
    getTableLabel,
} from "../selectors";
import { getLocationName, getVisibleMenuData } from "../../menu/selectors";
import { getIsAllowedZeroPayment, getPaymentMethods, getSelectedPaymentMethod } from "../../payment/selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { shouldFoodShowPickup } from "src/features/orderHistoryData/selectors";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { showModalMessage } from "../../modalMessage/actions/show";
import { paymentFlowActions } from "../../payment";
import { actionCreators as partyActions } from "../reducers/party";
import { manageMembershipsActions } from "src/features/membership";
import { validateActiveOrderInBackground } from "../actions/validateActiveOrder";
import { actionCreators as accountActions } from "src/features/accounts/reducers/authState";
import { actionCreators as takeawayActions, CollectionDetails } from "src/features/takeaway/reducers/takeawayOptions";
import { getPreviousVenueLocationsDetails, getTakeawayDetails } from "src/features/takeaway/selectors/takeawayOptions";
import { getConnectedMembershipState } from "../../membership/selectors/getConnectedMembershipState";
import { isNewCardPaymentMethod } from "src/common/payment";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        restaurantFlags: { mustAcceptAllergenNotice },
        profile: { result },
        paymentFlow: { page, isOpeningTab },
        party: { acceptedAllergenNotice },
    } = state;

    const party = getParty(state);
    const selectedPaymentMethod = getSelectedPaymentMethod(state);
    const { fromTheVenueTitle, fromTheVenue } = getVisibleMenuData(state)!;
    const connected = getIsConnected(state);
    const foodPickup = shouldFoodShowPickup(state);
    const orderStatus = getActiveOrderStatus(state);
    const connectedMemberShip = getConnectedMembershipState(state);
    const isTakeaway = getIsSectionTakeaway(state);
    const orderReadyTime = getOrderReadyTime(state);
    const orderDateTimeScheduled = getOrderDateTimeScheduled(state);
    const venueCollectionDetails = getActiveVenueLocationDetails(state);
    const locationName = getLocationName(state);
    const locationId = getActiveLocationId(state);
    const allVenuesCollectionDetails = getPreviousVenueLocationsDetails(state);
    const hasVenueCollectionDetails = !!(locationId && allVenuesCollectionDetails?.[locationId]);
    const takeawayDetails = getTakeawayDetails(state);
    const showDemoBanner = getCanShowDemoFeatures(state);
    const isAllowedZeroPayment = getIsAllowedZeroPayment(state);
    const paymentMethods = getPaymentMethods(state);
    const canChangePaymentMethod =
        !!paymentMethods?.length && (paymentMethods.length > 1 || isNewCardPaymentMethod(paymentMethods[0]));
    const tableLabel = getTableLabel(state);

    return {
        party,
        selectedPaymentMethod,
        fromTheVenueTitle,
        fromTheVenue,
        connected,
        showDeliveryOptions: foodPickup || isTakeaway,
        orderStatus,
        mustAcceptAllergenNotice,
        profile: result!,
        closeOrderView: page !== "ReviewOrder",
        acceptedAllergenNotice,
        connectedMemberShip,
        isOpeningTab,
        isTakeaway,
        orderReadyTime,
        orderDateTimeScheduled,
        venueCollectionDetails,
        locationName,
        locationId,
        hasVenueCollectionDetails,
        takeawayDetails,
        showDemoBanner,
        isAllowedZeroPayment,
        canChangePaymentMethod,
        tableLabel,
    };
};

const mapDispatchToProps = (dispatch: any): FunctionProps<Props> => {
    return {
        showModalMessage: (modalMessage: ModalMessage) => dispatch(showModalMessage(modalMessage)),
        editAccountDetails: () => dispatch(paymentFlowActions.editPaymentPage("AccountDetails")),
        changePaymentMethod: () => dispatch(paymentFlowActions.editPaymentPage("SelectPaymentMethod")),
        changeTabPaymentMethod: () => dispatch(paymentFlowActions.editPaymentPage("CreateTab")),
        acceptAllergenNotice: (accept: boolean) => dispatch(partyActions.acceptAllergenNotice(accept)),
        manageMemberships: () => dispatch(manageMembershipsActions.showManageMemberships(true)),
        validateActiveOrderImmediately: () => dispatch(validateActiveOrderInBackground()),
        onPrivacyPolicyClicked: () => dispatch(accountActions.onPrivacyPolicyClicked(false)),
        storeVenueCollectionDetails: (locationId: string, collectionDetails: CollectionDetails) =>
            dispatch(takeawayActions.setVenueDetails({ [locationId]: collectionDetails })),
    };
};

export const ReviewOrderContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);
