import { createOperation } from "../../operations";
import { AddedCardResult, ExternalPaymentResult, GetAddedCardFunc, GetCvvTokenFunc } from "../../payment";

export const showStripeAddCardOperation = createOperation<GetAddedCardFunc>("PAYMENT/SHOW_STRIPE_ADD_CARD");
export const getStripeAddedCardOperation = createOperation<AddedCardResult | null>("PAYMENT/GET_STRIPE_ADDED_CARD");
export const getStripeAddedCardForPaymentOperation = createOperation<AddedCardResult | null>(
    "PAYMENT/GET_STRIPE_ADDED_CARD_FOR_PAYMENT",
    { showSpinner: true }
);
export const completeStripePaymentOperation = createOperation<ExternalPaymentResult>("PAYMENT/COMPLETE_STRIPE_PAYMENT");
export const showStripeEnterCvvOperation = createOperation<GetCvvTokenFunc>("PAYMENT/SHOW_STRIPE_ENTER_CVV");
export const getStripeCvvTokenOperation = createOperation<string>("PAYMENT/GET_STRIPE_CVV_TOKEN", {
    showSpinner: true,
});
