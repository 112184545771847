import { fetchingBackgroundImageOperation } from "../operations";
import { preloadImage } from "../../../common/shared";

export function fetchBackgroundImage(backgroundImage?: string) {
    return fetchingBackgroundImageOperation.getThunk(async () => {
        if (backgroundImage) {
            await preloadImage(backgroundImage);
        }
    });
}
