import "./TabOrdersModal.scss";

import classNames from "classnames";
import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { SimpleNavHeader } from "src/common/navigation";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { Text, CallToActionHeader, RefreshButton } from "src/sharedComponents/";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../reducers/activeGroupTab";
import {
    getTabTypeName,
    getDebits,
    getShowOrdersModal,
    getOrders,
    getIsGroupTabOwner,
    getGroupTabData,
} from "../selectors/activeGroupTab";
import { EmptyBag } from "src/sharedComponents/assets/icons";
import { getParty } from "src/features/order/selectors";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { InviteToGroupTab } from "./InviteToGroupTab";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { NavBarType } from "../../../common/navigation/types";
import { getOrderDataAction } from "../actions/getOrderDataAction";
import { getGroupTabUpdatesAction } from "../actions/getGroupTabAction";
import { GroupTabOrders } from "../../groupTabsHistory/components/GroupTabOrders";
import { getIsGroupTabPollingPaused } from "../selectors/getIsGroupTabPollingPaused";
import { ModalContainer } from "../../../common/modal";
import { simplePlural } from "src/common/strings";

const POLLING_INTERVAL = 10000;

export const TabOrdersModal = () => {
    const activeTab = useSelector(getGroupTabData);
    const showOrdes = useSelector(getShowOrdersModal);
    const tabTypeName = useSelector(getTabTypeName);
    const isInParty = !!useSelector(getParty);
    const debits = useSelector(getDebits);
    const orders = useSelector(getOrders);
    const paused = useSelector(getIsGroupTabPollingPaused);
    const isGroupTabOwner = useSelector(getIsGroupTabOwner);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [newOrdersCount, setNewOrdersCount] = useState(0);

    const timerRef = useRef(-1);

    const [show, setShow] = useState(!!showOrdes);
    const [visibleDebits, setVisibleDebits] = useState(debits);

    const dispatch = useDispatch();

    const afterClose = useCallback(() => {
        dispatch(actionCreators.showTabOrders(false));
    }, [dispatch]);

    const closeModal = () => setShow(false);

    const goToMenu = useCallback(() => {
        dispatch(navBarActionCreators.activeType(NavBarType.MENU));
        closeModal();
    }, [dispatch]);

    const handleShowingOrder = useCallback(
        (partyId: string) => {
            dispatch(getOrderDataAction(partyId));
        },
        [dispatch]
    );

    const handleOnClickRefreshButton = () => {
        setVisibleDebits(debits);
        setShowRefreshButton(false);
    };

    const poll = useCallback(() => {
        dispatch(getGroupTabUpdatesAction());
        timerRef.current = window.setTimeout(poll, POLLING_INTERVAL);
    }, [dispatch]);

    useEffect(() => {
        if (paused) {
            window.clearTimeout(timerRef.current);
        }
    }, [paused]);

    useEffect(() => {
        return () => {
            afterClose();
        };
    }, [afterClose]);

    useEffect(() => {
        setShow(!!showOrdes);
    }, [showOrdes]);

    useEffect(() => {
        if (!paused && isGroupTabOwner) {
            poll();
        }
        return () => {
            window.clearTimeout(timerRef.current);
        };
    }, [dispatch, paused, isGroupTabOwner, timerRef, poll]);

    const newOrders = useMemo(
        () => (debits && visibleDebits ? debits.length - visibleDebits.length : 0),
        [debits, visibleDebits]
    );

    useEffect(() => {
        if (newOrders && visibleDebits?.length) {
            setNewOrdersCount(newOrders);
            setShowRefreshButton(true);
        }
    }, [newOrders, visibleDebits]);

    useEffect(() => {
        if (!visibleDebits?.length) {
            setVisibleDebits(debits);
        }
    }, [debits, visibleDebits]);

    const tabOwnerCopy = `${isGroupTabOwner ? "your" : "this"} ${tabTypeName}`;

    return (
        <ModalContainer
            isOpen={show}
            className={{
                base: "TabOrdersModal-modal slide-in",
                afterOpen: "TabOrdersModal-modal--after-open slide-in--after-open",
                beforeClose: "TabOrdersModal-modal--before-close slide-in--before-close",
            }}
            overlayClassName={classNames("TabOrdersModal-modal--overlay", "slide-in-modal--overlay")}
            onRequestClose={closeModal}
            shouldCloseOnEsc
            closeTimeoutMS={250}
            onAfterClose={afterClose}
        >
            <LocationThemeContainer>
                <div className="tab-orders-page">
                    <SimpleNavHeader closeToBack={true} customBack={"manage-tab-orders"} onBack={closeModal} />
                    <div className="tab-orders-page__title profile-page__title">
                        <Text preset="title-28" mode="bold">
                            {capitaliseFirstLetter(tabTypeName)} orders
                        </Text>
                        <Text preset="g-14" className="tab-orders-page__title--description">
                            {`View all orders placed using ${tabOwnerCopy} as payment.`}
                        </Text>
                    </div>
                    <main className="scroll-element">
                        {!!visibleDebits?.length && activeTab ? (
                            <div className="tab-orders__orders">
                                <GroupTabOrders
                                    debits={visibleDebits}
                                    orders={orders}
                                    getOrderData={handleShowingOrder}
                                    isTabActive={true}
                                    groupTabId={activeTab.id}
                                />
                            </div>
                        ) : (
                            <div className="tab-orders-page__empty">
                                <CallToActionHeader
                                    icon={<EmptyBag />}
                                    title={`You don’t have any orders on this ${tabTypeName}`}
                                    subtitle={`Items that have been ordered on ${tabOwnerCopy} will appear here.`}
                                    ctaText={isInParty ? "View menu" : undefined}
                                    ctaProps={{ mode: "outline", secondary: true, preset: "default" }}
                                    onClick={isInParty ? goToMenu : undefined}
                                />
                            </div>
                        )}
                    </main>
                    <footer>
                        {isGroupTabOwner && (
                            <RefreshButton
                                show={showRefreshButton}
                                text={`Tap to view ${newOrdersCount} new order${simplePlural(newOrdersCount)}`}
                                onClick={handleOnClickRefreshButton}
                            />
                        )}
                    </footer>
                </div>
            </LocationThemeContainer>
            <InviteToGroupTab />
        </ModalContainer>
    );
};
