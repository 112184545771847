import { orderApi } from "../../order/orderApi";
import { applyPromoCodeOperation } from "../operations";
import { AppDispatch, AppState } from "../..";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

const applyPromoCodeApi = async (code: string) => {
    const headers = getTableTokenHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/promotions/apply-code", {
        method: "POST",
        body: JSON.stringify({ code }),
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return response.status;
};

export function applyPromoCode(code: string) {
    return applyPromoCodeOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            await applyPromoCodeApi(code);
        } catch (ex) {
            console.log("failed to apply promo code:", ex);
            throw ex;
        }
    });
}
