import * as React from "react";
import { Text } from "src/sharedComponents";
import { TimingIcon } from "../../../sharedComponents/assets/icons";

import "../assets/WaitTimeBanner.scss";

interface Props {
    waitTime: number | undefined;
}

export const WaitTimeBanner = ({ waitTime }: Props) =>
    waitTime ? (
        <Text preset="g-14" mode="medium" className="wait-time-banner">
            <TimingIcon />
            This product has a {waitTime} minute wait
        </Text>
    ) : null;
