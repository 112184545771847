import {
    AvailablePrices,
    HasPrices,
    Indexed,
    LocationMenuData,
    MenuItem,
    MenuItemModifier,
    MenuItemVariant,
    ModifierOption,
} from "..";
import { parsePrice } from "../../../common/shared";

const getAvailablePrices = (
    hasPrices: HasPrices,
    priceList?: number,
    membershipPriceList?: number
): AvailablePrices | undefined => {
    const priceLevel = priceList !== undefined && hasPrices.priceLevels ? hasPrices.priceLevels[priceList] : undefined;

    // If neither a priceLevel or a price are defined
    // then assume the price is meant to be undefined
    if (priceLevel === undefined && hasPrices.price === undefined) return undefined;

    const basePrice = priceLevel ?? parsePrice(hasPrices.price);

    const memberPrice =
        membershipPriceList !== undefined &&
        hasPrices.priceLevels &&
        hasPrices.priceLevels[membershipPriceList] < basePrice
            ? hasPrices.priceLevels[membershipPriceList]
            : undefined;

    return {
        basePrice,
        memberPrice,
    };
};

export const getMembershipPriceList = (
    menuData: LocationMenuData,
    serviceId?: string,
    membershipLevelId?: string | null
) => {
    const service = serviceId ? menuData.services.find((s) => s.id === serviceId) : undefined;
    return membershipLevelId && service && service.membershipPriceLists
        ? service.membershipPriceLists[membershipLevelId]
        : undefined;
};

export const updatePrices = (
    menuData: LocationMenuData,
    serviceId?: string,
    membershipLevelId?: string | null
): LocationMenuData => {
    const service = serviceId ? menuData.services.find((s) => s.id === serviceId) : undefined;
    const priceList = service?.priceList;
    const membershipPriceList = getMembershipPriceList(menuData, serviceId, membershipLevelId);

    return {
        ...menuData,
        items: updateItemAvailablePrices(menuData.items, priceList, membershipPriceList),
        modifiers: !menuData.modifiers
            ? menuData.modifiers
            : updateModifiersAvailablePrices(menuData.modifiers, priceList, membershipPriceList),
    };
};

const updateItemAvailablePrices = (
    items: Indexed<MenuItem>,
    priceList?: number,
    membershipPriceList?: number
): Indexed<MenuItem> => {
    return Object.keys(items).reduce((map, id) => {
        const item = items[id];
        const variants = updateVariantAvailablePrices(item.variants, priceList, membershipPriceList);
        map[id] = {
            ...item,
            availablePrices: getAvailablePrices(item, priceList, membershipPriceList),
            variants,
            modifiers: updateModifierAvailablePrices(item.modifiers, priceList, membershipPriceList),
            minVariantPrice: getMinVariantAvailablePrices(variants),
        };
        return map;
    }, {} as Indexed<MenuItem>);
};

const updateVariantAvailablePrices = (
    variants?: MenuItemVariant[],
    priceList?: number,
    membershipPriceList?: number
): MenuItemVariant[] | undefined =>
    variants?.map(
        (variant) =>
            ({
                ...variant,
                availablePrices: getAvailablePrices(variant, priceList, membershipPriceList),
            } as MenuItemVariant)
    );

const getMinVariantAvailablePrices = (variants?: MenuItemVariant[]) => {
    if (!variants?.length) return undefined;

    return variants.reduce((min: AvailablePrices | undefined, variant: MenuItemVariant) => {
        if (!variant.availablePrices) return min;
        if (!min) return variant.availablePrices;
        const currentMinPrice = Math.min(
            variant.availablePrices.basePrice,
            variant.availablePrices.memberPrice ?? Number.MAX_VALUE
        );
        const prevMinPrice = Math.min(min.basePrice, min.memberPrice ?? Number.MAX_VALUE);
        return currentMinPrice < prevMinPrice ? variant.availablePrices : min;
    }, undefined);
};

const updateModifierAvailablePrices = (
    modifiers?: MenuItemModifier[],
    priceList?: number,
    membershipPriceList?: number
): MenuItemModifier[] | undefined =>
    modifiers?.map(
        (modifier) =>
            ({
                ...modifier,
                options: updateModifierOptionAvailablePrices(modifier, priceList, membershipPriceList),
            } as MenuItemModifier)
    );

const updateModifiersAvailablePrices = (
    modifiers: Indexed<MenuItemModifier>,
    priceList?: number,
    membershipPriceList?: number
): Indexed<MenuItemModifier> => {
    return Object.keys(modifiers).reduce((map, id) => {
        const modifier = modifiers[id];
        map[id] = {
            ...modifier,
            options: updateModifierOptionAvailablePrices(modifier, priceList, membershipPriceList),
        };
        return map;
    }, {} as Indexed<MenuItemModifier>);
};

function updateModifierOptionAvailablePrices(
    modifier: MenuItemModifier,
    priceList: number | undefined,
    membershipPriceList: number | undefined
): ModifierOption[] {
    return modifier.options.map(
        (option) =>
            ({
                ...option,
                availablePrices: getAvailablePrices(option, priceList, membershipPriceList),
            } as ModifierOption)
    );
}
