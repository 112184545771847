import { getEasyReorderItemsOperation, easyReorderActions } from "../reducers";
import { fetchEasyReorderItems } from "../api/easyReorderApi";
import { auth } from "../../../common/auth";
import { AppDispatch } from "src/features";

export async function getEasyReorderItemsInternal(dispatch: AppDispatch, partyId: string) {
    const items = auth.getIsAnonymous() ? [] : await fetchEasyReorderItems(partyId);

    dispatch(easyReorderActions.getEasyReorderItem(items));
}

export const getEasyReorderItems = (partyId: string) =>
    getEasyReorderItemsOperation.getThunk(async (dispatch) => getEasyReorderItemsInternal(dispatch, partyId));

export const getEasyReorderItemsAwaitable = (dispatch: AppDispatch, partyId: string) =>
    getEasyReorderItemsOperation.invoke(() => getEasyReorderItemsInternal(dispatch, partyId), dispatch);
