import { resetOpenTableDateToSubmitOperation } from "../operations";
import { orderApi } from "../../order/orderApi";
import { AppDispatch, AppState } from "../..";
import { getOrderDateToSubmitString } from "../../order/selectors";

export const resetOpenTableDateToSubmit = (delaySeconds: number) => {
    return resetOpenTableDateToSubmitOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const dateToSubmit = getOrderDateToSubmitString(getState());
        await orderApi.invokeWithPartyUpdateAsNeeded(
            dispatch,
            getState,
            "resetOpenTableDateToSubmit",
            dateToSubmit,
            delaySeconds
        );
    });
};
