let rootElement = document.getElementById("root");

function getScrollElement(elementQuery?: string) {
    if (elementQuery) {
        return document.querySelector(elementQuery);
    }
    if (!rootElement) {
        rootElement = document.getElementById("root");
    }
    return rootElement;
}

export const scrolling = {
    scrollElementIntoView: (element: HTMLElement) => {
        element.scrollIntoView({ block: "nearest" });
        window.setTimeout(() => element.scrollIntoView({ block: "nearest" }), 100);
    },
    scrollTop: (elementQuery?: string) => getScrollElement(elementQuery)?.scrollTop || 0,
    scrollTo: (options?: ScrollToOptions, elementQuery?: string) => getScrollElement(elementQuery)?.scroll(options),
};
