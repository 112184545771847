import React from "react";
import classNames from "classnames";
import "./Text.scss";

export type TextModes = "bold" | "extra-bold" | "block" | "medium" | "emphasis" | "caps";
export type TextPreset =
    | "g-10"
    | "g-12"
    | "g-14"
    | "g-16"
    | "g-18"
    | "title-20"
    | "title-24"
    | "title-28"
    | "title-32"
    | "title-36"
    | "title-48";

interface baseProp {
    mode?: TextModes | TextModes[];
    preset?: TextPreset;
    className?: string;
    style?: React.CSSProperties;
    id?: string;
}

interface optionalChild extends baseProp {
    children: React.ReactNode;
}

interface optionalValue extends baseProp {
    value: string;
}

export type TextProps = optionalChild | optionalValue;

export const Text = (props: TextProps) => {
    const child = !!(props as optionalValue).value ? (props as optionalValue).value : (props as optionalChild).children;
    const { mode, preset = "content", className, id, style } = props;
    const modClass = !mode
        ? undefined
        : Array.isArray(mode)
        ? mode.map((m) => `mode--${m}`).join(" ")
        : `mode--${mode}`;
    return (
        <span id={id} style={style} className={classNames("text", modClass, preset, className)}>
            {child}
        </span>
    );
};
