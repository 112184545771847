import { cordovaWindow } from "../../cordova";
import { googlePayEnvironment, GooglePayPaymentMethod } from "../../../../payment";
import { StripeGooglePayPaymentMethod } from "../../../../../features/paymentGateways/stripe/types";
import { GetDevicePaymentMethodAvailableFunc } from "../../../interface";

export const getStripeGooglePayAvailable: GetDevicePaymentMethodAvailableFunc<GooglePayPaymentMethod> = (
    googlePayPaymentMethod
) =>
    new Promise<boolean>((resolve, reject) => {
        const { apiKey } = googlePayPaymentMethod as StripeGooglePayPaymentMethod;

        cordovaWindow.StripeDevicePayments.getGooglePayAvailable(
            googlePayEnvironment,
            apiKey,
            ({ available }) => resolve(available),
            reject
        );
    });
