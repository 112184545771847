import "../assets/LoadingOffer.scss";
import React from "react";
import { LoadingText } from "src/sharedComponents/controls/loadingText";

export const LoadingOffer = () => (
    <div className="loading-offer">
        <div className="loading-offer__stamp-card">
            <LoadingText />
            <div className="loading-offer__offer-component">
                <LoadingText />
            </div>
        </div>

        <div className="loading-offer__your-offers">
            <LoadingText />
            <LoadingText />
            <div className="loading-offer__offer-component">
                <LoadingText />
            </div>
        </div>
    </div>
);
