export interface NotificationsState {
    showNoNetworkConnection: boolean;
}

export enum TypeKeys {
    SHOW_NO_NETWORK_CONNECTION = "NETWORK/SHOW_NO_NETWORK_CONNECTION",
}

export const actionCreators = {
    showNoNetworkConnection: (show: boolean) => ({ type: TypeKeys.SHOW_NO_NETWORK_CONNECTION, show }),
};

export type NotificationsAction = { type: TypeKeys.SHOW_NO_NETWORK_CONNECTION; show: boolean };

const initialState: NotificationsState = {
    showNoNetworkConnection: false,
};

export function reducer(state: NotificationsState = initialState, action: NotificationsAction): NotificationsState {
    if (action.type === TypeKeys.SHOW_NO_NETWORK_CONNECTION) {
        return {
            ...state,
            showNoNetworkConnection: action.show,
        };
    }
    return state;
}
