import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import { groupTabsMiddleware } from "./middleware/groupTabsMiddleware";

export * from "./types";
export * from "./reducers";
export * from "./components/GroupTabsView";

export default function (config: FeatureConfiguration) {
    config.reducers.groupTabs = reducer;
    config.middleware.push(groupTabsMiddleware());
}
