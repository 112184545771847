import { AppDispatch, AppState } from "src/features";
import { increaseGroupTabLimitOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { resetModalMessage } from "src/features/modalMessage/actions/reset";
import { getPaymentInfo } from "src/features/payment/actions/handlePayment";
import { ActiveGroupTabData, GroupTabErrorType, IncreaseGroupTabInfo } from "..";
import { getWizardGroupTabsLimit } from "../selectors/groupTabWizardSelectors";
import { getActiveGroupTabData, getTabTypeName } from "../selectors/activeGroupTab";
import { actionCreators as wizardActionCreators } from "../reducers/groupTabWizard";
import { fetchProfile } from "../../accountmenu";
import { openOrUpdateGroupTab } from "./createGroupTabAction";
import { PaymentFunc } from "../../payment";
import { HandledError } from "../../../common/error";

export const increaseGroupTabAction: PaymentFunc = (result, completePayment, validate) => {
    return increaseGroupTabLimitOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(resetModalMessage());
        const state = getState();
        const tabTypeName = getTabTypeName(state);

        try {
            const tabLimit = getWizardGroupTabsLimit(state)!;
            const activeGroupTab = getActiveGroupTabData(state)!;
            const newGroupTabLimit = tabLimit + activeGroupTab.limit;

            result = await validate(result);

            const getIncreaseGroupTabInfo = async () => {
                const paymentInfo = await getPaymentInfo(dispatch, getState, result);

                const increaseGroupTabInfo: IncreaseGroupTabInfo = {
                    ...paymentInfo,
                    limit: newGroupTabLimit,
                };

                return increaseGroupTabInfo;
            };

            let increaseGroupTabInfo = await getIncreaseGroupTabInfo();
            let groupTab: ActiveGroupTabData;

            try {
                groupTab = await openOrUpdateGroupTab(dispatch, getState, increaseGroupTabInfo);
            } catch (err) {
                if (
                    !(err instanceof ProblemDetailsError) ||
                    err.problemDetails.type !== GroupTabErrorType.MUST_USE_EXTENDED_VALIDATION
                ) {
                    throw err;
                }

                result = await validate(result, true);
                increaseGroupTabInfo = await getIncreaseGroupTabInfo();
                groupTab = await openOrUpdateGroupTab(dispatch, getState, increaseGroupTabInfo);
            }

            dispatch(fetchProfile());
            dispatch(activeTabActionCreators.setGroupTabData(groupTab));
            dispatch(paymentActionCreators.clearPaymentMethods());
            dispatch(wizardActionCreators.completeGroupTabWizard());
            dispatch(wizardActionCreators.trackGroupTabLimitIncreased(activeGroupTab.id, newGroupTabLimit, tabLimit));
        } catch (err) {
            if (err instanceof ProblemDetailsError) {
                if (err.problemDetails.type !== GroupTabErrorType.PAYMENT_FAILED) {
                    dispatch(showModalMessage(modalMessages.knownFailureGroupTab(err.problemDetails, tabTypeName)));
                    throw new HandledError(err.problemDetails.detail);
                }
            } else {
                console.log("failed to increase group tab:", err);
            }
            throw err;
        } finally {
            await completePayment?.();
        }
    });
};
