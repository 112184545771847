import { orderApiMiddleware, orderApiProxy as partyProxy, OrderApiProxy } from "./features/order";
import { orderApiProxy as partyOnboardingProxy } from "./features/partyOnboarding";
import init from "./features";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { mealHistoryNotificationsMiddleware } from "./features/orderHistoryData/middleware/mealHistoryNotificationsMiddleware";
import { browserHistory } from "./browserHistory";
import { menuSearchMiddleware } from "./features/menuSearch/middleware/menuSearchMiddleware";
import { menudataMiddleware } from "./features/menudata/middleware/menudataMiddleware";

const proxies: OrderApiProxy[] = [partyProxy, partyOnboardingProxy];

export const featureConfig = init(browserHistory);

featureConfig.middleware.unshift(orderApiMiddleware(proxies));

const enhancer = composeWithDevTools(
    applyMiddleware(
        menuSearchMiddleware(),
        mealHistoryNotificationsMiddleware(),
        menudataMiddleware(),
        thunkMiddleware,
        routerMiddleware(browserHistory),
        ...featureConfig.middleware
    )
    // other store enhancers if any
);

export const store = createStore(combineReducers(featureConfig.reducers), enhancer);

export function reconfigureReducers() {
    const updatedConfig = require("./features")();

    store.replaceReducer(combineReducers(updatedConfig.reducers));
}
