import { PaymentGatewayBehaviors } from "../../interface";
import {
    applePayBehavior as braintreeApplePayBehavior,
    googlePayBehavior as braintreeGooglePayBehavior,
} from "./braintree/behaviors";
import {
    applePayBehavior as stripeApplePayBehavior,
    googlePayBehavior as stripeGooglePayBehavior,
} from "./stripe/behaviors";
import { applePayBehavior as tyroApplePayBehavior, googlePayBehavior as tyroGooglePayBehavior } from "./tyro/behaviors";
import { PaymentMethod } from "src/common/payment";
import { getPosApplePayBehavior, getPosCardVerificationSupported, getPosGooglePayBehavior } from "./pos/behaviors";
import { PosPaymentMethod } from "src/features/paymentGateways/pos/types";

const paymentGatewayBehaviors: PaymentGatewayBehaviors = {
    Braintree: {
        getApplePayBehavior: () => braintreeApplePayBehavior,
        getGooglePayBehavior: () => braintreeGooglePayBehavior,
        getCardVerificationSupported: () => true,
    },
    Tyro: {
        getApplePayBehavior: () => tyroApplePayBehavior,
        getGooglePayBehavior: () => tyroGooglePayBehavior,
        getCardVerificationSupported: () => true,
    },
    Stripe: {
        getApplePayBehavior: () => stripeApplePayBehavior,
        getGooglePayBehavior: () => stripeGooglePayBehavior,
    },
    GroupTab: {},
    Spreedly: {},
    Pos: {
        getApplePayBehavior: (paymentMethod) =>
            getPosApplePayBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getGooglePayBehavior: (paymentMethod) =>
            getPosGooglePayBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getCardVerificationSupported: (paymentMethod) =>
            getPosCardVerificationSupported((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
    },
};

export const getApplePayBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getApplePayBehavior?.(paymentMethod);

export const getGooglePayBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getGooglePayBehavior?.(paymentMethod);

export const getCardVerificationSupported = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getCardVerificationSupported?.(paymentMethod);
