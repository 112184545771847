import * as React from "react";
import Select, { Option } from "react-select";
import { Tag } from "../../menudata";

import "react-select/dist/react-select.css";
import "../assets/DropDownList.scss";

type TagMap = { [tagGroupId: string]: string[] };

export interface DropDownListProps {
    label?: string;
    onChange(tagGroupId: string, selectedOption: Tag): void;
    options: Tag[];
    tagGroupId: string;
    selectedValues: TagMap;
}

export interface DropDownListStates {
    selectedOption: Tag;
}

export class DropDownList<TOption, TValue extends string | number> extends React.Component<
    DropDownListProps,
    DropDownListStates
> {
    render() {
        const { options } = this.props;
        const { selectedValues, tagGroupId } = this.props;
        const selectedOptionId = selectedValues[tagGroupId] && selectedValues[tagGroupId][0];

        const displayOptions = [{ id: "", displayName: "Show all" }].concat(options);
        const selectedOption = displayOptions.find((x) => x.id === (selectedOptionId || ""));

        const labelKey = "displayName";
        const valueKey = "id";

        return (
            <Select
                options={displayOptions}
                labelKey={labelKey}
                valueKey={valueKey}
                optionRenderer={this.renderOption}
                value={selectedOption}
                onChange={this.handleChange}
                className="filtermodal--dropdownlist--select"
                searchable={false}
                clearable={false}
            />
        );
    }

    handleChange = (selectedOption: any | null) => {
        if (!selectedOption) {
            return;
        }

        const { tagGroupId } = this.props;
        this.props.onChange(tagGroupId, selectedOption);
    };

    renderOption = (option: Option<TValue>) => {
        const key = option.labelKey;
        return (
            <div className="dropdownlist__option" key={key}>
                {option.displayName}
            </div>
        );
    };
}
