import "../assets/MenuItemPage.scss";
import * as React from "react";
import { SimpleNavHeader } from "src/common/navigation";
import { Indexed, MenuItem, MenuItemModifier, MenuItemVariant, Tag } from "../../menudata";
import { MenuItemDetails } from "./MenuItemDetails";
import classNames from "classnames";
import { EasyReorderItem, PendingItem } from "../../order";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { StatusBar } from "src/common/statusBar";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { scrolling } from "src/common/experience/web";
import { PriceResolver } from "../../membership/selectors/getPriceResolver";
import { waitForKeyboardClose } from "src/common/keyboard";
import { WaitTimeBanner } from "../../menu/components/WaitTimeBanner";
import { AvailabilityTrackingAction } from "../../menu/reducers/availability";
import { getItemAvailability } from "../../menu/util/helpers";
import { getSelectedModifierChoice } from "../../menu/helpers";
import { NestedModifiersPage } from "./NestedModifiersPage";
import { PendingModifierState } from "src/features/order/reducers/pendingItem";
import { MenuItemFooter } from "./MenuItemFooter";
import { MenuItemVideo } from "./MenuItemVideo";
import { MenuItemImage } from "./MenuItemImage";
import { AddToOrderFunc } from "../types";

export interface Props {
    courseId: string;
    menuItemId: string;
    menuItem: MenuItem;
    globalModifiers?: Indexed<MenuItemModifier>;
    dietaryTags: Tag[];
    dietaryInformationUrl?: string;
    backText: string | null;
    relatedItems: string[];
    setPendingItem: (pendingItem: PendingItem) => void;
    resetPendingItem: () => void;
    removeOrderItem?: (orderItemId: string, menuItemId: string) => void;
    triedToSubmit: number;
    addToOrder: AddToOrderFunc;
    pendingItem: PendingItem;
    isEditingNestedModifier: boolean;
    goBackButton: () => void;
    allowNotesToKitchen: boolean;
    hasParty: boolean;
    easyReorderItems: EasyReorderItem[];
    hasPreselectOptions: boolean;
    currentMemberInParty: boolean;
    connected: boolean;
    showModalMessage: (modalMessage: ModalMessage) => void;
    priceResolver: PriceResolver;
    trackAvailability: (trackingData: AvailabilityTrackingAction) => void;
    editNestedModifier?: (pendingModifier: PendingModifierState) => void;
    resetNestedModifiers: () => void;
    trackDisabledButtonClicked: () => void;
    trackAllergenNoticeClick: () => void;
    activeServiceMenuItemIds: Indexed<boolean>;
}

export interface State {
    pendingItem: PendingItem | null;
}

export class MenuItemPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            pendingItem: props.pendingItem,
        };
    }

    static getDerivedStateFromProps(props: Props): Partial<State> | null {
        const { pendingItem } = props;
        return pendingItem ? { pendingItem } : null;
    }

    componentDidMount() {
        scrolling.scrollTo({ top: 0 });

        const { menuItemId, trackAvailability, menuItem } = this.props;
        const availability = getItemAvailability(menuItem);

        if (availability) {
            trackAvailability({
                [menuItemId]: {
                    item_type: "item",
                    availability,
                    name: menuItem.displayName,
                },
            });
        }
    }

    addToOrder = () => {
        const { addToOrder, setPendingItem, pendingItem, trackDisabledButtonClicked } = this.props;
        const {
            menuItem: { variants, videos },
        } = this.props;

        if (!pendingItem || window.navigator.onLine === false) return;

        if (pendingItem.canSubmit) {
            const { courseId, categoryId, itemId, quantity, notes, variant, orderItemId, isFeatured } = pendingItem;
            let { modifiers } = pendingItem;

            const selectedVariant: MenuItemVariant | undefined = getSelectedModifierChoice(variants, variant);

            if (variant !== null && selectedVariant && selectedVariant.modifiers && modifiers) {
                modifiers = modifiers.filter((m) => selectedVariant.modifiers!.indexOf(m.modifier) >= 0);
                if (!modifiers.length) modifiers = null;
            }

            const isVideo = !!videos;

            waitForKeyboardClose().then(() => {
                addToOrder(
                    courseId || "",
                    categoryId,
                    itemId,
                    notes,
                    orderItemId,
                    variant,
                    quantity,
                    modifiers || undefined,
                    isFeatured,
                    "item_page",
                    isVideo
                );
            });
        } else {
            trackDisabledButtonClicked();
            setPendingItem({
                ...pendingItem,
                triedToSubmit: pendingItem.triedToSubmit + 1,
            });
        }
    };

    removeFromOrder = () => {
        const { removeOrderItem, pendingItem } = this.props;
        if (!pendingItem || !pendingItem.orderItemId || !removeOrderItem || window.navigator.onLine === false) {
            return;
        }

        removeOrderItem(pendingItem.orderItemId, pendingItem.itemId);
    };

    render() {
        const {
            courseId,
            menuItemId,
            menuItem,
            dietaryTags,
            dietaryInformationUrl,
            backText,
            relatedItems,
            setPendingItem,
            resetPendingItem,
            triedToSubmit,
            addToOrder,
            goBackButton,
            allowNotesToKitchen,
            hasParty,
            easyReorderItems,
            hasPreselectOptions,
            showModalMessage,
            priceResolver,
            connected,
            editNestedModifier,
            resetNestedModifiers,
            globalModifiers,
            trackAllergenNoticeClick,
            activeServiceMenuItemIds,
            isEditingNestedModifier,
        } = this.props;

        const pendingItemProps = this.props.pendingItem;
        const { pendingItem } = this.state;
        const easyReorderItem = easyReorderItems.find((item) => item.itemId === menuItemId);
        const pageClassName = classNames("menu-page", `menu-page-${menuItem.type}`, "menu-item");

        return (
            <LocationThemeContainer>
                <StatusBar backgroundColor="#000" />
                <div className="menu-page__shield" />
                <div className={pageClassName}>
                    {menuItem.videos ? (
                        <MenuItemVideo
                            imageName="full"
                            images={menuItem.images}
                            videos={menuItem.videos}
                            placeholderName="thumb"
                            itemId={menuItemId}
                        />
                    ) : (
                        <div className="menuitemcard-image">
                            <MenuItemImage
                                className="menuitemcard-image__header"
                                images={menuItem.images}
                                name="full"
                                placeholderName="thumb"
                            />
                        </div>
                    )}

                    <section>
                        <div
                            className={classNames(
                                "menu-page__menu-item-container",
                                (!connected || !!menuItem.waitTime) && "menu-page__menu-item-container--with-banner"
                            )}
                        >
                            <MenuItemDetails
                                courseId={courseId}
                                menuItemId={menuItemId}
                                menuItem={menuItem}
                                dietaryTags={dietaryTags}
                                dietaryInformationUrl={dietaryInformationUrl}
                                relatedItems={relatedItems}
                                modal={this.props.pendingItem ? this.props.pendingItem.modal : undefined}
                                setPendingItem={setPendingItem}
                                triedToSubmit={triedToSubmit}
                                pendingItem={pendingItemProps}
                                isEditingNestedModifier={isEditingNestedModifier}
                                addToOrder={addToOrder}
                                allowNotesToKitchen={allowNotesToKitchen}
                                hasParty={hasParty}
                                easyReorderItem={easyReorderItem}
                                hasPreselectOptions={hasPreselectOptions}
                                showModalMessage={showModalMessage}
                                priceResolver={priceResolver}
                                editNestedModifier={editNestedModifier}
                                resetNestedModifiers={resetNestedModifiers}
                                globalModifiers={globalModifiers}
                                removeFromOrder={this.removeFromOrder}
                                trackAllergenNoticeClick={trackAllergenNoticeClick}
                                activeServiceMenuItemIds={activeServiceMenuItemIds}
                            />
                        </div>
                    </section>
                    <header className="menu-page__header">
                        <SimpleNavHeader
                            title={backText}
                            reset={resetPendingItem}
                            closeToBack={true}
                            circleClose
                            onBack={goBackButton}
                            customBack={`menu/${pendingItem?.menuId}/${
                                pendingItem?.categoryId
                            }/${menuItemId}?isFeatured=${pendingItem?.isFeatured ? "true" : "false"}`}
                        />
                    </header>
                    <div className="menu-page__status-shadow" />
                </div>
                <footer className="menu-item__footer">
                    {connected && <WaitTimeBanner waitTime={menuItem.waitTime} />}
                    {this.renderActionButtons()}
                </footer>
                {menuItemId && <NestedModifiersPage />}
            </LocationThemeContainer>
        );
    }

    renderActionButtons = () => {
        const { hasParty, menuItem } = this.props;

        if (!hasParty) return null;

        return (
            <MenuItemFooter addToOrder={this.addToOrder} menuItem={menuItem} removeFromOrder={this.removeFromOrder} />
        );
    };
}
