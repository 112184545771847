import "../assets/EnergyContent.scss";

import React, { useMemo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Text } from "src/sharedComponents";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";

interface Props {
    value?: number;
    className?: string;
}

export const EnergyContent = ({ value, className }: Props) => {
    const menuDataLocale = useSelector(getMenuDataLocale);
    const formattedValue = useMemo(
        () => (value === undefined ? undefined : menuDataLocale?.formatNumber(value)),
        [value, menuDataLocale]
    );

    if (!menuDataLocale || value === undefined) return null;

    return (
        <Text preset="g-14" mode="block" className={classNames("energy-content", className)}>
            {formattedValue} kcal
        </Text>
    );
};
