import { connect } from "react-redux";
import { AppDispatch, AppState } from "src/features";
import { joinTable } from "../actions/joinTable";
import { JoinTablePage, Props } from "../components/JoinTablePage";
import { changingLocationOperation } from "../operations";
import { getJoinTableError, getJoinTableStep, getLoaderImages } from "../selectors";
import { fetchOngoingPartiesMealHistory } from "../../orderHistoryData/actions/fetchMealHistory";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { getReadyOrdersHistory } from "../../orderHistoryData/selectors";
import { fetchProfile } from "../../accountmenu";
import { config } from "src/common/config";
import { actions as menudataActions } from "src/features/menudata";
import { getLatestTermsUpdate } from "../../marketing/actions/termsUpdate";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { showModalMessage } from "../../modalMessage/actions/show";
import { findQrCode } from "../actions/findQrCode";
import { getParty, MemberActivity } from "../../order";
import { getJoinGroupTabCode } from "src/features/groupTabs/selectors/joinGroupTab";
import { actionCreators as joinTabActionCreators } from "src/features/groupTabs/reducers/joinGroupTab";
import { getGroupTabAction } from "src/features/groupTabs/actions/getGroupTabAction";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { getTabTypeName } from "../../groupTabs/selectors/activeGroupTab";
import { getIsOpenTableOrderingDisabled, getOpenTableMemberActivity } from "../../openTable/selectors";
import { resetJoinTableOperations } from "../actions/resetOperations";
import { actionCreators as openTableActionCreators } from "src/features/openTable/reducers";
import { getHasFetchedProfile } from "src/features/accountmenu/selectors";
import { redirectToParty } from "src/features/partyOnboarding/actions/redirectToParty";

export function mapStateToProps(state: AppState): DataProps<Props> {
    const {
        nativeState: { connected },
        qr: { cameraAccessDenied, cameraReady, scanError },
    } = state;

    const step = getJoinTableStep(state);
    const partyError = getJoinTableError(state);
    const locationLoading = changingLocationOperation.getStatus(state) === "processing";
    const readyOrderHistory = getReadyOrdersHistory(state);
    const party = getParty(state);
    const joinCode = getJoinGroupTabCode(state);
    const menuData = getVisibleMenuData(state);
    const tabTypeName = getTabTypeName(state);
    const loaderImages = getLoaderImages(state);
    const memberActivity = getOpenTableMemberActivity(state);
    const isOpenTableOrderingDisabled = getIsOpenTableOrderingDisabled(state);
    const hasFetchedProfile = getHasFetchedProfile(state);

    return {
        step,
        locationLoading,
        partyError,
        isReady: !!readyOrderHistory.length,
        networkAvailable: connected,
        backgroundImage: menuData?.backgroundImage?.full,
        logoUrl: menuData ? menuData.logoUrl : undefined,
        cameraAccessDenied,
        cameraReady,
        party,
        scanError,
        showJoinTabMessage: !party && !!joinCode && step === "TouchToStart",
        tabTypeName,
        loaderImages,
        memberActivity: memberActivity || MemberActivity.None,
        isOpenTableOrderingDisabled,
        hasFetchedProfile,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Props> {
    const fakeJoinTable =
        config.REACT_APP_ALLOW_FAKE_BEACON === "1"
            ? (locationSlug: string | null, table: string | null) =>
                  dispatch(joinTable([{ type: "test", token: `${locationSlug}|${table}` }]))
            : undefined;

    return {
        resetOperations: () => dispatch(resetJoinTableOperations()),
        fakeJoinTable,
        startOrdering: () => {
            dispatch(redirectToParty());
            dispatch(resetJoinTableOperations());
        },
        updateMealHistory: () => dispatch(fetchOngoingPartiesMealHistory(null)),
        fetchProfile: () => dispatch(fetchProfile()),
        clearActiveMenuData: () => dispatch(menudataActions.reset),
        showModalMessage: (modalMessage: ModalMessage) => dispatch(showModalMessage(modalMessage)),
        getLatestTermsUpdate: () => dispatch(getLatestTermsUpdate()),
        findQrCode: (cancelPromise: Promise<void>) => dispatch(findQrCode(cancelPromise)),
        resetJoinTabCode: () => !fakeJoinTable && dispatch(joinTabActionCreators.setJoinGroupTabCode(undefined)),
        updateGroupTabs: () => dispatch(getGroupTabAction()),
        goToActivityPage: (memberActivity: MemberActivity) => {
            dispatch(openTableActionCreators.setActivity(memberActivity, true));
            dispatch(resetJoinTableOperations());
        },
    };
}

export const JoinTablePageContainer = connect(mapStateToProps, mapDispatchToProps)(JoinTablePage);
