import React from "react";
import { href, reCaptcha } from "src/common/experience";
import { regionHelper } from "src/features/region";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { Text } from "src/sharedComponents";

export const RecaptchaLegalText = () => {

    if (regionHelper.region.enableRecaptcha !== true || !reCaptcha.enabled) {
        return null;
    }

    return (
        <Text preset="g-12" className="legal-text__terms">
            This site is protected by reCAPTCHA and the Google{"\u00A0"}
            <TappableAnchor
                className="my-offers-page__privacy-policy__link"
                {...href.openInNewWindowAttributes("https://policies.google.com/privacy")}
            >
                Privacy Policy
            </TappableAnchor> and{"\u00A0"}
            <TappableAnchor
                className="my-offers-page__privacy-policy__link"
                {...href.openInNewWindowAttributes("https://policies.google.com/terms")}
            >
                Terms of Service
            </TappableAnchor> apply.
        </Text>
    )

};