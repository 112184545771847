import { useDispatch } from "react-redux";
import React, { useCallback } from "react";
import { trackPaymentMethodSelected } from "../../payment/actions/trackPaymentMethodSelected";
import { SelectablePaymentMethodIcon, SelectedPaymentMethodIcon } from "../../../sharedComponents/assets/icons";
import { PaymentMethod, Props as PaymentMethodProps } from "./PaymentMethod";
import { SelectPaymentMethodActions } from "../types";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";

interface Props extends PaymentMethodProps {
    actions?: SelectPaymentMethodActions;
}

export const SelectPaymentMethodAction = ({ paymentMethod, onClick, rightIcon, actions, ...props }: Props) => {
    const dispatch = useDispatch();
    const [tempSelectedPaymentMethod, setTempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    const onSelect = useCallback(() => {
        setTempSelectedPaymentMethod(paymentMethod);
        dispatch(trackPaymentMethodSelected(paymentMethod, false));
        actions?.setGetPaymentMethod(null);
        onClick?.();
    }, [dispatch, setTempSelectedPaymentMethod, paymentMethod, onClick, actions]);

    return (
        <PaymentMethod
            paymentMethod={paymentMethod}
            onClick={onSelect}
            rightIcon={
                rightIcon !== undefined ? (
                    rightIcon
                ) : tempSelectedPaymentMethod === paymentMethod ? (
                    <SelectedPaymentMethodIcon />
                ) : (
                    <SelectablePaymentMethodIcon />
                )
            }
            {...props}
        />
    );
};
