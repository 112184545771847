import "./TabSettingCard.scss";

import React from "react";
import { Text, Chip } from "src/sharedComponents/";
import { CircleTick } from "../../../sharedComponents/assets/icons";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";

interface Props {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    ctaText?: string;
    onCtaClick?: () => void;
    currentSettingText?: string;
}

export const TabSettingCard = ({ icon, title, subtitle, ctaText, onCtaClick, currentSettingText }: Props) => (
    <div className="tab-setting-card">
        <div className="tab-setting-card__body">
            {icon}
            <div className="tab-setting-card__details">
                <Text preset="g-16" mode="bold" value={title} />
                <Text preset="g-14" value={subtitle} />
                {currentSettingText && (
                    <Chip
                        text={currentSettingText}
                        size="xsmall"
                        active
                        icon={<CircleTick />}
                        className="tab-setting-card__details__chip"
                        mode="dark"
                    />
                )}
            </div>
            {ctaText && (
                <div className="tab-setting-card__button">
                    <NetworkConnectedButton
                        onClick={onCtaClick}
                        value={ctaText}
                        mode="outline"
                        secondary
                        preset="half-size"
                    />
                </div>
            )}
        </div>
    </div>
);
