import { OrderItemModifier, OrderItemNestedModifier, OrderItemOptionNestedModifier } from "../../types";
import {
    Indexed,
    MenuItem,
    MenuItemModifier,
    ModifierOption,
    VisibleModifierOption,
    VisibleNestedModifierOption,
} from "../../../menudata";
import { flatten } from "./flatten";
import { getSelectedModifierChoice } from "../../../menu/helpers";
import { RoundModifierOption } from "src/features/orderAgain/types";
import { nonNullable } from "src/common/types/nonNullable";

export function getOrderItemVariantName(variant: number | null, menuItem: MenuItem | null) {
    if (variant === null || !menuItem?.variants) {
        return;
    }
    const selectedVariant = getSelectedModifierChoice(menuItem.variants, variant);

    if (!selectedVariant) return;

    return selectedVariant.displayName;
}

export function getOrderItemVariantIndexFromName(variantName: string | null, menuItem: MenuItem | null) {
    if (variantName === null || !menuItem?.variants?.length) {
        return null;
    }
    const variant = menuItem.variants.find(
        (variant) => variant.displayName === variantName && variant.available !== false
    );
    return variant?.originalIndex ?? null;
}

export function getOrderItemModifiers(modifiers: OrderItemModifier[] | null, menuItem: MenuItem | null) {
    if (modifiers === null || !menuItem?.modifiers) {
        return;
    }
    return flatten(
        modifiers.map((m) =>
            m.options.map(
                (o) =>
                    ({
                        ...getModifierOption(menuItem, m.modifier, o),
                        nestedOptions: m.optionNestedModifiers
                            ? mapItemNestedOptionToVisibleNestedOptionRecursive(
                                  m.optionNestedModifiers.find((nested) => nested.optionIndex === o)
                              )
                            : undefined,
                    } as VisibleModifierOption)
            )
        )
    );
}

function mapItemNestedOptionToVisibleNestedOptionRecursive(itemModifierOption?: OrderItemOptionNestedModifier) {
    if (!itemModifierOption) return undefined;
    return flatten(
        itemModifierOption.modifiers?.map((m) =>
            m.options
                .map((o): VisibleNestedModifierOption | null => {
                    const selectedOption = m.selectedOptions?.find((so) => so.optionIndex === o);
                    if (!selectedOption) return null;
                    return {
                        displayName: selectedOption.displayName,
                        price: selectedOption.price ?? selectedOption.nonMemberPrice,
                        nestedOptions: m.optionNestedModifiers
                            ? mapItemNestedOptionToVisibleNestedOptionRecursive(
                                  m.optionNestedModifiers.find((onm) => onm.optionIndex === o)
                              )
                            : undefined,
                    };
                })
                .filter(nonNullable)
        ) ?? []
    );
}

export function getOrderItemModifiersFromOptions(
    modifiersOptions: RoundModifierOption[] | undefined,
    menuItem: MenuItem | null,
    menuModifiers?: Indexed<MenuItemModifier>
) {
    if (!modifiersOptions?.length || !menuItem?.modifiers?.length) {
        return {
            itemModifiers: null,
            allOptionsAvailable: !modifiersOptions?.length,
        };
    }

    const itemModifiers = menuItem.modifiers
        .map((m, mIndex) => getModifierOptionsFromOptions(mIndex, modifiersOptions, m, menuModifiers))
        .filter((res) => res.orderItemModifier.options.length);

    return {
        itemModifiers: itemModifiers.map((i) => i.orderItemModifier),
        allOptionsAvailable: !!itemModifiers.length && itemModifiers.every((i) => i.allOptionsAvailable),
    };
}

function getModifierOption(menuItem: MenuItem, modifierId: number, option: number) {
    const selectedOption = getSelectedModifierChoice(menuItem.modifiers![modifierId].options, option);
    return selectedOption!;
}

function getModifierOptionsFromOptions(
    mIndex: number,
    modifiersOptions: RoundModifierOption[],
    modifier: MenuItemModifier,
    menuModifiers?: Indexed<MenuItemModifier>
) {
    let allOptionsAvailable = true;

    const orderItemModifier = modifiersOptions.reduce<OrderItemModifier>(
        (orderItemModifier, selectedOption) => {
            const option = modifier.options.find(
                (option) =>
                    option.displayName === selectedOption.option &&
                    (!selectedOption.modifierId || selectedOption.modifierId === modifier.id)
            );

            if (!option || option.available === false) {
                allOptionsAvailable = false;
                return orderItemModifier;
            }

            const addOption = setNestedModifierOptionsRecursive(
                selectedOption,
                option,
                orderItemModifier,
                menuModifiers
            );

            if (!addOption) {
                allOptionsAvailable = false;
                return orderItemModifier;
            }

            orderItemModifier.options.push(option.originalIndex);
            return orderItemModifier;
        },
        { modifier: mIndex, options: [], optionNestedModifiers: [] }
    );

    return {
        orderItemModifier,
        allOptionsAvailable,
    };
}

function setNestedModifierOptionsRecursive(
    selectedOption: RoundModifierOption,
    option: ModifierOption,
    orderItemModifier: OrderItemModifier,
    menuModifiers?: Indexed<MenuItemModifier>
) {
    if (!menuModifiers || !option.nestedModifiers?.length) return true;

    const existingNested = orderItemModifier.optionNestedModifiers?.find(
        (onm) => onm.optionIndex === option.originalIndex
    );

    if (existingNested) {
        existingNested.quantity++;
        return true;
    }

    const globalOptions: ModifierOption[] = [];

    for (const nestedModifierId of option.nestedModifiers) {
        const globalModifer = menuModifiers[nestedModifierId];
        if (!selectedOption.nestedModifiers?.length && globalModifer.minSelection) return false;
        globalOptions.push(...globalModifer.options);
    }

    if (!selectedOption.nestedModifiers?.length) return true;

    const optionNestedModifier: OrderItemOptionNestedModifier = {
        optionIndex: option.originalIndex,
        quantity: 1,
        modifiers: [],
        selectedNestedData: [],
    };

    orderItemModifier.optionNestedModifiers = orderItemModifier.optionNestedModifiers ?? [];
    orderItemModifier.optionNestedModifiers.push(optionNestedModifier);

    let nestedModifierIndex = 0;

    for (const nestedModifierId of option.nestedModifiers) {
        const nestedModifier: OrderItemNestedModifier = {
            modifierId: nestedModifierId,
            modifier: nestedModifierIndex++,
            options: [],
            optionNestedModifiers: [],
        };

        optionNestedModifier.modifiers.push(nestedModifier);

        for (const roundModifierOption of selectedOption.nestedModifiers) {
            const globalOption = globalOptions.find((o) => o.displayName === roundModifierOption.option);

            if (!globalOption || globalOption.available === false) return false;

            nestedModifier.options.push(globalOption.originalIndex);

            const setNested = setNestedModifierOptionsRecursive(
                roundModifierOption,
                globalOption,
                nestedModifier,
                menuModifiers
            );

            if (!setNested) return false;
        }
    }

    return true;
}
