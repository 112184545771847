import * as React from "react";
import { MenuItemVariant } from "../../menudata";
import "../assets/OrderVariants.scss";
import { ModifierRadioListItem } from "./ModifierRadioListItem";
import { getModifierListInitialLimit } from "../helpers";
import { ExpandableList } from "../../../sharedComponents/controls/expandableList";
import { MODIFIER_LIST_INITIAL_LIMIT } from "../../menuitem/components/MenuItemDetails";
import { useHasMounted } from "../../../sharedComponents/common/shared";

export interface Props {
    variants: MenuItemVariant[];
    selectedVariantId: number | null;
    onVariantSelected: (variant: any) => void;
}

export const OrderVariants = (props: Props) => {
    const { variants, selectedVariantId, onVariantSelected } = props;
    const [limit, setLimit] = React.useState(MODIFIER_LIST_INITIAL_LIMIT);
    const hasMounted = useHasMounted();

    React.useEffect(() => {
        if (!hasMounted && selectedVariantId !== null) {
            setLimit(getModifierListInitialLimit(MODIFIER_LIST_INITIAL_LIMIT, variants, [selectedVariantId]));
        }
    }, [hasMounted, variants, selectedVariantId]);

    return !hasMounted ? null : (
        <ExpandableList
            initialLimit={limit}
            totalItems={variants.length}
            showHiddenCount={true}
            renderItem={(positionIndex: number) => {
                const variant = variants[positionIndex];
                const disabled = variant.available === false;

                return (
                    <ModifierRadioListItem
                        key={"variant-" + positionIndex}
                        onChange={() =>
                            selectedVariantId !== variant.originalIndex ? onVariantSelected(variant.originalIndex) : {}
                        }
                        checked={selectedVariantId === variant.originalIndex}
                        disabled={disabled}
                        listItem={variant}
                    />
                );
            }}
        />
    );
};
