import { createSelector } from "reselect";
import { getActiveOrder, getPartySubmittedOrders } from "../../order/selectors";
import { Order, OrderStatus } from "../../order";
import { getIsOpenTable } from ".";
import { getCurrentMemberId } from "../../accounts/selectors";

const SUBMITTING_ORDERS_STATES = [
    OrderStatus.SUBMITTING,
    OrderStatus.SUBMITTED,
    OrderStatus.RESUBMITTED,
    OrderStatus.RESUBMITTING,
];

const RESUBMITTING_ORDERS_STATES = [OrderStatus.RESUBMITTED, OrderStatus.RESUBMITTING];

export const getOpenTableSubmittingOrders = createSelector(
    getIsOpenTable,
    getPartySubmittedOrders,
    (isOpenTable, submittedOrders) =>
        isOpenTable ? submittedOrders.filter((order) => SUBMITTING_ORDERS_STATES.includes(order.status)) : []
);
export const getOpenTableHasSubmittingOrder = createSelector(
    getOpenTableSubmittingOrders,
    (submittingOrders) => submittingOrders.length > 0
);

export const getOpenTableResubmittingOrders = createSelector(
    getIsOpenTable,
    getPartySubmittedOrders,
    (isOpenTable, submittedOrders) =>
        isOpenTable ? submittedOrders.filter((order) => RESUBMITTING_ORDERS_STATES.includes(order.status)) : []
);

export const getOpenTableHasResubmittingOrder = createSelector(
    getOpenTableResubmittingOrders,
    (submittingOrders) => submittingOrders.length > 0
);

export const getMemberHasItemInOrders = (orders: Order[], memberId: string) =>
    orders.some((order) => order.items.some((item) => item.memberId === memberId));

export const getOpenTableMemberHasFailedToSubmitItemsInActiveOrder = createSelector(
    getActiveOrder,
    getCurrentMemberId,
    (order, memberId) => order?.items.some((item) =>
        item.memberId === memberId &&
        item.failedToSubmit
    )
);

export const getOpenTableMemberHasUnconfirmedOrders = createSelector(
    getOpenTableSubmittingOrders,
    getCurrentMemberId,
    getMemberHasItemInOrders
);

export const getOpenTableMemberHasResubmittingOrders = createSelector(
    getOpenTableResubmittingOrders,
    getCurrentMemberId,
    getMemberHasItemInOrders
);

export const getOpenTableFailedToSubmitOrders = createSelector(
    getIsOpenTable,
    getPartySubmittedOrders,
    (isOpenTable, submittedOrders) =>
        isOpenTable
            ? submittedOrders.filter(
                  (order) =>
                      order.status === OrderStatus.SUBMITFAILED ||
                      order.status === OrderStatus.REJECTED ||
                      order.status === OrderStatus.ORPHANED
              )
            : []
);

export const getOpenTableMemberHasFailedToSubmitOrders = createSelector(
    getOpenTableFailedToSubmitOrders,
    getCurrentMemberId,
    getMemberHasItemInOrders
);

export const getOpenTableMemberSubmittedOrders = createSelector(
    getIsOpenTable,
    getPartySubmittedOrders,
    getCurrentMemberId,
    (isOpenTable, submittedOrders, currentMemberId) =>
        isOpenTable
            ? submittedOrders.filter((order) => order.items.some((item) => item.memberId === currentMemberId))
            : []
);
