import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { RadioList } from "../../../sharedComponents";
import { LimitedMenuType } from "../types";
import { getActiveServiceMenus, getLimitedMenuPackages } from "../../menu/selectors";
import { actionCreators } from "../reducers/groupTabWizard";
import { startGroupTabWizardAction } from "../actions/startGroupTabWizardAction";

interface Props {
    onChange: (value: LimitedMenuType) => void;
    menuType: LimitedMenuType;
}

export const GroupTabMenuType = ({ onChange, menuType }: Props) => {
    const locationPackages = useSelector(getLimitedMenuPackages);
    const activeServiceMenus = useSelector(getActiveServiceMenus);

    const dispatch = useDispatch();
    const { errors } = useFormContext();

    const setMenuType = useCallback(
        (menuType: LimitedMenuType) => {
            dispatch(actionCreators.setGroupTabMenuType(menuType));
            if (menuType === LimitedMenuType.FULL) {
                dispatch(actionCreators.setGroupTabPackage(undefined));
            }
            dispatch(startGroupTabWizardAction());
            onChange(menuType);
        },
        [dispatch, onChange]
    );

    return (
        <RadioList
            items={[
                {
                    title: "Show the full menu",
                    description: "Allow friends to order everything on the menu with no restrictions",
                    disabled: false,
                    checked: menuType === LimitedMenuType.FULL,
                    value: LimitedMenuType.FULL,
                    name: "menuType",
                },
                {
                    title: "Limit your menu",
                    description: "Limit what your friends can order. Select from preset packages",
                    disabled: !locationPackages?.length || !activeServiceMenus?.length,
                    checked: menuType === LimitedMenuType.LIMITED,
                    value: LimitedMenuType.LIMITED,
                    name: "menuType",
                },
            ]}
            errorMessage={errors?.menuType?.message}
            onChange={setMenuType}
        />
    );
};
