import { AnyAction, Middleware, MiddlewareAPI, ReducersMapObject } from "redux";
import initMenuFeature, { State as MenuState } from "./menu";
import initMenuDataFeature, { State as MenuDataState } from "./menudata";
import partyFeature, { State as PartyState } from "./order";
import filtering, { State as FilteringState } from "./filter";
import signupFeature, { State as SignupState } from "./signup";
import initAnalytics from "./analytics";
import initApplicationUpdate, { State as ApplicationUpdateState } from "./updates";
import { History } from "history";
import initCustomerCards, { State as CustomerCardsState } from "./accounts";
import initPaymentState, { State as PaymentState } from "./payment";
import foodPreferences from "./accountmenu";
import accountMenu, { State as AccountsState } from "./accountmenu";
import initNative, { State as NativeState } from "./native";
import initMsgBox, { State as MsgBoxState } from "./modalMessage";
import initOperations, { State as OperationsState } from "./operations";
import initDeepLink, { State as DeepLinkState } from "./deeplink";
import initSpinnerModal, { State as SpinnerState } from "./spinnerModal";
import initMealHistory, { State as OrderHistoryDataState } from "./orderHistoryData";
import initGroupTabHistory, { State as GroupTabHistoryDataState } from "./groupTabsHistory";
import initSurvey, { State as SurveyState } from "./survey";
import initMarketing, { State as MarketingState } from "./marketing";
import initPartyOnboarding, { State as PartyOnboardingState } from "./partyOnboarding";
import initEasyReorderItems, { State as EasyReorderState } from "./easyReorderItem";
import initPagePosition, { State as PagePositionState } from "./pagePosition";
import initMenuSearch, { State as MenuSearchState } from "./menuSearch";
import initOrderAgain, { State as OrderAgainState } from "./orderAgain";
import initQuickSell, { State as QuickSellState } from "./quickSell";
import initGroupTabs, { State as GroupTabsState } from "./groupTabs";
import initNavBar, { State as NavBarState } from "../common/navigation";
import initMultiInstance, { State as MultiInstanceState } from "./multiInstance";
import initMembership, { State as MembershipState } from "./membership";
import initRegion, { State as RegionState } from "./region";
import initBanners, { State as BannersState } from "./banners";
import initABTests, { State as ABTestsState } from "./abtests";
import initWizards, { State as WizardsState } from "./wizards";
import initPayOnly, { State as PayOnlyState } from "./payOnly";
import initOpenTable, { State as OpenTableState } from "./openTable";
import initPaymentMethods, { State as PaymentMethodsState } from "./paymentMethods";
import initPreview from "./preview";
import initHistory from "./history";
import { ThunkDispatch } from "redux-thunk";
import { ErrorHandler } from "src/common/error";
import initNotifications, { State as NotificationsState } from "./notifications";
import initTakeawayOptions, { State as TakeawayOptionsState } from "./takeaway";
import initOffers, { State as OffersState } from "./offers";
import initPromotions from "./promotion";

export type AppState = ApplicationUpdateState &
    MenuDataState &
    PartyState &
    MenuState &
    FilteringState &
    SignupState &
    CustomerCardsState &
    PaymentState &
    NativeState &
    MsgBoxState &
    OperationsState &
    AccountsState &
    DeepLinkState &
    SpinnerState &
    OrderHistoryDataState &
    GroupTabHistoryDataState &
    SurveyState &
    MarketingState &
    PartyOnboardingState &
    EasyReorderState &
    PagePositionState &
    NavBarState &
    MultiInstanceState &
    MembershipState &
    MenuSearchState &
    OrderAgainState &
    QuickSellState &
    GroupTabsState &
    RegionState &
    BannersState &
    ABTestsState &
    WizardsState &
    PayOnlyState &
    PaymentMethodsState &
    NotificationsState &
    TakeawayOptionsState &
    OffersState &
    OpenTableState;

export type AppDispatch = ThunkDispatch<AppState, void, AnyAction>;

export type AppMiddleware = MiddlewareAPI<AppDispatch, AppState>;

export type CallbackUrl = {
    url: string;
    context: "window" | "iframe";
    messageType: string;
    idToRemove?: string;
};

export interface FeatureConfiguration {
    middleware: Middleware[];
    reducers: Partial<ReducersMapObject<AppState, any>>;
    history: History;
    callbackUrls: CallbackUrl[];
    errorHandlers: ErrorHandler[];
}

type InitFeatureFn = (config: FeatureConfiguration) => void;

const initFeatureFunctions: InitFeatureFn[] = [
    initApplicationUpdate,
    initMenuFeature,
    initMenuDataFeature,
    partyFeature,
    filtering,
    initAnalytics,
    signupFeature,
    initCustomerCards,
    initPaymentState,
    foodPreferences,
    accountMenu,
    initNative,
    initMsgBox,
    initOperations,
    initDeepLink,
    initSpinnerModal,
    initMealHistory,
    initGroupTabHistory,
    initSurvey,
    initMarketing,
    initPartyOnboarding,
    initEasyReorderItems,
    initPagePosition,
    initNavBar,
    initMultiInstance,
    initPreview,
    initHistory,
    initMembership,
    initMenuSearch,
    initOrderAgain,
    initQuickSell,
    initRegion,
    initGroupTabs,
    initBanners,
    initABTests,
    initWizards,
    initPayOnly,
    initPaymentMethods,
    initNotifications,
    initTakeawayOptions,
    initOpenTable,
    initOffers,
    initPromotions,
];

export default function init(history: History): FeatureConfiguration {
    const config: FeatureConfiguration = {
        middleware: [],
        reducers: {},
        history,
        callbackUrls: [],
        errorHandlers: [],
    };

    for (const initFeature of initFeatureFunctions) {
        initFeature(config);
    }

    return config;
}
