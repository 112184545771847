import React from "react";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { getNavBarStatus } from "../../../common/navigation";
import { NavBarType } from "../../../common/navigation/types";
import { PayOnly } from "./PayOnly";

export const Pay = () => {
    const navBarStatus = useSelector(getNavBarStatus);

    return (
        <CSSTransition
            in={navBarStatus === NavBarType.PAY}
            classNames="party-wrapper-transition"
            timeout={150}
            unmountOnExit
        >
            <PayOnly />
        </CSSTransition>
    );
};
