import { SignInWizardPage } from "..";
import { trackedError, trackedEvent } from "../../../common/events/reduxEventTracking";
import { AppState } from "../../index";
import { CreatedVerifiedAccountPageDetails } from "./createVerifiedAccount";
import { SsoProvider } from "../../../common/sso";

export interface SignInOptions {
    requireVerification?: boolean;
    useLocationTheme?: boolean;
    onComplete?: () => void;
    onCancel?: () => void;
    getSignInComplete?: (state: AppState) => boolean;
    slideAnimation?: "slide-in" | "slide-from-right";
    isMidWizard?: boolean;
    accountDetailsOptions?: CreatedVerifiedAccountPageDetails;
    completeAfterLinkSso?: boolean;
}

export interface SignInFlowState {
    page?: SignInWizardPage;
    prevPage?: SignInWizardPage;
    startPage?: SignInWizardPage;
    phone?: string;
    options?: SignInOptions;
}

export enum TypeKeys {
    SIGN_IN_FLOW_SHOW = "SIGN_IN_FLOW/SHOW",
    SET_PHONE = "SIGN_IN_FLOW/SET_PHONE",
    SET_OPTIONS = "SIGN_IN_FLOW/SET_OPTIONS",
    SIGN_IN_FLOW_DONE = "SIGN_IN_FLOW/DONE",
    LOGOUT = "SIGN_IN/LOGOUT",
    LOGIN_SOURCE_CLICKED = "SIGN_IN/LOGIN_SOURCE_CLICKED",
    INITIALIZE_LOGIN_SOURCE_FAILED = "SIGN_IN/INITIALIZE_LOGIN_SOURCE_FAILED",
    ACCOUNT_LINK_RESPONSE = "SIGN_IN/ACCOUNT_LINK_RESPONSE",
    ACCOUNT_LINK_SOURCE_CLICKED = "SIGN_IN/ACCOUNT_LINK_SOURCE_CLICKED",
}

type LoginSource = SsoProvider | "PhoneNumber";

type AccountLinkResponse = "link" | "cancel";

export const actionCreators = {
    showPage: (page: SignInWizardPage) => ({ type: TypeKeys.SIGN_IN_FLOW_SHOW, page }),
    setPhone: (phone: string) => ({ type: TypeKeys.SET_PHONE, phone }),
    setOptions: (options?: SignInOptions) => ({ type: TypeKeys.SET_OPTIONS, options }),
    completeSignInFlow: () => ({ type: TypeKeys.SIGN_IN_FLOW_DONE }),
    logout: () => ({ type: TypeKeys.LOGOUT }),
    trackLoginSourceClicked: (source_clicked: LoginSource) =>
        trackedEvent({ type: TypeKeys.LOGIN_SOURCE_CLICKED, source_clicked }),
    trackInitializeLoginSourceFailed: (source: SsoProvider, error: any) =>
        trackedError({ type: TypeKeys.INITIALIZE_LOGIN_SOURCE_FAILED, source, error }),
    trackAccountLinkResponse: (response: AccountLinkResponse) =>
        trackedEvent({ type: TypeKeys.ACCOUNT_LINK_RESPONSE, response }),
    trackAccountLinkSourceClicked: (source_clicked: SsoProvider) =>
        trackedEvent({ type: TypeKeys.ACCOUNT_LINK_SOURCE_CLICKED, source_clicked }),
};

export type StartSignInFlowAction = { type: TypeKeys.SIGN_IN_FLOW_SHOW; page: SignInWizardPage };
export type SetPhoneAction = { type: TypeKeys.SET_PHONE; phone: string };
export type SetOptionsAction = { type: TypeKeys.SET_OPTIONS; options?: SignInOptions };
export type CompleteSignInFlowAction = { type: TypeKeys.SIGN_IN_FLOW_DONE; noAnimate?: boolean };
export type LogoutAction = { type: TypeKeys.LOGOUT };

export type SignInFlowActions = StartSignInFlowAction | SetPhoneAction | SetOptionsAction | CompleteSignInFlowAction;

const initialState: SignInFlowState = {};

export function reducer(state: SignInFlowState = initialState, action: SignInFlowActions): SignInFlowState {
    if (action.type === TypeKeys.SIGN_IN_FLOW_SHOW) {
        const { page } = action;
        return {
            ...state,
            page,
            prevPage: state.page,
            startPage: state.startPage ?? page,
        };
    }

    if (action.type === TypeKeys.SET_PHONE) {
        const { phone } = action;
        return {
            ...state,
            phone,
        };
    }

    if (action.type === TypeKeys.SET_OPTIONS) {
        return {
            ...state,
            options: action.options,
        };
    }

    if (action.type === TypeKeys.SIGN_IN_FLOW_DONE) {
        return {
            ...state,
            page: undefined,
            prevPage: undefined,
            startPage: undefined,
            phone: undefined,
        };
    }

    return state;
}
