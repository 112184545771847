import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import jwtDecode from "jwt-decode";

const KEY = "MEANDU_TABLE";

let tableToken: string | null = null;

type TableTokenInfo = {
    partyId: string;
    restaurantId: string;
    memberId: string;
};

type TableTokenJwt = {
    sub: string;
    party: string;
    restaurant: string;
};

export function getTableTokenPartyInfo(tableToken?: string | null): TableTokenInfo | null {
    tableToken = tableToken ?? getTableToken();

    if (!tableToken) {
        return null;
    }

    try {
        const { sub, party, restaurant } = jwtDecode<TableTokenJwt>(tableToken);

        return {
            partyId: party,
            restaurantId: restaurant,
            memberId: sub,
        };
    } catch (e) {
        return null;
    }
}

export function getTableToken(): string | null {
    try {
        const token = window.localStorage.getItem(KEY);
        if (token && !tableToken) {
            // Store token in memory as a backup
            tableToken = token;
        }
        if (tableToken && !token) {
            // If we have the token in memory but it's disappeared from localStorage then add it back
            saveTableToken(tableToken);
        }
        return tableToken;
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to retrieve table token from storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        return null;
    }
}

export function saveTableToken(token: string) {
    try {
        tableToken = token;
        window.localStorage.setItem(KEY, token);
        if (!token) {
            getAppInsights()?.trackTrace({
                message: `Saved null table token`,
                severityLevel: SeverityLevel.Error,
            });
        }
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to save table token to storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        throw err;
    }
}

export type DeleteTableReason =
    | "none"
    | "left"
    | "upgrade"
    | "failed_join"
    | "failed_rejoin"
    | "failed_reconnect"
    | "disconnect_party"
    | "switch_to_pay"
    | "party_closed"
    | "pay_party_closed"
    | "no_flex_payment_order"
    | "failed_rejoin_pay";

export function deleteTableToken(reason: DeleteTableReason) {
    try {
        tableToken = null;
        window.localStorage.removeItem(KEY);
        getAppInsights()?.trackTrace({
            message: `Deleted table token (${reason})`,
            severityLevel: SeverityLevel.Information,
        });
    } catch (err) {
        getAppInsights()?.trackTrace({
            message: `Failed to delete table token from storage: ${err.message}`,
            severityLevel: SeverityLevel.Error,
        });
        throw err;
    }
}

export function getTableTokenHeaders(): Headers {
    const token = getTableToken();

    if (!token) {
        getAppInsights()?.trackTrace({
            message: `Table token authentication required but token was ${JSON.stringify(token)}`,
            severityLevel: SeverityLevel.Error,
        });
    }

    return new Headers({
        Authorization: `Bearer ${token}`,
    });
}
