import { createSelector } from "reselect";
import { getOrdersByType } from "src/features/order/selectors";
import { DeliveryOptionType } from "src/features/order/types";
import { AppState } from "../../index";
import { MealHistoryOrderStatus } from "../reducers/types";

export const getMealHistoryItems = (state: AppState) =>
    state.orderHistoryData ? state.orderHistoryData.orderHistoryData : [];
export const getMealPartyToDisplay = (state: AppState) =>
    state.orderHistoryData ? state.orderHistoryData.selectedPartyOrderHistory : "";
export const getPickupOptions = (state: AppState) =>
    (state.party.activeParty || state.party.previousParty)?.deliveryOptions?.filter(
        (o) => o.type === DeliveryOptionType.PICKUP
    );

export const getOngoingOrderHistories = createSelector(getMealHistoryItems, (mealHistory) => {
    return mealHistory.filter(
        (m) =>
            m.mealHistoryOrders &&
            m.mealHistoryOrders.some((o) =>
                [
                    MealHistoryOrderStatus.SUBMITTED,
                    MealHistoryOrderStatus.PREPARING,
                    MealHistoryOrderStatus.READY,
                ].includes(o.status)
            )
    );
});

const getIncompleteOrdersHistory = createSelector(getMealHistoryItems, (mealHistory) => {
    return mealHistory.filter((m) => m.mealHistoryOrders && m.mealHistoryOrders.some((o) => o.isPickup && !!o.status));
});

export const getActiveOrdersHistory = createSelector(getIncompleteOrdersHistory, (incompleteHistory) => {
    return incompleteHistory.filter((m) =>
        m.mealHistoryOrders!.some(
            (o) => o.status === MealHistoryOrderStatus.PREPARING || o.status === MealHistoryOrderStatus.SUBMITTED
        )
    );
});

export const getReadyOrdersHistory = createSelector(getIncompleteOrdersHistory, (incompleteHistory) => {
    return incompleteHistory.filter((m) => m.mealHistoryOrders!.some((o) => o.status === MealHistoryOrderStatus.READY));
});

export const getHasActiveOrdersHistory = createSelector(
    getActiveOrdersHistory,
    getReadyOrdersHistory,
    (activeOrdersHistory, readyOrdersHistory) => !!activeOrdersHistory.length || !!readyOrdersHistory.length
);

export const getHistoryDetail = createSelector(
    getMealHistoryItems,
    getMealPartyToDisplay,
    (mealHistory, selectedParty) =>
        selectedParty ? mealHistory.find((m) => m.partyId === selectedParty) || null : null
);

export const getFoodPickup = createSelector(getPickupOptions, (pickupOptions) =>
    pickupOptions?.filter((o) => o.menuItemType === "food")
);

export const getDrinkPickup = createSelector(getPickupOptions, (pickupOptions) =>
    pickupOptions?.filter((o) => o.menuItemType === "drinks")
);

export const shouldFoodShowPickup = createSelector(getFoodPickup, getOrdersByType, (foodPickup, order) => {
    if (!foodPickup?.length) return false;
    return !!order.food?.length;
});
