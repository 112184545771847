import { AnchorHTMLAttributes } from "react";

export const href = {
    openInNewWindowAttributes: (href: string) => {
        const attributes: AnchorHTMLAttributes<HTMLAnchorElement> = {
            href,
        };

        if (href?.indexOf("mailto") !== 0) {
            attributes.target = "_blank";
            attributes.rel = "noopener noreferrer";
        }

        return attributes;
    },
};
