import classNames from "classnames";
import React from "react";
import { CrownIcon } from "src/sharedComponents/assets/icons";
import { Text } from "../../../sharedComponents";
import "./MemberInitials.scss";

interface MemberInitialsProps {
    className?: string;
    memberInitials: string;
    special?: boolean;
}

export const MemberInitials = ({ className, memberInitials, special }: MemberInitialsProps) => (
    <Text preset="g-14" mode={["extra-bold", "caps"]} className={classNames("member-initials", className)}>
        {memberInitials}
        {!!special && (
            <div className="member-initials__special">
                <CrownIcon />
            </div>
        )}
    </Text>
);
