import { generatePath } from "react-router";
import { createSelector } from "reselect";
import { AppState } from "..";
import { Indexed, LocationMenuData, Menu, MenuItem, Tag } from "../menudata";
import { getRestaurantFlags } from "../order/selectors/restaurantFlags";
import {
    embellishGlobalModifierOptions,
    embellishItemVariantsAndModifierOptions,
    reduceItemsByPackage,
    reduceCategories,
    reduceMenus,
    reduceServices,
} from "./helpers";
import { DietaryIcons } from "src/sharedComponents/assets/icons";
import { DietaryIconKey } from "./components/DietaryTags";

export const TAG_GROUP_TAG_ID = "5b6590ae49634b2c1c312f99";

export const getActiveMenuType = (_: any, { type }: any) => type as string;

export const getMenuState = (state: AppState) => state.menuData;

export const getMenuData = createSelector(getMenuState, (menu) => menu.data || null);

export const getActiveServiceId = createSelector(getMenuState, (menuState) =>
    menuState.activeServiceId ? menuState.activeServiceId : null
);

const getParty = (state: AppState) => state.party.activeParty;

export const getActivePackageId = createSelector(getParty, (party) => party?.packageId ?? null);
export const getActiveServiceUnfiltered = createSelector(
    getMenuData,
    getParty,
    getActiveServiceId,
    (menuData, party, activeServiceId) => {
        if (!menuData) return null;
        const serviceId = party ? party.serviceId : activeServiceId;
        return menuData.services.find((s) => s.id === serviceId);
    }
);

export const getLimitedMenuPackages = createSelector(
    getMenuData,
    getActiveServiceUnfiltered,
    (menuData, activeService) =>
        menuData?.packages?.filter(({ enabled, categories }) => {
            const hasAvailableCategories = Object.keys(categories).some((cat) =>
                activeService?.categories.includes(cat)
            );
            return enabled && hasAvailableCategories;
        })
);

export const getActivePackageData = createSelector(
    getActivePackageId,
    getLimitedMenuPackages,
    (packageId, availablePackages) =>
        packageId && availablePackages ? availablePackages.find((pack) => pack.id === packageId) : null
);

export const getVisibleMenuData = createSelector(
    getMenuData,
    getActivePackageData,
    (menuData, packageData): LocationMenuData | null => {
        if (!menuData) {
            return menuData;
        }

        const items = reduceItemsByPackage(menuData.items, packageData);
        const embellishedItems = embellishItemVariantsAndModifierOptions(items);
        const embellishedGlobalModifiers = embellishGlobalModifierOptions(menuData.modifiers);
        const activeCategoryIds = packageData?.categories
            ? Object.keys(packageData.categories)
            : Object.keys(menuData.categories);
        const categories = reduceCategories(menuData.categories, embellishedItems, activeCategoryIds);
        const menus = reduceMenus(menuData.menus, categories);
        const services = reduceServices(menuData.services, menus, categories);

        return {
            ...menuData,
            services,
            menus,
            categories,
            items: embellishedItems,
            modifiers: embellishedGlobalModifiers,
        };
    }
);

export const getVisibleMenuDataModifiers = createSelector(
    getVisibleMenuData,
    (visibleMenuData) => visibleMenuData?.modifiers
);

export const getActiveService = createSelector(
    getVisibleMenuData,
    getParty,
    getActiveServiceId,
    (menuData, party, activeServiceId) => {
        if (!menuData) return null;
        const serviceId = party ? party.serviceId : activeServiceId;
        return menuData.services.find((s) => s.id === serviceId);
    }
);

export const getDietaryTagGroupDetails = (tagsList: Tag[], foodPreferences: string[]) =>
    foodPreferences.map((prefId) => tagsList.find(({ id }) => id === prefId)?.displayName || "").filter(Boolean);

export const getDietaryTagGroup = createSelector(
    getVisibleMenuData,
    (menuData) => menuData?.tagGroups.find((tg) => tg.id === TAG_GROUP_TAG_ID) || null
);

export const getPriorityDietaryTags = createSelector(
    getDietaryTagGroup,
    (group) => group?.tags.filter((_, i) => i < 5) // First five
);

export const getMenuItemDietaryTags = (menuItem: MenuItem, state: AppState, max?: number): Tag[] => {
    const tagSet = new Set(menuItem.tags);

    return getDietaryTagGroup(state)!
        .tags.filter((t) => tagSet.has(t.id))
        .filter((_, i) => max === undefined || i < max);
};

export const getMenuItemPriorityDietaryTags = (menuItem: MenuItem, state: AppState, max: number): Tag[] => {
    const tagSet = new Set(menuItem.tags);

    const dietaryTagGroup = getDietaryTagGroup(state);

    if (!dietaryTagGroup?.tags) {
        return [];
    }

    return dietaryTagGroup.tags.filter((t) => tagSet.has(t.id) && DietaryIcons[t.icon as DietaryIconKey]).slice(0, max);
};

export const getActiveOrLastActiveService = createSelector(
    getVisibleMenuData,
    getParty,
    getActiveService,
    (menuData, party, service) => {
        if (service) return service;
        if (!menuData || !party || party.serviceHistory.length === 0) return null;
        const serviceId = party.serviceHistory[party.serviceHistory.length - 1];
        return menuData.services.find((s) => s.id === serviceId);
    }
);

export const getCourses = createSelector(getVisibleMenuData, (menuData) => (menuData ? menuData.courses : []));

export const getActiveServiceMenus = createSelector(
    getVisibleMenuData,
    getActiveOrLastActiveService,
    (snapshot, service) => (service ? service.menus.map((id) => snapshot!.menus[id]) : null)
);

export const getActiveServiceSetMenus = createSelector(getActiveOrLastActiveService, (service) =>
    service && service.setMenus ? service.setMenus.slice(0) : null
);

export const getActiveServiceSetMenuItems = createSelector(getVisibleMenuData, getActiveService, (snapshot, service) =>
    service ? service.setMenus!.map((id) => snapshot!.items[id]) : null
);

export interface MenuItemLinkParams {
    menuId: string;
    categoryId: string;
    menuItemId: string;
}
export const getActiveServiceMenuItemLinkParams = createSelector(
    getActiveOrLastActiveService,
    getVisibleMenuData,
    (service, menuData) => {
        const map = new Map<string, MenuItemLinkParams>();

        if (!service || menuData === null) {
            return map;
        }

        for (const menuId of service!.menus) {
            const menu = menuData.menus[menuId];
            for (const categoryId of menu.categories) {
                const category = menuData.categories[categoryId];
                for (const menuItemId of category.menuItems) {
                    if (!map.has(menuItemId)) {
                        map.set(menuItemId, {
                            menuId,
                            categoryId,
                            menuItemId,
                        });
                    }
                }
            }
        }

        return map;
    }
);

// This doubles as a "not in this menu" fallback when generating links, as well as a "does this menu item even exist in this service"
export const getActiveServiceMenuItemLinks = createSelector(
    getActiveServiceMenuItemLinkParams,
    (activeServiceMenuItemLinkParams) => {
        const map = new Map<string, string>();

        if (!activeServiceMenuItemLinkParams || !activeServiceMenuItemLinkParams.size) {
            return map;
        }

        const params = { menuId: "", categoryId: "", menuItemId: "" };
        activeServiceMenuItemLinkParams.forEach((data: MenuItemLinkParams, menuItemId: string) => {
            params.menuId = data.menuId;
            params.categoryId = data.categoryId;
            params.menuItemId = data.menuItemId;
            const url = generatePath("/menu/:menuId/:categoryId/:menuItemId", params);
            map.set(menuItemId, url);
        });
        return map;
    }
);

export const getActiveServiceMenuItemIds = createSelector(
    getActiveServiceMenuItemLinkParams,
    (activeServiceMenuItemLinkParams) => {
        const menuItemIds: Indexed<boolean> = {};

        activeServiceMenuItemLinkParams.forEach((_, menuItemId: string) => {
            menuItemIds[menuItemId] = true;
        });

        return menuItemIds;
    }
);

export const getMenuSwitcherActiveServiceMenus = createSelector(
    getVisibleMenuData,
    getActiveOrLastActiveService,
    (menuData, service): { [menuId: string]: Menu } | null => {
        if (!service) return null;
        const serviceMenus = service.originalMenus || service.menus;
        const menus = {};

        for (const menuId of serviceMenus) {
            const serviceMenu = menuData!.menus[menuId];
            menus[menuId] = serviceMenu;
        }

        return menus;
    }
);

export const getActiveMenuId = (_: any, { menuId }: any) => menuId as string;

export const getActiveMenu = createSelector(
    getVisibleMenuData,
    getActiveMenuId,
    (menuData, menuId) => menuData!.menus[menuId] ?? menuData!.menus[Object.keys(menuData!.menus)[0]]
);

export const getActiveCategoryId = (_: any, { categoryId }: any) => categoryId as string | null;

export const getActiveCategory = createSelector(getVisibleMenuData, getActiveCategoryId, (menuData, categoryId) =>
    categoryId ? menuData!.categories[categoryId] : null
);

export const getActiveMenuCategories = createSelector(getActiveMenu, getVisibleMenuData, (menu, menuData) =>
    menu.categories.map((c) => menuData!.categories[c]).filter((c) => c)
);

const getMenuFilters = (state: AppState) => state.menuFilters;

export const getActiveMenuFilters = createSelector(
    getActiveMenuType,
    getMenuFilters,
    (menuType: string, filters: { [menuType: string]: string[] }) => filters[menuType]
);

export const getMenuItemLinkParamCategoryIdAccessor = createSelector(
    getActiveServiceMenuItemLinkParams,
    getActiveCategoryId,
    getActiveCategory,
    (menuItemLinkParams, categoryId, category) => (menuItemId: string) =>
        category && category.menuItems.indexOf(menuItemId) !== -1
            ? categoryId!
            : menuItemLinkParams.has(menuItemId)
            ? menuItemLinkParams.get(menuItemId)!.categoryId
            : undefined
);

export const getMenuItemCourseAccessor = createSelector(
    getVisibleMenuData,
    getActiveService,
    getMenuItemLinkParamCategoryIdAccessor,
    (menuData, service, getMenuItemLinkParamCategoryId) => (menuItemId: string) => {
        if (!service || !menuData) {
            return undefined;
        }

        const categoryId = getMenuItemLinkParamCategoryId(menuItemId);

        if (!categoryId) {
            return undefined;
        }

        const category = menuData.categories[categoryId];

        if (category.course && service.courses.indexOf(category.course) !== -1) {
            return category.course;
        }

        return service.defaultCourse;
    }
);

export const getLocationName = createSelector(getVisibleMenuData, (menuData) => menuData?.title);

export const getLocationHeroImage = createSelector(
    getVisibleMenuData,
    (locationMenuData) => locationMenuData?.heroImage?.full
);

export const getAlcoholicDrinksLimit = createSelector(
    getActiveService,
    (service) => service?.maxAlcoholicDrinksPerOrder
);

export const getHasLimitedAlcoholicTooltipBeenDismissed = (state: AppState) =>
    state.party?.limitedAlcoholicTooltipDismissed;

export const getAllServices = createSelector(getMenuData, (menuData) => menuData?.services);

export const getItemIdsWithVideos = createSelector(getVisibleMenuData, (menuData) => {
    if (!menuData) return undefined;
    return Object.keys(menuData.items).filter((itemId) => menuData.items[itemId].videos);
});

export const getOpenTableBatchTimeSeconds = createSelector(
    getRestaurantFlags,
    ({ openTableBatchTimeSeconds }) => openTableBatchTimeSeconds
);

export const getBespokeModalData = createSelector(getRestaurantFlags, (restaurantFlags) =>
    restaurantFlags?.bespokeModalText
        ? {
              title: restaurantFlags?.bespokeModalTitle,
              text: restaurantFlags?.bespokeModalText,
          }
        : null
);
