import { AppDispatch } from "../../index";
import {
    findingQrCodeOperation,
    joiningFlexTabPartyOperation,
    joiningOpenTablePartyOperation,
    joiningTableOperation,
    rejoiningTableOperation,
} from "../operations";
import { qrActionCreators } from "../reducers";

export function resetJoinTableOperations() {
    return (dispatch: AppDispatch) => {
        dispatch(findingQrCodeOperation.actionCreators.reset());
        dispatch(joiningTableOperation.actionCreators.reset());
        dispatch(joiningFlexTabPartyOperation.actionCreators.reset());
        dispatch(rejoiningTableOperation.actionCreators.reset());
        dispatch(qrActionCreators.resetScanStatus());
        dispatch(joiningOpenTablePartyOperation.actionCreators.reset());
    };
}
