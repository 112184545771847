import * as React from "react";
import "../assets/LegalPage.scss";
import { LegalPage } from "./LegalPage";
import { regionHelper } from "src/features/region";

interface Props {
    goBack?: () => void;
}

export const TermsOfUse = ({ goBack }: Props) => {
    const region = regionHelper.region.id;
    const termsUrl = `legal/app-meandu-terms-conditions-${region}`;

    return <LegalPage title="Terms of Use" goBack={goBack} relativeUrl={termsUrl} />;
};
