import { actionCreators, getParty, OrderItemModifier } from "..";
import { AppDispatch, AppState } from "../..";
import { addOrUpdateOrderItemOperation } from "../operations";
import { OrderCampaigns } from "../reducers/types";
import { orderApi } from "../orderApi";

export function addOrUpdateOrderItem(
    courseId: string,
    categoryId: string | null,
    menuItemId: string,
    notes: string | null,
    orderItemId: string | null,
    variant: number | null,
    modifiers?: OrderItemModifier[],
    quantity?: number,
    campaign?: OrderCampaigns,
    source?: string,
    isVideo?: boolean,
    quickSellCarouselPosition?: number
) {
    return addOrUpdateOrderItemOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(
            actionCreators.addOrUpdateToOrder(
                courseId,
                categoryId,
                menuItemId,
                notes,
                orderItemId,
                variant,
                modifiers,
                quantity,
                campaign,
                source,
                isVideo,
                quickSellCarouselPosition
            )
        );

        const party = getParty(getState());

        if (!party) return;

        const orderItem = {
            courseId,
            categoryId,
            itemId: menuItemId,
            variant: variant === null ? undefined : variant,
            modifiers,
            quantity: quantity || 1,
            notes,
            id: orderItemId,
        };

        try {
            await orderApi.invokeWithPartyUpdateAsNeeded(
                dispatch,
                getState,
                "addOrUpdateOrderItem",
                party.id,
                orderItem
            );
        } catch (e) {
            dispatch(actionCreators.addToOrderFailed(e));
            throw e;
        }
    });
}
