import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { Button, FormControl, Input, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getSignupMembershipState } from "../selectors/getActiveMembershipState";
import { connect as connectMembership } from "../actions/connect";
import "./MembershipSignup.scss";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormMembershipAuthentication, MembershipDataCollectionStatement } from "..";
import { MembershipSignupHeader } from "./MembershipSignupHeader";
import { MembershipScanBarcode } from "./MembershipScanBarcode";
import { AvailableMembershipState, FormConnectField } from "../types/MembershipState";
import { connectMembershipOperation } from "../operations";
import { isValidationProblemDetails, ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { href } from "src/common/experience";

export const MembershipFormSignup = () => {
    const signupMembership = useSelector(getSignupMembershipState);

    if (signupMembership === null || signupMembership.authentication.method !== "form") {
        return null;
    }

    return <MembershipForm signupMembership={signupMembership} />;
};

export const MembershipForm = ({ signupMembership }: { signupMembership: AvailableMembershipState }) => {
    const dispatch = useDispatch();
    const form = useForm({
        mode: "onChange",
    });

    const { handleSubmit, formState, setError } = form;

    const { authentication, links, programName, programId, dataCollectionStatement } = signupMembership;
    const connectError = useSelector(connectMembershipOperation.getError);

    const {
        flows: {
            connect: { title, fields },
        },
    } = authentication as FormMembershipAuthentication;

    useEffect(() => {
        if (connectError instanceof ProblemDetailsError) {
            const problemDetails = connectError.problemDetails;
            let handled = false;

            if (isValidationProblemDetails(problemDetails)) {
                for (let fieldName of Object.getOwnPropertyNames(problemDetails.errors)) {
                    const field = fields.find((f) => f.name === fieldName);

                    if (!field) {
                        break;
                    }

                    const message = problemDetails.errors[fieldName].length
                        ? problemDetails.errors[fieldName][0]
                        : `Not a valid ${field.label}`;

                    handled = true;
                    setError(fieldName, { message });
                }
            }

            if (!handled) {
                dispatch(showModalMessage(modalMessages.problemDetail(problemDetails)));
            }

            dispatch(connectMembershipOperation.actionCreators.reset());
        }
    }, [fields, connectError, setError, dispatch]);

    const onClick = useCallback(
        (fields: { [x: string]: string }) => {
            dispatch(
                connectMembership(signupMembership!.programId, async () => {
                    const body = new FormData();
                    Object.keys(fields).forEach((k) => body.append(k, fields[k]));
                    return body;
                })
            );
        },
        [dispatch, signupMembership]
    );

    if (signupMembership === null || signupMembership.authentication.method !== "form") {
        return null;
    }

    return (
        <div className={classNames("profile-page-wrapper standalone")}>
            <div className="membership-signup">
                <div className="scroll-element animated-child">
                    <MembershipSignupHeader title={title} programName={programName} links={links} />
                    <div className="membership-signup__fields">
                        <FormProvider {...form}>
                            {fields.map((field, index) => (
                                <MembershipFormField
                                    key={`${programId}-${field.name}-${index}`}
                                    field={field}
                                    programName={programName}
                                />
                            ))}
                        </FormProvider>
                    </div>
                    {dataCollectionStatement && (
                        <DataCollectionStatement
                            programName={programName}
                            dataCollectionStatement={dataCollectionStatement}
                        />
                    )}
                </div>
                <div className="membership-signup__submit profile-page__submit">
                    <Button onClick={handleSubmit(onClick)} value="Link account" disabled={!formState.isValid} />
                </div>
            </div>
        </div>
    );
};

interface DataCollectionStatementProps {
    programName: string;
    dataCollectionStatement: MembershipDataCollectionStatement;
}

const DataCollectionStatement = ({
    programName,
    dataCollectionStatement: {
        statementIntroText,
        statementText,
        statementUrlText,
        statementUrl,
        privacyUrlPrefix,
        privacyUrlText,
        privacyUrl,
    },
}: DataCollectionStatementProps) => (
    <div className="membership-signup__data-collection-statement">
        {statementIntroText && <Text preset="g-12" mode="block" value={statementIntroText} />}
        <Text preset="g-12" mode="block">
            {statementText}
            {(statementUrl || privacyUrl) && (
                <span>
                    {" "}
                    {statementUrl && (
                        <a
                            className="membership-signup__subtitle__link"
                            {...href.openInNewWindowAttributes(statementUrl)}
                        >
                            {statementUrlText || `${programName} Data Collection Statement`}
                        </a>
                    )}
                    {privacyUrl && (
                        <>
                            {statementUrl && ` ${privacyUrlPrefix || "and"} `}
                            <a
                                className="membership-signup__subtitle__link"
                                {...href.openInNewWindowAttributes(privacyUrl)}
                            >
                                {privacyUrlText || "Privacy Policy"}
                            </a>
                        </>
                    )}
                </span>
            )}
            .
        </Text>
    </div>
);

interface MembershipFormFieldProps {
    field: FormConnectField;
    programName: string;
}

const MembershipFormField = (props: MembershipFormFieldProps) => {
    const {
        field: { name, label, placeholder, help, type, required },
        programName,
    } = props;

    const { register, setValue, getValues, errors } = useFormContext();

    const errorMessage = getValues(name) ? errors[name]?.message : null;

    return (
        <div className="membership-signup__fields__input" key={`membership-fields-${name}-index`}>
            <FormControl
                id={`membership-sign-up-${name}`}
                invalid={!!errorMessage}
                message={help}
                invalidMessage={errorMessage}
            >
                <Input
                    ref={register({
                        required: {
                            value: required,
                            message: "Required",
                        },
                    })}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    icon={
                        type === "barcode" ? (
                            <MembershipScanBarcode
                                membershipName={programName}
                                onSetMemberNumber={(code) =>
                                    setValue(name, code, { shouldValidate: true, shouldDirty: true })
                                }
                            />
                        ) : undefined
                    }
                    onClear={() => {
                        setValue(name, undefined, { shouldValidate: true, shouldDirty: false });
                    }}
                />
            </FormControl>
        </div>
    );
};
