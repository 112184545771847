import { getTableToken, getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { orderApi } from "../../order/orderApi";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { CustomerInfo } from "../types";
import { config } from "../../../common/config";
import { getAuthHeaders } from "../../../common/auth";

export const fetchABTests = async (customerInfo: CustomerInfo) => {
    if (config.enableABTests !== "1") return null;

    const token = getTableToken();
    const headers = token ? getTableTokenHeaders() : await getAuthHeaders();
    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/abtests", {
        method: "POST",
        body: JSON.stringify(customerInfo),
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return await response.json();
};
