import { createSelector } from "reselect";
import { AvailableFilters, CategoryIndexed, Range, SelectedFilters, TagValueMap } from "../types";
import { getAvailableFilters } from "./getAvailableFilters";
import { getSelectedFiltersByCategory } from "./getSelectedFiltersByCategory";
import { Category, Indexed, MenuItem, MenuLine, TagGroup } from "../../menudata";
import { getMenuItemPrices } from "./getMenuItemPriceRange";
import { getPriceResolver, PriceResolver } from "../../membership/selectors/getPriceResolver";
import { getVisibleMenuData, getActiveMenu } from "../../menu/selectors";

export const getMenuItemsForDisplay = createSelector(
    getVisibleMenuData,
    getActiveMenu,
    getAvailableFilters,
    getSelectedFiltersByCategory,
    getPriceResolver,
    function (menuData, menu, availableFilters, selectedFilters, priceResolver): CategoryIndexed<string[]> {
        const { items: menuItems, categories } = menuData!;
        return menu.categories.reduce((map, categoryId) => {
            map[categoryId] = getFilteredMenuItems(
                categories[categoryId],
                availableFilters[categoryId],
                selectedFilters[categoryId],
                menuItems,
                priceResolver
            );
            return map;
        }, {} as CategoryIndexed<string[]>);
    }
);

export const getMenuLinesForDisplay = createSelector(
    getVisibleMenuData,
    getActiveMenu,
    getAvailableFilters,
    getSelectedFiltersByCategory,
    getPriceResolver,
    function (menuData, menu, availableFilters, selectedFilters, priceResolver): MenuLine[] {
        const mapLines: MenuLine[] = [];
        const { items: menuItems, categories } = menuData!;
        menu.categories.forEach((categoryId, categoryIndex) => {
            const category = categories[categoryId];
            const items = getFilteredMenuItems(
                category,
                availableFilters[categoryId],
                selectedFilters[categoryId],
                menuItems,
                priceResolver
            );
            mapLines.push({
                type: category.type,
                displayName: category.displayName,
                categoryId,
                category,
                categoryIndex,
            });
            items.forEach((relatedItem, originalIndex) => {
                mapLines.push({
                    type: category.type,
                    categoryId,
                    category,
                    relatedItem,
                    categoryIndex,
                    originalIndex,
                });
            });
        });
        return mapLines;
    }
);

function getFilteredMenuItems(
    category: Category,
    availableFilters: AvailableFilters,
    selectedFilters: SelectedFilters,
    menuItems: Indexed<MenuItem>,
    priceResolver: PriceResolver
) {
    return category.menuItems.filter((menuItemId) =>
        matchesFilter(menuItems[menuItemId], availableFilters, selectedFilters, priceResolver)
    );
}

export function matchesFilter(
    menuItem: MenuItem,
    availableFilters: AvailableFilters,
    selectedFilters: SelectedFilters,
    priceResolver: PriceResolver
) {
    return (
        matchesTagFilter(menuItem, availableFilters, selectedFilters.tags) &&
        matchesPriceFilter(menuItem, selectedFilters.price, priceResolver)
    );
}

function matchesPriceFilter(menuItem: MenuItem, selectedRange: Range | undefined, priceResolver: PriceResolver) {
    const prices = getMenuItemPrices(menuItem, priceResolver);

    return (
        selectedRange === undefined || prices.some((price) => price >= selectedRange.min && price <= selectedRange.max)
    );
}

function matchesTagFilter(menuItem: MenuItem, availableFilters: AvailableFilters, selectedTags: TagValueMap) {
    return availableFilters.tagGroups.every((tagGroup) =>
        matchesTagGroupFilter(menuItem, tagGroup, selectedTags[tagGroup.id])
    );
}

function matchesTagGroupFilter(menuItem: MenuItem, tagGroup: TagGroup, selectedTags: string[] | undefined) {
    if (selectedTags === undefined || selectedTags.length === 0) {
        return true;
    }

    if (menuItem.tags === undefined || menuItem.tags.length === 0) {
        return false;
    }

    if (tagGroup.filterMode === "single") {
        return menuItem.tags.indexOf(selectedTags[0]) !== -1;
    }

    if (tagGroup.filterMode === "all") {
        return selectedTags.every((tagId) => menuItem.tags!.indexOf(tagId) !== -1);
    }

    if (tagGroup.filterMode === "any") {
        return selectedTags.some((tagId) => menuItem.tags!.indexOf(tagId) !== -1);
    }

    return false;
}
