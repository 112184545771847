import activeMembershipReducer, { ActiveMembershipState, fetchActions } from "./active";
import manageMembershipsReducer, { manageMembershipsActions, ManageMembershipsState } from "./manageMemberships";
import signupPromotionReducer, { signupPromotionActions, SignupPromotionState } from "./signupPromotion";
import { actionCreators as connectedActions, reducer as showConnectedConfirmation } from "./connected";
import { combineReducers } from "redux";

export default combineReducers({
    active: activeMembershipReducer,
    showConnectedConfirmation,
    manageMemberships: manageMembershipsReducer,
    signupPromotion: signupPromotionReducer,
});

export { fetchActions, connectedActions, manageMembershipsActions, signupPromotionActions };

export interface State {
    membership: {
        active: ActiveMembershipState;
        showConnectedConfirmation: boolean;
        manageMemberships: ManageMembershipsState;
        signupPromotion: SignupPromotionState;
    };
}
