import * as React from "react";
import { AvailableFilters, SelectedFilters } from "../../filter";
import { FilterModal } from "../../filter/components/FilterModal";
import { MenuCardContainer } from "../../menu";
import { LocationMenuData, Menu, MenuItem, Service, TagGroup } from "../../menudata";
import "../assets/ServicePage.scss";
import classNames from "classnames";
import { StatusNotifications } from "../../notifications/components/StatusNotifications";
import Swiper from "react-id-swiper";
import { MenuItemButton, MenuItemImage } from "../../menuitem";
import { EasyReorderItem, Party } from "../../order";
import { calculateOrderItemTotal } from "../../order/selectors/util/calculateOrderTotal";
import { ArrowRight, Map, UnavailableIcon } from "src/sharedComponents/assets/icons";
import { ConfirmAgeRestrictionModal } from "../../partyOnboarding/components/joinTable/ConfirmAgeRestrictionModal";
import * as smoothscroll from "smoothscroll-polyfill";
import { initDeviceStyle, isLightColor } from "src/common/shared";
import SVG from "react-inlinesvg";
import { Button, Divider, Text, TrackScroll } from "src/sharedComponents";
import { StatusBar } from "src/common/statusBar";
import { CSSTransition } from "react-transition-group";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { device, href, scrolling } from "src/common/experience";
import { PriceResolver } from "../../membership/selectors/getPriceResolver";
import { FeaturedItemContainer } from "src/features/featuredItem/containers/FeaturedItemContainer";
import { MembershipCallToAction } from "../../membership/components/MembershipCallToAction";
import { MembershipConnectedConfirmation } from "../../membership/components/MembershipConnectedConfirmation";
import { MenuSearchButtonContainer } from "src/features/menuSearch/containers/MenuSearchButtonContainer";
import { AnotherRound } from "src/features/orderAgain/components/AnotherRound";
import { AnotherRoundCarousel } from "src/features/orderAgain/components/AnotherRoundCarousel";
import { isPreview } from "../../preview";
import { HeaderBackgroundImage } from "src/sharedComponents/controls/headerBackgroundImage";
import { NewGroupTabWizard } from "../../groupTabs/components/NewGroupTabWizard";
import { GroupTabMenuCallToAction } from "src/features/groupTabs/components/GroupTabMenuCallToAction";
import { TakeawayScheduleButton } from "src/features/takeaway/components/TakeawayScheduleButton";
import { LocationAddressDetails } from "src/features/takeaway/reducers/takeawayOptions";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { OpenTableOrderingDisabledPage } from "src/features/openTable/components/OpenTableOrderingDisabledPage";
import { InviteToFlexTabCallToAction } from "src/features/openTable/components/FlexTab/InviteToFlexTabCallToAction";

export interface Props {
    title: string;
    service: Service;
    party: Party | null;
    menus: Menu[];
    dietaryInformationUrl?: string;
    userPreferenceFilters: AvailableFilters;
    selectedUserPreferenceFilters: SelectedFilters;
    locationMenuData: LocationMenuData;
    easyReorderItems: EasyReorderItem[];
    setServicePagePosition: (pagePosition: number) => void;
    servicePagePosition: number;
    applyFilters: (categoryId: string, tagGroups: TagGroup[], selectedFilters: SelectedFilters) => void;
    isActive: boolean;
    animatingService: boolean;
    priceResolver: PriceResolver;
    showConnectedConfirmation: boolean;
    showGroupTabMenuCTA: boolean;
    hasBanner: boolean;
    menuBackgroundImage?: string;
    isTakeaway: boolean;
    activeLocationAddressDetails?: LocationAddressDetails;
    showDemoModeBanner: () => void;
    showDemoBanner: boolean;
    isOpenTable: boolean;
    isOpenTableOrderingDisabled: boolean;
    showInviteToFlexTabCTA: boolean;
    trackAllergenNoticeClick: () => void;
    showOnSomeoneElsesFlexTabBanner: (tabOwnerName: string) => void;
    flexTabOwnerName: string;
    showOnSomeoneElsesFlexTab: boolean;
}

export interface State {
    modalIsOpen: boolean;
    showHeader: boolean;
}

export class ServicePage extends React.Component<Props, State> {
    pageRef: React.RefObject<HTMLDivElement>;
    headerRef: React.RefObject<HTMLDivElement>;
    pagePos: number;
    scrollPos: number;
    showHeaderBounce: number;

    static contextType = MenuDataLocaleContext;
    context!: MenuDataLocale;

    constructor(props: Props) {
        super(props);

        const { servicePagePosition } = props;

        this.state = {
            modalIsOpen: false,
            showHeader: this.shouldShowHeader(servicePagePosition),
        };

        this.pageRef = React.createRef();
        this.headerRef = React.createRef();
        this.pagePos = 0;
        this.scrollPos = 0;
        this.showHeaderBounce = 0;
        smoothscroll.polyfill();
        // to add another browser back before closing the menu
        window.history.pushState({ back: false }, "", null);
    }

    componentDidMount(): void {
        const {
            servicePagePosition,
            showDemoBanner,
            showDemoModeBanner,
            showOnSomeoneElsesFlexTab,
            showOnSomeoneElsesFlexTabBanner,
            flexTabOwnerName,
        } = this.props;
        initDeviceStyle.deviceHeight();
        setTimeout(() => scrolling.scrollTo({ top: servicePagePosition }));

        if (showDemoBanner) {
            showDemoModeBanner();
        } else if (showOnSomeoneElsesFlexTab) {
            showOnSomeoneElsesFlexTabBanner(flexTabOwnerName);
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.showHeaderBounce);
    }

    componentDidUpdate(prevProps: Props) {
        const { servicePagePosition } = this.props;
        if (prevProps.animatingService && !this.props.animatingService) {
            scrolling.scrollTo({ top: servicePagePosition });
        }
    }

    shouldShowHeader = (top: number) => {
        return top > window.innerHeight / 3.3 - 65 && !this.props.showConnectedConfirmation;
    };

    onScroll = () => {
        const top = scrolling.scrollTop();
        const showHeader = this.pageRef.current?.classList.contains("show-header");
        if (!showHeader && this.shouldShowHeader(top)) {
            this.pageRef.current?.classList.add("show-header");
            this.headerRef.current?.classList.add("show-header");
            this.showHeader(true);
        } else if (showHeader && !this.shouldShowHeader(top)) {
            this.pageRef.current?.classList.remove("show-header");
            this.headerRef.current?.classList.remove("show-header");
            this.showHeader(false);
        }
        this.scrollPos = scrolling.scrollTop();
    };

    showHeader = (shouldShow: boolean) => {
        window.clearTimeout(this.showHeaderBounce);
        this.showHeaderBounce = window.setTimeout(() => {
            this.setState({ showHeader: shouldShow });
        }, 50);
    };

    onEnter = () => scrolling.scrollTo({ top: this.pagePos });
    onExit = () => (this.pagePos = this.scrollPos);

    render() {
        const {
            service,
            dietaryInformationUrl,
            userPreferenceFilters,
            selectedUserPreferenceFilters,
            party,
            easyReorderItems,
            locationMenuData,
            isActive,
            showConnectedConfirmation,
            showGroupTabMenuCTA,
            hasBanner,
            menuBackgroundImage: menuBackground,
            isTakeaway,
            activeLocationAddressDetails,
            isOpenTable,
            isOpenTableOrderingDisabled,
            showInviteToFlexTabCTA,
            trackAllergenNoticeClick,
        } = this.props;
        const { modalIsOpen, showHeader } = this.state;

        const isLogoSVG = locationMenuData.logoUrl && locationMenuData.logoUrl.endsWith(".svg");
        const withHero = !!locationMenuData.heroImage.full;
        const { googleBusinessName, addressLine1, city, postcode } = activeLocationAddressDetails || {};
        const formattedLocationAddress = activeLocationAddressDetails && `${addressLine1}, ${city}`;

        const mapAppType = device.isIOS ? "apple" : "google";
        const addressQuery = `${addressLine1} ${city}, ${postcode}`;
        const locationQuery = googleBusinessName ? `${googleBusinessName} ${addressQuery}` : addressQuery;
        const encodedQuery = encodeURIComponent(locationQuery);
        const mapsAddress = `https://maps.${mapAppType}.com/?q=${encodedQuery}`;

        const pageClassName = classNames(
            "service-page",
            isActive && "active-page",
            { "show-header": showHeader || !withHero },
            !withHero && "no-hero"
        );

        const backgroundColor = showConnectedConfirmation
            ? isLightColor(locationMenuData.themeColor)
                ? "#fff"
                : "#000"
            : withHero && !showHeader
            ? "#000"
            : "#fff";

        const style = {
            "--menu-background-image": `
            linear-gradient(
                360deg, #FFFFFF 0%,
                rgba(255, 255, 255, 0.991615) 4.59%,
                rgba(255, 255, 255, 0.967585) 8.33%,
                rgba(255, 255, 255, 0.9296) 11.34%,
                rgba(255, 255, 255, 0.879348) 13.76%,
                rgba(255, 255, 255, 0.818519) 15.72%,
                rgba(255, 255, 255, 0.7488) 17.35%,
                rgba(255, 255, 255, 0.671881) 18.79%,
                rgba(255, 255, 255, 0.589452) 20.15%,
                rgba(255, 255, 255, 0.5032) 21.59%,
                rgba(255, 255, 255, 0.414815) 23.22%,
                rgba(255, 255, 255, 0.325985) 25.18%,
                rgba(255, 255, 255, 0.2384) 27.6%,
                rgba(255, 255, 255, 0.153748) 30.61%,
                rgba(255, 255, 255, 0.0737185) 34.35%,
                rgba(255, 255, 255, 0) 38.94%
            ),
            url("${menuBackground}")`,
        } as React.CSSProperties;

        return (
            <CSSTransition
                onEnter={this.onEnter}
                onExit={this.onExit}
                unmountOnExit
                in={isActive}
                classNames="party-wrapper-transition"
                timeout={150}
            >
                {!isOpenTableOrderingDisabled ? (
                    <div style={menuBackground ? style : undefined} className={menuBackground ? "menu-background" : ""}>
                        <div className={pageClassName} ref={this.pageRef}>
                            {isActive && !hasBanner && (
                                <StatusBar key={"service-page" + backgroundColor} backgroundColor={backgroundColor} />
                            )}
                            <TrackScroll
                                className={classNames(
                                    "service-page__content",
                                    "page__scroll-container",
                                    isLogoSVG && "with-logo",
                                    isPreview && "preview",
                                    hasBanner && "with-banner",
                                    menuBackground && "with-menu-background"
                                )}
                                updatedOnScroll={withHero ? this.onScroll : undefined}
                            >
                                {withHero && (
                                    <HeaderBackgroundImage
                                        className="service-page__image-header"
                                        url={menuBackground ? undefined : locationMenuData.heroImage.full}
                                        backgroundGradientDecimal={menuBackground ? 0 : 0.3}
                                    >
                                        {isLogoSVG ? (
                                            <SVG src={locationMenuData.logoUrl} />
                                        ) : (
                                            <Text preset="title-32" mode="extra-bold">
                                                {locationMenuData.title}
                                            </Text>
                                        )}
                                        {isTakeaway && formattedLocationAddress && (
                                            <TappableAnchor
                                                className="service-page__location-address"
                                                {...href.openInNewWindowAttributes(mapsAddress)}
                                            >
                                                <Text
                                                    preset="g-14"
                                                    mode={["bold", "block"]}
                                                    className="service-page__location-address__formatted"
                                                >
                                                    <Map />
                                                    {formattedLocationAddress}
                                                </Text>
                                            </TappableAnchor>
                                        )}
                                        {!(showHeader && !showConnectedConfirmation) && (
                                            <div
                                                className={classNames("service-page__top", {
                                                    "service-page__top--banner": hasBanner,
                                                    "service-page__top--right": !dietaryInformationUrl,
                                                })}
                                            >
                                                {dietaryInformationUrl && (
                                                    <a {...href.openInNewWindowAttributes(dietaryInformationUrl)}>
                                                        <Button
                                                            mode="solidinverted"
                                                            className="service-page__top__allergen"
                                                            rightIcon={<ArrowRight />}
                                                            onClick={trackAllergenNoticeClick}
                                                        >
                                                            Allergen information
                                                        </Button>
                                                    </a>
                                                )}
                                                <MenuSearchButtonContainer
                                                    className="service-page__top__search"
                                                    innerClassName="service-page__top__search__inner"
                                                />
                                            </div>
                                        )}
                                    </HeaderBackgroundImage>
                                )}
                                <div className="service-page__content__items">
                                    {isTakeaway && (
                                        <div className="service-page__scheduling">
                                            <TakeawayScheduleButton />
                                        </div>
                                    )}
                                    {showInviteToFlexTabCTA && (
                                        <div className="service-page__cta">
                                            <InviteToFlexTabCallToAction />
                                        </div>
                                    )}
                                    <div className="service-page__cta">
                                        {showGroupTabMenuCTA ? (
                                            <GroupTabMenuCallToAction />
                                        ) : (
                                            <MembershipCallToAction instanceName="ServicePage" />
                                        )}
                                    </div>
                                    <StatusNotifications />
                                    {easyReorderItems.length > 0 && (
                                        <>
                                            <div className="service-page__favourite">
                                                <Text
                                                    preset="title-28"
                                                    mode="extra-bold"
                                                    className="menu-card__title service-page__favourite__title"
                                                >
                                                    Order again
                                                </Text>
                                                <div className="service-page__favourite__container">
                                                    {this.renderSwiper()}
                                                </div>
                                            </div>
                                            <Divider />
                                        </>
                                    )}
                                    {isOpenTable && <AnotherRoundCarousel />}
                                    <FeaturedItemContainer />
                                    <ul className={classNames("service-page__menu-card-list", !party && "inactive")}>
                                        {service.menus.map(this.renderMenu)}
                                    </ul>
                                    {!!party && (
                                        <Text preset="g-12" className="service-page__surcharge-message">
                                            Venue and/or payment surcharges may apply, please review all charges before
                                            submitting your order.
                                            {this.context.locale === "en-GB" && " Adults need around 2,000 kcal a day."}
                                        </Text>
                                    )}
                                </div>
                            </TrackScroll>
                            {userPreferenceFilters && (
                                <FilterModal
                                    availableFilters={userPreferenceFilters}
                                    selectedFilters={selectedUserPreferenceFilters}
                                    modalIsOpen={modalIsOpen}
                                    handleOnClick={this.handleFilter}
                                    closeModal={this.closeFilterModal}
                                    handleApplyFilters={this.handleApplyFilters}
                                />
                            )}
                            <ConfirmAgeRestrictionModal />
                        </div>
                        <div
                            className={classNames(
                                "service-page__header",
                                "bottom-shadow",
                                { "show-header": (showHeader || !withHero) && !showConnectedConfirmation },
                                hasBanner && "with-banner"
                            )}
                            ref={this.headerRef}
                        >
                            {isLogoSVG ? (
                                <SVG src={locationMenuData.logoUrl} />
                            ) : (
                                <Text preset="g-16" mode="bold">
                                    {locationMenuData.title}
                                </Text>
                            )}
                            {(showHeader || !withHero) && !showConnectedConfirmation && (
                                <MenuSearchButtonContainer
                                    className={classNames(
                                        "service-page__top__search",
                                        "service-page__top__search--nav"
                                    )}
                                />
                            )}
                        </div>
                        <MembershipConnectedConfirmation />
                        <AnotherRound />
                        <NewGroupTabWizard />
                    </div>
                ) : (
                    <OpenTableOrderingDisabledPage />
                )}
            </CSSTransition>
        );
    }

    renderSwiper = () => {
        const { easyReorderItems } = this.props;
        return (
            <Swiper
                slidesPerView={Math.max(2, Math.floor(window.innerWidth / 174))}
                spaceBetween={7}
                allowTouchMove={easyReorderItems.length > 2}
                freeMode={true}
                freeModeMomentumRatio={0.3}
                freeModeSticky={true}
                width={window.innerWidth - 40}
            >
                {easyReorderItems.map((item, index) => this.renderFavourite(item, index))}
            </Swiper>
        );
    };

    renderFavourite = (item: EasyReorderItem, index: number) => {
        const { locationMenuData, priceResolver } = this.props;
        const menuItem: MenuItem = locationMenuData.items[item.itemId];
        const disabled = menuItem.available === false;
        const price = calculateOrderItemTotal(menuItem, item, priceResolver);
        return (
            <div
                className={classNames("service-page__favourite__container__item", disabled && "unavailable")}
                key={"favourite" + index}
            >
                <MenuItemButton
                    menuItemId={item.itemId}
                    preselect
                    disabled={disabled}
                    className="service-page__favourite__container__item__link"
                >
                    <MenuItemImage
                        className="service-page__favourite__container__item__image"
                        images={menuItem.images}
                        name="thumb"
                    />
                </MenuItemButton>
                <Text
                    preset="g-14"
                    mode="bold"
                    value={menuItem.displayName}
                    className="service-page__favourite__container__item__name"
                />
                <Text preset="g-14" className="service-page__favourite__container__item__cost">
                    {disabled ? (
                        <div className="service-page__favourite__container__item__unavailable">
                            <UnavailableIcon /> Unavailable
                        </div>
                    ) : (
                        <>{this.context.formatCurrency(price)}</>
                    )}
                </Text>
            </div>
        );
    };

    renderMenu = (menuId: string, index: number) => (
        <React.Fragment key={menuId}>
            {index > 0 && <Divider />}
            <li>
                <MenuCardContainer
                    menuId={menuId}
                    menu={this.props.menus[index]}
                    onBeforeNavigate={this.handleOnClick}
                />
            </li>
        </React.Fragment>
    );

    closeFilterModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };

    handleApplyFilters = (tagGroups: TagGroup[], selectedFilters: SelectedFilters) => {
        this.props.applyFilters("", tagGroups, selectedFilters);
        this.setState({
            modalIsOpen: false,
        });
    };

    handleFilter = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen });
    };

    handleOnClick = () => {
        const { setServicePagePosition } = this.props;
        setServicePagePosition(scrolling.scrollTop());
    };
}
