import { trackedEvent } from "../../../common/events/reduxEventTracking";

export enum TypeKeys {
    AVAILABILITY_WARNING_DISPLAYED = "AVAILABILITY_WARNING/DISPLAYED",
}

export const actionCreators = {
    trackAvailability: (availabilityData: string) =>
        trackedEvent({ type: TypeKeys.AVAILABILITY_WARNING_DISPLAYED, availabilityData }),
};

export type AvailabilityItemElementType = "item" | "category";

export type AvailabilityTrackingAction = {
    [itemId: string]: AvailabilityTrackingItem;
};

export type AvailabilityTrackingItem = {
    item_type: AvailabilityItemElementType;
    availability: string;
    name: string;
};
