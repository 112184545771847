import "../assets/ModifierMultiSelectListItem.scss";
import React, { useCallback, useEffect, useRef } from "react";
import { ModifierListItemDetails } from "./ModifierListItemDetails";
import { ModifierOption } from "../../menudata";
import { QuantitySelector } from "src/sharedComponents/controls/quantitySelector";
import { TappableDiv } from "../../../sharedComponents/common/tappable";
import { animateListItemBackground } from "../helpers";
import { ListItemBackground } from "../component/ListItemBackground";
import { OrderItemSelectedNestedModiferDisplayData } from "..";
import { NestedModifiersView } from "src/features/menuitem/components/NestedModifiersView";
import { useSelector } from "react-redux";
import { getActiveServiceMenuItemIds } from "src/features/menu/selectors";
import { getOptionItemReferenceNotInActiveService } from "src/features/menuitem/helpers";

interface Props {
    listItem: ModifierOption;
    quantity: number;
    remainingModifierGroupLimit: number;
    onListItemIncrement: () => void;
    onListItemDecrement: () => void;
    canAddOptions: boolean;
    disabled: boolean;
    maxSelectionPerOption: number;
    selectedNestedModifier?: OrderItemSelectedNestedModiferDisplayData[][];
    onNestedModifierEdit?: () => void;
    isUnlimited?: boolean;
}

export const MODIFIER_OPTION_LIMIT = 99;

export const ModifierMultiSelectListItem = ({
    listItem,
    quantity,
    remainingModifierGroupLimit,
    onListItemIncrement,
    onListItemDecrement,
    canAddOptions,
    disabled,
    maxSelectionPerOption,
    selectedNestedModifier,
    onNestedModifierEdit,
    isUnlimited,
}: Props) => {
    const backgroundRef = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<Animation | null>(null);

    const totalQuantityRemaining = quantity + remainingModifierGroupLimit;
    const maxValue = isUnlimited
        ? maxSelectionPerOption || MODIFIER_OPTION_LIMIT
        : Math.min(maxSelectionPerOption, totalQuantityRemaining);

    const nestedOptions = selectedNestedModifier ? selectedNestedModifier[listItem.originalIndex] : undefined;

    const activeServiceMenuItemIds = useSelector(getActiveServiceMenuItemIds);
    const hide = getOptionItemReferenceNotInActiveService(listItem, activeServiceMenuItemIds);

    const onIncrement = useCallback(() => {
        animateListItemBackground(backgroundRef, animationRef);
        onListItemIncrement();
    }, [onListItemIncrement]);

    const onDecrement = useCallback(() => {
        animateListItemBackground(backgroundRef, animationRef);
        onListItemDecrement();
    }, [onListItemDecrement]);

    useEffect(
        () => () => {
            if (animationRef.current) {
                animationRef.current.cancel();
            }
        },
        [animationRef]
    );

    if (hide) {
        return null;
    }

    return (
        <>
            <div className="modifier-multi-select-list-item">
                <div className="modifier-multi-select-list-item__container">
                    <TappableDiv
                        className="modifier-multi-select-list-item__details"
                        onTap={onIncrement}
                        disabled={!canAddOptions || quantity >= maxValue}
                    >
                        <ModifierListItemDetails listItem={listItem} disabled={disabled} />
                    </TappableDiv>
                    <QuantitySelector
                        quantity={quantity}
                        minValue={0}
                        maxValue={maxValue}
                        shouldAnimateOnActive={true}
                        onIncrement={onIncrement}
                        onDecrement={onDecrement}
                        disableIncrementOverride={!canAddOptions}
                    />
                </div>
                <ListItemBackground ref={backgroundRef} />
            </div>
            {nestedOptions && (
                <div className="modifier-multi-select-list-item__nested-options">
                    <NestedModifiersView nestedOptions={nestedOptions} onChangeSelection={onNestedModifierEdit} />
                </div>
            )}
        </>
    );
};
