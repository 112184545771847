import { AppDispatch } from "../../index";
import { googlePay } from "../../../common/experience";
import { GooglePayPaymentMethod } from "../../../common/payment";
import { getGooglePayAvailableOperation } from "../operations";

export const getGooglePayAvailable = (dispatch: AppDispatch, googlePayPaymentMethod: GooglePayPaymentMethod) =>
    getGooglePayAvailableOperation.invoke(
        () =>
            googlePay.isAvailable(googlePayPaymentMethod, (action) =>
                getGooglePayAvailableOperation.trackEvent(action, dispatch)
            ),
        dispatch
    );
