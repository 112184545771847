import React, { useMemo } from "react";

import { useDispatch } from "react-redux";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { getOfferData as getOfferDataAction } from "src/features/offers/actions";
import { IdentifierScope } from "src/features/offers";
import { offerClaimFlowWizard } from "src/features/offers/wizard";
import { Text } from "src/sharedComponents";
import { OfferModal } from "src/features/offers/components/OfferModal";
import { PresentIcon } from "src/sharedComponents/assets/icons";
import { BillAdjustment } from "src/features/order/types";
import { simplePlural } from "src/common/strings";
import { StampCardHistoryDetails } from "../types";

interface Props {
    locationId: string;
    cepStampsAdjustments: BillAdjustment[];
}

export const OrderHistoryStampCardButton = ({ locationId, cepStampsAdjustments }: Props) => {
    const dispatch = useDispatch();

    const groupedStampCards = useMemo(
        () =>
            cepStampsAdjustments.reduce((byName, adjustment) => {
                if (!byName[adjustment.name]) {
                    byName[adjustment.name] = {
                        name: adjustment.name,
                        offerId: adjustment.promotionId!,
                        stamps: 0,
                    };
                }
                ++byName[adjustment.name].stamps;
                return byName;
            }, {} as { [x: string]: StampCardHistoryDetails }),
        [cepStampsAdjustments]
    );

    return (
        <>
            {Object.values(groupedStampCards).map((stampCard: StampCardHistoryDetails) => (
                <TappableDiv
                    key={stampCard.name}
                    className="history-item__section history-item__marketing-opt-in"
                    onTap={() => {
                        dispatch(
                            getOfferDataAction(locationId, stampCard.offerId, IdentifierScope.LOCATION, () =>
                                dispatch(offerClaimFlowWizard.actionCreators.start("OfferPage"))
                            )
                        );
                    }}
                >
                    <Text preset="g-16" mode={["bold", "block"]}>
                        Stamp card details
                        <Text preset="g-14">
                            You earned {stampCard.stamps} stamp{simplePlural(stampCard.stamps)} with this order.
                        </Text>
                    </Text>
                    <Text preset="g-14" className="history-item__marketing-opt-in--icon">
                        <PresentIcon />
                    </Text>
                    <OfferModal
                        parentSelector={() => document.querySelector(".ReactModal__ProfileDrawer") ?? document.body}
                    />
                </TappableDiv>
            ))}
        </>
    );
};
