import { AppDispatch, AppState } from "../..";
import { rejoiningTableOperation } from "../operations";
import { disconnectParty, joinParty, verifyParty } from "../api";
import { deleteTableToken, getTableTokenPartyInfo, saveTableToken } from "../persistence/tableToken";
import { easyReorderActions } from "../../easyReorderItem/reducers";
import { profileActionCreators } from "../../accountmenu";
import { getRestaurantFlagsOperation } from "../../order/operations";
import { resetOrderingState } from "../../order/actions/resetOrderingState";
import { config } from "src/common/config";
import { updateMenuAdUnits } from "src/features/menudata/actions/updateMenuAdUnits";
import { getGroupTabAction } from "src/features/groupTabs/actions/getGroupTabAction";
import { getABTests } from "../../abtests/actions/getABTests";
import { closeAllModals, MIN_JOIN_LOADING } from "./helper";
import { getParty, PartyType } from "../../order";
import { replace } from "connected-react-router";
import { fetchMealOrOrderHistory } from "src/features/orderHistoryData/actions/fetchMealHistory";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { waitMinimum } from "src/common/shared";
import { onboardingActionCreators } from "../reducers";
import { isBrandedVenueLoaderEnabled } from "../util/isBrandedVenueLoaderEnabled";
import { actionCreators as takeawayOptionActions } from "src/features/takeaway/reducers/takeawayOptions";
import { actionCreators as openTableActionCreators } from "src/features/openTable/reducers";
import { setTestingFeaturesIfEnabled } from "./setTestingFeatures";
import { getOpenTableMemberActivity } from "src/features/openTable/selectors";
import { getCurrentMemberActivity } from "../components/JoinTablePage";
import { getAvailableOffersAction } from "src/features/offers/actions/getAvailableOffers";
import { getShowPreviousPartyAnotherRound } from "src/features/orderAgain/selectors";
import { getAnotherRound } from "src/features/orderAgain/actions/getAnotherRound";
import { getLastTableData } from "src/features/compliance/persistence/lastTableData";
import { getAppInsights } from "src/features/analytics";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

// rejoin a table using an existing 'tableToken' (party JWT)
// This occurs when the user reloads the page/app while in a party
export function rejoinTable(tableToken: string) {
    return rejoiningTableOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(resetOrderingState());
            dispatch(closeAllModals());
            await disconnectParty(dispatch, false, "none");

            const { easyReorderItems, profile, restaurantFlags, takeawayContext, openTableContext } = await verifyParty(
                tableToken
            );

            dispatch(setTestingFeaturesIfEnabled());
            dispatch(easyReorderActions.getEasyReorderItem(easyReorderItems));
            dispatch(profileActionCreators.fetchSuccess(profile));
            dispatch(getRestaurantFlagsOperation.actionCreators.completed(restaurantFlags));
            dispatch(takeawayOptionActions.setTakeawayDetails(takeawayContext));
            dispatch(getABTests());

            saveTableToken(tableToken);

            if (isBrandedVenueLoaderEnabled()) {
                await waitMinimum(joinParty(getState, dispatch, tableToken, openTableContext), MIN_JOIN_LOADING);
            } else {
                await joinParty(getState, dispatch, tableToken, openTableContext);
            }

            dispatch(getAvailableOffersAction());

            const party = getParty(getState());

            if (party?.type === PartyType.PAYONLY) {
                // Purely belt and braces. We should never join a Pay Only party with regular rejoinTable
                // but if we do then make sure we end up at /pay-only otherwise the app will crash
                dispatch(replace("/pay-only"));
            }

            if (party?.type === PartyType.MULTIUSER) {
                const memberActivity = getOpenTableMemberActivity(getState());
                const currentMemberActivity = getCurrentMemberActivity(memberActivity, party.id);
                const showPreviousePartyAnotherRound = getShowPreviousPartyAnotherRound(getState());

                if (showPreviousePartyAnotherRound) {
                    await getAnotherRound(dispatch);
                }

                dispatch(openTableActionCreators.setActivity(currentMemberActivity));
            }

            if (!openTableContext) {
                dispatch(getGroupTabAction());
            }

            if (config.enableAdUnits === "1") {
                dispatch(updateMenuAdUnits());
            }
        } catch (err) {
            if (err instanceof Error && err.message === "MemberAlreadyPaid") {
                const tableTokenInfo = getTableTokenPartyInfo(tableToken);

                if (tableTokenInfo) {
                    dispatch(fetchMealOrOrderHistory(tableTokenInfo.partyId));
                }

                const { tableLabel, actualTable } = getLastTableData() ?? {};

                dispatch(
                    showModalMessage(
                        modalMessages.feedbackSurvey({
                            tableLabel,
                            tableNumber: actualTable,
                        })
                    )
                );
            }

            getAppInsights()?.trackException({
                exception: err as Error,
                severityLevel: SeverityLevel.Error,
            })
            deleteTableToken("failed_rejoin");
            dispatch(onboardingActionCreators.clearOnboardingImages());
            dispatch(openTableActionCreators.resetActivity());

            throw err;
        }
    });
}
