import * as React from "react";
import { ReactNode } from "react";
import { connect } from "react-redux";
import { State, OperationStatus } from "../reducer";
import { getOperationStatus, getOperationError } from "../utils";

export interface OperationViewProps {
    operation: string;
    children: (status: OperationStatus, error?: any) => ReactNode;
}

interface InnerProps extends OperationViewProps {
    status: OperationStatus;
    error?: any;
}

export class OperationViewInner extends React.Component<InnerProps> {
    render() {
        const { children, status, error } = this.props;

        return children(status, error);
    }
}

const mapStateToProps = (state: State, props: OperationViewProps): InnerProps => ({
    ...props,
    status: getOperationStatus(state, props.operation),
    error: getOperationError(state, props.operation),
});

export const OperationView = connect(mapStateToProps)(OperationViewInner);
