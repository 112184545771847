import { fetchSurveyDetailsOperation } from "../reducers";
import { surveyDetails } from "../api/surveyDetails";
import { actionCreators as surveyActionCreators } from "../reducers/survey";
import { AppDispatch } from "../..";

export const fetchSurveyDetails = (dispatch: AppDispatch, locationId?: string, partyId?: string) =>
    fetchSurveyDetailsOperation.invoke(async () => {
        const fetchedSurveyDetails = await surveyDetails(locationId, partyId);
        dispatch(surveyActionCreators.setDetails(fetchedSurveyDetails));

        return fetchedSurveyDetails;
    }, dispatch);
