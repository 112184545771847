import { orderApi } from "../../order/orderApi";
import { removeVenueCauseOperation } from "../operations";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

const removeVenueCauseApi = async () => {
    const headers = getTableTokenHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/party/remove-venue-cause", {
        method: "DELETE",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return response.status;
};

export function removeVenueCause() {
    return removeVenueCauseOperation.getThunk(async () => {
        await removeVenueCauseApi();
    });
}
