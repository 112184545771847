import { getAuthHeaders } from "src/common/auth";
import { orderApi, reattemptStrategies } from "src/features/order/orderApi";
import { MembershipState, MembershipStatus } from "../types/MembershipState";
import { FetchError } from "src/features/order/orderApi/FetchError";
import { config } from "src/common/config";

export async function getMembershipState(locationId: string) {
    const response = await orderApi.send(
        `/membership/${encodeURIComponent(locationId)}`,
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw new FetchError(response);
    }

    const data = (await response.json()) as MembershipState[];

    if (config.supportOnlyOAuthMembership === "1") {
        return data.filter((d) => d.status !== MembershipStatus.AVAILABLE || d.authentication.method === "oauth");
    }

    return data;
}
