import "./PayOnlySelectAmount.scss";

import React, { useContext, useEffect, useMemo } from "react";
import { FormControl, MaskedInput, Text, masks } from "src/sharedComponents";
import { useDispatch } from "react-redux";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { actionCreators } from "../reducers";
import { Controller, useForm } from "react-hook-form";
import { validationRules } from "../../../common/validation";

const MIN_AMOUNT = 0.5;

export const PayOnlySelectAmount = () => {
    const menuDataLocale = useContext(MenuDataLocaleContext)!;

    const {
        thousandsSeparatorSymbol,
        decimalSymbol: decimalSeparatorSymbol,
        currencySymbol,
    } = useMemo(() => menuDataLocale.getCurrencySymbols(), [menuDataLocale]);

    const currencyMask = useMemo(
        () =>
            masks.currency({
                thousandsSeparatorSymbol,
                decimalSeparatorSymbol,
                max: 1000000,
                padDecimalPlaces: true,
            }),
        [thousandsSeparatorSymbol, decimalSeparatorSymbol]
    );

    const {
        formState: { isValid },
        errors,
        control,
        watch,
    } = useForm({
        mode: "onChange",
    });

    const watchAmount = watch("amount");

    const dispatch = useDispatch();

    useEffect(() => {
        if (isValid) {
            const amount = parseFloat(watchAmount);
            if (!isNaN(amount)) {
                dispatch(actionCreators.setAmount(amount));
                return;
            }
        }
        dispatch(actionCreators.setAmount(0));
    }, [dispatch, isValid, watchAmount]);

    return (
        <div className="pay-only-select-amount">
            <div className="pay-only-select-split-options__title">
                <Text preset="title-28" mode="bold">
                    Select amount
                </Text>
            </div>
            <div className="pay-only-select-amount__input">
                <FormControl
                    id="select-amount"
                    invalid={!!errors.amount?.message}
                    invalidMessage={errors.amount?.message}
                >
                    <Controller
                        control={control}
                        name="amount"
                        defaultValue=""
                        rules={{
                            ...validationRules.amountLimit({
                                minValue: {
                                    val: MIN_AMOUNT,
                                    display: menuDataLocale.formatCurrency(MIN_AMOUNT),
                                },
                            }),
                        }}
                        render={({ value, onChange }) => (
                            <MaskedInput
                                size="lg"
                                inputMode="decimal"
                                label="Payment amount"
                                allowClear={!!watchAmount}
                                prefix={currencySymbol}
                                value={value}
                                onAccept={(_, mask) => onChange(mask.value)}
                                onClear={() => onChange("")}
                                {...currencyMask}
                            />
                        )}
                    />
                </FormControl>
            </div>
        </div>
    );
};
