import { profileActionCreators } from "../reducers";
import { AppDispatch, AppState } from "../..";
import * as accountApi from "../api/accountApi";
import { fetchProfileOperation } from "../operations";

const fetchProfileInternal = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    success?: () => void,
    failure?: () => void
) => {
    const {
        profile: { status },
    } = getState();

    if (status === "processing") return;

    try {
        const profile = await accountApi.fetchProfile();

        dispatch(profileActionCreators.fetchSuccess(profile));

        if (success) {
            success();
        }
    } catch {
        if (failure) {
            failure();
        }
    }
};

export const fetchProfile = (success?: () => void, failure?: () => void) =>
    fetchProfileOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        fetchProfileInternal(dispatch, getState, success, failure)
    );

export const fetchProfileAwaitable = (
    dispatch: AppDispatch,
    getState: () => AppState,
    success?: () => void,
    failure?: () => void
) => fetchProfileOperation.invoke(() => fetchProfileInternal(dispatch, getState, success, failure), dispatch);
