import * as React from "react";
import { FC } from "react";
import "../assets/LegalPage.scss";
import { LegalPage } from "./LegalPage";

interface Props {
    goBack?: () => void;
}

export const CookiePolicy: FC<Props> = ({ goBack }) => (
    <LegalPage title="Cookie Policy" goBack={goBack} relativeUrl="legal/app-meandu-cookie-policy" />
);
