import { MemberActivity, OrderStatus } from "../../order";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { LocationMarkerClaim } from "src/features/partyOnboarding/types";

export interface OpenTableState {
    memberActivity: MemberActivity;
    memberActiveOrderStatus?: OrderStatus;
    hasFlexTabBehavior?: boolean;
    locationMarkerClaims?: LocationMarkerClaim[];
    openInviteToFlexTabModal?: boolean;
}

export enum TypeKeys {
    SET_ACTIVITY = "OPEN_TABLE/SET_ACTIVITY",
    RESET_ACTIVITY = "OPEN_TABLE/RESET_ACTIVITY",
    SET_ACTIVE_ORDER_STATUS = "OPEN_TABLE/SET_ACTIVE_ORDER_STATUS",
    SET_HAS_FLEX_TAB_BEHAVIOR = "OPEN_TABLE/SET_HAS_FLEX_TAB_BEHAVIOR",
    SET_LOCATION_MARKER_CLAIMS = "OPEN_TABLE/SET_LOCATION_MARKER_CLAIMS",
    RESET_LOCATION_MARKER_CLAIMS = "OPEN_TABLE/RESET_LOCATION_MARKER_CLAIMS",
    SET_OPEN_INVITE_TO_FLEX_TAB_MODAL = "OPEN_TABLE/SET_OPEN_INVITE_TO_FLEX_TAB_MODAL",
}

export const actionCreators = {
    setActivity: (activity: MemberActivity, shouldStartActivity?: boolean) => ({
        type: TypeKeys.SET_ACTIVITY,
        activity,
        shouldStartActivity,
    }),
    resetActivity: () => ({
        type: TypeKeys.RESET_ACTIVITY,
    }),
    setMemberActiveOrderStatus: (memberActiveOrderStatus?: OrderStatus) => ({
        type: TypeKeys.SET_ACTIVE_ORDER_STATUS,
        memberActiveOrderStatus,
    }),
    trackActivitySelection: (activity: MemberActivity) =>
        trackedEvent({
            type: "PARTY/LOBBY_RESPONSE",
            response: activity === MemberActivity.Ordering ? "Order" : "Pay",
        }),
    setHasFlexTabBehavior: (hasFlexTabBehavior?: boolean) => ({
        type: TypeKeys.SET_HAS_FLEX_TAB_BEHAVIOR,
        hasFlexTabBehavior,
    }),
    setLocationMarkerClaims: (locationMarkerClaims?: LocationMarkerClaim[]) => ({
        type: TypeKeys.SET_LOCATION_MARKER_CLAIMS,
        locationMarkerClaims,
    }),
    resetLocationMarkerClaims: () => ({
        type: TypeKeys.RESET_LOCATION_MARKER_CLAIMS,
    }),
    setOpenInviteToFlexTabModal: (openInviteToFlexTabModal: boolean) => ({
        type: TypeKeys.SET_OPEN_INVITE_TO_FLEX_TAB_MODAL,
        openInviteToFlexTabModal,
    }),
    trackJoinFlexTab: (flex_join_code: string, joined_with_qr: boolean) =>
        trackedEvent({ type: "PARTY/FLEX_TAB/JOIN", flex_join_code, joined_with_qr }),
};

type SetActivityAction = { type: TypeKeys.SET_ACTIVITY; activity: MemberActivity; shouldStartActivity?: boolean };
type ResetActivityAction = { type: TypeKeys.RESET_ACTIVITY };
type SetMemberActiveOrderStatusAction = {
    type: TypeKeys.SET_ACTIVE_ORDER_STATUS;
    memberActiveOrderStatus: OrderStatus;
};
type SetHasFlexTabBehaviorAction = {
    type: TypeKeys.SET_HAS_FLEX_TAB_BEHAVIOR;
    hasFlexTabBehavior?: boolean;
};

type SetLocationMarkerClaims = {
    type: TypeKeys.SET_LOCATION_MARKER_CLAIMS;
    locationMarkerClaims?: LocationMarkerClaim[];
};

type ResetLocationMarkerClaimsAction = { type: TypeKeys.RESET_LOCATION_MARKER_CLAIMS };

type SetShowInviteToFlexTabModal = {
    type: TypeKeys.SET_OPEN_INVITE_TO_FLEX_TAB_MODAL;
    openInviteToFlexTabModal: boolean;
};

export type OpenTableAction =
    | SetActivityAction
    | ResetActivityAction
    | SetMemberActiveOrderStatusAction
    | SetHasFlexTabBehaviorAction
    | SetLocationMarkerClaims
    | ResetLocationMarkerClaimsAction
    | SetShowInviteToFlexTabModal;

const initialState: OpenTableState = {
    memberActivity: MemberActivity.None,
    memberActiveOrderStatus: OrderStatus.OPEN,
};

export function reducer(state: OpenTableState = initialState, action: OpenTableAction): OpenTableState {
    if (action.type === TypeKeys.SET_ACTIVITY) {
        return {
            ...state,
            memberActivity: action.activity,
        };
    }

    if (action.type === TypeKeys.SET_ACTIVE_ORDER_STATUS) {
        return {
            ...state,
            memberActiveOrderStatus: action.memberActiveOrderStatus,
        };
    }

    if (action.type === TypeKeys.SET_HAS_FLEX_TAB_BEHAVIOR) {
        return {
            ...state,
            hasFlexTabBehavior: action.hasFlexTabBehavior,
        };
    }

    if (action.type === TypeKeys.SET_LOCATION_MARKER_CLAIMS) {
        return {
            ...state,
            locationMarkerClaims: action.locationMarkerClaims,
        };
    }

    if (action.type === TypeKeys.RESET_ACTIVITY) {
        return initialState;
    }

    if (action.type === TypeKeys.RESET_LOCATION_MARKER_CLAIMS) {
        return {
            ...state,
            locationMarkerClaims: undefined,
        };
    }

    if (action.type === TypeKeys.SET_OPEN_INVITE_TO_FLEX_TAB_MODAL) {
        return {
            ...state,
            openInviteToFlexTabModal: action.openInviteToFlexTabModal,
        };
    }

    return state;
}
