import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { AppState } from "../..";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { IReduxActionPlugin } from "./IReduxActionPlugin";
import { AnyAction } from "redux";
import { auth } from "../../signup";
import { IUserContext } from "@microsoft/applicationinsights-common";
import { findPropertiesPlugin } from "../util/findAppInsightsPlugin";

export class AuthenticatedUserPlugin extends BaseTelemetryPlugin implements IReduxActionPlugin {
    public identifier = "MeanduAuthenticatedUserPlugin";

    private user: IUserContext | undefined = undefined;

    initialize(
        config: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(config, core, extensions, pluginChain);

        const propertiesPlugin = findPropertiesPlugin(extensions);

        if (!propertiesPlugin) {
            return;
        }

        this.user = propertiesPlugin.context.user;

        setUserContext(this.user);

        auth.onAuthStateChanged(() => setUserContext(this.user!));
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        event.data = {
            ...event.data,
            ...{
                isAnonymous: auth.getIsAnonymous(),
                "member.id": auth.getId(),
            },
        };

        this.processNext(event, itemCtx!);
    }

    handleReduxAction(action: AnyAction, state: () => AppState) {
        if (!this.user) {
            return;
        }

        if (action.type === "ONBOARDING/VERIFY" && (action as any).operation === "ONBOARDING/VERIFY") {
            setUserContext(this.user);
        }
    }
}

function setUserContext(user: IUserContext) {
    if (auth.isAuthenticated()) {
        const id = auth.getId();
        const idWithoutInvalidCharacters = id.replace(/[,;= |]/g, ":");

        user.setAuthenticatedUserContext(idWithoutInvalidCharacters);
    } else {
        user.clearAuthenticatedUserContext();
    }
}
