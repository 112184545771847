import React from "react";
import { Text } from "src/sharedComponents";
import { useSelector } from "react-redux";
import { getFormattedTabMandatoryGratuity } from "../../payment/selectors";
import { getTabTypeName } from "../selectors/activeGroupTab";

interface Props {
    className?: string;
}

export const TabGratuityDescription = ({ className }: Props) => {
    const formattedGratuity = useSelector(getFormattedTabMandatoryGratuity);
    const tabTypeName = useSelector(getTabTypeName);

    if (!formattedGratuity) return null;

    return (
        <Text preset="g-14" mode="block" className={className}>
            This venue requires that a {formattedGratuity} gratuity will be added to all orders using a {tabTypeName}.
        </Text>
    );
};
