import React from "react";
import "../assets/OfferTile.scss";
import { OfferData } from "../types";
import { Chip, Text } from "src/sharedComponents";
import { BrandedCouponIcon } from "src/sharedComponents/assets/icons";
import classNames from "classnames";
import { TappableDiv } from "src/sharedComponents/common/tappable";

interface Props {
    offer: OfferData;
    mode?: "default" | "small";
    onDetailsClick?: () => void;
    onOrderClick?: () => void;
}

export const OfferTile = ({ offer, mode = "default", onDetailsClick, onOrderClick }: Props) => {
    return (
        <TappableDiv onClick={onDetailsClick} className={classNames("offer-tile", `offer-tile__mode--${mode}`)}>
            <section className="offer-tile__image-section">
                <div className="offer-tile__fallback-image">
                    <BrandedCouponIcon />
                </div>
                {offer.imageUrl && (
                    <div
                        className="offer-tile__image"
                        style={{
                            background: `linear-gradient(0, rgba(0,0,0,0.3), rgba(0,0,0, 0.3)), url(${offer.imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    />
                )}
            </section>
            <section className="offer-tile__details">
                <div>
                    <Text className="offer-tile__title" preset="g-16" mode="bold">
                        {offer.title}
                    </Text>
                    <Text className="offer-tile__subtitle" preset="g-14">
                        {offer.subtitle}
                    </Text>
                </div>
                {(onDetailsClick || onOrderClick) && (
                    <div className="offer-tile__buttons">
                        {onDetailsClick && (
                            <Chip size="xsmall" onClick={onDetailsClick}>
                                <Text preset="g-16" mode="medium" value="Details" />
                            </Chip>
                        )}
                        {onOrderClick && (
                            <Chip size="xsmall" onClick={onOrderClick}>
                                <Text preset="g-16" mode="medium" value="Order" />
                            </Chip>
                        )}
                    </div>
                )}
            </section>
        </TappableDiv>
    );
};
