import { AppDispatch, AppState } from "../../index";
import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { TypeKeys } from "../reducers";
import { PaymentMethod, PaymentType, SelectablePaymentMethod } from "../../../common/payment";
import { getAddedCardPaymentMethod, getGroupTabPaymentMethod, getListablePaymentMethods } from "../selectors";

function getReportingPaymentType(paymentType: PaymentType) {
    switch (paymentType) {
        case PaymentType.CARD:
            return "Card";
        case PaymentType.ADDEDCARD:
            return "AddedCard";
        case PaymentType.APPLEPAY:
            return "ApplePay";
        case PaymentType.GOOGLEPAY:
            return "GooglePay";
        case PaymentType.PAYPAL:
            return "PayPal";
        case PaymentType.PAYPALCHECKOUT:
            return "PayPalCheckout";
        case PaymentType.GROUPTAB:
            return "GroupTab";
        case PaymentType.NEWCARD:
            return "NewCard";
        default:
            return "Unknown";
    }
}

export function trackPaymentMethodSelected(selectedPaymentMethod: PaymentMethod | null, save: boolean) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        // Don't track anything when we temporarily select an added card
        // as we do that just before we select it so the payment method is in Redux
        if (!selectedPaymentMethod) return;

        const state = getState();
        const paymentMethods = ((getListablePaymentMethods(state) ?? []) as [SelectablePaymentMethod | null]).concat(
            getGroupTabPaymentMethod(state),
            getAddedCardPaymentMethod(state)
        );

        // if the selected paymentMethod is null we are adding a card
        const data = {
            payment_method: getReportingPaymentType(selectedPaymentMethod.paymentType),
            button: save ? "save" : "radio",
        } as any;

        if (save && paymentMethods) {
            data.available_payment_methods = paymentMethods
                .filter(Boolean)
                .map((paymentMethod) => getReportingPaymentType(paymentMethod!.paymentType))
                .toString();
        }

        if (selectedPaymentMethod.paymentType === PaymentType.ADDEDCARD) {
            data.vaulted_card = selectedPaymentMethod.remember;
        }

        dispatch(trackedEvent({ type: TypeKeys.PAYMENT_METHOD_SELECTED, ...data }));
    };
}
