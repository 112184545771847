import { getEnvUrl, timeoutAndError } from "../shared";
import { config } from "../config";

export const messageHandler = {
    listen(messageType: string, returnUrl: string, timeout?: number): [Promise<URL | null>, () => void] {
        let cancel = () => {};
        const cancelPromise = new Promise<null>((resolve) => (cancel = () => resolve(null)));

        let handle = (_: URL) => {};
        const handlePromise = new Promise<URL>((resolve) => (handle = resolve));

        const promises = [cancelPromise, handlePromise];
        if (timeout) {
            promises.push(
                timeoutAndError(timeout, new Error(`Timeout waiting ${timeout}ms for message ${messageType}`))
            );
        }

        function handleMessage(ev: MessageEvent) {
            if (
                (ev.origin !== window.location.origin && ev.origin !== getEnvUrl(config.REACT_APP_ORDER_API)) ||
                ev.data?.type !== messageType ||
                !ev.data.url.startsWith(returnUrl)
            ) {
                return;
            }

            handle(new URL(ev.data.url));
        }

        window.addEventListener("message", handleMessage);

        return [
            new Promise<URL | null>(async (resolve, reject) => {
                try {
                    const result = await Promise.race(promises);
                    resolve(result);
                } catch (err) {
                    reject(err);
                } finally {
                    window.removeEventListener("message", handleMessage);
                }
            }),
            cancel,
        ];
    },
};
