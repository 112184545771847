import { isValidPhoneNumber } from "src/sharedComponents";

export const isValidName = (name: string) => validationRules.name.pattern.value.test(name);

export const isValidEmail = (email: string) => validationRules.email.pattern.value.test(email);

interface AmountLimitOptions {
    minValue?: { val: number; display: string };
    maxValue?: { val: number; display: string };
    minValidationMessagePrefix?: string;
    maxValidationMessagePrefix?: string;
    isRequired?: boolean;
}

export const validationRules = {
    name: {
        required: {
            value: true,
            message: "Required",
        },
        minLength: {
            value: 2,
            message: "Minimum of 2 characters",
        },
        pattern: {
            value: /^[\s|\p{L}\-`‘’'.,]*\p{L}{2,}[\s|\p{L}\-`‘’'.,]*$/iu,
            message: "Name cannot contain numbers or special characters",
        },
    },
    firstName: {
        maxLength: {
            value: 32,
            message: "Maximum of 32 characters",
        },
    },
    phone: {
        required: {
            value: true,
            message: "Required",
        },
        validate: {
            number: (phone: string) => (isValidPhoneNumber(phone) ? undefined : "Invalid. Please check your number"),
        },
    },
    email: {
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            message: "Invalid email",
        },
    },
    amountLimit: ({
        minValue,
        maxValue,
        minValidationMessagePrefix = "Minimum amount is",
        maxValidationMessagePrefix = "Maximum amount is",
        isRequired = true,
    }: AmountLimitOptions) => ({
        required: {
            value: isRequired,
            message: "Required",
        },
        validate: {
            minValue: (value?: string) => {
                if (!value || !minValue) return;
                const val = Number(value);
                const res = val >= minValue.val ? undefined : `${minValidationMessagePrefix} ${minValue.display}`;
                return res;
            },
            maxValue: (value?: string) => {
                if (!value || !maxValue) return;
                const val = Number(value);
                return val <= maxValue.val ? undefined : `${maxValidationMessagePrefix} ${maxValue.display}`;
            },
        },
    }),
    requiredOption: {
        required: {
            value: true,
            message: "Select one option.",
        },
    },
};
