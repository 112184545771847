import { fetchPaymentSessionToken } from "../api/fetchPaymentSessionToken";
import { AppDispatch, AppState } from "../../index";
import { getSelectedPaymentMethod } from "../selectors";
import { getParty } from "../../order";

export const getPaymentSessionToken = (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const { paymentGateway } = getSelectedPaymentMethod(state)!;
    const inParty = !!getParty(state);

    return async () => {
        const token = await fetchPaymentSessionToken(dispatch, paymentGateway, inParty);
        if (token) return token;
        throw new Error("No payment session token");
    };
};
