import { AppMiddleware } from "src/features";
import { Action, Dispatch } from "redux";
import { device } from "src/common/experience";
import { orderApi } from "src/features/order/orderApi";
import { activateInstance, setDeactivatedHandler } from "../api/activeInstance";
import { actionCreators } from "../reducers";
import { isPreview } from "src/features/preview";
import { isExtension, isGoogleTagManagerPreview } from "src/features/preview/previewMiddleware";

export const multiInstanceMiddleware = () => (store: AppMiddleware) => {
    if (device.supportsMultipleInstances) {
        if (window.opener && !isPreview && !isExtension() && !isGoogleTagManagerPreview) {
            window.close();
        } else {
            activateInstance();
            setDeactivatedHandler(() => {
                setDeactivatedHandler(null);
                document.title += " (inactive)";
                store.dispatch(actionCreators.duplicate());
                try {
                    orderApi.disconnect();
                } catch {}
            });
        }
    }

    return (next: Dispatch) => (action: Action) => next(action);
};
