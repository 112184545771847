import * as React from "react";
import { useEffect } from "react";
import { NoNetworkIcon } from "src/sharedComponents/assets/icons";
import "../assets/NoConnectionShield.scss";
import { Text } from "src/sharedComponents";
import { StatusBar } from "../../../common/statusBar";

export interface Props {
    visible: boolean;
}

export const NoConnectionShield = (props: Props) => {
    useEffect(() => {
        if ("fonts" in (document as any)) {
            try {
                // Preload ProximaNovaSemibold as we haven't used it yet
                (document as any).fonts.load("500 14px Manrope");
            } catch (err) {
                // Don't report anything
            }
        }
    }, []);

    if (!props.visible) return null;
    return (
        <div className="no-connection-shield">
            <StatusBar backgroundColor="#fff" />
            <div className="no-connection-shield__content">
                <NoNetworkIcon />
                <Text preset="g-18" mode="bold" value="No internet connection available" />
                <Text
                    preset="g-14"
                    mode="medium"
                    value="Your device is not connected to the internet. When a connection is established, this page will update."
                />
            </div>
            <div className="no-connection-shield__footer">
                <Text preset="g-14" mode="medium" value="Have a data connection?" />
                <Text
                    preset="g-14"
                    mode="medium"
                    value="Please check your network data settings or app permissions and try again."
                />
            </div>
        </div>
    );
};
