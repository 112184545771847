import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleNavHeader } from "src/common/navigation";
import { manageMembershipsActions } from "../reducers";
import { ManageMemberships } from "./ManageMemberships";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { ModalContainer } from "src/common/modal";
import { getIsManageMembershipsOpen } from "../selectors/getActiveMembershipState";

export const ManageMembershipsModal = () => {
    const showModal = useSelector(getIsManageMembershipsOpen);
    const dispatch = useDispatch();

    return (
        <ModalContainer
            isOpen={showModal}
            className={{
                base: "manage-membership-modal profile-drawer-modal",
                afterOpen: "manage-membership-modal--after-open profile-drawer-modal--after-open",
                beforeClose: "manage-membership-modal--before-close profile-drawer-modal--before-close",
            }}
            overlayClassName="ReactModal__ManageMemberships ReactModal__ProfileDrawer"
            bodyOpenClassName="ManageMemberships-modal__Body--open"
            closeTimeoutMS={250}
        >
            <SimpleNavHeader
                onBack={() => dispatch(manageMembershipsActions.showManageMemberships(false))}
                closeToBack
                customBack="manage-membership-modal"
                withBorder
            />
            <LocationThemeContainer>
                <ManageMemberships />
            </LocationThemeContainer>
        </ModalContainer>
    );
};
