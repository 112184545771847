import { AnyAction } from "redux";
import { actionCreators, WizardOptions, WizardState } from "./reducer";
import { AppState } from "../index";

interface WizardStateT<T extends string> extends WizardState {
    currentPage: T;
}

export interface Wizard<T extends string> {
    name: string;
    pages: T[];
    actionCreators: WizardActionCreators<T>;
    getState(state: AppState): WizardStateT<T> | undefined;
    supportsKeyboardUnfixed(page: T): boolean;
}

export interface WizardOptionsT<T extends string> extends WizardOptions {
    overridePages?: T[];
}

export interface WizardActionCreators<T extends string> {
    start(page: T, options?: WizardOptionsT<T>): AnyAction;
    next(page?: T): AnyAction;
    prev(page?: T): AnyAction;
    standalone(page: T): AnyAction;
    done(): AnyAction;
}

export const createWizard = <T extends string>(
    wizard: string,
    pages: T[],
    supportsKeyboardUnfixed: (page: T) => boolean = (_) => true
): Wizard<T> => {
    const wizardActionCreators: WizardActionCreators<T> = {
        start: (page, options) => actionCreators.start(wizard, page, pages, options),
        next: (page) => actionCreators.next(wizard, page),
        prev: (page) => actionCreators.prev(wizard, page),
        standalone: (page) => actionCreators.standalone(wizard, page),
        done: () => actionCreators.done(wizard),
    };

    return {
        name: wizard,
        pages,
        actionCreators: wizardActionCreators,
        getState: (state: AppState) => state.wizards[wizard] as WizardStateT<T>,
        supportsKeyboardUnfixed,
    };
};
