import { GroupTabHistoryOrderItems } from "src/features/groupTabsHistory";
import { Indexed, MenuItemWithId, Tips } from "src/features/menudata";
import { PaymentInfoBase } from "../../payment";

export type NewGroupTabPage =
    | "TabName"
    | "PaymentMethods"
    | "SetLimit"
    | "IncreaseLimit"
    | "LimitedMenuPackages"
    | "LimitedMenuPackagePreview";

export enum LimitedMenuType {
    FULL = "full",
    LIMITED = "limited",
}

export enum TabType {
    PAYONCREATE = 0,
    PAYONORDER,
}

export enum TabJoinMethod {
    QR = "qr",
    LINK = "link",
}

export interface GroupTabsWizardState {
    showWizard: boolean;
    pages?: NewGroupTabPage[];
    tabName?: string;
    tabLimit?: number;
    menuType?: LimitedMenuType;
    packageId?: string;
    tabType?: TabType;
    page?: NewGroupTabPage;
}

export interface ActiveGroupTabState {
    data?: ActiveGroupTabData;
    orders?: Indexed<GroupTabHistoryOrderItems>;
    showInviteModal?: boolean;
    showManageModal?: boolean;
    showOverviewModal?: boolean;
    showCloseConfirmation?: boolean;
    showOrdersModal?: boolean;
    showMembersModal?: boolean;
}

export interface ActiveGroupTabData {
    id: string;
    locationId?: string;
    joinCode?: string;
    displayName: string;
    tabName: string;
    owner: string;
    ownerName: string;
    limit: number;
    available: number;
    members: GroupTabMember[];
    removedMembers: GroupTabMember[];
    status: GroupTabStatus;
    debits: GroupTabDebit[];
    locationImage?: string;
    locationColor?: string;
    locale?: string;
    currency?: string;
    locationName?: string;
    dateUpdated: string;
    packageId?: string;
    packageName?: string;
    isDemo: boolean;
    closeReason: GroupTabCloseReason;
    gratuityFactor?: number;
    type: TabType;
    hasSeenManagePrompt?: boolean;
    spend?: number;
    tips?: Tips;
}

export interface GroupTabMember {
    memberId: string;
    firstName?: string;
    lastName?: string;
}

export interface GroupTabOrderBase {
    partyId: string;
    amount: number;
    surcharges?: number;
    dateCreated: string; //"2021-06-16T05:51:00.808Z"
}

export interface GroupTabDebit extends GroupTabOrderBase {
    id: string;
    memberId: string;
}

export interface JoinCodeState {
    joinCode?: string;
    error?: string;
}

export enum GroupTabStatus {
    OPEN = 0,
    CLOSED = 1,
}

export enum GroupTabCloseReason {
    PENDING = 0,
    USER = 1,
    SYSTEM = 2,
    VENUE = 3,
}

export enum GroupTabErrorType {
    OWNER_CREATE_TAB = "OwnerCreateTab",
    OPEN_FAILED = "OpenFailed",
    MEMBER_JOIN_TAB = "MemberJoinTab",
    OWNER_JOIN_TAB = "OwnerJoinTab",
    JOIN_FAILED = "JoinFailed",
    REMOVE_FAILED = "RemoveFailed",
    PAYMENT_FAILED = "PaymentFailed",
    INFO = "InfoFailure",
    JOIN_ATTEMPT_LIMIT = "JoinAttemptLimit",
    JOIN_NOT_FOUND = "JoinNotFound",
    MUST_USE_EXTENDED_VALIDATION = "MustUseExtendedValidation",
    UPDATE_LIMIT_FAILED = "SetTabLimitFailure",
}

export interface OpenGroupTabInfo extends IncreaseGroupTabInfo {
    tabName: string;
    packageId?: string;
    packageName?: string;
    type?: TabType;
}

export interface IncreaseGroupTabInfo extends PaymentInfoBase {
    limit: number;
}

export interface UpdateTabLimitInfo {
    limit: number;
}

export interface PackageCategory {
    menuItems: string[];
    variants?: number[][];
}

export interface LimitedMenuPackage {
    id: string;
    displayName: string;
    description?: string;
    enabled: boolean;
    categories: Indexed<PackageCategory>;
}

export interface LimitedMenuPackageDetails {
    displayName: string;
    menuItems: MenuItemWithId[];
}

export interface GroupTabMenuCallToAction {
    actionState: GroupTabMenuActionState;
    title: string;
    callToAction: string;
}

export enum GroupTabMenuActionState {
    NONE = 0,
    OWNER = 1,
    TO_LIMITED = 2,
    TO_FULL = 3,
}

export const TAB_NAME_MAX_LENGTH = 23;
