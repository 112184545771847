import { FeatureConfiguration } from "..";

import reducer from "./reducer";

export * from "./reducer";

export { createWizard } from "./wizard";

export default function (config: FeatureConfiguration) {
    config.reducers.wizards = reducer;
}
