import { createSelector } from "reselect";
import { CurrentPartyDetails, Party } from "..";
import { AppState } from "../..";
import {
    getActiveService,
    getAlcoholicDrinksLimit,
    getCourses,
    getDietaryTagGroup,
    getPriorityDietaryTags,
    getVisibleMenuData,
} from "../../menu/selectors";
import {
    Course,
    Indexed,
    LocationMenuData,
    MenuItem,
    MenuItemModifier,
    MenuItemVariant,
    Subtypes,
    TagGroup,
} from "../../menudata";
import { OrderCourse, OrderCourseItem, OrderSummary } from "../reducers/types";
import {
    AdjustmentCategory,
    AlcoholicDrinksLimit,
    DeliveryOptionType,
    Order,
    OrderItem,
    OrderStatus,
    PartyAdjustment,
    PendingItem,
    PriceSource,
} from "../types";
import { flatten } from "./util/flatten";
import { getOrderItemModifiers, getOrderItemVariantName } from "./util/getOrderItemOptions";
import { getPriceResolver, PriceResolver } from "../../membership/selectors/getPriceResolver";
import { getCurrentMemberId, getIsAnonymous } from "src/features/accounts/selectors";
import { getPendingItem } from "./pendingItem";
import { getIsSalesMode } from "./restaurantFlags";
import { getTestingFeaturesEnabledState } from "src/features/accountmenu/selectors";
import { ItemCategory, OfferType } from "src/features/offers/types";
import {
    getAvailableStampCardOffers,
    getOfferIemsSelectionIndex,
    getSelectedOfferOfferItems,
} from "src/features/offers/selectors";
import { isOrderAndPayParty } from "src/features/order/helpers";

export const getParty = (state: AppState) => state.party.activeParty;

export const getPartyMembersLength = createSelector(getParty, (party) => party?.members.length || 0);

export const getInParty = createSelector(getParty, (party) => !!party);

export const getPreviousParty = (state: AppState) => state.party.previousParty;

export const getIsPartyConnected = (state: AppState) =>
    !state.party.activeParty || state.party.connectionStatus === "connected";

export const getIsAppConnected = (state: AppState) => state.nativeState.connected;

export const getIsConnected = createSelector(
    getIsPartyConnected,
    getIsAppConnected,
    (partyConnected, appConnected) => partyConnected && appConnected
);

export const getAnyParty = createSelector(getParty, getPreviousParty, (party, previousParty) => party || previousParty);

const getTagGroups = createSelector(getVisibleMenuData, (menuData) => menuData?.tagGroups || null);

export const getServiceTagSet = createSelector(
    getVisibleMenuData,
    getPriorityDietaryTags,
    getActiveService,
    (menuData, priorityDietaryTags, activeService) => {
        if (!activeService || !menuData || !priorityDietaryTags) {
            return null;
        }

        const set = new Set<string>();

        for (const menuId of activeService.menus) {
            const menu = menuData.menus[menuId];

            for (const categoryId of menu.categories) {
                const category = menuData.categories[categoryId];

                for (const menuItemId of category.menuItems) {
                    const menuItem = menuData.items[menuItemId];

                    if (menuItem.tags) {
                        for (const tag of menuItem.tags) {
                            set.add(tag);
                        }
                    }
                }
            }
        }

        if (activeService.setMenus) {
            for (const setMenuId of activeService.setMenus) {
                const setMenu = menuData.items[setMenuId];

                if (setMenu.tags) {
                    for (const tag of setMenu.tags) {
                        set.add(tag);
                    }
                }
            }
        }

        for (const priorityTag of priorityDietaryTags) {
            set.add(priorityTag.id);
        }

        return set;
    }
);

export const getServiceTagGroups = createSelector(getTagGroups, getServiceTagSet, (tagGroups, serviceTags) => {
    if (serviceTags === null || tagGroups === null) {
        return tagGroups || [];
    }

    return tagGroups.map(
        (group) =>
            ({
                ...group,
                tags: group.tags.filter((t) => serviceTags.has(t.id)),
            } as TagGroup)
    );
});

export const getServiceDietaryTags = createSelector(
    getServiceTagGroups,
    getDietaryTagGroup,
    (serviceTagGroups, dietaryTagGroup) =>
        serviceTagGroups ? serviceTagGroups.find((g) => !!dietaryTagGroup && g.id === dietaryTagGroup.id)!.tags : null
);

export const getPartyBillPriceSource = createSelector(
    getParty,
    (party: Party | null) => party?.billPriceSource || PriceSource.LOCAL
);

export const getIsPartyPosBillPrice = createSelector(
    getPartyBillPriceSource,
    (billPriceSource: PriceSource) => billPriceSource === PriceSource.POS
);

export const getIsValidatingPrices = createSelector(getParty, (party: Party | null) =>
    party?.billPriceSource ? isValidatingPrices(party.billPriceSource, party?.activeOrder.status) : undefined
);

export const getActiveOrder = createSelector(getParty, (party) => party?.activeOrder);

export const getPartyOrders = createSelector(getAnyParty, (party: Party | null) =>
    party ? party.submittedOrders.concat(party.activeOrder) : []
);

export const getPartySubmittedOrders = createSelector(getParty, (party: Party | null) => party?.submittedOrders || []);

const getOrderMembersByLineItem = (orders: Order[]) =>
    orders.reduce((memberIds, order) => {
        order.items.forEach((i) => {
            memberIds.add(i.memberId);
        });
        return memberIds;
    }, new Set<string>());

export const getPartyAllSubmittedOrderMembers = createSelector(getPartySubmittedOrders, getOrderMembersByLineItem);

export const getCurrentMemberHasSubmittedOrders = createSelector(
    getCurrentMemberId,
    getPartyAllSubmittedOrderMembers,
    (currentMemberId, memberIdsWithSubmittedOrders) => {
        return memberIdsWithSubmittedOrders.has(currentMemberId);
    }
);

const getOrderFoodAndDrinks = (
    order: Order[] | Order = [],
    courses: Course[],
    menuData: LocationMenuData | null,
    memberId: string,
    priceResolver: PriceResolver,
    billPriceSource: PriceSource
): OrderSummary => {
    const menuItems = menuData!.items;
    const orderArray = Array.isArray(order) ? order : [order];

    const orderCourses = courses!
        .map((c) => createOrderCourse(c!, orderArray, menuItems, memberId, priceResolver, billPriceSource))
        .filter((oc) => oc.items.length);

    const pendingItems = flatten(orderCourses.map((x) => x.items.filter((item) => !item.submitted)));

    return {
        food: pendingItems.filter((item) => item.type === "food"),
        drinks: pendingItems.filter((item) => item.type === "drink"),
    } as OrderSummary;
};

export const getActiveOrderByType = createSelector(
    getActiveOrder,
    getCourses,
    getVisibleMenuData,
    getCurrentMemberId,
    getPriceResolver,
    getPartyBillPriceSource,
    getOrderFoodAndDrinks
);

export const getOrdersByType = createSelector(
    getPartyOrders,
    getCourses,
    getVisibleMenuData,
    getCurrentMemberId,
    getPriceResolver,
    getPartyBillPriceSource,
    getOrderFoodAndDrinks
);

function createOrderCourse(
    course: Course,
    orders: Order[],
    items: Indexed<MenuItem>,
    memberId: string,
    priceResolver: PriceResolver,
    billPriceSource: PriceSource
): OrderCourse {
    const isTableView = memberId === undefined;

    let courseItems = flatten(
        orders.map((o) =>
            o.items
                .filter((x) => x.courseId === course.id && (!memberId || x.memberId === memberId))
                .filter((orderItem) => !orderItem.itemId || validateOrderItem(orderItem, items[orderItem.itemId]))
                .map((orderItem) =>
                    createCourseItem(
                        orderItem,
                        orderItem.itemId ? items[orderItem.itemId] : null,
                        o.status === OrderStatus.SUBMITTED || o.status === OrderStatus.ACCEPTED,
                        o.status === OrderStatus.OPEN && !isTableView,
                        priceResolver,
                        o.status,
                        billPriceSource
                    )
                )
        )
    );

    return {
        displayName: course.displayName,
        items: courseItems,
        totalQuantity: getTotalQuantity(courseItems),
    };
}

function validateOrderItem(orderItem: OrderItem, menuItem: MenuItem) {
    if (!menuItem) {
        console.warn(`Order item had an invalid menuItemId '${orderItem.itemId}'`);
        return false;
    }

    return true;
}

function getTotalQuantity(courseItems: OrderCourseItem[]): number {
    return courseItems.reduce((t, i) => t + i.quantity, 0);
}

function createCourseItem(
    orderItem: OrderItem,
    menuItem: MenuItem | null,
    submitted: boolean,
    canDelete: boolean,
    priceResolver: PriceResolver,
    orderStatus: OrderStatus,
    billPriceSource: PriceSource
): OrderCourseItem {
    const displayName = menuItem ? menuItem.displayName : orderItem.displayName!;

    return {
        orderItemId: orderItem.id,
        menuItemId: orderItem.itemId,
        type: menuItem ? menuItem.type : "food",
        displayName,
        waitTime: menuItem?.waitTime,
        quantity: orderItem.quantity,
        submitted,
        canDelete,
        price: orderItem.price!, // Get this directly from serverside billing
        notes: orderItem.notes,
        variant: orderItem.variant,
        variantName: getOrderItemVariantName(orderItem.variant, menuItem),
        modifierOptions: getOrderItemModifiers(orderItem.modifiers, menuItem),
        modifiers: orderItem.modifiers,
        memberId: orderItem.memberId,
        failedToSubmit: orderItem.failedToSubmit,
    };
}

function isValidatingPrices(billSource: PriceSource, orderStatus?: OrderStatus) {
    return (
        billSource === PriceSource.POS &&
        (orderStatus === OrderStatus.OPEN ||
            orderStatus === OrderStatus.VALIDATING ||
            orderStatus === OrderStatus.INVALID)
    );
}

export const getActiveLocationId = createSelector(getParty, (party) => party && party.restaurantId);

export const getPreviousLocationId = createSelector(getPreviousParty, (party) => party && party.restaurantId);

export const getAnyPartyDetails = createSelector(getVisibleMenuData, getAnyParty, getPartyDetails);

export const getCurrentPartyDetails = createSelector(getVisibleMenuData, getParty, getPartyDetails);

export const getCanSubmit = createSelector(
    getParty,
    getCurrentMemberId,
    (party, userId) => party?.activeOrder.items.some((oi) => oi.memberId === userId) && !!party!.activeOrder.bill
);

export const getActiveOrderStatus = createSelector(getActiveOrder, (activeOrder) => activeOrder?.status ?? null);

export const getCurrentMemberDetails = createSelector(getParty, getCurrentMemberId, (activeParty, userId) =>
    activeParty?.members.find((m) => m.memberId === userId)
);

export const getCurrentMemberInParty = createSelector(
    getParty,
    getCurrentMemberId,
    (activeParty, userId) => !!userId && !!activeParty && activeParty.members.some((m) => m.memberId === userId)
);

export const getPartyPromoCodePromotionContext = createSelector(
    getParty,
    (party) =>
        party?.adjustments?.find((adjustment: PartyAdjustment) => !!adjustment.promotionContext?.code)?.promotionContext
);

function getPartyDetails(menuData: LocationMenuData | null, party: Party | null): CurrentPartyDetails | null {
    return !menuData || !party
        ? null
        : {
              id: party.id,
              tableNumber: party.tableNumber,
              tableLabel: getPartyTableLabel(party),
              location: menuData.title,
              address: party.companyReceiptDetails?.address,
          };
}

const iterateAlcoholicDrinkItems = (
    menuData: LocationMenuData,
    orderItem: OrderItem | PendingItem,
    isItemAlcoholicDrink: (item: MenuItem | MenuItemModifier | MenuItemVariant, quantity: number) => void
) => {
    const menuItem = orderItem?.itemId ? menuData?.items[orderItem.itemId] : undefined;
    if (!menuItem) {
        return;
    }

    isItemAlcoholicDrink(menuItem, orderItem.quantity);

    if (orderItem.modifiers && menuItem.modifiers) {
        for (let orderItemModifier of orderItem.modifiers) {
            const modifier = menuItem.modifiers[orderItemModifier.modifier];
            isItemAlcoholicDrink(modifier, orderItemModifier.options.length * orderItem.quantity);
            orderItemModifier?.optionNestedModifiers?.forEach((nestedOption) => {
                nestedOption.modifiers?.forEach((nestedOptionModifier) => {
                    const modifierData = menuData.modifiers![nestedOptionModifier.modifierId];
                    isItemAlcoholicDrink(
                        modifierData,
                        nestedOptionModifier.options.length * nestedOption.quantity * orderItem.quantity
                    );
                });
            });
        }
    }
};

const getActiveOrderAlcoholicDrinksCount = (
    pendingItem: PendingItem | null,
    party: Party,
    menuData: LocationMenuData
) => {
    let totalAlcoholicCount = 0;

    const { items } = { ...party?.activeOrder };

    const activeOrderFilteringPendingItem: (OrderItem | PendingItem)[] | undefined = items?.filter(
        (item) => item.id !== pendingItem?.orderItemId
    );

    if (pendingItem) {
        activeOrderFilteringPendingItem?.push(pendingItem);
    }

    const isItemAlcoholicDrink = (menuItem: MenuItem | MenuItemModifier | MenuItemVariant, quantity: number) => {
        if ((menuItem?.subtypes && menuItem.subtypes & Subtypes.ALCOHOLIC) === Subtypes.ALCOHOLIC) {
            totalAlcoholicCount += quantity;
        }
    };
    activeOrderFilteringPendingItem?.forEach((item) => {
        iterateAlcoholicDrinkItems(menuData, item, isItemAlcoholicDrink);
    });

    return totalAlcoholicCount;
};

export const getDoesPendingItemHaveAlcoholicDrink = createSelector(
    getPendingItem,
    getVisibleMenuData,
    getAlcoholicDrinksLimit,
    (pendingItem, menuData, alcoholicLimit) => {
        let isAlcoholic = false;
        if (!pendingItem.item || !menuData || !alcoholicLimit) {
            return isAlcoholic;
        }
        const isItemAlcoholicDrink = (menuItem: MenuItem | MenuItemModifier | MenuItemVariant) => {
            if ((menuItem?.subtypes && menuItem.subtypes & Subtypes.ALCOHOLIC) === Subtypes.ALCOHOLIC) {
                isAlcoholic = true;
            }
        };
        iterateAlcoholicDrinkItems(menuData, pendingItem.item, isItemAlcoholicDrink);
        return isAlcoholic;
    }
);

export const getAlcoholicDrinksCount = createSelector(
    getParty,
    getPendingItem,
    getVisibleMenuData,
    getAlcoholicDrinksLimit,

    (party, pendingItem, menuData, alcoholLimit) => {
        if (!party || !menuData || !alcoholLimit) {
            return undefined;
        }
        return getActiveOrderAlcoholicDrinksCount(pendingItem?.item, party, menuData);
    }
);

export const getAlcoholicDrinksLimitStatus = createSelector(
    getAlcoholicDrinksCount,
    getAlcoholicDrinksLimit,

    (alcoholicDrinksCount, alcoholicDrinksLimit) => {
        if (!alcoholicDrinksLimit || alcoholicDrinksCount === undefined) {
            return AlcoholicDrinksLimit.NONE;
        }
        if (alcoholicDrinksCount === alcoholicDrinksLimit) return AlcoholicDrinksLimit.REACHED;
        if (alcoholicDrinksCount > alcoholicDrinksLimit) return AlcoholicDrinksLimit.EXCEEDED;
        return AlcoholicDrinksLimit.UNDER;
    }
);

export const getIsAlcoholicDrinksLimitReached = createSelector(
    getAlcoholicDrinksLimitStatus,
    (status) => status === AlcoholicDrinksLimit.REACHED
);
export const getIsAlcoholicDrinksLimitExceeded = createSelector(
    getAlcoholicDrinksLimitStatus,
    (status) => status === AlcoholicDrinksLimit.EXCEEDED
);
export const getAlcoholicDrinksLimitReachedOrExceeded = createSelector(
    getIsAlcoholicDrinksLimitReached,
    getIsAlcoholicDrinksLimitExceeded,
    (reached, exceeded) => reached || exceeded
);

export const getShouldShowPriceLoader = createSelector(
    getIsValidatingPrices,
    getIsAlcoholicDrinksLimitExceeded,
    (isValidatingPrices, isDrinksExceeded) => !isDrinksExceeded && isValidatingPrices
);

export const getIsSectionTakeaway = createSelector(getParty, (activeParty) => {
    return (
        activeParty?.deliveryOptions?.some((deliveryOption) => deliveryOption.type === DeliveryOptionType.TAKEAWAY) ||
        false
    );
});

export const getOrderReadyTime = createSelector(
    getParty,
    (activeParty) => activeParty?.orderReadyTime?.earliestInMinutes
);

export const getLatestOrderReadyTime = createSelector(
    getParty,
    (activeParty) => activeParty?.orderReadyTime?.latestInMinutes
);

export const getOrderDateTimeScheduled = createSelector(getParty, (activeParty) =>
    activeParty?.activeOrder?.dateScheduled ? new Date(activeParty.activeOrder.dateScheduled) : null
);

export const getOrderDateToSubmitString = createSelector(getActiveOrder, (order) => order?.dateToSubmit);

export const getOrderDateToSubmit = createSelector(getOrderDateToSubmitString, (dateToSubmit) =>
    dateToSubmit ? new Date(dateToSubmit) : null
);

export const getActiveVenueLocationDetails = createSelector(
    getParty,
    (activeParty) => activeParty?.venueDetails?.venueAddress
);

export const getPartyMembers = createSelector(getParty, (activeParty) => activeParty?.members);

export const getPartyMembersExcludingCurrentMember = createSelector(
    getPartyMembers,
    getCurrentMemberId,
    (members, memberId) => members?.filter((member) => member.memberId !== memberId)
);

export const getCurrentMemberActiveOrderItems = createSelector(getActiveOrder, getCurrentMemberId, (order, memberId) =>
    order?.items.filter((i) => i.memberId === memberId)
);

export const getOrderAcceptedMembers = createSelector(getActiveOrder, (order) => order?.acceptedMembers);

export const getCurrentMemberInAcceptedMembers = createSelector(
    getOrderAcceptedMembers,
    getCurrentMemberId,
    (acceptedMembers, currentMemberId) => !!acceptedMembers?.includes(currentMemberId)
);

export const getPartyHasSubmittedOrders = createSelector(
    getParty,
    (party: Party | null) => !!party?.submittedOrders.length
);

export const getIsPartyDemo = createSelector(getParty, (party) => !!party?.isDemo);

export const getCanShowDemoFeatures = createSelector(
    getIsPartyDemo,
    getIsSalesMode,
    (isDemo, isSalesMode) => isDemo && !isSalesMode
);

export const getTestingFeaturesEnabled = createSelector(
    getTestingFeaturesEnabledState,
    getCanShowDemoFeatures,
    (enableTestingFeatures, canShowDemoFeatures) => canShowDemoFeatures && enableTestingFeatures
);

export const getIsOrderAndPay = createSelector(getParty, isOrderAndPayParty);

export const getCurrentMemberHasInvalidActiveOrderItem = createSelector(
    getCurrentMemberActiveOrderItems,
    (orderItems: OrderItem[] | undefined) => orderItems?.some((a) => a.failedToSubmit)
);

export const getWalletDetails = createSelector(getParty, (activeParty) => activeParty?.wallet);

export const getIsEngageEnabledForLocation = createSelector(getWalletDetails, (wallet) => !!wallet?.enabledForLocation);

export const getIsEngageEnabledForParty = createSelector(getWalletDetails, (wallet) => wallet?.enabledForParty);

export const getShowEngageForUser = createSelector(
    getIsEngageEnabledForLocation,
    getIsAnonymous,
    (engageEnabled, isAnonymous) => engageEnabled && !isAnonymous
);

export const getIsEngageEnabled = createSelector(
    getIsEngageEnabledForLocation,
    getIsEngageEnabledForParty,
    (enabledForLocation, enabledForParty) => enabledForLocation && enabledForParty
);

export const getEngageOfferAdjustments = createSelector(getAnyParty, (party) =>
    party?.adjustments?.filter(
        (adjustment: PartyAdjustment) => adjustment.adjustmentCategory === AdjustmentCategory.ENGAGE_OFFER
    )
);

export const getStampCardAdjustments = createSelector(getEngageOfferAdjustments, (engageAdjustments) =>
    engageAdjustments?.filter((adjustment) => adjustment.cepOfferContext?.offerType === OfferType.STAMP_CARD)
);

export const getBounceBackEngageOfferAdjustments = createSelector(getEngageOfferAdjustments, (engageAdjustments) =>
    engageAdjustments?.filter((adjustment) => adjustment.cepOfferContext?.bounceBack)
);

export const getBounceBackOffer = createSelector(
    getBounceBackEngageOfferAdjustments,
    getAvailableStampCardOffers,
    (bounceBackEngageOfferAdjustments, stampCardOfers) =>
        !bounceBackEngageOfferAdjustments?.length
            ? null
            : stampCardOfers?.find((sc) => sc.id === bounceBackEngageOfferAdjustments[0].cepOfferContext?.offerId)
);

export const getBounceBackOfferStampCard = createSelector(getBounceBackOffer, (bounceBackOffer) =>
    bounceBackOffer?.offerType !== OfferType.STAMP_CARD ? null : bounceBackOffer
);

export const getSelectedOfferHasItems = createSelector(
    getSelectedOfferOfferItems,
    getVisibleMenuData,
    getCurrentMemberActiveOrderItems,
    (offerItems, menuData, cartItems) => {
        if (!menuData) return false;
        const offerItemIds = offerItems?.[0]?.ids ?? [];

        const availableItemIds = getAvailableOfferItems(offerItemIds, menuData);
        return !!Object.keys(availableItemIds).length;
    }
);

export const getSelectedOfferItems = createSelector(
    getSelectedOfferOfferItems,
    getOfferIemsSelectionIndex,
    getVisibleMenuData,
    getCurrentMemberActiveOrderItems,
    (offerItems, itemsSelectionIndex, menuData, cartItems) => {
        if (!menuData || itemsSelectionIndex === undefined || !offerItems) return [];
        const offerItemIds = offerItems[itemsSelectionIndex]?.ids ?? [];

        if (!offerItemIds.length) return [];

        const availableItemIds = getAvailableOfferItems(offerItemIds, menuData);

        const categories = Object.keys(menuData.categories)
            .filter((categoryId) =>
                menuData.categories[categoryId].menuItems.some((itemId) => availableItemIds[itemId])
            )
            .filter(Boolean);

        const offerItemsCategory = categories.reduce((itemCat: ItemCategory[], categoryId: string) => {
            Object.keys(availableItemIds).forEach((itemId) => {
                if (menuData.categories[categoryId].menuItems.includes(itemId)) {
                    itemCat.push({
                        itemId,
                        categoryId,
                        cartQuantity: 0,
                        discountedIds: availableItemIds[itemId],
                        itemsQuantity:
                            cartItems
                                ?.filter((item) => item?.itemId === itemId)
                                .reduce((total, item) => total + item?.quantity, 0) ?? 0,
                    });
                }
            });

            return itemCat;
        }, []);

        return offerItemsCategory;
    }
);

export const getOfferItemInCartCount = createSelector(
    getSelectedOfferItems,
    getCurrentMemberActiveOrderItems,
    (selectedOfferItems, currentMemberOrderItems) => {
        if (!currentMemberOrderItems?.length) return 0;
        return currentMemberOrderItems.reduce(
            (total, currItem) =>
                selectedOfferItems.find((offerItem) => offerItem.itemId === currItem.itemId)
                    ? (total += currItem.quantity)
                    : total,
            0
        );
    }
);

function getAvailableOfferItems(offerItemIds: string[], menuData: LocationMenuData) {
    return offerItemIds.reduce((itemIds: { [itemId: string]: string[] }, itemId: string) => {
        if (menuData.items[itemId]) {
            if (menuData.items[itemId].available !== false) {
                itemIds[itemId] = [itemId];
            }
        } else {
            var item = Object.keys(menuData.items).find(
                (itemKey) =>
                    menuData.items[itemKey].variants &&
                    menuData.items[itemKey].variants!.some(
                        (variant) => variant.id === itemId && variant.available !== false
                    )
            );

            if (item) {
                if (!itemIds[item]) {
                    itemIds[item] = [];
                }
                itemIds[item].push(itemId);
            }
        }

        return itemIds;
    }, {});
}

function getPartyTableLabel(party: Party | null) {
    return party?.tableLabel?.toLowerCase() || "table";
}

export const getTableLabel = createSelector(getAnyParty, getPartyTableLabel);
