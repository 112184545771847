import { createSelector } from "reselect";
import { AppState } from "../..";
import { getActiveGroupTabData } from "../../groupTabs/selectors/activeGroupTab";
import { getLocationGroupTabsEnabled, getOpenTabInCheckoutSupported } from "../../groupTabs/selectors";
import { PaymentType } from "../../../common/payment";
import { getSelectedPaymentMethod } from "./paymentMethods";

export const getPaymentFlowState = (state: AppState) => state.paymentFlow;

export const getIsPaymentWizardOpen = createSelector(getPaymentFlowState, (paymentFlow) => paymentFlow.showWizard);

export const getCanOpenTabInPaymentFlow = createSelector(
    getActiveGroupTabData,
    getLocationGroupTabsEnabled,
    getOpenTabInCheckoutSupported,
    (activeGroupTab, groupTabsEnabledAtLocation, openTabInCheckoutSupported) =>
        openTabInCheckoutSupported && groupTabsEnabledAtLocation && !activeGroupTab
);

export const getIsOpeningTab = createSelector(getPaymentFlowState, (paymentFlow) => paymentFlow.isOpeningTab);

export const getIsOpeningOrPayingWithTab = createSelector(
    getPaymentFlowState,
    getSelectedPaymentMethod,
    (paymentFlow, selectedPaymentMethod) =>
        selectedPaymentMethod?.paymentType === PaymentType.GROUPTAB || paymentFlow.isOpeningTab
);
