import { FetchError } from "./FetchError";

const problemDetailsMimeTypes = ["application/json", "application/problem+json"];

export class ProblemDetailsError extends Error {
    public status: number;

    constructor(public problemDetails: ProblemDetails) {
        super(problemDetails.detail);

        this.status = problemDetails.status;
    }

    static isProblemDetailsError(error: any) {
        return error instanceof ProblemDetailsError;
    }

    static async read(response: Response): Promise<ProblemDetails | null> {
        if (response.body === null) {
            return null;
        }

        const contentTypeHeader = response.headers.get("Content-Type");

        if (contentTypeHeader && problemDetailsMimeTypes.some((mimeType) => contentTypeHeader.includes(mimeType))) {
            const responseBody = await response.json();

            return responseBody as ProblemDetails;
        }

        return null;
    }

    static async throwError(response: Response): Promise<void> {
        if (response.ok) {
            return;
        }

        const problemDetails = await ProblemDetailsError.read(response);

        if (problemDetails) {
            throw new ProblemDetailsError(problemDetails);
        }

        throw new FetchError(response);
    }
}

export interface Extensions {
    helpUrl?: string;
}

export interface ProblemDetails {
    type: string;
    title: string;
    detail: string;
    status: number;
    extensions?: Extensions;
}

export interface ValidationProblemDetails extends ProblemDetails {
    errors: { [field: string]: string[] };
}

export function isValidationProblemDetails(details: ProblemDetails): details is ValidationProblemDetails {
    return details.status === 400 && typeof details["errors"] == "object";
}
