import { cordovaWindow } from "./cordova";

let isWatching: boolean = false;
let watchAbort: AbortController | null = null;

export const oneTimePassword = {
    isSupported: "AbortController" in window,
    otpFormat: "android",
    request: async function (signal: AbortSignal): Promise<string> {
        const watchAbort = startWatch();

        return new Promise<string>((res, rej) => {
            function onSmsArrive(ev: any) {
                const code = extractCode(ev.message);

                if (code) {
                    res(code);
                } else {
                    rej(Error("Could not extract code from OTP SMS"));
                }
            }

            function onSmsAbort(err: any) {
                signal.removeEventListener("abort", onSmsAbort);
                watchAbort.removeEventListener("abort", onSmsAbort);
                document.removeEventListener("onSMSArrive", onSmsArrive);

                rej(err);
            }

            signal.addEventListener("abort", onSmsAbort);
            watchAbort.addEventListener("abort", onSmsAbort);
            document.addEventListener("onSMSArrive", onSmsArrive);
        });
    },
};

function extractCode(message: string): string | null {
    const matches = message.match(/(\d{4,})/);

    return matches && matches[0];
}

function startWatch(): AbortSignal {
    if (!isWatching) {
        isWatching = true;
        watchAbort = new AbortController();

        cordovaWindow.SMSRetriever.startWatch(
            (message) => {
                // Watch started message
                if (message.indexOf("SMS Retriever") === 0) return;

                // The plugin will fire off events for SMS messages containing the legacy prefix
                if (message.indexOf("<#>") === 0) return;

                // Prefix-less SMS messages are not handled by the plugin, we'll do it manually
                cordovaWindow.cordova.fireDocumentEvent("onSMSArrive", { message });
            },
            () => {
                isWatching = false;
                watchAbort?.abort();
            }
        );
    }

    return watchAbort!.signal;
}
