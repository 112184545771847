import {
    ApplePayPaymentMethod,
    getStripe,
    GooglePayPaymentMethod,
    paymentOptions,
    PaymentType,
} from "../../../../payment";
import { AnyAction } from "redux";
import {
    StripeApplePayPaymentMethod,
    StripeGooglePayPaymentMethod,
} from "../../../../../features/paymentGateways/stripe/types";
import { PaymentRequest } from "@stripe/stripe-js";
import { GetDevicePaymentMethodAvailableFunc } from "../../../interface";

export const canMakePayment = async (
    { paymentType }: StripeApplePayPaymentMethod | StripeGooglePayPaymentMethod,
    paymentRequest: PaymentRequest
) => {
    const result = await paymentRequest.canMakePayment();
    const available = paymentType === PaymentType.APPLEPAY ? result?.applePay : result?.googlePay;
    return { result, available };
};

const getStripeDevicePaymentAvailable = async (
    paymentMethod: StripeApplePayPaymentMethod | StripeGooglePayPaymentMethod,
    trackEvent: (action: AnyAction) => void
) => {
    const { countryCode, currency, paymentType, apiKey, paymentGateway } = paymentMethod;
    const stripe = await getStripe(apiKey);

    const paymentRequest = stripe.paymentRequest({
        country: countryCode.toUpperCase(),
        currency: currency.toLowerCase(),
        total: {
            label: paymentOptions.label,
            amount: 100,
        },
    });

    const { result, available } = await canMakePayment(paymentMethod, paymentRequest);

    if (!available) {
        trackEvent({
            type: "NOT_AVAILABLE",
            stripe: !!stripe,
            paymentType,
            paymentGateway,
            result: JSON.stringify(result),
        });
    }

    return !!available;
};

export const getStripeApplePayAvailable: GetDevicePaymentMethodAvailableFunc<ApplePayPaymentMethod> = (
    applePayPaymentMethod,
    trackEvent
) => getStripeDevicePaymentAvailable(applePayPaymentMethod as StripeApplePayPaymentMethod, trackEvent);

export const getStripeGooglePayAvailable: GetDevicePaymentMethodAvailableFunc<GooglePayPaymentMethod> = (
    googlePayPaymentMethod,
    trackEvent
) => getStripeDevicePaymentAvailable(googlePayPaymentMethod as StripeGooglePayPaymentMethod, trackEvent);
