import {
    AddCardBehavior,
    PaymentBehavior,
    PayPalBehavior,
    VerifyCardBehavior,
} from "src/features/paymentGateways/types";
import { showBraintreeAddCard } from "./actions/showBraintreeAddCard";
import { verifyBraintreeCard } from "./actions/verifyBraintreeCard";
import { visitBraintreePaymentInfo } from "./visitors/visitBraintreePaymentInfo";
import { initializeBraintreePayPalButton } from "./initializeBraintreePayPalButton";

export const addCardBehavior: AddCardBehavior = {
    showAddCard: showBraintreeAddCard,
};

export const verifyCardBehavior: VerifyCardBehavior = {
    verifyCardAction: verifyBraintreeCard,
};

export const paymentBehavior: PaymentBehavior = {
    paymentInfoVisitor: visitBraintreePaymentInfo,
};

export const payPalBehavior: PayPalBehavior = {
    initializeButton: initializeBraintreePayPalButton,
};
