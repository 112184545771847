import "./ExpandableList.scss";

import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Text } from "../text";
import { TappableDiv } from "../../common/tappable";
import { DownArrowRightIcon } from "../../assets/icons";

export interface ExpandableListProps {
    initialLimit: number;
    totalItems: number;
    renderItem: (index: number) => void;
    showHiddenCount?: boolean;
    onExpand?: (optionsRevealed: number) => void;
    canHideItems?: boolean;
}

const HIDDEN_ITEMS_THRESHOLD = 1; // If only 1 item is hidden, render the item rather than showing 'Show 1 more'

export const ExpandableList = ({
    initialLimit,
    totalItems,
    renderItem,
    showHiddenCount,
    onExpand,
    canHideItems = true,
}: ExpandableListProps) => {
    const [numVisibleItems, setNumVisibleItems] = useState(initialLimit);
    const [listHeight, setListHeight] = useState<number>();
    const [isExpanded, setIsExpanded] = useState(totalItems - (initialLimit + HIDDEN_ITEMS_THRESHOLD) <= 0);
    const [isExpanding, setIsExpanding] = useState(false);

    const expandListRef = useRef<HTMLDivElement>(null);
    const numberOfHiddenItems = useMemo(() => totalItems - numVisibleItems, [totalItems, numVisibleItems]);
    const canExpand = useMemo(
        () => canHideItems && !isExpanded && numberOfHiddenItems > HIDDEN_ITEMS_THRESHOLD,
        [isExpanded, numberOfHiddenItems, canHideItems]
    );

    const listRef = useRef<HTMLDivElement>(null);

    const onTransitionEnd = useCallback(() => {
        setIsExpanding(false);
        setIsExpanded(true);
        expandListRef.current?.removeEventListener("transitionend", onTransitionEnd);
        expandListRef.current?.removeEventListener("transitioncancel", onTransitionEnd);
    }, []);

    const handleExpand = useCallback(() => {
        if (onExpand) {
            onExpand(totalItems - numVisibleItems);
        }
        if (expandListRef.current) {
            expandListRef.current.addEventListener("transitionend", onTransitionEnd);
            expandListRef.current.addEventListener("transitioncancel", onTransitionEnd);
        }
        setNumVisibleItems(totalItems);
        setIsExpanding(true);
    }, [onExpand, totalItems, numVisibleItems, onTransitionEnd]);

    useEffect(() => {
        if (listRef.current && listRef.current.offsetHeight) {
            setListHeight(listRef.current.offsetHeight);
        }
    }, [numVisibleItems]);

    const totalItemsToShow = canExpand ? numVisibleItems : totalItems;

    return (
        <div
            className={classNames("expandable-list", isExpanding && "expanding", isExpanded && "expanded")}
            ref={expandListRef}
            style={!isExpanded && listHeight ? ({ "--list-height": listHeight + "px" } as CSSProperties) : undefined}
        >
            <div className="expandable-list__list" ref={listRef}>
                {Array.from(Array(totalItemsToShow)).map((_, index) => renderItem(index))}
            </div>
            {canExpand && (
                <TappableDiv className="expandable-list__show-more" onClick={handleExpand}>
                    <Text preset="g-14" mode="medium">
                        Show{showHiddenCount && numberOfHiddenItems > 0 ? " " + numberOfHiddenItems : ""} more
                        <DownArrowRightIcon />
                    </Text>
                </TappableDiv>
            )}
        </div>
    );
};
