import * as React from "react";
import { generatePath, RouteComponentProps } from "react-router";
import { AvailableFilters, CategoryIndexed, ChangedTagValueMap, SelectedFilters } from "../../filter";
import { FilterModal } from "../../filter/components/FilterModal";
import { Category, LocationMenuData, Menu, MenuLine, TagGroup } from "../../menudata";
import classNames from "classnames";
import { triggerVirtualPageTrack } from "src/common/shared";
import { TabButton } from "./TabButton";
import { isFiltered } from "src/features/filter/components/util/isFiltered";
import { LineItem } from "./LineItem";
import { scrolling } from "src/common/experience";
import { PartyFoodPreferenceState, PartyMenuFoodPreferenceFilterData } from "src/features/order/reducers/party";
import { AvailabilityTrackingAction } from "../reducers/availability";
import { getItemAvailability } from "../util/helpers";
import { MenuPageFooter } from "./MenuPageFooter";
import { MenuPageEmptyTab } from "./MenuPageEmptyTab";
import "../assets/MenuPage.scss";
import { MenuRouteParams } from "../types";
import { CategoryPopup } from "./CategoryPopup";

export interface CategoryRouteParams {
    menuId: string;
    categoryId?: string;
}

export interface Props extends RouteComponentProps<CategoryRouteParams> {
    filteredItems: CategoryIndexed<string[]>;
    menu: Menu;
    categories: Category[];
    menuLines: MenuLine[];
    initialCategoryId?: string;
    availableFilters: CategoryIndexed<AvailableFilters>;
    selectedFilters: CategoryIndexed<SelectedFilters>;
    applyFilters: (categoryId: string, tagGroups: TagGroup[], selectedFilters: SelectedFilters) => void;
    showingItem: boolean;
    isActive: boolean;
    animatingMenu: boolean;
    trackMenuFoodPreferenceApplied: (preferenceName: string, preferenceState: PartyFoodPreferenceState) => void;
    dietaryTagGroup: TagGroup | null;
    trackMenuFoodPreferenceFiltered: (filteredMenuFoodPreferenceData: PartyMenuFoodPreferenceFilterData) => void;
    filteredMenuFoodPreferenceCategoryData: { [categoryId: string]: PartyMenuFoodPreferenceFilterData };
    menuData: LocationMenuData | null;
    trackAvailability: (trackingData: AvailabilityTrackingAction) => void;
    modalToggle: boolean;
    animatingBetweenTabs: boolean;
    tabParams: MenuRouteParams;
    handleChangeTab?: () => void;
    showFooter: boolean;
    hasBanner: boolean;
}

export interface State {
    headerIndex: number;
    isAnimating: boolean;
    modalIsOpen: boolean;
    headerNavigate: boolean;
    noAnimation: boolean;
    modalCategory?: string;
    initialCategoriesId: string[];
    defaultVisibleItems: string[];
}

export class MenuPage extends React.Component<Props, State> {
    pageRef: React.RefObject<any>;
    scrollRef: React.RefObject<any>;
    tabButtons: React.RefObject<HTMLDivElement>;
    animatingComp: React.RefObject<HTMLDivElement>;
    animationCompleteTimeout: number;
    scrollPos: number;
    pagePos: any;
    initialIndex: number;
    visibleItems: Set<string>;
    itemObserver: IntersectionObserver;

    constructor(props: Props) {
        super(props);
        const { menu, initialCategoryId, filteredItems } = props;
        let initialIndex = initialCategoryId && menu ? menu.categories.indexOf(initialCategoryId) : 0;

        if (initialIndex === -1) {
            initialIndex = 0;
        }

        const initialCategoriesId: string[] = this.getPrevNextCategories(
            menu,
            filteredItems,
            initialIndex,
            initialIndex
        );
        const upItems: string[] = this.getPrevNextCategoriesItems(menu, filteredItems, initialIndex, "up");
        const downItems: string[] = this.getPrevNextCategoriesItems(menu, filteredItems, initialIndex, "down");

        this.state = {
            headerIndex: initialIndex,
            modalIsOpen: false,
            isAnimating: false,
            headerNavigate: true,
            noAnimation: initialIndex !== 0,
            initialCategoriesId,
            defaultVisibleItems: [...upItems, ...downItems],
        };
        this.pageRef = React.createRef();
        this.scrollRef = React.createRef();
        this.tabButtons = React.createRef();
        this.animatingComp = React.createRef();
        this.animationCompleteTimeout = 0;
        this.scrollPos = 0;
        this.pagePos = 0;
        this.initialIndex = initialIndex;
        this.visibleItems = new Set();

        this.itemObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            const targets = entries.filter((e) => e.isIntersecting).map(({ target }) => target);
            if (targets.length) {
                targets.forEach((target) => {
                    const id = target.id.split("_")[0].replace("animating", "");
                    this.addItemViewed(id);
                    this.itemObserver.unobserve(target);
                });
            }
        }, {});
    }

    getPrevNextCategoriesItems = (
        menu: Menu,
        filteredItems: CategoryIndexed<string[]>,
        index: number,
        direction: "up" | "down",
        initialItemsIds: string[] = [],
        items: number = 0
    ): string[] => {
        if (items < 6) {
            if (direction === "up") {
                const upCatId = menu.categories[index - 1];
                if (upCatId) {
                    const itemsArray = filteredItems[upCatId];
                    const lastItems = itemsArray.slice(Math.max(itemsArray.length - 6, 0));
                    initialItemsIds.push(...lastItems);
                    items += lastItems.length;

                    return this.getPrevNextCategoriesItems(
                        menu,
                        filteredItems,
                        index - 1,
                        direction,
                        initialItemsIds,
                        items
                    );
                }
            }

            if (direction === "down") {
                const downCatId = menu.categories[index + 1];
                if (downCatId) {
                    const itemsArray = filteredItems[downCatId];
                    const firstItems = itemsArray.slice(0, 6);

                    initialItemsIds.push(...firstItems);
                    items += firstItems.length;

                    return this.getPrevNextCategoriesItems(
                        menu,
                        filteredItems,
                        index + 1,
                        direction,
                        initialItemsIds,
                        items
                    );
                }
            }
        }
        return initialItemsIds;
    };

    getPrevNextCategories = (
        menu: Menu,
        filteredItems: CategoryIndexed<string[]>,
        initialIndex: number,
        index: number,
        initialCategoriesId: string[] = [],
        items: number = 0
    ): string[] => {
        let cat = menu.categories[index];
        if (cat) {
            initialCategoriesId.push(cat);
            items += filteredItems[cat].length + 1;
            if (items < 6) {
                const upIndex = initialIndex < index ? initialIndex : index;
                const downIndex = initialIndex > index ? initialIndex : index;
                if (downIndex + 1 < menu.categories.length) {
                    return this.getPrevNextCategories(
                        menu,
                        filteredItems,
                        initialIndex,
                        downIndex + 1,
                        initialCategoriesId,
                        items
                    );
                } else if (upIndex - 1 > 0) {
                    return this.getPrevNextCategories(
                        menu,
                        filteredItems,
                        initialIndex,
                        upIndex - 1,
                        initialCategoriesId,
                        items
                    );
                }
            }
        }
        return initialCategoriesId;
    };

    componentDidMount() {
        if (this.props.animatingMenu) {
            const animateScroll = this.animatingComp.current?.querySelector(".animating-category-list");
            if (animateScroll) {
                this.animationCompleteTimeout = window.setTimeout(() => {
                    const topPos = animateScroll.getBoundingClientRect().top || 0;
                    const offsetTop =
                        document.getElementById("animating_" + this.props.initialCategoryId)?.getBoundingClientRect()
                            .top || 0;
                    animateScroll.scrollTop = Math.max(offsetTop - topPos, 0);
                });
            }
        }

        const categoryId = this.props.menu.categories[this.initialIndex];
        this.trackFilterPreferenceData(categoryId);
    }

    trackVisibleItems = () => {
        const { menuData, trackAvailability } = this.props;
        if (!menuData) return;

        const items = Array.from(this.visibleItems);

        const trackingData = items.reduce((data, itemId) => {
            const category = menuData.categories[itemId];

            if (category?.waitTime) {
                data[itemId] = {
                    item_type: "category",
                    availability: `${category.waitTime} min`,
                    name: category.displayName,
                };

                return data;
            }

            const menuItem = menuData.items[itemId];

            if (menuItem) {
                const availability = getItemAvailability(menuItem);

                if (availability) {
                    data[itemId] = {
                        item_type: "item",
                        availability,
                        name: menuItem.displayName,
                    };
                }
            }

            return data;
        }, {} as AvailabilityTrackingAction);

        if (!!Object.keys(trackingData).length) {
            trackAvailability(trackingData);
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.animatingMenu !== this.props.animatingMenu && !this.props.animatingBetweenTabs) {
            const topPos = this.tabButtons.current?.getBoundingClientRect().bottom || 0;
            const offsetTop = document.getElementById("_" + this.props.initialCategoryId)?.offsetTop || 0;
            scrolling.scrollTo({ top: Math.max(offsetTop - topPos, 0) });
        }

        if (prevProps.modalToggle !== this.props.modalToggle) {
            this.setState({ modalIsOpen: true });
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.animationCompleteTimeout);
        this.trackVisibleItems();
    }

    static getDerivedStateFromProps(prevProps: Props, nextState: State): Partial<State> | null {
        if (
            (prevProps.showingItem && prevProps.showingItem !== nextState.noAnimation) ||
            (!prevProps.isActive && !prevProps.isActive !== nextState.noAnimation)
        ) {
            return {
                noAnimation: true,
            };
        }
        return null;
    }

    render() {
        const {
            menu,
            availableFilters,
            selectedFilters,
            categories,
            menuLines,
            isActive,
            handleChangeTab,
            showFooter,
            hasBanner,
        } = this.props;
        const { headerIndex, modalCategory } = this.state;
        let clampHeaderIndex = headerIndex;
        if (categories.length && (clampHeaderIndex < 0 || clampHeaderIndex >= menu.categories.length)) {
            if (modalCategory) {
                clampHeaderIndex = menu.categories.indexOf(modalCategory);
            }
            clampHeaderIndex = MenuPage.clamp(clampHeaderIndex, 0, menu.categories.length - 1);
        }
        const categoryId = menu.categories[clampHeaderIndex];
        const categoryAvailableFilters = availableFilters[categoryId];
        const categorySelectedFilters = selectedFilters[categoryId];
        const lineIndex =
            menuLines.length < 6
                ? 0
                : MenuPage.clamp(
                      menuLines.findIndex((line) => line.categoryIndex === clampHeaderIndex),
                      0,
                      menuLines.length - 6
                  );

        return (
            <>
                <CategoryPopup />
                <div
                    className={classNames("menu-page", "menus", isActive && "active-page", hasBanner && "with-banner")}
                    ref={this.animatingComp}
                >
                    {categories.length ? (
                        <>
                            <div className="menu-page__items-header__height" />
                            <div
                                className="menu-page__items-header menu-page__items-header__height"
                                ref={this.tabButtons}
                            >
                                {menu.categories.map(this.renderTab)}
                            </div>
                            <section className={handleChangeTab && "with-footer"}>
                                <div className="animating-category-list">
                                    {menuLines
                                        .slice(lineIndex, lineIndex + 6)
                                        .map((item, index) => this.renderLine(item, index, "animating"))}
                                    {showFooter && (
                                        <MenuPageFooter menuName={menu.displayName} onClick={handleChangeTab} />
                                    )}
                                </div>
                                <div
                                    className="category-list"
                                    key={"category-list" + menuLines.length}
                                    ref={this.pageRef}
                                >
                                    {menuLines.map((item, index) => this.renderLine(item, index, ""))}
                                </div>
                                {showFooter && <MenuPageFooter menuName={menu.displayName} onClick={handleChangeTab} />}
                            </section>
                            {categoryAvailableFilters && (
                                <FilterModal
                                    availableFilters={categoryAvailableFilters}
                                    selectedFilters={categorySelectedFilters}
                                    modalIsOpen={this.state.modalIsOpen}
                                    handleOnClick={this.handleFilter}
                                    handleApplyFilters={this.handleApplyFilters}
                                    category={modalCategory}
                                />
                            )}
                        </>
                    ) : (
                        <section className="empty-tab">
                            <MenuPageEmptyTab menuName={menu.displayName} onClick={handleChangeTab!} />
                        </section>
                    )}
                </div>
            </>
        );
    }

    animationComplete = () => {
        window.clearTimeout(this.animationCompleteTimeout);
        this.setState({ isAnimating: false, noAnimation: false, headerNavigate: false });
    };

    handleApplyFilters = (
        tagGroups: TagGroup[],
        selectedFilters: SelectedFilters,
        category?: string,
        changedTagValues?: ChangedTagValueMap
    ) => {
        const { menu, dietaryTagGroup } = this.props;
        const { headerIndex } = this.state;
        const categoryId = category || menu.categories[headerIndex];

        if (dietaryTagGroup) {
            const changedDietaryValues = changedTagValues?.[dietaryTagGroup.id] || [];
            for (const changedTagValue of changedDietaryValues) {
                this.props.trackMenuFoodPreferenceApplied(
                    changedTagValue.preferenceName,
                    changedTagValue.preferenceState
                );
            }
        }
        this.props.applyFilters(categoryId, tagGroups, selectedFilters);
        this.setState({ noAnimation: true });
    };

    handleFilter = () => {
        this.handleFilterInternal(undefined, true);
    };

    handleFilterInternal = (category?: string, preventAnimation?: boolean) => {
        if (this.state.modalIsOpen) {
            this.setState({ modalIsOpen: false, modalCategory: undefined, noAnimation: preventAnimation || false });
        } else {
            this.setState({ modalIsOpen: true, modalCategory: category, noAnimation: true });
        }
    };

    trackFilterPreferenceData = (categoryId: string) => {
        if (this.props.filteredMenuFoodPreferenceCategoryData) {
            const categoryPreferenceFilterData = this.props.filteredMenuFoodPreferenceCategoryData[categoryId];
            if (categoryPreferenceFilterData) {
                this.props.trackMenuFoodPreferenceFiltered(categoryPreferenceFilterData);
            }
        }
    };

    onTabSelected = (index: number) => {
        const {
            match: { path },
            tabParams,
            menu: { categories },
        } = this.props;
        const { headerIndex, noAnimation } = this.state;
        const diffIndex = headerIndex - index;
        const beforeId = categories[diffIndex < 0 ? index - 1 : index + 1];
        const categoryId = categories[index];
        const topPos = this.tabButtons.current?.getBoundingClientRect().bottom || 0;

        this.trackFilterPreferenceData(categoryId);

        if (diffIndex !== 0) {
            MenuPage.replaceUrlWithoutTransition(
                generatePath(path + "?tab-selection=" + index, {
                    ...tabParams,
                    categoryId,
                })
            );
        }
        const title = document.getElementById("_" + categoryId);
        const scrollTo = () => {
            scrolling.scrollTo({ top: title ? Math.max(title.offsetTop - topPos, 0) : 0, behavior: "smooth" });
            this.animationCompleteTimeout = window.setTimeout(this.animationComplete, 500);
        };
        if (Math.abs(diffIndex) > 1) {
            const before = document.getElementById("_" + beforeId);
            scrolling.scrollTo({ top: before ? Math.max(before.offsetTop - topPos, 0) : 0 });
            setTimeout(scrollTo);
        } else {
            scrollTo();
        }

        this.setState({
            isAnimating: headerIndex !== index,
            headerIndex: noAnimation ? headerIndex : index,
            headerNavigate: true,
        });
    };

    onCategoryVisible = (categoriesId: string[]) => {
        const {
            menu: { categories },
            showingItem,
            animatingMenu,
        } = this.props;
        const { headerNavigate, isAnimating, noAnimation } = this.state;
        const headerId = categories[this.state.headerIndex];
        window.clearTimeout(this.animationCompleteTimeout);
        if (!animatingMenu) {
            this.animationCompleteTimeout = window.setTimeout(this.animationComplete, 150);
        }
        const headerIndex = categories.findIndex((c) => c === categoriesId[categoriesId.length - 1]);
        if (
            noAnimation ||
            headerNavigate ||
            !categoriesId.length ||
            categoriesId.some((item) => item === headerId) ||
            isAnimating ||
            showingItem
        ) {
            return;
        }
        this.setState({
            headerIndex,
            isAnimating: false,
            headerNavigate: false,
        });

        categoriesId.forEach((catId) => this.addItemViewed(catId));
    };

    private renderTab = (categoryId: string, index: number) => {
        const { headerIndex, isAnimating, noAnimation } = this.state;

        const isActive = index === headerIndex;
        return (
            <TabButton
                noAnimation={noAnimation}
                key={"tabButton-" + categoryId}
                isActive={isActive}
                index={index}
                categoryId={categoryId}
                displayName={this.props.categories[index].displayName}
                onTabSelected={isAnimating ? undefined : this.onTabSelected}
            />
        );
    };

    private addItemViewed = (itemId: string) => this.visibleItems.add(itemId);

    private renderLine = (item: MenuLine, index: number, prefix: string = "") => {
        const { filteredItems, selectedFilters, availableFilters } = this.props;
        const { initialCategoriesId, defaultVisibleItems } = this.state;
        const menuLine = item;
        const categoryId = menuLine.categoryId;
        const category = menuLine.category;
        const categoryAvailableFilters = availableFilters[categoryId];
        const categorySelectedFilters = selectedFilters[categoryId];
        const canOpenFilter = !!(categoryAvailableFilters.price || categoryAvailableFilters.tagGroups.length);

        return (
            <LineItem
                type={category.type === "drink" ? "drinklisting" : "foodlisting"}
                categoryId={categoryId}
                menuItemIds={filteredItems[categoryId]}
                displayName={category.displayName}
                onCategoryVisible={this.onCategoryVisible}
                scrollElement={this.pageRef.current}
                isFiltered={isFiltered(categorySelectedFilters)}
                handleFilter={() => {
                    this.onTabSelected(menuLine.categoryIndex);
                    this.handleFilterInternal(categoryId);
                }}
                selectedFilters={categorySelectedFilters}
                canOpenFilter={canOpenFilter}
                relatedItem={menuLine.relatedItem}
                prefix={prefix}
                key={prefix + "title" + categoryId + index}
                initialCategoriesId={initialCategoriesId}
                defaultVisibleItems={defaultVisibleItems}
                resetFilter={() => {
                    this.onTabSelected(menuLine.categoryIndex);
                    this.handleApplyFilters(
                        categoryAvailableFilters.tagGroups,
                        {
                            tags: {},
                            price: undefined,
                        },
                        categoryId
                    );
                }}
                onItemVisible={this.addItemViewed}
                itemObserver={this.itemObserver}
            />
        );
    };

    // using props.history.replaceState triggers the page transition, but accessing history directly does not
    private static replaceUrlWithoutTransition(pathname: string) {
        const data = { back: true, name: pathname };
        triggerVirtualPageTrack(data);
        window.history.replaceState(data, "", undefined);
    }

    private static clamp(val: number, min: number, max: number) {
        return Math.min(Math.max(val, min), max);
    }
}
