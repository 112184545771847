import "../assets/OfferItemsPage.scss";

import React, { useEffect, useState } from "react";
import {
    getOfferData,
    getOfferIemsSelectionIndex,
    getSelectedOfferMinItems,
    getSelectedOfferOfferItems,
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { ModalContainer } from "src/common/modal";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { SimpleNavHeader } from "src/common/navigation";
import { Divider, Text } from "src/sharedComponents";
import { MenuItemCardContainer } from "src/features/menu";
import { MenuItemButton } from "src/features/menuitem";
import { Button, ButtonProps } from "src/sharedComponents/controls/button/Button";
import { actionCreators } from "../reducers";
import { getOfferItemInCartCount, getSelectedOfferItems } from "src/features/order/selectors";
import { ViewCartButton } from "src/features/order/component/ViewCartButton";
import { simplePlural } from "src/common/strings";

interface Props {
    buttonsProps?: ButtonProps;
}
export const OfferItemsPage = ({ buttonsProps }: Props) => {
    const selectedOffer = useSelector(getOfferData);
    const offerItems = useSelector(getSelectedOfferOfferItems);
    const itemsIndex = useSelector(getOfferIemsSelectionIndex);
    const itemsId = useSelector(getSelectedOfferItems);
    const totalSelected = useSelector(getOfferItemInCartCount);
    const minItems = useSelector(getSelectedOfferMinItems);

    const [open, setOpen] = useState(!!selectedOffer);

    const dispatch = useDispatch();

    const hasAllData = selectedOffer && !!itemsId.length && itemsIndex !== undefined;

    useEffect(() => {
        if (hasAllData) {
            setOpen(true);
        }
    }, [hasAllData]);

    const primaryButton = () => {
        if (minItems && minItems - totalSelected > 0) {
            return (
                <Button disabled>
                    Add {minItems - totalSelected}
                    {totalSelected ? " more" : ""} item{simplePlural(minItems - totalSelected)}
                </Button>
            );
        }
        if (offerItems?.length && itemsIndex !== undefined && offerItems?.length > itemsIndex + 1) {
            return (
                <Button
                    onClick={() => {
                        dispatch(actionCreators.setItemsSelectionIndex(itemsIndex + 1));
                    }}
                >
                    Next
                </Button>
            );
        }
        if (!minItems && !totalSelected) {
            return <Button disabled>Select a reward</Button>;
        }

        return (
            <>
                <ViewCartButton forceShow />
                <Button
                    onClick={() => {
                        buttonsProps?.onClick?.();
                        setTimeout(() => setOpen(false), 100);
                    }}
                    mode="outline"
                    secondary
                >
                    Explore full menu
                </Button>
            </>
        );
    };

    return (
        <ModalContainer
            isOpen={open}
            className={{
                base: "offer-items-modal",
                afterOpen: "offer-items-modal slide-in--after-open",
                beforeClose: "offer-items-modal--before-close slide-in--before-close",
            }}
            overlayClassName="slide-in-modal--overlay offer-items-modal--overlay"
            closeTimeoutMS={250}
            onAfterClose={() => {
                dispatch(actionCreators.setOffer(undefined));
                dispatch(actionCreators.setItemsSelectionIndex(undefined));
            }}
            parentSelector={() => document.querySelector(".party-wrapper") || document.body}
            allowBodyScroll
        >
            <LocationThemeContainer>
                <div className="offer-items-modal__page">
                    <div className="offer-items-modal__scroll-element">
                        <SimpleNavHeader
                            withBorder
                            closeToBack={itemsIndex === 0}
                            onBack={() =>
                                itemsIndex && itemsIndex > 0
                                    ? dispatch(actionCreators.setItemsSelectionIndex(itemsIndex - 1))
                                    : setOpen(false)
                            }
                        />

                        {selectedOffer && hasAllData && (
                            <>
                                <section className="offer-items-modal__top-section">
                                    {offerItems && offerItems.length > 1 && itemsIndex !== undefined && (
                                        <Text preset="g-12" mode="block" className="offer-page__subtitle">
                                            Step {itemsIndex + 1} of {offerItems.length}
                                        </Text>
                                    )}
                                    <Text preset="title-28" mode={["extra-bold", "block"]}>
                                        {selectedOffer.title}
                                    </Text>
                                    {selectedOffer.subtitle && (
                                        <Text preset="g-14" className="offer-page__subtitle">
                                            Discount applied at checkout.
                                        </Text>
                                    )}
                                </section>
                                <section>
                                    {itemsId.map(({ itemId, categoryId, itemsQuantity }, index) => (
                                        <div key={`item-${itemId}-${categoryId}-${index}`}>
                                            <div id={itemId + "_" + categoryId} className="offer-items-modal__item">
                                                <MenuItemButton
                                                    menuItemId={itemId}
                                                    categoryId={categoryId}
                                                    className="menuitemcard-link"
                                                >
                                                    <MenuItemCardContainer
                                                        menuItemId={itemId}
                                                        categoryId={categoryId}
                                                        quantity={itemsQuantity}
                                                    />
                                                </MenuItemButton>
                                            </div>
                                            {index < itemsId.length - 1 && <Divider small fullWidth />}
                                        </div>
                                    ))}
                                </section>
                            </>
                        )}
                    </div>
                    <footer className="offer-items-modal__footer">{primaryButton()}</footer>
                </div>
            </LocationThemeContainer>
        </ModalContainer>
    );
};
