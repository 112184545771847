import React from "react";

export const DietaryDairyfree = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M3.43146 11.1666H5.53546C7.20746 11.1666 8.36746 10.1106 8.36746 8.50265C8.36746 6.89465 7.20746 5.83065 5.53546 5.83065H3.43146V11.1666ZM4.56746 10.1666V6.83065H5.53546C6.63146 6.83065 7.20746 7.55865 7.20746 8.50265C7.20746 9.40665 6.59146 10.1666 5.53546 10.1666H4.56746Z"
            fill="white"
        />
        <path
            d="M9.17364 11.1666H10.3096V8.94265H12.8936V7.96665H10.3096V6.80665H12.9496V5.83065H9.17364V11.1666Z"
            fill="white"
        />
    </svg>
);

export const DietaryGlutenfree = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M3.16766 8.50268C3.16766 10.1827 4.44766 11.2707 6.00766 11.2707C6.97566 11.2707 7.73566 10.8707 8.27966 10.2627V8.22268H5.73566V9.19868H7.15966V9.84668C6.92766 10.0547 6.49566 10.2547 6.00766 10.2547C5.03966 10.2547 4.33566 9.51068 4.33566 8.50268C4.33566 7.49468 5.03966 6.75068 6.00766 6.75068C6.56766 6.75068 7.02366 7.04668 7.27166 7.42268L8.21566 6.91068C7.80766 6.27868 7.11166 5.74268 6.00766 5.74268C4.44766 5.74268 3.16766 6.81468 3.16766 8.50268Z"
            fill="white"
        />
        <path
            d="M9.18148 11.1667H10.3175V8.94268H12.9015V7.96668H10.3175V6.80668H12.9575V5.83068H9.18148V11.1667Z"
            fill="white"
        />
    </svg>
);

export const DietaryVegan = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M4.54856 11.1667H5.97256L8.02856 5.83068H6.73256L5.26056 9.93468L3.78056 5.83068H2.48456L4.54856 11.1667Z"
            fill="white"
        />
        <path
            d="M8.10906 8.50268C8.10906 10.1827 9.38906 11.2707 10.9491 11.2707C11.9171 11.2707 12.6771 10.8707 13.2211 10.2627V8.22268H10.6771V9.19868H12.1011V9.84668C11.8691 10.0547 11.4371 10.2547 10.9491 10.2547C9.98106 10.2547 9.27706 9.51068 9.27706 8.50268C9.27706 7.49468 9.98106 6.75068 10.9491 6.75068C11.5091 6.75068 11.9651 7.04668 12.2131 7.42268L13.1571 6.91068C12.7491 6.27868 12.0531 5.74268 10.9491 5.74268C9.38906 5.74268 8.10906 6.81468 8.10906 8.50268Z"
            fill="white"
        />
    </svg>
);

export const DietaryVegetarian = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M7.28959 11.1666H8.71359L10.7696 5.83065H9.47359L8.00159 9.93465L6.52159 5.83065H5.22559L7.28959 11.1666Z"
            fill="white"
        />
    </svg>
);

export const DietaryHalal = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M9.31793 11.1666H10.4619V5.83065H9.31793V7.92665H6.79793V5.83065H5.66193V11.1666H6.79793V8.92665H9.31793V11.1666Z"
            fill="white"
        />
    </svg>
);

export const DietaryKosher = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M9.21274 11.1666H10.6127L8.34874 8.33465L10.4687 5.83065H9.06874L7.18074 8.21465V5.83065H6.04474V11.1666H7.18074V9.52665L7.59674 9.03065L9.21274 11.1666Z"
            fill="white"
        />
    </svg>
);

export const DietaryNoGarlic = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M6.4748 11.1667H7.5708V5.83068H6.4348V9.28668L3.9628 5.83068H2.7948V11.1667H3.9308V7.58268L6.4748 11.1667Z"
            fill="white"
        />
        <path
            d="M8.36692 8.50268C8.36692 10.1827 9.64692 11.2707 11.2069 11.2707C12.1749 11.2707 12.9349 10.8707 13.4789 10.2627V8.22268H10.9349V9.19868H12.3589V9.84668C12.1269 10.0547 11.6949 10.2547 11.2069 10.2547C10.2389 10.2547 9.53492 9.51068 9.53492 8.50268C9.53492 7.49468 10.2389 6.75068 11.2069 6.75068C11.7669 6.75068 12.2229 7.04668 12.4709 7.42268L13.4149 6.91068C13.0069 6.27868 12.3109 5.74268 11.2069 5.74268C9.64692 5.74268 8.36692 6.81468 8.36692 8.50268Z"
            fill="white"
        />
    </svg>
);

export const DietaryNoOnion = () => (
    <svg
        className="multi-color"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8" r="8" fill="black" />
        <path
            d="M6.10603 10.664H7.20203V5.328H6.06603V8.784L3.59403 5.328H2.42603V10.664H3.56203V7.08L6.10603 10.664Z"
            fill="white"
        />
        <path
            d="M7.99815 8C7.99815 9.608 9.17415 10.76 10.7822 10.76C12.3982 10.76 13.5742 9.608 13.5742 8C13.5742 6.392 12.3982 5.24 10.7822 5.24C9.17415 5.24 7.99815 6.392 7.99815 8ZM12.4062 8C12.4062 8.992 11.7661 9.752 10.7822 9.752C9.79815 9.752 9.16615 8.992 9.16615 8C9.16615 7 9.79815 6.248 10.7822 6.248C11.7661 6.248 12.4062 7 12.4062 8Z"
            fill="white"
        />
    </svg>
);
export const DietaryQuick = () => (
    <svg
        className="multi-color"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8" r="8" fill="black" />
        <path
            d="M10.788 7.884C10.788 6.276 9.61204 5.124 7.99604 5.124C6.38804 5.124 5.21204 6.276 5.21204 7.884C5.21204 9.492 6.38804 10.644 7.99604 10.644C8.46004 10.644 8.89204 10.548 9.26804 10.372L9.69204 10.876L10.484 10.228L10.092 9.772C10.532 9.292 10.788 8.636 10.788 7.884ZM7.99604 9.636C7.01204 9.636 6.38004 8.876 6.38004 7.884C6.38004 6.884 7.01204 6.132 7.99604 6.132C8.98004 6.132 9.62004 6.884 9.62004 7.884C9.62004 8.268 9.52404 8.62 9.34804 8.9L8.74004 8.188L7.94804 8.828L8.55604 9.54C8.38804 9.604 8.19604 9.636 7.99604 9.636Z"
            fill="white"
        />
    </svg>
);
export const DietarySeasonal = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="black" />
        <path
            d="M5.80005 9.908C6.30405 10.412 7.04005 10.756 8.06405 10.756C9.50405 10.756 10.2 10.02 10.2 9.044C10.2 7.86 9.08005 7.596 8.20005 7.396C7.58405 7.252 7.13605 7.14 7.13605 6.78C7.13605 6.46 7.40805 6.236 7.92005 6.236C8.44005 6.236 9.01605 6.42 9.44805 6.82L10.08 5.988C9.55205 5.5 8.84805 5.244 8.00005 5.244C6.74405 5.244 5.97605 5.964 5.97605 6.852C5.97605 8.044 7.09605 8.284 7.96805 8.484C8.58405 8.628 9.04805 8.764 9.04805 9.164C9.04805 9.468 8.74405 9.764 8.12005 9.764C7.38405 9.764 6.80005 9.436 6.42405 9.044L5.80005 9.908Z"
            fill="white"
        />
    </svg>
);

export const DietaryNutfree = () => (
    <svg
        className="multi-color"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8.5" r="8" fill="black" />
        <path
            d="M7.06855 11.1666H8.16455V5.83065H7.02855V9.28665L4.55655 5.83065H3.38855V11.1666H4.52455V7.58265L7.06855 11.1666Z"
            fill="white"
        />
        <path
            d="M9.21667 11.1666H10.3527V8.94265H12.9367V7.96665H10.3527V6.80665H12.9927V5.83065H9.21667V11.1666Z"
            fill="white"
        />
    </svg>
);

export const DietarySpicy = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5 14.25C12.4 14.25 11.125 13.6 9.875 10.35C8.625 7.09997 8.25 5.79997 7.575 4.84997L7.35 4.57497H7.325C6.98425 4.21091 6.54241 3.95707 6.05627 3.84608C5.57013 3.73508 5.06191 3.77199 4.59691 3.95208C4.13192 4.13216 3.7314 4.44719 3.44684 4.85667C3.16227 5.26615 3.00666 5.75137 3 6.24997V6.34997C3 9.34997 7.975 16.225 14.475 16.25H14.525C14.7902 16.2467 15.0433 16.1381 15.2284 15.9482C15.4136 15.7584 15.5158 15.5027 15.5125 15.2375C15.5092 14.9723 15.4006 14.7192 15.2108 14.534C15.0209 14.3488 14.7652 14.2467 14.5 14.25Z"
            fill="black"
        />
        <path
            d="M3.625 3.3L3.825 3.175C4.2335 2.94545 4.6842 2.80089 5.15 2.75C5.00574 2.58862 4.82826 2.46037 4.62976 2.37406C4.43125 2.28775 4.21641 2.24543 4 2.25H3.775C3.225 1.275 1.875 0.75 1 0.75C0.867392 0.75 0.740215 0.802678 0.646447 0.896447C0.552678 0.990215 0.5 1.11739 0.5 1.25C0.5 1.38261 0.552678 1.50979 0.646447 1.60355C0.740215 1.69732 0.867392 1.75 1 1.75C1.6 1.75 2.55 2.125 2.9 2.75C2.71246 2.95602 2.5871 3.21094 2.53843 3.48525C2.48977 3.75956 2.51978 4.04204 2.625 4.3C2.88736 3.90254 3.22754 3.56236 3.625 3.3Z"
            fill="black"
        />
    </svg>
);

export const DietaryMildSpicy = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5 14.4834V14.4833C13.3761 14.4833 12.3424 14.3101 11.4209 13.5994C10.5242 12.9079 9.83246 11.7822 9.19055 10.1132C8.86336 9.26255 8.60204 8.56171 8.37953 7.96493C8.16803 7.39768 7.99158 6.92446 7.82695 6.50594C7.50203 5.67998 7.26817 5.18952 6.99096 4.79423L6.97154 4.77049L6.7896 4.57611C6.5488 4.31884 6.23657 4.13946 5.89303 4.06103C5.5495 3.98259 5.19035 4.00867 4.86175 4.13593C4.53316 4.26319 4.25013 4.48581 4.04903 4.77518C3.84879 5.06332 3.73891 5.40453 3.73333 5.75531V5.84999C3.73333 6.4276 3.98365 7.29527 4.52621 8.31434C5.05967 9.31631 5.84219 10.4014 6.82359 11.4022C8.79585 13.4134 11.4882 15.0047 14.4765 15.0167H14.519C14.5886 15.015 14.6548 14.9861 14.7035 14.9362C14.7529 14.8856 14.7801 14.8174 14.7792 14.7467C14.7783 14.6759 14.7494 14.6084 14.6987 14.559C14.6481 14.5096 14.5799 14.4824 14.5092 14.4833L14.5 14.4834ZM14.525 15.75H14.475C7.975 15.725 3 8.84999 3 5.84999V5.74999C3.00666 5.25138 3.16227 4.76616 3.44684 4.35668C3.7314 3.9472 4.13192 3.63218 4.59691 3.45209C5.06191 3.27201 5.57013 3.23509 6.05627 3.34609C6.54241 3.45709 6.98425 3.71093 7.325 4.07499H7.35L7.575 4.34999C8.07767 5.05744 8.41396 5.959 9.06914 7.71541C9.29376 8.31758 9.55586 9.02023 9.875 9.84999C11.125 13.1 12.4 13.75 14.5 13.75C14.7652 13.7467 15.0209 13.8488 15.2108 14.034C15.4006 14.2192 15.5092 14.4723 15.5125 14.7375C15.5158 15.0027 15.4136 15.2584 15.2284 15.4483C15.0433 15.6381 14.7902 15.7467 14.525 15.75Z"
            fill="black"
        />
        <path
            d="M3.625 2.8L3.825 2.675C4.2335 2.44545 4.6842 2.30089 5.15 2.25C5.00574 2.08862 4.82826 1.96037 4.62976 1.87406C4.43125 1.78775 4.21641 1.74543 4 1.75H3.775C3.225 0.775 1.875 0.25 1 0.25C0.867392 0.25 0.740215 0.302678 0.646447 0.396447C0.552678 0.490215 0.5 0.617392 0.5 0.75C0.5 0.882608 0.552678 1.00979 0.646447 1.10355C0.740215 1.19732 0.867392 1.25 1 1.25C1.6 1.25 2.55 1.625 2.9 2.25C2.71246 2.45602 2.5871 2.71094 2.53843 2.98525C2.48977 3.25956 2.51978 3.54204 2.625 3.8C2.88736 3.40254 3.22754 3.06236 3.625 2.8Z"
            fill="black"
        />
    </svg>
);

export const DietaryMediumSpicy = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5 14.9834V14.9833C13.3761 14.9833 12.3424 14.8101 11.4209 14.0994C10.5242 13.4079 9.83246 12.2822 9.19055 10.6132C8.86336 9.76255 8.60204 9.06171 8.37953 8.46493C8.16803 7.89768 7.99158 7.42446 7.82695 7.00594C7.50203 6.17998 7.26817 5.68952 6.99096 5.29423L6.97154 5.27049L6.7896 5.07611C6.5488 4.81884 6.23657 4.63946 5.89303 4.56103C5.5495 4.48259 5.19035 4.50867 4.86175 4.63593C4.53316 4.76319 4.25013 4.98581 4.04903 5.27518C3.84879 5.56332 3.73891 5.90453 3.73333 6.25531V6.34999C3.73333 6.9276 3.98365 7.79527 4.52621 8.81434C5.05967 9.81631 5.84219 10.9014 6.82359 11.9022C8.79585 13.9134 11.4882 15.5047 14.4765 15.5167H14.519C14.5886 15.515 14.6548 15.4861 14.7035 15.4362C14.7529 15.3856 14.7801 15.3174 14.7792 15.2467C14.7783 15.1759 14.7494 15.1084 14.6987 15.059C14.6481 15.0096 14.5799 14.9824 14.5092 14.9833L14.5 14.9834ZM14.525 16.25H14.475C7.975 16.225 3 9.34999 3 6.34999V6.24999C3.00666 5.75138 3.16227 5.26616 3.44684 4.85668C3.7314 4.4472 4.13192 4.13218 4.59691 3.95209C5.06191 3.77201 5.57013 3.73509 6.05627 3.84609C6.54241 3.95709 6.98425 4.21093 7.325 4.57499H7.35L7.575 4.84999C8.07767 5.55744 8.41396 6.459 9.06914 8.21541C9.29376 8.81758 9.55586 9.52023 9.875 10.35C11.125 13.6 12.4 14.25 14.5 14.25C14.7652 14.2467 15.0209 14.3488 15.2108 14.534C15.4006 14.7192 15.5092 14.9723 15.5125 15.2375C15.5158 15.5027 15.4136 15.7584 15.2284 15.9483C15.0433 16.1381 14.7902 16.2467 14.525 16.25Z"
            fill="black"
        />
        <path
            d="M14.5 14.25C12.4 14.25 11.125 13.6 9.87505 10.35H4.60181C6.57408 13.2453 10.1938 16.2335 14.475 16.25H14.525C14.7903 16.2466 15.0433 16.1381 15.2285 15.9482C15.4137 15.7583 15.5159 15.5027 15.5125 15.2375C15.5092 14.9722 15.4007 14.7192 15.2108 14.534C15.0209 14.3488 14.7653 14.2466 14.5 14.25Z"
            fill="black"
        />
        <path
            d="M3.625 3.3L3.825 3.175C4.2335 2.94545 4.6842 2.80089 5.15 2.75C5.00574 2.58862 4.82826 2.46037 4.62976 2.37406C4.43125 2.28775 4.21641 2.24543 4 2.25H3.775C3.225 1.275 1.875 0.75 1 0.75C0.867392 0.75 0.740215 0.802678 0.646447 0.896447C0.552678 0.990215 0.5 1.11739 0.5 1.25C0.5 1.38261 0.552678 1.50979 0.646447 1.60355C0.740215 1.69732 0.867392 1.75 1 1.75C1.6 1.75 2.55 2.125 2.9 2.75C2.71246 2.95602 2.5871 3.21094 2.53843 3.48525C2.48977 3.75956 2.51978 4.04204 2.625 4.3C2.88736 3.90254 3.22754 3.56236 3.625 3.3Z"
            fill="black"
        />
    </svg>
);

export const DietaryIcons = {
    "dietary-dairyfree": <DietaryDairyfree />,
    "dietary-glutenfree": <DietaryGlutenfree />,
    "dietary-vegan": <DietaryVegan />,
    "dietary-vegetarian": <DietaryVegetarian />,
    "dietary-halal": <DietaryHalal />,
    "dietary-kosher": <DietaryKosher />,
    "dietary-nogarlic": <DietaryNoGarlic />,
    "dietary-noonion": <DietaryNoOnion />,
    "dietary-quick": <DietaryQuick />,
    "dietary-seasonal": <DietarySeasonal />,
    "dietary-nutfree": <DietaryNutfree />,
    "dietary-spicy": <DietarySpicy />,
    "dietary-mild-spicy": <DietaryMildSpicy />,
    "dietary-medium-spicy": <DietaryMediumSpicy />,
};
