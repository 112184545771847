import React, { ComponentType, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingItemId } from "../selectors/pendingItem";
import { actionCreators as partyActions } from "../../order/reducers/party";
import { MenuItemModifier } from "../../menudata";
import { ModifierListType } from "../types";

export interface WithListTrackingProps {
    trackExpand?: (numberOptionsRevealed: number, listType: ModifierListType) => void;
}

interface InjectedListProps {
    modifierId: number;
    modifier: MenuItemModifier;
}

export const withListTracking =
    <P extends InjectedListProps>(WrappedComponent: ComponentType<P>): FC<P & WithListTrackingProps> =>
    ({ trackExpand, ...props }: WithListTrackingProps & InjectedListProps) => {
        const pendingItemId = useSelector(getPendingItemId);
        const menuItemModifierId = props.modifier.id;

        const dispatch = useDispatch();

        const trackExpandedModifier = useCallback(
            (numberOptionsRevealed: number, listType: ModifierListType) => {
                if (!!pendingItemId && !!menuItemModifierId) {
                    dispatch(
                        partyActions.trackExpandedModifiersOptions(
                            pendingItemId,
                            menuItemModifierId,
                            numberOptionsRevealed,
                            listType
                        )
                    );
                }
            },
            [dispatch, menuItemModifierId, pendingItemId]
        );

        return <WrappedComponent trackExpand={trackExpandedModifier} {...(props as P)} />;
    };
