import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { updatePaymentAmountOperation } from "../operations";
import { Dispatch } from "redux";
import { getAuthHeaders } from "src/common/auth";
import { PaymentGateway } from "src/common/payment";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { UpdatePaymentAmountInfo } from "src/features/payment/types";

export function updatePaymentAmount(
    dispatch: Dispatch,
    paymentGateway: PaymentGateway,
    updatePaymentAmountInfo: UpdatePaymentAmountInfo,
    inParty: boolean
) {
    return updatePaymentAmountOperation.invoke(async () => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

        headers.append("Content-Type", "application/json");

        const response = await orderApi.send(
            `/paymentgateway/${paymentGateway}/paymentamount`,
            {
                method: "PUT",
                body: JSON.stringify(updatePaymentAmountInfo),
                headers,
            },
            reattemptStrategies.limited
        );

        await ProblemDetailsError.throwError(response);

        return true;
    }, dispatch);
}
