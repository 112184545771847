import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { config } from "src/common/config";
import { browserTranslate } from "src/common/experience";
import { SearchIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import "../assets/MenuSearchButton.scss";

const SHOW_PAGE_COUNTER = 3;
let tapCounter = config.forceMenuSearchVisibility === "1" ? SHOW_PAGE_COUNTER : 0;

export interface Props {
    className?: string;
    innerClassName?: string;
    beforeOpen?: () => void;
    showMenuSearch: () => void;
}
export const MenuSearchButton = ({ className, innerClassName, beforeOpen, showMenuSearch }: Props) => {
    const [showButton, setShowButton] = useState(tapCounter >= SHOW_PAGE_COUNTER && !browserTranslate.isEnabled());

    useEffect(() => {
        const onTranslate = (isEnabled: boolean) => {
            setShowButton(!isEnabled && tapCounter >= SHOW_PAGE_COUNTER);
        };
        if (config.enableMenuSearch === "1") {
            browserTranslate.observeTranslate(onTranslate);
        }
        return () => {
            browserTranslate.disconnect(onTranslate);
        };
    }, []);

    if (config.enableMenuSearch !== "1") {
        return null;
    }

    const onClick = () => {
        if (browserTranslate.isEnabled()) return;
        if (tapCounter < SHOW_PAGE_COUNTER) {
            if (++tapCounter >= SHOW_PAGE_COUNTER) {
                setShowButton(true);
            }
        } else {
            beforeOpen && beforeOpen();
            showMenuSearch();
        }
    };

    return (
        <TappableDiv
            className={classNames("menu-search-button", className, showButton && "menu-search-button__show")}
            onClick={onClick}
        >
            <div className={classNames("menu-search-button__inner", innerClassName)}>
                {showButton && <SearchIcon />}
            </div>
        </TappableDiv>
    );
};
