import { Dispatch } from "redux";
import { accountMenuActions } from "src/features/accountmenu";
import { ENABLE_TESTING_KEY, getTestingFeatures } from "../persistence/testingFeatures";

export function setTestingFeaturesIfEnabled() {
    return (dispatch: Dispatch) => {
        const testingFeaturesEnabled = getTestingFeatures();
        dispatch(accountMenuActions.setEnableTestingFeatures(testingFeaturesEnabled));
    };
}

export function updateTestingFeatures(testingFeaturesEnabled: boolean) {
    return (dispatch: Dispatch) => {
        localStorage.setItem(ENABLE_TESTING_KEY, JSON.stringify(testingFeaturesEnabled));
        dispatch(accountMenuActions.setEnableTestingFeatures(testingFeaturesEnabled));
    };
}
