import * as React from "react";
import { FoodMenuItem, MenuItem, Tag } from "../../menudata";
import { MenuItemImage } from "../../menuitem";
import "../assets/FoodCard.scss";
import { DietaryTags } from "./DietaryTags";
import { PriceList } from "./PriceList";
import classNames from "classnames";
import { UnavailableIcon } from "src/sharedComponents/assets/icons";
import { Button, Counter, Text } from "src/sharedComponents";
import { Tags } from "./Tags";
import { MemberPriceIndicator } from "./MemberPriceIndicator";
import { WaitTimeBadge } from "./WaitTimeBadge";

export interface Props {
    menuItem: FoodMenuItem;
    dietaryTags: Tag[];
    showFromPrice?: boolean;
    onAddAllItem: (menuItem: MenuItem) => void;
    showAddAllItem: boolean;
    quantity?: number;
    hideProductImages?: boolean;
    showTags?: boolean;
}

export const FoodCard = ({
    menuItem,
    dietaryTags,
    showFromPrice,
    onAddAllItem,
    showAddAllItem,
    quantity,
    hideProductImages,
    showTags,
}: Props) => (
    <li className={classNames("foodcard", menuItem.available === false && "unavailable")} r-cmp="foodcard">
        {!hideProductImages && (
            <div className="l">
                <div className="foodcard__thumb-wrapper">
                    <MenuItemImage className="foodcard__thumb" images={menuItem.images} name="thumb" />
                    <WaitTimeBadge waitTime={menuItem.waitTime} />
                    {!!quantity && <Counter mode="border" className="foodcard__item-counter" quantity={quantity} />}
                </div>
                <Tags menuItem={menuItem} />
                {showAddAllItem && (
                    <Button onClick={() => onAddAllItem(menuItem)} className="foodcard__add-item-button">
                        Add
                    </Button>
                )}
            </div>
        )}
        <div className="r">
            <div className="foodcard__descriptors">
                <div className="foodcard__descriptors__name-wrapper">
                    <Text preset="g-16" mode="bold" className="foodcard__descriptors__name">
                        {hideProductImages && !!quantity && (
                            <Counter mode="border" className="foodcard__item-counter" quantity={quantity} />
                        )}
                        {hideProductImages && <Tags menuItem={menuItem} />}
                        {menuItem.displayName}
                    </Text>
                    {hideProductImages && <WaitTimeBadge waitTime={menuItem.waitTime} />}
                </div>
                {menuItem.description && (
                    <Text preset="g-14" className="foodcard__descriptors__description">
                        {menuItem.description}
                    </Text>
                )}
                {showTags && (
                    <div className="foodcard__descriptors__tags">
                        <MemberPriceIndicator menuItem={menuItem} />
                        <DietaryTags tags={dietaryTags} />
                    </div>
                )}
            </div>
            <div className="foodcard__attributes">
                <PriceList item={menuItem} showFromPrice={showFromPrice} />
            </div>
            <div className="foodcard__unavailable">
                <UnavailableIcon /> <Text preset="g-14"> Unavailable</Text>
            </div>
            {hideProductImages && showAddAllItem && (
                <Button onClick={() => onAddAllItem(menuItem)} className="foodcard__add-item-button">
                    Add
                </Button>
            )}
        </div>
    </li>
);
