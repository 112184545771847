export enum TypeKeys {
    PROCESSING = "AUTH0/GETTING_USER_INFO_PROCESSING",
    SUCCESS = "AUTH0/GETTING_USER_INFO_SUCCESS",
    FAILURE = "AUTH0/GETTING_USER_INFO_FAILURE",
}

export const auth0ActionCreators = {
    processing: () => ({ type: TypeKeys.PROCESSING }),
    success: (result: any) => ({ type: TypeKeys.SUCCESS, result }),
    failure: (result: any) => ({ type: TypeKeys.FAILURE, result }),
};

export interface Auth0QueryState {
    result: any | null;
    status: "processing" | "success" | "failure";
}

export type QueryProcessingAction = { type: TypeKeys.PROCESSING };
export type QuerySuccessAction = { type: TypeKeys.SUCCESS; result: any };
export type QuerytFailureAction = { type: TypeKeys.FAILURE; result: any };

export type Auth0QueryAction = QueryProcessingAction | QuerySuccessAction | QuerytFailureAction;

const initialState: Auth0QueryState = {
    result: null,
    status: "processing",
};

export function reducer(state: Auth0QueryState = initialState, action: Auth0QueryAction): Auth0QueryState {
    if (action.type === TypeKeys.PROCESSING) {
        return {
            ...state,
            status: "processing",
        };
    }

    if (action.type === TypeKeys.SUCCESS) {
        const { result } = action;
        return {
            ...state,
            status: "success",
            result: result,
        };
    }

    if (action.type === TypeKeys.FAILURE) {
        const { result } = action;
        return {
            ...state,
            status: "failure",
            result: result,
        };
    }

    return state;
}
