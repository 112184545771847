import { TagGroup } from "../../menudata";
import { CategoryIndexed, TagValueMap, SelectedFilters } from "../types";

export enum TypeKeys {
    APPLY_PREFERENCES = "APPLY_PREFERENCES",
    APPLY_FILTERS = "APPLY_FILTERS",
    APPLY_SORT = "APPLY_SORT",
}

export const actionCreators = {
    applyFilters: (categoryId: string, tagGroups: TagGroup[], filters: SelectedFilters): ApplyFiltersAction => ({
        type: TypeKeys.APPLY_FILTERS,
        categoryId,
        tagGroups,
        filters,
    }),
    applySort: (categoryId: string, sort: string): ApplySortAction => ({
        type: TypeKeys.APPLY_SORT,
        categoryId,
        sort,
    }),
    applyPreferences: (preferences: TagValueMap): ApplyPreferencesAction => ({
        type: TypeKeys.APPLY_PREFERENCES,
        preferences,
    }),
};

export interface State {
    filtering: FilteringState;
}

export type FilteringState = {
    preferences: TagValueMap;
    categoryFilters: CategoryIndexed<SelectedFilters | undefined>;
    sort: string | undefined;
};

const initialState: FilteringState = {
    preferences: {},
    categoryFilters: {},
    sort: undefined,
};

type ApplyFiltersAction = {
    type: TypeKeys.APPLY_FILTERS;
    categoryId: string;
    tagGroups: TagGroup[];
    filters: SelectedFilters;
};
type ApplySortAction = {
    type: TypeKeys.APPLY_SORT;
    categoryId: string;
    sort: string;
};
type ApplyPreferencesAction = {
    type: TypeKeys.APPLY_PREFERENCES;
    preferences: TagValueMap;
};

export type FilterAction = ApplyFiltersAction | ApplySortAction | ApplyPreferencesAction;

export function reducer(state = initialState, action: FilterAction): FilteringState {
    if (action.type === TypeKeys.APPLY_PREFERENCES) {
        const { preferences } = action;
        return {
            ...state,
            preferences,
        };
    }

    if (action.type === TypeKeys.APPLY_FILTERS) {
        const { categoryId, tagGroups, filters } = action;

        const newPreferences: TagValueMap = createTagValueMap(
            tagGroups.filter((g) => g.isUserPreference),
            filters
        );

        const newCategoryFilters: TagValueMap = createTagValueMap(
            tagGroups.filter((g) => !g.isUserPreference),
            filters
        );

        const { preferences } = state;
        let { categoryFilters } = state;

        if (categoryId) {
            categoryFilters = {
                ...categoryFilters,
                [categoryId]: {
                    ...categoryFilters[categoryId],
                    tags: newCategoryFilters,
                    price: filters.price,
                },
            };
        }

        return {
            ...state,
            preferences: {
                ...preferences,
                ...newPreferences,
            },
            categoryFilters,
        };
    }
    if (action.type === TypeKeys.APPLY_SORT) {
        const { sort } = action;

        return {
            ...state,
            sort,
        };
    }

    return state;
}

function createTagValueMap(groups: TagGroup[], filters: SelectedFilters): TagValueMap {
    return groups.reduce((map, group) => {
        map[group.id] = filters.tags && filters.tags[group.id] ? filters.tags[group.id].slice(0) : [];
        return map;
    }, {} as TagValueMap);
}
