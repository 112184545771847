import * as React from "react";
import { LegalPage } from "./LegalPage";
import "../assets/LegalPage.scss";
import { regionHelper } from "src/features/region";

interface Props {
    goBack?: () => void;
    closeIcon?: boolean;
}

export const PrivacyPolicy = ({ goBack, closeIcon }: Props) => {
    const region = regionHelper.region.id;
    const privacyUrl = `legal/app-meandu-privacy-policy-${region}`;

    return <LegalPage title="Privacy Policy" closeIcon={closeIcon} goBack={goBack} relativeUrl={privacyUrl} />;
};
