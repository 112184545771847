import "../assets/ModifierRadioListItem.scss";
import React, { useCallback, useRef } from "react";
import { MenuItemVariant, ModifierOption } from "../../menudata";
import { ModifierListItemDetails } from "./ModifierListItemDetails";
import { animateListItemBackground } from "../helpers";
import { ListItemBackground } from "../component/ListItemBackground";
import { OrderItemSelectedNestedModiferDisplayData } from "..";
import { NestedModifiersView } from "src/features/menuitem/components/NestedModifiersView";
import { Radio } from "src/sharedComponents/controls/radio";
import { useSelector } from "react-redux";
import { getActiveServiceMenuItemIds } from "src/features/menu/selectors";
import { getOptionItemReferenceNotInActiveService } from "src/features/menuitem/helpers";

export interface Props {
    prefix?: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    disabled: boolean;
    listItem: ModifierOption | MenuItemVariant;
    selectedNestedModifier?: OrderItemSelectedNestedModiferDisplayData[][];
    onNestedModifierEdit?: () => void;
}

export const ModifierRadioListItem = ({
    listItem,
    prefix,
    onChange,
    checked,
    disabled,
    selectedNestedModifier,
    onNestedModifierEdit,
}: Props) => {
    const backgroundRef = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<Animation | null>(null);
    const nestedOptions = selectedNestedModifier ? selectedNestedModifier[listItem.originalIndex] : undefined;
    const activeServiceMenuItemIds = useSelector(getActiveServiceMenuItemIds);
    const hide = getOptionItemReferenceNotInActiveService(listItem, activeServiceMenuItemIds);

    const handleChange = useCallback(
        (checked: boolean) => {
            animateListItemBackground(backgroundRef, animationRef);
            onChange(checked);
        },
        [onChange]
    );

    if (hide) {
        return null;
    }

    return (
        <>
            <li className="modifier-radio-list-item">
                <div className="modifier-radio-list-item__container">
                    <Radio disabled={disabled} checked={checked} onChange={handleChange}>
                        <ModifierListItemDetails listItem={listItem} prefix={prefix} disabled={disabled} />
                    </Radio>
                </div>
                <ListItemBackground ref={backgroundRef} />
            </li>
            <NestedModifiersView nestedOptions={nestedOptions} onChangeSelection={onNestedModifierEdit} />
        </>
    );
};
