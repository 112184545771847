import { backoff, BackoffOptions } from "./util/backoff";

const baseOptions: BackoffOptions = {
    initialDelay: 2000,
    maxDelay: 10000,
    multiplier: 1.5,
};

// Use this if there's no error handling (ie. it would have just displayed a spinner forever anyway)
export const forever = backoff(baseOptions);

// Use this if there's error handling
export const limited = backoff({
    ...baseOptions,
    maxRetries: 5,
});

// Use neither if the operation isn't retryable (most POST/DELETE's)
