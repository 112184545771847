import { GetCvvTokenFunc } from "../../../payment";
import { getStripe } from "../../../../common/payment";
import { StripeCardCvcElement, StripeElementChangeEvent } from "@stripe/stripe-js";
import { getStripeCvvTokenOperation, showStripeEnterCvvOperation } from "../operations";
import { timeoutAndError } from "../../../../common/shared";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { StripeCardPaymentMethod, stripeElementOptions, StripeHostedField } from "../types";
import { ShowEnterCvvFunc } from "../../types";
import { registerKeyboardClosed, registerKeyboardOpen } from "../../../../common/keyboard";
import { setHostedFieldValidity } from "../../../paymentMethods/actions/setHostedFieldValidity";

export const showStripeEnterCvv: ShowEnterCvvFunc = (dispatch, cardPaymentMethod) => {
    return showStripeEnterCvvOperation.invoke(async () => {
        const { apiKey } = cardPaymentMethod as StripeCardPaymentMethod;

        const initializeCvcField = async () => {
            const stripe = await getStripe(apiKey);
            const elements = stripe.elements();

            const cardCvcElement: StripeCardCvcElement = elements.create(StripeHostedField.CVV, {
                style: stripeElementOptions.style,
                placeholder: "",
            });

            cardCvcElement.on("change", ({ complete, error }: StripeElementChangeEvent) =>
                dispatch(setHostedFieldValidity(HostedField.CVV, complete, !!error))
            );

            const mountCardCvcElement = () =>
                new Promise<unknown>((resolve) => {
                    cardCvcElement.on("ready", resolve);
                    cardCvcElement.mount(`#${HostedField.CVV}`);
                });

            await mountCardCvcElement();

            cardCvcElement.on("focus", registerKeyboardOpen);
            cardCvcElement.on("blur", registerKeyboardClosed);

            return {
                stripe,
                cardCvcElement,
            };
        };

        const { stripe, cardCvcElement } = await Promise.race([
            initializeCvcField(),
            timeoutAndError(hostedFieldOptions.initializeTimeout, "Timed out initializing Stripe hosted fields"),
        ]);

        const getCvvToken: GetCvvTokenFunc = () =>
            getStripeCvvTokenOperation.invoke(async () => {
                const result = await stripe.createToken("cvc_update", cardCvcElement);

                if (result.error) {
                    throw new Error(result.error.message);
                }

                return result.token.id;
            }, dispatch);

        return getCvvToken;
    }, dispatch);
};
