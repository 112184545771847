import { OrderAgain, RoundItem } from "../types";

export interface State {
    orderAgain: OrderAgain;
}

enum TypeKeys {
    SET_ANOTHER_ROUND = "ORDER_AGAIN/SET_ANOTHER_ROUND",
    RESET_ANOTHER_ROUND = "ORDER_AGAIN/RESET_ANOTHER_ROUND",
}

export const actionCreators = {
    setAnotherRound: (items: RoundItem[]) => ({ type: TypeKeys.SET_ANOTHER_ROUND, items }),
    resetAnotherRound: () => ({ type: TypeKeys.RESET_ANOTHER_ROUND }),
};

export type SetAnotherRoundAction = { type: TypeKeys.SET_ANOTHER_ROUND; items: RoundItem[] };
export type ResetAnotherRoundAction = { type: TypeKeys.RESET_ANOTHER_ROUND };

export type OrderAgainActions = SetAnotherRoundAction | ResetAnotherRoundAction;

const initialState: OrderAgain = {};

export default function reducers(state: OrderAgain = initialState, action: OrderAgainActions): OrderAgain {
    switch (action.type) {
        case TypeKeys.SET_ANOTHER_ROUND:
            return {
                ...state,
                anotherRound: action.items
                    ? {
                          items: action.items,
                      }
                    : state.anotherRound,
            };
        case TypeKeys.RESET_ANOTHER_ROUND:
            return {
                ...state,
                anotherRound: undefined,
            };
        default:
            return state;
    }
}
