import { MemberActivity, Party, PartyCloseReason } from "../types";
import { AppDispatch, AppState } from "src/features";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { actionCreators } from "..";
import { replace } from "connected-react-router";
import { getVisibleMenuData } from "../../menu/selectors";
import { actionCreators as joinTabActionCreators } from "../../groupTabs/reducers/joinGroupTab";
import { actionCreators as newTabActionCreators } from "../../groupTabs/reducers/groupTabWizard";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { actionCreators as bannerActionCreators } from "../../banners/reducers";
import { actionCreators as cartActionCreators } from "../../order/reducers/cart";
import { actionCreators as openTableActions } from "../../openTable/reducers";
import { actionCreators as orderHeadCountActions } from "../reducers/orderHeadCount";
import { createVerifiedAccountActions, signInActions } from "../../signup";
import { getGroupTabAction } from "src/features/groupTabs/actions/getGroupTabAction";
import { getIsGroupTabOpen } from "src/features/groupTabs/selectors/activeGroupTab";
import { onboardingActionCreators } from "src/features/partyOnboarding";
import { paymentFlowActions } from "src/features/payment";
import { deleteMemberActivityInfo } from "src/features/partyOnboarding/persistence/memberActivity";

export function partyClosed(party: Party) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(actionCreators.closed(party));
        dispatch(cleanPartyClosed());

        switch (party.closeReason) {
            case PartyCloseReason.TIMEDOUT:
                dispatch(showModalMessage(modalMessages.timedOut()));
                break;
            case PartyCloseReason.VENUECLOSED:
                const venueName = getVisibleMenuData(getState())?.title;
                dispatch(showModalMessage(modalMessages.venueClosed(venueName)));
                break;
            default:
                dispatch(showModalMessage(modalMessages.tableClosed()));
                break;
        }
    };
}

export function cleanPartyClosed() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(replace("/join-table"));
        dispatch(accountMenuActionCreators.closeAccountMenu());

        dispatch(joinTabActionCreators.resetJoinGroupTab());
        const activeGroupTab = getIsGroupTabOpen(getState());
        if (activeGroupTab) {
            dispatch(getGroupTabAction());
        }

        dispatch(newTabActionCreators.completeGroupTabWizard());
        dispatch(paymentActionCreators.resetTips());
        dispatch(bannerActionCreators.resetBanners());
        dispatch(cartActionCreators.showCart(false));
        dispatch(createVerifiedAccountActions.completeCreateVerifiedAccountFlow());
        dispatch(onboardingActionCreators.clearOnboardingImages());
        dispatch(paymentFlowActions.completePaymentFlow());
        dispatch(signInActions.completeSignInFlow());
        dispatch(openTableActions.setActivity(MemberActivity.None));
        dispatch(orderHeadCountActions.resetOrderHeadCount());
        deleteMemberActivityInfo();
    };
}
