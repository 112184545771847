import { createSelector } from "reselect";
import { AppState } from "../..";
import { NavBarType } from "../../../common/navigation/types";
import { getNavBarStatus } from "../../../common/navigation";
import { getCurrentMemberActiveOrderItems } from ".";

export const getShowCart = (state: AppState) => state.cart.showCart;

export const getShowViewCart = createSelector(
    getCurrentMemberActiveOrderItems,
    getNavBarStatus,
    (currentMemberOrderItems, navBarStatus) => navBarStatus === NavBarType.MENU && !!currentMemberOrderItems?.length
);

export const getOrderItemQuantityTotal = createSelector(getCurrentMemberActiveOrderItems, (currentMemberOrderItems) => {
    if (!currentMemberOrderItems?.length) return 0;
    return currentMemberOrderItems.reduce((total, currItem) => (total += currItem.quantity), 0);
});
