import { MarketingState, OptInData, MarketingMetadata, TermsUpdate } from "./types";

export interface State {
    marketing: MarketingState;
}

enum TypeKeys {
    PROMPT_MARKETING = "MARKETING/PROMPT_MARKETING",
    MARKETING_METADATA = "MARKETING/MARKETING_METADATA",
    RESET_MARKETING = "MARKETING/RESET_MARKETING",
    PROMPT_ACCEPT_TERMS_UPDATE = "MARKETING/PROMPT_ACCEPT_TERMS_UPDATE",
    ACCEPT_TERMS_UPDATE = "MARKETING/ACCEPT_TERMS_UPDATE",
}

export const actionCreators = {
    promptMarketing: (optInData: OptInData) => ({ type: TypeKeys.PROMPT_MARKETING, optInData }),
    marketingMetadata: (metadata: MarketingMetadata) => ({ type: TypeKeys.MARKETING_METADATA, metadata }),
    resetMarketing: () => ({ type: TypeKeys.RESET_MARKETING }),
    promptAcceptTermsUpdate: (termsUpdate: TermsUpdate) => ({ type: TypeKeys.PROMPT_ACCEPT_TERMS_UPDATE, termsUpdate }),
    acceptTermsUpdate: () => ({ type: TypeKeys.ACCEPT_TERMS_UPDATE }),
};

type ShowMarketingAction = { type: TypeKeys.PROMPT_MARKETING; optInData: OptInData };
type MarketingMetadataAction = { type: TypeKeys.MARKETING_METADATA; metadata: MarketingMetadata };
type ResetMarketingAction = { type: TypeKeys.RESET_MARKETING };
type PromptAcceptTermsUpdateAction = { type: TypeKeys.PROMPT_ACCEPT_TERMS_UPDATE; termsUpdate: TermsUpdate };
type AcceptTermsUpdateAction = { type: TypeKeys.ACCEPT_TERMS_UPDATE };

export type RatingActions =
    | ShowMarketingAction
    | MarketingMetadataAction
    | ResetMarketingAction
    | PromptAcceptTermsUpdateAction
    | AcceptTermsUpdateAction;

const initialState: MarketingState = {
    optInData: {
        locationId: null,
        partyId: null,
    },
    termsUpdate: null,
};

export function reducer(state: MarketingState = initialState, action: RatingActions): MarketingState {
    switch (action.type) {
        case TypeKeys.RESET_MARKETING: {
            return {
                ...state,
                optInData: {
                    locationId: null,
                    partyId: null,
                },
            };
        }
        case TypeKeys.PROMPT_MARKETING: {
            return {
                ...state,
                prompt: false,
                optInData: {
                    ...action.optInData,
                },
            };
        }
        case TypeKeys.MARKETING_METADATA: {
            const { enabled, title, prompt, newsletterProvider, privacyPolicyUrl } = action.metadata;
            return {
                ...state,
                enabled: !!enabled,
                title,
                prompt,
                newsletterProvider,
                privacyPolicyUrl,
            };
        }
        case TypeKeys.PROMPT_ACCEPT_TERMS_UPDATE: {
            return {
                ...state,
                termsUpdate: action.termsUpdate,
            };
        }
        case TypeKeys.ACCEPT_TERMS_UPDATE: {
            return {
                ...state,
                termsUpdate: null,
            };
        }
        default: {
            return state;
        }
    }
}
