import { trackedEvent } from "src/common/events/reduxEventTracking";
import { FuseMenu, KeywordSearched, MenuSearch } from "../types";

export interface State {
    menuSearch: MenuSearch;
}

enum TypeKeys {
    SET_FUSE_MENU = "SEARCH/SET_FUSE_MENU",
    SHOW_MENU_SEARCH_PAGE = "SEARCH/SHOW_MENU_SEARCH_PAGE",
    KEYWORD_SEARCHED = "PARTY/KEYWORD_SEARCHED",
}

export const actionCreators = {
    setFuseMenu: (fuseMenu: FuseMenu) => ({ type: TypeKeys.SET_FUSE_MENU, fuseMenu }),
    showMenuSearchPage: (showPage: boolean) => ({ type: TypeKeys.SHOW_MENU_SEARCH_PAGE, showPage }),
    trackKeywordSearch: (data: KeywordSearched) => trackedEvent({ type: TypeKeys.KEYWORD_SEARCHED, ...data }),
};

export type SetFuseMenu = { type: TypeKeys.SET_FUSE_MENU; fuseMenu: FuseMenu };
export type ShowMenuSearchPage = { type: TypeKeys.SHOW_MENU_SEARCH_PAGE; showPage: boolean };
export type MenuSearchActions = SetFuseMenu | ShowMenuSearchPage;

const initialState: MenuSearch = {
    showPage: false,
};

export default function reducers(state: MenuSearch = initialState, action: MenuSearchActions): MenuSearch {
    switch (action.type) {
        case TypeKeys.SET_FUSE_MENU:
            return {
                ...state,
                fuseMenu: action.fuseMenu,
            };
        case TypeKeys.SHOW_MENU_SEARCH_PAGE:
            return {
                ...state,
                showPage: action.showPage,
            };
        default:
            return state;
    }
}
