import { GroupTabsWizardState, LimitedMenuType, NewGroupTabPage, TabType } from "../types";
import { trackedEvent } from "../../../common/events/reduxEventTracking";

export enum TypeKeys {
    GROUP_TAB_WIZARD_START = "GROUP_TAB_WIZARD/START",
    GROUP_TAB_WIZARD_SET_PAGE = "GROUP_TAB_WIZARD/SET_PAGE",
    GROUP_TAB_WIZARD_SET_NAME = "GROUP_TAB_WIZARD/SET_NAME",
    GROUP_TAB_WIZARD_SET_PACKAGE = "GROUP_TAB_WIZARD/SET_PACKAGE",
    GROUP_TAB_WIZARD_SET_LIMIT = "GROUP_TAB_WIZARD/SET_LIMIT",
    GROUP_TAB_WIZARD_SET_MENU_TYPE = "GROUP_TAB_WIZARD/SET_MENU_TYPE",
    GROUP_TAB_WIZARD_DONE = "GROUP_TAB_WIZARD/DONE",
    GROUP_TAB_WIZARD_LIMIT_INCREASED = "GROUP_TAB/LIMIT_INCREASED",
}

export const actionCreators = {
    startGroupTabWizard: (pages?: NewGroupTabPage[]) => ({ type: TypeKeys.GROUP_TAB_WIZARD_START, pages }),
    setGroupTabWizardPage: (page: NewGroupTabPage) => ({ type: TypeKeys.GROUP_TAB_WIZARD_SET_PAGE, page }),
    setGroupTabName: (tabName: string) => ({ type: TypeKeys.GROUP_TAB_WIZARD_SET_NAME, tabName }),
    setGroupTabLimit: (tabLimit?: number) => ({ type: TypeKeys.GROUP_TAB_WIZARD_SET_LIMIT, tabLimit }),
    setGroupTabMenuType: (menuType?: LimitedMenuType) => ({ type: TypeKeys.GROUP_TAB_WIZARD_SET_MENU_TYPE, menuType }),
    setGroupTabPackage: (packageId?: string) => ({ type: TypeKeys.GROUP_TAB_WIZARD_SET_PACKAGE, packageId }),
    completeGroupTabWizard: () => ({ type: TypeKeys.GROUP_TAB_WIZARD_DONE }),
    trackGroupTabLimitIncreased: (group_tab_id: string, new_limit: number, increasing_amount: number) =>
        trackedEvent({ type: TypeKeys.GROUP_TAB_WIZARD_LIMIT_INCREASED, group_tab_id, new_limit, increasing_amount }),
};

export type StartGroupTabFlowAction = { type: TypeKeys.GROUP_TAB_WIZARD_START; pages?: NewGroupTabPage[] };
export type SetGroupTabWizardPageAction = { type: TypeKeys.GROUP_TAB_WIZARD_SET_PAGE; page: NewGroupTabPage };
export type SetGroupTabNameAction = { type: TypeKeys.GROUP_TAB_WIZARD_SET_NAME; tabName?: string };
export type SetGroupTabLimitAction = { type: TypeKeys.GROUP_TAB_WIZARD_SET_LIMIT; tabLimit?: number };
export type SetGroupTabMenuTypeAction = { type: TypeKeys.GROUP_TAB_WIZARD_SET_MENU_TYPE; menuType?: LimitedMenuType };
export type SetGroupTabPackageAction = { type: TypeKeys.GROUP_TAB_WIZARD_SET_PACKAGE; packageId?: string };
export type CompletePaymentFlowAction = { type: TypeKeys.GROUP_TAB_WIZARD_DONE };

export type GroupTabsWizardActions =
    | StartGroupTabFlowAction
    | SetGroupTabWizardPageAction
    | SetGroupTabNameAction
    | SetGroupTabLimitAction
    | SetGroupTabMenuTypeAction
    | SetGroupTabPackageAction
    | CompletePaymentFlowAction;

const initialState: GroupTabsWizardState = {
    showWizard: false,
};

export function reducer(
    state: GroupTabsWizardState = initialState,
    action: GroupTabsWizardActions
): GroupTabsWizardState {
    if (action.type === TypeKeys.GROUP_TAB_WIZARD_START) {
        const { pages } = action;
        return {
            ...state,
            showWizard: !!pages?.length,
            pages,
            tabType: TabType.PAYONCREATE,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_SET_PAGE) {
        const { page } = action;
        return {
            ...state,
            page,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_SET_NAME) {
        const { tabName } = action;
        return {
            ...state,
            tabName,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_SET_LIMIT) {
        const { tabLimit } = action;
        return {
            ...state,
            tabLimit,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_SET_MENU_TYPE) {
        const { menuType } = action;
        return {
            ...state,
            menuType,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_SET_PACKAGE) {
        const { packageId } = action;
        return {
            ...state,
            packageId,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_WIZARD_DONE) {
        return {
            showWizard: false,
        };
    }

    return state;
}
