import { createSelector } from "reselect";
import { applyPromoCodeOperation, removePromoCodeOperation } from "../operations";
import { ProblemDetails, ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getRestaurantFlags } from "../../order/selectors/restaurantFlags";

export const getLocationPromotionsEnabled = createSelector(
    getRestaurantFlags,
    ({ promotionsEnabled }) => promotionsEnabled
);

export const getIsApplyingPromoCode = createSelector(
    applyPromoCodeOperation.getStatus,
    (status) => status === "processing"
);

export const getIsRemovingPromoCode = createSelector(
    removePromoCodeOperation.getStatus,
    (status) => status === "processing"
);

export const getApplyPromoCodeErrorDetails = createSelector(
    applyPromoCodeOperation.getError,
    (error) =>
        (ProblemDetailsError.isProblemDetailsError(error) ? error.problemDetails : undefined) as
            | ProblemDetails
            | undefined
);
