import { isBackCameraLabel, QrCodeError, SCAN_ERROR_DISMISS_DELAY, validateQrCodeContents } from "../shared/qr";
import { loadExternalScript, poll } from "../../shared";
import { CameraAccessDeniedMessage, QrScanStatus } from "../interface";
import { __ } from "../../strings";
import { normalizeError } from "../../error";
import * as React from "react";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";

interface Camera {
    id: string;
    label: string;
}

class QrCodeScanner {
    private observer: MutationObserver | null = null;
    private html5QrCode: any | null = null;
    private started: boolean = false;
    private cancelled: boolean = false;
    private onError: ((err: any) => void) | null = null;
    private timer: number | undefined = undefined;
    private barcodeType: BarcodeType;

    constructor(barcodeType: BarcodeType) {
        this.barcodeType = barcodeType;
    }

    private handleWindowErrorEvent = (event: ErrorEvent) => {
        event.preventDefault();
        this.onError && this.onError(event.error);
    };

    private listenForWindowErrors() {
        window.addEventListener("error", this.handleWindowErrorEvent);
    }

    private stopListenForWindowErrors() {
        window.removeEventListener("error", this.handleWindowErrorEvent);
    }

    public async scan(onStatusChanged: (status: QrScanStatus) => void) {
        await loadExternalScript("Html5Qrcode", "/html5-qrcode-2.0.3.min.js");
        const { Html5Qrcode } = window as any;

        const cameras = (await Html5Qrcode.getCameras()) as Camera[];
        if (!cameras || !cameras.length) {
            throw new QrCodeError("QrCodeCameraAccessDenied", "No cameras found");
        }
        const camera = cameras.find((c) => isBackCameraLabel(c.label)) ?? cameras.reverse()[0];

        if (this.cancelled) return ""; // May have been cancelled already

        this.html5QrCode = new Html5Qrcode("reader");

        return new Promise<string>(async (resolve, reject) => {
            this.onError = (err: any) => {
                this.stopListenForWindowErrors();
                this.started = true; // set started so cancel doesn't wait
                reject(err);
            };

            this.listenForWindowErrors();

            let lastContents: string | null = null;

            const clearError = () => {
                lastContents = null;
                onStatusChanged("waiting");
            };

            await this.html5QrCode
                .start(
                    camera.id,
                    {},
                    (contents: string) => {
                        clearTimeout(this.timer);

                        const isValid = this.barcodeType !== BarcodeType.QRCODE || validateQrCodeContents(contents);

                        if (isValid) {
                            this.stopListenForWindowErrors();
                            resolve(contents);
                        } else if (contents !== lastContents) {
                            onStatusChanged("error");
                        }

                        lastContents = contents;
                        this.timer = window.setTimeout(clearError, SCAN_ERROR_DISMISS_DELAY);
                    },
                    () => (this.started = true)
                )
                .catch((err: any) => {
                    clearTimeout(this.timer);
                    this.onError && this.onError(err);
                });
        });
    }

    public observeCanvas(onCameraReady: () => void) {
        const reader = document.getElementById("reader");
        if (!reader) return;

        this.observer = new MutationObserver((mutations) => {
            const addedElements = mutations
                .filter((mutation) => mutation.addedNodes)
                .reduce((nodes, mutation) => {
                    mutation.addedNodes.forEach((node) => nodes.push(node as Element));
                    return nodes;
                }, [] as Element[]);

            addedElements.forEach((element) => {
                if (element.nodeName === "CANVAS") {
                    const canvas = element as HTMLCanvasElement;
                    const scale = window.innerHeight / canvas.height;
                    reader.style.transform = `scale(${scale})`;
                    onCameraReady();
                    this.stopObserveCanvas();
                    return false;
                }
                return true;
            });
        });

        this.observer.observe(reader, { childList: true });
    }

    public stopObserveCanvas() {
        if (this.observer) {
            this.observer.disconnect();
            delete this.observer;
            this.observer = null;
        }
    }

    public async stopScan() {
        this.cancelled = true;
        clearTimeout(this.timer);
        if (this.html5QrCode) {
            try {
                await poll(() => this.started, 10000, 100);
                await this.html5QrCode.stop();
                this.html5QrCode.clear();
            } catch (err) {
                throw new QrCodeError("QrCodeUnknownError", "Failed to stop scanner");
            } finally {
                delete this.html5QrCode;
                this.html5QrCode = null;
                this.started = false;
                this.cancelled = false;
            }
        }
    }
}

let scanner: QrCodeScanner;

export const qr = {
    findQRCode: async (barcodeType: BarcodeType, onStatusChanged: (status: QrScanStatus) => void) => {
        try {
            scanner = new QrCodeScanner(barcodeType);
            scanner.observeCanvas(() => onStatusChanged("cameraReady"));
            return await scanner.scan(onStatusChanged);
        } catch (err) {
            if (err instanceof QrCodeError) throw err;
            if (typeof err === "string" && err.indexOf("NotAllowedError") !== -1) {
                throw new QrCodeError("QrCodeCameraAccessDenied");
            }
            throw new QrCodeError("QrCodeUnknownError", normalizeError(err).message);
        }
    },
    cancelFindQRCode: async () => {
        if (!scanner) return;
        scanner.stopObserveCanvas();
        await scanner.stopScan();
    },
    requiresTargetElement: true,
    getCameraAccessDeniedMessage: async (barcodeType: BarcodeType) => {
        const message: CameraAccessDeniedMessage = {
            text: (
                <>
                    {__(
                        "Allow camera access in your browser settings, or refresh the page and allow camera access when prompted."
                    )}
                    {barcodeType === BarcodeType.QRCODE && (
                        <>
                            <br />
                            <br />
                            {__("You can also use your default camera app.")}
                        </>
                    )}
                </>
            ),
        };
        return message;
    },
    storeCameraAccessDenied: true,
};
