import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdUnitItem } from "src/features/menudata";
import { addToOrder } from "src/features/order/actions/addToOrder";
import { OrderCampaigns, OrderCourseItem } from "src/features/order/reducers/types";
import { getActiveOrderByType, getIsConnected } from "src/features/order/selectors";
import { actionCreators as partyAction } from "../../order";
import { Text } from "src/sharedComponents";
import { MenuItemImage } from "src/features/menuitem";
import Swiper, { SwiperRefNode } from "react-id-swiper";
import { parsePrice } from "src/common/shared";
import { Price } from "src/features/menu/components/Price";
import { AddIcon, DecreaseIcon, IncreaseIcon } from "src/sharedComponents/assets/icons";
import "./QuickSell.scss";
import { createSelector } from "reselect";
import { getActiveService, getActiveServiceMenuItemLinkParams, getVisibleMenuData } from "src/features/menu/selectors";
import { getTopPage } from "src/common/navigation";
import { CampaignNotDisplayedReason, campaignsActionCreators } from "src/features/analytics/actions";
import {
    getFilteringFoodPreference,
    getFilteringFoodPreferenceWithName,
} from "src/features/filter/selectors/getFilteringState";
import { EnergyContent } from "../../menuitem/components/EnergyContent";
import { NetworkConnectedTappableSpan } from "src/features/notifications/components/NetworkConnectedTappableSpan";
import { NetworkConnectedTappableDiv } from "src/features/notifications/components/NetworkConnectedTappableDiv";
import { getQuickSellDrinksOnly, getQuickSellIds } from "../selectors";

const getQuickSellToDisplay = createSelector(
    getQuickSellIds,
    getVisibleMenuData,
    getActiveService,
    getFilteringFoodPreference,
    getActiveServiceMenuItemLinkParams,
    getActiveOrderByType,
    (quickSellIds, data, activeService, selectedFiltersTags, menuItemLinkParams, activeOrderByType) => {
        if (quickSellIds && data && activeService && quickSellIds[activeService.id]) {
            const { drinks } = activeOrderByType;

            return quickSellIds[activeService.id]
                .map((itemId) => {
                    const itemLinkParams = menuItemLinkParams.get(itemId);
                    const categoryId = itemLinkParams && itemLinkParams.categoryId;
                    const category = itemLinkParams && data.categories[itemLinkParams.categoryId];
                    const notHiddenDueToFilters =
                        !selectedFiltersTags.length ||
                        selectedFiltersTags.every((preference) => data.items[itemId]?.tags?.includes(preference));

                    return {
                        ...data.items[itemId],
                        courseId: category?.course ?? activeService.defaultCourse,
                        categoryId: categoryId || null,
                        quantity: 0,
                        itemId,
                        available:
                            !category || !notHiddenDueToFilters || category.unavailable
                                ? false
                                : data.items[itemId].available,
                    } as AdUnitItem;
                })
                .filter(
                    (item) =>
                        item.available !== false &&
                        !!item.price &&
                        !!item.availablePrices &&
                        !item.variants?.length &&
                        !item.modifiers?.some((mod) => !!mod.minSelection) &&
                        (!drinks?.length || item.type === "food") &&
                        !item.waitTime
                )
                .filter((item, index, array) => index === array.findIndex((i) => i.itemId === item.itemId))
                .slice(0, 5);
        }
        return [] as AdUnitItem[];
    }
);

function isQuickSellItem(orderItem: OrderCourseItem, quickSellId: string) {
    return orderItem.menuItemId === quickSellId && !orderItem.notes && !orderItem.modifiers?.length;
}

export const QuickSell = () => {
    const connected = useSelector(getIsConnected);
    const { food, drinks } = useSelector(getActiveOrderByType);
    const itemsToDisplay = useSelector(getQuickSellToDisplay);
    const drinksOnly = useSelector(getQuickSellDrinksOnly);
    const hideProductImages = useSelector(getVisibleMenuData)?.hideProductImages;
    const drinksOnlyOnLoad = useRef(drinksOnly || !food?.length);
    const [quickSellItems, setQuickItems] = useState(itemsToDisplay);
    const quickSellTrackData = {
        seen: new Set(itemsToDisplay.slice(0, 2).map((item) => item.itemId)),
        available: quickSellItems.map((item) => item.itemId),
        filters: useSelector(getFilteringFoodPreferenceWithName),
        allItems: useSelector(getQuickSellIds),
        foodAndDrinks: !drinksOnly,
        itemTypes: food?.length && drinks?.length ? "both" : food?.length ? "food" : drinks?.length ? "drinks" : "none",
    };

    const viewedItems = useRef(quickSellTrackData);
    const swiperRef = useRef<SwiperRefNode>(null);
    const dispatch = useDispatch();

    const removeItem = useCallback(
        (orderItemId: string, menuItemId: string) => dispatch(partyAction.removeFromOrder(orderItemId, menuItemId)),
        [dispatch]
    );
    const addItem = useCallback(
        (item: AdUnitItem, orderItemId: string | null, carouselPositionIndex?: number) => {
            dispatch(
                addToOrder(
                    item.itemId,
                    item.courseId,
                    item.categoryId,
                    orderItemId,
                    null,
                    undefined,
                    item.quantity,
                    OrderCampaigns.QUICK_SELL,
                    true,
                    carouselPositionIndex
                )
            );
        },
        [dispatch]
    );

    useEffect(() => {
        setQuickItems((quickItems) =>
            quickItems.map((item) => {
                const qsItem = (food ? food : [])
                    .concat(drinks ? drinks : [])
                    .find((foodItem) => isQuickSellItem(foodItem, item.itemId));
                return {
                    ...item,
                    orderItemId: qsItem ? qsItem.orderItemId : undefined,
                    quantity: qsItem ? qsItem.quantity : 0,
                } as AdUnitItem;
            })
        );
    }, [food, drinks]);

    const swiper = swiperRef.current?.swiper;

    useEffect(() => {
        const onActiveIndexChange = () => {
            const itemId = swiper?.realIndex !== undefined && quickSellItems[swiper.realIndex + 1]?.itemId;
            itemId && viewedItems.current.seen.add(itemId);
        };
        swiper?.on("slideChange", onActiveIndexChange);
        return () => {
            swiper?.off("slideChange", onActiveIndexChange);
        };
    }, [swiper, quickSellItems]);

    useEffect(() => {
        const trackedViewedItems = viewedItems.current;
        const page = getTopPage();
        return () => {
            const data = {
                current_page: page,
                "campaign.seen_item": JSON.stringify(Array.from(trackedViewedItems.seen)),
                "campaign.available_items": JSON.stringify(trackedViewedItems.available),
                "campaign.active_filters": JSON.stringify(trackedViewedItems.filters),
                "campaign.all_items": JSON.stringify(trackedViewedItems.allItems),
                "campaign.item_type": trackedViewedItems.itemTypes,
                "campaign.qs_food_and_drink_status": JSON.stringify(trackedViewedItems.foodAndDrinks),
            };
            if (trackedViewedItems.available.length > 1) {
                dispatch(
                    campaignsActionCreators.campaignImpression({ campaign_name: OrderCampaigns.QUICK_SELL, data })
                );
            } else {
                dispatch(
                    campaignsActionCreators.campaignNotDisplayed({
                        campaign_name: OrderCampaigns.QUICK_SELL,
                        noDisplayedReason: CampaignNotDisplayedReason.NOT_ENOUGH_ITEMS,
                        data,
                    })
                );
            }
        };
    }, [dispatch]);

    if (quickSellItems.length < 2) return null;

    const addQuickItem = (id: string, carouselPositionIndex?: number) => {
        setQuickItems((items) =>
            items.map((item) => {
                const quantity = item.itemId === id ? item.quantity + 1 : item.quantity;
                if (item.itemId === id) {
                    const orderItemId =
                        item.orderItemId ??
                        (food ? food : []).concat(drinks ? drinks : []).find((oi) => isQuickSellItem(oi, id))
                            ?.orderItemId;
                    addItem({ ...item, quantity }, orderItemId, carouselPositionIndex);
                }
                return {
                    ...item,
                    quantity,
                };
            })
        );
    };
    const removeQuickItem = (id: string) => {
        setQuickItems((items) =>
            items.map((item) => {
                const resItem = {
                    ...item,
                    quantity: item.itemId === id ? item.quantity - 1 : item.quantity,
                };
                if (item.itemId === id && removeItem) {
                    const orderItemId =
                        item.orderItemId ??
                        (food ? food : []).concat(drinks ? drinks : []).find((oi) => isQuickSellItem(oi, id))
                            ?.orderItemId;
                    if (orderItemId) {
                        if (resItem.quantity) {
                            // Adding exiting item with OrderItemId should update the quantity of existing item to the given quantity,
                            // Unlike Adding exiting item without the OrderItemId will add the specific quantity to the existing item
                            addItem(resItem, orderItemId);
                        } else {
                            // if there is no quantity we need to remove the item from the order
                            const itemToRemove = document.getElementById("order-item-" + orderItemId);
                            if (itemToRemove) {
                                itemToRemove.classList.add("force-hide");
                                // Collapse the Food title in case this is the last food item
                                if (food && food.length === 1) {
                                    document
                                        .getElementById("cart-view__content__items--food")
                                        ?.classList.add("force-hide");
                                }
                            }
                            removeItem(orderItemId, resItem.itemId);
                        }
                    }
                }
                return resItem;
            })
        );
    };

    return (
        <div
            className={classNames(
                "quick-sell",
                !connected && "quick-sell--disabled",
                hideProductImages && "hide-product-images"
            )}
        >
            <div className="quick-sell__title">
                <Text preset="title-20" mode={["bold", "block"]}>
                    {drinksOnlyOnLoad.current ? "Hungry?" : "Before you go"}
                </Text>
                <Text
                    className="quick-sell__description"
                    preset="g-14"
                    mode="block"
                    value={
                        drinksOnlyOnLoad.current
                            ? "Add a quick bite to your order."
                            : "Treat yourself to something extra."
                    }
                />
            </div>
            <Swiper
                ref={swiperRef}
                slidesPerView={Math.max(2, Math.floor(window.innerWidth / 164))}
                spaceBetween={8}
                allowTouchMove={quickSellItems.length > 2}
                freeMode={true}
                freeModeMomentumRatio={0.3}
                freeModeSticky={true}
                containerClass="quick-sell__swiper"
            >
                {quickSellItems.map((item, index) => (
                    <div className="quick-sell__item" key={`quick-sell-${item.itemId}`}>
                        <div className="quick-sell__adder__container">
                            {item.images && !hideProductImages && (
                                <MenuItemImage className="quick-sell__thumb" images={item.images} name="thumb" />
                            )}
                            <div className={classNames("quick-sell__adder__overlay", item.quantity && "show-picker")}>
                                <div className="quick-sell__adder__picker">
                                    <NetworkConnectedTappableSpan
                                        onTap={() => removeQuickItem(item.itemId)}
                                        disabled={item.quantity <= 0}
                                    >
                                        <DecreaseIcon color="black" />
                                    </NetworkConnectedTappableSpan>
                                    <Text className="quick-sell__adder__picker__quantity" preset="g-16" mode="bold">
                                        {item.quantity}
                                    </Text>
                                    <NetworkConnectedTappableSpan
                                        onTap={() => addQuickItem(item.itemId, index + 1)}
                                        disabled={item.quantity >= 99}
                                    >
                                        <IncreaseIcon color="black" />
                                    </NetworkConnectedTappableSpan>
                                </div>
                                <NetworkConnectedTappableDiv
                                    onTap={() => addQuickItem(item.itemId, index + 1)}
                                    className="quick-sell__adder__add"
                                >
                                    <AddIcon />
                                </NetworkConnectedTappableDiv>
                            </div>
                        </div>
                        <Text className="quick-sell__name" preset="g-14" mode="bold">
                            {item.displayName}
                        </Text>
                        <EnergyContent value={item.energyContent} className="energy-content--dark-grey" />
                        <div className="quick-sell__flex-wrapper">
                            {item.availablePrices ? (
                                <Price
                                    priceTextPreset="g-14"
                                    wrapperClassName="quick-sell__price"
                                    availablePrices={item.availablePrices}
                                />
                            ) : (
                                <Price
                                    priceTextPreset="g-14"
                                    price={parsePrice(item.price)}
                                    wrapperClassName="quick-sell__price"
                                />
                            )}
                            <Text
                                className={classNames("quick-sell__added", item.quantity && "quick-sell__added--show")}
                                preset="g-14"
                                mode="bold"
                                value="Added"
                            />
                        </div>
                    </div>
                ))}
            </Swiper>
        </div>
    );
};
