import { ClaimOfferResult, OfferData, OfferStatus, OffersState } from "../types";

export interface State {
    offers: OffersState;
}

enum TypeKeys {
    SET_OFFER = "OFFER/SET",
    OFFER_CLAIMED = "OFFER/CLAIMED",
    SET_AVAILABLE_OFFERS = "OFFER/SET_AVAILABLE_OFFERS",
    SET_SHOW_TOOLTIP = "OFFER/SET_SHOW_TOOLTIP",
    SET_DISCOVERABLE_OFFERS = "OFFER/SET_DISCOVERABLE_OFFERS",
    SET_ITEMS_SELECTION_INDEX = "OFFER/SET_ITEMS_SELECTION_INDEX",
}

export const actionCreators = {
    setOffer: (offer?: OfferData) => ({ type: TypeKeys.SET_OFFER, offer }),
    offerClaimResult: (claimedResult?: ClaimOfferResult) => ({ type: TypeKeys.OFFER_CLAIMED, claimedResult }),
    setAvailableOffers: (offers: OfferData[]) => ({
        type: TypeKeys.SET_AVAILABLE_OFFERS,
        offers,
    }),
    setDiscoverableOffers: (offers: OfferData[]) => ({
        type: TypeKeys.SET_DISCOVERABLE_OFFERS,
        offers,
    }),
    setShowTooltip: (showTooltip: boolean) => ({ type: TypeKeys.SET_SHOW_TOOLTIP, showTooltip }),
    setItemsSelectionIndex: (index?: number) => ({ type: TypeKeys.SET_ITEMS_SELECTION_INDEX, index }),
};

type SetOfferAction = { type: TypeKeys.SET_OFFER; offer?: OfferData };
type OfferClaimedAction = { type: TypeKeys.OFFER_CLAIMED; claimedResult: ClaimOfferResult };
type SetAllOffersAction = { type: TypeKeys.SET_AVAILABLE_OFFERS; offers: OfferData[] };
type SetShowTooltipAction = { type: TypeKeys.SET_SHOW_TOOLTIP; showTooltip: boolean };
type SetDiscoverableOffersAction = { type: TypeKeys.SET_DISCOVERABLE_OFFERS; offers: OfferData[] };
type SetItemsSelectionIndexAction = { type: TypeKeys.SET_ITEMS_SELECTION_INDEX; index?: number };

export type OfferActions =
    | SetOfferAction
    | OfferClaimedAction
    | SetAllOffersAction
    | SetShowTooltipAction
    | SetDiscoverableOffersAction
    | SetItemsSelectionIndexAction;

const initialState: OffersState = {};

export function reducer(state: OffersState = initialState, action: OfferActions): OffersState {
    if (action.type === TypeKeys.SET_OFFER) {
        const newOffers = state.availableOffers?.map((offer) =>
            offer.id === action.offer?.id ? { ...action.offer, isPartial: false } : offer
        );
        return {
            ...state,
            offerData: action.offer,
            availableOffers: newOffers,
        };
    }
    if (action.type === TypeKeys.OFFER_CLAIMED) {
        return {
            ...state,
            offerData: state.offerData && {
                ...state.offerData,
                status: action.claimedResult.claimed ? OfferStatus.CLAIMED : state.offerData.status,
            },
            claimOfferResult: action.claimedResult,
        };
    }
    if (action.type === TypeKeys.SET_AVAILABLE_OFFERS) {
        return {
            ...state,
            availableOffers: action.offers,
        };
    }
    if (action.type === TypeKeys.SET_SHOW_TOOLTIP) {
        return {
            ...state,
            showTooltip: action.showTooltip,
        };
    }
    if (action.type === TypeKeys.SET_DISCOVERABLE_OFFERS) {
        return {
            ...state,
            discoverableOffers: action.offers,
        };
    }
    if (action.type === TypeKeys.SET_ITEMS_SELECTION_INDEX) {
        return {
            ...state,
            itemsSelectionIndex: action.index,
        };
    }
    return state;
}
