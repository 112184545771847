import React, { useEffect, useRef } from "react";
import canvasConfetti, { GlobalOptions } from "canvas-confetti";
import "./Confetti.scss";
import classNames from "classnames";

export interface ConfettiProps {
    fire?: boolean;
    full?: boolean;
    onDone?: () => void;
    y?: number;
    x?: number;
    angle?: number;
    startVelocity?: number;
    particleCount?: number;
    spread?: number;
}

const globalOptions: GlobalOptions = {
    resize: true,
    useWorker: true,
};

export const Confetti = ({
    fire,
    full,
    onDone,
    y = 0.5,
    x = 0.5,
    angle = 90,
    startVelocity = 40,
    particleCount = 200,
    spread = 60,
}: ConfettiProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        let confetti: canvasConfetti.CreateTypes | null;
        const reducesMotion = window.matchMedia("(prefers-reduced-motion: reduce)");
        if (canvasRef.current && !reducesMotion.matches) {
            if (fire) {
                confetti = canvasConfetti.create(canvasRef.current, globalOptions);
                const donePromise = confetti({
                    particleCount,
                    spread,
                    startVelocity,
                    angle,
                    origin: {
                        x,
                        y,
                    },
                } as canvasConfetti.Options);

                if (onDone && donePromise) {
                    donePromise.then(() => {
                        onDone && onDone();
                    });
                }
            }
        }
        return () => {
            if (confetti) {
                confetti.reset();
            }
        };
    }, [canvasRef, fire, onDone, y, x, angle, startVelocity, particleCount, spread]);

    return <canvas ref={canvasRef} className={classNames("canvas-confetti", full && "canvas-confetti--full")} />;
};
