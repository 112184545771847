import moment from "moment";
import { LocationMenuData } from "src/features/menudata";
import { Party } from "src/features/order";
import { regionHelper } from "src/features/region";
import { DATE_TIME_FORMAT } from "src/common/time/utils";
import { getTableNumber } from "src/common/shared";

const KEY = "MEANDU_LAST_TABLE";

export type LastActiveInfo = {
    location: string;
    partyId: string;
    table: string;
    actualTable?: string;
    tableLabel?: string;
    locale: string;
    time: string;
    url: string | undefined;
};

export function getLastTableData(): LastActiveInfo | null {
    try {
        return JSON.parse(window.localStorage.getItem(KEY)!) as LastActiveInfo;
    } catch (err) {
        return null;
    }
}

export function saveLastTableData(party: Party, menuData: LocationMenuData | null, url: string | undefined) {
    const { id: partyId, restaurantId, tableNumber: table, tableLabel } = party;

    const data = {
        location: menuData?.title || restaurantId,
        partyId,
        table,
        actualTable: getTableNumber(party),
        tableLabel: tableLabel || undefined,
        locale: menuData?.locale || regionHelper.region.id,
        time: moment().format(DATE_TIME_FORMAT),
        url,
    } as LastActiveInfo;

    if (!data.url) {
        const prevData = getLastTableData();
        if (prevData?.partyId === data.partyId) {
            data.url = prevData.url;
        }
    }

    window.localStorage.setItem(KEY, JSON.stringify(data));
}

export function deleteLastTableToken() {
    window.localStorage.removeItem(KEY);
}
