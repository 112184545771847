import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "../../../common/auth";

export async function surveyDetails(locationId?: string, partyId?: string) {
    const response = await orderApi.send(`/feedback/surveydetails?locationId=${locationId}&partyId=${partyId}`, {
        method: "GET",
        headers: await getAuthHeaders(),
    });

    if (!response.ok) {
        throw response;
    }
    return await response.json();
}
