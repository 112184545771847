import { OrderItemBase, OrderItemOptionNestedModifier } from "../../types";
import { MenuItem, MenuItemVariant, ModifierOption } from "../../../menudata";
import { PriceResolver } from "../../../membership/selectors/getPriceResolver";
import { getSelectedModifierChoice } from "../../../menu/helpers";

export function calculateOrderItemTotal(
    menuItem: MenuItem,
    orderItem: OrderItemBase,
    priceResolver: PriceResolver
): number {
    if (!menuItem || !orderItem) {
        return 0;
    }

    let subtotal = 0;

    if (orderItem.variant !== null) {
        if (menuItem.variants) {
            const selectedVariant: MenuItemVariant | undefined = getSelectedModifierChoice(
                menuItem.variants,
                orderItem.variant
            );
            subtotal += priceResolver(selectedVariant?.availablePrices);
        }
    } else {
        subtotal += priceResolver(menuItem.availablePrices);
    }

    if (orderItem.modifiers && menuItem.modifiers) {
        for (const orderItemModifier of orderItem.modifiers) {
            const menuItemModifier = menuItem.modifiers[orderItemModifier.modifier];
            let isVariantModifier = false;

            // If the order item has a variant selected, validate that the current modifier exists on the active variant
            if (orderItem.variant !== null) {
                const activeVariant = menuItem.variants?.find(
                    ({ originalIndex }) => originalIndex === orderItem.variant
                );

                // Is the current looped modifier in the active variants modifiers list?
                isVariantModifier = !!activeVariant?.modifiers?.includes(orderItemModifier.modifier);
            }

            // If the order item has no variants OR the current modifier IS on the active variant, update subtotal, otherwise do nothing
            if (orderItem.variant === null || isVariantModifier) {
                for (const orderItemOption of orderItemModifier.options) {
                    const option: ModifierOption | undefined = getSelectedModifierChoice(
                        menuItemModifier.options,
                        orderItemOption
                    );
                    subtotal += priceResolver(option?.availablePrices);
                }

                // Recurse optionNestedModifiers and add total
                subtotal += calculateNestedModifierTotalRecursive(
                    orderItemModifier.optionNestedModifiers,
                    priceResolver
                );
            }
        }
    }

    return subtotal * orderItem.quantity;
}

const calculateNestedModifierTotalRecursive = (
    optionNestedModifiers: OrderItemOptionNestedModifier[] | undefined,
    priceResolver: PriceResolver
) =>
    optionNestedModifiers?.reduce((total, optionNestedModifier) => {
        total +=
            optionNestedModifier?.selectedNestedData?.reduce(
                (selectedOptionTotal, nestedData) =>
                    selectedOptionTotal + priceResolver(nestedData?.price) * (optionNestedModifier.quantity || 1),
                0
            ) ?? 0;

        if (!optionNestedModifier?.modifiers) return total;

        for (const nestedNestedModifier of optionNestedModifier.modifiers) {
            total += calculateNestedModifierTotalRecursive(nestedNestedModifier?.optionNestedModifiers, priceResolver);
        }

        return total;
    }, 0) ?? 0;
