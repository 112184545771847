import { useEffect, useRef, useState } from "react";

export * from "./exactInterval";

export function useRefValues<T>(value: T) {
    const ref = useRef<T>(value);
    useEffect(() => {
        ref.current = value;
    });
    return [ref];
}
export function usePrevious<T>(value: T) {
    const ref = useRef<T>(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useHasMounted() {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    return hasMounted;
}

export function useUnmountCancelPromise() {
    const cancelRef = useRef(() => {});
    const promiseRef = useRef(new Promise<void>((resolve) => (cancelRef.current = resolve)));
    useEffect(() => cancelRef.current, []);
    return promiseRef.current;
}
