import "../assets/CardPaymentButton.scss";

import React from "react";
import { Button } from "../../../sharedComponents";
import { useDispatch } from "react-redux";
import { PaymentButtonProps } from "../index";

export interface CardPaymentButtonProps extends PaymentButtonProps {
    value: string;
    disabled?: boolean;
}

export const CardPaymentButton = ({ value, disabled, onPayment }: CardPaymentButtonProps) => {
    const dispatch = useDispatch();
    return (
        <Button
            className="card-payment-button"
            mode="solid"
            disabled={disabled}
            onClick={() => dispatch(onPayment())}
            value={value}
        />
    );
};
