import "./CardFlip.scss";

import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TappableDiv } from "../../common/tappable";

export interface CardFlipProps {
    frontSide: ReactNode;
    backSide: ReactNode;
    autoFlipTimeMS?: number;
    flipDurationMS?: number;
    flipTimingFunction?: string;
    className?: string;
}

export const CardFlip = ({
    frontSide,
    backSide,
    autoFlipTimeMS,
    flipDurationMS,
    flipTimingFunction,
    className,
}: CardFlipProps) => {
    const [flipped, setFlipped] = useState<boolean | undefined>();
    const flipTimeout = useRef<number>();
    const clickHandler = () => {
        window.clearTimeout(flipTimeout.current);
        setFlipped((prevFlipped) => !prevFlipped);
    };

    useEffect(() => {
        if (autoFlipTimeMS) {
            flipTimeout.current = window.setTimeout(() => setFlipped(true), autoFlipTimeMS);
        }

        return () => window.clearTimeout(flipTimeout.current);
    }, [autoFlipTimeMS]);

    const contentFlippedClass = flipped ? "card-flipped" : flipped === false ? "card-unflip" : undefined;

    var style: CSSProperties = {};
    if (flipDurationMS) {
        style["--flip-duration"] = flipDurationMS + "ms";
    }
    if (flipTimingFunction) {
        style["--flip-timing-function"] = flipTimingFunction;
    }

    return (
        <TappableDiv className={classNames("card-flip", className)} onClick={clickHandler}>
            <div className={classNames("card-flip__content", contentFlippedClass)} style={style}>
                <div className={classNames("card-flip__card", "card-flip__front")}>{frontSide}</div>
                <div className={classNames("card-flip__card", "card-flip__back")}>{backSide}</div>
            </div>
        </TappableDiv>
    );
};
