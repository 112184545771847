import "./TabOverview.scss";

import React, { useCallback, useContext, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Text } from "../../../sharedComponents";
import {
    getDebits,
    getGroupTabData,
    getIsGroupTabOwner,
    getTabMembersExcludingOwner,
    getTabTypeName,
    getShowCloseConfirmation,
    getTabSpend,
} from "../selectors/activeGroupTab";
import { StatusBar } from "src/common/statusBar";
import { InviteToGroupTab } from "./InviteToGroupTab";
import { actionCreators } from "../reducers/activeGroupTab";
import { leaveGroupTabAction } from "../actions/leaveGroupTabAction";
import { TabOverviewHeader } from "./TabOverviewHeader";
import {
    ShareIcon,
    MembersIcon,
    InvoiceIcon,
    MoneyBoxPigIcon,
    GastronomyIcon,
} from "../../../sharedComponents/assets/icons";
import { closeGroupTabConfirmation } from "../actions/closeGroupTabAction";
import { TabSettingCard } from "./TabSettingCard";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { TabMembersModal } from "./TabMembersModal";
import { TabOrdersModal } from "./TabOrdersModal";
import { startGroupTabWizardAction } from "../actions/startGroupTabWizardAction";
import { NewGroupTabWizard } from "./NewGroupTabWizard";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { TipSelectionModal } from "../../tip/components/TipSelectionModal";
import { actionCreators as paymentActionCreators, CustomTip } from "../../payment/reducers";
import { getTipLevel, getCustomTip, calculateTipAmount, calculateTipPercentage } from "../../payment/selectors";
import { TabDefaultTipDisclaimer } from "./TabDefaultTipDisclaimer";

interface Props {
    heroImage?: string;
}

export const TabOverview = ({ heroImage }: Props) => {
    const activeTab = useSelector(getGroupTabData);
    const isGroupTabOwner = useSelector(getIsGroupTabOwner);
    const tabTypeName = useSelector(getTabTypeName);
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const tabDebits = useSelector(getDebits);
    const tabMembers = useSelector(getTabMembersExcludingOwner);
    const showCloseConfirmation = useSelector(getShowCloseConfirmation);
    const activeTabSpend = useSelector(getTabSpend);
    const customTip = useSelector(getCustomTip);
    const tipLevel = useSelector(getTipLevel);

    const [startTabSpend, setStartTabSpend] = useState(activeTabSpend);

    const dispatch = useDispatch();

    const tabTipAmount = useMemo(
        () => calculateTipAmount(customTip, tipLevel, startTabSpend),
        [customTip, tipLevel, startTabSpend]
    );

    const tabTipPercentage = useMemo(
        () => (startTabSpend ? calculateTipPercentage(customTip, tipLevel, startTabSpend) : 0),
        [customTip, tipLevel, startTabSpend]
    );

    const confirmCloseTab = useCallback(
        (tipAmount?: number, tabTipPercentage?: number, customTip?: CustomTip) =>
            dispatch(closeGroupTabConfirmation(tipAmount, tabTipPercentage, customTip)),
        [dispatch]
    );

    const onConfirmTip = useCallback(() => {
        confirmCloseTab(tabTipAmount, tabTipPercentage, customTip);
        dispatch(paymentActionCreators.resetTips());
    }, [dispatch, confirmCloseTab, tabTipAmount, tabTipPercentage, customTip]);

    const closeTab = useCallback(() => {
        if (activeTab?.tips?.enabled) {
            setStartTabSpend(activeTabSpend);
            dispatch(paymentActionCreators.showTipModal(true));
        } else {
            confirmCloseTab();
        }
    }, [dispatch, activeTab, confirmCloseTab, activeTabSpend]);

    const hideTipModal = useCallback(() => dispatch(paymentActionCreators.resetTips()), [dispatch]);

    const leave = useCallback(() => {
        dispatch(leaveGroupTabAction());
    }, [dispatch]);

    const invite = useCallback(() => {
        dispatch(actionCreators.showInviteToGroupTab(true));
    }, [dispatch]);

    const showMembersPage = useCallback(() => {
        dispatch(actionCreators.showTabMembers(true));
    }, [dispatch]);

    const showOrdersPage = useCallback(() => {
        dispatch(actionCreators.showTabOrders(true));
    }, [dispatch]);

    const showLimitPage = useCallback(() => {
        dispatch(startGroupTabWizardAction({ increasingGroupTab: true }));
    }, [dispatch]);

    const tabLimit = useMemo(
        () => (activeTab?.limit ? `${menuDataLocale.formatCurrencyNoDigits(activeTab.limit)}` : 0),
        [activeTab, menuDataLocale]
    );

    useEffect(() => {
        if (showCloseConfirmation) {
            closeTab();
        }
    }, [showCloseConfirmation, closeTab]);

    const renderTipConfirmButton = (disabled: boolean = false) => {
        return <NetworkConnectedButton mode="solid" value="Continue" disabled={disabled} onClick={onConfirmTip} />;
    };

    if (!activeTab) return null;

    return (
        <div className="tab-overview">
            <StatusBar backgroundColor={"#000"} />
            <div className="tab-overview__body">
                <TabOverviewHeader heroImage={heroImage} />
                <div className="tab-overview__button-row">
                    {isGroupTabOwner && (
                        <Button
                            className="tab-overview__button-row__button"
                            onClick={showMembersPage}
                            mode="outline"
                            secondary
                            leftIcon={<MembersIcon />}
                            value="Members"
                            flexDirection="column"
                            preset="large"
                            pillValue={tabMembers?.length || undefined}
                        />
                    )}
                    <Button
                        className="tab-overview__button-row__button"
                        onClick={showOrdersPage}
                        mode="outline"
                        secondary
                        leftIcon={<InvoiceIcon />}
                        value="Orders"
                        flexDirection="column"
                        preset="large"
                        pillValue={tabDebits?.length || undefined}
                    />
                    {isGroupTabOwner && (
                        <Button
                            className="tab-overview__button-row__button"
                            onClick={invite}
                            mode="outline"
                            secondary
                            leftIcon={<ShareIcon />}
                            value="Invite"
                            flexDirection="column"
                            preset="large"
                        />
                    )}
                </div>
                <Divider extraHeight fullWidth />
                {isGroupTabOwner && (
                    <div className="tab-overview__settings">
                        <Text
                            mode="bold"
                            preset="g-18"
                            value="Tab settings"
                            className="tab-overview__settings__title"
                        />
                        <TabSettingCard
                            icon={<MoneyBoxPigIcon />}
                            title="Spend limit"
                            onCtaClick={showLimitPage}
                            subtitle="Block orders once your spend limit has been reached."
                            ctaText={tabLimit ? "Edit" : "Add"}
                            currentSettingText={tabLimit || undefined}
                        />
                        {activeTab.packageName && (
                            <TabSettingCard
                                icon={<GastronomyIcon />}
                                title="Package"
                                subtitle="Packages restrict what your members can order."
                                currentSettingText={activeTab.packageName}
                            />
                        )}
                        <Divider space="none" fullWidth extraHeight />
                    </div>
                )}
                <div className="tab-overview__footer-actions">
                    <NetworkConnectedButton
                        className="tab-overview__footer-actions__button"
                        value={isGroupTabOwner ? `Close ${tabTypeName}` : `Leave ${tabTypeName}`}
                        onClick={isGroupTabOwner ? closeTab : leave}
                        mode="outline"
                        secondary
                    />
                    {isGroupTabOwner && (
                        <TabDefaultTipDisclaimer className="tab-overview__footer-actions__disclaimer" />
                    )}
                </div>
            </div>
            {isGroupTabOwner && (
                <>
                    <InviteToGroupTab />
                    <TabMembersModal />
                    <NewGroupTabWizard />
                    <TipSelectionModal
                        renderCallToAction={renderTipConfirmButton}
                        onClose={hideTipModal}
                        calculatedTipAmount={tabTipAmount}
                        calculatedTipPercentage={tabTipPercentage}
                    />
                </>
            )}
            <TabOrdersModal />
        </div>
    );
};
