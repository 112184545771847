export const ENABLE_TESTING_KEY = "enableTestingFeatures";
export const getTestingFeatures = () => {
    const enableTestingFeatures = localStorage.getItem(ENABLE_TESTING_KEY);
    if (enableTestingFeatures) {
        try {
            const parsed = JSON.parse(enableTestingFeatures);
            if (typeof parsed === "boolean") {
                return parsed;
            }
        } catch (err) {
            localStorage.setItem(ENABLE_TESTING_KEY, JSON.stringify(false));
        }
    }
    return false;
};
