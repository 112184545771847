import "../assets/SsoButton.scss";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../sharedComponents";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import { getIdentitySource, IdentityValidationRequest, SsoProvider } from "src/common/sso";
import { sso } from "../../../common/experience";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { AppleSignInIcon, FacebookSignInIcon, GoogleSignInIcon } from "../../../sharedComponents/assets/icons";
import { ModalMessagePrimaryButton } from "../../modalMessage/reducers/modalMessage";
import { ssoSignInOperation } from "../operations";
import { signInActions } from "../reducers";
import classNames from "classnames";
import { getIsAnonymous } from "../../accounts/selectors";

interface Props extends ModalMessagePrimaryButton {
    ssoProvider: SsoProvider;
    onSignIn: (request: IdentityValidationRequest) => void;
}

const getIcon = (ssoProvider: SsoProvider) => {
    switch (ssoProvider) {
        case "Google":
            return <GoogleSignInIcon />;
        case "Apple":
            return <AppleSignInIcon />;
        case "Facebook":
            return <FacebookSignInIcon />;
    }
};

const defaultWrapper = (action: () => void) => {
    action();
};

export const SsoButton = ({ ssoProvider, onSignIn, closeAndAction }: Props) => {
    const dispatch = useDispatch();

    const isAnonymous = useSelector(getIsAnonymous);

    const buttonElementRef = useRef<HTMLDivElement>(null);
    const onSignInRef = useRef(onSignIn);
    onSignInRef.current = onSignIn;
    const isAnonymousRef = useRef(isAnonymous);
    isAnonymousRef.current = isAnonymous;

    const signIn = useCallback(
        (isProviderSource?: boolean) =>
            ssoSignInOperation.invoke(async () => {
                if (isAnonymousRef.current) {
                    dispatch(signInActions.trackLoginSourceClicked(ssoProvider));
                } else {
                    dispatch(signInActions.trackAccountLinkSourceClicked(ssoProvider));
                }

                try {
                    if (sso[ssoProvider].renderButton && !isProviderSource) {
                        throw new Error(`${ssoProvider} button not rendered`);
                    }

                    const identity = await sso[ssoProvider].signIn();

                    if (identity) {
                        // request will be null if the user cancelled
                        (closeAndAction ?? defaultWrapper)(() => {
                            onSignInRef.current({
                                ...identity,
                                source: getIdentitySource(ssoProvider),
                            });
                        });
                    }
                } catch (err) {
                    dispatch(showModalMessage(modalMessages.loginFailed()));
                    throw err;
                }
            }, dispatch),
        [dispatch, ssoProvider, closeAndAction]
    );

    const onClick = useCallback(() => signIn(), [signIn]);

    useEffect(() => {
        if (!sso[ssoProvider].isAvailable()) return;

        try {
            sso[ssoProvider].renderButton?.(buttonElementRef.current!);
        } catch (err) {
            dispatch(signInActions.trackInitializeLoginSourceFailed(ssoProvider, err));
        }
    }, [dispatch, ssoProvider]);

    useEffect(() => {
        let handleProviderIframeClick: (() => void) | undefined;
        if (sso[ssoProvider].renderButton && sso[ssoProvider].isAvailable()) {
            handleProviderIframeClick = () => {
                setTimeout(async () => {
                    const button = document.activeElement?.closest(".sso-provider-button");
                    if (button?.classList.contains(ssoProvider)) {
                        await signIn(true);
                    }
                });
            };
            window.addEventListener("blur", handleProviderIframeClick);
        }
        return () => {
            if (handleProviderIframeClick) {
                window.removeEventListener("blur", handleProviderIframeClick);
            }
        };
    }, [ssoProvider, signIn]);

    if (!sso[ssoProvider].isAvailable()) return null;

    return (
        <div className="sso-button">
            <Button
                leftIcon={getIcon(ssoProvider)}
                mode={"outline"}
                value={`Continue with ${ssoProvider}`}
                unthemed
                onClick={onClick}
            />
            {sso[ssoProvider].renderButton && (
                <div className={classNames("sso-provider-button", ssoProvider)} ref={buttonElementRef} />
            )}
        </div>
    );
};
