import { getIsFlexJoinPath, getIsTabJoinPath, getIsTakeawayPath } from "./url";

const MEANDU_TOKEN_BASE_URL = "https://app.meandu.com.au"; // Standard production URL
const CURRENT_SITE_TOKEN_URL = window.location.origin; // Allows for localhost PWA testing
const MEANDU_WEB_TOKEN_PATTERN = /https:\/\/[\w-]+\.meandu\.com/; // Allows the native app to read any non-production PWA tags

export const SCAN_ERROR_DISMISS_DELAY = 5000;

export type QrCodeErrors = "QrCodeCameraAccessDenied" | "QrCodeUnknownError" | "FindQrCodeTimeout";

export class QrCodeError extends Error {
    constructor(public errorCode: QrCodeErrors, public additionalInfo?: string) {
        super(`QrCodeError: ${errorCode}${additionalInfo ? `: ${additionalInfo}` : ""}`);
        // TODO: Look at setting an ES6 target so we can remove this
        Object.setPrototypeOf(this, QrCodeError.prototype);
    }
}

export const validateQrCodeContents = (contents: string) => {
    try {
        const url = new URL(contents);
        const { origin, pathname } = url;
        const validOrigin =
            origin === MEANDU_TOKEN_BASE_URL ||
            origin === CURRENT_SITE_TOKEN_URL ||
            MEANDU_WEB_TOKEN_PATTERN.test(origin);

        if (!validOrigin) return false;
        if (pathname === "/qr") return true;

        return getIsTabJoinPath(pathname) || getIsTakeawayPath(pathname) || getIsFlexJoinPath(pathname);
    } catch (err) {
        return false;
    }
};

const backCameraKeywords = [
    "rear",
    "back",
    "rück",
    "arrière",
    "trasera",
    "trás",
    "traseira",
    "posteriore",
    "后面",
    "後面",
    "背面",
    "后置", // alternative
    "後置", // alternative
    "背置", // alternative
    "задней",
    "الخلفية",
    "후",
    "arka",
    "achterzijde",
    "หลัง",
    "baksidan",
    "bagside",
    "sau",
    "bak",
    "tylny",
    "takakamera",
    "belakang",
    "אחורית",
    "πίσω",
    "spate",
    "hátsó",
    "zadní",
    "darrere",
    "zadná",
    "задня",
    "stražnja",
    "belakang",
    "बैक",
];

export const isBackCameraLabel = (label: string) =>
    label && backCameraKeywords.some((keyword) => label.toLowerCase().indexOf(keyword) !== -1);
