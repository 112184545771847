import { GooglePayPaymentMethod, NewCardPaymentMethod } from "../../../common/payment";

export interface TyroNewCardPaymentMethod extends NewCardPaymentMethod {
    baseUrl: string;
    apiVersion: string;
    merchantId: string;
}

export interface TyroGooglePayPaymentMethod extends GooglePayPaymentMethod {
    googlePayMerchantId?: string | null;
    googlePayMerchantName: string;
    gateway: string;
    gatewayMerchantId: string;
}

export enum TyroHostedField {
    Number = "card.number",
    ExpiryMonth = "card.expiryMonth",
    ExpiryYear = "card.expiryYear",
    CVV = "card.securityCode",
}

// See https://tyro-enterprise-ecommerce.docs.stoplight.io/hosted-session/sessionJS-reference
// for documentation on possible session update responses
export type TyroPaymentSessionResponseStatus = "ok" | "system_error" | "fields_in_error" | "request_timeout";

export interface TyroPaymentSessionResponse {
    status: TyroPaymentSessionResponseStatus;
}

export interface TyroPaymentSessionInitializedResponse extends TyroPaymentSessionResponse {
    message?: string;
}

export interface TyroPaymentSession {
    id: string;
}

export interface TyroPaymentSessionCardDetails {
    scheme: string;
    fundingMethod: string;
    number: string;
    securityCode: string;
    expiry: { month: string; year: string };
}

export interface TyroPaymentSessionSourceOfFunds {
    provided: { card: TyroPaymentSessionCardDetails };
}

export interface TyroPaymentSessionErrors {
    cardNumber?: string;
    expiryMonth?: string;
    expiryYear?: string;
    securityCode?: string;
    message?: string;
}

export interface TyroPaymentSessionUpdateResponse extends TyroPaymentSessionResponse {
    session: TyroPaymentSession;
    sourceOfFunds: TyroPaymentSessionSourceOfFunds;
    errors: TyroPaymentSessionErrors;
}

export interface TyroThreeDSecureVerificationData {
    sessionId: string;
    transactionId: string;
    ipAddress?: string;
}
