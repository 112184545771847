export type SsoProvider = "Google" | "Apple" | "Facebook";

export enum IdentitySource {
    PhoneNumber = 0,
    Google = 3,
    Apple = 4,
    Facebook = 5,
}

export interface Identity {
    token: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface IdentityValidationRequest extends Identity {
    source: IdentitySource;
}
