import { AppState } from "src/features";
import { createSelector } from "reselect";
import {
    AvailableMembershipState,
    ConnectedMembershipState,
    MembershipState,
    MembershipStatus,
} from "../types/MembershipState";
import { getIsPartyPosBillPrice } from "src/features/order/selectors";
import { getStateData } from "./getMembershipState";
import { getConnectedMembershipState, getCurrentMembershipApplyOnValidate } from "./getConnectedMembershipState";

export const getSignupProgramId = (state: AppState) => state.membership.manageMemberships.signupProgramId;

export const getTotalMembershipCount = createSelector(
    getStateData,
    (data: MembershipState[] | null) => data?.length ?? 0
);
export const getAvailableMembershipStates = createSelector(getStateData, (data: MembershipState[] | null) =>
    !!data
        ? (data.filter(
              (membershipState) => membershipState.status === MembershipStatus.AVAILABLE
          ) as AvailableMembershipState[])
        : null
);

export const getOtherConnectedMembershipsState = createSelector(
    getStateData,
    getConnectedMembershipState,
    (data: MembershipState[] | null, connectedMembership: ConnectedMembershipState | null): MembershipState[] | null =>
        data?.filter(
            (state) => state.programId !== connectedMembership?.programId && state.status === MembershipStatus.CONNECTED
        ) ?? null
);

export const getFeaturedMembershipState = createSelector(
    getAvailableMembershipStates,
    getConnectedMembershipState,
    getOtherConnectedMembershipsState,
    (
        states: AvailableMembershipState[] | null,
        connectedMembership: ConnectedMembershipState | null,
        otherConnected: MembershipState[] | null
    ): AvailableMembershipState | null => {
        const firstAvailableWithCta = states?.find((membershipState) => !!membershipState.cta);

        if (firstAvailableWithCta) {
            return firstAvailableWithCta;
        }

        if (connectedMembership || otherConnected?.length) {
            return null;
        }

        const firstAvailable = states?.[0];

        if (!firstAvailable?.featured) {
            return null;
        }

        return firstAvailable;
    }
);

export const getOtherMembershipsState = createSelector(
    getStateData,
    getFeaturedMembershipState,
    (data: MembershipState[] | null, featured: AvailableMembershipState | null): MembershipState[] | null =>
        data?.filter(
            (state) => state.programId !== featured?.programId && state.status === MembershipStatus.AVAILABLE
        ) ?? null
);

export const getSignupMembershipState = createSelector(
    getAvailableMembershipStates,
    getSignupProgramId,
    (states: AvailableMembershipState[] | null, programId?: string): AvailableMembershipState | null =>
        !states?.length || !programId ? null : states.find((state) => state.programId === programId) ?? null
);

export const getShowMembershipWillApply = createSelector(
    getConnectedMembershipState,
    getIsPartyPosBillPrice,
    getCurrentMembershipApplyOnValidate,
    (connectedMembership, isPosBillingSource, currentMembershipApplyOnValidate) =>
        (isPosBillingSource || currentMembershipApplyOnValidate) && connectedMembership?.programName
);

export const getIsManageMembershipsOpen = ({ membership: { manageMemberships } }: AppState) =>
    manageMemberships.showManageModal;
