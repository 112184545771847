export type OffersState = {
    offerData?: OfferData;
    claimOfferResult?: ClaimOfferResult;
    availableOffers?: OfferData[];
    discoverableOffers?: OfferData[];
    showTooltip?: boolean;
    itemsSelectionIndex?: number;
};

export type OfferData = {
    scopeId: string; // groupSlug or locationSlug depends on the scope
    scope: IdentifierScope;
    id: string;
    coupon?: string;
    title: string;
    subtitle?: string;
    imageUrl?: string;
    source?: string;
    isPartial?: boolean;
    status: OfferStatus;
    offerDetails?: OfferDetails;
    offerColor?: string;
    offerType: OfferType;
    stampCard?: StampCard;
    offerItems?: OfferProducts[];
};

export type OfferDetails = {
    description?: string;
    redeemDateSummary?: string;
    claimedActionCopy?: string;
    claimedActionUrl?: string;
    complianceCopy?: string;
    complianceLink?: string;
    offerLogoUrl?: string;
};

export type StampCard = {
    totalStamps: number;
    earnedStamps: number;
    rewards: StampReward[];
    rotatingCountRemaining?: number;
};

export type StampReward = {
    stampIndex: number;
    rewardOfferId: string;
};
export type ClaimOfferResult = {
    id: string;
    claimed: boolean;
    claimedFailedReason?: ClaimedFailedReason;
};

export enum ClaimedFailedReason {
    UNKNOWN = "Unknown",
    WALLET_NOT_EXISTS = "WalletNotExists",
    ALREADY_EXISTS = "AlreadyExists",
}

export enum OfferType {
    OTHER = "Other",
    DISCOUNT = "Discount",
    STAMP_CARD = "StampCard",
}

export enum OfferStatus {
    AVAILABLE = "Available",
    CLAIMED = "Claimed",
    REDEEMED = "Redeemed",
    EXPIRED = "Expired",
}

export enum ReviewOrderReason {
    OfferChanged = "OfferChanged",
}

export enum IdentifierScope {
    GROUP = "group",
    LOCATION = "location",
}

export type OfferProducts = {
    ids?: string[];
    skus?: string[];
    categories?: string[];
    menuType?: string[];
    minSpend?: number;
    minUnits?: number;
    exclude: boolean;
};

export type ItemCategory = {
    itemId: string;
    categoryId: string;
    cartQuantity: number;
    discountedIds: string[];
    itemsQuantity?: number;
};
