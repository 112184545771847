import { AppDispatch } from "../../index";
import { PayPalCheckoutPaymentMethod } from "src/common/payment";
import { getPayPalAvailableOperation } from "../operations";
import { getPayPalBehavior } from "src/features/paymentGateways";

export const getPayPalAvailable = (dispatch: AppDispatch, payPalCheckoutPaymentMethod: PayPalCheckoutPaymentMethod) =>
    getPayPalAvailableOperation.invoke(
        () => Promise.resolve(!!getPayPalBehavior(payPalCheckoutPaymentMethod)),
        dispatch
    );
