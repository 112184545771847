import { AppDispatch, AppState } from "../..";
import { auth } from "../../../common/auth";
import { verifyOperation } from "../operations";
import { InvalidVerificationCodeError } from "../index";
import { getTableToken } from "../../partyOnboarding/persistence/tableToken";
import { onboard } from "./onboardAction";
import { fetchProfileAwaitable } from "src/features/accountmenu";
import { clearUpdateTime } from "src/features/orderHistoryData/API/orderHistoryApi";
import { getLatestTermsUpdate } from "../../marketing/actions/termsUpdate";
import { fetchMealHistory } from "src/features/orderHistoryData/actions/fetchMealHistory";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { getGroupTabAction } from "src/features/groupTabs/actions/getGroupTabAction";
import { fetchGroupTabHistoryList } from "src/features/groupTabsHistory/actions";
import { IdentityValidationRequest } from "../../../common/sso";
import { SsoError } from "../util/SsoError";
import { getIsAnonymous } from "../../accounts/selectors";
import { getIsOpenTable } from "../../openTable/selectors";
import { getAvailableOffersAction } from "src/features/offers/actions/getAvailableOffers";

export function verifying(request: IdentityValidationRequest, cb?: () => void) {
    return verifyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const isVerifyOnly = !getIsAnonymous(state);
        const isOpenTable = getIsOpenTable(state);

        try {
            await auth.verify(request);
        } catch (err) {
            if (err instanceof SsoError) throw err;
            throw new InvalidVerificationCodeError();
        }

        if (isVerifyOnly) {
            await fetchProfileAwaitable(dispatch, getState);
            dispatch(fetchMealHistory(null));
            cb?.();
            return;
        }

        clearUpdateTime();

        const tableToken = getTableToken();

        if (tableToken) {
            await onboard(getState, dispatch, tableToken);
        }

        await fetchProfileAwaitable(dispatch, getState);

        dispatch(fetchMealHistory(null));
        dispatch(fetchGroupTabHistoryList(null));
        dispatch(getLatestTermsUpdate());
        dispatch(paymentActionCreators.clearPaymentMethods());
        dispatch(getAvailableOffersAction());

        if (!isOpenTable) {
            dispatch(getGroupTabAction());
        }

        cb?.();
    });
}

export const resetVerify = () => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const status = verifyOperation.getStatus(getState());
        if (status === "failed") {
            dispatch(verifyOperation.actionCreators.reset());
        }
    };
};
