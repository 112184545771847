import * as React from "react";
import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { LinkProps } from "react-router-dom";
import * as H from "history";

export interface Props extends RouteComponentProps, LinkProps {
    jump?: H.LocationDescriptor;
    onBeforeNavigate?: () => void;
    disabled?: boolean;
}

const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { to, history, jump, onBeforeNavigate, disabled }: Props
) => {
    e.stopPropagation();

    if (disabled) {
        e.preventDefault();
        return;
    }

    if (onBeforeNavigate) {
        onBeforeNavigate();
    }

    if (jump) {
        history.push(jump as string);
    }

    history.push(to as string);
};

const TappableLink: FC<Props> = (props) => {
    const { className, disabled, children } = props;
    return (
        <a className={className} onClick={(e) => onClick(e, props)} {...{ disabled }}>
            {children}
        </a>
    );
};

export default withRouter(TappableLink);
