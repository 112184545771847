import { connect } from "react-redux";

// import { State } from "../../menudata"
import { LocationThemeProps, LocationTheme } from "src/sharedComponents/common/theme";
import { AppState } from "../..";
import { DataProps } from "src/common/types/utils";
import { PropsWithChildren } from "react";
import { isLightColor } from "src/common/shared";
import { getActiveGroupTabData } from "src/features/groupTabs/selectors/activeGroupTab";
import { getVisibleMenuData } from "src/features/menu/selectors";

type OwnProps = PropsWithChildren<{
    customTheme?: LocationThemeProps;
}>;

function mapStateToProps(state: AppState, ownProps: OwnProps): DataProps<PropsWithChildren<LocationThemeProps>> {
    const menuData = getVisibleMenuData(state);
    if (ownProps.customTheme) {
        return {
            ...ownProps.customTheme,
            children: ownProps.children,
        };
    }
    if (menuData) {
        return {
            themeColor: menuData.themeColor,
            themeTextColor: menuData.themeTextColor,
            themedButtonTextColor: isLightColor(menuData.themeColor)
                ? "var(--color-text)"
                : "var(--color-text-inverse)",
            children: ownProps.children,
        };
    }
    const activeTab = getActiveGroupTabData(state);

    return {
        themeColor: activeTab?.locationColor || "",
        themeTextColor: "",
        themedButtonTextColor: activeTab?.locationColor
            ? isLightColor(activeTab.locationColor)
                ? "var(--color-text)"
                : "var(--color-text-inverse)"
            : "",
        children: ownProps.children,
    };
}

export const LocationThemeContainer = connect(mapStateToProps)(LocationTheme);
