import React, { FC, LabelHTMLAttributes } from "react";
import { onTappableElementClick, TappableElementProps, useTappable } from "./base/TappableBase";

const TappableLabel: FC<TappableElementProps<HTMLLabelElement, LabelHTMLAttributes<HTMLLabelElement>>> = (props) => {
    const [attributes, children] = useTappable(props);
    return (
        <label onClick={(e) => onTappableElementClick(e, props)} {...attributes}>
            {children}
        </label>
    );
};

export default TappableLabel;
