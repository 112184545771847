import { NavBarType } from "../types";

export interface NavBarState {
    navBarStatus: NavBarType;
}

export enum TypeKeys {
    RESET = "NAV_BAR/RESET",
    ACTIVE_TYPE = "NAV_BAR/ACTIVE_TYPE",
}

export const actionCreators = {
    reset: () => ({ type: TypeKeys.RESET }),
    activeType: (navBarType: NavBarType) => ({ type: TypeKeys.ACTIVE_TYPE, navBarType }),
};

type ResetAction = { type: TypeKeys.RESET };
type ActiveTypeAction = { type: TypeKeys.ACTIVE_TYPE; navBarType: NavBarType };

export type NavBarActions = ResetAction | ActiveTypeAction;

const initialState: NavBarState = {
    navBarStatus: NavBarType.MENU,
};

export const reducer = (state: NavBarState = initialState, action: NavBarActions) => {
    switch (action.type) {
        case TypeKeys.RESET:
            return { ...state, navBarStatus: NavBarType.MENU };
        case TypeKeys.ACTIVE_TYPE:
            return {
                ...state,
                navBarStatus: action.navBarType
            };
        default:
            return state;
    }
};
