import { createOperation } from "../operations";
import { GroupTabHistoryView } from "./types";

export const getGroupTabHistoryListOperation = createOperation<GroupTabHistoryView[]>(
    "HISTORYDATA/GET_GROUP_TAB_HISTORY_LIST"
);
export const getGroupTabHistoryDataOperation = createOperation<GroupTabHistoryView>(
    "HISTORYDATA/GET_GROUP_TAB_HISTORY_DATA"
);
export const sendGroupTabReceiptOperation = createOperation("HISTORYDATA/GET_GROUP_TAB_RECEIPT_DATA", {
    showSpinner: true,
});
