import { Party, PartyType } from "../../order";
import { AppDispatch, AppState } from "../../index";
import { actions as menudataActions } from "../../menudata";
import { actions as membershipActions } from "../../membership";
import { updateMenuData } from "../../menudata/actions/update";
import { getCurrentMembershipLevelId } from "src/features/membership/selectors/getConnectedMembershipState";

export const fetchMenuAndMembership = async (party: Party, next: AppDispatch, getState: () => AppState) => {
    const membershipLevelId = getCurrentMembershipLevelId(getState(), party);
    const isPayOnly = party.type === PartyType.PAYONLY;

    await menudataActions.fetch(party.menuDataUrl, next, getState, party.serviceId, membershipLevelId, isPayOnly);

    if (!isPayOnly) {
        await membershipActions.fetchActive(party.restaurantId, next);
        await updateMenuData(next, getState, false, party.menuDataUpdateUrl);
    }
};
