import { AppDispatch, AppState } from "src/features";
import { getCompletePayments } from "../../payment/selectors";
import { getAnyParty } from "../../order/selectors";
import { getMenuData } from "../../menu/selectors";
import { isOrderAndPayParty } from "src/features/order/helpers";

export const pushPurchaseEvent = () => {
    return (_: AppDispatch, getState: () => AppState) => {
        if (!window.dataLayer) return;

        const state = getState();
        const party = getAnyParty(state);
        const menuData = getMenuData(state);
        const payment = getCompletePayments(state)[0];

        if (!party || !isOrderAndPayParty(party) || !payment || !menuData) return;

        window.dataLayer.push({ ecommerce: null }); // Required to flush the object (GTM merges them)
        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                // GA4 schema
                transaction_id: `${party.id}.${payment.id}`,
                currency: menuData.currency,
                items: [],
                value: payment.amount, // Order total (including surcharge/discounts, excluding processing fees/tip)
                // UA schema
                currencyCode: menuData.currency,
                purchase: {
                    products: [
                        {
                            price: payment.amount,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    };
};
