import { orderApi } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { ActiveGroupTabData } from "..";
import { getAuthHeaders } from "../../../common/auth";

export const removeMemberFromGroupTabApi = async (memberId: string) => {
    const headers = await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(`/grouptabs/members/${memberId}`, {
        method: "DELETE",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ActiveGroupTabData;
};
