import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { MenuLogo, OrderNotificationIcon, TabIcon, UserProfileIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { IoIosArrowForward } from "react-icons/io";
import classNames from "classnames";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import Swiper from "react-id-swiper";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as accountMenuActionCreators } from "../../accountmenu/reducers/accountMenu";
import { actionCreators as activeGroupTabActionCreators } from "../../groupTabs/reducers/activeGroupTab";
import { selectMealHistory } from "src/features/orderHistoryData/actions/fetchMealHistory";
import {
    getActiveOrdersHistory,
    getHasActiveOrdersHistory,
    getReadyOrdersHistory,
} from "src/features/orderHistoryData/selectors";
import { getIsGroupTabOpen, getIsGroupTabOwner, getTabTypeName } from "src/features/groupTabs/selectors/activeGroupTab";
import "../assets/HomePage.scss";
import { getABTests } from "../../abtests/actions/getABTests";

export interface Props {
    beforeMenuNavigate?: () => void;
}

const OrderHistoryHomeCard = () => {
    const readyOrderHistory = useSelector(getReadyOrdersHistory);
    const activeOrderHistory = useSelector(getActiveOrdersHistory);

    const dispatch = useDispatch();
    const goToHistory = useCallback(() => {
        if (readyOrderHistory.length) {
            if (readyOrderHistory.length === 1) {
                selectMealHistory(dispatch, readyOrderHistory[0].partyId, true);
            }
        } else if (activeOrderHistory.length === 1) {
            selectMealHistory(dispatch, activeOrderHistory[0].partyId, true);
        } else {
            dispatch(accountMenuActionCreators.openAccountMenu("OrderHistory"));
        }
    }, [readyOrderHistory, activeOrderHistory, dispatch]);

    return (
        <TappableDiv
            onTap={() => goToHistory()}
            className={classNames("scrollable-info scrollable-info__section order")}
        >
            <div className="scrollable-info__section__icon">
                <OrderNotificationIcon />
            </div>
            <div className="scrollable-info__section__header">
                {!!readyOrderHistory.length ? (
                    <Text preset="g-14" mode="bold" value="Order is ready to collect" />
                ) : !!activeOrderHistory.length ? (
                    <Text
                        preset="g-14"
                        mode="bold"
                        value={`${activeOrderHistory.length} Active order${activeOrderHistory.length === 1 ? "" : "s"}`}
                    />
                ) : null}
            </div>
            <div className="scrollable-info__section__description">
                <Text preset="g-14" value="Tap for more information" />
            </div>
            <div className="scrollable-info__section__arrow">
                <IoIosArrowForward />
            </div>
        </TappableDiv>
    );
};

function GroupTabHomeCard() {
    const dispatch = useDispatch();
    const tabTypeName = useSelector(getTabTypeName);

    const openGroupTabOverview = useCallback(() => {
        dispatch(activeGroupTabActionCreators.showGroupTabOverview(true));
    }, [dispatch]);

    return (
        <TappableDiv
            onTap={openGroupTabOverview}
            className={classNames("scrollable-info scrollable-info__section order")}
        >
            <div className="scrollable-info__section__icon">
                <span className="scrollable-info__section__icon__red-dot">
                    <TabIcon />
                </span>
            </div>
            <div className="scrollable-info__section__header">
                <Text preset="g-14" mode="bold" value={`View ${tabTypeName}`} />
            </div>
            <div className="scrollable-info__section__description">
                <Text preset="g-14" value="Tap for more information" />
            </div>
            <div className="scrollable-info__section__arrow">
                <IoIosArrowForward />
            </div>
        </TappableDiv>
    );
}

export const HomePage = ({ beforeMenuNavigate }: Props) => {
    const showHistory = useSelector(getHasActiveOrdersHistory);
    const isOrderReady = !!useSelector(getReadyOrdersHistory).length;
    const isGroupTabOwner = useSelector(getIsGroupTabOwner);
    const isGroupTabOpen = useSelector(getIsGroupTabOpen);
    const [slides, setSlides] = useState<
        {
            key: string;
            className?: string;
            element: ReactNode;
        }[]
    >([]);

    const dispatch = useDispatch();

    const onProfile = useCallback(() => {
        beforeMenuNavigate && beforeMenuNavigate();
        dispatch(accountMenuActionCreators.openAccountMenu());
    }, [beforeMenuNavigate, dispatch]);

    useEffect(() => {
        const slides = [];
        if (showHistory) {
            slides.push({
                key: "history",
                element: <OrderHistoryHomeCard />,
                className: isOrderReady ? "is-ready" : undefined,
            });
        }
        if (isGroupTabOpen && isGroupTabOwner) {
            slides.push({ key: "group-tab", element: <GroupTabHomeCard /> });
        }
        setSlides(slides);
    }, [showHistory, isGroupTabOwner, isGroupTabOpen, isOrderReady]);

    useEffect(() => {
        dispatch(getABTests());
    }, [dispatch]);

    return (
        <div className={classNames("home-page", isOrderReady && "focus")}>
            <div className="home-page__header">
                <MenuLogo />
                <TappableDiv className="home-page__header__profile" onClick={onProfile}>
                    <UserProfileIcon />
                </TappableDiv>
            </div>
            <div className={classNames("home-page__content", !!slides.length && "home-page__content--with-slider")}>
                {!!slides.length && (
                    <Swiper
                        slidesPerView={slides.length > 1 ? 1.2 : 1}
                        spaceBetween={10}
                        allowTouchMove={slides.length > 1}
                        key={`home-swiper-${slides?.length}`}
                        containerClass={classNames("scrollable-info")}
                    >
                        {slides.map((slide) => (
                            <div key={slide?.key} className={slide.className}>
                                {slide.element}
                            </div>
                        ))}
                    </Swiper>
                )}
                <Text preset="title-48" mode="extra-bold" value="Let’s order." />
                <Text
                    preset="g-18"
                    mode="bold"
                    value="Start your me&u order by tapping the beacon with your phone, or scanning the QR code."
                />
            </div>
        </div>
    );
};
