import { AppDispatch } from "src/features";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { sendGroupTabReceiptApi } from "../API/sendGroupTabReceiptApi";
import { sendGroupTabReceiptOperation } from "../operations";

export function sendGroupTabReceiptAction(groupTabId: string) {
    return sendGroupTabReceiptOperation.getThunk(async (dispatch: AppDispatch) => {
        const email = await sendGroupTabReceiptApi(groupTabId);

        if (email) {
            dispatch(showModalMessage(modalMessages.emailReceipt(email)));
        } else {
            dispatch(showModalMessage(modalMessages.emailReceiptFailed()));
        }
    });
}
