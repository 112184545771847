import { ThreeDSecure, threeDSecure } from "braintree-web";
import { PaymentType } from "../../../../common/payment";
import { AppDispatch, AppState } from "../../../index";
import { getThreeDSecureVerificationData } from "../../../payment/selectors";
import { ThreeDSecureInfo, VerificationCancelledError } from "../../../payment";
import { normalizeError } from "../../../../common/error";
import { device } from "../../../../common/experience";
import { timeoutAndError, triggerVirtualPageTrack } from "../../../../common/shared";
import { actionCreators as paymentEventsActionCreators } from "../../../payment/reducers/paymentEvents";
import { fetchThreeDSecureVerificationData } from "../../../payment/api/fetchThreeDSecureVerificationData";
import { getParty } from "../../../order";
import { fetchPaymentSessionToken } from "../../../payment/api/fetchPaymentSessionToken";
import { getBraintreeClientInstance } from "../../../../common/braintree";
import { verifyBraintreeCardOperation } from "../operations";
import { BraintreeAddedCardPaymentMethod, BraintreeThreeDSecureVerificationData } from "../types";
import { VerifyCardAction } from "../../types";

export const verifyBraintreeCard: VerifyCardAction = (completePayment, abortSignal, result) => {
    return verifyBraintreeCardOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            let cancelled = false;
            let lookupComplete = false;
            let verifyCardStarted = false;
            let threeDSecureInstance: ThreeDSecure | undefined;

            abortSignal.addEventListener("abort", () => {
                dispatch(paymentEventsActionCreators.threeDSecureCancelled());
                cancelled = true;
                if (lookupComplete) {
                    threeDSecureInstance!.cancelVerifyCard(() => {});
                } else if (!verifyCardStarted) {
                    completePayment(null);
                }
            });

            const { profile, memberId, total, selectedPaymentMethod } = getThreeDSecureVerificationData(getState());
            const { paymentGateway, token: paymentToken, paymentType } = selectedPaymentMethod!;

            const threeDSecureInfo: ThreeDSecureInfo = {
                paymentGateway,
                paymentToken,
                isAddedCard: paymentType === PaymentType.ADDEDCARD || paymentType === PaymentType.GOOGLEPAY,
            };

            if (cancelled) return;
            const inParty = !!getParty(getState());
            let [{ nonce, bin, ipAddress }, clientToken] = await Promise.all([
                fetchThreeDSecureVerificationData<BraintreeThreeDSecureVerificationData>(
                    dispatch,
                    threeDSecureInfo,
                    inParty
                ),
                fetchPaymentSessionToken(dispatch, paymentGateway, inParty),
            ]);

            if (!clientToken) {
                throw new Error("No client token");
            }

            const { clientInstance, deviceData } = await getBraintreeClientInstance(clientToken);

            if (cancelled) return;
            threeDSecureInstance = await Promise.race([
                threeDSecure.create({
                    client: clientInstance,
                    version: 2,
                }),
                timeoutAndError(20000, "Timed out initializing Braintree 3DS"),
            ]);

            if (paymentType === PaymentType.ADDEDCARD) {
                ({ token: nonce, bin } = selectedPaymentMethod as BraintreeAddedCardPaymentMethod);
            } else if (paymentType === PaymentType.GOOGLEPAY) {
                nonce = result?.paymentToken;
            }

            // Phone number should be numbers only and max length 25
            const phone = profile?.phone?.replace(/\D/g, "").substring(0, 25);
            const givenName = profile?.firstName?.substring(0, 50);
            const surname = profile?.lastName?.substring(0, 50);

            // See https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~additionalInformation
            // for information on all parameters that can be added to additionalInformation
            // I have included commented out properties that we have the data for but are currently not being used

            const options: any = {
                amount: total.toFixed(2),
                nonce,
                bin,
                email: profile?.email?.substring(0, 255),
                mobilePhoneNumber: phone,
                billingAddress: {
                    givenName,
                    surname,
                    phoneNumber: phone,
                },
                additionalInformation: {
                    productCode: "RES", // Merchant is a restaurant
                    // accountAgeIndicator, (Enum of lengths of time user has had account)
                    // accountCreateDate,
                    // accountPurchases, (Over last 6 months)
                    accountId: memberId,
                    ipAddress,
                    // orderDescription,
                    // taxAmount,
                    userAgent: device.userAgent?.substring(0, 500),
                },
            };

            ((threeDSecureInstance: any) => {
                threeDSecureInstance.on("lookup-complete", (data: any, next: any) => {
                    if (cancelled) {
                        threeDSecureInstance.cancelVerifyCard(() => {});
                    }
                    lookupComplete = true;
                    next();
                });

                threeDSecureInstance.on("customer-canceled", () => {
                    cancelled = true;
                    dispatch(paymentEventsActionCreators.threeDSecureCancelled());
                });

                let modalRendered = false;

                threeDSecureInstance.on("authentication-modal-render", () => {
                    if (!modalRendered) {
                        // Triggers multiple times
                        modalRendered = true;
                        triggerVirtualPageTrack({ name: "payment-wizard-3ds" });
                    }
                });
            })(threeDSecureInstance);

            if (cancelled) return;
            verifyCardStarted = true;

            const threeDSecureResponse = await threeDSecureInstance.verifyCard(options);

            // Only fail the process if liability wasn't shifted because the user cancelled
            // Otherwise treat as successful and pass the results on to Braintree's Fraud Protection
            if (!threeDSecureResponse.liabilityShifted && cancelled) {
                throw new VerificationCancelledError();
            }

            completePayment({
                paymentToken: threeDSecureResponse.nonce,
                additionalFraudProtectionData: deviceData,
            });

            dispatch(paymentEventsActionCreators.threeDSecureSuccess());
        } catch (err) {
            if (err?.code === "THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT") {
                document.getElementById("Cardinal-ElementContainer")?.remove();
                const err = new VerificationCancelledError();
                completePayment(err);
                throw err;
            }

            if (!(err instanceof VerificationCancelledError)) {
                // If this is a BraintreeError it could be in this format
                // {
                //   "name": "BraintreeError",
                //   "code": "THREEDS_CARDINAL_SDK_ERROR",
                //   "message": "A general error has occurred with Cardinal. See description for more information.",
                //   "type": "UNKNOWN",
                //   "details": {
                //     "originalError": {
                //       "code": 10004,
                //       "description": "A general error has occurred."
                //     }
                //   }
                // }
                //
                // Or it could be in this format
                // {
                //   "name": "BraintreeError",
                //   "code": "THREEDS_LOOKUP_VALIDATION_ERROR",
                //   "message": "The data passed in `verifyCard` did not pass validation checks. See details for more info",
                //   "type": "CUSTOMER",
                //   "details": {
                //     "originalError": {
                //       "name": "BraintreeError",
                //       "code": "CLIENT_REQUEST_ERROR",
                //       "message": "There was a problem with your request.",
                //       "type": "NETWORK",
                //       "details": {
                //         "originalError": {
                //           "error": {
                //             "message": "Shipping line2 format is invalid."
                //           },
                //           "threeDSecureInfo": {
                //             "liabilityShifted": false,
                //             "liabilityShiftPossible": false
                //           }
                //         },
                //         "httpStatus": 422
                //       }
                //     }
                //   }
                // }

                // Change to log top level code and message as we're logging the error JSON anyway
                const errorCode = err?.code ?? err?.details?.originalError?.code;

                const errorMessage =
                    err?.message ??
                    err?.details?.originalError?.message ??
                    err?.details?.originalError?.description ??
                    err?.details?.originalError?.details?.originalError?.error?.message;

                const errorJson = err ? JSON.stringify(err, null, 2) : undefined;

                dispatch(paymentEventsActionCreators.threeDSecureFailed(errorCode, errorMessage, errorJson));
            }

            ((err) => {
                completePayment(err);
                throw err;
            })(normalizeError(err));
        }
    });
};
