import {
    BaseTelemetryPlugin,
    IAppInsights,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { AppState } from "../..";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { getActionProperties, IReduxActionPlugin } from "./IReduxActionPlugin";
import { findAppInsightsPlugin } from "../util/findAppInsightsPlugin";
import {
    PartyConnectedAction,
    PartyConnectingAction,
    PartyConnectionFailedAction,
    TypeKeys as PartyTypeKeys,
} from "../../order/reducers/party";
import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "../../../common/auth";
import { FetchMenuDataConnectionFailedAction, TypeKeys as MenuDataTypeKeys } from "../../menudata/reducers/active";
import { ORDER_HUB_URL } from "../../partyOnboarding/constants";

type NoNetworkConnectivityAction =
    | PartyConnectionFailedAction
    | PartyConnectingAction
    | PartyConnectedAction
    | FetchMenuDataConnectionFailedAction;

const PING_URL = "/ping";

export class NoNetworkConnectivityPlugin extends BaseTelemetryPlugin implements IReduxActionPlugin {
    public identifier = "NoNetworkConnectivityPlugin";

    private appInsights: IAppInsights | undefined = undefined;

    private dropped = false;

    private failedConnectionAttempts = 0;

    log(message: string) {
        //console.log(message);
    }

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        this.appInsights = findAppInsightsPlugin(extensions);
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        this.processNext(event, itemCtx!);
    }

    handleReduxAction(action: NoNetworkConnectivityAction, getState: () => AppState) {
        if (!this.appInsights) {
            return;
        }

        if (action.type === PartyTypeKeys.CONNECTION_FAILED && action.url !== PING_URL) {
            this.log(`Connecting to ${action.url} failed (attempt ${action.count})`);

            this.appInsights.trackEvent({
                name: action.type,
                properties: getActionProperties(action),
            });

            if (action.url === ORDER_HUB_URL) {
                this.failedConnectionAttempts = action.count;

                (async () => {
                    try {
                        const response = await orderApi.send(PING_URL, {
                            method: "GET",
                            headers: await getAuthHeaders(),
                        });

                        if (response.ok) {
                            this.log("Order Hub ping succeeded");

                            this.appInsights &&
                                this.appInsights.trackEvent({
                                    name: "PARTY/ORDER_HUB_PING_SUCCEEDED",
                                });
                        } else {
                            throw new Error();
                        }
                    } catch (err) {
                        this.log("Order Hub ping failed");

                        this.appInsights &&
                            this.appInsights.trackEvent({
                                name: "PARTY/ORDER_HUB_PING_FAILED",
                            });
                    }
                })();
            }
        }

        if (action.type === MenuDataTypeKeys.CONNECTION_FAILED) {
            this.log(`Fetching menu data from ${action.url} failed (attempt ${action.count})`);

            this.appInsights.trackEvent({
                name: action.type,
                properties: getActionProperties(action),
            });
        }

        if (action.type === PartyTypeKeys.CONNECTING) {
            const {
                party: { connectionStatus, activeParty },
            } = getState();

            this.dropped = connectionStatus === "connected" && !!activeParty;

            if (this.dropped) {
                this.failedConnectionAttempts = 0;
                this.log("SignalR connection dropped");

                this.appInsights.trackEvent({
                    name: "PARTY/CONNECTION_DROPPED",
                });
            }
        }

        if (action.type === PartyTypeKeys.CONNECTED) {
            if (this.dropped) {
                this.log("SignalR connection restored");

                this.appInsights.trackEvent({
                    name: "PARTY/CONNECTION_RESTORED",
                    properties: {
                        failedConnectionAttempts: String(this.failedConnectionAttempts),
                    },
                });
            }
        }
    }
}
