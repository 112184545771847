export const poll = (
    fn: () => boolean,
    timeout: number,
    interval: number,
    err?: string,
) => {
    const endTime = Number(new Date()) + timeout;

    const checkCondition = (
        resolve: () => void,
        reject: (err: any) => void,
    ) => {
        if (fn()) {
            resolve();
        } else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        } else {
            reject(new Error(err));
        }
    };

    return new Promise(checkCondition);
};
