import { Party } from "../types";
import { AppDispatch, AppState } from "src/features";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getSurveyDetails } from "../../survey/selectors/survey";
import { sendSurvey } from "../../survey/actions/sendSurvey";
import { shouldFoodShowPickup } from "../../orderHistoryData/selectors";
import { actionCreators as surveyActionCreators } from "../../survey/reducers/survey";
import { getRating } from "src/features/survey/selectors/rating";
import { Rating } from "src/sharedComponents";
import { actions as marketingActions } from "src/features/marketing/actions";
import { getMarketingOptInData, getShowMarketingPrompt } from "src/features/marketing/selectors/optInMarketing";
import {
    getBounceBackEngageOfferAdjustments,
    getBounceBackOfferStampCard,
    getIsSectionTakeaway,
    getTableLabel,
} from "../selectors";
import { getProfileEmail } from "src/features/accountmenu/selectors";
import { getMembershipSignupPromotion } from "src/features/membership/selectors/getMembershipSignupPromotion";
import { signupPromotionActions } from "src/features/membership/reducers/signupPromotion";
import { getTableNumber } from "src/common/shared";

export const partyCompleted = (party: Party) => (dispatch: AppDispatch, getState: () => AppState) => {
    const items = party.submittedOrders.reduce((i, o) => i.concat(o.items), party.activeOrder.items.slice(0));
    const sendingError = items.some((i) => i.failedToSubmit);
    const nothingSent = items.every((i) => i.failedToSubmit);
    const state = getState();
    const hasFoodPickUp = shouldFoodShowPickup(state);
    const surveyDetails = getSurveyDetails(state);
    const marketingOptInData = getMarketingOptInData(state);
    const isTakeaway = getIsSectionTakeaway(state);
    const tableLabel = getTableLabel(state);
    const tableNumber = getTableNumber(party);

    const stampCardOfferReward = getBounceBackOfferStampCard(state);
    const bounceBackAdjustments = getBounceBackEngageOfferAdjustments(state);

    const showMarketingPromptOrSignupPromotion = () => {
        const state = getState();
        const signupPromotion = getMembershipSignupPromotion(state);
        if (signupPromotion) {
            setTimeout(() => dispatch(signupPromotionActions.showSignupPromotionModal(true)), 200);
            return;
        }
        const showMarketingPrompt = getShowMarketingPrompt(state);
        if (showMarketingPrompt && marketingOptInData) {
            setTimeout(() => dispatch(marketingActions.showMarketingPage(marketingOptInData)), 200);
        }
    };

    if (sendingError) {
        if (nothingSent) {
            dispatch(showModalMessage(modalMessages.failedToSubmit()));
        } else {
            dispatch(showModalMessage(modalMessages.someItemsFailedToSend()));
        }
    } else if (stampCardOfferReward || bounceBackAdjustments?.length) {
        dispatch(
            showModalMessage(
                modalMessages.offerUnlocked(
                    hasFoodPickUp,
                    showMarketingPromptOrSignupPromotion,
                    isTakeaway,
                    tableLabel,
                    tableNumber
                )
            )
        );
    } else {
        const profileEmail = getProfileEmail(getState());
        if (profileEmail) {
            dispatch(surveyActionCreators.setEmail(profileEmail));
        }

        dispatch(
            showModalMessage(
                modalMessages.feedbackSurvey({
                    hasSurveyDetails: !!surveyDetails,
                    hasPickUp: hasFoodPickUp,
                    action: () => {
                        const rating = getRating(getState());
                        dispatch(
                            showModalMessage(
                                modalMessages.feedbackSurvey({
                                    hasSurveyDetails: !!surveyDetails,
                                    hasPickUp: hasFoodPickUp,
                                    shouldValidate: true,
                                    initialValid: rating === Rating.POSITIVE,
                                    primaryAction: () => {
                                        dispatch(sendSurvey());
                                        showMarketingPromptOrSignupPromotion();
                                    },
                                    isTakeaway,
                                    tableLabel,
                                    tableNumber,
                                })
                            )
                        );
                    },
                    primaryAction: () => {
                        dispatch(surveyActionCreators.resetSurvey());
                        showMarketingPromptOrSignupPromotion();
                    },
                    isTakeaway,
                    tableLabel,
                    tableNumber,
                })
            )
        );
    }
};
