import { OrderHistoryData, RefundedState } from "../orderHistoryData";
import { OrderPaymentsProps } from "./components/OrderPayments";
import { OrderTotalSummaryProps } from "./components/OrderTotalSummary";
import { getTotalExclusiveTaxes } from "../order/helpers";
import { config } from "../../common/config";

export const getOrderPaymentsFromOrderData = (orderHistory: OrderHistoryData | null) => {
    const { details, summary } = orderHistory || {};
    if (!details || !summary) return null;

    return (
        details.payments?.map(
            (payment): OrderPaymentsProps => ({
                paymentIsOutstanding: payment.paymentIsOutstanding,
                tip: payment.tip,
                paymentMethod: payment.paymentMethod,
                cardType: payment.cardType,
                paymentMethodDescription: payment.paymentMethodDescription,
                processingFee: payment.processingFee,
                totalRefunded: payment.totalRefunded,
                total: payment.total,
                amount: payment.subtotal,
            })
        ) ?? [
            {
                paymentIsOutstanding: details.paymentIsOutstanding,
                tip: details.tip,
                paymentMethod: details.paymentMethod,
                cardType: details.cardType,
                paymentMethodDescription: details.paymentMethodDescription,
                processingFee: details.processingFee,
                totalRefunded: summary.refunded === RefundedState.REFUNDED ? details.totalRefunded : undefined,
                total: summary.total,
                amount: details.subtotal,
                pointsSpendAmount: details.pointsSpendAmount,
            },
        ]
    );
};

export const getOrderTotalSummaryFromOrderData = (
    orderHistory: OrderHistoryData | null
): OrderTotalSummaryProps | null => {
    if (!orderHistory?.details) return null;

    const { details } = orderHistory;

    return {
        tableTotal: details.tableTotal,
        orderAmount: details.orderAmount,
        memberDiscount: details.memberDiscount,
        discount: details.discount,
        surcharge: details.surcharge,
        unallocatedAmount: details.unallocatedAmount,
        taxSummary: details.billShare?.taxSummary,
        adjustments: details.billShare?.adjustments,
        surchargePercent: details.billShare?.surchargePercentage,
        offerDiscountAmount: details.billShare?.offerDiscountAmount,
        gratuityFactor: details.gratuityFactor,
        gratuityAmount: details.paymentMethod === "GroupTab" ? details.tip : undefined,
        total: details.total,
    };
};

export const getShowOrderTotalSummary = (summary?: OrderTotalSummaryProps | null) => {
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";
    const hasTotal = orderHistoryUpdateEnabled ? false : !!summary?.total;

    return (
        summary &&
        !!(
            summary.memberDiscount ||
            summary.discount ||
            summary.surcharge ||
            summary.unallocatedAmount ||
            summary.surchargePercent ||
            summary.gratuityAmount ||
            hasTotal ||
            summary.adjustments?.length ||
            getTotalExclusiveTaxes(summary.taxSummary)
        )
    );
};
