import { createSelector } from "reselect";
import { LocationMenuData, Service } from "src/features/menudata";
import { getActiveService, getVisibleMenuData } from "src/features/menu/selectors";
import { getUserPreferenceFilters } from "src/features/filter/selectors/getUserPreferenceFilters";
import { AvailableFilters, matchesFilter, SelectedFilters } from "src/features/filter";
import { getSelectedUserPreferencesFilters } from "src/features/filter/selectors/getSelectedUserPreferenceFilters";
import { PriceResolver, getPriceResolver } from "src/features/membership/selectors/getPriceResolver";

export const getFeaturedItem = createSelector(
    getSelectedUserPreferencesFilters,
    getUserPreferenceFilters,
    getVisibleMenuData,
    getActiveService,
    getPriceResolver,
    (
        tags: SelectedFilters,
        allTags: AvailableFilters,
        data: LocationMenuData | null | undefined,
        activeService: Service | null | undefined,
        priceResolver: PriceResolver
    ) => {
        const featured = activeService?.featured;
        const featuredItem = featured?.items[0];

        if (!featured || !featuredItem || !data) {
            return null;
        }

        const featuredItemId = featuredItem.item;
        const item = data.items[featuredItemId];

        if (!item) {
            return null;
        }

        if (item.available === false) {
            return null;
        }

        if (item.type === "food" && !matchesFilter(item, allTags, tags, priceResolver)) {
            return null;
        }

        let categoryId: string | undefined = featuredItem.category;

        if (featuredItem.categories) {
            categoryId = featuredItem.categories.find(
                (cat) => !!data.categories[cat] && !data.categories[cat].unavailable
            );
            if (!categoryId) {
                // couldn't find any category available for this Featured product
                return null;
            }
        } else {
            if (!data.categories[featuredItem.category] || data.categories[featuredItem.category]?.unavailable) {
                // category doesn't exists or unavailable for this Featured product
                return null;
            }
        }

        return {
            title: featured.title,
            id: featuredItem.item,
            categoryId,
        };
    }
);
