import React, { useContext, useEffect, useRef, useState } from "react";
import { OrderSummary } from "../reducers/types";
import { MembershipIcon, PlusIcon, TrolleyIcon } from "src/sharedComponents/assets/icons";
import classNames from "classnames";
import { Button, CallToActionHeader, Divider, Text } from "src/sharedComponents";
import { Price } from "src/features/menu/components/Price";
import "../assets/CartView.scss";
import { MembershipCallToAction } from "../../membership/components/MembershipCallToAction";
import { MembershipCallToActionText } from "src/features/membership/components/MembershipCallToActionText";
import { MembershipSaving } from "../../membership/components/MembershipSaving";
import { CartItemsByType } from "./CartItemsByType";
import { QuickSell } from "src/features/quickSell/components/QuickSell";
import { LoadingText } from "src/sharedComponents/controls/loadingText";
import { PromoCode } from "./PromoCode";
import { PromoCodeDetails } from "./PromoCodeDetails";
import { useSelector } from "react-redux";
import { getLocationPromotionsEnabled } from "../../payment/selectors";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { getIsConnectedToMembershipWithSavings } from "src/features/membership/selectors/getVenueMembershipState";
import {
    getIsEngageEnabledForLocation,
    getIsSectionTakeaway,
    getOrderDateTimeScheduled,
    getOrderReadyTime,
    getShowEngageForUser,
    getTableLabel,
} from "../selectors";
import { getIsTakeawaySchedulingEnabled } from "src/features/takeaway/selectors/takeawayOptions";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MembersStatusList } from "../../openTable/components/MembersStatusList";
import { getIsOpenTable, getShowFlexMembersStatusList } from "../../openTable/selectors";
import { EngageOfferSection } from "./EngageOfferSection";
import { getCurrentMembershipApplyOnValidate } from "src/features/membership/selectors/getConnectedMembershipState";
import { getShowMembershipWillApply } from "src/features/membership/selectors/getActiveMembershipState";
import { PaymentItem } from "src/features/payment/components/PaymentItem";

export interface Props {
    order: OrderSummary;
    canSubmit: boolean;
    editable?: boolean;
    subTotal: number;
    nonMemberSubTotal: number;
    membershipDiscountAmount: number;
    showQuickSellItems: boolean;
    removeItem?: (orderItemId: string, menuItemId: string) => void;
    addItems: (editable?: boolean) => void;
    manageMemberships: () => void;
    membershipAvailable: boolean;
    showPriceLoader?: boolean;
    isPartyPosBillPrice: boolean;
    tableNumber?: string;
    showTakeawaySchedulePage: () => void;
}

const ManageMembershipsCta = ({ onClick }: { onClick: () => void }) => (
    <TappableDiv onClick={onClick} className="membership-cta manage-membership">
        <div className="membership-cta__icon">
            <MembershipIcon />
        </div>
        <div className="membership-cta__text">
            <MembershipCallToActionText textOne={"Your deals have been applied."} textTwo={"Manage your account"} />
        </div>
    </TappableDiv>
);

export const CartView = ({
    order: { food, drinks },
    subTotal,
    nonMemberSubTotal,
    membershipDiscountAmount,
    canSubmit,
    editable,
    showQuickSellItems,
    membershipAvailable,
    removeItem,
    addItems,
    showPriceLoader,
    isPartyPosBillPrice,
    tableNumber,
    manageMemberships,
    showTakeawaySchedulePage,
}: Props) => {
    // Showing quick sell should be change only on Render
    const [quickSellItemsVisible] = useState(showQuickSellItems);
    const promoCodesEnabled = useSelector(getLocationPromotionsEnabled);
    const isConnectedToMembershipWithSavings = useSelector(getIsConnectedToMembershipWithSavings);
    const isTakeaway = useSelector(getIsSectionTakeaway);
    const orderReadyTime = useSelector(getOrderReadyTime);
    const orderReadyTimeRef = useRef<number | undefined>(orderReadyTime);
    const orderScheduledDateTime = useSelector(getOrderDateTimeScheduled);
    const takeawaySchedulingEnabled = useSelector(getIsTakeawaySchedulingEnabled);
    const isOpenTable = useSelector(getIsOpenTable);
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const engageEnabled = useSelector(getIsEngageEnabledForLocation);
    const showEngage = useSelector(getShowEngageForUser);
    const showFlexMembersStatusList = useSelector(getShowFlexMembersStatusList);
    const currentMembershipApplyOnValidate = useSelector(getCurrentMembershipApplyOnValidate);
    const membershipWillApplyName = useSelector(getShowMembershipWillApply);
    const tableLabel = useSelector(getTableLabel);

    useEffect(() => {
        if (orderReadyTime) {
            orderReadyTimeRef.current = orderReadyTime;
        }
    }, [orderReadyTime]);

    const getSubheading = () => {
        if (editable) {
            if (isTakeaway && orderScheduledDateTime) {
                return `Pick up at ${menuDataLocale.getDateTimeShort(orderScheduledDateTime)}`;
            }
            if (isTakeaway && orderReadyTimeRef.current) {
                return `Pick up in ${orderReadyTimeRef.current} mins`;
            }
            if (tableNumber && !isTakeaway) {
                return `Ordering from ${tableLabel} ${tableNumber}`;
            }
        }
        return "";
    };

    const subheading = getSubheading();
    const showDealsAndSubtotal = isPartyPosBillPrice && membershipDiscountAmount < 0;

    const renderCartTotal = () => {
        if (showDealsAndSubtotal) {
            return <Price priceTextPreset="g-18" priceTextMode="bold" price={subTotal} />;
        }
        if (
            isPartyPosBillPrice ||
            currentMembershipApplyOnValidate ||
            !nonMemberSubTotal ||
            nonMemberSubTotal === subTotal
        ) {
            return <Price priceTextPreset="g-18" priceTextMode="bold" price={nonMemberSubTotal || subTotal} />;
        }
        return (
            <Price
                priceTextPreset="g-18"
                priceTextMode="bold"
                availablePrices={{
                    memberPrice: subTotal,
                    basePrice: nonMemberSubTotal,
                }}
                showBasePrice
                basePriceTextPreset="g-18"
                basePriceClassName="cart-view__content__total__base-price"
                wrapperClassName="cart-view__content__total__price-wrapper"
            />
        );
    };

    return (
        <div className="cart-view">
            <main className={classNames("cart-view__content", !canSubmit && "no-items")}>
                <div className="cart-view__header">
                    <Text preset="title-28" mode={["bold", "block"]}>
                        Cart
                    </Text>
                    {subheading && (
                        <section className="cart-view__header__section">
                            <Text
                                preset="g-14"
                                mode="block"
                                className="cart-view__header__subtitle"
                                value={subheading}
                            />
                            {takeawaySchedulingEnabled && (
                                <TappableDiv
                                    className="cart-view__header__takeaway-link"
                                    onClick={showTakeawaySchedulePage}
                                >
                                    <Text preset="g-14" mode={["block", "bold"]} value={"Change time"} />
                                </TappableDiv>
                            )}
                        </section>
                    )}
                </div>
                {canSubmit && !isOpenTable && !showEngage && membershipWillApplyName && <MembershipSaving />}
                {editable && showFlexMembersStatusList && <MembersStatusList />}
                {canSubmit ? (
                    <>
                        {editable && <MembershipCallToAction instanceName="CartView" />}
                        {editable && quickSellItemsVisible && !membershipAvailable && <QuickSell />}
                        <CartItemsByType food={food} drinks={drinks} editable={editable} removeItem={removeItem} />
                        {!isOpenTable && promoCodesEnabled && editable && !engageEnabled && <PromoCode />}
                        {isConnectedToMembershipWithSavings && (
                            <>
                                <Divider />
                                <ManageMembershipsCta onClick={manageMemberships} />
                            </>
                        )}
                        {showEngage && (
                            <>
                                <Divider />
                                <EngageOfferSection />
                            </>
                        )}
                        {!isOpenTable && !showEngage && (
                            <>
                                <Divider />
                                {!membershipWillApplyName && <MembershipSaving />}
                                {!showPriceLoader && showDealsAndSubtotal && (
                                    <PaymentItem
                                        title="Deals"
                                        value={membershipDiscountAmount}
                                        extraClass="payment-item--member-discount"
                                        titleSuffix={<MembershipIcon />}
                                    />
                                )}
                                <div className="cart-view__content__total">
                                    {showPriceLoader ? (
                                        <LoadingText />
                                    ) : (
                                        <Text
                                            preset="g-18"
                                            mode="bold"
                                            value={`${showDealsAndSubtotal ? "Sub" : "Item "}total`}
                                        />
                                    )}
                                    {showPriceLoader ? <LoadingText /> : renderCartTotal()}
                                </div>
                                {showPriceLoader !== undefined && (
                                    <Text className="cart-view__content__disclosure" preset="g-12">
                                        Discounts or surcharges may apply. You’ll be able to review the total before you
                                        pay.
                                    </Text>
                                )}
                            </>
                        )}
                        <Button onClick={() => addItems(editable)} mode="blank" className="cart-view__content__button">
                            {editable ? (
                                <>
                                    <PlusIcon />
                                    Add more items
                                </>
                            ) : (
                                "Edit cart"
                            )}
                        </Button>
                    </>
                ) : (
                    <>
                        <CallToActionHeader
                            title="Looks like your cart is empty"
                            ctaText="View menu"
                            icon={<TrolleyIcon />}
                            onClick={() => addItems(editable)}
                        />
                        {promoCodesEnabled && editable && <PromoCodeDetails />}
                    </>
                )}
            </main>
        </div>
    );
};
