import "../assets/AnotherRoundCarousel.scss";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateOrderItem } from "src/features/order/actions/addOrUpdateOrderItem";
import { OrderCampaigns } from "src/features/order/reducers/types";
import { getActiveOrder, getTableLabel } from "src/features/order/selectors";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { actionCreators } from "src/features/order";
import { Text } from "src/sharedComponents";

import { RoundItem } from "../types";
import { getIsSameItem } from "../helpers/getIsSameItem";
import { AnotherRoundCarouselItem } from "./AnotherRoundCarouselItem";
import { getOpenTableAnotherRoundItems } from "../selectors";

export function AnotherRoundCarousel() {
    const currentMemberId = useSelector(getCurrentMemberId);
    const roundItems = useSelector(getOpenTableAnotherRoundItems);
    const activeOrder = useSelector(getActiveOrder);
    const tableLabel = useSelector(getTableLabel);
    const dispatch = useDispatch();

    if (!roundItems?.length) return null;

    const getOrderItem = (roundItem: RoundItem) => {
        return activeOrder?.items?.find(
            (orderItem) => getIsSameItem(roundItem, orderItem) && orderItem.memberId === currentMemberId
        );
    };

    const handleOnChange = (roundItem: RoundItem, quantity: number) => {
        const orderItem = getOrderItem(roundItem);

        if (quantity > 0) {
            dispatch(
                addOrUpdateOrderItem(
                    roundItem.courseId,
                    roundItem.categoryId,
                    roundItem.itemId,
                    roundItem.notes || null,
                    orderItem?.id || null,
                    roundItem.variant,
                    roundItem.modifiers || undefined,
                    quantity,
                    OrderCampaigns.ANOTHER_ROUND
                )
            );
        } else if (orderItem?.id) {
            dispatch(actionCreators.removeFromOrder(orderItem.id, roundItem.itemId));
        }
    };

    return (
        <div className="another-round-carousel">
            <div className="another-round-carousel__header">
                <Text preset="title-28" mode="extra-bold">
                    Another round?
                </Text>
                <Text preset="g-14">Previous orders from your {tableLabel}.</Text>
            </div>
            <div className="another-round-carousel__items">
                {roundItems.map((roundItem, index) => (
                    <AnotherRoundCarouselItem
                        index={index}
                        key={roundItem.id}
                        quantity={getOrderItem(roundItem)?.quantity}
                        roundItem={roundItem}
                        onChange={handleOnChange}
                    />
                ))}
            </div>
        </div>
    );
}
