import { AvailablePrices, Subtypes } from "../menudata";
import { Payment } from "../payment";
import { LocationAddressDetails } from "../takeaway/reducers/takeawayOptions";
import { OpenTableMemberStatus } from "../openTable/types";
import { OfferData, OfferType } from "../offers";

export type ModifierMap = { [modifierIndex: number]: number[] };

export interface Party {
    id: string;
    restaurantId: string;
    tableNumber: string;
    type: PartyType;
    openTableOrderFlowBehaviorFlags?: OrderFlowBehaviorFlags;
    submittedOrders: Order[];
    activeOrder: Order;
    paymentOrder?: Order;
    serviceId: string;
    waiterId: string;
    menuDataUrl: string;
    menuDataUpdateUrl?: string;
    members: PartyMember[];
    payments: PartyPayment[] | null;
    refunds: PartyRefund[] | null;
    surchargeAmount: number;
    adjustments: PartyAdjustment[] | null;
    nextService: NextService;
    serviceHistory: string[];
    closeReason: PartyCloseReason;
    notifications: PartyNotification[];
    sequenceId: number | null;
    deliveryOptions?: DeliveryOptions[];
    orderReadyTime?: OrderReadyTime;
    menuDataAdUnitUrl?: string;
    billPriceSource?: PriceSource;
    isDemo: boolean;
    packageId: string | null;
    companyReceiptDetails?: CompanyDetails;
    dateOpened: string;
    status: PartyStatus;
    isTrayChargeEnabled: boolean;
    venueDetails: {
        venueAddress: LocationAddressDetails;
    };
    wallet: WalletDetails;
    securedPaymentState?: SecuredPaymentState;
    walkOutGratuityFactor?: number;
    supportedVenueCauses?: number[];
    flexJoinCode?: string;
    tableLabel?: string;
}

export enum PartyStatus {
    OPEN = 0,
    CLOSED = 1,
    PENDING_CLOSED = 2,
}

export interface CompanyDetails {
    registeredBusinessName: string;
    abn: string;
    address: string;
    privacyPolicyUrl: string;
}

export enum PartyType {
    MULTIUSER = 0,
    SINGLEUSER = 1,
    PAYONLY = 2,
}

export enum OrderFlowBehaviorFlags {
    Ordering = 1,
    Paying = 2,
    Tab = 4,
}

export enum PriceSource {
    LOCAL = 0,
    POS = 1,
}

export enum ServiceType {
    TABLE = 0,
    BAR = 1,
    BUZZER = 2,
}

export enum PartyCloseReason {
    PENDING = 0,
    ACCEPTED = 1,
    REJECTED = 2,
    CANCELLED = 3,
    COMPLETED = 4,
    TIMEDOUT = 5,
    LEFT = 6,
    FAILED = 7,
    VENUECLOSED = 8,
}

export interface PartyPayment extends Payment {
    includedMembers: string[];
    tip: number;
    creditAmount: number;
    forced: boolean;
    external: boolean;
    taxSummary: TaxClassTotal[];
    ownerName?: string;
    attemptedPaymentMethods?: string;
    cardType?: string;
    paymentMethodDescription?: string;
    processingFeeAbsorbed?: boolean;
    dateCreated?: string;
    transactionId?: string;
    includedItems?: OrderItem[];
}

export interface PaymentsFeedPayment extends PartyPayment {
    amountRefunded: number;
}

export interface PartyRefund {
    paymentId: string;
    amountRefunded: number;
    posTotal: number;
    processingFee: number;
    status: string;
}

export interface PromotionContext {
    locationPromotionId: string;
    code: string;
    name: string;
    minSpend: number;
    posId: string;
    maxUsage: number;
    description?: string;
    errorMessage?: string;
}

export interface PartyAdjustment {
    id: string;
    value: number;
    volatile: boolean; // Cannot be trusted to apply on bento screen, only calculate on payment screen
    type: AdjustmentType;
    adjustmentCategory: AdjustmentCategory;
    promotionContext?: PromotionContext;
    cepOfferContext?: CepOfferContext;
}

export enum AdjustmentCategory {
    PROMO_CODE = "PromoCode",
    DISCOUNT = "Discount",
    MEMBER_DISCOUNT = "MemberDiscount",
    SURCHARGE = "Surcharge",
    TRAY_CHARGE = "TrayCharge",
    POS_PROMO_CODE = "PosPromoCode",
    VENUE_SERVICE_FEE = "VenueServiceFee",
    VENUE_CAUSE = "VenueCause",
    ENGAGE_OFFER = "CepApplicableOffer",
}

export enum AdjustmentType {
    MONEY = 0,
    PERCENTAGE = 1,
}

export interface PaymentOptions {
    owner: string;
    splitPaymentMode: SplitPaymentMode;
}

export enum SplitPaymentMode {
    ORDER = 0,
    EQUAL = 1,
}

export interface NextService {
    id: string | null;
    name: string;
    changeTime: string;
    changing: boolean;
    overdue: boolean;
}

export interface Order {
    id: string;
    status: OrderStatus;
    items: OrderItem[];
    acceptedMembers: string[];
    bill: Bill;
    dateScheduled?: string;
    dateToSubmit?: string;
    dateSubmitted?: string;
}

export enum OrderStatus {
    OPEN = 0,
    SUBMITTED = 1,
    ACCEPTED = 2,
    SUBMITTING = 3,
    SUBMITFAILED = 4,
    VALIDATING = 5,
    VALID = 6,
    INVALID = 7,
    PAYING = 8,
    PAID = 9,
    PAYMENTFAILED = 10,
    REJECTED = 11,
    CLOSED = 12,
    PENDING = 13,
    ORPHANED = 14,
    READY = 15,
    COLLECTED = 16,
    ACCEPTED_OVERDUE = 17,
    READY_OVERDUE = 18,
    NOT_COLLECTED = 19,
    RESOLVED = 20,
    RESUBMITTING = 21,
    RESUBMITTED = 22,
}

export interface OrderItemBase {
    quantity: number;
    variant: number | null;
    modifiers: OrderItemModifier[] | null;
}

export interface EasyReorderItem extends OrderItemBase {
    itemId: string;
    notes: string | null;
}

export interface PendingItem extends OrderItemBase {
    courseId: string | null;
    orderItemId: string | null;
    itemId: string;
    menuId?: string;
    notes: string | null;
    canSubmit: boolean;
    unavailable: boolean;
    triedToSubmit: number;
    price: number;
    categoryId: string;
    modal?: boolean;
    preselect?: boolean;
    isFeatured?: boolean;
    subtypes?: Subtypes;
}

export interface OrderItem extends OrderItemBase {
    courseId: string;
    id: string;
    itemId?: string;
    categoryId?: string | null;
    memberId: string;
    notes: string;
    price?: number;
    unitPrice?: number;
    displayName?: string;
    description?: string;
    menuItemType?: string;
    failedToSubmit: boolean;
    failedOrderId: string | null;
    subtypes?: Subtypes;
    referenceId?: string;
    basePrice: number;
}
export interface OrderItemModifier {
    displayName?: string;
    modifier: number;
    options: number[];
    selectedOptions?: OrderItemSelectedModifier[];
    optionNestedModifiers?: OrderItemOptionNestedModifier[];
}

export interface OrderItemSelectedModifier {
    optionIndex: number;
    displayName: string;
    unitNonMemberPrice: number;
    unitPrice: number;
    nonMemberPrice: number;
    price: number;
}

export interface OrderItemOptionNestedModifier {
    optionIndex: number;
    quantity: number;
    modifiers: OrderItemNestedModifier[];
    selectedNestedData: OrderItemSelectedNestedModiferData[];
}

export interface OrderItemNestedModifier extends OrderItemModifier {
    modifierId: string;
}

export interface OrderItemSelectedNestedModiferData {
    displayName?: string;
    optionIndex?: number;
    modifierId?: string;
    price?: AvailablePrices;
    required: boolean;
}

export interface OrderItemSelectedNestedModiferDisplayData extends OrderItemSelectedNestedModiferData {
    nestingLevel: number;
}

export interface PartyMember {
    memberId: string;
    memberName: string;
    memberType: MemberType;
    membershipLevelId?: string | null;
    membershipProgramId?: string | null;
    membershipApplyOnValidate?: boolean;
    pictureUrl?: string;
    payOnlyPaymentsSeen?: number;
    payOnlyPaidWithServerTotalSeen?: number;
    memberActivity?: MemberActivity;
    firstName?: string;
    lastName?: string;
    hasSecuredPayment?: boolean;
}

export interface OpenTablePartyMember extends PartyMember {
    derivedMemberStatus?: OpenTableMemberStatus;
    initials?: string;
}

export enum MemberType {
    DEVICE = 0, // Tablet
    APP = 1, // Customer
    ANONYMOUS = 2, // Customer with no payment methods
}

export enum MemberActivity {
    None = 1,
    Ordering = 2,
    Paying = 3,
}

export interface CurrentPartyDetails {
    id: string;
    tableNumber: string;
    tableLabel: string;
    location: string;
    address?: string;
}

export enum PartyNotificationType {
    NOLIVEUPDATES = -1,
    PAYMENTRECEIVED = 0,
    PAYMENTRULESET = 1,
    USERJOINED = 2,
    USERLEFT = 3,
    VENUESLAMMED = 4,
    SERVICEENDING = 5,
    ORDERSENT = 6,
    ORDERACCEPTED = 7,
    EXTERNALPRODUCTADDED = 8,
    BARSERVICE = 9,
    RESTAURANTSERVICE = 10,
    CREDIT = 11,
    PARTYTIMEOUTWARNING = 12,
    REQUESTTOJOIN = 13,
    ORDERREJECTED = 14,
    ORDERFAILED = 15,
}

export enum PartyNotificationTier {
    TOAST = 0,
    STATUS = 1,
    MODAL = 2,
}

export interface PartyNotification {
    id: string;
    tier: PartyNotificationTier;
    type: PartyNotificationType;
    title: string;
    shortDescription: string | null;
    longDescription: string | null;
    dateCreated: number;
    viewedMembers: string[];
    includedMembers: string[];
    excludedMembers: string[];
    cancelled: boolean;
    choices: PartyNotificationChoice[];
    hideInTimeline: boolean;
}

export interface PartyNotificationChoice {
    name: string;
    selectedMembers: string[];
}

export interface TaxClassTotal {
    taxClass: string;
    taxRate: number;
    total: number;
    exclusive: boolean;
}

export enum BillAdjustmentType {
    PROMOCODE = "PromoCode",
    TRAY_CHARGE = "TrayCharge",
    ENGAGE_OFFER = "CepApplicableOffer",
    VENUE_CAUSE = "VenueCause",
}

export interface BillAdjustment {
    name: string;
    promotionId?: string;
    type: BillAdjustmentType;
    value: number;
    adjustmentId: string;
}

export interface BillBase {
    orderTotal: number;
    subTotal: number;
    nonMemberSubTotal: number;
    surchargeAmount: number;
    surchargeAmountExTax: number;
    surchargePercentage: number;
    discountAmount: number;
    membershipDiscountAmount: number;
    total: number;
    pendingRemainingBalance?: number;
    remainingBalance?: number;
    taxSummary: TaxClassTotal[];
    adjustments?: BillAdjustment[];
    exclusiveTaxTotal: number;
    offerDiscountAmount?: number;
}

export interface Bill extends BillBase {
    memberShares: MemberShare[];
}

export interface MemberShare extends BillBase {
    memberId: string;
}
export interface DeliveryOptions {
    type: DeliveryOptionType;
    name?: string;
    menuItemType: string;
}

export enum DeliveryOptionType {
    TAKEAWAY = "takeaway",
    PICKUP = "pickup",
    TABLE = "table",
}

export enum AlcoholicDrinksLimit {
    NONE = 0,
    UNDER = 1,
    REACHED = 2,
    EXCEEDED = 3,
}

export interface OrderReadyTime {
    earliestInMinutes: number;
    latestInMinutes: number;
}

export interface OpenTableContext {
    hasSubmittedOrders: boolean;
    hasFlexTabBehavior: boolean;
}

export type ModifierListType = "radio" | "checkbox" | "multi-select";

export interface WalletDetails {
    enabledForLocation: boolean;
    enabledForParty: boolean;
}

export enum SecuredPaymentState {
    NOT_REQUIRED = 1,
    REQUIRED = 2,
    SATISFIED = 3,
}

export interface CepOfferContext {
    offerId: string;
    offerType: OfferType;
    bounceBack?: OfferData;
}
