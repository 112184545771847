import { createSelector } from "reselect";
import {
    getPayOnlyDiscountAmount,
    getPayOnlyExclusiveTaxTotal,
    getPayOnlyProcessingFee,
    getPayOnlyProcessingFeeWithoutTip,
    getPayOnlyTipAmount,
    getPayOnlyTotal,
    getPayOnlyTotalBeforeTip,
    getPayOnlyTotalWithoutTip,
    getVenueServiceFee,
    getVenueServiceFeeFactor,
} from "./payOnlyShare";
import { getCompleteRefunds, getMemberCompletePayments } from "src/features/payment/selectors/party";
import { getSelectedPaymentMethod } from "src/features/payment/selectors/paymentMethods";
import { getCompletePayments } from "../../payment/selectors/party";
import { getCurrentMemberId } from "../../accounts/selectors";
import { PartyPayment, PaymentsFeedPayment } from "../../order";
import { getAnyParty, getPartyMembersLength } from "../../order/selectors";
import { getPayOnlyBill } from "./payOnlyBill";
import { evenRound } from "src/sharedComponents/common/utils";
import { makePayOnlyPaymentOperation } from "../operations";
import { getIsOpenTableFlexTab } from "../../openTable/selectors";
import { PosPaymentStatus } from "../../payment";
import { getHasPaymentOrder, getPayOnlyState } from "./payOnlyState";
import { getRestaurantFlags } from "src/features/order/selectors/restaurantFlags";
import { getPaidWithServerTotalSeen, getPayOnlyEmbellishedItems } from "./payOnlyOrder";

export const getPayOnlyMemberHasUnconfirmedPayments = createSelector(
    getHasPaymentOrder,
    getMemberCompletePayments,
    (hasPaymentOrder, payments) =>
        hasPaymentOrder &&
        payments.some((p) => (p.posStatus ?? PosPaymentStatus.SUBMITTING) === PosPaymentStatus.SUBMITTING)
);

const getFailedToSubmitPayments = (hasPaymentOrder: boolean, payments: PartyPayment[]) =>
    hasPaymentOrder ? payments.filter((p) => p.posStatus === PosPaymentStatus.REJECTED) : [];

export const getPayOnlyMemberHasFailedToSubmitPayments = createSelector(
    getHasPaymentOrder,
    getMemberCompletePayments,
    (hasPaymentOrder, payments) => !!getFailedToSubmitPayments(hasPaymentOrder, payments).length
);

export const getPayOnlyFailedToSubmitPayments = createSelector(
    getHasPaymentOrder,
    getCompletePayments,
    getFailedToSubmitPayments
);

export const getPayOnlyPaymentStatus = createSelector(
    makePayOnlyPaymentOperation.getStatus,
    (paymentStatus) => paymentStatus
);

export const getPayOnlySplitPaymentOptions = createSelector(
    getPartyMembersLength,
    getRestaurantFlags,
    getIsOpenTableFlexTab,
    getPayOnlyEmbellishedItems,
    (partyMembersLength, restaurantFlags, isOpenTableFlexTab, embellishedItems) => {
        let availableOptions =
            (isOpenTableFlexTab && partyMembersLength <= 1) || !restaurantFlags?.splitPaymentOptions
                ? []
                : restaurantFlags.splitPaymentOptions;

        // Edgecase, but if there are no items to select we don't want to show
        // a blank "Split by items" view
        if (embellishedItems.length === 0) {
            availableOptions = availableOptions.filter((o) => o !== "SelectItems");
        }

        return availableOptions;
    }
);

export const getPayOnlyPaymentDetail = createSelector(
    getPayOnlyTotal,
    getPayOnlyTotalWithoutTip,
    getPayOnlyTotalBeforeTip,
    getPayOnlyProcessingFee,
    getPayOnlyProcessingFeeWithoutTip,
    getPayOnlyTipAmount,
    getPayOnlyPaymentStatus,
    getSelectedPaymentMethod,
    getVenueServiceFee,
    getVenueServiceFeeFactor,
    getPayOnlyExclusiveTaxTotal,
    getPayOnlyDiscountAmount,
    (
        total,
        totalWithoutTip,
        totalBeforeTip,
        processingFee,
        processingFeeWithoutTip,
        tipAmount,
        paymentStatus,
        selectedPaymentMethod,
        venueServiceFee,
        venueServiceFeeFactor,
        exclusiveTaxTotal,
        discountAmount
    ) => {
        const paying = paymentStatus === "processing" || paymentStatus === "complete";

        return {
            subTotal: totalBeforeTip,
            processingFee: paying ? processingFee : processingFeeWithoutTip,
            total: paying ? total : totalWithoutTip,
            tipAmount,
            paymentType: selectedPaymentMethod && selectedPaymentMethod.paymentType,
            paying,
            venueServiceFee,
            venueServiceFeeFactor,
            exclusiveTaxTotal,
            discountAmount,
        };
    }
);

export const getPayOnlyPaidAmount = createSelector(getMemberCompletePayments, getCompleteRefunds, (payments, refunds) =>
    evenRound(
        payments.reduce((total, payment) => {
            const refund = refunds.find((r) => r.paymentId === payment.id);
            return total + payment.amount - (refund?.posTotal ?? 0);
        }, 0),
        2
    )
);

export const getPayOnlyUnseenPayments = createSelector(
    getAnyParty,
    getCurrentMemberId,
    getCompletePayments,
    getCompleteRefunds,
    getPaidWithServerTotalSeen,
    (party, memberId, payments, refunds, paidWithServerTotalSeen) => {
        const member = party?.members.find((m) => m.memberId === memberId);
        let unseenPayments = payments.length + refunds.length - (member?.payOnlyPaymentsSeen ?? 0);
        if (paidWithServerTotalSeen > (member?.payOnlyPaidWithServerTotalSeen ?? 0)) {
            unseenPayments++;
        }
        return unseenPayments;
    }
);

export const getPayOnlyPaymentsFeed = createSelector(
    getCompletePayments,
    getCompleteRefunds,
    getCurrentMemberId,
    getPayOnlyBill,
    (payments, refunds, memberId, bill) =>
        payments.reduce(
            (feed, payment, index) => {
                const { owner, ownerName = "Someone", amount, tip } = payment;
                const refund = refunds.find((r) => r.paymentId === payment.id);
                const amountRefunded = refund?.amountRefunded ?? 0;
                const posTotalRefunded = refund?.posTotal ?? 0;
                const processingFeeRefunded = refund?.processingFee ?? 0;
                const tipRefunded = evenRound(amountRefunded - posTotalRefunded - processingFeeRefunded, 2);
                feed.payments.push({
                    ...payment,
                    ownerName: owner === memberId ? "You" : ownerName,
                    amountRefunded,
                });
                feed.total += evenRound(amount - posTotalRefunded, 2);
                feed.tipPercentage += evenRound(tip - tipRefunded, 2);
                if (index === payments.length - 1) {
                    feed.total = evenRound(feed.total, 2);
                    feed.tipPercentage = evenRound(feed.tipPercentage / bill!.total, 2);
                }
                return feed;
            },
            {
                payments: [] as PaymentsFeedPayment[],
                total: 0,
                tipPercentage: 0,
            }
        )
);

export const getPayOnlyLastShare = createSelector(getPayOnlyState, getCompletePayments, ({ share }, payments) => {
    if (share) return share;
    const lastPaymentShare = payments.filter((p) => p.share).reverse()[0]?.share;
    return (
        lastPaymentShare && {
            split: lastPaymentShare.split,
            shares: 1,
        }
    );
});
