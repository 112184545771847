import * as React from "react";
import { StatusBar } from "../../../common/statusBar";
import { ProfileDrawerContainer } from "../containers/ProfileDrawerContainer";
import { AppState } from "../../index";
import { connect } from "react-redux";
import { ModalContainer } from "src/common/modal";

export interface Props {
    open: boolean;
}

export class ProfileModalInner extends React.Component<Props> {
    render() {
        const { open } = this.props;
        return (
            <ModalContainer
                isOpen={open}
                className={{
                    base: "profile-drawer-modal",
                    afterOpen: "profile-drawer-modal--after-open",
                    beforeClose: "profile-drawer-modal--before-close",
                }}
                overlayClassName="ReactModal__ProfileDrawer keyboard-unfixed"
                contentLabel="Filter Modal"
                closeTimeoutMS={250}
            >
                <StatusBar backgroundColor="#fff" />
                <ProfileDrawerContainer open />
            </ModalContainer>
        );
    }
}

const mapStateToProps = (state: AppState): Props => {
    const { open } = state.accountMenu;
    return {
        open,
    };
};

export const ProfileModal = connect(mapStateToProps)(ProfileModalInner);
