import "../assets/OrderItem.scss";

import React, { useState } from "react";
import { OrderCourseItem } from "../reducers/types";
import { MenuItemButton } from "src/features/menuitem";
import classNames from "classnames";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { AlertIcon } from "src/sharedComponents/assets/icons";
import { CSSTransition } from "react-transition-group";
import { OrderItemLine } from "./OrderItemLine";
import { Text } from "src/sharedComponents";

export interface Props {
    item: OrderCourseItem;
    hidePrice?: boolean;
    activeItem?: boolean;
    selectable?: boolean;
    removeItem?: (orderItemId: string, menuItemId: string) => void;
    onSelectItem?: (referenceId: string, quantity: number) => void;
}

export interface AllOrderItemLinesProps {
    item: OrderCourseItem;
    activeItem?: boolean;
    itemSelected?: boolean;
    selectable?: boolean;
}

export const OrderItemRow = ({ item, hidePrice, activeItem, selectable, removeItem, onSelectItem }: Props) => {
    const [showing, setShowing] = useState(true);

    return (
        <CSSTransition
            in={showing}
            enter={false}
            onExited={() =>
                removeItem && item.orderItemId && item.menuItemId && removeItem(item.orderItemId, item.menuItemId)
            }
            classNames="order-item-transition"
            timeout={200}
            unmountOnExit
        >
            <div className={classNames("order-item__botton", { "order-item__error": item.failedToSubmit })}>
                {selectable ? (
                    <OrderItemLine
                        item={item}
                        hidePrice={hidePrice}
                        activeItem={activeItem}
                        selectable={selectable}
                        onSelectItem={onSelectItem}
                    />
                ) : (
                    <>
                        {item.failedToSubmit && activeItem && removeItem && (
                            <TappableDiv onTap={() => setShowing(false)} className="order-item__remove">
                                <Text preset="g-14" mode="medium" className="order-item__remove__content">
                                    <AlertIcon />
                                    Not available. Tap to remove
                                </Text>
                            </TappableDiv>
                        )}
                        <MenuItemButton
                            menuItemId={item.menuItemId || ""}
                            item={item}
                            disabledItem={!activeItem}
                            disabled={!activeItem || !item.menuItemId}
                            className="order-item__botton"
                        >
                            <OrderItemLine item={item} hidePrice={hidePrice} activeItem={activeItem} />
                        </MenuItemButton>
                    </>
                )}
            </div>
        </CSSTransition>
    );
};
