import { waitMinimum } from "src/common/shared";
import { AppDispatch, AppState } from "src/features";
import { getParty } from "src/features/order";
import { OrderCourseItem } from "src/features/order/reducers/types";
import { mapOrderItemToCourseItem } from "src/features/orderHistoryItem/components/OrderHistoryItemList";
import { getHistoryOrderDataApi, getOrderDataApi } from "../API/getOrderDataApi";
import { getOrderDataOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as groupTabHistoryActionCreators } from "src/features/groupTabsHistory/reducers/groupTabHistory";
import { getActiveGroupTabData } from "../selectors/activeGroupTab";
import { getSpecificGroupTab } from "./getGroupTabAction";

export const getOrderDataAction = (partyId: string) => {
    return getOrderDataOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const state = getState();
            const inParty = !!getParty(state);
            const res = await waitMinimum(getOrderDataApi(inParty, partyId), 1000);
            if (!res) {
                const groupTab = getActiveGroupTabData(state);
                if (groupTab) {
                    dispatch(getSpecificGroupTab(groupTab.id));
                }
                return;
            }
            const mapped = res.details?.orderDetail
                ? mapOrderItemToCourseItem(res.details?.orderDetail)
                : ([] as OrderCourseItem[]);
            dispatch(activeTabActionCreators.setOrderData(partyId, mapped, res));
        } catch (ex) {
            console.log("failed to get Group Tab order data:", ex);
        }
    });
};

export const getGroupTabOrderDataAction = (groupTabId: string, partyId: string) => {
    return getOrderDataOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const res = await waitMinimum(getHistoryOrderDataApi(groupTabId, partyId), 1000);
            const mapped = res.details?.orderDetail
                ? mapOrderItemToCourseItem(res.details?.orderDetail)
                : ([] as OrderCourseItem[]);

            dispatch(groupTabHistoryActionCreators.setOrderData(groupTabId, partyId, mapped, res));
        } catch (ex) {
            console.log("failed to get Group Tab History order data:", ex);
        }
    });
};
