import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { BeginBrowserPaymentInfo, BeginBrowserPaymentResult } from "../types";
import { getAuthHeaders } from "../../../common/auth";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { PaymentGateway } from "../../../common/payment";

export const beginBrowserPayment = async (
    paymentGateway: PaymentGateway,
    beginBrowserPaymentInfo: BeginBrowserPaymentInfo,
    inParty: boolean
) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/paymentgateway/${paymentGateway}/browserpayment`,
        {
            method: "POST",
            body: JSON.stringify(beginBrowserPaymentInfo),
            headers,
        },
        reattemptStrategies.limited
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as BeginBrowserPaymentResult;
};
