import * as React from "react";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { OrderStatus, Party } from "../types";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { AlertIcon, GastronomyIcon } from "src/sharedComponents/assets/icons";
import classNames from "classnames";
import { SelectablePaymentMethod } from "src/common/payment";
import { PaymentDetail } from "./PaymentDetail";
import { getTableNumber } from "src/common/shared";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { modalMessages } from "../../modalMessage/messages";
import { Button, Checkbox, Divider, Prompt, Text } from "src/sharedComponents";
import { AccountProfile } from "src/features/accountmenu";
import { capitaliseFirstLetter, emailFormatter } from "src/common/formatter";
import { LegalModal, LegalPageType } from "src/features/compliance/components/LegalModal";
import "../assets/ReviewOrder.scss";
import { CartViewPreviewContainer } from "../container/CartViewPreviewContainer";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { ConnectedMembershipState } from "src/features/membership";
import { ManageMembershipRow } from "src/features/membership/components/ManageMembershipRow";
import { TitleWithAction } from "src/sharedComponents/controls/titleWithAction/TitleWithAction";
import { PaymentButton } from "../../payment/components/PaymentButton";
import { submitPaymentAndOrderOperation } from "../../payment/operations";
import { handlePayment } from "../../payment/actions/handlePayment";
import { PaymentMethod } from "../../paymentMethods/components/PaymentMethod";
import { VenueDetails } from "src/features/takeaway/components/VenueDetails";
import {
    CollectionDetails,
    LocationAddressDetails,
    TakeawayDetails,
} from "src/features/takeaway/reducers/takeawayOptions";
import { VenueCause } from "../../venueCause/VenueCause";
import { MembershipPayWithPoints } from "../../membership/components/MembershipPayWithPoints";

export interface Props {
    party: Party | null;
    selectedPaymentMethod: SelectablePaymentMethod | null;
    fromTheVenueTitle?: string;
    fromTheVenue?: string;
    connected: boolean;
    showDeliveryOptions: boolean;
    showModalMessage: (modalMessage: ModalMessage) => void;
    orderStatus: OrderStatus | null;
    mustAcceptAllergenNotice: boolean;
    profile: AccountProfile;
    closeOrderView: boolean;
    editAccountDetails: () => void;
    changePaymentMethod: () => void;
    changeTabPaymentMethod: () => void;
    acceptAllergenNotice: (accept: boolean) => void;
    acceptedAllergenNotice: boolean;
    connectedMemberShip: ConnectedMembershipState | null;
    manageMemberships: () => void;
    validateActiveOrderImmediately: () => void;
    isOpeningTab?: boolean;
    onPrivacyPolicyClicked: () => void;
    isTakeaway: boolean;
    orderReadyTime?: number;
    orderDateTimeScheduled?: Date | null;
    venueCollectionDetails: LocationAddressDetails | undefined;
    locationName: string | undefined;
    storeVenueCollectionDetails: (locationId: string, collectionDetails: CollectionDetails) => void;
    locationId: string | null;
    hasVenueCollectionDetails: boolean;
    takeawayDetails: TakeawayDetails;
    showDemoBanner: boolean;
    isAllowedZeroPayment: boolean;
    canChangePaymentMethod: boolean;
    tableLabel: string;
}

export interface State {
    showLegalModal?: LegalPageType;
    showOrderView: boolean;
    acceptAllergenNoticeInvalid: boolean;
}

export class ReviewOrder extends React.Component<Props, State> {
    static contextType = MenuDataLocaleContext;
    context!: MenuDataLocale;

    venueMessageComp: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            acceptAllergenNoticeInvalid: false,
            showOrderView: false,
        };

        this.venueMessageComp = React.createRef();
    }

    componentDidUpdate(prevProps: Props) {
        const { orderStatus, showModalMessage, validateActiveOrderImmediately } = this.props;

        if (prevProps.orderStatus !== orderStatus) {
            switch (orderStatus) {
                case OrderStatus.OPEN:
                    return validateActiveOrderImmediately();
                case OrderStatus.INVALID:
                    return showModalMessage(modalMessages.validationFailed());
                case OrderStatus.SUBMITFAILED:
                    return showModalMessage(modalMessages.lostConnection());
            }
        }
    }

    render() {
        const {
            fromTheVenue,
            showDeliveryOptions,
            closeOrderView,
            connectedMemberShip,
            isTakeaway,
            orderReadyTime,
            orderDateTimeScheduled,
            venueCollectionDetails,
            locationName,
            showDemoBanner,
        } = this.props;

        const { showLegalModal, showOrderView } = this.state;

        const showPickupDetails =
            isTakeaway && (orderReadyTime || orderDateTimeScheduled) && venueCollectionDetails && locationName;

        return (
            <LocationThemeContainer>
                <div className="review-order">
                    <main className={classNames("review-order__content", "animated-child")}>
                        <Text preset="title-28" mode="bold" className="review-order__title">
                            Review order
                        </Text>
                        {showDemoBanner && (
                            <Prompt
                                type="warning"
                                title="You’re in demo mode"
                                subtitle="Your payment won’t be processed. The kitchen won’t prepare your order."
                                titleLeftIcon={<AlertIcon />}
                            />
                        )}
                        <VenueCause />
                        {showDeliveryOptions && !isTakeaway && this.renderFoodDelivery()}
                        {this.renderFromTheVenueMessage()}
                        {showPickupDetails && this.renderPickupDetails()}
                        {(showDeliveryOptions || !!fromTheVenue || showDemoBanner) && <Divider fullWidth />}
                        {this.renderDetails()}
                        {!!connectedMemberShip && (
                            <>
                                <Divider fullWidth />
                                {this.renderMembership()}
                            </>
                        )}
                        <Divider fullWidth />
                        {this.renderPaymentMethod()}
                        <MembershipPayWithPoints />
                        <Divider fullWidth />
                        {this.renderSummary()}
                        <Divider fullWidth />
                        <Text preset="g-12" className="legal-text__terms">
                            Card processing fee may change based on payment method. By placing an order I agree to the{" "}
                            <TappableSpan onTap={() => this.setState({ showLegalModal: "termsOfUse" })}>
                                Terms &amp; Conditions
                            </TappableSpan>
                            &nbsp;&amp; to my data being handled in accordance with the{" "}
                            <TappableSpan
                                onTap={() => {
                                    this.props.onPrivacyPolicyClicked();
                                    this.setState({ showLegalModal: "privacyPolicy" });
                                }}
                            >
                                Privacy Policy
                            </TappableSpan>
                            .
                        </Text>
                    </main>
                    {this.renderFooter()}
                    <LegalModal
                        legalPage={showLegalModal}
                        goBack={() => this.setState({ showLegalModal: undefined })}
                    />
                </div>
                <CartViewPreviewContainer isOpen={!closeOrderView && showOrderView} close={this.closeOrderView} />
            </LocationThemeContainer>
        );
    }

    renderFoodDelivery = () => (
        <div className="review-order__content__messages">
            <Text preset="g-16" mode="bold" className="review-order__content__messages__title">
                <GastronomyIcon />
                Collect your order when ready
            </Text>
            <Text preset="g-14" className="review-order__content__messages__description">
                We’ll send you an SMS when your food is ready to collect. Drinks will be delivered to your{" "}
                {this.props.tableLabel}
            </Text>
        </div>
    );

    renderPickupDetails = () => {
        const {
            orderReadyTime,
            orderDateTimeScheduled,
            venueCollectionDetails,
            locationName,
            hasVenueCollectionDetails,
            storeVenueCollectionDetails,
            locationId,
            takeawayDetails: venueContactInfo,
        } = this.props;

        const collectionDetails = {
            venueAddress: venueCollectionDetails!,
        };

        if (!hasVenueCollectionDetails && locationId) {
            storeVenueCollectionDetails(locationId, {
                ...collectionDetails,
                collectionInstructions: venueContactInfo.collectionInstructions,
                contactPhoneNumber: venueContactInfo.contactPhoneNumber,
            });
        }

        const orderReadyTimeFormat = new Date();
        orderReadyTimeFormat.setMinutes(orderReadyTimeFormat.getMinutes() + (orderReadyTime || 0));

        const collectionTime = orderDateTimeScheduled
            ? this.context.getDateTimeShort(orderDateTimeScheduled)
            : `From ${this.context.getDateTimeShort(orderReadyTimeFormat)}`;

        return (
            <div className="review-order__content__details">
                <VenueDetails
                    locationName={locationName!}
                    collectionDetails={collectionDetails}
                    collectionTime={collectionTime}
                    lightBackground
                    showMapAndPhone={false}
                />
            </div>
        );
    };

    onAcceptAllergenNoticeChecked = (checked: boolean) => {
        this.props.acceptAllergenNotice(checked);
        this.setState({ acceptAllergenNoticeInvalid: false });
    };

    renderFromTheVenueMessage = () => {
        const { fromTheVenueTitle, fromTheVenue, mustAcceptAllergenNotice, acceptedAllergenNotice } = this.props;
        const { acceptAllergenNoticeInvalid } = this.state;
        if (!fromTheVenue) return null;
        return (
            <div className="review-order__content__messages" ref={this.venueMessageComp}>
                <Text preset="g-18" mode="bold" className="review-order__content__messages__title">
                    {fromTheVenueTitle ?? "Dietary & allergen notice"}
                </Text>
                <Text
                    preset="g-14"
                    mode="block"
                    className="review-order__content__messages__description"
                    value={fromTheVenue}
                />
                {mustAcceptAllergenNotice && (
                    <Checkbox
                        id="i-understand"
                        className={classNames({ invalid: acceptAllergenNoticeInvalid })}
                        onChange={this.onAcceptAllergenNoticeChecked}
                        label="I understand"
                        defaultChecked={acceptedAllergenNotice}
                    />
                )}
            </div>
        );
    };

    renderDetails = () => {
        const {
            profile: { firstName, phone, email },
            party,
            editAccountDetails,
            isTakeaway,
            tableLabel,
        } = this.props;

        return (
            <div className="review-order__content__details">
                <TitleWithAction title="Your details" onClick={editAccountDetails} ctaText="Edit" />
                {this.renderDetailsRow("Name", firstName)}
                {this.renderDetailsRow("Phone number", phone!)}
                {this.renderDetailsRow("Email", emailFormatter(email), "Add for a receipt")}
                {!isTakeaway && this.renderDetailsRow(capitaliseFirstLetter(tableLabel), getTableNumber(party) || "")}
            </div>
        );
    };

    renderMembership = () => {
        const { connectedMemberShip, manageMemberships } = this.props;
        return (
            <div className="review-order__content__details">
                <TitleWithAction title="Membership" onClick={manageMemberships} ctaText="Change program" />
                <ManageMembershipRow membership={connectedMemberShip!} />
            </div>
        );
    };

    renderPaymentMethod = () => {
        const { selectedPaymentMethod, changePaymentMethod, canChangePaymentMethod, isAllowedZeroPayment } = this.props;

        if (isAllowedZeroPayment) {
            return (
                <div className="review-order__content__details no-payment-method">
                    <TitleWithAction title="Paying with" />
                </div>
            );
        }

        return (
            <div
                className={classNames("review-order__content__details", !selectedPaymentMethod && "no-payment-method")}
            >
                {!canChangePaymentMethod ? (
                    <TitleWithAction title="Paying with" />
                ) : (
                    <TitleWithAction
                        title="Paying with"
                        onClick={changePaymentMethod}
                        ctaText={`${selectedPaymentMethod ? "Change" : "Add"} payment`}
                    />
                )}
                {selectedPaymentMethod && <PaymentMethod paymentMethod={selectedPaymentMethod} />}
            </div>
        );
    };

    renderSummary = () => (
        <div className="review-order__content__details">
            <TitleWithAction title="Payment summary" onClick={this.showOrderView} ctaText="Show items" />
            <PaymentDetail />
        </div>
    );

    renderDetailsRow = (key: string, value?: string, placeholder?: string) => (
        <div className="review-order__content__details__row">
            <Text preset="g-14" value={key} />
            {!!value ? (
                <Text preset="g-14" value={value} />
            ) : (
                <Text
                    preset="g-14"
                    className="review-order__content__details__row__placeholder"
                    value={placeholder || ""}
                />
            )}
        </div>
    );

    showPaymentMethodsOnFail = () => {
        const { isOpeningTab, changePaymentMethod, changeTabPaymentMethod } = this.props;
        if (isOpeningTab) {
            changeTabPaymentMethod();
        } else {
            changePaymentMethod();
        }
    };

    renderFooterButton = () => {
        const { fromTheVenueTitle } = this.props;

        if (this.getNeedsToAcceptAllergenNotice()) {
            return (
                <Button
                    mode="solid"
                    className="disabled"
                    onClick={() =>
                        this.setState({ acceptAllergenNoticeInvalid: true }, () => {
                            this.venueMessageComp.current?.previousElementSibling?.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end",
                            });
                        })
                    }
                    value={fromTheVenueTitle ? "Accept venue notice" : "Accept allergen notice"}
                />
            );
        }

        return (
            <PaymentButton
                value="Send order"
                payingValue="Sending your order..."
                onPayment={handlePayment}
                changePaymentMethod={this.showPaymentMethodsOnFail}
                operation={submitPaymentAndOrderOperation}
            />
        );
    };

    renderFooter = () => {
        const { connected } = this.props;

        return (
            <footer
                className={classNames(
                    "review-order__footer",
                    "top-shadow",
                    !connected && "review-order__footer--not-connected"
                )}
            >
                {this.renderFooterButton()}
            </footer>
        );
    };

    getNeedsToAcceptAllergenNotice = () => {
        const { mustAcceptAllergenNotice, fromTheVenue, acceptedAllergenNotice } = this.props;
        return mustAcceptAllergenNotice && fromTheVenue && !acceptedAllergenNotice;
    };

    showOrderView = () => this.setState({ showOrderView: true });

    closeOrderView = () => this.setState({ showOrderView: false });
}
