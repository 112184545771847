import "./HistoryPage.scss";

import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import { SimpleNavHeader } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import { Text } from "src/sharedComponents";
import {
    AngleRightIcon,
    PromotionsTagIcon,
    QuestionMarkIcon,
    RightArrowIcon,
    SendIcon,
} from "src/sharedComponents/assets/icons";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/features";
import { actionCreators as taxInvoiceActions } from "src/features/orderHistoryData/reducers/taxInvoice";
import { AccountDetailsModal } from "src/features/accounts/components/AccountDetailsModal";
import { marketingMetaData, showMarketingPage } from "src/features/marketing/actions/displayMarketing";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { actionCreators as historyActionCreators } from "../../orderHistoryData/reducers/mealHistory";
import classNames from "classnames";
import { getMembershipSignupPromotion } from "src/features/membership/selectors/getMembershipSignupPromotion";
import { signupPromotionActions } from "src/features/membership";

interface Props {
    onBack?: () => void;
    historyName: string;
    historyTime: string;
    locationName: string;
    locationId: string;
    locationImagePath?: string;
    locationLogoPath?: string;
    locationColor?: string;
    customBack?: string;
    items: ReactNode;
    payment: ReactNode;
    orderDetails?: ReactNode;
    additional?: ReactNode;
    topElements?: ReactNode;
    sendReceipt?: () => void;
    update?: boolean;
}

export const HistoryPage = ({
    onBack,
    historyName,
    historyTime,
    locationName,
    locationId,
    locationImagePath,
    locationLogoPath,
    locationColor,
    sendReceipt,
    customBack,
    items,
    payment,
    topElements,
    additional,
    orderDetails,
    update,
}: Props) => {
    const dispatch = useDispatch();
    const profile = useSelector((state: AppState) => state.profile.result);
    const showInlineMarketingOptIn = useSelector((state: AppState) => state.marketing.enabled);
    const signupPromotion = useSelector(getMembershipSignupPromotion);

    const [showAccountDetails, setShowAccountDetails] = useState(false);

    const sendReceiptInner = useCallback(() => {
        dispatch(taxInvoiceActions.trackInvoiceRequested());
        if (!profile?.email) {
            setShowAccountDetails(true);
        } else {
            sendReceipt && sendReceipt();
        }
    }, [profile, dispatch, sendReceipt]);

    useEffect(
        () => () => {
            dispatch(historyActionCreators.selectPartyMealHistory(null, false));
        },
        [dispatch]
    );

    const openMarketing = () => {
        dispatch(
            showMarketingPage({
                locationId,
                partyId: null,
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                email: profile?.email,
                locationImage: locationImagePath,
                locationLogo: locationLogoPath,
                locationName,
            })
        );
    };

    useEffect(() => {
        if (locationId && !signupPromotion) {
            dispatch(marketingMetaData(locationId));
        }
    }, [dispatch, locationId, signupPromotion]);

    return (
        <div
            className={classNames("history-item", update && "history-item--update")}
            style={{ "--theme-primary-color": locationColor || "var(--color-business)" } as CSSProperties}
        >
            <StatusBar backgroundColor={"#000"} />
            <div
                className="history-item__header-container"
                style={{
                    background: `linear-gradient(0, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${locationImagePath})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
            <div className={"fixed-at-top no-background"}>
                <div className={"history-item__title"} />
                <SimpleNavHeader closeToBack={!!onBack} circleClose onBack={onBack} customBack={customBack} />
            </div>
            <div className="flex-top item advanced-scroll">
                <div className="scroll-area">
                    <div className="history-item__header-content">
                        <Text preset="title-20" mode={["bold", "block"]} className="history-item__header">
                            <div className="history-item__logo">
                                {locationLogoPath && locationLogoPath.endsWith(".svg") && (
                                    <SVG className="history-item__logo" src={locationLogoPath} color="white" />
                                )}
                            </div>
                            {locationName}
                        </Text>
                        <Text preset="g-16" mode="block" className="history-item__header-details">
                            <Text preset="g-16" mode={["bold", "block"]}>
                                {historyName}
                            </Text>
                            {historyTime}
                        </Text>
                    </div>
                    {topElements}
                    {showInlineMarketingOptIn && !signupPromotion && <MarketingOptInButton onTap={openMarketing} />}
                    {signupPromotion && <MembershipSignupPromotionButton />}
                    {sendReceipt && (
                        <TappableDiv className="history-item__section request-receipt" onClick={sendReceiptInner}>
                            <Text preset="g-16" mode="bold">
                                {"Request email receipt"}
                            </Text>
                            {!!profile?.email ? <SendIcon /> : <RightArrowIcon color="#C4C4C4" />}
                        </TappableDiv>
                    )}
                    {orderDetails ?? (
                        <>
                            {payment}
                            {items}
                        </>
                    )}
                    {additional}
                    <div className="history-item__section having-issues">
                        <QuestionMarkIcon />
                        <Text preset="g-12" mode="block">
                            Having an issue with your order? Talk to venue staff for assistance.
                        </Text>
                    </div>
                </div>
            </div>
            <AccountDetailsModal
                showModal={showAccountDetails}
                onSuccess={sendReceipt}
                onClose={() => setShowAccountDetails(false)}
            />
        </div>
    );
};

const MarketingOptInButton = ({ onTap }: { onTap: () => void }) => {
    const title = useSelector((state: AppState) => state.marketing.title);

    return (
        <TappableDiv className="history-item__section history-item__marketing-opt-in" onTap={onTap}>
            <Text preset="g-16" mode="bold">
                {title || "Get venue promotions"}
            </Text>
            <Text preset="g-14" className="history-item__marketing-opt-in--icon">
                <PromotionsTagIcon />
            </Text>
        </TappableDiv>
    );
};

const MembershipSignupPromotionButton = () => {
    const dispatch = useDispatch();
    const { logoUrl, programName, manualTriggerText } = useSelector(getMembershipSignupPromotion)!;

    return (
        <TappableDiv
            className="history-item__section history-item__membership-signup-promotion"
            onTap={() => dispatch(signupPromotionActions.showSignupPromotionModal(true))}
        >
            <Text preset="g-16" mode="bold">
                {logoUrl && <img src={logoUrl} alt={programName} />}
                {manualTriggerText}
            </Text>
            <Text preset="g-14">
                <AngleRightIcon />
            </Text>
        </TappableDiv>
    );
};
