import { createSelector } from "reselect";
import { AppState } from "src/features";
import { getActiveServiceMenus, getLimitedMenuPackages, getMenuData } from "../../menu/selectors";
import { LimitedMenuType } from "../types";
import { limitedMenuDetails } from "./helpers";

export const getGroupTabsWizard = (state: AppState) => state.groupTabs.wizard;

export const getShowGroupTabsWizard = createSelector(getGroupTabsWizard, (wizard) => wizard.showWizard);

export const getWizardGroupTabsPages = createSelector(getGroupTabsWizard, (wizard) => wizard.pages);

export const getWizardGroupTabsPage = createSelector(getGroupTabsWizard, (wizard) => wizard.page);

export const getWizardGroupTabsName = createSelector(getGroupTabsWizard, (wizard) => wizard.tabName);

export const getWizardGroupTabsLimit = createSelector(getGroupTabsWizard, (wizard) => wizard.tabLimit);

export const getWizardGroupTabsMenuType = createSelector(
    getActiveServiceMenus,
    getLimitedMenuPackages,
    getGroupTabsWizard,
    (activeServiceMenus, limitedMenuPackages, wizard) => {
        const hasActiveLimitedMenuPackages = limitedMenuPackages && limitedMenuPackages.some((pack) => pack.enabled);
        if (!wizard.menuType && (!hasActiveLimitedMenuPackages || !activeServiceMenus?.length)) {
            return LimitedMenuType.FULL;
        }

        return wizard.menuType;
    }
);

export const getWizardGroupTabsPackageId = createSelector(getGroupTabsWizard, (wizard) => wizard.packageId);

export const getMenuPackageByWizardId = createSelector(
    getLimitedMenuPackages,
    getWizardGroupTabsPackageId,
    (packages, packageId) => packages?.find((pack) => pack.id === packageId)
);

export const getWizardPackageData = createSelector(
    getWizardGroupTabsPackageId,
    getLimitedMenuPackages,
    (packageId, availablePackages) =>
        packageId && availablePackages ? availablePackages.find((pack) => pack.id === packageId) : null
);

export const getWizardPackageVisibleData = createSelector(
    getMenuData,
    getWizardPackageData,
    (menudata, packageDetails) => limitedMenuDetails(menudata, packageDetails)
);
