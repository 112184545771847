import { createSelector } from "reselect";
import { config } from "src/common/config";
import { getEnvUrl } from "src/common/shared";
import { AppState } from "src/features";
import { getCurrentMemberId } from "src/features/accounts/selectors";
import { GroupTabMenuActionState, GroupTabMenuCallToAction, GroupTabStatus, TAB_NAME_MAX_LENGTH } from "../types";
import { getActivePackageId, getLimitedMenuPackages, getMenuData } from "../../menu/selectors";
import {
    disableGroupTabPackageOperation,
    enableGroupTabPackageOperation,
    updateTabLimitOperation,
} from "../operations";
import { getActiveLocationId } from "src/features/order/selectors";
import { compareFn, getTabTypeNameByLocale, limitedMenuDetails } from "./helpers";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";
import { regionHelper } from "../../region";
import { getProfile } from "../../accountmenu/selectors";
import { capitaliseFirstLetter } from "../../../common/formatter";
import { MEANDU_REGION_KEY } from "../../region/middleware/regionMiddleware";
import { ProblemDetailsError, ProblemDetails } from "../../order/orderApi/ProblemDetailError";
import { getInitials } from "../../../common/strings";

export const getGroupTabData = (state: AppState) => state.groupTabs.activeTab.data;
export const getShowInviteModal = (state: AppState) => state.groupTabs.activeTab.showInviteModal;
export const getShowManageModal = (state: AppState) => state.groupTabs.activeTab.showManageModal;
export const getShowCloseConfirmation = (state: AppState) => state.groupTabs.activeTab.showCloseConfirmation;
export const getShowMembersModal = (state: AppState) => state.groupTabs.activeTab.showMembersModal;
export const getShowOrdersModal = (state: AppState) => state.groupTabs.activeTab.showOrdersModal;
export const getOrders = (state: AppState) => state.groupTabs.activeTab.orders;
export const getShowOverviewModal = (state: AppState) => state.groupTabs.activeTab.showOverviewModal;

export const getGroupTabName = createSelector(getGroupTabData, (activeTab) => activeTab?.displayName);
export const getActiveTabLocale = createSelector(getGroupTabData, (activeTab) => activeTab?.locale);

export const getIsGroupTabOpen = createSelector(
    getGroupTabData,
    getCurrentMemberId,
    getActiveLocationId,
    (activeTab, currentMemberId, activeLocation) =>
        activeTab?.status === GroupTabStatus.OPEN &&
        activeTab.members.some((member) => member.memberId === currentMemberId) &&
        (!activeLocation || activeLocation === activeTab.locationId)
);

export const getActiveGroupTabData = createSelector(getGroupTabData, getIsGroupTabOpen, (activeTab, isOpen) =>
    isOpen ? activeTab : undefined
);

export const getIsGroupTabOwner = createSelector(
    getGroupTabData,
    getCurrentMemberId,
    (activeTab, currentMemberId) => activeTab?.owner === currentMemberId
);

export const getGroupTabMembersInitials = createSelector(getGroupTabData, (activeTab) =>
    activeTab?.members.map((member) => getInitials(member.firstName, member.lastName, "?"))
);

export const getGroupTabMembers = createSelector(getGroupTabData, (activeTab) =>
    activeTab?.members.map((member) => ({
        ...member,
        initial: getInitials(member.firstName, member.lastName, "?"),
    }))
);

export const getTabMembersExcludingOwner = createSelector(
    getGroupTabData,
    getGroupTabMembers,
    (activeTab, tabMembers) => {
        const tabOwnerId = activeTab?.owner;
        if (!tabOwnerId) {
            return tabMembers;
        }
        return tabMembers?.filter((member) => member.memberId !== tabOwnerId);
    }
);

export const getActiveTabJoinCode = createSelector(getGroupTabData, (activeTab) => activeTab?.joinCode);

export const getActiveTabLink = createSelector(getGroupTabData, (activeTab) =>
    config.REACT_APP_APP_BASE_URL
        ? `${getEnvUrl(config.REACT_APP_APP_BASE_URL)}/group-tab/${activeTab?.joinCode}`
        : undefined
);

export const getDebits = createSelector(
    getActiveGroupTabData,
    getIsGroupTabOwner,
    getCurrentMemberId,
    (activeGroupTab, isOwner, memberId) =>
        isOwner
            ? activeGroupTab?.debits.sort(compareFn)
            : activeGroupTab?.debits.filter((debit) => debit.memberId === memberId).sort(compareFn)
);

export const getGroupTabLocationName = createSelector(
    getActiveGroupTabData,
    (activeGroupTab) => activeGroupTab?.locationName
);

export const getGroupTabPackageId = createSelector(getActiveGroupTabData, (groupTab) => groupTab?.packageId);

export const getGroupTabId = createSelector(getActiveGroupTabData, (groupTab) => groupTab?.id);

export const getLocale = createSelector(
    getActiveTabLocale,
    getMenuDataLocale,
    (activeTabLocale, menuDataLocale) =>
        activeTabLocale ||
        menuDataLocale?.locale ||
        regionHelper?.region?.id ||
        window.localStorage.getItem(MEANDU_REGION_KEY)
);

export const getTabTypeName = createSelector(getLocale, (locale) => getTabTypeNameByLocale(locale));

export const getTabType = createSelector(getActiveGroupTabData, (activeTab) => activeTab?.type);
export const getTabLimit = createSelector(getActiveGroupTabData, (activeTab) => activeTab?.limit);
export const getActiveTabGratuityFactor = createSelector(
    getActiveGroupTabData,
    (activeTab) => activeTab?.gratuityFactor
);

export const getTabDisplayName = createSelector(getTabTypeName, getProfile, (tabTypeName, profile) => {
    if (!profile?.firstName) return capitaliseFirstLetter(tabTypeName);
    const suffix = `’s ${tabTypeName}`;
    const firstNameMaxLength = TAB_NAME_MAX_LENGTH - suffix.length;
    return `${profile.firstName.substring(0, firstNameMaxLength)}${suffix}`;
});

export const getGroupTabMenuCallToAction = createSelector(
    getActiveGroupTabData,
    getLimitedMenuPackages,
    getIsGroupTabOwner,
    getActivePackageId,
    getTabTypeName,
    (activeGroupTab, packages, isOwner, partyPackage, tabTypeName): GroupTabMenuCallToAction | null => {
        if (!activeGroupTab?.packageId || !packages?.find((pack) => pack.id === activeGroupTab?.packageId)) {
            return null;
        }
        return isOwner
            ? {
                  actionState: GroupTabMenuActionState.OWNER,
                  title: `Your ${tabTypeName} has a limited menu`,
                  callToAction: "View what your guests can order",
              }
            : partyPackage === activeGroupTab.packageId
            ? {
                  actionState: GroupTabMenuActionState.TO_FULL,
                  title: "Order & pay on your own.",
                  callToAction: "Swap to the full menu",
              }
            : {
                  actionState: GroupTabMenuActionState.TO_LIMITED,
                  title: `Order & pay using a ${tabTypeName}.`,
                  callToAction: `Swap to ${tabTypeName} menu`,
              };
    }
);

export const getGroupTabMenuChanging = createSelector(
    enableGroupTabPackageOperation.getStatus,
    disableGroupTabPackageOperation.getStatus,
    (enableGroupTabMenuStatus, disableGroupTabMenuStatus) =>
        enableGroupTabMenuStatus === "processing" || disableGroupTabMenuStatus === "processing"
);

export const getGroupTabPackageData = createSelector(
    getGroupTabPackageId,
    getLimitedMenuPackages,
    (packageId, availablePackages) =>
        packageId && availablePackages ? availablePackages.find((pack) => pack.id === packageId) : null
);

export const getActivePackageVisibleData = createSelector(
    getMenuData,
    getGroupTabPackageData,
    (menudata, packageDetails) => limitedMenuDetails(menudata, packageDetails)
);

export const getShowManageTabPrompt = createSelector(
    getActiveGroupTabData,
    getIsGroupTabOwner,
    (activeTab, isOwner) => !!activeTab && isOwner && !activeTab.hasSeenManagePrompt
);

export const getTabSpend = createSelector(getDebits, (tabDebits = []) =>
    tabDebits.reduce((total, debit) => total + debit.amount, 0)
);

export const getUpdateLimitErrorDetails = createSelector(
    updateTabLimitOperation.getError,
    (error) =>
        (ProblemDetailsError.isProblemDetailsError(error) ? error.problemDetails : undefined) as
            | ProblemDetails
            | undefined
);

export const getActiveTabId = createSelector(getActiveGroupTabData, (activeTab) => activeTab?.id);

export const getActiveTabTipFactor = createSelector(getActiveGroupTabData, (activeTab) => {
    if (!activeTab?.tips?.enabled || activeTab.tips.default === null) return 0;
    return activeTab.tips.levels[activeTab.tips.default];
});
