import { AppDispatch } from "src/features";
import { qr } from "src/common/experience";
import { QrCodeError } from "../../../common/experience/shared/qr";
import { qrActionCreators } from "../../partyOnboarding/reducers";
import { timeoutAndError } from "../../../common/shared";
import { findingBarcodeOperation } from "../operations";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";

export async function findBarcode(dispatch: AppDispatch, cancelPromise: Promise<void>) {
    return findingBarcodeOperation.invoke(async () => {
        try {
            const barcode = await Promise.race([
                qr.findQRCode(BarcodeType.BARCODE, (status) => dispatch(qrActionCreators.setScanStatus(status))),
                timeoutAndError(30000, new QrCodeError("FindQrCodeTimeout")),
                cancelPromise,
            ]);
            return typeof barcode === "string" ? barcode : null;
        } catch (err) {
            if (
                err instanceof QrCodeError &&
                err.errorCode === "QrCodeCameraAccessDenied" &&
                qr.storeCameraAccessDenied
            ) {
                dispatch(qrActionCreators.setCameraAccessDenied());
            }
            throw err;
        } finally {
            await qr.cancelFindQRCode();
        }
    }, dispatch);
}
