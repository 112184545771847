import "../assets/ModalMessage.scss";
import "../../../sharedComponents/controls/sheets/ActionSheet.scss";

import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NativeBackButton, SimpleNavHeader } from "src/common/navigation";
import { Button, Text } from "../../../sharedComponents";
import { ModalActionMessage, ModalInfoMessage, ModalMessage } from "../reducers/modalMessage";
import classNames from "classnames";
import { SpinnerModal } from "src/features/spinnerModal/components/SpinnerModal";
import { LocationThemeContainer } from "../../location/container/LocationThemeContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../index";
import { resetModalMessage } from "../actions/reset";
import { getParty } from "../../order";
import { showModalMessage } from "../actions/show";
import { LegalModal, LegalPageType } from "../../compliance/components/LegalModal";
import { TappableDiv, TappableSpan } from "../../../sharedComponents/common/tappable";
import { StatusBar } from "../../../common/statusBar";
import { ReplaceLinkProps, replaceLinks } from "../../../common/strings";
import { getIsConnected } from "../../order/selectors";
import { CloseIcon } from "../../../sharedComponents/assets/icons";
import { Confetti } from "src/sharedComponents/controls/confetti";
import { ModalContainer } from "src/common/modal";

export const ModalMessageModal = () => {
    const modalMessage = useSelector(({ modalMessage }: AppState) => modalMessage);
    const party = useSelector(getParty);
    const connected = useSelector(getIsConnected);
    const dispatch = useDispatch();

    const [message, setMessage] = useState<ModalMessage | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isChanging, setIsChanging] = useState(false);
    const [legalPageType, setLegalPageType] = useState<LegalPageType | undefined>();
    const [expanded, setExpanded] = useState(false);
    const [expanding, setExpanding] = useState(false);

    const shouldValidate = (modalMessage as ModalActionMessage)?.shouldValidate;
    const showConnectivityIssues = !!(modalMessage as ModalActionMessage)?.showNetworkConnectivityIssues;
    const forceExpanded = (modalMessage as ModalActionMessage)?.isExpanded;

    const isExpanded = forceExpanded || expanded;

    const [isValid, setIsValid] = useState(!shouldValidate);

    useEffect(() => {
        setIsValid(!shouldValidate || shouldValidate.initialValid);
    }, [shouldValidate]);

    const requiresConnectivityToSubmit = useMemo(
        () => showConnectivityIssues && !connected,
        [showConnectivityIssues, connected]
    );

    const prevMessage = useRef<ModalMessage | null>(null);
    const serverMessages = useRef<ModalMessage[]>([]);
    const closeTimeoutMS = useRef(0);
    const modalInnerRef = useRef<HTMLDivElement | null>(null);
    const expandTimer = useRef<number | undefined>();
    const setHeightTimer = useRef<number | undefined>();

    const closeAndAction = useCallback(
        (action?: (() => void) | ModalMessage) => {
            if ((action && typeof action !== "function") || serverMessages.current.length || prevMessage.current) {
                setIsChanging(true);
            } else {
                setIsOpen(false);
            }

            setTimeout(() => {
                typeof action === "function" && action();

                let nextMessage: ModalMessage | undefined;

                if (action && typeof action !== "function") {
                    nextMessage = action;
                } else if (serverMessages.current.length) {
                    nextMessage = serverMessages.current.pop();
                } else if (prevMessage.current) {
                    nextMessage = prevMessage.current;
                    prevMessage.current = null;
                }

                if (nextMessage) {
                    dispatch(
                        showModalMessage({
                            ...nextMessage,
                            internal: true,
                        })
                    );
                } else {
                    dispatch(resetModalMessage());
                }
            }, closeTimeoutMS.current);
        },
        [dispatch]
    );

    const LinkComponent = useCallback(({ link, children }: PropsWithChildren<ReplaceLinkProps>) => {
        return (
            <TappableSpan className="modal-message__text-link" onTap={() => setLegalPageType(link as LegalPageType)}>
                {children}
            </TappableSpan>
        );
    }, []);

    const renderInfo = useCallback(
        (message: ModalInfoMessage) => {
            const {
                icon,
                title,
                text,
                leftAlign,
                importantText,
                primaryActionText,
                primaryAction,
                secondaryActionText,
                secondaryAction,
            } = message;

            const showSecondary = !!secondaryActionText || !!secondaryAction;
            const textClassName = classNames(
                "modal-message__text",
                leftAlign && "modal-message__text--left-align",
                typeof text === "string" && "modal-message__text-string"
            );

            return (
                <>
                    <div className="modal-message__content">
                        {icon && <div className="modal-message__icon">{icon}</div>}
                        {title && (
                            <Text preset="g-18" mode="bold" className="modal-message__title">
                                {title}
                            </Text>
                        )}
                        {text && (
                            <Text preset="g-14" className={textClassName}>
                                {replaceLinks(text, LinkComponent)}
                            </Text>
                        )}
                        {importantText && (
                            <Text preset="g-14" mode="bold" className="modal-message__important-text">
                                {importantText}
                            </Text>
                        )}
                    </div>
                    <div
                        className={classNames(
                            "modal-message__actions",
                            "modal-message__actions__info",
                            showSecondary && "modal-message__actions--with-secondary"
                        )}
                    >
                        <TappableDiv
                            className="modal-message__actions__info-action"
                            onClick={() => closeAndAction(primaryAction)}
                        >
                            <Text preset="g-16" mode="bold">
                                {primaryActionText ?? "OK"}
                            </Text>
                        </TappableDiv>

                        {showSecondary && (
                            <TappableDiv
                                className="modal-message__actions__info-action"
                                onClick={() => closeAndAction(secondaryAction)}
                            >
                                <Text preset="g-16" mode="bold">
                                    {secondaryActionText ?? "OK"}
                                </Text>
                            </TappableDiv>
                        )}
                    </div>
                </>
            );
        },
        [closeAndAction, LinkComponent]
    );

    const expand = useCallback(() => {
        setExpanding(true);
        expandTimer.current = window.setTimeout(() => setExpanded(true), 250 + 100);
    }, []);

    const renderAction = useCallback(
        (message: ModalActionMessage) => {
            const {
                icon,
                title,
                titlePreset,
                text,
                expandedText,
                additionalExpandedContent,
                primaryAction,
                primaryActionText,
                primaryActionIcon,
                actionsReversed,
                secondaryAction,
                secondaryActionText,
                secondaryActionIcon,
                secondaryActionExpands,
                showCancel,
                primaryComponent,
                expandedOptions,
                additionalExpandedComponent,
                centerAlignContent,
                showInlineClose,
                primaryActionAdditionalProperties,
                primaryButtonDisabled,
                confetti,
                primaryButton,
                asActionSheet,
                unthemed,
                onClose,
            } = message;

            const textClassName = classNames(
                "modal-message__text",
                typeof text === "string" && "modal-message__text-string"
            );
            const showingNavHeader = expandedOptions?.showClose && isExpanded;
            const isPrimaryButtonDisabled = requiresConnectivityToSubmit || !isValid || primaryButtonDisabled;
            const canShowInlineClose = !showingNavHeader && showInlineClose;
            const contentClassName = classNames(
                asActionSheet
                    ? "action-sheet__content action-sheet__content-heading action-sheet__content-scrollable"
                    : "modal-message__content",
                showingNavHeader && "with-nav-header",
                centerAlignContent && "modal-message__content--center-align",
                canShowInlineClose && "with-inline-close"
            );

            const showSecondaryActionFirst =
                actionsReversed && (secondaryAction || secondaryActionText) && !secondaryActionExpands;
            const showExpandAction = secondaryActionExpands && !isExpanded;

            return (
                <>
                    {showingNavHeader && (
                        <SimpleNavHeader
                            closeToBack={true}
                            onBack={() => closeAndAction(onClose)}
                            customBack={expandedOptions?.customBack}
                            withBorder={true}
                        />
                    )}
                    <Confetti fire={confetti} full y={0.7} startVelocity={50} />
                    <div className={contentClassName}>
                        {canShowInlineClose && (
                            <div className="modal-message__close">
                                <TappableSpan
                                    className="modal-message__close__wrapper"
                                    onTap={() => closeAndAction(onClose)}
                                >
                                    <CloseIcon />
                                </TappableSpan>
                            </div>
                        )}
                        {icon && <div className="modal-message__icon">{icon}</div>}
                        {(expandedOptions?.hideTitle && isExpanded) || !title ? null : asActionSheet ? (
                            <div className="action-sheet__content--title">
                                <Text preset={"g-16"} mode="bold">
                                    {title}
                                </Text>
                            </div>
                        ) : (
                            <Text preset={titlePreset ?? "title-28"} mode="extra-bold" className="modal-message__title">
                                {title}
                            </Text>
                        )}
                        {!isExpanded && (text || primaryComponent) && (
                            <div
                                className={
                                    asActionSheet ? "action-sheet__content--message" : "modal-message__content--regular"
                                }
                            >
                                {text && (
                                    <Text preset="g-14" className={textClassName}>
                                        {replaceLinks(text, LinkComponent)}
                                    </Text>
                                )}
                                {primaryComponent && primaryComponent({ validate: setIsValid, expand, isExpanded })}
                            </div>
                        )}
                        {isExpanded && (
                            <div className="modal-message__content--expanded">
                                {primaryComponent && primaryComponent({ validate: setIsValid, expand, isExpanded })}
                                <Text preset="g-14" className={textClassName}>
                                    {replaceLinks(expandedText, LinkComponent)}
                                </Text>
                                {additionalExpandedContent}
                                {additionalExpandedComponent && additionalExpandedComponent({ validate: setIsValid })}
                            </div>
                        )}
                    </div>
                    <div className={asActionSheet ? "action-sheet__actions" : "modal-message__actions"}>
                        {showSecondaryActionFirst && (
                            <Button
                                leftIcon={secondaryActionIcon}
                                mode="outline"
                                onClick={() => closeAndAction(secondaryAction)}
                                value={secondaryActionText ?? "Cancel"}
                                unthemed={unthemed}
                            />
                        )}
                        {showExpandAction && (
                            <Button
                                leftIcon={secondaryActionIcon}
                                mode="outline"
                                className="modal-message__expand"
                                onClick={expand}
                                value={secondaryActionText ?? "Show more"}
                                unthemed={unthemed}
                            />
                        )}
                        {primaryButton ? (
                            primaryButton(closeAndAction)
                        ) : (
                            <Button
                                leftIcon={primaryActionIcon}
                                mode="solid"
                                onClick={() => closeAndAction(primaryAction)}
                                value={primaryActionText ?? "OK"}
                                disabled={isPrimaryButtonDisabled}
                                {...primaryActionAdditionalProperties}
                                secondary={asActionSheet && (showSecondaryActionFirst || showExpandAction)}
                            />
                        )}
                        {!actionsReversed && (secondaryAction || secondaryActionText) && !secondaryActionExpands && (
                            <Button
                                leftIcon={secondaryActionIcon}
                                mode={showCancel ? "outline" : "blank"}
                                onClick={() => closeAndAction(secondaryAction)}
                                value={secondaryActionText ?? "Cancel"}
                                secondary={asActionSheet}
                                unthemed={unthemed}
                            />
                        )}
                        {showCancel && (
                            <Button
                                mode="blank"
                                onClick={() => closeAndAction()}
                                value="Cancel"
                                secondary={asActionSheet}
                                unthemed={unthemed}
                            />
                        )}
                    </div>
                </>
            );
        },
        [closeAndAction, isExpanded, expand, LinkComponent, isValid, requiresConnectivityToSubmit]
    );

    const renderModalMessage = useCallback(
        (message: ModalMessage) => {
            switch (message.type) {
                case "info":
                    return message.useLocationTheme ? (
                        <LocationThemeContainer>{renderInfo(message)}</LocationThemeContainer>
                    ) : (
                        renderInfo(message)
                    );
                case "action":
                    return message.useLocationTheme ? (
                        <LocationThemeContainer>{renderAction(message)}</LocationThemeContainer>
                    ) : (
                        renderAction(message)
                    );
                case "progress":
                    return <SpinnerModal isLoading={true} />;
            }
        },
        [renderAction, renderInfo]
    );

    const setMessageAndTimeout = useCallback((message: ModalMessage) => {
        clearTimeout(expandTimer.current);
        setExpanding(false);
        setExpanded(false);

        closeTimeoutMS.current = message.type === "action" ? 250 : 150;
        setMessage(message);
    }, []);

    useEffect(() => {
        if (!message && modalMessage) {
            setMessageAndTimeout(modalMessage);
            setIsOpen(true);
        } else if (!modalMessage && message) {
            setMessage(null);
        } else if (modalMessage && message && message !== modalMessage) {
            if (modalMessage.internal) {
                setMessageAndTimeout(modalMessage);
                setIsChanging(false);
            } else {
                setIsChanging(true);
                setTimeout(() => {
                    if (modalMessage.type !== "progress" && modalMessage.fromServer) {
                        if (message.type !== "progress" && message.fromServer) {
                            // If we got another server message then put it in the queue
                            serverMessages.current.unshift(modalMessage);
                        } else {
                            // Keep our current app message to restore after this server message is dealt with
                            prevMessage.current = message;
                        }
                    }
                    setMessageAndTimeout(modalMessage);
                    setIsChanging(false);
                }, closeTimeoutMS.current);
            }
        }
    }, [modalMessage, message, setMessageAndTimeout]);

    useEffect(() => {
        if (!party) {
            serverMessages.current.length = 0;
            prevMessage.current = null;
        }
    }, [party]);

    const setHeight = useCallback(() => {
        setHeightTimer.current = window.setTimeout(() => {
            modalInnerRef.current?.style.setProperty("--modal-height", `${modalInnerRef.current.clientHeight}px`);
        }, closeTimeoutMS.current);
    }, []);

    const clearHeight = useCallback(() => {
        clearTimeout(setHeightTimer.current);
        modalInnerRef.current?.style.removeProperty("--modal-height");
    }, []);

    useEffect(() => {
        isChanging ? clearHeight() : setHeight();
    }, [isChanging, setHeight, clearHeight]);

    useEffect(() => {
        if (isExpanded) {
            setHeight();
        }
    }, [isExpanded, setHeight]);

    useEffect(() => {
        return () => {
            clearTimeout(setHeightTimer.current);
            clearTimeout(expandTimer.current);
        };
    }, []);

    const isClosable =
        (message?.type === "info" && !message.mustAction) ||
        (message?.type === "action" &&
            (message.forceClosable ||
                (!message.secondaryAction &&
                    !message.secondaryActionText &&
                    !message.showCancel &&
                    !message.mustAction)));

    const closeModal = () => closeAndAction();
    const hasCurvedBorders = message?.type === "action" && message?.curvedBorders;

    return (
        <ModalContainer
            isOpen={!!message && isOpen}
            className={{
                base: classNames(
                    "modal-message__modal",
                    (expanding || isExpanded) && "modal-message__modal--expanding"
                ),
                afterOpen: "modal-message__modal--after-open",
                beforeClose: "modal-messag__modal--before-close",
            }}
            overlayClassName={classNames(
                "ReactModal__ModalMessage",
                `ModalMessage-${message?.type}`,
                isChanging && "ReactModal__Overlay--before-close",
                message && message.type !== "progress" && message.fromServer && "ModalMessage-important"
            )}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={isClosable}
            shouldCloseOnEsc={isClosable}
            bodyOpenClassName="modal-message__Body"
            closeTimeoutMS={closeTimeoutMS.current}
            onAfterOpen={setHeight}
            onAfterClose={clearHeight}
        >
            {message && (
                <>
                    <NativeBackButton
                        name={`#msg-box/[${message.title}]`}
                        preventBack={!isClosable}
                        onPressed={message.type === "progress" ? null : closeModal}
                    />
                    {isExpanded && <StatusBar backgroundColor="#fff" />}
                    <div
                        ref={modalInnerRef}
                        className={classNames(
                            "modal-message__inner",
                            message.type === "action" && message.asActionSheet
                                ? "action-sheet__container"
                                : `modal-message__${message.type}`,
                            hasCurvedBorders && "modal-message__curved-borders"
                        )}
                    >
                        {renderModalMessage(message)}
                    </div>
                    {message.showsLegalPage && (
                        <LegalModal legalPage={legalPageType} goBack={() => setLegalPageType(undefined)} />
                    )}
                </>
            )}
        </ModalContainer>
    );
};
