import { deleteCookie, getCookie, setCookie } from "../../cookies";
import { regionHelper } from "../../../features/region";
import { CookiePreferences } from "../interface";

export const gdpr = {
    canSetCookiePreferences: () => regionHelper.region.isGdpr,
    shouldShowCookiePreferences: () => {
        if (regionHelper.region.isGdpr) {
            return !getCookie(`meandu_gdpr_${regionHelper.region.id}`);
        } else {
            deleteCookie(`meandu_gdpr_${regionHelper.region.id}`);
            return false;
        }
    },
    setCookiePreferences: ({ allowAnalytics, allowAdvertising }: CookiePreferences) => {
        const value = `${allowAnalytics ? "ai_ok" : "no_ai"}|${allowAdvertising ? "ad_ok" : "no_ad"}`;
        setCookie(`meandu_gdpr_${regionHelper.region.id}`, value);
    },
    getCookiePreferences: () => {
        const cookie = getCookie(`meandu_gdpr_${regionHelper.region.id}`);
        const [allowAnalytics, allowAdvertising] = cookie?.split("|") ?? [];
        return {
            allowAnalytics: allowAnalytics !== "no_ai",
            allowAdvertising: allowAdvertising !== "no_ad",
        };
    },
};
