import { getAuthHeaders } from "../../../common/auth";
import { orderApi } from "../../order/orderApi";

export const verifyParty = async (tableToken: string) => {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("X-Meandu-Party-Token", tableToken);

    try {
        const response = await orderApi.send("/party/verify", {
            method: "GET",
            credentials: "include",
            headers,
        });

        const json = await response.json();

        if (response.ok && !json.errorCode) {
            return json;
        }

        throw json;
    } catch (err) {
        throw new Error((err || {}).errorCode);
    }
};
