import { IdentitySource, SsoProvider } from "./types";

export const getIdentitySource = (ssoProvider: SsoProvider) => {
    switch (ssoProvider) {
        case "Google":
            return IdentitySource.Google;
        case "Apple":
            return IdentitySource.Apple;
        case "Facebook":
            return IdentitySource.Facebook;
    }
};

export const getSsoProvider = (source: IdentitySource): SsoProvider | null => {
    switch (source) {
        case IdentitySource.Google:
            return "Google";
        case IdentitySource.Apple:
            return "Apple";
        case IdentitySource.Facebook:
            return "Facebook";
        default:
            return null;
    }
};
