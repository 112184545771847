import { orderApi } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { ActiveGroupTabData } from "..";
import { getAuthHeaders } from "../../../common/auth";

export const closeGroupTabApi = async (tipAmount?: number) => {
    const headers = await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/grouptabs/close", {
        method: "POST",
        body: JSON.stringify({ tipAmount }),
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ActiveGroupTabData;
};
