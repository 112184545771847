import "../assets/TakeawayScheduleButton.scss";
import React, { useContext, useMemo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Button, Text } from "src/sharedComponents";
import { getOrderDateTimeScheduled, getOrderReadyTime } from "src/features/order/selectors";
import { getTakeawayASAPServiceId, getIsTakeawaySchedulingEnabled } from "../selectors/takeawayOptions";
import { showSchedulePage } from "../actions/showSchedulePage";
import { sendScheduleTime } from "../actions/sendScheduledTime";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";

export const TakeawayScheduleButton = () => {
    const earliestReadyTime = useSelector(getOrderReadyTime);
    const schedulingEnabled = useSelector(getIsTakeawaySchedulingEnabled);
    const scheduledTime = useSelector(getOrderDateTimeScheduled);
    const takeawaySlotASAPServiceId = useSelector(getTakeawayASAPServiceId);
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const dispatch = useDispatch();

    const scheduledSubtitle = useMemo(() => {
        if (!schedulingEnabled) return "Unavailable";
        if (scheduledTime) return `Scheduled for ${menuDataLocale.getDateTimeShort(scheduledTime)}`;
        return "Schedule a time";
    }, [schedulingEnabled, scheduledTime, menuDataLocale]);

    const buttonOptions = [
        {
            title: "ASAP",
            subtitle: `${!scheduledTime ? "Ready in " : ""}${earliestReadyTime} mins`,
            active: !scheduledTime,
            onClick: () => dispatch(sendScheduleTime(null, takeawaySlotASAPServiceId)),
        },
        {
            title: "Later",
            subtitle: scheduledSubtitle,
            active: scheduledTime,
            disabled: !schedulingEnabled,
            onClick: () => dispatch(showSchedulePage()),
        },
    ];

    return (
        <div className="takeaway-schedule__container">
            {buttonOptions.map((button) => (
                <Button
                    key={`${button.title}-schedule-button`}
                    className={classNames(
                        "takeaway-schedule__button",
                        !button.active && !button.disabled && "inactive"
                    )}
                    disabled={button.disabled}
                    mode={button.active || button.disabled ? "solid" : "blank"}
                    onClick={button.onClick}
                    flexDirection="column"
                >
                    <Text preset="g-16" mode="bold">
                        {button.title}
                    </Text>
                    <Text preset="g-12">{button.subtitle}</Text>
                </Button>
            ))}
        </div>
    );
};
