import { HandledError } from "../../../common/error";

export enum KnownPromotionErrors {
    InvalidPromotion = "InvalidPromotion",
}

export class InvalidPromotionError extends HandledError {
    public reason?: string;

    constructor(reason?: string) {
        super(reason);
        this.reason = reason;
    }
}

export function invalidPromotionErrorHandler(details?: string) {
    return new InvalidPromotionError(details);
}
