import * as React from "react";
import "../assets/LicensePage.scss";
import { SimpleNavHeader } from "src/common/navigation";
import { Spinner } from "src/features/Spinner";
import ReactMarkdown from "react-markdown";
import { href } from "src/common/experience";

const licensesData: LicensesData[] = require("../assets/licenses.json");

interface LicensesData {
    name: string;
    key: string;
    description: string;
    licenses: string[] | string;
    copyright: string;
    licenseText: string;
    repository: string;
}

interface LicenseProps {
    data: LicensesData;
}

const getLicenseTitle = ({ name, repository }: LicensesData) => {
    if (!repository) {
        return <span className="license--title">{name}</span>;
    }

    const attributes = href.openInNewWindowAttributes(repository);

    return (
        <a className="license--title" {...attributes}>
            {name}
        </a>
    );
};

const LicenseLine = ({ data }: LicenseProps) => (
    <>
        {getLicenseTitle(data)}
        <div className="license--content">
            <ReactMarkdown source={data.copyright ? data.licenseText : data.licenses.toString()} />
        </div>
    </>
);

interface Props {}

interface State {
    isLoading: boolean;
}

export class LicensePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        //wait for the page transition to complete
        window.setTimeout(() => this.setState({ isLoading: false }), 370);
    }

    render() {
        return (
            <div className="flex bg-white license">
                <span className="license--header">
                    <SimpleNavHeader title={"Software Licenses"} withBorder customBack={"software-licenses"} />
                </span>
                {this.state.isLoading ? (
                    <Spinner />
                ) : (
                    <div className="license--container">
                        {licensesData.map((data) => (
                            <LicenseLine key={data.key} data={data} />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
