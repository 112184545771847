import classNames from "classnames";
import React, { ReactNode } from "react";
import { Text } from "src/sharedComponents";
import "./assets/HeaderTabs.scss";

export interface HeaderTabsProps {
    brandColor?: boolean;
    headerTabs: HeaderTab[];
    activeIndex: number;
    onTabClick: (index: number) => void;
}

export interface HeaderTab {
    icon?: ReactNode;
    name: string;
}

export const HeaderTabs = ({ headerTabs, brandColor, activeIndex, onTabClick }: HeaderTabsProps) => (
    <div className={classNames("header-tabs", brandColor && "header-tabs--brand-color")}>
        {headerTabs.map((tab, index) => (
            <div
                key={`header-tab-${index}-${activeIndex}`}
                className={classNames("header-tabs__header", index === activeIndex && "header-tabs__header--active")}
                onClick={() => onTabClick(index)}
            >
                {tab.icon}
                <Text preset="g-16" mode="bold" className="header-tabs__header__title" value={tab.name} />
                <div className={classNames("header-tabs__indicator", `header-tabs__indicator--${index}`)} />
            </div>
        ))}
    </div>
);
