import * as React from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../sharedComponents";
import { PaymentButtonProps } from "../types";

interface Props extends PaymentButtonProps {
    value: string;
}

export const GroupTabPaymentButton = ({ onPayment, value }: Props) => {
    const dispatch = useDispatch();
    return <Button mode="solid" onClick={() => dispatch(onPayment())} value={value} />;
};
