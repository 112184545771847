import "./CreateTabPage.scss";

import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Text } from "../../../sharedComponents";
import { TabDisclaimer } from "./TabDisclaimer";
import { TabGratuityDescription } from "./TabGratuityDescription";
import { InlineVaultedPaymentMethods } from "../../paymentMethods/components/InlineVaultedPaymentMethods";
import { actionCreators as paymentActionCreators, paymentFlowActions } from "../../payment";
import {
    SelectablePaymentMethod,
    isCardPaymentMethod,
    isDisplayedForVaultedPaymentMethodSelection,
} from "../../../common/payment";
import { getTabDisplayName } from "../selectors/activeGroupTab";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { TabDefaultTipDisclaimer } from "./TabDefaultTipDisclaimer";
import { getGroupTabAction } from "../actions/getGroupTabAction";

interface Props {
    onSuccess: () => void;
}

export const CreateTabPage = ({ onSuccess }: Props) => {
    const dispatch = useDispatch();
    const displayName = useSelector(getTabDisplayName);
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);

    const hasTriggeredCreateTabCardDetailsEvent = useRef(false);

    const onOpenTabButtonClick = useCallback(
        (paymentMethod: SelectablePaymentMethod) => {
            dispatch(paymentFlowActions.trackPaymentFlowOpenTabClicked());

            const openTabPaymentMethod: SelectablePaymentMethod = {
                ...paymentMethod,
                displayName,
            };

            onSuccess();

            dispatch(paymentActionCreators.setOpenTabPaymentMethod(openTabPaymentMethod));
        },
        [dispatch, onSuccess, displayName]
    );

    useEffect(() => {
        if (!hasTriggeredCreateTabCardDetailsEvent.current) {
            const hasVaultedCard = !!selectedPaymentMethod && isCardPaymentMethod(selectedPaymentMethod);
            dispatch(paymentFlowActions.trackPaymentFlowCreateTabCardDetailsViewed(hasVaultedCard));
            hasTriggeredCreateTabCardDetailsEvent.current = true;
        }
    }, [dispatch, selectedPaymentMethod]);

    useEffect(() => {
        dispatch(
            getGroupTabAction(() => {
                dispatch(paymentFlowActions.prevPaymentPage({ page: "SelectPaymentMethod", isOpeningTab: false }));
            })
        );
    }, [dispatch]);

    return (
        <div className="create-tab-page animated-child scroll-element">
            <div className="create-tab-page__header">
                <Text preset="title-28" mode={["bold", "block"]} value="Create tab" />
                <TabGratuityDescription className="create-tab-page__header__subheading" />
                <TabDefaultTipDisclaimer className="create-tab-page__header__subheading" />
            </div>
            <Text
                preset="g-18"
                mode={["bold", "block"]}
                value="Choose payment method"
                className="create-tab-page__payment-title"
            />
            <InlineVaultedPaymentMethods
                buttonText="Open tab"
                onSuccess={onOpenTabButtonClick}
                filter={isDisplayedForVaultedPaymentMethodSelection}
            >
                <Divider fullWidth={true} extraHeight={true} />
                <TabDisclaimer className="create-tab-page__disclaimer" />
            </InlineVaultedPaymentMethods>
        </div>
    );
};
