import { DevicePaymentBehavior } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod } from "src/common/payment";
import { getStripeApplePayAvailable } from "./getStripeApplePayAvailable";
import { initializeStripeApplePayButton } from "./initializeStripeApplePayButton";
import { completeStripeApplePayPayment } from "./completeStripeApplePayPayment";
import { getStripeGooglePayAvailable } from "./getStripeGooglePayAvailable";
import { initializeStripeGooglePayButton } from "./initializeStripeGooglePayButton";

export const applePayBehavior: DevicePaymentBehavior<ApplePayPaymentMethod> = {
    getAvailable: getStripeApplePayAvailable,
    initializeButton: initializeStripeApplePayButton,
    completePayment: completeStripeApplePayPayment,
};

export const googlePayBehavior: DevicePaymentBehavior<GooglePayPaymentMethod> = {
    getAvailable: getStripeGooglePayAvailable,
    initializeButton: initializeStripeGooglePayButton,
};
