import { SelectablePaymentMethod } from "../../common/payment";

export enum HostedField {
    Number = "payment-method__add-card__field-number",
    Expiry = "payment-method__add-card__field-expiry",
    ExpiryMonth = "payment-method__add-card__field-expiry-month",
    ExpiryYear = "payment-method__add-card__field-expiry-year",
    CVV = "payment-method__add-card__field-cvv",
}

export type HostedFieldFlags = { [field: string]: boolean | undefined };

export const hostedFieldOptions = {
    color: "#333333",
    placeholderColor: "#999999",
    errorColor: "#d94029",
    fontFamily: "'Manrope', sans-serif",
    fontWeight: "400",
    fontSize: "16px",
    placeholders: {
        cardNumber: "0000 0000 0000 0000",
        expiry: "MM / YY",
        expiryMonth: "MM",
        expiryYear: "YY",
        cvv: "123",
        cvvAmex: "1234",
    },
    initializeTimeout: 40000,
};

export interface SelectPaymentMethodActions {
    setGetPaymentMethod: (getPaymentMethod: (() => Promise<SelectablePaymentMethod | null>) | null) => void;
    setIsValid: (isValid: boolean) => void;
    parentOnClick: () => void;
}
