import { orderApi } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "src/features/partyOnboarding/persistence/tableToken";
import { ActiveGroupTabData } from "..";

export const setHasSeenManageTabPromptApi = async () => {
    const headers = getTableTokenHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send("/grouptabs/set-has-seen-manage-prompt", {
        method: "POST",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ActiveGroupTabData;
};
