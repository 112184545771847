import "../assets/MarketingModal.scss";

import React from "react";
import classNames from "classnames";
import { StatusBar } from "src/common/statusBar";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { PrivacyPolicy } from "src/features/compliance/components/PrivacyPolicy";
import { TermsUpdate } from "../reducers/types";
import { TermsOfUse } from "../../compliance/components/TermsOfUse";
import { MeanduIcon } from "src/sharedComponents/assets/icons";
import { Button, Text } from "src/sharedComponents";
import { ModalContainer } from "src/common/modal";

export interface Props {
    termsUpdate: TermsUpdate | null;
    accept: () => void;
    onPrivacyPolicyClicked: () => void;
}

interface State {
    openPrivacy: boolean;
    openTerms: boolean;
    accepting: boolean;
}

export class AcceptTermsUpdateModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openPrivacy: false,
            openTerms: false,
            accepting: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { termsUpdate } = this.props;

        if (prevProps.termsUpdate && !termsUpdate) {
            this.setState({ accepting: false });
        }
    }

    onAccept = () => this.setState({ accepting: true }, this.props.accept);

    render() {
        const { termsUpdate, onPrivacyPolicyClicked } = this.props;
        const { openPrivacy, openTerms, accepting } = this.state;

        return (
            <ModalContainer
                isOpen={!!termsUpdate && !accepting}
                className={{
                    base: "marketing slide-in",
                    afterOpen: "marketing-modal--after-open slide-in--after-open",
                    beforeClose: "marketing-modal--before-close slide-in--before-close",
                }}
                overlayClassName={classNames("slide-in-modal--overlay", "marketing-modal--overlay")}
                contentLabel="Accept Terms Update Modal"
                closeTimeoutMS={250}
            >
                <StatusBar backgroundColor={"#fff"} />
                <div className="marketing--content">
                    <div className="marketing--content--logo">
                        <MeanduIcon />
                    </div>
                    <Text preset="title-20" mode="bold" className="marketing--content--title terms">
                        We’ve made some updates to our Terms &amp; Privacy Policy
                    </Text>
                    <Text preset="g-14" className="marketing--content--subtitle">
                        Please take a moment to review some changes to our Terms &amp; Privacy Policy. Let us know you
                        agree to them to continue using me&amp;u.
                    </Text>
                    <Text preset="g-14" className="marketing--content--subtitle">
                        You can view the updated Terms &amp; Conditions{" "}
                        <TappableSpan
                            className={"marketing--footer--privacy--link"}
                            onTap={() => this.setState({ openTerms: true })}
                        >
                            here{" "}
                        </TappableSpan>
                        and the Privacy Policy{" "}
                        <TappableSpan
                            className={"marketing--footer--privacy--link"}
                            onTap={() => {
                                this.setState({ openPrivacy: true });
                                onPrivacyPolicyClicked();
                            }}
                        >
                            here
                        </TappableSpan>
                        .
                    </Text>
                    <Text preset="g-18" mode="bold" className="marketing--content--title terms small">
                        What we’ve changed
                    </Text>
                    <ul>
                        {termsUpdate?.changes.map((change) => (
                            <Text className="marketing--content--subtitle" preset="g-14" key={change}>
                                <li>{change}</li>
                            </Text>
                        ))}
                    </ul>
                </div>
                <div className="marketing--footer">
                    <div className="marketing--footer--actions">
                        <Button onClick={this.onAccept}>I agree</Button>
                    </div>
                </div>
                <ModalContainer
                    isOpen={openPrivacy}
                    className={{
                        base: "privacy-modal",
                        afterOpen: "privacy-modal--after-open",
                        beforeClose: "privacy-modal--before-close",
                    }}
                    overlayClassName="privacy-modal--overlay"
                    contentLabel="Privacy Policy Modal"
                    closeTimeoutMS={360}
                >
                    <PrivacyPolicy goBack={() => this.setState({ openPrivacy: false })} />
                </ModalContainer>
                <ModalContainer
                    isOpen={openTerms}
                    className={{
                        base: "privacy-modal",
                        afterOpen: "privacy-modal--after-open",
                        beforeClose: "privacy-modal--before-close",
                    }}
                    overlayClassName="privacy-modal--overlay"
                    contentLabel="Terms of Use Modal"
                    closeTimeoutMS={360}
                >
                    <TermsOfUse goBack={() => this.setState({ openTerms: false })} />
                </ModalContainer>
            </ModalContainer>
        );
    }
}
