import { FeatureConfiguration } from "..";
import { payOnlyFlagsReducer, payOnlyGetReceiptReducer, reducer } from "./reducers";
import {
    KnownPayOnlyErrors,
    payOnlyOverpaymentErrorHandler,
    payOnlyPartyClosedBeforePaymentErrorHandler,
    payOnlyRemainingBalanceTooLowErrorHandler,
} from "./errors";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.payOnly = reducer;
    config.reducers.payOnlyGetReceipt = payOnlyGetReceiptReducer;
    config.reducers.payOnlyFlags = payOnlyFlagsReducer;
    config.errorHandlers.push({
        code: KnownPayOnlyErrors.Overpayment,
        handler: payOnlyOverpaymentErrorHandler,
    });
    config.errorHandlers.push({
        code: KnownPayOnlyErrors.RemainingBalanceTooLow,
        handler: payOnlyRemainingBalanceTooLowErrorHandler,
    });
    config.errorHandlers.push({
        code: KnownPayOnlyErrors.PartyClosedBeforePayment,
        handler: payOnlyPartyClosedBeforePaymentErrorHandler,
    });
}
