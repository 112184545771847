import { createSelector } from "reselect";
import { AppState } from "src/features";
import { getProfile } from "src/features/accountmenu/selectors";
import { getHistoryDetail } from "src/features/orderHistoryData/selectors";
import { OptInData } from "../reducers/types";

export const getMarketingData = (state: AppState) => state.marketing;

export const getShowMarketingPrompt = (state: AppState) => state.marketing.enabled && state.marketing.prompt;

export const getMarketingOptInData = createSelector(getHistoryDetail, getProfile, (historyDetailData, profile) => {
    if (historyDetailData?.summary) {
        const { locationId, locationName, locationImage, locationLogo } = historyDetailData.summary;

        return {
            locationId,
            partyId: historyDetailData.partyId,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            locationImage,
            locationLogo,
            locationName,
        } as OptInData;
    }
    return null;
});

export const getMarketingPrivacyPolicyUrl = createSelector(getMarketingData, (marketing) =>
    marketing.enabled ? marketing.privacyPolicyUrl : undefined
);
