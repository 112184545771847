import { AppState, AppMiddleware, AppDispatch } from "../..";

export type OrderApiProxy = (state: () => AppState, next: AppDispatch) => (action: any) => void;

export const orderApiMiddleware = (proxies: OrderApiProxy[]) => {
    return (store: AppMiddleware) => (next: AppDispatch) => {
        const handlers = proxies.map((proxy) => proxy(store.getState, store.dispatch));

        return (action: any) => {
            for (const handler of handlers) {
                handler(action);
            }

            next(action);
        };
    };
};
