import { OperationStatus, State } from "./reducer";
import { AnyAction } from "redux";
import { normalizeError } from "src/common/error";
import { AppDispatch } from "../index";
import { Operation } from "./types";

export function getOperationStatus(state: State, operation: string): OperationStatus {
    const operationState = state.operations[operation];

    if (!operationState) {
        return "initial";
    }

    return operationState.status;
}

export function getOperationError(state: State, operation: string): any {
    const operationState = state.operations[operation];

    return operationState && operationState.status === "failed" ? operationState.error : undefined;
}

export async function fromPromise<T, S extends State, A extends AnyAction>(
    operation: Operation<T>,
    promise: (dispatch: AppDispatch, getState: () => S) => Promise<T>,
    dispatch: AppDispatch,
    getState?: () => S,
    completeActionCreator?: (result: T) => AnyAction
): Promise<T | undefined> {
    if (!operation.options.allowParallel) {
        if (!getState) {
            throw new Error(`getState must be provided when allowParallel === false`);
        }

        if (operation.getStatus(getState()) === "processing") {
            return;
        }
    }

    dispatch(operation.actionCreators.begin());

    try {
        const result = await promise(dispatch, getState!);

        dispatch(operation.actionCreators.completed(result));

        if (completeActionCreator) {
            dispatch(completeActionCreator(result));
        }

        return result;
    } catch (e) {
        dispatch(operation.actionCreators.failed(normalizeError(e)));

        return undefined;
    }
}
