import React, { FC } from "react";
import { Text, Toast } from "../../";
import { BarcodeType } from "../../common/shared/interfaces";
import { QrScanner } from "../../assets/icons";
import "./BarcodeScanView.scss";

interface Props {
    title: string;
    description: string;
    barcodeType: BarcodeType;
    scanError?: boolean;
}

export const BarcodeScanView: FC<Props> = ({ title, description, barcodeType, scanError }) => {
    return (
        <div className="barcode-scan-view">
            <Toast text="Oops, couldn’t scan code. Try again!" show={!!scanError} autoDismiss={false} type="warning" />
            <div className="barcode-scan-view__content">
                <Text preset="title-28" mode="extra-bold" value={title} />
                <Text preset="g-14" mode="bold" value={description} />
                {barcodeType === BarcodeType.QRCODE ? <QrScanner /> : <div className="barcode-scan-view__focus-area" />}
            </div>
        </div>
    );
};
