import { updateTabLimitOperation } from "../operations";
import { AppDispatch, AppState } from "../..";
import { getWizardGroupTabsLimit } from "../selectors/groupTabWizardSelectors";
import { openOrUpdateGroupTab } from "./createGroupTabAction";
import { actionCreators as wizardActionCreators } from "../reducers/groupTabWizard";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { GroupTabErrorType } from "..";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getTabTypeName } from "../selectors/activeGroupTab";
import { HandledError } from "../../../common/error";

export const updateTabLimitAction = () => {
    return updateTabLimitOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const tabLimit = getWizardGroupTabsLimit(getState()) || 0;
        const tabTypeName = getTabTypeName(state);

        try {
            const groupTab = await openOrUpdateGroupTab(dispatch, getState, { limit: tabLimit });
            dispatch(activeTabActionCreators.setGroupTabData(groupTab));
            dispatch(wizardActionCreators.completeGroupTabWizard());
        } catch (err) {
            if (err instanceof ProblemDetailsError) {
                if (err.problemDetails.type !== GroupTabErrorType.UPDATE_LIMIT_FAILED) {
                    dispatch(showModalMessage(modalMessages.knownFailureGroupTab(err.problemDetails, tabTypeName)));
                    throw new HandledError(err.problemDetails.detail);
                } else {
                    throw err;
                }
            } else {
                console.log("failed to update tab limit:", err);
            }
        }
    });
};
