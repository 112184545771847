import { scrolling as rootScrolling } from "../shared/scrolling";
import { device } from "./device";

export const scrolling = device.isIOS15orHigher
    ? rootScrolling
    : {
          scrollElementIntoView: () => {},
          scrollTop: (elementQuery?: string) =>
              elementQuery ? document.querySelector(elementQuery)?.scrollTop ?? window.scrollY : window.scrollY,
          scrollTo: (options?: ScrollToOptions, elementQuery?: string) => window.scrollTo(options),
      };
