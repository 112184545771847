import { getDeviceInfo } from "./device";
import { cordovaWindow } from "./cordova";
import { device } from "../index";
import { asset } from "./asset";

export * from "./device";
export * from "./statusBar";
export * from "./prompt";
export * from "./browserSupport";
export * from "./asset";
export * from "./splashScreen";
export * from "./handleUrlLink";
export * from "./backHandler";
export * from "./storeRating";
export * from "./appForegroundChange";
export * from "./href";
export * from "./connectivity";
export * from "./applePay";
export * from "./googlePay";
export * from "./qr";
export * from "./errorBoundaryMessages";
export * from "./oneTimePassword";
export * from "./scrolling";
export * from "./rotationHandler";
export * from "./navigation";
export * from "./webFlow";
export * from "./incorrectDocumentHeightHandler";
export * from "./scaleHandler";
export * from "./browserTranslate";
export * from "./tracking";
export * from "./gdpr";
export * from "./cardVerification";
export * from "./sso";
export * from "./recaptcha";

export const deviceReady = () =>
    new Promise<boolean>((resolve) => {
        document.addEventListener("deviceready", () => resolve(true), false);
    });

export const refreshApp = () => {
    cordovaWindow.navigator.splashscreen.show();
    if (device.isIOS) {
        window.location.reload();
    } else {
        cordovaWindow.location.replace(asset("index.html"));
    }
};

function prepareCordova() {
    getDeviceInfo();
    document.documentElement.classList.add("native", "scroll-root");
    document.body.classList.add("native", "scroll-root");

    console.log("Cordova device ready");
}

document.addEventListener("deviceready", prepareCordova);

export const isWeb = false;
export const isNative = true;
