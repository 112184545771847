import { ModifierListType, ModifierMap, OrderItemModifier, OrderStatus, Party } from "../types";
import { OperationCompletedAction } from "../../operations";
import { OrderCampaigns } from "./types";
import { trackedEvent } from "src/common/events/reduxEventTracking";

export interface PartyState {
    activeParty: Party | null;
    previousParty: Party | null;
    updatedTimestamp: number;
    connectionStatus: PartyConnectionStatus;
    viewedNotifications: string[];
    acceptedAllergenNotice: boolean;
    categoriesChanging?: boolean;
    limitedAlcoholicTooltipDismissed?: boolean;
}

export type PartyConnectionStatus = "disconnected" | "connecting" | "connected";

export enum PartyFoodPreferenceState {
    ON = "ON",
    OFF = "OFF",
}

export enum TypeKeys {
    SWITCHED = "PARTY/SWITCHED",
    CLOSED = "PARTY/CLOSED",
    LEFT = "PARTY/LEFT",
    PAUSED = "PARTY/PAUSED",
    UPDATED = "PARTY/UPDATED",
    ADD_OR_UPDATE_ORDER_ITEM_BEGIN = "PARTY/ADD_OR_UPDATE_ORDER_ITEM_BEGIN",
    ADD_ORDER_ITEM_FAILURE = "PARTY/ADD_ORDER_ITEM_FAILURE",
    REMOVE_ORDER_ITEM_BEGIN = "PARTY/REMOVE_ORDER_ITEM_BEGIN",
    REMOVE_ORDER_ITEM_FAILURE = "PARTY/REMOVE_ORDER_ITEM_FAILURE",
    LEAVE_PARTY_BEGIN = "PARTY/LEAVE_PARTY_BEGIN",
    LEAVE_PARTY_FAILURE = "PARTY/LEAVE_PARTY_FAILURE",
    CONNECTING = "PARTY/CONNECTING",
    CONNECTED = "PARTY/CONNECTED",
    DISCONNECTED = "PARTY/DISCONNECTED",
    CONNECTION_FAILED = "PARTY/CONNECTION_FAILED",
    VIEW_NOTIFICATION_BEGIN = "PARTY/VIEW_NOTIFICATION_BEGIN",
    VIEW_NOTIFICATION_FAILURE = "PARTY/VIEW_NOTIFICATION_FAILURE",
    SELECT_NOTIFICATION_CHOICE_BEGIN = "PARTY/SELECT_NOTIFICATION_CHOICE_BEGIN",
    SELECT_NOTIFICATION_CHOICE_FAILURE = "PARTY/SELECT_NOTIFICATION_CHOICE_FAILURE",
    OVERRIDE_ACTIVE_ORDER_STATUS = "PARTY/OVERRIDE_ACTIVE_ORDER_STATUS",
    ACCEPT_ALLERGEN_NOTICE = "PARTY/ACCEPT_ALLERGEN_NOTICE",
    CATEGORIES_CHANGING = "PARTY/CATEGORIES_CHANGING",
    MENU_FOOD_PREFERENCE_APPLIED = "PARTY/MENU_FOOD_PREFERENCE_APPLIED",
    CUSTOMER_FOOD_PREFERENCE_SET = "CUSTOMER_FOOD_PREFERENCE_SET",
    CUSTOMER_FOOD_PREFERENCE_LOAD = "PARTY/CUSTOMER_FOOD_PREFERENCE_LOAD",
    MENU_FOOD_PREFERENCE_FILTERED = "PARTY/MENU_FOOD_PREFERENCE_FILTERED",
    MODIFIERS_LIST_EXPANDED = "PARTY/MODIFIERS_VIEW_MORE_CLICKED",
    VIEW_PAY_ONLY_PARTY = "PARTY/VIEW_PAY_ONLY_PARTY",
    DISABLED_BUTTON_CLICKED = "PARTY/DISABLED_BUTTON_CLICKED",
    VIEWED_LIMITED_TOOLTIP = "PARTY/LIMITED_WARNING_VIEWED",
    LIMITED_TOOLTIP_DISMISSED = "PARTY/LIMITED_WARNING_DISMISSED",
    ALLERGEN_NOTICE_CLICKED = "PARTY/ALLERGEN_NOTICE_CLICKED",
}

export const actionCreators = {
    switched: (party: Party, previousParty: Party | null) => ({ type: TypeKeys.SWITCHED, party, previousParty }),
    updated: (party: Party, timestamp: number) => ({ type: TypeKeys.UPDATED, party, timestamp }),
    addOrUpdateToOrder: (
        courseId: string,
        categoryId: string | null,
        menuItemId: string,
        notes: string | null,
        orderItemId: string | null,
        variant: number | null,
        modifiers?: OrderItemModifier[],
        quantity?: number,
        campaign?: OrderCampaigns,
        source?: string,
        isVideo?: boolean,
        quickSellCarouselPosition?: number
    ) =>
        trackedEvent({
            type: TypeKeys.ADD_OR_UPDATE_ORDER_ITEM_BEGIN,
            courseId,
            categoryId,
            menuItemId,
            notes,
            orderItemId,
            variant,
            modifiers,
            quantity,
            campaign,
            source,
            isVideo,
            quickSellCarouselPosition,
        }),
    addToOrderFailed: (error: any) => ({ type: TypeKeys.ADD_ORDER_ITEM_FAILURE, error }),
    removeFromOrder: (orderItemId: string, menuItemId: string) =>
        trackedEvent({
            type: TypeKeys.REMOVE_ORDER_ITEM_BEGIN,
            orderItemId,
            menuItemId,
        }),
    removeFromOrderFailure: (error: any) => ({ type: TypeKeys.REMOVE_ORDER_ITEM_FAILURE, error }),
    leaveParty: () => ({ type: TypeKeys.LEAVE_PARTY_BEGIN }),
    leavePartyFailure: (error: any) => ({ type: TypeKeys.LEAVE_PARTY_FAILURE, error }),
    connecting: () => ({ type: TypeKeys.CONNECTING }),
    connected: () => ({ type: TypeKeys.CONNECTED }),
    disconnected: () => ({ type: TypeKeys.DISCONNECTED }),
    connectionFailed: (url: string, count: number) => ({ type: TypeKeys.CONNECTION_FAILED, url, count }),
    closed: (party: Party) => ({ type: TypeKeys.CLOSED, party }),
    leftParty: (partyId: string) => ({ type: TypeKeys.LEFT, partyId }),
    paused: (party: Party) => ({ type: TypeKeys.PAUSED, party }),
    viewNotification: (notificationIds: string[]) => ({ type: TypeKeys.VIEW_NOTIFICATION_BEGIN, notificationIds }),
    viewNotificationFailure: (error: any) => ({ type: TypeKeys.VIEW_NOTIFICATION_FAILURE, error }),
    selectNotificationChoice: (notificationId: string, choice: string) => ({
        type: TypeKeys.SELECT_NOTIFICATION_CHOICE_BEGIN,
        notificationId,
        choice,
    }),
    selectNotificationChoiceFailure: (error: any) => ({ type: TypeKeys.SELECT_NOTIFICATION_CHOICE_FAILURE, error }),
    overrideActiveOrderStatus: (status: OrderStatus) => ({ type: TypeKeys.OVERRIDE_ACTIVE_ORDER_STATUS, status }),
    acceptAllergenNotice: (accept: boolean) => ({ type: TypeKeys.ACCEPT_ALLERGEN_NOTICE, accept }),
    categoriesChanging: (changing: boolean) => ({ type: TypeKeys.CATEGORIES_CHANGING, changing }),
    trackMenuFoodPreferenceApplied: (changedPreference: PartyFoodPreferenceSet) =>
        trackedEvent({ type: TypeKeys.MENU_FOOD_PREFERENCE_APPLIED, ...changedPreference }),
    trackCustomerFoodPreferenceSet: (preferences: string) =>
        trackedEvent({ type: TypeKeys.CUSTOMER_FOOD_PREFERENCE_SET, preferences }),
    trackMenuFoodPreferenceFiltered: (filterData: PartyMenuFoodPreferenceFilterData) =>
        trackedEvent({ type: TypeKeys.MENU_FOOD_PREFERENCE_FILTERED, ...filterData }),
    trackCustomerFoodPreferenceLoad: (customerFoodPreference: string) =>
        trackedEvent({ type: TypeKeys.CUSTOMER_FOOD_PREFERENCE_LOAD, customerFoodPreference }),
    trackExpandedModifiersOptions: (
        product_id: string,
        modifier_id: string,
        number_of_mods_revealed: number,
        list_type: ModifierListType
    ) =>
        trackedEvent({
            type: TypeKeys.MODIFIERS_LIST_EXPANDED,
            product_id,
            modifier_id,
            number_of_mods_revealed,
            list_type,
        }),
    viewPayOnlyParty: (party: Party) => ({ type: TypeKeys.VIEW_PAY_ONLY_PARTY, party }),
    trackDisabledButtonClicked: (page: string) =>
        trackedEvent({ type: TypeKeys.DISABLED_BUTTON_CLICKED, current_page: page }),
    trackViewedLimitedAlcoholicDrinksTooltip: (properties: AlcoholLimitTooltipData) =>
        trackedEvent({ type: TypeKeys.VIEWED_LIMITED_TOOLTIP, ...properties }),
    trackDismissedLimitedAlcoholicDrinksTooltip: (page: string, totalItems: number) =>
        trackedEvent({ type: TypeKeys.LIMITED_TOOLTIP_DISMISSED, current_page: page, nb_total_items: totalItems }),
    trackAllergenNoticeClicked: (source_page: "service" | "product") =>
        trackedEvent({ type: TypeKeys.ALLERGEN_NOTICE_CLICKED, source_page }),
};

export type PartySwitchedAction = { type: TypeKeys.SWITCHED; party: Party; previousParty: Party };
export type PartyClosedAction = { type: TypeKeys.CLOSED; party: Party };
export type PartyLeftAction = { type: TypeKeys.LEFT; partyId: string };
export type PartyPausedAction = { type: TypeKeys.PAUSED; party: Party };
export type PartyUpdatedAction = { type: TypeKeys.UPDATED; party: Party; timestamp: number };
export type PartyConnectingAction = { type: TypeKeys.CONNECTING };
export type PartyConnectedAction = { type: TypeKeys.CONNECTED };
export type PartyDisconnectedAction = { type: TypeKeys.DISCONNECTED };
export type PartyConnectionFailedAction = { type: TypeKeys.CONNECTION_FAILED; url: string; count: number };

export type UpdateOrderItemAction = {
    type: TypeKeys.ADD_OR_UPDATE_ORDER_ITEM_BEGIN;
    courseId: string;
    menuItemId: string;
    orderItemId: string | null;
    notes: string | null;
    variant: number | null;
    modifiers?: ModifierMap;
    quantity?: number;
    campaign?: OrderCampaigns;
};

export type RemoveOrderItemAction = {
    type: TypeKeys.REMOVE_ORDER_ITEM_BEGIN;
    orderItemId: string;
    menuItemId: string;
};

export type LeavePartyAction = {
    type: TypeKeys.LEAVE_PARTY_BEGIN;
};

export type ViewedNotificationAction = {
    type: TypeKeys.VIEW_NOTIFICATION_BEGIN;
    notificationIds: string[];
};

export type SelectNotificationChoiceAction = {
    type: TypeKeys.SELECT_NOTIFICATION_CHOICE_BEGIN;
    notificationId: string;
    choice: string;
};

export type PartyOverrideActiveOrderStatusAction = { type: TypeKeys.OVERRIDE_ACTIVE_ORDER_STATUS; status: OrderStatus };

export type AcceptAllergenNoticeAction = { type: TypeKeys.ACCEPT_ALLERGEN_NOTICE; accept: boolean };

export type PartyCategoriesChanging = { type: TypeKeys.CATEGORIES_CHANGING; changing: boolean };

export type PartyFoodPreferenceSet = {
    preferenceName: string;
    preferenceState: PartyFoodPreferenceState;
};

export type AlcoholLimitTooltipData = {
    current_page: string;
    max_number_drinks: number;
    nb_total_items: number;
};

export type PartyMenuFoodPreferenceFilterData = {
    itemsTotal: number;
    itemsVisible: number;
    categoryId: string;
    categoryName: string;
    menuFoodPreference: string;
};
export type ViewPayOnlyPartyAction = { type: TypeKeys.VIEW_PAY_ONLY_PARTY; party: Party };

export type LimitedAlcoholicTooltipDismissedAction = { type: TypeKeys.LIMITED_TOOLTIP_DISMISSED };

export type PartyAction =
    | PartySwitchedAction
    | PartyUpdatedAction
    | UpdateOrderItemAction
    | RemoveOrderItemAction
    | PartyConnectingAction
    | PartyConnectedAction
    | PartyDisconnectedAction
    | PartyClosedAction
    | LeavePartyAction
    | PartyLeftAction
    | PartyPausedAction
    | OperationCompletedAction
    | ViewedNotificationAction
    | SelectNotificationChoiceAction
    | PartyOverrideActiveOrderStatusAction
    | AcceptAllergenNoticeAction
    | PartyCategoriesChanging
    | ViewPayOnlyPartyAction
    | LimitedAlcoholicTooltipDismissedAction;

const initialState: PartyState = {
    activeParty: null,
    previousParty: null,
    updatedTimestamp: 0,
    connectionStatus: "disconnected",
    viewedNotifications: [],
    acceptedAllergenNotice: false,
};

export function reducer(state: PartyState = initialState, action: PartyAction): PartyState {
    if (action.type === TypeKeys.SWITCHED) {
        return {
            ...state,
            activeParty: action.party,
            updatedTimestamp: 0,
        };
    }

    if (action.type === TypeKeys.UPDATED) {
        if (
            state.activeParty &&
            state.activeParty.id === action.party.id &&
            action.timestamp > state.updatedTimestamp
        ) {
            return {
                ...state,
                activeParty: action.party,
                updatedTimestamp: action.timestamp,
            };
        }
    }

    if (action.type === TypeKeys.CLOSED || action.type === TypeKeys.PAUSED) {
        if (state.activeParty && state.activeParty.id === action.party.id) {
            return {
                ...state,
                activeParty: null,
                previousParty: action.party,
                updatedTimestamp: 0,
                acceptedAllergenNotice: false,
                limitedAlcoholicTooltipDismissed:
                    action.type === TypeKeys.PAUSED ? state.limitedAlcoholicTooltipDismissed : false,
            };
        }
    }

    if (action.type === TypeKeys.LEFT) {
        if (state.activeParty && state.activeParty.id === action.partyId) {
            return {
                ...state,
                activeParty: null,
                previousParty: state.activeParty,
                updatedTimestamp: 0,
                acceptedAllergenNotice: false,
                limitedAlcoholicTooltipDismissed: false,
            };
        }
    }

    if (action.type === TypeKeys.VIEW_NOTIFICATION_BEGIN) {
        return {
            ...state,
            viewedNotifications: state.viewedNotifications.concat(action.notificationIds),
        };
    }

    if (action.type === TypeKeys.CONNECTING) {
        return {
            ...state,
            connectionStatus: "connecting",
        };
    }

    if (action.type === TypeKeys.CONNECTED) {
        return {
            ...state,
            connectionStatus: "connected",
        };
    }

    if (action.type === TypeKeys.DISCONNECTED) {
        return {
            ...state,
            connectionStatus: "disconnected",
        };
    }

    if (action.type === TypeKeys.OVERRIDE_ACTIVE_ORDER_STATUS) {
        if (!state.activeParty || !state.activeParty.activeOrder) {
            return state;
        }

        return {
            ...state,
            activeParty: {
                ...state.activeParty,
                activeOrder: {
                    ...state.activeParty.activeOrder,
                    status: action.status,
                },
            },
        };
    }

    if (action.type === TypeKeys.ACCEPT_ALLERGEN_NOTICE) {
        return {
            ...state,
            acceptedAllergenNotice: action.accept,
        };
    }

    if (action.type === TypeKeys.CATEGORIES_CHANGING) {
        return {
            ...state,
            categoriesChanging: action.changing,
        };
    }

    if (action.type === TypeKeys.VIEW_PAY_ONLY_PARTY) {
        return {
            ...state,
            activeParty: null,
            previousParty: action.party,
            updatedTimestamp: 0,
        };
    }

    if (action.type === TypeKeys.LIMITED_TOOLTIP_DISMISSED) {
        return {
            ...state,
            limitedAlcoholicTooltipDismissed: true,
        };
    }

    return state;
}
