import { getAuthHeaders } from "src/common/auth";
import { orderApi, reattemptStrategies } from "src/features/order/orderApi";
import { GroupTabHistoryResponse, GroupTabHistoryView } from "../types";

export async function getGroupTabHistoryList(lastServiceDate: string | null, limit: number = 13) {
    const response = await orderApi.send(
        `/grouptabshistory/list?lastServiceDate=${lastServiceDate}&limit=${limit}`,
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }

    const data = await response.json();

    return {
        hasMore: data.length === limit,
        data,
    } as GroupTabHistoryResponse;
}

export async function getGroupTabHistoryData(groupTabId: string) {
    const response = await orderApi.send(
        `/grouptabshistory/${groupTabId}`,
        {
            method: "GET",
            headers: await getAuthHeaders(),
        },
        reattemptStrategies.limited
    );

    if (!response.ok) {
        throw response;
    }
    const data = await response.json();

    return data as GroupTabHistoryView;
}
