import { trackedError } from "src/common/events/reduxEventTracking";

export enum TypeKeys {
    BEGIN = "OPERATION/BEGIN",
    COMPLETED = "OPERATION/COMPLETE",
    FAILED = "OPERATION/FAILED",
    RESET = "OPERATION/RESET",
}

export const actionCreators = {
    begin: (operation: string) => ({ type: TypeKeys.BEGIN, operation }),
    completed: <T = any>(operation: string, result: T | undefined, timing?: number) =>
        ({ type: TypeKeys.COMPLETED, operation, result, timing }),
    failed: (operation: string, error: any, timing?: number) =>
        trackedError({ type: TypeKeys.FAILED, operation, error, timing }),
    reset: (operation: string) => ({ type: TypeKeys.RESET, operation }),
};

export type OperationsState = { [operation: string]: OperationState | undefined };

export type State = {
    operations: OperationsState;
};

export interface OperationState {
    status: OperationStatus;
    error?: any;
}

export type OperationStatus = "initial" | "processing" | "complete" | "failed";

export type OperationBeginAction = { type: TypeKeys.BEGIN; operation: string };
export type OperationCompletedAction = { type: TypeKeys.COMPLETED; operation: string; result: any };
export type OperationFailedAction = { type: TypeKeys.FAILED; error: any; operation: string };
export type OperationResetAction = { type: TypeKeys.RESET; operation: string };

export type OperationAction =
    | OperationBeginAction
    | OperationCompletedAction
    | OperationFailedAction
    | OperationResetAction;

const initialState: OperationsState = {};

export default function reducer(state = initialState, action: OperationAction) {
    if (action.type === TypeKeys.BEGIN) {
        return changeStatus(state, action.operation, "processing");
    }

    if (action.type === TypeKeys.COMPLETED) {
        return changeStatus(state, action.operation, "complete");
    }

    if (action.type === TypeKeys.FAILED && state[action.operation]?.status === "processing") {
        return changeStatus(state, action.operation, "failed", action.error);
    }

    if (action.type === TypeKeys.RESET) {
        return changeStatus(state, action.operation, "initial");
    }

    return state;
}

function changeStatus(state: OperationsState, operation: string, status: OperationStatus, error?: any) {
    return {
        ...state,
        [operation]: {
            status,
            error,
        },
    };
}
