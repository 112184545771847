import { orderApi, reattemptStrategies } from "../../order/orderApi";
import { getPaymentSessionOperation } from "../operations";
import { Dispatch } from "redux";
import { getAuthHeaders } from "src/common/auth";
import { PaymentGateway } from "src/common/payment";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";

export function fetchPaymentSession<T extends Record<string, string>>(
    dispatch: Dispatch,
    paymentGateway: PaymentGateway,
    inParty: boolean
) {
    return getPaymentSessionOperation.invoke(async () => {
        const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

        const response = await orderApi.send(
            `/paymentgateway/${paymentGateway}/paymentsession`,
            {
                method: "POST",
                headers,
            },
            reattemptStrategies.limited
        );

        await ProblemDetailsError.throwError(response);

        return (await response.json()) as T;
    }, dispatch);
}
