import "./MemberIndicator.scss";

import React from "react";
import { Text } from "../";
import { UserProfileIcon, CircleTick } from "../../assets/icons";
import classNames from "classnames";

export interface MemberIndicatorProps {
    value?: string;
    active?: boolean;
    disabled?: boolean;
    isLarge?: boolean;
}

export const MemberIndicator = ({ value, active, disabled, isLarge }: MemberIndicatorProps) => (
    <div className={classNames("member-indicator", active && "active", disabled && "disabled", isLarge && "large")}>
        {active && (
            <span className="member-indicator__active-icon">
                <CircleTick />
            </span>
        )}
        {value ? (
            <Text preset={isLarge ? "g-18" : "g-12"} mode={["extra-bold", "caps"]}>
                {value}
            </Text>
        ) : (
            <UserProfileIcon />
        )}
    </div>
);
