import { orderApi } from "../orderApi";
import { AppDispatch, AppState } from "../..";
import {
    validateActiveOrderInBackgroundOperation,
    validateActiveOrderOperation,
    validateActiveOrderSilentlyOperation,
} from "../operations";
import { getParty } from "../selectors";
import { actionCreators as partyActionCreators, OrderStatus } from "..";
import { submitPaymentAndOrderOperation } from "src/features/payment/operations";
import { PartyStatus } from "../types";
import { getIsOpenTable } from "src/features/openTable/selectors";
import { actionCreators as openTableActionCreators } from "src/features/openTable/reducers";

const validateActiveOrderInternal = async (dispatch: AppDispatch, getState: () => AppState) => {
    const party = getParty(getState());
    const isOpenTable = getIsOpenTable(getState());

    const activeOrderActionCreator = isOpenTable
        ? openTableActionCreators.setMemberActiveOrderStatus
        : partyActionCreators.overrideActiveOrderStatus;

    dispatch(activeOrderActionCreator(OrderStatus.VALIDATING));

    try {
        await orderApi.invoke("validateActiveOrder", party?.sequenceId);
        if (isOpenTable) {
            dispatch(activeOrderActionCreator(OrderStatus.VALID));
        }
    } catch (err) {
        dispatch(activeOrderActionCreator(OrderStatus.INVALID));

        throw err;
    }
};

export const validateActiveOrderSilently = () =>
    validateActiveOrderSilentlyOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        validateActiveOrderInternal(dispatch, getState)
    );

export const validateActiveOrder = () =>
    validateActiveOrderOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        validateActiveOrderInternal(dispatch, getState)
    );

export const validateActiveOrderInBackground = () =>
    validateActiveOrderInBackgroundOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        // If an anonymous user joins post-validation, they can reach the ReviewOrder
        // page with the ActiveOrder already the Open state. Since ReviewOrder only
        // triggers revalidation on property changes, this can result in the validation
        // request occuring in the middle of payment.
        const submitPaymentStatus = submitPaymentAndOrderOperation.getStatus(state);
        if (submitPaymentStatus === "processing" || submitPaymentStatus === "complete") {
            return;
        }

        // Ensure that the party is still in a valid state for background validation:
        // - If the order state has changed since this async method was scheduled
        // - Ensure that the party itself is stil open (closing the party reverts
        //   ActiveOrder to a clean state, which has an OPEN status)
        const party = getParty(state);
        if (
            !party ||
            party.status !== PartyStatus.OPEN ||
            (party.activeOrder.status !== OrderStatus.OPEN &&
                party.activeOrder.status !== OrderStatus.INVALID &&
                party.activeOrder.status !== OrderStatus.PAYMENTFAILED)
        ) {
            return;
        }

        return await validateActiveOrderInternal(dispatch, getState);
    });
