import "../assets/TagButtonGroup.scss";

import * as React from "react";
import { Tag } from "../../menudata";
import { Button } from "src/sharedComponents";
import { DietaryIcons } from "src/sharedComponents/assets/icons/DietaryIcons";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";

interface TagButtonGroupProps {
    tags: Tag[];
    tagGroupId: string;
    selectedValues: string[];
    onSelect(tagGroupId: string, selectedTag: Tag, selected: boolean): void;
    brandColor?: boolean;
    requiresNetworkConnection?: boolean;
}

export class TagButtonGroup extends React.Component<TagButtonGroupProps> {
    render() {
        const { tags } = this.props;

        return <div className="tagbuttongroup--container">{tags.map(this.renderTagButton)}</div>;
    }

    renderTagButton = (tag: Tag) => {
        const { selectedValues, brandColor, requiresNetworkConnection } = this.props;

        const isSelected = selectedValues && selectedValues.indexOf(tag.id) !== -1;

        return (
            <TagButton
                tag={tag}
                selected={isSelected}
                onSelect={this.handleonSelect}
                key={tag.id}
                brandColor={brandColor}
                requiresNetworkConnection={requiresNetworkConnection}
            />
        );
    };

    handleonSelect = (tag: Tag, selected: boolean) => {
        const { tagGroupId } = this.props;
        this.props.onSelect(tagGroupId, tag, !selected);
    };
}

export interface TagButtonProps {
    tag: Tag;
    selected: boolean;
    onSelect: (tag: Tag, selected: boolean) => void;
    brandColor?: boolean;
    requiresNetworkConnection?: boolean;
}

export class TagButton extends React.Component<TagButtonProps> {
    render() {
        const { tag, selected, brandColor, requiresNetworkConnection } = this.props;

        const ButtonComponent = requiresNetworkConnection ? NetworkConnectedButton : Button;

        return (
            <div className="tagbuttongroup--item">
                <ButtonComponent
                    mode={selected ? "solid" : "outline"}
                    brandColor={!!brandColor}
                    aria-selected={selected}
                    className={"tagbuttongroup--button toggle"}
                    onClick={this.onSelect}
                    leftIcon={tag.icon ? DietaryIcons[tag.icon] : undefined}
                >
                    {tag.displayName}
                </ButtonComponent>
            </div>
        );
    }

    onSelect = () => {
        const { onSelect, tag, selected } = this.props;

        onSelect(tag, !selected);
    };
}
