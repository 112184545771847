import { AppState } from "../../index";
import { HostedField } from "../types";
import { createSelector } from "reselect";

const getHostedFieldsValidState = (state: AppState) => state.hostedFields.validState;

const getHostedFieldsEmptyState = (state: AppState) => state.hostedFields.emptyState;

export const getHostedFieldsValidity = createSelector(
    getHostedFieldsValidState,
    getHostedFieldsEmptyState,
    (validState, emptyState) => ({
        cardNumberValid: validState[HostedField.Number] === true,
        cardNumberInvalid: validState[HostedField.Number] === false,
        cvvValid: validState[HostedField.CVV] === true,
        cvvInvalid: validState[HostedField.CVV] === false,
        expiryValid:
            validState[HostedField.Expiry] === true ||
            (validState[HostedField.ExpiryMonth] === true && validState[HostedField.ExpiryYear] === true),
        expiryInvalid:
            validState[HostedField.Expiry] === false ||
            validState[HostedField.ExpiryMonth] === false ||
            validState[HostedField.ExpiryYear] === false,
        expiryEmpty: emptyState[HostedField.ExpiryMonth] !== false && emptyState[HostedField.ExpiryYear] !== false,
    })
);

export const getAllHostedFieldsValid = createSelector(
    getHostedFieldsValidity,
    ({ cardNumberValid, cvvValid, expiryValid }) => cardNumberValid && cvvValid && expiryValid
);
