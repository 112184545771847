import { AppMiddleware, FeatureConfiguration } from "..";
import { ApplicationInsights, ITelemetryPlugin } from "@microsoft/applicationinsights-web";
import { AnyAction, Dispatch } from "redux";
import { History } from "history";
import {
    AppStartPlugin,
    AuthenticatedUserPlugin,
    BuildInfoPlugin,
    DeviceInfoPlugin,
    HistoryPlugin,
    LocationInfoPlugin,
    NoNetworkConnectivityPlugin,
    PartyInfoPlugin,
    ReduxActionEventPlugin,
    ReduxTelemetryEnhancer,
} from "./plugins";
import { findReduxActionPlugins } from "./util/findReduxActionPlugins";
import { RegionChangedAction, TypeKeys as RegionTypeKeys } from "../region/reducers/region";
import {
    CookiePreferencesChangedAction,
    TypeKeys as CookiePreferencesTypeKeys,
} from "../compliance/reducers/cookiePreferences";
import { gdpr } from "../../common/experience";
import { deleteCookie, getCookie, setCookie } from "../../common/cookies";
import { IUserContext } from "@microsoft/applicationinsights-common";
import { analyticsMiddleware } from "./middleware/analyticsMiddleware";

let appInsights: ApplicationInsights | null = null;
let buffer = true;
export const getAppInsights = () => appInsights;

export default function (config: FeatureConfiguration) {
    config.middleware.push(appInsightsMiddleware(config.history));
    config.middleware.push(analyticsMiddleware());
}

const reduxEnhancers: ReduxTelemetryEnhancer[] = [];

const addRemoveCookies = (allowAnalytics: boolean, user?: IUserContext) => {
    if (!allowAnalytics) {
        deleteCookie("ai_user");
        deleteCookie("ai_session");
    } else if (user && !getCookie("ai_user")) {
        setCookie("ai_user", `${user.id}|${user.accountAcquisitionDate}`, { "max-age": 31536000 });
    }
};

export const appInsightsMiddleware = (history: History) => {
    const extensions = setPriority([
        new AuthenticatedUserPlugin(),
        new BuildInfoPlugin(),
        new DeviceInfoPlugin(),
        new HistoryPlugin(history),

        new LocationInfoPlugin(),
        new PartyInfoPlugin(),

        new AppStartPlugin(),
        new ReduxActionEventPlugin(reduxEnhancers),
        new NoNetworkConnectivityPlugin(),
    ]);

    const reduxActionPlugins = findReduxActionPlugins(extensions || []);

    return (store: AppMiddleware) => (next: Dispatch) => (action: AnyAction) => {
        if (action.type === RegionTypeKeys.CHANGED) {
            const {
                region: { appInsightsConnectionString },
            } = action as RegionChangedAction;
            const { allowAnalytics } = gdpr.getCookiePreferences();

            if (appInsightsConnectionString) {
                if (appInsights === null) {
                    addRemoveCookies(allowAnalytics);

                    appInsights = new ApplicationInsights({
                        config: {
                            disableCorrelationHeaders: true,
                            connectionString: appInsightsConnectionString,
                            disableFetchTracking: false,
                            extensions,
                            loggingLevelConsole: 2,
                            enableDebug: true,
                            cookieCfg: {
                                enabled: allowAnalytics,
                            },
                        },
                    });

                    appInsights.loadAppInsights();
                } else {
                    // Flush before changing instrumentation key
                    // Hopefully flushing async (default) will be sufficient as sync could take a while
                    appInsights.flush();
                    appInsights.config.connectionString = appInsightsConnectionString;
                    appInsights.config.disableTelemetry = false;
                    // Changing region may mean we also need to enable/disable cookie usage based on GDPR choice
                    appInsights.getCookieMgr().setEnabled(allowAnalytics);
                    addRemoveCookies(allowAnalytics, appInsights.context.user);
                }
            } else if (appInsights !== null) {
                appInsights.flush();
                appInsights.config.disableTelemetry = true;
                addRemoveCookies(allowAnalytics, appInsights.context.user);
            } else {
                // We will never initialize the plugins so stop buffering Redux actions
                buffer = false;
            }
        }

        if (action.type === CookiePreferencesTypeKeys.CHANGED) {
            const { allowAnalytics } = action as CookiePreferencesChangedAction;
            appInsights?.getCookieMgr().setEnabled(allowAnalytics);
            addRemoveCookies(allowAnalytics, appInsights?.context.user);
        }

        for (let reduxActionPlugin of reduxActionPlugins) {
            reduxActionPlugin.handleReduxAction(action, store.getState, buffer);
        }

        next(action);
    };
};

// AppInsights plugins need to have a unique priority, but since our declarative order is what we
// want, it's easier to set it dynamically
function setPriority(ext: ITelemetryPlugin[]) {
    const BASE_PRIORITY = 220;

    for (let i = 0; i < ext.length; i++) {
        ext[i].priority = BASE_PRIORITY + i;
    }

    return ext;
}

export function registerReduxEnhancer(enhancer: ReduxTelemetryEnhancer) {
    reduxEnhancers.push(enhancer);
}
