import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scrolling } from "src/common/experience";
import { getSupportEmailAttributes } from "src/features/compliance/selectors/supportEmail";
import { Divider, Text } from "src/sharedComponents";
import { getCompleteRefunds, getMemberCompletePayments } from "../../payment/selectors";
import { getPaymentMethodText } from "../../payment/util/getPaymentMethodText";
import { PaymentItem } from "../../payment/components/PaymentItem";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";
import { evenRound } from "../../../sharedComponents/common/utils";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { getVenueServiceFeeText } from "../utils";

export const PayOnlyReceiptClosedMessages = () => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const payments = useSelector(getMemberCompletePayments);
    const refunds = useSelector(getCompleteRefunds);
    const supportEmailAttributes = useSelector(getSupportEmailAttributes);

    const dispatch = useDispatch();

    useEffect(() => {
        scrolling.scrollTo({ top: 0, behavior: "smooth" });
    }, [dispatch]);

    return (
        <>
            <Divider className="pay-only-receipt__container__after-receipt-divider" />
            {payments.length ? (
                <>
                    <Text
                        className="pay-only-receipt__container__your-payments"
                        preset="g-18"
                        mode="bold"
                        value="Your payments"
                    />
                    {payments.map((payment) => {
                        const {
                            id,
                            attemptedPaymentMethods: paymentMethod,
                            cardType,
                            paymentMethodDescription,
                            amount,
                            tip,
                            processingFee,
                            venueServiceFee,
                            venueServiceFeeFactor,
                            processingFeeAbsorbed,
                        } = payment;

                        const refund = refunds.find((r) => r.paymentId === id);

                        const visibleProcessingFee = processingFeeAbsorbed ? 0 : processingFee;

                        return (
                            <div key={id} className="pay-only-receipt__payment">
                                <Text className="pay-only-receipt__payment-method" preset="g-14">
                                    {getPaymentMethodText(paymentMethod, cardType, paymentMethodDescription)}
                                </Text>
                                <PaymentItem title="Balance paid" value={amount} />
                                <PaymentItem
                                    title={getVenueServiceFeeText(menuDataLocale, venueServiceFeeFactor)}
                                    value={venueServiceFee}
                                />
                                <PaymentItem title="Tip" value={tip} />
                                <PaymentItem
                                    title={getProcessingFeeText(menuDataLocale, paymentMethod)}
                                    value={visibleProcessingFee}
                                />
                                {refund && (
                                    <PaymentItem
                                        extraClass="pay-only-receipt__payment-refund"
                                        title="Refund"
                                        value={-refund.amountRefunded}
                                    />
                                )}
                                <PaymentItem
                                    extraClass="pay-only-receipt__payment-total"
                                    title="Total"
                                    value={evenRound(amount + tip + visibleProcessingFee + venueServiceFee, 2)}
                                    bold
                                />
                            </div>
                        );
                    })}
                    <div className="pay-only-receipt__container__bottom-message">
                        <Text preset="g-14" mode="block">
                            Need help with this order? Contact our{" "}
                            <a {...supportEmailAttributes}>
                                <Text preset="g-14" mode="bold">
                                    support team
                                </Text>
                            </a>
                        </Text>
                    </div>
                </>
            ) : (
                <div className="pay-only-receipt__container__bottom-message">
                    <Text preset="g-14" mode="block" className="pay-only-receipt__container__non-member">
                        You didn’t make any payments for this order
                    </Text>
                    <Text preset="g-14" mode="block">
                        Believe this is an error? Contact our{" "}
                        <a {...supportEmailAttributes}>
                            <Text preset="g-14" mode="bold">
                                support team
                            </Text>
                        </a>
                    </Text>
                </div>
            )}
        </>
    );
};
