import { actionCreators } from "../reducers/mealHistory";
import { actionCreators as deepLinkActionCreators } from "src/features/deeplink";
import { actionCreators as accountMenuActionCreators } from "src/features/accountmenu/reducers/accountMenu";
import {
    actionCreators as takeawayActionCreators,
    CollectionDetails,
} from "src/features/takeaway/reducers/takeawayOptions";
import {
    fetchOrderHistoryOperation,
    getMealHistoryDetailsOperation,
    getMealHistoryOperation,
    markMealHistoryAsReadOperation,
    markMealHistoryAsCollectedOperation,
} from "../operations";
import { fetchGroupTabHistoryList } from "src/features/groupTabsHistory/actions";
import * as orderHistoryApi from "../API/orderHistoryApi";
import { AppDispatch, AppState } from "../..";
import { MealHistoryOrderStatus, OrderHistoryData } from "../reducers/types";
import { fetchTakeawayCollectionDetails } from "src/features/takeaway/api/collectionDetailsApi";
import { DeliveryOptionType } from "src/features/order/types";

export function fetchMealHistory(lastServiceDate: string | null, limit?: number, onComplete?: Function) {
    return getMealHistoryOperation.getThunk(async (dispatch, getState: () => AppState) => {
        dispatch(actionCreators.loadingMealHistory("processing"));
        let mealHistory;

        try {
            mealHistory = await orderHistoryApi.getMealHistory(lastServiceDate, limit);
            dispatch(actionCreators.getMealHistoryComplete(mealHistory));
            onComplete && dispatch(onComplete());
        } catch {
            dispatch(actionCreators.loadingMealHistory("failure"));
        }
        return mealHistory?.data || [];
    });
}

const fetchPartyMealHistoryDataInternal = async (
    dispatch: AppDispatch,
    getState: () => AppState,
    partyId: string,
    force: boolean
) => {
    const {
        orderHistoryData: { orderHistoryData },
        takeawayOptions: { previousVenueDetails },
    } = getState();
    const history = orderHistoryData.find((h) => h.partyId === partyId);
    const locationId = history?.summary?.locationId;
    const shouldFetchVenueDetails = shouldFetchVenueCollectionDetails(
        locationId,
        partyId,
        history,
        previousVenueDetails
    );
    if (!force && history && history.details) {
        if (locationId && shouldFetchVenueDetails) {
            const collectionDetails = await fetchTakeawayCollectionDetails(locationId, partyId);
            if (collectionDetails) {
                dispatch(takeawayActionCreators.setVenueDetails({ [locationId]: collectionDetails }));
            }
        }
        return history;
    }

    let mealHistory: OrderHistoryData | null;

    if (locationId && shouldFetchVenueDetails) {
        const [mealHistoryData, collectionDetails] = await Promise.all([
            orderHistoryApi.getPartyMealHistoryData(partyId),
            fetchTakeawayCollectionDetails(locationId, partyId),
        ]);
        mealHistory = mealHistoryData;
        if (collectionDetails) {
            dispatch(takeawayActionCreators.setVenueDetails({ [locationId]: collectionDetails }));
        }
    } else {
        mealHistory = await orderHistoryApi.getPartyMealHistoryData(partyId);
    }

    dispatch(actionCreators.getPartyMealHistoryData(mealHistory));

    return mealHistory;
};

export const fetchPartyMealHistoryData = (partyId: string, force: boolean = false) =>
    getMealHistoryDetailsOperation.getThunk((dispatch: AppDispatch, getState: () => AppState) =>
        fetchPartyMealHistoryDataInternal(dispatch, getState, partyId, force)
    );

export const fetchPartyMealHistoryDataAwaitable = (
    dispatch: AppDispatch,
    getState: () => AppState,
    partyId: string,
    force: boolean = false
) =>
    getMealHistoryDetailsOperation.invoke(
        () => fetchPartyMealHistoryDataInternal(dispatch, getState, partyId, force),
        dispatch
    );

const fetchOngoingPartiesMealHistoryInternal = async (
    dispatch: AppDispatch,
    partyIds: string[] | null,
    forceUpdate?: boolean
) => {
    const mealHistory = partyIds
        ? await orderHistoryApi.getOngoingPartiesMealHistory(partyIds, forceUpdate)
        : await orderHistoryApi.getAllOngoingMealHistory();

    dispatch(actionCreators.getPartiesMealHistoryComplete(mealHistory));

    return mealHistory;
};

export const fetchOngoingPartiesMealHistory = (partyIds: string[] | null, forceUpdate?: boolean) =>
    getMealHistoryOperation.getThunk((dispatch: AppDispatch) =>
        fetchOngoingPartiesMealHistoryInternal(dispatch, partyIds, forceUpdate)
    );

export const fetchOngoingPartiesMealHistoryAwaitable = (
    dispatch: AppDispatch,
    partyIds: string[] | null,
    forceUpdate?: boolean
) =>
    getMealHistoryOperation.invoke(
        () => fetchOngoingPartiesMealHistoryInternal(dispatch, partyIds, forceUpdate),
        dispatch
    );

export function selectMealHistory(dispatch: AppDispatch, historyId: string, shouldOpen: boolean | null) {
    return fetchOrderHistoryOperation.invoke(async () => {
        dispatch(fetchPartyMealHistoryData(historyId));

        dispatch(actionCreators.selectPartyMealHistory(historyId, shouldOpen));
    }, dispatch);
}

export const fetchMealOrOrderHistory = (partyId: string, deepLinkUrl?: string) => {
    return fetchOrderHistoryOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const orderHistory = await fetchPartyMealHistoryDataAwaitable(dispatch, getState, partyId, true);
        if (orderHistory?.partyId === partyId) {
            if (deepLinkUrl) {
                dispatch(deepLinkActionCreators.loaded(deepLinkUrl, "OrderHistoryItem"));
            }
            dispatch(actionCreators.selectPartyMealHistory(partyId, true));
        } else {
            fetchMealAndGroupTabHistories(dispatch, deepLinkUrl);
        }
    });
};

function loadRelevantOrderHistory() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const {
            orderHistoryData: { orderHistoryData },
        } = getState();
        if (orderHistoryData) {
            const ordersToShow = orderHistoryData.filter(
                (item) =>
                    item.mealHistoryOrders?.[0]?.status &&
                    [
                        MealHistoryOrderStatus.READY,
                        MealHistoryOrderStatus.PREPARING,
                        MealHistoryOrderStatus.SUBMITTED,
                    ].includes(item.mealHistoryOrders[0].status)
            );
            if (ordersToShow?.length === 1) {
                dispatch(actionCreators.selectPartyMealHistory(ordersToShow[0].partyId, true));
            }
        }
    };
}

export function fetchMealAndGroupTabHistories(dispatch: AppDispatch, deepLinkUrl?: string) {
    dispatch(fetchMealHistory(null, undefined, loadRelevantOrderHistory));
    dispatch(accountMenuActionCreators.openAccountMenu("OrderHistory"));
    if (deepLinkUrl) {
        dispatch(deepLinkActionCreators.loaded(deepLinkUrl, "OrderHistory"));
    }
    dispatch(fetchGroupTabHistoryList(null));
}

export function markMealHistoryAsRead(partyId: string) {
    return markMealHistoryAsReadOperation.getThunk(async (dispatch) => {
        const mealHistory = await orderHistoryApi.markMealHistoryAsRead(partyId);

        dispatch(actionCreators.getPartyMealHistoryData(mealHistory));

        return mealHistory;
    });
}

export function markMealHistoryAsCollected(partyId: string) {
    return markMealHistoryAsCollectedOperation.getThunk(async (dispatch) => {
        const mealHistory = await orderHistoryApi.markMealHistoryAsCollected(partyId);

        dispatch(actionCreators.getPartyMealHistoryData(mealHistory));

        return mealHistory;
    });
}

function shouldFetchVenueCollectionDetails(
    locationId: string | undefined,
    partyId: string,
    history: OrderHistoryData | undefined,
    previousVenueDetails?: {
        [key: string]: CollectionDetails;
    }
) {
    const mealHistoryOrder = history?.mealHistoryOrders?.[0];
    const isTakeaway = mealHistoryOrder?.deliveryOption?.type === DeliveryOptionType.TAKEAWAY;
    const isValidOrderStatus =
        mealHistoryOrder &&
        [MealHistoryOrderStatus.SUBMITTED, MealHistoryOrderStatus.PREPARING, MealHistoryOrderStatus.READY].includes(
            mealHistoryOrder.status
        );

    return locationId && partyId && isTakeaway && isValidOrderStatus && !previousVenueDetails?.[locationId];
}
