import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MODIFIER_OPTION_LIMIT, ModifierMultiSelectListItem } from "./ModifierMultiSelectListItem";
import { ExpandableList } from "src/sharedComponents/controls/expandableList";
import { getModifierListInitialLimit } from "../helpers";
import { MODIFIER_LIST_INITIAL_LIMIT, reduceNestedModifierLength } from "../../menuitem/components/MenuItemDetails";
import { useHasMounted } from "src/sharedComponents/common/shared";
import { withListTracking } from "../component/withListTracking";
import { ModifierListMultiSelectProps } from "src/features/order/Elements/OrderModifiers";

const MultiSelectList = ({
    modifier,
    modifierId,
    selectedOptions,
    onModifierMultiSelectChanged,
    onNestedModifierEdit,
    selectedNestedModifier,
    trackExpand,
    canHideItems,
}: ModifierListMultiSelectProps) => {
    const hasMounted = useHasMounted();
    const [limit, setLimit] = useState(MODIFIER_LIST_INITIAL_LIMIT);

    const selectedValues = selectedOptions || [];
    const nestedModiferKey = useMemo(
        () =>
            !selectedNestedModifier || limit === modifier.options.length
                ? ""
                : `-${reduceNestedModifierLength(selectedNestedModifier)}`,
        [modifier.options, limit, selectedNestedModifier]
    );

    useEffect(() => {
        if (!hasMounted) {
            setLimit(getModifierListInitialLimit(MODIFIER_LIST_INITIAL_LIMIT, modifier.options, selectedOptions));
        }
    }, [hasMounted, modifier.options, selectedOptions]);

    const onListExpand = useCallback(
        (numberOptionsRevealed: number) => {
            setLimit(modifier.options.length);
            if (trackExpand) {
                trackExpand(numberOptionsRevealed, "multi-select");
            }
        },
        [trackExpand, modifier.options.length]
    );

    return !hasMounted ? null : (
        <ExpandableList
            key={`modifier-multi-select-list-${modifierId}${nestedModiferKey}`}
            initialLimit={limit}
            totalItems={modifier.options.length}
            onExpand={onListExpand}
            canHideItems={canHideItems}
            showHiddenCount={true}
            renderItem={(positionIndex: number) => {
                const option = modifier.options[positionIndex];
                const quantity = selectedValues.filter((o) => o === option.originalIndex).length;
                const isGroupUnlimited = !modifier.maxSelection && !modifier.minSelection;

                const hasReachedMinimumSelections = !isGroupUnlimited && selectedValues.length >= modifier.minSelection;
                const optionLimitReached = !isGroupUnlimited && selectedValues.length === modifier.maxSelection;
                const hasReachedALimit = hasReachedMinimumSelections && optionLimitReached;
                const disabled = option.available === false;
                const remainingModifierGroupLimit = modifier.maxSelection
                    ? modifier.maxSelection - selectedValues.length
                    : MODIFIER_OPTION_LIMIT;

                return (
                    <ModifierMultiSelectListItem
                        key={`modifier-${modifierId}-${positionIndex}`}
                        listItem={option}
                        quantity={quantity}
                        remainingModifierGroupLimit={remainingModifierGroupLimit}
                        onListItemIncrement={() => onModifierMultiSelectChanged(modifierId, option.originalIndex, true)}
                        onListItemDecrement={() =>
                            onModifierMultiSelectChanged(modifierId, option.originalIndex, false)
                        }
                        canAddOptions={!hasReachedALimit && !disabled}
                        disabled={disabled || (hasReachedALimit && quantity === 0)}
                        maxSelectionPerOption={modifier.maxSelectionPerOption || 0}
                        selectedNestedModifier={selectedNestedModifier}
                        onNestedModifierEdit={
                            onNestedModifierEdit
                                ? () => onNestedModifierEdit(modifierId, option.originalIndex)
                                : undefined
                        }
                        isUnlimited={isGroupUnlimited}
                    />
                );
            }}
        />
    );
};

export const ModifierMultiSelectList = withListTracking(MultiSelectList);
