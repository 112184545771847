import { HandleExternalPaymentFunc } from "src/features/paymentGateways/types";
import { completeToastPaymentOperation } from "src/features/paymentGateways/pos/toast/operations";
import { ExternalPaymentResult } from "src/features/payment";
import {
    ToastConfirmPaymentErrorEvent,
    ToastConfirmPaymentResultEvent,
} from "src/features/paymentGateways/pos/toast/types";

export const handleToastPayment: HandleExternalPaymentFunc = (dispatch, getState) => {
    return completeToastPaymentOperation.invoke(
        () =>
            new Promise<ExternalPaymentResult>((resolve) => {
                const Toast = (window as any).Toast;

                Toast.fetchUpdates();

                Toast.confirmPayment(
                    (e: ToastConfirmPaymentResultEvent) => {
                        console.log(e);

                        const transactionId = e.content?.payment?.id;

                        if (!transactionId) {
                            const result: ExternalPaymentResult = {
                                error: {
                                    code: "",
                                    reason: "Failed to get transactionId",
                                    isConsumerFriendly: false,
                                },
                            };
                            resolve(result);
                            return;
                        }

                        const result: ExternalPaymentResult = {
                            transactionId,
                        };
                        resolve(result);
                    },
                    (e: ToastConfirmPaymentErrorEvent) => {
                        console.log(e);
                        const result: ExternalPaymentResult = {
                            error: {
                                code: "",
                                reason: e.cause?.message ?? "Failed to confirm payment",
                                isConsumerFriendly: false,
                            },
                        };
                        resolve(result);
                    }
                );
            }),
        dispatch
    );
};
