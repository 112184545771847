import "../assets/CategoryCard.scss";

import * as React from "react";
import { Category } from "../../menudata";
import { MenuItemImage } from "src/features/menuitem";
import { SpecialIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { WaitTimeBadge } from "./WaitTimeBadge";
import classNames from "classnames";

export interface Props {
    category: Category;
    hideProductImages?: boolean;
}

export const CategoryCard = ({ category: { special, waitTime, images, displayName }, hideProductImages }: Props) => (
    <div className={classNames("category-card", special && "category-card--specials")}>
        {!hideProductImages && (
            <div className="img-div">
                <WaitTimeBadge waitTime={waitTime} />
                <MenuItemImage className="catcard__thumb" images={images} name="thumb" />
            </div>
        )}
        <Text preset="g-18" mode="bold" className="text-div">
            {special && <SpecialIcon />}
            {displayName}
        </Text>
        {hideProductImages && <WaitTimeBadge waitTime={waitTime} />}
    </div>
);
