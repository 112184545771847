import * as React from "react";
import { PropsWithChildren } from "react";
import { backHandler } from "src/common/experience";
import { PopStateData } from "src/common/experience/interface";
import { triggerVirtualPageTrack } from "src/common/shared";
import { AppState } from "../../../features";
import { connect } from "react-redux";

export interface NativeBackButtonPropsBase {
    onPressed: null | (() => void);
    name: string;
    preventBack?: boolean;
    disabled?: boolean;
}

export interface NativeBackButtonProps extends PropsWithChildren<NativeBackButtonPropsBase> {}

type BackHandler = {
    back: () => void;
    preventBack: () => boolean;
    data: PopStateData;
};

const handlers: BackHandler[] = [];
let handlerPromise = Promise.resolve();

function popStateHandler() {
    if (!handlers.length) return;

    handlerPromise = new Promise<void>((resolve) => {
        const { preventBack, data, back } = handlers[0];

        if (preventBack()) {
            // Re-push to push state so the next back will be this one again
            backHandler.push(data);
        } else {
            back();
            handlers.splice(0, 1);
        }

        resolve();
    });
}

backHandler.register(popStateHandler);

export function replayHandlers() {
    [...handlers].reverse().forEach(({ data }, i) => backHandler.push(data, i === 0));
}

export const getTopPage = () => handlers[0]?.data.name || window.location.pathname;

export class NativeBackButtonInner extends React.Component<NativeBackButtonProps> {
    data?: PopStateData;
    backHandled = false;

    registerHandler = async () => {
        const { onPressed, name } = this.props;
        if (!onPressed) return;

        await handlerPromise;

        this.data = { name };
        backHandler.push(this.data);
        triggerVirtualPageTrack(this.data);

        handlers.unshift({
            back: this.handleBackButton,
            preventBack: this.preventBack,
            data: this.data,
        });
    };

    unregisterHandler = async () => {
        if (!this.data) return;

        await handlerPromise;

        const index = handlers.findIndex((h) => h.data.name === this.data!.name);

        if (index !== -1) {
            handlers.splice(index, 1);
            replayHandlers();
        }
    };

    async componentDidMount() {
        await this.registerHandler();
    }

    async componentDidUpdate(prevProps: Readonly<NativeBackButtonProps>) {
        const { onPressed, name } = this.props;

        if (prevProps.onPressed && !onPressed) {
            await this.unregisterHandler();
        } else if (!prevProps.onPressed && onPressed) {
            await this.registerHandler();
        }

        if (prevProps.onPressed && onPressed && prevProps.name !== name && this.data) {
            this.data.name = name;
            triggerVirtualPageTrack(this.data);
        }
    }

    async componentWillUnmount() {
        await this.unregisterHandler();
    }

    preventBack = () => !!this.props.preventBack;

    handleBackButton = () => this.props.onPressed && !this.props.disabled && this.props.onPressed();

    render() {
        return this.props.children || null;
    }
}

function mapStateToProps(
    { nativeBackButton: { preventBack } }: AppState,
    props: NativeBackButtonProps
): NativeBackButtonProps {
    return {
        ...props,
        preventBack: props.preventBack ?? preventBack,
    };
}

export const NativeBackButton = connect(mapStateToProps)(NativeBackButtonInner);
