import React, { ReactNode } from "react";
import "./Checkbox.scss";
import { Text } from "../text";
import { CheckboxTickIcon } from "../../assets/icons";
import classNames from "classnames";
import { RequireOnlyOne } from "src/sharedComponents/types/utils";
import { TappableLabel } from "../../common/tappable";

interface PropsBase {
    id: string;
    className?: string;
    onChange?: (checked: boolean) => void;
    label?: ReactNode;
    defaultChecked: boolean;
    disabled?: boolean;
    checked: boolean;
}

export type CheckboxProps = RequireOnlyOne<PropsBase, "checked" | "defaultChecked">;

export const Checkbox = ({ id, className, onChange, label, defaultChecked, disabled, checked }: CheckboxProps) => (
    <div className={classNames("checkbox-element", className)}>
        <input
            type="checkbox"
            defaultChecked={defaultChecked}
            id={id}
            disabled={disabled}
            checked={checked}
            onChange={onChange ? (event) => onChange(event.target.checked) : undefined}
            readOnly={!onChange}
        />
        <TappableLabel htmlFor={id} disabled={disabled}>
            <CheckboxTickIcon />
            {label && <Text preset="g-14">{label}</Text>}
        </TappableLabel>
    </div>
);
