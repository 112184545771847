import { ReactElement, ReactNode } from "react";
import { TextPreset } from "../../../sharedComponents";

type ModalMessageBase = {
    title?: string;
    titlePreset?: TextPreset;
    internal?: boolean;
    showsLegalPage?: boolean;
};

type ModalActionMessageBase = ModalMessageBase & {
    icon?: ReactElement;
    text?: ReactNode;
    primaryAction?: (() => void) | ModalMessage;
    primaryActionText?: string;
    useLocationTheme?: boolean;
    unthemed?: boolean;
    mustAction?: boolean;
    fromServer?: boolean;
    allowsSpinner?: boolean;
    secondaryAction?: (() => void) | ModalMessage;
    secondaryActionText?: string;
};

export type ModalInfoMessage = ModalActionMessageBase & {
    type: "info";
    leftAlign?: boolean;
    importantText?: string;
};

export type ComponentActions = {
    validate: (result: boolean) => void;
};

export type PrimaryComponentActions = ComponentActions & {
    expand: () => void;
    isExpanded?: boolean;
};

type ExpandedOptions = {
    hideTitle?: boolean;
    showClose?: boolean;
    customBack?: string;
};

type PrimaryActionAdditionalProperties = {
    countDownSeconds?: number;
    startCountDown?: boolean;
};

export interface ModalMessagePrimaryButton {
    closeAndAction?: (action?: (() => void) | ModalMessage) => void;
}

export type PrimaryButtonFunc = (
    closeAndAction: (action?: (() => void) | ModalMessage) => void
) => ReactElement<ModalMessagePrimaryButton>;

export type ModalActionMessage = ModalActionMessageBase & {
    type: "action";
    isExpanded?: boolean;
    primaryActionIcon?: ReactElement;
    showCancel?: boolean;
    secondaryActionIcon?: ReactElement;
    secondaryActionExpands?: boolean; // implies buttons will also be swapped around so primary is at the bottom
    actionsReversed?: boolean;
    expandedText?: ReactNode;
    additionalExpandedContent?: ReactNode;
    shouldValidate?: {
        initialValid: boolean;
        validate: boolean;
    };
    primaryComponent?: (primaryComponentActions: PrimaryComponentActions) => ReactNode;
    additionalExpandedComponent?: (additionalComponentActions: ComponentActions) => ReactNode;
    expandedOptions?: ExpandedOptions;
    showNetworkConnectivityIssues?: boolean;
    centerAlignContent?: boolean;
    curvedBorders?: boolean;
    showInlineClose?: boolean;
    primaryActionAdditionalProperties?: PrimaryActionAdditionalProperties;
    confetti?: boolean;
    forceClosable?: boolean;
    primaryButtonDisabled?: boolean;
    primaryButton?: PrimaryButtonFunc;
    onClose?: () => void;
    asActionSheet?: boolean;
};

export type ModalProgressMessage = ModalMessageBase & {
    type: "progress";
};

export type ModalMessage = ModalInfoMessage | ModalActionMessage | ModalProgressMessage;

export interface ModalMessageState {
    modalMessage: ModalMessage | null;
}

export enum TypeKeys {
    SHOW = "MSGBOX/SHOW",
    RESET = "MSGBOX/RESET",
}

export const actionCreators = {
    show: (modalMessage: ModalMessage) => ({ type: TypeKeys.SHOW, modalMessage }),
    reset: () => ({ type: TypeKeys.RESET }),
};

export type ModalMessageShowAction = { type: TypeKeys.SHOW; modalMessage: ModalMessage };
export type ModalMessageResetAction = { type: TypeKeys.RESET };

export type ModalMessageAction = ModalMessageShowAction | ModalMessageResetAction;

export function reducer(state: ModalMessage | null = null, action: ModalMessageAction): ModalMessage | null {
    if (action.type === TypeKeys.SHOW) {
        return action.modalMessage;
    }

    if (action.type === TypeKeys.RESET) {
        return null;
    }

    return state;
}
