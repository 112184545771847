import { AppDispatch, AppState } from "src/features";
import { PaymentShare } from "src/features/payment";
import { actionCreators } from "../reducers";
import { getPayOnlyFullBalance, getPayOnlyRemainingBalance } from "../selectors";
import { evenRound } from "../../../sharedComponents/common/utils";

export const setShare = (share: PaymentShare) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const fullBalance = getPayOnlyFullBalance(state);
        const remainingBalance = getPayOnlyRemainingBalance(state);

        let amount = evenRound((fullBalance / share.split) * share.shares, 2);
        // Try and cater for last share if difference < 10c
        if (remainingBalance - amount < 0.1) {
            amount = remainingBalance;
        }

        dispatch(actionCreators.setShare(amount, share));
    };
};
