import { FeatureConfiguration } from "..";
import { authStateReducer } from "./reducers";
import { authStateMiddleware } from "./middleware/authStateMiddleware";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.authState = authStateReducer;

    config.middleware.push(authStateMiddleware());
}
