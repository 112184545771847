import { connect } from "react-redux";
import { MenuPage, Props } from "../components/MenuPage";
import { getActiveMenu, getActiveMenuCategories, getDietaryTagGroup, getVisibleMenuData } from "../selectors";
import { RouteComponentProps } from "react-router";
import {
    actionCreators,
    getAvailableFilters,
    getFilteredMenuFoodPreferenceCategoryData,
    getMenuItemsForDisplay,
    getMenuLinesForDisplay,
    getSelectedFiltersByCategory,
    SelectedFilters,
} from "../../filter";
import { TagGroup } from "../../menudata";
import {
    actionCreators as partyActions,
    PartyFoodPreferenceState,
    PartyMenuFoodPreferenceFilterData,
} from "../../order/reducers/party";
import { actionCreators as availabilityActions, AvailabilityTrackingAction } from "../../menu/reducers/availability";
import { AppDispatch, AppState } from "../..";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { NavBarType } from "src/common/navigation/types";
import { getStaticBanners } from "../../banners";
import { MenuRouteParams } from "../types";

export interface OwnProps extends RouteComponentProps<MenuRouteParams> {
    menuId: string;
    categoryId: string;
    animatingBetweenTabs: boolean;
    modalToggle: boolean;
    handleChangeTab?: () => void;
    showFooter: boolean;
}

export function mapStateToProps(state: AppState, ownProps: OwnProps): DataProps<Props> {
    const {
        pagePosition: { animatingMenu },
        pendingItem: { item },
        navBar: { navBarStatus },
    } = state;
    const tabParams = {
        ...ownProps.match.params,
        menuId: ownProps.menuId,
        categoryId: ownProps.categoryId,
    } as MenuRouteParams;
    const filteredItems = getMenuItemsForDisplay(state, tabParams);
    const menu = getActiveMenu(state, tabParams);
    const categories = getActiveMenuCategories(state, tabParams);
    const initialCategoryId = ownProps.categoryId;
    const availableFilters = getAvailableFilters(state, tabParams);
    const selectedFilters = getSelectedFiltersByCategory(state, tabParams);

    const menuLines = getMenuLinesForDisplay(state, tabParams);
    const dietaryTagGroup = getDietaryTagGroup(state);
    const filteredMenuFoodPreferenceCategoryData = getFilteredMenuFoodPreferenceCategoryData(state, tabParams);
    const menuData = getVisibleMenuData(state);
    const hasBanner = !!getStaticBanners(state)?.length;

    return {
        filteredItems,
        menu,
        categories,
        menuLines,
        initialCategoryId,
        availableFilters,
        selectedFilters,
        animatingMenu,
        showingItem: !!item,
        isActive: navBarStatus === NavBarType.MENU,
        dietaryTagGroup,
        filteredMenuFoodPreferenceCategoryData,
        menuData,
        tabParams,
        hasBanner,
        ...ownProps,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch, ownProps: OwnProps): FunctionProps<Props> {
    return {
        applyFilters: (categoryId: string, tagGroups: TagGroup[], selectedFilters: SelectedFilters) =>
            dispatch(actionCreators.applyFilters(categoryId, tagGroups, selectedFilters)),
        trackMenuFoodPreferenceApplied: (preferenceName: string, preferenceState: PartyFoodPreferenceState) =>
            dispatch(partyActions.trackMenuFoodPreferenceApplied({ preferenceName, preferenceState })),
        trackMenuFoodPreferenceFiltered: (filteredMenuFoodPreferences: PartyMenuFoodPreferenceFilterData) =>
            dispatch(partyActions.trackMenuFoodPreferenceFiltered({ ...filteredMenuFoodPreferences })),
        trackAvailability: (availabilityData: AvailabilityTrackingAction) =>
            dispatch(availabilityActions.trackAvailability(JSON.stringify(availabilityData))),
        handleChangeTab: ownProps.handleChangeTab,
    };
}

export const MenuPageContainer = connect(mapStateToProps, mapDispatchToProps)(MenuPage);
