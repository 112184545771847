import { authorizeApplePayPaymentOperation } from "../operations";
import { AppDispatch, AppState } from "../../index";
import { applePay } from "src/common/experience";
import { normalizeError } from "src/common/error";
import { ApplePayPaymentMethod } from "src/common/payment";
import { getIsConnected } from "../../order/selectors";
import { modalMessages } from "../../modalMessage/messages";
import { showModalMessage } from "../../modalMessage/actions/show";
import { getPaymentTotal, getSelectedPaymentMethod } from "../selectors";
import { getPaymentSessionToken } from "../util/getPaymentSessionToken";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";
import { CompletePaymentFunc } from "src/features/payment/types";
import { updatePaymentAmount } from "src/features/payment/util/updatePaymentAmount";

export function initializeApplePayButton(
    applePayButton: HTMLDivElement,
    completePayment: CompletePaymentFunc,
    cancelPromise: Promise<void>
) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        applePayButton.addEventListener("click", async (event: MouseEvent) => {
            event.preventDefault();

            const state = getState();
            const connected = getIsConnected(state);

            if (!connected) {
                dispatch(showModalMessage(modalMessages.noNetworkFound()));
                return;
            }

            await authorizeApplePayPaymentOperation.invoke(async () => {
                try {
                    const applePayPaymentMethod = getSelectedPaymentMethod(state) as ApplePayPaymentMethod;
                    const total = getPaymentTotal(state);

                    const actions: InitializeDevicePaymentMethodButtonActions = {
                        getPaymentSessionToken: getPaymentSessionToken(dispatch, getState),
                        updatePaymentAmount: updatePaymentAmount(dispatch, getState),
                        trackEvent: (action) => authorizeApplePayPaymentOperation.trackEvent(action, dispatch),
                        spinnerActions: {
                            start: () => dispatch(authorizeApplePayPaymentOperation.actionCreators.begin()),
                            stop: () => dispatch(authorizeApplePayPaymentOperation.actionCreators.reset()),
                        },
                    };

                    const authorizePayment = await applePay.initializeButton(
                        applePayPaymentMethod,
                        total,
                        actions,
                        cancelPromise
                    );

                    if (typeof authorizePayment !== "function") {
                        throw new Error("Expected authorizePayment to be a function");
                    }

                    const result = await authorizePayment();
                    completePayment(result);
                } catch (err) {
                    completePayment(normalizeError(err));
                    throw err;
                }
            }, dispatch);
        });
    };
}
