export interface PayOnlyFlagsState {
    showFeedback: boolean;
    billPaid: boolean;
    refreshing: boolean;
    displayPendingPayments: boolean;
}

export enum TypeKeys {
    SET_SHOW_FEEDBACK = "PAY_ONLY/SET_SHOW_FEEDBACK",
    SET_BILL_PAID = "PAY_ONLY/SET_BILL_PAID",
    SET_REFRESHING = "PAY_ONLY/SET_REFRESHING",
    SET_DISPLAY_PENDING_PAYMENTS = "PAY_ONLY/SET_DISPLAY_PENDING_PAYMENTS",
}

export const actionCreators = {
    setShowFeedback: (show: boolean) => ({ type: TypeKeys.SET_SHOW_FEEDBACK, show }),
    setBillPaid: () => ({ type: TypeKeys.SET_BILL_PAID }),
    setRefreshing: (refreshing: boolean) => ({ type: TypeKeys.SET_REFRESHING, refreshing }),
    setDisplayPendingPayments: (displayPendingPayments: boolean) => ({
        type: TypeKeys.SET_DISPLAY_PENDING_PAYMENTS,
        displayPendingPayments,
    }),
};

type SetShowFeedbackAction = { type: TypeKeys.SET_SHOW_FEEDBACK; show: boolean };
type SetBillPaidAction = { type: TypeKeys.SET_BILL_PAID };
type SetRefreshingAction = { type: TypeKeys.SET_REFRESHING; refreshing: boolean };
type SetDisplayPendingPaymentsAction = { type: TypeKeys.SET_DISPLAY_PENDING_PAYMENTS; displayPendingPayments: boolean };

export type PayOnlyFlagsAction =
    | SetShowFeedbackAction
    | SetBillPaidAction
    | SetRefreshingAction
    | SetDisplayPendingPaymentsAction;

const initialState: PayOnlyFlagsState = {
    showFeedback: false,
    billPaid: false,
    refreshing: false,
    displayPendingPayments: false,
};

export function reducer(state: PayOnlyFlagsState = initialState, action: PayOnlyFlagsAction): PayOnlyFlagsState {
    if (action.type === TypeKeys.SET_SHOW_FEEDBACK) {
        return {
            ...state,
            showFeedback: action.show,
        };
    }

    if (action.type === TypeKeys.SET_REFRESHING) {
        return {
            ...state,
            refreshing: action.refreshing,
        };
    }

    if (action.type === TypeKeys.SET_DISPLAY_PENDING_PAYMENTS) {
        return {
            ...state,
            displayPendingPayments: action.displayPendingPayments,
        };
    }

    if (action.type === TypeKeys.SET_BILL_PAID) {
        return {
            ...state,
            billPaid: true,
        };
    }

    return state;
}
