import { PosPaymentGatewayBehaviors } from "./types";
import {
    applePayBehavior as toastApplePayBehavior,
    googlePayBehavior as toastGooglePayBehavior,
} from "./toast/behaviors";
import { PosType } from "src/features/paymentGateways/pos/types";
import { PaymentMethod } from "src/common/payment";

const posPaymentGatewayBehaviors: PosPaymentGatewayBehaviors = {
    toast: {
        getApplePayBehavior: () => toastApplePayBehavior,
        getGooglePayBehavior: () => toastGooglePayBehavior,
    },
};

export const getPosApplePayBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getApplePayBehavior?.(paymentMethod);

export const getPosGooglePayBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getGooglePayBehavior?.(paymentMethod);

export const getPosCardVerificationSupported = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getCardVerificationSupported?.(paymentMethod);
