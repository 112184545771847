import { showStripeAddCard } from "./actions/showStripeAddCard";
import { showStripeEnterCvv } from "./actions/showStripeEnterCvv";
import { handleStripePayment } from "./actions/handleStripePayment";
import { AddCardBehavior, PaymentBehavior } from "src/features/paymentGateways/types";

export const addCardBehavior: AddCardBehavior = {
    showAddCard: showStripeAddCard,
    showEnterCvv: showStripeEnterCvv,
};

export const paymentBehavior: PaymentBehavior = {
    handleExternalPayment: handleStripePayment,
};
