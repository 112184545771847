import React, { useEffect, useRef, useState } from "react";
import { Animate } from "../../../sharedComponents";

interface Props {
    showRefreshing: boolean;
    observeClassName?: string;
}

export const PayOnlyPullToRefreshLoader = ({
    showRefreshing,
    observeClassName = "ptr--pull-down-treshold-breached",
}: Props) => {
    const [showLoader, setShowLoader] = useState(false);

    const receiptRef = useRef<Element | null>();
    const showLoaderRef = useRef(showLoader);
    showLoaderRef.current = showLoader;

    useEffect(() => {
        const receipt = document.querySelector(".pay-only-receipt");
        if (!receipt) return;

        receiptRef.current = receipt;

        const thresholdObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    if (receipt.classList.contains(observeClassName)) {
                        setShowLoader(true);
                    } else if (showLoaderRef.current) {
                        // react-simple-pull-to-refresh loses its classes if anything causes
                        // a re-render, so we need to apply the threshold-breached class back
                        // again so the spinner shows when it should
                        receipt.classList.add(observeClassName);
                    }
                }
            }
        });

        thresholdObserver.observe(receipt, {
            attributes: true,
            attributeFilter: ["class"],
        });

        return () => {
            thresholdObserver.disconnect();
        };
    }, [observeClassName]);

    useEffect(() => {
        if (!showRefreshing) {
            setShowLoader(false);
            // Remove the threshold-breached class we forcibly added
            receiptRef.current?.classList.remove(observeClassName);
        }
    }, [showRefreshing, observeClassName]);

    return (
        <div className="pay-only-receipt__pull-to-refresh__loader">
            {showLoader && <Animate name="twoDotSpinner" />}
        </div>
    );
};
