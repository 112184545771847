import { AppDispatch, AppState } from "../..";
import { paymentFlowActions } from "../../payment";
import { getPaymentMethodsAsNeeded } from "../../payment/actions/getPaymentMethods";
import { confirmOrderOperation } from "../operations";
import {
    getCanOpenTabInPaymentFlow,
    getPaymentFlowState,
    getShouldHaveGroupTabPaymentMethod,
} from "src/features/payment/selectors";
import { getSpecificGroupTab } from "src/features/groupTabs/actions/getGroupTabAction";
import { getActiveGroupTabData } from "src/features/groupTabs/selectors/activeGroupTab";
import { getCurrentMemberId, getIsUnverified, getRequireSignIn } from "../../accounts/selectors";
import { getIsSectionTakeaway, getOrderAcceptedMembers, getParty, getShowEngageForUser } from "../selectors";
import { getIsOpenTable } from "../../openTable/selectors";
import { setOrderMemberAccepted } from "./setOrderMemberAccepted";
import { signInAction } from "../../signup/actions/signInAction";
import { actionCreators as paymentActionCreators } from "../../payment/reducers";
import { getIsSecuredPaymentMethodRequired } from "../../securedPayment/selectors";
import { validateActiveOrder } from "./validateActiveOrder";
import { OrderStatus } from "../types";

export function confirmOrder() {
    return confirmOrderOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        let state = getState();
        const isTakeaway = getIsSectionTakeaway(state);

        const requireSignIn = getRequireSignIn(state);
        const isUnverified = getIsUnverified(state);
        if (requireSignIn || (isTakeaway && isUnverified)) {
            dispatch(
                signInAction({
                    useLocationTheme: true,
                    slideAnimation: "slide-from-right",
                    requireVerification: isTakeaway,
                    onComplete: () => {
                        const state = getState();
                        const isEngageEnabledForUser = getShowEngageForUser(state);
                        const party = getParty(state);
                        if (!isEngageEnabledForUser && party?.activeOrder.status !== OrderStatus.VALID) {
                            dispatch(validateActiveOrder());
                        } else {
                            dispatch(confirmOrder());
                        }
                    },
                    getSignInComplete: (state) => !!getPaymentFlowState(state).page,
                })
            );
            return;
        }

        const isOpenTable = getIsOpenTable(state);

        if (isOpenTable) {
            const currentMemberId = getCurrentMemberId(state);
            const acceptedMembers = getOrderAcceptedMembers(state);
            const shouldSetMemberAccepted = !acceptedMembers?.includes(currentMemberId);
            const partyRequiresSecuredPayment = getIsSecuredPaymentMethodRequired(state);

            if (shouldSetMemberAccepted) {
                if (partyRequiresSecuredPayment) {
                    await getPaymentMethodsAsNeeded(dispatch, getState);
                    dispatch(paymentActionCreators.showSecuredPaymentModal(true));
                } else {
                    dispatch(setOrderMemberAccepted(true));
                }
            }
            return;
        }

        const selectedPaymentMethod = await getPaymentMethodsAsNeeded(dispatch, getState);
        // state is updated by fetching payment methods so will be stale
        state = getState();

        const shouldHaveGroupTabPaymentMethod = getShouldHaveGroupTabPaymentMethod(state);
        const canOpenTabInPaymentFlow = getCanOpenTabInPaymentFlow(state);
        const showSelectPaymentMethod =
            !selectedPaymentMethod || shouldHaveGroupTabPaymentMethod || canOpenTabInPaymentFlow;

        if (shouldHaveGroupTabPaymentMethod) {
            const activeGroupTab = getActiveGroupTabData(state);
            if (activeGroupTab?.id) {
                dispatch(getSpecificGroupTab(activeGroupTab.id));
            }
        }

        dispatch(paymentFlowActions.startPaymentFlow(showSelectPaymentMethod ? "SelectPaymentMethod" : "ReviewOrder"));
    });
}
