import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "src/sharedComponents";
import { getLimitedMenuPackages } from "../../menu/selectors";
import { LimitedMenuPackageItem } from "./LimitedMenuPackageItem";
import { actionCreators } from "../reducers/groupTabWizard";

import "./GroupTabLimitedMenuPackages.scss";

interface Props {
    onSuccess: () => void;
}

export const GroupTabLimitedMenuPackages = ({ onSuccess }: Props) => {
    const limitedMenuPackages = useSelector(getLimitedMenuPackages);
    const dispatch = useDispatch();
    const setActivePackage = (packageId: string) => {
        dispatch(actionCreators.setGroupTabPackage(packageId));
        onSuccess();
    };

    return (
        <div className="group-tab-packages">
            <main className="scroll-element animated-child">
                <div className="group-tab-wizard__title profile-page__title">
                    <Text preset="title-28" mode="bold">
                        Packages
                    </Text>
                    <Text preset="g-14" mode="block" className="group-tab-wizard__description">
                        Select a preset package created by the venue.
                    </Text>
                </div>
                <div className="group-tab-packages__list">
                    {limitedMenuPackages?.map((activePackage) => (
                        <LimitedMenuPackageItem
                            key={activePackage.id}
                            item={activePackage}
                            onClick={setActivePackage}
                        />
                    ))}
                </div>
            </main>
        </div>
    );
};
