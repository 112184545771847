import { joinParty } from "../api/joinParty";

import { AppDispatch, AppState } from "../..";
import { joiningFlexTabPartyOperation, joiningTableOperation } from "../operations";
import { deleteTableToken, saveTableToken } from "../persistence/tableToken";
import { easyReorderActions } from "../../easyReorderItem/reducers";
import { profileActionCreators } from "../../accountmenu";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getRestaurantFlagsOperation } from "../../order/operations";
import { timeoutAndError, waitMinimum } from "src/common/shared";
import { resetOrderingState } from "../../order/actions/resetOrderingState";
import { setInitialAnotherRound } from "src/features/orderAgain/actions/setAnotherRound";
import { config } from "src/common/config";
import { updateMenuAdUnits } from "src/features/menudata/actions/updateMenuAdUnits";
import { getGroupTabAction } from "src/features/groupTabs/actions/getGroupTabAction";
import { getABTests } from "../../abtests/actions/getABTests";
import { closeAllModals, INITIAL_WAIT_PARTY_TIME, MIN_JOIN_LOADING } from "./helper";
import { onboardingActionCreators } from "../reducers";
import { downloadLoaderImages, downloadMenuBackgroundImage } from "./downloadOnboardingImages";
import { actionCreators as takeawayOptionActions } from "src/features/takeaway/reducers/takeawayOptions";
import { LocationMarkerClaim, PartyError } from "../types";
import { disconnectParty, getPartyToken } from "../api";
import { setTestingFeaturesIfEnabled } from "./setTestingFeatures";
import { getAvailableOffersAction } from "src/features/offers/actions/getAvailableOffers";
import { OpenTableContext } from "src/features/order";
import { actionCreators as openTableActionCreators } from "../../openTable/reducers";
import { href, isWeb } from "src/common/experience";
import { getIsPayOnly } from "src/features/payOnly/selectors";

export function joinTable(locationMarkerClaims: LocationMarkerClaim[], existingToken?: string, startFlexTab?: boolean) {
    const operation = startFlexTab ? joiningFlexTabPartyOperation : joiningTableOperation;
    return operation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(resetOrderingState());
            dispatch(closeAllModals());
            dispatch(onboardingActionCreators.clearOnboardingImages());
            await disconnectParty(dispatch, false, "none");

            const timeStart = performance.now();
            const {
                access_token: tableToken,
                easyReorderItems,
                anotherRoundItems,
                profile,
                restaurantFlags,
                backgroundImage,
                logoUrl,
                menuBackgroundImage,
                takeawayContext,
                openTableContext,
                externalRedirectUrl,
                attemptedToJoinAnotherTable,
            } = await Promise.race([
                getPartyToken(locationMarkerClaims, existingToken, startFlexTab),
                timeoutAndError(INITIAL_WAIT_PARTY_TIME, new Error("Timeout")),
            ]);

            if (externalRedirectUrl) {
                redirectToExternalUrl(externalRedirectUrl);

                // We don't want to revert to the 'join' page before the migrated url has been displayed
                await timeoutAndError(INITIAL_WAIT_PARTY_TIME, new PartyError("Cancelled"));
            }

            const timeEnd = performance.now();
            const timeoutLeft = INITIAL_WAIT_PARTY_TIME - (timeEnd - timeStart);
            const hasFlexTabBehavior = openTableContext && (openTableContext as OpenTableContext).hasFlexTabBehavior;
            const joiningFlexTab = locationMarkerClaims.some((c) => c.type === "party");
            const cannotJoinFlexTabParty = hasFlexTabBehavior && !startFlexTab && !joiningFlexTab && !tableToken;

            dispatch(setTestingFeaturesIfEnabled());
            dispatch(easyReorderActions.getEasyReorderItem(easyReorderItems));
            dispatch(profileActionCreators.fetchSuccess(profile));
            dispatch(getRestaurantFlagsOperation.actionCreators.completed(restaurantFlags));
            dispatch(takeawayOptionActions.setTakeawayDetails(takeawayContext));
            dispatch(openTableActionCreators.setHasFlexTabBehavior(hasFlexTabBehavior));

            dispatch(downloadLoaderImages(backgroundImage, logoUrl));
            dispatch(downloadMenuBackgroundImage(menuBackgroundImage));

            if (cannotJoinFlexTabParty) {
                deleteTableToken("no_flex_payment_order");
                dispatch(openTableActionCreators.setLocationMarkerClaims(locationMarkerClaims));
                return;
            }

            saveTableToken(tableToken);
            dispatch(getABTests());

            await waitMinimum(
                joinParty(getState, dispatch, tableToken, openTableContext, timeoutLeft),
                MIN_JOIN_LOADING
            );

            if (getIsPayOnly(getState())) return;

            dispatch(openTableActionCreators.resetLocationMarkerClaims());
            dispatch(getAvailableOffersAction());
            dispatch(setInitialAnotherRound(anotherRoundItems));
            dispatch(getGroupTabAction(undefined, !!openTableContext));

            if (joiningFlexTab) {
                const joinFlexLocationMarkerClaim = locationMarkerClaims.find((lmc) => lmc.type === "party");
                if (joinFlexLocationMarkerClaim) {
                    const uri = new URL(joinFlexLocationMarkerClaim.token);
                    dispatch(
                        openTableActionCreators.trackJoinFlexTab(
                            uri.pathname.split("/")[2],
                            uri.searchParams.get("from") === "qr"
                        )
                    );
                }
            }
            if (attemptedToJoinAnotherTable) {
                dispatch(showModalMessage(modalMessages.attemptedToJoinAnotherTable()));
            }
            if (config.enableAdUnits === "1") {
                dispatch(updateMenuAdUnits());
            }
        } catch (e) {
            if (!e.message) {
                dispatch(showModalMessage(modalMessages.unableToJoinThisTable()));
                throw new Error("Failed to fetch table: no error message");
            }
            throw e;
        }
    });
}

function redirectToExternalUrl(url: string) {
    if (isWeb) {
        window.location.href = url;
    } else {
        // For the app, open in an in-app browser because
        // a) opening an external site in the cordova context would give it access to execute native plugins
        // b) We might run afoul of Apple
        window.location.href = href.openInNewWindowAttributes(url).href!;
    }
}
