import { ApplePayPaymentMethod, AuthorizePaymentResult, paymentOptions } from "src/common/payment";
import { getBraintreeClientInstance } from "src/common/braintree";
import { cordovaWindow } from "../../cordova";
import { InitializeDevicePaymentMethodButtonFunc } from "../../../interface";

export const initializeBraintreeApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = async (
    _,
    total,
    { getPaymentSessionToken }
) => {
    const token = await getPaymentSessionToken();

    return () =>
        new Promise<AuthorizePaymentResult | null>((resolve, reject) =>
            cordovaWindow.BraintreeDevicePayments.authorizeApplePayPayment(
                total.toFixed(2),
                paymentOptions.label,
                token,
                async (result) => {
                    if (result) {
                        const { deviceData } = await getBraintreeClientInstance(token);
                        result.additionalFraudProtectionData = deviceData;
                    }
                    resolve(result);
                },
                reject
            )
        );
};
