import React from "react";
import { CSSTransition } from "react-transition-group";
import { NativeBackButton } from "src/common/navigation";
import { StatusBar } from "src/common/statusBar";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { Spinner } from "../../Spinner";
import "../assets/JoinTablePage.scss";
import { FakeBeacon, getFakeBeacon, saveFakeBeacon } from "../persistence/fakeBeacon";
import { Connecting } from "./joinTable/Connecting";
import { Initializing } from "./joinTable/Initializing";
import classNames from "classnames";
import { asset, prompt, qr, splashScreen } from "src/common/experience";
import { NoConnectionShield } from "./NoConnectionShield";
import { BarcodeScanView, Button } from "src/sharedComponents";
import { Camera } from "src/sharedComponents/assets/icons";
import { ModalMessage } from "../../modalMessage/reducers/modalMessage";
import { modalMessages } from "../../modalMessage/messages";
import { MemberActivity, Party, PartyType } from "../../order";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { HomePage } from "./HomePage";
import { GetOrCreateTableOrderFailureAdditionalData, OnboardingLoadingImages, PartyError } from "../types";
import { isBrandedVenueLoaderEnabled } from "../util/isBrandedVenueLoaderEnabled";
import { Lobby } from "../../openTable/components/Lobby";
import { getMemberActivityInfo } from "../persistence/memberActivity";
import { StartOrJoinFlexTab } from "src/features/openTable/components/FlexTab/StartOrJoinFlexTab";

export type JoinTableStep =
    | "Initializing"
    | "TouchToStart"
    | "Joining"
    | "Rejoining"
    | "Viewing"
    | "Deciding"
    | "Paused"
    | "Congrats"
    | "FindingQrCode"
    | "Failed";

export interface Props {
    step: JoinTableStep;
    resetOperations: () => void;
    fakeJoinTable?: (locationSlug: string | null, table: string | null) => void;
    locationLoading: boolean;
    startOrdering: () => void;
    updateMealHistory: () => void;
    partyError?: PartyError;
    isReady: boolean;
    fetchProfile: () => void;
    clearActiveMenuData: () => void;
    showModalMessage: (modalMessage: ModalMessage) => void;
    networkAvailable: boolean;
    getLatestTermsUpdate: () => void;
    backgroundImage?: string;
    logoUrl?: string;
    findQrCode: (cancelPromise: Promise<void>) => void;
    cameraAccessDenied?: boolean;
    cameraReady?: boolean;
    party: Party | null;
    scanError?: boolean;
    showJoinTabMessage: boolean;
    resetJoinTabCode: () => void;
    updateGroupTabs: () => void;
    tabTypeName: string;
    loaderImages: OnboardingLoadingImages | null;
    memberActivity: MemberActivity;
    goToActivityPage: (activity: MemberActivity) => void;
    isOpenTableOrderingDisabled: boolean;
    hasFetchedProfile: boolean;
}

export interface State {
    fakeBeacon?: FakeBeacon;
    focusOnOrder: boolean;
    backgroundImage?: string;
    logoUrl?: string;
    loaderImages: OnboardingLoadingImages | null;
}

let cancel: () => void = () => {};

export const getCurrentMemberActivity = (memberActivity: MemberActivity, partyId: string) => {
    if (memberActivity === MemberActivity.None) {
        const memberActivityInfo = getMemberActivityInfo();
        if (memberActivityInfo && memberActivityInfo.partyId === partyId) {
            return memberActivityInfo.memberActivity;
        }

        return MemberActivity.Ordering;
    }
    return memberActivity;
};

export class JoinTablePage extends React.Component<Props, State> {
    buttonPressTimer: number = 0;
    msgTimer: number = 0;
    oncontextmenu: any;

    constructor(props: Props) {
        super(props);

        const {
            step,
            resetOperations,
            updateMealHistory,
            fetchProfile,
            clearActiveMenuData,
            getLatestTermsUpdate,
            backgroundImage,
            logoUrl,
            loaderImages,
        } = props;

        if (step === "Initializing" || step === "TouchToStart") {
            clearActiveMenuData();
            fetchProfile();
            resetOperations();
            getLatestTermsUpdate();
            updateMealHistory();
        } else {
            getLatestTermsUpdate();
        }

        this.state = {
            focusOnOrder: false,
            backgroundImage,
            logoUrl,
            loaderImages,
        };

        this.oncontextmenu = window.oncontextmenu;
        window.history.pushState({ back: false }, "", null);
    }

    triggerJoiningATab = () => {
        const { showModalMessage, findQrCode, tabTypeName } = this.props;
        showModalMessage(
            modalMessages.joiningGroupTab(() => {
                findQrCode(new Promise((resolve) => (cancel = resolve)));
            }, tabTypeName)
        );
    };

    findQrCode = () => {
        if (this.state.fakeBeacon) return;
        const { findQrCode, cameraAccessDenied, showModalMessage } = this.props;
        if (cameraAccessDenied) {
            qr.getCameraAccessDeniedMessage(BarcodeType.QRCODE).then((message) =>
                showModalMessage(modalMessages.showQrCodeCameraAccessDenied(message))
            );
            return;
        }
        findQrCode(new Promise((resolve) => (cancel = resolve)));
    };

    cancelAndResetOperations = () => {
        const { resetOperations, resetJoinTabCode } = this.props;
        cancel();
        resetOperations();
        resetJoinTabCode();
    };

    onLongPress = async () => {
        const { fakeJoinTable } = this.props;

        if (fakeJoinTable) {
            const fakeBeacon = getFakeBeacon() || { locationSlug: "handlwpp", table: "50" };

            this.setState({ fakeBeacon });

            await this.onLocationSlugPrompt(await prompt("Enter location slug", "Join table", fakeBeacon.locationSlug));
        }
    };

    onLocationSlugPrompt = async (locationSlug: string | null) => {
        const { fakeBeacon } = this.state;

        if (locationSlug) {
            this.setState({ fakeBeacon: { ...fakeBeacon!, locationSlug } });
            this.onTablePrompt(await prompt("Enter table", "Join table", fakeBeacon!.table));
        }
    };

    onTablePrompt = (table: string | null) => {
        const { fakeJoinTable } = this.props;
        const { locationSlug } = this.state.fakeBeacon!;

        if (table) {
            saveFakeBeacon({ locationSlug, table });
            fakeJoinTable!(locationSlug, table);
        }
    };

    showModalMessage = (modalMessage: ModalMessage) => {
        const { showModalMessage } = this.props;
        this.msgTimer = window.setTimeout(() => showModalMessage(modalMessage), 300);
    };

    onTouchStart = () => {
        window.oncontextmenu = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        this.setState({ fakeBeacon: undefined });

        this.buttonPressTimer = window.setTimeout(async () => {
            window.oncontextmenu = this.oncontextmenu;
            await this.onLongPress();
        }, 1000);
    };

    onTouchEnd = () => {
        clearTimeout(this.buttonPressTimer);
        window.oncontextmenu = this.oncontextmenu;
    };

    onBack = () => {
        const { step } = this.props;

        switch (step) {
            case "TouchToStart":
                (navigator as any).app && (navigator as any).app.exitApp();
                break;
            case "FindingQrCode":
                this.cancelAndResetOperations();
                break;
        }
    };

    componentDidMount() {
        const { step, updateGroupTabs, party } = this.props;
        if (step !== "Initializing") {
            splashScreen.hide();
        }
        if (step === "TouchToStart" && !party) {
            updateGroupTabs();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            step,
            isReady,
            partyError,
            updateGroupTabs,
            party,
            loaderImages,
            memberActivity,
            goToActivityPage,
            startOrdering,
            isOpenTableOrderingDisabled,
            hasFetchedProfile,
        } = this.props;

        if (prevProps.step !== "Congrats" && step === "Congrats") {
            if (party?.type === PartyType.MULTIUSER) {
                const currentMemberActivity = isOpenTableOrderingDisabled
                    ? MemberActivity.Paying
                    : getCurrentMemberActivity(memberActivity, party.id);

                goToActivityPage(currentMemberActivity);
            } else {
                startOrdering();
            }
        }

        if (prevProps.step === "Initializing" && step !== "Initializing") {
            splashScreen.hide();
        }

        if (!prevProps.showJoinTabMessage && this.props.showJoinTabMessage) {
            this.triggerJoiningATab();
        }

        if (
            step === "TouchToStart" &&
            ((prevProps.step === "Initializing" && hasFetchedProfile) ||
                (prevProps.party && !party) ||
                (!prevProps.hasFetchedProfile && hasFetchedProfile))
        ) {
            updateGroupTabs();
        }

        if (prevProps.isReady !== isReady) {
            this.setState({ focusOnOrder: isReady });
        }

        if (partyError && prevProps.partyError !== partyError) {
            this.handleErrorMessage();
        }

        if (isBrandedVenueLoaderEnabled() && prevProps.loaderImages !== loaderImages) {
            this.setState({
                loaderImages,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.msgTimer);
    }

    dismissShield = () => {
        this.setState({ focusOnOrder: false });
    };

    contactUs = () => {
        window.open("mailto:helpme@meandu.com.au", "_system");
    };

    handleErrorMessage = () => {
        const { partyError, resetOperations, showModalMessage } = this.props;

        resetOperations();

        switch (partyError?.errorCode) {
            case "InvalidSource":
            case "GuarantorNotFound":
                this.showModalMessage(modalMessages.tableNotSetUp());
                break;
            case "AccountInArrears":
                this.showModalMessage(modalMessages.accountInArrears(this.contactUs));
                break;
            case "OutsideServiceHours":
                if (partyError.additionalData === "service") {
                    this.showModalMessage(modalMessages.outsideServiceHours(partyError.venueName));
                } else {
                    // Covers 'section closed' as well as the hypothetical 'table closed' and 'venue closed'
                    this.showModalMessage(modalMessages.tableOrSectionDisabled(partyError.venueName));
                }
                break;
            case "VenueNotFound":
                this.showModalMessage(modalMessages.venueNotFound());
                break;
            case "UnsupportedApiVersion":
                this.showModalMessage(modalMessages.unsupportedApiVersion());
                break;
            case "Timeout":
                this.showModalMessage(modalMessages.noNetworkFound());
                break;
            case "FindQrCodeTimeout":
                this.showModalMessage(modalMessages.findQrCodeTimeout());
                break;
            case "QrCodeUnknownError":
                this.showModalMessage(modalMessages.showQrCodeUnknownError());
                break;
            case "QrCodeCameraAccessDenied":
                qr.getCameraAccessDeniedMessage(BarcodeType.QRCODE).then((message) =>
                    showModalMessage(modalMessages.showQrCodeCameraAccessDenied(message))
                );
                break;
            case "TakeawayUnavailable":
                this.showModalMessage(modalMessages.takeawayUnavailable(partyError.venueName));
                break;
            case "GetOrCreateTableOrderFailure":
                try {
                    const { preOrderingModalTitle, preOrderingModalText, backgroundImage } = JSON.parse(
                        partyError.additionalData!
                    ) as GetOrCreateTableOrderFailureAdditionalData;
                    if (backgroundImage) {
                        this.setState({ backgroundImage });
                    }
                    this.showModalMessage(
                        modalMessages.getOrCreateTableOrderFailure(preOrderingModalTitle, preOrderingModalText)
                    );
                } catch {
                    this.showModalMessage(modalMessages.getOrCreateTableOrderFailure());
                }
                break;
            case "Cancelled":
                break;
            case "InvalidFlexJoinCodeException":
                this.showModalMessage(modalMessages.unableToJoinFlexTab());
                break;
            default:
                this.showModalMessage(modalMessages.unableToJoinThisTable());
                break;
        }
    };

    render() {
        const { step, locationLoading, networkAvailable, cameraReady, scanError } = this.props;
        const { focusOnOrder, backgroundImage, logoUrl, loaderImages } = this.state;

        const stepSupportImage = step === "TouchToStart" || (step === "FindingQrCode" && !cameraReady);
        const withImage = !!backgroundImage && stepSupportImage;
        const style = withImage
            ? ({ "--background-image": `url(${asset(backgroundImage!)})` } as React.CSSProperties)
            : undefined;

        if (locationLoading) return <Spinner />;

        return (
            <NativeBackButton name={"join-table-" + step} onPressed={step === "FindingQrCode" ? this.onBack : null}>
                <StatusBar backgroundColor={withImage ? "#000" : "#095d44"} />
                {step === "FindingQrCode" && !cameraReady && <Spinner />}
                {qr.requiresTargetElement && <div id="reader" />}
                <div
                    className={classNames(
                        `join-table ${step}`,
                        withImage && "with-hero",
                        !cameraReady && "camera-not-ready",
                        isBrandedVenueLoaderEnabled() && loaderImages && "dark-background",
                        !backgroundImage && "home-page-business-background"
                    )}
                    style={style}
                >
                    <div className="item join-table__top">
                        <CSSTransition
                            in={step === "TouchToStart" || (step === "FindingQrCode" && !cameraReady)}
                            classNames="fade"
                            timeout={300}
                            unmountOnExit
                        >
                            <HomePage beforeMenuNavigate={this.cancelAndResetOperations} />
                        </CSSTransition>
                        <CSSTransition
                            in={step === "FindingQrCode" && cameraReady}
                            classNames="fade"
                            timeout={300}
                            unmountOnExit
                        >
                            <BarcodeScanView
                                title="Scan the QR code."
                                description="Hold your camera over the me&u QR code."
                                barcodeType={BarcodeType.QRCODE}
                                scanError={scanError}
                            />
                        </CSSTransition>
                        <CSSTransition
                            in={step === "Joining" || step === "Rejoining" || step === "Paused" || step === "Congrats"}
                            classNames="fade"
                            timeout={300}
                            unmountOnExit
                        >
                            <Connecting logoUrl={logoUrl} paused={step === "Paused"} loaderImages={loaderImages} />
                        </CSSTransition>
                        <CSSTransition in={step === "Initializing"} classNames="fade" timeout={300} unmountOnExit>
                            <Initializing loaderImages={loaderImages} />
                        </CSSTransition>
                    </div>
                    <div className="join-table__bottom">
                        <CSSTransition
                            in={step === "TouchToStart" || (step === "FindingQrCode" && !cameraReady)}
                            classNames="fade"
                            timeout={300}
                            unmountOnExit
                        >
                            <div>
                                <Button
                                    className="join-table__bottom__button"
                                    leftIcon={<Camera />}
                                    mode="solidinverted"
                                    onClick={this.findQrCode}
                                    onTouchStart={this.onTouchStart}
                                    onTouchEnd={this.onTouchEnd}
                                    value="Scan QR code"
                                />
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={step === "FindingQrCode" && cameraReady}
                            classNames="fade-buttons"
                            timeout={300}
                            unmountOnExit
                        >
                            <div>
                                <Button
                                    className="join-table__bottom__button"
                                    mode="outline"
                                    secondary
                                    onClick={this.cancelAndResetOperations}
                                    value="Cancel"
                                />
                            </div>
                        </CSSTransition>
                        <CSSTransition in={step === "Viewing"} classNames="fade" timeout={300} unmountOnExit>
                            <Lobby />
                        </CSSTransition>
                        <CSSTransition in={step === "Deciding"} classNames="fade" timeout={300} unmountOnExit>
                            <StartOrJoinFlexTab />
                        </CSSTransition>
                    </div>
                    {focusOnOrder && <TappableDiv className="shield" onTap={this.dismissShield} />}
                </div>
                <NoConnectionShield visible={!networkAvailable} />
            </NativeBackButton>
        );
    }
}
