import React from "react";
import { useSelector } from "react-redux";
import { getHostedFieldsValidity } from "../../../paymentMethods/selectors/hostedFields";
import { HostedField, hostedFieldOptions } from "../../../paymentMethods/types";
import { AddCardFormInner, AddCardFormProps } from "../../../paymentMethods/components/AddCardForm";
import { DateSeparator } from "../../../paymentMethods/components/DateSeparator";

export const TyroAddCardForm = (props: AddCardFormProps) => {
    const { expiryEmpty } = useSelector(getHostedFieldsValidity);

    return (
        <AddCardFormInner
            cardNumberElement={
                <input
                    type="text"
                    id={HostedField.Number}
                    className="payment-method__add-card__tyro-input-field"
                    title="card number"
                    aria-label="enter your card number"
                    value=""
                    tabIndex={1}
                    readOnly
                    placeholder={hostedFieldOptions.placeholders.cardNumber}
                />
            }
            expiryElement={
                <>
                    <div className={HostedField.ExpiryMonth}>
                        <input
                            type="text"
                            id={HostedField.ExpiryMonth}
                            className="payment-method__add-card__tyro-input-field"
                            title="expiry month"
                            aria-label="two digit expiry month"
                            value=""
                            tabIndex={2}
                            readOnly
                            placeholder={hostedFieldOptions.placeholders.expiryMonth}
                        />
                    </div>
                    <DateSeparator className={expiryEmpty ? "empty" : undefined} />
                    <div className={HostedField.ExpiryYear}>
                        <input
                            type="text"
                            id={HostedField.ExpiryYear}
                            className="payment-method__add-card__tyro-input-field"
                            title="expiry year"
                            aria-label="two digit expiry year"
                            value=""
                            tabIndex={3}
                            readOnly
                            placeholder={hostedFieldOptions.placeholders.expiryYear}
                        />
                    </div>
                </>
            }
            cvvElement={
                <input
                    type="text"
                    id={HostedField.CVV}
                    className="payment-method__add-card__tyro-input-field"
                    title="security code"
                    aria-label="three digit CCV security code"
                    value=""
                    tabIndex={4}
                    readOnly
                    placeholder={hostedFieldOptions.placeholders.cvv}
                />
            }
            {...props}
        />
    );
};
