import { actionCreators } from "../reducers/marketing";
import { AppDispatch, AppState } from "../../index";
import {
    getGroupMarketingMetadata,
    getLocationMarketingMetadata,
    getMarketingMetadata,
    logMarketingOptIn,
} from "../api";
import { marketingMetaDataOperation } from "..";
import { OptInData } from "../reducers/types";

export function marketingMetaData(locationId: string) {
    return marketingMetaDataOperation.getThunk(async (dispatch) => {
        const response = await getMarketingMetadata(locationId);
        dispatch(actionCreators.marketingMetadata(response));
    });
}

export function showMarketingPage(optInData: OptInData) {
    return async (dispatch: AppDispatch) => {
        await logMarketingOptIn(optInData.locationId!, optInData.historyOpen);
        dispatch(actionCreators.promptMarketing(optInData));
    };
}

export function resetMarketing() {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreators.resetMarketing());
    };
}

export const getLocationMarketingInfo = (locationSlug: string) => {
    return marketingMetaDataOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const response = await getLocationMarketingMetadata(locationSlug);
        dispatch(actionCreators.marketingMetadata(response));
    });
};
export const getGroupMarketingInfo = (groupId: string) => {
    return marketingMetaDataOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const response = await getGroupMarketingMetadata(groupId);
        dispatch(actionCreators.marketingMetadata(response));
    });
};
