import { addCardOperation } from "../operations";
import { AddedCardResult } from "../../payment";
import { addPaymentMethod, CardRejectedError } from "../api/addPaymentMethod";

export function addCard(addedCardResult: AddedCardResult, success: () => void, failure?: (rejected: boolean) => void) {
    return addCardOperation.getThunk(async () => {
        try {
            await addPaymentMethod(addedCardResult);
            success();
        } catch (err) {
            failure && failure(err instanceof CardRejectedError);
            throw err;
        }
    });
}
