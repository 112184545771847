import "../assets/ApplePayButton.scss";

import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializeApplePayButton } from "../actions/initializeApplePayButton";
import { CompletePaymentFunc, PaymentButtonProps } from "../types";
import { getSelectedPaymentMethod } from "../selectors";
import { applePay } from "src/common/experience";
import { ApplePayPaymentMethod } from "src/common/payment";
import { useUnmountCancelPromise } from "src/sharedComponents/common/shared";

export const ApplePayButton = ({ onPayment }: PaymentButtonProps) => {
    const dispatch = useDispatch();
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod) as ApplePayPaymentMethod;

    const buttonRef = useRef<HTMLDivElement>(null);
    const onPaymentInternal = useRef(onPayment);

    const cancelPromise = useUnmountCancelPromise();

    useEffect(() => {
        onPaymentInternal.current = onPayment;
    }, [onPayment]);

    useEffect(() => {
        const completePayment: CompletePaymentFunc = (result) => {
            if (!result) return;
            dispatch(onPaymentInternal.current(result, () => applePay.completePayment(selectedPaymentMethod)));
        };
        dispatch(initializeApplePayButton(buttonRef.current!, completePayment, cancelPromise));
    }, [dispatch, selectedPaymentMethod, cancelPromise]);

    return (
        <div className="apple-pay-button-container">
            <div className="apple-pay-button apple-pay-button-black" ref={buttonRef} />
        </div>
    );
};
