import { createSelector } from "reselect";
import { getMenuData } from "src/features/menu/selectors";
import { MenuDataLocale } from "../types/MenuDataLocale";

export const getMenuDataLocale = createSelector(getMenuData, (menuData) => {
    if (menuData == null) {
        return null;
    }

    return MenuDataLocale.fromMenuData(menuData);
});
