import React from "react";
import classNames from "classnames";
import { Text } from "../text";
import { RadioListItem, RadioListItemProps } from "./radioListItem";

import "./radioList.scss";

export interface RadioListProps {
    errorMessage?: string;
    onChange: (value: any) => void;
    items: RadioListItemProps[];
}

export const RadioList = ({ errorMessage, items, onChange }: RadioListProps) => (
    <div className="radio-list">
        <ol className={classNames("radio-list__items", !!errorMessage && "in-error")}>
            {items.map((item) => (
                <RadioListItem {...item} key={item.value} onChange={onChange} />
            ))}
        </ol>
        {errorMessage && <Text preset="g-14" className="radio-list__error-message" value={errorMessage} />}
    </div>
);
