import "./GroupTabLimit.scss";

import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { Chip, FormControl, MaskedInput, masks, Text } from "src/sharedComponents";
import { actionCreators as wizardActionCreators } from "../reducers/groupTabWizard";
import { getWizardGroupTabsLimit } from "../selectors/groupTabWizardSelectors";
import { validationRules } from "src/common/validation";
import { Tag } from "src/features/menudata";
import { createGroupTabAction } from "../actions/createGroupTabAction";
import { increaseGroupTabAction } from "../actions/increaseGroupTabAction";
import { PaymentButton } from "../../payment/components/PaymentButton";
import { createGroupTabOperation, increaseGroupTabLimitOperation, updateTabLimitOperation } from "../operations";
import { TabGratuityDescription } from "./TabGratuityDescription";
import { capitaliseFirstLetter } from "src/common/formatter";
import {
    getTabLimit,
    getTabSpend,
    getTabType,
    getTabTypeName,
    getUpdateLimitErrorDetails,
} from "../selectors/activeGroupTab";
import { TabPaymentSelector } from "./TabPaymentSelector";
import { TabType } from "..";
import { updateTabLimitAction } from "../actions/updateTabLimitAction";
import { usePrevious } from "src/sharedComponents/common/shared";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";

interface Props {
    increasingGroupTab?: boolean;
    changePaymentMethod: () => void;
}

let increaseTags: Tag[] = [
    {
        id: "50",
        displayName: "50",
        value: 50,
    },
    {
        id: "100",
        displayName: "100",
        value: 100,
    },
    {
        id: "200",
        displayName: "200",
        value: 200,
    },
    {
        id: "500",
        displayName: "500",
        value: 500,
    },
];

const MAX_LIMIT = 99999;
const MIN_INCREASE = 50;

export const GroupTabLimit = ({ increasingGroupTab, changePaymentMethod }: Props) => {
    const tabLimit = useSelector(getWizardGroupTabsLimit);
    const tabTypeName = useSelector(getTabTypeName);
    const activeTabType = useSelector(getTabType);
    const activeTabLimit = useSelector(getTabLimit);
    const activeTabSpend = useSelector(getTabSpend);
    const updateTabLimitError = useSelector(getUpdateLimitErrorDetails);
    const isPayOnOrderTab = activeTabType === TabType.PAYONORDER;

    const prevTabLimit = usePrevious(tabLimit);

    const { formState, errors, setValue, control, watch } = useForm({
        mode: "onChange",
        defaultValues: {
            tabLimit: isPayOnOrderTab ? activeTabLimit : "",
        },
    });

    const checkIsValid = useRef(!formState.isValid);

    const watchLimit = watch("tabLimit");

    const dispatch = useDispatch();
    const menuDataLocale = useContext(MenuDataLocaleContext)!;
    const { thousandsSeparatorSymbol, currencySymbol } = useMemo(
        () => menuDataLocale.getCurrencySymbols(),
        [menuDataLocale]
    );
    const maxValueString = useMemo(() => menuDataLocale.formatCurrencyNoDigits(MAX_LIMIT), [menuDataLocale]);

    const minIncrease = useMemo(
        () => (isPayOnOrderTab ? Math.ceil(activeTabSpend + MIN_INCREASE) - 0.01 : MIN_INCREASE),
        [isPayOnOrderTab, activeTabSpend]
    );

    const title = useMemo(() => {
        if (isPayOnOrderTab) {
            return "Set a spend limit";
        } else {
            return increasingGroupTab ? "Increase limit" : "Set a limit";
        }
    }, [increasingGroupTab, isPayOnOrderTab]);

    const inputLabel = useMemo(() => {
        if (isPayOnOrderTab) {
            return "Spending limit";
        } else {
            return increasingGroupTab ? "Add amount" : `${capitaliseFirstLetter(tabTypeName)} limit`;
        }
    }, [increasingGroupTab, tabTypeName, isPayOnOrderTab]);

    const increaseLimitString = useMemo(
        () => menuDataLocale.formatCurrencyNoDigits((tabLimit || 0).toString()),
        [menuDataLocale, tabLimit]
    );
    const tags = useMemo(
        () =>
            increaseTags.map((tag) => ({
                ...tag,
                displayName: `+ ${currencySymbol}${tag.displayName}`,
            })),
        [currencySymbol]
    );

    const [selectedTagId, setSelectedTagId] = useState<string>();

    const resetSelectedIdTimeout = useRef(0);

    const resetUpdateError = useCallback(() => {
        dispatch(updateTabLimitOperation.actionCreators.reset());
    }, [dispatch]);

    const setLimit = useCallback(
        (limit?: number) => {
            dispatch(wizardActionCreators.setGroupTabLimit(limit));
        },
        [dispatch]
    );

    const increaseBy = useCallback(
        (id: string, value: any) => {
            const newVal = Math.min((tabLimit || 0) + value, MAX_LIMIT);
            setLimit(newVal);
            setSelectedTagId(id);
            resetSelectedIdTimeout.current = window.setTimeout(() => {
                setSelectedTagId(undefined);
            }, 350);
            setValue("tabLimit", newVal.toString(), { shouldValidate: true });
        },
        [setValue, tabLimit, setLimit]
    );

    const updateTabLimit = useCallback(() => {
        dispatch(updateTabLimitAction());
    }, [dispatch]);

    useEffect(() => {
        const limit = Number(watchLimit);
        if (limit <= MAX_LIMIT) {
            setLimit(limit || undefined);
        }
    }, [watchLimit, setLimit]);

    useEffect(() => {
        if (prevTabLimit !== tabLimit && updateTabLimitError) {
            resetUpdateError();
        }
    }, [prevTabLimit, tabLimit, updateTabLimitError, resetUpdateError]);

    useEffect(() => {
        return () => {
            window.clearTimeout(resetSelectedIdTimeout.current);
            if (increasingGroupTab) {
                setLimit();
            }
        };
    }, [resetSelectedIdTimeout, setLimit, increasingGroupTab]);

    useEffect(() => {
        // formState.isValid is true to begin with then turns false
        // which results in the payment button rendering briefly then hiding
        // so let it turn false before using it to show the payment button
        if (!checkIsValid.current && !formState.isValid) {
            checkIsValid.current = true;
        }
    }, [formState]);

    return (
        <div className="group-tab-limit">
            <main className={classNames("scroll-element", "animated-child")}>
                <div className="group-tab-wizard__title profile-page__title">
                    <Text preset="title-28" mode="bold" value={title} />
                    {isPayOnOrderTab ? (
                        <Text
                            preset="g-14"
                            mode="block"
                            className="group-tab-wizard__description"
                            value="Block orders once your spend limit has been reached."
                        />
                    ) : (
                        !increasingGroupTab && <TabGratuityDescription className="group-tab-wizard__description" />
                    )}
                </div>
                <FormControl
                    id="group-tab-limit"
                    invalid={(!!errors.tabLimit && !!errors.tabLimit?.message) || !!updateTabLimitError}
                    invalidMessage={errors.tabLimit?.message || updateTabLimitError?.detail}
                >
                    <Controller
                        control={control}
                        name="tabLimit"
                        defaultValue={tabLimit?.toString() || ""}
                        rules={{
                            ...validationRules.amountLimit({
                                minValue: { val: minIncrease, display: `${currencySymbol}${minIncrease}` },
                                maxValue: { val: MAX_LIMIT, display: `${maxValueString}` },
                                minValidationMessagePrefix: isPayOnOrderTab
                                    ? `${activeTabLimit ? "New spending" : "Spending"} limit must be higher than`
                                    : undefined,
                                maxValidationMessagePrefix: isPayOnOrderTab
                                    ? `${activeTabLimit ? "New spending" : "Spending"} limit cannot be higher than`
                                    : undefined,
                                isRequired: !isPayOnOrderTab,
                            }),
                        }}
                        render={({ onChange }) => (
                            <MaskedInput
                                allowClear
                                size="lg"
                                inputMode="numeric"
                                label={inputLabel}
                                value={tabLimit?.toString() || ""}
                                prefix={isPayOnOrderTab && !tabLimit ? undefined : currencySymbol}
                                placeholder={isPayOnOrderTab && !tabLimit ? "No limit" : undefined}
                                onAccept={(_, mask) =>
                                    Number(mask.unmaskedValue) !== tabLimit ? onChange(mask.unmaskedValue) : undefined
                                }
                                onClear={tabLimit?.toString() ? () => setTimeout(() => onChange(""), 1) : undefined}
                                onBlur={() =>
                                    errors.tabLimit?.type === "maxValue" &&
                                    setTimeout(() => onChange(tabLimit?.toString()))
                                }
                                {...masks.currency({
                                    thousandsSeparatorSymbol,
                                    max: (MAX_LIMIT + 1) * 10,
                                })}
                            />
                        )}
                    />
                </FormControl>
                <div className="group-tab-limit__amount-tags">
                    {tags.map((tag) => {
                        const isDisabled = !!tabLimit && tabLimit >= MAX_LIMIT;
                        return (
                            <Chip
                                key={tag.id}
                                onClick={() => increaseBy(tag.id, tag.value)}
                                text={tag.displayName}
                                size="small"
                                active={selectedTagId === tag.id}
                                className="group-tab-limit__amount-tags__tag"
                                disabled={selectedTagId !== tag.id && isDisabled}
                                mode="grey"
                            />
                        );
                    })}
                </div>
                {!isPayOnOrderTab && (
                    <TabPaymentSelector
                        increasingGroupTab={increasingGroupTab}
                        changePaymentMethod={changePaymentMethod}
                    />
                )}
            </main>
            <footer className="top-shadow">
                {isPayOnOrderTab ? (
                    <NetworkConnectedButton
                        value={activeTabLimit && !tabLimit ? "Remove limit" : "Set limit"}
                        disabled={!formState.isValid || !!updateTabLimitError}
                        onClick={updateTabLimit}
                    />
                ) : (
                    <PaymentButton
                        onPayment={increasingGroupTab ? increaseGroupTabAction : createGroupTabAction}
                        value={
                            increasingGroupTab
                                ? `Add ${tabLimit && tabLimit > 0 ? increaseLimitString : ""}`
                                : `Start ${tabTypeName}`
                        }
                        valueIncludesTotal={true}
                        payingValue={increasingGroupTab ? "Increasing limit..." : `Starting ${tabTypeName}...`}
                        operation={increasingGroupTab ? increaseGroupTabLimitOperation : createGroupTabOperation}
                        changePaymentMethod={changePaymentMethod}
                        disabled={!checkIsValid.current || !formState.isValid}
                        allowTips={false}
                    />
                )}
            </footer>
        </div>
    );
};
