import "./PayOnlySplitEvenly.scss";

import React, { useCallback } from "react";
import { Divider, Text } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getPayOnlyFullBalance, getPayOnlyState, getPayOnlyTotalBeforeTip } from "../selectors";
import { QuantitySelector } from "src/sharedComponents/controls/quantitySelector";
import { setShare } from "../actions/setShares";
import { PaymentItem } from "src/features/payment/components/PaymentItem";

export const PayOnlySplitEvenly = () => {
    const { share } = useSelector(getPayOnlyState);
    const fullBalance = useSelector(getPayOnlyFullBalance);
    const total = useSelector(getPayOnlyTotalBeforeTip);

    const dispatch = useDispatch();

    const updateShare = useCallback(
        (split, shares) => {
            dispatch(setShare({ split, shares }));
        },
        [dispatch]
    );

    if (!share) return null;

    return (
        <div className="pay-only-split-evenly">
            <div className="pay-only-select-split-options__title">
                <Text preset="title-28" mode="bold">
                    Split evenly
                </Text>
            </div>
            <div className="pay-only-split-evenly__options">
                <div className="pay-only-split-evenly__option">
                    <Text preset="g-16">Split into</Text>
                    <QuantitySelector
                        onIncrement={() => updateShare(share.split + 1, share.shares)}
                        onDecrement={() => updateShare(share.split - 1, Math.min(share.shares, share.split - 1))}
                        quantity={share.split}
                        size="large"
                        minValue={1}
                        maxValue={20}
                    />
                </div>
                <div className="pay-only-split-evenly__option">
                    <Text preset="g-16">I’ll pay for</Text>
                    <QuantitySelector
                        onIncrement={() => updateShare(share.split, share.shares + 1)}
                        onDecrement={() => updateShare(share.split, share.shares - 1)}
                        quantity={share.shares}
                        size="large"
                        minValue={1}
                        maxValue={share.split}
                    />
                </div>
            </div>
            <Divider extraHeight fullWidth />
            <div className="pay-only-split-evenly__summary">
                <Text preset="g-18" mode="medium">
                    Split summary
                </Text>
                <PaymentItem value={fullBalance} title={"Bill total"} />
                <PaymentItem value={total} title={"Your split"} />
            </div>
        </div>
    );
};
