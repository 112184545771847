import { AppDispatch, AppState } from "src/features";
import { getMenuData } from "../../menu/selectors";
import { getMemberTotalBeforeTip } from "../../payment/selectors";
import { getIsOrderAndPay } from "../../order/selectors";

export const pushBeginCheckoutEvent = () => {
    return (_: AppDispatch, getState: () => AppState) => {
        if (!window.dataLayer) return;

        const state = getState();
        const menuData = getMenuData(state);
        const total = getMemberTotalBeforeTip(state);
        const isOrderAndPay = getIsOrderAndPay(state);

        if (!menuData || !total || !isOrderAndPay) return;

        window.dataLayer.push({ ecommerce: null }); // Required to flush the object (GTM merges them)
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                // GA4 schema
                currency: menuData.currency,
                items: [],
                value: total, // Order total (including surcharge/discounts, excluding processing fees/tip)
                // UA schema
                currencyCode: menuData.currency,
                checkout: {
                    products: [
                        {
                            price: total,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    };
};
