import { UrlLinkData, UrlType } from "../interface";
import {
    getIsFlexJoinPath,
    getIsOfferPath,
    getIsPayOnlyPath,
    getIsTabJoinPath,
    getIsTakeawayPath,
} from "../shared/url";

const initialUrl = new URL(window.location.href);

export const handleUrlLink = (successCB: (res: UrlLinkData) => void) => {
    let res = {
        type: UrlType.UNKNOWN,
        claimUrl: initialUrl.toString(),
    };

    if (/\/nfc$/.test(initialUrl.pathname)) {
        res.type = UrlType.NFC;
    }

    if (/\/qr$/.test(initialUrl.pathname)) {
        res.type = UrlType.QR;
    }

    if (/\/order-history$/.test(initialUrl.pathname)) {
        res.type = UrlType.ORDER_HISTORY;
    }

    if (/\/order-history\/[0-9A-Za-z]+$/.test(initialUrl.pathname)) {
        res.type = UrlType.ORDER_HISTORY_ITEM;
    }
    if (getIsTabJoinPath(initialUrl.pathname)) {
        res.type = UrlType.JOIN_GROUP_TAB;
    }

    if (getIsFlexJoinPath(initialUrl.pathname)) {
        res.type = UrlType.JOIN_FLEX;
    }

    if (/\/group-tab$/.test(initialUrl.pathname)) {
        res.type = UrlType.MANAGE_GROUP_TAB;
    }

    if (/\/close-group-tab$/.test(initialUrl.pathname)) {
        res.type = UrlType.CLOSE_GROUP_TAB;
    }

    if (/\/group-tab-history$/.test(initialUrl.pathname)) {
        res.type = UrlType.GROUP_TAB_HISTORY;
    }

    if (getIsPayOnlyPath(initialUrl.pathname)) {
        res.type = UrlType.PAY_ONLY;
    }

    if (/\/pay$/.test(initialUrl.pathname)) {
        res.type = UrlType.PAY;
    }

    if (getIsTakeawayPath(initialUrl.pathname)) {
        res.type = UrlType.TAKEAWAY;
    }

    if (getIsOfferPath(initialUrl.pathname)) {
        res.type = UrlType.OFFER;
    }

    setTimeout(() => successCB(res));
};
