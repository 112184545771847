import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import "./Button.scss";
import classNames from "classnames";
import { Counter, Text } from "../../";
import { TappableButton } from "../../common/tappable";
import { useExactTimeInterval } from "../../common/shared";

export interface ButtonProps {
    value?: string;
    children?: ReactNode | ReactNode[];
    mode?: "solid" | "solidinverted" | "outline" | "blank";
    preset?: "default" | "half-size" | "large";
    className?: string;
    disabled?: boolean;
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    secondary?: boolean;
    onClick?: (event?: React.MouseEvent) => void;
    onTouchStart?: (event: React.TouchEvent) => void;
    onTouchEnd?: (event: React.TouchEvent) => void;
    type?: string;
    id?: string;
    form?: string;
    brandColor?: boolean;
    countDownSeconds?: number;
    overrideCountDownIntervalMS?: number;
    startCountDown?: boolean;
    flexDirection?: "row" | "column";
    pillValue?: number;
    onDisabledClick?: (event?: React.MouseEvent) => void;
    unthemed?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
    className,
    value,
    mode = "solid",
    preset = "default",
    children,
    disabled,
    leftIcon,
    rightIcon,
    secondary,
    type,
    form,
    id,
    onClick,
    onTouchStart,
    onTouchEnd,
    brandColor,
    countDownSeconds,
    startCountDown,
    overrideCountDownIntervalMS,
    flexDirection = "row",
    pillValue,
    onDisabledClick,
    unthemed,
}: ButtonProps) => {
    const counterRef = useRef<HTMLSpanElement>(null);

    const [startCounter, stopCounter] = useExactTimeInterval(overrideCountDownIntervalMS || 1000, (ticks: number) => {
        if (counterRef.current && countDownSeconds) {
            const timeToClose = countDownSeconds - ticks;
            if (timeToClose <= 0) {
                onClick && onClick();
                stopCounter();
            }
            counterRef.current.innerText = timeToClose.toString();
        }
    });

    useEffect(() => {
        if (startCountDown && countDownSeconds) {
            startCounter();
        }
        return () => {
            stopCounter();
        };
    }, [startCounter, stopCounter, startCountDown, countDownSeconds]);

    return (
        <TappableButton
            form={form}
            type={type}
            id={id}
            className={classNames(
                "button-element",
                className,
                "mode-" + mode,
                "preset-" + preset,
                secondary && "secondary",
                brandColor && "brand-color",
                unthemed && "unthemed",
                `flex-${flexDirection}`,
                pillValue !== undefined && "counter-button-position",
                disabled && onDisabledClick && "disabled"
            )}
            disabled={onDisabledClick ? false : disabled}
            onClick={disabled && onDisabledClick ? onDisabledClick : onClick}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
        >
            {!!leftIcon && <span className="button-element__left-icon">{leftIcon}</span>}
            {!!value ? (
                <Text preset="g-16" mode="bold">
                    {value}{" "}
                    {!!countDownSeconds && (
                        <>
                            (<span ref={counterRef}>{countDownSeconds}</span>)
                        </>
                    )}
                </Text>
            ) : (
                children
            )}
            {!!rightIcon && <span className="button-element__right-icon">{rightIcon}</span>}
            {pillValue !== undefined && (
                <Counter mode="outline" limitPillLengthToMax={true} max={99} quantity={pillValue} />
            )}
        </TappableButton>
    );
};
