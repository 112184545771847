import { AccountProfile } from "src/features/accountmenu";

export enum ProfileTypeKeys {
    PROCESSING = "PROFILE/FETCH_PROCESSING",
    SUCCESS = "PROFILE/FETCH_SUCCESS",
    FAILURE = "PROFILE/FETCH_FAILURE",
    RESET = "PROFILE/FETCH_RESET",
}

export const profileActionCreators = {
    fetchProcessing: () => ({ type: ProfileTypeKeys.PROCESSING }),
    fetchSuccess: (result: AccountProfile | null) => ({ type: ProfileTypeKeys.SUCCESS, result }),
    fetchFailed: () => ({ type: ProfileTypeKeys.FAILURE }),
    fetchReset: () => ({ type: ProfileTypeKeys.RESET }),
};

export interface State {
    profile: ProfileState;
}

export interface ProfileState {
    result: AccountProfile | null;
    status: LoadingStatus;
}

export type LoadingStatus = "initial" | "processing" | "success" | "failure";

export type ProfileFetchProcessingAction = { type: ProfileTypeKeys.PROCESSING };
export type ProfileFetchSuccessAction = { type: ProfileTypeKeys.SUCCESS; result: AccountProfile | null };
export type ProfileFetchFailureAction = { type: ProfileTypeKeys.FAILURE };
export type ProfileFetchResetAction = { type: ProfileTypeKeys.RESET };

export type ProfileAction =
    | ProfileFetchProcessingAction
    | ProfileFetchSuccessAction
    | ProfileFetchFailureAction
    | ProfileFetchResetAction;

const initialState: ProfileState = {
    result: null,
    status: "initial",
};

export function profileReducer(state: ProfileState = initialState, action: ProfileAction): ProfileState {
    if (action.type === ProfileTypeKeys.RESET) {
        return {
            status: "initial",
            result: null,
        };
    }

    if (action.type === ProfileTypeKeys.PROCESSING) {
        return {
            ...state,
            status: "processing",
            result: null,
        };
    }

    if (action.type === ProfileTypeKeys.SUCCESS) {
        const { result } = action;
        return {
            ...state,
            status: "success",
            result,
        };
    }

    if (action.type === ProfileTypeKeys.FAILURE) {
        return {
            ...state,
            status: "failure",
            result: null,
        };
    }

    return state;
}
