import {
    hideKeyboard,
    hidesKeyboard,
    registerKeyboardClosed,
    registerKeyboardOpen,
    setKeyboardOpenCallback,
    showsKeyboard,
} from "../../keyboard";

export const registerToKeyboard = (onOpenChanged: (open: boolean) => void) => {
    setKeyboardOpenCallback(onOpenChanged);

    document.addEventListener("focusin", ({ target }) => {
        if (showsKeyboard(target as HTMLElement)) {
            registerKeyboardOpen();
        } else if (hidesKeyboard(target as HTMLElement)) {
            hideKeyboard();
        } else {
            registerKeyboardClosed();
        }
    });

    document.addEventListener("focusout", ({ target }) => {
        if (showsKeyboard(target as HTMLElement)) {
            registerKeyboardClosed();
        }
    });
};
