export enum TypeKeys {
    SET_FOOD_PREFERENCES = "FOOD_PREFERENCES/SET",
}

export const actionCreators = {
    setFoodPreferences: (result: string[]) => ({ type: TypeKeys.SET_FOOD_PREFERENCES, result }),
};

export interface State {
    foodPreferences: FoodPreferencesState;
}

export interface FoodPreferencesState {
    result: string[];
}

export type SetFoodPreferencesAction = {
    type: TypeKeys.SET_FOOD_PREFERENCES;
    result: string[];
};

export type FoodPreferencesAction = SetFoodPreferencesAction;

const initialState: FoodPreferencesState = {
    result: [],
};

export function reducer(
    state: FoodPreferencesState = initialState,
    action: FoodPreferencesAction
): FoodPreferencesState {
    if (action.type === TypeKeys.SET_FOOD_PREFERENCES) {
        return {
            ...state,
            result: action.result,
        };
    }

    return state;
}
