import * as React from "react";
import { ReactNode } from "react";
import { SimpleNavHeader } from "../../../common/navigation";
import classNames from "classnames";
import "../assets/ProfilePageWrapper.scss";

export interface Props {
    done: () => void;
    navBarIsVisible: boolean;
    customBack: string;
    renderPage: (done: () => void) => ReactNode;
}

export class ProfilePageWrapper extends React.Component<Props> {
    render() {
        const { done, navBarIsVisible, customBack, renderPage } = this.props;
        return (
            <div className={classNames("profile-page-wrapper standalone", navBarIsVisible && "navBarIsVisible")}>
                <SimpleNavHeader customBack={customBack} withBorder />
                {renderPage(done)}
            </div>
        );
    }
}
