import { Action, Dispatch } from "redux";
import { AppMiddleware } from "../..";
import { History } from "history";

const preventBackPathnames = ["/menu/service", "/join-table", "/pay", "/pay-only"];

export const historyMiddleware = (history: History) => (store: AppMiddleware) => {
    let prevPathname: string;

    history.listen((location, action) => {
        const { pathname, state } = location;

        switch (action) {
            case "PUSH":
            case "REPLACE":
                if (preventBackPathnames.indexOf(pathname) !== -1 && (!state || !state.preventBack)) {
                    // Re-push this pathname so pressing back goes to the same pathname
                    history.push(pathname, { preventBack: true });
                }
                break;
            case "POP":
                if (
                    pathname === prevPathname &&
                    preventBackPathnames.indexOf(pathname) !== -1 &&
                    state &&
                    state.preventBack
                ) {
                    // If we go back to a pathname from the same pathname and it's one
                    // we added then go forward again
                    history.goForward();
                }
                break;
        }

        prevPathname = pathname;
    });

    return (next: Dispatch) => (action: Action) => next(action);
};
