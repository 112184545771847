import React, { useCallback } from "react";
import { Text } from "src/sharedComponents/controls/text/Text";
import classNames from "classnames";
import { Button } from "src/sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import { getSignupMembershipState } from "../selectors/getActiveMembershipState";
import { connect as connectMembership } from "../actions/connect";
import "./MembershipSignup.scss";
import { getEnvUrl } from "src/common/shared";
import { config } from "src/common/config";
import { webFlow } from "src/common/experience";
import { MembershipStatus, MessageTypeKeys, OAuthMembershipAuthentication } from "..";
import { MembershipSignupHeader } from "./MembershipSignupHeader";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";

export const RETURN_URL = "/membership/oauth/complete";
export interface Props {
    onStartFlow: () => void;
}

export const MembershipOAuthSignup = ({ onStartFlow }: Props) => {
    const dispatch = useDispatch();

    const signupMembership = useSelector(getSignupMembershipState);

    const onClick = useCallback(() => {
        if (signupMembership?.authentication.method === "oauth") {
            dispatch(
                connectMembership(
                    signupMembership.programId,
                    async () => {
                        const authentication = signupMembership?.authentication as OAuthMembershipAuthentication;
                        const returnUrl = getEnvUrl(config.REACT_APP_APP_BASE_URL) + RETURN_URL;
                        const startUrl = authentication.url + encodeURIComponent(returnUrl);
                        const returnedUri = await webFlow.start(
                            startUrl,
                            returnUrl,
                            MessageTypeKeys.OAUTH_SIGNUP_COMPLETE
                        );
                        const body = new FormData();
                        body.append("redirect_uri", returnUrl);
                        returnedUri.searchParams.forEach((v, k) => body.append(k, v));
                        return body;
                    },
                    (e) => {
                        dispatch(showModalMessage(modalMessages.problemDetail(e.problemDetails)));
                    }
                )
            );
        }
        onStartFlow();
    }, [dispatch, onStartFlow, signupMembership]);

    if (signupMembership === null || signupMembership.status !== MembershipStatus.AVAILABLE) {
        return null;
    }

    const { programName, links } = signupMembership;

    return (
        <div className={classNames("profile-page-wrapper standalone")}>
            <div className="membership-signup">
                <div className="scroll-element animated-child">
                    <MembershipSignupHeader programName={programName} links={links} />
                    <Button onClick={onClick} value="I’m a member. Sign in" />
                </div>
                <div className="membership-signup__submit profile-page__submit">
                    <Text className="membership-signup__submit__text" preset="g-14" mode="block">
                        Not a member?
                        <br />
                        Speak to venue staff to create your free account
                    </Text>
                </div>
            </div>
        </div>
    );
};
