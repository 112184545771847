import "../assets/SignInOptionsPage.scss";

import React, { useCallback, useMemo, useRef, useState } from "react";
import { LegalModal, LegalPageType } from "src/features/compliance/components/LegalModal";
import { Button, Divider, Text } from "src/sharedComponents";
import { TappableSpan } from "src/sharedComponents/common/tappable";
import { MenuLogo, PhoneSignInIcon } from "../../../sharedComponents/assets/icons";
import { SsoButton } from "./SsoButton";
import { IdentityValidationRequest } from "../../../common/sso";
import { useDispatch, useSelector } from "react-redux";
import { signInActions } from "../reducers";
import { ABTest } from "../../abtests/components/ABTest";
import { CSSTransition } from "react-transition-group";
import { getIsAnonymous } from "../../accounts/selectors";
import { getAccountDetailsOptions } from "../selectors";

interface Props {
    onContinueWithPhoneNumber: () => void;
    onSsoSignIn: (request: IdentityValidationRequest) => void;
}

export const SignInOptionsPage = ({ onContinueWithPhoneNumber, onSsoSignIn }: Props) => {
    const [showLegal, setShowLegal] = useState<LegalPageType | undefined>();
    const [showPhone, setShowPhone] = useState(false);

    const dispatch = useDispatch();

    const isAnonymous = useSelector(getIsAnonymous);
    const isAnonymousRef = useRef(isAnonymous);
    const options = useSelector(getAccountDetailsOptions);

    const renderContinueWithPhone = useCallback(
        () => (
            <Button
                leftIcon={<PhoneSignInIcon />}
                mode={"outline"}
                value="Continue with phone number"
                unthemed
                onClick={() => {
                    dispatch(signInActions.trackLoginSourceClicked("PhoneNumber"));
                    onContinueWithPhoneNumber();
                }}
            />
        ),
        [dispatch, onContinueWithPhoneNumber]
    );

    const descriptionText = useMemo(() => {
        if (options?.signInDescription) {
            return options.signInDescription;
        }
        if (isAnonymousRef.current) {
            return "Save your previous orders, payment methods & more with a me&u account.";
        }
        return "Boost your account security & log in easier next time when you link your account.";
    }, [options]);

    return (
        <div className="verify-page sign-in-options-page">
            <main>
                <Text preset="title-28" mode={["bold", "block"]} className="verify-page__title">
                    {isAnonymousRef.current ? "Log in or sign up" : "Link your account"}
                </Text>
                <Text preset="g-14" mode="block" className="verify-page__description">
                    {descriptionText}
                </Text>
                <SsoButton ssoProvider="Google" onSignIn={onSsoSignIn} />
                <SsoButton ssoProvider="Apple" onSignIn={onSsoSignIn} />
                <SsoButton ssoProvider="Facebook" onSignIn={onSsoSignIn} />
                {isAnonymousRef.current && (
                    <>
                        <Divider fullWidth caption="or" />
                        <ABTest
                            testName="SSO hide phone number"
                            A={renderContinueWithPhone}
                            B={() => (
                                <div className="sign-in-options-page__hidden-option">
                                    <CSSTransition in={!showPhone} classNames="fade" timeout={300} unmountOnExit>
                                        <Button
                                            mode="blank"
                                            value="See other options"
                                            unthemed
                                            onClick={() => setShowPhone(true)}
                                        />
                                    </CSSTransition>
                                    <CSSTransition in={showPhone} classNames="fade" timeout={300} unmountOnExit>
                                        {renderContinueWithPhone()}
                                    </CSSTransition>
                                </div>
                            )}
                        />
                    </>
                )}
                <Text preset="g-12" className="legal-text__terms">
                    By continuing, I agree to the{" "}
                    <TappableSpan onTap={() => setShowLegal("termsOfUse")}>Terms &amp; Conditions</TappableSpan>
                    &nbsp;&amp; acknowledge the{" "}
                    <TappableSpan onTap={() => setShowLegal("privacyPolicy")}>Privacy Policy</TappableSpan>.
                </Text>
                <LegalModal legalPage={showLegal} goBack={() => setShowLegal(undefined)} />
            </main>
            <div className="verify-page__submit">
                <MenuLogo />
            </div>
        </div>
    );
};
