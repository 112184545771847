import React, { useState, useCallback, useEffect, useRef, CSSProperties } from "react";
import classNames from "classnames";
import { Button } from "src/sharedComponents";
import { useSelector, useDispatch } from "react-redux";
import { getSurvey, getSurveyDetails } from "../selectors/survey";
import { actions } from "../actions";
import "../assets/FeedbackList.scss";

interface Props {
    showAll: boolean;
}

export const FeedbackList = ({ showAll }: Props) => {
    const { tags } = useSelector(getSurvey);
    const surveyDetails = useSelector(getSurveyDetails);

    const [selectedTags, setSelectedTags] = useState(tags);
    const [visibleTags, setVisibleTags] = useState<string[]>([]);
    const [tagsHeight, setTagsHeight] = useState<number>();

    const tagsRef = useRef<HTMLUListElement>(null);

    const dispatch = useDispatch();
    const setTags = useCallback((tags: string[]) => dispatch(actions.setTags(tags)), [dispatch]);

    useEffect(() => {
        if (surveyDetails?.availableTags) {
            if (showAll) {
                setVisibleTags(surveyDetails.availableTags);
            } else {
                setVisibleTags(surveyDetails.availableTags.slice(0, 6));
            }
        }
    }, [showAll, surveyDetails]);

    const onTagClick = (tag: string) => {
        if (selectedTags.indexOf(tag) > -1) {
            setSelectedTags(selectedTags.filter((t) => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    useEffect(() => {
        setTags(selectedTags);
    }, [selectedTags, setTags]);

    useEffect(() => {
        if (tagsRef.current && tagsRef.current.offsetHeight) {
            setTagsHeight(tagsRef.current.offsetHeight);
        }
    }, [visibleTags]);

    return (
        <div
            className={classNames("feedback-list", showAll && "expanded")}
            style={tagsHeight ? ({ "--tags-height": tagsHeight + "px" } as CSSProperties) : undefined}
        >
            <ul className="feedback-list__list" ref={tagsRef}>
                {visibleTags.map((tag) => (
                    <Button
                        key={"feedback-list_" + tag}
                        className="feedback-list__button"
                        mode={selectedTags.indexOf(tag) > -1 ? "solid" : "outline"}
                        onClick={() => onTagClick(tag)}
                    >
                        {tag}
                    </Button>
                ))}
            </ul>
        </div>
    );
};
