import { AppDispatch, AppState } from "src/features";
import { getActiveService } from "src/features/menu/selectors";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { orderApi } from "src/features/order/orderApi";
import { getCurrentMemberActiveOrderItems, getOrderDateTimeScheduled } from "src/features/order/selectors";
import { sendTakeawayScheduledTimeOperation } from "..";
import { actionCreators } from "../reducers/takeawayOptions";

export function sendScheduleTime(time: string | Date | null, selectedServiceId: string | null) {
    return sendTakeawayScheduledTimeOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const activeService = getActiveService(getState());
            const currentScheduledAt = getOrderDateTimeScheduled(getState());
            const activeOrderItems = getCurrentMemberActiveOrderItems(getState());

            const menuChangeRequired = selectedServiceId && activeService?.id !== selectedServiceId;

            const changeService = (time: string | Date | null) => {
                dispatch(actionCreators.showSchedulePage(false));
                dispatch(actionCreators.showServiceChange(true));
                dispatch(invokeSendScheduleTime(time));
            };

            if (currentScheduledAt !== time) {
                if (menuChangeRequired) {
                    if (activeOrderItems?.length) {
                        dispatch(
                            showModalMessage(
                                modalMessages.newTakeawayMenuChange(
                                    () => dispatch(actionCreators.trackServiceChange(false)),
                                    async () => {
                                        dispatch(actionCreators.trackServiceChange(true));
                                        changeService(time);
                                    }
                                )
                            )
                        );
                    } else {
                        changeService(time);
                    }
                } else {
                    dispatch(actionCreators.showSchedulePage(false));
                    dispatch(invokeSendScheduleTime(time));
                }
            }
        } catch (e) {
            throw e;
        }
    });
}

function invokeSendScheduleTime(time: string | Date | null) {
    return async () => await orderApi.invoke("scheduleOrder", { scheduleAt: time });
}
