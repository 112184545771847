import "./Disclaimer.scss";
import React from "react";
import classNames from "classnames";
import { Text } from "../text";

export interface DisclaimerProps {
    bodyText: string;
    className?: string;
}

export const Disclaimer = ({ bodyText, className }: DisclaimerProps) => {
    return (
        <div className={classNames("disclaimer", className && className)}>
            <Text preset="g-12" mode="block">
                <Text preset="g-12" mode="bold">
                    Disclaimer:
                </Text>{" "}
                {bodyText}
            </Text>
        </div>
    );
};
