import { tracking } from "src/common/experience";
import { AppDispatch, AppState } from "src/features";
import { getFacebookPixelId } from "../../order/selectors/restaurantFlags";

export const initializeFacebookPixel = () => {
    return (_: AppDispatch, getState: () => AppState) => {
        const facebookPixelId = getFacebookPixelId(getState());
        if (!facebookPixelId) return;
        tracking.trackFacebookPixelId(facebookPixelId);
    };
};
