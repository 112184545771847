import "./Input.scss";

import React, {
    ChangeEvent,
    DetailedHTMLProps,
    FocusEvent,
    InputHTMLAttributes,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    Ref,
    TouchEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { useFormControlContext } from "../formControl";
import { ClearIcon } from "../../assets/icons";
import { Button } from "../button";

interface BasicInputProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange" | "size"> {
    refContainer?: Ref<HTMLDivElement>;
    label?: string;
    prefix?: string;
    blockPattern?: RegExp;
    allowClear?: boolean;
    invalid?: boolean;
    size?: "md" | "lg";
    icon?: ReactNode;
    applyButtonText?: string;
    applyButtonIsDisabled?: boolean;
    onApplyButtonClick?: () => void;
    onApplyButtonDisabledClick?: () => void;
    onChange?: (value: string) => void;
    onClear?: () => void;
}

interface OnEnterInputProps extends BasicInputProps {
    onEnter?: () => void;
    onApplyButtonClick?: never;
}

interface OnApplyInputProps extends BasicInputProps {
    onEnter?: never;
    onApplyButtonClick?: () => void;
}

export type InputProps = OnEnterInputProps | OnApplyInputProps;

export const Input = forwardRef<HTMLInputElement | null, InputProps>(
    (
        {
            id,
            value,
            defaultValue,
            prefix,
            refContainer,
            label,
            placeholder,
            blockPattern,
            allowClear,
            disabled,
            invalid,
            size = "md",
            icon,
            applyButtonText,
            applyButtonIsDisabled,
            className,
            onApplyButtonClick,
            onApplyButtonDisabledClick,
            onChange,
            onFocus,
            onBlur,
            onKeyPress,
            onEnter,
            onClear,
            ...rest
        },
        ref
    ) => {
        const internalRef = useRef<HTMLInputElement>(null);
        const formControl = useFormControlContext() || { id, disabled, invalid };
        const [isBlank, setIsBlank] = useState(!defaultValue && !value);
        const [isFocused, setIsFocused] = useState(false);

        const isControlled = value !== undefined;

        useImperativeHandle(ref, () => internalRef.current, []);

        useEffect(() => {
            // Necessary due to react-imask ref value
            if (!isControlled) setIsBlank(!internalRef.current?.value);
            // eslint-disable-next-line
        }, [isControlled, internalRef.current?.value]);

        const handleOnClick = () => {
            internalRef.current?.focus();
        };

        const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = blockPattern ? event.target.value.replace(blockPattern, "") : event.target.value;

            if (!isControlled && internalRef.current) {
                internalRef.current.value = newValue;
            }

            setIsBlank(!newValue);
            onChange?.(newValue);
        };

        const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);
            onFocus?.(event);
        };

        const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            onBlur?.(event);
        };

        const handleOnClear = () => {
            if (formControl.disabled) return;

            if (!isControlled && internalRef.current) {
                internalRef.current.value = "";
            }

            setIsBlank(true);
            if (onClear) onClear();
            else if (onChange) onChange("");
        };

        const handleOnKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
            onKeyPress?.(event);
            if (event.key === "Enter") {
                if (onApplyButtonClick) onApplyButtonClick();
                else if (onEnter) onEnter();

                event.currentTarget.blur();
            }
        };

        const preventLossOfFocus = (event: MouseEvent | TouchEvent) => {
            if (event.target !== internalRef.current) event.preventDefault();
        };

        return (
            <div
                ref={refContainer}
                className={classNames(
                    "input-control",
                    `input-control--${size}`,
                    {
                        "input-control--disabled": formControl.disabled,
                        "input-control--invalid": formControl.invalid,
                    },
                    className
                )}
            >
                <div className="input-control__input" onMouseDown={preventLossOfFocus} onClick={handleOnClick}>
                    <div
                        className={classNames("input-control__input__wrap", {
                            "input-control__input__wrap--label": !!label,
                        })}
                    >
                        <input
                            ref={internalRef}
                            id={formControl.id}
                            value={value}
                            defaultValue={defaultValue}
                            placeholder={placeholder || " "}
                            disabled={formControl.disabled}
                            onChange={handleOnChange}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onKeyPress={handleOnKeyPress}
                            {...rest}
                        />
                        {prefix && <span className="input-control__input__wrap__prefix">{prefix}</span>}
                    </div>
                    {label && (
                        <label
                            className={classNames({ "label--floating": isFocused || !isBlank })}
                            htmlFor={formControl.id}
                        >
                            {label}
                        </label>
                    )}
                    {(allowClear || icon) && (
                        <div className="input-control__input__icons">
                            {allowClear && !isBlank && (
                                <div className="input-control__input__icons__clear" onClick={handleOnClear}>
                                    <ClearIcon />
                                </div>
                            )}
                            {icon && <div className="input-control__input__icons__icon">{icon}</div>}
                        </div>
                    )}
                </div>
                {applyButtonText && (onApplyButtonClick || onEnter) && (
                    <Button
                        className="input-control__apply-button"
                        disabled={formControl.invalid || formControl.disabled || applyButtonIsDisabled}
                        onClick={onApplyButtonClick || onEnter}
                        onDisabledClick={onApplyButtonDisabledClick}
                    >
                        {applyButtonText}
                    </Button>
                )}
            </div>
        );
    }
);
