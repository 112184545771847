import * as React from "react";
import { useCallback } from "react";
import { actionCreators as paymentActionCreators, AddedCardResult, GetAddedCardFunc } from "../../payment";
import { useDispatch, useSelector, useStore } from "react-redux";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import {
    isVisibleForPayment,
    isVisibleForProfile,
    NewCardPaymentMethod,
    SelectablePaymentMethod,
} from "src/common/payment";
import { getAllHostedFieldsValid } from "../selectors/hostedFields";
import { addCard } from "../actions/addCard";
import { getPaymentMethods } from "../../payment/actions/getPaymentMethods";
import { trackPaymentMethodSelected } from "../../payment/actions/trackPaymentMethodSelected";
import { SelectPaymentMethodActions } from "../types";
import { AddCardBehavior, ShowAddCardFunc } from "../../paymentGateways/types";
import { AddCardFormPaymentMethodAction } from "./AddCardFormPaymentMethodAction";
import { actionCreators } from "../../spinnerModal/reducers/spinner";
import { usePaymentMethodSelectionContext } from "./SelectPaymentMethodContext";

interface Props {
    select?: boolean;
    vaultCard?: boolean;
    showVaultCard?: boolean;
    paymentMethod: NewCardPaymentMethod;
    actions?: SelectPaymentMethodActions;
    onVaultCard?: () => void;
}

export const AddCardPaymentMethodAction = ({
    select,
    vaultCard,
    showVaultCard,
    paymentMethod,
    actions,
    onVaultCard,
}: Props) => {
    const dispatch = useDispatch();

    const [, setTempSelectedPaymentMethod] = usePaymentMethodSelectionContext();

    const allHostedFieldsValid = useSelector(getAllHostedFieldsValid);

    const { getState } = useStore();

    const getRequiredBehavior = useCallback((addCardBehavior: AddCardBehavior) => {
        return addCardBehavior.showAddCard;
    }, []);

    const getResultFunc = useCallback(
        (showAddCard: ShowAddCardFunc) => showAddCard(dispatch, getState, paymentMethod),
        [dispatch, getState, paymentMethod]
    );

    const getPaymentMethod = useCallback(
        (
            getAddedCard: GetAddedCardFunc,
            resetForm: (() => void) | undefined,
            remember: boolean,
            disableVaultCard: boolean = false
        ) =>
            new Promise<SelectablePaymentMethod | null>(async (resolve) => {
                const isSavingCard = (!select || vaultCard) && !disableVaultCard;

                if (isSavingCard) {
                    dispatch(actionCreators.setIsLoading(true));
                }

                const result = await getAddedCard(!isSavingCard);

                if (result === null) {
                    if (isSavingCard) {
                        dispatch(actionCreators.setIsLoading(false));
                    }
                    dispatch(showModalMessage(modalMessages.invalidCard()));
                    return resolve(null);
                }

                if (result === undefined) {
                    // addedCardResult will be undefined if getAddedCard failed for some reason
                    // due to operation.invoke() swallowing the error and returning undefined
                    if (isSavingCard) {
                        dispatch(actionCreators.setIsLoading(false));
                    }
                    dispatch(showModalMessage(modalMessages.addCardFailed()));
                    return resolve(null);
                }

                const addedCardResult = result["paymentMethod"] ? (result as AddedCardResult) : undefined;

                const paymentMethod = addedCardResult?.paymentMethod ?? (result as SelectablePaymentMethod);

                const onSuccess = () => {
                    if (isSavingCard) {
                        dispatch(actionCreators.setIsLoading(false));
                    }

                    if (select) {
                        dispatch(trackPaymentMethodSelected(paymentMethod, false));
                        if (!isSavingCard) {
                            setTempSelectedPaymentMethod(paymentMethod);
                        }
                    }

                    resetForm?.();

                    if (
                        (select && !isVisibleForPayment(paymentMethod)) ||
                        (!select && !isVisibleForProfile(paymentMethod))
                    ) {
                        return resolve(paymentMethod);
                    }

                    setTimeout(() => resolve(paymentMethod), 500);
                };

                const onFailure = (rejected: boolean) => {
                    if (isSavingCard) {
                        dispatch(actionCreators.setIsLoading(false));
                    }
                    dispatch(showModalMessage(rejected ? modalMessages.invalidCard() : modalMessages.addCardFailed()));
                    resolve(null);
                };

                if (!addedCardResult) {
                    onSuccess();
                    return;
                }

                addedCardResult.paymentMethod.remember = remember;

                if (isSavingCard) {
                    dispatch(addCard(addedCardResult, () => dispatch(getPaymentMethods(onSuccess)), onFailure));
                    onVaultCard?.();
                } else {
                    dispatch(paymentActionCreators.addCard(addedCardResult));
                    onSuccess();
                }
            }),
        [dispatch, onVaultCard, setTempSelectedPaymentMethod, select, vaultCard]
    );

    return (
        <AddCardFormPaymentMethodAction
            paymentMethod={paymentMethod}
            getRequiredBehavior={getRequiredBehavior}
            getResultFunc={getResultFunc}
            getPaymentMethod={getPaymentMethod}
            isValid={allHostedFieldsValid}
            showVaultCard={showVaultCard}
            actions={actions}
            select={select}
        />
    );
};
