import { trackedEvent } from "../../../common/events/reduxEventTracking";
import { TabJoinMethod } from "..";

enum TypeKeys {
    GROUP_TAB_DEEPLINK_MANAGE = "PARTY/GROUP_TAB/DEEPLINK_MANAGE",
    GROUP_TAB_DEEPLINK_CLOSE = "PARTY/GROUP_TAB/DEEPLINK_CLOSE",
    GROUP_TAB_DEEPLINK_JOIN = "PARTY/GROUP_TAB/DEEPLINK_JOIN",
}

export const actionCreators = {
    trackManageGroupTabDeepLink: () => trackedEvent({ type: TypeKeys.GROUP_TAB_DEEPLINK_MANAGE }),
    trackGroupTabCloseDeepLink: () => trackedEvent({ type: TypeKeys.GROUP_TAB_DEEPLINK_CLOSE }),
    trackGroupTabJoinWithDeepLink: (joinCode: string, join_method: TabJoinMethod) =>
        trackedEvent({ type: TypeKeys.GROUP_TAB_DEEPLINK_JOIN, joinCode, join_method }),
};
