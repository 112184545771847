import { AnyAction } from "redux";

export const TRACK_EVENT_PROPERTY = "_trackEvent";
export const TRACK_ERROR_PROPERTY = "_trackError";

export function trackedEvent<T extends AnyAction>(action: T): T {
    (action as any)[TRACK_EVENT_PROPERTY] = true;
    return action;
}

export function trackedError(action: AnyAction) {
    (action as any)[TRACK_ERROR_PROPERTY] = true;
    return action;
}
