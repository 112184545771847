import { createSelector } from "reselect";
import { getRestaurantFlags } from "../../order/selectors/restaurantFlags";
import { getIsGroupTabOpen, getLocale } from "./activeGroupTab";
import { getParty } from "src/features/order";
import { getIsSectionTakeaway } from "src/features/order/selectors";
import { getIsOpenTable } from "../../openTable/selectors";

export const getIsTrayChargeEnabled = createSelector(getParty, (party) => !!party?.isTrayChargeEnabled);

export const getLocationGroupTabsEnabled = createSelector(
    getRestaurantFlags,
    getIsTrayChargeEnabled,
    getIsSectionTakeaway,
    ({ groupTabsEnabled }, isTrayChargeEnabled, isTakeaway) => groupTabsEnabled && !isTrayChargeEnabled && !isTakeaway
);

export const getLocationOpenTabsEnabled = createSelector(getRestaurantFlags, ({ enableOpenTabs }) => enableOpenTabs);

export const getOpenTabInCheckoutSupported = createSelector(
    getLocationOpenTabsEnabled,
    getLocale,
    (openTabsEnabledAtLocation, locale) => !!openTabsEnabledAtLocation || locale?.toLowerCase().includes("us")
);

export const getHideTabsFromNavBar = createSelector(
    getLocationOpenTabsEnabled,
    getIsGroupTabOpen,
    getIsOpenTable,
    (openTabsEnabledAtLocation, inActiveGroupTab, isOpenTable) =>
        (!!openTabsEnabledAtLocation && !inActiveGroupTab) || isOpenTable
);
