import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth/auth";
import { AppDispatch, AppState } from "../..";
import { Rating } from "../../../sharedComponents";
import { getAnyParty } from "src/features/order/selectors";
import { sendSurveyOperation } from "src/features/survey/operations";
import { SurveyRatingRequest } from "src/features/survey/reducers/types";
import { actionCreators } from "../reducers/payOnlyFlags";

export function payOnlySendFeedback(title: string, rating: Rating) {
    return sendSurveyOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const headers = await getAuthHeaders();
        headers.append("Content-Type", "application/json");

        const state = getState();
        const party = getAnyParty(state);
        if (!party) return;

        const ratingRequest: SurveyRatingRequest = {
            locationId: party.restaurantId,
            partyId: party.id,
            title,
            rating,
        };

        const response = await orderApi.send("/feedback/payonlysubmitfeedback", {
            method: "POST",
            body: JSON.stringify(ratingRequest),
            headers,
        });

        if (!response.ok) {
            throw response;
        } else {
            dispatch(actionCreators.setShowFeedback(false));
        }
    });
}
