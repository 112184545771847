import { connect } from "react-redux";
import { AppState } from "../../index";
import { CartViewPreview, Props } from "../component/CartViewPreview";
import { DataProps, FunctionProps } from "src/common/types/utils";

interface OwnProps {
    isOpen: boolean;
    close: () => void;
}
const mapStateToProps = (state: AppState, { isOpen }: OwnProps): DataProps<Props> => {
    return {
        isOpen,
    };
};

const mapDispatchToProps = (dispatch: any, { close }: OwnProps): FunctionProps<Props> => {
    return {
        close: () => close(),
    };
};

export const CartViewPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(CartViewPreview);
