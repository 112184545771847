import "./assets/PaymentDetail.scss";

import * as React from "react";
import { useContext } from "react";
import { AppState } from "../../index";
import { useSelector } from "react-redux";
import {
    getLocationPromotionsEnabled,
    getMemberDiscountAmount,
    getMemberGratuityAmount,
    getMemberGratuityFactor,
    getMemberMembershipDiscountAmount,
    getMemberNonMemberSubTotal,
    getMemberOfferDiscountAmount,
    getMemberProcessingFee,
    getMemberProcessingFeeWithoutTip,
    getMemberPromoCode,
    getMembershipPointsSpendTotal,
    getMemberSubTotal,
    getMemberSurchargeAmount,
    getMemberTaxSummary,
    getMemberTipAmount,
    getMemberTotal,
    getMemberTotalWithoutTip,
    getMemberVenueCause,
    getSelectedPaymentMethod,
    getShowGratuity,
    getSurchargePercentage,
    getVenueTrayCharge,
} from "../../payment/selectors";
import { PaymentType } from "../../../common/payment";
import { submitPaymentAndOrderOperation } from "../../payment/operations";
import { Divider } from "src/sharedComponents";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MembershipIcon, PromotionsTagIcon } from "../../../sharedComponents/assets/icons";
import { createSelector } from "reselect";
import { TaxesDetail } from "./TaxesDetail";
import { BillAdjustment, TaxClassTotal } from "../types";
import { PaymentItem } from "../../payment/components/PaymentItem";
import { getProcessingFeeText } from "../../payment/util/getProcessingFeeText";
import { getSurchargeText } from "../../payment/util/getSurchargeText";

interface Props {
    subTotal: number;
    memberDiscount: number;
    discountAmount: number;
    surchargeAmount: number;
    surchargePercentage: number;
    tipAmount: number;
    processingFee: number;
    total: number;
    paymentType: PaymentType | null;
    submitting: boolean;
    orderTotal: number;
    taxSummary?: TaxClassTotal[];
    promoCode?: BillAdjustment;
    gratuityFactor?: number;
    gratuityAmount?: number;
    trayCharge?: BillAdjustment;
    offerDiscountAmount: number;
    venueCause?: BillAdjustment;
    membershipPointsSpendTotal: number;
}

export const getSubmitPaymentAndOrderOperationState = (state: AppState) =>
    submitPaymentAndOrderOperation.getStatus(state);

const getMemberShare = createSelector(
    getMemberSubTotal,
    getMemberNonMemberSubTotal,
    getMemberSurchargeAmount,
    getMemberDiscountAmount,
    getMemberMembershipDiscountAmount,
    getMemberTipAmount,
    getMemberTotal,
    getMemberTotalWithoutTip,
    getMemberProcessingFee,
    getMemberProcessingFeeWithoutTip,
    getMemberTaxSummary,
    getMemberOfferDiscountAmount,
    (
        subTotal,
        nonMemberSubTotal,
        surchargeAmount,
        discountAmount,
        memberDiscountAmount,
        tipAmount,
        total,
        totalWithoutTip,
        processingFee,
        processingFeeWithoutTip,
        taxSummary,
        offerDiscountAmount
    ) => ({
        subTotal,
        nonMemberSubTotal,
        surchargeAmount,
        discountAmount,
        memberDiscountAmount,
        tipAmount,
        total,
        totalWithoutTip,
        processingFee,
        processingFeeWithoutTip,
        taxSummary,
        offerDiscountAmount,
    })
);

const getPaymentDetail = createSelector(
    getMemberShare,
    getSurchargePercentage,
    getSubmitPaymentAndOrderOperationState,
    getSelectedPaymentMethod,
    getMemberPromoCode,
    getMemberGratuityAmount,
    getMemberGratuityFactor,
    getShowGratuity,
    getVenueTrayCharge,
    getMemberVenueCause,
    getMembershipPointsSpendTotal,
    (
        {
            subTotal,
            nonMemberSubTotal,
            surchargeAmount,
            discountAmount,
            memberDiscountAmount,
            tipAmount,
            total,
            totalWithoutTip,
            processingFee,
            processingFeeWithoutTip,
            taxSummary,
            offerDiscountAmount,
        },
        surchargePercentage,
        submitStatus,
        selectedPaymentMethod,
        promoCode,
        gratuityAmount,
        gratuityFactor,
        showGratuity,
        trayCharge,
        venueCause,
        membershipPointsSpendTotal
    ): Props => {
        const submitting = submitStatus === "processing" || submitStatus === "complete";

        return {
            subTotal,
            memberDiscount: memberDiscountAmount,
            surchargeAmount,
            surchargePercentage,
            discountAmount,
            tipAmount: showGratuity ? 0 : tipAmount,
            total: showGratuity || submitting ? total : totalWithoutTip,
            processingFee: showGratuity || submitting ? processingFee : processingFeeWithoutTip,
            paymentType: selectedPaymentMethod && selectedPaymentMethod.paymentType,
            submitting,
            orderTotal: nonMemberSubTotal,
            taxSummary,
            promoCode,
            gratuityFactor,
            gratuityAmount,
            trayCharge,
            offerDiscountAmount,
            venueCause,
            membershipPointsSpendTotal,
        };
    }
);

export const PaymentDetail = () => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const promoCodesEnabled = useSelector(getLocationPromotionsEnabled);

    const {
        subTotal,
        memberDiscount,
        surchargeAmount,
        surchargePercentage,
        discountAmount,
        tipAmount,
        processingFee,
        total,
        paymentType,
        submitting,
        orderTotal,
        taxSummary,
        promoCode,
        gratuityFactor,
        gratuityAmount,
        trayCharge,
        offerDiscountAmount,
        venueCause,
        membershipPointsSpendTotal,
    } = useSelector(getPaymentDetail);

    return (
        <div className="payment-detail">
            {!!(memberDiscount || discountAmount || offerDiscountAmount) && (
                <>
                    <PaymentItem title="Item total" value={orderTotal} />
                    <PaymentItem
                        title="Deals"
                        value={memberDiscount}
                        extraClass="payment-item--member-discount"
                        titleSuffix={<MembershipIcon />}
                    />
                    <PaymentItem title="Venue discount" value={discountAmount} />
                    <PaymentItem
                        title="Offer"
                        value={offerDiscountAmount}
                        extraClass="payment-item--offer-discount"
                        titleSuffix={<PromotionsTagIcon />}
                    />
                    <Divider fullWidth />
                </>
            )}
            {subTotal !== total && <PaymentItem title="Subtotal" value={subTotal} />}
            {promoCodesEnabled && !!promoCode && (
                <PaymentItem
                    title={promoCode.name}
                    value={promoCode.value}
                    titleSuffix={<PromotionsTagIcon />}
                    extraClass="payment-item--promocode"
                />
            )}
            <PaymentItem title={getSurchargeText(menuDataLocale, surchargePercentage)} value={surchargeAmount} />
            {venueCause && <PaymentItem title={venueCause.name} value={venueCause.value} />}
            <TaxesDetail taxSummary={taxSummary} />
            {!!gratuityFactor && (
                <PaymentItem
                    title={`Venue gratuity (${menuDataLocale.formatPercent(gratuityFactor)})`}
                    value={gratuityAmount}
                />
            )}
            {trayCharge && <PaymentItem title={trayCharge.name} value={trayCharge.value} />}
            <PaymentItem
                title="Points"
                value={-membershipPointsSpendTotal}
                extraClass="payment-item--member-discount"
                titleSuffix={<MembershipIcon />}
            />
            {submitting && <PaymentItem title="Tip" value={tipAmount} />}
            <PaymentItem title={getProcessingFeeText(menuDataLocale, paymentType)} value={processingFee} />
            <PaymentItem title="Total" value={total} showZeroValue bold />
        </div>
    );
};
