import "./assets/BackButton.scss";

import * as React from "react";
import classNames from "classnames";
import { History } from "history";
import { useHistory } from "react-router";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { BackIcon, CloseIcon } from "src/sharedComponents/assets/icons";
import { backHandler } from "src/common/experience";
import { NativeBackButton } from "./NativeBackButton";

export interface Props {
    showAsClose?: boolean;
    customBack?: string;
    reset?: () => void;
    onBack?: () => void;
    disabled?: boolean;
}

export const BackButton = ({ showAsClose, customBack, reset, onBack, disabled }: Props) => {
    const history = useHistory();

    const handleOnClick = () => {
        if (customBack) {
            backHandler.handleBack(handleOnBack);
        } else {
            handleOnBack();
        }
    };

    const handleOnBack = () => {
        reset?.();
        onBack ? onBack() : goBack(history);
    };

    return (
        <NativeBackButton name={customBack ?? ""} onPressed={customBack ? handleOnBack : null} disabled={disabled}>
            <TappableDiv disabled={disabled} onClick={handleOnClick} className="button--back__wrapper allow-swipe-back">
                <div
                    className={classNames("button--back", "allow-swipe-back", {
                        "button--back--close": showAsClose,
                    })}
                >
                    {showAsClose ? (
                        <CloseIcon className="allow-swipe-back" />
                    ) : (
                        <BackIcon className="allow-swipe-back" />
                    )}
                </div>
            </TappableDiv>
        </NativeBackButton>
    );
};

type MemoryRouterHistory = History & { canGo?: (d: number) => boolean };

function goBack(history: MemoryRouterHistory) {
    if (!history.canGo || history.canGo(-1)) {
        history.goBack();
        return;
    }
}
