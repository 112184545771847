import { MemberActivity } from "src/features/order";

const KEY = "MEANDU_MEMBER_ACTIVITY";

export type MemberActivityInfo = {
    partyId: string;
    memberActivity: MemberActivity;
};

export function getMemberActivityInfo(): MemberActivityInfo | null {
    try {
        return JSON.parse(localStorage.getItem(KEY)!) as MemberActivityInfo;
    } catch (err) {
        return null;
    }
}

export function saveMemberActivityInfo(partyId: string, memberActivity: MemberActivity) {
    const memberActivityInfo: MemberActivityInfo = {
        partyId,
        memberActivity,
    };
    localStorage.setItem(KEY, JSON.stringify(memberActivityInfo));
}

export function deleteMemberActivityInfo() {
    window.localStorage.removeItem(KEY);
}
