import { useDispatch, useSelector } from "react-redux";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { useEffect } from "react";
import { getBespokeModalData } from "../selectors";

const BESPOKE_SCREEN_KEY = "MEANDU_BESPOKE_SCREEN";

const hasBeenViewedToday = (date: string | null) => {
    if (!date) return false;
    const d1 = new Date();
    const d2 = new Date(+date);
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

const getBespokeModalViews = () => {
    try {
        return hasBeenViewedToday(window.localStorage.getItem(BESPOKE_SCREEN_KEY));
    } catch (err) {
        return false;
    }
};

const clearStorage = () => {
    window.localStorage.removeItem(BESPOKE_SCREEN_KEY);
};

const saveBespokeViewed = () => {
    window.localStorage.setItem(BESPOKE_SCREEN_KEY, new Date().getTime().toString());

    document.removeEventListener("logout", clearStorage);
    document.addEventListener("logout", clearStorage);
};

export const CategoryPopup = () => {
    const bespokeData = useSelector(getBespokeModalData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!bespokeData) {
            return;
        }

        const { title, text } = bespokeData;

        if (!getBespokeModalViews()) {
            saveBespokeViewed();
            dispatch(showModalMessage(modalMessages.showBespokeModal(title, text)));
        }
    }, [bespokeData, dispatch]);

    return null;
};
