import { poll } from "../shared/poll";

const keyboardOpenClass = "keyboard-open";

let keyboardOpenCallback: ((open: boolean) => void) | undefined;

export const setKeyboardOpenCallback = (callback: (open: boolean) => void) => (keyboardOpenCallback = callback);

export const isKeyboardOpen = () => document.body.classList.contains(keyboardOpenClass);

export const waitForKeyboardClose = async () => {
    try {
        await poll(() => !isKeyboardOpen(), 1000, 50);
    } catch {
        console.log("Keyboard didn't close in allotted time");
    }
};

let removeClassTimeout: number | null = null;
let focusedFieldCount = 0;

export const registerKeyboardOpen = () => {
    focusedFieldCount++;
    if (removeClassTimeout !== null) {
        window.clearTimeout(removeClassTimeout);
        removeClassTimeout = null;
    }
    if (isKeyboardOpen()) return;
    document.body.classList.add(keyboardOpenClass);
    keyboardOpenCallback?.(true);
};

export const registerKeyboardClosed = () => {
    if (removeClassTimeout !== null || !isKeyboardOpen()) return;
    focusedFieldCount--;
    if (focusedFieldCount > 0) return;
    removeClassTimeout = window.setTimeout(() => {
        removeClassTimeout = null;
        document.body.classList.remove(keyboardOpenClass);
        keyboardOpenCallback?.(false);
        // Fix for issue where dismissing the keyboard leaves the viewport shifted
        // even though the screen looks right
        window.scrollBy(0, 0);
    }, 100);
};

const isHidden = (element: HTMLElement) => {
    return (
        element.getAttribute("aria-hidden") === "true" ||
        element.style.opacity === "0" ||
        element.style.visibility === "hidden" ||
        element.style.display === "none"
    );
};

const isKeyboardInput = (element: HTMLElement) => {
    if (element.tagName === "TEXTAREA") return true;
    if (element.tagName !== "INPUT") return false;
    const input = element as HTMLInputElement;
    return input.type === "text" || input.type === "email" || input.type === "tel";
};

export const showsKeyboard = (element: HTMLElement | null) => element && isKeyboardInput(element) && !isHidden(element);

export const hidesKeyboard = (element: HTMLElement | null) =>
    element && element !== hideKeyboardInput && isKeyboardInput(element) && isHidden(element);

const hideKeyboardInput = document.createElement("input");
hideKeyboardInput.setAttribute("type", "text");
hideKeyboardInput.setAttribute("style", "position:absolute;opacity:0;");
hideKeyboardInput.addEventListener("focus", () => {
    registerKeyboardClosed();
    hideKeyboardInput.setAttribute("readonly", "readonly");
    document.body.removeChild(hideKeyboardInput);
});

export const hideKeyboard = () => {
    document.body.appendChild(hideKeyboardInput);
    hideKeyboardInput.focus();
};
