import { createOperation } from "../operations";

export const fetchProfileOperation = createOperation<void>("ACCOUNT/FETCH_PROFILE");
export const saveProfileOperation = createOperation<void>("ACCOUNT/SAVE_PROFILE", { showSpinner: true });
export const deleteAccountOperation = createOperation<void>("ACCOUNT/DELETE_REQUESTED", { showSpinner: true });
export const logoutOperation = createOperation<void>("ACCOUNT/LOGOUT");
export const fetchFoodPreferencesOperation = createOperation<void>("ACCOUNT/FETCH_FOOD_PREFERENCES");
export const saveFoodPreferencesOperation = createOperation<void>("ACCOUNT/SAVE_FOOD_PREFERENCES", {
    showSpinner: true,
});
