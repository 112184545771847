import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "src/features";
import { getIsOpenTable } from "src/features/openTable/selectors";
import { modalMessages } from "src/features/modalMessage/messages";
import { getSurveyDetails } from "src/features/survey/selectors/survey";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { CollapsibleSection, Rating, Text, Thumbs } from "src/sharedComponents";
import { actions } from "src/features/survey/actions";
import { getRating } from "src/features/survey/selectors/rating";
import { sendSurvey } from "src/features/survey/actions/sendSurvey";
import { payOnlySendFeedback } from "../actions/payOnlySubmitFeedback";
import { getPayOnlyFlagsState } from "../selectors";
import { getTableLabel } from "src/features/order/selectors";

const FEEDBACK_TITLE = "How was your experience paying the bill?";
const THANKS_TITLE = "Thanks for your feedback!";

export function openFeedbackModal(onSubmit: (rating: Rating) => void) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(
            showModalMessage(
                modalMessages.feedbackSurvey({
                    hasSurveyDetails: true,
                    shouldValidate: true,
                    primaryAction: () => {
                        const rating = getRating(getState());
                        onSubmit(rating);
                    },
                    isExpanded: true,
                    useLocationTheme: true,
                    onClose: () => {
                        dispatch(actions.resetRating());
                    },
                    tableLabel: getTableLabel(getState()),
                })
            )
        );
    };
}

const useFeedback = () => {
    const [isSent, setIsSent] = useState(false);
    const [ratingSent, setRatingSent] = useState<Rating | null>(null);
    const isOpenTable = useSelector(getIsOpenTable);
    const { showFeedback } = useSelector(getPayOnlyFlagsState);
    const surveyDetails = useSelector(getSurveyDetails);
    const rating = useSelector(getRating);
    const dispatch = useDispatch();

    const handleOnRate = useCallback(
        (newRating: Rating) => {
            if (isOpenTable) {
                dispatch(actions.setRating(newRating));
                dispatch(
                    openFeedbackModal((rating) => {
                        setIsSent(true);
                        setRatingSent(rating);
                        dispatch(sendSurvey());
                    })
                );
            } else {
                setIsSent(true);
                setRatingSent(newRating);
                dispatch(payOnlySendFeedback(FEEDBACK_TITLE, newRating));
            }
        },
        [isOpenTable, dispatch]
    );

    useEffect(() => {
        if (isSent) setTimeout(() => setIsSent(false), 2000);
    }, [isSent]);

    return {
        isAvailable: isSent || (isOpenTable ? !!surveyDetails : showFeedback),
        title: ratingSent !== null ? THANKS_TITLE : isOpenTable && surveyDetails ? surveyDetails.title : FEEDBACK_TITLE,
        rating: isOpenTable ? (ratingSent !== null ? ratingSent : rating) : undefined,
        onRate: handleOnRate,
    };
};

export const PayOnlyFeedback = () => {
    const { isAvailable, title, rating, onRate } = useFeedback();

    return (
        <CollapsibleSection
            show={isAvailable}
            collapsibleElement={
                <div className="pay-only-receipt__feedback">
                    <Text className="pay-only-receipt__feedback__title" preset="g-16" mode={["bold", "block"]}>
                        {title}
                    </Text>
                    <div className="pay-only-receipt__feedback__icons">
                        <Thumbs locationTheme showSelectedOnly rating={rating} onThumbClick={onRate} />
                    </div>
                </div>
            }
        />
    );
};
