import { IAppInsights } from "@microsoft/applicationinsights-web";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { IPropertiesPlugin, PropertiesPluginIdentifier } from "@microsoft/applicationinsights-common";

export const findAppInsightsPlugin = (extensions: IPlugin[]) =>
    findPlugin<IAppInsights>("ApplicationInsightsAnalytics", extensions);

export const findPropertiesPlugin = (extensions: IPlugin[]) =>
    findPlugin<IPropertiesPlugin>(PropertiesPluginIdentifier, extensions);

export function findPlugin<T>(identifier: string, extensions: IPlugin[]): T | undefined {
    const ext = extensions.find((ext) => ext.identifier === identifier);

    return ext ? (ext as any as T) : undefined;
}
