import { FeatureConfiguration } from "..";
import { reducer as foodPreferencesReducer } from "./reducers/foodPreferences";
import { profileReducer } from "./reducers/profile";
import { reducer as accountMenuReducer } from "./reducers/accountMenu";

export * from "./types";

export * from "./actions";
export * from "./reducers";
export { actionCreators } from "./reducers/foodPreferences";
export * from "./reducers";

export { actionCreators as accountMenuActions } from "./reducers/accountMenu";

export default function (config: FeatureConfiguration) {
    config.reducers.foodPreferences = foodPreferencesReducer;
    config.reducers.profile = profileReducer;
    config.reducers.accountMenu = accountMenuReducer;
}
