import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { href, scrolling } from "src/common/experience";
import { trimInput } from "src/common/shared";
import { validationRules } from "src/common/validation";
import { AccountProfile, saveProfile } from "src/features/accountmenu";
import { EditPhoneInput } from "src/features/signup/components/EditPhoneInput";
import { FormControl, Input, isValidPhoneNumber, Text } from "src/sharedComponents";
import "../assets/AccountDetails.scss";
import { TappableAnchor } from "src/sharedComponents/common/tappable";
import { LegalModal } from "src/features/compliance/components/LegalModal";
import { actionCreators } from "../reducers/authState";
import classNames from "classnames";
import { getPhone, getSignInPage } from "src/features/signup/selectors";
import { NetworkConnectedButton } from "src/features/notifications/components/NetworkConnectedButton";
import { actionCreators as partyActions } from "src/features/order/reducers/party";
import { getProfile } from "../../accountmenu/selectors";
import { getIsUnverified } from "../selectors";

export interface VenueDetails {
    venueName?: string;
    legalAgreementMessage?: string;
    privacyPolicy?: string;
}

export interface Props {
    standalone?: boolean;
    onSuccess: () => void;
    submitText?: string;
    title?: string;
    subtitle?: string;
    requireEmail?: boolean;
    venueDetails?: VenueDetails;
    containerRef?: RefObject<HTMLDivElement>;
    flowName: string;
    hideEmailMessage?: boolean;
    hidePhone?: boolean;
    animateTopLevel?: boolean;
    emailMessage?: string;
}

const SIGN_UP_BUTTON_HEIGHT = "84px";

export const AccountDetails = ({
    standalone,
    title,
    subtitle,
    submitText,
    requireEmail,
    onSuccess,
    venueDetails,
    containerRef,
    flowName,
    hideEmailMessage,
    hidePhone,
    animateTopLevel,
    emailMessage,
}: Props) => {
    const dispatch = useDispatch();

    const { firstName, lastName, email } = useSelector(getProfile) || {};
    const phone = useSelector(getPhone);
    const isUnverified = useSelector(getIsUnverified);

    const updateProfileData = useCallback(
        (profile: AccountProfile) => {
            dispatch(saveProfile(profile, onSuccess));
        },
        [dispatch, onSuccess]
    );

    const [showLegalModal, setShowLegalModal] = useState<"privacyPolicy" | undefined>();

    const {
        register,
        handleSubmit,
        errors,
        formState: { isValid },
        control,
        setValue,
        watch,
        trigger,
        reset,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            firstName,
            lastName,
            phone,
            email,
        },
    });

    const accountDetailsBI = useRef<{ dismissed: boolean; errors: string[]; flowName: string }>({
        dismissed: true,
        errors: [],
        flowName,
    });

    const { ref, inView } = useInView({
        threshold: 1,
        triggerOnce: true,
        rootMargin: `0px 0px -${SIGN_UP_BUTTON_HEIGHT}`,
    });

    const signInPage = useSelector(getSignInPage);
    const { privacyPolicy, venueName, legalAgreementMessage } = { ...venueDetails };

    useEffect(() => {
        if (signInPage === "Verify") {
            accountDetailsBI.current.dismissed = false;
        }
        if (signInPage === "Phone" || Object.keys(errors).length) {
            accountDetailsBI.current.dismissed = true;
        }
        accountDetailsBI.current.errors = Object.keys(errors)?.map(
            (error: any) => `${error} - ${errors[error].message}`
        );
    }, [isValid, errors, signInPage]);

    useEffect(() => {
        reset({ firstName, lastName, phone, email });
    }, [reset, firstName, lastName, phone, email]);

    useEffect(() => {
        const copyOfBIRef = accountDetailsBI;
        return () => {
            const { dismissed, errors: formattedErrors, flowName } = copyOfBIRef.current;
            dispatch(actionCreators.accountDetailsInteraction(dismissed, formattedErrors, flowName));
        };
    }, [dispatch]);

    const onPrivacyPolicyClicked = useCallback(
        (venuePolicy: boolean) => {
            if (venuePolicy) {
                dispatch(actionCreators.onPrivacyPolicyClicked(true, privacyPolicy));
            }
            dispatch(actionCreators.onPrivacyPolicyClicked(false));
        },
        [dispatch, privacyPolicy]
    );

    const watchPhone = watch("phone");

    const onClickSubmit = () => {
        if (!isValid || (privacyPolicy && !inView)) {
            dispatch(partyActions.trackDisabledButtonClicked(flowName));
        }
        if (privacyPolicy && !inView && containerRef?.current) {
            return containerRef.current.scrollTo({
                top: containerRef.current.clientHeight,
                behavior: "smooth",
            });
        }
        if (isValid) {
            accountDetailsBI.current.dismissed = false;
        }
        return handleSubmit((profile) => {
            updateProfileData({
                ...profile,
                phone: profile.phone || phone,
            });
        })();
    };

    const buttonText =
        privacyPolicy && !inView
            ? "View terms & sign up"
            : submitText || (standalone ? "Save changes" : "Save details");

    const emailMessageText = useMemo(() => {
        if (hideEmailMessage) {
            return "";
        }
        if (emailMessage) {
            return emailMessage;
        }
        return "Add your email to get email order receipts";
    }, [hideEmailMessage, emailMessage]);

    return (
        <form
            onSubmit={(event) => event.preventDefault()}
            className={classNames("account-details", animateTopLevel && "animated-child")}
        >
            <div className={classNames("scroll-element", !animateTopLevel && "animated-child")}>
                <div className="account__title profile-page__title">
                    <Text preset="title-28" mode="bold">
                        {title || (standalone ? "Manage details" : "Confirm your details")}
                    </Text>
                    {(standalone || subtitle) && (
                        <Text preset="g-14" className="account__title__subtitle" mode="block">
                            {standalone ? "Adjust your me&u account details" : subtitle}
                        </Text>
                    )}
                </div>
                <FormControl
                    id="account_firstName"
                    invalid={!!errors.firstName}
                    invalidMessage={errors.firstName?.message}
                >
                    <Input
                        ref={register({ ...validationRules.name, ...validationRules.firstName })}
                        name="firstName"
                        label="First name"
                        placeholder="First name"
                        onBlur={(e) => e?.target && trimInput(e.target)}
                        onFocus={(e) => e?.target && scrolling.scrollElementIntoView(e.target)}
                        onClear={() => {
                            setValue("firstName", undefined, { shouldValidate: true, shouldDirty: !!firstName });
                        }}
                    />
                </FormControl>
                <FormControl
                    id="account_lastName"
                    invalid={!!errors.lastName}
                    invalidMessage={errors.lastName?.message}
                >
                    <Input
                        ref={register({ ...validationRules.name })}
                        name="lastName"
                        label="Last name"
                        placeholder="Last name"
                        onBlur={(e) => e?.target && trimInput(e.target)}
                        onFocus={(e) => e?.target && scrolling.scrollElementIntoView(e.target)}
                        onClear={() => {
                            setValue("lastName", undefined, { shouldValidate: true, shouldDirty: !!lastName });
                        }}
                    />
                </FormControl>
                <FormControl
                    id="account_email"
                    invalid={!!errors.email}
                    message={emailMessageText}
                    invalidMessage={errors.email?.message}
                >
                    <Input
                        type="email"
                        ref={register({
                            ...validationRules.email,
                            required: requireEmail
                                ? {
                                      value: true,
                                      message: "Required",
                                  }
                                : undefined,
                        })}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        onBlur={(e) => e?.target && trimInput(e.target)}
                        onFocus={(e) => e?.target && scrolling.scrollElementIntoView(e.target)}
                        onClear={() => {
                            setValue("email", undefined, { shouldValidate: true, shouldDirty: !!email });
                        }}
                    />
                </FormControl>
                {!hidePhone && (
                    <EditPhoneInput
                        phone={phone}
                        watchValue={watchPhone}
                        disabled={!isUnverified}
                        id="account_phone"
                        error={errors.phone?.message}
                        message={
                            isUnverified
                                ? "For SMS notifications about your order"
                                : "We’ve already verified your phone number"
                        }
                        control={control}
                        validateOnChange={() => isValidPhoneNumber(watchPhone) && trigger("phone")}
                        onClear={() => setValue("phone", "", { shouldValidate: true, shouldDirty: !!phone })}
                        onFocus={(e) => e.target && scrolling.scrollElementIntoView(e.target)}
                    />
                )}
                {!!privacyPolicy && (
                    <div ref={ref} className="account-details__privacy-policy">
                        <Text preset="g-12" mode="block">
                            {legalAgreementMessage} View our{" "}
                            <TappableAnchor
                                shouldPreventDefault
                                onTap={() => {
                                    onPrivacyPolicyClicked(false);
                                    setShowLegalModal("privacyPolicy");
                                }}
                                className="account-details__privacy-policy__link"
                            >
                                Privacy Policy
                            </TappableAnchor>{" "}
                            and {venueName}{" "}
                            <TappableAnchor
                                onTap={() => onPrivacyPolicyClicked(true)}
                                className="account-details__privacy-policy__link"
                                {...href.openInNewWindowAttributes(privacyPolicy)}
                            >
                                Privacy Policy
                            </TappableAnchor>{" "}
                            to find out more about how each of us uses and discloses your information.
                        </Text>
                    </div>
                )}
            </div>
            <div className="account-details__submit profile-page__submit top-shadow">
                <div className="account-details__submit__wrapper">
                    <NetworkConnectedButton
                        type="submit"
                        value={buttonText}
                        className={classNames(!isValid || (privacyPolicy && !inView && "disabled"))}
                        onClick={onClickSubmit}
                    />
                </div>
            </div>
            <LegalModal
                legalPage={showLegalModal}
                goBack={() => {
                    setShowLegalModal(undefined);
                }}
            />
        </form>
    );
};
