import { AppDispatch } from "src/features";
import { getAnotherRoundOperation } from "../operations";
import { fetchAnotherRound } from "../api/fetchAnotherRound";
import { setInitialAnotherRound } from "./setAnotherRound";

export const getAnotherRound = (dispatch: AppDispatch) =>
    getAnotherRoundOperation.invoke(async () => {
        const anotherRound = await fetchAnotherRound();
        dispatch(setInitialAnotherRound(anotherRound));
        return anotherRound;
    }, dispatch);
