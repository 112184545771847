import { QrCodeErrors } from "src/common/experience/shared/qr";
import { PartyAction } from "src/features/order/reducers/party";
import { OperationBeginAction, OperationCompletedAction } from "../operations";
import { NativePausedAction, NativeResumedAction } from "../native/reducers/native";

export interface TableToken {
    partytype?: string;
    locationclaimtype?: string;
    locationclaimtoken?: string;
}

export interface OnboardingLoadingImages {
    backgroundImage: string;
    logoUrl: string;
}

export interface OnboardingImages {
    menuBackgroundImage?: string;
    loaderImages?: OnboardingLoadingImages;
}

export interface LocationMarkerClaim {
    type: "test" | "estimote" | "nfc" | "mobile" | "qr" | "party";
    token: string;
}

export type PartyErrors =
    | "InvalidSource"
    | "GuarantorNotFound"
    | "AccountInArrears"
    | "OutsideServiceHours"
    | "VenueNotFound"
    | "UnsupportedApiVersion"
    | "Timeout"
    | "UnknownError"
    | "MemberAlreadyPaid"
    | "TakeawayUnavailable"
    | "GetOrCreateTableOrderFailure"
    | QrCodeErrors
    | "Cancelled"
    | "InvalidFlexJoinCodeException";

export class PartyError extends Error {
    constructor(
        public errorCode?: PartyErrors,
        public additionalData?: string | null,
        public venueName?: string | null
    ) {
        super(`PartyError: ${errorCode}${additionalData ? ` (${additionalData})` : ""}`);
        // TODO: Look at setting an ES6 target so we can remove this
        Object.setPrototypeOf(this, PartyError.prototype);
    }
}

export type PartyOnboardingAction =
    | PartyAction
    | OperationCompletedAction
    | OperationBeginAction
    | NativePausedAction
    | NativeResumedAction;

export interface GetOrCreateTableOrderFailureAdditionalData {
    preOrderingModalTitle?: string;
    preOrderingModalText?: string;
    backgroundImage?: string;
}
