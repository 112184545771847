import {
    ApplePayPaymentMethod,
    AuthorizePaymentResult,
    getStripe,
    GooglePayPaymentMethod,
    paymentOptions,
    PaymentType,
} from "src/common/payment";
import { StripeApplePayPaymentMethod, StripeGooglePayPaymentMethod } from "src/features/paymentGateways/stripe/types";
import { canMakePayment } from "./getStripeDevicePaymentAvailable";
import {
    InitializeDevicePaymentMethodButtonActions,
    InitializeDevicePaymentMethodButtonFunc,
} from "../../../interface";
import { hideKeyboard } from "src/common/keyboard";
import { customGooglePayButtonId } from "./behaviors";

const initializeStripeDevicePaymentButton = async (
    paymentMethod: StripeApplePayPaymentMethod | StripeGooglePayPaymentMethod,
    total: number,
    { spinnerActions }: InitializeDevicePaymentMethodButtonActions,
    cancelPromise: Promise<void>
) => {
    const { apiKey, countryCode, currency } = paymentMethod;
    const stripe = await getStripe(apiKey);

    let showObserver: MutationObserver | undefined;
    let cancelled = false;

    const paymentRequest = stripe.paymentRequest({
        country: countryCode.toUpperCase(),
        currency: currency.toLowerCase(),
        total: {
            label: paymentOptions.label,
            amount: Math.floor(total * 100),
        },
    });

    const authorizePayment = () =>
        new Promise<AuthorizePaymentResult | null>(async (resolve, reject) => {
            try {
                paymentRequest.on("paymentmethod", ({ paymentMethod: { id }, complete }) => {
                    complete("success");
                    if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
                        spinnerActions.stop();
                        showObserver?.disconnect();
                    }
                    resolve({
                        paymentToken: id,
                    });
                });

                paymentRequest.on("cancel", () => {
                    if (paymentMethod.paymentType === PaymentType.GOOGLEPAY) {
                        spinnerActions.stop();
                        cancelled = true;
                        return;
                    }
                    resolve(null);
                });

                const { available } = await canMakePayment(paymentMethod, paymentRequest);

                if (!available) {
                    throw new Error("Can't make payment");
                }

                if (paymentMethod.paymentType === PaymentType.APPLEPAY) {
                    paymentRequest.show();
                    return;
                }

                // paymentRequest.show(); no longer works for Google Pay for some reason
                // but using the paymentRequestButton from elements does
                const paymentRequestButton = stripe.elements().create("paymentRequestButton", {
                    paymentRequest,
                    style: {
                        paymentRequestButton: {
                            height: "54px",
                        },
                    },
                    classes: {
                        focus: `${customGooglePayButtonId}--focus`,
                    },
                });

                paymentRequestButton.mount(`#${customGooglePayButtonId}`);
            } catch (err) {
                reject(err);
            }
        });

    if (paymentMethod.paymentType === PaymentType.APPLEPAY) {
        return authorizePayment;
    }

    const buttonContainer = document.getElementById(customGooglePayButtonId)!;

    showObserver = new MutationObserver(() => {
        if (!buttonContainer.classList.contains(`${customGooglePayButtonId}--focus`)) return;
        if (cancelled) {
            hideKeyboard(); // If cancel was called prior to this then just unfocus the button
            cancelled = false;
            return;
        }
        spinnerActions.start();
    });

    showObserver.observe(buttonContainer, {
        attributes: true,
        attributeFilter: ["class"],
    });

    cancelPromise.then(() => showObserver?.disconnect());

    return {
        authorizePayment,
        updateTotal: (total: number) => {
            paymentRequest.update({
                total: {
                    label: paymentOptions.label,
                    amount: Math.floor(total * 100),
                },
            });
        },
    };
};

export const initializeStripeApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = (
    applePayPaymentMethod,
    total,
    actions,
    cancelPromise
) =>
    initializeStripeDevicePaymentButton(
        applePayPaymentMethod as StripeApplePayPaymentMethod,
        total,
        actions,
        cancelPromise
    );

export const initializeStripeGooglePayButton: InitializeDevicePaymentMethodButtonFunc<GooglePayPaymentMethod> = (
    googlePayPaymentMethod,
    total,
    actions,
    cancelPromise
) =>
    initializeStripeDevicePaymentButton(
        googlePayPaymentMethod as StripeGooglePayPaymentMethod,
        total,
        actions,
        cancelPromise
    );
