import { AppDispatch, AppState } from "src/features";
import { connectMembership } from "../api/connectMembership";
import { fetchActions } from "../reducers/active";
import { actionCreators as loginActions } from "../reducers/login";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { connectMembershipOperation } from "../operations";
import { getParty } from "src/features/order";
import { getActiveLocationId } from "src/features/order/selectors";
import { orderApi } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";

export function connect(programId: string, getParams: () => Promise<FormData>, onError?: (e : ProblemDetailsError) => void) {
    return connectMembershipOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const locationId = getActiveLocationId(state);

        if (!locationId) {
            return;
        }

        try {
            const params = await getParams();
            const partyId = getParty(state)?.id;
            const newState = await connectMembership(programId, params, locationId);

            if (partyId) {
                await orderApi.invoke("setActiveMembershipProgram", programId, false);
            }

            dispatch(fetchActions.connected(newState));
        } catch (e) {
            if (e && e.message === "cancelled") {
                return;
            }

            if (e instanceof ProblemDetailsError) {
                onError && onError(e);
            } else {
                dispatch(loginActions.trackLoginFailed(programId, e?.message));
                dispatch(showModalMessage(modalMessages.connectMembershipFailed()));
            }

            throw e; // For app insights
        }
    });
}
