import "../assets/OrderHistoryDetails.scss";

import moment from "moment";
import React, { useCallback, useContext, useMemo } from "react";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { Text } from "src/sharedComponents";
import { MembershipIcon, PromotionsTagIcon } from "src/sharedComponents/assets/icons";
import { TaxesDetail } from "../../order/component/TaxesDetail";
import { BillAdjustment, BillAdjustmentType, TaxClassTotal } from "../../order";
import { config } from "../../../common/config";

export interface OrderTotalSummaryProps {
    tableTotal?: number;
    orderAmount?: number;
    amountAdded?: number;
    memberDiscount?: number;
    discount?: number;
    surcharge?: number;
    unallocatedAmount?: number;
    time?: string;
    taxSummary?: TaxClassTotal[];
    adjustments?: BillAdjustment[];
    surchargePercent?: number;
    gratuityFactor?: number;
    gratuityAmount?: number;
    total?: number;
    isTabOrder?: boolean;
    showTotal?: boolean;
    offerDiscountAmount?: number;
}

export const OrderTotalSummary = ({
    tableTotal,
    orderAmount,
    amountAdded,
    memberDiscount,
    discount,
    surcharge,
    unallocatedAmount,
    time,
    taxSummary,
    adjustments,
    surchargePercent = 0,
    gratuityFactor = 0,
    gratuityAmount,
    total,
    isTabOrder,
    showTotal,
    offerDiscountAmount,
}: OrderTotalSummaryProps) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const timeDisplay = useMemo(() => (time ? moment(time).format("h:mm a") : ""), [time]);

    const getAdjustment = useCallback(
        (type: BillAdjustmentType) => adjustments?.find((adj) => adj.type === type),
        [adjustments]
    );
    const promoCode = useMemo(() => getAdjustment(BillAdjustmentType.PROMOCODE), [getAdjustment]);
    const trayCharge = useMemo(() => getAdjustment(BillAdjustmentType.TRAY_CHARGE), [getAdjustment]);
    const venueCause = useMemo(() => getAdjustment(BillAdjustmentType.VENUE_CAUSE), [getAdjustment]);

    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    return (
        <Text preset="g-14" mode="block" className="order-history-details">
            {!!tableTotal && tableTotal !== orderAmount && (
                <div className="order-history-details__value">
                    <span>Item total</span>
                    <span>{menuDataLocale.formatCurrency(tableTotal)}</span>
                </div>
            )}
            {!!time && (
                <div className="order-history-details__value">
                    <span>Time</span>
                    <span>{timeDisplay}</span>
                </div>
            )}
            {!!amountAdded && (
                <div className="order-history-details__value">
                    <span>Amount added</span>
                    <span>{menuDataLocale.formatCurrency(amountAdded)}</span>
                </div>
            )}
            {!!offerDiscountAmount && (
                <div className="order-history-details__value order-history-details__value--offer-discount">
                    <span>
                        Offer <PromotionsTagIcon />
                    </span>
                    <span>{menuDataLocale.formatCurrency(-1 * Math.abs(offerDiscountAmount))}</span>
                </div>
            )}
            {!!memberDiscount && (
                <div className="order-history-details__value order-history-details__value--member-discount">
                    <span>
                        Deals <MembershipIcon />
                    </span>
                    <span>{`${menuDataLocale.formatCurrency(-1 * Math.abs(memberDiscount))}`}</span>
                </div>
            )}
            {!!discount && (
                <div className="order-history-details__value">
                    <span>Venue discount</span>
                    <span>{menuDataLocale.formatCurrency(-1 * Math.abs(discount))}</span>
                </div>
            )}
            {!!orderAmount && (
                <div className="order-history-details__value">
                    <span>Subtotal</span>
                    <span>{menuDataLocale.formatCurrency(orderAmount)}</span>
                </div>
            )}
            {!!promoCode && (
                <div className="order-history-details__value order-history-details__value--promotion">
                    <span className="order-history-details__value--promotion--name">
                        {promoCode.name} <PromotionsTagIcon />
                    </span>
                    <span>{menuDataLocale.formatCurrency(promoCode.value)}</span>
                </div>
            )}
            {!!surcharge && (
                <div className="order-history-details__value">
                    <span>
                        {menuDataLocale.surchargeText}
                        {surchargePercent > 0 ? ` (${menuDataLocale.formatPercent(surchargePercent)})` : ""}
                    </span>
                    <span>{menuDataLocale.formatCurrency(surcharge)}</span>
                </div>
            )}
            {!!venueCause && (
                <div className="order-history-details__value">
                    <span>{venueCause.name}</span>
                    <span>{menuDataLocale.formatCurrency(venueCause.value)}</span>
                </div>
            )}
            {!!trayCharge && (
                <div className="order-history-details__value">
                    <span>{trayCharge.name}</span>
                    <span>{menuDataLocale.formatCurrency(trayCharge.value)}</span>
                </div>
            )}
            {!!unallocatedAmount && (
                <div className="order-history-details__value">
                    <span>Unallocated items</span>
                    <span>{menuDataLocale.formatCurrency(unallocatedAmount)}</span>
                </div>
            )}
            <TaxesDetail taxSummary={taxSummary} isAlwaysExpanded={isTabOrder && orderHistoryUpdateEnabled} />
            {!!gratuityAmount && (
                <div className="order-history-details__value">
                    <span>
                        Gratuity
                        {gratuityFactor > 0 ? ` (${menuDataLocale.formatPercent(gratuityFactor)})` : ""}
                    </span>
                    <span>{menuDataLocale.formatCurrency(gratuityAmount)}</span>
                </div>
            )}
            {showTotal && !!total && (
                <div className="order-history-details__value">
                    <Text preset="g-16" mode={["bold", "block"]} value="Total" />
                    <Text preset="g-16" mode={["bold", "block"]} value={menuDataLocale.formatCurrency(total)} />
                </div>
            )}
        </Text>
    );
};
