import { downloadLoaderImagesOperation, downloadMenuBackgroundImageOperation } from "../operations";
import { preloadImage } from "../../../common/shared";
import { AppDispatch } from "../..";
import { onboardingActionCreators } from "../reducers";
import { isBrandedVenueLoaderEnabled } from "../util/isBrandedVenueLoaderEnabled";

export function downloadLoaderImages(backgroundImage?: string, logoUrl?: string) {
    return downloadLoaderImagesOperation.getThunk(async (dispatch: AppDispatch) => {
        if (backgroundImage && isBrandedVenueLoaderEnabled()) {
            await preloadImage(backgroundImage);
        }
        dispatch(onboardingActionCreators.setLoaderImages(backgroundImage || "", logoUrl || ""));
    });
}

export function downloadMenuBackgroundImage(menuBackgroundImage?: string) {
    return downloadMenuBackgroundImageOperation.getThunk(async (dispatch: AppDispatch) => {
        if (menuBackgroundImage) {
            await preloadImage(menuBackgroundImage);
        }
        dispatch(onboardingActionCreators.setMenuBackgroundImage(menuBackgroundImage || ""));
    });
}
