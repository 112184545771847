import { createSelector } from "reselect";
import { getParty } from "../../order/selectors";
import { PartyNotificationTier } from "../../order";
import { AppState } from "../../index";
import { getCurrentMemberId } from "src/features/accounts/selectors";

export const getViewedNotifications = (state: AppState) => state.party.viewedNotifications;

export const getShowNoNetworkConnection = (state: AppState) => state.notifications.showNoNetworkConnection;

export const getVisibleNotifications = createSelector(
    getParty,
    getViewedNotifications,
    getCurrentMemberId,
    (party, viewedNotifications, memberId) => {
        if (!party) return undefined;

        return party.notifications.filter(
            (n) =>
                (!n.includedMembers.length || n.includedMembers.indexOf(memberId) >= 0) &&
                (!n.excludedMembers.length || n.excludedMembers.indexOf(memberId) < 0) &&
                n.viewedMembers.indexOf(memberId) === -1 &&
                viewedNotifications.indexOf(n.id) === -1
        );
    }
);

export const getModalNotification = createSelector(
    getVisibleNotifications,
    (notifications) =>
        notifications && notifications.find((n) => n.tier === PartyNotificationTier.MODAL && !n.cancelled)
);

export const getToastNotifications = createSelector(
    getVisibleNotifications,
    (notifications) => notifications && notifications.filter((n) => n.tier === PartyNotificationTier.TOAST)
);
