import { orderApi } from "../../order/orderApi";
import { getAuthHeaders } from "src/common/auth/auth";
import { forceSendReceiptOperation } from "../operations";
import { AppDispatch } from "src/features";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { actionCreators as taxInvoiceActions } from "src/features/orderHistoryData/reducers/taxInvoice";

interface ForceSendingReceiptEmailOptions {
    email?: string;
    useLocationTheme?: boolean;
    isPayOnly?: boolean;
}

export function forceSendingReceiptEmail(partyId: string, options?: ForceSendingReceiptEmailOptions) {
    return forceSendReceiptOperation.getThunk(async (dispatch: AppDispatch) => {
        const body = new FormData();
        body.append("partyId", partyId);

        if (options?.email) {
            body.append("email", options.email);
        }

        dispatch(taxInvoiceActions.trackInvoiceSent());
        const response = await orderApi.send(`/party/forcesendingreceipt`, {
            method: "POST",
            body,
            headers: await getAuthHeaders(),
        });

        let email = null;

        if (response.ok) {
            email = await response.json();
        }

        if (email) {
            dispatch(
                showModalMessage(modalMessages.emailReceipt(email, options?.useLocationTheme, options?.isPayOnly))
            );
        } else {
            dispatch(showModalMessage(modalMessages.emailReceiptFailed(options?.useLocationTheme)));
        }
    });
}
