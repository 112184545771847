import { AppState } from "../../features";
import { createSelector } from "reselect";
import { NavBarType } from "./types";
import { getPayOnlyFailedToSubmitPayments } from "../../features/payOnly/selectors";
import { getOpenTableFailedToSubmitOrders } from "../../features/openTable/selectors/orders";

export const getNavBar = (state: AppState) => state.navBar;

export const getNavBarStatus = (state: AppState) => state.navBar.navBarStatus;

export const getOpenTableShowPayBadge = createSelector(
    getNavBar,
    getOpenTableFailedToSubmitOrders,
    getPayOnlyFailedToSubmitPayments,
    (navBar, hasFailedOrders, hasFailedPayments) =>
        navBar.navBarStatus !== NavBarType.PAY && !!(hasFailedOrders.length || hasFailedPayments.length)
);
