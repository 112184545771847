import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { getBanners, actionCreators } from "../../../features/banners";
import { Banner, BannerType, BannerNotification, BannerEvent } from "../../../sharedComponents/controls/banner";
import { StatusBar } from "../../../common/statusBar";
import { getVisibleMenuData } from "../../menu/selectors";
import { isLightColor } from "../../../common/shared";
import { AppState } from "../..";
import { NavBarType } from "../../../common/navigation/types";
import { getStaticBanners } from "../selectors";

import "./BannerNotifications.scss";

const cleanUpShowAnimation = (animation: AnimationEvent) => {
    if (animation.animationName === "move-to-position") {
        document.body.classList.remove("show-banner");
        document.body.removeEventListener("animationend", cleanUpShowAnimation);
        document.body.removeEventListener("animationcancel", cleanUpShowAnimation);
    }
};

export const BannerNotifications = () => {
    const banners = useSelector(getBanners);
    const hasStaticBanner = !!useSelector(getStaticBanners).length;
    const locationMenuData = useSelector(getVisibleMenuData)!;
    const navBarStatus = useSelector((state: AppState) => state.navBar.navBarStatus);
    const [isShowingBanner, setIsShowingBanner] = useState(false);
    const dispatch = useDispatch();
    const isMenuTypePage = navBarStatus === NavBarType.MENU;

    const cleanUpHideAnimation = useCallback(
        (animation: AnimationEvent) => {
            if (animation.animationName === "move-to-position-reverse") {
                document.body.classList.remove("hide-banner");
                document.body.removeEventListener("animationend", cleanUpHideAnimation);
                document.body.removeEventListener("animationcancel", cleanUpHideAnimation);
                dispatch(actionCreators.resetBanners());
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (hasStaticBanner && !isShowingBanner) {
            document.body.addEventListener("animationend", cleanUpShowAnimation);
            document.body.addEventListener("animationcancel", cleanUpShowAnimation);
            setIsShowingBanner(true);
            document.body.classList.add("show-banner");
        }

        if (!!banners.length && !hasStaticBanner && isShowingBanner) {
            document.body.addEventListener("animationend", cleanUpHideAnimation);
            document.body.addEventListener("animationcancel", cleanUpHideAnimation);
            setIsShowingBanner(false);
            document.body.classList.add("hide-banner");
        }
    }, [isShowingBanner, hasStaticBanner, cleanUpHideAnimation, banners]);

    const remove = useCallback(
        (banner: BannerNotification) => {
            const staticBanners = banners.filter(({ event }) => event === BannerEvent.STATIC);
            if (banner.event === BannerEvent.DYNAMIC || staticBanners.length > 1) {
                dispatch(actionCreators.removeBanner(banner.title));
            }
        },
        [dispatch, banners]
    );

    const bannerColourMap = {
        [BannerType.DEFAULT]: "#000", // $color-grey-8
        [BannerType.PRIMARY]: isLightColor(locationMenuData.themeColor) ? "#fff" : "#000",
        [BannerType.WARNING]: "#FFF", // $color-warning
    };

    const color = bannerColourMap[banners?.[0]?.type || BannerType.DEFAULT];

    return (
        <span className="banner-notifications">
            {isShowingBanner && isMenuTypePage && <StatusBar backgroundColor={color} />}
            <div className={classNames("banner-notifications__container", isMenuTypePage && "active-page")}>
                {banners.map((banner) => (
                    <Banner
                        key={banner.title}
                        removeBanner={() => remove(banner)}
                        noAnimation={banners.length === 1 && banner.event === BannerEvent.STATIC}
                        {...banner}
                    />
                ))}
            </div>
        </span>
    );
};
