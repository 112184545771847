// This import can be shortened to "../../accountmenu"
// but doing so crashes the app so leave it as is
import { profileActionCreators } from "../../accountmenu/reducers";
// This needs to be imported before disconnectParty otherwise the app will crash
import { leaveTable } from "../../partyOnboarding/actions/leaveTable";

import { AppDispatch, AppState } from "../../index";
import { disconnectParty, joinParty, upgradeParty } from "../../partyOnboarding/api";
import { saveTableToken } from "../../partyOnboarding/persistence/tableToken";
import { easyReorderActions } from "../../easyReorderItem/reducers";
import { replace } from "connected-react-router";
import { joiningTableOperation } from "../../partyOnboarding/operations";
import { getABTests } from "../../abtests/actions/getABTests";
import { orderApi } from "../../order/orderApi";

// The member has just signed in and now potentially has a different member id
// We need to 'upgrade' the party to switch the member and receive a new party
// JWT that references the new member, and then we need to 'rejoin' the party
// to everything is fine
export const onboard = async (getState: () => AppState, dispatch: AppDispatch, partyToken: string) => {
    try {
        // Stop listening for party updates as upgradeParty modifies the party
        // but we don't want the updated version until we disconnect and rejoin
        orderApi.removeMethodSubscription("partyUpdated");

        const { access_token: tableToken, easyReorderItems, profile } = await upgradeParty(partyToken);

        await disconnectParty(dispatch, true, "upgrade");

        saveTableToken(tableToken);

        dispatch(easyReorderActions.getEasyReorderItem(easyReorderItems));
        dispatch(profileActionCreators.fetchSuccess(profile));
        dispatch(getABTests());

        await joinParty(getState, dispatch, tableToken);
    } catch (err) {
        dispatch(leaveTable());
        dispatch(joiningTableOperation.actionCreators.failed(err));
        dispatch(replace("/join-table"));
        throw err;
    }
};
