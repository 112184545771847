import { getAuthHeaders } from "src/common/auth";
import { orderApi } from "src/features/order/orderApi";
import { ConnectedMembershipState } from "../types/MembershipState";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";

export async function connectMembership(programId: string, body: FormData, locationId: string) {
    const response = await orderApi.send(
        `/membership/${encodeURIComponent(programId)}?locationId=${encodeURI(locationId)}`,
        {
            method: "PUT",
            body: body,
            headers: await getAuthHeaders(),
        }
    );

    await ProblemDetailsError.throwError(response);

    const responseBody = await response.json();
    return responseBody as ConnectedMembershipState;
}
