import { trackedEvent } from "src/common/events/reduxEventTracking";
import { getTopPage } from "src/common/navigation";

export type ManageMembershipsState = {
    showManageModal: boolean;
    signupProgramId?: string;
};

enum TypeKeys {
    SHOW_MANAGE_MEMBERSHIPS = "MANAGE_MEMBERSHIPS/SHOW",
    SET_SIGNUP_PROGRAM_ID = "MANAGE_MEMBERSHIPS/SET_ACTIVE",
}

export const manageMembershipsActions = {
    showManageMemberships: (show: boolean) =>
        trackedEvent({ type: TypeKeys.SHOW_MANAGE_MEMBERSHIPS, show, current_page: getTopPage() }),
    setSignupProgramId: (programId?: string) => ({ type: TypeKeys.SET_SIGNUP_PROGRAM_ID, programId }),
};

type ShowManageMembershipsAction = { type: TypeKeys.SHOW_MANAGE_MEMBERSHIPS; show: boolean };
type SetSignupProgramId = { type: TypeKeys.SET_SIGNUP_PROGRAM_ID; programId?: string };

type ManageMembershipsActions = ShowManageMembershipsAction | SetSignupProgramId;

const defaultState: ManageMembershipsState = {
    showManageModal: false,
};

export default function reducer(
    state: ManageMembershipsState = defaultState,
    action: ManageMembershipsActions
): ManageMembershipsState {
    if (action.type === TypeKeys.SHOW_MANAGE_MEMBERSHIPS) {
        return {
            ...state,
            showManageModal: action.show,
        };
    }
    if (action.type === TypeKeys.SET_SIGNUP_PROGRAM_ID) {
        return {
            ...state,
            signupProgramId: action.programId,
        };
    }

    return state;
}
