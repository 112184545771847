import React from "react";
import { sharedErrorBoundaryMessages } from "../shared/errorBoundaryMessages";

export const errorBoundaryMessages = {
    ...sharedErrorBoundaryMessages,
    description: (
        <>
            Please reload the app to continue ordering.
            <br />
            If you continue to see this page, try again later.
        </>
    ),
    action: "Reload",
};
