import { Region } from "./types";
import { setHeader } from "../order/orderApi/util/headers";
import { orderApi } from "../order/orderApi";
import { apiBackoff } from "../order/orderApi/serverOrderApi";
import { connectivity } from "src/common/experience";
import { getLegacyRegion } from "./utils/getLegacyRegion";

type RegionChangedListener = (region: Region) => void;

class RegionHelper {
    private _region: Region | null = null;
    private resolve = () => {};
    private _regionPromise: Promise<void> | null = new Promise<void>((resolve) => (this.resolve = resolve));
    private regionChangedListeners: RegionChangedListener[] = [];

    onRegionChanged(listener: RegionChangedListener) {
        this.regionChangedListeners.push(listener);
    }

    get region() {
        return this._region!;
    }

    get isUK() {
        return this._region?.id === "gb";
    }

    get isUS() {
        return this._region?.id === "us";
    }

    get isAU() {
        return this._region?.id === "au";
    }

    set region(region: Region) {
        this._region = region;

        for (const listener of this.regionChangedListeners) {
            listener(region);
        }

        this.resolve();
        this._regionPromise = null;
    }

    get regionPromise() {
        return this._regionPromise;
    }

    init = async (regionId: string | null) => {
        if (this._regionPromise === null) {
            this._regionPromise = new Promise<void>((resolve) => (this.resolve = resolve));
        }

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        if (regionId !== null) {
            headers.set("x-meandu-region", regionId);
        }

        try {
            const response = await orderApi.send(
                "/region",
                {
                    method: "GET",
                    headers,
                },
                apiBackoff(2)
            );

            this.region = (await response.json()) as Region;
            return true;
        } catch {
            connectivity.onOnline(function () {
                if (regionHelper.region.isFake) {
                    regionHelper.init(regionId);
                }
            });

            this.region = {
                isFake: true,
                id: regionId || getLegacyRegion(),
                displayName: "",
                isGdpr: false,
                locationDataUrl: "",
            };
            return false;
        }
    };

    addRegionHeaders = async (init?: RequestInit) => {
        if (this._regionPromise) {
            await this._regionPromise;
        }

        if (this.region.isFake) {
            await this.init(this.region.id);
        }

        init = init || {};

        setHeader(init, "x-meandu-region", this.region.id);

        return init;
    };
}

export const regionHelper = new RegionHelper();
