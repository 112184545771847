import { orderApi } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { getTableTokenHeaders } from "src/features/partyOnboarding/persistence/tableToken";

export async function fetchAvailableOffers() {
    const headers = await getTableTokenHeaders();
    headers.append("Content-Type", "application/json");
    const response = await orderApi.send("/campaign/availableOffers", {
        method: "GET",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return await response.json();
}
