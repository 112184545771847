import "./PayOnlyReceipt.scss";

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Text } from "src/sharedComponents";
import { CheckIcon } from "src/sharedComponents/assets/icons";
import { getAnyPartyDetails } from "src/features/order/selectors";
import { PayOnlyReceiptInfo } from "./PayOnlyReceiptInfo";
import { getIsPayingMember } from "../../payment/selectors";
import { getMarketingInfo } from "../actions/getMarketingInfo";
import { memberProfileEmail } from "../actions/memberProfileEmail";
import { PayOnlyReceiptClosedMessages } from "./PayOnlyReceiptClosedMessages";
import { showGetReceipt } from "../actions/showGetReceipt";
import { PayOnlyFeedback } from "./PayOnlyFeedback";
import classNames from "classnames";
import { PayOnlyHeader, PayOnlyHeaderSpacer } from "./PayOnlyHeader";
import { getIsOpenTable } from "../../openTable/selectors";
import { actionCreators as payOnlyFlagsActionCreators } from "../reducers/payOnlyFlags";
import { PayOnlyCustomFeedback } from "src/features/payOnly/components/PayOnlyCustomFeedback";

export const PayOnlyPaidReceipt = () => {
    const partyDetails = useSelector(getAnyPartyDetails);
    const isPayingMember = useSelector(getIsPayingMember);
    const isOpenTable = useSelector(getIsOpenTable);

    const dispatch = useDispatch();

    const getReceipt = useCallback(() => {
        dispatch(showGetReceipt());
    }, [dispatch]);

    useEffect(() => {
        dispatch(memberProfileEmail());
        dispatch(getMarketingInfo());
        dispatch(payOnlyFlagsActionCreators.setRefreshing(false));
    }, [dispatch]);

    return (
        <>
            {isOpenTable && (
                <>
                    <PayOnlyHeader />
                    <PayOnlyHeaderSpacer />
                </>
            )}
            <div
                className={classNames(
                    "pay-only-receipt",
                    "pay-only-receipt__bill-paid",
                    isPayingMember && "paying-member"
                )}
            >
                <div className="pay-only-receipt__icon">
                    <CheckIcon />
                    <Text preset="title-28" mode={["block", "bold"]}>
                        Bill paid
                    </Text>
                    <Text preset="g-16" mode={["block"]}>
                        Thanks for dining{partyDetails?.location && ` at ${partyDetails.location}`}
                    </Text>
                </div>
                <PayOnlyFeedback />
                <PayOnlyCustomFeedback />
                <PayOnlyReceiptInfo />
                <PayOnlyReceiptClosedMessages />
                {isPayingMember && (
                    <div className="pay-only-receipt__footer">
                        <div className="pay-only-receipt__footer__container">
                            <Button onClick={getReceipt} value={"Get receipt"} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
