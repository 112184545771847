import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { IntersectionObserverRef, normalizeAccents } from "src/common/shared";
import { MenuItemButton, MenuItemImage } from "src/features/menuitem";
import { UnavailableIcon } from "src/sharedComponents/assets/icons";
import { Text } from "src/sharedComponents";
import { FuseMenuItem } from "../types";
import { WaitTimeBadge } from "../../menu/components/WaitTimeBadge";
import { getItemAvailability } from "../../menu/util/helpers";
import { useSelector } from "react-redux";
import { getVisibleMenuData } from "src/features/menu/selectors";

interface Props {
    item: FuseMenuItem;
    search?: RegExp;
    whenVisible: (itemId: string, availability: string) => void;
    itemRemoved: (itemId: string) => void;
}

const SEARCH_ITEM_IDENTIFIER = "search_item_";

export const MenuSearchLineItem = ({ item, search, whenVisible, itemRemoved }: Props) => {
    const [displayMatch, setDisplayMatch] = useState<RegExpMatchArray | null>(null);
    const observer = useRef<IntersectionObserverRef>({} as IntersectionObserverRef);
    const lineRef = useRef<HTMLDivElement>(null);

    const menuData = useSelector(getVisibleMenuData);

    useEffect(() => {
        setDisplayMatch((search && normalizeAccents(item.displayName)?.match(search)) ?? null);
    }, [search, item]);

    useEffect(() => {
        const observe = observer.current;
        const line = lineRef.current;
        if (line) {
            observe.data = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                const data = entries
                    .filter((e) => e.isIntersecting && e.target.id.startsWith(SEARCH_ITEM_IDENTIFIER))
                    .map((e) => e.target.id.replace(SEARCH_ITEM_IDENTIFIER, ""))
                    .join(",");
                if (data && line && item.id === data) {
                    const availability = getItemAvailability(item) || "true";
                    whenVisible(data, availability);
                    observe.data?.unobserve(line);
                }
            }, {});
            observe.data.observe(line);
        }
        return () => {
            if (line) {
                observe.data?.unobserve(line);
            }
        };
    }, [whenVisible, item]);

    useEffect(() => {
        return () => {
            itemRemoved(item.id);
        };
    }, [itemRemoved, item.id]);

    return (
        <MenuItemButton
            menuItemId={item.id}
            className={classNames(
                "menu-search-page__content__line-item",
                item.available === false && "search-item-unavailable"
            )}
        >
            {!menuData?.hideProductImages && (
                <div
                    className="menu-search-page__content__line-item__image"
                    ref={lineRef}
                    id={SEARCH_ITEM_IDENTIFIER + item.id}
                >
                    <MenuItemImage className={`${item.type}card__thumb`} images={item.images} name="thumb" />
                </div>
            )}
            <div className="menu-search-page__content__line-item__name">
                {displayMatch?.index === undefined || displayMatch.index < 0 || !search ? (
                    <Text className="menu-search-page__content__line-item__name--inner" preset="g-16" mode="medium">
                        {item.displayName}
                    </Text>
                ) : (
                    <Text className="menu-search-page__content__line-item__name--inner" preset="g-16" mode="medium">
                        {item.displayName.substr(0, displayMatch.index)}
                        <Text preset="g-16" mode="extra-bold" className="menu-search-page__content__line-item__match">
                            {item.displayName.substr(displayMatch.index, search?.source.length)}
                        </Text>
                        {item.displayName.substr(displayMatch.index + search?.source.length)}
                    </Text>
                )}
                {item.available === false && (
                    <Text preset="g-14" className="menu-search-page__content__line-item__unavailable">
                        <UnavailableIcon /> Unavailable
                    </Text>
                )}
                {item.available !== false && !!item.waitTime && (
                    <div className="menu-search-page__content__line-item__wait-time">
                        <WaitTimeBadge waitTime={item.waitTime} />
                    </div>
                )}
            </div>
        </MenuItemButton>
    );
};
