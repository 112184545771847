import { cordovaWindow } from "./cordova";
import { QrCodeError, SCAN_ERROR_DISMISS_DELAY, validateQrCodeContents } from "../shared/qr";
import { __ } from "../../strings";
import { CameraAccessDeniedMessage, QrScanStatus } from "../interface";
import { BarcodeType } from "src/sharedComponents/common/shared/interfaces";
import { config } from "src/common/config";

interface QRScannerStatusNew extends QRScannerStatus {
    showing: boolean;
    canChangeCamera: boolean;
}

const prepare = () =>
    new Promise<QRScannerStatus>((resolve, reject) => {
        cordovaWindow.QRScanner.prepare((err, status) => {
            if (err) {
                reject(err);
            } else {
                resolve(status);
            }
        });
    });

const show = () => new Promise<QRScannerStatus>((resolve) => cordovaWindow.QRScanner.show(resolve));

const scan = () =>
    new Promise<string>((resolve, reject) => {
        cordovaWindow.QRScanner.scan((err, contents) => {
            if (err) {
                reject(err);
            } else {
                resolve(contents);
            }
        });
    });

const destroy = () => cordovaWindow.QRScanner.destroy();

const cancel = () => cordovaWindow.QRScanner.cancelScan();

const getStatus = () => new Promise<QRScannerStatus>((resolve) => cordovaWindow.QRScanner.getStatus(resolve));

let cancelled = false;
let timer: number | undefined;

export const qr = {
    findQRCode: async (barcodeType: BarcodeType, onStatusChanged: (status: QrScanStatus) => void) => {
        cancelled = false;

        try {
            let status = (await prepare()) as QRScannerStatusNew;
            if (!status.authorized || status.denied) {
                throw new QrCodeError("QrCodeCameraAccessDenied");
            }

            status = (await show()) as QRScannerStatusNew;
            if (!status.showing) {
                destroy();
                throw new QrCodeError("QrCodeUnknownError");
            }

            onStatusChanged("cameraReady");

            let lastContents: string | null = null;

            const clearError = () => {
                lastContents = null;
                onStatusChanged("waiting");
            };

            while (!cancelled) {
                const contents = await scan();
                clearTimeout(timer);

                const isValid = barcodeType !== BarcodeType.QRCODE || validateQrCodeContents(contents);

                if (isValid) {
                    return contents;
                } else if (contents !== lastContents) {
                    onStatusChanged("error");
                }

                lastContents = contents;
                timer = window.setTimeout(clearError, SCAN_ERROR_DISMISS_DELAY);
            }

            return "";
        } catch (err) {
            clearTimeout(timer);
            if (err instanceof QrCodeError) throw err;
            if ("name" in err && err.name === "CAMERA_ACCESS_DENIED") throw new QrCodeError("QrCodeCameraAccessDenied");
            throw new QrCodeError("QrCodeUnknownError");
        }
    },
    cancelFindQRCode: async () => {
        cancelled = true;
        clearTimeout(timer);
        cancel();
        destroy();
    },
    requiresTargetElement: false,
    getCameraAccessDeniedMessage: async (barcodeType: BarcodeType) => {
        const status = await getStatus();

        const message: CameraAccessDeniedMessage = {
            text: __("To scan a {barcodeType}code, go to Settings and allow me&u access to your camera.", {
                barcodeType: barcodeType === BarcodeType.QRCODE ? "QR " : "bar",
            }),
        };

        if (status.canOpenSettings && config.enableQrAccessDeniedOpenSettings === "1") {
            message.primaryAction = () => QRScanner.openSettings();
            message.primaryActionText = __("Open settings");
        }

        return message;
    },
    storeCameraAccessDenied: false,
};
