import { orderApi } from "../../order/orderApi";
import { setPayOnlyPaymentsSeenOperation } from "../operations";
import { actionCreators, getParty } from "../../order";
import { getAnyParty } from "../../order/selectors";
import { getCompletePayments, getCompleteRefunds } from "../../payment/selectors";
import { getCurrentMemberId } from "../../accounts/selectors";
import { getPaidWithServerTotalSeen } from "src/features/payOnly/selectors";

export const setPayOnlyPaymentsSeen = () =>
    setPayOnlyPaymentsSeenOperation.getThunk(async (dispatch, getState) => {
        const state = getState();
        let party = getParty(state);
        if (party) {
            await orderApi.invoke("setPayOnlyPaymentsSeen");
            return;
        }
        party = getAnyParty(state);
        if (!party) return;
        const payments = getCompletePayments(state);
        const refunds = getCompleteRefunds(state);
        const paidWithServerTotalSeen = getPaidWithServerTotalSeen(state);
        const currentMemberId = getCurrentMemberId(state);
        dispatch(
            actionCreators.viewPayOnlyParty({
                ...party,
                members: party.members.map((member) => {
                    if (member.memberId !== currentMemberId) return member;
                    return {
                        ...member,
                        payOnlyPaymentsSeen: payments.length + refunds.length,
                        payOnlyPaidWithServerTotalSeen: paidWithServerTotalSeen,
                    };
                }),
            })
        );
    });
