import { device } from "./device";
import { ApplePayPaymentMethod } from "../../payment";
import { AnyAction } from "redux";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";
import { getApplePayBehavior } from "./paymentGateways/behaviors";

export const applePay = {
    isAvailable: async (applePayPaymentMethod: ApplePayPaymentMethod, trackEvent: (action: AnyAction) => void) => {
        if (!device.isIOS) return false;

        const available = await getApplePayBehavior(applePayPaymentMethod)?.getAvailable(
            applePayPaymentMethod,
            trackEvent
        );

        return !!available;
    },
    initializeButton: (
        applePayPaymentMethod: ApplePayPaymentMethod,
        total: number,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        getApplePayBehavior(applePayPaymentMethod)!.initializeButton(
            applePayPaymentMethod,
            total,
            actions,
            cancelPromise
        ),
    vaultPaymentMethod: (
        applePayPaymentMethod: ApplePayPaymentMethod,
        actions: InitializeDevicePaymentMethodButtonActions,
        cancelPromise: Promise<void>
    ) =>
        getApplePayBehavior(applePayPaymentMethod)!.vaultPaymentMethod?.(
            applePayPaymentMethod,
            actions,
            cancelPromise
        ) ?? Promise.resolve(null),
    canVaultPaymentMethod: (applePayPaymentMethod: ApplePayPaymentMethod) =>
        !!getApplePayBehavior(applePayPaymentMethod)?.vaultPaymentMethod,
    completePayment: () => Promise.resolve(),
};
