import "../assets/PaymentButton.scss";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthorizePaymentResult, PaymentMethod, PaymentType, VerifiablePaymentMethod } from "src/common/payment";
import { ApplePayButton } from "./ApplePayButton";
import { GooglePayButton } from "./GooglePayButton";
import { PayPalCheckoutButton } from "./PayPalCheckoutButton";
import { CardPaymentButton } from "./CardPaymentButton";
import { useDispatch, useSelector } from "react-redux";
import {
    getIsAllowedZeroPayment,
    getIsOpeningTab,
    getIsVerifyingCard,
    getMemberFailedPayments,
    getNewCardPaymentMethod,
    getPaymentMethods,
    getPaymentTotal,
    getSelectedPaymentMethod,
    getShouldContextSwitchCancelPayment,
    getTipsEnabled,
} from "../selectors";
import { Operation } from "../../operations/types";
import { Button } from "src/sharedComponents";
import { modalMessages } from "../../modalMessage/messages";
import { getIsAppConnected, getIsConnected, getParty } from "../../order/selectors";
import { showModalMessage } from "../../modalMessage/actions/show";
import { MenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import { GroupTabPaymentButton } from "./GroupTabPaymentButton";
import { disableGroupTabPackageAction } from "../../groupTabs/actions/disableGroupTabPackageAction";
import { actionCreators as paymentActions, actionCreators as paymentActionCreators } from "../reducers";
import {
    ButtonPaymentFunc,
    Payment,
    PaymentFunc,
    ValidateFunc,
    VerificationCancelledError,
    VerificationFailedError,
} from "../types";
import { ProblemDetailsError } from "../../order/orderApi/ProblemDetailError";
import { HandledError } from "src/common/error";
import { NativeBackButton, replayHandlers } from "src/common/navigation";
import { useHistory } from "react-router";
import { cardVerification } from "src/common/experience";
import { PartyType } from "../../order";
import { timeout } from "src/common/shared";
import { resetModalMessage } from "../../modalMessage/actions/reset";
import { getTabType, getTabTypeName } from "../../groupTabs/selectors/activeGroupTab";
import { TipSelectionModal } from "../../tip/components/TipSelectionModal";
import { NetworkConnectedButton } from "../../notifications/components/NetworkConnectedButton";
import { actionCreators as orderHeadCountActions } from "src/features/order/reducers/orderHeadCount";
import { OrderHeadCountModal } from "src/features/order/component/OrderHeadCountModal";
import { getIsOrderHeadCountEnabled } from "src/features/order/selectors/restaurantFlags";
import { getAddCardBehavior, getVerifyCardBehavior } from "src/features/paymentGateways";

interface Props {
    onPayment: PaymentFunc;
    value: string;
    valueIncludesTotal?: boolean;
    payingValue: string;
    operation: Operation<any>;
    allowTips?: boolean;
    tipRecipients?: string;
    changePaymentMethod: () => void;
    disabled?: boolean;
}

export const PaymentButton = (props: Props) => {
    const {
        onPayment,
        value,
        valueIncludesTotal = false,
        payingValue,
        operation,
        allowTips = true,
        tipRecipients,
        changePaymentMethod,
        disabled,
    } = props;

    const menuDataLocale = useContext(MenuDataLocaleContext);

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
    const newCardPaymentMethod = useSelector(getNewCardPaymentMethod);
    const paymentMethods = useSelector(getPaymentMethods);
    const tipsEnabled = useSelector(getTipsEnabled);
    const paymentStatus = useSelector(operation.getStatus);
    const paymentError = useSelector(operation.getError);
    const paying = paymentStatus === "processing" || paymentStatus === "complete";
    const connected = useSelector(getIsConnected);
    const appConnected = useSelector(getIsAppConnected);
    const shouldContextSwitchCancelPayment = useSelector(getShouldContextSwitchCancelPayment);
    // Switching context on a mobile to retrieve information from another app can cause SignalR to
    // disconnect temporarily which causes the CardPaymentButton to unmount and cancels the payment process
    // so don't replace the payment button in certain cases if SignalR disconnects but we are still online
    const offline = !connected && (shouldContextSwitchCancelPayment || !appConnected);
    const total = useSelector(getPaymentTotal);
    const failedPayments = useSelector(getMemberFailedPayments);
    const verifyingCard = useSelector(getIsVerifyingCard);
    const party = useSelector(getParty);
    const tabTypeName = useSelector(getTabTypeName);
    const tabType = useSelector(getTabType);
    const isOpeningTab = useSelector(getIsOpeningTab);
    const orderHeadCountEnabled = useSelector(getIsOrderHeadCountEnabled);
    const isAllowedZeroPayment = useSelector(getIsAllowedZeroPayment);

    const shouldShowPaymentFailed = useRef(false);
    const failedPaymentsLength = useRef(failedPayments.length);
    const latestFailedPayment = useRef<Payment | null>(null);
    const abortController = useRef<AbortController>();
    const verificationTotal = useRef(total);

    const [canShowPaying, setCanShowPaying] = useState(true);

    const showOrderHeadCountModal = useCallback(() => {
        setCanShowPaying(false);
        dispatch(orderHeadCountActions.showOrderHeadCountModal(true));
    }, [dispatch]);
    const hideOrderHeadCountModal = useCallback(
        () => dispatch(orderHeadCountActions.showOrderHeadCountModal(false)),
        [dispatch]
    );

    const showTips = useCallback(() => {
        setCanShowPaying(false);
        dispatch(paymentActionCreators.showTipModal(true));
    }, [dispatch]);
    const hideTips = useCallback(() => dispatch(paymentActionCreators.showTipModal(false)), [dispatch]);

    const clearAddedCard = useCallback(() => {
        dispatch(paymentActionCreators.preselectPaymentMethod(newCardPaymentMethod));
        dispatch(paymentActionCreators.clearAddedCard());
        changePaymentMethod();
    }, [dispatch, changePaymentMethod, newCardPaymentMethod]);

    const clearGroupTab = useCallback(() => {
        dispatch(disableGroupTabPackageAction(true));
        dispatch(paymentActionCreators.clearPaymentMethods());
        changePaymentMethod();
    }, [dispatch, changePaymentMethod]);

    const showPaymentFailed = useCallback(
        (reason: string) => {
            let primaryAction = undefined;

            if (
                selectedPaymentMethod?.paymentType === PaymentType.ADDEDCARD ||
                (newCardPaymentMethod && getAddCardBehavior(newCardPaymentMethod)?.clearPaymentMethodOnFailure)
            ) {
                primaryAction = clearAddedCard;
            } else if (selectedPaymentMethod?.paymentType === PaymentType.CARD && selectedPaymentMethod.cvv) {
                primaryAction = changePaymentMethod;
            }

            dispatch(showModalMessage(modalMessages.paymentFailed(reason, primaryAction)));
        },
        [dispatch, clearAddedCard, selectedPaymentMethod, changePaymentMethod, newCardPaymentMethod]
    );

    const handleLatestFailedPayment = useCallback(() => {
        if (latestFailedPayment.current?.paymentGateway !== "GroupTab") {
            return showPaymentFailed(latestFailedPayment.current?.errorReason ?? "Unknown");
        }
        switch (latestFailedPayment.current.errorCode) {
            case "Closed":
                return dispatch(
                    showModalMessage(modalMessages.paymentFailedGroupTabClosed(clearGroupTab, tabTypeName))
                );
            case "Removed":
                return dispatch(
                    showModalMessage(modalMessages.paymentFailedGroupTabMemberRemoved(clearGroupTab, tabTypeName))
                );
            case "PaymentMethod":
            case "PaymentMethodOwner": {
                let primaryAction = undefined;
                if (latestFailedPayment.current.errorCode === "PaymentMethodOwner" && isOpeningTab) {
                    primaryAction =
                        selectedPaymentMethod?.paymentType === PaymentType.ADDEDCARD ||
                        (newCardPaymentMethod && getAddCardBehavior(newCardPaymentMethod)?.clearPaymentMethodOnFailure)
                            ? clearAddedCard
                            : changePaymentMethod;
                }

                return dispatch(
                    showModalMessage(
                        modalMessages.paymentFailedGroupTabPaymentMethod(
                            tabTypeName,
                            latestFailedPayment.current.errorCode === "PaymentMethodOwner"
                                ? latestFailedPayment.current.errorReason
                                : undefined,
                            primaryAction
                        )
                    )
                );
            }
            default:
                return dispatch(
                    showModalMessage(
                        modalMessages.paymentFailedGroupTabInsufficientFunds(tabType!, tabTypeName, changePaymentMethod)
                    )
                );
        }
    }, [
        dispatch,
        clearGroupTab,
        showPaymentFailed,
        tabTypeName,
        changePaymentMethod,
        tabType,
        isOpeningTab,
        clearAddedCard,
        selectedPaymentMethod,
        newCardPaymentMethod,
    ]);

    const primaryButtonText = useMemo(() => {
        if (paying) {
            return payingValue;
        }
        if (orderHeadCountEnabled) {
            return "Continue";
        }
        return "Continue to payment";
    }, [paying, payingValue, orderHeadCountEnabled]);

    useEffect(() => {
        if (failedPayments.length > failedPaymentsLength.current) {
            latestFailedPayment.current = failedPayments[failedPayments.length - 1];
            failedPaymentsLength.current = failedPayments.length;
        }
    }, [failedPayments]);

    useEffect(() => {
        if (paying) {
            hideTips();
            hideOrderHeadCountModal();
        }
    }, [paying, hideTips, hideOrderHeadCountModal]);

    useEffect(() => {
        if (paymentStatus === "processing") {
            shouldShowPaymentFailed.current = true;
        } else if (paymentStatus === "failed" && shouldShowPaymentFailed.current) {
            shouldShowPaymentFailed.current = false;
            if (paymentError instanceof ProblemDetailsError) {
                showPaymentFailed(paymentError.problemDetails.detail);
            } else if (paymentError instanceof HandledError) {
                // Ignore these errors as they are being handled
            } else {
                handleLatestFailedPayment();
            }
            latestFailedPayment.current = null;
        }
    }, [paymentStatus, paymentError, handleLatestFailedPayment, showPaymentFailed, dispatch]);

    const cancelVerifyCard = useCallback(() => {
        abortController.current?.abort();
    }, []);

    useEffect(() => {
        if (verifyingCard && offline) {
            cancelVerifyCard();
        }
    }, [verifyingCard, cancelVerifyCard, offline]);

    useEffect(() => {
        verificationTotal.current = total;
    }, [total]);

    const verifyCard = useCallback<ValidateFunc>(
        (result, mustValidate = false) =>
            new Promise<AuthorizePaymentResult | Error | undefined>((resolve) => {
                if (result instanceof Error || result?.validated) return resolve(result);

                const { paymentType, verificationMinAmount } = selectedPaymentMethod as PaymentMethod &
                    VerifiablePaymentMethod;

                if (mustValidate && (paymentType === PaymentType.ADDEDCARD || paymentType === PaymentType.GOOGLEPAY)) {
                    // We can't continue as we can't reuse the nonce so just fail this payment
                    // and ensure that this payment method will be verified next time round
                    const updatedPaymentMethods = paymentMethods?.map((paymentMethod) => {
                        if (
                            (paymentType === PaymentType.ADDEDCARD &&
                                paymentMethod.paymentType !== PaymentType.NEWCARD) ||
                            (paymentType === PaymentType.GOOGLEPAY &&
                                paymentMethod.paymentType !== PaymentType.GOOGLEPAY)
                        ) {
                            return paymentMethod;
                        }
                        const updatedPaymentMethod = {
                            ...paymentMethod,
                            verificationMinAmount: 0,
                        };
                        if (updatedPaymentMethod.paymentType === PaymentType.NEWCARD) {
                            updatedPaymentMethod.cardTypeVerificationMinAmounts = null;
                        }
                        return updatedPaymentMethod;
                    });

                    dispatch(
                        showModalMessage(
                            modalMessages.verifyCardRequired(() => {
                                if (paymentType === PaymentType.ADDEDCARD) {
                                    clearAddedCard();
                                }
                                if (updatedPaymentMethods) {
                                    dispatch(paymentActions.getPaymentMethodsComplete(updatedPaymentMethods));
                                }
                            })
                        )
                    );

                    return resolve(new VerificationFailedError());
                }

                const threeDSecureEnabled =
                    mustValidate ||
                    (typeof verificationMinAmount === "number" && verificationTotal.current >= verificationMinAmount);

                const verifyCardBehavior =
                    cardVerification.isSupported(selectedPaymentMethod!) &&
                    getVerifyCardBehavior(selectedPaymentMethod!);

                if (!verifyCardBehavior && mustValidate) {
                    // 3DS2 is not enabled for the app so if we get a 2099 we should just fail here
                    dispatch(showModalMessage(modalMessages.verifyCardFailed()));
                    return resolve(new VerificationFailedError());
                }

                if (!threeDSecureEnabled || !verifyCardBehavior || result?.extendedValidation === false) {
                    return resolve(result);
                }

                abortController.current = new AbortController();

                dispatch(
                    verifyCardBehavior.verifyCardAction(
                        async (result) => {
                            if (result) {
                                await timeout(1000); // Give the 3DS iframe time to go away
                            }

                            if (!result || result instanceof VerificationCancelledError) {
                                if (paymentType === PaymentType.ADDEDCARD) {
                                    dispatch(showModalMessage(modalMessages.verifyCardCancelled(clearAddedCard)));
                                } else {
                                    dispatch(resetModalMessage());
                                }
                                return resolve(new VerificationCancelledError());
                            }

                            if (result instanceof Error) {
                                dispatch(
                                    showModalMessage(
                                        modalMessages.verifyCardFailed(
                                            paymentType === PaymentType.ADDEDCARD ? clearAddedCard : undefined
                                        )
                                    )
                                );
                                return resolve(new VerificationFailedError(result.message));
                            }

                            // If we have redirected in the 3DS iframe our history will be affected so
                            // re-push all of our current backHandlers to pushState and replace the history
                            if (party) {
                                const path = (() => {
                                    if (party.type === PartyType.PAYONLY) return "/pay-only";
                                    if (party.type === PartyType.MULTIUSER) return "/pay";
                                    return "/menu/service";
                                })();

                                history.replace(path); // To be safe
                                replayHandlers();
                            }

                            resolve({
                                ...result,
                                validated: true,
                            });
                        },
                        abortController.current.signal,
                        result
                    )
                );
            }),
        [dispatch, selectedPaymentMethod, paymentMethods, party, history, clearAddedCard]
    );

    const onPaymentInternal = useCallback<ButtonPaymentFunc>(
        (result, completePayment) => onPayment(result, completePayment, verifyCard),
        [onPayment, verifyCard]
    );

    if (paying && canShowPaying) {
        return (
            <>
                <Button mode="solid" className="disabled" disabled value={payingValue} />
                {verifyingCard && (
                    <NativeBackButton preventBack={false} onPressed={cancelVerifyCard} name="#cancel-verify-card" />
                )}
            </>
        );
    }

    const getButtonValue = () => {
        if (total < 0 || (total === 0 && !isAllowedZeroPayment)) {
            return "Total too low";
        } else if (!selectedPaymentMethod && total > 0) {
            return "Add payment";
        } else {
            return `${value}${!valueIncludesTotal && total > 0 ? `: ${menuDataLocale.formatCurrency(total)}` : ""}`;
        }
    };

    const renderPaymentButton = (disabled: boolean = false) => {
        const buttonValue = getButtonValue();

        if (total === 0 && isAllowedZeroPayment) {
            return (
                <NetworkConnectedButton
                    mode="solid"
                    onClick={() =>
                        dispatch(
                            onPaymentInternal({
                                paymentToken: "",
                                validated: true, // so we don't trigger verifyCard
                            })
                        )
                    }
                    value={buttonValue}
                />
            );
        }

        if (disabled || total <= 0 || !selectedPaymentMethod || offline) {
            return (
                <NetworkConnectedButton
                    mode="solid"
                    className="disabled"
                    disabled={disabled}
                    onClick={changePaymentMethod}
                    value={buttonValue}
                />
            );
        }

        switch (selectedPaymentMethod.paymentType) {
            case PaymentType.APPLEPAY:
                return <ApplePayButton onPayment={onPaymentInternal} />;
            case PaymentType.GOOGLEPAY:
                return <GooglePayButton onPayment={onPaymentInternal} />;
            case PaymentType.PAYPALCHECKOUT:
                return <PayPalCheckoutButton onPayment={onPaymentInternal} />;
            case PaymentType.GROUPTAB:
                return <GroupTabPaymentButton onPayment={onPaymentInternal} value={buttonValue} />;
            default:
                return <CardPaymentButton onPayment={onPaymentInternal} value={buttonValue} disabled={disabled} />;
        }
    };

    // bypasses tips/order enabled stages and renders payment button
    if (!selectedPaymentMethod || disabled || ((!tipsEnabled || !allowTips) && !orderHeadCountEnabled) || total <= 0) {
        return renderPaymentButton(disabled);
    }

    const renderOrderHeadCountCallToAction = () => {
        if (tipsEnabled && allowTips) {
            return (
                <NetworkConnectedButton
                    mode="solid"
                    onClick={() => {
                        hideOrderHeadCountModal();
                        showTips();
                    }}
                    value={"Continue"}
                />
            );
        }
        return renderPaymentButton(false);
    };

    return (
        <>
            <NetworkConnectedButton
                mode="solid"
                className={paying ? "disabled" : undefined}
                disabled={paying}
                onClick={orderHeadCountEnabled ? showOrderHeadCountModal : showTips}
                value={primaryButtonText}
            />
            <TipSelectionModal
                onClose={hideTips}
                onAfterClose={() => setCanShowPaying(true)}
                renderCallToAction={renderPaymentButton}
                tipRecipients={tipRecipients}
            />
            <OrderHeadCountModal
                onClose={hideOrderHeadCountModal}
                onAfterClose={!allowTips ? () => setCanShowPaying(true) : undefined}
                renderCallToAction={renderOrderHeadCountCallToAction}
            />
        </>
    );
};
