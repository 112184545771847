import { AppDispatch, AppState } from "src/features";
import { signInActions } from "..";
import { SignInOptions } from "../reducers/signIn";
import { getNextSignInPage, getSignInPage } from "../selectors";

export const signInAction = (options?: SignInOptions) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (getSignInPage(state)) return;
        dispatch(signInActions.setOptions(options));
        const page = getNextSignInPage(getState());
        if (page) {
            dispatch(signInActions.showPage(page));
        }
    };
};
