import { connect } from "react-redux";
import { AppDispatch, AppState } from "../..";
import { actionCreators, SelectedFilters } from "src/features/filter";
import { getSelectedUserPreferencesFilters } from "../../filter/selectors/getSelectedUserPreferenceFilters";
import { getUserPreferenceFilters } from "../../filter/selectors/getUserPreferenceFilters";
import { getActiveOrLastActiveService, getActiveServiceMenus, getVisibleMenuData } from "../../menu/selectors";
import { getStaticBanners } from "../../banners";
import { TagGroup } from "../../menudata";
import { Props, ServicePage } from "../components/ServicePage";
import {
    getActiveVenueLocationDetails,
    getCanShowDemoFeatures,
    getIsSectionTakeaway,
    getParty,
    getTableLabel,
} from "../../order/selectors";
import { pagePositionActions } from "../../pagePosition/reducers";
import { DataProps, FunctionProps } from "src/common/types/utils";
import { NavBarType } from "src/common/navigation/types";
import { getPriceResolver, PriceResolverProps } from "../../membership/selectors/getPriceResolver";
import { getGroupTabMenuCallToAction } from "src/features/groupTabs/selectors/activeGroupTab";
import { getDietaryInformationUrl } from "src/features/order/selectors/restaurantFlags";
import { getMenuBackgroundImage } from "src/features/partyOnboarding/selectors";
import {
    getFlexTabOwnerFirstName,
    getIsOpenTable,
    getIsOpenTableOrderingDisabled,
    getShowInviteToFlexTabCTA,
    getShowOnSomeoneElsesFlexTabBanner,
} from "../../openTable/selectors";
import { bannerMessages } from "../../banners/messages";
import { actionCreators as bannerActionCreators } from "../../banners/reducers";
import { actionCreators as partyActionCreators } from "../../order";
import { capitaliseFirstLetter } from "src/common/formatter";

export function mapStateToProps(state: AppState): DataProps<Props> & PriceResolverProps {
    const {
        easyReorderItems,
        pagePosition: { servicePagePosition, animatingService },
        navBar: { navBarStatus },
        membership: { showConnectedConfirmation },
    } = state;

    const service = getActiveOrLastActiveService(state)!;
    const menus = getActiveServiceMenus(state)!;
    const party = getParty(state);
    const tableLabel = getTableLabel(state);
    const locationMenuData = getVisibleMenuData(state)!;

    const title =
        party && party.tableNumber
            ? `${capitaliseFirstLetter(tableLabel)} ${party.tableNumber.slice(0, party.tableNumber.indexOf("-"))}`
            : service.displayName;

    const dietaryInformationUrl = getDietaryInformationUrl(state);
    const userPreferenceFilters = getUserPreferenceFilters(state);
    const selectedUserPreferenceFilters = getSelectedUserPreferencesFilters(state);
    const priceResolver = getPriceResolver(state);
    const showGroupTabMenuCTA = !!getGroupTabMenuCallToAction(state);
    const hasBanner = !!getStaticBanners(state).length;
    const menuBackgroundImage = getMenuBackgroundImage(state);
    const isTakeaway = getIsSectionTakeaway(state);
    const activeLocationAddressDetails = getActiveVenueLocationDetails(state);
    const showDemoBanner = getCanShowDemoFeatures(state);
    const isOpenTable = getIsOpenTable(state);
    const isOpenTableOrderingDisabled = getIsOpenTableOrderingDisabled(state);
    const showInviteToFlexTabCTA = getShowInviteToFlexTabCTA(state);
    const flexTabOwnerName = getFlexTabOwnerFirstName(state);
    const showOnSomeoneElsesFlexTab = getShowOnSomeoneElsesFlexTabBanner(state);

    return {
        service,
        menus,
        dietaryInformationUrl,
        userPreferenceFilters,
        selectedUserPreferenceFilters,
        title,
        party,
        locationMenuData,
        easyReorderItems,
        servicePagePosition,
        isActive: navBarStatus === NavBarType.MENU,
        animatingService,
        priceResolver,
        showConnectedConfirmation,
        showGroupTabMenuCTA,
        hasBanner,
        menuBackgroundImage: menuBackgroundImage,
        isTakeaway,
        activeLocationAddressDetails,
        showDemoBanner,
        isOpenTable,
        isOpenTableOrderingDisabled,
        showInviteToFlexTabCTA,
        flexTabOwnerName,
        showOnSomeoneElsesFlexTab,
    };
}

export function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<Exclude<Props, PriceResolverProps>> {
    return {
        applyFilters: (categoryId: string, tagGroups: TagGroup[], selectedFilters: SelectedFilters) =>
            dispatch(actionCreators.applyFilters(categoryId, tagGroups, selectedFilters)),
        setServicePagePosition: (pagePosition: number) =>
            dispatch(pagePositionActions.setServicePagePosition(pagePosition)),
        showDemoModeBanner: () => dispatch(bannerActionCreators.showBanner(bannerMessages.demoMode(), true)),
        showOnSomeoneElsesFlexTabBanner: (tabOwnerName: string) =>
            dispatch(bannerActionCreators.showBanner(bannerMessages.onSomeoneElsesFlexTab(tabOwnerName), true)),
        trackAllergenNoticeClick: () => dispatch(partyActionCreators.trackAllergenNoticeClicked("service")),
    };
}

export const ServicePageContainer = connect(mapStateToProps, mapDispatchToProps)(ServicePage);
