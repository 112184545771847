import { AppDispatch, AppState } from "src/features";
import { getIsGroupTabOpen, getTabTypeName } from "src/features/groupTabs/selectors/activeGroupTab";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { getParty } from "src/features/order";
import { getOngoingOrderHistories } from "src/features/orderHistoryData/selectors";
import * as accountActions from "../actions";

export function initiateDeleteAccount() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const inParty = !!getParty(state);
        const tabTypeName = getTabTypeName(state);

        if (inParty) {
            dispatch(showModalMessage(modalMessages.cannotDeleteAccountInParty()));
            return;
        }

        const hasOngoingOrderHistories = !!getOngoingOrderHistories(state).length;
        if (hasOngoingOrderHistories) {
            dispatch(showModalMessage(modalMessages.cannotDeleteAccountPendingOrder()));
            return;
        }

        const isGroupTabOpen = getIsGroupTabOpen(state);
        if (isGroupTabOpen) {
            dispatch(showModalMessage(modalMessages.cannotDeleteAccountOpenedGroupTab(tabTypeName)));
            return;
        }

        dispatch(showModalMessage(modalMessages.deleteAccount(() => dispatch(accountActions.deleteAccount()))));
    };
}
