import { createSelector } from "reselect";
import { getDietaryTagGroup, getDietaryTagGroupDetails, getActiveMenu } from "../../menu/selectors";
import { getMenuItemsForDisplay, getMenuLinesForDisplay } from "./getMenuItemsForDisplay";
import { getSelectedFiltersByCategory } from "./getSelectedFiltersByCategory";
import { PartyMenuFoodPreferenceFilterData } from "../../order/reducers/party";

export const getFilteredMenuFoodPreferenceCategoryData = createSelector(
    getActiveMenu,
    getMenuItemsForDisplay,
    getSelectedFiltersByCategory,
    getMenuLinesForDisplay,
    getDietaryTagGroup,
    (activeMenu, filteredItems, selectedFilters, menuLines, dietaryTagGroup) => {
        const categories = activeMenu.categories;

        return categories.reduce((allCategoryData, category) => {
            const categoryDetails = menuLines.find(({ categoryId }) => categoryId === category);

            if (categoryDetails && categoryDetails.type === "food") {
                const { categoryId } = categoryDetails;
                const categorySelectedFilters = selectedFilters[categoryId];
                let menuFoodPreferences: string[] = [];

                if (dietaryTagGroup) {
                    const selectedDietaryTags = categorySelectedFilters.tags[dietaryTagGroup.id];
                    menuFoodPreferences = getDietaryTagGroupDetails(dietaryTagGroup.tags, selectedDietaryTags);
                }

                if (menuFoodPreferences.length) {
                    // Only capture category data when a filter has been applied to the category
                    const currentCategoryVisibleItems = filteredItems[categoryId]?.length;
                    const currentCategoryTotalItems = menuLines.find(
                        ({ categoryId: menuLineCategoryId }) => menuLineCategoryId === categoryId
                    )?.category.menuItems.length;

                    allCategoryData[categoryId] = {
                        itemsTotal: currentCategoryTotalItems || 0,
                        itemsVisible: currentCategoryVisibleItems || 0,
                        categoryId,
                        categoryName: categoryDetails.displayName!,
                        menuFoodPreference: menuFoodPreferences.toString(),
                    };
                }
            }

            return allCategoryData;
        }, {} as { [categoryId: string]: PartyMenuFoodPreferenceFilterData });
    }
);
