import twoDotSpinner from "./twodotspinner.json";
import twoDotSpinnerWhite from "./twodotspinner-white.json";
import logoSpinner from "./logospinner.json";
import logoSpinnerWhite from "./logospinner-white.json";

export type AnimationsName = "twoDotSpinner" | "twoDotSpinnerWhite" | "logoSpinner" | "logoSpinnerWhite";

export const Animations: { [K in AnimationsName]: any } = {
    twoDotSpinner,
    twoDotSpinnerWhite,
    logoSpinner,
    logoSpinnerWhite,
};
