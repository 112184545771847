import { getAuthHeaders } from "src/common/auth";
import { orderApi } from "src/features/order/orderApi";
import { ClaimOfferResult, IdentifierScope } from "../types";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";

export async function claimOffer(groupId: string, offerId: string, scope: IdentifierScope, source?: string) {
    const headers = await getAuthHeaders();
    headers.append("Content-Type", "application/json");
    const sourceQuery = source ? `?source=${source}` : "";
    const response = await orderApi.send(`/campaign/${scope}/${groupId}/${offerId}${sourceQuery}`, {
        method: "POST",
        headers,
    });

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as ClaimOfferResult;
}
