import { BaseTelemetryPlugin, IProcessTelemetryContext, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { AppState } from "../..";
import { getActiveLocationId, getPreviousLocationId } from "../../order/selectors";
import { getVisibleMenuData } from "../../menu/selectors";
import { IReduxActionPlugin } from "./IReduxActionPlugin";
import { AnyAction } from "redux";

export class LocationInfoPlugin extends BaseTelemetryPlugin implements IReduxActionPlugin {
    public identifier = "MeanduLocationInfoPlugin";

    private getState: (() => AppState) | null = null;

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        if (this.getState) {
            const state = this.getState();

            if (!!state.menuData.data) {
                const activeLocationId = getActiveLocationId(state) || "";
                const previousLocationId = getPreviousLocationId(state) || "";
                const menuData = getVisibleMenuData(state)!;
                const menuDataUrl = state.menuData.url!;

                event.data = {
                    ...event.data,
                    ...{
                        "location.id": activeLocationId,
                        "location.previousId": previousLocationId,
                        "location.title": menuData.title,
                        "location.menuDataUrl": menuDataUrl,
                    },
                };
            }
        }

        this.processNext(event, itemCtx!);
    }

    handleReduxAction(action: AnyAction, getState: () => AppState) {
        this.getState = getState;
    }
}
