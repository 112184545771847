import { AppDispatch, AppState } from "../../index";
import {
    authorizeGooglePayPaymentOperation,
    initializeCustomGooglePayButtonOperation,
    initializeGooglePayPaymentBackgroundOperation,
    initializeGooglePayPaymentOperation,
} from "../operations";
import { normalizeError } from "src/common/error";
import { googlePay } from "src/common/experience";
import { getIsConnected } from "../../order/selectors";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getPaymentTotal, getSelectedPaymentMethod } from "../selectors";
import {
    GetAuthorizePaymentResultFunc,
    getGooglePaymentsClient,
    GooglePayPaymentMethod,
    UpdateTotalFunc,
} from "src/common/payment";
import { getPaymentSessionToken } from "../util/getPaymentSessionToken";
import { InitializeDevicePaymentMethodButtonActions } from "src/common/experience/interface";
import { CompletePaymentFunc } from "src/features/payment/types";
import { updatePaymentAmount } from "src/features/payment/util/updatePaymentAmount";

export function initializeGooglePay(onSuccess?: () => void) {
    return initializeGooglePayPaymentOperation.getThunk(async () => {
        await getGooglePaymentsClient();
        onSuccess && onSuccess();
    });
}

export const initializeGooglePayAwaitable = (dispatch: AppDispatch) =>
    initializeGooglePayPaymentBackgroundOperation.invoke(async () => {
        await getGooglePaymentsClient();
    }, dispatch);

export function initializeGooglePayButton(
    googlePayButtonContainer: HTMLDivElement,
    completePayment: CompletePaymentFunc,
    setUpdateTotal: ((updateTotal: UpdateTotalFunc) => void) | null,
    cancelPromise: Promise<void>
) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const paymentsClient = await getGooglePaymentsClient();

        const googlePayButton = paymentsClient.createButton({
            onClick: async (ev: any) => {
                if (setUpdateTotal) {
                    // The presence of setUpdateTotal indicates that a custom button is being used
                    // which needs to be initialized up front and not by clicking this button
                    return;
                }

                const actions: InitializeGooglePayButtonButtonActions = {
                    buttonActions: {
                        disable: () => {
                            ev.target.disabled = true;
                        },
                        enable: () => {
                            ev.target.disabled = false;
                        },
                    },
                };

                await initializeGooglePayButtonInternal(dispatch, getState, completePayment, actions, cancelPromise);
            },
            buttonType: "checkout",
            buttonSizeMode: "fill",
        });

        googlePayButtonContainer.append(googlePayButton);

        if (!setUpdateTotal) return;

        const actions: InitializeGooglePayButtonButtonActions = {
            setUpdateTotal,
        };

        await initializeGooglePayButtonInternal(dispatch, getState, completePayment, actions, cancelPromise);
    };
}

interface ButtonActions {
    disable: () => void;
    enable: () => void;
}

interface InitializeGooglePayButtonButtonActions {
    buttonActions?: ButtonActions;
    setUpdateTotal?: (updateTotal: UpdateTotalFunc) => void;
}

async function initializeGooglePayButtonInternal(
    dispatch: AppDispatch,
    getState: () => AppState,
    completePayment: CompletePaymentFunc,
    { buttonActions, setUpdateTotal }: InitializeGooglePayButtonButtonActions,
    cancelPromise: Promise<void>
) {
    const state = getState();
    const connected = getIsConnected(state);

    if (!connected) {
        dispatch(showModalMessage(modalMessages.noNetworkFound()));
        return;
    }

    buttonActions?.disable();

    const operation = buttonActions ? authorizeGooglePayPaymentOperation : initializeCustomGooglePayButtonOperation;

    await operation.invoke(async () => {
        try {
            const googlePayPaymentMethod = getSelectedPaymentMethod(state) as GooglePayPaymentMethod;
            const total = getPaymentTotal(state);

            const actions: InitializeDevicePaymentMethodButtonActions = {
                getPaymentSessionToken: getPaymentSessionToken(dispatch, getState),
                updatePaymentAmount: updatePaymentAmount(dispatch, getState),
                trackEvent: (action) => authorizeGooglePayPaymentOperation.trackEvent(action, dispatch),
                spinnerActions: {
                    start: () => dispatch(authorizeGooglePayPaymentOperation.actionCreators.begin()),
                    stop: () => dispatch(authorizeGooglePayPaymentOperation.actionCreators.reset()),
                },
            };

            const initializeButtonResult = await googlePay.initializeButton(
                googlePayPaymentMethod,
                total,
                actions,
                cancelPromise
            );

            let authorizePayment: GetAuthorizePaymentResultFunc;

            if (typeof initializeButtonResult === "function") {
                authorizePayment = initializeButtonResult;
            } else {
                let updateTotal: UpdateTotalFunc;
                ({ authorizePayment, updateTotal } = initializeButtonResult);
                setUpdateTotal?.(updateTotal);
            }

            const result = await Promise.race([authorizePayment(), cancelPromise]);

            if (result || result === null) {
                completePayment(result);
            }
        } catch (err) {
            completePayment(normalizeError(err));
            throw err;
        } finally {
            buttonActions?.enable();
        }
    }, dispatch);
}
