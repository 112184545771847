import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";

export * from "./types";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.offers = reducer;
}
