import { DeviceInfo } from "../shared";
import { registerToKeyboard } from "../shared/registerToKeyboard";
import { cordovaWindow } from "./cordova";

export const device: DeviceInfo = (window as any).device || {};

export function getDeviceInfo() {
    const tempDevice = cordovaWindow.device;
    if (!tempDevice) return device;
    tempDevice.userAgent = window.navigator && window.navigator.userAgent;
    for (let [key, value] of Object.entries(tempDevice)) {
        device[key] = value;
    }
    device.isAndroid = device.platform === "Android";
    device.isIOS = device.platform === "iOS";
    device.isEdge = false;
    device.isChrome = false;
    device.isSafari = false;
    device.isMobile = true;
    device.supportsMultipleInstances = false;
    device.isLocalHost = false;
    device.isPrivate = false;
    return device;
}

export { registerToKeyboard };
