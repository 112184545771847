const KEY = "MEANDU_RSA";

const hasConfirmedToday = (date: string | null) => {
    if (!date) return false;
    const d1 = new Date();
    const d2 = new Date(+date);
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const getAgeRestrictionConfirmation = () => {
    try {
        return hasConfirmedToday(window.localStorage.getItem(KEY));
    } catch (err) {
        return false;
    }
};

const clearStorage = () => {
    window.localStorage.removeItem(KEY);
};

export const saveAgeRestrictionConfirmation = () => {
    window.localStorage.setItem(KEY, new Date().getTime().toString());

    document.removeEventListener("logout", clearStorage);
    document.addEventListener("logout", clearStorage);
};
