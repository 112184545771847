import { AppDispatch, AppState } from "src/features";
import { getActiveService, getActiveServiceMenuItemLinkParams, getVisibleMenuData } from "src/features/menu/selectors";
import { OrderItemModifier } from "src/features/order";
import {
    getOrderItemModifiersFromOptions,
    getOrderItemVariantIndexFromName,
} from "src/features/order/selectors/util/getOrderItemOptions";
import { orderAgainActions } from "..";
import { RoundItem } from "../types";

export const setInitialAnotherRound = (anotherRound?: RoundItem[]) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const service = getActiveService(state);
        const data = getVisibleMenuData(state);

        if (!anotherRound?.length || !data || !service) return;

        const menuItemLinkParams = getActiveServiceMenuItemLinkParams(state);

        const anotherRoundItems = anotherRound
            .filter((itemRound) => !!data.items[itemRound.itemId])
            .map((itemRound) => {
                const itemData = data.items[itemRound.itemId];
                const itemLinkParams = menuItemLinkParams.get(itemRound.itemId);
                const category = itemLinkParams && data.categories[itemLinkParams.categoryId];

                let itemAvailable = itemData?.available !== false;
                let variantIndex: number | null = null;
                let itemModifiers: OrderItemModifier[] | null = null;

                if (itemAvailable) {
                    variantIndex = getOrderItemVariantIndexFromName(itemRound.variantName, itemData);

                    let allOptionsAvailable;
                    ({ itemModifiers, allOptionsAvailable } = getOrderItemModifiersFromOptions(
                        itemRound.modifierOptions,
                        itemData,
                        data.modifiers
                    ));

                    itemAvailable =
                        itemAvailable &&
                        (itemRound.variantName === null || (variantIndex !== null && variantIndex > -1)) &&
                        allOptionsAvailable &&
                        !!category;
                }

                const energyContent =
                    variantIndex === null ? itemData.energyContent : itemData.variants?.[variantIndex]?.energyContent;

                return {
                    ...itemRound,
                    variant: variantIndex,
                    modifiers: itemModifiers,
                    courseId: category?.course ?? service.defaultCourse,
                    images: itemData?.images,
                    quantity: itemAvailable ? itemRound.quantity : 0,
                    available: itemAvailable,
                    waitTime: itemData.waitTime,
                    energyContent,
                } as RoundItem;
            })
            .filter(({ courseId, quantity, available }) => courseId && quantity && available);

        dispatch(orderAgainActions.setAnotherRound(anotherRoundItems));
    };
};
