import { orderApi, reattemptStrategies } from "src/features/order/orderApi";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { OrderHistoryData } from "src/features/orderHistoryData";
import { getAuthHeaders } from "../../../common/auth";
import { getTableTokenHeaders } from "../../partyOnboarding/persistence/tableToken";

export const getOrderDataApi = async (inParty: boolean, partyId: string) => {
    const headers = inParty ? getTableTokenHeaders() : await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/grouptabs/meal/${partyId}`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as OrderHistoryData;
};
export const getHistoryOrderDataApi = async (groupTabId: string, partyId: string) => {
    const headers = await getAuthHeaders();

    headers.append("Content-Type", "application/json");

    const response = await orderApi.send(
        `/grouptabshistory/${groupTabId}/meal/${partyId}`,
        {
            method: "GET",
            headers,
        },
        reattemptStrategies.forever
    );

    await ProblemDetailsError.throwError(response);

    return (await response.json()) as OrderHistoryData;
};
