import * as React from "react";
import "../assets/PromotionPage.scss";
import { SimpleNavHeader } from "../../../common/navigation";
import { PartyNotification } from "../../order";
import { ModalContainer } from "src/common/modal";

export interface Props {
    isOpen: boolean;
    close: () => void;
    notification?: PartyNotification;
}

export const PromotionalPage = ({ notification, isOpen, close }: Props) =>
    !notification ? null : (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "promotion-modal",
                afterOpen: "promotion-modal--after-open",
                beforeClose: "promotion-modal--before-close",
            }}
            overlayClassName="ReactModal__Promotion"
            closeTimeoutMS={400}
        >
            <div className="promotion-page fixed-at-top">
                <SimpleNavHeader title={notification.title} hasBackButton onBack={close} />
                {notification.longDescription && (
                    <div
                        className="promotion-page__content"
                        dangerouslySetInnerHTML={{ __html: notification.longDescription }}
                    />
                )}
            </div>
        </ModalContainer>
    );
