import { flatten } from "lodash";
import { createSelector } from "reselect";
import { AppState } from "src/features";
import { VisibleModifierOption } from "src/features/menudata";
import { OrderItem } from "src/features/order";
import { getAnyParty, getPartySubmittedOrders } from "../../order/selectors";
import { getVisibleMenuData } from "src/features/menu/selectors";
import { evenRound } from "src/sharedComponents/common/utils";
import { getCompletePayments, getCompleteRefunds } from "src/features/payment/selectors/party";

export const getPayOnlyPaymentOrder = createSelector(getAnyParty, (party) => party?.paymentOrder ?? null);

export const getPayOnlyPaymentOrderStatus = createSelector(getPayOnlyPaymentOrder, (order) => order?.status ?? null);

interface FlexBillOrderItem extends OrderItem {
    isPaid: boolean;
    sourceIndex: number;
}

function getOrderItemFingerprint(item: OrderItem) {
    let fingerprint = item.displayName + item.basePrice.toString();

    if (item.modifiers) {
        for (const modifier of item.modifiers) {
            if (modifier.selectedOptions) {
                for (const option of modifier.selectedOptions) {
                    fingerprint += `,${option.displayName}`;
                }
            }
        }
    }

    return fingerprint;
}

export const getCompletePaymentsTotal = createSelector(getCompletePayments, getCompleteRefunds, (payments, refunds) =>
    evenRound(
        payments.reduce((total, p) => {
            const refund = refunds.find((r) => r.paymentId === p.id);
            return total + p.amount - (refund?.posTotal ?? 0);
        }, 0),
        2
    )
);

export const getPaidItemMap = createSelector(
    getPayOnlyPaymentOrder,
    getCompletePayments,
    (paymentOrder, completePayments) => {
        if (!paymentOrder?.items.length || !completePayments) {
            return {};
        }

        return completePayments.reduce((paidItemMap, payment) => {
            if (payment?.includedItems?.length) {
                for (const paidItem of payment.includedItems) {
                    const itemFingerprint = getOrderItemFingerprint(paidItem);
                    const previousQty = paidItemMap[itemFingerprint] ?? 0;
                    paidItemMap[itemFingerprint] = previousQty + paidItem.quantity;
                }
            }

            return paidItemMap;
        }, {} as { [key: string]: number });
    }
);

const createBillOrderItem = (
    sourceIndex: number,
    item: OrderItem,
    quantity: number,
    isPaid: boolean
): FlexBillOrderItem => ({
    ...item,
    quantity,
    isPaid,
    sourceIndex,
});

export const getBillItems = createSelector(getPayOnlyPaymentOrder, getPaidItemMap, (order, paidItemMap) => {
    const items = order?.items;
    const remainingPaidItemMap = { ...paidItemMap };

    const billItems: FlexBillOrderItem[] = [];

    const paidBillItems: FlexBillOrderItem[] = [];

    if (items) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemFingerprint = getOrderItemFingerprint(item);

            const paidQuantity = remainingPaidItemMap[itemFingerprint];

            if (paidQuantity > 0) {
                const itemPaidQuantity = paidQuantity < item.quantity ? paidQuantity : item.quantity;

                remainingPaidItemMap[itemFingerprint] -= itemPaidQuantity;

                paidBillItems.push(createBillOrderItem(i, item, itemPaidQuantity, true));

                if (itemPaidQuantity < item.quantity) {
                    billItems.push(createBillOrderItem(i, item, item.quantity - itemPaidQuantity, false));
                }
            } else {
                billItems.push(createBillOrderItem(i, item, item.quantity, false));
            }
        }
    }

    while (paidBillItems.length) {
        billItems.push(paidBillItems.shift()!);
    }

    return billItems;
});

export const getPaidWithServerLabels = createSelector(getVisibleMenuData, (menuData) => {
    const serverName = menuData?.locale === "en-US" ? "server" : "staff";
    return {
        paidWithServerLabel: `Paid with ${serverName}`,
        totalOfAllServerPaymentsLabel: `Total of all ${serverName} payments`,
    };
});

export const getPaidWithServerTotal = createSelector(
    getPayOnlyPaymentOrder,
    getCompletePaymentsTotal,
    (order, paidWithMeanduTotal) =>
        evenRound((order?.bill.total ?? 0) - (order?.bill.remainingBalance ?? 0) - paidWithMeanduTotal, 2)
);

export const getPaidWithServerTotalSeen = createSelector(getPaidWithServerTotal, (paidWithServerTotal) =>
    Math.floor(paidWithServerTotal * 100)
);

export const getReceiptData = createSelector(
    getPayOnlyPaymentOrder,
    getPartySubmittedOrders,
    getBillItems,
    getCompletePaymentsTotal,
    getPaidWithServerTotal,
    getPaidWithServerLabels,
    (_: AppState, refreshing?: boolean) => refreshing,
    (
        order,
        submittedOrders,
        billItems,
        paidWithMeanduTotal,
        paidWithServerTotal,
        { paidWithServerLabel },
        refreshing
    ) => {
        const useSubmittedOrderItems = !order?.items.length && !!refreshing;

        // Try and keep the refreshing bill as close to the actual bill as possible
        // to avoid "jankiness" by using what we have in submittedOrders
        const receiptBillItems = useSubmittedOrderItems
            ? flatten(submittedOrders.map((o) => o.items)).map((i, index) =>
                  createBillOrderItem(index, i, i.quantity, false)
              )
            : billItems;

        return {
            useSubmittedOrderItems,
            items: receiptBillItems,
            bill: order?.bill,
            paidWithMeanduTotal,
            paidWithServerTotal,
            paidWithServerLabel,
        };
    }
);

export const getPayOnlyEmbellishedItems = createSelector(
    getBillItems,
    (billItems) =>
        billItems
            .filter((i) => !i.isPaid)
            .map((item) => ({
                displayName: item.displayName || "",
                orderItemId: null,
                quantity: item.quantity,
                failedToSubmit: !!item.failedToSubmit,
                price: item.basePrice,
                memberId: item.memberId,
                notes: item.notes,
                variant: item.variant,
                variantName: undefined,
                modifierOptions: mapItemModifier(item),
                submitted: true,
                waitTime: undefined,
                referenceId: `${item.sourceIndex}-${item.displayName}`,
                sourceItem: item,
            })) ?? []
);

const mapItemModifier = (item: OrderItem): VisibleModifierOption[] | null =>
    item.modifiers &&
    flatten(
        item.modifiers.map((m) =>
            !m.selectedOptions
                ? []
                : m.selectedOptions.map(
                      (option) =>
                          ({
                              displayName: option.displayName,
                              originalIndex: -1,
                          } as VisibleModifierOption)
                  )
        )
    );
