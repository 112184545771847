import { createSelector } from "reselect";
import { getWizardGroupTabsLimit } from "../../groupTabs/selectors/groupTabWizardSelectors";
import { getGroupTabPaymentMethod, getOpenTabPaymentMethod, getSelectedPaymentMethod } from "./paymentMethods";
import { getIsGroupTabOpen } from "../../groupTabs/selectors/activeGroupTab";
import { combineAndRound } from "../util/combineAndRound";
import { calculateProcessingFee, getProcessingFeeBaseAmount, getProcessingFeePercentage } from "./processingFees";
import { getRestaurantFlags } from "../../order/selectors/restaurantFlags";
import { getMenuDataLocale } from "../../menudata/selectors/getMenuDataLocale";
import { PaymentType } from "../../../common/payment";

export const getGroupTabTotalBeforeProcessingFee = createSelector(getWizardGroupTabsLimit, (tabLimit) => tabLimit ?? 0);

export const getGroupTabProcessingFee = createSelector(
    getProcessingFeePercentage,
    getProcessingFeeBaseAmount,
    getGroupTabTotalBeforeProcessingFee,
    calculateProcessingFee
);

export const getGroupTabTotal = createSelector(
    getGroupTabTotalBeforeProcessingFee,
    getGroupTabProcessingFee,
    combineAndRound
);

export const getShouldHaveGroupTabPaymentMethod = createSelector(
    getIsGroupTabOpen,
    getGroupTabPaymentMethod,
    (activeGroupTab, groupTabPaymentMethod) => activeGroupTab && !groupTabPaymentMethod
);

export const getGroupTabMandatoryGratuity = createSelector(
    getRestaurantFlags,
    ({ groupTabGratuityFactor }) => groupTabGratuityFactor
);

export const getFormattedTabMandatoryGratuity = createSelector(
    getGroupTabMandatoryGratuity,
    getMenuDataLocale,
    (groupTabGratuityFactor, menuDataLocale) =>
        !!groupTabGratuityFactor ? menuDataLocale?.formatPercent(groupTabGratuityFactor) : ""
);

export const getShowGratuity = createSelector(
    getSelectedPaymentMethod,
    getOpenTabPaymentMethod,
    (selectedPaymentMethod, openTabPaymentMethod) =>
        selectedPaymentMethod?.paymentType === PaymentType.GROUPTAB ||
        (selectedPaymentMethod && selectedPaymentMethod === openTabPaymentMethod)
);
