import { OrderCourseItem } from "src/features/order/reducers/types";
import { ActiveGroupTabData, ActiveGroupTabState, LimitedMenuType } from "../types";
import { trackedEvent } from "src/common/events/reduxEventTracking";
import { OrderHistoryData } from "../../orderHistoryData";
import { TipType } from "../../payment";

export enum TypeKeys {
    GROUP_TAB_SET_DATA = "GROUP_TAB/SET_DATA",
    GROUP_TAB_SHOW_INVITE_MODAL = "GROUP_TAB/SHOW_INVITE_MODAL",
    GROUP_TAB_SHOW_MANAGE_MODAL = "GROUP_TAB/SHOW_MANAGE_MODAL",
    GROUP_TAB_SET_ORDER_DATA = "GROUP_TAB/SET_ORDER_DATA",
    GROUP_TAB_OVERVIEW_MODAL = "GROUP_TAB/SHOW_OVERVIEW_MODAL",
    GROUP_TAB_CONFIRM_CLOSE = "GROUP_TAB/CONFIRM_CLOSE",
    GROUP_TAB_CANCEL_CONFIRM_CLOSE = "GROUP_TAB/CANCEL_CONFIRM_CLOSE",
    GROUP_TAB_SWAP_MENU = "PARTY/GROUP_TAB/SWAP_MENU_CONFIRMED",
    GROUP_TAB_STARTED = "GROUP_TAB/GROUP_TAB_STARTED",
    GROUP_TAB_SHOW_MEMBERS_MODAL = "GROUP_TAB/SHOW_MEMBERS_MODAL",
    GROUP_TAB_SHOW_ORDERS_MODAL = "GROUP_TAB/SHOW_ORDERS_MODAL",
    TAB_REMOVE_MEMBER_VIEWED = "TAB/REMOVE_MEMBER_VIEWED",
    TAB_MEMBER_REMOVED = "TAB/MEMBER_REMOVED",
    TAB_TIP_SELECTED = "TAB/TIP_SELECTED",
}

export const actionCreators = {
    setGroupTabData: (data?: ActiveGroupTabData) => ({ type: TypeKeys.GROUP_TAB_SET_DATA, data }),
    showInviteToGroupTab: (show: boolean) => ({ type: TypeKeys.GROUP_TAB_SHOW_INVITE_MODAL, show }),
    showManageGroupTab: (show: boolean) => ({ type: TypeKeys.GROUP_TAB_SHOW_MANAGE_MODAL, show }),
    showTabMembers: (show: boolean) => ({ type: TypeKeys.GROUP_TAB_SHOW_MEMBERS_MODAL, show }),
    showTabOrders: (show: boolean) => ({ type: TypeKeys.GROUP_TAB_SHOW_ORDERS_MODAL, show }),
    setOrderData: (partyId: string, orderItems: OrderCourseItem[], orderHistoryData: OrderHistoryData) => ({
        type: TypeKeys.GROUP_TAB_SET_ORDER_DATA,
        partyId,
        orderItems,
        orderHistoryData,
    }),
    showGroupTabOverview: (show: boolean) => ({ type: TypeKeys.GROUP_TAB_OVERVIEW_MODAL, show }),
    confirmCloseGroupTab: () => ({ type: TypeKeys.GROUP_TAB_CONFIRM_CLOSE }),
    cancelConfirmCloseGroupTab: () => ({ type: TypeKeys.GROUP_TAB_CANCEL_CONFIRM_CLOSE }),
    trackSwapMenu: (group_tab_id: string, swapping_to_menu: LimitedMenuType) =>
        trackedEvent({ type: TypeKeys.GROUP_TAB_SWAP_MENU, group_tab_id, swapping_to_menu }),
    trackGroupTabStarted: (data: ActiveGroupTabData) =>
        trackedEvent({
            type: TypeKeys.GROUP_TAB_STARTED,
            group_tab_id: data.id,
            limit: data.limit,
            menu_type: data.packageId ? "limited" : "full",
            package_id: data.packageId,
        }),
    trackGroupTabRemoveMemberViewed: (tab_id: string, tab_type: string) =>
        trackedEvent({
            type: TypeKeys.TAB_REMOVE_MEMBER_VIEWED,
            tab_id,
            tab_type,
        }),
    trackGroupTabMemberRemoved: (tab_id: string, tab_type: string) =>
        trackedEvent({
            type: TypeKeys.TAB_MEMBER_REMOVED,
            tab_id,
            tab_type,
        }),
    trackTabTipSelected: (
        tab_id: string,
        tab_type: string,
        tip_amount: number,
        tip_percentage: number,
        customTip: boolean,
        tipType?: TipType
    ) =>
        trackedEvent({
            type: TypeKeys.TAB_TIP_SELECTED,
            tab_id,
            tab_type,
            tip_amount,
            tip_percentage,
            tip_selected: customTip ? "custom" : "predefined",
            tip_type: TipType[tipType ?? TipType.PERCENTAGE].toLowerCase(),
        }),
};

export type SetGroupTabDataAction = { type: TypeKeys.GROUP_TAB_SET_DATA; data: ActiveGroupTabData };
export type ShowInviteToGroupTabAction = { type: TypeKeys.GROUP_TAB_SHOW_INVITE_MODAL; show: boolean };
export type ShowManageGroupTabAction = { type: TypeKeys.GROUP_TAB_SHOW_MANAGE_MODAL; show: boolean };
export type ShowTabMembersAction = { type: TypeKeys.GROUP_TAB_SHOW_MEMBERS_MODAL; show: boolean };
export type ShowTabOrdersAction = { type: TypeKeys.GROUP_TAB_SHOW_ORDERS_MODAL; show: boolean };
export type SetGroupTabOrderAction = {
    type: TypeKeys.GROUP_TAB_SET_ORDER_DATA;
    partyId: string;
    orderItems: OrderCourseItem[];
    orderHistoryData: OrderHistoryData;
};
export type ShowOverviewGroupTabAction = { type: TypeKeys.GROUP_TAB_OVERVIEW_MODAL; show: boolean };
export type ConfirmCloseGroupTabAction = { type: TypeKeys.GROUP_TAB_CONFIRM_CLOSE };
export type CancelConfirmCloseGroupTabAction = { type: TypeKeys.GROUP_TAB_CANCEL_CONFIRM_CLOSE };

export type ActiveGroupTabActions =
    | SetGroupTabDataAction
    | ShowInviteToGroupTabAction
    | ShowManageGroupTabAction
    | ShowOverviewGroupTabAction
    | SetGroupTabOrderAction
    | ConfirmCloseGroupTabAction
    | ShowTabMembersAction
    | ShowTabOrdersAction
    | CancelConfirmCloseGroupTabAction;

const initialState: ActiveGroupTabState = {};

export function reducer(state: ActiveGroupTabState = initialState, action: ActiveGroupTabActions): ActiveGroupTabState {
    if (action.type === TypeKeys.GROUP_TAB_SET_DATA) {
        const { data } = action;
        return {
            ...state,
            data,
            showOverviewModal: data ? state.showOverviewModal : false,
            showManageModal: data ? state.showManageModal : false,
            showCloseConfirmation: data ? state.showCloseConfirmation : false,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SHOW_INVITE_MODAL) {
        const { show } = action;
        return {
            ...state,
            showInviteModal: show,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SHOW_MANAGE_MODAL) {
        const { show } = action;
        return {
            ...state,
            showManageModal: show,
            showCloseConfirmation: false,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SET_ORDER_DATA) {
        const { orderHistoryData, partyId, orderItems } = action;
        return {
            ...state,
            orders: {
                ...state.orders,
                [partyId]: {
                    items: orderItems,
                    orderData: orderHistoryData,
                },
            },
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_OVERVIEW_MODAL) {
        const { show } = action;
        return {
            ...state,
            showOverviewModal: show,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_CONFIRM_CLOSE) {
        return {
            ...state,
            showOverviewModal: true,
            showManageModal: true,
            showCloseConfirmation: true,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SHOW_MEMBERS_MODAL) {
        return {
            ...state,
            showMembersModal: action.show,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_SHOW_ORDERS_MODAL) {
        return {
            ...state,
            showOrdersModal: action.show,
        };
    }

    if (action.type === TypeKeys.GROUP_TAB_CANCEL_CONFIRM_CLOSE) {
        return {
            ...state,
            showCloseConfirmation: false,
        };
    }

    return state;
}
