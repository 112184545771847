import React, { Component, CSSProperties, createRef, PropsWithChildren, Ref } from "react";
import classNames from "classnames";
import "./TrackScroll.scss";

interface BaseProps {
    className?: string;
    style?: CSSProperties;
    throttleTimeout?: number;
    innerRef?: Ref<HTMLDivElement>;
    updatedOnScroll?: () => void;
}

export type TrackScrollProps = PropsWithChildren<BaseProps>;

//For Parallax effect, pass parallax as className and assign parallax-element to the relevant element
// Parallax will work only for Android as the feature is broken since iOS 13
export class TrackScrollInner extends Component<TrackScrollProps> {
    throttleScroll: number;
    topSpace: number = 0;
    scrollView: any;

    constructor(props: BaseProps) {
        super(props);
        this.scrollView = props.innerRef || createRef();
        this.throttleScroll = -1;
        window.addEventListener("scroll", this.onScroll, true);
    }
    updatedOnScroll = () => {
        this.throttleScroll = -1;
        const { updatedOnScroll } = this.props;
        this.scrollView.current && updatedOnScroll && updatedOnScroll();
    };

    componentWillUnmount() {
        if (this.throttleScroll && this.throttleScroll > -1) {
            window.clearTimeout(this.throttleScroll);
        }
        window.removeEventListener("scroll", this.onScroll, true);
    }

    onScroll = () => {
        if (this.throttleScroll === -1) {
            this.throttleScroll = window.setTimeout(this.updatedOnScroll, this.props.throttleTimeout || 50);
        }
    };

    render() {
        const { className, style, children } = this.props;
        return (
            <div
                ref={this.scrollView}
                className={classNames("track-scroll", className)}
                style={style}
                onScroll={this.onScroll}
            >
                {children}
            </div>
        );
    }
}

export const TrackScroll = React.forwardRef<HTMLDivElement, TrackScrollProps>((props, ref?) => (
    <TrackScrollInner innerRef={ref} {...props} />
));
