import "../assets/OpenTableOrderingDisabledPage.scss";

import React, { useCallback } from "react";
import { EmptyBag } from "src/sharedComponents/assets/icons";
import { CallToActionHeader } from "src/sharedComponents";
import { NavBarType } from "src/common/navigation/types";
import { useDispatch } from "react-redux";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { __ } from "src/common/strings";

export const OpenTableOrderingOfflineMessage = __(
    "Thanks for dining, we’re no longer taking orders. Before you head out, don’t forget to pay."
);

export const OpenTableOrderingDisabledPage = () => {
    const dispatch = useDispatch();

    const goToPay = useCallback(() => {
        dispatch(navBarActionCreators.activeType(NavBarType.PAY));
    }, [dispatch]);

    return (
        <div className="open-table-ordering-disabled-page">
            <div className="open-table-ordering-disabled-page__content">
                <CallToActionHeader
                    title="Ordering offline"
                    ctaText="View bill"
                    icon={<EmptyBag />}
                    subtitle={OpenTableOrderingOfflineMessage}
                    onClick={goToPay}
                />
            </div>
        </div>
    );
};
