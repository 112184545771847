import * as React from "react";
import { MenuFilterIcon } from "src/sharedComponents/assets/icons";
import { TappableButton } from "src/sharedComponents/common/tappable";
import { SelectedFilters } from "../types";
import classNames from "classnames";
import { isFiltered } from "./util/isFiltered";
import "../assets/Filter.scss";

export interface Props {
    onClick?: () => void;
    selectedFilters: SelectedFilters;
    canOpenFilter?: boolean;
}

export class Filter extends React.Component<Props> {
    render() {
        const { onClick, selectedFilters, canOpenFilter } = this.props;
        const hasSelectedFilter = isFiltered(selectedFilters);
        return (
            <div className="filter__wrapper">
                <TappableButton
                    type="button"
                    onClick={onClick}
                    className={classNames("filter", !!hasSelectedFilter && "selected-filters-on")}
                    disabled={canOpenFilter !== undefined && !canOpenFilter}
                >
                    <MenuFilterIcon />
                </TappableButton>
            </div>
        );
    }
}
