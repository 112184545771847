import * as React from "react";
import { FC, useContext } from "react";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { AvailablePrices } from "../../menudata";
import classNames from "classnames";
import { Text, TextModes, TextPreset } from "../../../sharedComponents";

interface PropsBase {
    className?: string;
    prefix?: string;
    suffix?: string;
    priceTextMode?: TextModes | TextModes[];
    priceTextPreset?: TextPreset;
    showBasePrice?: boolean;
    basePriceTextMode?: TextModes | TextModes[];
    basePriceTextPreset?: TextPreset;
    basePriceClassName?: string;
    wrapperClassName?: string;
    strike?: boolean;
}

interface ResolvedPriceProps extends PropsBase {
    availablePrices: AvailablePrices;
}

interface PriceProps extends PropsBase {
    price: number;
}

export type Props = ResolvedPriceProps | PriceProps;

export const Price: FC<Props> = (props) => {
    const {
        className,
        prefix,
        suffix,
        priceTextMode,
        priceTextPreset,
        showBasePrice,
        basePriceTextMode,
        basePriceTextPreset,
        basePriceClassName,
        wrapperClassName,
        strike,
    } = props;
    const menuDataLocale = useContext(MenuDataLocaleContext)!;

    const price = "price" in props ? props.price : props.availablePrices.memberPrice ?? props.availablePrices.basePrice;

    const PriceWrapper = strike === true
        ? "del"
        : React.Fragment;

    return (
        <span className={classNames("price__wrapper", wrapperClassName)}>
            {priceTextMode || priceTextPreset ? (
                <Text preset={priceTextPreset} mode={priceTextMode} className={classNames("price__value", className)}>
                    <PriceWrapper>
                        {prefix}
                        {menuDataLocale.formatCurrency(price)}
                        {suffix}
                    </PriceWrapper>
                </Text>
            ) : (
                <span className={classNames("price__value", className)}>
                    <PriceWrapper>
                        {prefix}
                        {menuDataLocale.formatCurrency(price)}
                        {suffix}
                    </PriceWrapper>
                </span>
            )}
            {"availablePrices" in props && props.availablePrices.memberPrice !== undefined && showBasePrice && (
                <Text
                    preset={basePriceTextPreset}
                    mode={basePriceTextMode}
                    className={classNames("price__value--base", basePriceClassName)}
                >
                    <del>{menuDataLocale.formatCurrency(props.availablePrices.basePrice)}</del>
                </Text>
            )}
        </span>
    );
};
