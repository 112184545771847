import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getMealHistoryLocale } from "src/features/accounts/utils/mealHistoryLocaleCache";
import { getGroupTabOrderDataAction } from "src/features/groupTabs/actions/getOrderDataAction";
import { HistoryPage } from "src/features/historyInformation/components/HistoryPage";
import { HistoryPaymentDetails } from "src/features/historyInformation/components/HistoryPaymentDetails";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MenuDataLocale } from "src/features/menudata/types/MenuDataLocale";
import { InfoSection } from "src/features/orderHistoryItem/components/InfoSection";
import { Animate, Divider } from "src/sharedComponents";
import { fetchGroupTabHistoryData } from "../actions";
import { sendGroupTabReceiptAction } from "../actions/sendGroupTabReceiptAction";
import { fetchGroupTabDetailsStatus, getGroupTabHistoryById } from "../selectors/groupTabHistorySelector";
import { GroupTabHistoryView } from "../types";
import { GroupTabOrders } from "./GroupTabOrders";
import { groupTabHistoryActions } from "../reducers";
import { config } from "../../../common/config";
import { OrderPayments } from "../../orderHistoryItem/components/OrderPayments";

interface Props {
    selectedItem?: GroupTabHistoryView;
    onBack?: () => void;
}

export const GroupTabHistoryPage = ({ selectedItem, onBack }: Props) => {
    const { groupTabId: paramGroupTabId } = useParams<{ groupTabId: string }>();
    const paramItem = useSelector(getGroupTabHistoryById(paramGroupTabId));
    const loadingDetails = useSelector(fetchGroupTabDetailsStatus);
    const [item, setItem] = useState(selectedItem);
    const dispatch = useDispatch();

    useEffect(() => {
        const groupTab = paramItem || selectedItem;
        if (groupTab && !groupTab?.details) {
            dispatch(fetchGroupTabHistoryData(groupTab.groupTabId));
        }
        dispatch(groupTabHistoryActions.selectGroupTabHistory(groupTab?.groupTabId || null, null));
        setItem(groupTab);
    }, [selectedItem, paramItem, dispatch]);

    useEffect(
        () => () => {
            dispatch(groupTabHistoryActions.selectGroupTabHistory(null, null));
        },
        [dispatch]
    );

    const {
        groupTabId,
        totalCharged,
        dateOpened,
        dateClosed,
        tabName,
        locationName,
        locationId,
        locationImagePath,
        locale,
        currency,
        gratuityFactor,
        details,
    } = item || {};

    const { orders, payments, ordersItems, locationLogoPath, locationColor } = details || {};

    const activePeriod = useMemo(
        () => `${moment(dateOpened).format("MMMM DD, YYYY • h:mmA")} – ${moment(dateClosed).format("h:mmA")}`,
        [dateOpened, dateClosed]
    );
    const sendReceipt = useCallback(() => dispatch(sendGroupTabReceiptAction(groupTabId!)), [dispatch, groupTabId]);
    const menuDataLocale = currency && locale ? getMealHistoryLocale(locale, currency) : undefined;
    const orderHistoryUpdateEnabled = config.enableOrderHistoryUpdate === "1";

    return (
        <MenuDataLocaleContext.Provider
            value={menuDataLocale || new MenuDataLocale(locale || "en-AU", currency || "AUD")}
        >
            <HistoryPage
                onBack={onBack}
                historyTime={activePeriod}
                locationName={locationName!}
                locationId={locationId!}
                locationImagePath={locationImagePath}
                locationLogoPath={locationLogoPath}
                locationColor={locationColor}
                historyName={tabName!}
                sendReceipt={sendReceipt}
                customBack={`group-tab-history-item/${groupTabId}`}
                payment={
                    !menuDataLocale || !payments?.length ? (
                        loadingDetails ? (
                            <Animate name="twoDotSpinner" className="history-item__content-loader" />
                        ) : null
                    ) : (
                        <InfoSection
                            className="history-item__section history-item__section--fade-in"
                            title={
                                <div className={"history-item__paid-info"}>
                                    <span>
                                        You paid {!!totalCharged ? menuDataLocale?.formatCurrency(totalCharged) : ""}
                                    </span>
                                </div>
                            }
                            showDetailsAction={true}
                            waitForContent={!details}
                            hideChildrenDivider={orderHistoryUpdateEnabled}
                        >
                            {payments.map((payment, index) => (
                                <div key={`payment-${payment.dateCreated || index}`}>
                                    {orderHistoryUpdateEnabled ? (
                                        <OrderPayments
                                            paymentMethod={payment.paymentMethod}
                                            cardType={payment.cardType}
                                            paymentMethodDescription={payment.paymentMethodDescription}
                                            processingFee={payment.processingFee}
                                            totalRefunded={payment.refundAmount}
                                            amountAdded={payment.amountAdded}
                                            time={payment.dateCreated}
                                            total={payment.amountAdded + payment.processingFee - payment.refundAmount}
                                            isTipOnlyPayment={payment.tipOnly}
                                        />
                                    ) : (
                                        <HistoryPaymentDetails
                                            paymentMethod={payment.paymentMethod}
                                            cardType={payment.cardType}
                                            paymentMethodDescription={payment.paymentMethodDescription}
                                            processingFee={payment.processingFee}
                                            totalRefunded={payment.refundAmount}
                                            amountAdded={payment.amountAdded}
                                            time={payment.dateCreated}
                                            total={payment.amountAdded + payment.processingFee - payment.refundAmount}
                                            isTipOnlyPayment={payment.tipOnly}
                                        />
                                    )}
                                    {payments.length - 1 !== index && <Divider fullWidth />}
                                </div>
                            ))}
                        </InfoSection>
                    )
                }
                items={
                    orders === undefined ? null : (
                        <InfoSection
                            className={classNames(
                                "history-item__section",
                                !!orders?.length && "history-item__orders",
                                "history-item__section--fade-in"
                            )}
                            title="Orders"
                            description={
                                !orders?.length ? (
                                    "No orders"
                                ) : (
                                    <GroupTabOrders
                                        debits={orders}
                                        orders={ordersItems}
                                        gratuityFactor={gratuityFactor}
                                        getOrderData={(partyId) =>
                                            dispatch(getGroupTabOrderDataAction(groupTabId!, partyId))
                                        }
                                        groupTabId={paramGroupTabId}
                                    />
                                )
                            }
                        />
                    )
                }
            />
        </MenuDataLocaleContext.Provider>
    );
};
