import { AppDispatch, AppState } from "src/features";
import { closeGroupTabOperation } from "../operations";
import { actionCreators as activeTabActionCreators } from "../reducers/activeGroupTab";
import { actionCreators as paymentActionCreators, CustomTip } from "../../payment/reducers";
import { ProblemDetailsError } from "src/features/order/orderApi/ProblemDetailError";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { modalMessages } from "src/features/modalMessage/messages";
import { closeGroupTabApi } from "../API/closeGroupTabApi";
import { fetchGroupTabHistoryDataAwaitable } from "src/features/groupTabsHistory/actions";
import { actionCreators as actionCreatorsGroupTabHistory } from "src/features/groupTabsHistory/reducers/groupTabHistory";
import { GroupTabStatus } from "..";
import { getActiveGroupTabData, getTabTypeName } from "../selectors/activeGroupTab";
import { GroupTabErrorType, TabType } from "../types";
import { NavBarType } from "src/common/navigation/types";
import { actionCreators as navBarActionCreators } from "../../../common/navigation/reducers/navBar";
import { getParty } from "../../order";
import { getTabTypeReadableName } from "../selectors/helpers";

export function closeGroupTabConfirmation(tipAmount?: number, tipPercentage?: number, customTip?: CustomTip) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const tabTypeName = getTabTypeName(state);
        const tab = getActiveGroupTabData(state);

        dispatch(activeTabActionCreators.cancelConfirmCloseGroupTab());

        dispatch(
            showModalMessage(
                modalMessages.closeTab(
                    () => {
                        dispatch(closeGroupTabAction(true, tipAmount, tipPercentage, customTip));
                    },
                    tabTypeName,
                    tab?.type
                )
            )
        );
    };
}

export function closeGroupTabAction(
    showHistory: boolean = false,
    tipAmount?: number,
    tipPercentage?: number,
    customTip?: CustomTip
) {
    return closeGroupTabOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const tabTypeName = getTabTypeName(state);
        const inParty = !!getParty(state);
        const customTipOn = !!customTip;
        const tipType = customTip?.tipType;

        try {
            const res = await closeGroupTabApi(tipAmount);
            if (showHistory && res.status === GroupTabStatus.CLOSED) {
                await fetchGroupTabHistoryDataAwaitable(dispatch, getState, res.id);
                dispatch(actionCreatorsGroupTabHistory.selectGroupTabHistory(res.id, true));
            }

            dispatch(activeTabActionCreators.setGroupTabData(res));
            dispatch(paymentActionCreators.clearPaymentMethods());

            if (inParty && res.type === TabType.PAYONORDER) {
                dispatch(navBarActionCreators.activeType(NavBarType.MENU));
            }

            if (tipAmount) {
                dispatch(
                    activeTabActionCreators.trackTabTipSelected(
                        res.id,
                        getTabTypeReadableName(res.type),
                        tipAmount,
                        tipPercentage!,
                        customTipOn,
                        tipType
                    )
                );
            }
        } catch (ex) {
            if (ProblemDetailsError.isProblemDetailsError(ex)) {
                if (tipAmount && ex.problemDetails.type === GroupTabErrorType.PAYMENT_FAILED) {
                    dispatch(
                        showModalMessage(
                            modalMessages.tabTipFailedPayment(
                                tabTypeName,
                                ex.problemDetails.detail,
                                () => dispatch(closeGroupTabAction(showHistory)),
                                () => dispatch(paymentActionCreators.showTipModal(true))
                            )
                        )
                    );
                } else {
                    dispatch(showModalMessage(modalMessages.knownFailureGroupTab(ex.problemDetails, tabTypeName)));
                }
            } else {
                console.log("failed to close group tab:", ex);
            }
        }
    });
}
