import { useRef } from "react";
import { useSelector } from "react-redux";

import { getActiveABTests } from "../selectors";

export function useIsActiveABTest(testName: string): boolean | null {
    const abTests = useSelector(getActiveABTests);
    const abTestsRef = useRef(abTests);

    if (abTests !== undefined) {
        // To cater for abTests being refetched when a member signs in
        abTestsRef.current = abTests;
    }

    if (abTestsRef.current === undefined) {
        // A/B tests may not have been fetched yet
        return null;
    }

    if (abTestsRef.current?.includes(testName)) {
        // If we're part of the test
        return true;
    }

    return false;
}
