import {
    InitializeDevicePaymentMethodButtonActions,
    InitializeDevicePaymentMethodButtonFunc,
} from "src/common/experience/interface";
import {
    ApplePayPaymentMethod,
    AuthorizePaymentResult,
    GooglePayPaymentMethod,
    paymentOptions,
} from "src/common/payment";
import { createToastPaymentMethod } from "src/features/paymentGateways/pos/toast/actions/createToastPaymentMethod";

const initializeToastDevicePaymentButton =
    async (total: number, { updatePaymentAmount }: InitializeDevicePaymentMethodButtonActions) =>
    async () => {
        const result: AuthorizePaymentResult = {
            paymentToken: await createToastPaymentMethod([{ label: paymentOptions.label, amount: total }]),
        };
        return result;
    };

export const initializeToastApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = (
    _,
    total,
    actions
) => initializeToastDevicePaymentButton(total, actions);

export const initializeToastGooglePayButton: InitializeDevicePaymentMethodButtonFunc<GooglePayPaymentMethod> = (
    _,
    total,
    actions
) => initializeToastDevicePaymentButton(total, actions);
