import { connect } from "react-redux";
import { AppState } from "../..";
import { getAnyParty, getParty } from "../../order/selectors";

export interface OwnProps {
    open: boolean;
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

interface Props {
    hasParty: boolean;
    success: () => React.ReactElement<any>;
    fallback?: () => React.ReactElement<any>;
}

export const RequireParty = ({ hasParty, success, fallback }: Props) =>
    hasParty ? success() : fallback ? fallback() : null;

function mapStateToProps(state: AppState, props: OwnProps): Props {
    const { open, ...otherProps } = props;

    const hasParty = open ? getParty(state) !== null : getAnyParty(state) !== null;

    return {
        hasParty,
        ...otherProps,
    };
}

export const RequirePartyContainer = connect(mapStateToProps)(RequireParty);
