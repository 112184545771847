import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { SimpleNavHeader } from "src/common/navigation";
import { ModalContainer } from "src/common/modal";
import "../assets/AccountDetailsModal.scss";
import { AccountDetails } from "./AccountDetails";

interface Props {
    showModal: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
}

export const AccountDetailsModal = ({ showModal, onClose, onSuccess }: Props) => {
    const [isOpen, setIsOpen] = useState(showModal);

    useEffect(() => {
        setIsOpen(showModal);
    }, [showModal]);

    const closeModal = useCallback(() => {
        setIsOpen(false);
        onClose && onClose();
    }, [setIsOpen, onClose]);

    const onSuccessInner = useCallback(() => {
        onSuccess && onSuccess();
        closeModal();
    }, [onSuccess, closeModal]);

    return (
        <ModalContainer
            isOpen={isOpen}
            className={{
                base: "AccountDetails",
                afterOpen: "AccountDetails--after-open",
                beforeClose: "AccountDetails--before-close",
            }}
            bodyOpenClassName="AccountDetails-modal__Body--open"
            overlayClassName={classNames("AccountDetails-modal--overlay", "keyboard-unfixed")}
            contentLabel="Account Details modal container"
            closeTimeoutMS={250}
            onRequestClose={closeModal}
            shouldCloseOnEsc={true}
        >
            <SimpleNavHeader customBack="account-details-modal" onBack={closeModal} />
            <AccountDetails onSuccess={onSuccessInner} requireEmail flowName="order_history" />
        </ModalContainer>
    );
};
