import groupTabHistoryDataReducer, {
    State as GroupTabHistoryDataState,
    actionCreators as groupTabHistoryActions,
} from "./groupTabHistory";

export { TypeKeys } from "./groupTabHistory";

export { groupTabHistoryDataReducer, groupTabHistoryActions };

export type State = GroupTabHistoryDataState;
