import "./LobbyHeader.scss";

import React from "react";
import { HeaderBackgroundImage } from "../../../sharedComponents/controls/headerBackgroundImage";
import { useSelector } from "react-redux";
import { getParty } from "../../order";
import { Text } from "../../../sharedComponents";
import { getOtherPartyMembersWithStatus } from "../selectors/memberStatus";
import { getLoaderImages } from "../../partyOnboarding/selectors";
import { MemberIndicator } from "../../../sharedComponents/controls/memberIndicator";
import { getTableLabel } from "src/features/order/selectors";
import { capitaliseFirstLetter } from "src/common/formatter";

const LOBBY_HEADER_INDICATOR_LIMIT = 7;

const getTimeSincePartyStarted = (dateOpened?: string) => {
    if (!dateOpened) return undefined;
    const now = new Date();
    const dateOpenedDate = new Date(dateOpened);
    const diffSecs = (now.getTime() - dateOpenedDate.getTime()) / 1000;
    const diffMins = Math.round(diffSecs / 60);

    if (diffMins < 60) return `${diffMins} min`;

    const diffHours = Math.floor(diffMins / 60);
    return diffHours > 1 ? `${diffHours} hours` : "an hour";
};

export const LobbyHeader = () => {
    const members = useSelector(getOtherPartyMembersWithStatus) || [];
    const party = useSelector(getParty);
    const tableLabel = useSelector(getTableLabel);
    const loaderImages = useSelector(getLoaderImages);
    const timeSinceStarted = getTimeSincePartyStarted(party?.dateOpened);
    const showAdditionalMembersIndicator = members.length > LOBBY_HEADER_INDICATOR_LIMIT;
    const visibleMembers = members.slice(
        0,
        showAdditionalMembersIndicator ? LOBBY_HEADER_INDICATOR_LIMIT - 1 : LOBBY_HEADER_INDICATOR_LIMIT
    );
    const numberOfAdditionalMembers = showAdditionalMembersIndicator
        ? members.length - (LOBBY_HEADER_INDICATOR_LIMIT - 1)
        : 0;

    if (!loaderImages) return null;

    return (
        <HeaderBackgroundImage
            className="lobby-header"
            url={loaderImages.backgroundImage}
            imagePercent={0.2}
            backgroundGradientDecimal={0.7}
        >
            {party?.tableNumber && (
                <Text preset="g-18">
                    {capitaliseFirstLetter(tableLabel)} {party.tableNumber}
                </Text>
            )}
            {members.length > 0 && (
                <div className="lobby-header__members">
                    {showAdditionalMembersIndicator && <MemberIndicator value={`+${numberOfAdditionalMembers}`} />}
                    {visibleMembers.map((member) => (
                        <MemberIndicator key={`lobby-member-${member.memberId}`} value={member.initials} />
                    ))}
                </div>
            )}
            <Text preset="g-14" mode="block" className="lobby-header__description">
                {members.length > 0 && timeSinceStarted && `Started ${timeSinceStarted} ago • `}
                {members.length} member
                {members.length !== 1 ? "s" : ""}
            </Text>
        </HeaderBackgroundImage>
    );
};
