import "./RemovePaymentMethod.scss";

import React, { useRef } from "react";
import { isRemovablePaymentMethod, PaymentType, SelectablePaymentMethod } from "../../../common/payment";
import { Text } from "src/sharedComponents";
import classNames from "classnames";
import { useParams } from "react-router";
import { AppState } from "../../index";
import {
    getAddedCardPaymentMethod,
    getGroupTabPaymentMethod,
    getListablePaymentMethods,
} from "../../payment/selectors";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { RemovePaymentMethodAction } from "./RemovePaymentMethodAction";

interface PaymentMethodRouteParams {
    token: string;
}

const getPaymentMethod = createSelector(
    getListablePaymentMethods,
    getGroupTabPaymentMethod,
    getAddedCardPaymentMethod,
    (state: AppState, token: string) => token,
    (listablePaymentMethods, groupTabPaymentMethod, addedCardPaymentMethod, token) => {
        switch (token) {
            case addedCardPaymentMethod?.token:
                return addedCardPaymentMethod!;
            case groupTabPaymentMethod?.token:
                return groupTabPaymentMethod!;
            default:
                return listablePaymentMethods?.find((p) => p.token === token);
        }
    }
);

export const RemovePaymentMethod = () => {
    const { token } = useParams<PaymentMethodRouteParams>();
    const { current: paymentMethod } = useRef(useSelector((state: AppState) => getPaymentMethod(state, token)));

    if (!paymentMethod) return null;

    const removable = isRemovablePaymentMethod(paymentMethod);

    return (
        <div className="remove-payment-method">
            <div className="scroll-element animated-child">
                <div className="remove-payment-method__title profile-page__title">
                    <Text preset="title-28" mode="bold">
                        {paymentMethod.displayName}
                    </Text>
                    <Text
                        preset="g-14"
                        className={classNames(
                            "remove-payment-method__subtitle",
                            !removable && "remove-payment-method__subtitle--device"
                        )}
                        mode="block"
                        value={getPaymentMethodSubtitle(paymentMethod)}
                    />
                </div>
                {!removable ? (
                    <Text preset="g-14" className="remove-payment-method__instructions" mode="block">
                        Select {paymentMethod.displayName} as your payment method when placing an order.
                    </Text>
                ) : (
                    <RemovePaymentMethodAction paymentMethod={paymentMethod} />
                )}
            </div>
        </div>
    );
};

const getPaymentMethodSubtitle = (paymentMethod: SelectablePaymentMethod) => {
    switch (paymentMethod.paymentType) {
        case PaymentType.CARD:
        case PaymentType.ADDEDCARD:
            return paymentMethod.maskedNumber;
        default:
            return "Ready to use on this device.";
    }
};
