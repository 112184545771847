import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../../sharedComponents";
import { getShowViewCart, getOrderItemQuantityTotal } from "../selectors/cart";
import { actionCreators } from "../reducers/cart";
import { TrolleyIcon } from "../../../sharedComponents/assets/icons";
import { Text, Counter } from "../../../sharedComponents";

import "./ViewCartButton.scss";
import classNames from "classnames";

interface Props {
    forceShow?: boolean;
}

export const ViewCartButton = ({ forceShow }: Props) => {
    const showViewCart = useSelector(getShowViewCart);
    const itemsQuantity = useSelector(getOrderItemQuantityTotal);

    const dispatch = useDispatch();

    const showCartModal = useCallback(() => {
        dispatch(actionCreators.showCart(true));
    }, [dispatch]);

    useEffect(() => {
        if (showViewCart) {
            document.body.classList.add("with-view-cart");
        } else {
            document.body.classList.remove("with-view-cart");
        }
    }, [showViewCart]);

    useEffect(
        () => () => {
            // Party and MenuSearchPage have ViewCartButton components. Closing search will unmount the button, when we may still be showing the button on Party
            const viewCartButtons = document.querySelectorAll(".view-cart-button");
            if (!viewCartButtons?.length) {
                document.body.classList.remove("with-view-cart");
            }
        },
        []
    );

    if (!showViewCart && !forceShow) return null;

    return (
        <div className={classNames(!forceShow && "view-cart-button")}>
            <Button onClick={showCartModal}>
                <Text mode="bold" preset="g-16" value="View cart" />
                <div className="view-cart-button__icons">
                    <TrolleyIcon />
                    <Counter quantity={itemsQuantity} />
                </div>
            </Button>
        </div>
    );
};
