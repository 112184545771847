import { AppState, AppDispatch } from "../../index";
import { Props, ToastifiedMessage } from "../components/ToastifiedMessage";
import { connect } from "react-redux";
import { actionCreators as PartyActions } from "../../../features/order/reducers/party";
import { auth } from "../../../common/auth";
import { getToastNotifications } from "../selectors";
import { getJoinTableStep } from "../../partyOnboarding/selectors";
import { DataProps, FunctionProps } from "src/common/types/utils";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const userId = auth.getId();
    const notifications = userId ? getToastNotifications(state) : undefined;
    const step = getJoinTableStep(state);
    const disabled = step !== "TouchToStart";

    return {
        notifications,
        disabled,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        viewNotification: (id: string) => dispatch(PartyActions.viewNotification([id])),
    };
};

export const ToastifiedMessageContainer = connect(mapStateToProps, mapDispatchToProps)(ToastifiedMessage);
