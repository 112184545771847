import "./assets/TipSelection.scss";

import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as paymentActions } from "../../payment/reducers";
import { getPaymentTipAmount, getTipLevel, getTipsConfiguration } from "../../payment/selectors";
import { Button, Text } from "../../../sharedComponents";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { MenuDataLocale } from "../../menudata/types/MenuDataLocale";
import { HeartIcon } from "src/sharedComponents/assets/icons";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { TipType } from "src/features/payment";
import { NativeBackButton } from "src/common/navigation";

const getTipLevelChoiceValue = (tipLevel: number, menuDataLocale: MenuDataLocale) =>
    !tipLevel ? "No tip" : menuDataLocale.formatPercent(tipLevel);

const getTipLevelClassName = (tipLevel: number) => (!tipLevel ? undefined : "percentage");

interface Props {
    renderPaymentButton: (disabled: boolean) => JSX.Element;
    onBack: () => void;
    tipRecipients?: string;
    calculatedTipAmount?: number;
}

export const TipSelection = ({ renderPaymentButton, onBack, tipRecipients = "team", calculatedTipAmount }: Props) => {
    const menuDataLocale = useContext(MenuDataLocaleContext);
    const selectedTipLevel = useSelector(getTipLevel);
    const paymentTipAmount = useSelector(getPaymentTipAmount);
    const tipConfigurations = useSelector(getTipsConfiguration);

    const dispatch = useDispatch();

    const updateTipLevel = useCallback(
        (tipLevel: number) => {
            dispatch(paymentActions.updateTipLevel(tipLevel));
        },
        [dispatch]
    );

    const onCustomTip = useCallback(() => {
        dispatch(paymentActions.setCustomTipAmount(TipType.CURRENCY));
    }, [dispatch]);

    const renderTipLevelChoice = (tipLevel: number) => {
        return (
            <Button
                key={`tip-level-${tipLevel}`}
                mode={tipLevel === selectedTipLevel ? "solid" : "outline"}
                onClick={() => updateTipLevel(tipLevel)}
                value={getTipLevelChoiceValue(tipLevel, menuDataLocale)}
                className={getTipLevelClassName(tipLevel)}
            />
        );
    };

    return (
        <div className="tip-level-selection">
            <NativeBackButton name="#tip-selection-(review-order)/predefined" onPressed={onBack} />
            <div className="tip-level-selection__heart">
                <HeartIcon />
            </div>
            <Text preset="title-28" mode="extra-bold" value={`Add a tip for our ${tipRecipients}`} />
            <div className="tip-level-selection__content">
                <div className="tip-level-selection__tip-levels">
                    {tipConfigurations.levels.map(renderTipLevelChoice)}
                </div>
                <TappableDiv onTap={onCustomTip}>
                    <Text
                        className={"tip-level-selection__custom"}
                        preset="g-16"
                        mode="medium"
                        value={`Enter other amount`}
                    />
                </TappableDiv>
                <Text
                    className="tip-level-selection__tip-amount"
                    preset="g-14"
                    value={
                        selectedTipLevel === null
                            ? "Select a tip amount"
                            : selectedTipLevel === 0
                            ? "No tip"
                            : `Adding a tip of ${menuDataLocale.formatCurrency(
                                  calculatedTipAmount ?? paymentTipAmount
                              )}`
                    }
                />
            </div>
            {renderPaymentButton(selectedTipLevel === null || selectedTipLevel === undefined)}
        </div>
    );
};
