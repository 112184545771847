import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import { openTableMiddleware } from "./middleware/openTableMiddleware";

export * from "./reducers";

export default function (config: FeatureConfiguration) {
    config.reducers.openTable = reducer;
    config.middleware.push(openTableMiddleware());
}
