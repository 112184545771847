import { actionCreators } from "../reducers/native";
import { actionCreators as notificationsActionCreators } from "src/features/notifications/reducers/notifications";
import { AppDispatch } from "../../index";

export function offline() {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreators.offline());
        dispatch(notificationsActionCreators.showNoNetworkConnection(true));
    };
}
export function online() {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreators.online());
        dispatch(notificationsActionCreators.showNoNetworkConnection(false));
    };
}
