import { getPayOnlyRemainingBalance } from "../selectors";
import { actionCreators } from "../reducers";
import { AppDispatch, AppState } from "../../index";
import { getPaymentMethodsAsNeeded } from "../../payment/actions/getPaymentMethods";
import { payOnlyPaymentWizard } from "../wizards";
import { getIsOpenTable } from "../../openTable/selectors";
import { getSelectedPaymentMethod } from "../../payment/selectors";
import { getRequireSignIn } from "../../accounts/selectors";
import { signInAction } from "../../signup/actions/signInAction";

export const payTotal = (afterSignIn?: boolean) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const isOpenTable = getIsOpenTable(state);

        if (isOpenTable) {
            const requireSignIn = getRequireSignIn(state);
            if (requireSignIn) {
                dispatch(
                    signInAction({
                        useLocationTheme: true,
                        slideAnimation: "slide-in",
                        onComplete: () => {
                            dispatch(payTotal(true));
                        },
                        getSignInComplete: (state) => !!payOnlyPaymentWizard.getState(state),
                    })
                );
                return;
            }
        }

        const remainingBalance = getPayOnlyRemainingBalance(state);
        const selectedPaymentMethod = isOpenTable
            ? await getPaymentMethodsAsNeeded(dispatch, getState)
            : getSelectedPaymentMethod(state);

        dispatch(actionCreators.setAmount(remainingBalance));
        dispatch(
            payOnlyPaymentWizard.actionCreators.start(
                !selectedPaymentMethod ? "SelectPaymentMethod" : "ReviewPayment",
                afterSignIn ? { overrideStartTransition: "slide-from-right" } : undefined
            )
        );
    };
};
