import React, { ReactElement, useMemo } from "react";
import { ListChildComponentProps, VariableSizeList as List } from "react-window";
import { HistoryListItem } from "src/features/orderHistoryData";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { Animate, Text } from "src/sharedComponents";
import { Spinner } from "src/features/Spinner";
import { TappableLink } from "src/sharedComponents/common/tappable";
import classNames from "classnames";
import "./HistoryList.scss";
import { FULL_SIZE, HISTORY_LINE_HEIGHT, PADDING } from "../constants";

export interface Props {
    name: string;
    items: HistoryListItem[];
    hasMore: boolean;
    loadMoreItems: () => void;
    loading: boolean;
    showAnonymous: boolean;
    to: (itemId: string) => string;
    historyCardTile: (item: HistoryListItem) => ReactElement<{ item: HistoryListItem }>;
}

const itemDataSize = (index: number, items: HistoryListItem[]) =>
    items[index] ? items[index].relatedItems.length * HISTORY_LINE_HEIGHT + FULL_SIZE + PADDING : HISTORY_LINE_HEIGHT;

let lastScrollPos: { [x: string]: number } = {};

export const HistoryList = ({
    items,
    hasMore,
    loadMoreItems,
    loading,
    showAnonymous,
    to,
    historyCardTile,
    name,
}: Props) => {
    const itemSize = (index: number) =>
        showAnonymous && index === items.length - 1 ? itemDataSize(index, items) + 350 : itemDataSize(index, items);
    return (
        <>
            {items.length !== 0 ? (
                <AutoSizer disableWidth>
                    {({ height, width }: Size) => (
                        <List
                            key={"history-list_" + items.length}
                            initialScrollOffset={lastScrollPos[name] || 0}
                            itemKey={(index: number) =>
                                !items[index] ? index : index + items[index].itemId + items[index].relatedItems.length
                            }
                            onScroll={({ scrollOffset }) => (lastScrollPos[name] = scrollOffset)}
                            className="history-list"
                            height={height}
                            itemCount={hasMore ? items.length + 1 : items.length}
                            itemSize={itemSize}
                            itemData={items}
                            overscanCount={1}
                            onItemsRendered={({ overscanStopIndex }) => {
                                if (!items[overscanStopIndex]) {
                                    window.requestAnimationFrame(loadMoreItems);
                                }
                            }}
                            width={width}
                        >
                            {({ index, style, data }: ListChildComponentProps) =>
                                RenderHistoryCard({ index, style, data, to, historyCardTile })
                            }
                        </List>
                    )}
                </AutoSizer>
            ) : (
                <div className="no-history-item">
                    {loading ? (
                        <Spinner />
                    ) : showAnonymous ? (
                        "You haven’t ordered anything in the last 24 hours"
                    ) : (
                        "You haven’t ordered anything yet"
                    )}
                </div>
            )}
        </>
    );
};

interface LoadProps {
    style?: React.CSSProperties;
}

const HistoryLoader = ({ style }: LoadProps) => {
    return (
        <li className="no-border-items history-list-item__loader" style={{ ...style }}>
            <Animate name="twoDotSpinner" className="history-list-item__loader__svg" />
        </li>
    );
};

interface RenderHistoryCardProps extends ListChildComponentProps {
    to: (itemId: string) => string;
    historyCardTile: (item: HistoryListItem) => ReactElement<{ item: HistoryListItem }>;
}

const RenderHistoryCard = ({ index, style, data, to, historyCardTile }: RenderHistoryCardProps) => {
    if (!data[index]) {
        return <HistoryLoader style={style} />;
    }

    const item = data[index] as HistoryListItem;
    return (
        <li
            className="no-border-items history-list-item"
            key={index + item?.itemId + item?.relatedItems.length}
            id={index + item?.itemId + item?.relatedItems.length}
            style={{ ...style, height: itemDataSize(index, data) - PADDING + "px" }}
        >
            <HistoryItemLink
                itemId={item.itemId}
                to={to}
                key={"card-" + index}
                className="order-history-item-card-link"
            >
                <HistoryCard item={item} historyCardTile={historyCardTile} />
            </HistoryItemLink>
            {item.relatedItems.map((subItem: HistoryListItem, orderIndex: number) => (
                <HistoryItemLink
                    key={"card-extra-" + orderIndex + subItem.itemId}
                    itemId={subItem.itemId}
                    to={to}
                    className="order-history-item-card-link"
                >
                    <HistoryCard item={subItem} noImage historyCardTile={historyCardTile} />
                </HistoryItemLink>
            ))}
        </li>
    );
};

interface HistoryItemLinkProps {
    itemId: string;
    to: (itemId: string) => string;
    className?: string;
    children: React.ReactNode;
}

const HistoryItemLink = ({ to, className, children, itemId }: HistoryItemLinkProps) => {
    const toLink = useMemo(() => to(itemId), [itemId, to]);
    return (
        <TappableLink to={toLink} className={className}>
            {children}
        </TappableLink>
    );
};

interface HistoryCardProps {
    item: HistoryListItem;
    noImage?: boolean;
    historyCardTile: (item: HistoryListItem) => ReactElement<{ item: HistoryListItem }>;
}

const HistoryCard = ({
    item,
    item: { locationName, locationImagePath },
    noImage,
    historyCardTile,
}: HistoryCardProps) => {
    const style = {
        "--shade_image": `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${locationImagePath})`,
    } as React.CSSProperties;
    return (
        <div className={classNames("history-card", "flex-cols")}>
            {!noImage && (
                <div className="history-card__descriptors shaded-image" style={style}>
                    <Text preset="title-24" mode="bold" className="history-card__name">
                        {locationName}
                    </Text>
                </div>
            )}
            {historyCardTile(item)}
        </div>
    );
};
