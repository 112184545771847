import { reducer as auth0Reducer } from "./reducers/auth0";
import { reducer as signInReducer } from "./reducers/signIn";
import { reducer as createVerifiedAccountReducer } from "./reducers/createVerifiedAccount";

import { FeatureConfiguration } from "..";

export * from "./types";

export { auth } from "../../common/auth/auth";
export * from "./actions";
export * from "./reducers";
export * from "./util";

export default function (config: FeatureConfiguration) {
    config.reducers.auth0 = auth0Reducer;
    config.reducers.signIn = signInReducer;
    config.reducers.createVerifiedAccount = createVerifiedAccountReducer;
}
