import { messageHandler } from "../../messages/messageHandler";
import loader from "../../../assets/images/loader.gif";
import { getEnvUrl } from "../../shared/env";
import { config } from "../../config";

let wnd: Window | null = null;

const closeWindow = () => {
    if (wnd && !wnd.closed) {
        wnd.close();
        wnd = null;
    }
};

const getRedirectingHtml = (redirectingText = "Redirecting, please wait...") => `
<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${redirectingText}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap" rel="stylesheet">
        <style>*{margin:0;padding:0}html{height:100%}body{margin:0;padding:0;min-height:100%;font-family:"Manrope",sans-serif;font-size:18px;font-weight:600;color:#333;line-height:1.25;display:flex;flex-direction:column;justify-content:center;align-items:center}p{margin-top:16px}</style>
    </head>
    <body>
        <img src="${getEnvUrl(config.REACT_APP_APP_BASE_URL)}${loader}" alt="loader" width="70" height="70">
        <p>${redirectingText}</p>
    </body>
</html>
`;

export const webFlow = {
    preStart: (redirectingText?: string) => {
        const url = URL.createObjectURL(new Blob([getRedirectingHtml(redirectingText)], { type: "text/html" }));
        wnd = window.open(url, "_blank");
    },
    cancelPreStart: closeWindow,
    start: (startUrl: string, returnUrl: string, messageType: string) =>
        new Promise<URL>(async (res, rej) => {
            const [urlPromise, cancel] = messageHandler.listen(messageType, returnUrl);

            function handleVisibility() {
                if (document.visibilityState === "visible") {
                    closeWindow();
                    // I don't want to risk this running before the 'message' event handler
                    setTimeout(destroy, 500);
                }
            }

            function destroy() {
                cancel();
                window.document.removeEventListener("visibilitychange", handleVisibility);
            }

            window.document.addEventListener("visibilitychange", handleVisibility);

            if (wnd !== null && !wnd.closed) {
                wnd.location.href = startUrl;
            } else {
                wnd = window.open(startUrl, "_blank");
            }

            if (wnd === null) {
                destroy();
                return rej(Error("Failed to open window"));
            }

            const url = await urlPromise;

            if (url) {
                res(url);
            } else {
                rej(Error("cancelled"));
            }
        }),
};
