import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import "./DiagnosticInformation.scss";
import { AppState } from "../features";
import { useSelector, useDispatch } from "react-redux";
import { config } from "./config";
import { getVisibleMenuData } from "../features/menu/selectors";
import { getAnyParty, getCanShowDemoFeatures, getTestingFeaturesEnabled } from "../features/order/selectors";
import { getCurrentMemberId, getIsAnonymous } from "src/features/accounts/selectors";
import { getLegacyRegion } from "../features/region/utils/getLegacyRegion";
import { regionHelper } from "../features/region";
import { createSelector } from "reselect";
import { getGroupTabData, getIsGroupTabOpen, getTabTypeName } from "../features/groupTabs/selectors/activeGroupTab";
import { capitaliseFirstLetter } from "./formatter";
import CopyToClipboard from "react-copy-to-clipboard";
import { getAppInsights } from "../features/analytics";
import { getTableToken } from "../features/partyOnboarding/persistence/tableToken";
import jwtDecode from "jwt-decode";
import { TableToken } from "../features/partyOnboarding/types";
import { Checkbox } from "src/sharedComponents";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { updateTestingFeatures } from "src/features/partyOnboarding/actions/setTestingFeatures";
import { SimpleNavHeader } from "./navigation";
interface Props {
    onClick?: () => void;
}

interface Diagnostic {
    info: string;
    id?: string;
}

const getApplicationUpdateAvailable = (state: AppState) => state.applicationUpdate.status === "update-available";

const getDiagnosticInformation = createSelector(
    getApplicationUpdateAvailable,
    getVisibleMenuData,
    getAnyParty,
    getCurrentMemberId,
    getIsAnonymous,
    getGroupTabData,
    getIsGroupTabOpen,
    getTabTypeName,
    (updateAvailable, menuData, party, memberId, isAnonymous, groupTab, isGroupTabOpen, tabTypeName) => {
        const diagnostics: Diagnostic[] = [
            { info: config.REACT_APP_BUILD_NUMBER ?? "" },
            { info: `Member: ${isAnonymous ? "anonymous" : "verified"}`, id: memberId },
        ];

        if (party) {
            diagnostics.push({ info: `Table: ${party.tableNumber}`, id: party.id });
            diagnostics.push({ info: `Location: ${menuData?.title ?? "unknown"}`, id: party.restaurantId });
        }

        const tableToken = getTableToken();

        if (tableToken) {
            const decodedTableToken = jwtDecode<TableToken>(tableToken);
            diagnostics.push({
                info: `Claim: ${decodedTableToken.locationclaimtype ?? "unknown"}`,
                id: decodedTableToken.locationclaimtoken,
            });
        }

        if (groupTab) {
            diagnostics.push({
                info: `${capitaliseFirstLetter(tabTypeName)}: ${isGroupTabOpen ? "" : "in"}active`,
                id: groupTab.id,
            });
        }

        const appInsights = getAppInsights();

        diagnostics.push({
            info: `Telemetry: ${
                !appInsights || !appInsights.config || appInsights.config.disableTelemetry === true
                    ? "disabled"
                    : "enabled"
            }`,
            id:
                (appInsights as any)?.context?.sessionManager?.automaticSession?.id ??
                appInsights?.context?.session?.id,
        });

        diagnostics.push({ info: `Current region: ${regionHelper.region.id}` });

        const regions = Object.keys(localStorage)
            .filter((key) => key.startsWith("auth"))
            .map((key) => key.split("_")[1] ?? getLegacyRegion());

        diagnostics.push({ info: `Authenticated regions: ${regions.join(", ")}` });
        diagnostics.push({ info: `User-Agent: ${navigator.userAgent}` });

        return {
            updateAvailable,
            diagnostics,
        };
    }
);

export const DiagnosticInformation = ({ onClick }: Props) => {
    const { updateAvailable, diagnostics } = useSelector(getDiagnosticInformation);
    const canShowTestingFeatures = useSelector(getCanShowDemoFeatures);
    const testingFeaturesEnabled = useSelector(getTestingFeaturesEnabled);

    const dispatch = useDispatch();

    const info = useMemo(
        () => diagnostics.map((d) => `${d.info}${d.id ? ` (${d.id})` : ""}`).join(", "),
        [diagnostics]
    );

    const [copied, setCopied] = useState("");

    const onUpdateClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        window.location.reload();
    }, []);

    const onCopyClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
    }, []);

    const onCopy = useCallback((text: string) => {
        setCopied(text);
    }, []);

    return (
        <>
            <SimpleNavHeader customBack="diagnostics-page" onBack={onClick} closeToBack backgroundTransparent />
            <div className="diagnosticInformation__wrapper" onClick={onClick}>
                {updateAvailable && (
                    <button className="diagnosticInformation__apply-update" onClick={onUpdateClick}>
                        Update available
                    </button>
                )}
                <CopyToClipboard text={info} onCopy={onCopy}>
                    <button className="diagnosticInformation__copy-info" onClick={onCopyClick}>
                        {copied === info ? "Copied" : "Copy all"}
                    </button>
                </CopyToClipboard>
                {diagnostics.map((i) => (
                    <div key={i.info} className="diagnosticInformation__detail">
                        <span>
                            {i.info}
                            {i.id && (
                                <>
                                    <br />({i.id})
                                </>
                            )}
                        </span>
                        {i.id && (
                            <CopyToClipboard text={i.id} onCopy={onCopy}>
                                <button onClick={onCopyClick}>{copied === i.id ? "Copied" : "Copy"}</button>
                            </CopyToClipboard>
                        )}
                    </div>
                ))}
                {canShowTestingFeatures && (
                    <TappableDiv
                        className="diagnosticInformation__detail"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(updateTestingFeatures(!testingFeaturesEnabled));
                        }}
                    >
                        <span>Enable testing features</span>
                        <Checkbox checked={testingFeaturesEnabled} id="addAllItem" />
                    </TappableDiv>
                )}
            </div>
        </>
    );
};
