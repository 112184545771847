import { setOrderMemberAcceptedOperation } from "../operations";
import { orderApi } from "../orderApi";
import { AppDispatch, AppState } from "../..";
import { getOrderAcceptedMembers } from "../selectors";
import { showModalMessage } from "../../modalMessage/actions/show";
import { modalMessages } from "../../modalMessage/messages";
import { getCurrentMemberId } from "../../accounts/selectors";

export const setOrderMemberAccepted = (hasAccepted: boolean) => {
    return setOrderMemberAcceptedOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const state = getState();
            const currentMemberId = getCurrentMemberId(state);
            const acceptedMembers = getOrderAcceptedMembers(state);

            if (!hasAccepted && !acceptedMembers?.find((m) => m === currentMemberId)) {
                return;
            }

            await orderApi.invokeWithPartyUpdateAsNeeded(dispatch, getState, "updateAcceptedMember", hasAccepted);
        } catch (e) {
            if (e) {
                // TODO: Use more specific message on error
                dispatch(showModalMessage(modalMessages.failedToSubmit()));
            }
            throw e; // For app insights
        }
    });
};
