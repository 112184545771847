import { completeStripePaymentOperation } from "../operations";
import { ExternalPaymentResult } from "../../../payment";
import { getStripe, PaymentType } from "src/common/payment";
import { getNewCardPaymentMethod, getSelectedPaymentMethod } from "../../../payment/selectors";
import {
    StripeApplePayPaymentMethod,
    StripeCreatePaymentIntentResult,
    StripeNewCardPaymentMethod,
    StripePaymentMethod,
} from "../types";
import { applePay } from "src/common/experience";
import { HandleExternalPaymentFunc } from "../../types";
import { Stripe } from "@stripe/stripe-js";

export const handleStripePayment: HandleExternalPaymentFunc = (dispatch, getState, beginPaymentResult) => {
    return completeStripePaymentOperation.invoke(async () => {
        const { clientSecret, paymentIntentId, requiresAction } = beginPaymentResult as StripeCreatePaymentIntentResult;

        // Use the original PaymentIntent ID and not the one from the handleCardAction result
        // If we get a network error we won't get a PaymentIntent in the result but it may have completed
        const externalTransactionResult: ExternalPaymentResult = {
            transactionId: paymentIntentId,
        };

        const state = getState();
        const selectedPaymentMethod = getSelectedPaymentMethod(state);
        const newCardPaymentMethod = getNewCardPaymentMethod(state);

        if (!selectedPaymentMethod) {
            // If there's no selected payment method the party has been
            // closed already so just return like it was successful
            return externalTransactionResult;
        }

        if (!requiresAction) {
            // Payment was confirmed on the server so there's nothing to do
            if (selectedPaymentMethod.paymentType === PaymentType.APPLEPAY) {
                await applePay.completePayment(selectedPaymentMethod as StripeApplePayPaymentMethod, clientSecret);
            }
            return externalTransactionResult;
        }

        const apiKey =
            selectedPaymentMethod.paymentType === PaymentType.ADDEDCARD
                ? (newCardPaymentMethod as StripeNewCardPaymentMethod).apiKey
                : (selectedPaymentMethod as unknown as StripePaymentMethod).apiKey;

        let stripe: Stripe;

        try {
            stripe = await getStripe(apiKey);
        } catch (e) {
            // No logging - Stripe load errors are logged to AI anyway

            // If we can't load Stripe.js, we return like it was successful
            // The payment may have completed in beginPayment, and the actual
            // status of the payment will be verified server side anyway.
            return externalTransactionResult;
        }

        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                for (const parent of Array.from(mutation.addedNodes) as HTMLElement[]) {
                    for (const child of Array.from(parent.childNodes) as HTMLElement[]) {
                        if (child.matches("iframe[name^='__privateStripeFrame']")) {
                            parent.classList.add("stripe-threedsecure");
                            observer.disconnect();
                            return;
                        }
                    }
                }
            }
        });

        try {
            // Wait for the 3DS2 iframe to be added so we can bring it to the front
            observer.observe(document.body, { childList: true });

            const result = await stripe.handleCardAction(clientSecret);

            if (result.error) {
                const { decline_code, code, message, type } = result.error;

                externalTransactionResult.error = {
                    code: decline_code ?? code ?? "card_error",
                    reason: message,
                    isConsumerFriendly: type === "card_error" && !!message,
                };
            }
        } finally {
            observer.disconnect();
        }

        if (selectedPaymentMethod.paymentType === PaymentType.APPLEPAY) {
            await applePay.completePayment(selectedPaymentMethod as StripeApplePayPaymentMethod, clientSecret);
        }
        return externalTransactionResult;
    }, dispatch);
};
