export interface Cookies {
    [name: string]: string;
}

export const getCookies = (separator: string = "|") =>
    document.cookie.split(";").reduce((cookies, cookie) => {
        const [name, value] = cookie
            .split("=")
            .map((s) => decodeURIComponent(s.trim().replace(separator, encodeURIComponent(separator))));
        cookies[name] = value;
        return cookies;
    }, {} as Cookies);

export const getCookie = (name: string) => getCookies()[name];

export const setCookie = (name: string, value: string, options: any = {}, separator: string = "|") => {
    options = {
        path: "/",
        ...options,
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    document.cookie = Object.keys(options).reduce((cookie, key) => {
        return cookie + `; ${key}${options[key] !== true ? `=${options[key]}` : ""}`;
    }, `${encodeURIComponent(name)}=${encodeURIComponent(value).replace(encodeURIComponent(separator), separator)}`);
};

export const deleteCookie = (name: string) => setCookie(name, "", { "max-age": -1 });
