import { setRating, resetRating } from "./setRating";
import { setTags, setPositiveNote, setNegativeNote, setEmail } from "./setSurvey";
import { sendSurvey } from "./sendSurvey";
import { fetchSurveyDetails } from "./fetchSurveyDetails";

export const actions = {
    setRating,
    resetRating,
    setTags,
    setPositiveNote,
    setNegativeNote,
    setEmail,
    sendSurvey,
    fetchSurveyDetails,
};
