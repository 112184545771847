import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Text } from "../../../sharedComponents";
import { TappableLink } from "../../../sharedComponents/common/tappable";
import { modalMessages } from "../../modalMessage/messages";
import { ReplaceLinkProps, replaceLinks } from "../../../common/strings";
import { ModalActionMessage } from "../../modalMessage/reducers/modalMessage";
import "../assets/PrivacySettings.scss";
import { gdpr } from "../../../common/experience";
import { useDispatch } from "react-redux";
import { cookiePreferencesActionCreators } from "../../compliance/reducers";

export const PrivacySettings = () => {
    const dispatch = useDispatch();

    const [origCookiePreferences] = useState(gdpr.getCookiePreferences());

    const modal = useMemo(() => {
        const localCookiePreferences = { ...origCookiePreferences };
        return modalMessages.cookiePreferences(
            () => {},
            (allowed) => {
                localCookiePreferences.allowAnalytics = allowed;
                gdpr.setCookiePreferences(localCookiePreferences);
            },
            (allowed) => {
                localCookiePreferences.allowAdvertising = allowed;
                gdpr.setCookiePreferences(localCookiePreferences);
            },
            localCookiePreferences.allowAnalytics,
            localCookiePreferences.allowAdvertising
        ) as ModalActionMessage;
    }, [origCookiePreferences]);

    const LinkComponent = useCallback(({ children }: PropsWithChildren<ReplaceLinkProps>) => {
        return <TappableLink to="/cookie-policy">{children}</TappableLink>;
    }, []);

    useEffect(() => {
        // Only dispatch the changed event when this component is unmounted and if the
        // cookie settings actually changed, as some listeners may reload the app
        return () => {
            const cookiePreferences = gdpr.getCookiePreferences();
            if (
                cookiePreferences.allowAnalytics !== origCookiePreferences.allowAnalytics ||
                cookiePreferences.allowAdvertising !== origCookiePreferences.allowAdvertising
            ) {
                dispatch(cookiePreferencesActionCreators.changed(cookiePreferences));
            }
        };
    }, [dispatch, origCookiePreferences]);

    return (
        <div className="privacy-settings">
            <div className="scroll-element animated-child">
                <div className="profile-page__title">
                    <Text preset="title-28" mode="bold">
                        {modal.title}
                    </Text>
                </div>
                <Text preset="g-14" className="privacy-settings__text">
                    {replaceLinks(modal.expandedText, LinkComponent)}
                </Text>
                {modal.additionalExpandedContent}
            </div>
        </div>
    );
};
