import * as React from "react";
import { FC, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { OrderCourseItem } from "../reducers/types";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Text } from "../../../sharedComponents";
import { OrderItems } from "./OrderItems";
import { getIsOpenTable } from "src/features/openTable/selectors";

export interface Props {
    food?: OrderCourseItem[];
    drinks?: OrderCourseItem[];
    editable?: boolean;
    removeItem?: (orderItemId: string, menuItemId: string) => void;
}

export const CartItemsByType: FC<Props> = ({ food, drinks, editable, removeItem }) => {
    const isOpenTable = useSelector(getIsOpenTable);
    const itemsRef = useRef<HTMLDivElement>(null);
    const scrollLockedRef = useRef(false);

    const enableScroll = useCallback(() => {
        if (scrollLockedRef.current) {
            enableBodyScroll(itemsRef.current!);
            scrollLockedRef.current = false;
        }
    }, []);

    useEffect(() => {
        let leftObserver: MutationObserver | null = null;

        if (editable) {
            // react-swipe-to-delete-component lets you swipe in either direction to delete. I only want to allow
            // swiping from right to left so I'm monitoring the 'left' style attribute changing for all order items
            // and setting it to '0px' if it's > 0px. It also prevents the swipe if we're not connected
            leftObserver = new MutationObserver((mutations) => {
                for (const mutation of mutations) {
                    const style = (mutation.target as HTMLElement).style;

                    let left = parseInt(style.left);

                    if (left > 0 || window.navigator.onLine === false) {
                        style.left = "0px";
                        left = 0;
                    }

                    if (left < 0) {
                        if (!scrollLockedRef.current) {
                            disableBodyScroll(itemsRef.current!);
                            scrollLockedRef.current = true;
                        }
                    } else {
                        enableScroll();
                    }
                }
            });

            leftObserver.observe(itemsRef.current!, {
                attributes: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        return () => {
            leftObserver?.disconnect();
            enableScroll();
        };
    }, [editable, enableScroll]);

    return (
        <div ref={itemsRef}>
            <div className="cart-view__content__items">
                {!!food && food.length > 0 && (
                    <>
                        <Text
                            preset="g-14"
                            mode={["bold", "block", "emphasis"]}
                            className="cart-view__content__items__header"
                            id="cart-view__content__items--food"
                        >
                            Food
                        </Text>
                        <OrderItems
                            hidePrice={isOpenTable}
                            items={food}
                            editable={editable}
                            removeItem={removeItem}
                            onDeleted={enableScroll}
                        />
                    </>
                )}
            </div>
            <div className="cart-view__content__items">
                {!!drinks && drinks.length > 0 && (
                    <>
                        <Text
                            preset="g-14"
                            mode={["bold", "block", "emphasis"]}
                            className="cart-view__content__items__header"
                            id="cart-view__content__items--drinks"
                        >
                            Drinks
                        </Text>
                        <OrderItems
                            hidePrice={isOpenTable}
                            items={drinks}
                            editable={editable}
                            removeItem={removeItem}
                            onDeleted={enableScroll}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
