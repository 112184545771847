import {
    BaseTelemetryPlugin,
    IAppInsights,
    IAppInsightsCore,
    IConfiguration,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-web";
import { IPlugin } from "@microsoft/applicationinsights-core-js";
import { findAppInsightsPlugin } from "../util/findAppInsightsPlugin";
import { deviceReady } from "src/common/experience";

export class AppStartPlugin extends BaseTelemetryPlugin {
    public identifier = "MeanduAppStartPlugin";

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);

        const appInsights = findAppInsightsPlugin(extensions);

        deviceReady()
            .then(() => {
                if (appInsights) {
                    trackStartEvent(appInsights);
                }
            })
            .catch(() => {});
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        this.processNext(event, itemCtx!);
    }
}

function trackStartEvent(appInsights: IAppInsights) {
    appInsights.trackEvent({
        name: "APP_STARTUP",
    });
}
