import { flatten } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Price } from "src/features/menu/components/Price";
import { Divider, Text } from "src/sharedComponents";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { getAnyParty, getAnyPartyDetails, getTableLabel } from "../../order/selectors";
import { getVisibleMenuData } from "../../menu/selectors";
import classNames from "classnames";
import { useMenuDataLocaleContext } from "../../menudata/context/MenuDataLocaleContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AppState } from "../../index";
import { getReceiptData } from "../selectors";
import { getSurchargeText } from "../../payment/util/getSurchargeText";
import { SmallCircleTick } from "src/sharedComponents/assets/icons";
import { capitaliseFirstLetter } from "src/common/formatter";

interface Props {
    refreshing?: boolean;
}

export const PayOnlyReceiptInfo = ({ refreshing }: Props) => {
    const party = useSelector(getAnyParty);
    const dateOpened = party?.dateOpened;
    const partyDetails = useSelector(getAnyPartyDetails);
    const menuData = useSelector(getVisibleMenuData);
    const tableLabel = useSelector(getTableLabel);

    const menuDataLocale = useMenuDataLocaleContext();

    const date = useMemo(
        () => dateOpened && menuData && new Intl.DateTimeFormat(menuData.locale).format(new Date(dateOpened)),
        [menuData, dateOpened]
    );

    const time = useMemo(
        () =>
            dateOpened &&
            menuData &&
            new Intl.DateTimeFormat(menuData.locale, { hour: "2-digit", minute: "2-digit" }).format(
                new Date(dateOpened)
            ),
        [menuData, dateOpened]
    );

    const { items, bill, useSubmittedOrderItems, paidWithMeanduTotal, paidWithServerTotal, paidWithServerLabel } =
        useSelector((state: AppState) => getReceiptData(state, refreshing));

    return (
        <SkeletonTheme baseColor="#f0f0f0" height={10} borderRadius={12} enableAnimation={false}>
            <div className="pay-only-receipt__container">
                <div className="pay-only-receipt__container__area">
                    <div className={classNames("pay-only-receipt__container__header", !items?.length && "no-items")}>
                        {menuData?.logoUrl && <SVG src={menuData.logoUrl} />}
                        {partyDetails?.location && <Text preset="g-18" mode="bold" value={partyDetails.location} />}
                        {partyDetails?.address && <Text preset="g-14" value={partyDetails.address} />}
                    </div>
                    {!!items?.length && (
                        <>
                            <Divider dashed={!refreshing} transparent={refreshing} />
                            {date && <Row title="Date" value={date} refreshing={refreshing} />}
                            {time && <Row title="Time" value={time} refreshing={refreshing} />}
                            {party?.tableNumber && (
                                <Row
                                    title={capitaliseFirstLetter(tableLabel)}
                                    value={party.tableNumber}
                                    refreshing={refreshing}
                                />
                            )}
                            <Divider dashed={!refreshing} transparent={refreshing} />
                            <div
                                className={classNames(
                                    "pay-only-receipt__container__item-row",
                                    refreshing && "refreshing"
                                )}
                            >
                                {items.map((item, index) =>
                                    !item.displayName ? null : (
                                        <React.Fragment key={item.displayName + index}>
                                            <ItemRow
                                                className="pay-only-receipt__container__item-row__product"
                                                quantity={item.quantity}
                                                displayName={item.displayName}
                                                price={(item.unitPrice ?? 0) * item.quantity || item.price || 0}
                                                paid={item.isPaid && !refreshing}
                                                refreshing={refreshing}
                                            />
                                            {item.modifiers &&
                                                flatten(
                                                    item.modifiers.map((m, index) =>
                                                        !m.selectedOptions
                                                            ? []
                                                            : m.selectedOptions.map((option, oIndex) => (
                                                                  <ItemRow
                                                                      key={option.displayName + oIndex + index}
                                                                      displayName={option.displayName}
                                                                      hideZero
                                                                      price={
                                                                          option.unitPrice * item.quantity ||
                                                                          option.price ||
                                                                          0
                                                                      }
                                                                      paid={item.isPaid && !refreshing}
                                                                      refreshing={refreshing}
                                                                  />
                                                              ))
                                                    )
                                                )}
                                        </React.Fragment>
                                    )
                                )}
                            </div>
                            {bill && (
                                <>
                                    <Divider dashed={!refreshing} transparent={refreshing} />
                                    <PriceRow
                                        title="Subtotal"
                                        value={bill.subTotal}
                                        refreshing={refreshing}
                                        forceRender={useSubmittedOrderItems}
                                    />
                                    {bill.taxSummary.map((tax) => (
                                        <PriceRow
                                            key={tax.taxClass}
                                            title={tax.taxClass}
                                            value={tax.total}
                                            refreshing={refreshing}
                                        />
                                    ))}
                                    <PriceRow
                                        title="Venue discount"
                                        value={bill.discountAmount}
                                        refreshing={refreshing}
                                    />
                                    <PriceRow
                                        title={getSurchargeText(menuDataLocale, bill.surchargePercentage)}
                                        value={bill.surchargeAmountExTax || bill.surchargeAmount}
                                        refreshing={refreshing}
                                    />
                                    <TotalRow
                                        title="Total"
                                        value={bill.total}
                                        refreshing={refreshing}
                                        forceRender={useSubmittedOrderItems}
                                    />
                                    <Divider dashed={!refreshing} transparent={refreshing} />
                                    {paidWithServerTotal > 0 ? (
                                        <PriceRow
                                            icon="tick"
                                            title={paidWithServerLabel}
                                            value={paidWithServerTotal}
                                            refreshing={refreshing}
                                        />
                                    ) : null}
                                    {paidWithMeanduTotal > 0 ? (
                                        <PriceRow
                                            icon="tick"
                                            title="Paid on me&u"
                                            value={paidWithMeanduTotal}
                                            refreshing={refreshing}
                                        />
                                    ) : null}
                                    {(bill.remainingBalance ?? 0) < bill.total ? (
                                        <TotalRow
                                            title="Total paid"
                                            value={bill.total - (bill.remainingBalance ?? 0)}
                                            refreshing={refreshing}
                                        />
                                    ) : null}
                                    <TotalRow
                                        title="Balance"
                                        value={bill.remainingBalance ?? 0}
                                        refreshing={refreshing}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </SkeletonTheme>
    );
};

interface RowProps {
    title: string;
    value: ReactNode;
    refreshing?: boolean;
    icon?: ReactNode;
}

const Row = ({ icon, title, value, refreshing }: RowProps) => (
    <div className={classNames("pay-only-receipt__container__row", refreshing && "refreshing")}>
        <span className="text">
            {!refreshing ? icon : null}
            <Text preset="g-14">{!refreshing ? title : <Skeleton />}</Text>
        </span>
        <Text preset="g-14">{!refreshing ? value : <Skeleton />}</Text>
    </div>
);

interface PriceRowProps extends Omit<RowProps, "value" | "icon">, PriceRowIconProps {
    value: number;
    forceRender?: boolean;
}

type PriceRowIcon = "tick";

const PriceRow = ({ title, value, refreshing, forceRender, icon }: PriceRowProps) =>
    !value && !forceRender ? null : (
        <Row
            title={title}
            icon={<PriceRowIcon icon={icon} />}
            value={<Price priceTextPreset="g-14" price={value} />}
            refreshing={refreshing}
        />
    );

interface ItemRowProps {
    quantity?: number;
    displayName: string;
    price: number;
    className?: string;
    hideZero?: boolean;
    refreshing?: boolean;
    paid: boolean;
}

const ItemRow = ({ quantity, displayName, price, className, hideZero, refreshing, paid }: ItemRowProps) => {
    return (
        <>
            <Text className={className} preset="g-14">
                {!refreshing ? quantity ? `${quantity}x` : "" : <Skeleton />}
            </Text>
            <Text className={className} preset="g-14">
                {!refreshing ? (
                    <>
                        {}
                        {displayName}
                    </>
                ) : (
                    <Skeleton />
                )}
            </Text>
            {paid && !hideZero ? (
                <span className={className + " pay-only-receipt__container__item-row__paid-label"}>
                    <Text preset="g-12">Paid</Text>
                </span>
            ) : (
                <Text className={className} preset="g-12">
                    {" "}
                </Text>
            )}

            {!refreshing &&
                (hideZero && !price ? (
                    <span></span>
                ) : (
                    <Price className={className} priceTextPreset="g-14" price={price || 0} strike={paid} />
                ))}
        </>
    );
};

const TotalRow = ({ title, value, refreshing, forceRender }: PriceRowProps) =>
    !value && !forceRender ? null : (
        <div className={classNames("cart-view__content__total", refreshing && "refreshing")}>
            <Text preset="g-16" mode="bold">
                {!refreshing ? title : <Skeleton />}
            </Text>
            {!refreshing ? (
                <Price priceTextPreset="g-16" priceTextMode="bold" price={value} />
            ) : (
                <Text preset="g-16" mode="bold">
                    <Skeleton />
                </Text>
            )}
        </div>
    );

interface PriceRowIconProps {
    icon?: PriceRowIcon;
}

const PriceRowIcon = ({ icon }: PriceRowIconProps) => {
    if (!icon) {
        return null;
    }

    return <SmallCircleTick />;
};
