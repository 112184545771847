import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { setSelectValueWithEvent } from "src/common/shared";
import { validationRules } from "src/common/validation";
import { FormControl, PhoneInput, CountryCode, CountryOption, getCountryCallingCode } from "src/sharedComponents";
import "../assets/EditPhoneInput.scss";
import { MenuDataLocaleContext } from "src/features/menudata/context/MenuDataLocaleContext";
import { regionHelper } from "src/features/region";
import { config } from "src/common/config";
import { StarCheck } from "src/sharedComponents/assets/icons";

interface Props {
    id?: string;
    phone?: string;
    watchValue?: any;
    error?: string;
    disabled?: boolean;
    message?: string;
    control: any;
    hideClear?: boolean;
    hidePlaceholder?: boolean;
    validateOnChange: () => void;
    onClear?: () => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
}

type PhoneNumberLocale = {
    countryOptions: CountryOption[];
    exampleNumber: string;
};

const phoneNumberLocales: { [code: string]: PhoneNumberLocale } = {
    AU: {
        countryOptions: ["AU", "NZ", "|", "..."],
        exampleNumber: "0499 999 999",
    },
    GB: {
        countryOptions: ["GB", "IE", "|", "..."],
        exampleNumber: "07700 000000",
    },
    NZ: {
        countryOptions: ["NZ", "AU", "|", "..."],
        exampleNumber: "02 000 00000",
    },
    US: {
        countryOptions: ["US", "|", "..."],
        exampleNumber: "(555) 555 5555",
    },
};

function getPhoneNumberLocale(code: string) {
    return (
        phoneNumberLocales[code] ?? {
            ...phoneNumberLocales["AU"],
            countryOptions: [code, "|", "..."],
        }
    );
}

export const EditPhoneInput = ({
    id,
    phone,
    watchValue,
    error,
    disabled,
    message,
    control,
    hideClear,
    hidePlaceholder,
    validateOnChange,
    onClear,
    onFocus,
}: Props) => {
    const [countryShortCode, setCountryShortCode] = useState<CountryCode>();
    const [defaultPhoneNumberLocale, setDefaultPhoneNumberLocale] = useState<PhoneNumberLocale>();
    const [currentPhoneNumberLocale, setCurrentPhoneNumberLocale] = useState<PhoneNumberLocale>();
    const countrySelect = useRef<HTMLSelectElement>();
    const phoneDiv = useRef<HTMLDivElement>();

    const menuDataLocale = useContext(MenuDataLocaleContext);

    const defaultCountryCode = (menuDataLocale?.regionCode || regionHelper.region.id.toUpperCase()) as CountryCode;

    const handleOnKeyUp = useCallback(
        (event?: React.KeyboardEvent<HTMLInputElement>) => {
            if (event?.key === "Enter") {
                (event.target as HTMLElement).blur();
            }
            validateOnChange();
        },
        [validateOnChange]
    );

    useEffect(() => {
        const locale = getPhoneNumberLocale(defaultCountryCode);
        setDefaultPhoneNumberLocale(locale);
        setCountryShortCode(defaultCountryCode);
    }, [defaultCountryCode]);

    useEffect(() => {
        if (!countryShortCode) return;
        phoneDiv.current?.style.setProperty("--code-content", getCountryCallingCode(countryShortCode));
        setCurrentPhoneNumberLocale(getPhoneNumberLocale(countryShortCode));
    }, [countryShortCode]);

    if (!currentPhoneNumberLocale || !defaultPhoneNumberLocale || !countryShortCode) {
        return null;
    }

    const refContainer = (ref: HTMLDivElement | null) => {
        if (ref) {
            countrySelect.current = ref.getElementsByTagName("SELECT")[0] as HTMLSelectElement;
            phoneDiv.current = ref;
            phoneDiv.current.style.setProperty("--code-content", getCountryCallingCode(countryShortCode));
        } else {
            countrySelect.current = undefined;
            phoneDiv.current = undefined;
        }
    };

    const handleOnCountryChange = (country?: CountryCode) => {
        if (country) {
            setCountryShortCode(country);
        } else if (countryShortCode && countrySelect.current) {
            setSelectValueWithEvent(countrySelect.current, countryShortCode);
        }
    };

    return (
        <FormControl id={id} disabled={disabled} invalid={!!error} message={message} invalidMessage={error}>
            <Controller
                as={
                    <PhoneInput
                        className="edit-phone-input"
                        refContainer={refContainer}
                        allowClear={!hideClear && !disabled}
                        label="Phone number"
                        value={watchValue}
                        icon={disabled ? <StarCheck /> : undefined}
                        defaultCountry={countryShortCode}
                        countryOptionsOrder={defaultPhoneNumberLocale.countryOptions}
                        placeholder={hidePlaceholder ? undefined : currentPhoneNumberLocale.exampleNumber}
                        flagUrl={`${config.phoneInputFlagsPath}{xx}.svg`}
                        onChange={() => {}}
                        onKeyUp={handleOnKeyUp}
                        onCountryChange={handleOnCountryChange}
                        onClear={onClear}
                        onFocus={onFocus}
                    />
                }
                name="phone"
                control={control}
                rules={{ ...validationRules.phone }}
                defaultValue={phone}
            />
        </FormControl>
    );
};
