import { AppDispatch, AppState } from "../..";
import { getDietaryTagGroup, getDietaryTagGroupDetails } from "src/features/menu/selectors";
import { actionCreators } from "../reducers";
import * as accountApi from "src/features/accountmenu/api/accountApi";
import { getServiceDietaryTags } from "../../order/selectors";
import { actionCreators as partyActions } from "../../order/reducers/party";

export function applyDietaryTags() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const group = getDietaryTagGroup(state);

        if (!group) {
            return;
        }

        const tags = await accountApi.fetchFoodPreferencesApi();

        const customerFoodPreference = getDietaryTagGroupDetails(group.tags, tags);
        dispatch(partyActions.trackCustomerFoodPreferenceLoad(customerFoodPreference.toString()));

        const serviceDietaryTags = getServiceDietaryTags(state);

        if (!serviceDietaryTags) {
            return;
        }

        const availablePreferenceTags = tags.filter((t) => serviceDietaryTags.some((dt) => dt.id === t));

        const tagMap = {
            [group.id]: availablePreferenceTags,
        };

        dispatch(actionCreators.applyPreferences(tagMap));
    };
}
