import { AppDispatch, AppState } from "src/features";
import { fetchAllOffersOperation } from "../operations";
import { actionCreators } from "../reducers";
import { fetchAvailableOffers } from "../api/fetchAvailableOffers";
import { getShouldShowOfferTooltip } from "./offerTooltipAction";
import { modalMessages } from "src/features/modalMessage/messages";
import { showModalMessage } from "src/features/modalMessage/actions/show";
import { getLocationName } from "src/features/menu/selectors";
import { actionCreators as navBarActionCreators } from "src/common/navigation/reducers/navBar";
import { NavBarType } from "src/common/navigation/types";
import { getActiveLocationId, getIsEngageEnabledForLocation } from "src/features/order/selectors";
import { OfferData, OfferType } from "../types";
import { getNavBarStatus } from "src/common/navigation";
import { marketingMetaData } from "src/features/marketing/actions/displayMarketing";

export function getAvailableOffersAction() {
    return fetchAllOffersOperation.getThunk(async (dispatch: AppDispatch, getState: () => AppState) => {
        const engageEnabled = getIsEngageEnabledForLocation(getState());
        const navBarStatus = getNavBarStatus(getState());
        const locationId = getActiveLocationId(getState());

        if (!engageEnabled) {
            return;
        }
        const response = await fetchAvailableOffers();
        const filteredOffers = response.availableOffers.filter(
            (offer: OfferData) => offer.offerType !== OfferType.OTHER
        );

        dispatch(actionCreators.setAvailableOffers(filteredOffers));
        dispatch(actionCreators.setDiscoverableOffers(response.discoverableOffers));
        if ((filteredOffers.length || response.discoverableOffers.length) && navBarStatus !== NavBarType.OFFERS) {
            const showTooltip = getShouldShowOfferTooltip(getState());
            dispatch(actionCreators.setShowTooltip(showTooltip));
        }
        if (!filteredOffers.length && !response.discoverableOffers.length && navBarStatus === NavBarType.OFFERS) {
            dispatch(showNoOffersMessage());
        }
        if (locationId) {
            dispatch(marketingMetaData(locationId));
        }
    });
}

export function showNoOffersMessage() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const venueName = getLocationName(getState());
        dispatch(
            showModalMessage(
                modalMessages.noOffersFound(() => dispatch(navBarActionCreators.activeType(NavBarType.MENU)), venueName)
            )
        );
    };
}
